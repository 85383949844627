import React from "react"

import { MDBDataTable } from "mdbreact"
import { Col, Row } from "reactstrap"

const Course = () => {
  const detailsData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },

      {
        label: "Course",
        field: "course",
        sort: "asc",
        width: 200,
      },

      {
        label: "Start Date",
        field: "start",
        sort: "asc",
        width: 200,
      },
      {
        label: "End Date",
        field: "end",
        sort: "asc",
        width: 200,
      },
      {
        label: "Mode",
        field: "mode",
        sort: "asc",
        width: 200,
      },
      {
        label: "duration",
        field: "duration",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: [
      {
        id: "1",
        mode: "Online",
        date: "02-02-2024",
        start: "02-02-2024",
        end: "15-06-2024",
        duration: "720 Hours",
        course: "Marine Structural Fitter and Fabricator",
        sector: "Automotive",
        cluster: "Linguistics,Management,Automotive",

        action: (
          <>
            <i
              className="fas fa-eye"
              style={{
                color: "black",
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
            ></i>
          </>
        ),
      },
      {
        id: "2",
        date: "03-02-2024",
        start: "02-02-2024",
        end: "15-06-2024",
        duration: "720 Hours",
        course: "Project Management for Engineers",
        sector: "Automotive",
        cluster: "Linguistics,Management,Automotive",
        mode: "Offline",

        action: (
          <>
            <i
              className="fas fa-eye"
              style={{
                color: "black",
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
            ></i>
          </>
        ),
      },
    ],
  }
  return (
    <Row>
      <Col md="12">
        <MDBDataTable id="" responsive bordered data={detailsData} />
      </Col>
    </Row>
  )
}

export default Course
