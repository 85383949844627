import React, { useState, useEffect, useRef } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { getBranches, getCourses } from "../../../store/options/actions"
import { post, put, del, get } from "../../../helpers/api_helper"
import toastr from "toastr"
import Swal from "sweetalert2"
import SelectErrorMessage from "../../../components/Common/SelectErrorMessage"
import { API_URL } from "../../../config"
import axios from "axios"
import { dateConverter, jsonToExcel } from "../../../helpers/functions"
import useBranch from "../../../hooks/useBranch"
import PlacementModal from "./PlacementModal"
import CreatableSelect from 'react-select/creatable';
import moment from "moment"


const Placements = () => {
  const dispatch = useDispatch()
  const formRef = useRef()
  const filterRef = useRef()

  const myCsp = useBranch()
  const [myCspId, setMyCspId] = useState("")
  const { branches } = useSelector(state => state.Options)
  const [state, setState] = useState(false)
  const [popupView, setPopupView] = useState(false)
  const [modalData,setModalData] = useState(null)
  const [exportData, setExportData] = useState({})
  const initialState = {
    branch: "",
    course: "",
    student: "",
    designation: "",
    companyName: "",
    image: "",
  }
  const selectedState = {
    course: "",
    branch: "",
    student: "",
  }
  const errorState = {
    course: "",
    branch: "",
    student: "",
  }

  // const [popupView, setPopupView] = useState(false)
  const [modalImg, setModalImg] = useState("")
  const [masterObject, setMasterObject] = useState(initialState)
  const [filterObject, setFilterObject] = useState({})
  const [tableData, setTableData] = useState([])
  const [selected, setSelected] = useState(selectedState)
  const [error, setError] = useState(errorState)
  const [courses, setCourses] = useState([])
  const [students, setStudents] = useState([])
  const [Image, setImage] = useState("")

  const [selectedCsp, setSelectedCsp] = useState(null)
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [selectedStudent, setSelectedStudent] = useState(null)

  const [courses2, setCourses2] = useState([])

  const [selectedCsp2, setSelectedCsp2] = useState(null)
  const [selectedCourse2, setSelectedCourse2] = useState(null)
  const [selectedStudent2, setSelectedStudent2] = useState(null)

  useEffect(() => {
    let cspId = myCsp ? myCsp?.[0]?.value : ""
    if(cspId != ""){
      setMyCspId(cspId)
      setMasterObject({ ...masterObject, branch: cspId })
    }
  }, [state])

  useEffect(() => {
    dispatch(getBranches())
  }, [])
  useEffect(() => {
    handleTableData()
  }, [filterObject, selectedStudent2])

  useEffect(() => {
    fetchStudents()
  }, [selectedCsp,selectedCourse])


  useEffect(() => {
    let cspId = myCsp ? myCsp?.[0]?.value : ""
    if(cspId) getCourses(cspId)
  }, [selectedCsp,state])

  useEffect(() => {
    if (selectedCsp2?.value) {
      getCourses2()
    }
  }, [selectedCsp2])


  const fetchStudents = async () => {
    try {
      if (selectedCourse?.value) {
        const response = await get(
          `placement/students-options?course=${selectedCourse?.value}&branch=${selectedCsp?.value}`
        )
        setStudents(response?.data)
      }
    } catch (error) {}
  }


  const getCourses = async branch => {
    return await get(`temp-batch/course/${branch}`).then(res => {
      setCourses(res.data)
      return res.data
    })
  }

  const getCourses2 = async branch => {
    return await get(`temp-batch/course/${selectedCsp2?.value}`).then(res => {
      setCourses2(res.data)
      return res.data
    })
  }

  const handleTableData = () => {
    get(
      `placement?course=${filterObject?.course}&branch=${filterObject?.csp}&student=${selectedStudent2?.value}`
    ).then(res => {
      const result = res.data || []
      const exportData = []
      result.map((item, index) => {
        item.id = index + 1
        item.date = dateConverter(item.date)
        item.student = item.student
        item.studentId = item.studentId
        item.imageData = (
          <div>
            {item?.image && (
              <a
                href={`${API_URL}${item.image}`}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  style={{ height: "30px", width: "40px", cursor: "pointer" }}
                  src={API_URL + item.image}
                  alt="img"
                />
              </a>
            )}
          </div>
        )
        item.options = (
          <div style={{ display: "flex", justifyContent: "center" }}>
                <i
        className="fas fa-eye"
        style={{
          fontSize: "1em",
          cursor: "pointer",
          marginRight: "0.5rem",
        }}
        onClick={() => {
          setPopupView(true)
          setModalData(item)
        }}
      ></i>
            <i
              className="far fa-edit"
              onClick={() => handleEdit(item)}
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              onClick={() => handleDelete(item._id)}
              style={{ cursor: "pointer", fontSize: "1em" }}
            ></i>
          </div>
        )
        const dataExport = {}
        dataExport.id = item.id
        dataExport.Date = item.date
        dataExport.CSP = item.branch
        dataExport.Course = item.course
        dataExport.Student = item.student
        dataExport.Company = item.companyName
        dataExport.Designation = item.designation
        exportData.push(dataExport)
        return item
      })
      setTableData(result)
      setExportData(exportData)
    })
  }
  const handleExportData = () => {
    if (exportData.length > 0) {
      jsonToExcel(
        exportData,
        `${`Placements`}-${moment().format("DD-MM-YY")}`
      )
    } else {
      toastr.info(`There are No Data to export`)
    }
  }
  const handleSubmit = () => {
    const err = { ...error }
    let alreadyError = false
    // if (!masterObject.branch) {
    //   err.branch = true
    //   alreadyError = true
    // }
    // if (!masterObject.course) {
    //   err.course = true
    //   alreadyError = true
    // }
    if (!masterObject.student) {
      err.student = true
      alreadyError = true
    }

    setError(err)
    if (alreadyError) return

    if (masterObject.id) {
      put("placement", masterObject)
        .then(res => {
          toastr.success(res.message)
          reset()
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    } else {
      post("placement", masterObject)
        .then(res => {
          toastr.success(res.message)
          reset()
        })
        .catch(err => {
          // toastr.error(err.response.message)
        })
    }
  }
  const handleFileUpload = (e, index) => {
    const selectedFile = e.target.files[0]

    const allowedTypes = ["image/jpeg", "image/png"]

    if (!allowedTypes.includes(selectedFile?.type)) {
      toastr.error("Please select a valid file type (JPEG, PNG)")
      // setMasterObject({...masterObject,image:""})

      return
    }
    const fd = new FormData()
    fd.append("file", e.target.files[0])
    setImage("")
    axios.post(`${API_URL}agreement/document`, fd).then(response => {
      if (response.data.status === 200) {
        setImage(response.data.data.new_filename)
        handleValueChange(response.data.data.new_filename, "image")
      } else {
        toastr.error(response.data.message)
      }
    })
  }

  const handleEdit = async item => {
    toTop()
   
    setSelected({
      course: {label:item?.course,value:item?.courseId},
      branch: {label:item?.branch,value:item?.branchId},
    })

    setSelectedStudent({ label: item?.student, value: item?.student})

    setMasterObject({
      id: item._id,
      branch: item.branchId,
      course: item.courseId,
      student: item?.student,
      designation: item.designation,
      companyName: item.companyName,
      image: item.image,
    })
  }
  const handleDelete = async id => {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`placement?id=${id}`)
          .then(res => {
            toastr.success(res.message)
            handleTableData()
          })
          .catch(err => {
            toastr.error(err.response.data.message)
          })
      }
    })
  }

  const handleValueChange = (value, name) => {
    const obj = { ...masterObject }
    obj[name] = value
    setMasterObject(obj)
  }
  const handleFilterValueChange = (value, name) => {
    const obj = { ...filterObject }
    obj[name] = value
    setFilterObject(obj)
  }
  const handleSelectedValueChange = (data, name) => {
    const obj = { ...selected }
    obj[name] = data
    name === 'student' ? handleValueChange(data?.label , name) : handleValueChange(data?.value , name)
    setSelected(obj)
    setError(prev => ({ ...prev, [name]: false }))
  }


  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }
  const reset = () => {
    formRef.current.reset()
    setFilterObject({})
    setMasterObject(initialState)
    setSelected({})
    setSelectedStudent(null)
    setStudents([])
    setError({})
    toTop()
    setCourses([])
    setImage("")
    setState(!state)
  }

  const reset2 = () => {
    filterRef.current.reset()
    setMasterObject(initialState)
    setFilterObject({})
    setCourses2([])
    setSelectedStudent2(null)
    setSelectedCourse2(null)
    setSelectedCsp2(null)
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "CSP",
        field: "branch",
        width: 150,
      },
      {
        label: "Course",
        field: "course",
        width: 150,
      },
      {
        label: "Student",
        field: "student",
        width: 150,
      },
      {
        label: "Company",
        field: "companyName",
        width: 150,
      },
      {
        label: "Designation",
        field: "designation",
        width: 150,
      },
      {
        label: "Image",
        field: "imageData",
        width: 150,
      },
      {
        label: "Action",
        field: "options",
        width: 150,
      },
    ],
    rows: tableData,
  }

  console.log(selectedStudent,'student',masterObject)

  return (
    <>
      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
      > 
        <div className="modal-header">
          <div className="modal-title">
            <h5>Placement Details </h5>
          </div>
          <button
            type="button"
            onClick={() => setPopupView(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <PlacementModal data={modalData} />
      </Modal>

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem="Placements" />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm ref={formRef} onValidSubmit={handleSubmit}>
                    <Row>
                      {!myCspId && (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>CSP</Label>
                            <Select
                              name="center"
                              classNamePrefix="select2-selection"
                              options={branches}
                              value={selected.branch || ""}
                              onChange={selected => {
                                handleSelectedValueChange(selected, "branch")
                                setSelectedCsp(selected)
                                getCourses(selected.value)
                              }}
                            />
                            <SelectErrorMessage
                              customStyle={{
                                width: "100%",
                                fontSize: "87.5%",
                                color: "var(--bs-form-invalid-color)",
                              }}
                              message="Select Center"
                              show={error.branch}
                            />
                          </div>
                        </Col>
                      )}
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Course</Label>
                          <Select
                            name="course"
                            classNamePrefix="select2-selection"
                            options={courses}
                            value={selected.course || ""}
                            onChange={selected => {
                              handleSelectedValueChange(selected, "course")
                              setSelectedCourse(selected)
                            }}
                          />
                          <SelectErrorMessage
                            customStyle={{
                              width: "100%",
                              fontSize: "87.5%",
                              color: "var(--bs-form-invalid-color)",
                            }}
                            message="Select Course"
                            show={error.course}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Student</Label>
                          {/* <Select
                            name="student"
                            classNamePrefix="select2-selection"
                            options={students}
                            value={selectedStudent}
                            onChange={selected => {
                              handleSelectedValueChange(selected, "student")
                              setSelectedStudent(selected)
                            }}
                          /> */}
                           <CreatableSelect
                            name="student"
                            isClearable 
                            classNamePrefix="select2-selection"
                            options={students}
                            value={selectedStudent}
                            onChange={selected => {
                              handleSelectedValueChange(selected, "student")
                              setSelectedStudent(selected)
                            }}
                            formatCreateLabel={(inputValue) => inputValue}
                            />
                          <SelectErrorMessage
                            customStyle={{
                              width: "100%",
                              fontSize: "87.5%",
                              color: "var(--bs-form-invalid-color)",
                            }}
                            message="Select student"
                            show={error.student}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Designation</Label>
                          <AvField
                            name="designation"
                            className="form-control"
                            type="text"
                            placeholder="Designation"
                            validate={{ required: { value: true } }}
                            errorMessage="Enter Designation"
                            value={masterObject.designation}
                            onChange={e => {
                              handleValueChange(e.target.value, e.target.name)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Company Name</Label>
                          <AvField
                            name="companyName"
                            placeholder="Company Name"
                            validate={{ required: { value: true } }}
                            value={masterObject.companyName}
                            errorMessage="Enter Company Name"
                            onChange={e => {
                              handleValueChange(e.target.value, e.target.name)
                            }}
                          />
                        </div>
                      </Col>

                      <Col md={masterObject.image ? "2" : "3"}>
                        <div className="mb-3">
                          <Label>Image</Label>
                          {masterObject?.image ? (
                            <div div className="img-wraps d-flex flex-column">
                              <img
                                alt=""
                                width="150"
                                height="150"
                                src={`${API_URL}${masterObject.image}`}
                              />
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={() => {
                                  handleValueChange("", "image")
                                  setImage("")
                                }}
                                style={{ width: "150px" }}
                                type="button"
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <input
                              name="image"
                              type="file"
                              accept="image/*"
                              className="form-control"
                              id="validationCustom03"
                              onChange={handleFileUpload}
                              value={Image}
                            />
                          )}
                        </div>
                      </Col>

                      <Col md="3" style={{ marginTop: "26px" }}>
                        <Button
                          color={masterObject.id ? "warning" : "primary"}
                          type="submit"
                        >
                          {masterObject.id ? "Update" : "Submit"}
                        </Button>
                        <Button
                          color="danger"
                          type="button"
                          onClick={reset}
                          className="ms-2"
                        >
                          Reset
                        </Button>
                        
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Row>
                    <AvForm ref={filterRef}>
                      <Row>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>CSP</Label>
                            <Select
                              name="csp"
                              classNamePrefix="select2-selection"
                              options={branches}
                              value={selectedCsp2}
                              onChange={selected => {
                                handleFilterValueChange(selected.value, "csp")
                                setSelected(prev => ({
                                  ...prev,
                                  filterCsp: selected,
                                }))
                                setSelectedCsp2(selected)
                              }}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Course</Label>
                            <Select
                              name="course"
                              classNamePrefix="select2-selection"
                              value={selectedCourse2}
                              options={courses2}
                              onChange={selected => {
                                handleFilterValueChange(
                                  selected.value,
                                  "course"
                                )
                                setSelected(prev => ({
                                  ...prev,
                                  filterCourse: selected,
                                }))
                                setSelectedCourse2(selected)
                              }}
                            />
                          </div>
                        </Col>

                        {/* <Col md="3">
                          <div className="mb-3">
                            <Label>Student</Label>
                            <Select
                              name="student"
                              classNamePrefix="select2-selection"
                              options={students2}
                              value={selectedStudent2}
                              onChange={selected =>
                                setSelectedStudent2(selected)
                              }
                            />
                           
                          </div>
                        </Col> */}

                        <Col style={{ marginTop: "26px" }}>
                          <Button
                            color="danger"
                            type="submit"
                            className="me-2"
                            onClick={reset2}
                          >
                            Reset
                          </Button>
                          <Button onClick={handleExportData} color="warning">
                          Export
                        </Button>
                        </Col>
                      </Row>
                    </AvForm>
                  </Row>
                  <Row>
                    <MDBDataTable
                      id="allocationTableIdd"
                      bordered
                      responsive
                      pagination={false}
                      searching={true}
                      entries={20}
                      disableRetreatAfterSorting={true}
                      data={data}
                    />
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default Placements
