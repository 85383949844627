import React, { useEffect, useState } from "react"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import toastr from "toastr"
import "./style.scss"
import { useNavigate } from "react-router-dom"
import { get, post } from "../../../helpers/api_helper"
import TablePagination from "../../../components/Common/Pagination"
import { jsonToExcel } from "../../../helpers/functions"
import moment from "moment"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { isArray } from "lodash"

const RankList = () => {
  const [batches, setBatches] = useState([])
  const [students, setStudents] = useState([])
  const initialstate = {
    to: "",
    from: "",
    testname: "",
    status: "",
  }
  const [filterobject, setFilterObject] = useState(initialstate)
  const [selected, setSelected] = useState({})
  console.log(filterobject, "filterobjecthb")
  const [test, setTest] = useState([])
  const [selectedBatches, setSelectedBatches] = useState(null)
  const [selectedStudetns, setSelectedStudents] = useState(null)
  const [selectedTest, setSelectedTest] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState(null)
  const [TableData, setTableData] = useState([])
  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 10
  const [exports, setExports] = useState([])
  const [state, setState] = useState(false)
  useEffect(() => {
    FetchBatches()
  }, [])
  useEffect(() => {
    if (selectedBatches && selectedBatches?.value) {
      FetchStudent()
    }
  }, [selectedBatches])

  useEffect(() => {
    FetchTest()
  }, [])

  useEffect(() => {
    if (selectedBatches && selectedBatches?.value) {
      FetchTableData()
    }
  }, [selectedBatches, page, state, filterobject])

  const FetchBatches = async () => {
    try {
      const res = await get(`/options/batches`)
      setBatches(res?.data)
      setSelectedBatches(res?.data[0])
    } catch (error) {}
  }

  const FetchStudent = async () => {
    try {
      const res = await get(`/assign-test/options/filter/students`)
      setStudents(res?.data)
    } catch (error) {}
  }

  const FetchTest = async () => {
    try {
      const res = await get(`/ranklist/options/test`)
      setTest(res.data)
    } catch (error) {}
  }

  const FetchTableData = async () => {
    try {
      const res = await get(
        `/rankList/ranklist-table?status=${filterobject?.status}&from=${filterobject?.from}&to=${filterobject?.to}&test=${filterobject?.testname}&page=${page}&limit=${limit}`
      )

      const count = res?.count
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)

      let result = res?.data
      let data = []
      const exportData = []
      result.map((item, index) => {
        const dataExport = {}
 
        dataExport["#"] = index + 1
        dataExport["Test Name"] = item.test
        dataExport.UniqueId = item.uniqueId
        // dataExport.Name = `${item.firstname} ${item.lastname}`
        dataExport["TotalRanks"] = item.totalRanks
        dataExport.Status = item?.published === 1 ? "Published" : "Not Published"
        exportData.push(dataExport)
        data.push(item)
      })
      setTableData(data)
      setExports(exportData)
    } catch (error) {}
  }

  const handleExport = () => {
    if (exports.length > 0) {
      jsonToExcel(exports, `Rank list-${moment().format("DD-MM-YY")}`)
    } else {
      toastr.info(`There are no Requests to export`)
    }
  }

  const publishing = async id => {
    try {
      const res = await post(`/rankList/publish`, { id: id })
      if (res.message == "Document already published") {
        FetchTableData()
        toastr.error(res.message)
      } else {
        FetchTableData()

        toastr.success(res.message)
      }
    } catch (err) {
      toastr.error(err.response.data.message)
    }
  }

  const handleChange = (event, value) => {
    setPage(value)
  }

  const navigate = useNavigate()

  const reset = () => {
    setSelectedStatus(null)
    setSelectedStudents(null)
    setSelectedTest(null)
    setState(!state)
    setFilterObject(initialstate)
    setSelected({ testname: "", status: "" })
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      // {
      //   label: "Batch",
      //   field: "batch",
      //   width: 150,
      // },
      {
        label: "Test Name",
        field: "test",
        width: 150,
      },

      {
        label: "Unique ID",
        field: "UniqueID",
        width: 150,
      },
      // {
      //   label: "Test",
      //   field: "test",
      //   width: 200,
      // },

      {
        label: "Total Ranks",
        field: "Rank",
        width: 100,
      },

      {
        label: "Staff",
        field: "added",
        width: 150,
      },

      {
        label: "Status",
        field: "status",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        width: 150,
      },
    ],
    rows: TableData.map((item, index) => ({
      id: (page-1) *limit + index + 1,

      test: item?.test,
      added: `${item?.addedBy?.firstName || ""} ${
        item?.addedBy?.lastName || ""
      }`,
      UniqueID: item?.uniqueId,
      name: `${item.firstname} ${item.lastname}`,
      date: moment(item?.date).format("DD-MM-YYYY"),
      Rank: item.totalRanks,
      
      value:item.status==1?"published" :"not published",
      
      status: (
        <div>
          <span
            style={{
              color: item.status === 1 ? "blue" : "red",
              fontWeight: 500,
            }}
          >
            {item.status === 1 ? "Published" : "Not Published"}
          </span>
        </div>
      ),
      action: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            className="fas fa-eye"
            style={{
              fontSize: "1em",
              cursor: "pointer",
              marginRight: "0.5rem",
            }}
            onClick={() => {
              navigate(`/rank_list/${item?.uniqueId}`, {
                state: { id: item._id },
              })
            }}
          ></i>
           {item.status!=1&&
           <OverlayTrigger
           placement="top"
           overlay={
             <Tooltip
               id="button-tooltip-2"
               style={{ position: "absolute", top: "-30px" }}
             >
               Publish Result
             </Tooltip>
           }
         >
           <i
             onClick={() => publishing(item._id)}
             className="fa fa-share-alt"
           ></i>
         </OverlayTrigger>
           }
          
        </div>
      ),
      key: index + 1,
    })),
  }

  const handleValueChange = (value, name) => {
    const obj = { ...filterobject }
    obj[name] = value
    setFilterObject(obj)
  }

  const handleSelectChange = (e, name) => {
    const value = e ? (isArray(e) ? e.map(item => item.value) : e.value) : null
    setSelected(prev => ({ ...prev, [name]: e }))
    setFilterObject(prev => ({ ...prev, [name]: value }))
  }
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem="Rank List" />

          <Card>
            <CardBody>
              <Row>
                <AvForm>
                  <Row>
                    {/* <Col md="3">
                    <div className="mb-3">
                      <Label>Batch</Label>
                      <Select
                        name="batch"
                        classNamePrefix="select2-selection"
                        options={batches}
                        onChange={item => setSelectedBatches(item)}
                        value={selectedBatches}
                      />
                    </div>
                  </Col> */}
                    <Col md={2}>
                      <div className="mb-3">
                        <Label>From</Label>
                        <AvField
                          name="from"
                          type="date"
                          value={filterobject.from}
                          classNamePrefix="select2-selection"
                          onChange={e =>
                            handleValueChange(e.target.value, "from")
                          }
                        />
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="mb-3">
                        <Label>To</Label>
                        <AvField
                          name="to"
                          type="date"
                          value={filterobject.to}
                          classNamePrefix="select2-selection"
                          onChange={e =>
                            handleValueChange(e.target.value, "to")
                          }
                        />
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="mb-3">
                        <Label>Test Name</Label>
                        <Select
                          name="test"
                          classNamePrefix="select2-selection"
                          options={test}
                          value={selected.testname}
                          onChange={e => handleSelectChange(e, "testname")}
                          // value={selectedTest}
                        />
                      </div>
                    </Col>
                    {/* <Col md={2}>
                    <div className="mb-3">
                      <Label>Student</Label>
                      <Select
                        name="student"
                        classNamePrefix="select2-selection"
                        options={students}
                        value={selected.student}
                        onChange={e=> handleSelectChange(e ,"student")}
                        // onChange={item => setSelectedStudents(item)}
                        // value={selectedStudetns}
                      />
                    </div>
                  </Col> */}

                    {/* <Col md={2}>
                  <div className="mb-3">
                    <Label>Eligiblity</Label>
                    <Select
                      name="test"
                      classNamePrefix="select2-selection"
                      options={[
                        {
                          label: "Eligible",
                          value: 0,
                        },
                        {
                          label: "Not Eligible",
                          value: 1,
                        },
                      ]}
                    />
                  </div>
                </Col> */}
                    <Col md={2}>
                      <div className="mb-3">
                        <Label>Status</Label>
                        <Select
                          name="test"
                          classNamePrefix="select2-selection"
                          value={selected.status}
                          onChange={e => handleSelectChange(e, "status")}
                          options={[
                            {
                              label: "Published",
                              value: 1,
                            },
                            {
                              label: "Not Published",
                              value: 0,
                            },
                          ]}
                          // onChange={item => setSelectedStatus(item)}
                          // value={selectedStatus}
                        />
                      </div>
                    </Col>
                    <Col>
                      <Button
                        type="button"
                        style={{ marginTop: "1.7rem" }}
                        color="danger"
                        onClick={reset}
                      >
                        Reset
                      </Button>
                      {/* <Button
                        style={{ marginTop: "1.7rem", marginLeft: "1rem" }}
                        color="warning"
                        type="submit"
                        onClick={handleExport}
                      >
                        Export
                      </Button> */}
                    </Col>
                  </Row>
                </AvForm>

                <Col md={12}>
                  <MDBDataTable
                    id="rankList"
                    responsive
                    bordered
                    info={true}
                    searching={true}
                    entries={10}
                    disableRetreatAfterSorting={true}
                    data={data}
                    paginationLabel={false}
                    paging={false}
                  />
                  <TablePagination
                    page={page}
                    onChange={handleChange}
                    count={totalPage}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  )
}

export default RankList
