import React, { useEffect, useState } from "react"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Collapse, Card, CardBody } from "reactstrap"
import { get } from "../../../../helpers/api_helper"
import moment from "moment"
import classnames from "classnames"
import { Divider } from "@mui/material"

function MarkAssessment(props) {


  const [selectedenrolled, setselectedEnrolled] = useState([])
  const [activeTab, setActiveTab] = useState(null)
  const [assessmentMarks,setAssessmentMarks] = useState([])

  function MarksAssessment() {
    get(`/student/exam-details?studentId=${props?.id}`)
      .then(res => {
        const result = res?.examDetails
        let data = []
        result?.map((item, index) => {
          item.id = index + 1

          item.date = moment(item?.date).format("DD-MM-YYYY")

          data.push(item)
          return item
        })

        setselectedEnrolled(data)
      })

      .catch(error => {
        console.error("Error :", error)
      })
  }

  function fetchFinalAssessment() {
    get(`student/assessment?id=${props?.id}`)
      .then(res => {
        if (res.success) {
          setAssessmentMarks(res.data)
        }
      })
      .catch(error => {
        console.error("Error :", error)
      })
  }

  useEffect(() => {
    MarksAssessment()
    fetchFinalAssessment()
  }, [props?.id])

  const toggleTab = tab => {
    if (activeTab === tab) {
      setActiveTab(null)
    } else {
      setActiveTab(tab)
    }
  }

  const certificates = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        width: 50,
      },
      // {
      //   label: "Assessment Type",
      //   field: "title",
      //   width: 150,
      // },
      {
        label: "Assessment Name",
        field: "test_name",
        width: 50,
      },
      {
        label: "Max. Mark",
        field: "max_marks",
        width: 50,
      },
      {
        label: "Marks Awarded",
        field: "marks_obtained",
        width: 50,
      },
    ],
    rows: selectedenrolled,
  }

  return (
    <>
      <Row>
        <Col xl="12">
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className={classnames("accordion-button", "fw-medium", {
                    collapsed: activeTab !== 1,
                  })}
                  type="button"
                  onClick={() => toggleTab(1)}
                  style={{ cursor: "pointer" }}
                >
                  <h6>Screening Test</h6>
                </button>
              </h2>
              <Collapse
                id="collapseTwo"
                className="accordion-collapse"
                isOpen={activeTab === 1}
              >
                <div className="accordion-body">
                  <Row>
                    <Col md="12">
                      <MDBDataTable
                        id="marksTableId"
                        className="marks-assessment"
                        responsive
                        bordered
                        data={certificates}
                        info={true}
                        searching={true}
                        disableRetreatAfterSorting={true}
                        entries={20}
                      />
                    </Col>
                  </Row>
                </div>
              </Collapse>
            </div>
            {assessmentMarks.length > 0 && <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className={classnames("accordion-button", "fw-medium", {
                    collapsed: activeTab !== 2,
                  })}
                  type="button"
                  onClick={() => toggleTab(2)}
                  style={{ cursor: "pointer" }}
                >
                  <h6>Assessment Report</h6>
                </button>
              </h2>
              <Collapse
                id="collapseTwo"
                className="accordion-collapse"
                isOpen={activeTab === 2}
              >
                <div className="accordion-body">
                  <Row>
                    {assessmentMarks.map((item,index)=>(
                         <Col lg="4" sm='6' xs='12'>
                         <Card>
                           <CardBody>
                            <h5>{item.course?.name}</h5>
                            <p>{item.branch?.name}</p>
                            <Divider className="mb-2"/>
                            <Row className="mb-2">
                              <Col sm='12'><h6>Internal Marks</h6></Col>
                              {item.internalMarks.map((subItem,index)=>(<>
                              <Col md='10' className="d-flex justify-content-between"><span>Test {index + 1} </span> {subItem.mark} {subItem.examType === 1 && `/ ${subItem.maxMark}`}</Col>
                              <Col sm='12'><Divider/></Col></>
                            ))}</Row>
                            <h6>Final Assessment</h6>
                            <Row>
                            <Col md='4'><span>{`Grade  :  ${item.finalGrade}`}</span></Col>
                            <Col md='8'><span style={{textAlign:'right'}}>{`Date  :  ${item.finalAssessmentDate}`}</span></Col>
                            </Row>
                           </CardBody>
                         </Card>
                       </Col>

                    ))}
                  </Row>
                </div>
              </Collapse>
            </div>}
          </div>
        </Col>
      </Row>
    </>
  )
}

export default MarkAssessment
