/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Row, Col, Button, Label, Card, CardBody } from "reactstrap"
import Select from "react-select"
import { isArray } from "lodash"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import {
  getDegree,
  getDistrictOptions,
  getStream,
  getStudentCategory,
} from "../../store/options/actions"
import { get, post, put } from "../../helpers/api_helper"

import toastr from "toastr"
import moment from "moment"

const StudentCommonTab = ({ studentId, refreshFunction }) => {
  const [master, setMaster] = useState({})

  const [selected, setSelected] = useState({})

  const OPTIONS = useSelector(state => state.Options)

  const dispatch = useDispatch()

  useEffect(() => {
    get(`student/${studentId}`).then(res => {
      let data = res.data

      const selectedData = {}

      selectedData.gender =
        data.gender === 0
          ? { label: "Female", value: "0" }
          : data.gender === 1
          ? { label: "Male", value: "1" }
          : data.gender === 2
          ? { label: "Other", value: "2" }
          : null

      selectedData.category = {
        label: data?.additionalDetails?.category?.shortCode,
        value: data?.additionalDetails?.category?._id,
      }

      selectedData.state = {
        label: data?.state?.name,
        value: data?.state?._id,
      }

      selectedData.district = {
        label: data?.district?.district_name,
        value: data?.district?._id,
      }

      // selectedData.qualification = qualification.options
      // selectedData.degree = degree.options
      // selectedData.stream = stream.options

      data.category = data?.additionalDetails?.category?._id
      data.state = data?.state?._id
      data.district = data?.district?._id
      // data.qualification = qualification.values
      // data.degree = degree.values
      // data.stream = stream.values

      selectedData.minorityStatus = {
        label: data?.additionalDetails?.minorityStatus?.name,
        value: data?.additionalDetails?.minorityStatus?._id,
      }

      selectedData.povertyLine = {
        label: data?.additionalDetails?.povertyLine,
        value: data?.additionalDetails?.povertyLine,
      }

      selectedData.typeOfArea = {
        label: data?.additionalDetails?.typeOfArea,
        value: data?.additionalDetails?.typeOfArea,
      }

      data.qualifications =
        data?.qualifications.length > 0 ? data?.qualifications : [{}]

      data?.qualifications?.map(item => {
        item.selectedqualification = {
          label: item?.qualification?.name,
          value: item?.qualification?._id,
        }

        item.selecteddegree = {
          label: item?.degree?.name,
          value: item?.degree?._id,
        }

        item.selectedstream = {
          label: item?.stream?.name,
          value: item?.stream?._id,
        }

        item.qualification = item?.qualification?._id
        item.degree = item?.degree?._id
        item.stream = item?.stream?._id

        return item
      })

      delete data?.additionalDetails?.category

      data = {
        ...data,
        ...data?.additionalDetails,
      }

      delete data.additionalDetails

      dispatch(getDistrictOptions({ id: data.state }))
      setSelected(pre => ({ ...pre, ...selectedData }))
      setMaster(data)
    })
  }, [studentId, dispatch])

  const handleSelect = (e, name) => {
    const value = e ? (isArray(e) ? e.map(item => item.value) : e.value) : null
    setSelected(pre => ({ ...pre, [name]: e }))
    setMaster(pre => ({ ...pre, [name]: value }))
  }

  const handleChange = e => {
    let { name, value } = e.target
    setMaster(pre => ({ ...pre, [name]: value }))
  }

  const handleSubmit = () => {
    put(`student/${studentId}`, master)
      .then(res => {
        toastr.success(res.message)
        refreshFunction()
      })
      .catch(err => {
        console.log("err: ", err)
        toastr.error(err.message)
      })
  }

  const handleQualification = (e, name, index) => {
    setMaster(prevState => {
      const updatedQualifications = [...prevState.qualifications]
      updatedQualifications[index][name] = e.value
      updatedQualifications[index][`selected${name}`] = e
      return {
        ...prevState,
        qualifications: updatedQualifications,
      }
    })
  }

  return (
    <AvForm onValidSubmit={handleSubmit}>
      <Col md="12">
        <Row>
          <Col sm={6} lg={4} xl={3}>
            <div className="mb-3">
              <Label htmlFor="firstname">First Name</Label>
              <AvField
                name="firstname"
                id="firstName"
                placeholder="Name"
                value={master?.firstname}
                validate={{
                  pattern: {
                    value: "^[a-zA-Z ]+$",
                  },
                }}
                onChange={handleChange}
              />
            </div>
          </Col>
          <Col sm={6} lg={4} xl={3}>
            <div className="mb-3">
              <Label>Last Name</Label>
              <AvField
                name="lastname"
                placeholder="Name"
                value={master?.lastname}
                onChange={handleChange}
                validate={{
                  pattern: {
                    value: "^[a-zA-Z ]+$",
                  },
                }}
              />
            </div>
          </Col>
          <Col sm={6} lg={4} xl={3}>
            <div className="mb-3">
              <Label>Mobile</Label>
              <AvField
                name="mobile"
                type="number"
                placeholder="Mobile"
                value={master?.mobile}
                validate={{
                  required: { value: true },
                  pattern: {
                    value: "^(0|91)?[6-9][0-9]{9}$",
                  },
                }}
                onChange={handleChange}
              />
            </div>
          </Col>
          <Col sm={6} lg={4} xl={3}>
            <div className="mb-3">
              <Label>Email</Label>
              <AvField
                name="email"
                type="email"
                placeholder="Email"
                errorMessage="Enter valid Email"
                onChange={handleChange}
              />
            </div>
          </Col>
          <Col sm={6} lg={4} xl={3}>
            <div className="mb-3">
              <Label>DOB</Label>
              <AvField
                name="dob"
                type="date"
                placeholder="DOB"
                value={master?.dob}
                max={moment().format("YYYY-MM-DD")}
                onChange={handleChange}
              />
            </div>
          </Col>

          <Col sm={6} lg={4} xl={3}>
            <div className="mb-3">
              <Label>Gender</Label>
              <Select
                name="name"
                value={selected.gender}
                options={[
                  { label: "Female", value: "0" },
                  { label: "Male", value: "1" },
                  { label: "Other", value: "2" },
                ]}
                onChange={e => {
                  handleSelect(e, "gender")
                }}
              />
            </div>
          </Col>

          <Col sm={6} lg={4} xl={3}>
            <div className="mb-3">
              <Label>Aadhar Number</Label>
              <AvField
                name="aadharNo"
                value={master.aadharNo}
                onChange={handleChange}
                type="text"
                placeholder="0000 1111 2222"
                errorMessage="Enter valid aadhar number"
                validate={{
                  required: { value: true },
                  pattern: {
                    value: "^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$",
                  },
                }}
              />
            </div>
          </Col>

          <Col sm={6} lg={4} xl={3}>
            <div className="mb-3">
              <Label>Category</Label>
              <Select
                value={selected.category}
                options={OPTIONS.studentCategory}
                onChange={e => {
                  handleSelect(e, "category")
                }}
              />
            </div>
          </Col>
          <Col sm={6} lg={4} xl={3}>
            <div className="mb-3">
              <Label>State</Label>
              <Select
                name="name"
                options={OPTIONS.states}
                value={selected.state}
                onChange={e => {
                  handleSelect(e, "state")
                  dispatch(getDistrictOptions({ id: e.value }))
                }}
              />
            </div>
          </Col>
          <Col sm={6} lg={4} xl={3}>
            <div className="mb-3">
              <Label>District</Label>
              <Select
                name="name"
                value={selected?.district}
                onChange={e => {
                  handleSelect(e, "district")
                }}
                options={OPTIONS.distrcts}
              />
            </div>
          </Col>
          <Col sm={6} lg={4} xl={3}>
            <div className="mb-3">
              <Label>Address</Label>
              <AvField
                name="address"
                value={master.address}
                onChange={handleChange}
                type="textarea"
                rows={1}
                placeholder="address"
              />
            </div>
          </Col>
          <Col sm={6} lg={4} xl={3}>
            <div className="mb-3">
              <Label>City</Label>
              <AvField
                onChange={handleChange}
                name="city"
                placeholder="City"
                value={master?.city}
              />
            </div>
          </Col>

          <Card>
            <CardBody className="p-1 pt-3">
              {master?.qualifications?.map((item, idx) => (
                <Row key={idx}>
                  <Col sm={6} lg={4} xl={3}>
                    <div className="mb-3">
                      <Label>Qualification</Label>
                      <Select
                        name="qualification"
                        value={item?.selectedqualification}
                        onChange={e => {
                          handleQualification(e, "qualification", idx)
                        }}
                        options={OPTIONS.qualification}
                      />
                    </div>
                  </Col>
                  <Col sm={6} lg={4} xl={3}>
                    <div className="mb-3">
                      <Label>Degree</Label>
                      <Select
                        name="degree"
                        placeholder="Degree"
                        options={OPTIONS.degree.filter(
                          d =>
                            d.qualification ===
                            item?.selectedqualification?.value
                        )}
                        value={item?.selecteddegree}
                        onChange={e => {
                          handleQualification(e, "degree", idx)
                        }}
                      />
                    </div>
                  </Col>
                  <Col sm={6} lg={4} xl={3}>
                    <div className="mb-3">
                      <Label>Stream</Label>
                      <Select
                        name="stram"
                        placeholder="Stream"
                        options={OPTIONS.stream.filter(
                          d => d.degree === item?.selecteddegree?.value
                        )}
                        value={item?.selectedstream}
                        onChange={e => {
                          handleQualification(e, "stream", idx)
                        }}
                      />
                    </div>
                  </Col>
                  {master?.qualifications?.length > 1 && (
                    <Col md={1} className="d-flex align-items-center">
                      <span
                        className="mr-1 p-2"
                        style={{
                          fontSize: "1.125rem",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          const data = { ...master }
                          data.qualifications.splice(idx, 1)
                          setMaster(data)
                        }}
                      >
                        <i className="trash-btn mdi mdi-delete"></i>
                      </span>
                    </Col>
                  )}
                </Row>
              ))}
              <Row>
                <Col md={2}>
                  <button
                    type="button"
                    onClick={() => {
                      const data = { ...master }
                      data.qualifications.push({})
                      setMaster(data)
                    }}
                    className="waves-effect btn btn-outline-light d-flex"
                    style={{ gap: "5px" }}
                  >
                    Add more <i className="mdi mdi-plus-circle-outline"></i>
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Row>

        <Row>
          <Label style={{ fontSize: ".9rem" }}>Additional details</Label>

          <Col sm={6} lg={4} xl={3}>
            <div className="mb-3">
              <Label>Father Name</Label>
              <AvField
                placeholder="Name"
                name="father"
                value={master?.father}
                onChange={handleChange}
                validate={{
                  pattern: {
                    value: "^[a-zA-Z ]+$",
                  },
                }}
              />
            </div>
          </Col>
          <Col sm={6} lg={4} xl={3}>
            <div className="mb-3">
              <Label>Mother Name</Label>
              <AvField
                name="mother"
                placeholder="Name"
                value={master?.mother}
                onChange={handleChange}
                validate={{
                  pattern: {
                    value: "^[a-zA-Z ]+$",
                  },
                }}
              />
            </div>
          </Col>
          <Col sm={6} lg={4} xl={3}>
            <div className="mb-3">
              <Label>Guardian Name</Label>
              <AvField
                name="guardian"
                placeholder="Guardian"
                value={master?.guardian}
                onChange={handleChange}
                validate={{
                  pattern: {
                    value: "^[a-zA-Z ]+$",
                  },
                }}
              />
            </div>
          </Col>
          <Col sm={6} lg={4} xl={3}>
            <div className="mb-3">
              <Label>Constituency</Label>
              <AvField
                name="constituency"
                type="text"
                placeholder="Constituency"
                value={master?.constituency}
                onChange={handleChange}
                validate={{
                  pattern: {
                    value: "^[a-zA-Z ]+$",
                  },
                }}
              />
            </div>
          </Col>
          <Col sm={6} lg={4} xl={3}>
            <div className="mb-3">
              <Label>DWMS ID</Label>
              <AvField
                name="dwmsId"
                type="text"
                placeholder="DWMS ID"
                value={master?.dwmsId}
                onChange={handleChange}
                validate={{
                  pattern: {
                    value: "^[0-9]{9}$",
                  },
                }}
              />
            </div>
          </Col>
          <Col sm={6} lg={4} xl={3}>
            <div className="mb-3">
              <Label>APL/BPL</Label>
              <Select
                name="povertyLine"
                value={selected.povertyLine}
                onChange={e => {
                  handleSelect(e, "povertyLine")
                }}
                options={[
                  { label: "APL", value: "APL" },
                  { label: "BPL", value: "BPL" },
                ]}
              />
            </div>
          </Col>
          <Col sm={6} lg={4} xl={3}>
            <div className="mb-3">
              <Label>Minority Status</Label>
              <Select
                name="minorityStatus"
                value={selected.minorityStatus}
                onChange={e => {
                  handleSelect(e, "minorityStatus")
                }}
                options={OPTIONS.minorityStatus}
              />
            </div>
          </Col>
          <Col sm={6} lg={4} xl={3}>
            <div className="mb-3">
              <Label>Rural/Urban</Label>
              <Select
                name="typeOfArea"
                value={selected.typeOfArea}
                onChange={e => {
                  handleSelect(e, "typeOfArea")
                }}
                options={[
                  { label: "Rural", value: "Rural" },
                  { label: "Urban", value: "Urban" },
                ]}
              />
            </div>
          </Col>
          <Col sm={6} lg={4} xl={3}>
            <div className="mb-3">
              <Label>Annual Income</Label>
              <AvField
                name="annualIncome"
                onChange={handleChange}
                type="number"
                placeholder="Annual Income"
                value={master?.annualIncome}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button type="submit" color="warning">
                Update
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
    </AvForm>
  )
}

StudentCommonTab.propTypes = {}

export default StudentCommonTab
