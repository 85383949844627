export const API_URL = process.env.REACT_APP_APIURL
export const WEB_URL = process.env.REACT_APP_WEB_URL
// export const APP_NAME = process.env.REACT_APP_NAME || "Additional Skill Acquisition Programme Kerala"
export const APP_NAME =
  process.env.REACT_APP_NAME || "ASAP Community Skill Park Kerala"

export const CRYPTO_HASH = process.env.REACT_APP_CRYPTO_HASH

export const PRIVILEGES = {
  ADMIN: "61718a27e65a5639a3211feb",
  PROGRAM_MANAGER: "65bb3e3623e3dd8f4ac9ac2f",
  OPERATING_PARTNER: "65bb413ddbac38501461f47a",
  TRAINING_PARTNER: "65bb414edbac38501461f47b",
  CERTIFICATION_PARTNER: "65bb4182dbac38501461f47c",
  FUNDING_SOURCE: "65c47243b0ec20bc718fb92f",
  INTERN: "65ddbab40b6cd3ffea926ea2",
  TRAINER: "65ddbaa50b6cd3ffea926e98",
  RECRUITER: "6620ba6c079fc1daa8c8c220",
  CSP_PROGRAM_MANAGER: "65bb3e3623e3dd8f4ac9ac2f",
  SKILLS_PROGRAMME_MANAGER: "65ddb9d10b6cd3ffea926e4a",
  FINANCE_PROGRAMME_MANAGER: "65ddb9e80b6cd3ffea926e54",
  BUSINESS_HEAD: "65ddba090b6cd3ffea926e60",
  EVENTS_PROGRAMME_MANAGER: "65ddba230b6cd3ffea926e6a",
  ZONAL_HEAD: "65ddba410b6cd3ffea926e76",
  INTERNS: "65ddbab40b6cd3ffea926ea2",
  ASSESSMENT_PM: "6660394ff586a338324d8f88",
  CERTIFICATE_PM: "66603969f586a338324d8f9a",
  RECRUITER_PM: '666bcdf84a7badf2516b4a27'
}

export const COMPANY_TYPE = {
  ASAP: "65bb40a4dbac38501461f479",
  OPERATING_PARTNER: "65bb413ddbac38501461f47a",
  TRAINING_PARTNER: "65bb414edbac38501461f47b",
  CERTIFICATION_PARTNER: "65bb4182dbac38501461f47c",
  RECRUITER: "6620a97b20af0455ad46ccb3",
}

export const SKILL_LOAN_STATUS = {
  APPROVED: "65df0cc17955ee778a2af1e7",
}

export const SCHOLARSHIP_STATUS = {
  APPROVED: "65df0cc17955ee778a2af1e7",
}

export const LEAD_STATUS = {
  COURSE_CONFIRM: "6392cbe02d917dfcd065310a",
  EVENT_CONFIRM: "6603b27f63deeddb6d421bde",
  REASSIGN: "62c7d7117333e58969b3de0b",
  STAFF_ASSIGN: "65eaf448770ef7dd81f0648f",
  NOT_INTERESTED: "62c7d7427333e58969b3de0d",
}

export const ACCOUNT_TYPE = {
  expense: "65dc333401e1f55ffee67adb",
  income: "65dc332901e1f55ffee67ad1",
  bank: "65dc333901e1f55ffee67ae3",
}


export const NOTI_VAPIDKEY = process.env.REACT_APP_NOTI_VAPIDKEY