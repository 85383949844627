import React, { useEffect, useState } from "react"
import { Badge } from "reactstrap"
import { get } from "../../../../helpers/api_helper"
import moment from "moment"
import {
  dateConverter,
  numberToCurrency,
  timeConverter,
} from "../../../../helpers/functions"

export default function Details({ id }) {
  const [modalData, setModalData] = useState({})
  useEffect(() => {
    if (id) {
      handlePopUp(id)
    }
  }, [id])
  const handlePopUp = async id => {
    try {
      const response = await get(`credit-note/details?id=${id}`)
      const data = response.data
      
      data.student = `${data?.firstName || ""} ${data?.lastName || ""}`
      data.staff = `${data?.addedFirstName || ""} ${data?.addedLastName || ""}`
      data.approvedStaff = `${data?.approvedFirstName || ""} ${
        data?.approvedLastName || ""
      }`

      data.statusNumber = data.status
      data.statusText =
        data.status === 0 ? "Approved" : data.status === 3 ? "Cancelled" : ""

      data.status = (
        <Badge
          style={{
            padding: "0.25rem",
            color: "white",
          }}
          color={
            data.status === 0
              ? "success"
              : data.status === 3
              ? "danger"
              : "warning"
          }
        >
          {data.status === 0
            ? "Success"
            : data.status === 3
            ? "Cancelled"
            : "Pending"}
        </Badge>
      )

      data.amount = numberToCurrency(data.amount)
      data.fee = numberToCurrency(data.fee)
      data.due = numberToCurrency(data.due)

      data.date = dateConverter(data.date)
      data.time = timeConverter(data.time)

      if (data.approvedDate) {
        data.approvedDate = dateConverter(data.approvedDate)
        data.approvedTime = timeConverter(data.approvedTime)
      }

      data.addedDate = dateConverter(data.addedDate)
      data.addedTime = timeConverter(data.addedTime)
      setModalData(data)
    } catch (error) {}
  }
  return (
    <div className="modal-body">
      <table id="feeDetailsTable" className="table table-bordered dataTable">
        <tr>
          <td style={{ width: "150px", padding: "10px" }}>Name</td>
          <td>: {modalData?.student}</td>
          <td style={{ width: "150px", padding: "10px" }}>Register No</td>
          <td style={{ textAlign: "left" }}>: {modalData?.registerNo}</td>
        </tr>
        <tr>
          <td style={{ width: "150px" }}>Branch</td>
          <td>: {modalData?.branch}</td>
          <td style={{ width: "150px", padding: "10px" }}>Course/ Event :</td>
          <td style={{ textAlign: "left" }}>: {modalData?.name}</td>
        </tr>
        <tr>
          <td style={{ width: "150px" }}>Fee</td>
          <td>: {modalData?.fee}</td>
          <td style={{ width: "150px", padding: "10px" }}>Amount</td>
          <td style={{ textAlign: "left" }}>: {modalData?.amount}</td>
        </tr>
        <tr>
          <td style={{ width: "150px" }}>Due</td>
          <td style={{ textAlign: "left" }}>: {modalData?.due}</td>
          <td style={{ width: "150px" }}>Fund Source</td>
          <td style={{ textAlign: "left" }}>: {modalData?.fundSource}</td>
        </tr>
        <tr>
          <td style={{ width: "150px", padding: "10px" }}>Status</td>
          <td>: {modalData?.status}</td>
          <td style={{ width: "150px" }}>Remarks</td>
          <td style={{ textAlign: "left" }}>: {modalData?.remarks}</td>
        </tr>
        <tr>
          <td style={{ width: "150px", padding: "10px" }}>Date :</td>
          <td>: {modalData?.date}</td>
          <td style={{ width: "150px" }}>Time</td>
          <td style={{ textAlign: "left" }}>: {modalData?.time}</td>
        </tr>
        {modalData?.addedDate ? (
          <tr>
            <td style={{ width: "150px", padding: "10px" }}>Added Date :</td>
            <td>: {modalData?.addedDate}</td>
            <td style={{ width: "150px" }}>Added Time</td>
            <td style={{ textAlign: "left" }}>: {modalData?.addedTime}</td>
          </tr>
        ) : (
          ""
        )}
        <tr>
          <td style={{ width: "150px", padding: "10px" }}>Added By</td>
          <td style={{ textAlign: "left" }}>: {modalData?.staff}</td>
        </tr>
        {modalData.approvedStaff ? (
          <>
            <tr>
              <td style={{ width: "150px", padding: "10px" }}>
                {modalData.statusText} Date :
              </td>
              <td>: {modalData?.date}</td>
              <td style={{ width: "150px" }}>{modalData.statusText} Time</td>
              <td style={{ textAlign: "left" }}>: {modalData?.time}</td>
            </tr>
            <tr>
              <td style={{ width: "150px", padding: "10px" }}>
                {modalData.statusText} By :
              </td>
              <td>: {modalData?.approvedStaff}</td>
            </tr>
          </>
        ) : (
          ""
        )}
      </table>
    </div>
  )
}
