import React, { useState, useEffect, useRef } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap"
import Select from "react-select"
import { MDBDataTable } from "mdbreact"
import grinningface from "../../../assets/fotos/grinningface.svg"
import slightlysmile from "../../../assets/fotos/slightlysmileface.svg"
import Neutralface from "../../../assets/fotos/neutralface.svg"
import confusedface from "../../../assets/fotos/confusedface.svg"
import cryingface from "../../../assets/fotos/cryingface.svg"
import { getBranches } from "../../../store/options/actions"
import { useSelector, useDispatch } from "react-redux"
import { get } from "../../../helpers/api_helper"
import moment from "moment"
import "./Latestfeedback.scss"
import { isArray } from "lodash"
import { jsonToExcel } from "../../../helpers/functions"
import toastr from "toastr"
import MuiCustomTablePagination from "../../../components/Common/TablePagination"
import useBranch from "../../../hooks/useBranch"
import Admin from "../../../components/Common/Admin"

function Latestfeedback() {
  const { branches } = useSelector(state => state.Options)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getBranches())
    fetchAllfeedBackCourse()
  }, [])

  const [branch] = useBranch()

  const customStyles = {
    lineHeight: "1.8",
  }
  const formRef = useRef()
  const [popupView, setPopupView] = useState(false)
  const handleCloseModal = () => setPopupView(false)
  const [filterObject, setfilterObject] = useState({ csp: branch?.value })
  const [selectcsp, setselectcsp] = useState(null)
  const [selectform, setselectform] = useState(null)
  const [selected, setSelected] = useState(null)
  const [formoptions, setformoptions] = useState([])
  const [courseOptions, setCourseoptions] = useState([])
  const [batchOptions, setBatchOptions] = useState([])
  const [tabledata, settabledata] = useState([])
  const [popupData, setpopupData] = useState([])
  const [exportData, setExportData] = useState([])
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(20)
  const [count, setCount] = useState(0)
  const [eventOptions,setEventOptions] = useState([])

  useEffect(() => {
    fetchAllfeedBackform()
  }, [filterObject?.assignedFor , filterObject?.csp])

  useEffect(() => {
    fetchAllfeedback()
  }, [filterObject, page, pageSize])

  useEffect(() => {
    fetchAllfeedBackBatch()
  }, [selectcsp,selected?.course])

  useEffect(()=>{
    const fetchEvents = async()=>{
      try {
        const res = await get(`options/events?csp=${filterObject?.csp}`)
        setEventOptions(res?.data)
      } catch (error) {
        
      }
    }
    fetchEvents()
  },[filterObject?.csp])



  function selectfilter(selected, name) {
    switch (name) {
      case "csp":
        setselectcsp(selected)
        setfilterObject({ ...filterObject, csp: selected.value })
        break
      case "form":
        setselectform(selected)
        setfilterObject({ ...filterObject, form: selected.value })
      default:
        break
    }
  }

  function handleChangedate(e) {
    let name = e.target.name
    let value = e.target.value
    setfilterObject({ ...filterObject, [name]: value })
  }

  function fetchAllfeedback() {
    const ExportData = []

    get(
      `/feedback?form=${filterObject?.form}&fromDate=${
        filterObject?.fromDate
      }&toDate=${filterObject?.toDate}&batch=${filterObject?.batch}&status=${
        filterObject?.status
      }&course=${filterObject?.course}&event=${filterObject?.event}&csp=${filterObject?.csp}&page=${
        page + 1
      }&limit=${pageSize}&assignedFor=${filterObject?.assignedFor}`
    ).then(res => {
      let result = res.data
      setCount(res.count)
      result &&
        result.map((item, index) => {
          item.id = index + 1 + page * pageSize
          item.date1 = moment(item?.date).format("DD-MM-YYYY")
          item.submittedDate = item?.submittedDate
            ? moment(item?.submittedDate).format("DD-MM-YYYY")
            : "----"
          item.submittedTime1 = item?.submittedTime
            ? moment(item?.submittedTime, "hh:mm:ss").format("hh:mm A")
            : "----"
          item.csp1 = item.csp.name ?? "----"
          item.status = item?.status === 1 ? "Submitted" : "Not Submitted"
          item.batchName = item.batch.name 
          item.courseName = item.course
          item. eventName = item.event

          item.action = (
            <div>
              <i
                className="fas fa-eye"
                onClick={() => {
                  setPopupView(true)
                  setpopupData(item)
                }}
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                }}
              ></i>
            </div>
          )

          let dataExport = {}
          dataExport.Date = item.date1
          dataExport["Unique ID"] = item?.uniqueId
          dataExport.CSP = item.csp.name
          dataExport.Batch = item.batch.name
          dataExport.Course = item.course
          dataExport.Name = item?.name
          dataExport.Email = item?.email
          dataExport.Mobile = item?.mobile
          dataExport.Status = item?.status
          ExportData.push(dataExport)
          return item
        })
      settabledata(result)
      setExportData(ExportData)
    })
  }


  const handleExportData = () => {
    const ExportDatas = []
    
    get(
      `/feedback?form=${filterObject?.form}&fromDate=${filterObject?.fromDate}&toDate=${filterObject?.toDate}&batch=${filterObject?.batch}&status=${filterObject?.status}&course=${filterObject?.course}&event=${filterObject?.event}&csp=${filterObject?.csp}&limit=${count}&assignedFor=${filterObject?.assignedFor}`
    )
    .then(res => {
      const result = res.data;
      
      if (result && result.length > 0) {
        result.forEach((item, index) => {
          const dataExport = {};
          dataExport["SI NO"] = index + 1;
          dataExport.Date = item.date1;
          dataExport["Unique ID"] = item?.uniqueId;
          dataExport.CSP = item.csp.name;
          dataExport.Batch = item.batch.name;
          dataExport.Course = item.course;
          dataExport.Name = item?.name;
          dataExport.Email = item?.email;
          dataExport.Mobile = item?.mobile;
          dataExport.Status = item?.status;
          ExportDatas.push(dataExport);
        });
        
        // Call the export function after the data is ready
        jsonToExcel(ExportDatas, `Feedback-Log-${moment().format("DD-MM-YY")}`);
      } else {
        toastr.info(`There are No Feedback Log to export`);
      }
    })
    .catch(error => {
      console.error("Error fetching data: ", error);
      toastr.error("Failed to export feedback log");
    });
  };
  

  function fetchAllfeedBackform() {
    get(`/options/forms?assignedFor=${filterObject?.assignedFor}&csp=${filterObject?.csp}`)
      .then(res => {
        console.log("lsyt form", res)
        setformoptions(res.data)
      })
      .catch(err => {
        console.log("err", err)
      })
  }
  function fetchAllfeedBackCourse() {
    get(`/feedback/option/course`)
      .then(res => {
        console.log("lsyt form", res)
        setCourseoptions(res.data)
      })
      .catch(err => {
        console.log("err", err)
      })
  }
  function fetchAllfeedBackBatch() {
    get(`/feedback/option/batch?branch=${selectcsp?.value}&course=${selected?.course?.value}`)
      .then(res => {
        console.log("lsyt form", res)
        setBatchOptions(res.data)
      })
      .catch(err => {
        console.log("err", err)
      })
  }

  function resetAll() {
    formRef.current?.reset()
    setfilterObject({})
    setSelected(null)
    setselectcsp(null)
    setselectform(null)
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date1",
        width: 150,
      },
      // {
      //   label: "Time",
      //   field: "time",
      //   width: 150,
      // },

      {
        label: "Unique ID",
        field: "uniqueId",
        width: 150,
      },

      {
        label: "CSP",
        field: "csp1",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        width: 150,
      },
      {
        label: "Student ID",
        field: "studentId",
        width: 150,
      },
      {
        label: "Batch",
        field: "batchName",
        width: 150,
      },
      {
        label: "Course",
        field: "courseName",
        width: 150,
      },
      {
        label: "Event",
        field: "eventName",
        width: 150,
      },
        {
        label: "Form",
        field: "form",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        width: 150,
      },

      // {
      //     label: "Actions",
      //     field: "options",
      //     sort: "desc",
      //     width: 150,
      // },
    ],
    rows: tabledata,
  }

  const handleSelect = (e, name) => {
    const value = e ? (isArray(e) ? e.map(item => item.value) : e.value) : null
    setSelected(prev => ({ ...prev, [name]: e }))
    setfilterObject(prev => ({ ...prev, [name]: value }))
    if(name == 'assignedFor'){
      setSelected(prev => ({ ...prev,event:null, course:null, batch:null }))
      setfilterObject(prev => ({ ...prev,event:null, course:null, batch:null }))
    }
  }

  const handleChangeRowsPerPage = event => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handlePageChange = newPage => {
    setPage(newPage)
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Lastest Feedback" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm ref={formRef}>
                    <Row>
                    <Admin>
                        <Col md="2">
                          <div className="mb-3">
                            <Label>CSP</Label>
                            <Select
                              name="csp"
                              classNamePrefix="select2-selection"
                              options={branches}
                              onChange={selected => {
                                selectfilter(selected, "csp")
                              }}
                              value={selectcsp}
                            />
                          </div>
                        </Col>
                      </Admin>
                    <Col md="2">
                        <div className="mb-3">
                          <Label>From</Label>
                          <AvField
                            style={customStyles}
                            name="fromDate"
                            className="form-control"
                            type="date"
                            onChange={handleChangedate}
                            value={filterObject?.fromDate}
                            max={filterObject?.toDate}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label> To</Label>
                          <AvField
                            style={customStyles}
                            name="toDate"
                            className="form-control"
                            type="date"
                            onChange={handleChangedate}
                            value={filterObject?.toDate}
                            min={filterObject?.fromDate}
                          />
                        </div>
                      </Col>
                     
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Assigned For</Label>
                          <Select
                            options={[
                              {
                                label: "Event",
                                value: 0,
                                type: "events",
                              },
                              {
                                label: "Batch",
                                value: 1,
                                type: "batch",
                              },
                            ]}
                            classNamePrefix="select2-selection"
                            onChange={selected =>
                              handleSelect(selected, "assignedFor")
                            }
                            value={selected?.assignedFor || ""}
                          />
                        </div>
                      </Col>
                  {selected?.assignedFor?.value == 1 && 
                  <>
                     <Col md="2">
                        <div className="mb-3">
                          <Label>Course</Label>
                          <Select
                            name="form"
                            options={courseOptions}
                            value={selected?.course || ""}
                            onChange={selected =>
                              handleSelect(selected, "course")
                            }
                            type="text"
                            errorMessage="Enter Documents"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Batch</Label>
                          <Select
                            name="form"
                            type="text"
                            value={selected?.batch || ""}
                            onChange={selected =>
                              handleSelect(selected, "batch")
                            }
                            options={batchOptions}
                            errorMessage="Enter Documents"
                          />
                        </div>
                      </Col>
                   
                      </>
                         }
                         {selected?.assignedFor?.value  == 0 &&
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Event</Label>
                          <Select
                            name="form"
                            options={eventOptions}
                            value={selected?.event || ""}
                            onChange={selected =>
                              handleSelect(selected, "event")
                            }
                            type="text"
                          />
                        </div>
                      </Col>}
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Feedback Form</Label>
                          <Select
                            name="form"
                            value={selectform}
                            options={formoptions}
                            onChange={selected =>
                              selectfilter(selected, "form")
                            }
                            type="text"
                            errorMessage="Enter Documents"
                          />
                        </div>
                      </Col>

                     
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Status</Label>
                          <Select
                            name="form"
                            options={[
                              { label: "Not Submitted", value: 0 },
                              { label: "Submitted", value: 1 },
                            ]}
                            value={selected?.status || ""}
                            onChange={selected =>
                              handleSelect(selected, "status")
                            }
                            type="text"
                            errorMessage="Enter Documents"
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3" style={{ paddingTop: "1.7rem" }}>
                          {/* <Button
                            color="success"
                            type="submit"
                            style={{ marginRight: "10px" }}
                          >
                            Search
                          </Button> */}
                          <Button
                            onClick={resetAll}
                            color="danger"
                            type="reset"
                          >
                            Reset
                          </Button>
                          <Button
                            style={{ marginLeft: "14px" }}
                            color="warning"
                            type="button"
                            onClick={handleExportData}
                          >
                            Export{" "}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="feedbackID"
                    bordered
                    responsive
                    searching={false}
                    info={false}
                    paging={false}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>
                  {tabledata.length > 0 && count > 10 && (
                    <MuiCustomTablePagination
                      count={count}
                      page={page}
                      rowsPerPage={pageSize}
                      handleChangePage={handlePageChange}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
        className="lead-modal"
      >
        <div className="modal-header">
          <div className="modal-title">
            <h6 style={{ marginBottom: "0px" }} className="modal-title ">
              <h6 className="my-2 mx-2">
                Feedback Details - {popupData?.uniqueId}
              </h6>
            </h6>
          </div>
          <button
            style={{ marginBottom: "2px" }}
            type="button"
            onClick={handleCloseModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body">
          <Col md="12">
            <table className="table table-borderd lead-info table-striped feedbackformtable">
              <tbody>
                <tr>
                  <td>Submitted Date</td>
                  <th>: {popupData?.submittedDate}</th>
                  <td>Submitted Time</td>
                  <th>: {popupData?.submittedTime1}</th>
                </tr>
                <tr>
                  <td>Name</td>
                  <th>: {popupData?.name}</th>
                  <td>Student ID</td>
                  <th>: {popupData?.studentId}</th>
                </tr>
                <tr>
                  <td>Mobile</td>
                  <th>: {popupData?.mobile}</th>
                  <td>Email</td>
                  <th>: {popupData?.email}</th>
                </tr>
                <tr>
                  <td>Form</td>
                  <th>: {popupData?.form}</th>
                  <td>CSP</td>
                  <th>: {popupData?.csp?.name ?? "----"}</th>
                </tr>
                <tr>
                  {popupData?.assignedFor === 'batch' ?
                  <>
                  <td>Batch</td>
                  <th>: {popupData?.batch?.name ?? "----"}</th>
                  <td>Course</td>
                  <th>: {popupData?.course ?? "----"}</th>
                  </> :
                  <>
                     <td>Event</td>
                     <th>: {popupData?.event ?? "----"}</th>
                  </>
                  }
                </tr>
              </tbody>
            </table>
          </Col>
          <div>
            {popupData?.questions && (
              <table className="table table-bordered feedbackformtable">
                <thead>
                  <tr>
                    <th style={{ width: "120px", textAlign: "left" }}>Type</th>
                    <th style={{ width: "320px", textAlign: "left" }}>
                      Question
                    </th>
                    <th style={{ textAlign: "left" }}>Answer</th>
                  </tr>
                </thead>
                {popupData &&
                  popupData?.questions?.map((item, index) => (
                    <tbody>
                      <tr>
                        <td style={{ textAlign: "left" }}>
                          {item.type == 0
                            ? "Yes/No"
                            : item.type == 1
                            ? "Rating"
                            : item.type == 2
                            ? "Emoji Rating"
                            : ""}
                        </td>
                        <td style={{ textAlign: "left" }}>{item.question}</td>
                        <td style={{ textAlign: "left" }}>
                          <span style={{ fontSize: "15px" }}>
                            {item.type === 2 ? (
                              item?.answer == 5 ? (
                                <img src={grinningface} alt="Grinning face" />
                              ) : item?.answer == 4 ? (
                                <img
                                  src={slightlysmile}
                                  alt="Slightly smiling face"
                                />
                              ) : item?.answer == 3 ? (
                                <img src={Neutralface} alt="Neutral face" />
                              ) : item?.answer == 2 ? (
                                <img src={confusedface} alt="Confused face" />
                              ) : item?.answer == 1 ? (
                                <img src={cryingface} alt="Crying face" />
                              ) : (
                                ""
                              )
                            ) : item.type === 0 ? (
                              item?.answer == 1 ? (
                                "Yes"
                              ) : (
                                "No"
                              )
                            ) : item.type === 1 &&
                              item?.answer >= 1 &&
                              item?.answer <= 5 ? (
                              Array.from({ length: item.answer }).map(
                                (_, index) => (
                                  <span
                                    key={index}
                                    style={{
                                      fontSize: "18px",
                                    }}
                                  >
                                    ⭐
                                  </span>
                                )
                              )
                            ) : null}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  ))}
              </table>
            )}

            <div className="table-responsive">
              {popupData?.remarks && (
                <table className="table lead-info feedbackformtable">
                  <tbody>
                    <tr>
                      <td style={{ width: "100px" }}>Remarks</td>
                      <th>
                        <p style={{ textWrap: "wrap" }}>
                          : {popupData?.remarks}
                        </p>{" "}
                      </th>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Latestfeedback
