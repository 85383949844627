import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import "./style.scss"
import { get } from "../../../helpers/api_helper"
import { jsonToExcel } from "../../../helpers/functions"
import moment from "moment"
import toastr from "toastr"

import Tooltip from "@mui/material/Tooltip"

const ScholarshipReport = () => {
  const [tableData, setTableData] = useState([])
  const [exports, setExports] = useState([])

  useEffect(() => {
    handleTableData()
  }, [])

  const handleTableData = async () => {
    await get("/report/scholarship").then(res => {
      const data = res.data
      const exportData = []
      data.map((item, index) => {
        item.id = index + 1
        item.allocated = item.allocated?.toLocaleString("en-IN") || 0
        item.approvedAmount = item.approvedAmount?.toLocaleString("en-IN") || 0
        item.availedRequest = item.availedRequest?.toLocaleString("en-IN") || 0
        item.balanceAmount = item.balanceAmount?.toLocaleString("en-IN") || 0

        item.expireDate =
          item.expireDate && moment(item.expireDate).format("DD-MM-YYYY")

        // TODO: Export details
        const dataExport = {}
        dataExport["#"] = index + 1
        dataExport["Funding agency"] = item.fundingAgency
        dataExport["Name"] = item.name
        dataExport["Expire Date"] = item.expireDate
        dataExport["Allocated"] = item.allocated
        dataExport["Total Requests"] = item.totalRequests
        dataExport["Total Availed Requests"] = item.availedRequest
        dataExport["Sanctioned Amount"] = item.approvedAmount
        dataExport["Balance remaining"] = item.balanceAmount

        exportData.push(dataExport)

        return item
      })

      setExports(exportData)

      setTableData(data)
    })
  }

  const handleExport = () => {
    if (exports.length > 0) {
      jsonToExcel(
        exports,
        `scholarships-reports-${moment().format("DD-MM-YY")}`
      )
    } else {
      toastr.info(`There are no scholarship to export`)
    }
  }

  const data = {
    columns: [
      {
        label: "Serial No",
        field: "id",
        width: 150,
      },
      {
        label: "Funding agency",
        field: "fundingAgency",
        width: 150,
      },
      {
        label: "Scholarship Name",
        field: "name",
        width: 150,
      },
      {
        label: "Expiry Date",
        field: "expireDate",
        width: 150,
      },
      {
        label: "Total Allocated Amount",
        field: "allocated",
        width: 150,
      },

      {
        label: "Total Requests",
        field: "totalRequests",
        width: 150,
      },
      {
        label: "Total Availed Requests",
        field: "availedRequest",
        width: 150,
      },
      {
        label: "Sanctioned Amount",
        field: "approvedAmount",
        width: 150,
      },
      {
        label: "Balance remaining ",
        field: "balanceAmount",
        width: 150,
      },
    ],
    rows: tableData,
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Scholarship" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="p-2 pt-0 d-flex justify-content-end me-1">
                    <Tooltip title="Export" placement="top" arrow>
                      <i
                        onClick={handleExport}
                        className="cursor-pointer fs-4 fas fa-file-export"
                      ></i>
                    </Tooltip>
                  </div>
                  <MDBDataTable
                    id="scholarshipReportTable"
                    bordered
                    responsive
                    searching={false}
                    displayEntries={false}
                    paging={false}
                    disableRetreatAfterSorting={true}
                    data={data}
                    sortable={false}
                  ></MDBDataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default ScholarshipReport
