import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  BreadcrumbItem,
  Modal,
  Collapse,
  Card,
  CardBody,
  Button,
} from "reactstrap"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import { Box, Divider } from "@mui/material"
import StoreIcon from "@mui/icons-material/Store"
import WorkHistoryIcon from "@mui/icons-material/WorkHistory"
import AutoStoriesIcon from "@mui/icons-material/AutoStories"
import ScreenSearchDesktopIcon from "@mui/icons-material/ScreenSearchDesktop"
import MiniWidget from "../../../pages/Dashboard/Marketing/mini-widget"
import { Link } from "react-router-dom"
import Avatar from "@mui/material/Avatar"
import Stack from "@mui/material/Stack"
import "./style.scss"
import { useLocation, useParams } from "react-router-dom"
import { get } from "../../../helpers/api_helper"
import { API_URL } from "../../../config"
import moment from "moment"
import YouTubeIcon from "@mui/icons-material/YouTube"
import Tooltip from "@mui/material/Tooltip"
import toastr from "toastr"

const CourseDashboard = props => {
  const { state } = useLocation()
  const test = useParams()
  const [tab3, setTab3] = React.useState("1")
  const [CourseDetails, setCourseDetails] = useState([])
  const [modal, setModal] = useState(false)
  const [seoDetails, setseoDetails] = useState([])
  const [syllabusDetails, setsyllabusDetails] = useState([])
  const [feeDetails, setfeeDetails] = useState([])
  const [activeAccordion, setActiveAccordion] = useState(null)
  const handleAccordionClick = index => {
    setActiveAccordion(activeAccordion === index ? null : index)
  }
  const handleChangeTab = (event, newValue) => {
    setTab3(newValue)
  }

  const series1 = [50]
  const options1 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series3 = [55]

  const options3 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series4 = [33]

  const options4 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const reports = [
    {
      id: 1,
      icon: "fas fa-users",
      title: "Batch",
      value: 50,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue: "100",
      color: "success",
      desc: "Total",
      series: series1,
      options: options1,
    },
    {
      id: 3,
      icon: "fas fa-book-open",
      title: "Active Course",
      value: "120",
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue: "150",
      color: "success",
      desc: "Total ",
      series: series3,
      options: options3,
    },
    {
      id: 4,
      icon: "far fa-calendar-check",
      title: "Events",
      value: 8,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue: "69",
      color: "success",
      desc: "Total ",
      series: series4,
      options: options4,
    },
    {
      id: 5,
      icon: "fa fa-graduation-cap",
      title: "Placements",
      value: 267,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue: "11543",
      color: "success ",
      desc: "Total ",
      series: series4,
      options: options4,
    },
  ]

  useEffect(() => {
    get(`/course?_id=${state?.id}`).then(res => {
      // console.log("coursedetal dashbioard", res)
      let result = res.data[0]
      result.createDate = moment(result?.date).format("DD-MM-YYYY")
      result.sectorName = result.sector.label
      if (result.unit === "1") {
        result.duration1 = result.duration + " " + "Hours"
      } else if (result.unit === "2") {
        result.duration1 = result.duration + " " + "Days"
      } else if (result.unit === "3") {
        result.duration1 = result.duration + " " + "Months"
      }
      if (result.gender.length > 0) {
        result.gender1 = result?.gender?.map(item => {
          if (item === 1) return "Male"
          if (item === 0) return "Female"
          if (item === 3) return "Other"
          return item
        })
      } else {
        result.gender2 = "All"
      }
      if (result.mode === "1") {
        result.mode1 = "Offline"
      } else if (result.mode === "0") {
        result.mode1 = "Online"
      }
      //programmode
      if (result.programMode === 1) {
        result.programMode = "NSQF"
      } else if (result.programMode === 2) {
        result.programMode = "Industry"
      } else if (result.programMode === 3) {
        result.programMode = "ASAP NSQF"
      } else if (result.programMode === 4) {
        result.programMode = "ASAP Course"
      } else if (result.programMode === 5) {
        result.programMode = "Accredited"
      } else if (result.programMode === 6) {
        result.programMode = "Operating Partner Course"
      }

      setCourseDetails(result)
    })
  }, [])
  console.log("course", CourseDetails)

  useEffect(() => {
    if (tab3 === "4") {
      get(`/course/seo?_id=${state?.id}`)
        .then(res => {
          // console.log(res, "kfhfkhghg")
          setseoDetails(res.data.seo)
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    }
  }, [tab3])

  useEffect(() => {
    if (tab3 === "3") {
      get(`/course/details?_id=${state.id}`).then(res => {
        let result = res.data
        // console.log("result syllabus", result)
        result.sylabus = []
        result.eligiblity = []
        result.course = []
        result.scholarship = []
        result.skillcover = []
        result.skillloan = []
        result.placement = []
        result &&
          result.details.map((item, index) => {
            if (item.tab === "1") result.sylabus.push(item)
            if (item.tab === "2") result.eligiblity.push(item)
            if (item.tab === "3") result.course.push(item)
            if (item.tab === "4") result.scholarship.push(item)
            if (item.tab === "5") result.skillcover.push(item)
            if (item.tab === "6") result.placement.push(item)
            if (item.tab === "9") result.skillloan.push(item)

            return item
          })
        setsyllabusDetails(result)
      })
    }
  }, [tab3])

  useEffect(() => {
    if (tab3 === "2") {
      get(`/course/fee_details?_id=${state.id}`).then(res => {
        let result = res.data.fee
        console.log("result res", result)
        if (result?.phaseOne?.gst) {
          const gst = result?.phaseOne?.gst
          let label
          let value
          if (gst === "0") {
            label = "0%"
            value = 0
          } else {
            const parts = gst.split(".")
            const percentageString = parseInt(parts[1])
            label = percentageString + "%"
            value = result?.phaseOne?.gst
          }
          result.phaseOne.gst2 = label
        }
        if (result?.phaseTwo?.gst) {
          const gst3 = result?.phaseTwo?.gst
          let label3
          let value3
          if (gst3 === "0") {
            label3 = "0%"
            value3 = 0
          } else {
            const parts3 = gst3.split(".")
            const percentageString3 = parseInt(parts3[1])
            label3 = percentageString3 + "%"
            value3 = result?.phaseTwo?.gst
          }
          result.phaseTwo.gst2 = label3
        }
        if (result?.phaseOne?.default === true) {
          result.phaseOne.exdate = moment(result.phaseOne.expireDate).format(
            "DD-MM-YYYY"
          )
          result.defaultPrice = result.phaseOne
          result.priceAfter = result.phaseTwo
        } else if (result.phaseTwo.default === true) {
          result.defaultPrice = result.phaseTwo
          result.priceAfter = result.phaseOne
        }
        setfeeDetails(result)
      })
    }
  }, [tab3])

  console.log("feedetao state", feeDetails)

  console.log("seo details", syllabusDetails)

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function togModal() {
    setModal(!modal)
    removeBodyCss()
  }


  console.log("feedetail", feeDetails);

  return (
    <React.Fragment>
      <Modal
        size="lg"
        isOpen={modal}
        centered={true}
      // toggle={() => {
      //   togModal()
      // }}
      >
        <div className="modal-header">
          <button
            onClick={() => {
              setModal(false)
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9">
            <iframe
              title="infoVideo"
              allowFullScreen
              className="embed-responsive-item"
              src={CourseDetails?.assets?.video}
            />
          </div>
        </div>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">{CourseDetails?.name}</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      <Link to="/">Home</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="/manage-course">Course</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="#">{CourseDetails?.name}</Link>
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              {/* First Dialogue */}

              <div>
                <div className="row">
                  <div className="col-xl-3">
                    <div
                      className="card"
                      style={{
                        // width: "fit-content",
                        // width: "351px",
                        height: "max-content",
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                      }}
                    >
                      {CourseDetails?.assets?.video && (
                        <Tooltip title="View video">
                          <span
                            onClick={togModal}
                            style={{ position: "absolute", right: "1rem" }}
                          >
                            <YouTubeIcon
                              style={{
                                cursor: "pointer",
                                color: "#00afc3",
                                fontSize: "1.6rem",
                              }}
                            />
                          </span>
                        </Tooltip>
                      )}
                      <div className="card-body">
                        <div className="float-end dropdown"></div>
                        <h5
                          className="mt-2 mb-2"
                          style={{
                            textAlign: "center",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}
                        >
                          {CourseDetails?.name}
                        </h5>

                        <Stack
                          direction="row"
                          style={{ justifyContent: "center" }}
                        >
                          {/* <Avatar
                            alt="Remy Sharp"
                            src={`${API_URL}${CourseDetails?.assets?.image}`}
                            sx={{ width: 50, height: 5 }}
                            style={{
                              width: "6rem",
                              height: "6rem",
                              //marginTop: "20%",
                              marginBottom: "auto",
                            }}
                          /> */}
                          <img
                            alt="Course Image"
                            src={`${API_URL}${CourseDetails?.assets?.image}`}
                            style={{
                              // width: "6rem",
                              // height: "6rem",
                              //marginTop: "20%",
                              width: "100%",
                              borderRadius: "5px",
                            }}
                          />
                        </Stack>
                        <div
                          className="text-muted"
                          style={{
                            textAlign: "center",
                            color: "green !important",
                            fontFamily: "IBM Plex Sans,sans-serif",
                            fontSize: "14px",
                            marginBottom: "3px",
                          }}
                        >
                          <div
                            class="btn-group btn-group-sm"
                            role="group"
                            aria-label="Basic example"
                          ></div>
                        </div>
                        <p
                          style={{
                            textAlign: "center",
                            marginBottom: "3px",
                          }}
                        ></p>

                        {/* <Divider /> */}

                        <div
                          className="mt-2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="mb-1">Created Date:</p>
                          <h5 className="font-size-14">
                            {CourseDetails?.createDate}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div
                          className="mt-2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="mb-1">Course Code:</p>
                          <h5 className="font-size-14">
                            {CourseDetails?.code}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />

                        <div
                          className="mt-2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="mb-1">Sector:</p>
                          <h5 className="font-size-14">
                            {CourseDetails?.sectorName}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />

                        <div
                          className="mt-2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="mb-1">Mode:</p>
                          <h5 className="font-size-14">
                            {CourseDetails?.mode1}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div
                          className="mt-2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="mb-1">Duration:</p>
                          <h5 className="font-size-14">
                            {CourseDetails?.duration1}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div
                          className="mt-2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="mb-1">Program Mode:</p>
                          <h5 className="font-size-14">
                            {CourseDetails?.programMode}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div
                          className="mt-2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="mb-1"> QP Level:</p>
                          <h5 className="font-size-14">
                            Level {CourseDetails?.qpLevel}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div
                          className="mt-2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="mb-1">QP Code:</p>
                          <h5 className="font-size-14">
                            {CourseDetails?.qpCode}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />

                        <div
                          className="mt-2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="mb-1">Age:</p>
                          <h5 className="font-size-14">
                            From {CourseDetails?.ageFrom || "--"} To{" "}
                            {CourseDetails?.ageTo || "--"}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div
                          className="mt-2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="mb-1">Gender:</p>
                          <div>
                            {CourseDetails?.gender1
                              ? CourseDetails.gender1.map((item, index) => (
                                <h5 className="font-size-14" key={index}>
                                  {item}
                                </h5>
                              ))
                              : CourseDetails?.gender2 && (
                                <h5 className="font-size-14">
                                  {CourseDetails.gender2}
                                </h5>
                              )}
                          </div>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div
                          className="mt-2"
                          style={{
                            // display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="mb-1">Qualification:</p>
                          <div>
                            {CourseDetails.qualifications &&
                              CourseDetails.qualifications.length > 0 ? (
                              CourseDetails.qualifications.map(
                                (item, index) => (
                                  <h5 className="font-size-14 mx-1" key={index}>
                                    {item.label}
                                  </h5>
                                )
                              )
                            ) : (
                              <h5 className="font-size-14">Nill</h5>
                            )}
                          </div>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div
                          className="mt-2"
                          style={{
                            // display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="mb-1">Degree:</p>
                          <div>
                            {CourseDetails.degrees &&
                              CourseDetails.degrees.length > 0 ? (
                              CourseDetails.degrees.map((item, index) => (
                                <h6 className="font-size-14 mx-1" key={index}>
                                  {item.label}
                                </h6>
                              ))
                            ) : (
                              <h5 className="font-size-14">Nill</h5>
                            )}
                          </div>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div
                          className="mt-2"
                          style={{
                            // display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="mb-1">Stream:</p>
                          <div>
                            {CourseDetails.stream &&
                              CourseDetails.stream.length > 0 ? (
                              CourseDetails.stream.map((item, index) => (
                                <h6 className="font-size-14 mx-1" key={index}>
                                  {item.label}
                                </h6>
                              ))
                            ) : (
                              <h5 className="font-size-14">Nill</h5>
                            )}
                          </div>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />

                        <div
                          className="mt-2"
                          style={{
                            // display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="mb-1">CSP:</p>
                          <div>
                            {CourseDetails.branches &&
                              CourseDetails.branches.length > 0 ? (
                              CourseDetails.branches.map((item, index) => (
                                <h6 className="font-size-14 mx-1" key={index}>
                                  {item.label}
                                </h6>
                              ))
                            ) : (
                              <h5 className="font-size-14">Nill</h5>
                            )}
                          </div>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />

                        <div
                          className="mt-2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="mb-1">Created By:</p>
                          <h5 className="font-size-14">
                            {CourseDetails?.addedBy?.firstName +
                              "" +
                              CourseDetails?.addedBy?.lastName}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-9" style={{ marginBottom: "30px" }}>
                    <div
                      className="mb-0 card"
                      style={{
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={tab3}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              indicatorColor="primary"
                              textColor="primary"
                              variant="fullWidth"
                              onChange={handleChangeTab}
                              aria-label="lab API tabs example"
                            >
                              <Tab
                                icon={
                                  <StoreIcon style={{ fontSize: "20px" }} />
                                }
                                label="Summary"
                                value="1"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <WorkHistoryIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Fee Details"
                                value="2"
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <AutoStoriesIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Course Details"
                                value="3"
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <ScreenSearchDesktopIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="SEO"
                                value="4"
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                            </TabList>
                          </Box>

                          <TabPanel
                            value="1"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <MiniWidget reports={reports} />
                            </Row>
                            <hr style={{ margin: "0", color: "#00afc3" }} />

                            <Row
                              style={{
                                marginTop: "18px",
                                marginBottom: "18px",
                              }}
                            >
                              <h6>Operating Partners</h6>
                              {CourseDetails?.operatingPartner?.map(
                                (partner, index) => (
                                  <Col md="2" key={index} className="">
                                    <div>
                                      <img
                                        src={`${API_URL}${partner.logo}`} // Replace 'example.com' with your actual domain
                                        alt={partner.label}
                                        width="100px"
                                      />
                                    </div>
                                  </Col>
                                )
                              )}
                            </Row>
                            <hr style={{ margin: "0", color: "#00afc3" }} />

                            <Row
                              style={{
                                marginTop: "18px",
                                marginBottom: "18px",
                              }}
                            >
                              <h6>Training Partners</h6>
                              {CourseDetails?.trainingPartner?.map(
                                (partner, index) => (
                                  <Col md="2" key={index} className="">
                                    <div>
                                      <img
                                        src={`${API_URL}${partner.logo}`} // Replace 'example.com' with your actual domain
                                        alt={partner.label}
                                        width="100px"
                                      />
                                    </div>
                                  </Col>
                                )
                              )}
                            </Row>
                            <hr style={{ margin: "0", color: "#00afc3" }} />

                            <Row
                              style={{
                                marginTop: "18px",
                                marginBottom: "18px",
                              }}
                            >
                              <h6>Certification Partners</h6>
                              {CourseDetails?.certificationPartner?.map(
                                (partner, index) => (
                                  <Col md="2" key={index} className="">
                                    <div>
                                      <img
                                        src={`${API_URL}${partner.logo}`} // Replace 'example.com' with your actual domain
                                        alt={partner.label}
                                        width="100px"
                                      />
                                    </div>
                                  </Col>
                                )
                              )}
                            </Row>
                            <hr style={{ margin: "0", color: "#00afc3" }} />
                            <Row
                              style={{
                                marginTop: "18px",
                                marginBottom: "18px",
                              }}
                            >
                              <Col md="4">
                                <h6>Thumbnail</h6>
                                <div>
                                  <img
                                    src={`${API_URL}${CourseDetails?.assets?.thumbnail}`} // Replace 'example.com' with your actual domain
                                    width="100%"
                                    style={{ borderRadius: "5px" }}
                                  />
                                </div>
                              </Col>
                              <Col
                                md="6"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "20px",
                                  }}
                                >
                                  <Button
                                    onClick={() => {
                                      window.open(
                                        `${API_URL}${CourseDetails?.assets?.syllabus}`
                                      )
                                    }}
                                    className="view-syllabus syllabus"
                                  >
                                    Download Syllabus{" "}
                                    <i class="fa fa-download"></i>
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      window.open(
                                        `${API_URL}${CourseDetails?.assets?.brochure}`
                                      )
                                    }}
                                    className="view-syllabus broucher"
                                  >
                                    Download Brochure{" "}
                                    <i class="fa fa-download"></i>
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </TabPanel>
                          <TabPanel
                            value="2"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Card>
                                <CardBody>
                                  <Col className="col-12">
                                    <p
                                      style={{
                                        color: "#00afc3",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Default Price
                                    </p>
                                    <table className="table table-bordered dataTable">
                                      {feeDetails?.defaultPrice?.markupPrice ? (
                                        <tr>
                                          <td
                                            style={{
                                              padding: "10px",
                                              textAlign: "justify",
                                              width: "50%",
                                            }}
                                          >
                                            Markup Price
                                          </td>
                                          <td
                                            style={{
                                              textAlign: "left",
                                              fontWeight: "500",
                                            }}
                                          >
                                            ₹
                                            {feeDetails?.defaultPrice?.markupPrice
                                              ? feeDetails.defaultPrice
                                                .markupPrice
                                              : 0}
                                          </td>
                                        </tr>
                                      ) : null}
                                      <tr>
                                        <td
                                          style={{
                                            padding: "10px",
                                            textAlign: "justify",
                                          }}
                                        >
                                          Course Fee
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            fontWeight: "500",
                                          }}
                                        >
                                          ₹
                                          {feeDetails?.defaultPrice?.courseFee
                                            ? feeDetails?.defaultPrice
                                              ?.courseFee
                                            : 0}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            padding: "10px",
                                            textAlign: "justify",
                                          }}
                                        >
                                          GST slabs
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {feeDetails?.defaultPrice?.gst2
                                            ? feeDetails?.defaultPrice?.gst2
                                            : "NIL"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            padding: "10px",
                                            textAlign: "justify",
                                          }}
                                        >
                                          Number Of Installments
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {feeDetails?.defaultPrice
                                            ?.noOfInstallments
                                            ? feeDetails?.defaultPrice
                                              ?.noOfInstallments
                                            : "0"}
                                        </td>
                                      </tr>
                                      {feeDetails?.defaultPrice
                                        ?.installments != false ? (
                                        <tr>
                                          <td
                                            style={{
                                              padding: "10px",
                                              textAlign: "justify",
                                            }}
                                          >
                                            Installments
                                          </td>
                                          <td
                                            style={{
                                              textAlign: "left",
                                              fontWeight: "500",
                                            }}
                                          >
                                            {feeDetails?.defaultPrice
                                              ?.installments.length
                                              ? feeDetails?.defaultPrice?.installments
                                                .map(amount => `₹${amount}`)
                                                .join(" , ")
                                              : "NIL"}
                                          </td>
                                        </tr>

                                      ) : null}

                                      <tr>
                                        <td
                                          style={{
                                            padding: "10px",
                                            textAlign: "justify",
                                          }}
                                        >
                                          Expiry Date
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {feeDetails?.defaultPrice?.exdate}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            padding: "10px",
                                            textAlign: "justify",
                                          }}
                                        >
                                          Skill Loan
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            fontWeight: "500",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              flexFlow: "column",
                                              padding: "0",
                                            }}
                                          >
                                            {feeDetails?.skillLoans?.length
                                              ? feeDetails?.skillLoans
                                                ?.map(el =>
                                                  el.label !== null ||
                                                    el.label !== ""
                                                    ? el.label
                                                    : "NIL"
                                                )
                                                .join(" , ")
                                              : "NIL"}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            padding: "10px",
                                            textAlign: "justify",
                                          }}
                                        >
                                          Scholarship
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            fontWeight: "500",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              flexFlow: "column",
                                              padding: "0",
                                            }}
                                          >
                                            {feeDetails?.scholarships?.length
                                              ? feeDetails?.scholarships?.map(
                                                item => (
                                                  <>
                                                    <span className="mr-1">
                                                      {item.label
                                                        ? item.label
                                                        : "NIL"}
                                                    </span>
                                                  </>
                                                )
                                              )
                                              : "NIL"}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            padding: "10px",
                                            textAlign: "justify",
                                          }}
                                        >
                                          Assesment Type
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {feeDetails?.assesmentType === "1"
                                            ? "External Assessment"
                                            : feeDetails?.assesmentType === "0"
                                              ? "Internal Assessment"
                                              : "NIL"}{" "}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            padding: "10px",
                                            textAlign: "justify",
                                          }}
                                        >
                                          Assesment Fee
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            fontWeight: "500",
                                          }}
                                        >

                                          {feeDetails?.assesmentFee
                                            ? feeDetails?.assesmentFee
                                            : "0"} {feeDetails?.currency}
                                        </td>
                                      </tr>
                                    </table>
                                  </Col>
                                </CardBody>
                              </Card>
                            </Row>
                            <Row>
                              <Card>
                                <CardBody>
                                  <Col className="col-12">
                                    <p
                                      style={{
                                        color: "#00afc3",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Price After Expiry
                                    </p>
                                    <table className="table table-bordered dataTable table-striped">
                                      {feeDetails.priceAfter?.markupPrice ? (
                                        <tr>
                                          <td
                                            style={{
                                              padding: "10px",
                                              textAlign: "justify",
                                              width: "50%",
                                            }}
                                          >
                                            Markup Price
                                          </td>
                                          <td
                                            style={{
                                              textAlign: "left",
                                              fontWeight: "500",
                                            }}
                                          >
                                            ₹
                                            {feeDetails.priceAfter?.markupPrice
                                              ? feeDetails.priceAfter?.markupPrice
                                              : 0}
                                          </td>
                                        </tr>
                                      ) : null}

                                      <tr>
                                        <td
                                          style={{
                                            padding: "10px",
                                            textAlign: "justify",
                                          }}
                                        >
                                          Course Fee
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            fontWeight: "500",
                                          }}
                                        >
                                          ₹
                                          {feeDetails?.priceAfter?.courseFee
                                            ? feeDetails?.priceAfter?.courseFee
                                            : 0}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            padding: "10px",
                                            textAlign: "justify",
                                          }}
                                        >
                                          GST slabs
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {feeDetails?.priceAfter?.gst2
                                            ? feeDetails?.priceAfter?.gst2
                                            : "NIL"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            padding: "10px",
                                            textAlign: "justify",
                                          }}
                                        >
                                          Number Of Installments
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {feeDetails?.priceAfter
                                            ?.noOfInstallments
                                            ? feeDetails?.priceAfter
                                              ?.noOfInstallments
                                            : 0}
                                        </td>
                                      </tr>
                                      {feeDetails?.priceAfter?.installments != false ? (
                                        <tr>
                                          <td
                                            style={{
                                              padding: "10px",
                                              textAlign: "justify",
                                            }}
                                          >
                                            Installments
                                          </td>
                                          <td
                                            style={{
                                              textAlign: "left",
                                              fontWeight: "500",
                                            }}
                                          >
                                            {feeDetails?.priceAfter?.installments
                                              .length
                                              ? feeDetails?.priceAfter?.installments
                                                .map(amount => `₹${amount}`)
                                                .join(" , ")
                                              : "NIL"}
                                          </td>
                                        </tr>
                                      ):null}
                                      <tr>
                                        <td
                                          style={{
                                            padding: "10px",
                                            textAlign: "justify",
                                          }}
                                        >
                                          Skill Loan
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            fontWeight: "500",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              flexFlow: "column",
                                              padding: "0",
                                            }}
                                          >
                                            {feeDetails?.skillLoans?.length
                                              ? feeDetails?.skillLoans
                                                ?.map(el =>
                                                  el.label !== null ||
                                                    el.label !== ""
                                                    ? el.label
                                                    : "NIL"
                                                )
                                                .join(" , ")
                                              : "NIL"}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            padding: "10px",
                                            textAlign: "justify",
                                          }}
                                        >
                                          Scholarship
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            fontWeight: "500",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              flexFlow: "column",
                                              padding: "0",
                                            }}
                                          >
                                            {feeDetails?.scholarships?.length
                                              ? feeDetails?.scholarships?.map(
                                                item => (
                                                  <>
                                                    <span className="mr-1">
                                                      {item.label
                                                        ? item.label
                                                        : "NIL"}
                                                    </span>
                                                  </>
                                                )
                                              )
                                              : "NIL"}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            padding: "10px",
                                            textAlign: "justify",
                                          }}
                                        >
                                          Assesment Type
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {feeDetails?.assesmentType === "1"
                                            ? "External Assessment"
                                            : feeDetails?.assesmentType === "0"
                                              ? "Internal Assessment"
                                              : "NIL"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            padding: "10px",
                                            textAlign: "justify",
                                          }}
                                        >
                                          Assesment Fee
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            fontWeight: "500",
                                          }}
                                        >

                                          {feeDetails?.assesmentFee
                                            ? feeDetails?.assesmentFee
                                            : "0"} {feeDetails?.currency}
                                        </td>
                                      </tr>
                                    </table>
                                  </Col>
                                </CardBody>
                              </Card>
                            </Row>
                          </TabPanel>

                          <TabPanel
                            value="3"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            {/* SYLLABUS */}
                            <Row>
                              <Col xl="12">
                                <h6 style={{ textDecorationLine: "underline" }}>
                                  Key Topics
                                </h6>
                                {/* {syllabusDetails?.sylabus?.length > 0 ? (
                                  syllabusDetails.sylabus.map(item => (
                                    <details>
                                      <summary>{item.tabDescription}</summary>
                                      <div
                                        style={{ marginLeft: "38px" }}
                                        dangerouslySetInnerHTML={{
                                          __html: item.contents,
                                        }}
                                      ></div>
                                    </details>
                                  ))
                                ) : (
                                  <p> please update Syllabus </p>
                                )} */}

                                {syllabusDetails?.sylabus?.length > 0 ? (
                                  syllabusDetails.sylabus.map((item, index) => (
                                    <div class="accordion" key={index}>
                                      <div class="accordion-item mb-2">
                                        <h2
                                          class="accordion-header"
                                          id={`heading${index}`}
                                        >
                                          <button
                                            class={`accordion-button ${activeAccordion === index
                                              ? ""
                                              : "collapsed"
                                              }`}
                                            type="button"
                                            onClick={() =>
                                              handleAccordionClick(index)
                                            }
                                            aria-expanded={
                                              activeAccordion === index
                                                ? "true"
                                                : "false"
                                            }
                                            aria-controls={`collapse${index}`}
                                          >
                                            <b>{item.tabDescription}</b>
                                          </button>
                                        </h2>
                                        <div
                                          id={`collapse${index}`}
                                          class={`accordion-collapse collapse ${activeAccordion === index
                                            ? "show"
                                            : ""
                                            }`}
                                          aria-labelledby={`heading${index}`}
                                          data-bs-parent="#accordionExample"
                                        >
                                          <div
                                            className="contentHtml"
                                            dangerouslySetInnerHTML={{
                                              __html: item.contents,
                                            }}
                                          ></div>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <p> please update Syllabus </p>
                                )}
                              </Col>
                            </Row>

                            {/* SCHOLARSHIP */}

                            {/* COURSE FEE */}
                            {/* <Row>
                              <Col md="12" mt="2" style={{ marginTop: "20px" }}>
                                <h6 style={{ textDecorationLine: "underline" }}>
                                  COURSE FEE
                                </h6>
                                <div style={{ display: "flex" }}>
                                  <i
                                    class="fas fa-wallet"
                                    style={{
                                      fontSize: "50px",
                                      color: "#9fd3d8",
                                    }}
                                  ></i>
                                  <h6 style={{ marginLeft: "10px" }}>
                                    Total Amount
                                  </h6>
                                </div>

                                <div>
                                  <h5 className="course-fee">INR 22,300</h5>
                                </div>
                              </Col>
                            </Row> */}

                            {/* ELIGIBILITY */}
                            <Row>
                              <Col md="12" mt="2" style={{ marginTop: "20px" }}>
                                <h6 style={{ textDecorationLine: "underline" }}>
                                  Eligiblity
                                </h6>

                                {syllabusDetails?.eligiblity?.length ? (
                                  <div
                                    className="contentHtml"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        syllabusDetails?.eligiblity[0]
                                          ?.contents,
                                    }}
                                  ></div>
                                ) : (
                                  <p> update ELIGIBILITY </p>
                                )}
                              </Col>
                            </Row>

                            <Row>
                              <Col md="12" mt="2" style={{ marginTop: "20px" }}>
                                <h6 style={{ textDecorationLine: "underline" }}>
                                  Skill Covered
                                </h6>

                                {syllabusDetails?.skillcover?.length ? (
                                  <div
                                    className="contentHtml"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        syllabusDetails?.skillcover[0]
                                          ?.contents,
                                    }}
                                  ></div>
                                ) : (
                                  <p> update Skill Covered </p>
                                )}
                              </Col>
                            </Row>

                            <Row>
                              <Col md="12" mt="2" style={{ marginTop: "20px" }}>
                                <h6 style={{ textDecorationLine: "underline" }}>
                                  Scholarship
                                </h6>

                                {syllabusDetails?.scholarship?.length > 0 ? (
                                  <div
                                    className="contentHtml"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        syllabusDetails?.scholarship[0]
                                          ?.contents,
                                    }}
                                  ></div>
                                ) : (
                                  <div>
                                    <p> Update Scholorship details</p>
                                  </div>
                                )}
                              </Col>
                            </Row>

                            {/* <Row>
                              <Col md="12" mt="2" style={{ marginTop: "20px" }}>
                                <h6 style={{ textDecorationLine: "underline" }}>
                                  Skill Loans
                                </h6>

                                {syllabusDetails?.skillloan?.length > 0 ? (
                                  <div
                                    className="contentHtml"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        syllabusDetails?.skillloan[0]?.contents,
                                    }}
                                  ></div>
                                ) : (
                                  <div>
                                    <p> Update Skill Loans</p>
                                  </div>
                                )}
                              </Col>
                            </Row> */}
                            <Row>
                              <Col md="12" mt="2" style={{ marginTop: "20px" }}>
                                <h6 style={{ textDecorationLine: "underline" }}>
                                  Placement Opportunity
                                </h6>
                                {syllabusDetails?.placement?.length ? (
                                  <div
                                    className="contentHtml"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        syllabusDetails?.placement[0]?.contents,
                                    }}
                                  ></div>
                                ) : (
                                  <p> Update Placement Opportunity </p>
                                )}
                              </Col>
                            </Row>
                          </TabPanel>

                          <TabPanel
                            value="4"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col md="12">
                                <table
                                  id="product_available_day"
                                  width={"100%"}
                                  className="table table-bordered dataTable"
                                >
                                  <tr>
                                    <td
                                      style={{
                                        padding: "10px",
                                        textAlign: "justify",
                                        width: "100px",
                                      }}
                                    >
                                      Permalink
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        fontWeight: "500",
                                      }}
                                    >
                                      : {seoDetails?.permalink}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        padding: "10px",
                                        textAlign: "justify",
                                        width: "100px",
                                      }}
                                    >
                                      Title
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        fontWeight: "500",
                                      }}
                                    >
                                      : {seoDetails?.title}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        padding: "10px",
                                        textAlign: "justify",
                                        width: "100px",
                                      }}
                                    >
                                      Description
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        fontWeight: "500",
                                      }}
                                    >
                                      : {seoDetails?.description}
                                    </td>
                                  </tr>
                                </table>
                              </Col>

                              <Col md="12">
                                <h5 className="pb-2">FAQs</h5>
                                <div>
                                  {seoDetails &&
                                    seoDetails?.faq?.map((item, index) => (
                                      <div class="accordion" key={index}>
                                        <div class="accordion-item mb-3">
                                          <h2
                                            class="accordion-header"
                                            id={`heading${index}`}
                                          >
                                            <button
                                              class={`accordion-button ${activeAccordion === index
                                                ? ""
                                                : "collapsed"
                                                }`}
                                              type="button"
                                              onClick={() =>
                                                handleAccordionClick(index)
                                              }
                                              aria-expanded={
                                                activeAccordion === index
                                                  ? "true"
                                                  : "false"
                                              }
                                              aria-controls={`collapse${index}`}
                                            >
                                              <b>{item.question}</b>
                                            </button>
                                          </h2>
                                          <div
                                            id={`collapse${index}`}
                                            class={`accordion-collapse collapse ${activeAccordion === index
                                              ? "show"
                                              : ""
                                              }`}
                                            aria-labelledby={`heading${index}`}
                                            data-bs-parent="#accordionExample"
                                          >
                                            <div class="accordion-body">
                                              {item.answer}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </Col>
                            </Row>
                          </TabPanel>
                        </TabContext>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CourseDashboard
