import React, { useEffect, useState } from "react"

import { Col, Row } from "reactstrap"

import MiniWidget from "../../../pages/Dashboard/Marketing/mini-widget"
import { get } from "../../../helpers/api_helper"

const Summary = ({id}) => {

  const [countData,setCountData] = useState(null)
  
  useEffect(() => {
    fetchCounts()
  }, [])

  const fetchCounts = async()=>{
    try {
      const res =await get(`/branch/counts?id=${id}`) 
      setCountData(res.data)
    } catch (error) {
      
    }
  }
  const series1 = [50]
  const options1 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series2 = [70]

  const options2 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series3 = [55]

  const options3 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series4 = [33]

  const options4 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const reports = [
    {
      id: 1,
      title: "Lead",
      value: countData?.lead,
      decimal: 0,
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue: countData?.leadThisMonth,
      color: "success",
      desc: "This Month",
      series: series1,
      options: options1,
    },
    {
      id: 2,
      title: "Followup",
      value: countData?.LeadFollowup,
      decimal: 0,
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue:countData?.LeadFollowupThisMonth,
      color: "success",
      desc: "This Month",
      series: series2,
      options: options2,
    },
    {
      id: 3,
      title: "Assigned",
      value: countData?.assigned,
      decimal: 0,
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue:countData?.assignedThisMonth,
      color: "success",
      desc: "This Month",
      series: series3,
      options: options3,
    },
    {
      id: 4,
      title: "Student",
      value: countData?.student,
      decimal: 0,
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue:countData?.studentThisMonth,
      color: "success",
      desc: "This Month",
      series: series4,
      options: options4,
    },
  ]

  return (
    <Row>
      <Col md="12">
        <Row>
          <MiniWidget reports={reports} />
        </Row>
      </Col>
    </Row>
  )
}

export default Summary
