import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import Select from "react-select"

import { get } from "../../../helpers/api_helper"
import toastr from "toastr"
import moment from "moment"
import { API_URL } from "../../../config"
import MuiCustomTablePagination from "../../../components/Common/TablePagination"

const CertificateLog = () => {
  const initialFilters = {
    type: { label: "Select...", value: "" },
    from: "",
    to: "",
    search: "",
  }
  const [tableData, setTableData] = useState([])
  const [csp, setCsp] = useState({ label: "Select", value: "" })
  const [batch, setBatch] = useState({ label: "Select", value: "" })
  const [cspList, setCspList] = useState([])
  const [batchList, setBatchList] = useState([])
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(20)
  const [count, setCount] = useState(0)
  const [filter, setFilter] = useState(initialFilters)
  const [disableReset,setDisableReset] = useState(true)
  const [disableResetFilters, setDisableResetFilters] = useState(true)

  const fetchCspList = () => {
    get(`certification/options/log-branch`)
      .then(res => {
        if (res.success) {
          setCspList(res.data)
        }
      })
      .catch(err => toastr.error(err.response.data.message))
  }

  const fetchBatchList = () => {
    get(`certification/options/log-batch?branch=${csp.value}`)
      .then(res => {
        if (res.success) {
          setBatchList(res.data)
        }
      })
      .catch(err => toastr.error(err.response.data.message))
  }

  const fetchTableData = () => {
    get(
      `certification/log?batch=${batch.value}&type=${filter.type.value}&from=${filter.from}&to=${filter.to}&search=${filter.search}&page=${page}&limit=${pageSize}`
    )
      .then(res => {
        if (res.success) {
          setCount(res.count)
          setTableData(res.data)
        } else {
          toastr.error("Failed to fetch templates")
        }
      })
      .catch(err => toastr.error(err.response.data.message))
  }

  useEffect(() => {

      if (filter.from) {
        if (!filter.to) {
          return
        }
      }
      fetchTableData()

    //eslint-disable-next-line
  }, [batch, page, pageSize, filter])

  useEffect(() => {
    fetchCspList()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (csp.value) {
      fetchBatchList()
      setBatch({label:'Select...',value:''})
    }
    // eslint-disable-next-line
  }, [csp])

  const viewDocument = path => {
    if (path) {
      const openPath = `${API_URL}${path}`
      window.open(openPath, "_blank")
    }
  }

  const handleChangeRowsPerPage = event => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handlePageChange = newPage => {
    setPage(newPage)
  }

  const handleChange = e => {
    const { name, value } = e.target
    if (name === "to") {
      if (value < filter.from) {
        toastr.error("Invalid Date Range")
        return
      }
    }
    setFilter({
      ...filter,
      [name]: value,
    })
    setDisableResetFilters(false)
  }

  const handleReset = () => {
    setDisableReset(true)
    setCsp({ label: "Select...", value: "" })
    setBatch({ label: "Select...", value: "" })
    setBatchList([])
    setPage(0)
  }

  return (
    <div className="page-content">
      <div className="container-fluid" style={{ minHeight: "500px" }}>
        <Breadcrumb title="Home" breadcrumbItem="Certificate Log " />
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <Row>
                  <Col md="12">
                    <Col xl={12}>
                      <Row className="nav-row">
                        <Col md="12">
                          <Col xl={12}>
                            <Row>
                              <Col xl={3} sm='4' xs='6'>
                                <div>
                                  <Label>CSP Name</Label>
                                  <Select
                                    name="student"
                                    classNamePrefix="select2-selection"
                                    options={cspList}
                                    onChange={item => {setCsp(item); setDisableReset(false)}}
                                    value={csp}
                                  />
                                </div>
                              </Col>
                              <Col xl="3" sm='4' xs='6'>
                                <div>
                                  <Label>Batch</Label>
                                  <Select
                                    name="batch"
                                    classNamePrefix="select2-selection"
                                    options={batchList}
                                    onChange={item => {setBatch(item); setDisableReset(false)}}
                                    value={batch}
                                    width={50}
                                    isDisabled={csp.value === ''}
                                  />
                                </div>
                              </Col>
                              <Col>
                                <Button
                                  type="button"
                                  style={{
                                    marginTop: "1.7rem",
                                    marginLeft: "1rem",
                                  }}
                                  color="danger"
                                  onClick={handleReset}
                                  disabled={disableReset}
                                >
                                  Reset
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Col>
                      </Row>
                    </Col>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xl="2" md='3' xs='6'>
                    <div>
                      <Label>Type</Label>
                      <Select
                        name="type"
                        classNamePrefix="select2-selection"
                        options={[
                          { label: "Select...", value: "" },
                          { label: "Course Completion", value: 1 },
                          { label: "NCVET Short-term", value: 2 },
                          { label: "NCVET Long-term", value: 3 },
                          { label: "TP Assessment", value: 4 },
                        ]}
                        onChange={e => {
                          setFilter({ ...filter, type: e });
                          setDisableResetFilters(false)
                        }}
                        value={filter.type}
                        width={50}
                      />
                    </div>
                  </Col>
                  <Col xl="2" md='3' xs='6'>
                    <div>
                      <Label>From</Label>
                      <Input
                        name="from"
                        type="date"
                        onChange={e => handleChange(e)}
                        value={filter.from}
                        width={50}
                      />
                    </div>
                  </Col>
                  <Col xl="2" md='3' xs='6'>
                    <div>
                      <Label>To</Label>
                      <Input
                        name="to"
                        type="date"
                        onChange={e => handleChange(e)}
                        value={filter.to}
                        width={50}
                        min={new Date()}
                      />
                    </div>
                  </Col>
                  <Col xl="2" md='3' xs='6'>
                    <div>
                      <Label>Search</Label>
                      <Input
                        name="search"
                        onChange={e => handleChange(e)}
                        value={filter.search}
                        width={50}
                        placeholder="Search..."
                      />
                    </div>
                  </Col>

                  <Col>
                    <Button
                      type="button"
                      style={{
                        marginTop: "1.7rem",
                        marginLeft: "1rem",
                      }}
                      color="danger"
                      onClick={() => {setFilter(initialFilters);setDisableResetFilters(true)}}
                      disabled={disableResetFilters}
                    >
                      Reset Filters
                    </Button>
                  </Col>
                </Row>
                <div style={{width:'100%',overflow:'auto'}}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th rowSpan={2} style={{ verticalAlign: "middle" }}>#</th>
                      <th rowSpan={2} style={{ verticalAlign: "middle" }}>Name</th>
                      <th rowSpan={2} style={{ verticalAlign: "middle" }}>Register No.</th>

                      <th rowSpan={2} style={{ verticalAlign: "middle" }}>Type</th>
                      <th colSpan={3} style={{ verticalAlign: "middle" }}>Issue</th>
                      <th colSpan={3} style={{ verticalAlign: "middle" }}>Publish</th>
                      
                      <th rowSpan={2} style={{ verticalAlign: "middle" }}>Certificate</th>
                    </tr>
                    <tr>
                    <th style={{ verticalAlign: "middle" }}>Date</th>
                      <th style={{ verticalAlign: "middle" }}>Time</th>
                      <th style={{ verticalAlign: "middle" }}>Author</th>
                      <th style={{ verticalAlign: "middle" }}>Date</th>
                      <th style={{ verticalAlign: "middle" }}>Time</th>
                      <th style={{ verticalAlign: "middle" }}>Author</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((item, index) => (
                      <tr style={{ textAlign: "center" }}>
                        <td>{index + 1 + (page * pageSize)}</td>
                        <td style={{ textAlign: "start" }}>
                          {item.student.firstname} {item.student.lastname}
                        </td>
                        <td>{item.student.uniqueId}</td>
                        <td>
                          {item.type === 1
                            ? "Course Completion"
                            : item.type === 2
                            ? "NCVET Short-term"
                            : item.type === 3
                            ? "NCVET Long-term"
                            : item.type === 4 ? 
                              "TP Assessment" 
                            : "err"}
                        </td>
                        <td>{ item.issueDate && moment(item.issueDate).format("DD-MM-YYYY") || ''}</td>
                        <td>
                          {item.issueTime && moment(item.issueTime, "HH:mm:ss").format("hh:mm A") || ''}
                        </td>
                        <td>
                          {item.addedBy.firstname} {item.addedBy.lastname}
                        </td>
                        <td>
                          {item.publishedDate && moment(item.publishedDate).format("DD-MM-YYYY") || ''}
                        </td>
                        <td>
                          {item.publishedTime && moment(item.publishedTime, "HH:mm:ss").format(
                            "hh:mm A"
                          ) || ''}
                        </td>
                        <td>
                          {item.publishedBy.firstname}{" "}
                          {item.publishedBy.lastname}
                        </td>
                        <td>
                          <a
                            onClick={() => viewDocument(item.certificate)}
                            href="#"
                          >
                            <i className="far fa-file-pdf danger"></i>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table></div>
                {tableData.length > 0 && count > 0 && (
                  <MuiCustomTablePagination
                    count={count}
                    page={page}
                    rowsPerPage={pageSize}
                    handleChangePage={handlePageChange}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default CertificateLog
