import React, { useEffect, useRef, useState } from "react"
import { Row, Col, Card, CardBody, Label, Button, Modal } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select"
import { MDBDataTable } from "mdbreact"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { get, post, put } from "../../../helpers/api_helper"
import Swal from "sweetalert2"
import toastr, { options } from "toastr"
import moment from "moment"
import TablePagination from "../../../components/Common/Pagination"
import { PRIVILEGES } from "../../../config"
import useBranch from "../../../hooks/useBranch"
import SelectErrorMessage from "../../../components/Common/SelectErrorMessage"
import "./style.css"
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


function AssetIssue({
  useType,
  assetSingleId,
  assetName,
  setStateLog,
  stateLog,
  serialNumber,
  assetPurchaseDate,
}) {
 


  const myCsp = useBranch()
  const [myCspId, setMyCspId] = useState("")
  const [returnCaptured, setreturnCaptured] = useState(false)
  const [issuedDate, setIssuedDate] = useState("")
  const [issuedDateErr, setIssuedDateErr] = useState(false)
  const [returnDate, setReturnDate] = useState("")
  const [remark,setRemark] = useState('')
  const [returnDateErr, setReturnErr] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isEditID, setIsEditID] = useState("")
  const [changeIssuedFor, setChangeIssuedFor] = useState(false)
  const [specialPower, setSpecialPower] = useState(false)
  const [isPermanent,setisPermanent] = useState(false)
  const [permanent,setPermanent] = useState(false)
  const [updatePermanent,setUpdatePermanent] = useState(false)

  const [selectedType, setSelectedType] = useState(null)
  const initialErr = {
    type: false,
    category: false,
    subCategory: false,
    purchase: false,
    warranty: false,
    amcStart: false,
    amcEnd: false,
    csp: false,
  }
  const [err, setErr] = useState(initialErr)
  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [subCategories, setSubCategories] = useState([])
  const [selectedSubCategory, setSelectedSubCategory] = useState(null)

  const [selectedTypeFilter,setselectedTypeFilter] = useState(null)
const [selectedCategoryFilter,setselectedCategoryFilter] = useState(null)
const [selectedSubCategoryFilter,setselectedSubCategoryFilter] = useState(null)
const [subCategoriesFilter, setSubCategoriesFilter] = useState([])

  const [assets, setAssets] = useState([])
  const [assetsAll, setAssetsAll] = useState([])
  const [csp, setCsp] = useState([])
  const [classOrlab, setClassOrLab] = useState([])
  const [trainees, setTarinees] = useState([])
  const [trainres, setTrainers] = useState([])
  const [assetFilter, setAssetFilter] = useState(null)
  const [selectedIssuedForTypeFilter, setSelectedIssuedForTypeFilter] = useState(null)
  const [selectedCspFilter, setSelectedCSPFilter] = useState(null)
  const [selectedStudentFilter, setSelectedStudentFilter] = useState(null)
  const [selectedTrainerFilter, setSelectedTrainerFilter] = useState(null)
  const [selectedClassFilter, setSelectedClassFilter] = useState(null)

  const [selectedAsset, setSelectedAsset] = useState(null)
  const [selectedIssuedForType, setSelectedIssuedForType] = useState(null)
  const [selectedClass, setSelectedClass] = useState(null)
  const [selectedCSP, setSelectedCSP] = useState(null)
  const [selectedTrainer, setSelectedTrainer] = useState(null)
  const [selectedTrainee, setSelectedTrainee] = useState(null)
  const [issuedForTypeErrorMessage, setIssuedForTypeErrorMessage] = useState("")
  const [issuedErrorMessage, setIssuedErrorMessage] = useState("")
  const [issuedAssetMessage, setAssetErrorMessage] = useState("")

  const [tableData, setTableData] = useState([])
  const [usetId, setUserId] = useState("")
  const formRef = useRef()
  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 20
  const [state, setState] = useState(false)
  const [tableColState, setTableColState] = useState([])
  const [popupView, setPopupView] = useState(false)
  const [modalData,setModalData] = useState(null)

  const tableCol = [
    {
      label: "#",
      field: "id",
      sort: "asc",
      width: 150,
    },
    {
      label: "Date",
      field: "date",
      sort: "asc",
      width: 400,
    },
    {
      label: "Type",
      field: "type",
      sort: "asc",
      width: 150,
    },
    {
      label: "Category",
      field: "categoryName",
      sort: "asc",
      width: 150,
    },
    // {
    //   label: "Sub Category",
    //   field: "subCategoryName",
    //   sort: "asc",
    //   width: 150,
    // },
    {
      label: "Asset",
      field: "assets",
      sort: "asc",
      width: 200,
    },
    {
      label: "Issued To",
      field: "issued_for",
      sort: "asc",
      width: 200,
    },
    {
      label: "Name",
      field: "name",
      sort: "asc",
      width: 200,
    },
    // {
    //   label: "Issued Date",
    //   field: "issuedDate",
    //   sort: "asc",
    //   width: 200,
    // },
    {
      label: "Exp Return Date ",
      field: "returnDate",
      sort: "asc",
      width: 200,
    },
    {
      label: "Issued By",
      field: "issued_by",
      sort: "asc",
      width: 200,
    },
    {
      label: "Action",
      field: "action",
      sort: "asc",
      width: 200,
    },
  ]

  const tableCol2 = [
    {
      label: "#",
      field: "id",
      sort: "asc",
      width: 150,
    },
    {
      label: "Date",
      field: "date",
      sort: "asc",
      width: 400,
    },
    {
      label: "Time",
      field: "time",
      sort: "asc",
      width: 200,
    },
    {
      label: "Issued To",
      field: "issued_for",
      sort: "asc",
      width: 200,
    },
    {
      label: "Name",
      field: "name",
      sort: "asc",
      width: 200,
    },
    // {
    //   label: "Issued Date",
    //   field: "issuedDate",
    //   sort: "asc",
    //   width: 200,
    // },
    {
      label: "Exp Return Date",
      field: "returnDate",
      sort: "asc",
      width: 200,
    },
    {
      label: "Issued By",
      field: "issued_by",
      sort: "asc",
      width: 200,
    },
    {
      label: "Action",
      field: "action",
      sort: "asc",
      width: 200,
    },
  ]
  useEffect(() => {
    if (selectedIssuedForType && selectedIssuedForType?.value == 0) {
      let cspId = myCsp ? myCsp?.[0]?.value : ""
      // let cspItem = myCsp ? myCsp?.[0] : ""
      setMyCspId(cspId)
      // setSelectedCSP(cspItem)
    }
  }, [selectedIssuedForType])

  useEffect(() => {
    if (
      selectedIssuedForTypeFilter &&
      selectedIssuedForTypeFilter?.value == 0
    ) {
      let cspId = myCsp ? myCsp?.[0]?.value : ""
      let cspItem = myCsp ? myCsp?.[0] : ""
      setMyCspId(cspId)
      setSelectedCSPFilter(cspItem)
    }
  }, [selectedIssuedForTypeFilter])

  useEffect(() => {
    const updatedTableCol = useType !== "assetDetails" ? tableCol : tableCol2
    setTableColState(updatedTableCol)
  }, [useType])

  useEffect(() => {
    let cspId = myCsp ? myCsp?.[0]?.value : ""
    setMyCspId(cspId)
  }, [state])

  useEffect(() => {
    if (useType == "assetDetails" && assetName) {
      setSelectedAsset({
        label: { name: assetName, serialNumber: serialNumber },
        value: assetSingleId,
      })
    }
  }, [assetName, assetSingleId])

  useEffect(() => {
    fetchTableData()
  }, [
    state,
    page,
    assetFilter,
    selectedIssuedForTypeFilter,
    selectedClassFilter,
    selectedCspFilter,
    selectedStudentFilter,
    selectedTrainerFilter,
    selectedTypeFilter,
    selectedSubCategoryFilter,
    selectedCategoryFilter
  ])

  useEffect(() => {
    if(selectedSubCategoryFilter){
      fetchAssetsAll()
    }
  }, [selectedSubCategoryFilter])

  useEffect(() => {
    if(selectedSubCategory){
      fetchAssets()
    }
  }, [selectedSubCategory,selectedType])

  useEffect(() => {
    fetchCsp()
  }, [])

  useEffect(() => {
    fetchClassOrLab()
  }, [])

  useEffect(() => {
    fetchStudents()
  }, [])

  useEffect(() => {
    fetchTrainers()
  }, [])

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const res = await get("/options/asset/category")
        setCategories(res.data)
      } catch (error) {}
    }
    fetchCategory()
  }, [])

  useEffect(() => {
    const fetchSubCategory = async () => {
      try {
        const res = await get(
          `/options/asset/sub/category?category=${selectedCategory?.value}`
        )
        setSubCategories(res.data)
      } catch (error) {}
    }
    if (selectedCategory && selectedCategory?.value) fetchSubCategory()
  }, [selectedCategory])

  useEffect(() => {
    const fetchSubCategory = async () => {
      try {
        const res = await get(
          `/options/asset/sub/category?category=${selectedCategoryFilter?.value}`
        )
        setSubCategoriesFilter(res.data)
      } catch (error) {}
    }
    if (selectedCategoryFilter && selectedCategoryFilter?.value) fetchSubCategory()
  }, [selectedCategoryFilter])

  useEffect(()=>{
      const fetch = async()=>{
        const res = await get(`/asset/permanent?assetId=${selectedAsset?.value}`)
        setPermanent(res?.permission)
      }
      if(selectedAsset  && selectedAsset?.value) fetch()
  },[selectedAsset,updatePermanent])

  const fetchAssets = async () => {
    try {
      const response = await get(
        `/options/assets?type=${selectedType?.value}&subCategory=${selectedSubCategory?.value}`
      )
      setAssets(response?.data)
    } catch (error) {}
  }

  const fetchAssetsAll = async () => {
    try {
      const response = await get(`/options/assets/allOwner?type=${selectedTypeFilter?.value}&subCategory=${selectedSubCategoryFilter?.value}`)
      setAssetsAll(response?.data)
    } catch (error) {}
  }

  const fetchCsp = async () => {
    try {
      const response = await get(`/asset/branches-options`)
      setCsp(response?.data)
    } catch (error) {}
  }

  const fetchClassOrLab = async () => {
    try {
      const response = await get(`/asset/classORlab-options`)
      setClassOrLab(response?.data)
    } catch (error) {}
  }

  const fetchStudents = async () => {
    try {
      const response = await get(`/asset/profile-options`)
      setTarinees(response?.data)
    } catch (error) {}
  }

  const fetchTrainers = async () => {
    try {
      const response = await get(
        `/asset/trainer-options?trainersId=${PRIVILEGES.TRAINER}`
      )
      setTrainers(response?.data)
    } catch (error) {}
  }

  const isNull = field => {
    return (
      field === undefined ||
      field === "undefined" ||
      field === "" ||
      field === null ||
      field === "null"
    ) // || !!!field;
  }

  const fetchTableData = async () => {
    try {
      let assetId = !isNull(assetSingleId) ? assetSingleId : ""

      const filterQuery = `&page=${page}&limit=${limit}&assetId=${assetId}&asset=${assetFilter?.value}&csp=${selectedCspFilter?.value}&student=${selectedStudentFilter?.value}&classLab=${selectedClassFilter?.value}&trainer=${selectedTrainerFilter?.value}&issuedForType=${selectedIssuedForTypeFilter?.type}&assetType=${selectedTypeFilter?.value}&subCategory=${selectedSubCategoryFilter?.value}&category=${selectedCategoryFilter?.value}`

      const response = await get(`/asset/asset-issuesList?${filterQuery}`)
      setSpecialPower(response?.specicalPower)
      setUserId(response?.userId)
      setTableData(response?.data)
      const count = response?.count
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
    } catch (error) {}
  }

  const handleChange = (event, value) => {
    setPage(value)
  }

  function handleReturn(item) {
    return Swal.fire({
      title: "Are you sure?",
      // text: "You won't be able to return this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#3087d6",
      confirmButtonText: "Yes, return it!",
    }).then(result => {
      if (result.isConfirmed) {
        let typeReturn // 0=origin , 1=previous
        if (item?.isInitialOwner === true) {
          typeReturn = 0
        } else {
          typeReturn = 1
        }
        const response = post(
          `/asset/issues-return?id=${item?._id}&assetId=${item?.assetDetails?._id}&typeReturn=${typeReturn}`
        )
          .then(res => {
            toastr.success(res?.message)
            setState(!state)
            setStateLog(!stateLog)
            setUpdatePermanent(!updatePermanent)
            // getModule()
          })
          .catch(err => {
            if (err?.response?.data?.message) {
              toastr.error(err?.response?.data?.message)
            }
          })
      }
    })
  }

  function handleReturn1(item) {
    Swal.fire({
      title: "On which branch do you want to return?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Initiator",
      cancelButtonText: "Previous",
    }).then(result => {
      if (result.isConfirmed || result.dismiss === Swal.DismissReason.cancel) {
        const typeReturn = result.isConfirmed ? 0 : 1 //0=origin  , 1=previous

        Swal.fire({
          title: "Are you sure?",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, return it!",
        }).then(result => {
          if (result.isConfirmed) {
            const response = post(
              `/asset/issues-return?id=${item?._id}&assetId=${item?.assetDetails?._id}&typeReturn=${typeReturn}`
            )
              .then(res => {
                toastr.success(res.message)
                setState(!state)
                setStateLog(!stateLog)
                // getModule()
              })
              .catch(error => {
                if (error?.response?.data?.message) {
                  toastr.error(error?.response?.data?.message)
                }
              })
          }
        })
      }
    })
  }

  const reset2 = () => {
    setselectedTypeFilter(null)
    setselectedCategoryFilter(null)
    setselectedSubCategoryFilter(null)
    setAssetFilter(null)
    setSelectedIssuedForTypeFilter(null)
    setSelectedCSPFilter(null)
    setSelectedClassFilter(null)
    setSelectedStudentFilter(null)
    setSelectedTrainerFilter(null)
  }

  const reset = () => {
    formRef.current.reset()
    setSelectedCSP(null)
    setSelectedClass(null)
    setSelectedIssuedForType(null)
    setSelectedTrainee(null)
    setSelectedTrainer(null)

    if (useType != "assetDetails") {
      setSelectedAsset(null)
      setPermanent(false)
    }
    setSelectedType(null)
    setSelectedCategory(null)
    setSelectedSubCategory(null)
    setState(!state)
    setIssuedDateErr(false)
    setReturnErr(false)
    setAssetErrorMessage("")
    setIssuedForTypeErrorMessage("")
    setIssuedErrorMessage("")
    setIssuedDate("")
    setReturnDate("")
    setreturnCaptured(false)
    setChangeIssuedFor(false)
    setIsEdit(false)
    setIsEditID("")
  }
  const handleSubmit = async e => {
    e.preventDefault()
    if (!selectedAsset) {
      return setAssetErrorMessage("Please provide field")
    }

    if (!issuedDate) {
      return setIssuedDateErr(true)
    }

    if (!selectedIssuedForType) {
      return setIssuedForTypeErrorMessage("Please provide field")
    }

    if (
      !selectedCSP &&
      !selectedClass &&
      !selectedTrainee &&
      !selectedTrainer
    ) {
      return setIssuedErrorMessage("Please provide field")
    }

    let userId
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser")
      var user_obj = JSON.parse(data)
      userId = user_obj._id
    }

    try {
      let issuedForId

      switch (selectedIssuedForType.value) {
        case 0:
          issuedForId = selectedCSP.value
          break
        case 1:
          issuedForId = selectedClass.value
          break
        case 2:
          issuedForId = selectedTrainee.value
          break
        case 3:
          issuedForId = selectedTrainer.value
          break
        default:
          break
      }

      if (isEdit) {
        const response = await put("/asset/asset-issues", {
          id: isEditID,
          remark:remark,
          returnCaptured,
          returnDate,
        })
        toastr.success(response.message)
        reset()
      } else {
        const response = await post("/asset/asset-issues", {
          asset: selectedAsset?.value,
          isPermanent:isPermanent?1:0,
          issuedDate,
          returnCaptured,
          returnDate,
          remark,
          issuedForType: selectedIssuedForType?.type,
          issuedForId,
          addedBy: userId,
        })
        toastr.success(response.message)
        reset()
        setStateLog(!stateLog)
        setUpdatePermanent(!updatePermanent)
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toastr.error(error?.response?.data?.message)
      }
    }
  }

  const resetIssuedFor = () => {
    setChangeIssuedFor(true)
    setSelectedCSP(null)
    setSelectedClass(null)
    setSelectedTrainee(null)
    setSelectedTrainer(null)
  }

  const resetIssuedFor2 = () => {
    setSelectedCSPFilter(null)
    setSelectedClassFilter(null)
    setSelectedStudentFilter(null)
    setSelectedTrainerFilter(null)
  }

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  const permanentMap = {
    0:false,
    1:true,
  }

  const handleUpdation = async item => {
    setisPermanent(permanentMap[item?.isPermanent])
    let a = {
      label: item?.issuedFor?.name,
      value: item?.issuedFor?._id,
    }
    setIsEdit(true)
    setIsEditID(item._id)
    let asset = {
      label: {
        name: item?.assetDetails?.name,
        serialNumber: item?.assetDetails?.serialNumber,
        category: item?.subCategory?.categoryName,
      },
      value: item?.assetDetails?._id,
    }
    setSelectedAsset(asset)
    setRemark(item?.remark)
    setIssuedDate(item?.issuedDate)
    setReturnDate(item?.returnDate)

    if (item?.issuedFor?.type == "CSP") {
      setSelectedIssuedForType({ label: "CSP", value: 0, type: "branches" })
      const response = await get(`/asset/branches-options`)
      const s = response?.data.find(
        option => option.value === item?.issuedFor?._id
      )
      setSelectedCSP(s)
    } else if (item?.issuedFor?.type == "Student") {
      setSelectedIssuedForType({ label: "Student", value: 2, type: "profile" })
      setSelectedTrainee({
        label: item?.issuedFor?.name,
        value: item?.issuedFor?._id,
      })
    } else if (item?.issuedFor?.type == "Trainer") {
      setSelectedIssuedForType({ label: "Trainer", value: 3, type: "users" })
      setSelectedTrainer({
        label: item?.issuedFor?.name,
        value: item?.issuedFor?._id,
      })
    } else if (item?.issuedFor?.type == "Class/Lab") {
      setSelectedIssuedForType({
        label: "Class/Lab",
        value: 1,
        type: "classrooms_or_labs",
      })
      setSelectedClass({
        label: item?.issuedFor?.name,
        value: item?.issuedFor?._id,
      })
    }

    setreturnCaptured(item?.returnCaptured)
    if (item?.returnCaptured) setReturnDate(item?.returnDate)

    toTop()
  }

  const detailsData = {
    columns: tableColState,
    rows: tableData.map((item, index) => {
      // <i className="fas fa-undo-alt"></i>

      let actionButton1 = (
        <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip
            id="button-tooltip-2"
            style={{ position: "absolute", top: "-30px" }}
          >
            {'Asset Return'}
          </Tooltip>
        }
      >   
          <i
            className="fas fa-undo-alt "
            style={{
              fontSize: "1em",
              cursor: "pointer",
            }}            
            onClick={() => {
              handleReturn1(item)
            }}
          >
          </i>
          </OverlayTrigger>

      )

      let actionButton2 = (
        <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip
            id="button-tooltip-2"
            style={{ position: "absolute", top: "-30px" }}
          >
            {'Asset Return'}
          </Tooltip>
        }
      >   
          <i
            className="fas fa-undo-alt  "
            style={{
              fontSize: "1em",
              cursor: "pointer",
            }}           
             onClick={() => {
              handleReturn(item)
            }}
          >
          </i>
          </OverlayTrigger>

      )
      let actionButton
      let first =
        item?.isInitialOwner &&
        item?.isPrevOwner &&
        item?.status === 0 &&
        item?.assetDetails?.initialOwner != item?.assetDetails?.prevOwner
      let second =
        item?.status === 0 &&
        item?.assetDetails?.initialOwner != item?.assetDetails?.prevOwner &&
        specialPower

      let firstElese = item?.power === true && item?.status === 0
      let secondElese = specialPower === true && item?.status === 0

      if (first || second) {
        actionButton = actionButton1
      } else if (firstElese || secondElese) {
        actionButton = actionButton2
      }

      return {
        id: index + 1 + (page - 1) * limit,
        date: moment(item.date).format("DD-MM-YYYY"),
        time: moment(item.time, "HH:mm:ss").format("hh:mm A"),
        asset: item?.assetDetails?.name,
        assets: (
          <>
            <div>{item?.assetDetails?.name}</div>
            <div style={{ color: "#808080" }}>
              {item?.subCategory?.categoryName}
            </div>
            <div style={{ color: "#808080" }}>
              {item?.assetDetails?.serialNumber}
            </div>
          </>
        ),
        serialNumber: item?.assetDetails?.serialNumber,
        issuedFor: item?.issuedFor,
        categoryName: item?.category?.categoryName,
        subCategoryName: item?.subCategory?.categoryName,
        type: item?.assetDetails?.type == 0 ? "IT" : "NON IT",
        issued_for: item?.issuedFor?.type,
        returnDate: item?.returnDate
          ? moment(item?.returnDate).format("DD-MM-YYYY")
          : "",
        issuedDate: moment(item?.issuedDate).format("DD-MM-YYYY"),
        name: item?.issuedFor?.name,
        issue_for_days: item.durationUnit,
        issued_by: item.addedBy.name,
        action: (
          <div style={{ display: "flex", justifyContent: "start" }}>
             <i
        className="fas fa-eye"
        style={{
          fontSize: "1em",
          cursor: "pointer",
        }}
        onClick={() => {
          setPopupView(true)
          setModalData(item)
        }}
      ></i>
            <i
              className="far fa-edit"
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
              onClick={() => handleUpdation(item)}
            ></i>
            {item?.assetDetails?.initialOwner != item?.assetDetails?.owner &&
              actionButton}

          </div>
        ),
      }
    }),
  }

  return (
    <div className={`${useType != "assetDetails" ? "page-content" : ""}`}>
      <div className="container-fluid">
        {useType != "assetDetails" && (
          <Breadcrumb title="Home" breadcrumbItem="Assets Issues" />
        )}

        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <AvForm
                  className="needs-validation"
                  onSubmit={handleSubmit}
                  ref={formRef}
                >
                  <Row>
                    {useType != "assetDetails" && (
                      <>
                    {!isEdit &&
                      <>
                        <Col md="3">
                          <Label for={`type`}>Type</Label>
                          <Select
                            name="type"
                            classNamePrefix="select2-selection"
                            options={[
                              { label: "IT", value: 0 },
                              { label: "NON IT", value: 1 },
                            ]}
                            onChange={selected => {
                              setSelectedType(selected)
                              setErr({ ...err, type: false })
                              setSelectedAsset(null)
                            }}
                            value={selectedType}
                          />
                          <SelectErrorMessage
                            customStyle={{
                              width: "100%",
                              fontSize: "87.5%",
                              color: "var(--bs-form-invalid-color)",
                            }}
                            message="Select Type"
                            show={err?.type}
                          />
                        </Col>

                        <Col md={3}>
                          <Label for={`category`}>Category</Label>
                          <Select
                            name="category"
                            classNamePrefix="select2-selection"
                            options={categories}
                            onChange={selected => {
                              setSelectedCategory(selected)
                              setSelectedSubCategory(null)
                              setErr({ ...err, category: false })
                              setSelectedAsset(null)
                              setPermanent(false)
                            }}
                            value={selectedCategory}
                          />
                          <SelectErrorMessage
                            customStyle={{
                              width: "100%",
                              fontSize: "87.5%",
                              color: "var(--bs-form-invalid-color)",
                            }}
                            message="Select Category"
                            show={err.category}
                          />
                        </Col>
                        {selectedCategory && (
                          <Col md={3}>
                            <Label for={`category`}>Sub Category</Label>
                            <Select
                              name="category"
                              classNamePrefix="select2-selection"
                              options={subCategories}
                              onChange={selected => {
                                setSelectedSubCategory(selected)
                                setErr({ ...err, subCategory: false })
                                setSelectedAsset(null)
                                setPermanent(false)
                              }}
                              value={selectedSubCategory}
                            />
                            <SelectErrorMessage
                              customStyle={{
                                width: "100%",
                                fontSize: "87.5%",
                                color: "var(--bs-form-invalid-color)",
                              }}
                              message="Select Sub Category"
                              show={err.subCategory}
                            />
                          </Col>
                        )}
                        </>
                       }
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Assets</Label>
                            <Select
                              isDisabled={isEdit || useType == "assetDetails"}
                              name="students"
                              classNamePrefix="select2-selection"
                              options={assets}
                              onChange={item => {
                                setSelectedAsset(item)
                                setAssetErrorMessage("")
                              }}
                              value={selectedAsset}
                              formatOptionLabel={option => (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <strong class="gray-text">
                                    {option.label.name}
                                  </strong>
                                  <span>{option.label.category}</span>
                                  <span>{option.label.serialNumber}</span>
                                </div>
                              )}
                            />
                            <p
                              style={{
                                color: "red",
                                fontSize: "0.9em",
                                opacity: 0.7,
                              }}
                            >
                              {issuedAssetMessage}
                            </p>
                          </div>
                        </Col>
                      </>
                    )}
                    <Col md="3">
                      <Label> Issued Date</Label>
                      <AvField
                      name="date"
                      type="date"
                      disabled={isEdit}  
                      onChange={e => {
                        setIssuedDate(e.target.value);
                        setIssuedDateErr(false);
                      }}
                      value={issuedDate}
                      min={selectedAsset?.purchaseDate || (assetPurchaseDate && assetPurchaseDate)}
                    />
                      <SelectErrorMessage
                        customStyle={{
                          width: "100%",
                          fontSize: "87.5%",
                          color: "var(--bs-form-invalid-color)",
                          marginTop: "-14px",
                        }}
                        message="Please provide field"
                        show={issuedDateErr}
                      />
                    </Col>

                    <Col md="3">
                      <div className="mb-3">
                        <Label>Issued To</Label>
                        <Select
                          isDisabled={isEdit}
                          name="issued_for"
                          classNamePrefix="select2-selection"
                          options={[
                            { label: "CSP", value: 0, type: "branches" },
                            {
                              label: "Class/Lab",
                              value: 1,
                              type: "classrooms_or_labs",
                            },
                            { label: "Student", value: 2, type: "profile" },
                            { label: "Trainer", value: 3, type: "users" },
                          ]}
                          value={selectedIssuedForType}
                          onChange={item => {
                            setSelectedIssuedForType(item)
                            setIssuedForTypeErrorMessage("")
                            resetIssuedFor()
                          }}
                        />
                        <p
                          style={{
                            color: "red",
                            fontSize: "0.8em",
                            opacity: 0.7,
                          }}
                        >
                          {issuedForTypeErrorMessage}
                        </p>
                      </div>
                    </Col>

                    {selectedIssuedForType?.value == 0 && (
                      <Col md="3">
                        <div className="mb-3">
                          <Label>CSP</Label>
                          <Select
                            isDisabled={isEdit}
                            name="csp"
                            classNamePrefix="select2-selection"
                            options={csp}
                            value={selectedCSP}
                            onChange={item => setSelectedCSP(item)}
                          />
                        </div>
                        <p
                          style={{
                            color: "red",
                            fontSize: "0.8em",
                            opacity: 0.7,
                          }}
                        >
                          {issuedErrorMessage}
                        </p>
                      </Col>
                    )}

                    {selectedIssuedForType?.value == 1 && (
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Class/label</Label>
                          <Select
                            isDisabled={isEdit}
                            name="class"
                            classNamePrefix="select2-selection"
                            options={classOrlab}
                            value={selectedClass}
                            onChange={item => {
                              setSelectedClass(item)
                              setIssuedErrorMessage("")
                            }}
                          />
                          <p
                            style={{
                              color: "red",
                              fontSize: "0.8em",
                              opacity: 0.7,
                            }}
                          >
                            {issuedErrorMessage}
                          </p>
                        </div>
                      </Col>
                    )}

                    {selectedIssuedForType?.value == 2 && (
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Student</Label>
                          <Select
                            isDisabled={isEdit}
                            name="trainer"
                            classNamePrefix="select2-selection"
                            options={trainees}
                            value={selectedTrainee}
                            onChange={item => {
                              setSelectedTrainee(item)
                              setIssuedErrorMessage("")
                            }}
                          />
                          <p
                            style={{
                              color: "red",
                              fontSize: "0.8em",
                              opacity: 0.7,
                            }}
                          >
                            {issuedErrorMessage}
                          </p>
                        </div>
                      </Col>
                    )}

                    {selectedIssuedForType?.value == 3 && (
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Trainer</Label>
                          <Select
                            isDisabled={isEdit}
                            name="trainee"
                            classNamePrefix="select2-selection"
                            options={trainres}
                            value={selectedTrainer}
                            onChange={item => {
                              setSelectedTrainer(item)
                              setIssuedErrorMessage("")
                            }}
                          />
                          <p
                            style={{
                              color: "red",
                              fontSize: "0.8em",
                              opacity: 0.7,
                            }}
                          >
                            {issuedErrorMessage}
                          </p>
                        </div>
                      </Col>
                    )}

{permanent && selectedIssuedForType && !isEdit &&
 <Col md="1" style={{marginRight:"1.7rem"}}>
<div className="mb-3 ">
  <Label
   
  >
    Permanent
  </Label>

  <div
    className="btn-group btn-group-sm"
    role="group"
    aria-label="Basic example"
  >
    <input
      type="radio"
      className="btn-check"
      name="isPermanent"
      value={1}
      id="isPermanent-yes"
      onClick={() => setisPermanent(true)}
      checked={isPermanent === true}
    />
    <label
      style={{
        padding: "7px 17.6px",
        zIndex: "0",
        fontSize: "11px",
      }}
      className="btn btn-outline-primary"
      htmlFor="isPermanent-yes"
    >
      Yes
    </label>
    <input
      type="radio"
      name="isPermanent"
      className="btn-check"
      value={0}
      id="isPermanent-no"
      onClick={() => setisPermanent(false)}
      checked={isPermanent === false}
    />

    <label
      style={{
        padding: "7px 17.6px",
        zIndex: "0",
        fontSize: "11px",
      }}
      className="btn btn-outline-primary"
      htmlFor="isPermanent-no"
    >
      No
    </label>
  </div>
</div>
</Col>

    }

                 {!isPermanent && 
                 

                    <Col md="3" >
                      <Label>Expected Return Date</Label>
                      <AvField
                        name="date"
                        type="date"
                        onChange={e => {
                          setReturnDate(e.target.value)
                          setReturnErr(false)
                        }}
                        value={returnDate}
                        min={issuedDate}
                      />
                    </Col>

                    }
                    <Col md="3">
                        <div className="mb-3">
                          <Label>Remark</Label>
                          <AvField
                            name="remark"
                            type="text"
                            placeholder="Remark"
                            className="form-control"
                            onChange={e => setRemark(e.target.value)}
                            value={remark}
                           
                          />
                        </div>
                      </Col>

                      
                      <Col md="12">
                        <div className="">
                          {/* CSP Details */}
                          {changeIssuedFor &&
                          selectedCSP &&
                          selectedIssuedForType?.value == 0 &&
                          !myCspId ? (
                            <table
                              id="product_available_day"
                              className="table table-bordered dataTable"
                            >
                              <tr>
                                <td
                                  style={{
                                    width: "100px",
                                    padding: "10px",
                                    textAlign: "left",
                                  }}
                                >
                                  Address :
                                </td>
                                <td style={{ textAlign: "justify" }}>
                                  {selectedCSP?.address}
                                </td>
                              </tr>

                              <tr>
                                <td
                                  style={{
                                    width: "184px",
                                    padding: "10px",
                                    textAlign: "justify",
                                  }}
                                >
                                  Email :
                                </td>
                                <td style={{ textAlign: "justify" }}>
                                  {selectedCSP?.email}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    width: "184px",
                                    padding: "10px",
                                    textAlign: "justify",
                                  }}
                                >
                                  Mobile :
                                </td>
                                <td style={{ textAlign: "justify" }}>
                                  {selectedCSP?.mobile}
                                </td>
                              </tr>
                            </table>
                          ) : (
                            ""
                          )}

                          {/* Class Details */}
                          {changeIssuedFor &&
                          selectedClass &&
                          selectedIssuedForType?.value == 1 ? (
                            <table
                              id="product_available_day"
                              className="table table-bordered dataTable"
                            >
                              <tr>
                                <td
                                  style={{
                                    width: "184px",
                                    padding: "10px",
                                    textAlign: "justify",
                                  }}
                                >
                                  Class Code :
                                </td>
                                <td style={{ textAlign: "justify" }}>
                                  {selectedClass?.code}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    width: "184px",
                                    padding: "10px",
                                    textAlign: "justify",
                                  }}
                                >
                                  Class Strength :
                                </td>
                                <td style={{ textAlign: "justify" }}>
                                  {selectedClass?.maxStrength}
                                </td>
                              </tr>
                            </table>
                          ) : (
                            ""
                          )}

                          {/* Trainer Details */}

                          {changeIssuedFor &&
                          selectedTrainee &&
                          selectedIssuedForType?.value == 2 ? (
                            <table
                              id="product_available_day"
                              className="table table-bordered dataTable"
                            >
                              <tr>
                                <td
                                  style={{
                                    width: "184px",
                                    padding: "10px",
                                    textAlign: "justify",
                                  }}
                                >
                                  Student ID :
                                </td>
                                <td style={{ textAlign: "justify" }}>
                                  {selectedTrainee.unique_id}
                                </td>
                              </tr>

                              <tr>
                                <td
                                  style={{
                                    width: "184px",
                                    padding: "10px",
                                    textAlign: "justify",
                                  }}
                                >
                                  Mobile :
                                </td>
                                <td style={{ textAlign: "justify" }}>
                                  {selectedTrainee.mobile}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    width: "184px",
                                    padding: "10px",
                                    textAlign: "justify",
                                  }}
                                >
                                  Email :
                                </td>
                                <td style={{ textAlign: "justify" }}>
                                  {selectedTrainee.email}
                                </td>
                              </tr>
                            </table>
                          ) : (
                            ""
                          )}

                          {changeIssuedFor &&
                          selectedTrainer &&
                          selectedIssuedForType?.value == 3 ? (
                            <Col md="12">
                              <table
                                id="product_available_day"
                                className="table table-bordered dataTable"
                              >
                                <tr>
                                  <td
                                    style={{
                                      width: "184px",
                                      padding: "10px",
                                      textAlign: "justify",
                                    }}
                                  >
                                    Trainer ID :
                                  </td>
                                  <td style={{ textAlign: "justify" }}>
                                    {selectedTrainer.userId}
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    style={{
                                      width: "184px",
                                      padding: "10px",
                                      textAlign: "justify",
                                    }}
                                  >
                                    Mobile :
                                  </td>
                                  <td style={{ textAlign: "justify" }}>
                                    {selectedTrainer.mobile}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      width: "184px",
                                      padding: "10px",
                                      textAlign: "justify",
                                    }}
                                  >
                                    Email :
                                  </td>
                                  <td style={{ textAlign: "justify" }}>
                                    {selectedTrainer.email}
                                  </td>
                                </tr>
                              </table>
                            </Col>
                          ) : (
                            ""
                          )}

                          {/* Trainer Details */}
                        </div>
                      </Col>





                    <Row>
                      <Col md="3" style={{ marginTop: "8px" }}>
                        {isEdit ? (
                          <Button type="submit" color="warning">
                            Update
                          </Button>
                        ) : (
                          <Button type="submit" color="primary">
                            Submit
                          </Button>
                        )}

                        <Button
                          color="danger"
                          style={{ marginLeft: "15px" }}
                          onClick={reset}
                        >
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                {useType != "assetDetails" && (
                  <Row>
                    <AvForm className="">
                      <Row>

                      <Col md="3">
                        <Label for={`type`}>Type</Label>
                        <Select
                          name="type"
                          classNamePrefix="select2-selection"
                          options={[
                            { label: "IT", value: 0 },
                            { label: "NON IT", value: 1 },
                          ]}
                          onChange={selected => {
                            setselectedTypeFilter(selected)
                          }}
                          value={selectedTypeFilter}
                        />
                      
                      </Col>

                      <Col md={3}>
                        <Label for={`category`}>Category</Label>
                        <Select
                          name="category"
                          classNamePrefix="select2-selection"
                          options={categories}
                          onChange={selected => {
                            setselectedCategoryFilter(selected)
                            setselectedSubCategoryFilter(null)
                          }}
                          value={selectedCategoryFilter}
                        />
                      
                      </Col>
                      {selectedCategoryFilter && (
                        <Col md={3}>
                          <Label for={`category`}>Sub Category</Label>
                          <Select
                            name="category"
                            classNamePrefix="select2-selection"
                            options={subCategoriesFilter}
                            onChange={selected => {
                              setselectedSubCategoryFilter(selected)
                            }}
                            value={selectedSubCategoryFilter}
                          />
                        
                        </Col>
                      )}
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Assets</Label>
                            <Select
                              name="students"
                              classNamePrefix="select2-selection"
                              options={assetsAll}
                              onChange={item => {
                                setAssetFilter(item)
                              }}
                              value={assetFilter}
                              formatOptionLabel={option => (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <strong class="gray-text">
                                    {option.label.name}
                                  </strong>
                                  <span>{option.label.category}</span>
                                  <span>{option.label.serialNumber}</span>
                                </div>
                              )}
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label>Issued To</Label>
                            <Select
                              name="issued_for"
                              classNamePrefix="select2-selection"
                              options={[
                                { label: "CSP", value: 0, type: "branches" },
                                {
                                  label: "Class/Lab",
                                  value: 1,
                                  type: "classrooms_or_labs",
                                },
                                { label: "Student", value: 2, type: "profile" },
                                { label: "Trainer", value: 3, type: "users" },
                              ]}
                              value={selectedIssuedForTypeFilter}
                              onChange={item => {
                                setSelectedIssuedForTypeFilter(item)
                                resetIssuedFor2()
                              }}
                            />
                          </div>
                        </Col>

                        {selectedIssuedForTypeFilter?.value == 0 &&
                          !myCspId && (
                            <Col md="3">
                              <div className="mb-3">
                                <Label>CSP</Label>
                                <Select
                                  isDisabled={isEdit}
                                  name="csp"
                                  classNamePrefix="select2-selection"
                                  options={csp}
                                  value={selectedCspFilter}
                                  onChange={item => setSelectedCSPFilter(item)}
                                />
                              </div>
                            </Col>
                          )}

                        {selectedIssuedForTypeFilter?.value == 1 && (
                          <Col md="3">
                            <div className="mb-3">
                              <Label>Class/label</Label>
                              <Select
                                name="class"
                                classNamePrefix="select2-selection"
                                options={classOrlab}
                                value={selectedClassFilter}
                                onChange={item => {
                                  setSelectedClassFilter(item)
                                }}
                              />
                            </div>
                          </Col>
                        )}

                        {selectedIssuedForTypeFilter?.value == 2 && (
                          <Col md="3">
                            <div className="mb-3">
                              <Label>Student</Label>
                              <Select
                                name="trainer"
                                classNamePrefix="select2-selection"
                                options={trainees}
                                value={selectedStudentFilter}
                                onChange={item => {
                                  setSelectedStudentFilter(item)
                                }}
                              />
                            </div>
                          </Col>
                        )}

                        {selectedIssuedForTypeFilter?.value == 3 && (
                          <Col md="3">
                            <div className="mb-3">
                              <Label>Trainer</Label>
                              <Select
                                name="trainee"
                                classNamePrefix="select2-selection"
                                options={trainres}
                                value={selectedTrainerFilter}
                                onChange={item => {
                                  setSelectedTrainerFilter(item)
                                }}
                              />
                            </div>
                          </Col>
                        )}

                        <Col md="3" style={{ marginTop: "1.7rem" }}>
                          <Button color="danger" onClick={reset2}>
                            Reset
                          </Button>
                        </Col>
                      </Row>
                      <Row></Row>
                    </AvForm>
                  </Row>
                )}
                <MDBDataTable
                  id="dashboardTableId"
                  responsive
                  bordered
                  data={detailsData}
                  paginationLabel={false}
                  entries={20}
                  paging={false}
                />
                <TablePagination
                  page={page}
                  onChange={handleChange}
                  count={totalPage}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>


      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
      > 
        <div className="modal-header">
          <div className="modal-title">
            <h5>Assets Issues Details </h5>
          </div>
          <button
            type="button"
            onClick={() => setPopupView(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div>
      
      {" "}
      <div className="modal-body" style={{ paddingTop: "0px" }}>
        <Row>
          <Col md="12">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tbody>
                <tr>
                  <th style={{ textAlign: "start" }}>Issued Date</th>
                  <th> : </th>
                  <td style={{ textAlign: "start" }}>{modalData?.issuedDate ?moment(modalData?.issuedDate).format("DD-MM-YYYY"):''}</td>
                  <th style={{ padding: "", textAlign: "start" }}>Exp Return Date</th>
                  <th> : </th>
                  <td style={{ textAlign: "start" }}>{modalData?.returnDate ?moment(modalData?.returnDate).format("DD-MM-YYYY"):""}</td>
                </tr>
                <tr>
                  <th style={{ textAlign: "start" }}>Asset</th>
                  <th> : </th>
                  <td style={{ textAlign: "start" }}>
                    {modalData?.assetDetails.name}
                  </td>
                  <th style={{ textAlign: "start" }}>Issued To</th>
                  <th> : </th>
                  <td style={{ textAlign: "start" }}>
                    {modalData?.issuedFor.type}
                  </td>
                </tr>
                <tr>
                <th style={{ textAlign: "start" }}>Name</th>
                  <th> : </th>
                  <td style={{ textAlign: "start" }}>  {modalData?.issuedFor.name}</td>
                  {modalData?.issuedFor?.uniqueId && 
                  <>
                  <th style={{ textAlign: "start" }}>{modalData?.issuedFor.type} ID</th>
                  <th> : </th>
                  <td style={{ textAlign: "start" }}>
                  {modalData?.issuedFor?.uniqueId}
                  </td>
                  </>
                  }
                 
                </tr>

                <tr>
                <th style={{ textAlign: "start" }}>Remark</th>
                  <th> : </th>
                  <td style={{ textAlign: "start" }}>{modalData?.remark}</td>
                  <th style={{ textAlign: "start" }}>Type</th>
                  <th> : </th>
                  <td style={{ textAlign: "start" }}>
                      {modalData?.isPermanent === 1 ? "Permanent" : "Temporary"}
                    </td>
                </tr>
              </tbody>
            </table>  
          </Col>
        </Row>
      </div>
    </div>
      </Modal>
    </div>
  )
}

export default AssetIssue
