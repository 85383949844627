import React, { useEffect, useState } from "react"
import { Card, CardBody, Table, CardTitle, Row, Col } from "reactstrap"
import { numberToCurrency } from "../../../../helpers/functions"
import { get } from "../../../../helpers/api_helper"
import { useNavigate } from "react-router-dom"

const RevenueDetails = ({ masterObject }) => {
  const [revenueDetails, setRevenueDetails] = useState([])
  const navigate = useNavigate()
  useEffect(() => {
    handleRevenueDetails()
    // eslint-disable-next-line
  }, [masterObject])

  const handleRevenueDetails = async () => {
    try {
      const response = await get(
        `/finance-dashboard/details/revenue?startDate=${masterObject.startDate}&endDate=${masterObject.endDate}`
      )
      setRevenueDetails(response.data)
    } catch (error) {}
  }

  const handleDetails = branch => {
    navigate(`/finance-dashboard/details`, { state: { branch } })
  }

  return (
    <Row>
      {revenueDetails.length > 0 && (
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle className="h4 mb-4">Revenue Details</CardTitle>
              <div className="table-responsive">
                <Table
                  className="table-centered table-nowrap mb-0"
                  id="revenueDetails"
                >
                  <thead className="table-light">
                    <tr>
                      <th>#</th>
                      <th>CSP</th>
                      <th>Revenue</th>
                      <th>Expense</th>
                      <th>Margin</th>
                    </tr>
                  </thead>
                  <tbody>
                    {revenueDetails?.map((item, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td onClick={() => handleDetails(item._id)}>
                            {item.name}
                          </td>
                          <td>{numberToCurrency(item.revenue)}</td>
                          <td>{numberToCurrency(item.expense)}</td>
                          <td>
                            {numberToCurrency(item.revenue - item.expense || 0)}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      )}
    </Row>
  )
}

export default RevenueDetails
