import React, { useEffect, useRef, useState } from "react"
import { Row, Col, Card, CardBody, Label, Button } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import { MDBDataTable } from "mdbreact"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import moment from "moment"
import toastr from "toastr"
import { get } from "../../../helpers/api_helper"
import "./style.scss"
import TablePagination from "../../../components/Common/Pagination"
import { PRIVILEGES } from "../../../config"

function Index() {
  const [movementLogTable, setMovementLogTable] = useState([])
  const [assets, setAssets] = useState([])
  const formRef = useRef()
  const [csp, setCsp] = useState([])
  const [classOrlab, setClassOrLab] = useState([])
  const [trainees, setTarinees] = useState([])
  const [trainres, setTrainers] = useState([])

  const limit = 20
  const [totalPage2, setTotalPage2] = useState(0)
  const [page2, setPage2] = useState(1)

  const initialState = {
    asset: "",
    fromDate: "",
    toDate: "",
    returnStatus: "",
    fromType: "",
    from: "",
    toType: "",
    to: "",
  }
  const [filterObj, setFilterObj] = useState(initialState)

  useEffect(() => {
    fetchCsp()
  }, [])

  useEffect(() => {
    fetchClassOrLab()
  }, [])

  useEffect(() => {
    fetchStudents()
  }, [])

  useEffect(() => {
    fetchTrainers()
  }, [])

  const fetchCsp = async () => {
    try {
      const response = await get(`/asset/branches-options`)
      setCsp(response?.data)
    } catch (error) {}
  }

  const fetchClassOrLab = async () => {
    try {
      const response = await get(`/asset/classORlab-options`)
      setClassOrLab(response?.data)
    } catch (error) {}
  }

  const fetchStudents = async () => {
    try {
      const response = await get(`/asset/profile-options`)
      setTarinees(response?.data)
    } catch (error) {}
  }

  const fetchTrainers = async () => {
    try {
      const response = await get(
        `/asset/trainer-options?trainersId=${PRIVILEGES.TRAINER}`
      )
      setTrainers(response?.data)
    } catch (error) {}
  }

  useEffect(() => {
    fetchAssetMovementLog()
  }, [filterObj, page2])

  useEffect(() => {
    fetchAssets()
  }, [])

  const fetchAssetMovementLog = async () => {
    try {
      const filterQuery = `assetId=${filterObj?.asset?.value}&page=${page2}&limit=${limit}&fromDate=${filterObj?.fromDate}&toDate=${filterObj?.toDate}&returnStatus=${filterObj?.returnStatus?.value}&fromType=${filterObj?.fromType?.type}&toType=${filterObj?.toType?.type}&fromId=${filterObj?.from?.value}&toId=${filterObj?.to?.value}`

      const response = await get(`/asset/asset-movementLog?${filterQuery}`)
      setMovementLogTable(response?.data)
      const count = response?.count
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage2(totalPage)
    } catch (error) {
      if(error?.response?.data?.message){
        toastr.success(error?.response?.data?.message)
      }
    }
  }
  const fetchAssets = async () => {
    try {
      const response = await get(`/options/assets/allOwner`)
      setAssets(response.data)
    } catch (error) {}
  }
  const handleFilter = () => {
    fetchAssetMovementLog()
  }

  const reset = () => {
    formRef?.current?.reset()
    // setState(!state)
    setFilterObj(initialState)
  }

  const handleChange2 = (event, value) => {
    setPage2(value)
  }

  const logData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 200,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 200,
      },

      {
        label: "Asset",
        field: "asset",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Asset ID",
      //   field: "assetId",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "From Type",
        field: "fromType",
        sort: "asc",
        width: 200,
      },
      {
        label: "From",
        field: "from",
        sort: "asc",
        width: 200,
      },
      {
        label: "To Type",
        field: "toType",
        sort: "asc",
        width: 200,
      },
      {
        label: "To",
        field: "to",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 200,
      },
      {
        label: "Added By",
        field: "added_by",
        sort: "asc",
        width: 200,
      },
    ],
    rows: movementLogTable.map((item, index) => {
      let actionStatus
      if(item.returnStatus){
        actionStatus = <h6 style={{ color: "green" }}>Returned</h6>
      }else if(item?.isPermanent == true){
        actionStatus = <h6 style={{ color: "blue" }}>Permanent Issued</h6>
      }else{
        actionStatus = <h6 style={{ color: " " }}>Issued</h6>
      }

      return {
        id: index + 1 + (page2 - 1) * limit,
        date: moment(item?.createdAt).format("DD-MM-YYYY"),
        time: moment(item?.assetIssuesDetails?.time, "HH:mm:ss").format(
          "hh:mm A"
        ),
        asset: (
          <>
            <div>{item?.assetDetails?.name}</div>
            
            <div style={{ color: "#808080" }}>
              {item?.assetDetails?.serialNumber}
            </div>
          </>
        ),
        assetId: item?.assetDetails?.serialNumber,
        from: (
          <>
            <div>{item?.FromDetails?.name}</div>
            
            <div style={{ color: "#808080" }}>
              {item?.FromDetails?.uniqueId}
            </div>
          </>
        ),

        fromType: item?.FromDetails?.type,
        to: (
          <>
            <div>{item?.ToDetails?.name}</div>
            
            <div style={{ color: "#808080" }}>
              {item?.ToDetails?.uniqueId}
            </div>
          </>
        ),
        toType: item?.ToDetails?.type,
        status: actionStatus,
        added_by: item.addedByDetails.name,
      }
    }),
  }
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumb title="Home" breadcrumbItem="Assets Movement Log" />
        <Row>
                  <Col xl="12">
                    <Card>
                      <CardBody>
                                        <Col md="12">
                  <AvForm ref={formRef}>
                    <Row>
                      <Col md="2">
                        <Label>From Date</Label>
                        <AvField
                          name="date"
                          type="date"
                          onChange={e =>
                            setFilterObj({
                              ...filterObj,
                              fromDate: e.target.value,
                            })
                          }
                          value={filterObj?.fromDate}
                          // validate={max: {}}
                          max={filterObj?.toDate}
                        />
                      </Col>
                      <Col md="2">
                        <Label>To Date</Label>
                        <AvField
                          name="date"
                          type="date"
                          onChange={e =>
                            setFilterObj({
                              ...filterObj,
                              toDate: e.target.value,
                            })
                          }
                          value={filterObj?.toDate}
                          min={filterObj?.fromDate}
                          max={moment().format("YYYY-MM-DD")}
                        />
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Status</Label>
                          <Select
                            name="students"
                            classNamePrefix="select2-selection"
                            options={[
                              { label: "Issued", value: 0 },
                              { label: "Permanent Issued", value: 2 },
                              { label: "Returned", value: 1 },
                            ]}
                            onChange={item =>
                              setFilterObj({ ...filterObj, returnStatus: item })
                            }
                            value={filterObj?.returnStatus}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Assets</Label>
                          <Select
                            name="students"
                            classNamePrefix="select2-selection"
                            options={assets}
                            onChange={item =>
                              setFilterObj({ ...filterObj, asset: item })
                            }
                            value={filterObj?.asset}
                            formatOptionLabel={option => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                                }}
                              >
                                <strong class="gray-text">
                                  {option.label.name}
                                </strong>
                                <span>{option.label.category}</span>
                                <span>{option.label.serialNumber}</span>
                              </div>
                            )}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>From Type</Label>
                          <Select
                            name="issued_for"
                            classNamePrefix="select2-selection"
                            options={[
                              { label: "CSP", value: 0, type: "branches" },
                              {
                                label: "Class/Lab",
                                value: 1,
                                type: "classrooms_or_labs",
                              },
                              { label: "Student", value: 2, type: "profile" },
                              { label: "Trainer", value: 3, type: "users" },
                            ]}
                            value={filterObj?.fromType}
                            onChange={item => {
                              setFilterObj({ ...filterObj, fromType: item })
                             
                            }}
                          />
                        </div>
                      </Col>
                      {filterObj?.fromType?.value === 0 && (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>CSP</Label>
                            <Select
                              name="csp"
                              classNamePrefix="select2-selection"
                              options={csp}
                              value={filterObj?.from}
                              onChange={item =>
                                setFilterObj({
                                  ...filterObj,
                                  from:item,
                                })
                              }
                            />
                          </div>
                        </Col>
                      )}

                      {filterObj?.fromType?.value === 1 && (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Class/label</Label>
                            <Select
                              name="class"
                              classNamePrefix="select2-selection"
                              options={classOrlab}
                              value={filterObj?.from}
                              onChange={item =>
                                setFilterObj({
                                  ...filterObj,
                                  from:item,
                                })
                              }
                            />
                          </div>
                        </Col>
                      )}

                      {filterObj?.fromType?.value === 2 && (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Student</Label>
                            <Select
                              name="trainer"
                              classNamePrefix="select2-selection"
                              options={trainees}
                              value={filterObj?.from}
                              onChange={item =>
                                setFilterObj({
                                  ...filterObj,
                                  from:item,
                                })
                              }
                            />
                          </div>
                        </Col>
                      )}

                      {filterObj?.fromType?.value === 3 && (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Trainer</Label>
                            <Select
                              name="trainee"
                              classNamePrefix="select2-selection"
                              options={trainres}
                              value={filterObj?.from}
                              onChange={item =>
                                setFilterObj({
                                  ...filterObj,
                                  from:item,
                                })
                              }
                            />
                          </div>
                        </Col>
                      )}

                      <Col md="3">
                        <div className="mb-3">
                          <Label>To Type </Label>
                          <Select
                            name="issued_for"
                            classNamePrefix="select2-selection"
                            options={[
                              { label: "CSP", value: 0, type: "branches" },
                              {
                                label: "Class/Lab",
                                value: 1,
                                type: "classrooms_or_labs",
                              },
                              { label: "Student", value: 2, type: "profile" },
                              { label: "Trainer", value: 3, type: "users" },
                            ]}
                            value={filterObj?.toType}
                            onChange={item => {
                              setFilterObj({ ...filterObj, toType: item })
                             
                            }}
                          />
                        </div>
                      </Col>

                      {filterObj?.toType?.value === 0 && (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>CSP</Label>
                            <Select
                              name="csp"
                              classNamePrefix="select2-selection"
                              options={csp}
                              value={filterObj?.to}
                              onChange={item =>
                                setFilterObj({
                                  ...filterObj,
                                  to: item,
                                })
                              }
                            />
                          </div>
                        </Col>
                      )}

                      {filterObj?.toType?.value === 1 && (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Class/label</Label>
                            <Select
                              name="class"
                              classNamePrefix="select2-selection"
                              options={classOrlab}
                              value={filterObj?.to}
                              onChange={item =>
                                setFilterObj({
                                  ...filterObj,
                                  to: item,
                                })
                              }
                            />
                          </div>
                        </Col>
                      )}

                      {filterObj?.toType?.value === 2 && (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Student</Label>
                            <Select
                              name="trainer"
                              classNamePrefix="select2-selection"
                              options={trainees}
                              value={filterObj?.to}
                              onChange={item =>
                                setFilterObj({
                                  ...filterObj,
                                  to: item,
                                })
                              }
                            />
                          </div>
                        </Col>
                      )}

                      {filterObj?.toType?.value === 3 && (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Trainer</Label>
                            <Select
                              name="trainee"
                              classNamePrefix="select2-selection"
                              options={trainres}
                              value={filterObj?.to}
                              onChange={item =>
                                setFilterObj({
                                  ...filterObj,
                                  to: item,
                                })
                              }
                            />
                          </div>
                        </Col>
                      )}

                 <Col md="3">
                        {/* <Button onClick={handleFilter} type="submit" color="success">
                          Filter
                        </Button> */}
                        <Button
                          color="danger"
                          style={{ marginTop: "1.7rem" }}
                          onClick={reset}
                        >
                          Reset
                        </Button>
                      </Col>
                    </Row>
                   
                      
                  </AvForm>
                </Col>
                </CardBody>
        </Card>
      </Col>
    </Row>
        <Row>
          <Card>
            <CardBody>

              <Row>
                <Col className="col-12">
                  <MDBDataTable
                    id="movementTableID"
                    responsive
                    bordered
                    data={logData}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    paginationLabel={false}
                    paging={false}
                  />
                  <TablePagination
                    page={page2}
                    onChange={handleChange2}
                    count={totalPage2}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Row>
      </div>
    </div>
  )
}

export default Index
