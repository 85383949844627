import React, { useState, useEffect, useRef } from "react"
import { MDBDataTable } from "mdbreact"
// import toastr from "toastr"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import Select from "react-select"

import { AvForm } from "availity-reactstrap-validation"

import { get, post } from "../../../helpers/api_helper.js"
import moment from "moment"
import toastr from "toastr"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { getBranches } from "../../../store/options/actions.js"
import "./Attendance.scss"
function Attendace() {
  // const dispatch = useDispatch()

  const { allotted : { branches } }= useSelector(state =>state.Profile)

  // const { branches } = useSelector(state => state.Options)
  // useEffect(() => {
    // dispatch(getBranches())
    // eslint-disable-next-line
  // }, [dispatch])

  const formRef = useRef()
  const [selectedCsp, setselectedCsp] = useState(null)
  const [eventOption, seteventOption] = useState([])
  const [selectedEvent, setselectedEvent] = useState()
  const [master, setmaster] = useState({ cspIds: [] })
  const [attendList, setattendList] = useState({ registeredEvents: [], attendedEvents: [] })
  const [tableData, setTableData] = useState([])
  const [bookingDates, setbookingDates] = useState([])
  const [selectedBooking, setselectedBooking] = useState(null)


  useEffect(() => {
    if (master?.bookingDate) {
      fetchAllRegisterList()
    } else {
      setattendList([])
    }
  }, [master?.bookingDate])

  useEffect(()=>{
    if (master?.cspIds?.length > 0) {
      fetchAllEvents()
    } else { 
      seteventOption([]) 
    }
  },[master?.cspIds])

  useEffect(() => {
    fetchAllLog()
  }, [])



  function fetchAllDates(id) {
    get(`/event-reg/dates?event=${id}`).then((res) => {
      let result = res?.Data[0]?.bookingDate
      const bookDates = result?.map(date => ({ label: date, value: date }))
      setbookingDates(bookDates)
    })
  }


  function fetchAllEvents() {
    get(`/event-reg/getCspMatch?cspIds=${master?.cspIds}`)
      .then((res) => {
        let result = res.data;
        let transformedResult = result.map((item) => {
          return {
            value: item._id,
            label: item.name + "-" + item.uniqueId
          };
        });
        seteventOption(transformedResult);
      })
      .catch((err) => {
        console.error("Error fetching events:", err);
      });
  }


  function fetchAllRegisterList() {
    get(`/event-reg/manual-attendance?event=${master?.event}&bookingDate=${master?.bookingDate}`).then((res) => {
      setattendList(res)
    })
  }


  const handleAttend = (index) => {
    const data = { ...attendList };
    const eventToMove = data.registeredEvents[index];
    data.registeredEvents = data.registeredEvents.filter((_, i) => i !== index);
    data.attendedEvents = [eventToMove, ...data.attendedEvents];
    setattendList(data);
  };

  const handleUnattend = (index) => {
    const data1 = { ...attendList };
    const eventToMove1 = data1.attendedEvents[index];
    data1.attendedEvents = data1.attendedEvents.filter((_, i) => i !== index);
    data1.registeredEvents = [eventToMove1, ...data1.registeredEvents];
    setattendList(data1);
  }

  function handlesubmit() {
    let regDat = attendList?.attendedEvents.map((it) => it._id)
    let body = { registrationIds: regDat, attendedStatus: 1, bookingDate: master?.bookingDate, eventId: master?.event }
    console.log("regDat", regDat);
    post(`/event-reg/event-attendance`, body).then((res) => {
      toastr.success("Attended List Updated")
      fetchAllRegisterList()
    }).catch((err) => {
      toastr.error(err.response.data.message)
    })
  }

  function reset() {
    setmaster({})
    setselectedCsp(null)
    setselectedEvent(null)
    setselectedBooking(null)
    setattendList({ registeredEvents: [], attendedEvents: [] })
  }
  useEffect(()=>{
    console.log(selectedBooking,'booking')
  },[selectedBooking])
  function fetchAllLog() {
    get("/event-reg/log").then((res) => {
      let result = res.data
      result.map((item, index) => {
        item.id = index + 1;
        item.date = moment(item.activity_date).format("DD-MM-YYYY")
        item.time = moment(item.activity_time, 'HH:mm:ss').format('h:mm A');
        item.eventname = item.event.name
        return item
      })
      setTableData(result)
    })
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 150,
      },

      {
        label: "Event",
        field: "eventname",
        sort: "asc",
        width: 150,
      },
      {
        label: "Updated User",
        field: "activity_user",
        sort: "asc",
        width: 150,
      },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumb title="Home" breadcrumbItem="Attendance" />
        <Row>
          <Card>
            <CardBody>
              <AvForm ref={formRef} className="need-validation">
                <Row>
                  <Col md="3">
                    <Label>CSP</Label>
                    <Select options={branches}
                      value={selectedCsp}
                      onChange={(selected) => {
                        setselectedCsp(selected)
                        setmaster(pre => ({
                          ...pre, cspIds: [selected]?.map((id) => id._id)
                        }))
                        setselectedEvent(null)
                        setselectedBooking(null)
                        setbookingDates([])
                      }}
                    />
                  </Col>
                  <Col md="3">
                    <Label>Event</Label>
                    <Select
                      options={eventOption}
                      onChange={(selected) => {
                        setselectedEvent(selected)
                        fetchAllDates(selected.value)
                        setmaster(pre => ({ ...pre, event: selected.value }))
                      }}
                      value={selectedEvent}
                    />
                  </Col>
                  <Col md="2">
                    <Label>Booking Dates</Label>
                    <Select
                      options={bookingDates}
                      value={selectedBooking}
                      onChange={(selected) => {
                        setselectedBooking(selected)
                        setmaster(pre => ({ ...pre, bookingDate: selected.value }))
                      }}


                    />
                  </Col>
                  <Col md="2">
                    <Button onClick={reset} className="mt-4" type="reset" color="danger">Reset</Button>
                  </Col>
                </Row>
                <Row className="mt-5">
                  {master?.bookingDate &&
                    //     <Card>
                    // <CardBody>
                    <Row>
                      <Col md="6">
                        <div className="table-responsive">
                          <h6>Registered</h6>
                          {attendList?.registeredEvents?.length > 0 ? (
                            <table className="table table-bordered mb-0">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Name</th>
                                  <th>Mobile</th>
                                  {/* <th></th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {attendList?.registeredEvents?.map((item, index) => (
                                  <>
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>{item?.profile?.firstname || +" " + item?.profile?.lastename || ""}</td>
                                      <td>{item.profile.mobile}</td>
                                      <td>
                                        <input
                                          type="checkbox"
                                          name="headerCheckbox"
                                          id="headerCheckbox"
                                          checked={false}
                                          onClick={() => handleAttend(index)}
                                          style={{ cursor: "pointer" }}
                                        ></input>
                                      </td>
                                    </tr>
                                  </>
                                ))}


                              </tbody>
                            </table>

                          ) : <p>No Registered Data Found </p>}

                        </div>
                      </Col>
                      <Col md="6">
                        <Row>
                          <div className="table-responsive">
                            <h6>Attended</h6>
                            {attendList?.attendedEvents?.length > 0 ? (
                              <table
                                id="unAssignedStudents"
                                className="table table-bordered mb-0"
                              >
                                <thead>

                                  <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Mobile</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {attendList?.attendedEvents?.map((item, index) => (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>{item?.profile?.firstname || +" " + item?.profile?.lastename || ""}</td>
                                      <td>{item.profile.mobile}</td>

                                      <td>
                                        <i
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            handleUnattend(index)
                                          }}
                                          className="fas fa-window-close"
                                        ></i>
                                      </td>
                                    </tr>

                                  ))}


                                </tbody>
                              </table>

                            ) : <p>No Attended Data Found</p>}

                          </div>

                        </Row>
                        <Row>

                          <div className="mt-4" style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button type="submit" onClick={handlesubmit} color="primary">Submit</Button>
                            {/* <Button type="" color="success">Submit</Button> */}

                          </div>



                        </Row>

                      </Col>
                    </Row>

                    //   </CardBody>
                    // </Card>
                  }

                </Row>
              </AvForm>
            </CardBody>
          </Card>

        </Row>
        <Row>
          <Card>
            <CardBody>
              <MDBDataTable
                id="attendanceTableevent"
                responsive
                bordered
                data={data}
                searching={true}
                info={true}
                disableRetreatAfterSorting={true}
                entries={20}
              />
            </CardBody>
          </Card>
        </Row>



      </div>

    </div>
  )
}

export default Attendace
