import React, { useState, useEffect } from "react"
import { MDBDataTable } from "mdbreact"
import { Card, CardBody, CardTitle, Table } from "reactstrap"
import TablePagination from "../../../components/Common/Pagination"
import { get } from "../../../helpers/api_helper"
import { dateConverter, numberToCurrency } from "../../../helpers/functions"
import { useLocation } from "react-router-dom"

const DetailedReport = () => {
  const [tableData, setTableData] = useState([])
  const [revenueDetails, setRevenueDetails] = useState([])

  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 20

  const { state } = useLocation()

  useEffect(() => {
    handleExpenseTableData()
    handleRevenueTableData()
    // eslint-disable-next-line
  }, [page, state])

  const handleExpenseTableData = async () => {
    try {
      if (!state.branch) return
      const response = await get(
        `income-expense/expense/list?branch=${state.branch}&page=${page}&limit=${limit}`
      )

      const data = response.data
      if (!Array.isArray(data)) return

      data.map((item, index) => {
        item.id = index + 1 + (page - 1) * limit
        item.date = dateConverter(item.date)
        item.staff = `${item.staffFirstName || ""} ${item.staffLastName || ""}`
        item.amount = numberToCurrency(item.amount)
        item.gst = numberToCurrency(item.gst)
        item.total = numberToCurrency(item.total)
        return item
      })
      const count = response.count
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleRevenueTableData = async () => {
    try {
      const response = await get(
        `finance-dashboard/revenue-report?branch=${state.branch}`
      )
      const data = response.data
      data.map((item, index) => {
        item.booked = numberToCurrency(item.booked)
        item.realized = numberToCurrency(item.realized)
        item.pending = numberToCurrency(item.pending)
        return item
      })
      setRevenueDetails(data)
    } catch (err) {}
  }

  const handleChange = (event, value) => setPage(value)

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Detail Expense", field: "subType" },
      { label: "Chart of Account", field: "chartOfAccount" },
      { label: "Fund Source", field: "fundSource" },
      { label: "Amount", field: "amount" },
      { label: "Gst", field: "gst" },
      { label: "Total", field: "total" },
      { label: "Comment", field: "comment" },
      { label: "Added by", field: "staff" },
    ],
    rows: tableData,
  }
  return (
    <div className="page-content">
      <div className="container-fluid" id="finance-detailed">
        {revenueDetails.length > 1 && (
          <Card>
            <CardBody>
              <CardTitle className="h4 mb-4">Revenue Details</CardTitle>
              <div className="table-responsive">
                <Table
                  className="table-centered table-nowrap mb-0"
                  id="revenueDetails"
                >
                  <thead className="table-light">
                    <tr>
                      <th>#</th>
                      <th>Head</th>
                      <th>Booked</th>
                      <th>Realized</th>
                      <th>Pending</th>
                    </tr>
                  </thead>
                  <tbody>
                    {revenueDetails?.map((item, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item.name}</td>
                          <td>{item.booked}</td>
                          <td>{item.realized}</td>
                          <td>{item.pending}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        )}

        {tableData.length > 0 && (
          <Card>
            <CardBody>
              <CardTitle className="h4 mb-4">Expense Details</CardTitle>
              <MDBDataTable
                id="expenseDetailsTable"
                bordered
                responsive
                data={data}
                disableRetreatAfterSorting={true}
                info={false}
                sortable={false}
                searching={false}
                paging={false}
              ></MDBDataTable>
              <TablePagination
                page={page}
                onChange={handleChange}
                count={totalPage}
              />
            </CardBody>
          </Card>
        )}
      </div>
    </div>
  )
}
export default DetailedReport
