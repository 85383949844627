import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import React, { useState, useRef, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import moment from "moment"
import Select from "react-select"
import AsyncSelect from "react-select/async"
import { MDBDataTable } from "mdbreact"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { Modal } from "react-bootstrap"

import TablePagination from "../../../../components/Common/Pagination"
import Tooltip from "@mui/material/Tooltip"
import toastr from "toastr"
import queryString from "query-string"

import { get } from "../../../../helpers/api_helper"
import { getSkillLoanStatus } from "../../../../store/options/actions"

import "./style.scss"
import SkillLoanProcessing from "../Processing"

import { API_URL } from "../../../../config"
import {
  setTitle,
  jsonToExcel,
  dateConverter,
} from "../../../../helpers/functions"
import SearchStudent from "../../../../components/Common/SearchStudents"

const SkillLoanRequest = ({ options }) => {
  setTitle("Skill Loan Requests")
  const initialState = {
    from: "",
    to: "",
    provider: "",
    student: "",
    course: "",
    status: "",
  }

  const toggle = (start = true) => {
    var body = document.body

    if (start) {
      body.classList.remove("vertical-collpsed")
      body.classList.remove("sidebar-enable")
      body.setAttribute("data-sidebar-size", "sm")
    } else {
      body.classList.add("vertical-collpsed")
      body.classList.add("sidebar-enable")
      body.setAttribute("data-sidebar-size", "lg")
    }
  }

  useEffect(() => {
    toggle()

    return () => {
      toggle(false)
    }
    // eslint-disable-next-line
  }, [])

  const [tableData, setTableData] = useState([])

  const [filterObject, setFilterObject] = useState(initialState)
  const [selectedFields, setSelectedFields] = useState({})

  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const limit = 20

  const [modal, setModal] = useState(false)
  const [selectedRequest, setSelectedRequest] = useState(null)

  const formRef = useRef()
  const dispatch = useDispatch()

  // Providers, Student, Course, Status
  const { skillLoanStatus } = useSelector(state => state.Options)

  useEffect(() => {
    dispatch(getSkillLoanStatus())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleStudentOptions({})
    handleSkillLoanOptions()
    handleCourseOptions()
    handleBranchOptions()
    handleTableData()
    // eslint-disable-next-line
  }, [filterObject])

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  const [skillLoans, setSkillLoans] = useState([])
  const [courses, setCourses] = useState([])
  const [branches, setBranches] = useState([])

  const handleStudentOptions = async ({ obj = filterObject, search = "" }) => {
    try {
      // const query = `search=${search}&${queryString.stringify(obj)}`
      // const response = await get(`/skill-loan/options/students?${query}`)
      // if (!search) setStudents(response.data)
      // return response.data
    } catch (error) {
      console.log(error)
    }
  }


  const handleSkillLoanOptions = async (obj = filterObject) => {
    const query = queryString.stringify(obj)

    get(`/skill-loan/options/skill-loan?${query}`).then(res => {
      setSkillLoans(res.data)
    })
  }
  const handleCourseOptions = async (obj = filterObject) => {
    const query = queryString.stringify(obj)

    get(`/skill-loan/options/courses?${query}`).then(res => {
      setCourses(res.data)
    })
  }
  const handleBranchOptions = async (obj = filterObject) => {
    const query = queryString.stringify(obj)
    get(`/skill-loan/options/branches?${query}`).then(res => {
      setBranches(res.data)
    })
  }

  const handleTableData = async (perPage = page) => {
    const query = `page=${perPage}&limit=${limit}&${queryString.stringify(
      filterObject
    )}`
    get(`/skill-loan/request/list?${query}`).then(res => {
      const result = res.data
      const count = res.count
      result.map((item, index) => {
        item.id = index + 1 + (perPage - 1) * limit

        const statusData = item.status
        item.status = (
          <div>
            <span
              className="badge font-size-12 ms-2"
              style={{ backgroundColor: statusData.color }}
            >
              {statusData.name}
            </span>
          </div>
        )

        item.date = moment(item.date).format("DD-MM-YYYY")

        item.amount = item.amount?.toLocaleString("en-IN") || 0
        item.courseFee = item.courseFee?.toLocaleString("en-IN") || 0
        item.approvedAmount = item.approvedAmount?.toLocaleString("en-IN") || 0
        item.student =
          (item.studentFirstName ? item.studentFirstName : "") +
          " " +
          (item?.studentLastName ? item.studentLastName : "")

        item.options = (
          <div>
            {options.edit ? (
              <i
                className="fas fa-eye"
                onClick={() => {
                  setModal(true)
                  setSelectedRequest(item._id)
                }}
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
              ></i>
            ) : (
              ""
            )}
            <Tooltip title="Request letter" arrow placement="top">
              <a
                href={API_URL + item.receiptPath}
                target="_blank"
                rel="noreferrer"
              >
                <i
                  className="fas fa-file-pdf"
                  style={{
                    cursor: "pointer",
                    fontSize: "1.5em",
                    color: "red",
                    marginRight: "0.5rem",
                    marginLeft: "0.5rem",
                  }}
                ></i>
              </a>
            </Tooltip>
          </div>
        )

        return item
      })

      const totalPage = Math.ceil(Number(count) / limit)
      setCount(Number(count))
      setTotalPage(totalPage)
      setTableData(result)
    })
  }

  const handleExport = async limit => {
    try {
      const params = `limit=${limit}&${queryString.stringify(filterObject)}`
      const response = await get(`/skill-loan/request/list?${params}`)
      const data = response.data
      const exportData = []

      if (!data || !Array.isArray(data) || !data.length)
        toastr.info(`There are no skill loan requests to export`)

      data.map((item, index) => {
        const dataExport = {}
        dataExport.Id = index + 1
        dataExport.Date = dateConverter(item.date)
        dataExport["Request Id"] = item.uniqueId
        dataExport.Branch = item.branch
        dataExport.Provider = item.provider
        dataExport.Student = `${item.studentFirstName || ""} ${
          item.studentLastName || ""
        }`
        dataExport["Register No"] = item.studentUniqueId
        dataExport.Course = item.course
        dataExport.CourseFee = item.courseFee || 0
        dataExport["Requested Amount"] = item.amount
        dataExport["Approved Amount"] = item.approvedAmount
        dataExport.Status = item.status?.name || ""

        exportData.push(dataExport)
        return item
      })
      jsonToExcel(
        exportData,
        `Skill Loan Requests -${moment().format("DD-MM-YY")}`
      )
    } catch (error) {
      console.log(error.message)
      toastr.info(`There are no skill loan requests to export`)
    }
  }

  const handleSelectChange = (selected, name) => {
    const obj = { ...selectedFields }
    obj[name] = selected
    setSelectedFields(obj)

    const value = selected
      ? Array.isArray(selected)
        ? selected.map(item => item.value)
        : selected.value
      : null
    handleValueChange(value, name)
  }

  const handleValueChange = async (value, name) => {
    const obj = { ...filterObject }
    obj[name] = value
    setFilterObject(obj)
    setPage(1)
  }

  const handleChange = (event, value) => {
    setPage(value)
    handleTableData(value)
  }
  const reset = () => {
    formRef.current.reset()
    handleTableData()
    setFilterObject(initialState)
    setSelectedFields({})
    toTop()
    setCourses([])
    setPage(1)
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Req. ID", field: "uniqueId" },
      { label: "Branch", field: "branch" },
      { label: "Provider ", field: "provider" },
      { label: "Student", field: "student" },
      { label: "Register No", field: "studentUniqueId" },
      { label: "Course", field: "course" },
      { label: "Course Fee", field: "courseFee" },
      { label: "Requested Amount", field: "amount" },
      { label: "Approved Amount", field: "approvedAmount" },
      { label: "Status", field: "status" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Skill Loan"
            breadcrumbItem="Skill Loan Requests"
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <AvForm ref={formRef}>
                    <Row>
                      <Col md={2}>
                        <div className="mb-3">
                          <Label>From </Label>
                          <AvField
                            name="from"
                            className="form-control"
                            type="date"
                            value={filterObject.from}
                            onChange={e => {
                              handleValueChange(e.target.value, "from")
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="mb-3">
                          <Label>To </Label>
                          <AvField
                            name="to"
                            className="form-control"
                            type="date"
                            value={filterObject.to}
                            onChange={e => {
                              handleValueChange(e.target.value, "to")
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <Label>Skill Loan Provider</Label>
                        <Select
                          name="provider"
                          placeholder="Select..."
                          options={skillLoans}
                          value={selectedFields.provider || ""}
                          onChange={selected => {
                            handleSelectChange(selected, "provider")
                          }}
                        />
                      </Col>

                      <Col md={3}>
                        <Label>Student</Label>

                        {/* <AsyncSelect
                          isMulti
                          isClearable
                          cacheOptions
                          loadOptions={loadOptions}
                          defaultOptions={students}
                          onChange={selected => {
                            handleSelectChange(selected, "student")
                          }}
                          value={selectedFields.student || ""}
                        /> */}
                        <SearchStudent
                          api={`/skill-loan/options/students`}
                          onChange={selected => {
                            console.log("On change", selected)
                            handleSelectChange(selected, "student")
                          }}
                          value={selectedFields.student}
                          master={filterObject}
                        />
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label>Course</Label>
                          <Select
                            name="course"
                            placeholder="Select..."
                            options={courses}
                            value={selectedFields.course || ""}
                            onChange={selected => {
                              handleSelectChange(selected, "course")
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <Label>Status</Label>
                        <Select
                          name="status"
                          placeholder="Select..."
                          options={skillLoanStatus}
                          value={selectedFields.status || ""}
                          onChange={selected => {
                            handleSelectChange(selected, "status")
                          }}
                        />
                      </Col>
                      <Col md={2}>
                        <Label>Branch</Label>
                        <Select
                          name="branch"
                          placeholder="Select..."
                          options={branches}
                          value={selectedFields.branch || ""}
                          onChange={selected => {
                            handleSelectChange(selected, "branch")
                          }}
                        />
                      </Col>
                      <Col md={2} style={{ marginTop: "26px" }}>
                        <Button color="danger" onClick={reset}>
                          Reset
                        </Button>
                        {options.export ? (
                          <Button
                            style={{ marginLeft: "14px" }}
                            color="warning"
                            type="button"
                            onClick={() => handleExport(count)}
                          >
                            Export
                          </Button>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </AvForm>
                  <MDBDataTable
                    id="skillLoanRequest"
                    className="mt-3"
                    bordered
                    responsive
                    searching={false}
                    entries={20}
                    paging={false}
                    info={false}
                    sortable={false}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>
                  <TablePagination
                    page={page}
                    onChange={handleChange}
                    count={totalPage}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Modal show={modal} size="xl" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Skill Loan Details</h5>
          <button
            type="button"
            onClick={() => {
              setModal(false)
              handleTableData()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <SkillLoanProcessing id={selectedRequest} />
        </div>
      </Modal>
    </>
  )
}

export default SkillLoanRequest
