import React, { Suspense, useEffect, useState } from "react"
import { Navigate } from "react-router-dom"
import Cookies from "js-cookie"
import { post } from "../../helpers/api_helper"
import { startTransition } from "react"
import { decrypt, encrypt } from "../../helpers/functions"
// import { useDispatch } from "react-redux"
// import { accessToken } from "../../store/actions"
// import { accessToken, logoutUser } from "../../store/actions"

const Authmiddleware = props => {
  const [allowed, setAllowed] = useState(null)
  const [options, setOptions] = useState({})

  // const dispatch = useDispatch()

  useEffect(() => {
    // setAllowed(null)
    // setOptions({})
    // if (!Cookies.get("token")) {
    //   console.log("not exi")

    //   if (Cookies.get("_token")) {
    //     dispatch(accessToken())
    //   }
    // }

    const checkPrivilege = async () => {
      startTransition(async () => {
        try {
          if (Cookies.get("_token")) {
            let response = await post("auth/allowed", {
              data: encrypt(
                JSON.stringify({
                  module_url: props.path,
                  module_id: sessionStorage.getItem("module_id"),
                })
              ),
            })

            response = JSON.parse(JSON.parse(decrypt(response.data)))

            if (response && response.allowed) {
              setAllowed(true)
              setOptions(response.options || {})
            } else {
              setAllowed(false)
            }
          }
        } catch (error) {
          console.error("Error checking privileges:", error)
          setAllowed(false)
        }
      })
    }

    if (Cookies.get("token")) {
      checkPrivilege()
    }

    if (!Cookies.get("token") && Cookies.get("_token")) {
      setTimeout(() => {
        checkPrivilege()
      }, 500)
    }
  }, [props.path])

  if (!Cookies.get("_token")) {
    return <Navigate to="/login" />
  }

  if (allowed === false) {
    return (
      <Navigate
        to={JSON.parse(localStorage.getItem("authUser")).modules.redirect_url}
      />
    )
  }
  if (allowed === null) {
    return <></>
  }

  return (
    <React.Fragment>
      <Suspense>{React.cloneElement(props.children, { options })}</Suspense>
    </React.Fragment>
  )
}

export default Authmiddleware
