import React, { useState } from "react"
import NonSkilling from "./BusinessHeadNonSkilling/NonSkilling"
import Skilling from "./BusinessHeadSkilling/Skilling"

import { Row } from "reactstrap"
const EventBoard = () => {

  return (
    <div>
     
      <Skilling />
      <NonSkilling />
    </div>
  )
}

export default EventBoard
