import React, { createContext, useContext, useState } from "react"

// Create context
const BatchContext = createContext()

// Custom provider component
const BatchProvider = ({ value, children }) => {
  const [contextState, setContextState] = useState({})
  return (
    <BatchContext.Provider
      value={{ ...value, contextState, setContextState }}
    >
      {children}
    </BatchContext.Provider>
  )
}

const useBatch = () => useContext(BatchContext)

export { BatchProvider, useBatch }
