import React, { useEffect, useRef, useState } from "react"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, Label, Button } from "reactstrap"
import Select from "react-select"
import { AvField, AvForm } from "availity-reactstrap-validation"
import "./style.scss"
import { useNavigate } from "react-router-dom"
import { get, post, put } from "../../../helpers/api_helper"
import toastr from "toastr"
import { API_URL } from "../../../config"
import TablePagination from "../../../components/Common/Pagination"
import Swal from "sweetalert2"
import moment from "moment"
import SelectErrorMessage from "../../../components/Common/SelectErrorMessage"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import useBranch from "../../../hooks/useBranch"

function CreateAsests() {
  const myCsp = useBranch()
  const [myCspId, setMyCspId] = useState("")
  const [csp, setCsp] = useState("")
  const [cspSelection, setCspSelection] = useState(null)
  const [name, setName] = useState("")
  const [purchaseOrder, setPurchaseOrder] = useState("")
  const [invoiceOrder, setInvoiceOrder] = useState("")
  const [image, setImage] = useState("")
  const [quantity, setQuantity] = useState("")
  const [warranty, setWarranty] = useState("")
  const [branches, setBranches] = useState([])
  const [allAsset, setAllAsset] = useState([])
  const [isEdit, setIsedit] = useState(false)
  const [editId, setEditId] = useState("")
  const [state, setState] = useState(false)
  const formRef = useRef()
  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 20
  const [selectedType, setSelectedType] = useState(null)
  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [subCategories, setSubCategories] = useState([])
  const [selectedSubCategory, setSelectedSubCategory] = useState(null)
  const [purchaseDate, setpurchaseDate] = useState("")
  const [warrantyEndDate, setWarrantyEndDate] = useState("")
  const [amc, setAmc] = useState(false)
  const [amcStartDate, setAmcStartDate] = useState("")
  const [amcEndDate, setAmcEndDate] = useState("")
  const filterInitial = {
    csp: null,
    type: null,
    category: null,
    subCategory: null,
    status: null,
  }
  const [filterObject, setFilterObject] = useState(filterInitial)

  const [serialNumber, setSerialNumber] = useState([])
  const [SerialErr, setSerialErr] = useState([])
  const [alreadyExistIndices, setAlreadyExistIndices] = useState([]); 

  const [subCategoriesFilter, setSubCategoriesFilter] = useState([])
  const [serialNumberUpdate,setSerialNumberUpdate] = useState("")

  const initialErr = {
    type: false,
    category: false,
    subCategory: false,
    purchase: false,
    warranty: false,
    amcStart: false,
    amcEnd: false,
    csp: false,
  }
  const [err, setErr] = useState(initialErr)

  useEffect(() => {
    let cspId = myCsp ? myCsp?.[0]?.value : ""
    setMyCspId(cspId)
    setCsp(cspId)
  }, [state])

  useEffect(() => {
    csps()
  }, [])

  useEffect(() => {
    getAsset()
  }, [state, page, filterObject, cspSelection])

  const csps = async () => {
    try {
      const response = await get("/options/branches")
      setBranches(response.branches)
    } catch (error) {}
  }

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const res = await get("/options/asset/category")
        setCategories(res.data)
      } catch (error) {}
    }
    fetchCategory()
  }, [])

  useEffect(() => {
    const fetchSubCategory = async () => {
      try {
        const res = await get(
          `/options/asset/sub/category?category=${selectedCategory?.value}`
        )
        setSubCategories(res.data)
      } catch (error) {}
    }
    if (selectedCategory && selectedCategory?.value) fetchSubCategory()
  }, [selectedCategory])

  useEffect(() => {
    const fetchSubCategory = async () => {
      try {
        const res = await get(
          `/options/asset/sub/category?category=${filterObject?.category?.value}`
        )
        setSubCategoriesFilter(res.data)
      } catch (error) {}
    }
    if (filterObject?.category && filterObject?.category?.value)
      fetchSubCategory()
  }, [filterObject?.category])

  const navigate = useNavigate()

  const isValid = value => {
    if (value != "" && value != undefined && value != null ) {
      return true
    } else {
      return false
    }
  }



  function findDuplicateIndices(arr) {
    const map = new Map() 
    const duplicateIndices = []

    arr.forEach((item, index) => {
      if (map.has(item)) {
        const existingIndices = map.get(item)
        existingIndices.push(index) 
        map.set(item, existingIndices)
      } else {
        map.set(item, [index]) 
      }
    })

    map.forEach(indices => {
      if (indices.length > 1) {
        duplicateIndices.push(...indices) 
      }
    })

    setSerialErr(duplicateIndices)
    return duplicateIndices
      .sort((a, b) => a - b) 
      .map(index => index + 1) 
      .join(", ") 
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (!myCspId && !isValid(cspSelection )) {
      return setErr({ ...err, csp: true })
    }
    if (!isValid(selectedType)) {
      return setErr({ ...err, type: true })
    }
    if (!isValid(selectedCategory)) {
      return setErr({ ...err, category: true })
    }
    if (!isValid(selectedSubCategory)) {
      return setErr({ ...err, subCategory: true })
    }
    if (!isValid(purchaseDate)) {
      return setErr({ ...err, purchase: true })
    }
    if (!isValid(warrantyEndDate)) {
      return setErr({ ...err, warranty: true })
    }
    if(invoiceOrder.trim() === "" || name.trim() === ""){
      return
    }

    if (amc && !isValid(amcStartDate)) {
      return setErr({ ...err, amcStart: true })
    }
    if (amc && !isValid(amcEndDate)) {
      return setErr({ ...err, amcEnd: true })
    }

    let userId
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser")
      var user_obj = JSON.parse(data)
      userId = user_obj._id
    }

    e.preventDefault()
    try {
      if (isEdit) {
        if (serialNumberUpdate.trim() === "") return;

        const response = await put(`/asset/asset-update?id=${editId}`, {
          csp,
          serialNumber:serialNumberUpdate.trim(),
          type: selectedType?.value,
          category: selectedCategory?.value,
          subCategory: selectedSubCategory?.value,
          purchaseDate,
          warrantyEndDate,
          amc,
          amcStartDate,
          amcEndDate,
          name,
          purchaseOrder,
          invoiceOrder:invoiceOrder.trim(),
          quantity,
          warranty,
          addedBy: userId,
          image,
        })
        toastr.success(response.message)
      } else {
      
        setAlreadyExistIndices([])
        if(quantity < 1) return 

        if (serialNumber.length != quantity)
          return toastr.error("Please provide Serial Number for Asset")
    
        if (serialNumber.length < 1)
          return toastr.error("Please provide Serial Number for Asset")
        if (
          serialNumber.some(
            val =>
              val === "" || val === undefined || val === null || val.trim() === ""
          )
        ) {
          return toastr.error("Please provide Serial Number for Assets")
        }
    
    
        const allDuplicateIndices = findDuplicateIndices(serialNumber)
    
        if (allDuplicateIndices.length > 0) {
          return toastr.error(
            `Serial Number ${allDuplicateIndices} are Duplicate please provide Unique`
          )
        }


        const response = await post("/asset/asset-create", {
          csp,
          type: selectedType?.value,
          category: selectedCategory?.value,
          subCategory: selectedSubCategory?.value,
          purchaseDate,
          warrantyEndDate,
          amc,
          amcStartDate,
          amcEndDate,
          name,
          purchaseOrder,
          invoiceOrder,
          quantity,
          warranty,
          addedBy: userId,
          image,
          serialNum: serialNumber,
        })

        toastr.success(response.message)
      }
      reset()
    } catch (error) {
      if(error?.response?.data?.message){
        toastr.error(error?.response?.data?.message)
      }
      if(error?.response?.data?.serial){
        setAlreadyExistIndices(error?.response?.data?.serial)
      }

    }
  }

  const reset = () => {
    formRef.current.reset()
    setSerialNumberUpdate("")
    setCsp("")
    setName("")
    setPurchaseOrder("")
    setInvoiceOrder("")
    setQuantity("")
    setWarranty("")
    setCspSelection(null)
    setIsedit(false)
    setState(!state)
    setEditId("")
    setImage("")
    setSelectedType(null)
    setSelectedCategory(null)
    setSelectedSubCategory(null)
    setpurchaseDate("")
    setWarrantyEndDate("")
    setAmcStartDate("")
    setAmcEndDate("")
    setAmc(false)
    setErr(initialErr)
    setSerialNumber([])
    setSerialErr([])
    setAlreadyExistIndices([])
  }

  const resetFilter = () => {
    setFilterObject(filterInitial)
    setState(!state)
  }

  function deleteAsset(id) {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to delete this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        const response = put(`/asset/asset-delete?id=${id}`)
          .then(res => {
            toastr.success(res.message)
            setState(!state)
          })
          .catch(err => {
            if (err.response.data.message) {
              toastr.error(err.response.data.message)
            }
          })
      }
    })
  }

  const getAsset = async () => {
    try {
      const filterQuery = `page=${page}&limit=${limit}&csp=${filterObject?.csp?.value}&type=${filterObject?.type?.value}&category=${filterObject?.category?.value}&subCategory=${filterObject?.subCategory?.value}&status=${filterObject?.status?.value}`
      const response = await get(`/asset/asset?${filterQuery}`)
      setAllAsset(response.data)
      const count = response?.count
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
    } catch (error) {
      if (error?.response?.data?.message) {
        toastr.success(error?.response?.data?.message)
      }
    }
  }

  const handleChange = (event, value) => {
    setPage(value)
  }

  const statusMap = {
    0: { label: "IT", value: 0 },
    1: { label: "NON IT", value: 1 },
  }

  const handleUpdation = item => {
    setCsp(item?.csp)
    let branch = {
      label: item?.initialOwnerDetails[0]?.name,
      value: item?.initialOwnerDetails[0]?._id,
    }
    // const csps = branches.filter(items => items.value == item.csp)
    setCspSelection(branch)
    setSelectedType(statusMap[item?.type])
    setSelectedCategory(item?.category)
    setSelectedSubCategory(item?.subCategory)
    setpurchaseDate(item?.purchaseDate)
    setWarrantyEndDate(item?.warrantyEndDate)
    setAmc(item?.amc)
    setAmcStartDate(item?.amcStartDate)
    setAmcEndDate(item?.amcEndDate)
    setName(item?.name)
    setPurchaseOrder(item?.purchaseOrder)
    setInvoiceOrder(item?.invoiceOrder)
    setSerialNumberUpdate(item?.serialNumber)
    // setQuantity(item?.quantity)
    setWarranty(item?.warranty)
    setEditId(item?._id)
    setImage(item?.image)
    setIsedit(true)
    toTop()
  }

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }


  const uploadImage = async e => {
    const selectedFile = e.target.files[0]

    if (selectedFile) {
      try {
        const validExtensions = [
          "image/jpeg",
          "image/png",
          "image/gif",
          "video/mp4",
          "application/pdf",
        ]
        const isValidExtension = validExtensions.includes(selectedFile.type)

        if (!isValidExtension) {
          return toastr.error(
            "Invalid file type. Please select an image (JPEG/PNG/GIF), video (MP4), or PDF."
          )
        }

        const fd = new FormData()
        fd.append("file", selectedFile)

        const response = await post(`/asset/image`, fd)
        if (response.status === 200) {
          setImage(response.data.new_filename)
        } else {
          toastr.error(response.data.message)
        }
      } catch (error) {
        toastr.error("An error occurred while uploading the file.")
        e.target.value = ""
      }
    }
  }

  const typeMap = {
    0: "IT",
    1: "NON IT",
  }

  const data = {
    columns: [
      { label: "#", field: "id", width: 10 },
      { label: "Date", field: "date", width: 150 },
      { label: "Serial No", field: "serialNumber", width: 150 },
      { label: "Asset", field: "name", width: 150 },
      { label: "CSP", field: "csp", width: 150 },
      { label: "Type", field: "type", width: 150 },
      { label: "Category", field: "category", width: 150 },
      { label: "Sub Category", field: "subCategory", width: 150 },
      // { label: "Invoice No.", field: "invoiceOrder", width: 150 },
      { label: "Added By", field: "assigned", width: 150 },
      { label: "Status", field: "status", width: 150 },
      { label: "Action", field: "action", width: 150 },
    ],

    rows: allAsset.map((item, index) => ({
      id: index + 1 + (page - 1) * limit,
      date: moment(item.date).format("DD-MM-YYYY"),
      name: item.name,
      csp: item.branch.name,
      type: typeMap[item?.type],
      category: item?.category?.label,
      subCategory: item?.subCategory?.label,
      purchaseDate: moment(item?.purchaseDate).format("DD-MM-YYYY"),
      warrantyEndDate: moment(item?.warrantyEndDate).format("DD-MM-YYYY"),
      serialNumber: item.serialNumber,
      purchaseOrder: item.purchaseOrder,
      invoiceOrder: item.invoiceOrder,
      assigned: item.user.firstName,
      status: (
        <strong
          className={
            item?.initialOwner == item?.owner[0]._id
              ? "text-success"
              : "text-primary"
          }
        >
          {item?.initialOwner == item?.owner[0]._id ? "Initiator" : "Issued"}
        </strong>
      ),

      action: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            className="fa fa-eye"
            style={{
              cursor: "pointer",
              fontSize: "1em",
            }}
            onClick={() => {
              navigate(`/asset-creation/${item?._id}`)
            }}
          ></i>
          <i
            className="far fa-edit"
            style={{
              cursor: "pointer",
              fontSize: "1em",
              marginRight: "0.5rem",
              marginLeft: "0.5rem",
            }}
            onClick={() => handleUpdation(item)}
          ></i>
          <i
            className="far fa-trash-alt"
            style={{ cursor: "pointer", fontSize: "1em" }}
            onClick={() => deleteAsset(item?._id)}
          ></i>
          {item?.image ? (
            <>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip
                    id="button-tooltip-2"
                    style={{ position: "absolute", top: "-30px" }}
                  >
                    {"View Attachment"}
                  </Tooltip>
                }
              >
                <a
                  href={API_URL + item?.image}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    className="fa fa-file"
                    style={{
                      cursor: "pointer",
                      fontSize: "1.5em",
                      color: "gray",
                      marginRight: "0.5rem",
                      marginLeft: "0.5rem",
                    }}
                  ></i>
                </a>
              </OverlayTrigger>
            </>
          ) : (
            ""
          )}
        </div>
      ),
    })),
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem="Create Assets" />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="needs-validation"
                    onSubmit={handleSubmit}
                    ref={formRef}
                  >
                    <Row>
                      {(isEdit || !myCspId) && (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>CSP</Label>
                            <Select
                              name="csp"
                              classNamePrefix="select2-selection"
                              options={branches}
                              onChange={item => {
                                setCsp(item.value)
                                setCspSelection(item)
                                setErr({ ...err, csp: false })
                              }}
                              value={cspSelection}
                            />

                            <SelectErrorMessage
                              customStyle={{
                                width: "100%",
                                fontSize: "87.5%",
                                color: "var(--bs-form-invalid-color)",
                              }}
                              message="Select Csp"
                              show={err?.csp && !cspSelection}
                            />
                          </div>
                        </Col>
                      )}
                      <Col md="3">
                        <Label for={`type`}>Type</Label>
                        <Select
                          name="type"
                          classNamePrefix="select2-selection"
                          options={[
                            { label: "IT", value: 0 },
                            { label: "NON IT", value: 1 },
                          ]}
                          onChange={selected => {
                            setSelectedType(selected)
                            setErr({ ...err, type: false })
                          }}
                          value={selectedType}
                        />
                        <SelectErrorMessage
                          customStyle={{
                            width: "100%",
                            fontSize: "87.5%",
                            color: "var(--bs-form-invalid-color)",
                          }}
                          message="Select Type"
                          show={err?.type}
                        />
                      </Col>

                      <Col md={3}>
                        <Label for={`category`}>Category</Label>
                        <Select
                          name="category"
                          classNamePrefix="select2-selection"
                          options={categories}
                          onChange={selected => {
                            setSelectedCategory(selected)
                            setErr({ ...err, category: false })
                          }}
                          value={selectedCategory}
                        />
                        <SelectErrorMessage
                          customStyle={{
                            width: "100%",
                            fontSize: "87.5%",
                            color: "var(--bs-form-invalid-color)",
                          }}
                          message="Select Category"
                          show={err.category}
                        />
                      </Col>
                      {selectedCategory && (
                        <Col md={3}>
                          <Label for={`category`}>Sub Category</Label>
                          <Select
                            name="category"
                            classNamePrefix="select2-selection"
                            options={subCategories}
                            onChange={selected => {
                              setSelectedSubCategory(selected)
                              setErr({ ...err, subCategory: false })
                            }}
                            value={selectedSubCategory}
                          />
                          <SelectErrorMessage
                            customStyle={{
                              width: "100%",
                              fontSize: "87.5%",
                              color: "var(--bs-form-invalid-color)",
                            }}
                            message="Select Sub Category"
                            show={err.subCategory}
                          />
                        </Col>
                      )}

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Asset Name</Label>
                          <AvField
                            name="name"
                            type="text"
                            placeholder="Asset Name"
                            className="form-control"
                            onChange={e => setName(e.target.value)}
                            value={name}
                            validate={{ required: { value: true } }}
                            errorMessage="Please provide Asset name "
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <Label>Date of Purchase</Label>
                        <AvField
                          name="date"
                          type="date"
                          onChange={e => {
                            setpurchaseDate(e.target.value)
                            setErr({ ...err, purchase: false })
                          }}
                          value={purchaseDate}
                          max={warrantyEndDate}
                        />
                        <p
                          style={{
                            color: "red",
                            fontSize: "0.8em",
                            opacity: 0.7,
                            marginTop: "-10px",
                          }}
                        >
                          {err.purchase && "Select Date of Purchase"}
                        </p>
                      </Col>

                      <Col md="2">
                        <Label>Warranty End Date</Label>
                        <AvField
                          name="date2"
                          type="date"
                          onChange={e => {
                            setWarrantyEndDate(e.target.value)
                            setErr({ ...err, warranty: false })
                          }}
                          value={warrantyEndDate}
                          min={purchaseDate}
                        />

                        <p
                          style={{
                            color: "red",
                            fontSize: "0.8em",
                            opacity: 0.7,
                            marginTop: "-10px",
                          }}
                        >
                          {err.warranty && "Select Warranty End Date"}
                        </p>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Invoice Number</Label>
                          <AvField
                            name="invoice_order"
                            placeholder="Invoice Number"
                            className="form-control"
                            onChange={e => setInvoiceOrder(e.target.value)}
                            value={invoiceOrder || ""}
                            validate={{ required: { value: true } }}
                            errorMessage="Please provide Invoice Number "
                            id="validationCustom01"
                            type="text"
                          />
                        </div>
                      </Col>

                      <Col md="1" style={{ marginRight: "1.5rem" }}>
                        <div className="mb-3 ">
                          <Label
                            style={{ paddingRight: "15px", display: "block" }}
                          >
                            AMC
                          </Label>

                          <div
                            className="btn-group btn-group-sm"
                            role="group"
                            aria-label="Basic example"
                          >
                            <input
                              type="radio"
                              className="btn-check"
                              name="amc"
                              value={1}
                              id="amc-yes"
                              onClick={() => setAmc(true)}
                              checked={amc === true}
                            />
                            <label
                              style={{
                                padding: "7px 17.6px",
                                zIndex: "0",
                                fontSize: "11px",
                              }}
                              className="btn btn-outline-primary"
                              htmlFor="amc-yes"
                            >
                              Yes
                            </label>
                            <input
                              type="radio"
                              name="amc"
                              className="btn-check"
                              value={0}
                              id="amc-no"
                              onClick={() => setAmc(false)}
                              checked={amc === false}
                            />

                            <label
                              style={{
                                padding: "7px 17.6px",
                                zIndex: "0",
                                fontSize: "11px",
                              }}
                              className="btn btn-outline-primary"
                              htmlFor="amc-no"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </Col>

                      {amc && (
                        <>
                          <Col md="2">
                            <Label>AMC Start Date</Label>
                            <AvField
                              name="date3"
                              type="date"
                              onChange={e => {
                                setAmcStartDate(e.target.value)
                                setErr({ ...err, amcStart: false })
                              }}
                              value={amcStartDate}
                              // validate={max: {}}
                              max={amcEndDate}
                            />

                            <p
                              style={{
                                color: "red",
                                fontSize: "0.8em",
                                opacity: 0.7,
                                marginTop: "-10px",
                              }}
                            >
                              {err.amcStart && "Select AMC Start Date"}
                            </p>
                          </Col>

                          <Col md="2">
                            <Label>AMC End Date</Label>
                            <AvField
                              name="date4"
                              type="date"
                              onChange={e => {
                                setAmcEndDate(e.target.value)
                                setErr({ ...err, amcEnd: false })
                              }}
                              value={amcEndDate}
                              min={amcStartDate}
                            />

                            <p
                              style={{
                                color: "red",
                                fontSize: "0.8em",
                                opacity: 0.7,
                                marginTop: "-10px",
                              }}
                            >
                              {err.amcEnd && "Select AMC End Date"}
                            </p>
                          </Col>
                        </>
                      )}

                      <Col md={image ? "2" : "3"}>
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Attachment File
                          </Label>
                          {image ? (
                            <div div className="img-wraps d-flex flex-column">
                              <object
                                type="application/pdf"
                                alt=""
                                width="150"
                                height="150"
                                data={`${API_URL}${image}`}
                              />
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={() => setImage("")}
                                style={{ width: "150px" }}
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <input
                              name="image"
                              type="file"
                              className="form-control"
                              id="validationCustom03"
                              onChange={uploadImage}
                              value={image}
                            />
                          )}
                        </div>
                      </Col>

                      {!isEdit && (
                        <Col md="2">
                          <div className="mb-3">
                            <Label>Quantity</Label>
                            <AvField
                              name="quantity"
                              type="text"
                              placeholder="Quantity"
                              className="form-control"
                              onChange={e => {
                                const newQuantity = e.target.value
                                setQuantity(newQuantity)
                                const newSerialNumbers = Array.from(
                                  { length: newQuantity },
                                  () => ""
                                )
                                setSerialNumber(newSerialNumbers)
                              }}
                              value={quantity}
                              min="1"
                              max="100"
                              onInput={e => {
                                const value = e.target.value
                                if (value < 1) {
                                  e.target.value = ""
                                  setQuantity("")
                                } else if (value > 100) {
                                  e.target.value = 100
                                  setQuantity(100)
                                }
                              }}
                              validate={{
                                required: { value: true },
                                pattern: {
                                  value: /^\d+$/,
                                  errorMessage: "Please enter a valid quantity",
                                },
                              }}
                              errorMessage="Please provide Quantity between 1 and 100"
                            />
                          </div>
                        </Col>
                      )}

                      {!isEdit && quantity > 0 &&
                        Array.from({ length: quantity }).map((_, i) => (
                          <Col md="2" key={i}>
                            <div className="mb-3">
                              <Label>Serial Number {i + 1}</Label>
                              <AvField
                                name={`serialNumber-${i}`}
                                type="text"
                                placeholder="Serial Number"
                                className="form-control"
                                onChange={e => {
                                  const newSerialNumbers = [...serialNumber]
                                  newSerialNumbers[i] = e.target.value
                                  setSerialNumber(newSerialNumbers)
                                }}
                                value={serialNumber[i] || ""}
                                validate={{ required: { value: true } }}
                                errorMessage="Please provide Serial Number"
                              />
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "0.8em",
                                  opacity: 0.7,
                                  marginTop: "-10px",
                                }}
                              >

                                {SerialErr.length > 0 && SerialErr.includes(i) && "Please provide a Unique Serial Number"}
                                {alreadyExistIndices.length > 0 && alreadyExistIndices.includes(i) && "Serial Number already exists"}
                              </p>
                            </div>
                          </Col>
                        ))}

                                  {isEdit &&
                            <Col md="2" >
                            <div className="mb-3">
                              <Label>Serial Number</Label>
                              <AvField
                                name={`serialNumber`}
                                type="text"
                                placeholder="Serial Number"
                                className="form-control"
                                onChange={e => {
                                  setSerialNumberUpdate(e.target.value)
                                }}
                                value={serialNumberUpdate|| ""}
                                validate={{ required: { value: true } }}
                               errorMessage="Please provide Serial Number"
                              />
                             
                            </div>
                          </Col>}

                      <Col md="3" style={{ marginTop: "1.7rem" }}>
                        <Button
                          color={`${isEdit ? "warning" : "primary"}`}
                          type="submit"
                        >
                          {isEdit ? "Update" : "Submit"}
                        </Button>
                        <Button
                          color="danger"
                          style={{ marginLeft: "15px" }}
                          onClick={reset}
                        >
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      {!myCspId && (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>CSP</Label>
                            <Select
                              name="csp"
                              classNamePrefix="select2-selection"
                              options={branches}
                              onChange={selected => {
                                setFilterObject({
                                  ...filterObject,
                                  csp: selected,
                                })
                              }}
                              value={filterObject?.csp}
                            />
                          </div>
                        </Col>
                      )}
                      <Col md="2">
                        <Label for={`type`}>Type</Label>
                        <Select
                          name="type"
                          classNamePrefix="select2-selection"
                          options={[
                            { label: "IT", value: 0 },
                            { label: "NON IT", value: 1 },
                          ]}
                          onChange={selected => {
                            setFilterObject({ ...filterObject, type: selected })
                          }}
                          value={filterObject?.type}
                        />
                      </Col>

                      <Col md={2}>
                        <Label for={`category`}>Category</Label>
                        <Select
                          name="category"
                          classNamePrefix="select2-selection"
                          options={categories}
                          onChange={selected => {
                            setFilterObject({
                              ...filterObject,
                              category: selected,
                            })
                          }}
                          value={filterObject?.category}
                        />
                      </Col>

                      {filterObject?.category?.value && (
                        <Col md={2}>
                          <Label for={`category`}>Sub Category</Label>
                          <Select
                            name="category"
                            classNamePrefix="select2-selection"
                            options={subCategoriesFilter}
                            onChange={selected => {
                              setFilterObject({
                                ...filterObject,
                                subCategory: selected,
                              })
                            }}
                            value={filterObject?.subCategory}
                          />
                        </Col>
                      )}

                      <Col md={2}>
                        <Label for={`category`}>Status</Label>
                        <Select
                          name="category"
                          classNamePrefix="select2-selection"
                          options={[
                            { label: "Initiator", value: 0 },
                            { label: "Issued", value: 1 },
                          ]}
                          onChange={selected => {
                            setFilterObject({
                              ...filterObject,
                              status: selected,
                            })
                          }}
                          value={filterObject?.status}
                        />
                      </Col>
                      <Col md="1" style={{ marginTop: "1.7rem" }}>
                        <Button color="danger" onClick={resetFilter}>
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>

                  <MDBDataTable
                    id="allocationTableIdd"
                    responsive
                    bordered
                    info={true}
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                    paginationLabel={false}
                    paging={false}
                  />
                  <TablePagination
                    page={page}
                    onChange={handleChange}
                    count={totalPage}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default CreateAsests
