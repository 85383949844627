import React, { useEffect, useState } from "react"
import { Badge } from "reactstrap"
import { get } from "../../../../helpers/api_helper"
import { dateConverter, timeConverter } from "../../../../helpers/functions"

export default function PaymentDetails({ id }) {
  const [modalData, setModalData] = useState({})
  useEffect(() => {
    if (id) {
      handlePopUp(id)
    }
  }, [id])
  const handlePopUp = id => {
    get(`fee/details?id=${id}`).then(res => {
      const result = res.data
      result.name = (result?.firstName ?? "") + " " + (result?.lastName ?? "")
      result.mode =
        result.type === 1
          ? "Cash"
          : result.type === 2
          ? "Skill loan"
          : result.type === 3
          ? "Scholarship"
          : result.type === 4
          ? "Online"
          : ""
      result.status = (
        <Badge
          style={{
            padding: "0.25rem",
            color: "white",
          }}
          color={
            result.status === 0
              ? "success"
              : result.status === 1
              ? "danger"
              : "warning"
          }
        >
          {result.status === 0
            ? "Success"
            : result.status === 1
            ? "Rejected"
            : "Pending"}
        </Badge>
      )
      result.paid = result.amount

      result.paymentId = result.orderId.paymentId
      result.orderId = result.orderId.orderId

      result.added =
        (result.addedFirstName ?? "") + " " + (result.addedLastName ?? "")

      result.date = dateConverter(result.date)
      result.time = timeConverter(result.time)
      result.addedDate = dateConverter(result.addedDate)
      result.addedTime = timeConverter(result.addedTime)

      setModalData(result)
    })
  }
  return (
    <div className="modal-body">
      <table id="feeDetailsTable" className="table table-bordered dataTable">
        <tr>
          <td style={{ width: "150px", padding: "10px" }}>Name</td>
          <td>: {modalData?.name}</td>
          <td style={{ width: "150px" }}>Branch</td>
          <td style={{ textAlign: "left" }}>: {modalData?.branch}</td>
        </tr>
        <tr>
          <td style={{ width: "150px", padding: "10px" }}>Course/ Event :</td>
          <td>: {modalData?.course}</td>
          <td style={{ width: "150px" }}>Receipt No</td>
          <td style={{ textAlign: "left" }}>: {modalData?.uniqueId}</td>
        </tr>
        <tr>
          <td style={{ width: "150px" }}>Fee</td>
          <td style={{ textAlign: "left" }}>: {modalData?.courseFee}</td>
          <td style={{ width: "150px" }}>Due</td>
          <td style={{ textAlign: "left" }}>: {modalData?.due}</td>
        </tr>
        <tr>
          <td style={{ width: "150px", padding: "10px" }}>Paid</td>
          <td>: {modalData?.paid}</td>
          <td style={{ width: "150px", padding: "10px" }}>Type</td>
          <td style={{ textAlign: "left" }}>: {modalData?.mode}</td>
        </tr>
        <tr>
          <td style={{ width: "150px", padding: "10px" }}>Status</td>
          <td>: {modalData?.status}</td>
          <td style={{ width: "150px" }}>Comments</td>
          <td style={{ textAlign: "left" }}>: {modalData?.comments}</td>
        </tr>
        {modalData?.type === 1 ? (
          ""
        ) : modalData?.type === 2 ? (
          <tr>
            <td style={{ width: "150px", padding: "10px" }}>Skill loan</td>
            <td>: {modalData?.skillLoan}</td>
            <td style={{ width: "150px" }}>Request Id</td>
            <td style={{ textAlign: "left" }}>
              : {modalData?.skillLoanRequest}
            </td>
          </tr>
        ) : modalData?.type === 3 ? (
          <tr>
            <td style={{ width: "150px", padding: "10px" }}>Scholarship</td>
            <td>: {modalData?.scholarship}</td>
            <td style={{ width: "150px" }}>Request Id</td>
            <td style={{ textAlign: "left" }}>
              : {modalData?.scholarshipRequest}
            </td>
          </tr>
        ) : (
          <tr>
            <td style={{ width: "150px", padding: "10px" }}>
              Online Payment Id
            </td>
            <td>: {modalData?.paymentId}</td>
            <td style={{ width: "150px" }}>Order Id</td>
            <td style={{ textAlign: "left" }}>: {modalData?.orderId}</td>
          </tr>
        )}
        <tr>
          <td style={{ width: "150px", padding: "10px" }}>Date :</td>
          <td>: {modalData?.date}</td>
          <td style={{ width: "150px" }}>Time</td>
          <td style={{ textAlign: "left" }}>: {modalData?.time}</td>
        </tr>
        {modalData?.addedDate ? (
          <tr>
            <td style={{ width: "150px", padding: "10px" }}>Added Date :</td>
            <td>: {modalData?.addedDate}</td>
            <td style={{ width: "150px" }}>Added Time</td>
            <td style={{ textAlign: "left" }}>: {modalData?.addedTime}</td>
          </tr>
        ) : (
          ""
        )}
        {modalData?.type !== 4 ? (
          <tr>
            <td style={{ width: "150px", padding: "10px" }}>Added By</td>
            <td style={{ textAlign: "left" }}>: {modalData?.added}</td>
          </tr>
        ) : (
          ""
        )}
      </table>
    </div>
  )
}
