import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { Row, Col, Card, CardBody } from "reactstrap"

import Breadcrumb from "../../../../components/Common/Breadcrumb"
import { get } from "../../../../helpers/api_helper"

import "./style.scss"
import { API_URL } from "../../../../config"
import { setTitle } from "../../../../helpers/functions"

const ScholarshipSummary = () => {
  setTitle("Scholarship Summary")
  const [data, setData] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    get("/scholarship/summary").then(res => {
      setData(res.data)
    })
  }, [])

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem="Scholarships" />
          <Row>
            {data.map((row, idx) => {
              return (
                <Col sm={12} md={6} xl={4}>
                  <Card className="student-card">
                    <CardBody style={{ padding: "0.7rem" }}>
                      <div>
                        <div
                          onClick={() =>
                            navigate(`/scholarships/${row.uniqueId}`)
                          }
                        >
                          <div
                            className="scholarshipListHeader"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <h5
                              className="mt-2 mb-2"
                              style={{
                                fontFamily: "IBM Plex Sans,sans-serif",
                              }}
                            >
                              {row.name}
                            </h5>
                            <div
                              className="text-muted"
                              style={{
                                textAlign: "center",
                                color: "green !important",
                                fontFamily: "IBM Plex Sans,sans-serif",
                                fontSize: "14px",
                                marginBottom: "3px",
                              }}
                            >
                              <div
                                class="btn-group btn-group-sm"
                                role="group"
                                aria-label="Basic example"
                              >
                                <img
                                  style={{ width: "100px", height: "50px" }}
                                  src={API_URL + row.logo}
                                  alt={row.name}
                                />
                              </div>
                            </div>
                          </div>
                          {/* <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            className="mt-2"
                          >
                            <table
                              className="table table-bordered"
                              id="scholarshipSummaryTable"
                            >
                              <thead>
                                <tr>
                                  <th></th>
                                  <th>Request</th>
                                  
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <p>Total Request</p>
                                  </td>
                                  <td>
                                    <h5
                                      style={{
                                        fontWeight: "600",
                                        color: "#1327b7",
                                      }}
                                      className="font-size-14"
                                    >
                                      {row.totalRequests}
                                    </h5>
                                  </td>
                                
                                </tr>
                                <tr>
                                  <td>
                                    <p>Sanctioned</p>
                                  </td>
                                  <td>
                                    <h5
                                      style={{
                                        fontWeight: "600",
                                        color: "green",
                                      }}
                                      className="font-size-14"
                                    >
                                      {row.totalSanctioned}
                                    </h5>
                                  </td>
                                  
                                </tr>
                                <tr>
                                  <td>
                                    <p>Rejected</p>
                                  </td>
                                  <td>
                                    <h5
                                      style={{
                                        fontWeight: "600",
                                        color: "red",
                                      }}
                                      className="font-size-14"
                                    >
                                      {row.totalRejected}
                                    </h5>
                                  </td>
                                  
                                </tr>
                              </tbody>
                            </table>
                          </div> */}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            className="mt-2"
                          >
                            <p className="mb-1">Total Request:</p>
                            <h5
                              style={{ fontWeight: "600", color: "#1327b7" }}
                              className="font-size-14"
                            >
                              {row.totalRequests}
                            </h5>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            className="mt-2"
                          >
                            <p className="mb-1">Sanctioned:</p>
                            <h5
                              style={{ fontWeight: "600", color: "green" }}
                              className="font-size-14"
                            >
                              {row.totalSanctioned}
                            </h5>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            className="mt-2"
                          >
                            <p className="mb-1">Rejected:</p>
                            <h5
                              style={{ fontWeight: "600", color: "red" }}
                              className="font-size-14"
                            >
                              {row.totalRejected}
                            </h5>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            className="mt-2"
                          >
                            <p className="mb-1">Sanctioned Percentage</p>
                            <h5
                              style={{ fontWeight: "600", color: "green" }}
                              className="font-size-14"
                            >
                              {row.sanctionedPercentage
                                ? row.sanctionedPercentage?.toFixed(0)
                                : 0}
                              %
                            </h5>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )
            })}
          </Row>
        </div>
      </div>
    </>
  )
}

export default ScholarshipSummary
