import React, { useEffect, useState } from "react"
import Select from "react-select"
import PropTypes from "prop-types"
import moment from "moment"

function FinancialMonth(props) {
  const [options, setOptions] = useState([])

  useEffect(() => {
    const currentYear = moment().year()
    const startYear = currentYear - (moment().month() + 1 <= 3 ? 1 : 0)
    const monthLabels = [
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
      "Jan",
      "Feb",
      "Mar",
    ]

    const months = monthLabels.map((label, index) => {
      const month = (index + 4) % 12 || 12
      const year = index >= 9 ? startYear + 1 : startYear
      const start = moment(
        `${year}-${month.toString().padStart(2, "0")}-01`
      ).format("YYYY-MM-DD")
      const end = moment(start).endOf("month").format("YYYY-MM-DD")

      return {
        label: `${label} ${year}`,
        from: start,
        to: end,
        value: `${start},${end}`,
      }
    })

    setOptions(months)
  }, [])

  return (
    <>
      {/* <Label>Financial Month</Label> */}
      <Select
        value={props.value}
        onChange={selectedOption => {
          props.onChange(selectedOption)
        }}
        options={options}
        menuPosition="fixed"
        classNamePrefix="select2-selection"
        {...props}
      />
    </>
  )
}

FinancialMonth.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

export default FinancialMonth
