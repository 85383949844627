import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Modal,
  Row,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { APP_NAME, PRIVILEGES } from "../../config"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { MDBDataTable } from "mdbreact"
import { get, put } from "../../helpers/api_helper"
import "./Details.scss"
import moment from "moment"
import { isArray } from "lodash"

import qs from "query-string"

import Select from "react-select"
import Admin from "../../components/Common/Admin"
import useUser from "../../hooks/useUser"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { getUsersOptions } from "../../store/options/actions"

import Slider from "react-rangeslider"

import toastr from "toastr"

const types = [
  { value: "bg-danger", label: "Danger" },
  { value: "bg-success", label: "Success" },
  { value: "bg-primary", label: "Primary" },
  { value: "bg-info", label: "Info" },
  { value: "bg-dark", label: "Dark" },
  { value: "bg-warning", label: "Warning" },
]

const duration = [
  { label: "Day", value: 1 },
  { label: "Week", value: 2 },
  { label: "Month", value: 3 },
]

const PlannerDetails = () => {
  document.title = `Planner Detail | ${APP_NAME}`

  const [rows, setRows] = useState([])

  const [master, setMaster] = useState({})

  const [selected, setSelected] = useState({})

  const [category, setCategory] = useState([])

  const [search, setSearch] = useState({})

  const [searchSelect, setSearchSelect] = useState({})

  const OPTIONS = useSelector(state => state.Options)

  const user = useUser()

  const dispatch = useDispatch()

  useEffect(() => {
    handleTableData(qs.stringify(search))
  }, [search])

  useEffect(() => {
    get(`options/planner-category`).then(res => {
      setCategory(res.data)
    })
  }, [])

  useEffect(() => {
    dispatch(getUsersOptions({}))
  }, [dispatch])

  const handleTableData = query => {
    get(`planner/details?${query}`).then(res => {
      // console.log(res.data)

      const data = res.data.map((item, index) => {
        item.id = index + 1

        item.planDate = item.start
          ? moment(item.start).format("DD-MM-YYYY")
          : ""

          item.startingTime = item.startingTime ? moment(item.startingTime, "HH:mm:ss").format("hh:mm a") : ''
          item.endingTime = item.endingTime ? moment(item.endingTime, "HH:mm:ss").format("hh:mm a") : ''

        item.category_status = (
          <div className="text-center">
            <span
              className={`badge font-size-12 ms-2 ${
                "bg-soft-" + item?.type.split("-")[1] || "success"
              }`}
            >
              {item?.category?.name}
            </span>
          </div>
        )

        item.status = (
          <span
            className={`badge rounded-pill  font-size-12 ${
              item.status === "completed"
                ? "bg-soft-success"
                : item.status === "upcoming"
                ? "bg-soft-warning"
                : "bg-soft-danger"
            }`}
          >
            {item.status}
          </span>
        )

        item.user = `${item?.addedBy?.firstName || ""} ${
          item?.addedBy?.lastName || ""
        }`

        item.date = moment(item.date).format("DD-MM-YYYY")
        item.time = moment(item.time, "HH:mm:ss").format("hh:mm a")

        item.action = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2em",
                marginRight: "0.5em",
              }}
              onClick={() => {
                handleModal(item)
              }}
            ></i>
          </div>
        )

        return item
      })

      setRows(data)
    })
  }

  const handleSelectSearch = (e, name) => {
    const value = e ? (isArray(e) ? e.map(item => item.value) : e.value) : null
    setSearchSelect(pre => ({ ...pre, [name]: e }))
    setSearch(pre => ({ ...pre, [name]: value }))
  }

  const handleChangeSearch = e => {
    let { name, value } = e.target
    setSearch(pre => ({ ...pre, [name]: value }))
  }

  const handleModal = data => {

    setSelected({
      category: { label: data?.category?.name, value: data?.category?._id },
    })

    delete data?.addedBy

    setMaster({
      progression: data.progression,
      prevProgression: data.progression,
      id: data._id,
      start: data.start,
      to: data.start,
      title: data.title,
      category: data?.category?._id,
      durationType: data?.durationType,
      type: data?.type,
      startingTime: data?.startingTime,
      endingTime: data?.endingTime,
      remarks: data?.remarks,
    })
  }

  const handleSelect = (e, name) => {
    const value = e ? (isArray(e) ? e.map(item => item.value) : e.value) : null
    setSelected(pre => ({ ...pre, [name]: e }))
    setMaster(pre => ({ ...pre, [name]: value }))
  }

  const handleChange = e => {
    let { name, value } = e.target
    setMaster(pre => ({ ...pre, [name]: value }))
  }

  const updateEvent = () => {
    put(`planner/${master.id}`, {
      ...master,
      className: master.type,
    })
      .then(res => {
        toastr.success(res.message)
        setMaster({})
        setSelected({})
        handleTableData()
      })
      .catch(err => {
        toastr.error(err?.response?.data?.message)
      })
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 200,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 100,
      },
      {
        label: "Plan Date",
        field: "planDate",
        sort: "asc",
        width: 200,
      },
      {
        label: "Start Time",
        field: "startingTime",
        sort: "asc",
        width: 200,
      },
      {
        label: "End Time",
        field: "endingTime",
        sort: "asc",
        width: 200,
      },
      {
        label: "Category",
        field: "category_status",
        sort: "asc",
        width: 200,
      },
      {
        label: "Plan",
        field: "title",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 200,
      },
      {
        label: "Progression",
        field: "progression",
        sort: "asc",
        width: 200,
      },
      {
        label: "User",
        field: "user",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Home" breadcrumbItem="Planner Detail" />

          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md="2">
                        <Label>From Date</Label>
                        <AvField
                          name="from_date"
                          type="date"
                          className="form-control"
                          placeholder="Last Name"
                          max={moment().format("YYYY-MM-DD")}
                          value={search.from_date}
                          onChange={handleChangeSearch}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Enter From Date",
                            },
                          }}
                        />
                      </Col>
                      <Col md="2">
                        <Label>To Date</Label>
                        <AvField
                          name="to_date"
                          type="date"
                          min={search.from_date}
                          max={moment().format("YYYY-MM-DD")}
                          value={search.to_date}
                          onChange={handleChangeSearch}
                          className="form-control"
                        />
                      </Col>
                      <Col md="2">
                        <Label>Category</Label>
                        <Select
                          name="status"
                          onChange={e => handleSelectSearch(e, "category")}
                          value={searchSelect.category || null}
                          options={category}
                          classNamePrefix="select2-selection"
                          menuPosition="auto"
                        />
                      </Col>

                      {user.privilage === PRIVILEGES.ADMIN && (
                        <Col md="2">
                          <Label>User</Label>
                          <Select
                            name="status"
                            onChange={e => handleSelectSearch(e, "user")}
                            value={searchSelect.user || null}
                            options={OPTIONS.users}
                            classNamePrefix="select2-selection"
                            menuPosition="auto"
                          />
                        </Col>
                      )}

                      <Col md="2">
                        <Label>Status</Label>
                        <Select
                          name="status"
                          onChange={e => handleSelectSearch(e, "status")}
                          value={searchSelect.status || null}
                          options={[
                            { label: "Completed", value: "completed" },
                            { label: "Upcoming", value: "upcoming" },
                            { label: "Pending", value: "pending" },
                          ]}
                          classNamePrefix="select2-selection"
                          menuPosition="auto"
                        />
                      </Col>

                      <Col md="2">
                        <div className="mt-4 pt-1">
                          <Button
                            onClick={() => {
                              setSearch({})
                              setSearchSelect({})
                            }}
                            className="mx-2"
                            color="danger"
                            type="reset"
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="plannerDetailTable"
                    paging={false}
                    responsive
                    bordered
                    disableRetreatAfterSorting={true}
                    data={data}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal show={master.id} isOpen={master.id} centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add new plan</h5>
          <button
            type="button"
            onClick={() => {
              setSelected({})
              setMaster({ id: "" })
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <Row>
            <Col md={12}>
              <AvForm onValidSubmit={() => updateEvent()}>
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label>From Date</Label>
                      <AvField
                        name="start"
                        type="date"
                        min={moment().format("YYYY-MM-DD")}
                        placeholder="Choose date"
                        errorMessage="Choose valid date"
                        onChange={handleChange}
                        value={master.start}
                        disabled
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label>To Date</Label>
                      <AvField
                        name="to"
                        type="date"
                        placeholder="Choose date"
                        errorMessage="Choose valid date"
                        onChange={handleChange}
                        value={master.to}
                        disabled
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label>From</Label>
                      <AvField
                        name="startingTime"
                        type="time"
                        placeholder="Choose date"
                        errorMessage="Choose valid date"
                        onChange={handleChange}
                        value={master.startingTime}
                        disabled
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label>To </Label>
                      <AvField
                        name="endingTime"
                        type="time"
                        placeholder="Choose time"
                        errorMessage="Choose valid time"
                        onChange={handleChange}
                        value={master.endingTime}
                        disabled
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label>Plan</Label>
                      <AvField
                        name="title"
                        type="textarea"
                        rows={1}
                        value={master.title || ""}
                        // onChange={handleChange}
                        placeholder="Enter plan"
                        errorMessage="Enter valid plan"
                        onChange={handleChange}
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label>Duration</Label>
                      <Select
                        onChange={e => {
                          handleSelect(e, "durationType")
                        }}
                        isClearable
                        value={
                          selected.durationType ||
                          duration.filter(
                            item => item.value == master?.durationType
                          )
                        }
                        options={duration}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label>Category</Label>
                      <Select
                        onChange={e => {
                          handleSelect(e, "category")
                        }}
                        isClearable
                        value={selected.category || null}
                        options={category}
                      />
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <Label>Priority</Label>
                      <Select
                        onChange={e => {
                          handleSelect(e, "type")
                        }}
                        isClearable
                        value={
                          selected.type ||
                          types.filter(item => item.value == master?.type)
                        }
                        options={types}
                      />
                    </div>
                  </Col>

                  <Row className="d-flex align-items-center">
                    <Col md={8}>
                      <Label>Progression</Label>
                      <Slider
                        value={master?.progression}
                        orientation="horizontal"
                        // min={master?.prevProgression}
                        onChange={value => {
                          if (value >= master?.prevProgression) {
                            handleChange({
                              target: { name: "progression", value },
                            })
                          }
                        }}
                      />
                    </Col>

                    <Col md={4} className="mt-4 pt-3">
                      <div className="mb-3">
                        <AvField
                          name="progression"
                          type="number"
                          value={master.progression || ""}
                          // onChange={handleChange}
                          placeholder="Progression"
                          min={master?.prevProgression}
                          max={100}
                          errorMessage="Enter progression"
                          onChange={handleChange}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <Label>Remarks</Label>
                      <AvField
                        name="remarks"
                        type="texarea"
                        value={master.remarks || ""}
                        placeholder="Remarks"
                        errorMessage="Enter remarks"
                        onChange={handleChange}
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={12}>
                    <div className="d-flex justify-content-end">
                      <Button
                        className="bt-primary"
                        color="primary"
                        type="submit"
                      >
                        {master?.id ? "Update" : "Submit"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </Col>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default PlannerDetails
