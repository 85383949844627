import React, { useEffect, useRef, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Select from "react-select"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import PieChart from "./PieChart"
import grinningface from "../../../assets/fotos/grinningface.svg"
import slightlysmile from "../../../assets/fotos/slightlysmileface.svg"
import Neutralface from "../../../assets/fotos/neutralface.svg"
import confusedface from "../../../assets/fotos/confusedface.svg"
import cryingface from "../../../assets/fotos/cryingface.svg"
import { get } from "../../../helpers/api_helper"
import toastr from "toastr"
import { getBranches } from "../../../store/options/actions"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { isArray } from "lodash"
import moment from "moment"
import { jsonToExcel } from "../../../helpers/functions"
import useBranch from "../../../hooks/useBranch"
import Admin from "../../../components/Common/Admin"
function Graphicalreport() {
  const customStyles = {
    lineHeight: "1.8",
  }

  const [branch] = useBranch()
  
  const dispatch = useDispatch()
  const formRef = useRef()
  const { branches } = useSelector(state => state.Options)
  const initialFilter = {form:null,from:null,to:null}
  const [filterObject, setfilterObject] = useState({branch: branch?.value })
  const [fetchFeedback, setFetchfeedBack] = useState([])
  const [forms,setForms] = useState([])
  const [selectcsp, setselectcsp] = useState(null)
  const [selectform, setselectform] = useState(null)
  const [selected, setSelected] = useState(null)
  const [formoptions, setformoptions] = useState([])
  const [courseOptions, setCourseoptions] = useState([])
  const [batchOptions, setBatchOptions] = useState([])
  const [filter,setFilter] = useState(initialFilter)
  const [disableButton,setDisableButton] = useState(false)
  const [disableReset,setDisableReset] = useState(true)
  const [triggerRefresh,setTriggerRefresh] = useState(false)
  const [refresh,setRefresh] = useState(1)
  const [eventOptions,setEventOptions] = useState([])

  useEffect(() => {
    dispatch(getBranches())
    fetchAllfeedBackCourse() 
  }, [])


  async function fetchOptions(){
    get(`/options/forms?assignedFor=${filterObject?.assignedFor}&csp=${filterObject?.csp}`)
    .then(res=>{
      let result = res?.data
      setForms(result)
    })
    .catch(err => {
      console.log("err", err)
    })
  }

  useEffect(() => {
    fetchAllfeedBackform()
  }, [filterObject?.assignedFor , filterObject?.csp])

  useEffect(() => {
    fetchAllfeedBackBatch()
  }, [selectcsp,selected?.course])


  useEffect(()=>{
    const fetchEvents = async()=>{
      try {
        const res = await get(`options/events?csp=${filterObject?.csp}`)
        setEventOptions(res?.data)
      } catch (error) {
        
      }
    }
    fetchEvents()
  },[filterObject?.csp])

  async function fetchAllreports() {
    if(filter.from || filter.to){
      if(filter.from > filter.to || filter.from === null || filter.to === null){
        toastr.error('Invalid Date');
        return
      }
    }  
    get(
      `/feedback/report?id=${filterObject?.form}&fromDate=${filterObject?.fromDate}&toDate=${filterObject?.toDate}&batch=${filterObject?.batch}&status=${filterObject?.status}&course=${filterObject?.course}&csp=${filterObject?.csp}&event=${filterObject?.event}&assignedFor=${filterObject?.assignedFor}`
    )
    .then(res => {
      if (res.success) { 
        let result = res?.data
        setFetchfeedBack(result)
        setDisableButton(true) 
        if(filter.form || filter.from || filter.to){
          setDisableReset(false)
          setTriggerRefresh(true)
        }else{
          setDisableReset(true)
          setTriggerRefresh(false)
        }
      }
    })
  }



  const handleExportData = async () => {
    try {
      const response = await get(
        `/feedback/report?id=${filterObject?.form}&fromDate=${filterObject?.fromDate}&toDate=${filterObject?.toDate}&batch=${filterObject?.batch}&status=${filterObject?.status}&course=${filterObject?.course}&csp=${filterObject?.csp}&event=${filterObject?.event}&assignedFor=${filterObject?.assignedFor}`
      );
  
      const data = response.data;
      const dataExport = [];
      
      if (data && Array.isArray(data) && data.length > 0) {
        data.forEach((item) => {
          item?.questions.forEach((item2) => {
            // Define templates for each question type
            const answerTemplates = {
              0: [
                { answer: 'YES', count: 0, percentage: '0%' },
                { answer: 'NO', count: 0, percentage: '0%' }
              ],
              1: [
                { answer: '1 Star', count: 0, percentage: '0%' },
                { answer: '2 Star', count: 0, percentage: '0%' },
                { answer: '3 Star', count: 0, percentage: '0%' },
                { answer: '4 Star', count: 0, percentage: '0%' },
                { answer: '5 Star', count: 0, percentage: '0%' }
              ],
              2: [
                { answer: 'Need Improvement', count: 0, percentage: '0%' },
                { answer: 'Average', count: 0, percentage: '0%' },
                { answer: 'Good', count: 0, percentage: '0%' },
                { answer: 'Best', count: 0, percentage: '0%' },
                { answer: 'Excellent', count: 0, percentage: '0%' }
              ]
            };
  
            // Initialize dataExport with the templates
            answerTemplates[item2.type].forEach(template => {
              dataExport.push({
                Name: item.name,
                Question: item2.question,
                Answer: template.answer,
                Votes: template.count,
                Percentage: template.percentage
              });
            });
  
            // Fill in actual data
            item2.answers.forEach((item3) => {
              let answerText;
              if (item2.type == 0 && item3.answer == 1) {
                answerText = 'YES';
              } else if (item2.type == 0 && item3.answer == 2) {
                answerText = 'NO';
              } else if (item2.type == 1 && item3.answer >= 1 && item3.answer <= 5) {
                answerText = `${item3.answer} Star`;
              } else if (item2.type == 2 && item3.answer >= 1 && item3.answer <= 5) {
                const emojis = ['Need Improvement', 'Average', 'Good', 'Best', 'Excellent'];
                answerText = emojis[item3.answer - 1];
              }
  
              // Find the entry in dataExport and update it
              const entry = dataExport.find(
                d => d.Name === item.name && d.Question === item2.question && d.Answer === answerText
              );
  
              if (entry) {
                entry.Votes = item3.count;
                entry.Percentage = `${item3.percentage}%`;
              }
            });
          });
        });
  
        jsonToExcel(dataExport, `Feedback-Reports-${moment().format("DD-MM-YY")}`);
      } else {
        toastr.info(`There are No Feedback to export`);
      }
    } catch (error) {
      console.error("Error exporting data:", error);
      toastr.error("An error occurred while exporting data");
    }
  };
  
  

  function fetchAllfeedBackform() {
    get(`/options/forms?assignedFor=${filterObject?.assignedFor}&csp=${filterObject?.csp}`)
      .then(res => {
        console.log("lsyt form", res)
        setformoptions(res.data)
      })
      .catch(err => {
        console.log("err", err)
      })
  }

  function fetchAllfeedBackCourse() {
    get(`/feedback/option/course`)
      .then(res => {
        console.log("lsyt form", res)
        setCourseoptions(res.data)
      })
      .catch(err => {
        console.log("err", err)
      })
  }
  function fetchAllfeedBackBatch() {
    get(`/feedback/option/batch?branch=${selectcsp?.value}&course=${selected?.course?.value}`)
      .then(res => {
        console.log("lsyt form", res)
        setBatchOptions(res.data)
      })
      .catch(err => {
        console.log("err", err)
      })
  }


  const handleSelect = (e, name) => {
    const value = e ? (isArray(e) ? e.map(item => item.value) : e.value) : null
    setSelected(prev => ({ ...prev, [name]: e }))
    setfilterObject(prev => ({ ...prev, [name]: value }))
    if(name == 'assignedFor'){
      setSelected(prev => ({ ...prev, event:null, course:null, batch:null }))
      setfilterObject(prev => ({ ...prev, event:null, course:null, batch:null }))
    }
  }
  useEffect(() => { 
    fetchOptions()
  },[])

  useEffect(()=>{
    fetchAllreports()
  },[filterObject])


  function selectfilter(selected, name) {
    switch (name) {
      case "csp":
        setselectcsp(selected)
        setfilterObject({ ...filterObject, csp: selected.value })
        break
      case "form":
        setselectform(selected)
        setfilterObject({ ...filterObject, form: selected.value })
      default:
        break
    }
  }

  function handleChangedate(e) {
    let name = e.target.name
    let value = e.target.value
    setfilterObject({ ...filterObject, [name]: value })
  } 
  function resetAll() {
    formRef.current?.reset()
    setfilterObject({})
    setSelected(null)
    setselectcsp(null)
    setselectform(null)
  }
  return (
    <div>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Question wise report" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm ref={formRef}>
                  <Row>
                      <Admin>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>CSP</Label>
                          <Select
                            name="csp"
                            classNamePrefix="select2-selection"
                            options={branches}
                            onChange={selected => {
                              selectfilter(selected, "csp")
                            }}
                            value={selectcsp}
                          />
                        </div>
                      </Col>
                      </Admin>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>From</Label>
                          <AvField
                            style={customStyles}
                            name="fromDate"
                            className="form-control"
                            type="date"
                            onChange={handleChangedate}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label> To</Label>
                          <AvField
                            style={customStyles}
                            name="toDate"
                            className="form-control"
                            type="date"
                            placeholder="Enter Documents"
                            errorMessage="Enter Documents"
                            onChange={handleChangedate}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Assigned For</Label>
                          <Select
                            options={[
                              {
                                label: "Event",
                                value: 0,
                                type: "events",
                              },
                              {
                                label: "Batch",
                                value: 1,
                                type: "batch",
                              },
                            ]}
                            classNamePrefix="select2-selection"
                            onChange={selected =>
                              handleSelect(selected, "assignedFor")
                            }
                            value={selected?.assignedFor || ""}
                          />
                        </div>
                      </Col>
                      {selected?.assignedFor?.value == 1 && 
                  <>
                     <Col md="2">
                        <div className="mb-3">
                          <Label>Course</Label>
                          <Select
                            name="form"
                            options={courseOptions}
                            value={selected?.course || ""}
                            onChange={selected =>
                              handleSelect(selected, "course")
                            }
                            type="text"
                            errorMessage="Enter Documents"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Batch</Label>
                          <Select
                            name="form"
                            type="text"
                            value={selected?.batch || ""}
                            onChange={selected =>
                              handleSelect(selected, "batch")
                            }
                            options={batchOptions}
                            errorMessage="Enter Documents"
                          />
                        </div>
                      </Col>
                   
                      </>
                         }
                         {selected?.assignedFor?.value  == 0 &&
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Event</Label>
                          <Select
                            name="form"
                            options={eventOptions}
                            value={selected?.event || ""}
                            onChange={selected =>
                              handleSelect(selected, "event")
                            }
                            type="text"
                          />
                        </div>
                      </Col>}
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Feedback Form</Label>
                          <Select
                            name="form"
                            value={selectform}
                            options={formoptions}
                            onChange={selected =>
                              selectfilter(selected, "form")
                            }
                            type="text"
                            errorMessage="Enter Documents"
                          />
                        </div>
                      </Col>
                  
                    
                      <Col>
                        <div className="mb-3" style={{ paddingTop: "1.7rem" }}>
                        
                          <Button
                            onClick={resetAll}
                            color="danger"
                            type="reset"
                          >
                            Reset
                          </Button>
                          <Button
                            style={{ marginLeft: "14px" }}
                            color="warning"
                            type="button"
                            onClick={handleExportData}
                          >
                            Export{" "}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            {fetchFeedback.map(item => (
              <Col xl="12">
                <h6>Feedback Form : {item.name}</h6>
                <Card>
                  <CardBody>
                    <AvForm>
                      <Row>
                        {item?.questions?.map(el => {
                          return (
                            <>
                              {el.type === 1 ? (
                                <>
                                  <Col md="5">
                                    <p style={{ fontSize: ".9rem" }}>
                                      {el.question}
                                    </p>
                                    <table
                                      style={{
                                        borderRadius: "10px",
                                        borderColor: "#80808017",
                                      }}
                                      className="table table-bordered"
                                    >
                                      <tbody>
                                        {Array.from(
                                          { length: 5 },
                                          (_, index) => {
                                            const starCount = 5 - index
                                            const item = el?.answers?.find(
                                              item => parseInt(item.answer) === starCount
                                            )

                                            return (
                                              <tr key={starCount}>
                                                <td
                                                  style={{ textAlign: "start" }}
                                                >
                                                  {Array.from(
                                                    { length: starCount },
                                                    (_, count) => (
                                                      <span
                                                        key={count}
                                                        style={{
                                                          fontSize: "18px",
                                                        }}
                                                      >
                                                        ⭐
                                                      </span>
                                                    )
                                                  )}
                                                </td>
                                                <td>
                                                  {item ? (
                                                    <>
                                                      {item.count} Votes (
                                                      {item.percentage.toFixed(2)}%)
                                                    </>
                                                  ) : (
                                                    <>0 Votes (0%)</>
                                                  )}
                                                </td>
                                              </tr>
                                            )
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  </Col>
                                </>
                              ) : el.type === 2 ? (
                                <>
                                  <Col md="5">
                                    <p style={{ fontSize: ".9rem" }}>
                                      {el.question}
                                    </p>
                                    <table
                                      style={{
                                        borderRadius: "10px",
                                        borderColor: "#80808017",
                                      }}
                                      className="table table-bordered"
                                    >
                                      <tbody>
                                        {Array.from(
                                          { length: 5 },
                                          (_, index) => {
                                            const emoji = 5 - index
                                            const item = el?.answers?.find(
                                              item => parseInt(item.answer) === emoji
                                            )

                                            return (
                                              <tr key={emoji}>
                                                <td
                                                  style={{ textAlign: "start" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: "18px",
                                                    }}
                                                  >
                                                    {emoji === 5 ? (
                                                      <img
                                                        src={grinningface}
                                                        alt="Grinning face"
                                                      />
                                                    ) : emoji === 4 ? (
                                                      <img
                                                        src={slightlysmile}
                                                        alt="Grinning face"
                                                      />
                                                    ) : emoji === 3 ? (
                                                      <img
                                                        src={Neutralface}
                                                        alt="Grinning face"
                                                      />
                                                    ) : emoji === 2 ? (
                                                      <img
                                                        src={confusedface}
                                                        alt="Grinning face"
                                                      />
                                                    ) : emoji === 1 ? (
                                                      <img
                                                        src={cryingface}
                                                        alt="Grinning face"
                                                      />
                                                    ) : (
                                                      ""
                                                    )}
                                                  </span>
                                                </td>
                                                <td>
                                                  {item ? (
                                                    <>
                                                      {item.count} Votes (
                                                      {item.percentage.toFixed(2)}%)
                                                    </>
                                                  ) : (
                                                    <>0 Votes (0%)</>
                                                  )}
                                                </td>
                                              </tr>
                                            )
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  </Col>
                                </>
                              ) : el.type === 0 ? (
                                <>
                                  <Col md="5">
                                    <p style={{ fontSize: ".9rem" }}>
                                      {el.question}
                                    </p>
                                    <table
                                      style={{
                                        borderRadius: "10px",
                                        borderColor: "#80808017",
                                      }}
                                      className="table table-bordered"
                                    >
                                      <tbody>
                                        {Array.from(
                                          { length: 2 },
                                          (_, index) => {
                                            const emoji = 2 - index
                                            const item = el?.answers?.find(
                                              item => parseInt(item.answer) === emoji
                                            )

                                            return (
                                              <tr key={emoji}>
                                                <td
                                                  style={{ textAlign: "start" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: "18px",
                                                    }}
                                                  >
                                                    {emoji === 2
                                                      ? "No"
                                                      : emoji === 1
                                                      ? "Yes"
                                                      : ""}
                                                  </span>
                                                </td>
                                                <td>
                                                  {item ? (
                                                    <>
                                                      {item.count} Votes (
                                                      {item.percentage.toFixed(2)}%)
                                                    </>
                                                  ) : (
                                                    <>0 Votes (0%)</>
                                                  )}
                                                </td>
                                              </tr>
                                            )
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  </Col>
                                </>
                              ) : null}
                              <Col md="7">
                                <PieChart answers={el.answers} type={el.type} />
                              </Col>
                            </>
                          )
                        })}
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </div>
  )
}

export default Graphicalreport
