import React, { useState, useEffect, useRef } from "react"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { MDBDataTable } from "mdbreact"
import { AvForm, AvField } from "availity-reactstrap-validation"
import "./Sitemap.scss"
import axios from "axios"
import accessToken from "../../helpers/jwt-token-access/accessToken"
import moment from "moment"
import { get } from "../../helpers/api_helper"
function SiteMap() {
  const [tableData, settableData] = useState([])
  const [state,setState] = useState(false)
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/"

  useEffect(() => {
    fetch_sitemap_list()

  }, [state])

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "IP",
        field: "ipAddress",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 150,
      },
      {
        label: "Link Count",
        field: "linkCount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Added by",
        field: "added",
        sort: "asc",
        width: 150,
      },
      {
        label: "Actions",
        field: "options",
        sort: "asc",
        width: 150,
      },
    ],
    rows: tableData,
  }

  const generateSitemap = async()=>{
    try {
    await  get("site-map/generate-sitemap")
    setState(!state)
    } catch (error) {
      
    }
  }



  function fetch_sitemap_list() {
   get(`/site-map/list`).then((res)=>{
    let data=[] 
    let result =res?.data
    result.map((item,index)=>{
      item.date = moment(item.date).format("DD-MM-YYYY")
      item.time = moment(item.time, "HH:mm:ss").format("hh:mm A")

      item.id = index + 1
      item.added =item?.addedBy?.username
      item.options = (
        <div style={{ display: "flex", justifyContent: "center" }}>
              <i
      className="fas fa-eye"
      style={{
        fontSize: "1em",
        cursor: "pointer",
        marginRight: "0.5rem",
      }}
      onClick={() => {
        window.open(`${API_URL}${item.filePath}`)
      }}
    ></i>
    </div>
      )
      data.push(item)
      
    })
    settableData(data)
   })
  }
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Site Map" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Button className="mb-2" onClick={generateSitemap} color="primary">
                    <i class="fa fa-print me-2"></i>Generate Sitemap
                  </Button>
                  <MDBDataTable
                    id="sitemaptable"
                    responsive
                    bordered
                    data={data}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <Breadcrumb title="Home" breadcrumbItem="Visit-Log" /> */}
        </div>
      </div>
    </>
  )
}

export default SiteMap
