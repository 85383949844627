import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { checkingPrivilege, isAdmin } from "../helpers/functions"

const useMenuAllowed = privilege => {
  const navigate = useNavigate()

  useEffect(() => {
    checkIsAllowed()
    // eslint-disable-next-line
  }, [])

  const checkIsAllowed = () => {
    const admin = isAdmin()
    const isPrivilege = checkingPrivilege(privilege)
    if (!admin && !isPrivilege) {
      const moduleUrl = JSON.parse(localStorage.getItem("authUser")).modules
        .redirect_url
      navigate(moduleUrl)
    }

    console.log(
      `PRIVILEGE CHECK CALLED isAdmin= ${admin} isPrivilege=${isPrivilege}`
    )
    return admin || isPrivilege
  }
}

export default useMenuAllowed
