import React, { useState, useEffect } from "react"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label, Modal, Spinner } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { useSelector } from "react-redux"
import toastr from "toastr"
import "./TestResults.scss"
// import "./style.scss"
import { useNavigate } from "react-router-dom"
import { get, post } from "../../../helpers/api_helper"
import { AvField, AvForm } from "availity-reactstrap-validation"
import MuiCustomTablePagination from "../../../components/Common/TablePagination"

const RankList = () => {
  const navigate = useNavigate()
  const { courses } = useSelector(state => state.Options)
  const [popupView, setPopupView] = useState(false)
  const [popupView1, setPopupView1] = useState(false)
  const [retakeData, setretakeData] = useState([])
  const [retakeObject, setretakeObject] = useState({})

  const [masterObject, setMasterObject] = useState({page:0,pageSize:20})

  const [tableData, setTableData] = useState([])
  const [students, setStudents] = useState([])
  const [testOptions, setTestOptions] = useState([])
  const [count,setCount] = useState(0)
  const [loading,setLoading] = useState(false)
  const [refresh,setRefresh] = useState(1)

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleCloseModal = () => setPopupView(false)

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Test Name",
        field: "nametest",
        width: 150,
      },

      {
        label: "Name",
        field: "name",
        width: 150,
      },

      {
        label: "Student ID",
        field: "Studentid",
        width: 150,
      },

      {
        label: "Status",
        field: "status",
        width: 150,
      },

      {
        label: "Score",
        field: "score",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        width: 150,
      },
    ],
    rows: tableData,
  }

  const reset = () => {
    setMasterObject({page: 0, pageSize: 20})
  }

  const status = [
    {
      label: "Attending",
      value: 1,
    },
    {
      label: "Absent",
      value: 2,
    },
    {
      label: "Completed",
      value: 3,
    },
  ]

  const handleTestOptions = course => {
    get(`/options/screen-test?course=${course}`).then(res => {
      setTestOptions(res.data)
    })
  }

  const fetchStudentOptions = () => {
    get(`screen-test/options/student`).then(res=>{
      if(res.success){
        setStudents(res.data)
      }
    }).catch(err=>toastr.error(err.response.data.message))
  }

  const handleValueChange = async (value, name) => {
    const obj = { ...masterObject }
    obj[name] = value
    obj.page = 0
    setMasterObject(obj)
  }

  function getAlltestResult() {
    get(
      `/screen-test/result?course=${masterObject?.course}&test=${masterObject?.test}&student=${masterObject?.student}&status=${masterObject?.status}&page=${masterObject?.page}&limit=${masterObject?.pageSize}`
    ).then(res => {
      let result = res.data
      setCount(res.count)
      result.map((item, index) => {
        let notAttended = Object.keys(item?.attendData).length === 0
        item.id = index + 1 + (masterObject.page*masterObject.pageSize)
        item.score = item?.attendData?.result?.score ?? "NIL"
        item.nametest = item?.test?.name
        item.name = `${item?.profile?.firstname || ""} ${item?.profile?.lastname|| ""}`
        item.Studentid = item?.profile?.uniqueId
        if (item.attendData.status == 1)
          item.status = (
            <div>
              <span>Attending</span>
            </div>
          )
        if (item.attendData?.status == 2) item.status = "Absent"
        if (item.attendData?.status == 3) item.status = "Completed"

        if (item.attendData?.status == 5) item.status = "Retake"
        if (item.attendData?.status == 0 || notAttended)
          item.status = "Not Attended"
        item.action =  <div style={{ display: "flex", justifyContent: "center",width:'100%' }}>{item.attendData?.status == 3 ? (
          <span style={{ display: "flex", justifyContent: "flex-end",width:'50px' }}>
            <i
              className="fas fa-eye"
              onClick={() => {
                // console.log(item._id,"onclick");
                navigate(`/test_details/${item._id}`, {
                  state: { profileId: item.profile._id },
                })
              }}
              style={{
                fontSize: "1em",
                cursor: "pointer",
              }}
            ></i>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  id="button-tooltip-2"
                  style={{ position: "absolute", top: "-30px" }}
                >
                  Retake Test
                </Tooltip>
              }
            >
              <i
                class="fa fa-history"
                style={{cursor:'pointer', marginLeft:'10px' }}
                onClick={() => {
                  setPopupView(true)
                  setretakeData(item)
                }}
              ></i>
            </OverlayTrigger>
          </span>
        ) : item.attendData?.status == 2  ? (
          <span style={{ display: "flex", justifyContent: "flex-end",width:'50px' }}>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  id="button-tooltip-2"
                  style={{ position: "absolute", top: "-30px", marginLeft:'5px' }}
                >
                  Retake Test
                </Tooltip>
              }
            >
              <i
                class="fa fa-history ml-2"
                style={{cursor:'pointer' }}
                onClick={() => {
                  setPopupView(true)
                  setretakeData(item)
                }}
              ></i>
            </OverlayTrigger>
          </span>
        ): null }</div>
        return item
      })
      setTableData(result)
    })
  }
  useEffect(() => {
    handleTestOptions()
    fetchStudentOptions()
  }, [])

  const handleRefresh =()=>{
    setLoading(true)
    post(`screen-test/refresh`).then(res=>{
      if(res.success){
        setRefresh(prev=>prev+1)
        setLoading(false)
      }
    }).catch(err=> {
      toastr.error(err.response?.data?.message)
      setLoading(false)
    })
  }

  useEffect(() => {
    getAlltestResult()
  }, [masterObject,refresh])

  function handlechange(e) {
    let name = e.target.name
    let value = e.target.value
    setretakeObject({ ...retakeObject, [name]: value })
  }

  function validsubmit() {
    let data = { ...retakeObject }
    data.assignTest = retakeData?._id
    data.profile = retakeData?.profile?._id
    post(`/screen-test/retake`, data)
      .then(res => {
        toastr.success(res.message)
        setretakeData([])
        setretakeObject({})
        setPopupView(false)
        getAlltestResult()
      })
      .catch(err => {
        toastr.error(err.response.data.message)
      })
  }

  const handleChangeRowsPerPage = event => {
    setMasterObject({
      ...masterObject,
      page: 0,
      pageSize: parseInt(event.target.value, 10),
    })
  }

  const handlePageChange = newPage => {
    setMasterObject({
      ...masterObject,
      page:newPage
    })
  }

  return (
    <>
      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
        className="lead-modal"
      >
        <div className="modal-header">
          <div className="modal-title">
            <h6 style={{ marginBottom: "0px" }} className="modal-title ">
              Retake Test
            </h6>
          </div>
          <button
            style={{ marginBottom: "2px" }}
            type="button"
            onClick={handleCloseModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <Col md="12">
            <table className="table table-borderd lead-info table-striped">
              <tbody>
                <tr>
                  <td style={{ width: "100px" }}>Student</td>
                  <th>: {retakeData?.name}</th>
                  <td>StudentID</td>
                  <th>: {retakeData?.profile?.uniqueId}</th>
                </tr>
                <tr>
                  <td>Test Name</td>
                  <th>: {retakeData?.nametest}</th>
                  <td>Status</td>
                  <th>: {retakeData?.status}</th>
                </tr>
              </tbody>
            </table>
            <Row>
              <h6>Retake Details:</h6>
              <AvForm>
                <Row>
                  <Col md="3">
                    <Label>Retake Start Date</Label>
                    <AvField
                      onChange={handlechange}
                      type="date"
                      name="retakeDate"
                      min={getTodayDate()}
                    />
                  </Col>
                  <Col md="2">
                    <Label>Retake Start Time</Label>
                    <AvField
                      onChange={handlechange}
                      type="time"
                      name="retakeTime"
                    />
                  </Col>
                  <Col md="3">
                    <Label>Retake End Date</Label>
                    <AvField
                      onChange={handlechange}
                      type="date"
                      name="retakeEndDate"
                      min={getTodayDate()}
                    />
                  </Col>
                  <Col md="2">
                    <Label>Retake End Time</Label>
                    <AvField
                      onChange={handlechange}
                      type="time"
                      name="retakeEndTime"
                    />
                  </Col>
                  <Col md="2">
                    <Button
                      onClick={validsubmit}
                      type="button"
                      className="mt-4"
                      color="primary"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </AvForm>
            </Row>
          </Col>
          <div></div>
        </div>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem="Test Results" />
          <Card>
            <CardBody>
              <Row>
                <Col md={2}>
                  <div className="mb-3">
                    <Label>Test Name</Label>
                    <Select
                      name="test"
                      classNamePrefix="select2-selection"
                      options={testOptions}
                      // value={selected.test}
                      value={testOptions.filter(
                        option => option.value === masterObject.test
                      )}
                      onChange={selected => {
                        handleValueChange(selected.value, "test")
                      }}
                    />
                  </div>
                </Col>
                <Col md="3">
                  <div className="mb-3">
                    <Label>Course</Label>
                    <Select
                      className="text-editor-select"
                      name="course"
                      options={courses}
                      value={courses.filter(
                        option => option.value === masterObject.course
                      )}
                      onChange={selected => {
                        handleValueChange(selected.value, "course")
                      }}
                    />
                  </div>
                </Col>

                <Col md={3}>
                  <div className="mb-3">
                    <Label>Student</Label>
                    <Select
                      name="student"
                      classNamePrefix="select2-selection"
                      // value={selected.students}
                      options={students}
                      value={students.filter(
                        option => option.value === masterObject.student
                      )}
                      onChange={selected => {
                        handleValueChange(selected.value, "student")
                      }}
                    />
                  </div>
                </Col>

                <Col md={2}>
                  <div className="mb-3">
                    <Label>Status</Label>
                    <Select
                      name="test"
                      classNamePrefix="select2-selection"
                      options={status}
                      value={status.filter(
                        option => option.value === masterObject.status
                      )}
                      onChange={selected => {
                        handleValueChange(selected.value, "status")
                      }}
                    />
                  </div>
                </Col>
                <Col md={2}>
                  <div style={{ display: "flex", direction: "row" }}>
                    <Button
                      type="button"
                      style={{ marginTop: "1.7rem",height:'37px' }}
                      color="danger"
                      onClick={reset}
                    >
                      Reset
                    </Button>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip
                          id="button-tooltip-2"
                          style={{ position: "absolute"}}
                        >
                         {loading ? 'Refreshing...' : 'Refresh'} 
                        </Tooltip>
                      }
                    >
                      <div  style={{
                          marginTop: "1.7rem",
                          marginLeft: "10px",
                          marginRight: "5px",
                          height:'37px'
                        }}>
                      <Button
                        color="success"
                        onClick={handleRefresh}
                        style={{height:'100%',width:'60px'}}
                        disabled={loading}
                        outline
                      >
                        {loading ? 
                        <Spinner style={{height:'20px',width:'20px'}}/> : <i class="fas fa-redo"></i>}
                      </Button>
                      </div> 
                    </OverlayTrigger>
                  </div>
                </Col>
                <Col md={12}>
                  <MDBDataTable
                    id="testresultsid"
                    responsive
                    bordered
                    searching={false}
                    paging={false}
                    info={false}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                  />
                </Col>
                {tableData.length > 0 && (
                  <MuiCustomTablePagination
                    count={count}
                    page={masterObject?.page}
                    rowsPerPage={masterObject?.pageSize}
                    handleChangePage={handlePageChange}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                )}
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  )
}

export default RankList
