import React from 'react'
import { AvField, AvForm } from "availity-reactstrap-validation";
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { MDBDataTable } from 'mdbreact';
import Select from "react-select";
import "./style.scss"


function Chapter() {

    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                width: 150,
            },
            {
                label: "Chapter",
                field: "chapter",
                width: 150,
            },
            {
                label: "Subject",
                field: "subject",
                width: 150,
            },
            {
                label: "Section",
                field: "module",
                width: 150,
            },
            {
                label: "Staff",
                field: "staff",
                width: 150,
            },
            {
                label: "Action",
                field: "options",
                sort: "desc",
                width: 400,
            },

        ],
        rows: [
            {
                id: "1",
                chapter: "Basic of automobile system",
                subject: "Records & VIVA",
                module: "Introduction to automotive world",
                staff: "Admin",
                options: (
                    <div >

                        <i
                            className="uil-edit-alt"
                            style={{
                                fontSize: "1.2em",
                                cursor: "pointer",
                                marginLeft: "0.5rem",
                                marginRight: "0.5rem",
                            }}

                        ></i>
                        <i
                            className="uil-trash-alt"
                            style={{ fontSize: "1.2em", cursor: "pointer" }}

                        ></i>
                    </div>
                )
            },
            {
                id: "2",
                chapter: "Territory safety systems",
                subject: "Records & VIVA",
                module: "Vehicle safety",
                staff: "Admin",
                options: (
                    <div >

                        <i
                            className="uil-edit-alt"
                            style={{
                                fontSize: "1.2em",
                                cursor: "pointer",
                                marginLeft: "0.5rem",
                                marginRight: "0.5rem",
                            }}

                        ></i>
                        <i
                            className="uil-trash-alt"
                            style={{ fontSize: "1.2em", cursor: "pointer" }}

                        ></i>
                    </div>
                )
            },
            {
                id: "3",
                chapter: "Electric vehicle technology",
                subject: "Records & VIVA",
                module: "Vehicle safety",
                staff: "Admin",
                options: (
                    <div >

                        <i
                            className="uil-edit-alt"
                            style={{
                                fontSize: "1.2em",
                                cursor: "pointer",
                                marginLeft: "0.5rem",
                                marginRight: "0.5rem",
                            }}

                        ></i>
                        <i
                            className="uil-trash-alt"
                            style={{ fontSize: "1.2em", cursor: "pointer" }}

                        ></i>
                    </div>
                )
            },
        ],
    };


    return (
        <>
            <div className='page-content'>
                <div className='container-fluid'>
                    <Breadcrumbs title="Home" breadcrumbItem="Manage Chapter" />

                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <AvForm>
                                        <Row>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Chapter Name</Label>
                                                    <AvField
                                                        name="chapter"
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="category Name"
                                                        errorMessage="Enter chapter "
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Subject</Label>
                                                    <Select
                                                        name="category"
                                                        classNamePrefix="select2-selection"
                                                        type="text"
                                                        options={[
                                                            { label: "Records & VIVA", value: "Records & VIVA" },
                                                            { label: "Computer Aided Designing", value: "Computer Aided Designing" },
                                                            { label: "The Art of Draping", value: "The Art of Draping" },
                                                            { label: "Garment Construction (Practical)", value: "Garment Construction (Practical)" },
                                                        ]}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Section</Label>
                                                    <Select
                                                        name="category"
                                                        classNamePrefix="select2-selection"
                                                        type="text"
                                                        options={[
                                                            { label: "Introduction to automotive world" },
                                                            { label: "Vehicle safety" },
                                                            { label: "Introduction to automotive world" },
                                                        ]}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Chapter Order</Label>
                                                    <AvField
                                                        name="category"
                                                        className="form-control"
                                                        type="number"
                                                        min={0}
                                                        placeholder="Chapter Order"
                                                        errorMessage="Enter Order"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label>Description</Label>
                                                    <AvField
                                                        name="description"
                                                        placeholder="Description"
                                                        type="textarea"
                                                        className="form-control"
                                                        errorMessage="Enter Description"
                                                        rows={1}
                                                    />
                                                </div>
                                            </Col>

                                            <Col>
                                                <div className="mb-3" style={{ paddingTop: "25px" }}>
                                                    <Button color="primary" type="submit">
                                                        Submit
                                                    </Button>

                                                </div>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>


                    <Row>
                        <Col className='col-12'>
                            <Card>
                                <CardBody>


                                    <MDBDataTable
                                        id="Managetableid1"
                                        bordered
                                        responsive
                                        searching={true}
                                        entries={20}
                                        disableRetreatAfterSorting={true}
                                        data={data}

                                    >

                                    </MDBDataTable>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>



                </div>
            </div>
        </>
    )
}

export default Chapter;