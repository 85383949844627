import React, { useEffect, useState } from "react"
import { Row, Col } from "reactstrap"
import { get } from "../../helpers/api_helper"
import { useNavigate } from "react-router-dom"
import "./styles.scss"
import { API_URL } from "../../config"
function CSPDashboard() {
  const navigate = useNavigate()
  useEffect(() => {
    handleTableData()
  }, [])

  const [fetchData, setFetchData] = useState([])

  function handleTableData() {    
    get(`branch`)
      .then(res => {
        const result = res.branches
        setFetchData(result)
      })
      .catch(error => {
        console.error("Error fetching data:", error)
      })
  }

  function handleSearch(e) {
    const searchQuery = e.target.value;
    get(`branch?search=${searchQuery}`)
      .then((res) => {
        const filteredData = res.branches.filter((item) =>
          item.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFetchData(filteredData);
      })
      .catch((error) => {
        console.error("Error searching:", error);
      });
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row className="mb-2">
            <Col md={6}></Col>
            <Col md={6}>
              <div className="form-inline float-md-end mb-3">
                <div className="search-box ml-2">
                  <div className="position-relative">
                    <input
                      type="text"
                      onChange={handleSearch}
                      className="form-control rounded border-0"
                      placeholder="Search..."
                    />
                    <i className="mdi mdi-magnify search-icon"></i>
                  </div>
                </div>
              </div>
            </Col>

            {fetchData?.map((item, index) => {
              // console.log(item, "itemsss")

              return (
                <div
                  class="col-12 col-md-6 col-xl-4"
                  onClick={() => {
                    navigate(`/view-csp/${item.branchId}`, {
                      state: { id: item._id },
                    })
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div class="card">
                    <div class="card-body">
                      <div className="d-flex justify-content-between">
                        <h5 class="font-size-16 mb-0">
                          <p
                            style={{ textAlign: "left" }}
                            class="text-left text-dark "
                          >
                            {item.name}
                          </p>
                        </h5>
                        <img
                          style={{ width: "95px" }}
                          src={`${API_URL}${item?.operatingPartner?.logo}`}
                          alt=""
                        />
                      </div>

                      <div className="d-flex justify-content-between">
                        <div>
                          {/* <p className="m-1">{item.branchId}</p> */}
                          <div className="d-flex gap-2">
                            <i
                              style={{ paddingTop: "4px" }}
                              className="fas fa-user"
                            ></i>
                            <p style={{ marginBottom: "4px" }} className="">
                              {item.branchId}
                            </p>
                          </div>
                          <div className="d-flex gap-2">
                            <i
                              style={{ paddingTop: "4px" }}
                              className="fas fa-user-check"
                            ></i>
                            <p style={{ marginBottom: "4px" }} className="">
                              {item.contactPerson}
                            </p>
                          </div>
                          <div className="d-flex gap-2">
                            <i
                              style={{ paddingTop: "4px" }}
                              className="fas fa-phone-alt"
                            ></i>
                            <p className="mb-0">{item.mobile}</p>
                          </div>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CSPDashboard
