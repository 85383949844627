import React, { useEffect, useRef, useState } from "react"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import { MDBDataTable } from "mdbreact"
import { Modal } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import LockOpenIcon from "@mui/icons-material/LockOpen"
import "./style.scss"
import { getCluster, getSectors } from "../../../store/options/actions"
import { getStatesOptions } from "../../../store/options/actions"
import { getCountriesOptions } from "../../../store/options/actions"
import { getDistrictOptions } from "../../../store/options/actions"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { del, get, post, put } from "../../../helpers/api_helper"
import moment from "moment"
import toastr from "toastr"
import Swal from "sweetalert2"
import { API_URL } from "../../../config"
import "./style.scss"
function Company() {
  const initialState = {
    name: "",
    shortCode: "",
    sector: "",
    mobileNo: "",
    landlineNo: "",
    email: "",
    website: "",
    address: "",
    country: "",
    state: "",
    district: "",
    pin: "",
    gstin: "",
    latitude: "",
    longitude: "",
    logo: "",
    setSelectedSector: "",
  }

  const dispatch = useDispatch()

  const [masterObject, setMasterObject] = useState(initialState)
  const [selectedState, setSelectedState] = useState(null)
  const [selectedDistrict, setSelectedDistrict] = useState(null)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [selectedCluster, setSelectedCluster] = useState(null)
  const [selectedSector, setSelectedSector] = useState(null)
  const [tableListData, setTableListData] = useState([])
  const [popupView, setPopupView] = useState(false)
  const formRef = useRef()

  const navigate = useNavigate()
  const { cluster, sectors, countries, states, districts } = useSelector(
    state => state.Options
  )

  useEffect(() => {
    dispatch(getSectors({}))
    dispatch(getCluster({}))
    dispatch(getStatesOptions({}))
    dispatch(getCountriesOptions({}))
  }, [dispatch])
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }
  useEffect(() => {
    handleTableData()
  }, [])
  const reset = () => {
    formRef.current.reset()
    setSelectedCluster(null)
    setSelectedSector(null)
    setSelectedCountry(null)
    setSelectedDistrict(null)
    setSelectedState(null)
    setMasterObject(initialState)
    toTop()
  }
  function handleTableData() {
    get(`/recruiter`).then(res => {
      console.log(res, "lodkl") 
      let data = []
      let result = res?.data
      result?.map((item, index) => {
        console.log(item);
        item.id = index + 1
        item.date = moment(item?.date).format("DD-MM-YYYY")
       item.added =item?.addedBy?.firstName
        item.action = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                navigate(`/recruiter/${item?._id}`)
              }}
              // onClick={() => {
              //   navigate(`/recruiter/${item?.companyName}`, {
              //     state: { id: item?._id },
              //   })
              // }}
            ></i>
            {/* <i
              className="far fa-edit"
              onClick={() => {
                handleEdit(item)
              }}
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              onClick={() => {
                handleDelete(item?._id)
              }}
              style={{ fontSize: "1em", cursor: "pointer" }}
            ></i> */}
          </div>
        )
        data.push(item)
      })
      setTableListData(data)
    })
  }

  // const handleSubmit = () => {
  //   if (masterObject._id) { 
  //     put(`/recruiter/edit/${masterObject._id}`, masterObject).then(res => {
  //       toastr.success(res.message)
  //       handleTableData()
  //       formRef.current.reset()
  //       reset()
  //     }).catch(err => {
  //       toastr.error(err.response.data.message)
  //     })
  //   } else {
  //     post(`/recruiter/create`, masterObject)
  //       .then(res => {
  //         toastr.success(res.message)
  //         handleTableData()
  //         formRef.current.reset()
  //         reset()
  //       })
  //       .catch(err => {
  //         toastr.error(err.response.data.message)
  //       })
  //   }
  // }
  // const handleEdit = item => {

  //   toTop()
  //   setSelectedSector({
  //     label: item?.sector.label,
  //     value: item?.sector?.value,
  //   })
  //   setSelectedCountry({
  //     label: item?.country.label,
  //     value: item?.country?.value,
  //   })
  //   setSelectedDistrict({
  //     label: item?.district_name.label,
  //     value: item?.district_name.value,
  //   })
  //   setSelectedState({
  //     label: item?.state_name.label,
  //     value: item?.state_name.value,
  //   }) 
  //   setMasterObject({
  //     _id:item._id,
  //     name: item.name,
  //     shortCode: item?.shortCode,
  //     sector: item?.sector?.value,
  //     mobile: item?.mobile,
  //     landline: item?.landline,
  //     email: item?.email,
  //     website: item?.website,
  //     address: item?.address,
  //     country: item?.country?.value,
  //     state: item.state_name.value,
  //     district: item.district_name.value,
  //     pin: item.pin,
  //     gstIn: item.gstiIn,
  //     latitude: item?.latitude,
  //     longitude: item?.longitude,
  //     logo: item.logo,
  //   })
  // }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 50,
      },
      // {
      //   label: "Date",
      //   field: "date",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Company ID",
        field: "companyId",
        sort: "asc",
        width: 270,
      },
      {
        label: "Company Name",
        field: "companyName",
        sort: "asc",
        width: 270,
      },
      {
        label: "Code",
        field: "shortcode",
        sort: "asc",
        width: 200,
      },
      {
        label: "Contact Person",
        field: "person",
        sort: "asc",
        width: 200,
      },
        {
        label: "Mobile",
        field: "mob",
        sort: "asc",
        width: 10,
      },
      {
        label: "Job Post",
        field: "jobPostCount",
        sort: "asc",
        width: 10,
      },
      {
        label: "Internship",
        field: "internshipCount",
        sort: "asc",
        width: 10,
      },
      {
        label: "Added By",
        field: "added",
        sort: "asc",
        width: 10,
      },
      // {
      //     label: "Status",
      //     field: "status",
      //     sort: "asc",
      //     width: 10,
      // },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: tableListData,
  }
  // function handleSelect(selected, name) {
  //   switch (name) {
  //     case "sector":
  //       setSelectedSector(selected)
  //       setMasterObject({
  //         ...masterObject,
  //         sector: selected.value,
  //       })
  //       break
  //     case "cluster":
  //       setSelectedCluster(selected)
  //       setMasterObject({
  //         ...masterObject,
  //         cluster: selected.value,
  //       })
  //       dispatch(getSectors(selected.sector))
  //       break
  //     case "country":
  //       setSelectedCountry(selected)
  //       setMasterObject({ ...masterObject, country: selected.value })
  //       break
  //     case "state":
  //       setSelectedState(selected)
  //       setMasterObject({ ...masterObject, state: selected.value })
  //       dispatch(getDistrictOptions({ id: selected.value }))

  //       break
  //     case "district":
  //       setSelectedDistrict(selected)
  //       setMasterObject({ ...masterObject, district: selected.value })
  //       break
  //     default:
  //       break
  //   }
  // }

  // const handleValueChange = (value, name) => {
  //   const obj = { ...masterObject }
  //   obj[name] = value
  //   setMasterObject(obj)
  // }

  // function handleDelete(id) {
  //   return Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //   }).then(result => {
  //     if (result.isConfirmed) {
  //       del(`/recruiter/delete/${id}`)
  //         .then(res => {
  //           toastr.success(res.message)
  //           handleTableData()
  //           formRef.current.reset()
  //         })
  //         .catch(err => {
  //           toastr.success(err.response.data.message)
  //         })
  //     }
  //   })
  // }
  // const uploadImage = e => {
  //   const file = e.target.files[0]
  //   const name = e.target.name
  //   const fd = new FormData()
  //   fd.append(name, file)

  //   post(`recruiter/image`, fd).then(response => {
   
  //     if (response.status === 200) {
  //       toastr.success("image uploaded")

  //       setMasterObject({
  //         ...masterObject,
  //         logo: response.data.filename,
  //       })
  //     } else {
  //       toastr.error(response.data.message)
  //     }
  //   })
  // }
  return (
    <React.Fragment>
      {/* <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h5>Recruiters Details</h5>
          </div>

          <button
            type="button"
            onClick={() => setPopupView(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body" style={{ paddingTop: "0px" }}>
          <Row>
            <Col md="12">
              <table
                id="product_available_day"
                className="table table-bordered dataTable"
              >
                <tr>
                  <th style={{ padding: "10px 11px", textAlign: "left" }}>
                    Recruiters Name
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>UST Global</td>
                  <th style={{ textAlign: "left" }}>Short Code</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    ust
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>
                    Industry{" "}
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>
                    Information technology
                  </td>
                  <th style={{ textAlign: "left" }}>Mobile</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    (0471) 4040000{" "}
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>
                    PLandline No.
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>(0471) 4040000</td>
                  <th style={{ textAlign: "left" }}>Email </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    ustinfo@gmail.com
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>
                    Website
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    www.ust.com
                  </td>
                  <th style={{ textAlign: "left" }}>Address</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    {" "}
                    Orange County
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>
                    Country
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    United States
                  </td>
                  <th style={{ textAlign: "left" }}>State</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    California
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>Logo</th>
                  <th> : </th> 
                  <img
                    src="https://asapkerala.gov.in/wp-content/uploads/2021/11/UST.png"
                    alt=""
                    width="100"
                    height="50"
                  />
                </tr>
              </table>
            </Col>
          </Row>
        </div>
      </Modal> */}
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Home" breadcrumbItem="Recruiters" />

        

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="companyTableId1"
                    responsive
                    bordered
                    data={data}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Company
