import React, { useEffect, useState } from "react"
import { Row, Col } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import { Box, Divider } from "@mui/material"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import GradingIcon from "@mui/icons-material/Grading"
import { get } from "../../helpers/api_helper"
import { useLocation } from "react-router-dom"
import { dateConverter, setTitle } from "../../helpers/functions"
import { API_URL } from "../../config"
import "./style.scss"
const RenewDetails = props => {
  setTitle("Renew Details")
  const { state } = useLocation()
  const { id } = state
  const [tab3, setTab3] = useState("1")
  const [tableData, setTableData] = useState([])
  const [masterObject, setMasterObject] = useState({
    date: "",
    validity: "",
    person: "",
    companyName: "",
    agreement: "",
  })
  useEffect(() => {
    handleData()
    // handleRenewHistory()
    // eslint-disable-next-line
  }, [id])
  const handleData = () => {
    get(`agreement/renew/details?id=${id}`).then(res => {
      const data = res.data
      console.log(data)
      data.date = dateConverter(data.agreementDate)
      data.validity = data.validity
        ? dateConverter(data.validity)
        : dateConverter(data.createdValidity)

      const documents = data.documents || []
      delete data.documents
      setMasterObject(data)

      documents?.map((item, index) => {
        item.id = index + 1
        item.options = item.file ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <a href={API_URL + item.file} target="_blank" rel="noreferrer">
              <i
                className="fas fa-file-pdf"
                style={{
                  fontSize: "1rem",
                  cursor: "pointer",
                  color: "black",
                  marginLeft: "0.2rem",
                  marginRight: "0.5rem",
                }}
              ></i>
            </a>
          </div>
        ) : (
          ""
        )

        setTableData(documents)

        return item
      })
    })
  }

  // const handleRenewHistory = () => {
  //   get(`agreement/details?id=${id}`).then(res => {
  //     const data = res.data || []
  //     // renewDate: 1, person: 1, validity: 1
  //     data.map((item, index) => {
  //       item.date = dateConverter(item.renewDate)
  //       item.validity = dateConverter(item.validity)
  //       return item
  //     })

  //     setRenewHistory(data)
  //   })
  // }

  const handleChangeTab = (event, newValue) => {
    setTab3(newValue)
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Document Name",
        field: "name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Document",
        field: "options",
        sort: "asc",
        width: 200,
      },
    ],
    rows: tableData,
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem={masterObject.agreement} />
          <Row>
            <Col xl="12">
              {/* First Dialogue */}

              <div>
                <div className="row">
                  <div className="col-xl-3">
                    <div
                      className="card"
                      style={{
                        // width: "fit-content",
                        // width: "351px",
                        height: "max-content",
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                      }}
                    >
                      <div className="card-body">
                        <div className="float-end dropdown"></div>
                        <h5
                          className="mt-2 mb-2"
                          style={{
                            textAlign: "center",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}
                        >
                          {masterObject.agreement}
                        </h5>
                        <div
                          className="text-muted"
                          style={{
                            textAlign: "center",
                            color: "green !important",
                            fontFamily: "IBM Plex Sans,sans-serif",
                            fontSize: "14px",
                            marginBottom: "3px",
                          }}
                        >
                          {/* {!renew ? (
                            <div
                              class="btn-group btn-group-sm"
                              role="group"
                              aria-label="Basic example"
                            >
                              <button
                                class="btn btn-success"
                                style={{
                                  padding: "5px 8px",
                                  fontSize: "13px",
                                }}
                                onClick={() => {
                                  navigate("/renewals", {
                                    state: {
                                      option1: {
                                        label: "Educational Alliance Compact",
                                        value: "Value 5",
                                      },
                                      option2: {
                                        label: "L&t Pvt.Ltd",
                                        value: "Value 6",
                                      },
                                    },
                                  })
                                }}
                              >
                                Renew Agreement
                              </button>
                            </div>
                          ) : (
                            ""
                          )} */}
                        </div>
                        <p
                          style={{
                            textAlign: "center",
                            marginBottom: "3px",
                          }}
                        ></p>

                        <Divider />

                        <div className="mt-2">
                          <p className="mb-1">CSP:</p>
                          <h5 className="font-size-14">
                            {masterObject.branch}
                          </h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">Renewed Date:</p>
                          <h5 className="font-size-14">{masterObject.date}</h5>
                        </div>
                        {masterObject.person ? (
                          <div className="mt-2">
                            <p className="mb-1">Renewed Person:</p>
                            <h5 className="font-size-14">
                              {masterObject.person}
                            </h5>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="mt-2">
                          <p className="mb-1">Valid Up To:</p>
                          <h5 className="font-size-14">
                            {masterObject.validity}
                          </h5>
                        </div>
                        {/* <div className="mt-2">
                          <p className="mb-1">Training Partner:</p>
                          <h5 className="font-size-14">
                            {masterObject.partner}
                          </h5>
                        </div> */}
                        <div className="mt-2">
                          <p className="mb-1">Company:</p>
                          <h5 className="font-size-14">
                            {masterObject.partner}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-9" style={{ marginBottom: "30px" }}>
                    <div
                      className="mb-0 card"
                      style={{
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={tab3}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              indicatorColor="primary"
                              textColor="primary"
                              variant="fullWidth"
                              onChange={handleChangeTab}
                              aria-label="lab API tabs example"
                            >
                              <Tab
                                icon={
                                  <GradingIcon style={{ fontSize: "20px" }} />
                                }
                                label="Documents"
                                value="1"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                            </TabList>
                          </Box>
                          <TabPanel
                            value="1"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col className="col-12">
                                <MDBDataTable
                                  id="appliedDataId"
                                  responsive
                                  bordered
                                  data={data}
                                  searching={true}
                                  info={false}
                                  disableRetreatAfterSorting={true}
                                />
                              </Col>
                            </Row>
                          </TabPanel>
                        </TabContext>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default RenewDetails
