import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { USER_ALLOTTED } from "./actionTypes"
import { userAllottedSuccess } from "./actions"

//Include Both Helper File with needed methods

import {
  getAllotedBranch,
  // postFakeProfile,
  // postJwtProfile,
} from "../../../helpers/backend_helper"
import { PRIVILEGES } from "../../../config"

// const fireBaseBackend = getFirebaseBackend()

// function* editProfile({ payload: { user } }) {
//   try {
//     if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
//       const response = yield call(
//         fireBaseBackend.editProfileAPI,
//         user.username,
//         user.idx
//       )
//       yield put(profileSuccess(response))
//     } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
//       const response = yield call(postJwtProfile, "/post-jwt-profile", {
//         username: user.username,
//         idx: user.idx,
//       })
//       yield put(profileSuccess(response))
//     } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
//       const response = yield call(postFakeProfile, {
//         username: user.username,
//         idx: user.idx,
//       })
//       yield put(profileSuccess(response))
//     }
//   } catch (error) {
//     yield put(profileError(error))
//   }
// }

function* fetchAllotedDetails({ payload }) {
  try {
    const data = JSON.parse(localStorage.getItem("authUser") || {})
    const response = yield call(getAllotedBranch, payload)
    if (
      !sessionStorage.getItem("branch") &&
      data.privilage !== PRIVILEGES.ADMIN &&
      data.privilage !== PRIVILEGES.ZONAL_HEAD &&
      data.privilage !== PRIVILEGES.BUSINESS_HEAD &&
      data.privilage !== PRIVILEGES.ASSESSMENT_PM &&
      data.privilage !== PRIVILEGES.CERTIFICATE_PM &&
      data.privilage !== PRIVILEGES.FINANCE_PROGRAMME_MANAGER
    ) {
      sessionStorage.setItem(
        "branch",
        JSON.stringify(response?.data?.branches[0])
      )
    }
    yield put(userAllottedSuccess(response))
  } catch (error) {
    console.log(error)
    return error
  }
}

export function* watchProfile() {
  // yield takeEvery(EDIT_PROFILE, editProfile)
  yield takeEvery(USER_ALLOTTED, fetchAllotedDetails)
}
function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
