import React, { useEffect, useState } from "react"
import { Button, Col, Row } from "reactstrap"
import { Editor } from "react-draft-wysiwyg"
import draftToHtml from "draftjs-to-html"
import toastr from "toastr"
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js"
import { post } from "../../../helpers/api_helper"

const Overview = ({ details, ...props }) => {
  const [editor, setEditor] = useState(() => EditorState.createEmpty())

  const [master, setMaster] = useState({ overview: null })

  useEffect(() => {
    const overview = draftToHtml(convertToRaw(editor.getCurrentContent()))
    setMaster(pre => ({ ...pre, overview }))
  }, [editor])

  useEffect(() => {
    if (details?.overview) {
      const blocksFromHTML = convertFromHTML(details?.overview)
      setEditor(
        EditorState.createWithContent(
          customContentStateConverter(
            ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks,
              blocksFromHTML.entityMap
            )
          )
        )
      )
    }
  }, [details])

  const customContentStateConverter = contentState => {
    // changes block type of images to 'atomic'
    const newBlockMap = contentState.getBlockMap().map(block => {
      const entityKey = block.getEntityAt(0)
      if (entityKey !== null) {
        const entityBlock = contentState.getEntity(entityKey)
        const entityType = entityBlock.getType()
        switch (entityType) {
          case "IMAGE": {
            const newBlock = block.merge({
              type: "atomic",
              text: "img",
            })
            return newBlock
          }
          default:
            return block
        }
      }
      return block
    })
    const newContentState = contentState.set("blockMap", newBlockMap)
    return newContentState
  }

  const handleSubmit = () => {
    post("branch/overview", { ...master, id: details._id }).then(res => {
      toastr.success(res.message)
      props.reloadToggle()
    })
  }

  return (
    <Row>
      <Col md={12} className="branch-overview">
        <Editor editorState={editor} onEditorStateChange={setEditor} />
      </Col>
      <Col md="12" className="d-flex justify-content-end mt-2 ">
        <Button
          color="primary"
          type="button"
          onClick={handleSubmit}
          className="px-4 me-2"
        >
          Save
        </Button>
      </Col>
    </Row>
  )
}

export default Overview
