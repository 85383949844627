import React, { useState, useRef, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"

import toastr from "toastr"
import Swal from "sweetalert2"

import moment from "moment"

import "./style.scss"

import { del, get, post, put } from "../../../helpers/api_helper"
import { getAccountType } from "../../../store/options/actions"
import { setTitle } from "../../../helpers/functions"
const AccountDetailType = ({ options }) => {
  setTitle("Account Sub Head")
  const [tableData, setTableData] = useState([])
  const formRef = useRef()

  const { accountType } = useSelector(state => state.Options)
  const dispatch = useDispatch()

  const [masterObject, setMasterObject] = useState({ name: "", type: "" })

  useEffect(() => {
    handleTableData()
    dispatch(getAccountType())
    // eslint-disable-next-line
  }, [])

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  const handleTableData = async () => {
    get("/accounts/sub-type/list").then(res => {
      const result = res.data
      result.map((item, index) => {
        item.id = index + 1

        if (item.updated) item.staff = item.updated
        else item.staff = item.added
        if (item.upDate) item.date = moment(item.upDate).format("DD-MM-YYYY")
        else item.date = moment(item.date).format("DD-MM-YYYY")
        if (item.upTime)
          item.time = moment(item.upTime, "HH:mm:ss").format("hh:mm a")
        else item.time = moment(item.time, "HH:mm:ss").format("hh:mm a")

        if (options.edit)
          item.options = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="far fa-edit"
                onClick={() => handleEdit(item)}
                style={{
                  cursor: "pointer",
                  fontSize: "1em",
                  marginRight: "0.5rem",
                  marginLeft: "0.5rem",
                }}
              ></i>
              <i
                className="far fa-trash-alt"
                onClick={() => handleDelete(item._id)}
                style={{ cursor: "pointer", fontSize: "1em" }}
              ></i>
            </div>
          )

        return item
      })

      setTableData(result)
    })
  }

  const handleValueChange = async (value, name) => {
    const obj = { ...masterObject }
    obj[name] = value
    setMasterObject(obj)
  }

  const handleSubmit = () => {
    if (masterObject.id) {
      put("/accounts/sub-type/update", masterObject)
        .then(res => {
          reset()
          toastr.success(res.message)
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    } else {
      post("/accounts/sub-type/add", masterObject)
        .then(res => {
          reset()

          toastr.success(res.message)
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    }
  }

  const handleEdit = item => {
    toTop()
    setMasterObject({
      id: item._id,
      name: item.name,
      type: item.typeId,
    })
  }

  const handleDelete = id => {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`/accounts/sub-type/delete?id=${id}`)
          .then(res => {
            toastr.success(res.message)
            handleTableData()
          })
          .catch(err => {
            toastr.success(err.response.data.message)
          })
      }
    })
  }

  const reset = () => {
    formRef.current.reset()
    handleTableData()
    setMasterObject({ name: "" })
    toTop()
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        width: 150,
      },
      {
        label: "Account Head",
        field: "type",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        width: 150,
      },
      {
        label: "Added By",
        field: "staff",
        width: 150,
      },

      {
        label: "Actions",
        field: "options",
        sort: "desc",
        width: 400,
      },
    ],
    rows: tableData,
  }
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Account Sub Head" />
          {options.edit ? (
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm onValidSubmit={handleSubmit} ref={formRef}>
                      <Row>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Account Head</Label>
                            <Select
                              name="type"
                              placeholder="Select..."
                              options={accountType}
                              value={accountType.filter(
                                item => item.value === masterObject.type
                              )}
                              onChange={selected => {
                                handleValueChange(selected.value, "type")
                              }}
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label>Name</Label>
                            <AvField
                              name="name"
                              className="form-control"
                              type="text"
                              value={masterObject.name}
                              onChange={e => {
                                handleValueChange(e.target.value, "name")
                              }}
                              validate={{ required: { value: true } }}
                              placeholder="Name"
                              errorMessage="Enter Name"
                            />
                          </div>
                        </Col>

                        <Col>
                          <div
                            className="mb-3 d-flex gap-2"
                            style={{ paddingTop: "25px" }}
                          >
                            <Button
                              color={`${
                                masterObject.id ? "warning" : "primary"
                              }`}
                              type="submit"
                            >
                              {masterObject.id ? "Update" : "Submit"}
                            </Button>
                            <Button color="danger" onClick={reset}>
                              Reset
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            ""
          )}

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="accountDetailTypeTable"
                    bordered
                    responsive
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default AccountDetailType
