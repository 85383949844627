import React, { useEffect, useRef, useState } from "react"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { Button, Card, CardBody, Col, Label, Modal, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import { MDBDataTable } from "mdbreact"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { getCourses } from "../../../store/options/actions"
import { post, put, del, get } from "../../../helpers/api_helper"
import "./style.css"
import toastr from "toastr"
import Swal from "sweetalert2"
import SelectErrorMessage from "../../../components/Common/SelectErrorMessage"
import { setOptions } from "leaflet"
import { jsonToExcel } from "../../../helpers/functions"
import moment from "moment"

const Faq = () => {
  const dispatch = useDispatch()
  const formRef = useRef()
  const filterRef = useRef()

  const initialState = {
    placements: "",
    category: "",
    course: "",
    question: "",
    answer: "",
    url: "",
  }
  const selectedState = {
    course: "",
    category: "",
    placements: "",
  }
  const selectedState2 = {
    course: "",
    category: "",
    placements: "",
  }
  const errorState = {
    placements: "",
    course: "",
    category: "",
  }
  // const categoryOptions = [
  //   { label: "Course", value: 1 },
  //   { label: "Placements", value: 2 },
  //   { label: "Account", value: 3 },
  //   { label: "Community", value: 4 },
  //   { label: "Fee", value: 5 },
  //   { label: "Scholarship", value: 6 },
  //   { label: "Skill Loan", value: 7 },
  // ]

  const { courses } = useSelector(state => state.Options)
  const [course, setCourse] = useState([])
  const [masterObject, setMasterObject] = useState(initialState)
  const [filterObject, setFilterObject] = useState({})
  const [courseOptions, setCourseOptions] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])
  const [tableData, setTableData] = useState([])
  const [exportData, setExportData] = useState({})
  const [selected, setSelected] = useState(selectedState2)
  const [selected2, setSelected2] = useState(selectedState2)
  const [error, setError] = useState(errorState)
  const [state, setState] = useState(false)
  const [state2, setState2] = useState(false)

  const [placements, setPlacements] = useState([])

  useEffect(() => {
    dispatch(getCourses())
    handleFilterCourseOptions()
    // eslint-disable-next-line
  }, [state])

  useEffect(() => {
    get("options/courses").then(res => setCourse(res.data))
  }, [state])

  useEffect(() => {
    get("faq/op").then(res => setCategoryOptions(res.data))
  }, [state])

  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [filterObject, state])

  useEffect(() => {
    if (selected.category?.value == 2) {
      fetchPlacemts()
    }
  }, [selected])

  const handleFilterCourseOptions = () => {
    get("options/courses").then(res => {
      setCourseOptions(res.data)
    })
  }

  const fetchPlacemts = async () => {
    try {
      const response = await get("/options/placements")
      setPlacements(response?.data)
    } catch (error) {}
  }

  const categoryMap = {
    1: "Course",
    2: "Placement",
    3: "Account",
    4: "Community",
    5: "Fee",
    6: "Scholarship",
    7: "Skill Loan",
  }

  const handleTableData = () => {
    // courseId, category
    get(
      `faq?courseId=${filterObject.course}&placementId=${filterObject.placement}&category=${filterObject.category}`
    ).then(res => {
      const result = res.data || []
      const exportData = []
      result.map((item, index) => {
        item.id = index + 1
        item.categoryName = categoryMap[item.category]
        item.answerData = item.answer
        item.answer1 = (
          <>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  id="button-tooltip-2"
                  style={{ position: "absolute", top: "-30px" }}
                >
                  {item.answer}
                </Tooltip>
              }
            >
              <div>
                {item.answer?.slice(0, 350)}
                {item.answer.length > 350 ? "..." : ""}{" "}
              </div>
            </OverlayTrigger>
          </>
        )
        item.question2 = (
          <>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  id="button-tooltip-2"
                  style={{ position: "absolute", top: "-30px" }}
                >
                  {item.question}
                </Tooltip>
              }
            >
              <div>
                {item.question?.slice(0, 350)}
                {item.question.length > 350 ? "..." : ""}{" "}
              </div>
            </OverlayTrigger>
          </>
        )
        item.url2 = (
          <a href={item.url} target="_blank" rel="noopener noreferrer">
            {item.url}
          </a>
        )
        item.options = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="far fa-edit"
              onClick={() => handleEdit(item)}
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              onClick={() => handleDelete(item._id)}
              style={{ cursor: "pointer", fontSize: "1em" }}
            ></i>
          </div>
        )
        const dataExport = {}
        dataExport.id = item.id
        dataExport.Category = item.categoryName
        dataExport.Question = item.question
        dataExport.answer = item.answer
        dataExport.Url = item.url
        exportData.push(dataExport)
        return item
      })
      setExportData(exportData)
      setTableData(result)
    })
  }
  const handleExportData = () => {
    if (exportData.length > 0) {
      jsonToExcel(exportData, `${`Faqs`}-${moment().format("DD-MM-YY")}`)
    } else {
      toastr.info(`There are No Data to export`)
    }
  }
  const handleSubmit = () => {
    let alreadyError = false
    if (!masterObject.category) {
      alreadyError = true
      setError({ ...error, category: true })
    }

    function isValidUrl(url) {
      const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/
      return urlPattern.test(url)
    }

    // Example usage
    if (
      masterObject?.url != "" &&
      masterObject?.url != undefined &&
      masterObject?.url != null &&
      !isValidUrl(masterObject.url)
    ) {
      return toastr.error(
        "The URL is not in the correct format. Please include 'http://' or 'https://'."
      )
    }

    if (alreadyError) {
      return
    }

    if (masterObject.id) {
      put("faq", masterObject)
        .then(res => {
          toastr.success(res.message)
          reset()
          setState(!state)
        })
        .catch(err => {
          toastr.error(err.response.message)
        })
    } else {
      //  placements = 2 , course = 1
      post(`faq?type=${selected.category?.value == 2 ? 2 : 1}`, masterObject)
        .then(res => {
          toastr.success(res.message)
          reset()
        })
        .catch(err => {
          toastr.error(err.response.message)
        })
    }
  }

  const handleEdit = async item => {
    toTop()

    const category = categoryOptions.filter(
      option => item.category === option.value
    )
    if (category[0]?.value == 2) {
      fetchPlacemts()
      let selected = { label: "Placements", value: 2 }
      handleSelectedValueChange(selected, "category")
    }

    let newSelected = {
      category: category[0],
    }

    if (item.category == 1) {
      newSelected["course"] = { label: item?.course, value: item?.courseId }
    }

    if (item.category == 2) {
      let label = {
        name: item?.studentDetails?.name,
        uniqueId: item?.studentDetails?.uniqueId,
      }
      let value = item.placementId
      newSelected["placements"] = { label: label, value: value }
    }

    setSelected(newSelected)
    setMasterObject({
      id: item._id,
      question: item.question,
      answer: item.answerData,
      course: item.courseId,
      placements: item?.placementId,
      category: item?.category,
      url: item?.url,
    })
  }
  const handleDelete = async id => {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`faq?id=${id}`)
          .then(res => {
            toastr.success(res.message)
            handleTableData()
          })
          .catch(err => {
            toastr.error(err.response.data.message)
          })
      }
    })
  }

  const handleValueChange = (value, name) => {
    const obj = { ...masterObject }
    obj[name] = value
    setMasterObject(obj)
  }
  const handleFilterValueChange = (value, name) => {
    if (value == 2) {
      fetchPlacemts()
    }
    const obj = { ...filterObject }
    obj[name] = value
    setFilterObject(obj)
  }
  const handleSelectedValueChange = (data, name) => {
    const obj = { ...selected }
    obj[name] = data
    handleValueChange(data.value, name)
    setSelected(obj)
    setError(prev => ({ ...prev, [name]: false }))
  }

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  const reset = () => {
    formRef.current.reset()
    setMasterObject(initialState)
    setSelected(selectedState)
    setError({})
    toTop()
    setState(!state)
    // setCourse([])
  }

  const reset2 = () => {
    filterRef.current.reset()
    setFilterObject({})
    // setCourseOptions([])
    setState(!state)
    setSelected2(selectedState2)
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Category",
        field: "categoryName",
        width: 150,
      },

      {
        label: "Question",
        field: "question2",
        width: 150,
      },
      {
        label: "Answer",
        field: "answer1",
        width: 150,
      },
      {
        label: "Video URL",
        field: "url2",
        width: 150,
      },
      {
        label: "Action",
        field: "options",
        width: 150,
      },
    ],
    rows: tableData,
  }
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem="FAQ" />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="needs-validation"
                    ref={formRef}
                    onValidSubmit={handleSubmit}
                  >
                    <Row>
                      <Col md="2">
                        <Label>Category</Label>
                        <Select
                          name="domain"
                          classNamePrefix="select2-selection"
                          options={categoryOptions}
                          value={selected.category || ""}
                          onChange={selected => {
                            handleSelectedValueChange(selected, "category")
                          }}
                        />
                        <SelectErrorMessage
                          customStyle={{
                            width: "100%",
                            fontSize: "87.5%",
                            color: "var(--bs-form-invalid-color)",
                          }}
                          message="Select Category"
                          show={error.category}
                        />
                      </Col>

                      <Col md="6">
                        <Label>Question</Label>
                        <AvField
                          name="question"
                          placeholder="Question"
                          className="form-control"
                          errorMessage="Enter Question"
                          validate={{ required: { value: true } }}
                          value={masterObject.question}
                          onChange={e => {
                            handleValueChange(e.target.value, e.target.name)
                          }}
                        />
                      </Col>

                      <Col md="4">
                        <Label>Answer</Label>
                        <AvField
                          name="answer"
                          placeholder="Answer"
                          className="form-control"
                          errorMessage="Enter Answer"
                          validate={{ required: { value: true } }}
                          type="textarea"
                          rows={1}
                          value={masterObject.answer}
                          onChange={e => {
                            handleValueChange(e.target.value, e.target.name)
                          }}
                        />
                      </Col>

                      <Col md="6">
                        <Label>Video URL</Label>
                        <AvField
                          name="url"
                          placeholder="Video URL"
                          className="form-control"
                          // errorMessage="Enter Video URL"
                          // validate={{ required: { value: true } }}
                          value={masterObject.url}
                          onChange={e => {
                            handleValueChange(e.target.value, e.target.name)
                          }}
                        />
                      </Col>
                      <Col md="3" style={{ marginTop: "26px" }}>
                        <Button
                          color={masterObject.id ? "warning" : "primary"}
                          type="submit"
                        >
                          {masterObject.id ? "Update" : "Submit"}
                        </Button>
                        <Button
                          color="danger"
                          type="button"
                          onClick={reset}
                          className="ms-2"
                        >
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Row>
                    <AvForm ref={filterRef}>
                      <Row>
                        <Col md="2">
                          <Label>Category</Label>
                          <Select
                            name="domain"
                            classNamePrefix="select2-selection"
                            options={categoryOptions}
                            value={selected2.filterCategory || ""}
                            onChange={selected => {
                              handleFilterValueChange(
                                selected.value,
                                "category"
                              )
                              setSelected2(prev => ({
                                ...prev,
                                filterCategory: selected,
                              }))
                            }}
                          />
                        </Col>

                        <Col md="3"  style={{ marginTop: "1.8rem" }}>
                          <Button
                            color="danger"
                            type="submit"
                            className="me-2"
                           
                            onClick={reset2}
                          >
                            Reset
                          </Button>
                          <Button onClick={handleExportData} color="warning">
                            Export
                          </Button>
                        </Col>
                      </Row>
                    </AvForm>
                  </Row>
                  <Row>
                    <MDBDataTable
                      id="faqTableId"
                      responsive
                      bordered
                      info={true}
                      searching={true}
                      disableRetreatAfterSorting={true}
                      entries={20}
                      data={data}
                    />
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default Faq
