import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import Select from "react-select"
import { MenuItem, TextField } from "@mui/material"
import { get, put } from "../../../helpers/api_helper"
import toastr from "toastr"
import { useLocation } from "react-router-dom"
import MuiCustomTablePagination from "../../../components/Common/TablePagination"
import Swal from "sweetalert2"
import useBranch from "../../../hooks/useBranch"
import Admin from "../../../components/Common/Admin"

const InternalMarks = () => {
  const { state } = useLocation()

  
  const [branch] = useBranch()

  const [csp, setCsp] = useState(
    branch ? branch : { label: "Select...", value: "" }
  )
  const [batch, setBatch] = useState({ label: "Select...", value: "" })
  const [cspList, setCspList] = useState([])
  const [batchList, setBatchList] = useState([])
  const [internalExam, setInternalExam] = useState([])
  const [marks, setMarks] = useState([])
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(20)
  const [count, setCount] = useState(0)
  const [search, setSearch] = useState("")
  const [batchId, setBatchId] = useState("")
  const [internalsMarked, setInternalsMarked] = useState(true)
  const [disableButton, setDisableButton] = useState(false)



  const fetchCspList = () => {
    get(`options/branches`).then(res => {
      if (res.success) {
        setCspList(res.branches)
      }
    })
  }

  const fetchBatchList = () => {

    get(`assessment/cspBatch?branch=${csp.value}`).then(res => {
      if (res.success) {
        if (batchList.length !== 0) {
          setBatch({ label: "Select...", value: "" })
        }
        setBatchList(res.data)
      }
    })
  }

  const fetchStudents = () => {
    if (batch.value) {
      get(
        `assessment/internalMarkStudents?batch=${batch.value}&page=${page + 1}&limit=${pageSize}&keyword=${search}`
      )
        .then(res => {
          if (res.success) {
            setBatchId(res.data[0]._id)
            setInternalExam(res.data[0].internalExam || [])
            if (
              res.data[0].internalExam &&
              res.data[0].internalExam.length === 0
            ) {
              toastr.error("No internal exam found.")
            }
            setMarks(res.data[0].courseAssigned || [])
            setCount(res.data[0].Count)
            setInternalsMarked(res.data[0].internalsMarked)
            if (res.data[0].internalsMarked) {
              toastr.info("Internal marks already added.")
            }
            setDisableButton(false)
          }
        })
        .catch(err =>toastr.error(err.response.data.message))
    }
  }

  useEffect(() => {
    fetchStudents()
    // eslint-disable-next-line
  }, [batch, page, pageSize, search])

  useEffect(() => {
    if (state && state?.batchId) {
      setBatch({ label: state.batch, value: state.batchId })
      setCsp({ label: state.csp, value: state.cspId })
    }
    fetchCspList()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (csp.value) {
      fetchBatchList()
    }
    setSearch("")
    // eslint-disable-next-line
  }, [csp])

  const handleReset = () => {
    setBatch({ label: "Select...", value: "" })
    setCsp({ label: "Select...", value: "" })
    setBatchId(null)
    setBatchList([])
    setInternalExam([])
    setMarks([])
    setInternalsMarked(true)
  }

  const handleChangeRowsPerPage = event => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handlePageChange = newPage => {
    setPage(newPage)
  }

  const handleChange = (event, index, subIndex, type, maxMark) => {
    const { value } = event.target
    let numericValue
    if (type === "mark") {
      if (value > maxMark) {
        return
      }
      numericValue = value.replace(/[^0-9]/g, "")
    }else if(type === 'grade'){
      numericValue = value
    }

    setMarks(prevMarks => {
      const updatedMarks = [...prevMarks]
      updatedMarks[index].internalMarks[subIndex].mark = numericValue
      return updatedMarks
    })
  }

  const handleSubmit = (markObject, _id, index) => {
    if(markObject.mark === '' || markObject?.mark?.length === 0){
      console.log('Enter mark')
      return
    }
    const postBody = {
      id: _id,
      batch: batch.value,
      internalMark: { ...markObject, name: internalExam[index].name },
    }
    put(`assessment/updateInternal`, postBody)
      .then(res => {
        if (res.success) {
        }
      })
      .catch(err => console.log(err.message))
  }

  const handlePublish = id => {
    return Swal.fire({
      title: "Confirm publishing internal marks ?",
      text: "You won't be able to change this later.",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then(result => {
      if (result.isConfirmed) {
        put(`assessment/publishInternalMark`, { id: batchId })
          .then(res => {
            if (res.success) {
              toastr.success("Marks published successfully")
              setDisableButton(true)
              setInternalsMarked(true)
            }
          })
          .catch(err => {
            toastr.error(err.response.data.message)
          })
      }
    })
  }

  return (
    <div className="page-content">
      <div className="container-fluid" style={{ minHeight: "500px" }}>
        <Breadcrumb title="Home" breadcrumbItem="Internal Marks" />

        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <Row>
                  <Col md="12">
                    <Col xl={12}>
                      <Row className="nav-row">
                        <Col md="12">
                          <Col xl={12}>
                            <Row>
                            <Admin>
                                <Col sm={4} md={3}>
                                  <div>
                                    <Label>CSP Name</Label>
                                    <Select
                                      name="student"
                                      classNamePrefix="select2-selection"
                                      options={cspList}
                                      onChange={item => setCsp(item)}
                                      value={csp}
                                    />
                                  </div>
                                </Col>
                              </Admin>
                              <Col sm="4" md="3">
                                <div>
                                  <Label>Batch</Label>
                                  <Select
                                    name="batch"
                                    classNamePrefix="select2-selection"
                                    options={batchList}
                                    onChange={item => setBatch(item)}
                                    value={batch}
                                    placeholder="Select..."
                                    width={50}
                                    isDisabled={csp.value === ''}
                                  />
                                </div>
                              </Col>

                              <Col>
                                <Button
                                  type="button"
                                  style={{
                                    marginTop: "1.7rem",
                                    marginLeft: "1rem",
                                  }}
                                  color="danger"
                                  onClick={handleReset}
                                >
                                  Reset
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Col>
                      </Row>
                    </Col>
                  </Col>
                </Row>
                <Row style={{ marginBottom: "10px" }}>
                  <Col md="10"></Col>
                  <Col md="2">
                    <Input
                      placeholder="Search..."
                      value={search}
                      onChange={e => setSearch(e.target.value)}
                    ></Input>
                  </Col>
                </Row>

                <Row>
                  <Col xl="12">
                    <div style={{ overflow: "auto", maxWidth: "100%" }}>
                      <Table id="progressReportTableId" bordered>
                        <thead>
                          <tr>
                            <th style={{ verticalAlign: "middle" }} rowSpan={2}>
                              #
                            </th>
                            <th style={{ verticalAlign: "middle" }} rowSpan={2}>
                              Register No.
                            </th>
                            <th style={{ verticalAlign: "middle" }} rowSpan={2}>
                              Student
                            </th>
                            <th
                              style={{ verticalAlign: "middle" }}
                              colSpan={internalExam && internalExam.length}
                            >
                              Internal Marks
                            </th>
                          </tr>
                          <tr>
                            {internalExam &&
                              internalExam.length > 0 &&
                              internalExam.map((item, index) => (
                                <th key={index}>
                                  {item.name}
                                  {item.examType === "1"
                                    ? ` (${item.maxMark})`
                                    : ""}
                                </th>
                              ))}
                          </tr>
                        </thead>
                        <tbody>
                          {marks &&
                            (marks.length > 0 ? (
                              marks.map((item, index) => (
                                <tr key={index}>
                                  <th scope="row">{index + 1}</th>
                                  <td>{item.unique_id}</td>
                                  <td>
                                    {item.firstName || ''} {item.lastName || ''}
                                  </td>
                                  {item.internalMarks &&
                                    item.internalMarks.length > 0 &&
                                    item.internalMarks.map(
                                      (subItem, subIndex) => (
                                        <td key={subIndex}>
                                          {!internalsMarked ? (
                                            subItem.examType === "1" ? (
                                              <TextField
                                                value={subItem.mark}
                                                size="small"
                                                type="text"
                                                style={{ width: "55px" }}
                                                InputProps={{
                                                  style: {
                                                    height: "30px",
                                                    padding: "0px",
                                                  },
                                                }}
                                                onChange={e =>
                                                  handleChange(
                                                    e,
                                                    index,
                                                    subIndex,
                                                    'mark',
                                                    subItem.maxMark
                                                  )
                                                }
                                                onBlur={() =>
                                                  handleSubmit(
                                                    subItem,
                                                    item._id,
                                                    subIndex
                                                  )
                                                }
                                              />
                                            ) : subItem.examType === "2" ? (
                                              <TextField
                                                size="small"
                                                select
                                                style={{ width: "60px" }}
                                                value={subItem.mark}
                                                InputProps={{
                                                  style: {
                                                    height: "30px",
                                                    padding: 0,
                                                  },
                                                }}
                                                onChange={e =>
                                                  handleChange(
                                                    e,
                                                    index,
                                                    subIndex,
                                                    'grade'
                                                  )
                                                }
                                                onBlur={() =>
                                                  handleSubmit(
                                                    subItem,
                                                    item._id,
                                                    subIndex
                                                  )
                                                }
                                              >
                                                <MenuItem value="A">A</MenuItem>
                                                <MenuItem value="B">B</MenuItem>
                                                <MenuItem value="C">C</MenuItem>
                                                <MenuItem value="D">D</MenuItem>
                                                <MenuItem value="E">E</MenuItem>
                                                <MenuItem value="F">F</MenuItem>
                                              </TextField>
                                            ) : (
                                              ""
                                            )
                                          ) : (
                                            <h6>{subItem.mark}</h6>
                                          )}
                                        </td>
                                      )
                                    )}
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={internalExam.length + 4}>
                                  {" "}
                                  No rows{" "}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>

                      {marks.length > 0 && count > 20 && (
                        <MuiCustomTablePagination
                          count={count}
                          page={page}
                          rowsPerPage={pageSize}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                      )}

                      {marks.length > 0 &&
                        !internalsMarked &&
                        internalExam.length > 0 && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              type="button"
                              style={{
                                marginTop: ".5rem",
                                marginLeft: ".5rem",
                                marginRight: "50px",
                              }}
                              color="primary"
                              onClick={() => handlePublish()}
                              disabled={disableButton}
                            >
                              Publish Marks
                            </Button>
                          </div>
                        )}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default InternalMarks
