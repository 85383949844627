import React, { useRef, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import { Modal } from "react-bootstrap"
import "./style.scss"
import { get } from "../../../../helpers/api_helper"
import { useEffect } from "react"
import moment from "moment"
// import { useReactToPrint } from 'react-to-print';
import { jsonToExcel } from "../../../../helpers/functions"
import toastr from "toastr"
import MuiCustomTablePagination from "../../../../components/Common/TablePagination"


function Registration() {
  const [popupView, setPopupView] = useState(false)
  const [eventStudent, setEventStudent] = useState([])
  const [dataExports,setDataExports] = useState([])
  const [eventOptions, setEventOptions] = useState([])
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [slecetdstatus, setslecetdstatus] = useState(null)
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [count, setCount] = useState(0);

  const formRef = useRef()
  const initialState = {
    from: "",
    to: "",
    event: "",
  }

  const [filterObject, setFilterObject] = useState(initialState)

  useEffect(() => {
    handleTableData()
  }, [filterObject,page,pageSize])

  useEffect(() => {
    fetch_events()
  }, [])

  const toggle = (start = true) => {
    var body = document.body

    if (start) {
      body.classList.remove("vertical-collpsed")
      body.classList.remove("sidebar-enable")
      body.setAttribute("data-sidebar-size", "sm")
    } else {
      body.classList.add("vertical-collpsed")
      body.classList.add("sidebar-enable")
      body.setAttribute("data-sidebar-size", "lg")
    }
  }

  useEffect(() => {
    toggle()

    return () => {
      toggle(false)
    }
    // eslint-disable-next-line
  }, [])

  const handleValueChange = async (value, name) => {
    const obj = { ...filterObject }
    obj[name] = value
    setFilterObject(obj)
  }

  function fetch_events() {
    get("/events/listOptions").then(res => {
      var event_data = res.data.map(item => {
        return {
          label: item?.name,
          value: item?._id,
        }
      })
      setEventOptions([{ options: event_data }])
    })
  }

  function handleTableData(pageNo = page, limit = pageSize, exportOption= false) {
    const filterQuery = `from=${filterObject.from}&to=${filterObject.to}&event=${filterObject.event}&attendedStatus=${filterObject?.attendedStatus}`
    get(`/event-reg/list?${filterQuery}&page=${pageNo}&limit=${limit}`).then(res => {
      let result = res?.events
      const exportData = []
      setCount(res.totalCount)
      result.map((item,index)=>{
        let obj = {}
  
          obj.Date = item.date
          obj['Ticket No.'] = item?.uniqueId
          obj.Event = item?.event?.name
          obj.Name = `${item?.profile?.firstname || ""} ${item?.profile?.lastname || ""}`
          obj.Mobile = item?.profile?.mobile
          obj.Coupon = item?.lead?.coupon?.name
          obj.Redeem = item?.couponAmount || ""
          obj.Fee = item?.lead?.eventFee?.fee || ""
          obj['Payable Amount'] = item?.lead?.eventFee?.payableAmount || ""
          obj['Paid Amount'] = item?.paidAmount || ""
  
  
          item.id = index + 1 + (page*pageSize)
          item.date = moment(item?.date).format("DD-MM-YYYY")
          item.time = moment(item?.time, "hh:mm a").format("hh:mm a")
          item.profile1 =
            (item?.profile?.firstname ?? "") +
            " " +
            (item?.profile?.lastname ?? "")
          item.mobile = item?.profile?.mobile
          item.eventname = item?.event?.name
          item.eventid = item?.event?.uniqueId
          item.startdate1 = item?.event?.startDate
          item.payedStatus =
            item?.payedStatus === 0 ? (
              <>
                <span className="badge bg-danger font-size-12">Unpaid</span>
              </>
            ) : (
              <>
                <span className="badge bg-success font-size-12">Paid</span>
              </>
            )
  
          item.status1 = `${item?.attendance} / ${item?.eventBooking}`
  
          item.coupon = item?.lead?.coupon?.name ? (
            <span
              style={{
                background: "#50a5f1",
              }}
              className="badge  font-size-12 ms-2"
            >
              {item?.lead?.coupon?.name}
            </span>
          ) : (
            "-----"
          )
          item.redeem = item?.couponAmount ? item?.couponAmount : "-----"
          item.payableAmount = item?.lead?.eventFee?.payableAmount || "-----"
          item.fee = item?.lead?.eventFee?.fee || "-----"
          item.paidAmount = item?.paidAmount ? item.paidAmount : "-----"
          exportData.push(obj)
          return item
       })
      if(exportOption){
        // setDataExports(exportData)
        if (exportData.length > 0) {
          jsonToExcel(exportData, `Event-Registration-${moment().format("DD-MM-YY")}`)
        } else {
          toastr.info(`There are No Event Registration to export`)
        }
      }else{
      setEventStudent(result)
      }
    })
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      // {
      //     label: "Registration No.",
      //     field: "category",
      //     width: 150,
      // },
      {
        label: "Ticket No.",
        field: "uniqueId",
        width: 150,
      },
      {
        label: "Event",
        field: "eventname",
        width: 150,
      },
      // {
      //   label: "UniqueId",
      //   field: "eventid",
      //   sort: "desc",
      //   width: 400,
      // },
      {
        label: "Name",
        field: "profile1",
        sort: "desc",
        width: 400,
      },
      {
        label: "Mobile",
        field: "mobile",
        width: 150,
      },
      {
        label: "Coupon",
        field: "coupon",
        width: 150,
      },
      {
        label: "Redeem",
        field: "redeem",
        width: 150,
      },
      {
        label: "Fee",
        field: "fee",
        width: 150,
      },
      {
        label: "Payable Amount",
        field: "payableAmount",
        width: 150,
      },
      {
        label: "Paid Amount",
        field: "paidAmount",
        width: 150,
      },
      {
        label: "Paid Status",
        field: "payedStatus",
        width: 150,
      },
      {
        label: "Status",
        field: "status1",
        width: 150,
      },
    ],
    rows: eventStudent,
  }
  const reset = () => {
    formRef.current.reset()
    setFilterObject(initialState)
    setSelectedEvent(null)
    setslecetdstatus(null)
  }

  const handleChangeRowsPerPage = event => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handlePageChange = newPage => {
    setPage(newPage)
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Event Registration" />
          <Card>
            <CardBody>
              <Row>
                <Col xl="12">
                  <AvForm ref={formRef}>
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>From</Label>
                          <AvField
                            name="from"
                            className="form-control"
                            value={filterObject.from}
                            onChange={e => {
                              handleValueChange(e.target.value, "from")
                            }}
                            type="date"
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>To</Label>
                          <AvField
                            name="to"
                            value={filterObject.to}
                            onChange={e => {
                              handleValueChange(e.target.value, "to")
                            }}
                            className="form-control"
                            type="date"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Event</Label>
                          <Select
                            name="event"
                            classNamePrefix="select2-selection"
                            value={selectedEvent}
                            onChange={selected => {
                              handleValueChange(selected.value, "event")
                              setSelectedEvent(selected)
                            }}
                            options={eventOptions}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Status</Label>
                          <Select
                            name="attendedStatus"
                            classNamePrefix="select2-selection"
                            value={slecetdstatus}
                            onChange={selected => {
                              handleValueChange(
                                selected.value,
                                "attendedStatus"
                              )
                              setslecetdstatus(selected)
                            }}
                            options={[
                              { label: "Attendend", value: 1 },
                              { label: "Not Attended", value: "0" },
                            ]}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div style={{ paddingTop: "25px" }} className="mb-3">
                          <Button onClick={reset} color="danger" type="submit">
                            Reset
                          </Button>
                          <Button
                            type="button"
                            onClick={()=>handleTableData(0,count,true)}
                            color="warning"
                            style={{ marginLeft: "7px" }}
                          >
                            Export
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </Col>
              </Row>
              <Row>
                <Col className="col-12">
                  <MDBDataTable
                    id="registranTableId"
                    bordered
                    responsive
                    searching={true}
                    entries={20}
                    paging={false}
                    info={false}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>
                  {eventStudent.length > 0 && (
                    <MuiCustomTablePagination
                      count={count}
                      page={page}
                      rowsPerPage={pageSize}
                      handleChangePage={handlePageChange}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  )
}

export default Registration
