import React, { useState, useEffect,useRef} from 'react'
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap"
import Select from "react-select"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumb from '../../../../components/Common/Breadcrumb'
import { get, post } from '../../../../helpers/api_helper'
import { MDBDataTable } from "mdbreact"
import moment from 'moment'
import toastr from "toastr"

function ManualPayments() {
  const [eventOptions, setEventOptions] = useState([])
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [selectResourseperson, setselectResourseperson] = useState(null)
  const [studentOption, setstudentOption] = useState([])
  const [selectstudent, setselectstudent] = useState(null)
  const [master, setmaster] = useState({})
  const [tableData, settableData] = useState([])
  const formRef = useRef()

  function fetchEvents() {
    get("/events/listOptions").then(res => {

      var event_data = res.data.map(item => {
        return {
          label: item?.name +  (item.uniqueId ? " - " + item.uniqueId : ""),
          value: item?._id,
          person: item?.firstName + " " + item?.lastName,
          personId: item.resourcePerson,
        }
      })
      setEventOptions([{ options: event_data }])
    })
  }

  function fetchAllstudentEvent(id) {
    get(`/events/options/student?id=${id}`).then((res) => {
      console.log("studentfejo", res);
      let result = res?.data?.map((item, index) => {
        return {
          label: item.label,
          value: item._id
        }
      })
      setstudentOption(result)

    })
  }


  useEffect(() => {
    fetchEvents()
    getAllManualPayment()
  }, [])

  function handleselected(selected, name) {
    switch (name) {
      case "event":
        setSelectedEvent(selected);
        setselectResourseperson({ label: selected.person, value: selected.personId })
        fetchAllstudentEvent(selected.value)
        setmaster({ ...master, id: selected.value, eventAssignee: selected.personId })
        break;
      case "student":
        setselectstudent(selected)
        setmaster({ ...master, student: selected.value })
      default:
        break;
    }
  }

  function handleamount(e) {
    setmaster({ ...master, amount: e.target.value })
  }
console.log("master",master);


function validSubmit(e){
  e.preventDefault()
  post(`/event-manual/add`,master).then((res)=>{
    toastr.success(res.message)
  })
}


function getAllManualPayment(){
  get(`/event-manual/list`).then((res)=>{
    console.log("res",res);
    let result=res.data;
    result&&result.map((item,index)=>{
      item.id=index+1;
      item.date1=moment(item.date).format("DD-MM-YYYY");
      item.name=item.studentFirstName+" "+item.studentLastName
      return item
    })
    settableData(result)
  })
}

const data = {
  columns: [
    {
      label: "#",
      field: "id",
      width: 50,
      sort: "asc",
    },
    {
      label: "ID",
      field: "uniqueId",
      width: 50,
      sort: "asc",
    },
    {
      label: "Event Name",
      field: "event",
      width: 200,
      sort: "asc",
    },
    {
      label: "Student",
      field: "name",
      width: 150,
      sort: "asc",
    },
    {
      label: "Amount",
      field: "paid",
      width: 200,
      sort: "asc",
    },
  ],

  rows: tableData,
}

  return (
    <>
      <div className='page-content'>
        <div className='container-fluid'>
          <Breadcrumb title="Home" breadcrumbItem="Manual Payment" />
          <AvForm
          onValidSubmit={validSubmit}
          ref={formRef}
          >
            <Card>
              <CardBody>
                <Row>
                  <Col md="3">
                    <div className='mb-3'>
                      <Label>Event</Label>
                      <Select
                        options={eventOptions}
                        onChange={(selected) => {
                          handleselected(selected, "event")
                        }}
                        value={selectedEvent}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className='mb-3'>
                      <Label>Resourse Person</Label>
                      <Select
                        isDisabled={true}
                        value={selectResourseperson}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className='mb-3'>
                      <Label>Student</Label>
                      <Select
                        options={studentOption}
                        value={selectstudent}
                        onChange={(selected) => {
                          handleselected(selected, "student")
                        }}
                      />
                    </div>
                  </Col>
                  <Col md="2">
                    <div className='mb-3'>
                      <Label>Amount</Label>
                      <AvField name="amount" placeholder="Event Amount" onChange={handleamount} />
                    </div>
                  </Col>
                  <Col md="1">
                    <div style={{paddingTop:"25px"}}>
                    <Button type='submit' color='primary'>Submit</Button>

                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </AvForm>
          <Card>
            <CardBody>
            <Row>
                <Col md={12}>
                  <MDBDataTable
                    id="createTicket"
                    responsive
                    bordered
                    info={true}
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>

        </div>
      </div>
    </>

  )
}
export default ManualPayments
