import React, { useEffect, useRef, useState } from "react"
import { Container, Row, Col, Card, CardBody, Label, Button } from "reactstrap"
import Tooltip from "@mui/material/Tooltip"
import { AvField, AvForm } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { APP_NAME } from "../../config"
import Select from "react-select"
import { getCourses } from "../../store/options/actions"
import { useDispatch, useSelector } from "react-redux"
import { get, post } from "../../helpers/api_helper"
import toastr from "toastr"
import axios from "axios"
import { API_URL } from "../../config"
import { MDBDataTable } from "mdbreact"
import "./style.scss"

import _ from "lodash"
import { convertMinutesToHoursFormat } from "../../helpers/functions"
import moment from "moment"

const QualityFrameWork = () => {
  document.title = `Quality framework | ${APP_NAME}`

  const [logs, setLogs] = useState([])

  console.log("logs", logs)

  const [courses, setCourses] = useState([])

  const [isGreater, setIsGreater] = useState(false)

  const formRef = useRef()
  const initialState = {
    name: "",
    minutes: "",
    setSelectedCourses: "",
    qualityFramework: [],
  }

  const [masterObject, setMasterObject] = useState(initialState)

  const [totalSessionTime, setTotalSessionTime] = useState({ totalHour: 0 })

  const [selectedCourses, setSelectedCourses] = useState(null)

  const dispatch = useDispatch()
  // const { courses } = useSelector(state => state.Options)

  useEffect(() => {
    dispatch(getCourses())

    // eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    // handleTableData()
    partnerCourse()
    fetchLogs()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (
      selectedCourses?.unit === "1" &&
      totalSessionTime?.totalMinutes / 60 > selectedCourses?.duration
    ) {
      setIsGreater(true)
    } else {
      setIsGreater(false)
    }
  }, [selectedCourses, totalSessionTime])

  // const course1 = [
  //   { sessionname: "Session:" + "1".padStart(3, 0), time: "40" },
  //   { sessionname: "Session:" + "2".padStart(3, 0), time: "60" },
  // ]
  // const course2 = [
  //   { sessionname: "Session:" + "1".padStart(3, 0), time: "120" },
  //   { sessionname: "Session:" + "2".padStart(3, 0), time: "80" },
  //   { sessionname: "Session:" + "3".padStart(3, 0), time: "60" },
  // ]
  // const course3 = [
  //   { sessionname: "Session:" + "1".padStart(3, 0), time: "60" },
  //   { sessionname: "Session:" + "2".padStart(3, 0), time: "80" },
  //   { sessionname: "Session:" + "3".padStart(3, 0), time: "120" },
  //   { sessionname: "Session:" + "4".padStart(3, 0), time: "160" },
  // ]

  const reset = () => {
    formRef.current?.reset()
    // handleTableData()
    setSelectedCourses("")
    setMasterObject(initialState)
  }

  const handleChange = (value, name, index) => {
    const data = { ...masterObject }
    const pre = data.qualityFramework
    pre[index][name] = value

    data.qualityFramework = pre
    setMasterObject(data)
  }

  useEffect(() => {
    const [totalHour, totalMinutes] = calculateTotalHours(
      masterObject.qualityFramework
    )
    setTotalSessionTime({ totalHour, totalMinutes })
  }, [masterObject])

  const calculateTotalHours = sessionArray => {
    if (!Array.isArray(sessionArray)) {
      return
    }

    let totalMinutes = 0

    sessionArray.forEach(session => {
      const minutes = parseInt(session.minutes, 10)

      if (!isNaN(minutes) && minutes >= 0) {
        totalMinutes += minutes
      }
    })

    const totalHours = Math.floor(totalMinutes / 60)

    const remainingMinutes = totalMinutes % 60

    const formattedHours = totalHours.toString().padStart(2, "0")
    const formattedMinutes = remainingMinutes.toString().padStart(2, "0")

    return [`${formattedHours}:${formattedMinutes}`, totalMinutes]
  }

  function handleSubmit() {
    const updatedPrerequisites = masterObject.qualityFramework.filter(
      item => Object.keys(item).length !== 0
    )
    const updatedMaster = {
      ...masterObject,
      minutes: totalSessionTime?.totalMinutes,
      qualityFramework: updatedPrerequisites,
    }
    post(`quality_framework`, updatedMaster)
      .then(res => {
        fetchLogs()
        reset()
        setSelectedCourses("")
        toastr.success("Submitted Successfully")
      })
      .catch(err => {
        toastr.error("catch errrr")
      })
  }
  function handleTableData(id) {
    get(`quality_framework?course=${id}`)
      .then(res => {
        const result = res.data[0]
        if (result) {
          if (!result.qualityFramework.length) {
            result.qualityFramework = [{ name: "", minutes: "" }]
            setMasterObject(result)
          } else {
            setMasterObject(result)
          }
        }
        // else {
        //   setMasterObject(prev => ({
        //     ...prev,
        //     qualityFramework: [{ name: "", minutes: "" }],
        //   }))
        // }
      })
      .catch(err => {})
  }
  // console.log(masterObject,);
  const handleFileUpload = e => {
    const file = e.target.files[0]

    // Check if file exists and its type is Excel
    if (!file) {
      console.error("No file selected.")
      return
    }

    // Get file extension
    const extension = file.name.split(".").pop().toLowerCase()

    // Check if the file is an Excel file
    if (extension !== "xls" && extension !== "xlsx") {
      console.error("Invalid file type. Please upload an Excel file.")
      return
    }

    const fd = new FormData()
    fd.append("qualityFrameworkFile", file)

    axios
      .post(`${API_URL}quality_framework/upload`, fd)
      .then(response => {
        console.log(response, "reeee")
        if (response.status === 200) {
          // Assuming the response data is an object that you want to add to the masterObject
          const responseData = response.data.data // Adjust this according to your response structure
          setMasterObject(prevState => ({
            ...prevState,
            qualityFramework: [...prevState.qualityFramework, ...responseData],
          }))
        } else {
          toastr.success(response.data.message)
        }
      })
      .catch(error => {
        console.error("Error occurred during file upload:", error)
        // Handle error response
      })
  }

  // console.log(masterObject)
  function handleSelectChange(selected, name) {
    switch (name) {
      case "course":
        handleTableData(selected.value)

        setSelectedCourses(selected)
        setMasterObject({
          // ...masterObject,
          qualityFramework: [],
          course: selected.value,
        })
        break

      default:
        break
    }
  }
  // console.log(selet);

  const partnerCourse = () => {
    get(`options/partner-course`)
      .then(res => {
        setCourses(res.data)
      })
      .catch(err => {
        console.log(err?.response?.data?.message)
      })
  }
  const fetchLogs = () => {
    get("quality_framework/logs")
      .then(res => {
        const response = res.data.map((item, index) => {
          const details = {}
          details.id = index + 1
          details.date = moment(item.date).format("DD-MM-YYYY")
          details.time = moment(item.time, "HH:mm:ss").format("hh:mm a")

          details.course = item?.course?.name
          // details.action = item?.action
          details.desc = item?.desc

          details.user = `${item?.user?.firstName || ""} ${
            item?.user?.lastName || ""
          }`
          return details
        })

        setLogs(response)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const qualityFrameworkLogs = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        width: 150,
      },
      {
        label: "Course",
        field: "course",
        width: 150,
      },
      // {
      //   label: "Action",
      //   field: "action",
      //   width: 150,
      // },
      {
        label: "Description",
        field: "desc",
        width: 150,
      },
      {
        label: "User",
        field: "user",
        sort: "desc",
        width: 400,
      },
    ],
    rows: logs,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Home" breadcrumbItem="Quality Framework" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm ref={formRef} onValidSubmit={handleSubmit}>
                    <Row>
                      <Col md="6" xl="3">
                        <div className="mb-3">
                          <Label>Course</Label>
                          <Select
                            name="Course"
                            // menuPosition="fixed"
                            validate={{ required: { value: true } }}
                            errorMessage="Select Course"
                            onChange={selected => {
                              handleSelectChange(selected, "course")
                            }}
                            value={selectedCourses}
                            placeholder="Select..."
                            options={courses}
                            menuPosition="fixed"
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Import</Label>
                          <AvField
                            name="qualityFrameworkFile"
                            type="file"
                            accept=".xls,.xlsx"
                            validate={{
                              // required: {
                              //   value: true,
                              //   errorMessage: "Please select a file to import",
                              // },
                              pattern: {
                                value: /^.*\.(xls|xlsx)$/i,
                                errorMessage:
                                  "Please select a valid Excel file (.xls, .xlsx)",
                              },
                            }}
                            // errorMessage="Please select a file to import"
                            onChange={handleFileUpload}
                            className="form-control"
                            addonAfter="Days"
                          />
                        </div>
                      </Col>

                      <Col>
                        <div
                          className="mb-3 d-flex"
                          style={{ paddingTop: "25px" }}
                        >
                          <Button
                            color="primary"
                            className="me-2"
                            type="submit"
                            disabled={isGreater}
                          >
                            Submit
                          </Button>
                          <Button
                            color="danger"
                            className="me-2"
                            onClick={reset}
                          >
                            Reset
                          </Button>
                          <div
                            style={{
                              fontSize: "13px",
                              marginLeft: "7rem",
                            }}
                          >
                            <Tooltip title="Download sample file">
                              <a
                                href={"/samples/quality-framework-sample.xlsx"}
                                download
                                style={{ fontSize: "1rem" }}
                                className="d-flex align-items-center btn btn-success "
                              >
                                <i
                                  style={{ fontSize: "13px" }}
                                  className="fa fa-solid fa-download "
                                />
                                <span
                                  style={{
                                    marginLeft: "5px",
                                    fontSize: "13px",
                                  }}
                                >
                                  Download Sample Sheet
                                </span>
                              </a>
                            </Tooltip>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {masterObject?.qualityFramework && (
                      <Col xl={12} className="mt-4">
                        <Col className="col-12">
                          <div className="table-responsive">
                            <table className="table table-bordered mb-0">
                              <thead>
                                <tr>
                                  <th>Session No</th>
                                  <th>Session Name</th>
                                  <th>Time in minutes</th>
                                </tr>
                              </thead>
                              <tbody>
                                {masterObject?.qualityFramework?.map(
                                  (item, idx) => (
                                    <tr key={idx}>
                                      <td
                                        style={{
                                          width: "120px",
                                          textAlign: "center",
                                        }}
                                      >
                                        {idx + 1}
                                      </td>
                                      <td>
                                        <AvField
                                          value={item.name}
                                          type="text"
                                          className="form-control numberTime"
                                          onChange={e =>
                                            handleChange(
                                              e.target.value,
                                              "name",
                                              idx
                                            )
                                          }
                                          name={`name-${idx}`}
                                          validate={{
                                            required: { value: true },
                                          }}
                                          errorMessage="This field is required"
                                        />
                                      </td>
                                      <td className="qualityframeworktd">
                                        <AvField
                                          style={{ textAlign: "end" }}
                                          value={item.minutes}
                                          type="number"
                                          className="form-control numberTime"
                                          onChange={e =>
                                            handleChange(
                                              e.target.value,
                                              "minutes",
                                              idx
                                            )
                                          }
                                          name={`minutes-${idx}`}
                                          validate={{
                                            required: { value: true },
                                          }}
                                          errorMessage="This field is required"
                                        />
                                      </td>

                                      {masterObject.qualityFramework.length >
                                        1 && (
                                        <td style={{ width: "30px" }}>
                                          <span
                                            className="mr-1 p-2"
                                            style={{
                                              fontSize: "1.125rem",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              const data = { ...masterObject }
                                              data?.qualityFramework?.splice(
                                                idx,
                                                1
                                              )
                                              setMasterObject(data)
                                            }}
                                          >
                                            <i className="trash-btn mdi mdi-delete"></i>
                                          </span>
                                        </td>
                                      )}
                                    </tr>
                                  )
                                )}

                                <tr>
                                  {selectedCourses?.unit === "1" && (
                                    <td
                                      style={{ textAlign: "right" }}
                                      colSpan={2}
                                    >
                                      {selectedCourses?.duration} Course Total
                                      Hours
                                    </td>
                                  )}

                                  <td
                                    colSpan={`${
                                      selectedCourses?.unit === "1" ? 1 : 3
                                    }`}
                                    style={{
                                      textAlign: "right",
                                      width: "140px",
                                    }}
                                  >
                                    {totalSessionTime?.totalHour} Hours
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            <button
                              type="button"
                              onClick={() => {
                                const data = { ...masterObject }
                                data.qualityFramework.push({})
                                setMasterObject(data)
                              }}
                              className="waves-effect btn btn-outline-light d-flex"
                              style={{ gap: "5px" }}
                            >
                              Add more{" "}
                              <i className="mdi mdi-plus-circle-outline"></i>
                            </button>
                          </div>
                        </Col>
                      </Col>
                    )}
                  </AvForm>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <MDBDataTable
                    id="qualityFrameworkLogTable"
                    responsive
                    bordered
                    data={qualityFrameworkLogs}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default QualityFrameWork
