import React from "react"
import { Row, Col, Label, Card, CardBody, Button } from "reactstrap"
import Select from "react-select"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { MDBDataTable } from "mdbreact"
import Breadcrumb from "../../../components/Common/Breadcrumb"
function index() {
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Receipt",
        field: "receipt",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 100,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 200,
      },

      {
        label: "Course",
        field: "course",
        sort: "asc",
        width: 200,
      },
      {
        label: (
          <>
            <i className="fas fa-rupee-sign"></i> Course Fee
          </>
        ),
        field: "coursefee",
        sort: "asc",
        width: 200,
      },
      {
        label: (
          <>
            <i className="fas fa-rupee-sign"></i> Paid
          </>
        ),
        field: "amount",
        sort: "asc",
        width: 200,
      },
      {
        label: (
          <>
            <i className="fas fa-rupee-sign"></i> Amount Due
          </>
        ),
        field: "amount_due",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: [
      {
        id: "1",
        status: "0",
        name: "Ajay Jobin",
        currency: "INR",
        log_from: "1",
        amount: "20000",
        receipt: "#CSPR00166",
        razorpay_id: "",
        date: "23-01-2024",
        time: "10:01:55",
        admissionNo: "ASP125",
        course: "Diploma in Professional Accounting",
        coursefee: "50000",
        amount_due: "30000",
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="far fa-edit"
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ cursor: "pointer", fontSize: "1em" }}
            ></i>
          </div>
        ),
      },
      {
        id: "2",
        status: "0",
        name: "Jamsheer kp",
        currency: "INR",
        log_from: "1",
        amount: "10000",
        receipt: "#CSPR00165",
        razorpay_id: "",
        date: "23-01-2024",
        time: "10:01:55",
        admissionNo: "ASP125",
        course: "General Duty Assistant",
        coursefee: "20000",
        amount_due: "10000",
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="far fa-edit"
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ cursor: "pointer", fontSize: "1em" }}
            ></i>
          </div>
        ),
      },
      {    id:"3",
      status: "0",
      name:"Shankar kr",
      currency: "INR",
      log_from: "1",
      amount: "20000",
      receipt: "#CSPR00166",
      razorpay_id: "",
      date: "23-01-2024",
      time: "10:01:55",
      admissionNo:"ASP125",
      course:"Diploma in Professional Accounting",
      coursefee:"50000",
      amount_due:"30000",
      action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="far fa-edit"
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ cursor: "pointer", fontSize: "1em" }}
            ></i>
          </div>
        ),


  },
    ],
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem="Payment" />
          <Card>
            <CardBody>
              <Row>
                <AvForm>
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Student</Label>
                        <Select
                          name="students"
                          classNamePrefix="select2-selection"
                          options={[
                            { label: "Ajay jobin", value: 0 },
                            { label: "Shankar kr", value: 1 },
                            { label: "Shradha Shajimon", value: 2 },
                            { label: "Shiyona K", value: 3 },
                            { label: "Karthika B S", value: 4 },
                          ]}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Course</Label>
                        <Select
                          name="course"
                          classNamePrefix="select2-selection"
                          options={[
                            {
                              label: "Diploma in Professional Accounting",
                              value: 0,
                            },
                            {
                              label:
                                "Certified Program in EV Design, Simulation and Component Selection",
                              value: 1,
                            },
                            { label: "General Duty Assistant", value: 2 },
                            { label: "Texturing and LookDev", value: 3 },
                            { label: "Automobile Technician", value: 4 },
                          ]}
                        />
                      </div>
                    </Col>

                    <Col md="3">
                      <div className="mb-3">
                        <Label>Batch</Label>
                        <Select
                          name="batch"
                          classNamePrefix="select2-selection"
                          options={[
                            { label: "New 3 Day Batch", value: 0 },
                            { label: "New Batch", value: 1 },
                            { label: "New automobile batch", value: 2 },
                          ]}
                        />
                      </div>
                    </Col>

                    <Col md="3">
                      <Label>Payment</Label>
                      <AvField
                        name="amount"
                        type="number"
                        placeholder="Payment"
                      />
                    </Col>
                    <Col md="2">
                      <label>Payment mode</label>
                      <Select
                        options={[
                          { label: "Crm", value: "0" },
                          { label: "Website", value: "1" },
                        ]}
                      />
                    </Col>
                    <Col md="3">
                      <div className="mt-4 pt-1">
                        <Button color="primary" type="submit">
                          Submit
                        </Button>
                        <Button className="mx-2" color="danger" type="reset">
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <Row className="">
                <Col md="12">
                  <AvForm>
                    <Row>
                      <Col md="2">
                        <label>From date</label>
                        <AvField type="date" name="from_date" />
                      </Col>
                      <Col md="2">
                        <label>To date</label>
                        <AvField type="date" name="to_date" />
                      </Col>

                      <Col md="2">
                        <label>Student</label>
                        <Select name="student" />
                      </Col>

                      <Col md="2">
                        <label>Admission</label>
                        <Select />
                      </Col>

                      <Col md="2">
                        <label>Payment mode</label>
                        <Select
                          options={[
                            { label: "Crm", value: "0" },
                            { label: "Website", value: "1" },
                          ]}
                        />
                      </Col>
                    </Row>
                  </AvForm>
                  <Row className="mt-4">
                    <MDBDataTable
                      responsive
                      bordered
                      data={data}
                      searching={false}
                      paging={false}
                      id="payment-table-student"
                    />
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default index
