import React from "react"
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"

import "./style.scss"

const CommunityRegisteredMembers = () => {
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 270,
      },

      {
        label: "User",
        field: "name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Community",
        field: "community",
        sort: "asc",
        width: 270,
      },

      {
        label: "Member",
        field: "member",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: [
      {
        id: 1,
        date: "14-01-2024",
        time: "12:23 pm",
        name: "Latheef",
        community: "Python",
        member: "Django, React",
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="far fa-eye"
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
            ></i>
          </div>
        ),
        status: (
          <Select
            name="assign"
            classNamePrefix="select2-selection"
            options={[
              {
                label: "Approve",
                value: 0,
              },
              {
                label: "Reject",
                value: 1,
              },
            ]}
          />
        ),
      },
      {
        id: 2,
        date: "12-01-2024",
        time: "02:57 pm",
        name: "Thaha",
        community: "React",
        member: "JavaScript",
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="far fa-eye"
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
            ></i>
          </div>
        ),
        status: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button color={"success"} className="me-2" type="submit" disabled>
              Approved
            </Button>
          </div>
        ),
      },
      {
        id: 3,
        date: "12-01-2024",
        time: "02:55 pm",
        name: "Thaha",
        community: "Python",
        member: "JavaScript",
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="far fa-eye"
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
            ></i>
          </div>
        ),
        status: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button color={"danger"} className="me-2" type="submit" disabled>
              Rejected
            </Button>
          </div>
        ),
      },
    ],
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb title="Ticket" breadcrumbItem="Category" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="registeredMembers"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CommunityRegisteredMembers
