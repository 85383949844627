import React from "react"
import Breadcrumb from "../../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Label, Button } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import { MDBDataTable } from "mdbreact"

function index() {
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "ID",
        field: "reg_no",
        width: 150,
      },
      {
        label: "Faculty",
        field: "student",
        width: 150,
      },
      {
        label: "Batch",
        field: "batch",
        width: 150,
      },
      {
        label: "Course",
        field: "course",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        width: 150,
      },
      {
        label: "Mobile",
        field: "mobile",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        width: 150,
      },
    ],
    rows: [
      {
        id: "1",
        reg_no: "AS00040",
        student: "Ajay jobin",
        batch: "New Batch",
        course: "Texturing And LookDev",
        email: "ajy117@gmail.com",
        mobile: "7994945366",
        staff: "Admin",
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="far fa-edit"
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ cursor: "pointer", fontSize: "1em" }}
            ></i>
          </div>
        ),
      },
      {
        id: "2",
        reg_no: "AS00041",
        student: "Shankar kr",
        batch: "New Batch",
        course: "Texturing And LookDev",
        email: "shankr123@gmail.com",
        mobile: "9961112951",
        staff: "Admin",
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="far fa-edit"
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ cursor: "pointer", fontSize: "1em" }}
            ></i>
          </div>
        ),
      },
      {
        id: "3",
        reg_no: "AS00042",
        student: "Liya Elsa",
        batch: "New Batch",
        course: "Texturing And LookDev",
        email: "liya@gmail.com",
        mobile: "9544323348",
        staff: "Admin",
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="far fa-edit"
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ cursor: "pointer", fontSize: "1em" }}
            ></i>
          </div>
        ),
      },
    ],
  }
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumb title="Home" breadcrumbItem="Faculty Allocation" />

        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <AvForm className="needs-validation">
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Course</Label>
                        <Select
                          name="course"
                          classNamePrefix="select2-selection"
                          options={[
                            {
                              label: "Diploma in Professional Accounting",
                              value: 0,
                            },
                            {
                              label:
                                "Certified Program in EV Design, Simulation and Component Selection",
                              value: 1,
                            },
                            { label: "General Duty Assistant", value: 2 },
                            { label: "Texturing and LookDev", value: 3 },
                            { label: "Automobile Technician", value: 4 },
                          ]}
                        />
                      </div>
                    </Col>

                    <Col md="3">
                      <div className="mb-3">
                        <Label>Batch</Label>
                        <Select
                          name="batch"
                          classNamePrefix="select2-selection"
                          options={[
                            { label: "New 3 Day Batch", value: 0 },
                            { label: "New Batch", value: 1 },
                            { label: "New automobile batch", value: 2 },
                          ]}
                        />
                      </div>
                    </Col>

                    <Col md="3">
                      <div className="mb-3">
                        <Label>Faculty</Label>
                        <Select
                          name="students"
                          classNamePrefix="select2-selection"
                          options={[
                            { label: "Ajay jobin", value: 0 },
                            { label: "Shankar kr", value: 1 },
                            { label: "Shradha Shajimon", value: 2 },
                            { label: "Shiyona K", value: 3 },
                            { label: "Karthika B S", value: 4 },
                          ]}
                        />
                      </div>
                    </Col>

                    <Col md="3" style={{ paddingTop: "26px" }}>
                      <Button color="primary" type="submit">
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <MDBDataTable
                  id="allocationTableIdd"
                  responsive
                  bordered
                  info={true}
                  searching={true}
                  entries={20}
                  disableRetreatAfterSorting={true}
                  data={data}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default index
