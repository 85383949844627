import React, { useCallback, useEffect, useRef, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Row, Col, Button, Label } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import { isArray } from "lodash"
import { getLeadStatus } from "./backendHelper"
import { useSelector } from "react-redux"
import { LEAD_STATUS } from "../../../../config"
import moment from "moment"
import { get, post } from "../../../../helpers/api_helper"
import toastr from "toastr"
import { useDispatch } from "react-redux"
import {
  getTempBatch,
  getUsersOptions,
} from "../../../../store/options/actions"
import useBranch from "../../../../hooks/useBranch"

const FollowupTable = ({ leadId, refreshFunction, leadType, course }) => {
  const [master, setMaster] = useState({ courseFee: "" })

  const [eventCsp, setEventCsp] = useState([])

  const [btnDisable, setBtnDisable] = useState(false)

  const [rows, setRows] = useState([])
  const [selected, setSelected] = useState({ branch: null })
  const [feeDetails, setFeeDetails] = useState({})
  const [leadStatus, setLeadStatus] = useState([])

  const OPTIONS = useSelector(state => state.Options)
  const formRef = useRef()

  const [branch] = useBranch()

  const dispatch = useDispatch()

  useEffect(() => {
    getLeadStatus(leadType).then(res => {
      setLeadStatus(res.data)
    })
  }, [leadType])

  useEffect(() => {
    if (course?._id) {
      const selectedData = { ...selected }
      selectedData.course = { label: course.name, value: course._id }
      setSelected(selectedData)
      getCourseFee(course._id)
      setMaster(pre => ({ ...pre, course: course._id }))
    }
    // eslint-disable-next-line
  }, [course])

  useEffect(() => {
    dispatch(
      getUsersOptions({
        branch: branch?.value || "",
      })
    )
  }, [branch, dispatch])

  useEffect(() => {
    getFollowup(leadId)
    // eslint-disable-next-line
  }, [leadId])

  useEffect(() => {
    if (selected?.course?.value) {
      dispatch(getTempBatch({ course: selected.course.value }))
    }
  }, [selected?.course])

  const handleSelect = (e, name) => {
    const value = e ? (isArray(e) ? e.map(item => item.value) : e.value) : null
    setSelected(pre => ({ ...pre, [name]: e }))

    if (name === "status") {
      setMaster(pre => ({ ...pre, leadId: master.leadId, [name]: value }))
    } else {
      setMaster(pre => ({ ...pre, [name]: value }))
    }
  }

  const handleChange = e => {
    let { name, value } = e.target
    setMaster(pre => ({ ...pre, [name]: value }))
  }

  const getFollowup = useCallback(leadId => {
    if (leadId) {
      get(`leads/followup/${leadId}`).then(res => {
        res?.data?.map((item, idx) => {
          item.id = idx + 1
          item.date = moment(item.date).format("DD-MM-YYYY")
          item.next_followup =
            item?.next_followup &&
            moment(item.next_followup).format("DD-MM-YYYY")

          item.added_by =
            item?.addedBy &&
            `${item?.addedBy?.firstName || ""} ${item?.addedBy?.lastName || ""}`

          item.status = (
            <span
              style={{
                background: item?.followup_status?.color,
              }}
              className="badge font-size-12 ms-2"
            >
              {item?.followup_status?.name}
            </span>
          )
          return item
        })
        setMaster(pre => ({ ...pre, leadId }))
        setRows(res.data)
      })
    }
  }, [])

  const addFollowup = () => {
    setBtnDisable(true)
    post(`leads/followup`, { ...master, leadType })
      .then(res => {
        if (
          master.status === LEAD_STATUS.REASSIGN ||
          master.status === LEAD_STATUS.COURSE_CONFIRM
        ) {
          refreshFunction()
        }
        toastr.success(res.message)
        getFollowup(master.leadId)
        setMaster({ leadId: master.leadId })
        formRef.current.reset()
        setSelected({})
      })
      .catch(err => {
        toastr.error(err?.response?.data?.message)
      })
      .finally(() => {
        setBtnDisable(false)
      })
  }

  const getCourseFee = courseId => {
    get(`course/fee_details?_id=${courseId}`).then(res => {
      const data = res.data
      if (data.fee.phaseOne.default === true) {
        if (moment().isSameOrBefore(data.fee.phaseOne.expireDate, "days")) {
          setFeeDetails(data.fee.phaseOne)
        } else {
          setFeeDetails(data.fee.phaseTwo)
        }
      } else if (data.fee.phaseTwo.default === true) {
        if (moment().isSameOrBefore(data.fee.phaseTwo.expireDate, "days")) {
          setFeeDetails(data.fee.phaseTwo)
        } else {
          setFeeDetails(data.fee.phaseOne)
        }
      }
    })
  }

  const getEventCsp = eventId => {
    get(`options/events/csp/${eventId}`).then(res => {
      setEventCsp(res.data)
    })
  }

  const viewdetail_data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      // {
      //   label: "Ref No.",
      //   field: "ref",
      //   width: 150,
      // },

      {
        label: "Next Followup",
        field: "next_followup",
        width: 150,
      },

      {
        label: "Comments",
        field: "comments",
        width: 150,
      },

      {
        label: "Status",
        field: "status",
        sort: "desc",
        width: 400,
      },
      {
        label: "Added by",
        field: "added_by",
        sort: "desc",
        width: 400,
      },
    ],
    rows,
  }

  const handleLeadStatus = selected => {
    if (selected.value === LEAD_STATUS.COURSE_CONFIRM) {
      setSelected(pre => ({ ...pre, status: selected }))
      setMaster(pre => ({
        leadId: master.leadId,
        status: selected.value,
        payableAmount: feeDetails?.courseFee,
      }))
    } else {
      handleSelect(selected, "status")
    }
  }

  const handleReset = () => {
    setMaster(({ course, leadId }) => ({ course, leadId }))
    setSelected(({ course }) => ({ course }))
    formRef.current.reset()
  }

  return (
    <>
      <Col md="12" lg="12">
        <AvForm ref={formRef} onValidSubmit={addFollowup}>
          <Row>
            <Col md="3">
              <div className="">
                <Label>Status</Label>
                <Select
                  name="description"
                  type="text"
                  onChange={selected => {
                    handleLeadStatus(selected)
                  }}
                  value={selected.status || null}
                  options={leadStatus}
                  errorMessage="Enter service"
                />
              </div>
            </Col>

            {selected?.status?.value === LEAD_STATUS.STAFF_ASSIGN && (
              <Col md="3">
                <div className="">
                  <Label>Staff</Label>
                  <Select
                    name="assign"
                    onChange={selected => {
                      handleSelect(selected, "assign")
                    }}
                    value={selected.assign || null}
                    options={OPTIONS.users}
                  />
                </div>
              </Col>
            )}

            {selected?.status?.value === LEAD_STATUS.REASSIGN &&
              leadType === "Event" && (
                <>
                  <Col md="3">
                    <div className="">
                      <Label>Events</Label>
                      <Select
                        type="text"
                        name="event"
                        onChange={selected => {
                          handleSelect(selected, "event")
                          getEventCsp(selected.value)
                        }}
                        value={selected.event || null}
                        options={OPTIONS.events}
                        errorMessage="Enter service"
                      />
                    </div>
                  </Col>

                  {selected?.event?.value && (
                    <Col md="3">
                      <div className="">
                        <Label>CSP</Label>
                        <Select
                          type="text"
                          name="event"
                          onChange={selected => {
                            handleSelect(selected, "csp")
                          }}
                          value={selected.csp || null}
                          options={eventCsp}
                          errorMessage="Enter service"
                        />
                      </div>
                    </Col>
                  )}
                </>
              )}

            {selected?.status?.value === LEAD_STATUS.REASSIGN &&
            leadType === "Course" ? (
              <>
                <Col md="3">
                  <div className="">
                    <Label>Course</Label>
                    <Select
                      type="text"
                      name="course"
                      onChange={selected => {
                        handleSelect(selected, "course")
                        getCourseFee(selected.value)
                      }}
                      value={selected.course || null}
                      options={OPTIONS.courses}
                      errorMessage="Enter service"
                    />
                  </div>
                </Col>
                <Col md="3">
                  <div className="">
                    <Label>Batch</Label>
                    <Select
                      name="batch"
                      onChange={selected => {
                        handleSelect(selected, "batch")
                      }}
                      value={selected.batch || null}
                      options={OPTIONS.tempBatch}
                    />
                  </div>
                </Col>
              </>
            ) : (
              selected?.status?.value !== LEAD_STATUS.COURSE_CONFIRM &&
              selected?.status?.value !== LEAD_STATUS.EVENT_CONFIRM &&
              selected?.status?.value !== LEAD_STATUS.NOT_INTERESTED && (
                <>
                  <Col md="3">
                    <div className="">
                      <Label>Next Followup </Label>
                      <AvField
                        name="next_followup"
                        onChange={handleChange}
                        value={master.next_followup || ""}
                        type="date"
                        validate={{
                          required: { value: true },
                        }}
                        min={moment().format("YYYY-MM-DD")}
                        // min={moment().add(1, "day").format("YYYY-MM-DD")}
                      />
                    </div>
                  </Col>
                </>
              )
            )}

            <Col md="3">
              <div className="">
                <Label>Comments</Label>
                <AvField
                  rows={1}
                  type="textarea"
                  required
                  placeholder="Comments"
                  errorMessage="Enter Description"
                  name="comments"
                  onChange={handleChange}
                  value={master.comments || ""}
                />
              </div>
            </Col>

            {selected.course &&
              selected?.status?.value === LEAD_STATUS.COURSE_CONFIRM && (
                <Col md="3">
                  <div className="">
                    <Label>Payable Amount</Label>
                    <AvField
                      rows={1}
                      type="number"
                      min={1}
                      max={feeDetails?.courseFee}
                      placeholder="Payable Amount"
                      errorMessage="Enter valid payable amount"
                      name="payableAmount"
                      validate={{
                        required: { value: true },
                        min: { value: true },
                      }}
                      onChange={handleChange}
                      value={master.payableAmount || ""}
                    />
                  </div>
                </Col>
              )}

            {selected.course &&
              selected?.status?.value === LEAD_STATUS.REASSIGN && (
                <Row>
                  <div className="d-flex align-items-center">
                    <div className="mt-3 me-3">
                      <Label>
                        Tax Amount : <i className="fas fa-rupee-sign fs-6"></i>{" "}
                        {feeDetails.taxAmount}
                      </Label>
                    </div>

                    <div className="mt-3 me-3">
                      <Label>
                        Taxable Value :{" "}
                        <i className="fas fa-rupee-sign fs-6"></i>{" "}
                        {feeDetails.taxableValue}
                      </Label>
                    </div>
                    <div className="mt-3 me-3">
                      <Label>
                        Course Fee : <i className="fas fa-rupee-sign fs-6"></i>{" "}
                        {feeDetails.courseFee}
                      </Label>
                    </div>
                  </div>
                </Row>
              )}

            <Col>
              <div className="mt-4 mb-3">
                <Button
                  disabled={btnDisable}
                  color="primary"
                  type="submit"
                  className="mx-2"
                >
                  Submit
                </Button>
                <Button onClick={handleReset} color="danger" type="button">
                  Reset
                </Button>
              </div>
            </Col>
          </Row>
        </AvForm>
      </Col>
      <Col md="12">
        <MDBDataTable
          id="followupTable"
          responsive
          bordered
          data={viewdetail_data}
          searching={true}
          info={true}
          disableRetreatAfterSorting={true}
          entries={20}
        />
      </Col>
    </>
  )
}

export default FollowupTable
