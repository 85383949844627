import React, { useContext, useState, useEffect, useRef } from "react"

import { AvField, AvForm } from "availity-reactstrap-validation"
import toastr from "toastr"
import { Row, Col, Label, Card, CardBody, CardTitle, Button } from "reactstrap"
import { CourseContext } from ".."
import { put } from "../../../../helpers/api_helper"
const SEODetails = props => {
  const formRef = useRef()
  const main = useContext(CourseContext)
  const [masterSeo, setmasterSeo] = useState({ seo: { faq: [{}] } })
  const [isDisabled, setisDisabled] = useState(false)


   function resetAll(){
    setmasterSeo({ seo: { faq: [{}] } })
    main.setCourseID({})
   }

  useEffect(() => {
    if (Object.keys(props.data).length && main?.courseID?.objID) {
      setisDisabled(false)
      const prevMaster = { ...masterSeo, ...props?.data }
      if (props?.data?.seo?.faq.length === 0) prevMaster.seo.faq = [{}]
      setmasterSeo(prevMaster => ({ ...prevMaster, ...props?.data }))
    }
  }, [props.data])

  const handlePreValueChange = (value, name, index) => {
    const updatedFaq = [...masterSeo.seo.faq] // Copy the FAQ array
    updatedFaq[index][name] = value // Update the specific FAQ item at the given index with the new value
    const updatedSeo = { ...masterSeo.seo, faq: updatedFaq } // Update the FAQ array in the SEO object
    setmasterSeo(prevMaster => ({ ...prevMaster, seo: updatedSeo })) // Update the masterSeo state
  }

  function handlechange(e) { 
    let { name, value } = e.target
    const seoup = { ...masterSeo.seo }
    seoup[name] = value
    setmasterSeo(pre => ({
      ...pre,
      seo: seoup,
    }))
  }

  function handleSubmit(e) {
    e.preventDefault()
    let data = { ...masterSeo }
    data._id = main?.courseID?.objID
    setisDisabled(true)
    put(`/course/seo`, data)
      .then(res => {
        toastr.success(res.message)
        // formRef.current.reset()
        main.setCourseID(pre => ({ objID: res.data._id }))
        // setmasterSeo({ seo: { faq: [{}] } })
        setisDisabled(false)
      })
      .catch(err => {
        toastr.error(err.response.data.message)
        setisDisabled(false)
      })
  }

  const handleRemoveFaqItem = indexToRemove => {
    const updatedFaq = masterSeo.seo.faq.filter(
      (item, index) => index !== indexToRemove
    ) // Filter out the item at the specified index
    const updatedSeo = { ...masterSeo.seo, faq: updatedFaq } // Update the FAQ array in the SEO object
    setmasterSeo(prevMaster => ({ ...prevMaster, seo: updatedSeo })) // Update the masterSeo state
  }

  return (
    <AvForm ref={formRef} onValidSubmit={handleSubmit}>
      <Row>
        <Row className="mb-2">
          <Col md="6">
            <div className="mb-3">
              <Label>Permalink</Label>
              <AvField
                name="permalink"
                type="text"
                className="form-control"
                placeholder="Permalink"
                onChange={handlechange}
                value={masterSeo?.seo?.permalink || ""}
                readOnly={true}
                style={{ backgroundColor: "#E5E4E2" }}
              />
            </div>
          </Col>
          <Col md="6">
            <div className="mb-3">
              <Label>Title</Label>
              <AvField
                name="title"
                type="text"
                className="form-control"
                placeholder="Title"
                onChange={handlechange}
                value={masterSeo?.seo?.title || ""}
              />
            </div>
          </Col>
          <Col md="6">
            <div className="mb-3">
              <Label htmlFor="validationCustom03">Description</Label>
              <AvField
                name="description"
                type="textarea"
                rows="1"
                className="form-control"
                placeholder="Description"
                onChange={handlechange}
                value={masterSeo?.seo?.description || ""}
              />
            </div>
          </Col>

          <Col md="6">
            <div className="mb-3">
              <Label htmlFor="validationCustom03">Keywords</Label>
              <AvField
                name="keywords"
                type="textarea"
                rows="1"
                className="form-control"
                placeholder="Keywords1, Keywords2, Keywords3, Keywords4"
                onChange={handlechange}
                value={masterSeo?.seo?.keywords || ""}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Card>
            <CardBody>
              <CardTitle className="mb-3">Frequently Asked Questions</CardTitle>
              {masterSeo?.seo?.faq?.map((item, idx) => (
                <Row key={idx}>
                  <Col sm={masterSeo?.seo?.faq?.length > 1 ? 11 : 12}>
                    <Row>
                      <Col md="6">
                        <div className="mb-3">
                          <Label>Question</Label>
                          <AvField
                            name="question"
                            type="text"
                            className="form-control"
                            placeholder="Question"
                            value={item.question || ""}
                            onChange={e =>
                              handlePreValueChange(
                                e.target.value,
                                "question",
                                idx
                              )
                            }
                          />
                        </div>
                      </Col>

                      <Col md="6">
                        <div className="mb-3">
                          <Label>Answer</Label>
                          <AvField
                            name="answer"
                            type="text"
                            className="form-control"
                            placeholder="Answer"
                            value={item.answer || ""}
                            onChange={e =>
                              handlePreValueChange(
                                e.target.value,
                                "answer",
                                idx
                              )
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  {masterSeo?.seo?.faq?.length > 1 && (
                    <Col
                      width={"200px"}
                      sm="1"
                      className="d-flex justify-content-center align-items-center"
                    >
                      <div className="d-flex justify-content-end">
                        <span
                          className="mr-1"
                          style={{ fontSize: "1.125rem", cursor: "pointer" }}
                          onClick={() => handleRemoveFaqItem(idx)}
                        >
                          <i className="trash-btn mdi mdi-delete"></i>
                        </span>
                      </div>
                    </Col>
                  )}
                </Row>
              ))}
              <button
                onClick={e => {
                  e.preventDefault()
                  const data = { ...masterSeo }
                  const newFaqItem = {} // Create a new empty FAQ item
                  data?.seo?.faq?.push(newFaqItem) // Add the new FAQ item to the FAQ array
                  setmasterSeo(data) // Update the masterSeo state with the new FAQ item
                }}
                className="waves-effect btn btn-outline-light d-flex"
                style={{ gap: "5px" }}
              >
                Add more <i className="mdi mdi-plus-circle-outline"></i>
              </button>
            </CardBody>
          </Card>
        </Row>
        <Col md={12}>
          <div className="d-flex justify-content-end align-items-center">
            <div style={{ marginRight: "1rem" }}>
              <Button
                disabled={isDisabled ? true : false}
                color="primary"
                type="submit"
              >
                Save
              </Button>
              <Button onClick={resetAll}  className="mx-2" color="danger" type="reset">
                Reset
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </AvForm>
  )
}
export default SEODetails
