import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useRef, useState } from "react"
import { Button, Col, Label, Row } from "reactstrap"
import "./Student.scss"
import { isArray } from "lodash"
// import { useSelector } from "react-redux"
import SelectErrorMessage from "../../../../../components/Common/SelectErrorMessage"
import Select from "react-select"
import { useBatch } from "../BatchContext"
import { get, post } from "../../../../../helpers/api_helper"
import { MDBDataTable } from "mdbreact"
import toastr from "toastr"
import moment from "moment"
import { jsonToExcel } from "../../../../../helpers/functions"
function TrainerAllocation(props) {
  const formRef = useRef()
  const [master, setMaster] = useState({ rangeValue: null })
  const [qualityFrame, setQualityFrame] = useState([{}])

  const [exports, setExports] = useState([])

  const { ...context } = useBatch()

  const [selected, setSelected] = useState({ trainer: null })

  const [error, setError] = useState({})

  // const OPTIONS = useSelector(state => state.Options)

  useEffect(() => {
    if (context.master.id) {
      handleTable(context.master.id)
    }
  }, [context.master.id])

  const handleChangeRange = e => {
    let { name, value } = e.target
    const data = parseRanges(value)
    setMaster(pre => ({ ...pre, [name]: value, keys: data }))
  }

  const handleSelect = (e, name) => {
    const value = e ? (isArray(e) ? e.map(item => item.value) : e.value) : null
    setSelected(pre => ({ ...pre, [name]: e }))
    setMaster(pre => ({ ...pre, [name]: value }))
  }

  const handleTable = batchId => {
    get(`batch/trainer-assign-list/${batchId}`)
      .then(res => {
        const data = res.data

        const exportData = []

        data.map((item, idx) => {
          item.id = idx + 1
          item.trainer = item?.trainer?.fullName

          //? Export details
          const exportDetails = {
            "#": idx + 1,
            Trainer: item?.trainer,
            Session: item?.name,
          }
          exportData.push(exportDetails)
          return item
        })

        setExports(exportData)

        setQualityFrame(data)
      })
      .catch(err => {
        console.log(err?.response?.data?.message)
      })
  }

  const handleSubmit = () => {
    if (selected.trainer === null) {
      setError(pre => ({ ...pre, trainer: true }))
      return
    }

    if (context.master.id) {
      post(`batch/trainer-assign`, { batchId: context.master.id, ...master })
        .then(res => {
          toastr.success(res.message)
          handleTable(context.master.id)
          setMaster({})
          setSelected({ trainer: null })
          formRef.current.reset()
        })
        .catch(err => {
          toastr.error(err?.response?.data?.message)
        })
    }
  }

  const parseRanges = input => {
    const result = new Set()
    const parts = input.split(",")
    parts.forEach(part => {
      if (part.includes("-")) {
        const [start, end] = part.split("-").map(Number)
        if (
          !isNaN(start) &&
          !isNaN(end) &&
          start > 0 &&
          end > 0 &&
          start <= end
        ) {
          for (let i = start; i <= end; i++) {
            result.add(i - 1)
          }
        }
      } else {
        const page = Number(part)
        if (!isNaN(page) && page > 0) {
          result.add(page - 1)
        }
      }
    })
    return Array.from(result)
      .sort((a, b) => a - b)
      .map(page => page)
  }

  const handleExport = () => {
    if (exports.length > 0) {
      jsonToExcel(exports, `trainer-assign-${moment().format("DD-MM-YY")}`)
    } else {
      toastr.info("There are no session to export")
    }
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Session",
        field: "name",
        width: 150,
      },
      {
        label: "Trainer",
        field: "trainer",
        width: 150,
      },
    ],
    rows: qualityFrame,
  }

  return (
    <AvForm ref={formRef} onValidSubmit={handleSubmit}>
      {props?.allowed && (
        <Row>
          <Col md="3">
            <div className="mb-3 no-arrow">
              <Label htmlFor="validationCustom03">Session</Label>
              <AvField
                name="rangeValue"
                value={master.rangeValue || ""}
                type="text"
                className="form-control"
                placeholder="e.g. 1-5, 8, 11-13"
                onChange={handleChangeRange}
                validate={{
                  required: { value: true },
                  // pattern: {
                  //   value: "^(d+(-d+)?)(,d+(-d+)?)*$",
                  //   errorMessage:"Enter valid range or number e.g. 1-5, 8, 11-13",
                  // },
                }}
              />
            </div>
          </Col>

          <Col md="3">
            <div className="mb-3">
              <Label>Trainer</Label>
              <Select
                name="start_date"
                type="date"
                options={props?.trainers || context?.contextState?.trainers}
                value={selected.trainer || null}
                onChange={e => {
                  handleSelect(e, "trainer")
                  setError(pre => ({ ...pre, trainer: false }))
                }}
              />
            </div>
            <SelectErrorMessage message="Select Trainer" show={error.trainer} />
          </Col>

          <Col className="mt-4 pt-1">
            <Button type="submit" className="me-2" color="primary">
              Assign
            </Button>
          </Col>

          <Col className="d-flex justify-content-end align-items-center me-3">
            <Button
              type="button"
              onClick={() => handleExport()}
              color="primary"
              className="btn-sm"
            >
              Export
            </Button>
          </Col>
        </Row>
      )}

      <Row>
        <div className="table-responsive">
          <MDBDataTable
            id="trainerAllocationTable"
            responsive
            bordered
            info={true}
            searching={true}
            entries={20}
            disableRetreatAfterSorting={true}
            data={data}
            totalPage={20}
          />
        </div>
      </Row>
    </AvForm>
  )
}

export default TrainerAllocation
