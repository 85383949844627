import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import "./styles.scss"

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { APP_NAME } from "../../../.../../../config"
import { MDBDataTable } from "mdbreact"
import { get } from "../../../../helpers/api_helper"
import { Tooltip } from "@mui/material"
import { jsonToExcel } from "../../../../helpers/functions"
import toastr from "toastr"
import moment from "moment"

const PendingLeads = () => {
  document.title = `Pending Leads | ${APP_NAME}`

  const [rows, setRows] = useState([])

  const [exports, setExports] = useState([])

  useEffect(() => {
    fetchRows()
  }, [])

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Type", field: "type" },
      { label: "Reg Id", field: "unique_id" },
      { label: "Student", field: "student" },
      { label: "Email", field: "email" },
      { label: "Mobile", field: "mobile" },
      { label: "CSP", field: "branch" },
      { label: "Course/Event", field: "courseOrEvent" },
      // { label: "registered", field: "courseOrEvent" },
    ],
    rows,
  }

  const fetchRows = async () => {
    try {
      const response = await get("leads/pending/list")

      const data = response?.data || []

      const exportData = []

      data.map((item, index) => {
        item.id = index + 1

        item.student = `${item?.firstname || ""} ${item?.lastname || ""}`

        item.courseOrEvent = item?.course || item?.event

        item.type = item?.event ? "Event" : "Course"

        const exportDetails = {
          "#": index + 1,
          Type: item?.event ? "Event" : "Course",
          "Reg Id": item?.unique_id,
          Student: `${item?.firstname || ""} ${item?.lastname || ""}`,
          Email: item?.email,
          Mobile: item?.mobile,
          CSP: item?.branch,
          "Course/Event": item?.course || item?.event,
        }

        exportData.push(exportDetails)

        return item
      })
      setExports(exportData)
      setRows(data)
    } catch (error) {
      console.log(error?.response?.data?.message || error?.response)
    }
  }

  const handleExportData = () => {
    if (exports.length > 0) {
      jsonToExcel(exports, `pending-leads-${moment().format("DD-MM-YY")}`)
    } else {
      toastr.info(`There are no data to export`)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Home" breadcrumbItem="Pending Leads" />

          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <div className="pb-2 d-flex justify-content-end me-1 absolute top-10 ">
                    <Tooltip
                      title={"Export"}
                      arrow
                      placement="top"
                      id="button-tooltip-2"
                    >
                      <i
                        onClick={() => handleExportData()}
                        className="cursor-pointer fs-4 fas fa-file-export"
                      ></i>
                    </Tooltip>
                  </div>
                  <MDBDataTable
                    id="pending-leads"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PendingLeads
