import React, { useEffect, useRef, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap"
// import { Modal } from "react-bootstrap"
import Select from "react-select"
import { MDBDataTable } from "mdbreact"
import AsyncSelect from "react-select/async"
import { get, post } from "../../../../helpers/api_helper"
import "./style.scss"
import toastr from "toastr"

import Tooltip from "@mui/material/Tooltip"
import moment from "moment"
import TablePagination from "../../../../components/Common/Pagination"
import { API_URL } from "../../../../config"
import { getDate, setTitle } from "../../../../helpers/functions"
import PaymentDetails from "../PaymentDetails"

import SearchStudent from "../../../../components/Common/SearchStudents.jsx"

import queryString from "query-string"

const ManualPayments = ({ options }) => {
  setTitle("Manual Payments")

  const formRef = useRef()
  const filterRef = useRef()
  const initialState = {
    from: "",
    to: "",
    student: "",
    course: "",
    batch: "",
    comments: "",
    type: 1,
    for: "",
  }

  const forOptions = [
    { label: "Course", value: 0 },
    { label: "Event", value: 2 },
  ]

  const toggle = (start = true) => {
    var body = document.body

    if (start) {
      body.classList.remove("vertical-collpsed")
      body.classList.remove("sidebar-enable")
      body.setAttribute("data-sidebar-size", "sm")
    } else {
      body.classList.add("vertical-collpsed")
      body.classList.add("sidebar-enable")
      body.setAttribute("data-sidebar-size", "lg")
    }
  }

  useEffect(() => {
    toggle()

    return () => {
      toggle(false)
    }
    // eslint-disable-next-line
  }, [])
  const [selectedData, setSelectedData] = useState({
    student: "",
    course: "",
    amount: "",
    courseAssigned: "",
    customAmount: "",
    comments: "",
    due: "",
    for: "",
  })
  const [studentOptions, setStudentOptions] = useState([])
  const [courseOptions, setCourseOptions] = useState([])
  const [eventOptions, setEventOptions] = useState([])
  const [paymentData, setPaymentData] = useState([])
  const [paymentOption, setPaymentOption] = useState(false)
  const [fundSourceOptions, setFundSourceOptions] = useState([])

  const [modal, setModal] = useState(false)
  const [selectedId, setSelectedId] = useState({})

  const [tableData, setTableData] = useState([])
  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 20

  const [filterObject, setFilterObject] = useState(initialState)
  const [filterSelectedFields, setFilterSelectedFields] = useState({})

  const [courses, setCourses] = useState([])
  const [batches, setBatches] = useState([])
  const [branches, setBranches] = useState([])

  useEffect(() => {
    handleBatchOptions()
    handleBranchOptions()
    setPage(1)

    // eslint-disable-next-line
  }, [filterObject])


  const handleCourseOption = async (obj = filterObject) => {
    const query = `?${queryString.stringify(obj)}`
    get(`/fee/options/manual/course${query}`).then(res => {
      setCourses(res.data)
    })
  }
  const handleEventOption = async (obj = filterObject) => {
    const query = `?${queryString.stringify(obj)}`
    get(`/fee/options/manual/event${query}`).then(res => {
      setCourses(res.data)
    })
  }

  const handleBatchOptions = async (obj = filterObject) => {
    const query = `?${queryString.stringify(obj)}`
    get(`/fee/options/manual/batch${query}`).then(res => {
      setBatches(res.data)
    })
  }
  const handleBranchOptions = async (obj = filterObject) => {
    const query = `?${queryString.stringify(obj)}`
    get(`/fee/options/manual/branch${query}`).then(res => {
      setBranches(res.data)
    })
  }
  const handleChange = (event, value) => {
    setPage(value)
    handleTableData(value)
  }

  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [filterObject])

  const handleFilterSelectChange = (selected, name) => {
    const obj = { ...filterSelectedFields }
    obj[name] = selected
    setFilterSelectedFields(obj)

    const value = selected
      ? Array.isArray(selected)
        ? selected.map(item => item.value)
        : selected.value
      : null
    handleFilerValueChange(value, name)
  }

  const handleFilerValueChange = async (value, name) => {
    const obj = { ...filterObject }
    obj[name] = value
    setFilterObject(obj)
    if (name === "for") {
      if (value === 0) {
        handleCourseOption(obj)
      } else {
        handleEventOption(obj)
      }
    }
    setPage(1)
  }

  const handlePopUp = id => {
    setModal(true)
    setSelectedId(id)
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        width: 150,
      },
      {
        label: "Receipt No",
        field: "uniqueId",
        width: 150,
      },
      {
        label: "Student",
        field: "student",
        width: 150,
      },
      {
        label: "Register No",
        field: "studentId",
        width: 150,
      },

      {
        label: "For",
        field: "for",
        width: 150,
      },
      {
        label: "Course/Event",
        field: "name",
        width: 150,
      },
      {
        label: "Fee",
        field: "fee",
        width: 150,
      },
      {
        label: "Paid",
        field: "paid",
        width: 150,
      },
      {
        label: "Due",
        field: "due",
        width: 150,
      },

      {
        label: "Status",
        field: "status",
        width: 150,
      },
      {
        label: "Added By",
        field: "addedBy",
        width: 150,
      },
      {
        label: "Actions",
        field: "options",
        sort: "desc",
        width: 400,
      },
    ],
    rows: tableData,
  }

  const handleTableData = (perPage = page) => {
    const query = `?page=${perPage}&limit=${limit}&${queryString.stringify(
      filterObject
    )}`
    get(`/fee/manual/list?${query}`).then(res => {
      const result = res.data
      const count = res.count
      result?.map((item, index) => {
        item.id = index + 1 + (perPage - 1) * limit
        item.time = moment(item.time, "HH:mm:ss").format("hh:mm a")
        item.date = moment(item.date).format("DD-MM-YYYY")
        const paymentType = item.paymentFor === 0 ? "Course" : "Event"
        item.for = paymentType
        item.student =
          (item.studentFirstName ? item.studentFirstName : "") +
          " " +
          (item?.studentLastName ? item.studentLastName : "")
        item.addedBy =
          (item.addedFirstName ? item.addedFirstName : "") +
          " " +
          (item?.addedLastName ? item.addedLastName : "")
        item.type =
          item.type === 1
            ? "Cash"
            : item.type === 2
            ? "Skill loan"
            : item.type === 3
            ? "Scholarship"
            : item.type === "4"
            ? "Online"
            : ""
        item.status = (
          <Button
            style={{ width: "100%" }}
            color={
              item.status === 0
                ? "success"
                : item.status === 1
                ? "danger"
                : "warning"
            }
            disabled
          >
            {item.status === 0
              ? "Success"
              : item.status === 1
              ? "Failed"
              : "Pending"}
          </Button>
        )

        item.options = (
          <div>
            <i
              className="fas fa-eye"
              onClick={() => {
                handlePopUp(item._id)
              }}
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
            ></i>
            <Tooltip title="Receipt" arrow placement="top">
              <a
                href={API_URL + item.receiptPath}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <i
                  className="uil-file-download"
                  style={{ fontSize: "1.5em", cursor: "pointer" }}
                ></i>
              </a>
            </Tooltip>
          </div>
        )
        item.fee = item.fee?.toLocaleString("en-IN") || 0
        item.paid = item.paid?.toLocaleString("en-IN") || 0
        item.due = item.due?.toLocaleString("en-IN") || 0
        return item
      })

      setTableData(result)
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(result)
    })
  }

  const loadOptions = async (value, callback) => {
    if (value.length >= 2) {
      const response = await get(
        `fee/options/search-students?name=${value}&paymentFor=${selectedData.for}`
      ).then(res => res)
      const data = response.data
      setStudentOptions(data)
      callback(data)
    } else {
      callback([])
    }
  }
  const handleFundSourceOptions = async () => {
    get(`accounts/chart-account/options`).then(res =>
      setFundSourceOptions(res.data)
    )
  }

  const handleCourseHistory = async courseAssign => {
    return await get(
      `fee/course-payment-history?courseAssign=${courseAssign}`
    ).then(res => {
      const result = res.data

      result?.transactions?.map((item, index) => {
        item.id = index + 1
        item.type =
          item.type === 1
            ? "Cash"
            : item.type === 2
            ? "Skill loan"
            : item.type === 3
            ? "Scholarship"
            : item.type === 4
            ? "online"
            : ""
        item.status =
          item.status === 0 ? (
            <Button color="success" size="sm" disabled>
              Success
            </Button>
          ) : (
            <Button color="danger" size="sm" disabled>
              Failed
            </Button>
          )

        item.date = moment(item.date).format("DD-MM-YYYY")
        return item
      })
      result.due = result.courseFee - result.paid
      setPaymentData(result || [])
      return result.due
    })
  }

  const handleCourseOptions = student => {
    get(`fee/options/search-courses?student=${student}`).then(res => {
      setCourseOptions(res.data)
    })
  }
  const handleEventOptions = student => {
    get(`fee/options/search-events?student=${student}`).then(res => {
      setEventOptions(res.data)
    })
  }

  const handleValueChange = async (selected, name) => {
    let obj = { ...selectedData }
    obj[name] = selected.value

    if (name === "student") {
      obj = {
        student: selected.value,
        for: obj.for,
        date: getDate(),
        time: moment().format("HH:mm"),
      }
      if (obj.for === 0) handleCourseOptions(selected.value)
      else handleEventOptions(selected.value)
    }

    if (name === "course") {
      obj.courseAssigned = selected._id
      obj.amount = await handleCourseHistory(selected._id)
      handleFundSourceOptions()
    }

    if (name === "event") {
      obj.amount = selected.due || 0
      setPaymentData({
        due: selected.due || 0,
        eventFee: selected.fee || 0,
        paid: selected.paid || 0,
      })
      handleFundSourceOptions()
    }

    if (name === "customAmount") {
      obj.amount = selected.value
    }

    if (name === "for") {
      obj = { for: selected.value }
      setPaymentData({})
    }
    setSelectedData(obj)
  }
  const paymentHistory = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Type",
        field: "type",
        width: 150,
      },

      {
        label: "No",
        field: "uniqueId",
        width: 150,
      },
      {
        label: "Paid Amount",
        field: "amount",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        width: 150,
      },
    ],
    rows: paymentData.transactions,
  }

  const handleSubmit = () => {
    if (selectedData.for === 0) {
      post("/fee/manual-payment", selectedData)
        .then(res => {
          reset()
          toastr.success(res.message)
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    } else {
      post("/fee/manual-payment/event", selectedData)
        .then(res => {
          reset()
          toastr.success(res.message)
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    }
  }

  const reset = () => {
    formRef.current.reset()
    filterRef.current.reset()
    toTop()
    setCourseOptions([])
    setSelectedData({})
    setFilterObject({})
    setFilterSelectedFields({})
    setCourses([])
  }

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  return (
    <>
      <Modal
        show={modal}
        isOpen={modal}
        toggle={() => setModal(!modal)}
        size="lg"
        centered={true}
        className="lead-modal"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Payment Details</h5>
          <button
            type="button"
            onClick={() => {
              setModal(false)
              // handleTableData()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <PaymentDetails id={selectedId} />
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manual Payments" />
          {options.edit ? (
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm
                      id="manualPaymentsForm"
                      ref={formRef}
                      onValidSubmit={handleSubmit}
                    >
                      <Row>
                        <Col md={2}>
                          <Label>Course/Event</Label>
                          <Select
                            name="type"
                            classNamePrefix="select2-selection"
                            options={forOptions}
                            value={forOptions.filter(
                              item => item.value === selectedData.for
                            )}
                            onChange={selected => {
                              handleValueChange(selected, "for")
                            }}
                          />
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label>Student</Label>
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              id="manualPaymentsStudentSearch"
                              isDisabled={
                                selectedData.for !== 0 && selectedData.for !== 2
                              }
                              loadOptions={loadOptions}
                              value={studentOptions.filter(
                                item => item.value === selectedData.student
                              )}
                              onChange={selected => {
                                handleValueChange(selected, "student")
                              }}
                              noOptionsMessage={() => null}
                              placeholder="Search Student's Name, Mobile"
                            />
                          </div>
                        </Col>
                        {selectedData.for === 0 ? (
                          <Col md={3}>
                            <div className="mb-3">
                              <Label>Course</Label>
                              <Select
                                name="course"
                                classNamePrefix="select2-selection"
                                isDisabled={!selectedData.student}
                                options={courseOptions}
                                value={courseOptions.filter(
                                  item => item.value === selectedData.course
                                )}
                                onChange={selected => {
                                  handleValueChange(selected, "course")
                                }}
                              />
                            </div>
                          </Col>
                        ) : selectedData.for === 2 ? (
                          <Col md={3}>
                            <div className="mb-3">
                              <Label>Event</Label>
                              <Select
                                name="course"
                                classNamePrefix="select2-selection"
                                isDisabled={!selectedData.student}
                                options={eventOptions}
                                value={eventOptions.filter(
                                  item => item.value === selectedData.event
                                )}
                                onChange={selected => {
                                  handleValueChange(selected, "event")
                                }}
                              />
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {selectedData.for === 0 || selectedData.for === 2 ? (
                          <Col md="3">
                            <div className="mb-3">
                              <Label>Fund Source</Label>
                              <Select
                                name="fundSource"
                                value={fundSourceOptions.filter(
                                  item => item.value === selectedData.fundSource
                                )}
                                onChange={selected => {
                                  handleValueChange(selected, "fundSource")
                                }}
                                options={fundSourceOptions}
                                errorMessage="Enter Fund Source"
                              />
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                        {selectedData.course ? (
                          <>
                            <Col md={2}>
                              <div className="mb-3">
                                <Label>Date</Label>
                                <AvField
                                  name="date"
                                  classNamePrefix="select2-selection"
                                  type="date"
                                  value={selectedData.date}
                                  onChange={e => {
                                    handleValueChange(e.target, "date")
                                  }}
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: "Enter Date",
                                    },
                                    max: {
                                      value: getDate(),
                                      errorMessage: "Date is invalid",
                                    },
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md={2}>
                              <div className="mb-3">
                                <Label>Time</Label>
                                <AvField
                                  name="time"
                                  classNamePrefix="select2-selection"
                                  type="time"
                                  value={selectedData.time}
                                  onChange={e => {
                                    handleValueChange(e.target, "time")
                                  }}
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: "Enter Date",
                                    },
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md={12}>
                              <MDBDataTable
                                id="feeInstallmentTable"
                                bordered
                                responsive
                                searching={false}
                                entries={20}
                                displayEntries={false}
                                paging={false}
                                disableRetreatAfterSorting={true}
                                data={paymentHistory}
                              ></MDBDataTable>
                            </Col>

                            <Col md={6}>
                              <Row>
                                <Col
                                  md={6}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Label
                                    style={{
                                      marginLeft: "1.2rem",
                                    }}
                                  >
                                    Course Fee
                                  </Label>
                                  <Label>₹{paymentData.courseFee}</Label>
                                </Col>
                                <Col
                                  md={6}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Label>Paid Amount</Label>
                                  <Label>₹{paymentData.paid}</Label>
                                </Col>
                                <Col
                                  md={6}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: "1rem",
                                  }}
                                >
                                  <div
                                    className="checkbox"
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <AvField
                                      type="radio"
                                      name="due"
                                      value={paymentData.due}
                                      className="me-2"
                                      defaultChecked={!paymentOption}
                                      onChange={e => {
                                        if (e.target.checked) {
                                          setPaymentOption(false)
                                          handleValueChange(e.target, "amount")
                                        } else {
                                          setPaymentOption(true)
                                        }
                                      }}
                                    />

                                    <Label>Due Amount</Label>
                                  </div>
                                  <Label>₹{paymentData.due}</Label>
                                </Col>
                                <Col md={6}></Col>
                                <Col
                                  md={6}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    className="checkbox"
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <AvField
                                      type="radio"
                                      name="due"
                                      value={selectedData.customAmount}
                                      defaultChecked={paymentOption}
                                      className="me-2"
                                      onChange={e => {
                                        if (e.target.checked) {
                                          setPaymentOption(true)
                                          handleValueChange(e.target, "amount")
                                        } else {
                                          setPaymentOption(false)
                                        }
                                      }}
                                    ></AvField>

                                    <Label>Other Amount</Label>
                                  </div>
                                  <AvField
                                    type="number"
                                    name="amount"
                                    value={selectedData.customAmount}
                                    onChange={e => {
                                      handleValueChange(
                                        e.target,
                                        "customAmount"
                                      )
                                    }}
                                    validate={{
                                      min: {
                                        value: 1,
                                        errorMessage: "Enter valid amount",
                                      },
                                      max: {
                                        value: paymentData.due,
                                        errorMessage:
                                          "Amount is greater than due",
                                      },
                                    }}
                                    style={{ width: "100px", height: "30px" }}
                                    disabled={!paymentOption}
                                  ></AvField>
                                </Col>
                                <Col md={6}></Col>

                                <Col md={6}>
                                  <AvField
                                    type="text"
                                    name="comments"
                                    label="Comments"
                                    value={selectedData.comments}
                                    onChange={e => {
                                      handleValueChange(e.target, "comments")
                                    }}
                                  ></AvField>
                                </Col>
                                <Col md={3} className="mt-4">
                                  <Button color="primary">Submit</Button>
                                </Col>
                              </Row>
                            </Col>
                          </>
                        ) : selectedData.event ? (
                          <>
                            <Col md={2}>
                              <div className="mb-3">
                                <Label>Date</Label>
                                <AvField
                                  name="date"
                                  classNamePrefix="select2-selection"
                                  type="date"
                                  value={selectedData.date}
                                  onChange={e => {
                                    handleValueChange(e.target, "date")
                                  }}
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: "Enter Date",
                                    },
                                    max: {
                                      value: getDate(),
                                      errorMessage: "Date is invalid",
                                    },
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md={2}>
                              <div className="mb-3">
                                <Label>Time</Label>
                                <AvField
                                  name="time"
                                  classNamePrefix="select2-selection"
                                  type="time"
                                  value={selectedData.time}
                                  onChange={e => {
                                    handleValueChange(e.target, "time")
                                  }}
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: "Enter Date",
                                    },
                                  }}
                                />
                              </div>
                            </Col>

                            <Col md={3}>
                              <div className="d-flex justify-content-between">
                                <Label>Event Fee</Label>
                                <Label>₹{paymentData.eventFee}</Label>
                              </div>
                              <div className="d-flex justify-content-between">
                                <Label>Paid Amount</Label>
                                <Label>₹{paymentData.paid}</Label>
                              </div>
                              <div className="d-flex justify-content-between">
                                <Label>Due Amount</Label>
                                <Label>₹{paymentData.due}</Label>
                              </div>
                            </Col>
                            <Col md={2}>
                              <AvField
                                type="text"
                                name="comments"
                                label="Comments"
                                className="mt-2"
                                value={selectedData.comments}
                                onChange={e => {
                                  handleValueChange(e.target, "comments")
                                }}
                              ></AvField>
                            </Col>
                            <Col md={1}>
                              <Button
                                style={{ marginTop: "35px" }}
                                color="primary"
                              >
                                Submit
                              </Button>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            ""
          )}

          <Card>
            <CardBody>
              <AvForm ref={filterRef}>
                <Row>
                  <Col md={2}>
                    <div className="mb-3">
                      <Label>From </Label>
                      <AvField
                        name="from"
                        className="form-control"
                        type="date"
                        value={filterObject.from || ""}
                        onChange={e => {
                          handleFilerValueChange(e.target.value, "from")
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="mb-3">
                      <Label>To </Label>
                      <AvField
                        name="to"
                        className="form-control"
                        type="date"
                        value={filterObject.to || ""}
                        onChange={e => {
                          handleFilerValueChange(e.target.value, "to")
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <Label>Student</Label>
                    <SearchStudent
                      api={`/fee/options/manual/student`}
                      onChange={selected => {
                        handleFilterSelectChange(selected, "student")
                      }}
                      value={filterSelectedFields.student}
                      master={filterObject}
                    />
                  </Col>
                  <Col md={2}>
                    <div className="mb-3">
                      <Label>For</Label>
                      <Select
                        name="for"
                        classNamePrefix="select2-selection"
                        options={forOptions}
                        value={filterSelectedFields.for || ""}
                        menuPosition="fixed"
                        onChange={selected => {
                          handleFilterSelectChange(selected, "for")
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="mb-3">
                      <Label>Course/Event</Label>
                      <Select
                        name="course"
                        classNamePrefix="select2-selection"
                        options={courses}
                        value={filterSelectedFields.course || ""}
                        menuPosition="fixed"
                        onChange={selected => {
                          handleFilterSelectChange(selected, "course")
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="mb-3">
                      <Label>Batch</Label>
                      <Select
                        name="course"
                        classNamePrefix="select2-selection"
                        options={batches}
                        value={filterSelectedFields.batch || ""}
                        onChange={selected => {
                          handleFilterSelectChange(selected, "batch")
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="mb-3">
                      <Label>Branch</Label>
                      <Select
                        name="course"
                        classNamePrefix="select2-selection"
                        options={branches}
                        menuPosition="fixed"
                        value={filterSelectedFields.branch || ""}
                        onChange={selected => {
                          handleValueChange(selected.value, "branch")
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="mb-3 mt-4">
                      <Button color="danger" onClick={reset}>
                        Reset
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>

              <MDBDataTable
                id="manualPaymentsTable"
                className="mt-3"
                bordered
                responsive
                searching={false}
                entries={20}
                paging={false}
                info={false}
                disableRetreatAfterSorting={true}
                data={data}
                sortable={false}
              ></MDBDataTable>
              <TablePagination
                page={page}
                onChange={handleChange}
                count={totalPage}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  )
}

export default ManualPayments
