import { MDBDataTable } from "mdbreact"
import React, { useEffect, useState } from "react"
import { Col, Row, Modal } from "reactstrap"
import { get } from "../../../../../../helpers/api_helper"
import moment from "moment"
import { Tooltip } from "@mui/material"
import SkillLoanProcessing from "../../../../../Finance/SkillLoan/Processing"
import ScholarshipProcessing from "../../../../../Finance/Scholarship/Processing"
import Followup from "../../../../../Leads/Leads/Add-Lead/Followup"
import "./style.scss"
import TablePagination from "../../../../../../components/Common/Pagination"
const Student = ({ batchId }) => {
  const [rows, setRows] = useState([])

  const [page, setPage] = useState(1)

  const [limit, setLimit] = useState(20)

  const [count, setCount] = useState(0)

  const [scholarship, setScholarship] = useState({ show: false })

  const [skilloan, setSkilloan] = useState({ show: false })

  const [followup, setFollowup] = useState({ modal: false, studentId: null })

  useEffect(() => {
    get(`temp-batch/students/${batchId}?page=${page}&limit=${limit}`).then(res => {
      const data = res.data || []

      setCount(Math.ceil(Number(res.count) / limit) || 0)

      data.map((item, idx) => {
        item.id = idx + 1

        item.student = `${item?.reg_id?.firstname || ""} ${
          item?.reg_id?.lastname || ""
        }`

        item.unique_id = item?.reg_id?.unique_id

        item.mobile = item.reg_id?.mobile

        item.skillloan = item?.skillLoan?.skillLoanId?.name
        item.scholarships = item?.scholarship?.scholarshipId?.name

        item.status = (
          <div className="text-center">
            <span
              style={{
                background: item?.latest_followup_status?.color,
              }}
              className="badge font-size-12 ms-2"
            >
              {item?.latest_followup_status?.name}
            </span>
          </div>
        )

        // item.paid = item?.purchased?.transactionId?.amount || 0
        item.paid = item?.courseFee?.paidAmount || "0"
        item.due =
          item?.courseFee?.payableAmount - item?.courseFee?.paidAmount || "0"

        item.date = moment(item.date).format("DD-MM-YYYY")
        item.time = moment(item.time, "HH:mm:ss").format("hh:mm a")

        item.action = (
          <div>
            <Tooltip title="Followup" arrow placement="top">
              <i
                className="fas fa-eye me-2"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                }}
                onClick={() => {
                  // getLeadSingle(item._id)
                  setFollowup({
                    modal: true,
                    studentId: item?.reg_id?._id,
                    leadId: item._id,
                  })
                }}
              ></i>
            </Tooltip>

            {item.skillLoan.requested && item.skillLoan?.requestedId && (
              <Tooltip title="Skill loan" arrow placement="top">
                <i
                  onClick={() =>
                    setSkilloan({
                      show: true,
                      id: item?.skillLoan?.requestedId,
                    })
                  }
                  className="fa fa-university me-2"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                  }}
                ></i>
              </Tooltip>
            )}

            {item.scholarship.requested && item.scholarship?.requestedId && (
              <Tooltip title="Scholarship" arrow placement="top">
                <i
                  onClick={() =>
                    setScholarship({
                      show: true,
                      id: item?.scholarship?.requestedId,
                    })
                  }
                  className="fa fa-graduation-cap me-2"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                  }}
                ></i>
              </Tooltip>
            )}
          </div>
        )
        return item
      })

      setRows(data)
    })
    // eslint-disable-next-line
  }, [page,limit])

  const data = {
    // eslint-disable-next-line
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        width: 150,
      },
      {
        label: "ID",
        field: "unique_id",
        width: 150,
      },
      {
        label: "Student",
        field: "student",
        width: 150,
      },
      {
        label: "Mobile",
        field: "mobile",
        width: 150,
      },
      {
        label: "Req. Scholarship",
        field: "scholarships",
        width: 150,
      },
      {
        label: "Req. Skill Loan",
        field: "skillloan",
        sort: "desc",
        width: 400,
      },
      {
        label: "Paid",
        field: "paid",
        sort: "desc",
        width: 400,
      },
      {
        label: "Due",
        field: "due",
        sort: "desc",
        width: 400,
      },
      {
        label: "Status",
        field: "status",
        sort: "desc",
        width: 400,
      },
      {
        label: "Action",
        field: "action",
        sort: "desc",
        width: 400,
      },
    ],
    rows,
  }

  return (
    <>
      <Row>
        <Col md="12">
          <MDBDataTable
            id="TmeporaryDetails"
            responsive
            bordered
            data={data}
            // searching={true}
            paging={false}
            info={true}
            disableRetreatAfterSorting={true}
            entries={20}
          />
          <TablePagination
            page={page}
            onChange={(e, value) => setPage(value)}
            count={count}
          />
        </Col>
      </Row>
      {followup.modal && (
        <Modal
          show={followup.modal}
          isOpen={followup.modal}
          toggle={() => {
            // refresh()
            setFollowup({ modal: false })
          }}
          size="xl"
          centered={true}
          id="leadModal"
          className="lead-modal"
        >
          <div className="modal-header">
            <div className="modal-title">
              <h6 style={{ marginBottom: "0px" }} className="modal-title mt-0">
                {/* #{leadData.unique_id} */}
              </h6>
            </div>
            <button
              style={{ marginBottom: "2px" }}
              type="button"
              onClick={() => {
                // refresh()
                setFollowup({ modal: false })
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>

            <Followup followup={followup} />
          </div>
        </Modal>
      )}

      {scholarship.show && (
        <Modal
          show={scholarship.show}
          isOpen={scholarship.show}
          size="xl"
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Scholarship Details</h5>
            <button
              type="button"
              onClick={() => {
                setScholarship({ show: false })
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <ScholarshipProcessing id={scholarship?.id} />
          </div>
        </Modal>
      )}

      {skilloan.show && (
        <Modal show={skilloan.show} isOpen={skilloan.show} size="xl" centered>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Skill Loan Details</h5>
            <button
              type="button"
              onClick={() => {
                setSkilloan({ show: false })
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <SkillLoanProcessing id={skilloan?.id} />
          </div>
        </Modal>
      )}
    </>
  )
}

export default Student
