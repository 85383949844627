import axios from "axios"
import { API_URL } from "../config"
import Cookies from "js-cookie"
import { AccessToken } from "./backend_helper"
import { getBranch } from "./functions"

//pass new generated access token here
const token = () => Cookies.get("_token")

//apply base url for axios

axios.defaults.withCredentials = true

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.interceptors.response.use(
  response => response,
  async error => {
    if (error?.response?.status === 403) {
      try {
        await AccessToken({ refreshToken: token() })
        const response = await axios(error.config)
        return response
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError)
        Cookies.remove("_token")
        localStorage.removeItem("authUser")
        return Promise.reject(refreshError)
      }
    }
    console.log("Not a 401 or 403 error, or token refresh failed")
    return Promise.reject(error)
  }
)

axiosApi.interceptors.request.use(config => {
  // For GET requests, set default query parameters

  let branch = getBranch()

  if (
    config.url !== "/user/allotted" &&
    !config.url.startsWith("/auth/login") &&
    !config.url.startsWith("/menu")
  ) {
    config.headers = {
      ...config.headers,
      branch: branch ? branch.value : null,
    }
  }

  return config
})

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi.post(url, data, { ...config }).then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
