import { Pagination } from "@mui/material"
import { MDBDataTable } from "mdbreact"
import PropTypes from "prop-types"

const DataTable = props => {
  return (
    <>
      <div className={`row ${props.rootClassName}`}>
        <div
          dataTest="datatable-entries"
          className="col-sm-12 col-md-6"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div
            dataTest="datatable-select"
            className="dataTables_length bs-select"
          >
            {props.onLimitChange &&
              (props.entries || props.entries === undefined) && (
                <label>
                  Show entries
                  <select
                    className="custom-select custom-select-sm form-control form-control-sm"
                    style={{ marginLeft: "0.5rem" }}
                    value={props.limit || 20}
                    onChange={e => {
                      props.onLimitChange(e.target.value)
                    }}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </label>
              )}
          </div>
        </div>
        <div
          dataTest="datatable-search"
          className="col-sm-12 col-md-6"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <div
            dataTest="datatable-input"
            className="mdb-datatable-filter flex-row"
          >
            {props.onSearch && (
              <input
                className="form-control form-control-sm ml-0 my-1"
                type="text"
                placeholder="Search"
                aria-label="Search"
                onChange={e => {
                  props.onSearch(e.target.value)
                }}
                value={props.searchValue || ""}
              />
            )}
          </div>
        </div>
      </div>
      <MDBDataTable
        id={props.id}
        sortable={false}
        searching={false}
        displayEntries={false}
        paging={false}
        disableRetreatAfterSorting={true}
        bordered
        responsive
        entries={20}
        data={props.data}
        className={props.className}
      ></MDBDataTable>
      <div class="row align-items-center">
        <div dataTest="datatable-info" className="col-sm-12 col-md-5">
          {props.page && props.count ? (
            <div className="dataTables_info" role="status" ariaLive="polite">
              Showing {(props.page - 1) * props.limit + 1} to{" "}
              {Math.min(props.page * props.limit, props.count)} of {props.count}{" "}
              entries
            </div>
          ) : (
            <></>
          )}
        </div>
        <div data-test="datatable-pagination" className="col-sm-12 col-md-7">
          <div className="dataTables_paginate">
            {props?.count && props.page ? (
              <Pagination
                page={props.page}
                count={Math.ceil(props.count / props.limit)}
                shape="rounded"
                size="small"
                onChange={(e, value) => props.onPageChange(value)}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

DataTable.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  rootClassName: PropTypes.string,
  onSearch: PropTypes.func,
  searchValue: PropTypes.string,
  onPageChange: PropTypes.func,
  onLimitChange: PropTypes.func,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  limit: PropTypes.number,
  data: PropTypes.array.isRequired,
  entries: PropTypes.bool,
}

export default DataTable
