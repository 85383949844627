import React, { useEffect, useState } from "react"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, Label, Button } from "reactstrap"
import Select from "react-select"
import { AvField, AvForm } from "availity-reactstrap-validation"
import moment from "moment"
import { isArray } from "lodash"
import { useNavigate } from "react-router-dom"
import { get } from "../../helpers/api_helper"
import "./style.scss"
import {
  getStudent,
  getCourses,
  getSectors,
  getCluster,
} from "../../store/options/actions"
import { useSelector, useDispatch } from "react-redux"
import { checkingPrivilege, jsonToExcel } from "../../helpers/functions"
import toastr from "toastr"
import { PRIVILEGES } from "../../config"
import MuiCustomTablePagination from "../../components/Common/TablePagination"

const Students = () => {
  // eslint-disable-next-line
  const dispatch = useDispatch()

  const [count, setCount] = useState(0)

  const { cluster, students, sectors, courses } = useSelector(
    state => state.Options
  )

  const isTrainer = checkingPrivilege(PRIVILEGES.TRAINER)

  useEffect(() => {
    // dispatch(getSectors())
    dispatch(getStudent())
    dispatch(getCourses())
    dispatch(getCluster())
    // eslint-disable-next-line
  }, [])
  const initialState = {
    fromDate: "",
    toDate: "",
    cluster: "",
    batch: "",
    sectors: "",
    course: "",
    status: "",
    page: 0,
    pageSize: 20,
  }
  const [selected, setSelected] = useState({ status: "" })
  const [tabledetails, setTableDetails] = useState([])
  const [batchesOptions, setBatchesOptions] = useState([])
  const [filterObject, setFilterObject] = useState(initialState)
  const [exports, setExports] = useState([])

  const navigate = useNavigate()
  useEffect(() => {
    handleTableData()
  }, [filterObject])

  useEffect(() => {
    handleBatchOptions(filterObject?.course ?? null)
  }, [filterObject?.course])

  const handleBatchOptions = async course => {
    get(`/options/batches?course=${course}`).then(res => {
      setBatchesOptions(res?.data || [])
    })
  }
  const handleValueChange = async (selected, name) => {
    const obj = { ...filterObject }
    obj[name] = selected.value
    obj.page = 0
    if (name === "cluster") {
      dispatch(getSectors(selected.sector))
    }
    console.log("obj", obj)
    setFilterObject(obj)
  }
  function handleTableData() {
    const filterQuery = `fromDate=${filterObject.fromDate}&toDate=${filterObject.toDate}&batch=${filterObject.batch}&sector=${filterObject.sectors}&course=${filterObject.course}&status=${filterObject.status}&page=${filterObject.page}&limit=${filterObject.pageSize}`
    get(`student?${filterQuery}`).then(res => {
      const result = res.data
      setCount(res.count)
      const exportData = []

      result.map((item, index) => {
        item.id = index + 1 + filterObject.page * filterObject.pageSize
        item.date = moment(item.date).format("DD-MM-YYYY")
        item.addedby = item?.addedBy?.firstName
        item.course = item?.course?.name
        item.csp = item?.branch?.name
        item.batch = item?.batch?.name
        item.student =
          item?.profile?.firstname + " " + item?.profile?.lastname || " "
        item.option = (
          <div>
            <i
              className="fas fa-eye"
              onClick={() => {
                navigate(`/student-details/${item.profile.unique_id}`, {
                  state: { id: item.profile._id },
                })
              }}
              style={{
                fontSize: "1.1em",
                cursor: "pointer",
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
            />
          </div>
        )

        const dataExport = {}

        dataExport.Date = item.date
        dataExport.StudentID = item?.profile?.unique_id
        dataExport.Student = item.student
        dataExport.Center = item.csp
        dataExport.Course = item.course
        dataExport.Batch = item.batch
        dataExport.Fee = item.fee
        dataExport.Paid = item.paid
        dataExport.Due = item.feeDue
        exportData.push(dataExport)

        item.studentId = item?.profile?.unique_id
        item.fee = item.fee?.toLocaleString("en-IN") || 0
        item.paid = item.paid?.toLocaleString("en-IN") || 0
        item.due = item.feeDue?.toLocaleString("en-IN") || 0

        return item
      })
      setTableDetails(result)
      setExports(exportData)
    })
  }
  const data = isTrainer
    ? {
        columns: [
          {
            label: "#",
            field: "id",
            sort: "disabled",
            width: 150,
            hidden: true,
          },
          {
            label: "Date",
            field: "date",
            sort: "asc",
            width: 200,
          },
          {
            label: "Student",
            field: "student",
            sort: "asc",
            width: 200,
          },
          {
            label: "Register No",
            field: "studentId",
            sort: "asc",
            width: 200,
          },
          {
            label: "CSP",
            field: "csp",
            sort: "asc",
            width: 200,
          },

          {
            label: "Course",
            field: "course",
            sort: "asc",
            width: 100,
          },
          {
            label: "Batch",
            field: "batch",
            sort: "asc",
            width: 100,
          },
        ],
        rows: tabledetails,
      }
    : {
        columns: [
          {
            label: "#",
            field: "id",
            sort: "disabled",
            width: 150,
            hidden: true,
          },
          {
            label: "Date",
            field: "date",
            sort: "asc",
            width: 200,
          },
          {
            label: "Student",
            field: "student",
            sort: "asc",
            width: 200,
          },
          {
            label: "Register No",
            field: "studentId",
            sort: "asc",
            width: 200,
          },
          {
            label: "CSP",
            field: "csp",
            sort: "asc",
            width: 200,
          },

          {
            label: "Course",
            field: "course",
            sort: "asc",
            width: 100,
          },
          {
            label: "Batch",
            field: "batch",
            sort: "asc",
            width: 100,
          },

          {
            label: (
              <>
                <i className="fas fa-rupee-sign"></i> Fee
              </>
            ),
            field: "fee",
            sort: "asc",
            width: 100,
          },
          {
            label: (
              <>
                <i className="fas fa-rupee-sign"></i> Paid
              </>
            ),
            field: "paid",
            sort: "asc",
            width: 100,
          },
          {
            label: (
              <>
                <i className="fas fa-rupee-sign"></i> Due
              </>
            ),
            field: "due",
            sort: "asc",
            width: 100,
          },
          // {
          //   label: "Staff",
          //   field: "addedby",
          //   sort: "asc",
          //   width: 100,
          // },
          {
            label: "Action",
            field: "option",
            sort: "disabled",
            width: 100,
          },
        ],
        rows: tabledetails,
      }
  const handleSelect = (e, name) => {
    const value = isArray(e) ? e.map(el => el.value) : e.value

    setSelected(pre => ({ ...pre, [name]: e }))
    setFilterObject(pre => ({ ...pre, [name]: value, page: 0, pageSize: 20 }))
  }
  // console.log(filterObject,"filterobjrct");
  const reset = () => {
    setSelected({ status: null })
    setFilterObject(initialState)
  }
  const handleExport = () => {
    if (exports.length > 0) {
      jsonToExcel(exports, `Students-${moment().format("DD-MM-YY")}`)
    } else {
      toastr.info(`There are no students to export`)
    }
  }

  const handleChangeRowsPerPage = event => {
    setFilterObject({
      ...filterObject,
      pageSize: parseInt(event.target.value, 10),
      page: 0,
    })
  }

  const handlePageChange = newPage => {
    setFilterObject({
      ...filterObject,
      page: newPage,
    })
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem="Students" />
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md="2">
                        <Label>From Date</Label>
                        <AvField
                          name="fromDate"
                          type="date"
                          className="form-control"
                          placeholder="Last Name"
                          // value={moment().format("YYYY-MM-DD")}
                          value={filterObject.fromDate}
                          onChange={e => {
                            handleValueChange(e.target, "fromDate")
                          }}
                        />
                      </Col>
                      <Col md="2">
                        <Label>To Date</Label>
                        <AvField
                          name="toDate"
                          type="date"
                          value={filterObject.toDate}
                          onChange={e => {
                            handleValueChange(e.target, "toDate")
                          }}
                          className="form-control"
                          max={filterObject.fromDate}
                        />
                      </Col>
                      <Col md="2">
                        <Label>Cluster</Label>
                        <Select
                          name="cluster"
                          onChange={selected => {
                            handleValueChange(selected, "cluster")
                          }}
                          value={cluster?.filter(
                            item => item.value === filterObject?.cluster
                          )}
                          options={cluster}
                          classNamePrefix="select2-selection"
                          menuPosition="auto"
                        />
                      </Col>
                      <Col md="2">
                        <Label>Sector</Label>
                        <Select
                          name="sectors"
                          options={sectors}
                          // value={sectors.filter(
                          //   item => item.value === filterObject.sector || null
                          // )}
                          onChange={selected => {
                            handleValueChange(selected, "sectors")
                          }}
                          value={sectors?.filter(
                            item => item.value === filterObject?.sectors
                          )}
                          classNamePrefix="select2-selection"
                          menuPosition="auto"
                        />
                      </Col>

                      <Col md="2">
                        <Label>Course</Label>
                        <Select
                          name="course"
                          options={courses}
                          value={courses?.filter(
                            item => item.value === filterObject.course
                          )}
                          onChange={selected => {
                            handleValueChange(selected, "course")
                          }}
                          classNamePrefix="select2-selection"
                          menuPosition="auto"
                        />
                      </Col>

                      <Col md="2">
                        <Label>Batch</Label>
                        <Select
                          onChange={e => handleSelect(e, "batch")}
                          value={selected.batch || null}
                          options={batchesOptions}
                          classNamePrefix="select2-selection"
                          menuPosition="auto"
                        />
                      </Col>
                      <Col md="2">
                        <Label>Status</Label>
                        <Select
                          name="status"
                          onChange={e => handleSelect(e, "status")}
                          value={selected.status}
                          options={[
                            { label: "Active", value: 0 },
                            { label: "Inactive", value: 2 },
                          ]}
                          classNamePrefix="select2-selection"
                          menuPosition="auto"
                        />
                      </Col>

                      <Col md="3">
                        <div className="mt-4 pt-1">
                          <Button
                            onClick={reset}
                            className="me-2"
                            color="danger"
                            type="reset"
                          >
                            Reset
                          </Button>
                          <Button
                            style={{}}
                            color="warning"
                            type="button"
                            onClick={handleExport}
                          >
                            Export
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="studentTableId"
                    paging={false}
                    responsive
                    bordered
                    sortable={false}
                    disableRetreatAfterSorting={true}
                    data={data}
                  />
                  {tabledetails.length > 0 && (
                    <MuiCustomTablePagination
                      count={count}
                      page={filterObject.page}
                      rowsPerPage={filterObject.pageSize}
                      handleChangePage={handlePageChange}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default Students
