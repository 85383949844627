import { takeEvery, put, call } from "redux-saga/effects"

//Account Redux states
import {
  GET_BRANCHES,
  GET_CATEGORY,
  GET_COMPANIES,
  GET_COUNTRIES,
  GET_DISTRICTS,
  GET_MODULES,
  GET_PRIVILEGES,
  GET_STATES,
  GET_USERS,
  GET_ACCOUNT_TYPE,
  GET_ACCOUNT_SUB_TYPE,
  GET_CHART_ACCOUNT,
  GET_CERTIFICATION_PARTNER,
  GET_OPERATING_PARTNER,
  GET_TRAINING_PARKING,
  GET_SECTOR,
  GET_QUALIFICATION,
  GET_DEGREE,
  GET_STREAM,
  GET_COURSES,
  GET_STUDENT,
  GET_SKILL_LOANS,
  GET_SKILL_LOAN_STATUS,
  GET_STUDENT_CATEGORY,
  GET_SCHOLARSHIPS,
  GET_SCHOLARSHIP_STATUS,
  GET_MINORITY_STATUS,
  GET_LEAD_SOURCE,
  GET_CAMPAIGN,
  GET_TEMP_BATCH,
  GET_CLUSTER,
  GET_EXPENSE_CATEGORY,
  GET_CLASSROOMS_LABS,
  GET_EVENTS,
} from "./actionTypes"
import {
  getBranchesSuccessful,
  getCategorySuccessful,
  getCompaniesSuccessful,
  getCountriesSuccessful,
  getDistrictSuccessful,
  getModulesSuccessful,
  getPrivilegesSuccessful,
  getStatesSuccessful,
  getUsersSuccessful,
  getAccountTypeSuccessful,
  getAccountSubTypeSuccessful,
  getChartAccountSuccessful,
  getOperatingPartnerSuccessful,
  getTrainingPartnerSuccessful,
  getCertificationPartnerSuccessful,
  getSectorsSuccess,
  getQualificationSuccess,
  getStreamSuccess,
  getDegreeSuccess,
  getCoursesSuccess,
  getStudentSuccess,
  getSkillLoanSuccess,
  getSkillLoanStatusSuccess,
  getLeadSourceSuccess,
  getCampaignSuccess,
  getScholarshipSuccess,
  getScholarshipStatusSuccess,
  getMinorityStatusSuccess,
  getStudentCategorySuccess,
  getTempBatchSuccess,
  getClusterSuccess,
  getExpenseCategorySuccess,
  getClassRoomsOrLabsSuccess,
  getEventsSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getBranchesOptions,
  getCategoriesOptions,
  getCompaniesOptions,
  getCountriesOptions,
  getDistrictsOptions,
  getModules,
  getPrivileges,
  getStatesOptions,
  getUsersOptions,
  getAccountType,
  getAccountSubType,
  getChartAccount,
  getSectorList,
  getQualification,
  getCourses,
  getStudents,
  getSkillLoan,
  getSkillLoanStatus,
  getLeadSource,
  getCampaign,
  getStudentCategory,
  getScholarship,
  getScholarshipStatus,
  getStream,
  getDegree,
  getMinorityStatus,
  getTempBatch,
  getCluster,
  getExpenseCategory,
  getClassRoomOrLabsOptions,
  getEventsOptions,
} from "../../helpers/backend_helper"

function* fetchPrivileges({ payload }) {
  try {
    const response = yield call(getPrivileges, payload)
    yield put(getPrivilegesSuccessful(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}

function* fetchModules({ payload }) {
  try {
    const response = yield call(getModules)
    yield put(getModulesSuccessful(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}

function* fetchUsers({ payload }) {
  try {
    const response = yield call(getUsersOptions, payload)
    yield put(getUsersSuccessful(response))
  } catch (error) {
    return error
  }
}

function* fetchCountries() {
  try {
    const response = yield call(getCountriesOptions)
    yield put(getCountriesSuccessful(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}
function* fetchStates({ payload }) {
  try {
    const response = yield call(getStatesOptions)
    yield put(getStatesSuccessful(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}

function* fetchDistricts({ payload }) {
  try {
    const response = yield call(getDistrictsOptions, payload)
    yield put(getDistrictSuccessful(response))
  } catch (error) {
    console.log(error)
    return error
  }
}

function* fetchCompanies({ payload }) {
  try {
    const response = yield call(getCompaniesOptions, payload)
    yield put(getCompaniesSuccessful(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}

function* fetchBranches({ payload }) {
  try {
    const response = yield call(getBranchesOptions, payload)
    yield put(getBranchesSuccessful(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}
function* fetchCategory({ payload }) {
  try {
    const response = yield call(getCategoriesOptions, payload)
    yield put(getCategorySuccessful(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}

function* fetchAccountType({ payload }) {
  try {
    const response = yield call(getAccountType, payload)
    yield put(getAccountTypeSuccessful(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}

function* fetchAccountSubType({ payload }) {
  try {
    const response = yield call(getAccountSubType, payload)
    yield put(getAccountSubTypeSuccessful(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}
function* fetchChartAccountType({ payload }) {
  try {
    const response = yield call(getChartAccount, payload)
    yield put(getChartAccountSuccessful(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}

function* fetchOperatingPartners({ payload }) {
  try {
    const response = yield call(getCompaniesOptions, payload)
    yield put(getOperatingPartnerSuccessful(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}

function* fetchTrainingPartners({ payload }) {
  try {
    const response = yield call(getCompaniesOptions, payload)
    yield put(getTrainingPartnerSuccessful(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}
function* fetchCertificationPartners({ payload }) {
  try {
    const response = yield call(getCompaniesOptions, payload)
    yield put(getCertificationPartnerSuccessful(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}

function* fetchAllSectorList({ payload }) {
  try {
    const response = yield call(getSectorList, payload)
    yield put(getSectorsSuccess(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}

function* fetchAllQualification({ payload }) {
  try {
    const response = yield call(getQualification, payload)
    yield put(getQualificationSuccess(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}

function* fetchAllStream({ payload }) {
  try {
    const response = yield call(getStream, payload)
    yield put(getStreamSuccess(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}

function* fetchAllDegree({ payload }) {
  try {
    const response = yield call(getDegree, payload)
    yield put(getDegreeSuccess(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}

function* fetchAllCourses({ payload }) {
  try {
    const response = yield call(getCourses, payload)
    yield put(getCoursesSuccess(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}

function* fetchStudents({ payload }) {
  try {
    const response = yield call(getStudents, payload)
    yield put(getStudentSuccess(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}

function* fetchSkillLoan({ payload }) {
  try {
    const response = yield call(getSkillLoan, payload)
    yield put(getSkillLoanSuccess(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}

function* fetchSkillLoanStatus({ payload }) {
  try {
    const response = yield call(getSkillLoanStatus, payload)
    yield put(getSkillLoanStatusSuccess(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}

function* fetchScholarship({ payload }) {
  try {
    const response = yield call(getScholarship, payload)
    yield put(getScholarshipSuccess(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}

function* fetchScholarshipStatus({ payload }) {
  try {
    const response = yield call(getScholarshipStatus, payload)
    yield put(getScholarshipStatusSuccess(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}

function* fetchMinorityStatus({ payload }) {
  try {
    const response = yield call(getMinorityStatus, payload)
    yield put(getMinorityStatusSuccess(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}

function* fetchStudentCategory({ payload }) {
  try {
    const response = yield call(getStudentCategory, payload)
    yield put(getStudentCategorySuccess(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}

function* fetchLeadSource({ payload }) {
  try {
    const response = yield call(getLeadSource, payload)
    yield put(getLeadSourceSuccess(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}
function* fetchCampaign({ payload }) {
  try {
    const response = yield call(getCampaign, payload)
    yield put(getCampaignSuccess(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}

function* fetchTempBatch({ payload }) {
  try {
    const response = yield call(getTempBatch, payload)
    yield put(getTempBatchSuccess(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}
function* fetchCluster({ payload }) {
  try {
    const response = yield call(getCluster, payload)
    yield put(getClusterSuccess(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}
function* fetchExpenseCategory({ payload }) {
  try {
    const response = yield call(getExpenseCategory, payload)
    yield put(getExpenseCategorySuccess(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}

function* fetchClassRoomsLabs({ payload }) {
  try {
    const response = yield call(getClassRoomOrLabsOptions, payload)
    yield put(getClassRoomsOrLabsSuccess(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}

function* fetchEventsOptions({ payload }) {
  try {
    const response = yield call(getEventsOptions, payload)
    yield put(getEventsSuccess(response))
  } catch (error) {
    console.log(error.response.data)
    return error
  }
}

function* optionsSaga() {
  yield takeEvery(GET_PRIVILEGES, fetchPrivileges)
  yield takeEvery(GET_MODULES, fetchModules)
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(GET_COUNTRIES, fetchCountries)
  yield takeEvery(GET_STATES, fetchStates)
  yield takeEvery(GET_DISTRICTS, fetchDistricts)
  yield takeEvery(GET_COMPANIES, fetchCompanies)
  yield takeEvery(GET_BRANCHES, fetchBranches)
  yield takeEvery(GET_CATEGORY, fetchCategory)
  yield takeEvery(GET_ACCOUNT_TYPE, fetchAccountType)
  yield takeEvery(GET_ACCOUNT_SUB_TYPE, fetchAccountSubType)
  yield takeEvery(GET_CHART_ACCOUNT, fetchChartAccountType)
  yield takeEvery(GET_OPERATING_PARTNER, fetchOperatingPartners)
  yield takeEvery(GET_TRAINING_PARKING, fetchTrainingPartners)
  yield takeEvery(GET_CERTIFICATION_PARTNER, fetchCertificationPartners)
  yield takeEvery(GET_SECTOR, fetchAllSectorList)
  yield takeEvery(GET_QUALIFICATION, fetchAllQualification)
  yield takeEvery(GET_DEGREE, fetchAllDegree)
  yield takeEvery(GET_STREAM, fetchAllStream)
  yield takeEvery(GET_COURSES, fetchAllCourses)
  yield takeEvery(GET_STUDENT, fetchStudents)
  yield takeEvery(GET_SKILL_LOANS, fetchSkillLoan)
  yield takeEvery(GET_SKILL_LOAN_STATUS, fetchSkillLoanStatus)
  yield takeEvery(GET_SCHOLARSHIPS, fetchScholarship)
  yield takeEvery(GET_SCHOLARSHIP_STATUS, fetchScholarshipStatus)
  yield takeEvery(GET_MINORITY_STATUS, fetchMinorityStatus)
  yield takeEvery(GET_STUDENT_CATEGORY, fetchStudentCategory)
  yield takeEvery(GET_LEAD_SOURCE, fetchLeadSource)
  yield takeEvery(GET_CAMPAIGN, fetchCampaign)
  yield takeEvery(GET_TEMP_BATCH, fetchTempBatch)
  yield takeEvery(GET_CLUSTER, fetchCluster)
  yield takeEvery(GET_EXPENSE_CATEGORY, fetchExpenseCategory)
  yield takeEvery(GET_CLASSROOMS_LABS, fetchClassRoomsLabs)
  yield takeEvery(GET_EVENTS, fetchEventsOptions)
}

export default optionsSaga
