import { MDBDataTable } from "mdbreact"
import React, { useEffect, useState } from "react"
import { Col, Row, Badge } from "reactstrap"
import { get } from "../../../../helpers/api_helper"
import moment from "moment"
import "./style.scss"
const EnrolledCourse = props => {
  useEffect(() => {
    EnrolledCourse()
  }, [props?.student?._id])

  const [selectedenrolled, setselectedEnrolled] = useState([])

  function EnrolledCourse() {
    get(`student/enrolled_course?id=${props?.student?._id}`)
      .then(res => {
        const result = res?.data

        result?.map((item, index) => {

          item.id = index + 1
          item.batch1 = item?.batch?.name
          item.trainer1 = item?.trainer?.name
          item.startdate = item?.batch?.startDate? moment(item?.batch?.startDate).format("DD-MM-YYYY") : ""
          item.enddate =item?.batch?.endDate ? moment(item?.batch?.endDate).format("DD-MM-YYYY") : ""
          item.course1 = item?.course.name
          item.date = moment(item?.date).format("DD-MM-YYYY")
          item.unit =
            item?.course?.unit === "1"
              ? "Hours"
              : item?.course?.unit === "2"
              ? "Days"
              : item?.course?.unit === "3"
              ? "Months"
              : ""
             
           item.duration = (item?.course?.duration || "") + " " + item.unit

          item.status =
            item.status === 0 ? (
              <>
                <Badge
                  className="bg-success"
                  style={{ fontSize: "12px", padding: ".3rem" }}
                >
                  Active
                </Badge>
              </>
            ) : (
              <>
                <Badge
                  className="bg-warning"
                  style={{ fontSize: "12px", padding: ".3rem" }}
                >
                  Inactive
                </Badge>
              </>
            )
          return item
        })
        setselectedEnrolled(result)
      })

      .catch(error => {
        console.error("Error :", error)
      })
  }

  const jobPosts = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Batch",
        field: "batch1",
        sort: "asc",
        width: 400,
      },
      {
        label: "Course",
        field: "course1",
        sort: "asc",
        width: 400,
      },
      {
        label: "Start Date",
        field: "startdate",
        sort: "asc",
        width: 400,
      },
      {
        label: "End Date",
        field: "enddate",
        sort: "asc",
        width: 400,
      },
      {
        label: "Duration",
        field: "duration",
        sort: "asc",
        width: 400,
      },

      {
        label: "Trainer",
        field: "trainer1",
        sort: "asc",
        width: 400,
      },
      // {
      //   label: "Status",
      //   field: "status",
      //   sort: "asc",
      //   width: 400,
      // },
      // {
      //   label: "Action",
      //   field: "action",
      //   sort: "asc",
      //   width: 200,
      // },
    ],
    rows: selectedenrolled,
  }
  return (
    <Row>
      <Col className="col-12">
        <MDBDataTable
          id="EnrolledDataTableId"
          bordered
          responsive
          sortable={false}
          searching={true}
          entries={20}
          disableRetreatAfterSorting={true}
          info={false}
          data={jobPosts}
        ></MDBDataTable>
      </Col>
    </Row>
  )
}

export default EnrolledCourse
