import React, { useEffect, useRef, useState } from "react"
import "./styles.scss"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import useBranch from "../../../hooks/useBranch"
import { useDispatch, useSelector } from "react-redux"
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap"
import Tooltip from "@mui/material/Tooltip"

import { MDBDataTable } from "mdbreact"
import { getBranches } from "../../../store/options/actions"
import { get, post } from "../../../helpers/api_helper"
import toastr from "toastr"
import { API_URL } from "../../../config"
import { dateConverter, setTitle } from "../../../helpers/functions"
import moment from "moment"
import BulkFeeDetails from "./details"
import TablePagination from "../../../components/Common/Pagination"

const BulkFee = ({ options }) => {
  setTitle("Bulk Fees")
  const initialState = {
    branch: "",
    paymentFor: "",
    type: "",
    course: "",
    event: "",
    uploadedFile: "",
  }

  const formRef = useRef()
  const dispatch = useDispatch()
  const { branches } = useSelector(state => state.Options)

  const toggle = (start = true) => {
    var body = document.body

    if (start) {
      body.classList.remove("vertical-collpsed")
      body.classList.remove("sidebar-enable")
      body.setAttribute("data-sidebar-size", "sm")
    } else {
      body.classList.add("vertical-collpsed")
      body.classList.add("sidebar-enable")
      body.setAttribute("data-sidebar-size", "lg")
    }
  }

  useEffect(() => {
    toggle()

    return () => {
      toggle(false)
    }
    // eslint-disable-next-line
  }, [])

  const [masterObject, setMasterObject] = useState(initialState)
  const [selectedFields, setSelectedFields] = useState(initialState)
  const [branch, setBranch] = useState(...useBranch())
  const [alreadyBranch] = useBranch()

  const [tableData, setTableData] = useState([])
  const [courseOptions, setCourseOptions] = useState([])
  const [eventOptions, setEventOptions] = useState([])
  const [fundSourceOptions, setFundSourceOptions] = useState([])
  const [loading, setLoading] = useState(false)

  const [selectedId, setSelectedId] = useState(null)
  const [modal, setModal] = useState(false)

  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 20

  const handleModalToggle = (value = false, id = null) => {
    setModal(value)
    setSelectedId(id)
  }

  useEffect(() => {
    if (!branch) {
      dispatch(getBranches())
    } else {
      setSelectedFields(prev => ({ ...prev, branch: branch }))
      setMasterObject(prev => ({ ...prev, branch: branch?.value }))
      handleFundSourceOptions(branch.value)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [page])

  const handleSelectValueChange = (selected, name) => {
    const obj = { ...selectedFields }
    if (name === "paymentFor") {
      obj.course = ""
      obj.event = ""
    }
    if (name === "branch") handleFundSourceOptions(selected.value)

    obj[name] = selected
    handleValueChange(selected?.value, name)
    setSelectedFields(obj)
  }
  const handleValueChange = (value, name) => {
    const obj = { ...masterObject }
    obj[name] = value

    if (name === "paymentFor") {
      obj.course = ""
      obj.event = ""

      if (value === 0) handleCourseOptions(obj.branch)
      else handleEventOptions(obj.branch)
    }
    setMasterObject(obj)
  }

  const handleChange = (event, value) => {
    setPage(value)
  }

  const handleCourseOptions = async branch => {
    try {
      const response = await get(`fee/options/bulk/course?branch=${branch}`)
      setCourseOptions(response.data)
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }
  const handleEventOptions = async branch => {
    try {
      const response = await get(`fee/options/bulk/event?branch=${branch}`)
      setEventOptions(response.data)
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }
  const handleFundSourceOptions = async (branch = masterObject.branch) => {
    get(`accounts/chart-account/options?branch=${branch}`).then(res =>
      setFundSourceOptions(res.data)
    )
  }

  const handleTableData = async () => {
    try {
      const response = await get(`fee/bulk/list?page=${page}&limit=${limit}`)
      if (response.data && Array.isArray(response.data)) {
        response.data?.map((item, index) => {
          // item.id = index + 1
          item.id = index + 1 + (page - 1) * limit
          item.date = dateConverter(item.date)
          item.time = moment(item.time, "HH:mm:ss").format("hh:mm a")

          item.branch = item.branch?.name
          item.staff = `${item.updatedBy?.firstName} ${item.updatedBy?.lastName}`

          if (item.paymentFor === 0) {
            item.name = item.course?.name
            item.for = "Course"
          } else if (item.paymentFor === 1) {
            item.name = item.event?.name
            item.for = "Event"
          }

          item.type = item.type === 1 ? "Cash" : item.type === 0 ? "Online" : ""

          const isPending = item.status === 2
          if (isPending) {
            item.action = (
              <Button color="warning" size="sm" type="button">
                Pending
              </Button>
            )
          } else
            item.action = (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <i
                  className="fas fa-eye"
                  onClick={() => handleModalToggle(true, item._id)}
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginRight: "0.6em",
                  }}
                ></i>
                <a
                  href={`${API_URL}` + item?.uploadedFile}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    className="fa fa-file-excel"
                    style={{
                      fontSize: "1em",
                      cursor: "pointer",
                      color: "green",
                      marginRight: "0.6em",
                    }}
                  ></i>
                </a>
              </div>
            )

          return item
        })
        const count = response.count
        const totalPage = Math.ceil(Number(count) / limit)
        setTotalPage(totalPage)
        setTableData(response.data)
      }
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }
  const handleSubmit = async () => {
    const formData = new FormData()
    for (const key in masterObject) {
      formData.append(key, masterObject[key])
    }

    if (!loading) {
      setLoading(true)
      try {
        const response = await post(`fee/bulk/add`, formData)
        reset()
        setTimeout(handleTableData, 2000)
        toastr.success(response.message)
      } catch (error) {
        toastr.error(error.response.data.message)
      } finally {
        setLoading(false)
      }
    } else {
      toastr.warning("Your request is in progress")
    }
  }
  const forOptions = [
    { label: "Course", value: 0 },
    { label: "Event", value: 1 },
  ]
  const typeOptions = [
    { label: "Online", value: 0 },
    { label: "Cash", value: 1 },
  ]
  const reset = () => {
    formRef.current.reset()
    setSelectedFields(initialState)
    setMasterObject(initialState)
    handleTableData()
    if (!alreadyBranch) {
      setFundSourceOptions([])
      setBranch("")
    }
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        width: 150,
      },
      {
        label: "Branch",
        field: "branch",
        width: 150,
      },
      {
        label: "Payment Type",
        field: "type",
        width: 150,
      },
      {
        label: "Payment For",
        field: "for",
        width: 150,
      },
      {
        label: "Course/Event",
        field: "name",
        width: 150,
      },
      {
        label: "Added By",
        field: "staff",
        width: 150,
      },
      {
        label: "Actions",
        field: "action",
        sort: "desc",
        width: 400,
      },
    ],
    rows: tableData,
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Bulk Fee" />
          {options.edit ? (
            <Card>
              <CardBody>
                <AvForm ref={formRef} onValidSubmit={handleSubmit}>
                  <Row>
                    {!alreadyBranch && (
                      <Col md={2}>
                        <Label>Branch</Label>
                        <Select
                          name="branch"
                          value={branch || ""}
                          options={branches}
                          onChange={selected => {
                            setBranch(selected)
                            handleSelectValueChange(selected, "branch")
                          }}
                        />
                      </Col>
                    )}
                    <Col md={2} className="mb-3">
                      <Label>Payment Type</Label>
                      <Select
                        name="type"
                        options={typeOptions}
                        isDisabled={!branch}
                        value={selectedFields.type || ""}
                        onChange={selected => {
                          handleSelectValueChange(selected, "type")
                        }}
                      />
                    </Col>
                    <Col md={2} className="mb-3">
                      <Label>Payment For</Label>
                      <Select
                        name="for"
                        options={forOptions}
                        isDisabled={!branch}
                        value={selectedFields.paymentFor || ""}
                        onChange={selected => {
                          handleSelectValueChange(selected, "paymentFor")
                        }}
                      />
                    </Col>
                    {masterObject.paymentFor === 0 ? (
                      <Col md={3}>
                        <Label>Course</Label>
                        <Select
                          name="course"
                          options={courseOptions}
                          value={selectedFields.course}
                          onChange={selected => {
                            handleSelectValueChange(selected, "course")
                          }}
                        />
                      </Col>
                    ) : masterObject.paymentFor === 1 ? (
                      <Col md={3}>
                        <Label>Event</Label>
                        <Select
                          name="event"
                          options={eventOptions}
                          value={selectedFields.event}
                          onChange={selected => {
                            handleSelectValueChange(selected, "event")
                          }}
                        />
                      </Col>
                    ) : (
                      ""
                    )}
                    {masterObject.type === 1 ? (
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Fund Source</Label>
                          <Select
                            name="fundSource"
                            value={selectedFields.fundSource || ""}
                            onChange={selected => {
                              handleSelectValueChange(selected, "fundSource")
                            }}
                            options={fundSourceOptions}
                            errorMessage="Enter Fund Source"
                          />
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                    <Col md={3}>
                      <Label>Upload File</Label>
                      <AvField
                        name="uploadedFile"
                        type="file"
                        onChange={e => {
                          handleValueChange(e.target.files[0], "uploadedFile")
                        }}
                      />
                    </Col>

                    <Col md={2}>
                      <div className="d-flex" style={{ paddingTop: "26px" }}>
                        <Button
                          color="primary"
                          className="me-2"
                          disabled={loading}
                        >
                          Submit
                        </Button>
                        <Button color="danger" type="button" onClick={reset}>
                          Reset
                        </Button>
                      </div>
                    </Col>
                    <Col md={3} style={{ marginTop: "26px" }}>
                      <Tooltip title="Download sample file">
                        <a
                          href={"/samples/bulk_fee_sample_sheet.xlsx"}
                          download
                          style={{ fontSize: "1rem", width: "fit-content" }}
                          className="d-flex align-items-center btn btn-success "
                        >
                          <i
                            style={{ fontSize: "13px" }}
                            className="fa fa-solid fa-download "
                          />
                          <span
                            style={{
                              marginLeft: "5px",
                              fontSize: "13px",
                            }}
                          >
                            Download Sample Sheet
                          </span>
                        </a>
                      </Tooltip>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          ) : (
            ""
          )}
          <Card>
            <CardBody>
              <MDBDataTable
                id="bulkFeeTable"
                className="mt-3"
                bordered
                responsive
                data={data}
                disableRetreatAfterSorting={true}
                searching={false}
                paging={false}
                info={false}
                sortable={false}
              ></MDBDataTable>
              <TablePagination
                page={page}
                onChange={handleChange}
                count={totalPage}
              />
            </CardBody>
          </Card>
        </div>
      </div>
      <BulkFeeDetails
        id={selectedId}
        onClick={handleModalToggle}
        isOpen={modal}
      />
    </>
  )
}

export default BulkFee
