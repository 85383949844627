import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { AvForm } from "availity-reactstrap-validation"

import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { getUsersOptions } from "../../../store/options/actions"
import { MDBDataTable } from "mdbreact"
import { get } from "../../../helpers/api_helper"
import moment from "moment"
import { getDate, getFirstday } from "../../../helpers/functions"
import { APP_NAME } from "../../../config"
import TablePagination from "../../../components/Common/Pagination"
import "./index.scss"

const PagesStarter = () => {
  document.title = `User Activity Log | ${APP_NAME}`

  const dispatch = useDispatch()
  const [master, setMaster] = useState({
    from_date: getFirstday(),
    to_date: getDate(),
  })
  const [selectedUser, setSelectedUser] = useState(null)
  const [activity, setActivity] = useState([])
  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 20
  useEffect(() => {
    dispatch(getUsersOptions({}))
  }, [dispatch])

  const userOptions = useSelector(state => state.Options.users)

  function handleChange(e) {
    const { name, value } = e.target
    setMaster(pre => ({ ...pre, [name]: value }))
  }

  useEffect(() => {
    const { from_date, to_date, user_id } = master

    const queryParams = [
      from_date && `from_date=${from_date}`,
      to_date && `to_date=${to_date}`,
      user_id && `user_id=${user_id}`,
      `page=${page}`,
      `limit=${limit}`,
    ]
      .filter(Boolean)
      .join("&")

    get(`security/activity?${queryParams}`)
      .then(res => {
        const data = res.user
        data.map((item, index) => {
          item.id = index + 1 + (page - 1) * limit
          item.time = moment(item.time, "HHmmss").format("hh:mm a")
          return item
        })
        setActivity(data)
        const count = res.count
        const totalPage = Math.ceil(Number(count) / limit)
        setTotalPage(totalPage)
      })
      .catch(err => {
        console.log(err.response.data.message)
      })
  }, [master, page])

  function reset() {
    setMaster({
      from_date: getFirstday(),
      to_date: getDate(),
    })
    setSelectedUser(null)
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 270,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 270,
      },

      {
        label: "Created IP",
        field: "activity_ip",
        sort: "asc",
        width: 100,
      },
      {
        label: "Staff",
        field: "activity_user",
        sort: "asc",
        width: 100,
      },
      {
        label: "Activity",
        field: "activity_desc",
        sort: "asc",
        width: 100,
      },
    ],
    rows: activity,
  }
  const handlePageChange = (event, value) => {
    setPage(value)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Home" breadcrumbItem="User Activity Log" />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">From</Label>
                          <input
                            className="form-control"
                            type="date"
                            id="from_date"
                            name="from_date"
                            value={master.from_date}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">To</Label>

                          <input
                            className="form-control"
                            type="date"
                            id="to_date"
                            name="to_date"
                            value={master.to_date}
                            min={master.from_date}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>User</Label>
                          <Select
                            value={selectedUser}
                            onChange={selected => {
                              setSelectedUser(selected)
                              setMaster(pre => ({
                                ...pre,
                                user_id: selected.value,
                              }))
                            }}
                            options={userOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="1" style={{ paddingTop: "4px" }}>
                        <div className="mt-4 mb-3">
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="useractivitylogId"
                    responsive
                    bordered
                    data={data}
                    disableRetreatAfterSorting={true}
                    searching={false}
                    paging={false}
                    info={false}
                    sortable={false}
                  ></MDBDataTable>
                  <TablePagination
                    page={page}
                    onChange={handlePageChange}
                    count={totalPage}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PagesStarter
