import React from "react"
import PropTypes from "prop-types"
import Pagination from "@mui/material/Pagination"

const TablePagination = props => {
  return (
    <Pagination
      shape="rounded"
      key={props.page}
      id="paginationComponent"
      page={props.page}
      onChange={props.onChange}
      count={props.count}
    />
  )
}

TablePagination.propTypes = {
  page: PropTypes.number,
  onChange: PropTypes.func,
  count: PropTypes.number,
}

export default TablePagination
