import React, { useEffect, useState } from "react"
import { Row, Col } from "reactstrap"
import { Modal } from "react-bootstrap"
import MiniWidget from "../MiniWidget/mini-widget"
import Breadcrumb from "../../../../components/Common/Breadcrumb"
import { get } from "../../../../helpers/api_helper"
import { useSelector } from "react-redux"
import AdvancedDateFilter from "../../../../components/Common/AdvancedDateFilter"



const Skilling = () => {
  
  const [tab3, setTab3] = useState("2")

  const handleChangeTab = (event, newValue) => {
    setTab3(newValue)
  }
  const [popupView, setPopupView] = useState(false)

  const [countData,setCountData] = useState([])
  const getDateFilter = useSelector(state => state.Dashboard.data)

  useEffect(()=>{
    const fetch = async()=>{
      try {
        const res = await get(`/events/dashboard/count?startDate=${getDateFilter?.startDate}&endDate=${getDateFilter?.endDate}`)
        setCountData(res.data)
      } catch (error) {
        
      }
    }
    fetch()
  },[getDateFilter])



  const series3 = [55]

  const options3 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }



  const reports = [
    
    {
      id: '1',
      icon: "mdi mdi-arrow-up-bold",
      title: "Programmes(completed) ",
      icon2: "fas fa-flag",
      value: 10,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue:900,
      color: "success",
      desc: "Candidate Attended",
      series: series3,
      options: options3,
      value:countData?.completed?.length >= 1 ?countData?.completed[0].count :0,
      badgeValue:countData?.completed?.length >= 1 ?countData?.completed[0].attendedCount:0,
    },
    {
      id: '0',
      icon: "mdi mdi-arrow-up-bold",
      title: "Programmes(mobilisation) ",
      icon2: "fas fa-luggage-cart",
      value: 10,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue:900,
      color: "success",
      desc: "Candidate Registered",
      series: series3,
      options: options3,
      value:countData?.upcoming?.length >= 1 ?countData?.upcoming[0].count :0,
      badgeValue:countData?.upcoming?.length >= 1 ?countData?.upcoming[0].totalRegistration:0,
    },
    {
      id: '2',
      icon: "mdi mdi-arrow-up-bold",
      title: "Programmes(progression) ",
      icon2: "fas fa-chart-bar",
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      color: "success",
      desc: "Candidate Registered",
      series: series3,
      options: options3,
      value:countData?.ongoing?.length >= 1 ? countData?.ongoing[0].count :0,
      badgeValue:countData?.ongoing?.length >= 1  ? countData?.ongoing[0].totalRegistration:0,
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
       
        <Breadcrumb title="Home" breadcrumbItem="Event Dashboard" />

     <AdvancedDateFilter />

<Row>
  <MiniWidget reports={reports} />
</Row>

          <Row >
            <Col xl="12">
              <div>
                <div className="row">
                  

                  <div className="col-xl-12" >
                    <div
                      className="mb-0 card"
                      style={{
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                        width: "100%",
                      }}
                    >
                     
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h5>Event Details</h5>
          </div>

          <button
            type="button"
            onClick={() => setPopupView(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

       
      </Modal>
    </React.Fragment>
  )
}

export default Skilling
