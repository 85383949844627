import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"

import { connect, useDispatch, useSelector } from "react-redux"
import { Form, Input, Button, Row, Col, UncontrolledAlert } from "reactstrap"
import Select from "react-select"
import toastr from "toastr"
import SearchBar from "./SearchBar"
import { Link, useLocation, useNavigate } from "react-router-dom"

// Reactstrap
import { Dropdown, DropdownToggle, FormGroup, DropdownMenu } from "reactstrap"

// Import menuDropdown
// import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

import logoSm from "../../assets/images/asapsm.png"
import logoDark from "../../assets/images/asapsm.png"

import { Modal } from "react-bootstrap"
// import logoLight from "../../assets/images/logo-light.png"

// import images
// import github from "../../assets/images/brands/github.png"
// import bitbucket from "../../assets/images/brands/bitbucket.png"
// import dribbble from "../../assets/images/brands/dribbble.png"
// import dropbox from "../../assets/images/brands/dropbox.png"
// import mail_chimp from "../../assets/images/brands/mail_chimp.png"
// import slack from "../../assets/images/brands/slack.png"

//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  userAllotted,
  // changeSidebarType,
} from "../../store/actions"
import { getModule } from "../../store/module/actions"
import Cookies from "js-cookie"
import { getMenu } from "../../store/menu/actions"
import { post, get, put } from "../../helpers/api_helper"
import { API_URL, PRIVILEGES } from "../../config"
import "./Header.scss"
import {
  getDegree,
  getMinorityStatus,
  getQualification,
  getStatesOptions,
  getStream,
  getStudentCategory,
} from "../../store/options/actions"
import { encrypt } from "../../helpers/functions"
import $ from "jquery"

const Header = props => {
  const user = JSON.parse(localStorage.getItem("authUser")) || null
  const branch =
    sessionStorage.getItem("branch") === "undefined"
      ? {}
      : JSON.parse(sessionStorage.getItem("branch"))
  const [master, setMaster] = useState({
    // show: !user.passwordChanged,
    id: user._id,
  })
  const [search, setsearch] = useState(false)
  const [socialDrp, setsocialDrp] = useState(false)
  const [askForToken, setAskForToken] = useState(false)
  const [passShow, setpassShow] = useState(false)

  const [keyWord, setKeyWord] = useState(null)
  const [filteredData, setFilteredData] = useState([])

  const formRef = useRef()

  const [selectedBranch, setSelectedBranch] = useState(null)

  const { allotted } = useSelector(state => state.Profile)

  const dispatch = useDispatch()

  const module = useSelector(state => state.Module.data)

  const navigate = useNavigate()

  // useEffect(() => {
  //   sessionStorage.setItem("path", encrypt(props.path))
  // }, [props])

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body
    var windowSize = document.documentElement.clientWidth

    body.classList.toggle("vertical-collpsed")
    body.classList.toggle("sidebar-enable")
    if (windowSize > 991) {
      body.getAttribute("data-sidebar-size") === "sm" && windowSize > 991
        ? body.setAttribute("data-sidebar-size", "lg")
        : body.setAttribute("data-sidebar-size", "sm")
    }
  }

  useEffect(() => {
    dispatch(getModule(JSON.parse(localStorage.getItem("authUser")).privilage))
    dispatch(userAllotted({}))
  }, [dispatch])

  //TODO : Common options must call header
  useEffect(() => {
    dispatch(getStatesOptions())
    dispatch(getMinorityStatus())
    dispatch(getQualification())
    dispatch(getStream())
    dispatch(getDegree())
    dispatch(getStudentCategory())
  }, [dispatch])

  useEffect(() => {
    getCurrentToken()
  }, [])

  useEffect(() => {
    setSelectedBranch(branch)
    // eslint-disable-next-line
  }, [allotted])

  function handleModule(data) {
    sessionStorage.setItem("module", data.moduleName)
    sessionStorage.setItem("module_code", data.shortCode)
    sessionStorage.setItem("module_id", data._id)
    sessionStorage.setItem("module_url", data.redirect_url)
    if (Cookies.get("_token") && localStorage.getItem("authUser")) {
      const user = JSON.parse(localStorage.getItem("authUser"))._id
      dispatch(getMenu({ user, module: data._id }))
      navigate(data.redirect_url)
    }
  }

  const enableNotify = () => {
    var data = localStorage.getItem("authUser")
    var user_obj = JSON.parse(data)
    post(`user/browser/token`, {
      browser_token: localStorage.getItem("browserToken"),
    }).then(res => {
      user_obj.browser_token = localStorage.getItem("browserToken")
      localStorage.setItem("authUser", JSON.stringify(user_obj))
      setAskForToken(false)
    })
  }

  const getCurrentToken = () => {
    get(`user/browser/token`)
      .then(res => {
        var tken = res.user.browser_token
        if (tken !== localStorage.getItem("browserToken")) {
          // localStorage.setItem("mismatchBrowserToken", true)
          setAskForToken(true)
        } else {
          // localStorage.setItem("mismatchBrowserToken", false)
          setAskForToken(false)
        }
      })
      .catch(err => err)
  }

  function handlePasswordReset() {
    put("user/password-reset", master)
      .then(res => {
        // setMaster(pre => ({ ...pre, show: false }))
        toastr.success(res.message)
        navigate("/logout")
      })
      .catch(err => {
        console.log(err.response.data)
      })
  }

  // Search

  const handleSearch = () => {
    const value = keyWord
    setKeyWord(null)
    navigate("/student-details", {
      state: { key: value },
    })
  }
  const handleSelectSearch = e => {
    setFilteredData([])

    // Navigate
    // if (details)
    //   navigate("/student-details", {
    //     state: { key: e.target.value },
    //   })
    setKeyWord(null)

    // else
    navigate("/student-details/" + e.unique_id, {
      state: { id: e._id },
    })
  }

  const handleSearchChange = e => {
    setFilteredData([])
    if (e.length >= 2) {
      get("/student/search", { keyword: e }).then(res => {
        const data = res.data
        data.map(item => {
          item.value = item._id
          item.label =
            (item.firstname ? `${item.firstname} ` : "") +
            (item.lastname ? `${item.lastname} ` : "") +
            (item.mobile ? `${item.mobile} ` : "")
          return item
        })
        setFilteredData(data)
      })
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSm} alt="" height="50" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="50" />
                  {/* <img src={logoDark} alt="" height="20" /> */}
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSm} alt="" height="50" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="50" />
                  {/* <img src={logoLight} alt="" height="20" /> */}
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

            <div
              style={{ display: "flex" }}
              className={"header_responsive_dropdown "}
            >
              {/* <Form className="app-search  d-lg-block">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={props.t("Search") + "..."}
                  />
                  <span className="uil-search"></span>
                </div>
              </Form> */}
              {/* <AvForm
                className="app-search d-lg-block"
                onValidSubmit={handleSearch}
              >
        

                <div className="position-relative">
                  <Select
                    menuPosition="fixed"
                    placeholder="Search students"
                    id="main-search"
                    name="search"
                    value={keyWord}
                    onInputChange={selected => {
                      handleSearchChange(selected)
                    }}
                    onChange={(e) => {
                      handleSelectSearch(e)
                    }}
                    options={filteredData}
                    classNamePrefix="select2-selection"
                    defaultValue={() => null}
                    noOptionsMessage={() => null}
                  />
                  <span className="uil-search"></span>
                </div>
          

              </AvForm> */}
              <SearchBar />

              {/* {allotted.branch && ( */}
              <Form className="app-search  d-lg-block selectbranch">
                <div
                  className="position-relative selectbranch"
                  style={{ width: "200px" }}
                >
                  <Select
                    value={selectedBranch}
                    className="topbar_select selectbranch"
                    isClearable={
                      user.privilage === PRIVILEGES.ADMIN
                      // || user.privilage === PRIVILEGES.PROGRAM_MANAGER
                    }
                    onChange={e => {
                      sessionStorage.setItem("branch", JSON.stringify(e))
                      setSelectedBranch(e)
                      window.location.reload()
                    }}
                    options={allotted.branches}
                  />
                </div>
              </Form>

              {/* )} */}
            </div>

            <UncontrolledAlert
              color="success"
              className={`alert-dismissible fade show d-none ${
                askForToken ? "d-xl-block" : "d-none"
              }`}
              role="alert"
              style={{
                width: "100%",
                margin: "auto",
                marginLeft: "12px",
              }}
            >
              <i className="uil uil-exclamation-octagon me-2"></i>{" "}
              <strong
                style={{ cursor: "pointer" }}
                onClick={() => enableNotify()}
              >
                Click here{" "}
              </strong>
              to enable desktop notifications
            </UncontrolledAlert>
          </div>

          <div className="d-flex">
            <Dropdown
              className="d-none d-inline-block d-lg-none ms-2"
              onClick={() => {
                setsearch(!search)
              }}
              type="button"
            >
              <DropdownToggle
                className="btn header-item noti-icon waves-effect"
                id="page-header-search-dropdown"
                tag="button"
              >
                {" "}
                <i className="uil-search" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                <Form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <Button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify"></i>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </DropdownMenu>
            </Dropdown>

            {/* <LanguageDropdown /> */}

            <Dropdown
              className="d-inline-block ms-1"
              isOpen={socialDrp}
              toggle={() => {
                setsocialDrp(!socialDrp)
              }}
            >
              <DropdownToggle
                className="btn header-item noti-icon waves-effect"
                tag="button"
              >
                <i className="uil-apps"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-lg dropdown-menu-end" end>
                <div className="px-lg-2">
                  <Row className="g-0">
                    {module &&
                      module.map((el, index) => {
                        let color = ""
                        if (sessionStorage.getItem("module_id") === el._id) {
                          color = "#dddced"
                        }
                        return (
                          <Col
                            key={el._id}
                            md="4"
                            id={"module" + el?.moduleName}
                            className="module_box"
                            style={{ backgroundColor: color }}
                          >
                            <span
                              style={{ cursor: "pointer" }}
                              className="dropdown-icon-item"
                              // to="#"
                              onClick={() => {
                                if (
                                  sessionStorage.getItem("module_id") !== el._id
                                ) {
                                  handleModule(el)
                                }

                                setsocialDrp(!socialDrp)
                              }}
                            >
                              <img
                                src={`${API_URL}uploads/module_images/${el.icon}`}
                                alt=""
                              />
                              <span>{el?.moduleName}</span>
                            </span>
                          </Col>
                        )
                      })}
                  </Row>
                </div>
              </DropdownMenu>
            </Dropdown>

            <Dropdown className="d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen()
                }}
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen"
              >
                <i className="uil-minus-path"></i>
              </button>
            </Dropdown>

            <NotificationDropdown />

            <Dropdown className="d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  navigate("/planner")
                }}
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen"
              >
                <i className="uil-calendar-alt"></i>
              </button>
            </Dropdown>

            <ProfileMenu
              showRightSidebarAction={() =>
                props.showRightSidebarAction(!props.showRightSidebar)
              }
            />

            <button
              type="button"
              className="btn header-item noti-icon right-bar-toggle waves-effect"
            >
              <Link to={"/logout"}>
                <i className="uil uil-power"></i>
              </Link>
            </button>
          </div>
        </div>
      </header>
      <Modal show={master.show} centered={true} size="md">
        <div className="modal-header pb-0">
          <h5 className="modal-title mt-0">Change password</h5>
        </div>
        <AvForm
          className="needs-validation"
          ref={formRef}
          onValidSubmit={(e, v) => {
            handlePasswordReset()
          }}
        >
          <div className="modal-body pt-1">
            <Row>
              <Col md="12">
                <FormGroup className="my-3 position-relative" id="confirm-pass">
                  <AvField
                    name="password"
                    placeholder="Password"
                    type={`${passShow ? "text" : "password"}`}
                    errorMessage=" Please provide a valid password"
                    validate={{ required: { value: true } }}
                    id="validationCustom05"
                    value={master.password || ""}
                    onChange={e =>
                      setMaster(pre => ({ ...pre, password: e.target.value }))
                    }
                  />
                  {passShow ? (
                    <i
                      className="fas fa-eye-slash position-absolute"
                      onClick={() => setpassShow(pre => !pre)}
                      style={{
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                    ></i>
                  ) : (
                    <i
                      className="fas fa-eye position-absolute"
                      onClick={() => setpassShow(pre => !pre)}
                      style={{
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                    ></i>
                  )}
                </FormGroup>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <AvField
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    type={`${passShow ? "text" : "password"}`}
                    errorMessage="Please confirm the password"
                    className="form-control"
                    validate={{
                      required: { value: true },
                      match: {
                        value: "password",
                        errorMessage: "Password not match",
                      },
                    }}
                    id="validationCustom05"
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button className="btn btn-primary" type="submit">
              Submit
            </button>
          </div>
        </AvForm>
      </Modal>
    </React.Fragment>
  )
}

Header.propTypes = {
  // changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  // changeSidebarType,
})(withTranslation()(Header))
