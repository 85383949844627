import React, { useEffect, useState } from "react"
import { Row, Col, Card, Collapse } from "reactstrap"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import { Box, CardContent } from "@mui/material"
import classnames from "classnames"
import { Modal } from "react-bootstrap"

// import Rejected from "./Tabs/Rejected"
import BatchesUnderProgression from "./BatchesUnderProgression"
import { Assessment, DeliveryDining, EventNote } from "@mui/icons-material"
import CompletedBatchStatus from "./CompletedBatchStatus"
import CoursesUnderMobilisation from "./CoursesUnderMobilisation"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import MiniWidget from "../MiniWidget/mini-widget"
import { get } from "../../../helpers/api_helper"
import Occupancy from "./Occupancy"
import AdvancedDateFilter from "../../../components/Common/AdvancedDateFilter"
import { useSelector } from "react-redux"
import Footfall from "./Footfall"
const Skilling = () => {
  const [tab3, setTab3] = useState("1")

  const handleChangeTab = (event, newValue) => {
    setTab3(newValue)
  }
  const [popupView, setPopupView] = useState(false)
  const [countData, setCountData] = useState([])
  const [collapse, setCollapse] = useState(1)

  const getDateFilter = useSelector(state => state.Dashboard.data)

  const { allotted } = useSelector(state => state.Profile)

  useEffect(() => {
    const abortController = new AbortController()
    const signal = abortController.signal

    const fetch = async () => {
      try {
        const res = await get(
          `/batch/dashboard/count?&startDate=${getDateFilter?.startDate}&endDate=${getDateFilter?.endDate}`,
          { signal }
        )
        setCountData(res.data)
      } catch (error) {}
    }
    fetch()

    return () => {
      abortController.abort()
    }
  }, [getDateFilter, allotted.branches])

  const handleCollapse = index => {
    if (collapse === index) {
      setCollapse(null)
    } else {
      setCollapse(index)
    }
  }

  const series2 = [70]

  const options2 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series3 = [55]

  const options3 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const reports = [
    {
      id: 1,
      icon: "mdi mdi-arrow-up-bold",
      icon2: "fa fa-graduation-cap",
      title: "Batches completed",
      value:
        countData?.completed?.length >= 1 ? countData?.completed[0].count : 0,
      badgeValue:
        countData?.completed?.length >= 1
          ? countData?.completed[0].attendedCount
          : 0,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      color: "success",
      desc: (
        <>
          Students{" "}
          <span className="label-image-size">(Certificate generated)</span>
        </>
      ),
      series: series2,
      options: options2,
      for: "completed",
      navigateTo: "/batch-view",
    },
    {
      id: 2,
      icon: "mdi mdi-arrow-up-bold",
      icon2: "fas fa-book-open",
      title: "Batches(Mobilisation)",
      value: countData?.tempBatch?.registration || 0,
      badgeValue: countData?.tempBatch?.total || 0,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      color: "success",
      desc: "Total Temp Batch",
      series: series2,
      options: options2,
      // for: "upcoming",
      // navigateTo: "/batch-view",
    },

    {
      id: 3,
      icon: "mdi mdi-arrow-up-bold",
      title: "Batch(Progression)",
      icon2: "fas fa-book-reader",
      value: countData?.ongoing?.length >= 1 ? countData?.ongoing[0].count : 0,
      badgeValue:
        countData?.ongoing?.length >= 1
          ? countData?.ongoing[0].registeredCount
          : 0,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      color: "success",
      desc: "Students",
      series: series3,
      options: options3,
      for: "ongoing",
      navigateTo: "/batch-view",
    },
    {
      id: 4,
      icon: "mdi mdi-arrow-up-bold",
      icon2: "fa fa-graduation-cap",
      title: "Class/Lab Occupancy",
      value:
        countData?.occupancy?.length >= 1
          ? (countData?.occupancy[0]?.occupied /
              countData?.occupancy[0]?.total) *
            100
          : 0,
      badgeValue:
        countData?.occupancy?.length >= 1
          ? countData?.occupancy[0].total - countData?.occupancy[0]?.occupied
          : 0,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "%",
      color: "success",
      desc: "Available Classrooms",
      series: series2,
      options: options2,
      for: "completed",
      navigateTo: "/class-creation",
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem="Dashboard" />

          <AdvancedDateFilter />

          <Row>
            <MiniWidget reports={reports} />
          </Row>

          <Row>
            <Col xl="12">
              <Card>
                <CardContent>
                  <Box sx={{ width: "100%", typography: "body1" }}>
                    <div className="accordion mb-2" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              {
                                collapsed: collapse !== 1,
                              }
                            )}
                            type="button"
                            onClick={() => handleCollapse(1)}
                            style={{ cursor: "pointer" }}
                          >
                            Batches
                          </button>
                        </h2>
                        <Collapse
                          id="collapseOne"
                          className="accordion-collapse"
                          isOpen={collapse === 1}
                        >
                          <div className="accordion-body">
                            <TabContext value={tab3}>
                              <Box
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                              >
                                <TabList
                                  indicatorColor="primary"
                                  textColor="primary"
                                  variant="fullWidth"
                                  onChange={handleChangeTab}
                                  aria-label="lab API tabs example"
                                >
                                  <Tab
                                    icon={
                                      <Assessment
                                        style={{ fontSize: "20px" }}
                                      />
                                    }
                                    label="	Completed Batch Status"
                                    value="1"
                                    style={{
                                      textTransform: "capitalize",
                                      fontFamily: "IBM Plex Sans,sans-serif",
                                    }}
                                  />
                                  <Tab
                                    icon={
                                      <DeliveryDining
                                        style={{ fontSize: "20px" }}
                                      />
                                    }
                                    label="Batches under mobilisation"
                                    value="2"
                                    size="small"
                                    style={{
                                      textTransform: "capitalize",
                                      fontFamily: "IBM Plex Sans,sans-serif",
                                    }}
                                  />
                                  <Tab
                                    icon={
                                      <EventNote style={{ fontSize: "20px" }} />
                                    }
                                    label="Batches under progression"
                                    value="3"
                                    size="small"
                                    style={{
                                      textTransform: "capitalize",
                                      fontFamily: "IBM Plex Sans,sans-serif",
                                    }}
                                  />
                                </TabList>
                              </Box>

                              <TabPanel
                                value="1"
                                style={{
                                  fontSize: "13px",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              >
                                <CompletedBatchStatus />
                              </TabPanel>
                              <TabPanel
                                value="2"
                                style={{
                                  fontSize: "13px",
                                  fontFamily: "IBM Plex Sans, sans-serif",
                                }}
                              >
                                <CoursesUnderMobilisation />
                              </TabPanel>
                              <TabPanel
                                value="3"
                                style={{
                                  fontSize: "13px",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              >
                                <BatchesUnderProgression />
                              </TabPanel>
                            </TabContext>
                          </div>
                        </Collapse>
                      </div>
                    </div>

                    <div className="accordion mb-2" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              {
                                collapsed: collapse !== 2,
                              }
                            )}
                            type="button"
                            onClick={() => handleCollapse(2)}
                            style={{ cursor: "pointer" }}
                          >
                            Class/Lab Occupancy
                          </button>
                        </h2>
                        <Collapse
                          id="collapseOne"
                          className="accordion-collapse"
                          isOpen={collapse === 2}
                        >
                          <div className="accordion-body">
                            <Occupancy />
                          </div>
                        </Collapse>
                      </div>
                    </div>
                    <div className="accordion mb-2" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              {
                                collapsed: collapse !== 3,
                              }
                            )}
                            type="button"
                            onClick={() => handleCollapse(3)}
                            style={{ cursor: "pointer" }}
                          >
                            CSP Wise Footfall
                          </button>
                        </h2>
                        <Collapse
                          id="collapseOne"
                          className="accordion-collapse"
                          isOpen={collapse === 3}
                        >
                          <div className="accordion-body">
                            <Footfall />
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  </Box>
                </CardContent>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h5>Event Details</h5>
          </div>

          <button
            type="button"
            onClick={() => setPopupView(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default Skilling
