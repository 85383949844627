import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Label,
  Button,
} from "reactstrap"

import Slider from "react-rangeslider"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { APP_NAME } from "../../config"

import BootstrapTheme from "@fullcalendar/bootstrap"

import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin, { Draggable } from "@fullcalendar/interaction"

import FullCalendar from "@fullcalendar/react"
import { Tooltip } from "@mui/material"
import moment from "moment"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { useSelector } from "react-redux"

import Select from "react-select"
import { useDispatch } from "react-redux"
import { getUsersOptions } from "../../store/options/actions"
import { isArray } from "lodash"

import "react-rangeslider/lib/index.css"
import { get, post, put } from "../../helpers/api_helper"

import toastr from "toastr"
import "./Details.scss"

import queryString from "query-string"

const types = [
  { value: "bg-danger", label: "High" },
  { value: "bg-success", label: "Low" },
  { value: "bg-primary", label: "Medium" },
  { value: "bg-info", label: "Information" },
  { value: "bg-dark", label: "Critical" },
  { value: "bg-warning", label: "Warning" },
]

const duration = [
  { label: "Day", value: 1 },
  { label: "Week", value: 2 },
  { label: "Month", value: 3 },
]

const Planner = () => {
  document.title = `Planner | ${APP_NAME}`

  const [category, setCategory] = useState([])

  const [isUpdate, setIsUpdate] = useState(false)

  const [events, setEvents] = useState([])

  const [master, setMaster] = useState({ show: false })

  const [selected, setSelected] = useState({})

  const [fromDate, setFromDate] = useState(null)

  const [toDate, setToDate] = useState(null)

  const dispatch = useDispatch()

  // const OPTIONS = useSelector(state => state.Options)

  useEffect(() => {
    handleData({ fromDate, toDate })
  }, [fromDate, toDate])

  useEffect(() => {
    get(`options/planner-category`).then(res => {
      setCategory(res.data)
    })
    // dispatch(getUsersOptions({}))
  }, [dispatch])

  const handleData = query => {
    get(`planner?${queryString.stringify(query)}`).then(res => {
      setEvents(res.data)
    })
  }

  const handleDateClick = arg => {
    let date = arg["dateStr"]

    const today = moment().format("YYYY-MM-DD")

    if (date < today) {
      date = today
    }

    setMaster({
      show: true,
      start: date,
      // end: moment(endDate).subtract(1, "day").format("YYYY-MM-DDTHH:mm"),
    })
  }

  const addNewEvent = async () => {
    const newEvent = { ...master }
    const data = [...events]

    newEvent.className = `${
      selected?.type ? selected.type.value : "bg-success"
    } text-white`

    // newEvent.extendedProps = {}
    newEvent.category = selected?.category?.value
    newEvent.durationType = selected?.durationType?.value
    newEvent.type = selected?.type?.value

    // data.push(newEvent)

    await post("planner", newEvent)
      .then(res => {
        toastr.success(res.message)
        handleData()
        setEvents(data)
        setMaster({ show: false })

        setSelected({})
      })
      .catch(err => {
        toastr.error(err?.response?.data?.message)
        console.log(err)
      })
  }

  const handleEventClick = arg => {
    const event = arg.event

    setIsUpdate(true)

    const data = event.extendedProps

    setSelected({
      category: { label: data?.category?.name, value: data?.category?._id },
    })

    setMaster({
      ...data,
      prevProgression: data.progression,
      id: data._id,
      start: event.startStr,
      to: data.endDate,
      title: event.title,
      show: true,
      category: data?.category?._id,
    })
  }

  const handleSelect = (e, name) => {
    const value = e ? (isArray(e) ? e.map(item => item.value) : e.value) : null
    setSelected(pre => ({ ...pre, [name]: e }))
    setMaster(pre => ({ ...pre, [name]: value }))
  }

  const handleChange = e => {
    let { name, value } = e.target
    setMaster(pre => ({ ...pre, [name]: value }))
  }

  const updateEvent = () => {
    put(`planner/${master.id}`, {
      ...master,
      className: master.type,
    })
      .then(res => {
        handleData()
        toastr.success(res.message)
        setMaster({ show: false })
        setSelected({})
      })
      .catch(err => {
        toastr.error(err?.response?.data?.message)
      })
  }

  const handleDatesSet = arg => {
    setFromDate(arg.startStr.split("T")[0])
    setToDate(arg.endStr.split("T")[0])
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Home" breadcrumbItem="Planner" />

          <Row>
            <Col md={12}>
              <Card>
                <CardBody className="planner">
                  <FullCalendar
                    plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
                    slotDuration={"00:15:00"}
                    handleWindowResize={true}
                    themeSystem="bootstrap"
                    timeZone="Asia/Kolkata"
                    headerToolbar={{
                      left: "prev,next today",
                      center: "title",
                      right: "dayGridMonth,dayGridWeek,dayGridDay",
                    }}
                    events={events}
                    selectable={true}
                    eventClick={handleEventClick}
                    dateClick={handleDateClick}
                    eventContent={renderEventContent}
                    datesSet={handleDatesSet}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal show={master.show} isOpen={master.show} centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add new plan</h5>
          <button
            type="button"
            onClick={() => {
              setSelected({})
              setIsUpdate(false)
              setMaster({ show: false })
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <Row>
            <Col md={123}>
              <AvForm
                onValidSubmit={() =>
                  master.id ? updateEvent() : addNewEvent()
                }
              >
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label>From Date</Label>
                      <AvField
                        name="start"
                        type="date"
                        min={master.id ? null : moment().format("YYYY-MM-DD")}
                        placeholder="Choose date"
                        errorMessage="Choose valid date"
                        onChange={handleChange}
                        value={master.start}
                        disabled={isUpdate}
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label>To Date</Label>
                      <AvField
                        name="to"
                        type="date"
                        placeholder="Choose date"
                        errorMessage="Choose valid date"
                        onChange={handleChange}
                        value={master.to}
                        disabled={isUpdate}
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label>From</Label>
                      <AvField
                        name="startingTime"
                        type="time"
                        placeholder="Choose date"
                        errorMessage="Choose valid date"
                        onChange={handleChange}
                        value={master.startingTime}
                        disabled={isUpdate}
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label>To </Label>
                      <AvField
                        name="endingTime"
                        type="time"
                        placeholder="Choose time"
                        errorMessage="Choose valid time"
                        onChange={handleChange}
                        value={master.endingTime}
                        disabled={isUpdate}
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label>Plan</Label>
                      <AvField
                        name="title"
                        type="textarea"
                        rows={1}
                        value={master.title || ""}
                        // onChange={handleChange}
                        placeholder="Enter plan"
                        errorMessage="Enter valid plan"
                        onChange={handleChange}
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label>Duration</Label>
                      <Select
                        onChange={e => {
                          handleSelect(e, "durationType")
                        }}
                        isClearable
                        value={
                          selected.durationType ||
                          duration.filter(
                            item => item.value == master?.durationType
                          )
                        }
                        options={duration}
                      />
                      {console.log(master)}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label>Category</Label>
                      <Select
                        onChange={e => {
                          handleSelect(e, "category")
                        }}
                        isClearable
                        value={selected.category || null}
                        options={category}
                      />
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <Label>Priority</Label>
                      <Select
                        onChange={e => {
                          handleSelect(e, "type")
                        }}
                        isClearable
                        value={
                          selected.type ||
                          types.filter(item => item.value == master?.type)
                        }
                        options={types}
                      />
                    </div>
                  </Col>

                  {isUpdate && (
                    <>
                      <Row className="d-flex align-items-center">
                        <Col md={8}>
                          <Label>Progression</Label>
                          <Slider
                            value={master?.progression}
                            orientation="horizontal"
                            // min={master?.prevProgression}
                            onChange={value => {
                              if (value >= master?.prevProgression) {
                                handleChange({
                                  target: { name: "progression", value },
                                })
                              }
                            }}
                          />
                        </Col>

                        <Col md={4} className="mt-4 pt-3">
                          <div className="mb-3">
                            <AvField
                              name="progression"
                              type="number"
                              value={master.progression || ""}
                              // onChange={handleChange}
                              placeholder="Progression"
                              min={master?.prevProgression}
                              max={100}
                              errorMessage="Enter progression"
                              onChange={handleChange}
                              validate={{
                                required: { value: true },
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label>Remarks</Label>
                          <AvField
                            name="remarks"
                            type="texarea"
                            value={master.remarks || ""}
                            placeholder="Remarks"
                            errorMessage="Enter remarks"
                            onChange={handleChange}
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </div>
                      </Col>
                    </>
                  )}

                  <Col md={12}>
                    <div className="d-flex justify-content-end">
                      <Button
                        className="bt-primary"
                        color="primary"
                        type="submit"
                      >
                        {master?.id ? "Update" : "Submit"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </Col>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  )
}

function renderEventContent(eventInfo) {
  return (
    <Tooltip
      key={eventInfo.event.title}
      title={`Progression : ${eventInfo?.event?.extendedProps?.progression}`}
      placement="top"
      arrow
    >
      <span>{eventInfo.event.title}</span>
    </Tooltip>
  )
}

export default Planner

// {
//   id: 4,
//   title: "Repeating Event",
//   startRecur: "2024-06-15T10:30:00.000Z",
//   daysOfWeek: [1],
//   endRecur: "2024-06-25T10:30:00.000Z",
//   // endRecur: "2024-06-20T10:30:00.000Z",
//   // allDay: false,
//   className: "bg-primary text-white",
// },
