import React, { useEffect, useState } from "react"
import { Row, Col, Button, Label } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import { Box, Divider } from "@mui/material"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { AvForm } from "availity-reactstrap-validation"
import { Modal } from "react-bootstrap"
import Select from "react-select"
import StoreIcon from "@mui/icons-material/Store"
import WorkHistoryIcon from "@mui/icons-material/WorkHistory"
import { useLocation, useParams } from "react-router-dom"
import { APP_NAME } from "../../../config"
import { get, post } from "../../../helpers/api_helper"
const ViewClass = props => {
  // const { jobId } = props.match.params;
  const { state } = useLocation()
  const test = useParams()
  document.title = `${test._id} | ${APP_NAME}`
  const [popupView, setPopupView] = useState(false)
  const [popupView2, setPopupView2] = useState(false)
  const [popupView3, setPopupView3] = useState(false)
  const [popupView4, setPopupView4] = useState(false)

  const [tab3, setTab3] = React.useState("1")
  const [result, setResult] = useState([])
  const [popupSavedView, setPopupSavedView] = useState(false)
  const [popupAppliedView, setPopupAppliedView] = useState(false)
  const [popupShortlistedView, setPopupShortlistedView] = useState(false)
  const [popupPlacedView, setPopupPlacedView] = useState(false)
  const [showDeclineAppliedForm, setShowDeclineAppliedForm] = useState(false)
  const [showDeclinePlacedForm, setShowDeclinePlacedForm] = useState(false)
  const [showDeclineShortlistedForm, setShowDeclineShortlistedForm] =
    useState(false)

  const handleChangeTab = (event, newValue) => {
    setTab3(newValue)
    // resetPaginateJob();
    // setSearchValueJob("");

    // resetPaginateApp();
    // setSearchValueApp("");

    // resetPaginateShort();
    // setSearchValueShort("");

    // resetPaginatePlaced();
    // setSearchValuePlaced("");

    // resetPaginateRemarks();
    // setSearchValueActivity("");
  }

  useEffect(() => {
    get(`ClassroomsOrLabs/list/${state?.id || test._id}`).then(res => {
      const result = res.data
      console.log(result)
      setResult(result)
    })
  }, [])

  const assetdata = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Issued date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Sl No",
        field: "placed_reg_no",
        sort: "asc",
        width: 400,
      },
      {
        label: "Asset",
        field: "placed_cand_name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Owner",
        field: "placed_cand_mob",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 200,
      },
    ],
    rows: [
      {
        id: "1",
        date: "23-01-2024",
        placed_reg_no: "3D32YS5",
        placed_cand_name: "Desktop ",
        company: "Ernst & Young",
        applicant: "AL Amin Ali",
        placed_cand_mob: "KANNUR CSP",
        status: (
          <div>
            <span style={{ color: "green", fontWeight: "500" }}>Issued</span>
          </div>
        ),
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                setPopupView(true)
              }}
            ></i>
          </div>
        ),
      },
      {
        id: "2",
        date: "02-02-2024",
        placed_reg_no: "LP123S5",
        placed_cand_name: "Land phone ",
        company: "Ernst & Young",
        applicant: "AL Amin Ali",
        placed_cand_mob: "KOZHIKOD CSP",
        status: (
          <div>
            <span style={{ color: "red", fontWeight: "500" }}>Damaged</span>
          </div>
        ),
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                setPopupView(true)
              }}
            ></i>
          </div>
        ),
      },
      {
        id: "3",
        date: "03-02-2024",
        placed_reg_no: "DS123S5",
        placed_cand_name: "3D Printer ",
        company: "Ernst & Young",
        applicant: "AL Amin Ali",
        placed_cand_mob: "KANNUR CSP",
        status: (
          <div>
            <span style={{ color: "darkgray", fontWeight: "500" }}>
              In Service
            </span>
          </div>
        ),
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                setPopupView(true)
              }}
            ></i>
          </div>
        ),
      },
      {
        id: "4",
        date: "05-02-2024",
        placed_reg_no: "PJ12PP1",
        placed_cand_name: "Projector",
        company: "Ernst & Young",
        applicant: "AL Amin Ali",
        placed_cand_mob: "ERNAKULAM CSP",
        status: (
          <div>
            <span style={{ color: "darkgray", fontWeight: "500" }}>
              In Service
            </span>
          </div>
        ),
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                setPopupView(true)
              }}
            ></i>
          </div>
        ),
      },
    ],
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Start Date",
        field: "date",
        sort: "asc",
        width: 200,
      },
      {
        label: "Course",
        field: "application_cand_reg_no",
        sort: "asc",
        width: 200,
      },
      {
        label: "Trainer",
        field: "name",
        sort: "asc",
        width: 200,
      },

      {
        label: "Duration",
        field: "application_cand_phone",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 200,
      },
    ],
    rows: [
      {
        id: "1",
        date: "01-01-2024",
        application_cand_reg_no: "Advanced driver-assistance system",
        company: "Ernst & Young",
        name: "AL Amin Ali",
        application_cand_phone: "30 Days",
        status: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={{ fontWeight: "500", color: "green" }}>
              {" "}
              Completed
            </span>
          </div>
        ),
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                setPopupView4(true)
              }}
            ></i>
          </div>
        ),
      },

      {
        id: "2",
        date: "02-01-2024",
        application_cand_reg_no: "Automotive Electrician",
        company: "1 Year",
        name: "Alan Job",
        application_cand_phone: "1 Year",
        status: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={{ fontWeight: "500", color: "deepskyblue" }}>
              {" "}
              Ongoing
            </span>
          </div>
        ),
        staff: "Admin",
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                setPopupView4(true)
              }}
            ></i>
          </div>
        ),
      },
      {
        id: "3",
        date: "02-03-2024",
        application_cand_reg_no: "Diesel Engine Fundamentals ",
        company: "Tata Consultancy Service",
        name: "Suja joseph",
        application_cand_phone: "6 Months",
        status: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={{ fontWeight: "500", color: "red" }}> Upcomming</span>
          </div>
        ),
        staff: "Admin",
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                setPopupView4(true)
              }}
            ></i>
          </div>
        ),
      },
    ],
  }

  console.log("result", result)

  return (
    <React.Fragment>
      <Modal
        show={popupView4}
        isOpen={popupView4}
        toggle={() => setPopupView4(!popupView4)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h5>Applied Details</h5>
          </div>

          <button
            type="button"
            onClick={() => setPopupView4(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body" style={{ paddingTop: "0px" }}>
          <Row>
            <Col md="12">
              <table
                id="product_available_day"
                className="table table-bordered dataTable"
              >
                <tr>
                  <th style={{ padding: "10px 11px", textAlign: "left" }}>
                    Job
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>Project Engineer</td>
                  <th style={{ textAlign: "left" }}>Company</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Ernst & Young
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>
                    Application ID
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>I2401JP02513</td>
                  <th style={{ textAlign: "left" }}>Candidate</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Alan Job
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>Mobile</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    9365524183
                  </td>
                  <th style={{ textAlign: "left" }}>Email</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    alan@gmail.com
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>
                    Experiance
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Fresher
                  </td>
                  <th style={{ textAlign: "left" }}>Qualificaion</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Graduation
                  </td>
                </tr>
              </table>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h5>Placed Details</h5>
          </div>

          <button
            type="button"
            onClick={() => setPopupView(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body" style={{ paddingTop: "0px" }}>
          <Row>
            <Col md="12">
              <table
                id="product_available_day"
                className="table table-bordered dataTable"
              >
                <tr>
                  <th style={{ padding: "10px 11px", textAlign: "left" }}>
                    Job
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>Project Engineer</td>
                  <th style={{ textAlign: "left" }}>Company</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Ernst & Young
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>
                    Application ID
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>I2401JP02513</td>
                  <th style={{ textAlign: "left" }}>Candidate</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Alan Job
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>
                    Placed Date
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>24-01-2024</td>
                  <th style={{ textAlign: "left" }}>Placed Time</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    02:30 pm
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>Mobile</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    9365524183
                  </td>
                  <th style={{ textAlign: "left" }}>Email</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    alan@gmail.com
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>
                    Experiance
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Fresher
                  </td>
                  <th style={{ textAlign: "left" }}>Qualificaion</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Graduation
                  </td>
                </tr>
              </table>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal
        show={popupView2}
        isOpen={popupView2}
        toggle={() => setPopupView(!popupView2)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h5>Shortlisted Details</h5>
          </div>

          <button
            type="button"
            onClick={() => setPopupView2(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body" style={{ paddingTop: "0px" }}>
          <Row>
            <Col md="12">
              <table
                id="product_available_day"
                className="table table-bordered dataTable"
              >
                <tr>
                  <th style={{ padding: "10px 11px", textAlign: "left" }}>
                    Job
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>Project Engineer</td>
                  <th style={{ textAlign: "left" }}>Company</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Ernst & Young
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>
                    Application ID
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>I2401JP02513</td>
                  <th style={{ textAlign: "left" }}>Candidate</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Alan Job
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>
                    Placed Date
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>24-01-2024</td>
                  <th style={{ textAlign: "left" }}>Placed Time</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    02:30 pm
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>Mobile</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    9365524183
                  </td>
                  <th style={{ textAlign: "left" }}>Email</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    alan@gmail.com
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>
                    Experiance
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Fresher
                  </td>
                  <th style={{ textAlign: "left" }}>Qualificaion</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Graduation
                  </td>
                </tr>
              </table>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal
        show={popupView3}
        isOpen={popupView3}
        toggle={() => setPopupView3(!popupView3)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h5>Saved Details</h5>
          </div>

          <button
            type="button"
            onClick={() => setPopupView3(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body" style={{ paddingTop: "0px" }}>
          <Row>
            <Col md="12">
              <table
                id="product_available_day"
                className="table table-bordered dataTable"
              >
                <tr>
                  <th style={{ padding: "10px 11px", textAlign: "left" }}>
                    Job
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>Project Engineer</td>
                  <th style={{ textAlign: "left" }}>Company</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Ernst & Young
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>
                    Application ID
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>I2401JP02513</td>
                  <th style={{ textAlign: "left" }}>Candidate</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Alan Job
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>
                    Placed Date
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>24-01-2024</td>
                  <th style={{ textAlign: "left" }}>Placed Time</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    02:30 pm
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>Mobile</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    9365524183
                  </td>
                  <th style={{ textAlign: "left" }}>Email</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    alan@gmail.com
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>
                    Experiance
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Fresher
                  </td>
                  <th style={{ textAlign: "left" }}>Qualificaion</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Graduation
                  </td>
                </tr>
              </table>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal show={showDeclineAppliedForm} size="md" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Decline Candidate</h5>
          <button
            type="button"
            onClick={() => {
              setShowDeclineAppliedForm(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation">
          <div className="modal-body">
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label>Reason</Label>
                  <Select
                    name="decline_remark"
                    classNamePrefix="select2-selection"
                  />
                </div>
              </Col>
            </Row>
            <Row className="row mb-0">
              <Col className="col-12 text-end">
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </AvForm>
      </Modal>

      <Modal show={showDeclineShortlistedForm} size="md" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Decline Candidate</h5>
          <button
            type="button"
            onClick={() => {
              setShowDeclineShortlistedForm(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation">
          <div className="modal-body">
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label>Reason</Label>
                  <Select
                    name="decline_remark"
                    classNamePrefix="select2-selection"
                  />
                </div>
              </Col>
            </Row>
            <Row className="row mb-0">
              <Col className="col-12 text-end">
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </AvForm>
      </Modal>

      <Modal show={showDeclinePlacedForm} size="md" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Decline Candidate</h5>
          <button
            type="button"
            onClick={() => {
              setShowDeclinePlacedForm(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation">
          <div className="modal-body">
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label>Reason</Label>
                  <Select
                    name="decline_remark"
                    classNamePrefix="select2-selection"
                  />
                </div>
              </Col>
            </Row>

            <Row className="row mb-0">
              <Col className="col-12 text-end">
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </AvForm>
      </Modal>

      <Modal show={popupShortlistedView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Shortlisted Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupShortlistedView(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation">
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ width: "184px", padding: "10px" }}>
                  Application Name :
                </td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>Application ID :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Shortlisted Date :</td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>Shortlisted Time :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>State :</td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>District :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Institute :</td>
                <td style={{ width: "200px", wordBreak: "break-all" }}></td>
                <td style={{ paddingLeft: "25px" }}>Candidate :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Experience :</td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>qualification :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                ></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Status :</td>
                <td>{"Declined"}</td>
                <td style={{ paddingLeft: "25px" }}>Remarks :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                ></td>
              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>

      <Modal show={popupPlacedView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Placed Application Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupPlacedView(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation">
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ width: "184px", padding: "10px" }}>
                  Application Name :
                </td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>Reg No :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Placed Date :</td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>Placed Time :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>State :</td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>District :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Institute :</td>
                <td style={{ width: "200px", wordBreak: "break-all" }}></td>
                <td style={{ paddingLeft: "25px" }}>Candidate :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Experience :</td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>qualification :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                ></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Status :</td>
                <td>{"Declined"}</td>
                <td style={{ paddingLeft: "25px" }}>Remarks :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                ></td>
              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>

      <Modal show={popupSavedView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Saved Application Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupSavedView(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation">
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ width: "184px", padding: "10px" }}>
                  Application Name :
                </td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>Reg No :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Saved Date :</td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>Saved Time :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>State :</td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>District :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Subject :</td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>Experience :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Applicant :</td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>No of openings :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Class :</td>
                <td style={{ width: "191.67px", wordBreak: "break-all" }}></td>
                <td style={{ paddingLeft: "25px" }}>qualification :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                ></td>
              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>

      <Modal show={popupAppliedView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Application Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupAppliedView(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation">
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ width: "184px", padding: "10px" }}>
                  Application Name :
                </td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>Application ID :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>State :</td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>District :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Subject :</td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>Experience :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Applicant :</td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>Mobile :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Class :</td>
                <td style={{ width: "191.67px", wordBreak: "break-all" }}></td>
                <td style={{ paddingLeft: "25px" }}>qualification :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                ></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Status :</td>
                <td>{"Declined"}</td>
                <td style={{ paddingLeft: "25px" }}>Remarks :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                ></td>
              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem={result.name} />
          <Row>
            <Col xl="12">
              {/* First Dialogue */}

              <div>
                <div className="row">
                  <div className="col-xl-3">
                    <div
                      className="card"
                      style={{
                        // width: "fit-content",
                        // width: "351px",
                        height: "max-content",
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                      }}
                    >
                      <div className="card-body">
                        <div className="float-end dropdown"></div>
                        <h5
                          className="mt-2 mb-2"
                          style={{
                            textAlign: "center",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}
                        >
                          {result.name}
                        </h5>

                        <p
                          style={{
                            textAlign: "center",
                            marginBottom: "3px",
                          }}
                        ></p>

                        <Divider />

                        <div className="mt-2">
                          <p className="mb-1">Created Date:</p>
                          <h5 className="font-size-14">{result.date}</h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">CSP:</p>
                          <h5 className="font-size-14">{result?.csp?.name}</h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">Type:</p>
                          <h5 className="font-size-14"> {result.type}</h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">{result?.type} Name:</p>
                          <h5 className="font-size-14">{result.name}</h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">{result?.type} Code:</p>
                          <h5 className="font-size-14">{result.code}</h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">Max Strength:</p>
                          <h5 className="font-size-14">
                            {" "}
                            {result.maxStrength}
                          </h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">Status:</p>
                          <h5
                            className={`font-size-14 ${
                              result.status === 2
                                ? "text-danger"
                                : result.status === 0
                                ? "text-success"
                                : ""
                            }`}
                          >
                            {result.status === 2
                              ? "Inactive"
                              : result.status === 0
                              ? "Active"
                              : ""}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-9" style={{ marginBottom: "30px" }}>
                    <div
                      className="mb-0 card"
                      style={{
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={tab3}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              indicatorColor="primary"
                              textColor="primary"
                              variant="fullWidth"
                              onChange={handleChangeTab}
                              aria-label="lab API tabs example"
                            >
                              <Tab
                                icon={
                                  <StoreIcon style={{ fontSize: "20px" }} />
                                }
                                label="Assets"
                                value="1"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <WorkHistoryIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Course History"
                                value="2"
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                            </TabList>
                          </Box>
                          <TabPanel
                            value="1"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col className="col-12">
                                <MDBDataTable
                                  id="appliedDataId"
                                  responsive
                                  bordered
                                  data={assetdata}
                                  searching={true}
                                  info={false}
                                  disableRetreatAfterSorting={true}
                                />
                              </Col>
                            </Row>
                          </TabPanel>
                          <TabPanel
                            value="2"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col className="col-12">
                                <MDBDataTable
                                  id="appliedDataId"
                                  responsive
                                  bordered
                                  data={data}
                                  searching={true}
                                  info={false}
                                  disableRetreatAfterSorting={true}
                                />
                              </Col>
                            </Row>
                          </TabPanel>
                        </TabContext>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ViewClass
