import React, { useEffect, useState, useCallback } from "react"

import AsyncSelect from "react-select/async"

import queryString from "query-string"
import debounce from "lodash.debounce"

import { get } from "../../helpers/api_helper"

const SearchStudent = ({ api, onChange, value = "", master }) => {
  const [options, setOptions] = useState([])

  useEffect(() => {
    handleOptions({ master })
  }, [master]) // eslint-disable-line

  const handleOptions = async ({ master, search = "" }) => {
    try {
      const query = `search=${search}&${queryString.stringify(master)}`
      const response = await get(`${api}?${query}`)
      if (!search) setOptions(response.data)
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  const loadOptions = async (inputValue, callback) => {
    const data = await handleOptions({ master, search: inputValue })
    console.log(inputValue, data.length)
    callback(data)
  }
  
  //eslint-disable-next-line
  // const debouncedLoadOptions = useCallback(
  //   debounce(async (inputValue, callback) => {
  //     const data = await handleOptions({ master, search: inputValue })
  //     console.log(inputValue, data.length)
  //     callback(data)
  //   }, 500),
  //   [handleOptions]
  // )

  return (
    <AsyncSelect
      isMulti
      isClearable
      cacheOptions
      loadOptions={loadOptions}
      defaultOptions={options}
      onChange={onChange}
      value={value}
    />
  )
}

export default SearchStudent
