import React, { useEffect, useState } from "react"

import {
  AvForm,
  AvField,
} from "availity-reactstrap-validation"
import { Row, Col, Button, Label, Card, CardBody } from "reactstrap"
import { useBatch } from "../BatchContext"
import { get, post } from "../../../../../helpers/api_helper"
import toastr from "toastr"

const Assessment = props => {
  const [master, setMaster] = useState({ internalExam: [{examType:1}] })

  const context = useBatch()

  useEffect(() => {
    if (context?.master?.id) {
      get(`/batch/assessment/${context.master.id}`)
        .then(res => {
          setMaster(res.data)
        })
        .catch(err => [console.log(err)])
    }
  }, [context?.master?.id])

  const handleChange = (e, idx = false, fieldName) => {
    
    let { name, value } = e.target

    name = name.split("-")[0]

    if(fieldName)name = fieldName

    const data = { ...master }

    if(name === 'assessmentType'){
      if(value === '1'){
        data.certificationType = 1
      }else if(value === '2'){
        data.certificationType = 3
      }else if(value === '3'){
        data.certificationType = ''
      }
    }

    if (idx === false) {
      data[name] = value
    } else {
      //eslint-disable-next-line
      if (value == 2) {
        data.internalExam[idx][name] = value
        data.internalExam[idx].grade = ["A", "B", "C", "D", "E", "F"] 
      } else {
        data.internalExam[idx][name] = value
        data.internalExam[idx].grade = null
      }
    }

    setMaster(data)
  }

  const addAssessment = () => {
    let hasError = false
    master?.internalExam?.forEach(item => {
      if (!item.examType) {
        return hasError=true
      }
    })
    if(hasError)return toastr.error('Please select grade or marks for all exams.')
    post(`/batch/assessment`, { ...master, batch: context?.master?.id })
      .then(res => {
        if(res.success){
          toastr.success(res?.message)
        }
      })
      .catch(err => {
        console.log(err)
        toastr.error(err?.response?.data?.message)
      })
  }

  return (
    <AvForm onValidSubmit={addAssessment}>
      <Row>
        <Col md="12" className="d-flex mt-3 mr-2">
          <Label>Assessment Type : </Label>
          <div
            style={{
              marginLeft: "25px",
              marginBottom: "10px",
            }}
            className=" d-flex align-items-center gap-3"
          >
            <div>
              <input
                class="form-check-input"
                type="radio"
                name="assessmentType"
                id="assessmentType1"
                value={1}
                onClick={(e)=>{if(master?.assessmentStatus)return toastr.info('Assessment details cannot be changed after assessment request.');
                  handleChange(e)}}
                //eslint-disable-next-line
                checked={master?.assessmentType == 1}
                style={{
                  marginRight: "10px",
                  borderColor: "gray",
                }}
                validate={{ required: { value: true } }}
              />
              <label class="form-check-label" for="assessmentType1">
                ASAP
              </label>
            </div>
            <div>
              <input
                class="form-check-input"
                type="radio"
                name="assessmentType"
                id="assessmentType2"
                value={2}
                //eslint-disable-next-line
                checked={master?.assessmentType == 2}
                onClick={(e)=>{if(master?.assessmentStatus)return toastr.info('Assessment details cannot be changed after assessment request.');
                  handleChange(e)}}
                // checked={master?.assessmentType === 2 ? true : false}
                style={{
                  marginRight: "10px",
                  borderColor: "gray",
                }}
              />
              <label class="form-check-label" for="assessmentType2">
                Training Partner(TP)
              </label>
            </div>
            <div>
              <input
                class="form-check-input"
                type="radio"
                name="assessmentType"
                id="assessmentType3"
                value={3}
                //eslint-disable-next-line
                checked={master?.assessmentType == 3}
                onClick={(e)=>{if(master?.assessmentStatus)return toastr.info('Assessment details cannot be changed after assessment request.');
                  handleChange(e)}}
                // checked={master?.assessmentType === 2 ? true : false}
                style={{
                  marginRight: "10px",
                  borderColor: "gray",
                }}
              />
              <label class="form-check-label" for="assessmentType3">
                No Assessment
              </label>
            </div>
          </div>
        </Col>

        <Col md="12" className="d-flex mt-3 mr-2 mb-3">
          <Label>Certification Type : </Label>
          <div
            style={{
              marginLeft: "25px",
              marginBottom: "10px",
            }}
            className=" d-flex align-items-center gap-3"
          >
            <div>
              <input
                class="form-check-input"
                type="radio"
                name="certificationType"
                id="certificationType1"
                value={1}
                //eslint-disable-next-line
                disabled={master?.assessmentType != 1}
                onClick={(e)=>{if(master?.assessmentStatus)return toastr.info('Assessment details cannot be changed after assessment request.');
                  handleChange(e)}}
                //eslint-disable-next-line
                checked={master?.certificationType == 1}
                style={{
                  marginRight: "10px",
                  borderColor: "gray",
                }}
                validate={{ required: { value: true } }}
              />
              <label class="form-check-label" for="certificationType1">
                NCVT Short-term
              </label>
            </div>
            <div>
              <input
              //eslint-disable-next-line
              disabled={master?.assessmentType != 1}
                class="form-check-input"
                type="radio"
                name="certificationType"
                id="certificationType2"
                value={2}
                onClick={(e)=>{if(master?.assessmentStatus)return toastr.info('Assessment details cannot be changed after assessment request.');
                  handleChange(e)}}
                //eslint-disable-next-line
                checked={master?.certificationType == 2}
                style={{
                  marginRight: "10px",
                  borderColor: "gray",
                }}
                validate={{ required: { value: true } }}
              />
              <label class="form-check-label" for="certificationType2">
                NCVT Long-term
              </label>
            </div>
            <div>
              <input
              //eslint-disable-next-line
                disabled={master?.assessmentType != 2}
                class="form-check-input"
                type="radio"
                name="certificationType"
                id="certificationType3"
                value={3}
                //eslint-disable-next-line
                checked={master?.certificationType == 3}
                onClick={(e)=>{if(master?.assessmentStatus)return toastr.info('Assessment details cannot be changed after assessment request.');
                  handleChange(e)}}
                style={{
                  marginRight: "10px",
                  borderColor: "gray",
                }}
              />
              <label class="form-check-label" for="certificationType3">
                Training Partner(TP)
              </label>
            </div>
          </div>
        </Col>

        {master?.internalExam?.map((row, idx) => (
          <Card key={idx}>
            <CardBody>
              <Row>
                <Col md="4">
                  <div className="mb-3">
                    <Label htmlFor="validationCustom03">
                      Internal Assessment
                    </Label>
                    <AvField
                      name={`name${idx}`}
                      type="text"
                      className="form-control"
                      placeholder="Assessment Name"
                      disabled={master.internalsMarked}
                      onChange={e => {if(master?.internalsMarked)return toastr.info('Internals cannot be edited after publishing');
                        handleChange(e, idx, 'name')}}
                      value={row?.name}
                      validate={{required:{value:true, errorMessage: 'Name is required'}}}
                    />
                  </div>
                </Col>

                <Col md="3" lg={2} className="d-flex mt-3">
                  <div
                    style={{
                      marginLeft: "25px",
                      marginBottom: "10px",
                    }}
                    className=" d-flex align-items-center gap-3"
                  >
                    <div>
                      <input
                        class="form-check-input"
                        type="radio"
                        name={`examType-${idx}`}
                        id={`flexRadioDefault1-${idx}`}
                        onClick={e => {if(master?.internalsMarked)return toastr.info('Internals cannot be edited after publishing');
                          handleChange(e, idx)}}
                        //eslint-disable-next-line
                        checked={row.examType == 1}
                        value={1}
                        style={{
                          marginRight: "10px",
                          borderColor: "gray",
                        }}
                        required
                      />
                      <label
                        class="form-check-label"
                        for={`flexRadioDefault1-${idx}`}
                      >
                        Mark
                      </label>
                    </div>
                    <div>
                      <input
                        class="form-check-input"
                        type="radio"
                        name={`examType-${idx}`}
                        id={`flexRadioDefault2-${idx}`}
                        onClick={e => {if(master?.internalsMarked)return toastr.info('Internals cannot be edited after publishing');handleChange(e, idx)}}
                        //eslint-disable-next-line
                        checked={row.examType == 2}
                        value={2}
                        style={{
                          marginRight: "10px",
                          borderColor: "gray",
                        }}
                        required
                      />
                      <label
                        class="form-check-label"
                        for={`flexRadioDefault2-${idx}`}
                      >
                        Grade
                      </label>
                    </div>
                  </div>
                </Col>
                
                
                {
                  //eslint-disable-next-line
                row.examType == 1 && (
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom03">Maximum Mark</Label>
                      <AvField
                        name={`maxMark-${idx}`}
                        value={row?.maxMark}
                        type="number"
                        disabled={master.internalsMarked}
                        className="form-control"
                        placeholder="Maximum Mark"
                        onChange={e => handleChange(e, idx)}
                        validate={{ required: { value: true } }}
                        min={5}
                      />
                    </div>
                  </Col>
                )}

                {master?.internalExam.length > 1 && !master?.internalsMarked && (
                  <div className="d-flex justify-content-end">
                    <span
                      className="mr-1"
                      style={{
                        fontSize: "1.125rem",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const data = { ...master }
                        data.internalExam.splice(idx, 1)
                        setMaster(data)
                      }}
                    >
                      <i className="trash-btn mdi mdi-delete"></i>
                    </span>
                  </div>
                )}
              </Row>
            </CardBody>
          </Card>
        ))}

        <Col md={12}>
          <div className="d-flex justify-content-between align-items-center">
            <button
              type="button"
              disabled={master.internalsMarked}
              onClick={() => {
                const data = { ...master }
                data.internalExam.push({examType:1})
                setMaster(data)
              }}
              className="waves-effect btn btn-outline-light d-flex"
              style={{ gap: "5px" }}
            >
              Add more <i className="mdi mdi-plus-circle-outline"></i>
            </button>
          </div>
        </Col>

        <Col md={12}>
        
          <div className="d-flex justify-content-end align-items-center">
            <div style={{ marginRight: "1rem" }}>
              <Button
                onClick={() => {
                  context.back()
                }}
                type="button"
                className="me-2"
                color="primary"
              >
                Back
              </Button>
              <Button
                disabled={!context?.master?.id || (master.internalsMarked && (master.assessmentStatus?true:false))}
                type="submit"
                color="primary"
              >
                Save
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </AvForm>
  )
}

export default Assessment
