import React, { useEffect, useState } from "react"
import { Row, Col, Table } from "reactstrap"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import { Box} from "@mui/material"

import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap"
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

import SnowshoeingIcon from '@mui/icons-material/Snowshoeing';
import { useLocation } from "react-router-dom"

// import Rejected from "./Tabs/Rejected"
import ProgrammesUnderProgression from "./BatchesUnderProgression"
import CompletedProgrammes from "./CompletedProgrammes"
import ProgrammesUnderMobilisation from "./ProgrammesUnderMobilisation"

import {  Build, MinorCrash } from "@mui/icons-material"
const NonSkilling = () => {
  const [tab3, setTab3] = useState("1")
  const { state } = useLocation()

  const handleChangeTab = (event, newValue) => {
    setTab3(newValue)
  }
  const [popupView, setPopupView] = useState(false)


 
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xl="12">
              {/* First Dialogue */}
              <div>
                <div className="row">
                  <div className="col-xl-12" style={{ marginBottom: "30px" ,marginTop:'-125px'}}>
                    <div
                      className="mb-0 card"
                      style={{
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={tab3}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              indicatorColor="primary"
                              textColor="primary"
                              variant="fullWidth"
                              onChange={handleChangeTab}
                              aria-label="lab API tabs example"
                            >
                              
                              <Tab
                                icon={
                                  <EventAvailableIcon style={{ fontSize: "20px" }} />
                                }
                                label="Completed Programmes"
                                value="1"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={<MinorCrash style={{ fontSize: "20px" }} />}
                                label="Programmes Under Mobilisation"
                                value="2"
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                 icon={<Build style={{ fontSize: "20px" }} />}
                                label="Programmes Under Progression"
                                value="3"
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                             
                            </TabList>
                          </Box>

                          <TabPanel
                            value="1"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <CompletedProgrammes />
                          </TabPanel>
                          <TabPanel
                            value="2"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans, sans-serif",
                            }}
                          >
                            <ProgrammesUnderMobilisation/>
                          </TabPanel>
                          <TabPanel
                            value="3"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <ProgrammesUnderProgression/>
                            </TabPanel> 
                        </TabContext>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h5>Event Details</h5>
          </div>

          <button
            type="button"
            onClick={() => setPopupView(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

       
      </Modal>
    </React.Fragment>
  )
}

export default NonSkilling
