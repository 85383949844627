import React, { useEffect, useRef, useState } from "react"
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Swal from "sweetalert2"

import toastr from "toastr"
import moment from "moment"

import { dateConverter, setTitle } from "../../../helpers/functions"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { del, post, put, get } from "../../../helpers/api_helper"

import "./styles.scss"
const AgreementCategory = () => {
  setTitle("Document Type")
  const [masterObject, setMasterObject] = useState({})

  const [tableData, setTableData] = useState([])
  const formRef = useRef()

  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [])

  const handleChangeInput = e => {
    let { name, value } = e.target
    setMasterObject({ ...masterObject, [name]: value })
  }

  const handleUpdate = data => {
    toTop()
    setMasterObject({ id: data._id, name: data?.name })
  }

  const handleDelete = async id => {
    return await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) handleDeleteFunction(id)
    })
  }

  const handleDeleteFunction = async id => {
    try {
      const response = await del(`agreement/category/delete?id=${id}`)
      toastr.success(response.message)
      reset()
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }

  const handleSubmit = async () => {
    try {
      if (masterObject.id) {
        const response = await put(`agreement/category/update`, masterObject)
        toastr.success(response.message)
      } else {
        const response = await post(`agreement/category/add`, masterObject)
        toastr.success(response.message)
      }

      reset()
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }

  const handleTableData = async () => {
    try {
      const response = await get(`agreement/category/list`)
      if (response && Array.isArray(response?.data)) {
        const data = response.data

        data.map((item, index) => {
          item.id = index + 1
          item.date = dateConverter(item.date)
          item.time = moment(item.time, "HH:mm:ss").format("hh:mm a")

          item.staff = `${item.updatedBy?.firstName || ""} ${
            item.updatedBy.lastName || ""
          }`

          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => handleUpdate(item)}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => handleDelete(item?._id)}
              ></i>
            </div>
          )
          return item
        })

        setTableData(data)
      }
    } catch (error) {}
  }

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }
  const reset = () => {
    handleTableData()
    setMasterObject({})
    toTop()
    formRef.current.reset()
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: tableData,
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem="Document Type" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={handleSubmit}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Name</Label>

                          <AvField
                            name="name"
                            placeholder="Name"
                            type="text"
                            errorMessage="Enter Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={masterObject.name || ""}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="3" style={{ paddingTop: "4px" }}>
                        <div className="mt-4">
                          <Button
                            color={masterObject.id ? "warning" : "primary"}
                            className="me-2"
                            type="submit"
                          >
                            {masterObject.id ? "Update" : "Submit"}
                          </Button>

                          <Button
                            color="danger"
                            className="me-2"
                            type="reset"
                            onClick={reset}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="agreementCategoryTable"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default AgreementCategory
