import React, { useState, useEffect } from "react"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"

import moment from "moment"
import Select from "react-select"
import { MDBDataTable } from "mdbreact"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import { get, put } from "../../helpers/api_helper"
import "./style.scss"
import TablePagination from "../../components/Common/Pagination"
import { dateConverter } from "../../helpers/functions"
function Notification() {
  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 30

  const [list, setList] = useState([])
  const [filterObject, setFilterObject] = useState({
    fromDate: "",
    toDate: "",
    fromTime: "",
    toTime: "",
    status: "",
  })

  const readStatus = [
    { label: "Unread", value: 0 },
    { label: "Read", value: 1 },
  ]

  const categoryStatus = [
    { label: "Others", value: 0 },
    { label: "Jobs", value: 1 },
    { label: "Placements", value: 2 },
    { label: "Events", value: 3 },
    { label: "Course", value: 4 },
    { label: "Fee", value: 5 },
    { label: "Agreement", value: 6 },
    { label: "Lead", value: 7 },
  ]
  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [filterObject, page])

  const getData = async () => {
    try {
      const response = await get(
        `/notification/get_all?page=${page}&limit=${limit}&fromDate=${filterObject.fromDate}&toDate=${filterObject.toDate}&fromTime=${filterObject.fromTime}&toTime=${filterObject.toTime}&status=${filterObject.status}&category=${filterObject.category}`
      )
      const data = response.data
      const count = response.count
      if (!Array.isArray(data) || data.length < 1) return
      data.map((item, index) => {
        item.id = index + 1 + (page - 1) * limit
        item.time = moment(item?.date).format("hh:mm a")
        item.date = dateConverter(item.date)
        item.category = categoryStatus.find(
          category => category.value === item.category
        )?.label

        if (item?.read_status === 0)
          item.read_status = (
            <Button
              color={"danger"}
              onClick={() => readNotification(item._id)}
              size="sm"
            >
              UnRead
            </Button>
          )
        else
          item.read_status = (
            <Button color={"success"} size="sm" disabled>
              Read
            </Button>
          )
        return item
      })

      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setList(data)
    } catch (error) {}
  }
  // const getData = () => {
  //   get(
  //     `/notification/get_all?fromDate=${filterObject.fromDate}&toDate=${filterObject.toDate}&fromTime=${filterObject.fromTime}&toTime=${filterObject.toTime}&status=${filterObject.status}&category=${filterObject.category}`
  //   ).then(res => {
  //     const data = res.data
  //     data &&
  //       data.map((item, index) => {
  //         item.id = index + 1
  //         item.time = moment(item?.date).format("hh:mm a")
  //         item.date = moment(item?.date).format("DD-MM-YYYY")
  //         item.category = categoryStatus.find(
  //           category => category.value === item.category
  //         )?.label
  //         if (item?.read_status === 0)
  //           item.read_status = (
  //             <Button
  //               color={"danger"}
  //               onClick={() => readNotification(item._id)}
  //               size="sm"
  //             >
  //               UnRead
  //             </Button>
  //           )
  //         else
  //           item.read_status = (
  //             <Button color={"success"} size="sm" disabled>
  //               Read
  //             </Button>
  //           )
  //         return item
  //       })
  //     setList(data)
  //   })
  // }

  const readNotification = async id => {
    try {
      await put("/notification/read", { id })
      getData()
    } catch (err) {}
  }

  const handleChange = (value, name) => {
    setFilterObject(prev => ({ ...prev, [name]: value }))
  }
  const handlePageChange = (event, value) => {
    setPage(value)
  }
  const reset = () => {
    setFilterObject({})
    setPage(1)
    setTotalPage(0)
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 90,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 90,
      },
      {
        label: "Category",
        field: "category",
        sort: "asc",
        width: 90,
      },
      {
        label: "Title",
        field: "title",
        sort: "asc",
        width: 90,
      },
      {
        label: "Message",
        field: "body",
        sort: "asc",
        width: 90,
      },
      {
        label: "Status",
        field: "read_status",
        sort: "asc",
        width: 90,
      },
    ],
    rows: list,
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Notification" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <AvForm>
                      <Row>
                        <Col md="2">
                          <Label>From Date</Label>
                          <AvField
                            name="fromDate"
                            type="date"
                            value={filterObject.fromDate || ""}
                            onChange={e => {
                              handleChange(e.target.value, e.target.name)
                            }}
                          />
                        </Col>
                        <Col md="2">
                          <Label>To Date</Label>
                          <AvField
                            name="toDate"
                            type="date"
                            value={filterObject.toDate || ""}
                            onChange={e => {
                              handleChange(e.target.value, e.target.name)
                            }}
                          />
                        </Col>
                        <Col md="2">
                          <Label>From Time</Label>
                          <AvField
                            name="fromTime"
                            type="time"
                            value={filterObject.fromTime || ""}
                            onChange={e => {
                              handleChange(e.target.value, e.target.name)
                            }}
                          />
                        </Col>
                        <Col md="2">
                          <Label>To Time</Label>
                          <AvField
                            name="toTime"
                            type="time"
                            value={filterObject.toTime || ""}
                            onChange={e => {
                              handleChange(e.target.value, e.target.name)
                            }}
                          />
                        </Col>
                        <Col md="2">
                          <Label>Category</Label>
                          <Select
                            name="category"
                            options={categoryStatus}
                            value={categoryStatus.filter(
                              item => item.value === filterObject.category
                            )}
                            onChange={selected => {
                              handleChange(selected.value, "category")
                            }}
                          />
                        </Col>
                        <Col md="2">
                          <Label>Status</Label>
                          <Select
                            name="status"
                            options={readStatus}
                            value={readStatus.filter(
                              item => item.value === filterObject.status
                            )}
                            onChange={selected => {
                              handleChange(selected.value, "status")
                            }}
                          />
                        </Col>
                        <Col md="2" className="mb-3">
                          <div>
                            <Button color="danger" onClick={reset}>
                              Reset
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </Row>
                  <MDBDataTable
                    id="notificationTable"
                    bordered
                    responsive
                    data={data}
                    disableRetreatAfterSorting={true}
                    searching={false}
                    paging={false}
                    info={false}
                    sortable={false}
                  ></MDBDataTable>
                  <TablePagination
                    page={page}
                    onChange={handlePageChange}
                    count={totalPage}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default Notification
