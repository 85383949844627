export const GET_PRIVILEGES = "get_privileges"
export const GET_PRIVILEGES_SUCCESS = "get_privileges_success"

export const GET_MODULES = "get_modules"
export const GET_MODULES_SUCCESS = "get_modules_success"

export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"

export const GET_COUNTRIES = "GET_COUNTRIES"
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS"

export const GET_STATES = "GET_STATES"
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS"

export const GET_DISTRICTS = "GET_DISTRICTS"
export const GET_DISTRICTS_SUCCESS = "GET_DISTRICTS_SUCCESS"

export const GET_COMPANIES = "GET_COMPANIES"
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS"

export const GET_BRANCHES = "GET_BRANCHES"
export const GET_BRANCHES_SUCCESS = "GET_BRANCHES_SUCCESS"

export const GET_CATEGORY = "GET_CATEGORY"
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS"

export const GET_ACCOUNT_TYPE = "GET_ACCOUNT_TYPE"
export const GET_ACCOUNT_TYPE_SUCCESS = "GET_ACCOUNT_TYPE_SUCCESS"

export const GET_ACCOUNT_SUB_TYPE = "GET_ACCOUNT_SUB_TYPE"
export const GET_ACCOUNT_SUB_TYPE_SUCCESS = "GET_ACCOUNT_SUB_TYPE_SUCCESS"

export const GET_CHART_ACCOUNT = "GET_CHART_ACCOUNT"
export const GET_CHART_ACCOUNT_SUCCESS = "GET_CHART_ACCOUNT_SUCCESS"

export const GET_OPERATING_PARTNER = "GET_OPERATING_PARTNER"
export const GET_OPERATING_PARTNER_SUCCESS = "GET_OPERATING_PARTNER_SUCCESS"

export const GET_TRAINING_PARKING = "GET_TRAINING_PARKING"
export const GET_TRAINING_PARKING_SUCCESS = "GET_TRAINING_PARKING_SUCCESS"

export const GET_CERTIFICATION_PARTNER = "GET_CERTIFICATION_PARTNER"
export const GET_CERTIFICATION_PARTNER_SUCCESS =
  "GET_CERTIFICATION_PARTNER_SUCCESS"

export const GET_SECTOR = "GET_SECTOR"
export const GET_SECTOR_SUCCESS = "GET_SECTOR_SUCCESS"

export const GET_QUALIFICATION = "GET_QUALIFICATION"
export const GET_QUALIFICATION_SUCCESS = "GET_QUALIFICATION_SUCCESS"

export const GET_STREAM = "GET_STREAM"
export const GET_STREAM_SUCCESS = "GET_STREAM_SUCCESS"

export const GET_DEGREE = "GET_DEGREE"
export const GET_DEGREE_SUCCESS = "GET_DEGREE_SUCCESS"

export const GET_COURSES = "GET_COURSES"
export const GET_COURSES_SUCCESS = "GET_COURSES_SUCCESS"

export const GET_STUDENT = "GET_STUDENT"
export const GET_STUDENT_SUCCESS = "GET_STUDENT_SUCCESS"

export const GET_SKILL_LOANS = "GET_SKILL_LOANS"
export const GET_SKILL_LOANS_SUCCESS = "GET_SKILL_LOANS_SUCCESS"

export const GET_SKILL_LOAN_STATUS = "GET_SKILL_LOAN_STATUS"
export const GET_SKILL_LOAN_STATUS_SUCCESS = "GET_SKILL_LOAN_STATUS_SUCCESS"

export const GET_SCHOLARSHIPS = "GET_SCHOLARSHIPS"
export const GET_SCHOLARSHIPS_SUCCESS = "GET_SCHOLARSHIPS_SUCCESS"

export const GET_SCHOLARSHIP_STATUS = "GET_SCHOLARSHIP_STATUS"
export const GET_SCHOLARSHIP_STATUS_SUCCESS = "GET_SCHOLARSHIP_STATUS_SUCCESS"

export const GET_STUDENT_CATEGORY = "GET_STUDENT_CATEGORY"
export const GET_STUDENT_CATEGORY_SUCCESS = "GET_STUDENT_CATEGORY_SUCCESS"

export const GET_MINORITY_STATUS = "GET_MINORITY_STATUS"
export const GET_MINORITY_STATUS_SUCCESS = "GET_MINORITY_STATUS_SUCCESS"

export const GET_LEAD_SOURCE = "GET_LEAD_SOURCE"
export const GET_LEAD_SOURCE_SUCCESS = "GET_LEAD_SOURCE_SUCCESS"

export const GET_CAMPAIGN = "GET_CAMPAIGN"
export const GET_CAMPAIGN_SUCCESS = "GET_CAMPAIGN_SUCCESS"

export const GET_TEMP_BATCH = "GET_TEMP_BATCH"
export const GET_TEMP_BATCH_SUCCESS = "GET_TEMP_BATCH_SUCCESS"

export const GET_CLUSTER = "GET_CLUSTER"
export const GET_CLUSTER_SUCCESS = "GET_CLUSTER_SUCCESS"

export const GET_EXPENSE_CATEGORY = "GET_EXPENSE_CATEGORY"
export const GET_EXPENSE_CATEGORY_SUCCESS = "GET_EXPENSE_CATEGORY_SUCCESS"

export const GET_CLASSROOMS_LABS = "GET_CLASSROOMS_LABS"
export const GET_CLASSROOMS_LABS_SUCCESS = "GET_CLASSROOMS_LABS_SUCCESS"

export const GET_EVENTS = "GET_EVENTS"
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS"
