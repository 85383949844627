import moment from "moment"
import React, { useEffect, useState } from "react"
import Select from "react-select"
import PropTypes from "prop-types"
// import { Label } from "reactstrap"

function FinancialYear(props) {
  const [options, setOptions] = useState([])

  useEffect(() => {
    const data = []
    const currentYear = moment().year()

    for (let i = currentYear; i >= (props?.startYear || 2023); i--) {
      const nextYear = i + 1
      data.push({
        label: `${i}-${nextYear.toString().slice(-2)}`,
        from: moment(`${i}-04-01`).format("YYYY-MM-DD"),
        to: moment(`${nextYear}-03-31`).format("YYYY-MM-DD"),
        value: `${moment(`${i}-04-01`).format("YYYY-MM-DD")},${moment(
          `${nextYear}-03-31`
        ).format("YYYY-MM-DD")}`,
      })
    }

    setOptions(data)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {/* <Label>Financial Year</Label> */}
      <Select
        value={props.value}
        onChange={props.onChange}
        options={options}
        menuPosition="fixed"
        classNamePrefix="select2-selection"
        {...props}
      />
    </>
  )
}

FinancialYear.propTypes = {
  startYear: PropTypes.number,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default FinancialYear
