import React, { useEffect, useState } from "react"
import { Row, Col,Button } from "reactstrap"
import { OverlayTrigger, Tooltip,Modal } from "react-bootstrap"
import { MDBDataTable } from "mdbreact"
import moment from "moment"
import { get, post, put } from "../../../../helpers/api_helper" 
import Select from "react-select"
import toastr from "toastr"
import { API_URL } from "../../../../config"
import { Link, useLocation } from "react-router-dom"
import InternshipModal from "../../InternshipModal"
import Swal from "sweetalert2"

function Registrations(props) {
  const jobpost = props.data
  const [registration, setRegistration] = useState([])
  const [modal, setModal] = useState({})
  const [popupView, setPopupView] = useState(false)
  const { state } = useLocation()
  useEffect(() => {
    handleTableData()
  }, [])

  const statusOptions = [
    { label: "Place", value: 3 },
    { label: "Reject", value: 5 },
  ]
  const handleStatusChange = async (requestId, approvedStatus) => {
    if (approvedStatus == 5) {
      Swal.fire({
        title: "Reason For Rejection",
        html:
          '<textarea id="remark-text" class="swal2-input" placeholder="Enter your remark "></textarea>',
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonText: 'Submit',
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#3e9bee",
        preConfirm: () => {
          const remark = Swal.getPopup().querySelector('#remark-text').value;
          return { remark };
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const { remark } = result.value
          put("/internship/application-status", { _id: requestId, status: approvedStatus, remarks: remark })
            .then(res => {
              handleTableData()
              toastr.success(res.message)
            })
            .catch(err => {
              toastr.error(err.response.data.message)
            })
        }
      })
    } else {
      put("/internship/application-status", { _id: requestId, status: approvedStatus })
        .then(res => {
          handleTableData()
          toastr.success(res.message)
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })

    }
  
  }  
  const generatResume = async requestId => {
    post("/job/generate-resume", { candidate: requestId })
      .then(res => {
        handleTableData()
       
        toastr.success(res.message)
        window.open(`${API_URL}${res?.data}`, "_blank");
      })
      .catch(err => {
        toastr.error(err.response.data.message)
      })
  }
  function handleTableData() {
    get(`/internship/application?internship=${state.id}&status=2`).then(res => {
      
      let result = res.data
     let data= result.map((item, index) => {
      console.log(item,"sf");
        item.id = index + 1
        item.date1 = moment(item?.shortlist?.date).format("DD-MM-YYYY")
        item.applieddate = item?.date ? moment(item?.date).format("DD-MM-YYYY") : ""
        item.added = item.status === 2 ? item?.shortlist?.staff : item.status === 3 ? item?.placed?.staff : item.status === 4 || item.status === 5 ? item?.reject?.staff : ""

        item.registration = item?.applicant?.uniqueId
        item.name = item?.applicant?.name
        item.mobile = item?.applicant?.mobile
        item.options = (
          <div style={{ display: "flex", justifyContent: "center" ,alignItems:"center" }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                
                marginRight: "0.5rem",
              }}
              onClick={() => {
                setPopupView(true)
                setModal(item)
              }}
            ></i>
                       
            {item?.applicant?.uploadedResume && (
                      <a
                        href={API_URL + item?.applicant?.uploadedResume}
                        target="_blank"
                        // rel="noreferrer"
                      >
                        <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="copy-event-tooltip3">Uploaded Resume</Tooltip>
              }
            >
                        <i
                          className="fas fa-file-pdf"
                          style={{
                            fontSize: "1.2em",
                            cursor: "pointer",
                            
                            marginRight: "0.5rem",
                            color: "red",
                          }}
                        ></i>
            </OverlayTrigger>

                      </a>
                    )} 

            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="copy-event-tooltip">Generated Resume</Tooltip>
              }
            >
              <i
                className="fas fa-file-pdf"
                onClick={() => generatResume(item?.applicant?._id)}
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  
                  marginRight: "0.5rem",
                  color: "#0066b2",
                }}
              ></i>
            </OverlayTrigger>
            {/* )} */}

          
          </div>
        )
        if (item.status === 3) {
          item.status = ( 
            <Button style={{width:"80px"}} size="sm" color="success" disabled>
              Placed
            </Button>
          ) 
        } else if (item.status === 5) {
          item.status = (
            <Button style={{width:"80px"}} size="sm" color="danger" disabled>
              Rejected   
            </Button>
          )
        }else {
          item.status = (
            <Select
              options={statusOptions}
              value={statusOptions.filter(
                status => status.value === item.approvedStatus
              )}
              onChange={selected =>
                handleStatusChange(item._id, selected.value)
              }
              menuPosition="fixed"
            ></Select>
          )
        }
        return item
      })
      setRegistration(data)
    })
  }

  const shortlisted = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "date1",
        sort: "asc",
        width: 200,
      },
      {
        label: "Reg No",
        field: "registration",
        sort: "asc",
        width: 200,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Mobile",
        field: "mobile",
        sort: "asc",
        width: 200,
      },
      {
        label: "Staff",
        field: "added",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 200,
      },

      {
        label: "Action",
        field: "options",
        sort: "asc",
        width: 200,
      },
    ],
    rows: registration,
  }
  return (
    <div>
       <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
      > 
        <div className="modal-header">
          <div className="modal-title">
            <h5>Applicant Details - {modal?.applicant?.name}</h5>
          </div>
          <button
            type="button"
            onClick={() => setPopupView(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <InternshipModal data={modal?._id} />
      </Modal>
      <Row>
        <Col className="col-12">
          <MDBDataTable
            id="registeredpeopleid"
            responsive
            bordered
            data={shortlisted}
            searching={true}
            info={false}
            disableRetreatAfterSorting={true}
          />
        </Col>
      </Row>
    </div>
  )
}

export default Registrations
