import React, { useEffect, useState } from "react"
import { Row, Col, Button } from "reactstrap"
import Select from "react-select"
import { useBatch } from "../BatchContext"
import { get, post } from "../../../../../helpers/api_helper"
import moment from "moment"
import toastr from "toastr"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { useDispatch } from "react-redux"
import { getClassRoomsOrLabs } from "../../../../../store/options/actions"
import { useSelector } from "react-redux"
import useBranch from "../../../../../hooks/useBranch"
import { PRIVILEGES } from "../../../../../config"
import useUser from "../../../../../hooks/useUser"
const QualityFrameWork = props => {
  // const { master, setMaster } = useBatch()

  const user = useUser()

  const { next, back, details = false, ...context } = useBatch()

  const [qualityFrame, setQualityFrame] = useState([])
  const [error, setError] = useState(false)
  const [totalSessionTime, setTotalSessionTime] = useState({ totalHour: 0 })

  const OPTIONS = useSelector(state => state.Options)

  const [branch] = useBranch()

  const dispatch = useDispatch()

  useEffect(() => {
    if (context.master.id) {
      getQualityFrameWork(context.master.id)
    }
  }, [context.master.id])

  useEffect(() => {
    dispatch(getClassRoomsOrLabs({ branch: branch?.value || context.branch }))
  }, [dispatch, branch, context.branch])

  useEffect(() => {
    const [totalHour, totalMinutes] = calculateTotalHours(qualityFrame)
    setTotalSessionTime({ totalHour, totalMinutes })
  }, [qualityFrame])

  const getQualityFrameWork = batchId => {
    get(`batch/quality-framework/${batchId}`)
      .then(res => {
        const data = res.data
        data.map(item => {
          item.sessions.map(session => {
            session.selected = {
              label: session?.class?.name || session?.selected?.label,
              value: session?.class?._id || session?.selected?._id,
            }
            session.class = session?.class?._id || session?.selected?.label
            return session
          })

          return item
        })
        setQualityFrame(res.data || [])
      })
      .catch(err => {
        console.log(err)
      })
  }

  async function handleChange(e, qIndex, sessionIndex) {
    let { name, value } = e.target
    name = name.split("-")[0]
    setError(true)

    let classClear = ["date", "from", "to"]

    const data = [...qualityFrame]

    data[qIndex].sessions[sessionIndex][name] = value

    if (classClear.includes(name)) {
      const details = { ...data[qIndex].sessions[sessionIndex] }
      delete data[qIndex].sessions[sessionIndex].class
      delete data[qIndex].sessions[sessionIndex].selected

      if (details.reservationId) {
        await post(
          `classroomsorlabs/clear-booking?reservation=${details.reservationId}`
        ).then(res => {
          console.log(res)
        })
      }
    }

    if (name === "from" || name === "to") {
      let totalAllottedMinutes = 0

      data[qIndex].sessions.forEach(session => {
        if (session?.from && session?.to) {
          const fromTime = moment(session.from, "HH:mm")
          const toTime = moment(session.to, "HH:mm")
          const difference = toTime.diff(fromTime, "minutes")
          totalAllottedMinutes += difference
        }
      })

      const minutes = data[qIndex].minutes - totalAllottedMinutes

      if (
        data[qIndex].sessions[sessionIndex].from &&
        data[qIndex].sessions[sessionIndex].to
      ) {
        const time1 = moment(data[qIndex].sessions[sessionIndex].from, "HH:mm")
        const time2 = moment(data[qIndex].sessions[sessionIndex].to, "HH:mm")

        const differenceInMinutes = time2.diff(time1, "minutes")
        // console.log("differenceInMinutes: ", differenceInMinutes, minutes)

        if (
          minutes > 0 &&
          differenceInMinutes > 0 &&
          sessionIndex + 1 === data[qIndex].sessions.length
        ) {
          data[qIndex].sessions.push({})
          setError(false)
        } else if (minutes < 0) {
          toastr.info("Time range selected for higher than session minutes")

          data[qIndex].sessions.splice(
            sessionIndex + 1,
            data[qIndex].sessions.length - (sessionIndex + 1)
          )
        }
      } else {
        setError(false)
      }

      if (minutes === 0) {
        data[qIndex].sessions.splice(
          sessionIndex + 1,
          data[qIndex].sessions.length - (sessionIndex + 1)
        )
        setError(false)
      }
    }

    setQualityFrame(data)
  }

  const validationDate = index => {
    const date =
      qualityFrame[index - 1]?.sessions[
        qualityFrame[index - 1]?.sessions?.length - 1
      ]?.date
    return date
  }

  const calculateTotalHours = sessionArray => {
    if (!Array.isArray(sessionArray)) {
      return
    }

    let totalMinutes = 0

    sessionArray.forEach(session => {
      const minutes = parseInt(session.minutes, 10)

      if (!isNaN(minutes) && minutes >= 0) {
        totalMinutes += minutes
      }
    })

    const totalHours = Math.floor(totalMinutes / 60)

    const remainingMinutes = totalMinutes % 60

    const formattedHours = totalHours.toString().padStart(2, "0")
    const formattedMinutes = remainingMinutes.toString().padStart(2, "0")

    return [`${formattedHours}:${formattedMinutes}`, totalMinutes]
  }

  const handleSelect = async (e, qIndex, sessionIndex) => {
    const details = [...qualityFrame]

    const data = details[qIndex].sessions[sessionIndex]

    let flag = false

    await post(`classroomsorlabs/reservation`, {
      classroom: e.value,
      date: data.date,
      startTime: data.from,
      endTime: data.to,
      oldReservationId: data?.reservationId || "",
    })
      .then(res => {
        details[qIndex].sessions[sessionIndex].reservationId = res.data._id
      })
      .catch(err => {
        toastr.warning(err?.response?.data?.message || "Something went wrong")
        flag = true
      })

    if (flag) {
      return
    }

    details[qIndex].sessions[sessionIndex].class = e.value
    details[qIndex].sessions[sessionIndex].selected = e
    setQualityFrame(details)
  }

  const handleSubmit = () => {
    post(`batch/quality-framework`, {
      batchId: context.master.id,
      qualityFramework: qualityFrame,
      totalSessionTime: totalSessionTime?.totalMinutes,
    })
      .then(res => {
        if (context.master.id) {
          getQualityFrameWork(context.master.id)
        }
        toastr.success(res.message)
      })
      .catch(err => {
        console.log(err)
        toastr.error(err?.response?.data?.message)
      })
  }

  return (
    <Row>
      <AvForm onValidSubmit={handleSubmit}>
        <Col className="col-12">
          <div className="d-flex justify-content-end me-3">
            <Button
              type="button"
              size="xs"
              color="primary"
              className="btn-soft-primary waves-effect waves-light p-1 px-2 m-2"
              onClickCapture={() =>
                window.open("/class-availability", "_blank")
              }
            >
              Check availability
              <i className="uil uil-arrow-right ms-2"></i>
            </Button>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Session Name</th>
                  <th>Time in minutes</th>
                  <th>Date</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Class</th>
                </tr>
              </thead>
              <tbody>
                {qualityFrame.map((item, idx) => (
                  <React.Fragment key={idx}>
                    {item?.sessions?.map((session, index) => {
                      let isDisabled = true

                      if (PRIVILEGES.TRAINER === user.privilage) {
                        isDisabled = item?.trainer !== user._id ? true : false
                      } else {
                        isDisabled = false
                      }

                      if (index === 0) {
                        return (
                          <tr key={idx + index}>
                            <td
                              rowSpan={item?.sessions?.length}
                              style={{ width: "60px" }}
                            >
                              {idx + 1}
                            </td>
                            <td
                              rowSpan={item?.sessions?.length}
                              style={{ width: "280px" }}
                            >
                              <AvField
                                name="sessionname"
                                onChange={e => {
                                  // handleChange(e, idx, index)
                                }}
                                disabled
                                value={item.name}
                                title={item.name}
                                type="text"
                                className="form-control"
                              />
                            </td>
                            <td
                              rowSpan={item?.sessions?.length}
                              style={{ width: "130px" }}
                            >
                              <AvField
                                style={{ textAlign: "end" }}
                                disabled
                                name="time"
                                onChange={e => {
                                  // handleChange(e, idx, index)
                                }}
                                value={item.minutes}
                                type="number"
                                className="form-control numberTime"
                              />
                            </td>

                            <td style={{ width: "180px" }}>
                              <AvField
                                style={{ textAlign: "end" }}
                                name={`date-${idx}-${index}`}
                                // validate={{
                                //   required: { value: true },
                                // }}
                                onChange={e => {
                                  handleChange(e, idx, index)
                                }}
                                disabled={isDisabled || item.status === 1}
                                value={session.date}
                                min={
                                  idx === 0
                                    ? context?.master?.startDate
                                    : validationDate(idx)
                                }
                                max={context?.master?.endDate}
                                type="date"
                                className="form-control numberTime"
                              />
                            </td>
                            <td style={{ width: "180px" }}>
                              <AvField
                                style={{ textAlign: "end" }}
                                name={`from-${idx}-${index}`}
                                onBlur={e => {
                                  handleChange(e, idx, index)
                                }}
                                validate={{
                                  required: {
                                    value: session.date ? true : false,
                                  },
                                }}
                                disabled={isDisabled || item.status === 1}
                                value={session.from}
                                type="time"
                                className="form-control numberTime"
                              />
                            </td>

                            <td style={{ width: "180px" }}>
                              <AvField
                                style={{ textAlign: "end" }}
                                name={`to-${idx}-${index}`}
                                value={session.to}
                                disabled={isDisabled || item.status === 1}
                                validate={{
                                  required: {
                                    value: session.date ? true : false,
                                  },
                                }}
                                // min={session.from}
                                onBlur={e => {
                                  handleChange(e, idx, index)
                                }}
                                type="time"
                                className="form-control numberTime"
                              />
                            </td>

                            <td style={{ width: "280px" }}>
                              <div className="mb-3">
                                <Select
                                  name="class"
                                  classNamePrefix="select2-selection"
                                  menuPosition="fixed"
                                  options={OPTIONS.classRoomsLabs}
                                  onChange={e => handleSelect(e, idx, index)}
                                  value={session.selected || null}
                                  isDisabled={
                                    !(
                                      session.date &&
                                      session.from &&
                                      session.to &&
                                      !isDisabled &&
                                      item.status !== 1
                                    )
                                  }
                                />
                              </div>
                            </td>

                            <td
                              // rowSpan={item?.sessions?.length}
                              style={{ width: "30px" }}
                            >
                              {/* <span
                                className="mr-1 p-2"
                                style={{
                                  fontSize: "1.125rem",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  const data = [...qualityFrame]
                                  data.splice(idx, 1)
                                  setQualityFrame(data)
                                }}
                              >
                                <i className="trash-btn mdi mdi-delete"></i>
                              </span> */}
                            </td>
                          </tr>
                        )
                      } else {
                        return (
                          <tr key={idx + index}>
                            <td style={{ width: "180px" }}>
                              <AvField
                                style={{ textAlign: "end" }}
                                name={`date-${idx}-${index}`}
                                onChange={e => {
                                  handleChange(e, idx, index)
                                }}
                                disabled={isDisabled || item.status === 1}
                                // validate={{
                                //   required: { value: true },
                                // }}
                                value={session.date}
                                min={validationDate(idx)}
                                type="date"
                                className="form-control numberTime"
                              />
                            </td>

                            <td style={{ width: "180px" }}>
                              <AvField
                                style={{ textAlign: "end" }}
                                name={`from-${idx}-${index}`}
                                value={session?.from}
                                onBlur={e => {
                                  handleChange(e, idx, index)
                                }}
                                disabled={isDisabled || item.status === 1}
                                validate={{
                                  required: {
                                    value: session.date ? true : false,
                                  },
                                }}
                                type="time"
                                className="form-control numberTime"
                              />
                            </td>

                            <td style={{ width: "180px" }}>
                              <AvField
                                style={{ textAlign: "end" }}
                                name={`to-${idx}-${index}`}
                                onBlur={e => {
                                  handleChange(e, idx, index)
                                }}
                                disabled={isDisabled || item.status === 1}
                                validate={{
                                  required: {
                                    value: session.date ? true : false,
                                  },
                                }}
                                value={session.to}
                                type="time"
                                className="form-control numberTime"
                              />
                            </td>

                            <td style={{ width: "280px" }}>
                              <div className="mb-3">
                                <Select
                                  name="class"
                                  menuPosition="fixed"
                                  options={OPTIONS.classRoomsLabs}
                                  onChange={e => handleSelect(e, idx, index)}
                                  value={session.selected || null}
                                  isDisabled={
                                    !(
                                      session.date &&
                                      session.from &&
                                      session.to &&
                                      !isDisabled &&
                                      item.status !== 1
                                    )
                                  }
                                />

                                {/* {item?.sessions?.length - 1 === index && (
                                <div className="d-flex align-items-center justify-content-end">
                                  <button className="waves-effect btn btn-outline-light d-flex p-1">
                                    Add more{" "}
                                    <i className="ms-1 mdi mdi-plus-circle-outline"></i>
                                  </button>
                                </div>
                              )} */}
                              </div>
                            </td>
                            <td></td>
                          </tr>
                        )
                      }
                    })}
                  </React.Fragment>
                ))}

                <tr>
                  <td
                    colSpan={3}
                    style={{
                      textAlign: "right",
                      width: "140px",
                    }}
                  >
                    {totalSessionTime.totalHour} Hours
                  </td>
                </tr>
              </tbody>
            </table>

            {/* <button
            onClick={() => {
              const data = [...qualityFrame]
              data.push({
                sessionname:
                  "Session:" + `${qualityFrame.length + 1}`.padStart(3, 0),
                Time: "",
              })
              setQualityFrame(data)
            }}
            className="waves-effect btn btn-outline-light d-flex"
            style={{ gap: "5px" }}
          >
            Add more <i className="mdi mdi-plus-circle-outline"></i>
          </button> */}
          </div>
        </Col>

        <Col
          md=""
          style={{ display: "flex", justifyContent: "space-between" }}
          // style={{ paddingTop: "26px" }}
        >
          <div className="ms-1">
            {!details && (
              <Button
                type="button"
                onClick={() => context.reset({})}
                className="mr-auto"
                color="danger"
              >
                Reset
              </Button>
            )}
          </div>

          <div>
            <Button
              onClick={() => {
                back()
              }}
              type="button"
              className="me-2"
              color="primary"
            >
              Back
            </Button>

            {!error && (
              <Button type="submit" className="me-2" color="primary">
                Save
              </Button>
            )}

            {/* <Button
              onClick={() => {
                next()
              }}
              type="button"
              className="me-2"
              color="primary"
            >
              Next
            </Button> */}
          </div>
        </Col>
      </AvForm>
    </Row>
  )
}

export default QualityFrameWork
