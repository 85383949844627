import React, { useState, useRef, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"

import toastr from "toastr"
import moment from "moment"

import "./style.scss"

import { get, post } from "../../../helpers/api_helper"
import { getChartAccount } from "../../../store/options/actions"

import SelectErrorMessage from "../../../components/Common/SelectErrorMessage"
import { setTitle, getDate } from "../../../helpers/functions"

const CreditDebitFund = ({ options }) => {
  setTitle("Credit/Debit Fund")

  const date = getDate()
  const time = moment().format("HH:mm")

  const [tableData, setTableData] = useState([])
  const formRef = useRef()

  const [error, setError] = useState({
    type: false,
    fundSource: false,
  })

  const { chartOfAccount } = useSelector(state => state.Options)
  const dispatch = useDispatch()
  const [balance, setBalance] = useState(0)
  const [masterObject, setMasterObject] = useState({
    fundSource: "",
    type: 1,
    amount: "",
    comment: "",
    date,
    time,
  })

  useEffect(() => {
    handleTableData()
    dispatch(getChartAccount())
    // eslint-disable-next-line
  }, [])

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  const handleTableData = async () => {
    get("/accounts/credit-debit").then(res => {
      const result = res.data
      result.map((item, index) => {
        item.id = index + 1
        item.name = item.fundSource
        item.date = moment(item.date).format("DD-MM-YYYY")
        item.time = moment(item.time, "HH:mm:ss").format("hh:mm a")

        if (item.type === 0) item.credit = item.amount
        else item.debit = item.amount

        return item
      })

      setTableData(result)
    })
  }

  const handleValueChange = async (value, name, balance = 0) => {
    const obj = { ...masterObject }
    obj[name] = value

    if (name === "fundSource") setBalance(balance)
    setMasterObject(obj)
  }

  const handleSubmit = () => {
    let errorAvailable = false
    if (!masterObject.fundSource) {
      setError(prev => ({ ...prev, fundSource: true }))
      errorAvailable = true
    }
    if (masterObject.type !== 0 && masterObject.type !== 1) {
      setError(prev => ({ ...prev, type: true }))
      errorAvailable = true
    }

    if (errorAvailable) return
    post("/accounts/credit-debit", masterObject)
      .then(res => {
        reset()
        toastr.success(res.message)
      })
      .catch(err => {
        toastr.error(err.response.data.message)
      })
  }
  const reset = () => {
    formRef.current.reset()
    handleTableData()
    dispatch(getChartAccount())
    setMasterObject({
      fundSource: "",
      type: "",
      amount: "",
      comment: "",
      date,
      time,
    })
    toTop()
  }

  const typeOptions = [
    {
      label: "Credit",
      value: 0,
    },
    {
      label: "Debit",
      value: 1,
    },
  ]
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        width: 150,
      },
      {
        label: "Reference ID",
        field: "uniqueId",
        width: 150,
      },
      {
        label: "Chart Name",
        field: "name",
        width: 150,
      },
      {
        label: "Debit",
        field: "debit",
        width: 150,
      },
      {
        label: "Credit",
        field: "credit",
        width: 150,
      },
      {
        label: "Comment",
        field: "comment",
        width: 150,
      },
      {
        label: "User",
        field: "staff",
        width: 150,
      },
    ],
    rows: tableData,
  }
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Credit/Debit Fund" />
          {options.edit ? (
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm onValidSubmit={handleSubmit} ref={formRef}>
                      <Row>
                        <Col md={2}>
                          <div className="mb-3">
                            <AvField
                              name="date"
                              label="Date"
                              type="date"
                              value={masterObject.date}
                              defaultValue={date}
                              max={date}
                              onChange={e =>
                                handleValueChange(e.target.value, e.target.name)
                              }
                            />
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="mb-3">
                            <AvField
                              name="time"
                              label="Time"
                              type="time"
                              defaultValue={time}
                              value={masterObject.time}
                              onChange={e =>
                                handleValueChange(e.target.value, e.target.name)
                              }
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Fund Source</Label>
                            <Select
                              name="fundSource"
                              placeholder="Select..."
                              options={chartOfAccount}
                              value={chartOfAccount.filter(
                                item => item.value === masterObject.fundSource
                              )}
                              onChange={selected => {
                                handleValueChange(
                                  selected.value,
                                  "fundSource",
                                  selected.balance
                                )
                                setError(prev => ({
                                  ...prev,
                                  fundSource: false,
                                }))
                              }}
                            />
                            <SelectErrorMessage
                              customStyle={{
                                width: "100%",
                                fontSize: "87.5%",
                                color: "var(--bs-form-invalid-color)",
                              }}
                              message="Select Fund Source"
                              show={error.fundSource ? true : false}
                            />
                            {masterObject.fundSource ? (
                              <span className="mt-2">
                                <b>Balance :</b> {balance}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Type</Label>
                            <Select
                              name="fundSource"
                              placeholder="Select..."
                              options={typeOptions}
                              value={typeOptions.filter(
                                item => item.value === masterObject.type
                              )}
                              onChange={selected => {
                                handleValueChange(selected.value, "type")
                                setError(prev => ({
                                  ...prev,
                                  type: false,
                                }))
                              }}
                            />
                            <SelectErrorMessage
                              customStyle={{
                                width: "100%",
                                fontSize: "87.5%",
                                color: "var(--bs-form-invalid-color)",
                              }}
                              message="Select Type"
                              show={error.type ? true : false}
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <Label>Amount</Label>
                            <AvField
                              name="amount"
                              className="form-control"
                              type="text"
                              value={masterObject.amount}
                              onChange={e => {
                                handleValueChange(e.target.value, "amount")
                              }}
                              validate={{ required: { value: true } }}
                              placeholder="Amount"
                              errorMessage="Enter Amount"
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Comments</Label>
                            <AvField
                              name="Comments"
                              className="form-control"
                              type="textarea"
                              value={masterObject.comment}
                              onChange={e => {
                                handleValueChange(e.target.value, "comment")
                              }}
                              validate={{ required: { value: true } }}
                              placeholder="Comments"
                              rows="1"
                              errorMessage="Enter Comments"
                            />
                          </div>
                        </Col>

                        <Col>
                          <div
                            className="mb-3 d-flex gap-2"
                            style={{ paddingTop: "26px" }}
                          >
                            <Button
                              color={`${
                                masterObject.id ? "warning" : "primary"
                              }`}
                              type="submit"
                            >
                              {masterObject.id ? "Update" : "Submit"}
                            </Button>
                            <Button color="danger" onClick={reset}>
                              Reset
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            ""
          )}

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="CreditDebitId"
                    bordered
                    responsive
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default CreditDebitFund
