import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, CardHeader } from "reactstrap"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

import { MDBDataTable } from "mdbreact"

import moment from "moment"
import { get } from "../../../../helpers/api_helper"
import { Link, useLocation, useParams } from "react-router-dom"
import MiniWidget from "../mini-widget"
// import "../style.scss"

function Registrations(props) {
  const { state } = useLocation()
  const jobpost = props.data
  // console.log(jobpost,"jobpost");
  const [registration, setRegistration] = useState([])

  useEffect(() => {
    handleTableData()
  
  }, [])
  function handleTableData() {
    get(`internship/log?id=${state.id}`)
      // get(`job/log?id=${jobpost}`)
      .then(res => {
        let result = res?.data

        let data = result.map((item, index) => {
          item.id = index + 1
          item.date = moment(item?.date).format("DD-MM-YYYY")
          item.time1 = moment(item?.time, "hh:mm s").format("h:mm a")

          item.added = item?.addedBy?.name
          item.registration = item?.applicant?.uniqueId
          item.name = item?.applicant?.name
          item.mobile = item?.applicant?.mobile
          item.previousStatus =
            item?.previousStatus === 0 ? (
              <h6 style={{ color: "green" }}>{"Ongoing"}</h6>
            ) : item?.previousStatus === 2 ? (
              <h6 style={{ color: "orange" }}>{"Inactive"}</h6>
            ) : item?.previousStatus === 3 ? (
              <h6 style={{ color: "red" }}>{"Expired"}</h6>
            ) : item?.previousStatus === 4 ? (
              <h6 style={{ color: "blue" }}>{"Filled"}</h6>
            ) : (
              ""
            )
          item.status =
            item?.status === 0 ? (
              <h6 style={{ color: "green" }}>{"Ongoing"}</h6>
            ) : item?.status === 2 ? (
              <h6 style={{ color: "orange" }}>{"Inactive"}</h6>
            ) : item?.status === 3 ? (
              <h6 style={{ color: "red" }}>{"Expired"}</h6>
            ) : item?.status === 4 ? (
              <h6 style={{ color: "blue" }}>{"Filled"}</h6>
            ) : (
              ""
            )
          item.options = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2rem",
                  marginRight: "0.5rem",
                }}
                // onClick={() => {
                //   setPopupView2(true)
                // }}
              ></i>
            </div>
          )
          return item
        })
        setRegistration(data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const jobpostStatus = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Time",
        field: "time1",
        sort: "asc",
        width: 400,
      },
      {
        label: "Staff",
        field: "added",
        sort: "asc",
        width: 200,
      },
      {
        label: "Prev Status",
        field: "previousStatus",
        sort: "asc",
        width: 200,
      },
      {
        label: "Current Status",
        field: "status",
        sort: "asc",
        width: 200,
      },
    ],
    rows: registration,
  }
  const [registration1, setRegistration1] = useState([])
  useEffect(() => {
    handleTableData1()
  }, [])
  function handleTableData1(){
    get(`/internship/counts?id=${state.id}`)
      // get(`job/log?id=${jobpost}`)
      .then(res => {
        let result = res?.counts

        setRegistration1(result)
      })
      .catch(err => {
        console.log(err)
      })
  }
  const series1 = [50]

  const options1 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series2 = [70]

  const options2 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series3 = [55]

  const options3 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series4 = [33]

  const options4 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const reports = [
    {
      id: 1,
      // icon: "mdi mdi-arrow-up-bold",
      title: "Applied",
      value: registration1?.applied,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      // badgeValue: "0%",
      // color: "success",
      // desc: "Total",
      series: series1,
      options: options1,
    },
    // {
    //   id: 2,
    //   // icon: "mdi mdi-arrow-up-bold",
    //   title: "Saved",
    //   value: registration?.saved,
    //   decimal: 0,
    //   charttype: "radialBar",
    //   chartheight: 45,
    //   chartwidth: 45,
    //   prefix: "",
    //   suffix: "",
    //   // badgeValue: "0%",
    //   // color: "success",
    //   // desc: "Total",
    //   series: series2,
    //   options: options2,
    // },
    {
      id: 3,
      // icon: "mdi mdi-arrow-down-bold",
      title: "Shortlisted",
      value: registration1?.shortlisted,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      // badgeValue: "2.9%",
      // color: "danger",
      // desc: "Total",
      series: series3,
      options: options3,
    },
    {
      id: 4,
      // icon: "mdi mdi-arrow-up-bold",
      title: "Placed",
      value: registration1?.placed,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      // badgeValue: "0",
      // color: "success",
      // desc: "Total",
      series: series4,
      options: options4,
    },
    {
      id: 5,
      // icon: "mdi mdi-arrow-up-bold",
      title: "Rejected",
      value: registration1?.rejected,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      // badgeValue: "0",
      // color: "success",
      // desc: "Total",
      series: series4,
      options: options4,
    },
  ]
  return (
    <div>
      <Row>
                              <MiniWidget reports={reports} />
                            </Row>
      <Row>
        <Col className="col-12">
          <Card>
            <CardHeader style={{ fontSize: "18px" }}>
              Job Post Status Update Log
            </CardHeader>
            <CardBody>
              <MDBDataTable
                id="jobpostStatusLogTable"
                responsive
                bordered
                data={jobpostStatus}
                searching={true}
                info={false}
                disableRetreatAfterSorting={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Registrations
