import React, { useEffect, useRef, useState } from "react"
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { MDBDataTable } from "mdbreact"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { API_URL } from "../../../config"
import Swal from "sweetalert2"
import { del, post, put, get } from "../../../helpers/api_helper"
import toastr from "toastr"
import moment from "moment"
import { generateShortCode } from "../../../helpers/functions"

function MinorityStatus() {
  const [masterObj, setmasterObj] = useState({})
  const [modulesForTable, setModulesForTable] = useState([])
  const formRef = useRef()

  useEffect(() => {
    getModule()
    // eslint-disable-next-line
  }, [])

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`manage/minority-status/${id}`)
          .then(res => {
            toastr.success(res.message)
            getModule()
          })
          .catch(err => {
            toastr.success(err.response.data.message)
          })
      }
    })
  }

  function reset() {
    setmasterObj({})
    formRef.current.reset()
  }
  function handleChangeInput(e) {
    let { name, value } = e.target

    const data = { [name]: value }

    if (name === "name") {
      data.shortCode = generateShortCode(value)
    }

    setmasterObj({ ...masterObj, ...data })
  }

  function handleSubmit() {
    if (masterObj.id) {
      put(`${API_URL}manage/minority-status`, masterObj)
        .then(res => {
          toastr.success(res.message)
          getModule()
          reset()
        })
        .catch(err => {
          toastr.error(err.response.data.message)
          return
        })
    } else {
      post(`manage/minority-status`, masterObj)
        .then(res => {
          toastr.success(res.message)
          getModule()
          reset()
        })
        .catch(err => {
          toastr.error(err.response.data.message)
          return
        })
    }
  }

  const getModule = () => {
    get("manage/minority-status")
      .then(data => {
        data?.data?.map((data, idx) => {
          data.id = idx + 1
          data.date1 = moment(data?.date).format("DD-MM-YYYY")

          data.addedby =
            data?.addedBy?.firstName + data?.addedBy?.lastName ||
            data?.updatedBy?.firstName + data?.updatedBy?.lastName

          data.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop()
                  handleUpdate(data)
                }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => {
                  handleDelete(data?._id)
                }}
              ></i>
            </div>
          )

          return data.data
        })
        setModulesForTable(data.data)
      })
      .catch(err => {
        console.log("err", err)
      })
  }

  function handleUpdate(data) {
    setmasterObj({ id: data._id, name: data?.name, shortCode: data?.shortCode })
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Addedby",
        field: "addedby",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: modulesForTable,
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem="Minority Status" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleSubmit()
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Name</Label>

                          <AvField
                            name="name"
                            placeholder="Name"
                            type="text"
                            errorMessage="Enter name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={masterObj.name || ""}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Short Code</Label>

                          <AvField
                            name="shortCode"
                            placeholder="Short Code"
                            type="text"
                            errorMessage="Enter short Code"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: "^[A-Z]*$",
                                errorMessage:
                                  "Only Uppercase letters are allowed",
                              },
                            }}
                            id="validationCustom01"
                            value={masterObj.shortCode || ""}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="3" style={{ paddingTop: "4px" }}>
                        <div className="mt-4">
                          <Button
                            color={masterObj.id ? "warning" : "primary"}
                            className="me-2"
                            type="submit"
                          >
                            {masterObj.id ? "Update" : "Submit"}
                          </Button>

                          <Button
                            color="danger"
                            className="me-2"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="modulesTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default MinorityStatus
