import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Modal } from "react-bootstrap"
import { get } from "../../../../../helpers/api_helper"
import { MDBDataTable } from "mdbreact"
import moment from "moment"

function Costing(props) {
  const [popupView, setPopupView] = useState(false)
  const [tabledata, settabledata] = useState([])
  const [tabledata1, settabledata1] = useState({})
  const costID = props.data

  useEffect(() => {
    fetchallCost()
  }, [props])

  const data = {
    columns: [
      {
        label: "# ",
        field: "id",
        width: 150,
        sort: "asc",
      },
      {
        label: "Date",
        field: "date",
        width: 150,
        sort: "asc",
      },
      {
        label: "Expense Category",
        field: "expense",
        width: 150,
        sort: "asc",
      },
      {
        label: "Expense",
        field: "narration",
        width: 150,
        sort: "asc",
      },
      {
        label: "Amount",
        field: "amount",
        width: 150,
        sort: "asc",
      },
    ],
    rows: tabledata,
  }

  function fetchallCost() {
    get(`/event-costing/costing?event=${costID}`).then(res => {
    
      settabledata1(res)
      let result = res?.data


      settabledata(result)
    })
  }
 
  return (
    <div>
      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h5>Placed Details</h5>
          </div>

          <button
            type="button"
            onClick={() => setPopupView(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body" style={{ paddingTop: "0px" }}>
          <Row>
            <Col md="12">
              <table
                id="product_available_day"
                className="table table-bordered dataTable"
              >
                <tr>
                  <th style={{ padding: "10px 11px", textAlign: "left" }}>
                    Job
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>Project Engineer</td>
                  <th style={{ textAlign: "left" }}>Recruiters</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Ernst & Young
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>
                    Application ID
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>ASPJP0001</td>
                  <th style={{ textAlign: "left" }}>Candidate</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Alan Job
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>
                    Placed Date
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>24-01-2024</td>
                  <th style={{ textAlign: "left" }}>Placed Time</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    02:30 pm
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>Mobile</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    9365524183
                  </td>
                  <th style={{ textAlign: "left" }}>Email</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    alan@gmail.com
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>
                    Experiance
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Fresher
                  </td>
                  <th style={{ textAlign: "left" }}>Qualificaion</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Graduation
                  </td>
                </tr>
              </table>
            </Col>
          </Row>
        </div>
      </Modal>
      <Row>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Category</th>
                <th>Income</th>
                <th>Expense</th>
              </tr>
            </thead>
            <tbody>
              {tabledata?.map((item, index) => (
                
                <tr>
                  <td>{item?.category}</td>
                  <td style={{ textAlign: "end" }}>{item?.income}</td>
                  <td style={{ textAlign: "end" }}>{item?.expense}</td>
                </tr>
              ))}

              <tr>
                <td style={{ fontWeight: "bold" }}>Total</td>
                <td style={{ textAlign: "end", fontWeight: "bold" }}>
                  {tabledata1?.totalAmounts?.totalIncome}
                </td>
                <td style={{ textAlign: "end", fontWeight: "bold" }}>
                  {tabledata1?.totalAmounts?.totalExpense}
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ textAlign: "end" }}>
            {" "}
            <h6>Total Profit : {tabledata1?.totalAmounts?.profit}</h6>
          </div>
        </div>
        {/* <Col className="col-12">
          <MDBDataTable
            id="savedJobDataId"
            responsive
            bordered
            data={saved}
            searching={true}
            info={false}
            disableRetreatAfterSorting={true}
          />
        </Col> */}
        <Col className="col-12 d-none">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="clusterTableId"
                    bordered
                    responsive
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                    sortable={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* <div className="d-flex justify-content-end">
            Total Amount: <span style={{ fontWeight: "bold" }}> 10647</span>{" "}
          </div> */}
        </Col>
      </Row>
    </div>
  )
}

export default Costing
