import React from "react"
import { Card, CardBody, Table } from "reactstrap"

//Simple bar
import SimpleBar from "simplebar-react"

import { Link } from "react-router-dom"

const TopUser = props => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Link to={props.link}>
            <div className="float-end">View All</div>
          </Link>
          <h4 className="card-title mb-4">{props.title}</h4>
          <SimpleBar style={{ maxHeight: "336px" }}>
            <div className="table-responsive">
              <Table
                className="table-borderless table-centered table-nowrap"
                id="fs-small-table"
              >
                <tbody>
                  {props.list?.map(res => (
                    <tr>
                      <td>
                        <h6 className="font-size-15 mb-1 fw-normal">
                          {res?.profile?.name}
                        </h6>
                        <p className="text-muted font-size-13 mb-0">
                          {res?.profile?.uniqueId}
                        </p>
                      </td>
                      <td>
                        {/* {res.status === 0 ? (
                          <span className="badge bg-soft-info font-size-12">
                            Active
                          </span>
                        ) : res?.status === 2 ? (
                          <span className="badge bg-soft-success font-size-12">
                            Approved
                          </span>
                        ) : res?.status === 3 ? (
                          <span className="badge bg-soft-danger font-size-12">
                            Rejected
                          </span>
                        ) : (
                          ""
                        )} */}
                        <span
                          className="badge bg-soft font-size-12"
                          style={{
                            backgroundColor: res?.statusData?.color,
                          }}
                        >
                          {res?.statusData?.name}
                        </span>
                      </td>
                      <td className="text-muted fw-semibold text-end">
                        {res?.amount?.toLocaleString("en-IN", {
                          maximumFractionDigits: 0,
                          style: "currency",
                          currency: "INR",
                        }) || "₹0"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </SimpleBar>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default TopUser
