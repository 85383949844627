import React, { useState } from "react"
// import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label, Input } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import ReactSelect from "react-select"
import "./Certificates.scss"
const AddCertificate = () => {
  const forOptions = [
    { label: "Student", value: 0 },
    { label: "Batch", value: 1 },
  ]
  const students = [
    { label: "sravya", value: 0 },
    { label: "sreedarsh", value: 1 },
    { label: "Anuranj", value: 2 },
    { label: "Jamsheer", value: 3 },
  ]
  const batches = [
    { label: "Batch 1", value: 0 },
    { label: "Batch 2", value: 1 },
    { label: "Batch 3", value: 2 },
    { label: "Batch 4", value: 3 },
  ]
  const details = [
    {
      name: "Sravya",
      domain: "acsarchs.com",
      mobile: "918848791199",
      email: "Sravya@gmail.com",
      district: "Kannur",
      value: 0,
    },

    {
      name: "Sreedarsh",
      domain: "acsarchs.com",
      mobile: "918848791199",
      email: "Sreedarsh@gmail.com",
      district: "Kannur",
      value: 1,
    },
    {
      name: "Anuranj",
      domain: "acsarchs.com",
      mobile: "918848791199",
      email: "Anuranj@gmail.com",
      district: "Kannur",
      value: 2,
    },
    {
      name: "Jamsheer",
      domain: "acsarchs.com",
      mobile: "918848791199",
      district: "Kannur",
      email: "Jamsheer@gmail.com",
      value: 3,
    },
  ]
  const initialState = {
    certificate: "",
    for: "",
    students: "",
    batches: "",
  }

  const [masterObject, setMasterObject] = useState(initialState)

  const [selectedStudent, setSelectedStudent] = useState(null)
  const [selectedBatch, setSelectedBatch] = useState(null)

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Certificate",
        field: "name",
        width: 150,
      },
      {
        label: "Certificate For",
        field: "for",
        width: 150,
      },
      {
        label: "Actions",
        field: "options",
        sort: "desc",
        width: 400,
      },
    ],
    rows: [
      {
        id: "1",
        Code: "SD4MOT",
        date: "24-10-2022",
        Time: "12:30pm",
        name: "Certificate 1",
        User: "admin",
        for: "Students",
        Name: "SMOT DOCS",

        Description: " smot docs ",
        options: (
          <div>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",

                marginRight: "0.5rem",
              }}
            ></i>
            {/* <i
                        className="uil-edit-alt"
                        style={{
                            fontSize: "1.2em",
                            cursor: "pointer",
                            marginLeft: "0.5rem",
                            marginRight: "0.5rem",
                        }}

                    ></i> */}
            <i
              className="uil-edit-alt"
              style={{
                fontSize: "1.2em",
                marginRight: "0.5rem",
                cursor: "pointer",
              }}
            ></i>
            <i
              className="uil-trash-alt"
              style={{ fontSize: "1.2em", cursor: "pointer" }}
            ></i>
          </div>
        ),
      },
      {
        id: "2",
        Code: "ST2MOT",
        Name: "SMOT TOURS",
        date: "24-10-2022",
        Time: "12:30pm",
        for: "Batch",
        name: "Certificate 2",
        User: "admin",
        Description: "smot tours ",
        options: (
          <div>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",

                marginRight: "0.5rem",
              }}
            ></i>

            <i
              className="uil-edit-alt"
              style={{
                fontSize: "1.2em",
                marginRight: "0.5rem",
                cursor: "pointer",
              }}
            ></i>
            <i
              className="uil-trash-alt"
              style={{ fontSize: "1.2em", cursor: "pointer" }}
            ></i>
          </div>
        ),
      },
      {
        id: "3",
        Code: "SE5MOT",
        Name: "SMOT STUDY",
        Description: "smot study",
        name: "Certificate 3",
        date: "24-10-2022",
        for: "Students",
        Time: "12:30pm",
        User: "Sham",
        options: (
          <div>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
            
                marginRight: "0.5rem",
              }}
            ></i>

            <i
              className="uil-edit-alt"
              style={{
                fontSize: "1.2em",
                marginRight: "0.5rem",
                cursor: "pointer",
              }}
            ></i>
            <i
              className="uil-trash-alt"
              style={{ fontSize: "1.2em", cursor: "pointer" }}
            ></i>
          </div>
        ),
      },
    ],
  }
  const handleValueChange = (value, name) => {
    const obj = { ...masterObject }
    obj[name] = value
    setMasterObject(obj)
  }
  const renderStudentDetails = () => {
    if (selectedBatch) {
      return (
        <div className="table-responsive">
          <table
            style={{ borderColor: "#00000017" }}
            className="table table-bordered certtable"
          >
            <thead>
              <tr>
                <th>Name</th>
                <th>Mobile</th>
                <th>Email</th>
                <th>District</th>
                <th style={{ width: "125px" }}>Grade</th>
              </tr>
            </thead>
            <tbody className="certificatetbody">
              {details.map(student => (
                <tr key={student.value}>
                  <td>{student.name}</td>
                  <td>{student.mobile}</td>
                  <td>{student.email}</td>
                  <td>{student.district}</td>
                  <td>
                    <Input
                      style={{ margin: "0px" }}
                      name="Domain Code"
                      errorMessage="Enter Category"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )
    }
    const student = details.find(student => student.value === selectedStudent)
    if (student) {
      return (
        <div className="table-responsive">
          <table
            style={{ borderColor: "#00000017" }}
            className="table table-bordered certtable"
          >
            <thead>
              <tr>
                <th>Name</th>
                <th>Mobile</th>
                <th>Email</th>
                <th>District</th>
                <th style={{ width: "125px" }}>Grade</th>
              </tr>
            </thead>
            <tbody className="certificatetbody">
              <tr>
                <td>{student.name}</td>
                <td>{student.mobile}</td>
                <td>{student.email}</td>
                <td>{student.district}</td>{" "}
                <td>
                  <Input
                    style={{ margin: "0px" }}
                    className="mb-0"
                    name="Domain Code"
                    errorMessage="Enter Category"
                  />
                </td>
              </tr>
              {/* {student.map(item => (
                <tr key={item.value}>
                  <td>{item.name}</td>
                  <td>{item.mobile}</td>
                  <td>{item.email}</td>
                  <td>{item.district}</td>
                  <td>
                    <Input
                      style={{ margin: "0px" }}
                      name="Domain Code"
                      errorMessage="Enter Category"
                    />
                  </td>
                </tr>
              ))} */}
            </tbody>
          </table>
        </div>
      )
    }
    return null
  }
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Certificate" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  {/* <AvForm>  */}
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Certificate Template</Label>
                        <ReactSelect
                          name="Domain"
                          type="text"
                          errorMessage="Enter Category"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Certificate For</Label>
                        <ReactSelect
                          onChange={selected => {
                            // setSelectedFor(selected)
                            if (selected.value === 1) {
                              setSelectedStudent(null)
                            } else {
                              setSelectedBatch(null)
                            }
                            handleValueChange(selected.value, "for")
                          }}
                          // value={selectedFor}
                          name="for"
                          options={forOptions}
                          type="text"
                          errorMessage="Enter Category"
                        />
                      </div>
                    </Col>
                    {masterObject?.for === 0 && (
                      <> 
                        <Col md={4}> 
                          <div className="mb-3"> 
                            <Label>Student</Label> 
                            <ReactSelect
                              name="students" 
                              options={students}
                              // value={selectedStudents}
                              onChange={selected => {
                                setSelectedStudent(selected.value)
                                handleValueChange(selected.value, "students")
                              }}
                            />
                          </div>
                        </Col>
                      </>
                    )}
                    {masterObject?.for === 1 ? (
                      <>
                        <Col md="4">
                          <div className="mb-3">
                            <Label>Batch</Label>
                            <ReactSelect
                              // isMulti
                              name="batches"
                              classNamePrefix="select2-selection"
                              // value={selectedBatch}
                              onChange={selected => {
                                setSelectedBatch(selected)
                                handleValueChange(selected, "batches")
                              }}
                              options={batches}
                            />
                          </div>
                        </Col>
                      </>
                    ) : null}
                    <Col md="12">{renderStudentDetails()}</Col>
                    <Col>
                      <div className="mb-3" style={{ paddingTop: "25px" }}>
                        <Button color="primary" type="submit">
                          Submit
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  {/* </AvForm> */}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="certificateaddid"
                    bordered
                    responsive
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default AddCertificate
