import React, { useState, useEffect, useRef } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { post, put, del, get } from "../../../helpers/api_helper"
import toastr from "toastr"
import Swal from "sweetalert2"
import axios from "axios"
import { API_URL } from "../../../config"
import "./style.scss"
import moment from "moment"
import { jsonToExcel } from "../../../helpers/functions"

const Testimonials = () => {
  const formRef = useRef()

  const initialState = {
    name: "",
    designation: "",
    testimonial: "",
    image: "",
    companyName: "",
  }

  const [masterObject, setMasterObject] = useState(initialState)
  const [filterObject, setFilterObject] = useState({})
  const [exportData, setExportData] = useState({})
  const [tableData, setTableData] = useState([])
  const [state, setState] = useState(false)
  const [Image, setImage] = useState("")

  useEffect(() => {
    handleTableData()
  }, [filterObject])

  const handleTableData = () => {
    get(`testimonial`).then(res => {
      const result = res.data || []
      const exportData = []
      result.map((item, index) => {
        item.id = index + 1
        item.testimonialData = item.testimonial
        item.date = moment(item?.date).format("DD-MM-YYYY")
        item.testimonial = (
          <>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  id="button-tooltip-2"
                  style={{ position: "absolute", top: "-30px" }}
                >
                  {item.testimonial}
                </Tooltip>
              }
            >
              <div style={{ cursor: "pointer" }}>
                {item.testimonial?.slice(0, 250)}
                {item.testimonial.length > 250 ? "..." : ""}
              </div>
            </OverlayTrigger>
          </>
        )
        item.imageData = (
          <div>
            {item?.image && (
              <a
                href={`${API_URL}${item.image}`}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  style={{ height: "30px", width: "40px", cursor: "pointer" }}
                  src={API_URL + item.image}
                  alt="img"
                />
              </a>
            )}
          </div>
        )
        item.options = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="far fa-edit"
              onClick={() => handleEdit(item)}
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              onClick={() => handleDelete(item._id)}
              style={{ cursor: "pointer", fontSize: "1em" }}
            ></i>
          </div>
        )
        const dataExport = {}
        dataExport.id = item.id
        dataExport.Date = item.date
        dataExport.Name = item.name
        dataExport.Designation = item.designation
        dataExport.Company = item.companyName
        dataExport.Testimonial = item.testimonialData
        exportData.push(dataExport)
        return item
      })
      setExportData(exportData)
      setTableData(result)
    })
  }
  const handleExportData = () => {
    if (exportData.length > 0) {
      jsonToExcel(
        exportData,
        `${`Testimonials`}-${moment().format("DD-MM-YY")}`
      )
    } else {
      toastr.info(`There are No Data to export`)
    }
  }
  const handleSubmit = () => {
    if (masterObject.id) {
      put("testimonial", masterObject)
        .then(res => {
          toastr.success(res.message)
          reset()
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    } else {
      post("testimonial", masterObject)
        .then(res => {
          toastr.success(res.message)
          reset()
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    }
  }

  const handleEdit = async item => {
    toTop()

    let newObject = {
      id: item._id,
      name: item.name,
      designation: item.designation,
      testimonial: item.testimonialData,
      image: item.image,
      companyName: item.companyName,
    }
    setMasterObject(newObject)
  }

  const handleDelete = async id => {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`testimonial?id=${id}`)
          .then(res => {
            toastr.success(res.message)
            handleTableData()
          })
          .catch(err => {
            toastr.error(err.response.data.message)
          })
      }
    })
  }

  const handleValueChange = (value, name) => {
    const obj = { ...masterObject }
    obj[name] = value
    setMasterObject(obj)
  }

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }
  const reset = () => {
    formRef.current?.reset()
    setFilterObject({})
    setMasterObject(initialState)
    toTop()
    setImage("")
    setState(!state)
  }

  const handleFileUpload = e => {
    const selectedFile = e.target.files[0]

    const allowedTypes = ["image/jpeg", "image/png", "image/webp"]

    if (!allowedTypes.includes(selectedFile.type)) {
      setImage("")
      toastr.error("Please select a valid file type (JPEG, PNG , webp)")
      return
    }
    const fd = new FormData()
    fd.append("image", e.target.files[0])
    axios.post(`${API_URL}testimonial/image`, fd).then(response => {
      if (response.data.status === 200) {
        setImage(response.data.data.new_filename)
        handleValueChange(response.data.data.new_filename, "image")
      } else {
        toastr.error(response.data.message)
        setMasterObject(pre => ({
          ...pre,
          image: null,
        }))
      }
    })
  }
  const datas = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        width: 300,
      },
      {
        label: "Name",
        field: "name",
        width: 300,
      },
      {
        label: "Designation",
        field: "designation",
        width: 150,
      },
      {
        label: "Company",
        field: "companyName",
        width: 150,
      },
      {
        label: "Testimonial",
        field: "testimonial",
        width: 150,
      },
      {
        label: "Image",
        field: "imageData",
        width: 150,
      },

      {
        label: "Action",
        field: "options",
        sort: "desc",
        width: 150,
      },
    ],
    rows: tableData,
  }
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem="Testimonials" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm ref={formRef} onValidSubmit={handleSubmit}>
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Name</Label>
                          <AvField
                            name="name"
                            classNamePrefix="select2-selection"
                            placeholder="Name"
                            validate={{ required: { value: true } }}
                            errorMessage="This field is required"
                            value={masterObject.name}
                            onChange={e => {
                              handleValueChange(e.target.value, e.target.name)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Designation</Label>
                          <AvField
                            name="designation"
                            className="form-control"
                            type="text"
                            placeholder="Designation"
                            errorMessage="This field is required"
                            validate={{ required: { value: true } }}
                            value={masterObject.designation}
                            onChange={e => {
                              handleValueChange(e.target.value, e.target.name)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Company</Label>
                          <AvField
                            name="companyName"
                            className="form-control"
                            type="text"
                            placeholder="Company name"
                            errorMessage="This field is required"
                            validate={{ required: { value: true } }}
                            value={masterObject.companyName}
                            onChange={e => {
                              handleValueChange(e.target.value, e.target.name)
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Testimonial</Label>
                          <AvField
                            name="testimonial"
                            className="form-control"
                            type="textarea"
                            placeholder="Testimonial"
                            errorMessage="Enter Testimonials"
                            rows="1"
                            validate={{ required: { value: true } }}
                            value={masterObject.testimonial}
                            onChange={e => {
                              handleValueChange(e.target.value, e.target.name)
                            }}
                          />
                        </div>
                      </Col>

                      <Col md={masterObject.image ? "2" : "3"}>
                        <div className="mb-3">
                          <Label>Image (80x80)</Label>
                          {masterObject?.image ? (
                            <div div className="img-wraps d-flex flex-column">
                              <img
                                alt=""
                                width="150"
                                height="75"
                                src={`${API_URL}${masterObject.image}`}
                              />
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={() => {
                                  setImage("")
                                  setMasterObject(pre => ({
                                    ...pre,
                                    image: null,
                                  }))
                                }}
                                style={{ width: "150px" }}
                                type="button"
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <input
                              name="image"
                              type="file"
                              accept="image/*"
                              className="form-control"
                              id="validationCustom03"
                              onChange={handleFileUpload}
                              value={Image}
                            />
                          )}
                        </div>
                      </Col>
                      <Col md="3" style={{ marginTop: "26px" }}>
                        <Button
                          color={masterObject.id ? "warning" : "primary"}
                          className="me-2"
                          type="submit"
                        >
                          {masterObject.id ? "Update" : "Submit"}
                        </Button>
                        <Button
                          color="danger"
                          type="button"
                          onClick={reset}
                          className="me-2"
                        >
                          Reset
                        </Button>
                        <Button onClick={handleExportData} color="warning">
                          Export
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <MDBDataTable
                      id="testimonialTableId"
                      bordered
                      searching={true}
                      entries={20}
                      disableRetreatAfterSorting={true}
                      data={datas}
                    />
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default Testimonials
