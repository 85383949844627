import React, { useEffect, useState } from "react"
import { Row, Col, Button } from "reactstrap"

import { MDBDataTable } from "mdbreact"
import { useLocation } from "react-router-dom"
import toastr from "toastr"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { get, put } from "../../../../helpers/api_helper"
import moment from "moment"
import Select from "react-select"
import "./style.scss"
function Members() {
  const { state } = useLocation()
  const [requestcommunit, setRequestcommunit] = useState([])
  useEffect(() => {
    handleListData()
  }, [])

  const statusOptions = [
    { label: "Pending", value: 0 },
    { label: "Approve", value: 1 },
    { label: "Rejected", value: 2 },
  ]
  const handleStatusChange = async (requestId, approvedStatus) => {
    if (approvedStatus !== 0) {
      put("/request-community/approve", { requestId, approvedStatus })
        .then(res => {
          handleListData()
          toastr.success(res.message)
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    }
  }
  function handleListData() {
    get(`/request-community/requestedList/?communityId=${state?.id}`).then(res => {
      let result = res?.data
      result?.map((item, index) => {
        item.id = index + 1
        item.date = moment(item.date).format("DD-MM-YYYY")
        item.member =
        (item?.requestedStudent?.firstName ?? '') +
        " " +
        (item?.requestedStudent?.lastName ?? '');
        item.requestedStudentid = item?.requestedStudent?.unique_id
        item.event = item?.requestedStudent?.event

        if (item.approvedStatus === 1) {
          item.statusOption = (
            <Button size="sm" color="success" disabled>
              Approved
            </Button>
          )
        } else if (item.approvedStatus === 2) {
          item.statusOption = (
            <Button size="sm" color="danger" disabled>
              Rejected
            </Button>
          )
        } else {
          item.statusOption = (
            <Select
              options={statusOptions}
              value={statusOptions.filter(
                status => status.value === item.approvedStatus
              )}
              onChange={selected =>
                handleStatusChange(item._id, selected.value)
              }
              menuPosition="fixed"
            ></Select>
          )
        }
      })
      setRequestcommunit(result)
   
    })
  }
  const shortlisted = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "ID",
        field: "requestedStudentid",
        width: 150,
      },

      {
        label: "Member",
        field: "member",
        width: 150,
      },

      // {
      //   label: "Event",
      //   field: "event",
      //   width: 150,
      // },
      
      // {
      //   label: "Joined date",
      //   field: "date",
      //   width: 150,
      // },
      // {
      //   label: "Approved by",
      //   field: "admin",
      //   width: 150,
      // },

      // {
      //   label: "Status",
      //   field: "status",
      //   width: 150,
      // },
      {
        label: "Actions",
        field: "statusOption",
        sort: "desc",
        width: 400,
      },
    ],
    rows: requestcommunit,
  }
  return (
    <div>
      {" "}
      <Row>
        <Col className="col-12">
          <MDBDataTable
            id="communitylistrequestID"
            data={shortlisted}
            responsive
            bordered
            searching={true}
            info={false}
            disableRetreatAfterSorting={true}
          />
        </Col>
      </Row>
    </div>
  )
}

export default Members
