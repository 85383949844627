import { MDBDataTable } from "mdbreact"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Badge, Col, Row } from "reactstrap"
import { get } from "../../../../helpers/api_helper"
import moment from "moment"
import "./style.scss"
const EventHistory = ({ id }) => {
  const navigate = useNavigate()
  const [tableData, setTableData] = useState([])

  const jobPosts = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Id",
        field: "uniqueId",
        sort: "asc",
        width: 400,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Location",
        field: "location",
        sort: "asc",
        width: 400,
      },
      {
        label: "Attended",
        field: "status1",
        sort: "asc",
        width: 400,
      },

      {
        label: "Action",
        field: "options",
        sort: "asc",
        width: 200,
      },
    ],
    rows: tableData,
  }
  
  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [id])

  
  // const handleTableData = async () => {
  //   await get(`/student/events?id=${id}`).then(res => {
  //     const result = res.data
  //     result.map((item, index) => {
  //       item.id = index + 1
  //       item.date = moment(item.date).format("DD-MM-YYYY")
  //       item.status1 = (
  //        <div>
  //         <span>
  //           {item?.attendance}&#47;{item?.eventBooking}
  //         </span>
  //        </div>
  //       )
  //       item.options = (
  //         <div style={{ display: "flex", justifyContent: "center" }}>
  //           <i
  //             className="fas fa-eye"
  //             style={{ cursor: "pointer", fontSize: "1em" }}
  //             onClick={() => {
  //               navigate(`/creation/${item.uniqueId}`, {
  //                 state: { _id: item.eventId },
  //               })
  //             }}
  //           ></i>
  //         </div>
  //       )
  //       return item
  //     })
  //     setTableData(result)
  //   })
  // }

  const handleTableData = async () => {
    await get(`/student/events?id=${id}`).then(res => {
      const result = res.data
      result.map((item, index) => {
        item.id = index + 1
        item.date = moment(item.date).format("DD-MM-YYYY")
        item.status1 = (
         <div>
          <span>
            {item?.attendance}&#47;{item?.eventBooking}
          </span>
         </div>
        )
        item.options = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              style={{ cursor: "pointer", fontSize: "1em" }}
              onClick={() => {
                navigate(`/creation/${item.uniqueId}`, {
                  state: { _id: item.eventId },
                })
              }}
            ></i>
          </div>
        )
        return item
      })
      setTableData(result)
    })
  }



  return (
    <>
      <Row>
        <Col className="col-12">  
          <MDBDataTable
            id="EventHistoryID"
            className="event-history"
            data={jobPosts}
            responsive
            bordered
            searching={true}
            info={false}
            disableRetreatAfterSorting={true}
          />
        </Col>
      </Row>
    </>
  )
}
export default EventHistory
