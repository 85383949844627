import React, { useEffect, useState } from "react"
import { Row, Col, BreadcrumbItem } from "reactstrap"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import { Box, Divider, Tooltip } from "@mui/material"
import PersonIcon from "@mui/icons-material/Person"
import { Link } from "react-router-dom"
import JobApplied from "./Tabs/JobApplied"
import EnrolledCourse from "./Tabs/EnrolledCourse"
// import Fee from "./Tabs/Fee"
import Statement from "./Tabs/Statement"
import Attendance from "./Tabs/Attendance"
import EventHistory from "./Tabs/EventHistory"
import MarkAssessment from "./Tabs/MarkAssessment"
import Certification from "./Tabs/Certifications"
import RuleIcon from "@mui/icons-material/Rule"
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee"
import EventIcon from "@mui/icons-material/Event"
import WorkIcon from "@mui/icons-material/Work"
import AssessmentIcon from "@mui/icons-material/Assessment"
import { get, post } from "../../../helpers/api_helper"
import { useLocation } from "react-router-dom"
import moment from "moment"
import toastr from "toastr"
import Avatar from "@mui/material/Avatar"
import Stack from "@mui/material/Stack"
import Profile from "./Tabs/Profile"
import "./StudentDashboard.scss"
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium"
import { isAllowed } from "../../../components/Common/Allowed"
import { PRIVILEGES } from "../../../config"
// import AccountBalanceIcon from "@mui/icons-material/AccountBalance"
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/"
const BatchCreation = props => {
  // eslint-disable-next-line
  const { state } = useLocation()
  const [studentDetail, setStudentDetail] = useState(false)
  // const { studentid } = useParams()
  //   const handleAllCheckboxChange = e => {
  //     const checked = e.target.checked

  //     setIsCheckbox(checked)
  //     setIsCheckbox1(checked)
  //     setIsCheckbox2(checked)
  //     setIsCheckbox3(checked)
  //     setIsCheckbox4(checked)
  //   }

  // eslint-disable-next-line
  const [showAdditionalFields, setShowAdditionalFields] = useState({
    show: false,
  })

  // eslint-disable-next-line
  const [selectedRows, setSelectedRows] = useState([])

  // function handleChange(e, index) {
  //   const { value, name } = e.target
  //   const data = [...qualityFrame]
  //   data[index][name] = value
  //   setQualityFrame(data)
  // }
  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [state?.id])
  const [tab3, setTab3] = React.useState("8")

  const handleChangeTab = (event, newValue) => {
    setTab3(newValue)
  }

  function handleTableData() {
    get(`student/${state?.id}`)
      .then(res => {
        const result = res?.data
        setStudentDetail(result)
        console.log(result)
      })
      .catch(error => {
        console.error("Error fetching data:", error)
      })
  }

  const handleOtherAmount = () => {
    post(`student/other-amount/${studentDetail._id}`)
      .then(res => {
        toastr.success(res.message)
      })
      .catch(err => {
        console.log(err?.response?.data)
      })
  }

  const formatAadhaarNumber = number => {
    return number.replace(/(.{4})/g, "$1 ")
  }

  const toggle = (start = true) => {
    var body = document.body

    if (start) {
      body.classList.remove("vertical-collpsed")
      body.classList.remove("sidebar-enable")
      body.setAttribute("data-sidebar-size", "sm")
    } else {
      body.classList.add("vertical-collpsed")
      body.classList.add("sidebar-enable")
      body.setAttribute("data-sidebar-size", "lg")
    }
  }

  useEffect(() => {
    toggle()

    return () => {
      toggle(false)
    }
    // eslint-disable-next-line
  }, [])
  const isTrainer = isAllowed(PRIVILEGES.TRAINER)
  console.log('check', isTrainer)

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">
                  {studentDetail?.firstname || ""}{" "}
                  {studentDetail?.lastname && studentDetail.lastname}
                </h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      <Link to="/student-dashboard">Home</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="/student-details">Students</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="#">
                        {studentDetail?.firstname || ""}{" "}
                        {studentDetail?.lastname && studentDetail.lastname}
                      </Link>
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              {/* First Dialogue */}

              <div>
                <div className="row">
                  <div className="col-xl-3">
                    <div
                      className="card"
                      style={{
                        // width: "fit-content",
                        // width: "351px",
                        height: "max-content",
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                      }}
                    >
                      <div className="card-body">
                        <Stack
                          direction="row"
                          spacing={2}
                          style={{ justifyContent: "center" }}
                        >
                          <Avatar
                            alt={studentDetail?.firstname}
                            src={`${API_URL}${studentDetail.assets?.profile}`}
                            sx={{ width: 56, height: 56 }}
                            style={{
                              width: "6rem",
                              height: "6rem",
                              marginBottom: "auto",
                            }}
                          />
                        </Stack>

                        <div className="float-end dropdown"></div>
                        <h5
                          className="mt-2 mb-2"
                          style={{
                            textAlign: "center",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}
                        >
                          {studentDetail?.firstname || ""}{" "}
                          {studentDetail?.lastname && studentDetail.lastname}
                        </h5>
                        <p
                          style={{
                            textAlign: "center",
                            marginBottom: "3px",
                          }}
                        ></p>
                        <h6
                          className="mt-2 mb-2"
                          style={{
                            textAlign: "center",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}
                        >
                          {studentDetail.email}
                        </h6>
                        <h6
                          className="mt-2 mb-2"
                          style={{
                            textAlign: "center",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}
                        >
                          {studentDetail?.mobile}
                        </h6>
                        <Divider />
                        <div className="row">
                          <div className="col-xl-12 col-lg-4 col-sm-6 col-xs-12">
                            <div className="card-row ">
                              <span style={{ marginRight: "5px" }}>
                                <strong>Student Id</strong>
                              </span>
                              <span>{studentDetail.unique_id}</span>
                            </div>
                            <Divider />
                            <div className="card-row">
                              <span style={{ marginRight: "5px" }}>
                                <strong>Aadhaar No.</strong>
                              </span>
                              <span style={{ textAlign: "right" }}>
                                {studentDetail?.aadharNo
                                  ? formatAadhaarNumber(studentDetail.aadharNo)
                                  : ""}
                              </span>
                            </div>
                            <Divider />
                            <div className="card-row">
                              <span>
                                <strong>Gender</strong>
                              </span>
                              <span>
                                {studentDetail?.gender === 1
                                  ? "Male"
                                  : studentDetail?.gender === 0
                                    ? "Female"
                                    : studentDetail?.gender === 2
                                      ? "Non-Binary"
                                      : ""}
                                {/* {moment(studentDetail?.dob).format('MMMM Do YYYY')} */}
                              </span>
                            </div>
                            <Divider />
                            <div className="card-row">
                              <span>
                                <strong>DOB</strong>
                              </span>
                              <span>
                                {studentDetail?.dob &&
                                  moment(
                                    studentDetail.dob,
                                    "YYYY-MM-DD",
                                    true
                                  ).isValid()
                                  ? moment(studentDetail.dob).format(
                                    "DD-MM-YYYY"
                                  )
                                  : ""}
                              </span>
                            </div>
                            <Divider />
                          </div>
                          <div className="col-xl-12 col-lg-4 col-sm-6 col-xs-12">
                            <div className="card-row">
                              <span style={{ marginRight: "5px" }}>
                                <strong>Address</strong>
                              </span>
                              <span style={{ textAlign: "right" }}>
                                {studentDetail.address}
                              </span>
                            </div>
                            <Divider />
                            <div className="card-row">
                              <span>
                                <strong>Pincode</strong>
                              </span>
                              <span>{studentDetail.pin}</span>
                            </div>

                            <Divider />
                            <div className="card-row">
                              <span>
                                <strong>CSP</strong>
                              </span>
                              <span>{studentDetail?.branch?.name}</span>
                            </div>
                            <Divider />
                            <div className="card-row">
                              <span>
                                <strong>State</strong>
                              </span>
                              <span>
                                {studentDetail?.district?.state_id?.name}
                              </span>
                            </div>
                            <Divider />
                          </div>
                          <div className="col-xl-12 col-lg-4 col-sm-6 col-xs-12">
                            <div className="card-row">
                              <span>
                                <strong>District</strong>
                              </span>
                              <span>
                                {studentDetail?.district?.district_name}
                              </span>
                            </div>
                            <Divider />
                            <div className="card-row">
                              <span>
                                <strong>City</strong>
                              </span>
                              <span>{studentDetail?.city}</span>
                            </div>
                            <Divider />
                            <div className="card-row">
                              <span>
                                <strong>Joined Date</strong>
                              </span>
                              <span>
                                {moment(studentDetail?.date).format(
                                  "DD-MM-YYYY"
                                )}
                              </span>
                            </div>
                            <Divider />
                            <div className="card-row">
                              <span>
                                <strong>Joined Time</strong>
                              </span>
                              <span>
                                {/* {studentDetail?.upTime} */}
                                {moment(studentDetail.time, "HH:mm:ss").format(
                                  "hh:mm a"
                                )}
                              </span>
                            </div>
                            <Divider />
                          </div>
                          <div className="col-xl-12 col-lg-4 col-sm-6 col-xs-12">
                            <div className="card-row">
                              <span>
                                <strong>App Version</strong>
                              </span>
                              <span>
                                {studentDetail?.appVersion}
                                {/* {studentDetail?.upTime} */}
                                {/* {moment(studentDetail.upTime, "HH:mm:ss").format("hh:mm a")} */}
                              </span>
                            </div>
                            <Divider />
                          </div>
                        </div>

                        <Col
                          md={12}
                          className="mt-4 pt-2 d-flex justify-content-end"
                        >
                          <div className="form-check form-check-right">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="webShow"
                              id="defaultCheck2"
                              onClick={handleOtherAmount}
                              defaultChecked={
                                studentDetail?.customAmountEnabled
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="defaultCheck2"
                            >
                              Other Amount
                            </label>
                          </div>
                        </Col>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-9" style={{ marginBottom: "30px" }}>
                    <div
                      className="mb-0 card"
                      style={{
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={tab3}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              variant="scrollable"
                              scrollButtons="auto"
                              indicatorColor="primary"
                              textColor="primary"
                              // variant="fullWidth"
                              onChange={handleChangeTab}
                              aria-label="lab API tabs example"
                            >
                              <Tab
                                icon={<PersonIcon style={{ fontSize: "" }} />}
                                label="Profile"
                                value="8"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                  flexGrow: 1
                                }}
                              />
                              <Tab
                                icon={<PersonIcon style={{ fontSize: "" }} />}
                                label="Courses"
                                value="1"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                  flexGrow: 1
                                }}
                              />
                              <Tab
                                icon={<RuleIcon style={{ fontSize: "20px" }} />}
                                label="Attendance"
                                value="2"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                  flexGrow: 1
                                }}
                              />
                              {isTrainer ? "" : <Tab
                                icon={
                                  <CurrencyRupeeIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Fees"
                                value="3"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                  flexGrow: 1
                                }}
                              />}
                              {isTrainer ? "" : <Tab
                                icon={
                                  <EventIcon style={{ fontSize: "20px" }} />
                                }
                                label="Events"
                                value="4"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                  flexGrow: 1
                                }}
                              />}
                              {isTrainer ? "" :  <Tab
                                    icon={<WorkIcon style={{ fontSize: "20px" }} />}
                                    label="Job/Internship"
                                    value="5"
                                    style={{
                                      textTransform: "capitalize",
                                      fontFamily: "IBM Plex Sans,sans-serif",
                                      flexGrow: 1
                                    }}
                                  />}
                            
                              <Tab
                                icon={
                                  <AssessmentIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Assessments"
                                value="6"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                  flexGrow: 1
                                }}
                              />
                              <Tab
                                icon={
                                  <WorkspacePremiumIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Certifications"
                                value="7"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                  flexGrow: 1
                                }}
                              />
                            </TabList>
                          </Box>

                          <TabPanel
                            value="1"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <EnrolledCourse student={studentDetail} />
                          </TabPanel>
                          <TabPanel
                            value="2"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Attendance id={state.id} />
                          </TabPanel>
                          <TabPanel
                            value="3"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Statement id={state.id} />
                          </TabPanel>
                          <TabPanel
                            value="4"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <EventHistory id={state.id} />
                          </TabPanel>
                          <TabPanel
                            value="5"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <JobApplied id={state.id} />
                          </TabPanel>
                          <TabPanel
                            value="6"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <MarkAssessment id={state.id} />
                          </TabPanel>
                          <TabPanel
                            value="7"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Certification id={state.id} />
                          </TabPanel>
                          <TabPanel
                            value="8"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Profile id={state.id} />
                          </TabPanel>
                        </TabContext>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default BatchCreation
