import React, { useContext, useState, useEffect, useRef } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Row, Col, Button, Label, Card, CardBody } from "reactstrap"
import toastr from "toastr"
import Select from "react-select"
import { CourseContext } from ".."
import { put } from "../../../../helpers/api_helper"
import SunEditor from "suneditor-react"
import plugins from "suneditor/src/plugins"
import { en } from "suneditor/src/lang"
import { EditorView } from "codemirror"
import katex from "katex"
import "suneditor/dist/css/suneditor.min.css"
import "katex/dist/katex.min.css";
const SyllabusDetails = props => {
  const options = {
    plugins: plugins,
    height: 250,
    EditorView: {
      src: EditorView,
      options: {
        indentWithTabs: true,
        tabSize: 2,
      },
    },
    katex: katex,
    lang: en,
    buttonList: [
      [
        "font",
        "fontSize",
        "formatBlock",
        "bold",
        "underline",
        "italic",
        "paragraphStyle",
        "blockquote",
        "strike",
        "subscript",
        "superscript",
        "fontColor",
        "hiliteColor",
        "textStyle",
        "removeFormat",
        "undo",
        "redo",
        "outdent",
        "indent",
        "align",
        "horizontalRule",
        "list",
        "lineHeight",
        "table",
        "link",
        // 'image',
        // 'video',
        // 'audio',
        // You must add the 'katex' library at options to use the 'math' plugin.
        // 'math',
        // You must add the "imageGalleryUrl".
        // 'imageGallery',
        "fullScreen",
        "showBlocks",
        "codeView",
        "preview",
        // 'print'
        // 'save',
        // 'template'
      ],
    ],
  }

  const formRef = useRef()
  const main = useContext(CourseContext)
  const [masterobj, setmasterobj] = useState({ details: [{}] })
  const [isDisabled, setisDisabled] = useState(false)

  useEffect(() => {
    if (Object.keys(props.data).length&&main?.courseID?.objID) { 
      if (props.data?.details && props.data?.details?.length)
      setisDisabled(false) 
        hanldeOptionsData(props.data)
      setmasterobj(prevMaster => ({ ...prevMaster, ...props?.data }))
    }
  }, [props.data])                                                            

  const fieldTypeOptions = [
    { label: "Key Topics", value: "1" },
    { label: "Eligibility", value: "2" },
    // { label: "Course Fee", value: "3" },
    { label: "Scholarship", value: "4" },
    { label: "Skill Covered", value: "5" },
    { label: "Placement Oppurtunity", value: "6" },
    { label: "Training Centre", value: "7" },
    // { label: "Skill Loan", value: "9" },
  ]


  
  const [selectOptions, setSelectOptions] = useState([...fieldTypeOptions])
  const hanldeOptionsData = master => {
    let options = [...fieldTypeOptions]

    master.details?.map(item => {
      if (item.tab !== "1") {
        options = options.filter(option => {
          return option.value !== "" + item.tab
        })
      }
      return item
    })
    setSelectOptions(options)
  }

  const handlePreValueChange = (value, name, index) => {
    const data = { ...masterobj }
    const pre = data?.details
    pre[index][name] = value
    data.details = pre
    setmasterobj(data)
    hanldeOptionsData(data)
  }

  function handleSubmit(e) {
    e.preventDefault()
    let data = { ...masterobj } 
    data._id = main?.courseID?.objID
    setisDisabled(true) 
    put(`/course/details`, data)
      .then(res => {
        main.setCourseID(pre => ({ objID: res.data._id }))
        toastr.success(res.message)
        main.setactiveTabJustify("4")
        // setmasterobj({ details: [{}] })
        // formRef.current.reset()
        setisDisabled(false)
      })
      .catch(err => {
        toastr.error(err.response.data.message)
        setmasterobj({ details: [{}] })
        setisDisabled(false)
      })
  }

  const onChangeSuneditor = (content, idx) => {
    setmasterobj(prevState => {
      const updatedMasterobj = { ...prevState }
      const updatedDetails = [...updatedMasterobj.details]
      updatedDetails[idx] = { ...updatedDetails[idx], contents: content }
      updatedMasterobj.details = updatedDetails
      return updatedMasterobj
    })
  }


  function resetAll(){
    setmasterobj({ details: [{}] })
    setSelectOptions(fieldTypeOptions)
    main.setCourseID({})

  }

  return (
    <AvForm ref={formRef} onValidSubmit={handleSubmit}>
      <Row>
        {masterobj?.details?.map((item, idx) => (
          <Card>
            <CardBody>
              <Row>
                <Col md="3">
                  <div className="mb-3">
                    <Label>Tab name</Label>
                    <Select
                      className="text-editor-select"
                      name="tab"
                      classNamePrefix="select2-selection "
                      options={selectOptions}
                      value={fieldTypeOptions.filter(
                        tab => tab.value === item.tab
                      )}
                      onChange={selected =>
                        handlePreValueChange(selected.value, "tab", idx)
                      }
                    />
                  </div>
                </Col>
                <Col md="5">
                  <div className="mb-3">
                    <Label htmlFor="validationCustom03">
                      Topic Description
                    </Label>
                    <AvField
                      name="tabDescription"
                      type="textarea"
                      rows="1"
                      className="form-control"
                      placeholder="Topic Description"
                      value={item.tabDescription || ""}
                      onChange={e =>
                        handlePreValueChange(
                          e.target.value,
                          "tabDescription",
                          idx
                        )
                      }
                    />
                  </div>
                </Col>

                <Col md="12">
                  <SunEditor
                    setDefaultStyle="font-family: Arial; font-size: 14px;"
                    lang="en"
                    setOptions={options}
                    onChange={content => onChangeSuneditor(content, idx)}
                    setContents={item.contents || null}
                  />
                </Col>
                {masterobj.details.length > 1 && (
                  <div className="d-flex justify-content-end">
                    <span
                      className="mr-1 p-2"
                      style={{ fontSize: "1.125rem", cursor: "pointer" }}
                      onClick={() => {
                        const data = { ...masterobj }
                        data.details.splice(idx, 1)
                        hanldeOptionsData(data)
                        setmasterobj(data)
                      }}
                    >
                     <i className="trash-btn mdi mdi-delete"></i>
                    </span>
                  </div>
                )}
              </Row>
            </CardBody>
          </Card>
        ))}

        <Col md={12}>
          <div className="d-flex justify-content-between align-items-center">
            <button
              onClick={e => {
                e.preventDefault()
                const data = { ...masterobj }
                data.details.push({})
                setmasterobj(data)
              }}
              className="waves-effect btn btn-outline-light d-flex"
              style={{ gap: "5px" }}
            >
              Add more <i className="mdi mdi-plus-circle-outline"></i>
            </button>

            <div style={{ marginRight: "1rem" }}>
              <Button disabled={isDisabled?true:false} color="primary" type="submit">
                Save & Next
              </Button>

              <Button onClick={resetAll}  className="mx-2" color="danger" type="reset">
                Reset
              </Button>
            </div>
          </div>
        </Col>

        {/* <Col>

      </Col> */}
      </Row>
    </AvForm>
  )
}

export default SyllabusDetails
