import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle, Collapse } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap"
import image1 from "../../../../../assets/images/PRP-190-2022-04-21-PRASANTH-1-scaled.jpg"
import { get } from "../../../../../helpers/api_helper"
import { API_URL } from "../../../../../config"
import { Link } from "react-router-dom"
function Gallery(props) {
  const galleryId = props.data
  const [popupGallery, setPopupGallery] = useState(false)
  const [col1, setcol1] = useState(true);
  const [col2, setcol2] = useState(false);
  const [galleryData, setgalleryData] = useState([])

  useEffect(() => {
    fetchallgallery()
  }, [props])



  function fetchallgallery() {
    get(`/events-gallery/listEvent/${galleryId}`).then((res) => {
      // console.log("gallerydata", res);
      setgalleryData(res.data)
    })
  }


  return (
    <div>
      <Row>

        <Col xl={12}>
          <Card>
            <CardBody>
              <CardTitle className="h4">Event Images And Youtube URLs</CardTitle>
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button"
                      onClick={() => {
                        setcol1(!col1)
                      }}
                      style={{ cursor: "pointer" }}>
                      <strong>Event Images</strong>
                    </button>
                  </h2>
                  <Collapse id="collapseTwo" className="accordion-collapse" isOpen={col1}>
                    <div className="accordion-body">
                      <Row>
                        {
                          galleryData?.allImages?.map((el) => (
                            <>
                              <Col md="4">
                                <div>
                                  <img src={`${API_URL}${el?.image}`} alt="" width="100%" />
                                  <p style={{ textAlign: "center" }} >{el.comments}</p>
                                </div>

                              </Col>
                            </>
                          ))
                        }
                      </Row>
                    </div>
                  </Collapse>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button"
                      onClick={() => {
                        setcol2(!col2)
                      }}
                      style={{ cursor: "pointer" }}>
                      <strong>Youtube URLs</strong>
                    </button>
                  </h2>
                  <Collapse id="collapseTwo" className="accordion-collapse" isOpen={col2}>
                    <div className="accordion-body">
                      <ol>
                        {
                          galleryData && galleryData?.allYouTubeURLs?.map((item, index) => (
                            <li>  
                               <a target="_blank" href={item} className="mt-2" >{item}</a>
                            </li>
                          ))
                        }
                      </ol>
                    </div>
                  </Collapse>
                </div>



              </div>
            </CardBody>
          </Card>
        </Col>

      </Row>
    </div>
  )
}

export default Gallery
