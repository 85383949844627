import React, { useEffect,  useState } from "react"
import { Row, Col, Card, CardBody,Spinner } from "reactstrap"
import { Tooltip as TooltipMui} from "@mui/material"
import moment from "moment"
import toastr from "toastr"
import { useLocation, useNavigate } from "react-router-dom"
import { get } from "../../../../helpers/api_helper"
import { dateConverter, jsonToExcel, timeConverter } from "../../../../helpers/functions"
function Batchview() {
  const navigate = useNavigate()
  // const [count, setCount] = useState(0)
  // const [pageCount, setPageCount] = useState(0)
  const [isLoading,setIsLoading] = useState(false)
  const [search, setSearch] = useState("")


  useEffect(() => {
    FetchEventData()
  }, [])

  const location = useLocation();
  const { eventStatus,startDate,endDate } = location.state || {};


  const [Events, setEvents] = useState([])
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/"

  function FetchEventData(searchQuery = "") {
    get(`/events/list?keyword=${searchQuery}&eventStatus=${eventStatus}&startDate=${startDate}&endDate=${endDate}`).then(res => {
      let result = res?.data
      setEvents(result)
    })
  }

  const handleExportData = async () => {
    try {
      setIsLoading(true)
      let res = await get(
             `/events/list?keyword=${search}&eventStatus=${eventStatus}&startDate=${startDate}&endDate=${endDate}`
            )
      const data = res?.data
      const exportData = []
      if (data && Array.isArray(data) && data.length > 0) {
        data.map((item, index) => {
          const dataExport = {}
          dataExport["SI NO"] = index + 1
          dataExport.Name = item?.name
          dataExport.Category = item?.category?.label
          dataExport.Fee = item?.fee
          dataExport['Start Date'] = dateConverter(item?.startDate)
          dataExport['Start Time'] = timeConverter(item?.startTime)
          dataExport['End Date'] = dateConverter(item?.endDate)
          dataExport['End Time'] = timeConverter(item?.endTime)
          dataExport.Venue = item?.venue
          dataExport.Registration = item?.eventRegistrationCount || 0
          exportData.push(dataExport)
        })
        jsonToExcel(exportData, `Events-list-${moment().format("DD-MM-YY")}`)
        setIsLoading(false)
      } else {
        setIsLoading(false)
        toastr.info(`There are No data to export`)
      }
    } catch (error) {
      setIsLoading(false)
      toastr.info('Failed to export data. Please try again.');
    }
  }



  function handleSearch(e) {
    const searchQuery = e.target.value
    setSearch(e.target.value)
    FetchEventData(searchQuery)
  }



  return (
    <React.Fragment>


      <div className="page-content">
        <div className="container-fluid">
        <Col md='12'>
          <div className="p-2 pt-0 d-flex justify-content-end me-1 absolute top-10 ">
                     {isLoading ? 
                    <Spinner
                    color="dark"
                    size="sm"
                    style={{marginTop:"9px", marginRight:"9px"}}
                  >
                    Loading...
                  </Spinner> : 
                     <TooltipMui
                     title={"Export"}
                     arrow
                     placement="top"
                     id="button-tooltip-2"
                   >
                     <i
                       onClick={handleExportData}
                       className="cursor-pointer fs-4 fas fa-file-export"
                       style={{marginRight:"6px",marginTop:"6px"}}
                     ></i>
                   </TooltipMui>
                    }
                     
                      
              <div className="form-inline float-md-end mb-3">
                <div className="search-box ml-2">
                  <div className="position-relative">
                  <input
                      type="text"
                      onChange={handleSearch}
                      className="form-control rounded border-0"
                      placeholder="Search Event..."
                    />
                    <i className="mdi mdi-magnify search-icon"></i>
                  </div>
                </div>
              </div>
         </div>
 
          </Col>

          <Row className="mb-2">
            {Events?.map(item => (
              <Col md="6" xl="6">
                <Card>
                  <CardBody
                    onClick={() => {
                      navigate(`/creation/${item.uniqueId}`, {
                        state: { _id: item._id },
                      })
                    }}
                    le={{ cursor: "pointer" }}
                  >
                    <div>
                      <div>
                        <h5 class="font-size-16 mb-1">
                          <a class="text-dark" href="/students">
                            {item?.name}
                            <span
                              style={{ marginLeft: "10px" }}
                              className={`badge font-size-12 ${item.eventStatus === 0
                                  ? "bg-soft-warning"
                                  : item.eventStatus === 1
                                    ? "bg-soft-danger"
                                    : item.eventStatus === 2
                                      ? "bg-soft-success"

                                      : ""
                                }`}
                            >
                              {item.eventStatus === 0
                                ? "Upcoming"
                                : item.eventStatus === 1
                                  ? "Completed"
                                  : item.eventStatus === 2
                                    ? "Ongoing"
                                    : ""}
                            </span>
                          </a>
                        </h5>
                      </div>

                      <div className="d-flex justify-content-between">
                        <div style={{ paddingTop: "10px" }}>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <p style={{ fontSize: "14px" }} className="mb-0">
                              Category:{" "}
                              <span style={{ fontWeight: "bold" }}>
                                {item?.category?.label}
                              </span>
                            </p>
                          </div>
                          <p style={{ fontSize: "14px" }} className="mb-0">
                            Fee:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              Rs.{item?.fee}
                            </span>
                          </p>
                          <p style={{ fontSize: "14px" }} className="mb-0">
                            Date & Time:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {moment(item.startDate).format("DD-MM-YYYY")},
                              {item.startTime ? moment(item.startTime, "HH:mm:ss").format(
                                "hh:mmA"
                              ) : ""}
                            </span>
                          </p>
                          <div>
                            <p style={{ fontSize: "14px" }} className="mb-0">
                              {" "}
                              Venue :{" "}
                              <span style={{ fontWeight: "bold" }}>
                                {item.venue}
                              </span>
                            </p>
                          </div>
                          <div className="d-flex gap-3">
                            <div>
                              {" "}
                              <p style={{ fontSize: "14px" }} className="mb-0">
                                {" "}
                                Registrations:{" "}
                                <span className="badge bg-success font-size-12 ms-2">
                                  {item?.eventRegistrationCount}
                                </span>
                              </p>
                            </div>
                            {/* {moment(item.startDate).isSameOrBefore(
                              moment(),
                              "day"
                            ) && (
                                <div>
                                  <div>
                                    <p
                                      style={{ fontSize: "14px" }}
                                      className="mb-0"
                                    >
                                      Attended :{" "}
                                      <span className="badge bg-warning font-size-12 ms-2">
                                        {item?.attendedCount}
                                      </span>
                                    </p>
                                  </div> */}

                                {/* </div> */}
                              {/* )} */}
                          </div>
                        </div>
                        <div>
                        </div>
                        <div>
                          <img
                            style={{ width: "8rem", height: "7.5rem" }}
                            src={API_URL + item?.uploadedFile}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div style={{ width: "100px" }}>
                      <div
                        style={{
                          textAlign: "end",
                          position: "absolute",
                          top: "5px",
                          right: "7px",
                        }}
                        className="pt-1 "
                      >
                        {" "}
                        <span className="badge bg-success font-size-12 ms-2">
                          Active
                        </span>
                      </div>

                      <div id="pie-chart" className="e-chart">
                        
                        <div style={{ textAlign: "center" }}>Sessions</div>
                      </div>
                    </div> */}
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Batchview
