import { COMPANY_TYPE } from "../../config"
import * as action from "./actionTypes"

export const getPrivileges = data => {
  return {
    type: action.GET_PRIVILEGES,
    payload: data,
  }
}

export const getPrivilegesSuccessful = data => {
  return {
    type: action.GET_PRIVILEGES_SUCCESS,
    payload: data.privilage,
  }
}

export const getModules = data => {
  return {
    type: action.GET_MODULES,
    payload: data,
  }
}

export const getModulesSuccessful = data => {
  return {
    type: action.GET_MODULES_SUCCESS,
    payload: data.modules,
  }
}

export const getUsersOptions = data => {
  return {
    type: action.GET_USERS,
    payload: data,
  }
}

export const getUsersSuccessful = data => {
  return {
    type: action.GET_USERS_SUCCESS,
    payload: data.user,
  }
}

export const getStatesOptions = data => {
  return {
    type: action.GET_STATES,
    payload: data,
  }
}

export const getStatesSuccessful = data => {
  return {
    type: action.GET_STATES_SUCCESS,
    payload: data.states,
  }
}
export const getCountriesOptions = data => {
  return {
    type: action.GET_COUNTRIES,
    payload: data,
  }
}

export const getCountriesSuccessful = data => {
  return {
    type: action.GET_COUNTRIES_SUCCESS,
    payload: data.countries,
  }
}

export const getDistrictOptions = data => {
  return {
    type: action.GET_DISTRICTS,
    payload: data,
  }
}

export const getDistrictSuccessful = data => {
  return {
    type: action.GET_DISTRICTS_SUCCESS,
    payload: data.districts,
  }
}

export const getCompaniesOptions = data => {
  return {
    type: action.GET_COMPANIES,
    payload: data,
  }
}

export const getCompaniesSuccessful = data => {
  return {
    type: action.GET_COMPANIES_SUCCESS,
    payload: data.companies,
  }
}

export const getBranches = data => {
  return {
    type: action.GET_BRANCHES,
    payload: data,
  }
}

export const getBranchesSuccessful = data => {
  return {
    type: action.GET_BRANCHES_SUCCESS,
    payload: data.branches,
  }
}
export const getCategory = data => {
  return {
    type: action.GET_CATEGORY,
    payload: data,
  }
}

export const getCategorySuccessful = data => {
  return {
    type: action.GET_CATEGORY_SUCCESS,
    payload: data.categories,
  }
}

export const getAccountType = data => {
  return {
    type: action.GET_ACCOUNT_TYPE,
    payload: data,
  }
}

export const getAccountTypeSuccessful = data => {
  return {
    type: action.GET_ACCOUNT_TYPE_SUCCESS,
    payload: data.data,
  }
}

export const getAccountSubType = data => {
  return {
    type: action.GET_ACCOUNT_SUB_TYPE,
    payload: data,
  }
}

export const getAccountSubTypeSuccessful = data => {
  return {
    type: action.GET_ACCOUNT_SUB_TYPE_SUCCESS,
    payload: data.data,
  }
}

export const getChartAccount = data => {
  return {
    type: action.GET_CHART_ACCOUNT,
    payload: data,
  }
}

export const getChartAccountSuccessful = data => {
  return {
    type: action.GET_CHART_ACCOUNT_SUCCESS,
    payload: data.data,
  }
}

export const getOperatingPartner = data => {
  return {
    type: action.GET_OPERATING_PARTNER,
    payload: { type: COMPANY_TYPE.OPERATING_PARTNER },
  }
}

export const getOperatingPartnerSuccessful = data => {
  return {
    type: action.GET_OPERATING_PARTNER_SUCCESS,
    payload: data.companies,
  }
}

export const getTrainingPartner = data => {
  return {
    type: action.GET_TRAINING_PARKING,
    payload: { type: COMPANY_TYPE.TRAINING_PARTNER },
  }
}

export const getTrainingPartnerSuccessful = data => {
  return {
    type: action.GET_TRAINING_PARKING_SUCCESS,
    payload: data.companies,
  }
}

export const getCertificationPartner = data => {
  return {
    type: action.GET_CERTIFICATION_PARTNER,
    payload: { type: COMPANY_TYPE.CERTIFICATION_PARTNER },
  }
}

export const getCertificationPartnerSuccessful = data => {
  return {
    type: action.GET_CERTIFICATION_PARTNER_SUCCESS,
    payload: data.companies,
  }
}

export const getSectors = data => {
  return {
    type: action.GET_SECTOR,
    payload: data,
  }
}
export const getSectorsSuccess = data => {
  return {
    type: action.GET_SECTOR_SUCCESS,
    payload: data.data,
  }
}

export const getQualification = data => {
  return {
    type: action.GET_QUALIFICATION,
    payload: data,
  }
}
export const getQualificationSuccess = data => {
  return {
    type: action.GET_QUALIFICATION_SUCCESS,
    payload: data.data,
  }
}

export const getStream = data => {
  return {
    type: action.GET_STREAM,
    payload: data,
  }
}
export const getStreamSuccess = data => {
  return {
    type: action.GET_STREAM_SUCCESS,
    payload: data.data,
  }
}

export const getDegree = data => {
  return {
    type: action.GET_DEGREE,
    payload: data,
  }
}
export const getDegreeSuccess = data => {
  return {
    type: action.GET_DEGREE_SUCCESS,
    payload: data.data,
  }
}

export const getCourses = data => {
  return {
    type: action.GET_COURSES,
    payload: data,
  }
}
export const getCoursesSuccess = data => {
  return {
    type: action.GET_COURSES_SUCCESS,
    payload: data.data,
  }
}

export const getStudent = data => {
  return {
    type: action.GET_STUDENT,
    payload: data,
  }
}
export const getStudentSuccess = data => {
  return {
    type: action.GET_STUDENT_SUCCESS,
    payload: data.data,
  }
}

export const getSkillLoan = data => {
  return {
    type: action.GET_SKILL_LOANS,
    payload: data,
  }
}
export const getSkillLoanSuccess = data => {
  return {
    type: action.GET_SKILL_LOANS_SUCCESS,
    payload: data.data,
  }
}
export const getSkillLoanStatus = data => {
  return {
    type: action.GET_SKILL_LOAN_STATUS,
    payload: data,
  }
}
export const getSkillLoanStatusSuccess = data => {
  return {
    type: action.GET_SKILL_LOAN_STATUS_SUCCESS,
    payload: data.data,
  }
}
export const getStudentCategory = data => {
  return {
    type: action.GET_STUDENT_CATEGORY,
    payload: data,
  }
}
export const getStudentCategorySuccess = data => {
  return {
    type: action.GET_STUDENT_CATEGORY_SUCCESS,
    payload: data.data,
  }
}

export const getScholarship = data => {
  return {
    type: action.GET_SCHOLARSHIPS,
    payload: data,
  }
}
export const getScholarshipSuccess = data => {
  return {
    type: action.GET_SCHOLARSHIPS_SUCCESS,
    payload: data.data,
  }
}
export const getScholarshipStatus = data => {
  return {
    type: action.GET_SCHOLARSHIP_STATUS,
    payload: data,
  }
}
export const getScholarshipStatusSuccess = data => {
  return {
    type: action.GET_SCHOLARSHIP_STATUS_SUCCESS,
    payload: data.data,
  }
}

export const getMinorityStatus = data => {
  return {
    type: action.GET_MINORITY_STATUS,
    payload: data,
  }
}
export const getMinorityStatusSuccess = data => {
  return {
    type: action.GET_MINORITY_STATUS_SUCCESS,
    payload: data.data,
  }
}

export const getLeadSource = data => {
  return {
    type: action.GET_LEAD_SOURCE,
    payload: data,
  }
}
export const getLeadSourceSuccess = data => {
  return {
    type: action.GET_LEAD_SOURCE_SUCCESS,
    payload: data.data,
  }
}
export const getCampaign = data => {
  return {
    type: action.GET_CAMPAIGN,
    payload: data,
  }
}
export const getCampaignSuccess = data => {
  return {
    type: action.GET_CAMPAIGN_SUCCESS,
    payload: data.data,
  }
}
export const getTempBatch = data => {
  return {
    type: action.GET_TEMP_BATCH,
    payload: data,
  }
}
export const getTempBatchSuccess = data => {
  return {
    type: action.GET_TEMP_BATCH_SUCCESS,
    payload: data.data,
  }
}

export const getCluster = data => {
  return {
    type: action.GET_CLUSTER,
    payload: data,
  }
}
export const getClusterSuccess = data => {
  return {
    type: action.GET_CLUSTER_SUCCESS,
    payload: data.data,
  }
}

export const getExpenseCategory = data => {
  return {
    type: action.GET_EXPENSE_CATEGORY,
    payload: data,
  }
}
export const getExpenseCategorySuccess = data => {
  return {
    type: action.GET_EXPENSE_CATEGORY_SUCCESS,
    payload: data.data,
  }
}

export const getClassRoomsOrLabs = data => {
  return {
    type: action.GET_CLASSROOMS_LABS,
    payload: data,
  }
}
export const getClassRoomsOrLabsSuccess = data => {
  return {
    type: action.GET_CLASSROOMS_LABS_SUCCESS,
    payload: data.data,
  }
}

export const getEvents = data => {
  return {
    type: action.GET_EVENTS,
    payload: data,
  }
}
export const getEventsSuccess = data => {
  return {
    type: action.GET_EVENTS_SUCCESS,
    payload: data.data,
  }
}
