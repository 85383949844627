import React, { useEffect, useRef, useState } from "react"
import { Row, Col, Label, Button, BreadcrumbItem } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import AssignmentIcon from "@mui/icons-material/Assignment"
import SummarizeIcon from "@mui/icons-material/Summarize"
import { Box, Divider } from "@mui/material"
import Select from "react-select"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Link } from "react-router-dom"
import { get, post } from "../../../helpers/api_helper"
import { useParams } from "react-router-dom"
import { PRIVILEGES } from "../../../config"
import toastr from "toastr"
import moment from "moment"
import TablePagination from "../../../components/Common/Pagination"
import Swal from "sweetalert2"
import { formatDate } from "@fullcalendar/core"
import "./style.scss"
import AssetIssue from "../AssetIssue"


const AssetDashboard = () => {
  // const { jobId } = props.match.params;
  const [tab3, setTab3] = React.useState("1")
  const { assetId } = useParams()


  const [movementLogTable, setMovementLogTable] = useState([])
 
  const [assetDetails, setAssetDetails] = useState({})

  const formRef = useRef()
  const limit = 20
  const [state, setState] = useState(false)

  const [totalPage2, setTotalPage2] = useState(0)
  const [page2, setPage2] = useState(1)

  const [selectedFromDate, setSelectedFromDate] = useState("")
  const [selectedToDate, setSelectedToDate] = useState("")
  const [selectedStatus, setSelectedStatus] = useState(null)
  const [fromDateErrMsg, setFromDateErrMsg] = useState("")
  const [toDateErrMsg, setToDateErrMsg] = useState("")



  useEffect(() => {
    getAsset()
  }, [state])





  useEffect(() => {
    fetchAssetMovementLog()
  }, [state, page2])



  const getAsset = async () => {
    try {
      const response = await get(`/asset/asset?assetId=${assetId}`)
      setAssetDetails(response?.data[0])
    } catch (error) {}
  }



  const handleChange2 = (event, value) => {
    setPage2(value)
  }







  const fetchAssetMovementLog = async () => {
    try {
      const filterQuery = `assetId=${assetId}&page=${page2}&limit=${limit}&fromDate=${selectedFromDate}&toDate=${selectedToDate}&returnStatus=${selectedStatus?.value}`

      const response = await get(`/asset/asset-movementLog?${filterQuery}`)
      setMovementLogTable(response?.data)
      const count = response?.count
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage2(totalPage)
    } catch (error) {}
  }

  const handleFilter = () => {
    const currentDate = moment()
    const fromDateValue = moment(selectedFromDate)
    const toDateValue = moment(selectedToDate)

    if (fromDateValue.isAfter(currentDate, "day")) {
      return setFromDateErrMsg(
        "From date must be less than or equal to current date."
      )
    }
    if (toDateValue.isAfter(currentDate, "day")) {
      return setToDateErrMsg(
        "To date must be less than or equal to current date."
      )
    }
    if (fromDateValue.isAfter(toDateValue, "day")) {
      return setToDateErrMsg(
        "To date must be greater than or equal to from date."
      )
    }
    setFromDateErrMsg("")
    setToDateErrMsg("")
    fetchAssetMovementLog()
  }



  const typeMap = {
    0:"IT",
    1:"NON IT"
  }



  const reset = () => {
    formRef.current.reset()
  
    setState(!state)

    setSelectedFromDate("")
    setSelectedToDate("")
    setSelectedStatus(null)
    setFromDateErrMsg("")
    setToDateErrMsg("")

  }



  const handleChangeTab = (event, newValue) => {
    setTab3(newValue)
  }

  const logData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
    
      {
        label: "From Type",
        field: "fromType",
        sort: "asc",
        width: 400,
      },
      {
        label: "From",
        field: "from",
        sort: "asc",
        width: 200,
      },
      {
        label: "To Type",
        field: "toType",
        sort: "asc",
        width: 400,
      },
      {
        label: "To    ",
        field: "to",
        sort: "asc",
        width: 200,
        className: "text-center", 
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 200,
      },
      {
        label: "Added By",
        field: "added_by",
        sort: "asc",
        width: 200,
      },
    ],
    rows: movementLogTable.map((item, index) => {
      let actionStatus
      if(item.returnStatus){
        actionStatus = <h6 style={{ color: "green" }}>Returned</h6>
      }else if(item?.isPermanent == true){
        actionStatus = <h6 style={{ color: "blue" }}>Permanent Issued</h6>
      }else{
        actionStatus = <h6 style={{ color: " " }}>Issued</h6>
      }

      return {
        id: index + 1 + (page2 - 1) * limit,
        date: moment(item?.createdAt).format("DD-MM-YYYY"),
        time: moment(item.assetIssuesDetails.time, "HH:mm:ss").format(
          "hh:mm A"
        ),
        asset: "Laptop001",
        from: item.FromDetails.name,
        fromType: item.FromDetails.type,
        to: item.ToDetails.name,
        toType: item.ToDetails.type,
        status: actionStatus,
        added_by: item.addedByDetails.name,
      }
    }),
  }



  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">{assetDetails?.name}</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      <Link to="/">Home</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="/asset-creation">Create Asset</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="#">{assetDetails?.name}</Link>
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              {/* First Dialogue */}

              <div>
                <div className="row">
                  <div className="col-xl-3">
                    <div
                      className="card"
                      style={{
                        // width: "fit-content",
                        // width: "351px",
                        height: "max-content",
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                      }}
                    >
                      <div className="card-body">
                        <div className="float-end dropdown"></div>

                        <h5
                          className="mt-2 mb-2"
                          style={{
                            textAlign: "center",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}
                        >
                          {assetDetails?.name}
                        </h5>
                        <div
                          className="text-muted"
                          style={{
                            textAlign: "center",
                            color: "green !important",
                            fontFamily: "IBM Plex Sans,sans-serif",
                            fontSize: "14px",
                            marginBottom: "3px",
                          }}
                        ></div>
                        <p
                          style={{
                            textAlign: "center",
                            marginBottom: "3px",
                          }}
                        ></p>

                        <Divider />

                        <div className="mt-2 ">
                          <p className="mb-1">Date:</p>
                          <h5 className="font-size-14">
                            {moment(assetDetails?.date).format("DD-MM-YYYY")}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2">
                          <p className="mb-1">Serial Number:</p>
                          <h5 className="font-size-14">
                            {assetDetails?.serialNumber}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2">
                          <p className="mb-1">CSP:</p>
                          <h5 className="font-size-14">
                            {assetDetails?.branch?.name}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2">
                          <p className="mb-1">Type:</p>

                          <h5 className="font-size-14">
                            {typeMap[assetDetails?.type]}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2">
                          <p className="mb-1">Category:</p>

                          <h5 className="font-size-14">
                            {assetDetails?.category?.label}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2">
                          <p className="mb-1">Sub Category:</p>

                          <h5 className="font-size-14">
                            {assetDetails?.subCategory?.label}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2">
                          <p className="mb-1">Invoice Number:</p>
                          <h5 className="font-size-14">
                            {assetDetails?.invoiceOrder}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2">
                          <p className="mb-1">Date of Purchase:</p>
                          <h5 className="font-size-14">
                            { moment(assetDetails?.purchaseDate).format("DD-MM-YYYY")}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2">
                          <p className="mb-1">Warranty End Date:</p>
                          <h5 className="font-size-14">
                            {moment(assetDetails?.warrantyEndDate).format("DD-MM-YYYY")}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        {
                          assetDetails?.amc&&
                          <>
                          <div className="mt-2">
                          <p className="mb-1">Amc Start Date:</p>
                          <h5 className="font-size-14">
                            { moment(assetDetails?.amcStartDate).format("DD-MM-YYYY")}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2">
                          <p className="mb-1">Amc End Date:</p>
                          <h5 className="font-size-14">
                            {moment(assetDetails?.amcEndDate).format("DD-MM-YYYY")}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        </>
                        }
                      
           
                        <div className="mt-2">
                          <p className="mb-1">Current Ownership Type:</p>
                          <h5 className="font-size-14">
                            {assetDetails?.assign}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />

                        <div className="mt-2">
                          <p className="mb-1">Current Ownership:</p>
                          <h5 className="font-size-14">
                            {assetDetails?.owner &&
                              assetDetails?.owner[0]?.name}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2">
                          <p className="mb-1">Ownership:</p>
                          <h5 className="font-size-14">
                            {assetDetails?.initialOwnerDetails &&
                              assetDetails?.initialOwnerDetails[0]?.name}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />

                       

                      </div>
                    </div>
                  </div>

                  <div className="col-xl-9" style={{ marginBottom: "30px" }}>
                    <div
                      className="mb-0 card"
                      style={{
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={tab3}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              indicatorColor="primary"
                              textColor="primary"
                              variant="fullWidth"
                              onChange={handleChangeTab}
                              aria-label="lab API tabs example"
                            >
                              <Tab
                                icon={
                                  <SummarizeIcon style={{ fontSize: "20px" }} />
                                }
                                label="Issues/Return"
                                value="1"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <AssignmentIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Movement Log"
                                value="2"
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                            </TabList>
                          </Box>

                          <TabPanel
                            value="1"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                          
                          <AssetIssue useType='assetDetails' assetSingleId={assetId} assetName={assetDetails?.name} serialNumber={assetDetails?.serialNumber} setStateLog={setState} stateLog={state} assetPurchaseDate ={assetDetails?.purchaseDate }/>
                          </TabPanel>

                          <TabPanel
                            value="2"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col md="12">
                                <AvForm ref={formRef}>
                                  <Row>
                                    <Col md="3">
                                      <Label>From Date</Label>
                                      <AvField
                                        name="date"
                                        type="date"
                                        onChange={e =>
                                          setSelectedFromDate(e.target.value)
                                        }
                                        value={selectedFromDate}
                                      />
                                      <p
                                        style={{
                                          color: "red",
                                          fontSize: "0.8em",
                                          opacity: 0.7,
                                        }}
                                      >
                                        {fromDateErrMsg}
                                      </p>
                                    </Col>
                                    <Col md="3">
                                      <Label>To Date</Label>
                                      <AvField
                                        name="date"
                                        type="date"
                                        onChange={e =>
                                          setSelectedToDate(e.target.value)
                                        }
                                        value={selectedToDate}
                                      />
                                      <p
                                        style={{
                                          color: "red",
                                          fontSize: "0.8em",
                                          opacity: 0.7,
                                        }}
                                      >
                                        {toDateErrMsg}
                                      </p>
                                    </Col>

                                    <Col md="3">
                                      <div className="mb-3">
                                        <Label>Status</Label>
                                        <Select
                                          name="students"
                                          classNamePrefix="select2-selection"
                                          options={[
                                            { label: "Issued", value: 0 },
                                            { label: "Permanent Issued", value: 2 },
                                            { label: "Returned", value: 1 },
                                          ]}
                                          onChange={item =>
                                            setSelectedStatus(item)
                                          }
                                          value={selectedStatus}
                                        />
                                      </div>
                                    </Col>
                                    <Col md="3" style={{ marginTop: "1.7rem" }}>
                                      <Button
                                        onClick={handleFilter}
                                        type="submit"
                                        color="success"
                                      >
                                        Filter
                                      </Button>
                                   
                                      <Button style={{marginLeft:"1rem"}} color="danger" onClick={reset}>
                                        Reset
                                      </Button>
                                    </Col>
                                  </Row>
                                  <Row>
                                 
                                  </Row>
                                </AvForm>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="col-12">
                                <MDBDataTable
                                  id="movementTable"
                                  responsive
                                  bordered
                                  data={logData}
                                  searching={true}
                                  info={true}
                                  disableRetreatAfterSorting={true}
                                  paginationLabel={false}
                                  paging={false}
                                />
                                <TablePagination
                                  page={page2}
                                  onChange={handleChange2}
                                  count={totalPage2}
                                />
                              </Col>
                            </Row>
                          </TabPanel>
                        </TabContext>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AssetDashboard
