import React, { useEffect, useRef, useState } from "react"
import Breadcrumb from "../../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Label, Button } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import { MDBDataTable } from "mdbreact"
import { get, post } from "../../../../helpers/api_helper"
import useBranch from "../../../../hooks/useBranch"
import toastr from "toastr"
import TablePagination from "../../../../components/Common/Pagination"
import "./index.css"
import moment from "moment"

function Index() {
  const myCsp = useBranch()
  const [myCspId, setMyCspId] = useState("")
  const [cspFrom, setCspFrom] = useState(null)
  const [profileOptions, setProfileOptions] = useState([])
  const [selectedProfile, setSelectedProfile] = useState(null)
  const [SingleProfile, setSingleProfile] = useState(null)

  const [tempBatchFrom, setTempBatchFrom] = useState([])
  const [mainBatchFrom, setMainBatchFrom] = useState([])
  const [selectedBatchFrom, setSelectedBatchFrom] = useState(null)

  const [profileTempBatch, setProfileTempBatch] = useState([])
  const [profileMainBatch, setProfileMainBatch] = useState(null)
  const [cspOptions, setCspOptions] = useState([])
  const [selectedBranchTo, setSelectedBranchTo] = useState(null)
  const [tempBatchesTo, setTempBAtchesTo] = useState([])
  const [selectedTempBatchTo, setSelectedTempBatchTo] = useState(null)
  const [TableData, setTAbleData] = useState([])
  const [state, setState] = useState(false)
  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 20
  const formRef = useRef()

  useEffect(() => {
    const fetchData = async () => {
      const filterQuery = `page=${page}&limit=${limit}`
      const response = await get(`/student/transfer-list?${filterQuery}`)
      setTAbleData(response.data)
      const count = response?.count
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
    }
    fetchData()
  }, [state, page])

  useEffect(() => {
    let cspId = myCsp ? myCsp?.[0]?.value : ""
    setMyCspId(cspId)
  }, [state])

  useEffect(() => {
    if (myCspId || cspFrom?.value) {
      let id = myCspId ? myCspId : cspFrom?.value
      if (id) {
        const fetchData = async () => {
          const response = await get(`/student/options-profile?branchId=${id}`)
          setProfileOptions(response.data)
        }
        fetchData()
      }
    }
  }, [myCspId, cspFrom])

  useEffect(() => {
    if (
      selectedProfile &&
      selectedProfile?.value &&
      (myCspId || cspFrom?.value)
    ) {
      let id = myCspId ? myCspId : cspFrom?.value
      const fetchData = async () => {
        const response = await get(
          `/student/single-profile?student=${selectedProfile.value}&branchId=${id}`
        )
        setSingleProfile(response?.data[0])
        setTempBatchFrom(response?.TempBatch)
        setMainBatchFrom(response?.MainBatch)
      }
      fetchData()
    }
  }, [selectedProfile])

  useEffect(() => {
    const fetchData = async () => {
      const response = await get(`/options/branches`)
      setCspOptions(response.branches)
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (selectedBranchTo && selectedBranchTo.value) {
      const fetchData = async () => {
        const response = await get(
          `/student/options-temp-batch?branchId=${selectedBranchTo.value}`
        )
        setTempBAtchesTo(response.data)
      }
      fetchData()
    }
  }, [selectedBranchTo])

  const AddData = async () => {
    let userId
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser")
      var user_obj = JSON.parse(data)
      userId = user_obj._id
    }
    let batchIdFrom = profileTempBatch
      ? profileTempBatch?._id
      : profileMainBatch?._id
    let fromBatchType = selectedBatchFrom?.type == 0 ? 0 : 1 // 0 = temp batch , 1= main batch
    let fromBranchId = myCspId ? myCspId : cspFrom?.value
    try {
      const response = await post(`/student/sudent-transfer`, {
        profile: selectedProfile.value,
        fromBranch: fromBranchId,
        toBranch: selectedBranchTo.value,
        fromBatch: selectedBatchFrom?.value,
        toBatch: selectedTempBatchTo?.value,
        courseId: selectedTempBatchTo?.courseId,
        addedBy: userId,
        fromBatchType: fromBatchType,
      })
      if (response.message == "Student already exist in the selected batch") {
        return toastr.warning(response.message)
      }
      toastr.success(response.message)
      formRef.current.reset()

      setSelectedProfile(null)
      setSingleProfile(null)
      setProfileTempBatch(null)
      setProfileMainBatch(null)
      setSelectedBranchTo(null)
      setSelectedTempBatchTo(null)
      setSelectedBatchFrom(null)
      setCspFrom(null)
      setState(!state)
      setMyCspId("")

      setProfileOptions([])
      setTempBatchFrom([])
      setMainBatchFrom([])
      setProfileTempBatch([])
      setTempBAtchesTo([])
    } catch (error) {}
  }

  const handleChange = (event, value) => {
    setPage(value)
  }

  const reset = () => {
    formRef.current.reset()
    setSelectedProfile(null)
    setSingleProfile(null)
    setProfileTempBatch(null)
    setProfileMainBatch(null)
    setSelectedBranchTo(null)
    setSelectedTempBatchTo(null)
    setSelectedBatchFrom(null)
    setCspFrom(null)

    setMyCspId("")
    setState(!state)

    setProfileOptions([])
    setTempBatchFrom([])
    setMainBatchFrom([])
    setProfileTempBatch([])
    setTempBAtchesTo([])
  }

  //////////////

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Date",
        field: "reg_no",
        width: 150,
      },
      {
        label: "Student",
        field: "student",
        width: 150,
      },
      {
        label: " From CSP",
        field: "branch1",
        width: 150,
      },
      {
        label: "To CSP",
        field: "branch2",
        width: 150,
      },
      {
        label: "From Course",
        field: "courseFrom",
        width: 150,
      },
      {
        label: "To Course",
        field: "courses",
        width: 150,
      },
      // {
      //   label: "Email",
      //   field: "email",
      //   width: 150,
      // },
      {
        label: "Mobile",
        field: "mobile",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff",
        width: 150,
      },
      // {
      //   label: "Action",
      //   field: "action",
      //   width: 150,
      // },
    ],
    rows: TableData.map((item, index) => ({
      id: index + 1 + (page - 1) * limit,
      reg_no: `${moment(item.date).format("DD-MM-YYYY")}`,
      student: `${item?.student?.firstname} ${item?.student?.lastname} `,
      branch1: `${item?.cspFrom?.name}`,
      branch2: `${item?.cspTo?.name}`,
      courseFrom: item?.courseFrom?.name,
      courses: `${item?.course?.name}`,
      // email:`${item?.student?.email}`,
      mobile: `${item?.student?.mobile}`,
      staff: "Admin",

      key: index + 1,
    })),
  }
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumb title="Home" breadcrumbItem="Student Transfer" />

        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <AvForm
                  className="needs-validation"
                  onValidSubmit={AddData}
                  ref={formRef}
                >
                  <Row>
                    <Row>
                      {!myCspId && (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>From Csp</Label>
                            <Select
                              name="Csp_to"
                              classNamePrefix="select2-selection"
                              options={cspOptions}
                              onChange={item => setCspFrom(item)}
                              value={cspFrom}
                            />
                          </div>
                        </Col>
                      )}

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Student</Label>
                          <Select
                            name="Student"
                            classNamePrefix="select2-selection"
                            options={profileOptions}
                            onChange={item => setSelectedProfile(item)}
                            value={selectedProfile}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>From Batch </Label>
                          <Select
                            name="tembatchTo"
                            classNamePrefix="select2-selection"
                            options={[
                              {
                                label: "Temporary Batches", // Heading for temporary batches
                                options: tempBatchFrom,
                              },
                              {
                                label: "Main Batches", // Heading for main batches
                                options: mainBatchFrom,
                              },
                              // Add static options here if needed
                            ]}
                            onChange={item => setSelectedBatchFrom(item)}
                            value={selectedBatchFrom}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>To Csp</Label>
                          <Select
                            name="Csp_to"
                            classNamePrefix="select2-selection"
                            options={cspOptions}
                            onChange={item => setSelectedBranchTo(item)}
                            value={selectedBranchTo}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>To Temp Batch </Label>
                          <Select
                            name="tembatchTo"
                            classNamePrefix="select2-selection"
                            options={tempBatchesTo}
                            onChange={item => setSelectedTempBatchTo(item)}
                            value={selectedTempBatchTo}
                            formatOptionLabel={option => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                                }}
                              >
                           <strong class="gray-text">{option.label.courseName}</strong>
                                <span>
                                  next batch start :
                                  {option.label.expStartingDate}
                                </span>
                              </div>
                            )}
                          />
                        </div>
                      </Col>
                    </Row>

                    {SingleProfile && (
                      <Row>
                        <Col md="">
                          <div className="mb-3">
                            <table
                              id="product_available_day"
                              className="table table-bordered dataTable"
                            >
                              <tr>
                                <td
                                  style={{
                                    width: "184px",
                                    padding: "10px",
                                    textAlign: "justify",
                                  }}
                                >
                                  Student Name:
                                </td>
                                <td style={{ textAlign: "justify" }}>
                                  {SingleProfile?.firstname}
                                </td>
                                <td>Mobile :</td>
                                <td style={{ textAlign: "justify" }}>
                                  {SingleProfile?.mobile}{" "}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    width: "184px",
                                    padding: "10px",
                                    textAlign: "justify",
                                  }}
                                >
                                  Student Id:
                                </td>
                                <td style={{ textAlign: "justify" }}>
                                  {SingleProfile?.unique_id}
                                </td>
                                <td>Email :</td>
                                <td style={{ textAlign: "justify" }}>
                                  {SingleProfile?.email}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </Col>
                      </Row>
                    )}

                    <Row>
                      <Col md="3">
                        <Button type="submit" color="primary">
                          Submit
                        </Button>
                        <Button
                          color="danger"
                          style={{ marginLeft: "25px" }}
                          onClick={reset}
                        >
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <MDBDataTable
                  id="allocationTableIdd"
                  responsive
                  bordered
                  searching={true}
                  entries={20}
                  disableRetreatAfterSorting={true}
                  data={data}
                  paginationLabel={false}
                  paging={false}
                />
                <TablePagination
                  page={page}
                  onChange={handleChange}
                  count={totalPage}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Index
