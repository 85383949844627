import React, { useEffect, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import { get } from "../../../helpers/api_helper"
// import toastr from "toastr"
import moment from "moment"
import "./style.scss"
import {
  dateConverter,
  calculateGST,
  jsonToExcel,
} from "../../../helpers/functions"
import TablePagination from "../../../components/Common/Pagination"
import { useTransition } from "react"
import toastr from "toastr"

const FeeReport = () => {
  const [tableData, setTableData] = useState([])
  const [otherOptions, setOtherOptions] = useState([])

  const [exports, setExports] = useState([])

  const [isExportPending, startExport] = useTransition()

  const [selected, setSelected] = useState({
    type: "",
    option: "",
  })

  const [filterObject, setFilterObject] = useState({
    from: "",
    to: "",
    type: "",
    option: "",
  })
  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 20

  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [page, filterObject])

  const handleTableData = async () => {
    await get(
      `report/fee?page=${page}&limit=${limit}&from=${filterObject.from}&to=${filterObject.to}&type=${filterObject.type}&option=${filterObject.option}`
    ).then(res => {
      const data = res.data
      const count = res.count
      data.map((item, index) => {
        item.id = index + 1 + (page - 1) * limit
        item.date = dateConverter(item.date)
        item.dateOfRegistration = dateConverter(item.dateOfRegistration)
        item.student = (item.firstName || "") + " " + (item.lastName || "")
        item.mode =
          item.mode === 1
            ? "Cash"
            : item.mode === 2
            ? "Skill loan"
            : item.mode === 3
            ? "Scholarship"
            : "Online"

        item.type =
          item.type === 0 ? "Course" : item.type === 2 ? "Event" : "Other"

        // item.due = item.due?.toLocaleString("en-IN") || 0
        // item.fee = item.fee?.toLocaleString("en-IN") || 0
        // item.paid = item.paid?.toLocaleString("en-IN") || 0

        item.due = "₹" + item.due?.toLocaleString("en-IN") || 0
        item.fee = "₹" + item.fee?.toLocaleString("en-IN") || 0
        item.paid = "₹" + item.paid?.toLocaleString("en-IN") || 0
        return item
      })
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(data)

      startExport(async () => {
        await handleExportData(count)
      })
    })
  }

  const handleExportData = async count => {
    await get(
      `report/fee?page=1&limit=${count}&from=${filterObject.from}&to=${filterObject.to}&type=${filterObject.type}&option=${filterObject.option}`
    ).then(res => {
      const data = res.data
      const exportData = []
      data.map((item, index) => {
        item.id = index + 1 + (page - 1) * limit
        item.date = dateConverter(item.date)
        item.dateOfRegistration = dateConverter(item.dateOfRegistration)
        item.student = (item.firstName || "") + " " + (item.lastName || "")

        item.mode =
          item.mode === 1
            ? "Cash"
            : item.mode === 2
            ? "Skill loan"
            : item.mode === 3
            ? "Scholarship"
            : "Online"

        item.type =
          item.type === 0 ? "Course" : item.type === 2 ? "Event" : "Other"

        const { taxableValue, taxAmount } = calculateGST(
          item.paid || 0,
          item.gst || 0
        )
        item.due = item.due?.toLocaleString("en-IN") || 0
        item.fee = item.fee?.toLocaleString("en-IN") || 0
        item.paid = item.paid?.toLocaleString("en-IN") || 0

        // TODO: Export details
        const dataExport = {}

        dataExport["SI No"] = item.id
        dataExport["Fee Receipt"] = item.uniqueId
        dataExport["Registration Number"] = item.studentUniqueId
        dataExport["Name"] = item.student
        dataExport["Mobile"] = item.mobile
        dataExport["Email"] = item.email
        dataExport["Mode"] = item.mode
        dataExport["Type"] = item.type
        dataExport["Course/Event"] = item.name
        dataExport["Sector"] = item.sector
        dataExport["Center"] = item.center
        dataExport["Date Of Registration"] = item?.dateOfRegistration
        dataExport["Fee"] = item?.fee
        dataExport["Tax Amount"] = taxAmount.toFixed(2)
        dataExport["Taxable Value"] = taxableValue.toFixed(2)
        dataExport["Paid Amount"] = item.paid
        dataExport["Due Amount"] = item.due
        dataExport["Payment Date"] = item.date
        dataExport["Payment Id"] = item.paymentId
        dataExport["Order Id"] = item.orderId

        // dataExport["#"] = index + 1
        // dataExport.Id = item?.uniqueId
        // dataExport.studentId = item?.studentUniqueId
        // dataExport.Name = item?.student
        // dataExport.Mobile = item?.mobile
        // dataExport.Email = item?.email
        // dataExport.Mode = item?.mode
        // dataExport.Type = item?.type
        // dataExport["Course/Event"] = item?.name
        // dataExport.Sector = item?.sector
        // dataExport.Center = item?.center
        // dataExport[`Date of Registration`] = item?.dateOfRegistration
        // dataExport.Fee = item?.fee
        // dataExport["Tax Amount"] = taxAmount.toFixed(2)
        // dataExport["Taxable Value"] = taxableValue.toFixed(2)
        // dataExport.Paid = item?.paid
        // dataExport.Due = item?.due
        // dataExport.Date = item?.date
        // dataExport["Payment Id"] = item.paymentId
        // dataExport["Order Id"] = item.orderId
        exportData.push(dataExport)

        return item
      })

      setExports(exportData)
    })
  }

  const handleExport = () => {
    if (exports.length > 0) {
      jsonToExcel(exports, `fee-reports-${moment().format("DD-MM-YY")}`)
    } else {
      toastr.info(`There are No Fee reports to export`)
    }
  }

  const handleOptions = value => {
    if (value === 0)
      get("/options/courses").then(res => setOtherOptions(res.data))
    else if (value === 2)
      get("/options/events").then(res => setOtherOptions(res.data))
    else setOtherOptions([])
  }

  const handleChange = (event, value) => {
    setPage(value)
  }

  const handleValueChange = (value, name) => {
    const obj = { ...filterObject }
    obj[name] = value
    setFilterObject(obj)
  }

  const typeOptions = [
    { label: "Course", value: 0 },
    { label: "Event", value: 2 },
  ]
  const reset = () => {
    setFilterObject({})
    setPage(1)
    setTotalPage(0)
    setOtherOptions([])
    setSelected({})
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date of Payment",
        field: "date",
        width: 150,
      },
      {
        label: "Unique Id",
        field: "uniqueId",
        width: 150,
      },
      {
        label: "Name",
        field: "student",
        width: 150,
      },

      {
        label: "Mobile",
        field: "mobile",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        width: 150,
      },
      {
        label: "Mode",
        field: "mode",
        width: 150,
      },
      {
        label: "Type",
        field: "type",
        width: 150,
      },
      {
        label: "Course/Event",
        field: "name",
        width: 150,
      },
      {
        label: "Sector",
        field: "sector",
        width: 150,
      },
      {
        label: "Center",
        field: "center",
        width: 150,
      },
      {
        label: "Date of Registration",
        field: "dateOfRegistration",
        width: 150,
      },
      {
        label: "Fee",
        field: "fee",
        width: 150,
      },
      {
        label: "Paid",
        field: "paid",
        width: 150,
      },
      {
        label: "Due",
        field: "due",
        width: 150,
      },
    ],
    rows: tableData,
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Fee report" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label> From</Label>
                          <AvField
                            name="from"
                            value={filterObject.from || ""}
                            onChange={e =>
                              handleValueChange(e.target.value, e.target.name)
                            }
                            max={moment().format("YYYY-MM-DD")}
                            className="form-control"
                            type="date"
                            placeholder="From"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>To</Label>
                          <AvField
                            name="to"
                            max={moment().format("YYYY-MM-DD")}
                            min={filterObject.from}
                            value={filterObject.to || ""}
                            onChange={e =>
                              handleValueChange(e.target.value, e.target.name)
                            }
                            className="form-control"
                            type="date"
                            placeholder="To"
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Type</Label>
                          <Select
                            onChange={selected => {
                              handleOptions(selected?.value)
                              handleValueChange(selected?.value, "type")
                              setSelected({ type: selected })
                            }}
                            value={selected.type || ""}
                            isClearable={true}
                            options={typeOptions}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Course/ Event</Label>
                          <Select
                            onChange={selected => {
                              handleValueChange(selected?.value, "option")
                              setSelected(prev => ({
                                ...prev,
                                option: selected,
                              }))
                            }}
                            value={selected.option || ""}
                            isClearable={true}
                            options={otherOptions}
                          />
                        </div>
                      </Col>

                      <Col className="mt-4 pt-1">
                        <div className="mb-3">
                          <Button color="danger" type="button" onClick={reset}>
                            Reset
                          </Button>

                          <Button
                            style={{ marginLeft: "14px" }}
                            color="warning"
                            type="button"
                            disabled={isExportPending}
                            onClick={handleExport}
                          >
                            Export{" "}
                            {isExportPending && (
                              <i className="mdi mdi-spin mdi-loading"></i>
                            )}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                  <MDBDataTable
                    id="feeReportTable"
                    bordered
                    responsive
                    searching={false}
                    displayEntries={false}
                    paging={false}
                    disableRetreatAfterSorting={true}
                    data={data}
                    sortable={false}
                  ></MDBDataTable>
                  <TablePagination
                    page={page}
                    onChange={handleChange}
                    count={totalPage}
                  />
                  {/* <MuiCustomTablePagination
                    count={count}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  /> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default FeeReport
