import { useState } from "react"

const getBranch = () =>
  sessionStorage.getItem("branch") !== "undefined"
    ? JSON.parse(sessionStorage.getItem("branch"))
    : null

const useBranch = () => {
  const [data] = useState(() => getBranch())

  return [data]
}

export default useBranch
