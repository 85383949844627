import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Input, Label, Modal, Row, Table } from 'reactstrap'
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { MDBDataTable } from 'mdbreact'
import Select from "react-select"
import './style.scss'
import { get, post } from '../../../helpers/api_helper'
import { useLocation } from 'react-router-dom'
import MuiCustomTablePagination from '../../../components/Common/TablePagination'
import { API_URL, PRIVILEGES } from '../../../config'
import toastr from 'toastr'
import { isAllowed } from '../../../components/Common/Allowed'
import { Spinner } from 'reactstrap';

const CertificationGeneration = () => {

  const { state } = useLocation()

  const [csp,setCsp] = useState({label:'Select...',value:''})
  const [batch,setBatch] = useState({label:'Select',value:''})
  const [cspList,setCspList] = useState([])
  const [batchList,setBatchList] = useState([])
  const [page,setPage] = useState(0)
  const [pageSize,setPageSize] = useState(20)
  const [search,setSearch] = useState('')
  const [tableData,setTableData] = useState([])
  const [details, setDetails] = useState({})
  const [count] = useState(0)
  const [modal,setModal] = useState(false)
  const [path,setPath] = useState(null)
  const [refresh,setRefresh] = useState(1)
  const [loading, setLoading] = useState(false)
  const [selectedId,setSelectedId] = useState([])

  const allowed = isAllowed([
    PRIVILEGES.ADMIN,
    PRIVILEGES.CERTIFICATE_PM
  ])

  const fetchCspList = () => {
    get(`certification/options/ncvet-branch`).then(res => {
      if (res.success) {
        setCspList(res.data)
      }
    })
  }

  const fetchBatchList = () => {
    get(`certification/options/ncvet-batch?branch=${csp.value}`).then(res => {
      if (res.success) {
        if (batchList.length !== 0) {
          setBatch({ label: "Select...", value: "" })
        }
        setBatchList(res.data)
      }
    })
  }

  const fetchTableData = () => {
    get(
      `certification/ncvet-students?batch=${batch.value}&page=${page + 1}&limit=${pageSize}&keyword=${search}`
    )
      .then(res => {
        if (res.success) {
          const data = res.data[0]
          setDetails({
            id: data._id,
            name: data.name,
            batchId: data.batchId,
            branch: data.branch.name,
            course: data.course.name
          })
          setTableData(
            data?.courseAssigned.map((item, index) => ({
              ...item,
              _id: item._id,
              id: index + 1,
              student: `${item.firstName || ''} ${item.lastName || '' }`,
              regNo: item.unique_id,
            }))
          )
        }
      })
      .catch(err => toastr.error(err.response.data.message))
  }

  useEffect(()=>{
    if(batch.value){
      fetchTableData()
    }
    //eslint-disable-next-line
  },[batch,page,pageSize,search,refresh])

  useEffect(() => {
    if (state && state?.batchId) {
      setBatch({ label: state.batch, value: state.batchId })
      setCsp({ label: state.csp, value: state.cspId })
    }
    fetchCspList()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (csp.value) {
      fetchBatchList()
    }
    // eslint-disable-next-line
  }, [csp])

  const handleReset = ()=>{
    setTableData([])
    setDetails({})
    setCsp({label:'Select...',value:''})
    setBatch({label:'Select...',value:''})
    setBatchList([])
  }

  const handleChangeRowsPerPage = event => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handlePageChange = newPage => {
    setPage(newPage)
  }

  const handleView = (path)=> {
    const pdfUrl = `${API_URL}uploads/certificate/${path}.pdf`;
    window.open(pdfUrl, '_blank');
  }


  const handleGenerateClick = (index, id) => {
    post(`certification/ncvet-generate`, { id: id }).then(res => {
      if (res.success) {
        setRefresh(prev=>prev+1)
        toastr.success(res.message)
        setSelectedId([...selectedId,id])
        setTimeout(() => {
          setSelectedId((prevSelectedId) => prevSelectedId.filter((selected) => selected !== id));
        }, 2000);
      }
    }).catch(err=>toastr.error(err.response.data.message))
  }


  const handleBulkGenerate = () => {
    post(`certification/ncvet-generate-all`,{batch:details.id}).then(res => {
      if(res.success){
        setRefresh(prev=>prev+1)
        toastr.success(res.message)
        setLoading(true)
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    }).catch(err=>toastr.error(err.response.data.message))
  }

  const handlePublish = () => {
    post(`certification/ncvet-publish`,{batch:details.id}).then(res=>{
      if(res.success){
        toastr.success(res.message)
      }
    }).catch(err=>toastr.error(err.response.data.message))
  }

  const rows = tableData.map((item, index) => ({
    ...item,
    certificate: (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <span
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "250px",
          }}
        >
          <Button
            size="sm"
            style={{ width: "100px", whiteSpace: "nowrap" }}
            color={item.asapCertificate ? "danger" : "primary"}
            onClick={() => handleGenerateClick(index, item._id)}
            disabled={loading || selectedId.includes(item._id)}
          >
            {item.asapCertificate ? "Re-generate" : "Generate"}
          </Button>
          {!loading &&
            !selectedId.includes(item._id) &&
            item.asapCertificate && (
              <a
                style={{ cursor: "pointer" }}
                href="#"
                arrow
                onClick={() => handleView(item.asapCertificateId)}
              >
                <i
                  style={{
                    cursor: "pointer",
                    fontSize: "1.5em",
                    marginRight: "0.5rem",
                  }}
                  className="far fa-file-pdf"
                ></i>
                {item.asapCertificateId}
              </a>
            )}
          {(loading || selectedId.includes(item._id)) && (
            <span
              style={{
                height: "10px",
                marginRight: "50px",
                marginBottom: "13px",
              }}
            >
              <Spinner
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  borderWidth: "0.2em",
                }}
                color="primary"
              />
            </span>
          )}
        </span>
      </div>
    ),
  }))

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Register No.",
        field: "regNo",
        width: 150,
      },
      {
        label: "Student",
        field: "student",
        width: 150,
      },
      {
        label: "Certificate",
        field: "certificate",
        width: 150,
      },
    ],
    rows
  }


  
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumb title="Home" breadcrumbItem="Certification Generation" />

        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <Row>
                  <Col md="12">
                    <Col xl={12}>
                      <Row className="nav-row">
                        <Col md="12">
                          <Col xl={12}>
                            <Row>
                              <Col md={3}>
                                <div>
                                  <Label>CSP Name</Label>
                                  <Select
                                    name="student"
                                    classNamePrefix="select2-selection"
                                    options={cspList}
                                    onChange={(item)=>setCsp(item)}
                                    value={csp}
                                  />
                                </div>
                              </Col>
                              <Col md="3">
                                <div>
                                  <Label>Batch</Label>
                                  <Select
                                    name="batch"
                                    classNamePrefix="select2-selection"
                                    options={batchList}
                                    onChange={(item)=>setBatch(item)}
                                    value={batch}
                                    width={50}
                                    isDisabled = {csp.value === ''}
                                  />
                                </div>
                              </Col>

                              <Col>
                                <Button
                                  type="button"
                                  style={{
                                    marginTop: "1.7rem",
                                    marginLeft: "1rem",
                                  }}
                                  color="danger"
                                  onClick={handleReset}
                                >
                                  Reset
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Col>
                      </Row>
                    </Col>
                  </Col>
                </Row>
                <Row><Col md='12'>
                <Table className="table" id="batchtableId">
                  <tbody>
                    <tr>
                    <th>CSP</th>
                          <th>:</th>
                          <td>{details.branch}</td>
                          <th>Batch</th>
                          <th>:</th>
                          <td>{details.name}</td>
                          <th>Course</th>
                          <th>:</th>
                          <td>{details.course}</td>
                          {allowed && <div
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                                alignItems: "center",
                              }}
                            >
                              <Button color="warning" onClick={handleBulkGenerate} disabled={tableData.length === 0}>
                                Generate All
                              </Button>
                              <Button color="primary" onClick={handlePublish} disabled={tableData.length === 0}>
                                Publish
                              </Button>
                            </div>}
                    </tr>
                  </tbody>
                </Table>
                </Col></Row>
                <Row style={{marginBottom:'10px'}}><Col md='10'></Col><Col md='2'><Input placeholder="Search..." value={search} onChange={(e)=>setSearch(e.target.value)}></Input></Col></Row>
                <MDBDataTable
                  id="certificateTableId"
                  responsive
                  bordered
                  info={false}
                  searching={false}
                  paging={false}
                  entries={20}
                  disableRetreatAfterSorting={true}
                  data={data}
                  totalPage={20}
                />
                 {tableData.length > 0 && count > 10 && (
                  <MuiCustomTablePagination
                    count={count}
                    page={page}
                    rowsPerPage={pageSize}
                    handleChangePage={handlePageChange}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                )}
                <Modal
                  show={modal}
                  isOpen={modal}
                  toggle={() => {
                    setModal(false)
                    setPath(null)
                  }}
                  size="xl"
                  style={{
                    maxWidth: "60vw",
                    height: "100%",
                  }}
                  centered={true}
                >
                  <div className="modal-header">
                    <button
                      type="button"
                      onClick={() => {
                        setModal(false)
                        setPath(null)
                      }}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <iframe
                    src={`${API_URL}${path}`}
                    width="100%"
                    height="600px"
                    style={{ border: "none" }}
                    title="PDF Viewer"
                  />
                </Modal>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default CertificationGeneration