import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Label, Row, Spinner } from "reactstrap"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import "./style.scss"
import { get, post, put } from "../../../helpers/api_helper"
import { useLocation } from "react-router-dom"
import { MenuItem, TextField } from "@mui/material"
import toastr from "toastr"
import Swal from "sweetalert2"
import { jsonToExcel } from "../../../helpers/functions"
import { isAllowed } from "../../../components/Common/Allowed"
import { PRIVILEGES } from "../../../config"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { Tooltip as TooltipMui } from '@mui/material';

const AssessmentGrades = () => {
  const { state } = useLocation()

  const isCpm = isAllowed([PRIVILEGES.ADMIN, PRIVILEGES.CERTIFICATE_PM])

  const [csp, setCsp] = useState({ label: "Select...", value: "" })
  const [batch, setBatch] = useState({ label: "Select...", value: "" })
  const [cspList, setCspList] = useState([])
  const [batchList, setBatchList] = useState([])
  const [tableData, setTableData] = useState([])
  const [disableButton, setDisableButton] = useState(true)
  const [gradeMarked, setGradeMarked] = useState(true)
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(1)

  const fetchCspList = () => {
    get(`options/branches`).then(res => {
      if (res.success) {
        setCspList(res.branches)
      }
    })
  }

  const fetchBatchList = () => {
    get(`assessment/cspBatch?branch=${csp.value}`).then(res => {
      if (res.success) {
        if (batchList.length !== 0) {
          setBatch({ label: "Select...", value: "" })
        }
        setBatchList(res.data)
      }
    })
  }

  const fetchStudents = () => {
    if (batch.value) {
      setLoading(true)
      get(`assessment/BatchStudentsForGrades?batch=${batch.value}`).then(
        res => {
          if (res.success) {
            setGradeMarked(res.data[0].gradeMarked)
            if (!res.data[0].attendanceMarked && refresh === 1) {
              toastr.error("Assessment attendance not marked.")
              return
            }
            if (res.data[0].gradeMarked && refresh === 1) {
              toastr.info("Assessment grades already marked.")
            }
            const data = res.data[0].courseAssigned
            setTableData(
              data.map((item, index) => ({
                ...item,
                id: index + 1,
                _id: item._id,
                student: `${item.firstName || ""} ${item.lastName || ""}`,
                regNo: item.unique_id,
                attendance:
                  item.assessmentAttendance === 0
                    ? "Present"
                    : item.assessmentAttendance === 1
                    ? "Absent"
                    : "",
                grade: (item.assessmentAttendance === 1 ? 'NA' : item.finalGrade) || '',
              }))
            )
          }
        }
      ).catch(err=>toastr.error(err?.response?.data?.message))
      setTimeout(() => setLoading(false), 1000)
    }
  }

  useEffect(() => {
    if (state && state?.batchId) {
      setBatch({ label: state.batch, value: state.batchId })
      setCsp({ label: state.csp, value: state.cspId })
    }
    fetchCspList()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (batch && batch.value) {
      fetchStudents()
    }
    // eslint-disable-next-line
  }, [batch, refresh])

  useEffect(() => {
    if (csp.value) {
      fetchBatchList()
    }
    // eslint-disable-next-line
  }, [csp])

  const handleChange = (value, index) => {
    const newData = [...tableData]
    newData[index].grade = value
    put(`assessment/update-finalGrade`, {
      id: newData[index]._id,
      finalGrade: newData[index].grade,
    }).then(res => {
      if (res.success) {
        console.log('Grade Updated')
      }
    })
    setTableData(newData)
    setDisableButton(false)
  }

  const handleSubmit = id => {
    const hasInvalidGrade = tableData.some(
      item => item.attendance === "Present" && item.grade === ""
    )
    if (hasInvalidGrade) {
      toastr.error("All grades must be added before publishing.")
      return
    }
    return Swal.fire({
      title: "Confirm publishing grades ?",
      text: "You won't be able to change this later",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then(result => {
      if (result.isConfirmed) {
        const updates = tableData.map(item => ({
          id: item._id,
          finalGrade: item.grade,
        }))
        const postBody = { updates }
        put(`assessment/updateFinalGrade`, postBody)
          .then(res => {
            if (res.success) {
              toastr.success("Grades updated successfully")
              setDisableButton(true)
              setGradeMarked(true)
            }
          })
          .catch(err => toastr.error(err.response.data.message))
      }
    })
  }

  const uploadExcel = async e => {
    const selectedFile = e.target.files[0]
    setLoading(true)
    if (selectedFile) {
      try {
        const fd = new FormData()
        fd.append("csvFile", selectedFile)
        fd.append("batchId", batch.value)
        const response = await post(`assessment/bulkUpdateFinalGrades`, fd)
        if (response.code === 200) {
          toastr.success(response.message)
          setRefresh(prev => prev + 1)
        }
      } catch (error) {
        toastr.error(error.response.data.message)
      }
    }
    setLoading(false)
  }

  const handleReset = () => {
    setBatch({ label: "Select...", value: "" })
    setCsp({ label: "Select...", value: "" })
    setTableData([])
    setBatchList([])
  }

  const handleExport = async () => {
    if(!batch.value) return toastr.error('No batch selected.')
    await get(`assessment/BatchStudentsForGrades?batch=${batch.value}`).then(res => {
      const result = res.data[0]
      const exportData = []
      if (res.success) {
        result?.courseAssigned?.map((item, index) => {
          item.student = (item.firstName || "") + " " + (item?.lastName || '')
          const dataExport = {}
          dataExport['SI No.'] = index+1
          dataExport.Student = item.student
          dataExport['Register No'] = item.unique_id
          dataExport.Attendance = item.assessmentAttendance === 0 ? 'Present' : 'Absent'
          dataExport.Grade = item.finalGrade
          exportData.push(dataExport)
          return item
        })
        jsonToExcel(exportData, `Student Report-${result?.batchId}`)
      } else {
        toastr.info(`There are no students to export`)
      }
    })
  }

  const rows = tableData.map((item,index) => ({
    ...item,
    grade:(item.attendance === 'Present' ? (
      <TextField
        value={item.grade}
        select
        InputProps={{
          style: {
            height: "30px",
            padding: "0px",
          },
        }}
        onChange={(e)=>handleChange(e.target.value,index)}
      >
        <MenuItem value=""></MenuItem>
        <MenuItem value="A">A</MenuItem>
        <MenuItem value="B">B</MenuItem>
        <MenuItem value="C">C</MenuItem>
        <MenuItem value="D">D</MenuItem>
        <MenuItem value="E">E</MenuItem>
        <MenuItem value="F">F</MenuItem>
      </TextField>
    ) : item.attendance === 'Absent' ? 'NA' : ''),
  }))

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Register No.",
        field: "regNo",
        width: 150,
      },
      {
        label: "Student",
        field: "student",
        width: 150,
      },
      {
        label: "Attendance",
        field: "attendance",
        width: 150,
      },
      {
        label: "Final Grade",
        field: "grade",
        width: 150,
      },
    ],
    rows 
  }

  return (
    <div className="page-content">
      <div className="container-fluid" style={{ minHeight: "500px" }}>
        <Breadcrumb title="Home" breadcrumbItem="Assessment Grade" />

        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <Row>
                  <Col md="12">
                    <Col xl={12}>
                      <Row className="nav-row">
                        <Col md="12">
                          <Col xl={12}>
                            <Row>
                              <Col md={3}>
                                <div>
                                  <Label>CSP Name</Label>
                                  <Select
                                    name="student"
                                    classNamePrefix="select2-selection"
                                    options={cspList}
                                    onChange={item => setCsp(item)}
                                    value={csp}
                                  />
                                </div>
                              </Col>
                              <Col md="3">
                                <div>
                                  <Label>Batch</Label>
                                  <Select
                                    name="batch"
                                    classNamePrefix="select2-selection"
                                    options={batchList}
                                    onChange={item => setBatch(item)}
                                    value={batch}
                                    width={50}
                                    isDisabled={csp.value === ""}
                                  />
                                </div>
                              </Col>

                              <Col>
                                <Button
                                  type="button"
                                  style={{
                                    marginTop: "1.7rem",
                                    marginLeft: "1rem",
                                  }}
                                  color="danger"
                                  onClick={handleReset}
                                >
                                  Reset
                                </Button>
                                <Button
                                  style={{
                                    marginTop: "1.7rem",
                                    marginLeft: "1rem",
                                  }}
                                  color="warning"
                                  onClick={handleExport}
                                >
                                  Export
                                </Button>
                              </Col>
                              <Col md='4'>
                              <div
                                style={{
                                  fontSize: "13px",
                                  marginLeft: "7rem",
                                  marginTop:"28px"
                                }}
                              >
                                <TooltipMui title="Download sample file">
                                  <a
                                    href={
                                      "/samples/Assesment_grade_sample.xlsx"
                                    }
                                    download
                                    style={{ fontSize: "1rem" }}
                                    className="d-flex align-items-center btn btn-success "
                                  >
                                    <i
                                      style={{ fontSize: "13px" }}
                                      className="fa fa-solid fa-download "
                                    />
                                    <span
                                      style={{
                                        marginLeft: "5px",
                                        fontSize: "13px",
                                      }}
                                    >
                                      Download Sample Sheet
                                    </span>
                                  </a>
                                </TooltipMui>
                              </div>
                              </Col>
                           
                            </Row>
                          </Col>
                        </Col>
                      </Row>
                    </Col>
                  </Col>
                </Row>

                <>
                  <div className="d-flex  flex-row justify-content-end align-items-baseline">
                    <AvForm>
                      {isCpm && (
                        <div className="d-flex  flex-row justify-content-between align-items-baseline">
                          <AvField
                            disabled={batch.value ? false : true}
                            style={{ width: "300px" }}
                            name="image"
                            type="file"
                            className="form-control"
                            id="validationCustom03"
                            rows="1"
                            message="Select a excel file to upload"
                            title="Select a excel file to upload"
                            accept=".xlsx, .xls"
                            onChange={e => uploadExcel(e)}
                          />
                        </div>
                      )}
                    </AvForm>
                    {tableData?.length > 0 && isCpm && (
                      <Button
                        type="button"
                        style={{ marginTop: "1.7rem", marginLeft: "10px" }}
                        color="primary"
                        onClick={handleSubmit}
                        disabled={disableButton || gradeMarked}
                      >
                        {gradeMarked ? `Published ` : `Publish Grades`}
                        {gradeMarked && (
                          <i
                            className="fas fa-check"
                            style={{
                              fontSize: "0.7em",
                              color: "white",
                            }}
                          ></i>
                        )}
                      </Button>
                    )}
                    <OverlayTrigger
                      placement="top"
                      disabled={tableData.length === 0}
                      overlay={
                        <Tooltip
                          id="button-tooltip-2"
                          style={{ position: "absolute" }}
                        >
                          {loading ? "Refreshing..." : "Refresh"}
                        </Tooltip>
                      }
                    >
                      <div
                        style={{
                          height: "37px",
                          marginLeft: "10px",
                        }}
                      >
                        <Button
                          color="success"
                          onClick={() => setRefresh(prev => prev + 1)}
                          style={{ height: "100%", width: "60px" }}
                          disabled={loading || batch?.value === ""}
                          outline
                        >
                          {loading ? (
                            <Spinner
                              style={{
                                height: "20px",
                                width: "20px",
                              }}
                            />
                          ) : (
                            <i class="fas fa-redo"></i>
                          )}
                        </Button>
                      </div>
                    </OverlayTrigger>
                  </div>
                  <MDBDataTable
                    id="assessmentGradeTableId"
                    responsive
                    bordered
                    info={true}
                    searching={true}
                    entries={20}
                    paging={false}
                    disableRetreatAfterSorting={true}
                    data={data}
                  />
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default AssessmentGrades
