import React, { useEffect, useRef, useState } from "react"
import { Row, Col, BreadcrumbItem, Button } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import SummarizeIcon from "@mui/icons-material/Summarize"
import { Box, Divider } from "@mui/material"
import { useLocation } from "react-router-dom"
import { get, post } from "../../../helpers/api_helper"
import moment from "moment"
import { Link } from "react-router-dom"
import { CSVLink } from "react-csv"

import "./style.scss"
const RankListDetail = () => {
  const { state } = useLocation()

  const [tab3, setTab3] = React.useState("2")
  const [tableData, setTableData] = useState({})
  const [dataToBeExported, setdataToBeExported] = useState([])
  const handleChangeTab = (event, newValue) => {
    setTab3(newValue)
  }

  useEffect(() => {
    handletableData()
  }, [])

  function handletableData() {
    get(`/ranklist/ranklist?id=${state?.id}`).then(res => {
      console.log(res, "ress")
      let exportDat = []
      const data = res.data.rank; 
      data.map((item, index) => {
        let exItem = {}
        exItem["Serial No"] = index + 1;
        exItem.Name = item?.profile?.firstname + " " + item?.profile?.lastname || "";
        exItem.UniqueID = item?.profile?.uniqueId;
        exItem.Status = item?.attendData.attendStatus === 0 ? "Not Attended" : item?.attendData.attendStatus === 1 ? "Attending" : item?.attendData.attendStatus === 2 ? "Absent" : item?.attendData.attendStatus === 3 ? "Completed" : "";
          exItem.StartDate = item?.attendData?.startDate ? moment(item?.attendData?.startDate).format("DD-MM-YYYY") : "-";
          exItem.StartTime = item?.attendData?.startTime ? moment(item?.attendData?.startTime, "hh:mm:ss").format("hh:mm a") : "-";
          exItem.EndDate = item?.attendData?.endDate ? moment(item?.attendData?.endDate).format("DD-MM-YYYY") : "-";
          exItem.EndTime = item?.attendData?.endTime ? moment(item?.attendData?.endTime, "hh:mm:ss").format("hh:mm a") : "-";
          exItem.Score = item?.score;
          exItem.Rank = item?.rank;
          exItem.Result = item?.attendData.resultStatus === 0 ? "Pass" : "Fail";
          exportDat.push(exItem)
      })
      setdataToBeExported(exportDat)
      const result = res?.data
      result.test = result?.test
      result.added = `${result?.publishedBy?.firstName || ""} ${result?.publishedBy?.lastName || ""}`
      result.UniqueID = result?.uniqueId
      result.name = `${result.firstname} ${result.lastname}`
      result.date = moment(result?.date).format("DD-MM-YYYY")
      result.rank1 = result.totalRanks
      result.status1 = (
        <div>
          <span
            style={{
              color: result.status === 1 ? "blue" : "red",
              fontWeight: 500,
            }}
          >
            {result.status === 1 ? "Published" : "Not Published"}
          </span>
        </div>
      )
      setTableData(result)

    })
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "key",
        width: 50,
      },

      {
        label: "Name",
        field: "name",
        width: 150,
      },

      {
        label: "UniqueID",
        field: "uniqueid",
        width: 150,
      },
      {
        label: "Status",
        field: "attendStatus",
        width: 150,
      },
      {
        label: "Start Date",
        field: "startdate",
        width: 200,
      },

      {
        label: "Start Time",
        field: "starttime",
        width: 100,
      },

      {
        label: "End Date",
        field: "enddate",
        width: 150,
      },
      {
        label: "End Time",
        field: "endtime",
        width: 150,
      },
      {
        label: "Score",
        field: "score",
        width: 150,
      },

      {
        label: "Rank",
        field: "rank",
        width: 150,
      },
      {
        label: "Result",
        field: "resultStatus",
        width: 150,
      },
    ],
    rows: tableData?.rank?.map((item, index) => ({
      key: index + 1,
      name: `${item?.profile?.firstname || ""} ${item?.profile?.lastname || ""
        }`,
      uniqueid: item?.profile?.uniqueId,
      startdate: item?.attendData?.startDate ? moment(item?.attendData?.startDate).format("DD-MM-YYYY") : "-",
      enddate: item?.attendData?.endDate ? moment(item?.attendData?.endDate).format("DD-MM-YYYY") : "-",
      starttime: item?.attendData?.startTime ? moment(item?.attendData?.startTime, "hh:mm:ss").format("hh:mm a") : "-",
      endtime: item?.attendData?.endTime ? moment(item?.attendData?.endTime, "hh:mm:ss").format("hh:mm a") : "-",
      score: item?.score,
      resultStatus: <div>  {item?.attendData.resultStatus === 0 ? <span style={{color:"green"}}>Pass</span> : <span style={{color:"red"}}>Fail</span>} </div>,
      attendStatus: item?.attendData.attendStatus === 0 ? "Not Attended" : item?.attendData.attendStatus === 1 ? "Attending" : item?.attendData.attendStatus === 2 ? "Absent" : item?.attendData.attendStatus === 3 ? "Completed" : "",
      rank: item?.rank,
    })),
  }
  return (
    <div className="page-content">
      <div className="container-fluid">

        <Row>
          <Col className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="mb-0"></h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <BreadcrumbItem>
                    <Link to="/dashboad">Home</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    <Link to="/rank_list">Rank List</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    <Link to="#">{tableData.test}</Link>
                  </BreadcrumbItem>
                </ol>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xl="12">
            {/* First Dialogue */}
            <div>
              <div className="row">
                <div className="col-xl-3">
                  <div
                    className="card"
                    style={{
                      // width: "fit-content",
                      // width: "351px",
                      height: "max-content",
                      boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                    }}
                  >
                    <div className="card-body">
                      <div className="float-end dropdown"></div>
                      <h5
                        className="mt-2 mb-2"
                        style={{
                          textAlign: "center",
                          fontFamily: "IBM Plex Sans,sans-serif",
                        }}
                      >  {tableData.test}</h5>

                      <Divider />

                      <div
                        className="mt-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className="mb-1">Unique ID:</p>
                        <h5 className="font-size-14">{tableData.UniqueID}</h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />

                      {/* <hr style={{ margin: "0", color: "#00afc3" }} /> */}
                      <div
                        className="mt-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className="mb-1">Total Ranks:</p>
                        <h5 className="font-size-14">{tableData.rank1}</h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div
                        className="mt-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className="mb-1">Status:</p>
                        <h5 className="font-size-14">{tableData.status1}</h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div
                        className="mt-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className="mb-1">Published Date:</p>
                        <h5
                          className="font-size-14"
                          style={{ fontWeight: "500" }}
                        >{tableData?.publishedDate
                          ? moment(tableData?.publishedDate).format("DD-MM-YYYY") : ""} </h5>
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div
                        className="mt-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className="mb-1">Published Time:</p>
                        <h5
                          className="font-size-14"
                          style={{ fontWeight: "500" }}
                        >{tableData?.publishedTime
                          ? moment(tableData?.publishedTime, "hh:mm:ss").format("hh:mm A") : ""} </h5>{" "}
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                      <div
                        className="mt-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className="mb-1">Published By:</p>
                        <h5
                          className="font-size-14"
                          style={{ fontWeight: "500" }}
                        >{tableData.added} </h5>{" "}
                      </div>
                      <hr style={{ margin: "0", color: "#00afc3" }} />
                    </div>
                  </div>
                </div>

                <div className="col-xl-9" style={{ marginBottom: "30px" }}>
                  <div
                    className="mb-0 card"
                    style={{
                      boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                      width: "100%",
                    }}
                  >
                    <Box sx={{ width: "100%", typography: "body1" }}>
                      <TabContext value={tab3}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                          <TabList
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            onChange={handleChangeTab}
                            aria-label="lab API tabs example"
                          >
                            <Tab
                              icon={
                                <SummarizeIcon style={{ fontSize: "20px" }} />
                              }
                              label="Test log"
                              value="2"
                              style={{
                                textTransform: "capitalize",
                                fontFamily: "IBM Plex Sans,sans-serif",
                              }}
                            />
                          </TabList>
                        </Box>

                        <TabPanel
                          value="2"
                          style={{
                            fontSize: "13px",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}
                        >
                          <Row>
                            <Row>
                              <Col md="3">
                                <Button
                                  //  onClick={fetchallCourseListexport}
                                  color="primary"
                                  className="mx-2"
                                >
                                  <CSVLink
                                    data={dataToBeExported}
                                    filename={
                                      "Courselist_" +
                                      ` "All"}` +
                                      ".xls"
                                    }
                                    style={{ color: "white" }}
                                  >
                                    Export
                                    <i class='fas fa-file-export mx-2'></i>
                                  </CSVLink>
                                </Button>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="col-12">
                                <MDBDataTable
                                  id="ranklistID"
                                  responsive
                                  bordered
                                  data={data}
                                  searching={true}
                                  info={true}
                                  disableRetreatAfterSorting={true}
                                />
                              </Col>
                            </Row>
                          </Row>
                        </TabPanel>
                      </TabContext>
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default RankListDetail
