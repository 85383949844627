/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Row, Col, Button, Label, Tooltip } from "reactstrap"
import Select from "react-select"
import { get, post, put } from "../../../../helpers/api_helper"
import toastr from "toastr"
import axios from "axios"
import { useSelector, useDispatch } from "react-redux"
import { PRIVILEGES } from "../../../../config"
import {
  getOperatingPartner,
  getTrainingPartner,
  getCertificationPartner,
  getSectors,
  getBranches,
} from "../../../../store/options/actions"
import { API_URL } from "../../../../config"
import { CourseContext } from ".."
import { AiOutlineEye } from "react-icons/ai"
const CourseDetails = props => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [isDisabled, setisDisabled] = useState(false)

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen)
  const main = useContext(CourseContext)
  console.log("main", main);
  const Dataprop = props.data
  const [master, setmaster] = useState({ assets: {}, prerequisite: [{}] })
  useEffect(() => {
    if (Object.keys(props.data).length && main?.courseID?.objID) {
      setisDisabled(false)
      let data = { ...Dataprop }
      if (data.prerequisite.length === 0) data.prerequisite = [{}]
      setSelectedSector(data.sector)
      setselectedstream(data.stream)
      data.sector = data.sector.value
      // data.stream = data.stream
      //unit
      if (data.unit === "1") {
        setselecetdunit({ label: "Hours", value: "1" })
      } else if (data.unit === "2") {
        setselecetdunit({ label: "Days", value: "2" })
      } else if (data.unit === "3") {
        setselecetdunit({ label: "Months", value: "3" })
      }
      //qp level 1-10 static
      if (data.qpLevel === "1") {
        setselectQplevel({ label: "1", value: "1" })
      } else if (data.qpLevel === "2") {
        setselectQplevel({ label: "2", value: "2" })
      } else if (data.qpLevel === "3") {
        setselectQplevel({ label: "3", value: "3" })
      } else if (data.qpLevel === "4") {
        setselectQplevel({ label: "4", value: "4" })
      } else if (data.qpLevel === "5") {
        setselectQplevel({ label: "5", value: "5" })
      } else if (data.qpLevel === "6") {
        setselectQplevel({ label: "6", value: "6" })
      } else if (data.qpLevel === "7") {
        setselectQplevel({ label: "7", value: "7" })
      } else if (data.qpLevel === "8") {
        setselectQplevel({ label: "8", value: "8" })
      } else if (data.qpLevel === "9") {
        setselectQplevel({ label: "9", value: "9" })
      } else if (data.qpLevel === "10") {
        setselectQplevel({ label: "10", value: "10" })
      }
      //course mode
      if (data.mode === "0") {
        setselectedMode({ label: "Online", value: 0 })
      } else if (data.mode === "1") {
        setselectedMode({ label: "Offline", value: "1" })
      } else if (data.mode === "2") {
        setselectedMode({ label: "Blended", value: 2 })
      }
      if (data.programMode === 1) {
        setselecetedProgramMode({ label: "NSQF", value: 1 })
      } else if (data.programMode === 2) {
        setselecetedProgramMode({ label: "Industry", value: 2 })
      } else if (data.programMode === 3) {
        setselecetedProgramMode({ label: "ASAP NSQF", value: 3 })
      } else if (data.programMode === 4) {
        setselecetedProgramMode({ label: "ASAP Course", value: 4 })
      } else if (data.programMode === 5) {
        setselecetedProgramMode({ label: "Accredited", value: 5 })
      } else if (data.programMode === 6) {
        setselecetedProgramMode({ label: "Operating Partner Course", value: 6 })
      }
      const genderOptions = data?.gender?.map(value => {
        switch (value) {
          case 1:
            return { value: 1, label: "Male" }
          case 0:
            return { value: 2, label: "Female" }
          default:
            return { value: 3, label: "Other" }
        }
      })
      setselectedgender(genderOptions)
      setselectedcertificationpartner(data.certificationPartner)
      setselectedtrainingpartners(data.trainingPartner)
      setSelectedOperatingPartner(data.operatingPartner)
      setselectedqualification(data.qualifications)
      setselectedDegree(data.degrees)
      setselectedCourseTag(data.courseTag)
      setslectedCsp(data.branches)
      setselectedstream(data.stream)
      const branches = data?.branches?.map(branches => branches._id)
      const certificationPartnerId = data?.certificationPartner?.map(
        partner => partner._id
      )
      const operatingPartnerId = data?.operatingPartner?.map(
        partner => partner._id
      )
      const tarningPartnerId = data?.trainingPartner?.map(
        partner => partner._id
      )
      const streams = data?.stream?.map(stream => stream._id)
      data.certificationPartner = certificationPartnerId
      data.operatingPartner = operatingPartnerId
      data.trainingPartner = tarningPartnerId
      data.courseTag = data.courseTag.value
      data.branches = branches
      data.stream = streams
      setmaster(data)
    }
  }, [props.data])
  const formRef = useRef()
  const [selectedOperatingPartner, setSelectedOperatingPartner] = useState(null)
  const [selectedtrainingpartners, setselectedtrainingpartners] = useState(null)
  const [selectedcertificationpartner, setselectedcertificationpartner] =
    useState(null)
  const [selectedSector, setSelectedSector] = useState(null)
  const [selecetedProgramMode, setselecetedProgramMode] = useState(null)
  const [qualificationoptions, setqualificationoptions] = useState([])
  const [selectedqualification, setselectedqualification] = useState(null)
  const [selectedDegree, setselectedDegree] = useState(null)
  const [degreeoptions, setdegreeoptions] = useState([])
  const [selectedstream, setselectedstream] = useState(null)
  const [streamOptions, setstreamOptions] = useState([])
  const [selecetdunit, setselecetdunit] = useState(null)
  const [selectedgender, setselectedgender] = useState(null)
  const [selectedMode, setselectedMode] = useState(null)
  const [selectQplevel, setselectQplevel] = useState(null)
  const [courseTagoptions, setcourseTagoptions] = useState([])
  const [selectedCourseTag, setselectedCourseTag] = useState(null)
  const [slectedCsp, setslectedCsp] = useState(null)
  const fieldTypeOptions = [
    { label: "Text", value: "text" },
    { label: "Image", value: "file" },
    { label: "Number", value: "number" },
  ]
  //redux option call
  const {
    operatingPartners,
    trainingPartners,
    certificationPartners,
    sectors,
    branches,
  } = useSelector(state => state.Options)
  const dispatch = useDispatch()
  useEffect(() => {
    fetchAllQualification()
    // fetchAllDegree()
    // fetchallStream()
    fetchAllCourseTag()
    dispatch(getTrainingPartner({ type: PRIVILEGES.TRAINING_PARTNER }))
    dispatch(
      getCertificationPartner({ type: PRIVILEGES.CERTIFICATION_PARTNER })
    )
    dispatch(getOperatingPartner({ type: PRIVILEGES.OPERATING_PARTNER }))
    dispatch(getSectors())
    dispatch(getBranches())
  }, [])

  function fetchAllQualification() {
    get(`/options/qualifications`).then(res => {
      let result = res.data
      setqualificationoptions(result)
    })
  }

  function fetchAllDegree(ids = []) {
    get(`/options/degree?qualification=${ids.join(",")}`).then(res => {
      let result = res.data
      setdegreeoptions(result)
    })
  }

  function fetchallStream(ids = []) {
    get(`/options/stream?degree=${ids.join(",")}`).then(res => {
      let result = res.data
      setstreamOptions(result)
    })
  }

  function fetchAllCourseTag() {
    get(`/options/course-tags`).then(res => {
      setcourseTagoptions(res.data)
    })
  }

  function handleSelect(selected, name) {
    switch (name) {
      case "sector":
        setSelectedSector(selected)
        setmaster({
          ...master,
          sector: selected.value,
        })
        break
      case "courseTag":
        setselectedCourseTag(selected)
        setmaster({
          ...master,
          courseTag: selected.value,
        })
        break
      case "unit":
        setselecetdunit(selected)
        setmaster({
          ...master,
          unit: selected.value,
        })
        break
      case "mode":
        setselectedMode(selected)
        setmaster({
          ...master,
          mode: selected.value,
        })
        break
      case "programMode":
        setselecetedProgramMode(selected)
        setmaster({
          ...master,
          programMode: selected.value,
        })
        break
      case "operatingPartner":
        setSelectedOperatingPartner(selected)
        setmaster({
          ...master,
          operatingPartner: selected?.map(item => item?.value),
        })
        break
      case "trainingPartner":
        setselectedtrainingpartners(selected)
        setmaster({
          ...master,
          trainingPartner: selected?.map(item => item?.value),
        })
        break
      case "certificationPartner":
        setselectedcertificationpartner(selected)
        setmaster({
          ...master,
          certificationPartner: selected?.map(item => item?.value),
        })
        break
      case "qualifications":
        let qualifications = selected?.map(item => item?.value)
        setselectedqualification(selected)
        setmaster({
          ...master,
          qualifications,
        })
        fetchAllDegree(qualifications)
        setselectedDegree(null)
        setselectedstream(null)
        break
      case "degrees":
        setselectedDegree(selected)
        let degrees = selected?.map(item => item?.value)
        setmaster({ ...master, degrees })
        fetchallStream(degrees)
        setselectedstream(null)
        break
      case "stream":
        setselectedstream(selected)
        let steams = selected?.map(item => item?.value)
        setmaster({
          ...master,
          stream: steams,
        })
        break
      case "gender":
        setselectedgender(selected)
        setmaster({ ...master, gender: selected?.map(item => item?.value) })
        break
      case "branches":
        setslectedCsp(selected)
        setmaster({ ...master, branches: selected?.map(item => item?.value) })
        break
      case "qpLevel":
        setselectQplevel(selected)
        setmaster({ ...master, qpLevel: selected.value })
        break
      default:
        break
    }
  }

  function handleChnage(e) {
    let { name, value } = e.target
    if (name === "video") {
      setmaster(prevState => ({
        ...prevState,
        assets: { ...prevState.assets, [name]: value },
      }))
    } else {
      setmaster({ ...master, [name]: value })
    }
  }
  // const maxAgeLimit = (value, ctx, input, cb) => {
  //   if (parseInt(value) < parseInt(master.ageTo)) {
  //     return cb("Maximum Strength must be greater than Minimum Strength")
  //   }
  //   return cb(true)
  // }
  const handleLogoUpload = (e, api) => {
    let name = e.target.name
    const file = e.target.files[0]
    // const form = e.target.form;

    // Check if file is an image or PDF
    if (name === "image" || name === "thumbnail") {
      // If file is an image
      if (!file.type.startsWith("image/")) {
        toastr.error("Please upload an image file.")
        e.target.form.reset()
        return
      }

      // Create a new FileReader instance to read the image dimensions
      const reader = new FileReader()
      reader.onload = function (event) {
        const image = new Image()
        image.src = event.target.result
        image.onload = function () {
          const width = this.width
          const height = this.height

          if (width !== 800 && height !== 600) {
            toastr.error(`Dimensions must be exactly 800x600 pixels.`)
            e.target.value = ""
            return
          }

         
          // Check file size
          const fileSizeKB = Math.round(file.size / 1024)
          
          if (fileSizeKB > 200) {
            toastr.error(`Maximum file size is 200 KB.`)
            e.target.value = ""
            return
          }

          // If all checks pass, proceed with uploading
          const fd = new FormData()
          fd.append(name, file)
          axios
            .post(`${API_URL}${api}`, fd)
            .then(response => {
              if (response.data.status === 200) {
                handleValueChange(response.data.data.new_filename, name)
              } else {
                toastr.error(response.data.message)
              }
            })
            .catch(error => {
              toastr.error("An error occurred while uploading the image.")
            })
        }
      }
      reader.readAsDataURL(file)
    } else {
      
      if (file.type !== "application/pdf") {
        toastr.error("Please upload a PDF file.")
        e.target.form.reset()

        return
      }
      const fileSizeKB = Math.round(file.size / 1024)
      if (fileSizeKB > 5 * 1024) {
        toastr.error(`Maximum file size for PDF is 5 MB.`)
        return
      }

      const fd = new FormData()
      fd.append(name, file)
      axios
        .post(`${API_URL}${api}`, fd)
        .then(response => {
          if (response.data.status === 200) {
            handleValueChange(response.data.data.new_filename, name)
          } else {
            toastr.error(response.data.message)
          }
        })
        .catch(error => {
          console.log("errrr", error)
          toastr.error("An error occurred while uploading the file.")
        })
    }
  }

  const handleValueChange = async (value, name) => {
    const updatedAssets = { ...master.assets }
    updatedAssets[name] = value
    setmaster(prevState => ({
      ...prevState,
      assets: updatedAssets,
    }))
  }
  // submit 
  function handleSubmit(e) {
    e.preventDefault()
    const updatedPrerequisites = master.prerequisite.filter(
      item => Object.keys(item).length !== 0
    )
    const updatedMaster = { ...master, prerequisite: updatedPrerequisites }
    if (main.courseID.objID) {
      setisDisabled(true)
      put(`/course`, updatedMaster)
        .then(res => {
          toastr.success(res.message)
          main.setCourseID({ objID: res.data._id })
          main.setactiveTabJustify("2")
          setisDisabled(false)
        })
        .catch(err => {
          toastr.error(err.response.data.message)
          setisDisabled(false)
        })
    } else {
      setisDisabled(true)
      post(`/course`, updatedMaster)
        .then(res => {
          main.setCourseID(pre => ({ objID: res.data._id }))
          toastr.success(res.message)
          main.setactiveTabJustify("2")
          setisDisabled(false)
        })
        .catch(err => {
          toastr.error(err.response.data.message)
          setisDisabled(false)
        })
    }
  }
  const validateAgeTo = () => {
    const { ageFrom, ageTo } = master
    if (ageFrom && ageTo && parseInt(ageTo) <= parseInt(ageFrom)) {
      return " must be greater than Age From"
    }
    return true
  }
  //type select
  const handlePreValueChange = (value, name, index) => {
    const data = { ...master }
    const pre = data.prerequisite
    pre[index][name] = value
    data.prerequisite = pre
    setmaster(data)
  }

  function handleReset() {
    formRef.current.reset()
    setmaster({ assets: {}, prerequisite: [{}] })
    setselecetdunit(null)
    setselectedcertificationpartner(null)
    setselecetedProgramMode(null)
    setselectQplevel(null)
    setselectedDegree(null)
    setselectedMode(null)
    setselectedgender(null)
    setselectedqualification(null)
    setselectedstream(null)
    setselectedtrainingpartners(null)
    setSelectedOperatingPartner(null)
    setSelectedSector(null)
    setselectedCourseTag(null)
    setslectedCsp(null)
    main.setCourseID({})
  }
  const validateDuration = value => {
    const duration = parseInt(value)
    if (isNaN(duration) || duration < 0) {
      return "Duration cannot be negative"
    }
    return true // Validation passed
  }
  return (
    <>
      <AvForm ref={formRef} onValidSubmit={handleSubmit}>
        <Row>
          <Col md="3">
            <div className="mb-3">
              <Label>CSP</Label>
              <Select
                isMulti
                name="branches"
                classNamePrefix="select2-selection"
                options={branches}
                value={slectedCsp}
                onChange={selected => {
                  handleSelect(selected, "branches")
                }}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-3">
              <Label>Course Name</Label>
              <AvField
                name="name"
                className="form-control"
                type="text"
                placeholder="Course Name"
                errorMessage="Enter Course"
                onChange={handleChnage}
                validate={{ required: { value: true } }}
                value={master?.name || ""}
              />
            </div>
          </Col>
          <Col md="3">
            <div className="mb-3">
              <Label>Course Tag</Label>
              <Select
                name="courseTag"
                classNamePrefix="select2-selection"
                options={courseTagoptions}
                value={selectedCourseTag}
                onChange={selected => {
                  handleSelect(selected, "courseTag")
                }}
              />
            </div>
          </Col>
          <Col md={2}>
            <div className="mb-3">
              <Label>Course Code</Label>
              <AvField
                name="code"
                className="form-control"
                placeholder="Course Code"
                onChange={handleChnage}
                validate={{ required: { value: true } }}
                value={master?.code || ""}
              />
            </div>
          </Col>
          <Col md="3">
            <div className="mb-3">
              <Label>Sector</Label>
              <Select
                name="sector"
                classNamePrefix="select2-selection"
                options={sectors}
                value={selectedSector}
                onChange={selected => {
                  handleSelect(selected, "sector")
                }}
              />
            </div>
          </Col>
          <Col md="2">
            <div className="mb-3">
              <Label>Duration</Label>
              <AvField
                name="duration"
                type="number"
                placeholder="Duration"
                className="form-control"
                addonAfter="Duration"
                onChange={handleChnage}
                validate={{
                  required: { value: true, errorMessage: "Enter duration" },
                  custom: validateDuration, // Using custom validation function
                }}
                errorMessage="Enter duration"
                value={master?.duration || ""}
              />
            </div>
          </Col>
          <Col md="2">
            <div className="mb-3">
              <Label>Unit</Label>
              <Select
                name="unit"
                value={selecetdunit}
                validate={{ required: { value: true } }}
                options={[
                  { label: "Hours", value: "1" },
                  { label: "Days", value: "2" },
                  { label: "Months", value: "3" },
                ]}
                classNamePrefix="select2-selection"
                onChange={selected => {
                  handleSelect(selected, "unit")
                }}
              />
            </div>
          </Col>
          <Col md="4">
            <div className="mb-3">
              <Label>Operating Partners</Label>
              <Select
                name="operatingPartner"
                isMulti
                classNamePrefix="select2-selection"
                options={operatingPartners}
                value={selectedOperatingPartner}
                onChange={selected => {
                  handleSelect(selected, "operatingPartner")
                }}
              />
            </div>
          </Col>
          <Col md="4">
            <div className="mb-3">
              <Label>Training Partners</Label>
              <Select
                name="trainingPartner"
                isMulti
                classNamePrefix="select2-selection"
                options={trainingPartners}
                value={selectedtrainingpartners}
                onChange={selected => {
                  handleSelect(selected, "trainingPartner")
                }}
              />
            </div>
          </Col>

          <Col md="4">
            <div className="mb-3">
              <Label>Certification Partners</Label>
              <Select
                isMulti
                name="certificationPartner"
                classNamePrefix="select2-selection"
                options={certificationPartners}
                value={selectedcertificationpartner}
                onChange={selected => {
                  handleSelect(selected, "certificationPartner")
                }}
              />
            </div>
          </Col>

          <Col md="2">
            <div className="mb-3">
              <Label>Program Mode</Label>
              <Select
                name="programMode"
                classNamePrefix="select2-selection"
                value={selecetedProgramMode}
                options={[
                  { label: "NSQF", value: 1 },
                  { label: "Industry", value: 2 },
                  { label: "ASAP NSQF", value: 3 },
                  { label: "ASAP Course", value: 4 },
                  { label: "Accredited", value: 5 },
                  {
                    label: "Operating Partner Course",
                    value: 6,
                  },
                ]}
                onChange={selected => {
                  handleSelect(selected, "programMode")
                }}
              />
            </div>
          </Col>

          <Col md="2">
            <div className="mb-3">
              <Label>Qualification Pack Code</Label>
              <AvField
                name="qpCode"
                type="text"
                className="form-control"
                placeholder="Qualification Pack Code"
                onChange={handleChnage}
                value={master?.qpCode || ""}
              />
            </div>
          </Col>

          <Col md="2">
            <div className="mb-3">
              <Label>QP Level</Label>
              <Select
                name="qpLevel"
                value={selectQplevel}
                classNamePrefix="select2-selection"
                options={[
                  { label: "1", value: "1" },
                  { label: "2", value: "2" },
                  { label: "3", value: "3" },
                  { label: "4", value: "4" },
                  { label: "5", value: "5" },
                  { label: "6", value: "6" },
                  { label: "7", value: "7" },
                  { label: "8", value: "8" },
                  { label: "9", value: "9" },
                  { label: "10", value: "10" },
                ]}
                onChange={selected => {
                  handleSelect(selected, "qpLevel")
                }}
              />
            </div>
          </Col>

          <Col md="2">
            <div className="mb-3">
              <Label>Qualification</Label>
              <Select
                name="qualifications"
                isMulti
                classNamePrefix="select2-selection"
                options={qualificationoptions}
                value={selectedqualification}
                onChange={selected => {
                  handleSelect(selected, "qualifications")
                }}
              />
            </div>
          </Col>

          <Col md="2">
            <div className="mb-3">
              <Label>Degree</Label>
              <Select
                isMulti
                name="degrees"
                classNamePrefix="select2-selection"
                options={degreeoptions}
                value={selectedDegree}
                onChange={selected => {
                  handleSelect(selected, "degrees")
                }}
              />
            </div>
          </Col>

          <Col md="2">
            <div className="mb-3">
              <Label>Stream</Label>
              <Select
                name="stream"
                isMulti
                classNamePrefix="select2-selection"
                options={streamOptions}
                value={selectedstream}
                onChange={selected => {
                  handleSelect(selected, "stream")
                }}
              />
            </div>
          </Col>

          <Col md="2">
            <div className="mb-3">
              <Label>Gender Specific</Label>
              <Select
                isMulti
                name="gender"
                classNamePrefix="select2-selection"
                value={selectedgender}
                options={[
                  { label: "Male", value: 1 },
                  { label: "Female", value: 0 },
                  // { label: "Transgender", value: 2 },
                  { label: "Other", value: 3 },
                ]}
                onChange={selected => {
                  handleSelect(selected, "gender")
                }}
              />
            </div>
          </Col>

          <Col md="4">
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label>Age From</Label>
                  <AvField
                    name="ageFrom"
                    className="form-control"
                    type="number"
                    min={0}
                    max={99}
                    placeholder="Age From"
                    onChange={handleChnage}
                    value={master?.ageFrom || ""}
                    errorMessage="Invalid age"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label>Age To</Label>
                  <AvField
                    name="ageTo"
                    className="form-control"
                    type="number"
                    min={0}
                    max={99}
                    placeholder="Age To"
                    onChange={handleChnage}
                    value={master?.ageTo || ""}
                    errorMessage="Invalid age"
                    validate={{
                      custom: validateAgeTo,
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Col>

          <Col md="2">
            <div className="mb-3">
              <Label>Course Mode</Label>
              <Select
                name="mode"
                classNamePrefix="select2-selection"
                options={[
                  { label: "Online", value: 0 },
                  { label: "Offline", value: 1 },
                  { label: "Blended", value: 2 }
                ]}
                onChange={selected => {
                  handleSelect(selected, "mode")
                }}
                value={selectedMode}
              />
            </div>
          </Col>

          <Col md="2">
            <div className="mb-3">
              <Label>Image </Label>
              {master?.assets?.image ? (
                <div div className="img-wraps d-flex flex-column">
                  <img
                    alt=""
                    width="150"
                    height="150"
                    src={`${API_URL}${master?.assets?.image}`}
                  />
                  <button
                    className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                    onClick={() => {
                      setmaster(prevState => ({
                        ...prevState,
                        assets: {
                          ...prevState.assets,
                          image: null,
                        },
                      }))
                    }}
                    style={{ width: "150px" }}
                    type="button"
                  >
                    Delete
                  </button>
                </div>
              ) : (
                <input
                  name="image"
                  className="form-control"
                  type="file"
                  onChange={e => handleLogoUpload(e, "course/image")}
                  errorMessage="Please select an image"
                />
              )}
            </div>
            <AiOutlineEye
              id="imageTooltip"
              size={15}
              style={{
                cursor: "pointer",
                marginLeft: "5px",
                marginTop: "-25px",
              }}
              onMouseOver={toggleTooltip}
              onMouseOut={toggleTooltip}
            />
            <Tooltip
              placement="top"
              isOpen={tooltipOpen}
              target="imageTooltip"
              toggle={toggleTooltip}
            >
              Specifications: Image should be of type PNG or JPEG.Dimensions must be exactly 800x600 pixels
              Size Should be lessthan 200kb
            </Tooltip>
          </Col>

          <Col md="2"> 
            <div className="mb-3">
              <Label>Thumbnail </Label>
              {master?.assets?.thumbnail ? (
                <div div className="img-wraps d-flex flex-column">
                  <img
                    alt=""
                    width="150"
                    height="150"
                    src={`${API_URL}${master?.assets?.thumbnail}`}
                  />
                  <button
                    className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                    onClick={() => {
                      setmaster(prevState => ({
                        ...prevState,
                        assets: {
                          ...prevState.assets,
                          thumbnail: null,
                        },
                      }))
                    }}
                    style={{ width: "150px" }}
                    type="button"
                  >
                    Delete
                  </button>
                </div>
              ) : (
                <input
                  name="thumbnail"
                  className="form-control"
                  type="file"
                  onChange={e => handleLogoUpload(e, "course/image/thumbnail")}
                />
              )}
            </div>
            <AiOutlineEye
              id="imageTooltip"
              size={15}
              style={{
                cursor: "pointer",
                marginLeft: "5px",
                marginTop: "-25px",
              }}
              onMouseOver={toggleTooltip}
              onMouseOut={toggleTooltip}
            />
            <Tooltip
              placement="top"
              isOpen={tooltipOpen}
              target="imageTooltip"
              toggle={toggleTooltip}
            >
              Specifications: Image should be of type PNG or JPEG.Dimensions must be exactly 800x600 pixels
              Size Should be lessthan 200kb
            </Tooltip>
          </Col>

          <Col md="2">
            <div className="mb-3">
              <Label>
                Brochure <span></span>{" "}
              </Label>
              {master?.assets?.brochure ? (
                <div className="img-wraps d-flex flex-column">
                  <object
                    type="application/pdf"
                    width="150"
                    height="150"
                    data={`${API_URL}${master?.assets?.brochure}`}
                  />
                  <button
                    className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                    onClick={() => {
                      setmaster(prevState => ({
                        ...prevState,
                        assets: {
                          ...prevState.assets,
                          brochure: null,
                        },
                      }))
                    }}
                    style={{ width: "150px" }}
                    type="button"
                  >
                    Delete
                  </button>
                  <a
                    className="mt-2"
                    style={{ fontStyle: "italic" }}
                    href={`${API_URL}${master?.assets?.brochure}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Brochure
                  </a>
                </div>
              ) : (
                <input
                  name="brochure"
                  className="form-control"
                  type="file"
                  onChange={e => handleLogoUpload(e, "course/image/brochure")}
                />
              )}
            </div>
          </Col>

          <Col md="2">
            <div className="mb-3">
              <Label>Syllabus</Label>
              {master?.assets?.syllabus ? (
                <div div className="img-wraps d-flex flex-column">
                  <object
                    alt=""
                    type="application/pdf"
                    width="150"
                    height="150"
                    data={`${API_URL}${master?.assets?.syllabus}`}
                  />
                  <button
                    className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                    onClick={() => {
                      setmaster(prevState => ({
                        ...prevState,
                        assets: {
                          ...prevState.assets,
                          syllabus: null,
                        },
                      }))
                    }}
                    style={{ width: "150px" }}
                    type="button"
                  >
                    Delete
                  </button>
                  <a
                    className="mt-2"
                    style={{ fontStyle: "italic" }}
                    href={`${API_URL}${master?.assets?.syllabus}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Syllabus
                  </a>
                </div>
              ) : (
                <input
                  name="syllabus"
                  className="form-control"
                  type="file"
                  onChange={e => handleLogoUpload(e, "course/image/syllabus")}
                />
              )}
            </div>
          </Col>

          <Col md="3">
            <div className="mb-3">
              <Label>Video</Label>
              <AvField
                name="video"
                className="form-control"
                type="link"
                min={0}
                placeholder="Video URl"
                onChange={handleChnage}
                value={master?.assets?.video || ""}
                validate={{
                  // Add validation for URL
                  // required: { value: true, errorMessage: "Please enter a URL" },
                  pattern: {
                    value: /^(ftp|http|https):\/\/[^ "]+$/,
                    errorMessage: "Invalid URL",
                  },
                }}
              />
            </div>
          </Col>

          <Col md="12">
            <Row>
              <Label>Enter extra required fields during registration</Label>

              {master?.prerequisite?.map((item, idx) => (
                <Col sm="6" md="4" xxl="3" key={idx}>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3 no-margin">
                        <Label>Field</Label>
                        <AvField
                          name="field"
                          className="form-control mb-0"
                          type="text"
                          placeholder="Field"
                          value={item.field || ""}
                          onChange={e =>
                            handlePreValueChange(e.target.value, "field", idx)
                          }
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div>
                        <Label>Type</Label>
                        <Select
                          name="type"
                          classNamePrefix="select2-selection"
                          options={fieldTypeOptions}
                          value={fieldTypeOptions.filter(
                            type => type.value === item.type
                          )}
                          onChange={selected =>
                            handlePreValueChange(selected.value, "type", idx)
                          }
                        />
                        <div className="d-flex justify-content-end align-items-center mt-1">
                          {master?.prerequisite?.length - 1 === idx ? (
                            <span
                              onClick={() => {
                                const data = { ...master }
                                data.prerequisite.push({})
                                setmaster(data)
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              Add more{" "}
                              <i className="mdi mdi-plus-circle-outline"></i>
                            </span>
                          ) : (
                            <span
                              onClick={() => {
                                const data = { ...master }
                                data.prerequisite.splice(idx, 1)
                                setmaster(data)
                              }}
                              style={{
                                fontSize: "1.125rem",
                                cursor: "pointer",
                              }}
                            >
                              <i className="trash-btn mdi mdi-delete"></i>
                            </span>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Col>

          <Col md="12" className="d-flex justify-content-end">
            <div className="mb-3 mt-4 pt-1 me-1">
              <Button
                className="mx-2"
                disabled={isDisabled ? true : false}
                color="primary"
                type="submit"
              >
                Save & Next
              </Button>
              <Button onClick={handleReset} color="danger" type="button">
                Reset
              </Button>
            </div>
          </Col>
        </Row>
      </AvForm>
    </>
  )
}

export default CourseDetails
