import React, { useCallback, useState } from "react"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import { Box } from "@mui/material"
import StudentCommonTab from "../../../../components/Student"
import { Row, Col, Label } from "reactstrap"
import moment from "moment"
import { get } from "../../../../helpers/api_helper"
import { useEffect } from "react"
import FollowupTable from "./FollowupTable"
import Documents from "./Documents"
import Payments from "./Payments"
// import Followup from "./Followup"

const Followup = ({ followup }) => {
  const [leadData, setleadData] = useState({})
  const [tab, setTab] = useState("1")

  useEffect(() => {
    if (followup.leadId) {
      getLeadSingle(followup.leadId)
    }
    // eslint-disable-next-line
  }, [followup.leadId])

  const handleChangeTab = (event, newValue) => {
    setTab(newValue)
  }

  const getLeadSingle = useCallback(id => {
    get(`leads/${id}`).then(res => {
      let data = res.data

      if (data?.courseFee?.courseFee) {
        const fees = calculateTax(
          data?.courseFee?.courseFee,
          data?.courseFee?.gst
        )

        data = { ...data, ...fees }
      }

      setleadData(data)
    })
  }, [])

  const refresh = () => {
    getLeadSingle(followup.leadId)
  }

  const calculateTax = (amount, gst) => {
    if (gst === "0" || !gst) {
      return {
        taxableValue: amount,
        taxAmount: 0,
      }
    }
    const taxableValue = (amount - (amount - amount / Number(gst))).toFixed(0)
    const taxAmount = (amount - taxableValue).toFixed(0)

    return {
      taxableValue,
      taxAmount,
    }
  }

  return (
    <>
      <div className="modal-body">
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              onChange={handleChangeTab}
              aria-label="lab API tabs example"
            >
              <Tab
                label="Lead Info"
                value="1"
                style={{
                  textTransform: "capitalize",
                  fontFamily: "IBM Plex Sans,sans-serif",
                }}
              />
              <Tab
                label="Student"
                value="2"
                style={{
                  textTransform: "capitalize",
                  fontFamily: "IBM Plex Sans,sans-serif",
                }}
              />
              <Tab
                label="Documents"
                value="3"
                style={{
                  textTransform: "capitalize",
                  fontFamily: "IBM Plex Sans,sans-serif",
                }}
              />
              <Tab
                label="Payments"
                value="4"
                style={{
                  textTransform: "capitalize",
                  fontFamily: "IBM Plex Sans,sans-serif",
                }}
              />
            </TabList>
          </Box>

          <TabPanel
            value="1"
            style={{
              fontSize: "13px",
              fontFamily: "IBM Plex Sans,sans-serif",
            }}
          >
            <Row>
              <Col md="12">
                <ul className="lead-details">
                  <li>
                    ID :<Label> {leadData?.reg_id?.unique_id}</Label>
                  </li>
                  <li>
                    Name :{" "}
                    <Label>
                      {leadData?.reg_id?.firstname || ""}{" "}
                      {leadData?.reg_id?.lastname}
                    </Label>
                  </li>
                  <li>
                    Mobile : <Label>{leadData?.reg_id?.mobile}</Label>
                  </li>
                  <li hidden={!leadData?.reg_id?.email}>
                    Email : <Label>{leadData?.reg_id?.email}</Label>
                  </li>
                  <li hidden={!leadData?.reg_id?.city}>
                    City :<Label>{leadData?.reg_id?.city}</Label>
                  </li>
                  <li hidden={!leadData?.branch?.name}>
                    CSP : <Label>{leadData?.branch?.name}</Label>
                  </li>
                  <li hidden={!leadData?.reg_id?.district?.district_name}>
                    District :
                    <Label>{leadData?.reg_id?.district?.district_name}</Label>
                  </li>
                  <li hidden={!leadData?.reg_id?.state?.name}>
                    State :<Label>{leadData?.reg_id?.state?.name}</Label>
                  </li>
                  <li hidden={!leadData?.type}>
                    Lead Type : <Label>{leadData?.type}</Label>
                  </li>
                  <li hidden={!leadData?.source?.name}>
                    Source : <Label>{leadData?.source?.name}</Label>
                  </li>
                  <li hidden={!leadData?.source?.name}>
                    Lead from : <Label>{leadData?.from}</Label>
                  </li>
                  <li hidden={!leadData?.campaign?.name}>
                    Campaign : <Label>{leadData?.campaign?.name}</Label>
                  </li>
                  <li>
                    Date :{" "}
                    <Label>
                      {moment(leadData?.date).format("DD-MM-YYYY")}{" "}
                    </Label>
                  </li>
                  <li>
                    Time :{" "}
                    <Label>
                      {/* {moment(leadData?.date).format("DD-MM-YYYY")}{" "} */}
                      {moment(leadData?.time, "HH:mm:ss").format("hh:mm a")}
                    </Label>
                  </li>
                </ul>

                {/*//? Course Details */}
                {leadData?.course && (
                  <ul className="lead-details">
                    <li>
                      Course : <Label>{leadData?.course?.name}</Label>
                    </li>
                    <li hidden={!leadData?.taxableValue}>
                      Taxable value :{" "}
                      <Label>
                        <i className="fas fa-rupee-sign fs-6"></i>{" "}
                        {leadData?.taxableValue}
                      </Label>
                    </li>
                    <li hidden={!leadData?.taxAmount}>
                      Tax Amount:{" "}
                      <Label>
                        <i className="fas fa-rupee-sign fs-6"></i>{" "}
                        {leadData?.taxAmount}
                      </Label>
                    </li>
                    <li hidden={!leadData?.courseFee?.courseFee}>
                      Course Fee :{" "}
                      <Label>
                        <i className="fas fa-rupee-sign fs-6"></i>{" "}
                        {leadData?.courseFee?.courseFee}
                      </Label>
                    </li>
                    <li hidden={!leadData?.courseFee?.payableAmount}>
                      Payable Amount :{" "}
                      <Label>
                        <i className="fas fa-rupee-sign fs-6"></i>{" "}
                        {leadData?.courseFee?.payableAmount}
                      </Label>
                    </li>
                    <li hidden={!leadData?.courseFee?.paidAmount}>
                      Paid Amount :{" "}
                      <Label>
                        <i className="fas fa-rupee-sign fs-6"></i>{" "}
                        {leadData?.courseFee?.paidAmount}
                      </Label>
                    </li>
                    <li hidden={!leadData?.courseFee?.paidAmount}>
                      Due Amount :{" "}
                      <Label>
                        <i className="fas fa-rupee-sign fs-6"></i>{" "}
                        {/* {leadData?.purchased?.transactionId?.due} */}
                        {leadData?.courseFee?.payableAmount -
                          leadData?.courseFee?.paidAmount}
                      </Label>
                    </li>
                    <li hidden={!leadData?.coupon?.name}>
                      Coupon :{" "}
                      <span
                        style={{
                          background: "#50a5f1",
                        }}
                        className="badge  font-size-12 ms-2"
                      >
                        {leadData?.coupon?.name}
                      </span>
                    </li>
                    <li hidden={!leadData?.coupon?.name}>
                      Redeemed :
                      <Label>
                        {leadData?.coupon?.type === 0 && (
                          <i className="fas fa-rupee-sign fs-6"></i>
                        )}{" "}
                        {leadData?.offerCodeLogId?.availedAmount ||
                          leadData?.coupon?.amountOrPercentage}
                        {/* {leadData?.coupon?.type !== 1 && "%"}{" "} */}
                      </Label>
                    </li>
                  </ul>
                )}

                {/*//? Event Details */}
                {leadData?.event && (
                  <ul className="lead-details">
                    <li>
                      Event : <Label>{leadData?.event?.name}</Label>
                    </li>
                    <li>
                      Taxable value :{" "}
                      <Label>
                        <i className="fas fa-rupee-sign fs-6"></i>{" "}
                        {leadData?.event?.taxableValue}
                      </Label>
                    </li>
                    <li>
                      Tax Amount:{" "}
                      <Label>
                        <i className="fas fa-rupee-sign fs-6"></i>{" "}
                        {leadData?.event?.taxAmount}
                      </Label>
                    </li>

                    <li>
                      Event Fee :{" "}
                      <Label>
                        <i className="fas fa-rupee-sign fs-6"></i>{" "}
                        {leadData?.event?.fee}
                      </Label>
                    </li>
                    <li>
                      Paid Amount :{" "}
                      <Label>
                        <i className="fas fa-rupee-sign fs-6"></i>{" "}
                        {leadData?.eventRegistrationId?.paidAmount || 0}
                      </Label>
                    </li>
                    <li hidden={!leadData?.coupon?.name}>
                      Coupon :{" "}
                      <span
                        style={{
                          background: "#50a5f1",
                        }}
                        className="badge  font-size-12 ms-2"
                      >
                        {leadData?.coupon?.name}
                      </span>
                    </li>
                    <li hidden={!leadData?.coupon?.name}>
                      Redeemed :
                      <Label>
                        {leadData?.coupon?.type === 0 && (
                          <i className="fas fa-rupee-sign fs-6"></i>
                        )}{" "}
                        {leadData?.offerCodeLogId?.availedAmount ||
                          leadData?.coupon?.amountOrPercentage}
                        {/* {leadData?.coupon?.type !== 1 && "%"}{" "} */}
                      </Label>
                    </li>
                    <li>
                      Due Amount :{" "}
                      <Label>
                        <i className="fas fa-rupee-sign fs-6"></i>{" "}
                        {leadData?.eventRegistrationId?.due}
                      </Label>
                    </li>
                  </ul>
                )}

                <ul
                  hidden={
                    (!leadData?.paylater && !leadData?.tmpBatch?.feeEnabled) ||
                    leadData?.courseFee?.paidAmount
                  }
                  className="lead-details"
                >
                  <li
                    hidden={
                      !leadData?.paylater && !leadData?.tmpBatch?.feeEnabled
                    }
                  >
                    Payment Mode :
                    <span
                      style={{
                        background: leadData?.paylater
                          ? "#fe6800ab"
                          : "#008dfeab",
                      }}
                      className="badge  font-size-12 ms-2"
                    >
                      {leadData?.paylater ? "Pay Later" : "Fee Not Enabled"}
                    </span>
                  </li>
                </ul>

                {leadData?.skillLoan?.requested &&
                  leadData?.skillLoan?.skillLoanId && (
                    <ul className="lead-details">
                      <li>
                        Skill Loan :
                        <Label>{leadData?.skillLoan?.skillLoanId?.name}</Label>
                      </li>
                      <li>
                        Skill Loan Id :
                        <Label>
                          {leadData?.skillLoan?.skillLoanId?.uniqueId}
                        </Label>
                      </li>
                      <li
                        hidden={
                          !leadData?.skillLoan?.requestedId?.requestAmount
                        }
                      >
                        Requested Amount :{" "}
                        <Label>
                          {" "}
                          <i className="fas fa-rupee-sign fs-6"></i>{" "}
                          {leadData?.skillLoan?.requestedId?.requestAmount}
                        </Label>
                      </li>

                      <li
                        hidden={
                          !leadData?.skillLoan?.requestedId?.currentStatus?.name
                        }
                      >
                        Status :{" "}
                        <span
                          style={{
                            background:
                              leadData?.skillLoan?.requestedId?.currentStatus
                                ?.color,
                          }}
                          className="badge  font-size-12 ms-2"
                        >
                          {
                            leadData?.skillLoan?.requestedId?.currentStatus
                              ?.name
                          }
                        </span>
                      </li>
                    </ul>
                  )}

                {leadData?.scholarship?.requested &&
                  leadData?.scholarship?.scholarshipId && (
                    <ul className="lead-details">
                      <li>
                        Scholarship :
                        <Label>
                          {leadData?.scholarship?.scholarshipId?.name}
                        </Label>
                      </li>
                      <li>
                        Scholarship Id :
                        <Label>
                          {leadData?.scholarship?.scholarshipId?.uniqueId}
                        </Label>
                      </li>
                      {/* <li>
                        Requested Amount :{" "}
                        <Label>
                          <i className="fas fa-rupee-sign fs-6"></i>{" "}
                          {leadData?.scholarship?.requestedId?.requestAmount}
                        </Label>
                      </li> */}

                      <li>
                        Status :{" "}
                        <span
                          style={{
                            background:
                              leadData?.scholarship?.requestedId?.currentStatus
                                ?.color,
                          }}
                          className="badge  font-size-12 ms-2"
                        >
                          {
                            leadData?.scholarship?.requestedId?.currentStatus
                              ?.name
                          }
                        </span>
                      </li>
                    </ul>
                  )}
              </Col>
            </Row>
            <FollowupTable
              leadId={leadData._id}
              leadType={leadData?.type}
              course={leadData?.course}
              refreshFunction={refresh}
            />
          </TabPanel>

          <TabPanel
            value="2"
            style={{
              fontSize: "13px",
              fontFamily: "IBM Plex Sans,sans-serif",
            }}
          >
            <StudentCommonTab
              studentId={followup.studentId}
              refreshFunction={refresh}
            />
            <FollowupTable leadId={leadData._id} refreshFunction={refresh} />
          </TabPanel>

          <TabPanel value="3">
            <Documents profileID={leadData?.reg_id?._id} />
          </TabPanel>
          <TabPanel value="4">
            <Payments leadId={leadData?._id} />
          </TabPanel>
        </TabContext>
      </div>
    </>
  )
}

export default Followup
