import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Collapse,
} from "reactstrap"
import { API_URL } from "../../../../config"
import { get } from "../../../../helpers/api_helper"
import classnames from "classnames"
import "./style.scss"
import moment from "moment"
const Profile = props => {
  const [modal, setModal] = useState({})
  const [activeTab, setActiveTab] = useState(null)
  useEffect(() => {
    handleTableData()
  }, [props?.id])
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Document Type",
        field: "uniqueId",
        width: 150,
      },
      {
        label: "Document Number",
        field: "jobname",
        width: 150,
      },
      {
        label: "Attached File",
        field: "Recruitername",
        width: 150,
      },
      // {
      //   label: "Applicant",
      //   field: "applicantname",
      //   width: 150,
      // },
      // {
      //   label: "Mobile",
      //   field: "applicantmobile",
      //   sort: "desc",
      //   width: 400,
      // },
      // {
      //   label: "Status",
      //   field: "status",
      //   width: 150,
      // },
      // {
      //   label: "Action",
      //   field: "action",
      //   width: 150,
      // },
    ],
  }
  function handleTableData() {
    get(`student/details/${props?.id}`)
      .then(res => {
        const result = res?.data

        setModal(result)
      })
      .catch(error => {
        console.error("Error fetching data:", error)
      })
  }

  const toggleTab = (tab)=>{
    if(activeTab === tab){
      setActiveTab(null)
    }else{
      setActiveTab(tab)
    }
  }

  return (
    <div>
      <Row>
        <Col xl={12}>
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  type="button"
                  onClick={()=>toggleTab(1)}
                  className={classnames("accordion-button", "fw-medium", {
                    collapsed: activeTab !== 1,
                  })}
                  style={{ cursor: "pointer" }}
                >
                  <h6>Qualification</h6>
                </button>
              </h2>
              <Collapse
                id="collapseTwo"
                className="accordion-collapse"
                isOpen={activeTab === 1}
              >
                <div className="accordion-body">
                  {modal?.qualifications && modal.qualifications.length > 0 ? (
                    <Row>
                      <Col md="12">
                        <table
                          id="product_available_day"
                          className="table table-bordered dataTable"
                        >
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Qualification</th>
                              <th>Degree</th>
                              <th>Stream</th>
                            </tr>
                          </thead>

                          <tbody>
                            {modal.qualifications.map((el, index) => (
                              <tr key={index}>
                                <td style={{ textAlign: "center" }}>
                                  {index + 1}
                                </td>
                                <td>{el?.qualification?.name}</td>
                                <td>{el?.degree?.name}</td>
                                <td>{el?.stream?.name}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  ) : null}
                </div>
              </Collapse>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  type="button"
                  onClick={()=>toggleTab(2)}
                  className={classnames("accordion-button", "fw-medium", {
                    collapsed: activeTab !== 2,
                  })}
                  style={{ cursor: "pointer" }}
                >
                  <h6>Experience</h6>
                </button>
              </h2>
              <Collapse
                id="collapseTwo"
                className="accordion-collapse"
                isOpen={activeTab === 2}
              >
                <div className="accordion-body">
                  {modal?.workExperience && modal.workExperience.length > 0 ? (
                    <Row>
                      <Col md="12">
                        <table
                          id="product_available_day"
                          className="table table-bordered dataTable"
                        >
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Company</th>
                              <th>Designation</th>
                              <th>From Date</th>
                              <th>To Date</th>
                            </tr>
                          </thead>

                          {modal?.workExperience?.map((el, index) => (
                            <tbody>
                              <tr>
                                <th style={{ textAlign: "center" }}>
                                  {index + 1}
                                </th>
                                <th>{el?.company}</th>
                                <th>{el?.designation}</th>
                                <th style={{ textAlign: "center" }}>
                         
                                  {moment(el?.fromDate).format("DD-MM-YYYY")}
                                </th>
                                <th style={{ textAlign: "center" }}>
                                {moment(el?.toDate).format("DD-MM-YYYY")}

                                  
                                </th>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      </Col>
                    </Row>
                  ) : null}
                </div>
              </Collapse>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className={classnames("accordion-button", "fw-medium", {
                    collapsed: activeTab !== 3,
                  })}
                  type="button"
                  onClick={()=>toggleTab(3)}
                  style={{ cursor: "pointer" }}
                >
                  <h6>Additional Details</h6>
                </button>
              </h2>
              <Collapse
                id="collapseTwo"
                className="accordion-collapse"
                isOpen={activeTab === 3}
              >
                <div className="accordion-body">
                  {modal?.additionalDetails ? (
                    <Row>
                      <Col md="12">
                        <table
                          id="product_available_day"
                          className="table table-bordered dataTable"
                        >
                          <tbody>
                            <tr>
                              <th style={{ textAlign: "start" }}>
                                Father's Name
                              </th>
                              <th> : </th>
                              <td style={{ textAlign: "start" }}>
                                {" "}
                                {modal?.additionalDetails?.father}
                              </td>
                              <th style={{ textAlign: "start" }}>
                                Mother's Name
                              </th>
                              <th> : </th>
                              <td style={{ textAlign: "start" }}>
                                {modal?.additionalDetails?.mother}
                              </td>
                            </tr>
                            <tr>
                              <th style={{ textAlign: "start" }}>
                                Guardian's Name
                              </th>
                              <th> : </th>
                              <td style={{ textAlign: "start" }}>
                                {modal?.additionalDetails?.guardian}
                              </td>
                              <th style={{ textAlign: "start" }}>
                                Constituency
                              </th>
                              <th> : </th>
                              <td style={{ textAlign: "start" }}>
                                {modal?.additionalDetails?.constituency}
                              </td>
                            </tr>
                            <tr>
                              <th style={{ textAlign: "start" }}>DWMS ID</th>
                              <th> : </th>
                              <td style={{ textAlign: "start" }}>
                                {modal?.additionalDetails?.dwmsId}
                              </td>
                              <th style={{ textAlign: "start" }}>Category</th>
                              <th> : </th>
                              <td
                                style={{ textAlign: "start", width: "205px" }}
                              >
                                {" "}
                                {modal?.additionalDetails?.category?.name}
                              </td>
                            </tr>
                            <tr>
                              <th style={{ textAlign: "start" }}>APL/BPL</th>
                              <th> : </th>
                              <td style={{ textAlign: "start" }}>
                                {modal?.additionalDetails?.povertyLine}
                              </td>
                              <th style={{ textAlign: "start" }}>
                                Minority Status
                              </th>
                              <th> : </th>
                              <td style={{ textAlign: "start" }}>
                                {modal?.additionalDetails?.minorityStatus?.name}{" "}
                              </td>
                            </tr>
                            <tr>
                              <th style={{ textAlign: "start" }}>
                                Rural / Urban
                              </th>
                              <th> : </th>
                              <td style={{ textAlign: "start" }}>
                                {modal?.additionalDetails?.typeOfArea}
                              </td>

                              <th style={{ textAlign: "start" }}>Resume</th>
                              <th> : </th>
                              <td style={{ textAlign: "start" }}>
                                {" "}
                                {modal?.assets?.resume && (
                                  <a
                                    href={`${API_URL}${modal?.assets?.resume}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <i
                                      className="fas fa-file-pdf"
                                      style={{
                                        fontSize: "1.2em",
                                        cursor: "pointer",
                                        color: "red",
                                      }}
                                    ></i>
                                  </a>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  ) : null}
                </div>
              </Collapse>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className={classnames("accordion-button", "fw-medium", {
                    collapsed: activeTab !== 4,
                  })}
                  type="button"
                  onClick={()=>toggleTab(4)}
                  style={{ cursor: "pointer" }}
                >
                  <h6>Documents</h6>
                </button>
              </h2>
              <Collapse
                id="collapseTwo"
                className="accordion-collapse"
                isOpen={activeTab === 4}
              >
                <div className="accordion-body">
                  {modal?.documents && modal.documents.length > 0 ? (
                    <Row>
                      <Col md="12">
                        <table
                          id="product_available_day"
                          className="table table-bordered dataTable"
                        >
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Date</th>
                              <th>Document Type</th>
                              <th>Document No.</th>
                              <th>Attached File</th>
                            </tr>
                          </thead>

                          {modal?.documents?.map((el, index) => (
                            <tbody>
                              <tr>
                                <th style={{ textAlign: "center" }}>
                                  {index + 1}
                                </th>
                                <th style={{ textAlign: "center" }}>
                               
                                  {moment(el?.date).format("DD-MM-YYYY")}

                                </th>
                                <th>{el?.type?.name}</th>
                                <th>{el?.number}</th>
                                <th style={{ textAlign: "center" }}>
                                  {" "}
                                  {el?.path && (
                                    <a
                                      href={API_URL + el?.path}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <i
                                        className="fas fa-file-pdf"
                                        style={{
                                          fontSize: "1.5em",
                                          cursor: "pointer",
                                          color: "red",
                                        }}
                                      ></i>
                                    </a>
                                  )}
                                </th>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      </Col>
                    </Row>
                  ) : null}
                </div>
              </Collapse>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className={classnames("accordion-button", "fw-medium", {
                    collapsed: activeTab !== 5,
                  })}
                  type="button"
                  onClick={()=>toggleTab(5)}
                  style={{ cursor: "pointer" }}
                >
                  <h6>Interested Areas</h6>
                </button>
              </h2>
              <Collapse
                id="collapseTwo"
                className="accordion-collapse"
                isOpen={activeTab === 5}
              >
                <div className="accordion-body">
                  {modal?.interestedAreas &&
                  modal.interestedAreas.length > 0 ? (
                    <Row>
                      <Col md="12">
                        <ul className="interested">
                          {modal?.interestedAreas.map(el => (
                            <>
                              <li className="interestedareas">
                                <h6 className="mb-0">{el.name}</h6>
                              </li>
                            </>
                          ))}
                          
                        </ul>
                      </Col>
                    </Row>
                  ) : null}
                </div>
              </Collapse>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Profile
