import React, { useEffect, useState } from "react"
import { Badge, BreadcrumbItem, Col, Container, Row } from "reactstrap"

//Import Breadcrumb

import { Link, useLocation, useParams } from "react-router-dom"

import { Box, Divider } from "@mui/material"

import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import LocationCityIcon from "@mui/icons-material/LocationCity"

// import Select from "react-select"
import { APP_NAME } from "../../../../../config"

// import { Modal } from "react-bootstrap"
import { get } from "../../../../../helpers/api_helper"
import Student from "./Tabs/Student"
import moment from "moment"
import { sideBarToggle } from "../../../../../helpers/functions"

const TemporaryBatchDashBoard = () => {
  const { state } = useLocation()
  const params = useParams()

  document.title = `${params.tempBatchId} | ${APP_NAME}`

  const [details, setDetails] = useState({})

  const [tab, setTab] = useState("1")

  useEffect(() => {
    get(`temp-batch/${state?.id || params?.tempBatchId}`)
      .then(res => {
        setDetails(res.data)
      })
      .catch(err => {
        console.log(err)
      })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    sideBarToggle()
    return () => sideBarToggle(false)
  }, [])

  const handleChangeTab = (event, newValue) => {
    setTab(newValue)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}

          <Row>
            <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">{params.tempBatchId}</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      <Link to="/student-dashboard">Home</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="/student-details">Temporary Batch</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="#">{params.tempBatchId}</Link>
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <div className="col-xl-3">
              <div
                className="card"
                style={{
                  height: "max-content",
                  boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                }}
              >
                <div class="status mt-2 me-2 d-flex justify-content-end cursor-pointer">
                  {/* {true ? (
                      <Badge
                        className="bg-success"
                        style={{ padding: ".5rem .8rem" }}
                      >
                        Active
                      </Badge>
                    ) : (
                      <Badge className="bg-warning">Inactive</Badge>
                    )} */}
                </div>
                <div className="card-body">
                  <h5
                    className="mt-3 mb-1"
                    style={{
                      textAlign: "center",
                      fontFamily: "IBM Plex Sans,sans-serif",
                    }}
                  >
                    {params.tempBatchId}
                  </h5>

                  <div
                    className="mt-1 mb-2"
                    style={{
                      textAlign: "center",
                      fontFamily: "IBM Plex Sans,sans-serif",
                    }}
                  ></div>
                  <Divider />
                  <div className="mt-2">
                    {/* <div className="mt-2" hidden={!details?.address}> */}
                    <p className="mb-1">Course:</p>
                    <h5 className="font-size-14">{details?.course?.name}</h5>
                  </div>
                  <div className="mt-2">
                    {/* <div className="mt-2" hidden={!details?.address}> */}
                    <p className="mb-1">CSP:</p>
                    <h5 className="font-size-14">{details?.branch?.name}</h5>
                  </div>
                  <div className="mt-2">
                    {/* <div className="mt-2" hidden={!details?.address}> */}
                    <p className="mb-1">Assigned:</p>
                    <h5 className="font-size-14">{details?.assigned?.name}</h5>
                  </div>
                  <div className="mt-2">
                    <p className="mb-1">Expected Starting Date:</p>
                    <h5 className="font-size-14">
                      {details?.expStartingDate &&
                        moment(details?.expStartingDate, "YYYY-MM-DD").format(
                          "DD-MM-YYYY"
                        )}
                    </h5>
                  </div>
                  <div className="mt-2">
                    <p className="mb-1">Min strength:</p>
                    <h5 className="font-size-14">{details?.minStrength}</h5>
                  </div>
                  <div className="mt-2">
                    <p className="mb-1">Max strength:</p>
                    <h5 className="font-size-14">{details?.maxStrength}</h5>
                  </div>
                  <div className="mt-2">
                    <p className="mb-1">Lead:</p>
                    <h5 className="font-size-14">{details?.lead}</h5>
                  </div>
                  <div className="mt-2">
                    <p className="mb-1">Paid:</p>
                    <h5 className="font-size-14">{details?.paid}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-9" style={{ marginBottom: "30px" }}>
              <div
                className="mb-0 card"
                style={{
                  boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                  width: "100%",
                }}
              >
                <TabContext value={tab}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      indicatorColor="primary"
                      textColor="primary"
                      variant="fullWidth"
                      onChange={handleChangeTab}
                      aria-label="lab API tabs example"
                    >
                      <Tab
                        icon={<LocationCityIcon style={{ fontSize: "20px" }} />}
                        label="Students"
                        value="1"
                        size="small"
                        style={{
                          textTransform: "capitalize",
                          fontFamily: "IBM Plex Sans,sans-serif",
                        }}
                      />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <Student batchId={state?.id || params?.tempBatchId} />
                  </TabPanel>
                </TabContext>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TemporaryBatchDashBoard
