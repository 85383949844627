import React, { useState } from "react"
import { Col, Label, Button } from "reactstrap"
import { Row, FormGroup } from "reactstrap"
import Select from "react-select"
import { AvField, AvForm } from "availity-reactstrap-validation"
function PurchaseForm({
  handleClose,
  name,
  setName,
  bankName,
  setBankName,
  accountNumber,
  setAccountNumber,
  accountType,
  setAccountType,
  ifscCode,
  setIfscCode,
  bankSelected,
  setBankSelected,
  accountTypeSelected,
  setAccountTypeSelected,
}) {
  const handleSubmit = e => {
    e.preventDefault()
    if (!bankName) {
      setBankSelected(true)
      return
    }

    if (!accountType) {
      setAccountTypeSelected(true)
      return
    }
  }

  const handleBankNameChange = selectedOption => {
    setBankName(selectedOption)
    setBankSelected(false)
  }

  const handleAccountTypeChange = selectedOption => {
    setAccountType(selectedOption)
    setAccountTypeSelected(false)
  }

  const submitButtonStyles = {
    marginLeft: "5px",
    marginRight: "5px",
  }

  return (
    <div class="mt-3">
      <AvForm onSubmit={handleSubmit}>
        <Row xs={12} md={3}>
          <div className="mb-3">
            <Label>Account HolderName</Label>
            <AvField
              type="text"
              name="name"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <Label>Bank Name</Label>

            <Select
             value={bankName}
              name="branch"
              options={[
                { label: "Central Bank of India", value: 0 },
                { label: "Bank of Baroda", value: 1 },
                { label: "Canara Bank", value: 2 },
                { label: "Federal Bank", value: 3 },
                { label: "State Bank of India", value: 4 },
              ]}
              onChange={handleBankNameChange}

            />
            {bankSelected && (
              <div className="text-danger">Please select a bank.</div>
            )}
          </div>
        </Row>
        <Row xs={12} md={3}>
          <div className="mb-3">
            <Label>Account Number</Label>
            <AvField
              type="text"
              name="accountNumber"
              value={accountNumber}
              onChange={e => setAccountNumber(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <Label>Account Type</Label>
            <Select
              name="branch"
              value={accountType}
              options={[
                { label: "saving", value: 0 },
                { label: "Salary Account", value: 1 },
                { label: "Fixed Deposit Account", value: 2 },
                { label: "NRI Account", value: 3 },
              ]}

              onChange={handleAccountTypeChange}
            />
            {accountTypeSelected && (
              <div className="text-danger">Please select a bank.</div>
            )}
          </div>
        </Row>
        <Row xs={12} md={3}>
          <div className="mb-3">
            <Label>IFSC Code</Label>
            <AvField
              type="text"
              name="ifscCode"
              value={ifscCode}
              onChange={e => setIfscCode(e.target.value)}
            />
          </div>
        </Row>
      </AvForm>
    </div>
  )
}

export default PurchaseForm
