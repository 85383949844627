import React, { useEffect, useRef, useState } from "react"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Label, Button, Modal } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { del, get, post, put } from "../../../helpers/api_helper"
import { getCourses } from "../../../store/options/actions"
import { objectToString } from "../../../helpers/functions"
import SelectErrorMessage from "../../../components/Common/SelectErrorMessage"
import Swal from "sweetalert2"
import TablePagination from "../../../components/Common/Pagination"
import Tooltip from "@mui/material/Tooltip"
import toastr from "toastr"
import "./style.scss"
import { API_URL } from "../../../config"
import axios from "axios"

const QuestionAssign = () => {
  const { courses } = useSelector(state => state.Options)
  const [testOptions, setTestOptions] = useState([])
  const [filterTestOptions, setFilterTestOptions] = useState([])
  const [tableData, setTableData] = useState([])
  const [modalData, setModalData] = useState({})
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [answerError, setAnswerError] = useState({})
  const [screenTestError, setScreenTestError] = useState(false)
  const [questionErr, setQuestionErr] = useState({})
  const [error, setError] = useState({})

  const dispatch = useDispatch()
  const ref = useRef()
  const [popupView, setPopupView] = useState(false)
  const handleCloseModal = () => setPopupView(false)
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Course",
        field: "name",
        width: 150,
      },
      {
        label: "Test Name",
        field: "test",
        width: 150,
      },
      {
        label: "Question",
        field: "question",
        width: 150,
      },

      {
        label: "Answer",
        field: "ans",
        width: 150,
      },
      {
        label: "Action",
        field: "options",
        width: 150,
      },
    ],
    rows: tableData,
  }

  const [filterObject, setFilterObject] = useState({
    course: "",
    screenTest: "",
  })
  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 20
  const [importFile,ImportFile]=useState(null)

  const [masterObject, setMasterObject] = useState({
    initial: true,
    questions: [
      {
        question: "",
        answer: "",
        option1: "",
        option2: "",
        option3: "",
        option4: "",
        mark: '',
      },
    ],
  })

  const answerOptions = [
    { label: "Option 1", value: 1 },
    { label: "Option 2", value: 2 },
    { label: "Option 3", value: 3 },
    { label: "Option 4", value: 4 },
  ]

  useEffect(() => {
    dispatch(getCourses())
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [filterObject, page])

  const handleChange = (event, value) => {
    setPage(value)
  }
  const [selected, setSelected] = useState({})

  const handleRemove = indexToRemove => {
    const obj = { ...masterObject }
    const newValues = [
      ...obj.questions.slice(0, indexToRemove),
      ...obj.questions.slice(indexToRemove + 1),
    ]

    obj.questions = newValues
    setMasterObject(obj)
  }

  const addMore = () => {
    const obj = { ...masterObject }

    obj.questions.push({
      question: "",
      answer: "",
      option1: "",
      option2: "",
      option3: "",
      option4: "",
      mark: '',
    })
    setMasterObject(obj)
  }

  const handleTestOptions = course => {
    get(`/options/screen-test?course=${course}`).then(res => {
      // const data = res.data?.length ? res.data :
      setTestOptions(res.data)
    })
  }
  const handleFilterTestOptions = course => {
    get(`/options/screen-test?course=${course}`).then(res => {
      setFilterTestOptions(res.data)
    })
  }

  const handleValueChange = (name, value) => {
    let obj = { ...masterObject }

    console.log(obj,'obj-----------------------------');
    if (name === "screenTest") obj.initial = false

    obj[name] = value
    if (name === "course") {
      handleTestOptions(value)
      obj = {
        course: value,
        initial: true,
        questions: [
          {
            question: "",
            answer: "",
            option1: "",
            option2: "",
            option3: "",
            option4: "",
            mark: "",
          },
        ],
      }

    } else {
      console.log(value,'value else');
      getQuestions(value)
    }

    console.log(obj ,'obj final');
    setMasterObject(obj)
  }

  const getQuestions = value => {
    get(`/screen-test-question/questions?screenTest=${value}`).then(res => {
      const result = res.data?.questions
      const obj = { ...masterObject }
      if (result?.length) {
        obj.screenTest = value
        obj.questions = result
        obj.initial = false
        setMasterObject(obj)
      }else{
        setMasterObject({ 
          initial: false,
          course: obj?.course,
          screenTest:value,
          questions: [
            {
              question: "",
              answer: "",
              option1: "",
              option2: "",
              option3: "",
              option4: "",
              mark: '',
            },
          ],
        })
      }
     
    })
  }

  const handleOptionValueChange = (value, index, name) => {
    const obj = { ...masterObject }
    obj.questions[index][name] = value
    setMasterObject(obj)
  }

  const handleFilterValueChange = (value, name) => {
    if (name === "course") {
      handleFilterTestOptions(value)
    }

    const obj = { ...filterObject }
    obj[name] = value
    setFilterObject(obj)
  }
  const handleTableData = () => {
    get(
      `/screen-test-question?${objectToString(
        filterObject
      )}&page=${page}&limit=${limit}`
    ).then(res => {
      const result = res?.data
      const count = res?.count
      result.map((item, index) => {
        item.id = index + 1 + (page - 1) * limit
        item.name = item?.course[0]?.label
        item.test = item.screenTest.label
        item.question = item.questions.question
        let answerOption = `option${item.questions?.answer || 1}`
        item.ans = item.questions[answerOption]
        item.options = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              onClick={() => {
                handlePopUp(item)
              }}
              style={{
                fontSize: "1em",
                cursor: "pointer",
              }}
            ></i>
            <i
              className="far fa-edit"
              onClick={() => handleUpdate(item)}
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              onClick={() => handleDelete(item)}
              style={{ cursor: "pointer", fontSize: "1em" }}
            ></i>
          </div>
        )
        return item
      })
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(result)
    })
  }

  const handleDelete = item => {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(
          `/screen-test-question/question?screenTest=${item.screenTest.value}&questionId=${item.questions._id}`
        )
          .then(res => {
            toastr.success(res.message)
            handleTableData()
          })
          .catch(err => {
            toastr.success(err.response.data.message)
          })
      }
    })
  }
  const handleSubmit = () => {
    let obj = { ...masterObject }
    let error = { ...answerError }
    let alreadyError = false

    if (!masterObject._id) {
      if (selectedCourse === null) {
        return setError(pre => ({ ...pre, course: true }))
      }

      if (!masterObject?.screenTest) {
        return setScreenTestError(true)
      } else {
        setScreenTestError(false)
      }
    }

    obj.questions?.map((data, index) => {
      // if (!data?.answer) {
      //     error[index] = true;
      //     alreadyError = true;
      // }
      if (!data?.question) {
        setQuestionErr(prevState => ({ ...prevState, [index]: true }))
        alreadyError = true
      } else {
        setQuestionErr(prevState => ({ ...prevState, [index]: false }))
      }

      if (!data?.answer) {
        setAnswerError(prevState => ({ ...prevState, [index]: true }))
        alreadyError = true
      } else {
        setAnswerError(prevState => ({ ...prevState, [index]: false }))
      }

      // return data;
    })

    if (alreadyError) return

    if (masterObject._id) {
      obj.questions = obj.questions[0]
      put("/screen-test-question/question", obj)
        .then(res => {
          reset()
          toastr.success(res.message)
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    } else {
      post("/screen-test-question", obj)
        .then(res => {
          reset()
          toastr.success(res.message)
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    }
  }

  const handleUpdate = item => {
    toTop()
    setSelected({
      course: item.course,
      screenTest: item.screenTest,
    })
    let question = item.questions
    setMasterObject({
      _id: item._id,
      questionId: question._id,
      course: item.course?.value,
      screenTest: item.screenTest?.value,
      questions: [question],
    })

 
  }

  const handlePopUp = item => {
    setPopupView(true)
    setModalData(item)
  }

  const reset = () => {
    ref.current.reset()
    // handleTableData()
    setError({})
    setScreenTestError(false)
    setAnswerError({})
    setSelectedCourse(null)
    toTop()
    setMasterObject({
      initial: true,
      questions: [
        {
          question: "",
          answer: "",
          option1: "",
          option2: "",
          option3: "",
          option4: "",
          mark: "",
        },
      ],
    })
    setFilterTestOptions([])
    setFilterObject({})
    setSelected({})
  }

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }
  const handleFileUpload = e => {
    const file = e.target.files[0]
    // Check if file exists and its type is Excel
    if (!file) {
      console.error("No file selected.")
      return
    }
    const extension = file.name.split(".").pop().toLowerCase()
    if (extension !== "xls" && extension !== "xlsx") {
      console.error("Invalid file type. Please upload an Excel file.")
      return
    }
    const fd = new FormData()
    fd.append("questions", file)

    axios
      .post(`${API_URL}screen-test-question/upload`, fd)
      .then(response => {
        if (response.status === 200) {
          // Assuming the response data is an object that you want to add to the masterObject
          const responseData = response.data.data // Adjust this according to your response structure
          let data = { ...masterObject }
          
          let obj = {
            question: "",
            answer: "",
            option1: "",
            option2: "",
            option3: "",
            option4: "",
            mark: '',
          }
          const question = data.questions[0];
          let check = Object.values(question).every(value => value === '');  
          
          console.log(responseData,'responseData responseData');
         
          data.questions = data.initial
            ? responseData 
            : [...data.questions, ...responseData]
          data.initial = false
          if (check) {
           data.questions.shift();
          }

          console.log(masterObject,'ppppppppppppppppppp');
          
          setMasterObject(data)
          // setMasterObject(prevState => {
          //   if (prevState.questions.length === 0) {
          //     return {
          //       ...prevState,
          //       questions: responseData
          //     };
          //   } else {
          //     return {
          //       ...prevState,
          //       questions: [...prevState.questions, ...responseData]
          //     };
          //   }
          // });
        } else {
          toastr.success(response.data.message)
        }
      })
      .catch(error => {
        console.error("Error occurred during file upload:", error)
      })
  }
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem="Assign Question" />
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="needs-validation"
                    ref={ref}
                    onValidSubmit={handleSubmit}
                  >
                    <Row>
                      <Col md="12">
                        <Row>
                          <Col md="3">
                            <div className="mb-3">
                              <Label>Course</Label>
                              <Select
                                options={courses}
                                value={selected.course || ""}
                                isDisabled={masterObject._id}
                                onChange={selected => {
                                  handleValueChange("course", selected.value)
                                  setSelected({
                                    course: selected,
                                  })
                                  setSelectedCourse(selected)
                                }}
                              />
                              <SelectErrorMessage
                                show={selectedCourse === null && error.course}
                                customStyle={{
                                  width: "100%",
                                  fontSize: "87.5%",
                                  color: "var(--bs-form-invalid-color)",
                                }}
                                message="Please select a Course"
                              />
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <Label>Test Name</Label>
                              <Select
                                name="test"
                                classNamePrefix="select2-selection"
                                options={testOptions}
                                isDisabled={
                                  !masterObject.course || masterObject._id
                                }
                                onChange={selected => {
                                  handleValueChange(
                                    "screenTest",
                                    selected.value
                                  )
                                  setSelected(perv => ({
                                    ...perv,
                                    screenTest: selected,
                                  }))
                                }}
                                value={selected.screenTest || ""}
                              />
                              <SelectErrorMessage
                                customStyle={{
                                  width: "100%",
                                  fontSize: "87.5%",
                                  color: "var(--bs-form-invalid-color)",
                                }}
                                message="Select Test Name"
                                show={screenTestError} // Simplified to check directly if answerError[index] is truthy
                              />
                            </div>
                          </Col>
                          <Col md="3">
                            {!masterObject._id && masterObject.course && masterObject.screenTest && (
                              <div className="mb-3">
                                <Label>Import</Label>
                                <AvField 
                                  name="questions" 
                                  type="file" 
                                  accept=".xls,.xlsx" 
                                  validate={{
                                    pattern: {
                                      value: /^.*\.(xls|xlsx)$/i,
                                      errorMessage:
                                        "Please select a valid Excel file (.xls, .xlsx)",
                                    },
                                  }}
                                 
                                  onChange={handleFileUpload}
                                  className="form-control"
                                  addonAfter="Days"
                                />
                              </div>
                            )}
                          </Col>
                          <Col>
                            <div
                              className="mb-3 d-flex"
                              style={{ paddingTop: "20px" }}
                            >
                              <Button
                                type="submit"
                                color={masterObject._id ? "warning" : "primary"}
                              >
                                {masterObject._id ? "Update" : "Submit"}
                              </Button>
                              <Button
                                color={"danger"}
                                className="ms-2"
                                onClick={reset}
                              >
                                Reset
                              </Button>
                              <div
                                style={{
                                  fontSize: "13px",
                                  marginLeft: "7rem",
                                }}
                              >
                                <Tooltip title="Download sample file">
                                  <a
                                    href={
                                      "/samples/Assign_questions_sample.xlsx"
                                    }
                                    download
                                    style={{ fontSize: "1rem" }}
                                    className="d-flex align-items-center btn btn-success "
                                  >
                                    <i
                                      style={{ fontSize: "13px" }}
                                      className="fa fa-solid fa-download "
                                    />
                                    <span
                                      style={{
                                        marginLeft: "5px",
                                        fontSize: "13px",
                                      }}
                                    >
                                      Download Sample Sheet
                                    </span>
                                  </a>
                                </Tooltip>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      <Col md="12">
                        <div className="question-container">
                          {masterObject.questions &&
                            masterObject.questions?.map((question, index) => {
                              return (
                                <Col md={12}>
                                  <div
                                    key={index + "question"}
                                    className={
                                      masterObject.questions.length - 1 ===
                                      index
                                        ? ""
                                        : "question-row"
                                    }
                                  >
                                    <div className="question-header">
                                      <Row>
                                        <Col md={6}>
                                          <Label for={`question-${index}`}>
                                            Question {index + 1}
                                          </Label>
                                          <AvField
                                            type="textarea"
                                            name={`question-${index}`}
                                            id={`question-${index}`}
                                            placeholder="Enter question"
                                            className="question-input"
                                            rows={1}
                                            value={question.question}
                                            onChange={e => {
                                              handleOptionValueChange(
                                                e.target.value,
                                                index,
                                                "question"
                                              )
                                            }}
                                          />
                                          <SelectErrorMessage
                                            customStyle={{
                                              width: "100%",
                                              fontSize: "87.5%",
                                              color:
                                                "var(--bs-form-invalid-color)",
                                            }}
                                            message="Enter Question"
                                            show={questionErr[index] || false} // Simplified to check directly if answerError[index] is truthy
                                          />
                                        </Col>
                                        <Col md={2}>
                                          <Label for={`answer-number-${index}`}>
                                            Answer
                                          </Label>
                                          <Select
                                            name="answer"
                                            classNamePrefix="select2-selection"
                                            options={answerOptions}
                                            value={answerOptions.filter(
                                              item =>
                                                item.value === question.answer
                                            )}
                                            onChange={selected => {
                                              handleOptionValueChange(
                                                selected.value,
                                                index,
                                                "answer"
                                              )
                                              setAnswerError(prev => ({
                                                ...prev,
                                                [index]: false,
                                              }))
                                            }}
                                          />
                                          <SelectErrorMessage
                                            customStyle={{
                                              width: "100%",
                                              fontSize: "87.5%",
                                              color:
                                                "var(--bs-form-invalid-color)",
                                            }}
                                            message="Select Answer"
                                            show={answerError[index] || false} // Simplified to check directly if answerError[index] is truthy
                                          />
                                        </Col>
                                        <Col md={2}>
                                          <Label for={`question-${index}`}>
                                            Mark
                                          </Label>
                                          <AvField
                                            type="text"
                                            name={`mark-${index}`}
                                            id={`mark-${index}`}
                                            placeholder="Enter mark"
                                            className="mark-input"
                                            rows={1}
                                            validate={{
                                              required: {
                                                value: true,
                                                errorMessage: "Enter Marks",
                                              },
                                              isPercentage: value => {
                                                // Check if it's a percentage field
                                                const percentage =
                                                  parseFloat(value) // Parse the value to a floating-point number
                                                if (!isNaN(percentage)) {
                                                  return percentage > 0
                                                } else {
                                                  return false // Not a valid number
                                                }
                                              },
                                            }}
                                            errorMessage="Enter a valid Mark"
                                            value={question.mark}
                                            onChange={e => {
                                              handleOptionValueChange(
                                                e.target.value,
                                                index,
                                                "mark"
                                              )
                                            }}
                                          />
                                        </Col>
                                        {masterObject.questions.length !==
                                          1 && (
                                          <Col col={2}>
                                            <Button
                                              color="danger"
                                              onClick={() =>
                                                handleRemove(index)
                                              }
                                              style={{ marginTop: "1.5rem" }}
                                            >
                                              Remove
                                            </Button>
                                          </Col>
                                        )}
                                      </Row>
                                    </div>
                                    <div className="options-container">
                                      <Row>
                                        <Col md={3}>
                                          <Label for={`option-${index + 1}`}>
                                            Option 1
                                          </Label>
                                          <AvField
                                            key={index}
                                            type="text"
                                            name={`option-${index}-${1}`}
                                            id={`option-${index}-${1}`}
                                            placeholder={`Option ${1}`}
                                            errorMessage="This field is required"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            className="option-input"
                                            value={question.option1}
                                            onChange={e => {
                                              handleOptionValueChange(
                                                e.target.value,
                                                index,
                                                "option1"
                                              )
                                            }}
                                          />
                                        </Col>
                                        <Col md={3}>
                                          <Label for={`option-${index + 2}`}>
                                            Option 2
                                          </Label>
                                          <AvField
                                            key={index}
                                            type="text"
                                            name={`option-${index}-${2}`}
                                            id={`option-${index}-${2}`}
                                            placeholder={`Option ${2}`}
                                            validate={{
                                              required: { value: true },
                                            }}
                                            errorMessage="This field is required"
                                            className="option-input"
                                            value={question.option2}
                                            onChange={e => {
                                              handleOptionValueChange(
                                                e.target.value,
                                                index,
                                                "option2"
                                              )
                                            }}
                                          />
                                        </Col>
                                        <Col md={3}>
                                          <Label for={`option-${index + 3}`}>
                                            Option 3
                                          </Label>
                                          <AvField
                                            key={index}
                                            type="text"
                                            name={`option-${index}-${3}`}
                                            id={`option-${index}-${3}`}
                                            placeholder={`Option ${3}`}
                                            validate={{
                                              required: { value: true },
                                            }}
                                            errorMessage="This field is required"
                                            className="option-input"
                                            value={question.option3}
                                            onChange={e => {
                                              handleOptionValueChange(
                                                e.target.value,
                                                index,
                                                "option3"
                                              )
                                            }}
                                          />
                                        </Col>
                                        <Col md={3}>
                                          <Label for={`option-${index + 4}`}>
                                            Option 4
                                          </Label>
                                          <AvField
                                            key={index}
                                            type="text"
                                            name={`option-${index}-${4}`}
                                            id={`option-${index}-${4}`}
                                            placeholder={`Option ${4}`}
                                            validate={{
                                              required: { value: true },
                                            }}
                                            errorMessage="This field is required"
                                            className="option-input"
                                            value={question.option4}
                                            onChange={e => {
                                              handleOptionValueChange(
                                                e.target.value,
                                                index,
                                                "option4"
                                              )
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                </Col>
                              )
                            })}

                          {!masterObject._id && (
                            <span
                              onClick={() => addMore()}
                              style={{ cursor: "pointer", textAlign: "center" }}
                            >
                              {" "}
                              + Add New{" "}
                            </span>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Course</Label>
                        <Select
                          value={selected.filterCourse || ""}
                          options={courses}
                          onChange={selected => {
                            handleFilterValueChange(selected.value, "course")
                            setSelected(prev => ({
                              ...prev,
                              filterCourse: selected,
                            }))
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Test Name</Label>
                        <Select
                          name="test"
                          classNamePrefix="select2-selection"
                          value={selected.filterScreenTest || ""}
                          onChange={selected => {
                            handleFilterValueChange(
                              selected.value,
                              "screenTest"
                            )
                            setSelected(prev => ({
                              ...prev,
                              filterScreenTest: selected,
                            }))
                          }}
                          options={filterTestOptions}
                        />
                      </div>
                    </Col>

                    <Col style={{ paddingTop: "26px" }} md="2">
                      <div className="mb-3">
                        <Button onClick={reset} color="danger">
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <MDBDataTable
                      id="assignquestion"
                      className="mt-3"
                      bordered
                      responsive
                      searching={false}
                      entries={20}
                      paging={false}
                      info={false}
                      disableRetreatAfterSorting={true}
                      data={data}
                    ></MDBDataTable>
                    <TablePagination
                      page={page}
                      onChange={handleChange}
                      count={totalPage}
                    />
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <Modal
          show={popupView}
          isOpen={popupView}
          toggle={() => setPopupView(!popupView)}
          size="lg"
          centered={true}
          className="lead-modal "
        >
          <div className="modal-header">
            <div className="modal-title">
              <h6 style={{ marginBottom: "0px" }} className="modal-title ">
                <h6>Questions</h6>
              </h6>
            </div>
            <button
              style={{ marginBottom: "2px" }}
              type="button"
              onClick={handleCloseModal}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body ">
            <Col md="12 ">
              <div className="table-responsive">
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <th style={{ textAlign: "start" }}>Course</th>
                      <th> : </th>
                      <td style={{ textAlign: "start" }}>{modalData.name} </td>
                      <th style={{ textAlign: "start" }}>Test Name</th>
                      <th> : </th>
                      <td style={{ textAlign: "start" }}>{modalData.test}</td>
                    </tr>
                    <tr>
                      <th style={{ textAlign: "start" }}>Question</th>
                      <th> : </th>
                      <td style={{ textAlign: "start", width: "400px" }}>
                        {modalData.question}{" "}
                      </td>
                      <th style={{ textAlign: "start" }}>Answer</th>
                      <th> : </th>
                      <td style={{ textAlign: "start" }}>{modalData.ans}</td>
                    </tr>
                    <tr>
                      <th style={{ textAlign: "start" }}>Mark</th>
                      <th> : </th>
                      <td style={{ textAlign: "start", width: "400px" }}>
                        {modalData?.questions?.mark}{" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* <div className="table-responsive">
                <table className="table table-bordered table-striped">
                  <tbody>
                    <tr>
                      <td >Course</td>
                      <th>: {modalData.name}</th>
                      <td>Test Name</td>
                      <th>: {modalData.test}</th>
                    </tr>
                    <tr>
                      <td>Question</td>
                      <th>: {modalData.question}</th>
                      <td>Answer</td>
                      <th>: {modalData.ans}</th>
                    </tr>
                  </tbody>
                </table>
              </div> */}
            </Col>

            <div>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td style={{ textAlign: "left", width: "150px" }}>
                      Option 1
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {modalData.questions?.option1}
                    </td>
                  </tr>

                  <tr>
                    <td style={{ textAlign: "left", width: "150px" }}>
                      Option 2
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {modalData.questions?.option2}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", width: "150px" }}>
                      Option 3
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {modalData.questions?.option3}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", width: "150px" }}>
                      Option 4
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {modalData.questions?.option4}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default QuestionAssign
