import React, { useEffect, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import { get } from "../../helpers/api_helper"
import moment from "moment"
import "./style.scss"
import Select from "react-select"
import { jsonToExcel } from "../../helpers/functions"
import toastr from "toastr"
import qs from "query-string"
import DataTable from "../../components/Common/DataTable"

const EmailSubscription = () => {
  const [tableData, setTableData] = useState([])
  const [exportData, setExportData] = useState({})

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const [count, setCount] = useState(0)

  const [select, setSelect] = useState({})

  const initialSearch = {
    from_date: moment().startOf("year").format("YYYY-MM-DD"),
    to_date: moment().format("YYYY-MM-DD"),
  }

  const [master, setMaster] = useState(initialSearch)

  const status = [
    { label: "Not Verified", value: 0 },
    { label: "Verified", value: 1 },
  ]

  useEffect(() => {
    handleTableData()
  }, [master, page, limit])

  const handleSearchChange = e => {
    let { name, value } = e.target
    setMaster(pre => ({ ...pre, [name]: value }))
    setPage(1)
  }

  const handleTableData = async () => {
    try {
      const res = await get(
        `/email-subcription/list?page=${page}&limit=${limit}&${qs.stringify(
          master
        )}`
      )
      setCount(res.count || 0)
      let data = res?.data
      data = data.map((item, index) => {
        item.id = (page - 1) * limit + index + 1
        item.name = item.profile
          ? `${item?.profile?.firstname || ""} ${item?.profile?.lastname || ""}`
          : "----"

        item.regId = item?.profile?.unique_id || "----"

        item.Tabelestatus = (
          <strong
            className={`text-${item?.status === 0 ? "danger" : "success"}`}
          >
            {item?.status === 0 ? "Not Verified" : "Verified"}
          </strong>
        )

        item.date = moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY")
        item.time = moment(item.time, "HH:mm").format("hh:mm a")
        return item
      })
      setExportData(exportData)
      setTableData(data)
    } catch (error) {
      // Handle error
    }
  }
  const handleExportData = async () => {
    const res = await get(
      `/email-subcription/list?limit=${count}&${qs.stringify(master)}`
    )

    const data = res?.data ?? []

    if (data.length > 0) {
      const exportData = []
      data.map((item, index) => {
        const dataExport = {
          SI_NO: index + 1,
          Date: item.date,
          Time: item.time,
          Email: item.email,
          "Reg ID": item?.profile?.unique_id,
          Name: `${item?.profile?.firstname || ""} ${
            item?.profile?.lastname || ""
          }`,
          Status: item?.status == 1 ? "Verified" : "Not Verified",
        }
        exportData.push(dataExport)
        return item
      })

      jsonToExcel(
        exportData,
        `${`Email Subscription`}-${moment().format("DD-MM-YY")}`
      )
    } else {
      toastr.info(`There are No Data to export`)
    }
  }
  const reset = () => {
    setMaster(initialSearch)
    setSelect({})
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        width: 150,
      },
      {
        label: "Reg ID",
        field: "regId",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        width: 150,
      },
      {
        label: "Status",
        field: "Tabelestatus",
        width: 150,
      },
    ],
    rows: tableData,
  }
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Email Subscription" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label> From</Label>
                          <AvField
                            name="from_date"
                            value={master.from_date || ""}
                            onChange={handleSearchChange}
                            max={moment().format("YYYY-MM-DD")}
                            className="form-control"
                            type="date"
                            placeholder="From"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>To</Label>
                          <AvField
                            name="to_date"
                            value={master.to_date || ""}
                            max={moment().format("YYYY-MM-DD")}
                            min={master.from_date}
                            onChange={handleSearchChange}
                            className="form-control"
                            type="date"
                            placeholder="To"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Status</Label>
                          <Select
                            name="csp"
                            classNamePrefix="select2-selection"
                            options={status}
                            value={select?.status || null}
                            onChange={selected => {
                              setSelect(pre => ({ ...pre, status: selected }))
                              setMaster(pre => ({
                                ...pre,
                                status: selected.value ?? null,
                              }))
                              setPage(1)
                            }}
                          />
                        </div>
                      </Col>
                      <Col style={{ marginTop: "1.8rem" }}>
                        <Button
                          color="danger"
                          type="button"
                          className="me-2"
                          onClick={reset}
                        >
                          Reset
                        </Button>
                        <Button onClick={handleExportData} color="primary">
                          Export
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>

                  <DataTable
                    data={data}
                    count={count}
                    page={page}
                    limit={limit}
                    onPageChange={e => setPage(e)}
                    onLimitChange={e => setLimit(e)}
                    id="emailSubcriptiontd1"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default EmailSubscription
