import React from 'react'
import { AvField, AvForm } from "availity-reactstrap-validation";
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { MDBDataTable } from 'mdbreact';
import Select from "react-select"
import { Modal } from 'react-bootstrap';


function OfferLetter() {

    // const [popupView, setPopupView] = useState(false)




    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                width: 50,
            },
            {
                label: "Date",
                field: "date",
                width: 150,
            },
            {
                label: "Time",
                field: "time",
                width: 150,
            },
            {
                label: "Candidate",
                field: "candidate",
                width: 150,
            },
            {
                label: "Mobile",
                field: "mobile",
                width: 150,
            },
            {
                label: "Recruiters",
                field: "Recruiters",
                width: 150,
            },
            {
                label: "Job",
                field: "name",
                width: 150,
            },
            {
                label: "Joining Date",
                field: "join",
                width: 150,
            },
            {
                label: "Offer Letter",
                field: "action",
                width: 150,
            },
        ],
        rows: [
            {
                id: "1",
                date: "24-01-2024",
                time: "03:35 pm",
                candidate: "Alan Job",
                mobile: "9365524183",
                Recruiters: "WIPRO",
                name: "Project Engineer",
                join: "30-01-2024",
                action: (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <i
                            className="fas fa-file-pdf"
                            style={{
                                fontSize: "1.5em",
                                cursor: "pointer",
                                marginLeft: "0.2rem",
                                marginRight: "0.5rem",
                                color: "red"
                            }}
                        ></i>
                        <i
                            className="fab fa-whatsapp"
                            style={{
                                fontSize: "1.5em",
                                cursor: "pointer",
                                marginLeft: "0.2rem",
                                marginRight: "0.5rem",
                                color: "green"
                            }}
                        ></i>
                    </div>
                )
            },
        ],
    };


    return (
        <>

            <Modal
                // show={popupView}
                // isOpen={popupView}
                // toggle={() => setPopupView(!popupView)}
                size="lg"
                centered={true}>
                <div className="modal-header">
                    <div className="modal-title">
                        <h5>Event Details</h5>
                    </div>

                    <button
                        type="button"
                        // onClick={() => setPopupView(false)}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>

                <div className="modal-body" style={{ paddingTop: '0px' }}>
                    <Row>
                        <Col md="12">
                            <table
                                id="product_available_day"
                                className="table table-bordered dataTable"
                            >
                                <tr>
                                    <th class="mdl-td-c" style={{ padding: "10px 11px", textAlign: 'left' }}>Start Date</th>
                                    <th> : </th>
                                    <td style={{ paddingLeft: '20px' }}>14-10-2023</td>
                                    <th class="mdl-td-c" style={{ textAlign: "left" }}>Start Time</th>
                                    <th> : </th>
                                    <td style={{ paddingLeft: '20px', textAlign: "justify" }}>9:30 am</td>
                                </tr>
                                <tr>
                                    <th class="mdl-td-c" style={{ textAlign: "left", padding: '10px' }}>End Date</th>
                                    <th> : </th>
                                    <td style={{ paddingLeft: '20px' }}>14-10-2023</td>
                                    <th class="mdl-td-c" style={{ textAlign: "left" }}>End Time</th>
                                    <th> : </th>
                                    <td style={{ paddingLeft: '20px', textAlign: "justify" }}>4:30 pm</td>
                                </tr>
                                <tr>
                                    <th class="mdl-td-c" style={{ textAlign: "left", padding: '10px' }}>Category</th>
                                    <th> : </th>
                                    <td style={{ paddingLeft: '20px' }}>inauguration</td>
                                    <th class="mdl-td-c" style={{ textAlign: "left" }}>CSP</th>
                                    <th> : </th>
                                    <td style={{ paddingLeft: '20px', textAlign: "justify" }}>CSP Kazhakkottam</td>
                                </tr>
                                <tr>
                                    <th class="mdl-td-c" style={{ textAlign: "left", padding: '10px' }}>Event</th>
                                    <th> : </th>
                                    <td style={{ paddingLeft: '20px', textAlign: "justify" }} colSpan={5}>Datsi School for Storytellers’ Open House</td>
                                </tr>
                                <tr>
                                    <th class="mdl-td-c" style={{ textAlign: "left", padding: '10px' }}>Venue</th>
                                    <th> : </th>
                                    <td style={{ paddingLeft: '20px', textAlign: "justify" }} colSpan={5}>Kinfra Film & Video Park, Sainik school PO Chanthavila, Kazhakootam, Thiruvananthapuram, Kerala – 695585</td>
                                </tr>
                                <tr>
                                    <th class="mdl-td-c" style={{ textAlign: "left", padding: '10px' }}>Type</th>
                                    <th> : </th>
                                    <td style={{ paddingLeft: '20px' }}>Register</td>
                                    <th class="mdl-td-c" style={{ textAlign: "left" }}>Resource Person</th>
                                    <th> : </th>
                                    <td style={{ paddingLeft: '20px', textAlign: "justify" }}>Mr. IP Laiju</td>
                                </tr>
                                <tr>
                                    <th class="mdl-td-c" style={{ textAlign: "left", padding: '10px' }}>Added By</th>
                                    <th> : </th>
                                    <td style={{ paddingLeft: '20px', textAlign: "justify" }}>Admin</td>
                                </tr>
                            </table>
                        </Col>
                    </Row>
                </div>
            </Modal>

            <div className='page-content'>
                <div className='container-fluid'>
                    <Breadcrumbs title="Home" breadcrumbItem="Offer Letter" />

                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <AvForm>
                                        <Row>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label>Job Post</Label>
                                                    <Select
                                                        name="mode"
                                                        classNamePrefix="select2-selection"
                                                        options={[
                                                            { label: "Python Developer", value: 0 },
                                                            { label: "Project Engineer", value: 1 },
                                                            { label: "Software Engineer", value: 2 },
                                                            { label: "Cyber Security", value: 3 },
                                                            { label: ".NET Developer", value: 4 },
                                                        ]}
                                                    />
                                                </div>
                                            </Col>


                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label>Candidate</Label>
                                                    <Select
                                                        name="qualification"
                                                        classNamePrefix="select2-selection"
                                                        options={[
                                                            { label: "AL Amin Ali", value: 0 },
                                                            { label: "Suja joseph", value: 1 },
                                                            { label: "Deepak Kumar", value: 2 },
                                                            { label: "Saima", value: 2 },
                                                        ]}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label>Recruiters</Label>
                                                    <AvField
                                                        name="openings"
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Recruiters"
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label>Address</Label>
                                                    <AvField
                                                        name="openings"
                                                        className="form-control"
                                                        type="textarea"
                                                        placeholder="Address"
                                                        rows={1}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label>Issued Date</Label>
                                                    <AvField
                                                        name="openings"
                                                        className="form-control"
                                                        type="date"
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label>Joining Date</Label>
                                                    <AvField
                                                        name="openings"
                                                        className="form-control"
                                                        type="date"
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label>Salary</Label>
                                                    <AvField
                                                        name="openings"
                                                        className="form-control"
                                                        type="number"
                                                        placeholder="Salary"
                                                    />
                                                </div>
                                            </Col>


                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label>Provisions</Label>
                                                    <Select
                                                        name="provision"
                                                        classNamePrefix="select2-selection"
                                                        options={[
                                                            { label: "Discount on Children's School Fees", value: 0 },
                                                            { label: "Employee State Insurance", value: 1 },
                                                            { label: "Facilities For Self Cooking", value: 2 },
                                                            { label: "Food", value: 3 },
                                                            { label: "Overtime Payment", value: 4 },
                                                            { label: "Summer Salary", value: 5 },
                                                            { label: "Travel Allowance", value: 6 },
                                                            { label: "Shared Accomodation", value: 7 },
                                                            { label: "Provident Food", value: 8 },
                                                        ]}
                                                    />
                                                </div>
                                            </Col>

                                            <Col>
                                                <div className="mb-3" style={{ paddingTop: "25px" }}>
                                                    <Button color="primary" type="submit">
                                                        Submit
                                                    </Button>
                                                </div>
                                            </Col>

                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>


                    <Row>
                        <Col className='col-12'>
                            <Card>
                                <CardBody>
                                    <MDBDataTable
                                        id="Managetableid1"
                                        bordered
                                        responsive
                                        searching={true}
                                        entries={20}
                                        disableRetreatAfterSorting={true}
                                        data={data}
                                    >
                                    </MDBDataTable>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </div>
        </>
    )
}

export default OfferLetter;