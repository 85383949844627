import React from "react"
import MiniWidget from "./mini-widget"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Col, Container, Row } from "reactstrap"
import Chartapex from "./chartapex"
import TopProduct from "./Topselling-product"
import { Card, CardBody,CardTitle ,DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from "reactstrap";

import "./style.scss"

function CSPDashboard1({ title }) {
  const series1 = [50]

  const options1 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series2 = [70]

  const options2 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series3 = [55]

  const options3 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series4 = [33]

  const options4 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const reports = [
    {
      id: 3,
      icon: "fas fa-chalkboard-teacher",
      title: "Classrooms/Labs Occupancy",
      value: 250,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue: "500",
      color: "success",
      desc: "Total Classrooms",
      series: series3,
      options: options3,
    },
    {
      id: 1,
      icon: "fas fa-book-open",
      title: "Ongoing Courses",
      value: 110,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue: "200",
      color: "success",
      desc: " ",
      series: series1,
      options: options1,
    },
    {
      id: 2,
      icon: "fa fa-tag",
      title: "Live Events",
      value: 10,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue: "19",
      color: "success",
      desc: "Total Events",
      series: series2,
      options: options2,
    },

    {
      id: 4,
      icon: "fa fa-percent",
      title: "Enrolled Students",
      value: 0,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue: "0 ",
      color: "success",
      desc: "Total Students",
      series: series4,
      options: options4,
    },

    {
      id: 5,
      icon: "fa fa-percent",
      title: "Revenue",
      value: 0,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue: "0 ",
      color: "success",
      desc: "Total Revenue",
      series: series4,
      options: options4,
    },
    {
      id: 6,
      icon: "fa fa-percent",
      title: "Profit and Loss",
      value: 0,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue: "0 ",
      color: "success",
      desc: "Total",
      series: series4,
      options: options4,
    },
    {
      id: 6,
      icon: "fa fa-percent",
      title: "Completed Courses",
      value: 0,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue: "0 ",
      color: "success",
      desc: "Total",
      series: series4,
      options: options4,
    },
    {
      id: 6,
      icon: "fa fa-percent",
      title: "Students Placed",
      value: 0,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue: "0 ",
      color: "success",
      desc: "Total Students Placed",
      series: series4,
      options: options4,
    },
  ]

  return (
    <div>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Home"
            breadcrumbItem={title ? title : "Dashboard"}
          />
          {/* <div className='d-flex gap-2 pb-3'>
              <div className='top_social top_socialcolor1'>Facebook Ads</div>
              <div className='top_social top_socialcolor2'>Instagram Ads</div>
              <div className='top_social top_socialcolor3'>Google my business </div>
              <div className='top_social top_socialcolor4'>QR Creative</div>
              <button style={{ border: "none", color: "green" }} className='fas fa-undo'></button>
            </div> */}

            

          <>
            <Row>
              <MiniWidget reports={reports} />
            </Row>
            <Row className="">
              <Col md="6" lg="6">
                <Card>
                  <CardBody>
                    <div className="float-end">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="a"
                          className="text-reset"
                          id="dropdownMenuButton5"
                          caret
                          href="#"
                        >
                          <span className="fw-semibold">Sort By:</span>{" "}
                          <span className="text-muted">
                          Daily<i className="mdi mdi-chevron-down ms-1"></i>
                          </span>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem href="#">Daily</DropdownItem>
                          <DropdownItem href="#">Weekly</DropdownItem>
                          <DropdownItem href="#">Monthly</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                    <CardTitle className="mb-4">Activity @ Asap</CardTitle>
                    <Chartapex />
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" lg="6">
                <table className="table table-bordered campaign-dashboard ">
                  <thead>
                    <tr>
                      <th className="campaign1stth" colspan="2">
                        <p>
                          <i className="fas fa-users"></i>{" "}
                        </p>
                      </th>
                      {/* <th className='campaign1stth'>

              
            </th> */}
                      <th>Today</th>
                      <th>Jan-24</th>
                      <th>Dec-23</th>
                      <th>Nov-23</th>
                      <th>Oct-23</th>
                      {/* <th>Conversions</th> */}
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td className="rowtd01" rowspan="5">
                        <p></p>
                      </td>
                      <th>Event 1</th>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <th>Event 2</th>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <th>Event 3</th>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <th>Event 4</th>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <th>Event 5</th>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
            <Row className="d-none">
              <Col md="6" lg="6">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">CSP @ Asap</CardTitle>
                    <Chartapex />
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" lg="4">
                <CardTitle className=""></CardTitle>
                <TopProduct />
              </Col>
            </Row>
          </>
        </Container>
      </div>
    </div>
  )
}

export default CSPDashboard1
