import React, { useState, useEffect } from "react"
import { Modal, Row, Col, Label } from "reactstrap"
import { get } from "../../../../helpers/api_helper"
import moment from "moment"
import { minutesToHour } from "../../../../helpers/functions"

const SessionHistory = ({ id, isOpen, onClick }) => {
  const [requestData, setReportData] = useState([])
  useEffect(() => {
    if (isOpen) handleAttendance()
    // eslint-disable-next-line
  }, [isOpen])

  const handleAttendance = async () => {
    try {
      const response = await get(`remuneration/monthly-report/details?id=${id}`)
      const data = response.data
      const name =
        (data.firstName ? data.firstName : "") +
        " " +
        (data.lastName ? data.lastName : "")
      const staff =
        (data.userFirstName ? data.userFirstName : "") +
        " " +
        (data.userLastName ? data.userLastName : "")

      const date = moment(data.date).format("DD-MM-YYYY")
      const time = moment(data.time, "HH:mm:ss").format("hh:mm a")
      const minutes = minutesToHour(data.totalMinutes)

      const salary = data.salary || 0
      setReportData({
        ...data,
        name: name,
        salary: salary,
        staff,
        date,
        time,
        minutes,
      })
    } catch (err) {}
  }

  return (
    <React.Fragment>
      <Modal
        size="xl"
        centered
        isOpen={isOpen}
        toggle={() => {
          onClick()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Work Report</h5>
          <button
            type="button"
            onClick={() => onClick()}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <Row>
            <Col md={12}>
              <ul className="lead-details">
                <li>
                  Trainer : <Label>{requestData.name}</Label>
                </li>
                <li>
                  Username : <Label>{requestData.username || ""}</Label>
                </li>
                <li>
                  Branch : <Label>{requestData.branch}</Label>
                </li>
                <li>
                  Batch : <Label>{requestData.batch}</Label>
                </li>
                <li>
                  Course : <Label>{requestData.course}</Label>
                </li>
                <li>
                  Total Days : <Label>{requestData.totalDays}</Label>
                </li>
                <li>
                  Total Sessions : <Label>{requestData.totalSessions}</Label>
                </li>
                <li>
                  Total Hours : <Label>{requestData.minutes}</Label>
                </li>
                <li>
                  Salary : <Label>{requestData.salary}/Monthly</Label>
                </li>
                {/* <li>
                  Payable Amount : <Label>{requestData.salary}/Monthly</Label>
                </li> */}
                <li>
                  Approved By :<Label>{requestData.staff}</Label>
                </li>
                <li>
                  Approved Date : <Label>{requestData.date}</Label>
                </li>
                <li>
                  Approved Time : <Label>{requestData.time}</Label>
                </li>
              </ul>
            </Col>
            <Col md={12}>
              <table
                className="table table-bordered mb-0 table-hover table"
                id="reportDetailsTable"
              >
                <thead>
                  <tr>
                    <th>Session</th>
                    <th>Date</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Hours</th>
                    <th>Staff</th>
                  </tr>
                </thead>
                <tbody>
                  {requestData?.attendance?.map(item => {
                    const date = moment(item.date).format("DD-MM-YYYY")
                    const from = moment(item.fromTime, "HH:mm:ss").format(
                      "hh:mm a"
                    )
                    const to = moment(item.toTime, "HH:mm:ss").format("hh:mm a")
                    const time = minutesToHour(item.time)
                    const staff =
                      (item.firstName ? item.firstName : "") +
                      " " +
                      (item.lastName ? item.lastName : "")
                    return (
                      <tr>
                        <td>{item.session}</td>
                        <td>{date}</td>
                        <td>{from}</td>
                        <td>{to}</td>
                        <td>{time}</td>
                        <td>{staff}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </Col>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default SessionHistory
