import React, { useEffect, useState } from "react"
import { Row, Col, Modal } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import { Box, Divider } from "@mui/material"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import GradingIcon from "@mui/icons-material/Grading"
import { useNavigate } from "react-router-dom"
import WorkHistoryIcon from "@mui/icons-material/WorkHistory"
import { get } from "../../helpers/api_helper"
import { useLocation } from "react-router-dom"
import moment from "moment"
import { dateConverter, setTitle } from "../../helpers/functions"
import { API_URL } from "../../config"
import "./style.scss"
const AgreementDetails = props => {
  setTitle("Agreement Details")
  const { state } = useLocation()
  const { id, renew } = state

  const navigate = useNavigate()
  const [popupView, setPopupView] = useState(false)
  const [popuuData, setPopupData] = useState([])
  const handleCloseModal = () => setPopupView(false)
  const [tab3, setTab3] = useState("1")
  const [tableData, setTableData] = useState([])
  const [renewHistory, setRenewHistory] = useState([])
  const [masterObject, setMasterObject] = useState({
    date: "",
    validity: "",
    person: "",
    companyName: "",
    agreement: "",
  })
  useEffect(() => {
    handleData()
    // handleRenewHistory()
    // eslint-disable-next-line
  }, [id])
  const handleData = () => {
    get(`agreement/details?id=${id}`).then(res => {
      const data = res.data
      data.date = dateConverter(data?.agreementDate)
      data.validity = data.validity
        ? dateConverter(data.validity)
        : dateConverter(data.createdValidity)

      const documents = data.documents || []
      delete data.documents
      setMasterObject(data)

      const renewData = data.renewData
      renewData.map((item, index) => {
        item.id = index + 1
        item.date = dateConverter(item.renewDate)
        item.validity = dateConverter(item.validity)
        item.options = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              onClick={() => {
                setPopupView(true)
              }}
              className="fas fa-eye"
              style={{
                fontSize: "1rem",
                cursor: "pointer",
                color: "black",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
            ></i>
          </div>
        )
        // documents.push(...item.documents)
        return item
      })
      setRenewHistory(renewData)
      documents?.map((item, index) => {
        item.id = index + 1
        item.options = item.file ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <a href={API_URL + item.file} target="_blank" rel="noreferrer">
              <i
                className="fas fa-file-pdf"
                style={{
                  fontSize: "1rem",
                  cursor: "pointer",
                  color: "black",
                  marginLeft: "0.2rem",
                  marginRight: "0.5rem",
                }}
              ></i>
            </a>
          </div>
        ) : (
          ""
        )

        // if (isNull(item.name)) return null
        return item
      })
      console.log(documents)
      setTableData(documents)
    })
  }

  // const handleRenewHistory = () => {
  //   get(`agreement/details?id=${id}`).then(res => {
  //     const data = res.data || []
  //     // renewDate: 1, person: 1, validity: 1
  //     data.map((item, index) => {
  //       item.date = dateConverter(item.renewDate)
  //       item.validity = dateConverter(item.validity)
  //       return item
  //     })

  //     setRenewHistory(data)
  //   })
  // }

  const handleChangeTab = (event, newValue) => {
    setTab3(newValue)
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Document Name",
        field: "name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Document",
        field: "options",
        sort: "asc",
        width: 200,
      },
    ],
    rows: tableData,
  }
  const data2 = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Renewed Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Signed person",
        field: "person",
        sort: "asc",
        width: 400,
      },
      {
        label: "Expiry",
        field: "validity",
        sort: "asc",
        width: 400,
      },
      {
        label: "Action",
        field: "options",
        sort: "asc",
        width: 400,
      },
    ],
    rows: renewHistory,
  }
  return (
    <React.Fragment>
      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="md"
        centered={true}
        className="lead-modal"
      >
        <div className="modal-header">
          <div className="modal-title">
            <h6 style={{ marginBottom: "0px" }} className="modal-title ">
              <h6>Renewal Details</h6>
            </h6>
          </div>
          <button
            style={{ marginBottom: "2px" }}
            type="button"
            onClick={handleCloseModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <Col md="12">
            <table className="table table-borderd lead-info ">
              {renewHistory[0]?.documents.map((el, index) => {
                return (
                  <>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Documents Name</th>
                        <th>Documents</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {/* <td style={{ width: "140px" }}>Document Name :</td> */}
                        <th style={{ textAlign: "center" }}>{index + 1}</th>
                        <th style={{ textAlign: "center" }}>{el?.name}</th>
                        {/* <td style={{ width: "110px" }}>Document:</td> */}
                        <th style={{ textAlign: "center" }}>
                          {" "}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <a
                              href={API_URL + el.file}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i
                                className="fas fa-file-pdf"
                                style={{
                                  fontSize: "1rem",
                                  cursor: "pointer",
                                  color: "black",
                                  marginLeft: "0.2rem",
                                  marginRight: "0.5rem",
                                }}
                              ></i>
                            </a>
                          </div>
                        </th>
                      </tr>
                    </tbody>
                  </>
                )
              })}
            </table>
          </Col>
        </div>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem={masterObject.agreement} />
          <Row>
            <Col xl="12">
              {/* First Dialogue */}

              <div>
                <div className="row">
                  <div className="col-xl-3">
                    <div
                      className="card"
                      style={{
                        // width: "fit-content",
                        // width: "351px",
                        height: "max-content",
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                      }}
                    >
                      <div className="card-body">
                        <div className="float-end dropdown"></div>
                        <h5
                          className="mt-2 mb-2"
                          style={{
                            textAlign: "center",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}
                        >
                          {masterObject.agreement}
                        </h5>
                        <div
                          className="text-muted"
                          style={{
                            textAlign: "center",
                            color: "green !important",
                            fontFamily: "IBM Plex Sans,sans-serif",
                            fontSize: "14px",
                            marginBottom: "3px",
                          }}
                        >
                          {/* {!renew ? (
                            <div
                              class="btn-group btn-group-sm"
                              role="group"
                              aria-label="Basic example"
                            >
                              <button
                                class="btn btn-success"
                                style={{
                                  padding: "5px 8px",
                                  fontSize: "13px",
                                }}
                                onClick={() => {
                                  navigate("/renewals", {
                                    state: {
                                      option1: {
                                        label: "Educational Alliance Compact",
                                        value: "Value 5",
                                      },
                                      option2: {
                                        label: "L&t Pvt.Ltd",
                                        value: "Value 6",
                                      },
                                    },
                                  })
                                }}
                              >
                                Renew Agreement
                              </button>
                            </div>
                          ) : (
                            ""
                          )} */}
                        </div>
                        <p
                          style={{
                            textAlign: "center",
                            marginBottom: "3px",
                          }}
                        ></p>

                        <Divider />

                        <div className="mt-2">
                          <p className="mb-1">CSP:</p>
                          <h5 className="font-size-14">
                            {masterObject.branch}
                          </h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">Type:</p>
                          <h5 className="font-size-14">{masterObject.type}</h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">Agreement Date:</p>
                          <h5 className="font-size-14">{masterObject.date}</h5>
                        </div>
                        {masterObject.person ? (
                          <div className="mt-2">
                            <p className="mb-1">Signed Person:</p>
                            <h5 className="font-size-14">
                              {masterObject.person}
                            </h5>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="mt-2">
                          <p className="mb-1">Valid Up To:</p>
                          <h5 className="font-size-14">
                            {masterObject.validity}
                          </h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">Company:</p>
                          <h5 className="font-size-14">
                            {masterObject.partner}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-9" style={{ marginBottom: "30px" }}>
                    <div
                      className="mb-0 card"
                      style={{
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={tab3}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              indicatorColor="primary"
                              textColor="primary"
                              variant="fullWidth"
                              onChange={handleChangeTab}
                              aria-label="lab API tabs example"
                            >
                              <Tab
                                icon={
                                  <GradingIcon style={{ fontSize: "20px" }} />
                                }
                                label="Agreement Documents"
                                value="1"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />

                              <Tab
                                icon={
                                  <WorkHistoryIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Renewal History"
                                value="2"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                            </TabList>
                          </Box>
                          <TabPanel
                            value="1"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col className="col-12">
                                <MDBDataTable
                                  id="appliedDataId"
                                  responsive
                                  bordered
                                  data={data}
                                  searching={true}
                                  info={false}
                                  disableRetreatAfterSorting={true}
                                />
                              </Col>
                            </Row>
                          </TabPanel>

                          <TabPanel
                            value="2"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col className="col-12">
                                <MDBDataTable
                                  id="appliedDataId"
                                  responsive
                                  bordered
                                  data={data2}
                                  searching={true}
                                  info={false}
                                  disableRetreatAfterSorting={true}
                                />
                              </Col>
                            </Row>
                          </TabPanel>
                        </TabContext>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AgreementDetails
