import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  USER_ALLOTTED,
  USER_ALLOTTED_SUCCESS,
  USER_ALLOTTED_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  success: "",
  allotted: { branches: [], branch: true },
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state }
      break
    case PROFILE_SUCCESS:
      state = { ...state, success: action.payload }
      break
    case PROFILE_ERROR:
      state = { ...state, error: action.payload }
      break
    case RESET_PROFILE_FLAG:
      state = { ...state, success: null }
      break
    case USER_ALLOTTED:
      state = { ...state, success: action.payload }
      break
    case USER_ALLOTTED_SUCCESS:
      state = { ...state, allotted: action.payload }
      break
    case USER_ALLOTTED_ERROR:
      state = { ...state, error: action.payload }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default profile
