import React, { useEffect, useState } from "react"
import "./attendance.scss"
import { get } from "../../../../../helpers/api_helper"
import { Badge } from "react-bootstrap"
import Tooltip from "@mui/material/Tooltip"
import moment from "moment"
import { minutesToHour } from "../../../../../helpers/functions"
import { Button } from "reactstrap"
import ExcelJS from "exceljs"
import toastr from "toastr"

const Attendance = ({ id }) => {
  const [tableData, setTableData] = useState([])
  const [sessionData, setSessionData] = useState([])
  const [actualMinutes, setActualMinutes] = useState(0)
  const [totalTimeMinutes, setTotalTimeMinutes] = useState(0)
  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [id])

  const handleTableData = () => {
    get(`/batch/attendance?id=${id}`).then(res => {
      const sessionData = res.session
      const totalAttendanceMarkedMinutes = sessionData.reduce(
        (accumulator, { sessionMinutes }) => accumulator + sessionMinutes,
        0
      )

      const sessions = []

      let sessionCount = 1
      for (const item of sessionData) {
        if (item.sessionCount > 1) {
          let count = 1
          while (count <= item.sessionCount) {
            // sessions.push(`${item.session}-${count}`)
            sessions.push({
              name: item.session,
              date: item.date,
              value: `${sessionCount}-${count}`,
            })
            count++
          }
        } else {
          sessions.push({
            name: item.session,
            date: item.date,
            value: `${sessionCount}`,
          })
        }
        sessionCount++
      }

      const result = res.data
      setTableData(result)
      setSessionData(sessions)
      setTotalTimeMinutes(result[0]?.totalTime || 0)
      setActualMinutes(totalAttendanceMarkedMinutes)
    })
  }

  const handleExport = async () => {
    if (tableData.length > 0) {
      const exportData = []

      const transformedData = [
        { sessions: sessionData },
        { sessions: sessionData },
        ...tableData,
      ]

      transformedData.forEach((item, index) => {
        const details = {}

        details["#"] = ""
        details["Reg Id"] = ""
        details["Student"] = ""
        details["Total Hours"] = ""
        details["Actual Hour"] = ""
        details["Attended"] = ""
        details["Percentage"] = ""

        if (index > 1) {
          details["#"] = index - 1

          details["Reg Id"] = item.studentRegId

          details["Student"] = `${item.studentFirstName || ""} ${
            item.studentLastName || ""
          }`

          details["Total Hours"] = minutesToHour(item.totalTime)
          details["Actual Hour"] = minutesToHour(actualMinutes)
          details["Attended"] = minutesToHour(item.attended)
          details["Percentage"] =
            parseInt((item.attended / actualMinutes) * 100) + "%"
        }

        item.sessions.forEach((sess, sIndex) => {
          if (typeof sess.attended === "undefined") {
            if (index === 0) {
              details[`session-${sIndex + 1}`] = moment(sess.date).format(
                "DD-MM-YYYY"
              )
            } else if (index === 1) {
              details[`session-${sIndex + 1}`] = sess.name
            }
          } else {
            details[`session-${sIndex + 1}`] = sess.attended
              ? "Present"
              : "Absent"
          }
        })

        exportData.push(details)
      })

      // Create a new workbook and worksheet
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet("Attendance")

      // Add header row
      worksheet.addRow(Object.keys(exportData[0]))

      // Add data rows
      exportData.forEach(rowData => {
        const row = worksheet.addRow(Object.values(rowData))

        // Apply cell styles here if needed
        row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
          // Example: Set text color based on cell value
          if (cell.value === "Present") {
            cell.font = { color: { argb: "FF006400" } } // Green text
          } else if (cell.value === "Absent") {
            cell.font = { color: { argb: "FFFF0000" } } // Red text
          }
        })
      })

      // Save the workbook to a file
      workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
        const url = URL.createObjectURL(blob)
        const link = document.createElement("a")
        link.href = url
        link.download = `Attendance-${moment().format("DD-MM-YY")}.xlsx`
        link.click()
        URL.revokeObjectURL(url)
      })
    } else {
      toastr.info("There are no Attendance to export")
    }
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <p>
            Total Hours : <b>{minutesToHour(totalTimeMinutes)}</b>
          </p>
          <p>
            Actual Hours : <b>{minutesToHour(actualMinutes)}</b>
          </p>
        </div>
        <Button
          onClick={() => handleExport()}
          color="primary"
          className="btn-sm"
        >
          Export
        </Button>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered" id="attendanceTab">
          <thead>
            <tr>
              <th>#</th>
              <th>Reg Id</th>
              <th>Student</th>
              <th>
                <Tooltip
                  title="Total Session time (Minutes)"
                  arrow
                  placement="top"
                >
                  Time (Minutes)
                </Tooltip>
              </th>
              <th>%</th>
              {sessionData.map(session => (
                <th key={session?.name} className="cursor-pointer">
                  <Tooltip
                    placement="top"
                    title={
                      <React.Fragment>
                        <div>
                          <b>Name :</b> {session?.name} <br />
                          <b>Date :</b>{" "}
                          {moment(session?.date).format("DD-MM-YYYY")}
                        </div>
                      </React.Fragment>
                    }
                  >
                    <p>{session?.value}</p>
                  </Tooltip>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((item, index) => {
              return (
                <tr key={item._id}>
                  <td>{index + 1}</td>
                  <td>{item.studentRegId}</td>
                  <td>
                    {(item.studentFirstName ? item.studentFirstName : "") +
                      " " +
                      (item.studentLastName ? item.studentLastName : "")}
                  </td>
                  <td>
                    <Tooltip
                      title="Attended Hours / Actual Hours"
                      arrow
                      placement="right"
                    >
                      {(item.attended + "").padStart(
                        (actualMinutes + "").length,
                        "0"
                      )}
                      /{actualMinutes}
                    </Tooltip>
                  </td>
                  <td>
                    {parseInt((item.attended / actualMinutes) * 100) + "%"}
                  </td>

                  {item.sessions.map(session => (
                    <td key={session}>
                      {/* <p>{session.attended ? "Percent" : "Absent"}</p> */}
                      <Badge
                        className={
                          session.attended ? "bg-success" : "bg-danger"
                        }
                        style={{ fontSize: "12px", padding: ".3rem" }}
                      >
                        {session.attended ? "P" : "A"}
                      </Badge>
                    </td>
                  ))}
                </tr>
              )
            })}
            {/* <tr>
              <th>1</th>
              <td>Anjal</td>
              <td>100/200</td>
              {sessionData.map(session => (
                <td key={session}>
                  {" "}
                  <p>1hour</p>
                </td>
              ))}
            </tr>
            <tr>
              <th>2</th>
              <td>Anjali</td>
              <td>150/200</td>
              {sessionData.map(session => (
                <td key={session}>
                  {" "}
                  <p>{session}</p>
                </td>
              ))}
            </tr> */}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default Attendance
