import React from "react"
import MiniWidget from "./mini-widget"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Col,
  Container,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import Topuser from "./Topselling-product"
// import LatestTransaction from "./latest-transaction"
import Chartapex from "./chartapex"

import { Card, CardBody, CardTitle } from "reactstrap"
// import "./style.scss"

function EventDashboard() {
  const series1 = [40]

  const options1 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series2 = [70]

  const options2 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series3 = [55]

  const options3 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series4 = [33]

  const options4 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const reports = [
    {
      id: 1,
      icon: "mdi mdi-arrow-up-bold",
      title: "Upcoming Batch",
      value: 0,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue: "0",
      color: "success",
      desc: "Total Students",
      series: series1,
      options: options1,
    },
    {
      id: 2,
      icon: "mdi mdi-arrow-up-bold",
      title: "Upcoming Temporary Batches",
      value: 0,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue: "0",
      color: "success",
      desc: "Total Batches",
      series: series2,
      options: options2,
    },
    {
      id: 3,
      icon: "mdi mdi-arrow-up-bold",
      title: "Active Students",
      value: "0",
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue: "0",
      color: "success",
      desc: "Total Students",
      series: series3,
      options: options3,
    },
    {
      id: 4,
      icon: "mdi mdi-arrow-up-bold",
      title: "Transfered Students",
      value: 0,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue: "0",
      color: "success",
      desc: "",
      series: series4,
      options: options4,
    },
  ]
  return (
    <div>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Student-Dashboard" />

          <Row>
            <MiniWidget reports={reports} />
          </Row>
          <Row>
            {/* <Col md="4" lg="4">
              <Topuser title="Latest Scholarship Requests" />
            </Col> */}
            <Col md="8" lg="8">
              <Card>
                <CardBody>
                  {/* <CardTitle>Batches At ASAP</CardTitle> */}
                  <Chartapex />
                </CardBody>
              </Card>
            </Col>
            <Col md="4" lg="4">
              <Topuser title=" Latest Skill loan Requests" />
            </Col>
          </Row>
          <Row>
            {/* <Col md="12" lg="12">
              <LatestTransaction />
            </Col> */}
          </Row>

          {/* <Row>
            <Col md="8" lg="8">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Event @ Asap</CardTitle>
                  <Chartapex />
                </CardBody>
              </Card>
            </Col>
          </Row> */}
        </Container>
      </div>
    </div>
  )
}

export default EventDashboard
