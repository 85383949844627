import React, { useEffect, useRef, useState} from "react"
import { Row, Col, Card, CardBody, Label, Button, Input } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { get, post, put, del } from "../../../helpers/api_helper"
import { isArray } from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import toastr from "toastr"
import { useNavigate } from "react-router-dom"
import SunEditor from "suneditor-react"
import plugins from "suneditor/src/plugins"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

import _ from "lodash"

import { en } from "suneditor/src/lang"
import { EditorView } from "codemirror"
import katex from "katex"
import "suneditor/dist/css/suneditor.min.css"
import "katex/dist/katex.min.css"
import { API_URL, PRIVILEGES } from "../../../config"
import {
  getCluster,
  getSectors,
  getQualification,
  getDistrictOptions,
} from "../../../store/options/actions"
import { isRecruiter } from "../../../helpers/functions"
import Swal from "sweetalert2"
import moment from "moment"
import "./style.scss"
import axios from "axios"
import SelectErrorMessage from "../../../components/Common/SelectErrorMessage"
import MuiCustomTablePagination from "../../../components/Common/TablePagination"
import { isAllowed } from "../../../components/Common/Allowed"
const AddJobPost = () => {
  const dispatch = useDispatch()
  const formRef = useRef()
  const recruiter = isRecruiter()
  const [page,setPage] = useState(0)
  const [pageSize,setPageSize] = useState(20)
  const [count, setCount] = useState(21)
  const [recruitersoptions, setRecruitersoptions] = useState([])
  const [jobDistricts, setjobDistricts] = useState([])
  const [selectedtableData, setSelectedtableData] = useState([])
  const [search, setSearch] = useState('')
  const [error, setError] = useState({})
  const [searchSelect, setSearchSelect] = useState({})
  const initialstate = {
    name: "",
    salaryFrom: "",
    salaryTo: "",
    openings: "",
    eligibility: "",
    // qualification:[{}],
    fee: "",
    recruiter1: "",
    status: "",
    description: "",
    expiryDate: "",
    state: [19],
    details: [{}],
    recruiter: recruiter.status ? recruiter.id : "",
  }
  const [master, setmaster] = useState(initialstate)
  const [selectedstatus, setselectedstatus] = useState(null)
  const [selectedrecruiter, setSelectedrecruiter] = useState(null)
  const [selected, setSelected] = useState({
    state: [
      {
        _id: 19,
        name: "Kerala",
        label: "Kerala",
        value: 19,
      },
    ],
  })
  const navigate = useNavigate()
  const initialState1 = {
    status: "",
    recruiter1: "",
  }
  const [masterObject, setMasterObject] = useState(initialState1)
  const { sectors, qualification, districts, states } = useSelector(
    state => state.Options
  )

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  const allowed = isAllowed([
    PRIVILEGES.ADMIN,
    PRIVILEGES.CSP_PROGRAM_MANAGER,
    PRIVILEGES.RECRUITER_PM
  ])

  useEffect(() => {
    handleTableData()
  }, [searchSelect,search,page,pageSize])

  useEffect(() => {
    fetchAllRecruiters()
  }, [])

  function fetchAllRecruiters() {
    get(`/options/recruiters`).then(res => {
      let result = res.data
      setRecruitersoptions(result)
    })
  }

  function fetchDistricts(s) {
    if (s?.length === 0 ) {
      setjobDistricts([])
      return
    }
    const stateString = s.map(item=>item.value).join(',')
    get(`/job/options/district?state=${stateString}`).then(res => {
      let result = res.data
      setjobDistricts(result)
    })
  }

  useEffect(()=>{
    fetchDistricts(selected?.state)
  },[selected?.state])

  const options = {
    plugins: plugins,
    height: 250,
    EditorView: {
      src: EditorView,
      options: {
        indentWithTabs: true,
        tabSize: 2,
      },
    },
    katex: katex,
    lang: en,
    buttonList: [
      [
        "font",
        "fontSize",
        "formatBlock",
        "bold",
        "underline",
        "italic",
        "paragraphStyle",
        "blockquote",
        "strike",
        "subscript",
        "superscript",
        "fontColor",
        "hiliteColor",
        "textStyle",
        "removeFormat",
        "undo",
        "redo",
        "outdent",
        "indent",
        "align",
        "horizontalRule",
        "list",
        "lineHeight",
        "table",
        "link",
        // 'image',
        // 'video',
        // 'audio',
        // You must add the 'katex' library at options to use the 'math' plugin.
        // 'math',
        // You must add the "imageGalleryUrl".
        // 'imageGallery',
        "fullScreen",
        "showBlocks",
        "codeView",
        "preview",
        // 'print'
        // 'save',
        // 'template'
      ],
    ],
  }
  const genderoptions = [
    { label: "Male", value: 0 },
    { label: "Female", value: 1 },
    { label: "Transgender", value: 2 },
    { label: "No preference", value: 3 },
  ]
  const experianceOptions = [
    { label: "0-1 Years", value: 0 },
    { label: "1-3 Years", value: 1 },
    { label: "3-5 Years", value: 2 },
    { label: "5-7 Years", value: 3 },
    { label: "7-9 Years", value: 4 },
    { label: "9+ Years", value: 5 },
  ]
  useEffect(() => {
    dispatch(getSectors())
    dispatch(getCluster())
    dispatch(getQualification())
    // dispatch(getDistrictOptions({ id: 19 }))
    // eslint-disable-next-line
  }, [dispatch])

  const status = [
    { label: "Ongoing", value: 0 },
    { label: "Inactive", value: 2 },
    { label: "Expired", value: 3 },
    { label: "Filled", value: 4 },
  ]
  const handleStatusChange = async (requestId, approvedStatus) => {
    put("/job/status", { _id: requestId, status: approvedStatus })
      .then(res => {
        handleTableData()
        toastr.success(res.message)
      })
      .catch(err => {
        toastr.error(err.response.data.message)
      })
  }

  const handleChangeRowsPerPage = event => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handlePageChange = newPage => {
    setPage(newPage)
  }

  const handleApprove = (id,approve,index)=> {
    let value
    if(approve===1){
      value = 2
    }else if(approve === 2){
      value = 1
    }
    post(`job/approve`,{job:id,approve:value}).then(res=>{
      if(res.success){
        toastr.success(res.message)
        handleTableData()
      }
    }).catch(err=>{
      toastr.error(err.response.data.message)
    })
  }

  const fieldTypeOptions = [
    { label: "Job Description", value: "1" },
    { label: "Eligibility", value: "2" },
  ]
  const [selectOptions, setSelectOptions] = useState([...fieldTypeOptions])

  function handleTableData() {
    const SearchQuery = `status=${searchSelect?.status?.value}&recruiter=${searchSelect?.recruiter1?.value}&search=${search}&page=${page}&limit=${pageSize}`

    get(`job/basic?${SearchQuery}`).then(res => {
      setCount(res?.count)
      let result = res?.data
      result?.map((item, index) => {
        item.id = index + 1 + (page * pageSize)
        item.date = moment(item?.date).format("DD-MM-YYYY")

        item.exdate = item?.expiryDate
          ? moment(item?.expiryDate).format("DD-MM-YYYY")
          : ""

        item.qualification = item?.qualification
          ? item.qualification?.map(el => el?.name).join(", ")
          : ""
        item.recruiter = item?.recruiter?.name
        item.addedBy = item?.addedBy?.name
        item.status = (
          <div className="mb-3">
            <Select
              menuPosition="fixed"
              name="job_status"
              placeholder="Ongoing"
              onChange={selected => {
                handleStatusChange(item?._id, selected.value)
                setselectedstatus(selected.value)
              }}
              value={status?.filter(status => status.value === item?.status)}
              options={status}
              classNamePrefix="select2-selection"
            />
          </div>
        )

        item.options = (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                width: "100px",
              }}
            >
              {allowed && item.approve && (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="copy-event-tooltip">
                      {item.approve === 2 ? "Unapprove" : "Approve"}
                    </Tooltip>
                  }
                >
                  <Input
                    onClick={() => handleApprove(item._id, item.approve, index)}
                    checked={item.approve === 2}
                    type="checkbox"
                    style={{ marginRight: "0.5rem" }}
                  />
                </OverlayTrigger>
              )}
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  navigate(`/create-job-post/${item?.uniqueId}`, {
                    state: { id: item._id },
                  })
                }}
              ></i>
              <i
                className="far fa-edit"
                onClick={() => {
                  handleUpdate(item._id)
                  toTop()
                }}
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2rem",
                  marginRight: "0.5rem",
                }}
              ></i>
              <i
                className="far fa-trash-alt"
                onClick={() => {
                  handleDelete(item?._id)
                }}
                style={{ fontSize: "1em", cursor: "pointer" }}
              ></i>
            </span>
          </div>
        )
      })
      setSelectedtableData(result)
    })
  }

  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`/job/basic?id=${id}`)
          .then(res => {
            toastr.success(res.message)
            handleTableData()
            formRef.current.reset()
          })
          .catch(err => {
            toastr.success(err.response.data.message)
          })
      }
    })
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "ID",
        field: "uniqueId",
        width: 150,
      },
      {
        label: "Recruiter",
        field: "recruiter",
        width: 150,
      },
      {
        label: "Job Name",
        field: "name",
        width: 150,
      },
      {
        label: "Expiry",
        field: "exdate",
        width: 150,
      },
      // {
      //   label: "Qualificaion",
      //   field: "qualification",
      //   width: 150,
      // },
      {
        label: "Status",
        field: "status",
        width: 150,
      },
      {
        label: "Added By",
        field: "addedBy",
        width: 150,
      },
      {
        label: "Action",
        field: "options",
        width: 150,
      },
    ],
    rows: selectedtableData,
  }

  function handleSubmit() {
    let flag = false

    if (!master.image) {
      // Assuming 'master.image' represents the selected file
      flag = true
      setError(prevState => ({ ...prevState, image: true }))
    }
    if (selectedrecruiter === null && !isRecruiter().status) {
      flag = true
      setError(pre => ({ ...pre, recruiter: true }))
    }
    if (selected.experience == null) {
      flag = true
      setError(pre => ({ ...pre, experience: true }))
    }
    // if (selected.sector == null) {
    //   flag = true
    //   setError(pre => ({ ...pre, sector: true }))
    // }

    if (flag) {
      return
    }
    if (master._id) {
      put(`/job/basic`, master)
        .then(res => {
          toastr.success(res.message)
          handleTableData()
          handleReset()
          formRef.current.reset()
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    } else {
      post(`/job/basic`, master)
        .then(res => {
          toastr.success(res.message)
          handleTableData()
          handleReset()
          formRef.current.reset()
        })
        .catch(err => {
          // formRef.current.reset()
          toastr.error(err.response.data.message)
        })
    }
  }
  function handleUpdate(id) {
    get(`/job?id=${id}`).then(res => {
      let data = res.data
      if (data?.details && data?.details?.length) {
        handleOptionsData(data)
      }
      setSelectedrecruiter({
        label: data?.recruiter?.name,
        value: data?.recruiter?._id,
      })

      // setmasterobj(prevMaster => ({ ...prevMaster, ...props?.data }))
      setSelected({
        recruiter: { label: data?.recruiter?.name, value: data?.recruiter._id },
        sector: { label: data?.sector?.name, value: data?.sector?._id },

        state: data.state
          ? data?.state?.map(item => ({
              label: item.name,
              value: item._id,
              ...item,
            }))
          : [],
        location: data.location
          ? data?.location?.map(item => ({
              label: item.name,
              value: item._id,
              ...item,
            }))
          : [],
        qualification: data.qualification
          ? data?.qualification?.map(item => ({
              label: item.name,
              value: item._id,
              ...item,
            }))
          : [],
        gender: genderoptions?.filter(item => item.value === data?.gender),
        experience: experianceOptions?.filter(
          item => item.value === data?.experience
        ),

        // type: { label: data.type === 0 ? "paid" : "Unpaid" || null,
        // value: data.type || null,},
        // unit: { label: data.unit === 0 ? "days" :data.unit === 1 ? "Weeks" : data.unit === 0 ? "Months" : "Years" || null,
        // value: data.unit || null,},
      })
      setmaster(data)
    })
  }
  const handleSelect = (e, name) => {
    const obj = { ...selected }
    const masterObj = { ...master }

    const value = e ? (isArray(e) ? e.map(item => item.value) : e.value) : null

    if (name === "state" && (e?.length < obj.state?.length || e?.length < 1)) {
      const removedState = _.difference(obj[name], e)
      masterObj.location = []

      obj.location = obj.location?.filter(el => {
        if (el.stateId !== removedState[0].value) {
          masterObj.location.push(el.value)
        }

        return el.stateId !== removedState[0].value
      })

 
    }
    obj[name] = e
    masterObj[name] = value

    setSelected(obj)
    setmaster(masterObj)
  }

  function handlevaluechange(value, name) {
    const obj = { ...master }
    obj[name] = value
    setmaster(obj)
  }

  function handleReset() {
    formRef.current.reset()
    toTop()
    setmaster(initialstate)
    // setmaster({description:""})
    setSelectedrecruiter(null)
    setError({})
    setSelectOptions([...fieldTypeOptions])
    fetchDistricts([19])
    setSelected({
      recruiter: null,
      sector: null,
      qualification: null,
      gender: null,
      experience: null,
      location: null,
      state: [
        {
          _id: 19,
          name: "Kerala",
          label: "Kerala",
          value: 19,
        },
      ],
    })
  }

  const onChangeSuneditor = content => {
    setmaster(prevState => ({
      ...prevState,
      description: content, // Update description property with the new content
    }))
  }

  const onChangeSuneditor1 = (content, idx) => {
    setmaster(prevState => {
      const updatedMasterobj = { ...prevState }
      const updatedDetails = [...updatedMasterobj.details]
      updatedDetails[idx] = { ...updatedDetails[idx], contents: content }
      updatedMasterobj.details = updatedDetails
      return updatedMasterobj
    })
  }

  const handlePreValueChange = (value, name, index) => {
    const data = { ...master }
    const pre = data?.details
    pre[index][name] = value
    data.details = pre
    setmaster(data)
    handleOptionsData(data)
  }
  const handleOptionsData = master => {
    let options = [...fieldTypeOptions]

    master.details?.map(item => {
      if (item.tab) {
        options = options?.filter(option => {
          return option.value !== "" + item.tab
        })
      }
      return item
    })

    setSelectOptions(options)
  }

  const admin = isRecruiter()
  useEffect(() => {
    if (!admin) {
      const user = JSON.parse(localStorage.getItem("authUser"))

      setmaster(prev => ({ ...prev, recruiter: user?.company[0] }))
    }
  }, [])

  const uploadBanner = e => {
    const file = e.target.files[0]
    const name = e.target.name

    if (!file) {
      toastr.error("Please select an image file.")
      return
    }

    // Check if file is an image
    if (!file.type.startsWith("image")) {
      toastr.error("Please choose an image file.")
      e.target.value = ""
      return
    }

    // Create image object to get dimensions
    const img = new Image()
    img.src = URL.createObjectURL(file)

    img.onload = () => {
      //   // Check image dimensions
      if (!(img.width >= 1100 && img.height >= 640)) {
        toastr.error("Image dimensions should be at least 1100x640 pixels")
        e.target.value = ""
        return
      }

      // // Check file size
      if (file.size > 300 * 1024 || file.size < 100 * 1024) {
        toastr.error("Image size should be between 150KB and 200KB")
        e.target.value = ""
        return
      }

      // Image is valid, proceed with upload
      const fd = new FormData()
      fd.append(name, file)
      axios
        .post(`${API_URL}job/image`, fd)
        .then(response => {
          if (response.data.status === 200) {
            setmaster({
              ...master,
              image: response.data.data.new_filename,
            })
          } else {
            toastr.error(response.data.message)
          }
        })
        .catch(error => {
          console.error("Error uploading image:", error)
        })
    }
  }
  function handleSelectChange(selected, name) {
    switch (name) {
      case "recruiter":
        setSelectedrecruiter(selected)
        setmaster({ ...master, recruiter: selected.value })
        break

      default:
        break
    }
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Home" breadcrumbItem="Create Job Post" />

        <Row>
          <Col xl={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col xl="12">
                    <AvForm onValidSubmit={handleSubmit} ref={formRef}>
                      <Row>
                        {!isRecruiter().status ? (
                          <Col md="3">
                            <div className="mb-3">
                              <Label>Recruiter</Label>
                              <Select
                                name="recruiter"
                                classNamePrefix="select2-selection"
                                options={recruitersoptions}
                                className="recruiterzindex"
                                // value={selected.recruiter}
                                value={selectedrecruiter}
                                onChange={e => {
                                  // setSelectedrecruiter(e)
                                  handleSelectChange(e, "recruiter")
                                  // setSelectedAssign(e)
                                }}
                              />
                              <SelectErrorMessage
                                show={
                                  selectedrecruiter === null && error.recruiter
                                }
                                customStyle={{
                                  width: "100%",
                                  fontSize: "87.5%",
                                  color: "var(--bs-form-invalid-color)",
                                }}
                                message="Please select a Recruiter"
                              />
                            </div>
                          </Col>
                        ) : null}
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Job Post Title</Label>
                            <AvField
                              name="name"
                              className="form-control"
                              type="text"
                              value={master.name}
                              onChange={e => {
                                handlevaluechange(e.target.value, "name")
                              }}
                              placeholder="Job Post Title"
                              errorMessage="Enter Job Post Title"
                              validate={{ required: { value: true } }}
                            />
                          </div>
                        </Col>
                        {/* <Col md="3">
                          <div className="mb-3">
                            <Label>Cluster</Label>
                            <Select
                              name="cluster"
                              classNamePrefix="select2-selection"
                              options={cluster}
                            />
                          </div>
                        </Col> */}
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Sector</Label>
                            <Select
                              name="sector"
                              classNamePrefix="select2-selection"
                              options={sectors}
                              className="sectorrzindex"
                              value={selected.sector}
                              onChange={selected => {
                                handleSelect(selected, "sector")
                              }}
                            />
                            {/* <SelectErrorMessage
                            show={selected.sector == null && error?.sector}
                            customStyle={{
                              width: "100%",
                              fontSize: "87.5%",
                              color: "var(--bs-form-invalid-color)",
                            }}
                            message="Please select Sector"
                          /> */}
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label>Qualification</Label>
                            <Select
                              name="qualification"
                              isMulti
                              classNamePrefix="select2-selection"
                              className="qualificationzindex"
                              options={qualification}
                              value={selected.qualification}
                              onChange={selected => {
                                handleSelect(selected, "qualification")
                              }}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Experience</Label>
                            <Select
                              name="experience"
                              classNamePrefix="select2-selection"
                              value={selected.experience}
                              className="experiancezindex"
                              onChange={selected => {
                                handleSelect(selected, "experience")
                              }}
                              options={experianceOptions}
                            />
                            <SelectErrorMessage
                              show={
                                selected.experience == null && error?.experience
                              }
                              customStyle={{
                                width: "100%",
                                fontSize: "87.5%",
                                color: "var(--bs-form-invalid-color)",
                              }}
                              message="Please select Experience"
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Gender</Label>
                            <Select
                              name="gender"
                              classNamePrefix="select2-selection"
                              options={genderoptions}
                              value={selected.gender}
                              className="genderzindex"
                              onChange={selected => {
                                handleSelect(selected, "gender")
                              }}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>No. of Openings</Label>
                            <AvField
                              name="openings"
                              className="form-control"
                              type="number"
                              placeholder="No. of Openings"
                              value={master.openings}
                              onChange={e => {
                                handlevaluechange(e.target.value, "openings")
                              }}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Enter No. of Openings",
                                },
                                isPercentage: value => {
                                  // Check if it's a percentage field
                                  const percentage = parseFloat(value) // Parse the value to a floating-point number
                                  if (!isNaN(percentage)) {
                                    return percentage > 0
                                  } else {
                                    return false // Not a valid number
                                  }
                                },
                              }}
                              errorMessage="Enter a valid value"
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label>Salary Range From (&#x20B9; &nbsp;LPA)</Label>
                            <AvField
                              name="salaryFrom"
                              className="form-control"
                              type="number"
                              value={master.salaryFrom}
                              onChange={e => {
                                handlevaluechange(e.target.value, "salaryFrom")
                              }}
                              min={0}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Enter Salary Range From",
                                },
                                isPercentage: value => {
                                  // Check if it's a percentage field
                                  const percentage = parseFloat(value)
                                  if (!isNaN(percentage)) {
                                    // If the value is not NaN
                                    if (percentage === 0) {
                                      // Check if it's zero
                                      return true // Allow zero
                                    } else {
                                      return percentage >= 0 // Allow positive values
                                    }
                                  } else {
                                    return false // Not a valid number
                                  }
                                },
                              }}
                              errorMessage="Enter a valid value"
                              placeholder="Salary Range From"
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label>Salary Range To (&#x20B9; &nbsp;LPA)</Label>
                            <AvField
                              name="salaryTo"
                              className="form-control"
                              type="number"
                              value={master.salaryTo}
                              onChange={e => {
                                handlevaluechange(e.target.value, "salaryTo")
                              }}
                              min={master.salaryFrom}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Enter Salary Range To",
                                },
                                isPercentage: value => {
                                  // Check if it's a percentage field
                                  const percentage = parseFloat(value)
                                  return !isNaN(percentage) && percentage >= 0

                                  return true // If it's not a percentage field, no validation needed
                                },
                              }}
                              errorMessage="Enter a Value Greater Than Salary Range From"
                              placeholder="Salary Range To"
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>State</Label>
                            <Select
                              name="state"
                              isMulti
                              options={states}
                              className="locationzindex"
                              value={selected?.state}
                              onChange={selected => {
                                // dispatch(getDistrictOptions({id:selected.value}))
                                handleSelect(selected, "state")
                              }}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>District</Label>
                            <Select
                              name="location"
                              isMulti
                              options={jobDistricts}
                              className="locationzindex"
                              value={selected.location}
                              onChange={selected => {
                                handleSelect(selected, "location")
                              }}
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <Label>Expiry Date</Label>
                            <AvField
                              name="expiryDate"
                              type="date"
                              value={master?.expiryDate}
                              onChange={e => {
                                handlevaluechange(e.target.value, "expiryDate")
                              }}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">
                              Upload Image{" "}
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="copy-event-tooltip">
                                    {" "}
                                    Specifications: Image should be PNG or JPEG,
                                    with dimensions 1600x1200 pixels, and size
                                    150-200kb.
                                  </Tooltip>
                                }
                              >
                                <i
                                  className="fas fa-info-circle"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "1em",
                                    marginRight: "0.5rem",
                                  }}
                                ></i>
                              </OverlayTrigger>
                            </Label>
                            {master?.image ? (
                              <div div className="img-wraps d-flex flex-column">
                                {master?.image &&
                                master?.image.startsWith("http") ? (
                                  <img
                                    alt=""
                                    width="250"
                                    height="150"
                                    src={`${master?.image}`}
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    width="250"
                                    height="150"
                                    src={`${API_URL}${master?.image}`}
                                  />
                                )}
                                <button
                                  className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                  onClick={() => {
                                    setmaster(pre => ({
                                      ...pre,
                                      image: null,
                                    }))
                                  }}
                                  style={{ width: "250px", marginTop: "4px" }}
                                  type="button"
                                >
                                  Delete
                                </button>
                              </div>
                            ) : (
                              <input
                                name="file"
                                type="file"
                                className="form-control"
                                id="validationCustom03"
                                onChange={uploadBanner}
                                rows="1"
                              />
                            )}
                            {/* {error.image && <p style={{ color: "red" }}>Please select an image.</p>} */}
                            <SelectErrorMessage
                              show={master?.image == null && error.image}
                              customStyle={{
                                width: "100%",
                                fontSize: "87.5%",
                                color: "var(--bs-form-invalid-color)",
                              }}
                              message="Please Upload Image"
                            />
                          </div>
                          {/* <p style={{ fontSize: ".7rem", marginTop: "-1rem" }}>
                          (1600*250)/100-300kb
                        </p> */}
                        </Col>
                        {master?.details?.map((item, idx) => (
                          <Card>
                            <CardBody>
                              <Row>
                                <Col md="3">
                                  <div className="mb-3">
                                    <Label>Tab name</Label>
                                    <Select
                                      className="text-editor-select"
                                      name="tab"
                                      classNamePrefix="select2-selection "
                                      options={selectOptions}
                                      value={fieldTypeOptions?.filter(
                                        tab => tab.value === item.tab
                                      )}
                                      onChange={selected =>
                                        handlePreValueChange(
                                          selected.value,
                                          "tab",
                                          idx
                                        )
                                      }
                                    />
                                  </div>
                                </Col>

                                <Col md="12">
                                  <SunEditor
                                    setDefaultStyle="font-family: Arial; font-size: 14px; height: 100px;"
                                    lang="en"
                                    setOptions={options}
                                    onChange={content =>
                                      onChangeSuneditor1(content, idx)
                                    }
                                    setContents={item.contents || null}
                                  />
                                </Col>
                                {master.details.length > 1 && (
                                  <div className="d-flex justify-content-end">
                                    <span
                                      className="mr-1 p-2"
                                      style={{
                                        fontSize: "1.125rem",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        const data = { ...master }
                                        data.details.splice(idx, 1)
                                        handleOptionsData(data)
                                        setmaster(data)
                                      }}
                                    >
                                      <i className="trash-btn mdi mdi-delete"></i>
                                    </span>
                                  </div>
                                )}
                              </Row>
                            </CardBody>
                          </Card>
                        ))}
                        {selectOptions.length > 0 && (
                          <Col md={12}>
                            <div className="d-flex justify-content-between align-items-center">
                              <button
                                onClick={e => {
                                  e.preventDefault()
                                  const data = { ...master }
                                  data.details.push({})
                                  setmaster(data)
                                }}
                                className="waves-effect btn btn-outline-light d-flex"
                                style={{ gap: "5px" }}
                              >
                                Add more{" "}
                                <i className="mdi mdi-plus-circle-outline"></i>
                              </button>
                            </div>
                          </Col>
                        )}
                        {/* <Col md="12">
                          <Label>Job Description</Label>

                          <SunEditor
                            name="description"
                        
                            setContents={master.description}
                            onChange={content => onChangeSuneditor(content)}
                            setDefaultStyle="font-family: Arial; font-size: 14px; height: 100px;"
                            lang="en"
                            setOptions={options}
                            // onChange={content => onChangeSuneditor(content, idx)}
                          />
                        </Col> */}

                        <Col>
                          <div className="mb-3" style={{ paddingTop: "25px" }}>
                            <Button
                              className="me-2"
                              color={master._id ? "warning" : "primary"}
                              type="submit"
                            >
                              {master._id ? "Update" : "Submit"}
                            </Button>
                            <Button
                              onClick={handleReset}
                              color="danger"
                              type="button"
                            >
                              Reset
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <AvForm>
                  <Row>
                    {!isRecruiter().status ? (
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Recruiter</Label>
                          <Select
                            name="recruiter1"
                            classNamePrefix="select2-selection"
                            options={recruitersoptions}
                            className="recruiterzindex"
                            value={searchSelect.recruiter1 || null}
                            onChange={e => {
                              setSearchSelect(prev=>({...prev,recruiter1:e}))
                              setPage(0)
                            }}
                          />
                        </div>
                      </Col>
                    ) : null}
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Status</Label>
                        <Select
                          onChange={e => {
                            setSearchSelect(prev=>({...prev,status:e}))
                            setPage(0)
                          }}
                          value={searchSelect.status || null}
                          // name="community"
                          options={status}
                        />
                      </div>
                      </Col><Col md='2'>
                      <div className="mb-3" style={{marginTop:'27px'}}>
                        <Input
                        style={{height:'37px'}}
                          onChange={e => {setSearch(e.target.value);setPage(0)}}
                          placeholder="Search..."
                          value={search}
                          // name="community"
                        />
                      </div>
                    </Col>

                    <Col>
                      <div className="mb-3" style={{marginTop:'27px'}}>
                        <Button
                          style={{height:'37px'}}
                          color="danger"
                          type="button"
                          onClick={() => {
                            setSearch('')
                            setSearchSelect({status:null,recruiter1:null})
                            setPage(0)
                          }}
                        >
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
                <MDBDataTable
                  id="jobpostid"
                  bordered
                  responsive
                  searching={false}
                  entries={20}
                  paging={false}
                  info={false}
                  disableRetreatAfterSorting={true}
                  data={data}
                ></MDBDataTable>
                {count > 20 && (
                <MuiCustomTablePagination
                  count={count}
                  page={page}
                  rowsPerPage={pageSize}
                  handleChangePage={handlePageChange}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
              )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default AddJobPost
