import { MDBDataTable } from "mdbreact"
import React, { useEffect, useState } from "react"
import { Badge, Col, Row } from "reactstrap"
import { get } from "../../../../helpers/api_helper"
import { convertMinutesToHours } from "../../../../helpers/functions"
import "./style.scss"
import moment from "moment"
const FeeStatement = ({ id }) => {
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [id])

  const handleTableData = () => {
    get(`/student/attendance?id=${id}`).then(res => {
      const result = res.data
      result.map((item, index) => {
        item.id = index + 1
        item.date = moment(item?.date).format("DD-MM-YYYY")
        item.course = item.courses.name
        item.batch = item.batchData.name
        item.trainer =
          (item.trainerFirstName ? item.trainerFirstName : "") +
          " " +
          (item.trainerLastName ? item.trainerLastName : "")
        item.hours = item.status ? convertMinutesToHours(item.time) : "00:00"
        item.status = (
          <Badge
            className={item.status ? "bg-success" : "bg-danger"}
            style={{ fontSize: "12px", padding: ".3rem" }}
          >
            {item.status ? "Present" : "Absent"}
          </Badge>
        )
        return item
      })
      setTableData(result)
    })
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Course",
        field: "course",
        sort: "asc",
        width: 400,
      },
      {
        label: "Batch",
        field: "batch",
        sort: "asc",
        width: 400,
      },
      {
        label: "Trainer",
        field: "trainer",
        sort: "asc",
        width: 400,
      },
      {
        label: "Duration",
        field: "hours",
        sort: "asc",
        width: 400,
      },
      {
        label: "Session",
        field: "session",
        sort: "asc",
        width: 400,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 400,
      },
      
      
     

      // {
      //   label: "Action",
      //   field: "action",
      //   sort: "asc",
      //   width: 200,
      // },
    ],
    rows: tableData,
  }
  return (
    <Row>
      <Col className="col-12">
        <MDBDataTable
          id="attendanceID"
          className="student-aligned-table fee-table"
          data={data}
          responsive
          bordered
          searching={true}
          info={false}
          disableRetreatAfterSorting={true}
        />
      </Col>
    </Row>
  )
}

export default FeeStatement
