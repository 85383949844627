import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Label,
  Row,
  Col,
  Button,
} from "reactstrap";
import "./completedBatchStyle.scss";
import { AvForm } from "availity-reactstrap-validation";
import Select from "react-select";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import TablePagination from "../../../components/Common/Pagination";
import useBranch from "../../../hooks/useBranch";
import { get } from "../../../helpers/api_helper";
import { useSelector } from "react-redux";

const BatchesUnderProgression = () => {

  const myCsp = useBranch()
  const [myCspId, setMyCspId] = useState("")
  const [tableData,settableData] = useState([])
  const [courses,setCourses] = useState([])
  const [selecttedCourses,setSelectedCourses] = useState(null)
  const [batches,setBatches] = useState([])
  const [selectedBatches,setSelectedBatches] = useState(null)
  const [branches,setBranches] = useState([])
  const [selectedBranches,setSelectedBranches] = useState(null)
  const [selectedCategory,setSelectedCategory] = useState(null)
  const [selectedProgresion,setSelectedProgression] = useState(null)
  const [state,setState] = useState(false)
  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 10

  const getDateFilter = useSelector(state => state.Dashboard.data)

  useEffect(() => {
    let cspId = myCsp ? myCsp?.[0]?.value : ""
     setMyCspId(cspId)
  }, [])

  useEffect(()=>{
    const fetch = async()=>{
      try {
        const res = await get(`/batch/csp-options?status=${3}&startDate=${getDateFilter?.startDate}&endDate=${getDateFilter?.endDate}`)
        setBranches(res?.data)
       } catch (error) {
        
       }
    }
    fetch()
  },[getDateFilter])

  useEffect(()=>{
    const fetch = async()=>{
      try {
        const res = await get(`/batch/course-options?status=${3}&csp=${selectedBranches?.value}&startDate=${getDateFilter?.startDate}&endDate=${getDateFilter?.endDate}`)
        setCourses(res?.data)
       } catch (error) {
        
       }
    }
    fetch()
  },[selectedBranches,getDateFilter])

  useEffect(()=>{
    const fetch = async()=>{
      try {
        const res = await get(`/batch/batch-options?status=${3}&csp=${selectedBranches?.value}&course=${selecttedCourses?.value}&category=${selectedCategory?.value}&startDate=${getDateFilter?.startDate}&endDate=${getDateFilter?.endDate}`)
        setBatches(res?.data)
       } catch (error) {
        
       }
    }
    fetch()
  },[selectedBranches,selecttedCourses,selectedCategory,getDateFilter])

  useEffect(()=>{
    const fetch = async() =>{
      try {
        const res = await get(`batch/progress-batch?page=${page}&limit=${limit}&csp=${selectedBranches?.value}&course=${selecttedCourses?.value}&batchId=${selectedBatches?.value}&category=${selectedCategory?.value}&progression=${selectedProgresion?.value}&startDate=${getDateFilter?.startDate}&endDate=${getDateFilter?.endDate}`)
        const data = res.data.map((item, index) => {
          let status = { color: "success", title: "Paid" }
          if (item.fullyApprovedCount === item.registeredCount)
            status = { color: "info", title: "Funded" }
          else if (item.approvedCount === item.registeredCount)
            status = { color: "warning", title: "Partially Funded" }

          const category = (
            <span
              className={`badge rounded-pill bg-soft-${status.color} font-size-12`}
            >
              {status.title}
            </span>
          )
          return {
            ...item,
            id: index + 1 + (page - 1) * limit,
            startDate: moment(item.startDate).format("DD-MM-YYYY"),
            endDate: moment(item.endDate).format("DD-MM-YYYY"),
            progression: item.progression.toFixed(2) + "%",
            category,
          }
        })
        settableData(data)
        const count = res?.count
        const totalPage = Math.ceil(Number(count) / limit)
        setTotalPage(totalPage)
      } catch (error) {
        
      }
    }
    fetch()
  },[getDateFilter,page,selectedBranches,selectedBatches,selecttedCourses,state,selectedCategory,selectedProgresion])

  const handleChange = (event, value) => {
    setPage(value)
  }


  const data = {
    columns: [
      { label: "#", field: "id" },
      // { label: "Batch ID", field: "batchId" },
      { label: "Batch", field: "name" },
      { label: "Course", field: "course" },
      { label: "CSP", field: "csp" },
      { label: "Students Registered", field: "registeredCount" },
      { label: "Progression", field: "progression" },
      { label: "Start Date", field: "startDate" },
      { label: "End Date", field: "endDate" },
      { label: "Category", field: "category" },
    ],
    rows:tableData
  }

  const category = [
    { label: "Paid", value: 1 },
    { label: "Partially Funded", value: 2 },
    { label: "Funded", value: 3 },
  ]
  const progression = [
    { value: '0-25', label: '0-25%' },
    { value: '26-50', label: '26-50%' },
    { value: '51-75', label: '51-75%' },
    { value: '76-100', label: '76-100%' },
  ];

  const reset = () =>{
    setSelectedBatches(null)
    setSelectedCourses(null)
    setSelectedBranches(null)
    setSelectedCategory(null)
    setSelectedProgression(null)
    setState(!state)
  }
  
  return (
    <>
      <Row>
        <Col lg={12}>
              <AvForm>
                <Row>
                  {!myCspId && 
                <Col md="2">
                    <div className="mb-3">
                      <Label>CSP</Label>
                      <Select isClearable={true} 
                      options={branches} 
                      value={selectedBranches}
                       onChange={(item)=>setSelectedBranches(item)} 
                      />
                    </div>
                  </Col>}
                  <Col md="2">
                    <div className="mb-3">
                      <Label>Course</Label>
                      <Select isClearable={true} 
                      options={courses} 
                      value={selecttedCourses} 
                      onChange={(item)=>setSelectedCourses(item)}
                      />
                      
                    </div>
                  </Col>

                  <Col md="2">
                    <div className="mb-3">
                      <Label>Category</Label>
                      <Select isClearable={true} 
                      options={category} 
                      value={selectedCategory} 
                      onChange={(item)=>setSelectedCategory(item)}
                      />
                    </div>
                  </Col>

                  <Col md="2">
                    <div className="mb-3">
                      <Label>Batch</Label>
                      <Select isClearable={true} 
                      options={batches} 
                      value={selectedBatches} 
                      onChange={(item)=>setSelectedBatches(item)}
                      />
                      
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label>Progression</Label>
                      <Select isClearable={true} 
                      options={progression} 
                      value={selectedProgresion} 
                      onChange={(item)=>setSelectedProgression(item)}
                      />
                      
                    </div>
                  </Col>
                   
                  <Col className="mt-4 pt-1">
                    <div className="mb-3">
                      <Button color="danger" 
                       type="reset"
                       onClick={reset}
                       >
                        Reset
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
              <MDBDataTable
                    id="batchCompletedId"
                    responsive
                    bordered
                    info={true}
                    searching={true}
                    entries={10}
                    disableRetreatAfterSorting={true}
                    data={data}
                    paginationLabel={false}
                    paging={false}
                  />
                 <TablePagination
                    page={page}
                    onChange={handleChange}
                    count={totalPage}
                  />
        </Col>
      </Row>
    </>
  );
};

export default BatchesUnderProgression;





