import { MDBDataTable } from "mdbreact"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import { get } from "../../../../helpers/api_helper"
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap"
import JobModal from "../../../Jobs/JobModal"
import InternshipModal from "../../../Internship/InternshipModal"
import "./style.scss"
const JobApplied = props => {
  useEffect(() => {
    JobApplied()
  }, [props?.id])
  const [selectedenrolled, setselectedEnrolled] = useState([])
  const [modal, setModal] = useState({})
  const [popupView, setPopupView] = useState(false)

  function JobApplied() {
    get(`/student/job-details?studentId=${props?.id}`)
      .then(res => {
      
        const result = res?.data
       
        let data = [] 
 
 
        result?.map((item, index) => {

          // item?.all_applications.map(el => {
          //   console.log(el,"elll");
          //   // item.name = el?.application_details?.application_name
          //   // //  item.applieddate= moment(el?.date).format("DD-MM-YYYY")
          //   // item.uniqueId = el?.application_details?.uniqueId

          //   // item.date =
          //   //   el?.application_details?.status === 2
          //   //     ? moment(el?.application_details?.shortlist?.date).format(
          //   //         "DD-MM-YYYY"
          //   //       )
          //   //     : el?.application_details?.status === 3
          //   //     ? moment(el?.application_details?.placed?.date).format(
          //   //         "DD-MM-YYYY"
          //   //       )
          //   //     : el?.application_details?.status === 4 ||
          //   //       el?.application_details?.status === 5
          //   //     ? moment(el?.application_details?.reject?.date).format(
          //   //         "DD-MM-YYYY"
          //   //       )
          //   //     : item?.status === 0
          //   //     ? moment(el?.application_details?.date).format("DD-MM-YYYY")
          //   //     : ""

          //   // item.status1 =
          //   //   el?.application_details?.status === 0
          //   //     ? "Applied"
          //   //     : el?.application_details?.status === 2
          //   //     ? "Shortlisted"
          //   //     : el.application_details?.status === 3
          //   //     ? "Placed"
          //   //     : el.application_details?.status === 4 || el.application_details?.status === 5
          //   //     ? "Rejected"
          //   //     : ""
          // })
          item.name = item?.application_name
          item.id = index + 1
          item.type=item?.type === 1 ?"Job":"Internship"
     item.date =
              item?.status === 2
                ? moment(item?.shortlist?.date).format(
                    "DD-MM-YYYY"
                  )
                : item?.status === 3
                ? moment(item?.placed?.date).format(
                    "DD-MM-YYYY"
                  )
                : item?.status === 4 ||
                  item?.status === 5
                ? moment(item?.reject?.date).format(
                    "DD-MM-YYYY"
                  )
                : item?.status === 0
                ? moment(item?.date).format("DD-MM-YYYY")
                : ""
   item.status1 =
              item?.status === 0
                ? "Applied"
                : item?.status === 2
                ? "Shortlisted"
                : item?.status === 3
                ? "Placed"
                : item?.status === 4 || item?.status === 5
                ? "Rejected"
                : ""
          // item.date =
          //       item?.status === 2
          //     ? moment(item?.shortlist?.date).format("DD-MM-YYYY")
          //     : item?.status === 3
          //     ? moment(item?.placed?.date).format("DD-MM-YYYY")
          //     : item?.status === 4 || item?.status === 5
          //     ? moment(item?.reject?.date).format("DD-MM-YYYY")
          //     : item?.status === 0
          //     ? moment(item?.date).format("DD-MM-YYYY")
          //     : ""

          item.options = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  setPopupView(true)
                  setModal(item)
                }}
              ></i>
            </div>
          )
          data.push(item)
          return item
        })
   
        setselectedEnrolled(data)
      })

      .catch(error => {
        console.error("Error :", error)
      })
  }
  const jobPosts = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Type",
        field: "type",
        sort: "asc",
        width: 400,
      },
      {
        label: "ID",
        field: "uniqueId",
        sort: "asc",
        width: 400,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Status",
        field: "status1",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "options",
        sort: "asc",
        width: 200,
      },
    ],
    rows: selectedenrolled,
  }
  return (
    <>
      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h5>Applicant Details</h5>
          </div>
          <button
            type="button"
            onClick={() => setPopupView(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
      {modal.type === "Job" ? <JobModal data={modal?._id} /> : <InternshipModal data={modal?._id}/> }  
      </Modal>

      <Row>
        <Col className="col-12">
          <MDBDataTable
            id="shortlistedJobDataId"
            className="job-applied"
            data={jobPosts}
            responsive
            bordered
            searching={true}
            info={false}
            disableRetreatAfterSorting={true}
          />
        </Col>
      </Row>
    </>
  )
}

export default JobApplied
