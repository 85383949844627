import React, { useEffect, useRef, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Modal,
  Table,
  Spinner,
} from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Tooltip from "@mui/material/Tooltip"
import Select from "react-select"

import Admin from "../../components/Common/Admin"
import { getLeadSource } from "../../store/options/actions"
import { getCampaign } from "../../store/options/actions"
import { getCourses, getTempBatch } from "../../store/options/actions"
import toastr from "toastr"
import { useDispatch, useSelector } from "react-redux"
import { post, get } from "../../helpers/api_helper"
import TablePagination from "../../components/Common/Pagination"
import { PRIVILEGES } from "../../config"
import { getUsersOptions } from "../../store/options/actions"

import moment from "moment"
import "./Bulkupload.scss"
import useBranch from "../../hooks/useBranch"
import SelectErrorMessage from "../../components/Common/SelectErrorMessage"
// import { ModalDialog, OverlayTrigger } from "react-bootstrap"
function Bulkupload() {
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/"
  const dispatch = useDispatch()

  const [branch] = useBranch()

  const formRef = useRef()
  const reset = () => {
    formRef.current?.reset()
    setError({})
    setSelectedCourse(null)
    setSelectedEvents(null)
    setSelectedSource(null)
    setSelectedCampaign(null)
    setSelectedCsp(null)
    setSelectedTempbatch(null)
    setSelectedType(null)
    setSelectedFundSource(null)
    // dispatch(getTempBatch());
    dispatch(getTempBatch({ course: "" }))
    if (!masterObject.hasOwnProperty("csp")) {
      setCourses1([])
    }

    setMasterObject({ csp: branch?.value })
  }
  const { allotted } = useSelector(state => state.Profile)
  const { leadSource, Campaign, tempBatch } = useSelector(
    state => state.Options
  )
  const [error, setError] = useState({})

  const [masterObject, setMasterObject] = useState({ csp: branch?.value })
  const [fundSourceOptions, setFundSourceOptions] = useState([])
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [selectedEvents, setSelectedEvents] = useState(null)
  const [selectedFundSource, setSelectedFundSource] = useState(null)
  const [selectedSource, setSelectedSource] = useState(null)
  const [selectedCampaign, setSelectedCampaign] = useState(null)
  const [selectedTempbatch, setSelectedTempbatch] = useState(null)
  const [selectedCsp, setSelectedCsp] = useState(branch)
  const [tabledetails, setTableDetails] = useState([])
  const [singledetails, setSingleDetails] = useState([])
  // const [uploadedFiles, setUploadFiles] = useState({})
  const [totalPage, setTotalPage] = useState(0)
  const [courses1, setCourses1] = useState([])
  const [events1, setevents1] = useState([])
  const [page, setPage] = useState(1)
  const [selectedType, setSelectedType] = useState(null)
  const [profiles, setProfiles] = useState([])
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [modalId, setModalId] = useState(null)
  const limit = 10
  useEffect(() => {
    dispatch(getLeadSource({}))
    // dispatch(getCourses({}))
    dispatch(getCampaign({}))
    // dispatch(getTempBatch({}))
  }, [dispatch])

  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [page])

  useEffect(() => {
    if (masterObject?.csp) {
      getCourses(masterObject?.csp)
      getEvents(masterObject?.csp)
    }
  }, [masterObject?.csp])
  const getCourses = branch => {
    get(`temp-batch/course/${branch}`).then(res => {
      setCourses1(res.data)
    })
  }
  const getEvents = branch => {
    get(`temp-batch/event/${branch}`).then(res => {
      setevents1(res.data)
    })
  }

  useEffect(() => {
    if (masterObject.csp) {
      handleFundSourceOptions()
    }
  }, [masterObject])

  const handleFundSourceOptions = async () => {
    get(`accounts/chart-account/options?branch=${masterObject?.csp}`).then(
      res => {
        setFundSourceOptions(res.data)
      }
    )
  }
  useEffect(() => {
    if (masterObject?.csp !== null) {
      setCourses1([])
      dispatch(
        getUsersOptions({
          csp: masterObject?.csp,
          privilege: PRIVILEGES.INTERN,
        })
      )
    }
  }, [masterObject?.csp, dispatch])
  const customStyles = {
    lineHeight: "1.8",
  }
  const handleChange = (event, value) => {
    setPage(value)
  }
  const [popupView, setPopupView] = useState(false)
  const handleCloseModal = () => setPopupView(false)

  function handleSubmit() {
    const formData = new FormData()
    // console.log(Object.keys(masterObject))
    // formData.append("uploadedFiles", masterObject.uploadedFiles)
    for (const key of Object.keys(masterObject)) {
      formData.append(key, masterObject[key])
    }

    if (
      selectedCsp === null ||
      selectedType === null
      // selectedTempbatch === null ||
      // selectedSource === null
      // || selectedCampaign === null
    ) {
      setError(prevState => ({
        ...prevState,
        csp: selectedCsp === null,
        selectedType: selectedType === null,
        course: selectedCourse === null,
        tempbatch: selectedTempbatch === null,
        source: selectedSource === null,
        // campaign: selectedCampaign === null,
      }))

      return
    }
    if (selectedType != null) {
      if (selectedType.value == 1 && selectedCourse == null) {
        setError(prevState => ({
          ...prevState,
          course: selectedCourse === null,
          tempbatch: selectedTempbatch === null,
          source: selectedSource === null,
          // campaign: selectedCampaign === null,
        }))
        return
      } else if (selectedType.value == 2 && selectedEvents == null) {
        setError(prevState => ({
          ...prevState,
          event: selectedEvents === null,
          source: selectedSource === null,
          // campaign: selectedCampaign === null,
        }))
        return
      }
    }
    if (selectedSource === null) {
      setError(prevState => ({
        ...prevState,
        source: selectedSource === null,
        // campaign: selectedCampaign === null,
      }))
      return
    }
    post(`bulk-upload`, formData)
      .then(res => {
        toastr.success(res.message)

        reset()
        handleTableData()
      })
      .catch(err => {
        toastr.error(err.response?.data?.message)
        console.log(err.response?.data)
      })
  }

  function handleSelectChange(selected, name) {
    switch (name) {
      case "type":
        setSelectedType(selected)
        setMasterObject({ ...masterObject, type: selected.value })
        break

      case "course":
        setSelectedCourse(selected)
        setMasterObject({
          ...masterObject,
          course: selected.value,
          temp_batch: "",
        })
        break
      case "events":
        setSelectedEvents(selected)
        setMasterObject({
          ...masterObject,
          events: selected.value,
          // temp_batch: "",
        })
        break

      case "leadSource":
        setSelectedSource(selected)
        setMasterObject({ ...masterObject, leadSource: selected.value })
        break

      case "campaign":
        setSelectedCampaign(selected)
        setMasterObject({ ...masterObject, campaign: selected.value })
        break
      case "fundSource":
        setSelectedFundSource(selected)
        setMasterObject({ ...masterObject, fundSource: selected.value })
        // handleFundSourceOptions(selected)
        break
      case "csp":
        setSelectedCsp(selected)
        setMasterObject({ ...masterObject, csp: selected.value })
        break
      case "temp_batch":
        setSelectedTempbatch(selected)
        setMasterObject({ ...masterObject, temp_batch: selected.value })
        break

      default:
        break
    }
  }

  function handlepreupdatsingle(item) {
    setPopupView(true)
    setModalId(item._id)

    handleModal(item._id)
  }

  function handleTableData() {
    const filterQuery = `page=${page}&limit=${limit}`

    get(`bulk-upload/all?${filterQuery}`).then(res => {
      setLoading(true)
      const result = res?.data
      const count = res?.count

      result?.map((item, index) => {
        item.date = moment(item?.date).format("DD-MM-YYYY")
        item.id = index + 1 + (page - 1) * limit
        item.addedby = item?.addedBy?.firstName
        item.csp = item?.csp?.name
        item.campaigns = item?.campaign?.name
        item.leadSource = item?.leadSource?.name
        if (item.type === 1) {
          item.type = "Course"
          item.name = item.course?.name
        } else {
          item.type = "Event"
          item.name = item.event?.name
        }
        item.status =
          item?.status === 0 ? (
            <span
              className="badge bg-success font-size-12 ms-2"
              style={{
                width: "100px",
                padding: "0.3rem",
              }}
            >
              Success
            </span>
          ) : item?.status === 1 ? (
            <span
              style={{
                width: "100px",
                padding: "0.3rem",
              }}
              className="badge bg-danger font-size-12 ms-2"
            >
              Failed
            </span>
          ) : item?.status === 2 ? (
            <span
              style={{
                width: "100px",
                padding: "0.3rem",
              }}
              className="badge bg-warning font-size-12 ms-2"
            >
              Pending
            </span>
          ) : (
            ""
          )
        item.tempBatch =
          item?.tempBatch?.branch?.name +
          " - " +
          moment(item?.tempBatch?.expStartingDate).format("MMM YYYY")
        item.options = (
          <div>
            <a href={`${API_URL}` + item?.uploadedFiles} download>
              <i
                className="fa fa-file-excel"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  color: "green",
                  marginRight: "0.6em",
                }}
              ></i>
            </a>
            <i
              className="fas fa-eye"
              onClick={() => {
                handlepreupdatsingle(item)
              }}
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginRight: "0.6em",
              }}
            ></i>
            {/* <i
              className="far fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.1em",
                marginRight: "0.5em",
              }}
              onClick={() => {
                PreUpdate(item._id)
              }}
            ></i> */}
          </div>
        )

        return item
      })
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableDetails(result)
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    })
  }

  function handleModal(id = modalId) {
    setLoading2(true)
    get(`bulk-upload?_id=${id}`)
      .then(res => {
        const result = res?.data[0]
        console.log("single batch", result)
        if (result.type === 1) {
          result.type = "Course"
          result.name = result.course?.name
        } else {
          result.type = "Event"
          result.name = result.event?.name
        }
        setProfiles(
          result.profiles.map((item, index) => ({
            id: index + 1,
            name: `${item?.firstname || ""} ${item?.lastname || ""}`,
            studentId: item?.unique_id,
            date: moment(item.date).format("DD-MM-YYYY"),
            email: item?.email,
            address: item?.address,
            status:
              item?.paymentStatus === 0 ? (
                <span
                  style={{
                    width: "100px",
                    padding: "0.3rem",
                  }}
                  className="badge bg-success font-size-12 ms-2"
                >
                  Success
                </span>
              ) : [1, 2, 3].includes(item?.paymentStatus) ? (
                <span
                  style={{
                    width: "100px",
                    padding: "0.3rem",
                  }}
                  className="badge bg-danger font-size-12 ms-2"
                >
                  Failed
                </span>
              ) : (
                ""
              ),
            amount: item?.amount,
            mobile: item?.mobile,
            remark: item?.message,
            profileStatus:
              item?.profileStatus === 1
                ? "Success"
                : item?.profileStatus === 2
                ? "Failed"
                : "",
          }))
        )
        setSingleDetails(result)
      })
      .catch(err => {
        console.log(err.response?.data?.message)
      })
    setTimeout(() => {
      setLoading2(false)
    }, 1000)
  }

  function handleUpload(e) {
    setMasterObject(prevState => ({
      ...prevState,
      uploadedFiles: e.target.files[0],
    }))
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 350,
      },
      {
        label: "CSP",
        field: "csp",
        width: 150,
      },
      {
        label: "Type",
        field: "type",
        width: 150,
      },
      {
        label: "Course/Event",
        field: "name",
        width: 150,
      },
      {
        label: "Campaign",
        field: "campaigns",
        width: 150,
      },
      {
        label: "Source",
        field: "leadSource",
        width: 150,
      },

      {
        label: "Added By",
        field: "addedby",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        width: 150,
      },
      {
        label: "Actions",
        field: "options",
        sort: "desc",
        width: 400,
      },
    ],

    rows: tabledetails,
  }

  const followup_data = {
    columns: [
      {
        label: "ID",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Student ID",
        field: "studentId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Mobile",
        field: "mobile",
        sort: "asc",
        width: 150,
      },

      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 150,
      },
      {
        label: "Place",
        field: "address",
        sort: "asc",
        width: 150,
      },
      {
        label: "Amount",
        field: "amount",
        width: 150,
      },
      {
        label: "Profile Status",
        field: "profileStatus",
        sort: "asc",
        width: 150,
      },
      {
        label: "Payment Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Remark",
        field: "remark",
        sort: "asc",
        width: 150,
      },

      // {
      //   label: "Qualification",
      //   field: "qualificationname",
      //   sort: "asc",
      //   width: 150,
      // },
      // {
      //   label: "Department",
      //   field: "department",
      //   sort: "asc",
      //   width: 150,
      // },
    ],
    // rows: uploadedFiles || [],
    rows: profiles,
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Bulk Upload" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm ref={formRef} onValidSubmit={handleSubmit}>
                    <Row>
                      <Admin>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>CSP</Label>
                            <Select
                              name="csp"
                              options={allotted.branches}
                              type="text"
                              onChange={selected => {
                                handleSelectChange(selected, "csp")
                                setSelectedCourse(null)
                                setSelectedEvents(null)
                                setMasterObject(pre => ({
                                  ...pre,
                                  course: "",
                                  temp_batch: "",
                                }))
                                setSelectedTempbatch(null)
                              }}
                              value={selectedCsp}
                            />
                            <SelectErrorMessage
                              show={selectedCsp === null && error.csp}
                              customStyle={{
                                width: "100%",
                                fontSize: "87.5%",
                                color: "var(--bs-form-invalid-color)",
                              }}
                              message="Please select a CSP"
                            />
                          </div>
                        </Col>
                      </Admin>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Type</Label>
                          <Select
                            name="course"
                            value={selectedType}
                            onChange={selected => {
                              // console.log(selected,'selected')
                              handleSelectChange(selected, "type")
                            }}
                            options={[
                              { label: "Course", value: 1 },
                              { label: "Event", value: 2 },
                            ]}
                          />
                          <SelectErrorMessage
                            show={selectedType === null && error.selectedType}
                            customStyle={{
                              width: "100%",
                              fontSize: "87.5%",
                              color: "var(--bs-form-invalid-color)",
                            }}
                            message="Please select a Course"
                          />
                        </div>
                      </Col>
                      {selectedType?.value == 1 ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Course</Label>
                            <Select
                              name="course"
                              value={selectedCourse || ""}
                              onChange={selected => {
                                handleSelectChange(selected, "course")
                                dispatch(
                                  getTempBatch({ course: selected?.value })
                                )
                                setSelectedTempbatch(null)
                              }}
                              options={courses1}
                            />
                            <SelectErrorMessage
                              show={
                                selectedType?.value == 1 &&
                                selectedCourse === null &&
                                error.course
                              }
                              customStyle={{
                                width: "100%",
                                fontSize: "87.5%",
                                color: "var(--bs-form-invalid-color)",
                              }}
                              message="Please select a Course"
                            />
                          </div>
                        </Col>
                      ) : selectedType?.value == 2 ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Events</Label>
                            <Select
                              name="course"
                              value={selectedEvents || ""}
                              onChange={selected => {
                                handleSelectChange(selected, "events")
                                // dispatch(
                                //   getTempBatch({ course: selected?.value })
                                // )
                                // setSelectedTempbatch(null)
                              }}
                              options={events1}
                            />
                            <SelectErrorMessage
                              show={
                                selectedType?.value == 2 &&
                                selectedEvents === null &&
                                error.event
                              }
                              customStyle={{
                                width: "100%",
                                fontSize: "87.5%",
                                color: "var(--bs-form-invalid-color)",
                              }}
                              message="Please select a Event"
                            />
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}

                      {selectedCourse != null ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Temporary Batch</Label>
                            <Select
                              name="temp_batch"
                              classNamePrefix="select2-selection"
                              onChange={selected => {
                                handleSelectChange(selected, "temp_batch")
                              }}
                              // isDisabled={!masterObject?.course}
                              value={selectedTempbatch}
                              options={tempBatch.filter(
                                el => el?.branch?._id === masterObject?.csp
                              )}
                            />
                            <SelectErrorMessage
                              show={
                                selectedTempbatch === null && error.tempbatch
                              }
                              customStyle={{
                                width: "100%",
                                fontSize: "87.5%",
                                color: "var(--bs-form-invalid-color)",
                              }}
                              message="Please select a Temporary Batch"
                            />
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Fund source</Label>
                          <Select
                            name="fundSource"
                            // value={fundSourceOptions.filter(
                            //   item => item.value === masterObject.fundSource
                            // )}
                            value={selectedFundSource}
                            options={fundSourceOptions}
                            // onChange={selected => {
                            //   handleValueChange(selected.value, "fundSource")
                            //   setFundSourceError(false)
                            // }}
                            onChange={selected => {
                              handleSelectChange(selected, "fundSource")
                            }}
                          ></Select>
                          {/* <SelectErrorMessage
                      customStyle={{
                        width: "100%",
                        fontSize: "87.5%",
                        color: "var(--bs-form-invalid-color)",
                      }}
                      message="Select Fund Source"
                      show={fundSourceError}
                    /> */}
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Lead Source</Label>
                          <Select
                            name="leadSource"
                            classNamePrefix="select2-selection"
                            options={leadSource}
                            onChange={selected => {
                              handleSelectChange(selected, "leadSource")
                            }}
                            value={selectedSource}
                          />
                          <SelectErrorMessage
                            show={selectedSource === null && error.source}
                            customStyle={{
                              width: "100%",
                              fontSize: "87.5%",
                              color: "var(--bs-form-invalid-color)",
                            }}
                            message="Please select a Lead Source"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Campaign</Label>
                          <Select
                            name="campaign"
                            classNamePrefix="select2-selection"
                            onChange={selected => {
                              handleSelectChange(selected, "campaign")
                            }}
                            value={selectedCampaign}
                            options={Campaign}
                          />
                          {/* <SelectErrorMessage
                            show={selectedCampaign === null && error.campaign}
                            customStyle={{
                              width: "100%",
                              fontSize: "87.5%",
                              color: "var(--bs-form-invalid-color)",
                            }}
                            message="Please select a Campaign"
                          /> */}
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3 no-margin">
                          <Label>Upload Files</Label>
                          <div className="d-flex  flex-column">
                            <AvField
                              name="uploadedFiles "
                              classNamePrefix="select2-selection"
                              type="file"
                              onChange={handleUpload}
                              className="form-control"
                              style={customStyles}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage:
                                    "Please select a file to import",
                                },
                                pattern: {
                                  value: /^.*\.(xls|xlsx)$/i,
                                  errorMessage:
                                    "Please select a valid Excel file (.xls, .xlsx)",
                                },
                              }}
                              errorMessage="Please select a file to import"
                            />
                            {/* <div className="align-self-end">
                              <a
                                href={"/samples/bulk-upload-sample.xlsx"}
                                download
                                className="d-flex align-items-center"
                              >
                                Download sample file
                                <i className="mdi mdi-download fs-5" />
                              </a>
                            </div> */}
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div
                          className="mb-3 d-flex"
                          style={{ paddingTop: "25px" }}
                        >
                          <Button
                            color="primary"
                            className="me-2"
                            type="submit"
                          >
                            Submit
                          </Button>
                          <Button
                            color="danger"
                            className="me-2"
                            onClick={reset}
                          >
                            Reset
                          </Button>
                          <div
                            style={{
                              fontSize: "13px",
                              marginLeft: "7rem",
                            }}
                          >
                            <Tooltip title="Download sample file">
                              <a
                                href={"/samples/bulk_upload_sample_v02.xlsx"}
                                download
                                style={{ fontSize: "1rem" }}
                                className="d-flex align-items-center btn btn-success "
                              >
                                <i
                                  style={{ fontSize: "13px" }}
                                  className="fa fa-solid fa-download "
                                />
                                <span
                                  style={{
                                    marginLeft: "5px",
                                    fontSize: "13px",
                                  }}
                                >
                                  Download Sample Sheet
                                </span>
                              </a>
                            </Tooltip>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginBottom: "10px",
                    }}
                  >
                    <Button
                      color="success"
                      onClick={() => handleTableData()}
                      style={{ height: "100%", width: "130px" }}
                      disabled={loading}
                      outline
                    >
                      {loading ? (
                        <div>
                          Refreshing{" "}
                          <Spinner
                            style={{
                              height: "15px",
                              width: "15px",
                              marginLeft: "5px",
                            }}
                          />
                        </div>
                      ) : (
                        <>
                          Refresh<i class="fas fa-redo ms-2"></i>
                        </>
                      )}
                    </Button>
                  </div>
                  <MDBDataTable
                    id="bulkuploadId"
                    bordered
                    responsive
                    searching={false}
                    entries={20}
                    paging={false}
                    info={false}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>
                  <TablePagination
                    page={page}
                    onChange={handleChange}
                    count={totalPage}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="xl"
        centered={true}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h5 style={{ marginBottom: "0px" }}>Details</h5>
          </div>
          {/* <h3 className="modal-title mt-0">Lead Details - {leadDetails?.lead_unique_id}</h3> */}
          <button
            type="button"
            onClick={handleCloseModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        {/* {selectedItem && ( */}
        <div className="modal-body">
          <Row>
            <Col md="12">
              <Table className="table tbl-u-boarderd" id="followupmodalId">
                <tbody>
                  <tr>
                    <th>Date</th>
                    <th>:</th>
                    <td>{moment(singledetails?.date).format("DD-MM-YYYY")}</td>

                    <th>CSP</th>
                    <th>:</th>
                    <td>{singledetails?.csp?.name}</td>

                    <th>Source</th>
                    <th>:</th>
                    <td style={{ textAlign: "left" }}>
                      {singledetails?.leadSource?.name}
                    </td>
                  </tr>

                  <tr>
                    <th>Type</th>
                    <th>:</th>
                    <td>{singledetails?.type}</td>
                    <th>Course/Event</th>
                    <th>:</th>
                    <td>{singledetails?.name}</td>

                    <th>Campaign</th>
                    <th>:</th>
                    <td style={{ textAlign: "Left" }}>
                      {singledetails?.campaign?.name}
                    </td>
                  </tr>
                  <tr>
                    {singledetails?.tempBatch?.branch?.name ? (
                      <>
                        <th>Temporary Batch</th>
                        <th>:</th>
                        <td style={{ textAlign: "Left" }}>
                          {singledetails?.tempBatch?.branch?.name +
                            " - " +
                            moment(
                              singledetails?.tempBatch?.expStartingDate
                            ).format("MMM YYYY")}
                        </td>
                      </>
                    ) : (
                      ""
                    )}
                    <th>Added By</th>
                    <th>:</th>
                    <td style={{ textAlign: "Left" }}>
                      {singledetails?.addedBy?.firstName}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>

            <Col md="12">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <h5 class="font-size-16 mb-3">Imported Data</h5>
                <Button
                  color="success"
                  onClick={() => handleModal()}
                  style={{ height: "100%", width: "130px" }}
                  disabled={loading}
                  outline
                >
                  {loading2 ? (
                    <div>
                      Refreshing{" "}
                      <Spinner
                        style={{
                          height: "15px",
                          width: "15px",
                          marginLeft: "5px",
                        }}
                      />
                    </div>
                  ) : (
                    <>
                      Refresh<i class="fas fa-redo ms-2"></i>
                    </>
                  )}
                </Button>
              </div>

              <MDBDataTable
                id="followup_Id"
                responsive
                bordered
                data={followup_data}
                searching={true}
                info={false}
                sortable={false}
                disableRetreatAfterSorting={true}
                entries={10}
              />
            </Col>
          </Row>
        </div>

        {/* )} */}
      </Modal>
    </>
  )
}

export default Bulkupload
