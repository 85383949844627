import React, { useEffect, useState } from "react"
import { Row, Col } from "reactstrap"

import { MDBDataTable } from "mdbreact"
import { useLocation } from "react-router-dom"

import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { get } from "../../../../helpers/api_helper"
import moment from "moment"

function Members() {
  const { state } = useLocation()
  const [membersList, setMembersList] = useState([])
  useEffect(() => {
    handleTableList()
  }, [])
  function handleTableList() {
    get(`/request-community/approvedlist/?communityId=${state?.id}`).then(
      res => {
        let data = []
        let result = res?.data
        result?.map((item, index) => {
          item.date = moment(item?.date).format("DD-MM-YYYY")
          item.id = index + 1
          item.name =
            (item?.requestedStudent?.firstName ?? "") +
            " " +
            (item?.requestedStudent?.lastName ?? "")
          item.requestedStudentid = item?.requestedStudent?.unique_id
          item.added = item?.approvedBy?.name
          data.push(item)
        })
        setMembersList(data)
      }
    )
  }

  const shortlisted = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "ID",
        field: "requestedStudentid",
        width: 150,
      },

      {
        label: "Member",
        field: "name",
        width: 150,
      },
      // {
      //   label: "Event",
      //   field: "cousddrse",
      //   width: 150,
      // },
      
      // {
      //   label: "Joined date",
      //   field: "date",
      //   width: 150,
      // },
      {
        label: "Approved by",
        field: "added",
        width: 150,
      },
    ],
    rows: membersList,
  }
  return (
    <div>
      {" "}
      <Row>
        <Col className="col-12">
          <MDBDataTable
            id="eventcommunitid"
            data={shortlisted}
            responsive
            bordered
            searching={true}
            info={false}
            disableRetreatAfterSorting={true}
          />
        </Col>
      </Row>
    </div>
  )
}

export default Members
