import React, { useRef, useState, useEffect } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label, Badge } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import "./style.scss"
import moment from "moment"
import toastr from "toastr"
import { get, post, put, del } from "../../../helpers/api_helper"
import Swal from "sweetalert2"

function ManageCluster() {
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }
  const [selectedSector, setSelectedSector] = useState(null)
  const [masterObj, setmasterObj] = useState({ sector: [] })
  const formRef = useRef()
  const [sectorOptions, setsectorOptions] = useState([])
  const [tableDetails, settableDetails] = useState([])
  const [clusterIdToBeUpdate, setclusterIdToBeUpdate] = useState(null)

  useEffect(() => {
    ftechallSectorlist()
    getallCluster()
    // eslint-disable-next-line
  }, [])

  console.log("mster", masterObj)
  function handleSelectChange(selected, name) {
    switch (name) {
      case "sector":
        setSelectedSector(selected)
        setmasterObj({
          ...masterObj,
          sector: selected ? selected.map(item => item.value) : [],
        })
        break

      default:
        break
    }
  }

  const data = {
    columns: [
      {
        label: "# ",
        field: "id",
        width: 150,
        sort:"asc"
      },
      {
        label: "Date",
        field: "date1",
        width: 150,
        sort:"asc"

        
      },
      {
        label: "Cluster",
        field: "name",
        width: 150,
        sort:"asc"

      },
      {
        label: "Sector",
        field: "sector1",
        width: 150,
        sort:"asc"

      },
      {
        label: "Added By",
        field: "staff",
        width: 150,
        sort:"asc"

      },
      {
        label: "Action",
        field: "options",
        sort: "desc",
        width: 400,
        sort:"asc"

      },
    ],
    rows: tableDetails,
  }

  function handleSubmit(e, v) {
    if (clusterIdToBeUpdate) {
      put(`/cluster`, masterObj)
        .then(res => {
          toastr.success(res.message)
          formRef.current.reset()
          setmasterObj({ sector: [] })
          setSelectedSector(null)
          getallCluster()
          setclusterIdToBeUpdate(null)
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    } else {
      post(`/cluster`, masterObj)
        .then(res => {
          if (res.success === true) {
            toastr.success(res.message)
            getallCluster()
            formRef.current.reset()
            setmasterObj({ sector: [] })
            setSelectedSector(null)
          } else {
            toastr.error("Faild to add clusetr")
          }
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    }
  }

  const handleChange = e => {
    let { name, value } = e.target
    setmasterObj(pre => ({ ...pre, [name]: value }))
  }

  const handleUpdate = item => {
    let sectorop = item.sector.map(item => ({
      label: item.name,
      value: item._id,
    }))
    setSelectedSector(sectorop)
    setmasterObj({ _id: item._id, name: item.name, sector: item.sector })
  }

  console.log("sector", selectedSector)

  // Sector options

  function ftechallSectorlist() {
    get(`/options/sector`).then(res => {
      console.log("res", res)
      let result = res?.data?.map(item => {
        return {
          label: item?.name,
          value: item?._id,
        }
      })
      setsectorOptions(result)
    })
  }

  // all cluster

  function getallCluster() {
    get(`/cluster`)
      .then(res => {
        console.log("tab;ledata", res)
        let result = res.data
        result &&
          result.map((item, index) => {
            item.id = index + 1
            item.date1 = moment(item.date).format("DD-MM-YYYY")
            item.staff = item?.addedBy?.firstName + item?.addedBy?.lastName
            item.sector1 = (
              <>
                {item.sector.map(item => (
                  <Badge
                    style={{
                      padding: ".3rem",
                      fontSize: "11px",
                      backgroundColor: "rgb(230, 230, 230)",
                      margin: "2px",
                    }}
                  >
                    {item.name}
                  </Badge>
                ))}
              </>
            )
            item.options = (
              <div>
                <i
                  className="far fa-edit"
                  style={{
                    cursor: "pointer",
                    fontSize: "1em",
                    marginRight: "0.5rem",
                  }}
                  onClick={() => {
                    toTop()
                    handleUpdate(item)
                    setclusterIdToBeUpdate(item?._id)
                  }}
                ></i>

                <i
                  className="far fa-trash-alt"
                  style={{ cursor: "pointer", fontSize: "1em" }}
                  onClick={() => {
                    handleDelete(item._id)
                  }}
                ></i>
              </div>
            )
            return item
          })
        settableDetails(result)
      })
      .catch(err => {
        console.log(err.response)
        toastr.error(err.response.data.status)
      })
  }

  //delete
  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`/cluster/${id}`)
          .then(res => {
            toastr.success(res.message)
            getallCluster()
          })
          .catch(err => {
            toastr.success(err.response.data.message)
            getallCluster()
          })
      }
    })
  }

  console.log("resuly", sectorOptions)
  console.log("tabledetails", tableDetails)

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Cluster" />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    onValidSubmit={(e, v) => {
                      handleSubmit(e)
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Cluster</Label>
                          <AvField
                            name="name"
                            className="form-control"
                            type="text"
                            value={masterObj?.name || ""}
                            onChange={handleChange}
                            placeholder="Cluster"
                            errorMessage="Enter Cluster"
                            validate={{ required: { value: !masterObj.id } }}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Sector</Label>
                          <Select
                            name="sector"
                            isMulti
                            classNamePrefix="select2-selection"
                            value={selectedSector}
                            options={sectorOptions}
                            onChange={selected => {
                              handleSelectChange(selected, "sector")
                            }}
                          />
                        </div>
                      </Col>

                      <Col>
                        <div className="mb-3" style={{ paddingTop: "25px" }}>
                          {masterObj.id ? (
                            <Button
                              onClick={handleUpdate}
                              color="warning"
                              type="button"
                            >
                              Update
                            </Button>
                          ) : (
                            <Button
                              color={
                                clusterIdToBeUpdate ? "warning" : "primary"
                              }
                              type="submit"
                            >
                              {clusterIdToBeUpdate ? "Update" : "Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="clusterTableId"
                    bordered
                    responsive
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                    sortable={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default ManageCluster
