import React, { useState, useEffect } from "react"
import { Modal, Label, Button } from "reactstrap"
import { get } from "../../../helpers/api_helper"
import { dateConverter, numberToCurrency, timeConverter } from "../../../helpers/functions"
import moment from "moment"
import { API_URL } from "../../../config"
import { MDBDataTable } from "mdbreact"

import "./styles.scss"

const BulkFeeDetails = ({ id, isOpen, onClick }) => {
  useEffect(() => {
    if (id) handleData()
    // eslint-disable-next-line
  }, [id])

  const [details, setDetails] = useState({
    history: [],
  })
  const handleData = async () => {
    try {
      const response = await get(`fee/bulk/details?id=${id}`)

      const data = response.data
      console.log(data)
      if (data) {
        data.date = dateConverter(data.date)
        data.time = moment(data.time, "HH:mm:ss").format("hh:mm a")
        data.type = data.type === 0 ? "Cash" : data.type === 1 ? "Online" : ""
        data.for = data.for === 0 ? "Course" : data.for === 1 ? "Event" : ""
        data.staff = `${data.staffFirstName || ""} ${data.staffLastName || ""}`

        if (!data.data || !Array.isArray(data.data)) data.history = []
        else {
          data.data.map((item, index) => {
            item.id = index + 1
            item.name = `${item.firstName || ""} ${item.lastName || ""}`
            item.amount = numberToCurrency(item.amount)
            // item.date = dateConverter(item.date)
            item.time = timeConverter(item.time)
            if (item.status === 0) {
              item.currentStatus = (
                <div className="d-flex justify-content-center">
                  <Button color="success" size="sm">
                    Success
                  </Button>
                </div>
              )
            } else {
              if (item.errorStatus === 1)
                item.comments = "Payable amount exceeded"
              else if (item.errorStatus === 2)
                item.comments = "Internal Server Error"
              else if (item.errorStatus === 3)
                item.comments = "Registration not found"
              item.currentStatus = (
                <div className="d-flex justify-content-center">
                  <Button color="danger" size="sm">
                    Failed
                  </Button>
                </div>
              )
            }

            return item
          })

          data.history = data.data
        }
      }

      setDetails(data)
    } catch (error) {}
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Time", field: "time" },
      { label: "Register No", field: "studentId" },
      { label: "Name", field: "name" },
      { label: "Amount", field: "amount" },
      { label: "Transaction Id", field: "paymentId" },
      { label: "Status", field: "currentStatus" },
      { label: "Comments", field: "comments" },
    ],
    rows: details.history,
  }
  return (
    <React.Fragment>
      <Modal
        size="xl"
        centered
        isOpen={isOpen}
        toggle={() => {
          onClick()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Bulk Fee Details</h5>
          <button
            type="button"
            onClick={() => onClick()}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <ul className="lead-details">
            <li>
              Date : <Label>{details?.date}</Label>
            </li>

            <li>
              Time : <Label>{details?.time}</Label>
            </li>
            <li>
              Branch : <Label>{details?.branch}</Label>
            </li>
            <li>
              Payment Type : <Label>{details?.type}</Label>
            </li>
            <li>
              Payment For : <Label>{details?.for}</Label>
            </li>
            <li>
              Course/Event : <Label>{details?.name}</Label>
            </li>
            <li>
              Added By: <Label>{details?.staff}</Label>
            </li>
            <li>
              Total Count: <Label>{details?.totalCount}</Label>
            </li>

            <li>
              Total Success: <Label>{details?.totalSuccess}</Label>
            </li>
            <li>
              File:{" "}
              <Label>
                <a
                  href={`${API_URL}` + details?.uploadedFile}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    className="fa fa-file-excel"
                    style={{
                      fontSize: "1em",
                      cursor: "pointer",
                      color: "green",
                      marginRight: "0.6em",
                    }}
                  ></i>
                </a>
              </Label>
            </li>
            {details.type === "Cash" ? (
              <li>
                Fund Source: <Label>{details?.fundSource}</Label>
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>
        <MDBDataTable
          id="bulkFeeDetailsTable"
          className="mt-3"
          bordered
          responsive
          paging={false}
          searching={false}
          sortable={false}
          info={false}
          disableRetreatAfterSorting={true}
          data={data}
        />
      </Modal>
    </React.Fragment>
  )
}

export default BulkFeeDetails
