import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { AvForm } from "availity-reactstrap-validation"

import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { getPrivileges } from "../../../../store/options/actions"

import { Table, TableCell, TableBody, TableHead, TableRow } from "@mui/material"

// import toastr from "toastr"
import { get, post } from "../../../../helpers/api_helper"
import { APP_NAME } from "../../../../config"

const Rules = props => {
  document.title = `Rules | ${APP_NAME}`

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPrivileges())
  }, [dispatch])

  const { privileges } = useSelector(state => state.Options)
  const [selectedPrivilege, setSelectedPrivilege] = useState(null)

  const [dataOptions, setDataOptions] = useState([])

  const handleSelectChange = selected => {
    setSelectedPrivilege(selected)
    getDetails(selected.value)
  }

  const getDetails = privilege => [
    get(`rules/app/list/${privilege || ""}`).then(res => {
      setDataOptions(res.data)
    }),
  ]

  const handleMainMenuClick = (e, menu) => {
    const status = e.target.checked ? 0 : 1

    post(`rules/app/mainmenu/${selectedPrivilege.value}`, {
      status,
      menu,
    })
      .then(res => {
        getDetails(selectedPrivilege.value)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleSubMenuClick = (e, mainmenu, submenu) => {
    const status = e.target.checked ? 0 : 1

    post(`/rules/app/submenu/${selectedPrivilege.value}`, {
      mainmenu,
      submenu,
      status,
    }).then(res => {
      getDetails(selectedPrivilege.value)
      console.log(res)
    })

    console.log(status, selectedPrivilege.value, mainmenu, submenu)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Rules" />

          <Row>
            <Col xl="12">
              <Card className="p-2">
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Privilege</Label>
                          <Select
                            name="rules"
                            value={selectedPrivilege}
                            onChange={selected => {
                              handleSelectChange(selected)
                            }}
                            classNamePrefix="select2-selection"
                            options={privileges}
                          />
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <Card>
                <CardBody style={{ marginTop: "35px", marginBottom: "35px" }}>
                  <Table
                    style={{ textAlign: "center" }}
                    className="table table-bordered dataTable"
                  >
                    <TableHead>
                      <TableRow style={{ textAlign: "center" }}>
                        <TableCell
                          style={{ width: "180px", textAlign: "center" }}
                        >
                          Menu
                        </TableCell>
                        <TableCell
                          style={{ width: "210px", textAlign: "center" }}
                        >
                          Sub Menu
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataOptions &&
                        dataOptions.map((item, index) => (
                          <TableRow
                            style={{
                              verticalAlign: "top",
                              textAlign: "center",
                            }}
                            key={index}
                          >
                            <TableCell
                              component="td"
                              scope="row"
                              style={{ textAlign: "left", width: "220px" }}
                            >
                              <div>
                                <ul style={{ listStyleType: "none" }}>
                                  <li className="mb-2">
                                    <input
                                      type="checkbox"
                                      className="mb-2"
                                      checked={item.allocation_status === 1}
                                      onClick={e =>
                                        handleMainMenuClick(e, item._id)
                                      }
                                      style={{ cursor: "pointer" }}
                                    />
                                    <span
                                      style={{
                                        marginLeft: "5px",
                                      }}
                                    >
                                      {item.english_name}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </TableCell>
                            <TableCell style={{ textAlign: "left" }}>
                              <div>
                                <ul style={{ listStyleType: "none" }}>
                                  {item.sub_menu &&
                                    item.sub_menu.map((element, index) => {
                                      return (
                                        <li key={index} className="mb-2">
                                          <input
                                            type="checkbox"
                                            checked={
                                              element.allocation_status === 1
                                            }
                                            onClick={e =>
                                              handleSubMenuClick(
                                                e,
                                                item._id,
                                                element._id
                                              )
                                            }
                                            className="mb-2"
                                            style={{ cursor: "pointer" }}
                                          />
                                          <span
                                            style={{
                                              marginLeft: "5px",
                                            }}
                                          >
                                            {element.english_name}
                                          </span>
                                        </li>
                                      )
                                    })}
                                </ul>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Rules
