import React, { useEffect, useMemo, useState } from "react"
import { Row, Col, Card, CardBody, Progress, Button } from "reactstrap"
import toastr from "toastr"

// import ReactApexChart from "react-apexcharts"

import "./Batchview.scss"
import Pie from "./PieChat"
import { get, put } from "../../../../helpers/api_helper"
import moment from "moment"
import { API_URL } from "../../../../config"
import useSearchValue from "../../../../hooks/useSearchValue"
import { useLocation, useNavigate } from "react-router-dom"
import { jsonToExcel } from "../../../../helpers/functions"

function Batchview(props) {
  const navigate = useNavigate()
  const [keyword, setKeyword] = useState(null)
  const [batch, setBatch] = useState([])

  const deferredKeyword = useSearchValue(keyword, 700)

  const location = useLocation()

  useEffect(() => {
    getBatch(deferredKeyword, location?.state?.type)
  }, [deferredKeyword, location.state])

  const getBatch = (keyword, type = "") => {
    get(`batch/detail/list?keyword=${keyword}&type=${type}`).then(res => {
      const data = res.data

      data.map(item => {
        item.trainer = item.trainer
          .map(el => `${el.firstName || ""} ${el.lastName || ""}`)
          .join(", ")
      })
      setBatch(data)
    })
  }

  const handleToggle = id => {
    put(`batch/status/${id}`)
      .then(res => {
        getBatch(keyword)
        toastr.success(res.message)
      })
      .catch(err => {
        toastr.error(err?.response?.data?.message)
      })
  }

  function navigateToDetails(item) {
    navigate(`/batch-creations/${item.batchId}`, { state: { id: item._id } })
  }

  const handleExport = () => {
    if (batch.length > 0) {
      const exportData = batch.map((item, idx) => {
        let dataExport = {}

        dataExport["#"] = idx + 1

        dataExport["Batch ID"] = item.batchId
        dataExport["Batch Name"] = item.name
        dataExport["Course"] = item.course
        dataExport["Trainer"] = item?.trainer
        dataExport["Start Date"] = moment(item.startDate).format("DD-MM-YYYY")
        dataExport["End Date"] = moment(item.endDate).format("DD-MM-YYYY")
        dataExport["Status"] = item?.status === 0 ? "Active" : "Inactive"

        dataExport["Completed Sessions"] = item?.sessionsCompleted
        dataExport["%"] =(item?.sessions === 0
          ? 0
          : (
              (item?.sessionsCompleted / item?.sessions) *
              100
            ).toFixed(0)) || 0
     
        dataExport["Pending Sessions"] =
          item?.sessions - item?.sessionsCompleted

        dataExport["Total Sessions"] = item?.sessions

        return dataExport
      })
      jsonToExcel(exportData, `Batch-${moment().format("DD-MM-YY")}`)
    } else {
      toastr.info(`There are no batch details to export`)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row className="mb-2">
            <Col xs={12}>
              <div className="form-inline float-md-end mb-3 d-flex">
                <div className="search-box ml-2">
                  <div className="position-relative">
                    <input
                      type="text"
                      className="form-control rounded border-0"
                      placeholder="Search..."
                      onChange={e => setKeyword(e.target.value)}
                    />
                    <i className="mdi mdi-magnify search-icon"></i>
                  </div>
                </div>
                <Button
                    onClick={handleExport}
                    className="btn btn-sm ms-4"
                    color="primary"
                  >
                    Export
                  </Button>
              </div>

              
            </Col>

            {batch.map((item, index) => (
              <Col md="6" xl="6" key={item?.name}>
                <Card>
                  <CardBody style={{ cursor: "pointer" }}>
                    <div className="mb-3 d-flex justify-content-between">
                      <div
                        style={{
                          textAlign: "end",
                          position: "absolute",
                          top: "5px",
                          right: "7px",
                        }}
                        className="pt-1 "
                      >
                        <span
                          onClick={() => handleToggle(item._id)}
                          className={`badge ${
                            item?.status === 0 ? "bg-success" : "bg-warning"
                          } font-size-12 ms-2`}
                        >
                          {item?.status === 0 ? "Active" : "Inactive"}
                        </span>
                      </div>
                    </div>

                    <div
                      className="click"
                      onClick={() => navigateToDetails(item)}
                    >
                      <div className="d-flex justify-content-between">
                        <div>
                          <h5 class="font-size-16 mb-1">
                            <span class="text-dark"> {item?.name}</span>
                          </h5>
                          <div className="d-flex justify-content-between">
                            <div style={{ paddingTop: "12px" }}>
                              <p style={{ fontSize: "14px" }} className="mb-1">
                                Batch Id: {item?.batchId}
                              </p>
                              <p style={{ fontSize: "14px" }} className="mb-1">
                                Course: {item?.course}
                              </p>
                              <div>
                                <p
                                  style={{ fontSize: "14px" }}
                                  className="mb-0"
                                >
                                  <i
                                    style={{ color: "orange" }}
                                    className="far fa-clock"
                                  ></i>{" "}
                                  {moment(item?.endDate).diff(
                                    item?.startDate,
                                    "day"
                                  ) + 1}{" "}
                                  Days (
                                  {moment(item?.startDate).format("DD-MM-YYYY")}{" "}
                                  to{" "}
                                  {moment(item?.endDate).format("DD-MM-YYYY")})
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ width: "100px" }}>
                          <div id="pie-chart" className=" mt-3">
                            <Pie
                              style={{ width: "140px", height: "125px" }}
                              completed={item?.sessions}
                              pending={item?.sessionsCompleted}
                            />
                            <div style={{ textAlign: "center" }}>Sessions</div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <Progress
                          style={{ height: "14px" }}
                          color="primary"
                          value={
                            (item?.sessions === 0
                              ? 0
                              : (
                                  (item?.sessionsCompleted / item?.sessions) *
                                  100
                                ).toFixed(0)) || 0
                          }
                        >
                          {(item?.sessions === 0
                            ? 0
                            : (
                                (item?.sessionsCompleted / item?.sessions) *
                                100
                              ).toFixed(0)) || 0}
                          % Completed
                        </Progress>
                      </div>
                      <div
                        style={{
                          padding: "20px 0px 0px 0px ",

                          borderRadius: "0 0 0.3rem 0.3rem",
                        }}
                        className="d-flex justify-content-between"
                      >
                        <div>
                          <img
                            style={{ width: "95px" }}
                            src={API_URL + item?.trainingPartner?.logo}
                            alt=""
                          />
                        </div>

                        <div className="pt-1">
                          <div>
                            <span
                              style={{ fontSize: "13px", fontWeight: "400" }}
                            >
                              Trainer:
                            </span>
                            <span
                              style={{
                                marginLeft: "3px",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                            >
                              {item?.trainer}
                            </span>
                          </div>
                          <div
                            style={{ fontWeight: "400", textAlign: "end" }}
                            className=""
                          >
                            {item?.students} Students
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Batchview
