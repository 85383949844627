import React, { useEffect, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import { del, get } from "../../helpers/api_helper"
import moment from "moment"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import "./style.scss"
import toastr from "toastr"
import Swal from "sweetalert2"
import queryString from "query-string"
import { jsonToExcel } from "../../helpers/functions"
import { useNavigate } from "react-router-dom"

import { Tooltip as MToolTip } from "@mui/material"
import Select from "react-select"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { getCampaign, getDistrictOptions } from "../../store/options/actions"
import Admin from "../../components/Common/Admin"
import { isArray } from "lodash"
import DataTable from "../../components/Common/DataTable"

const FROM_OPTIONS = [
  {
    label: "Popup",
    value: 1,
  },
  {
    label: "Contact Us",
    value: 0,
  },
]

const Contacts = () => {
  const [tableData, setTableData] = useState([])
  const [state, setState] = useState(false)

  const [selected, setSelected] = useState({})

  const OPTIONS = useSelector(state => state.Options)

  const { allotted } = useSelector(state => state.Profile)

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const [search, setSearch] = useState({})

  const [exports, setExports] = useState([])

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const [count, setCount] = useState(0)

  useEffect(() => {
    fetchData(search, page, limit)
  }, [state, search, page, limit])

  useEffect(() => {
    dispatch(getCampaign())
    dispatch(getDistrictOptions({ id: 19 }))
  }, [dispatch])

  const fetchData = async (query, page, limit) => {
    try {
      const res = await get(
        `/contacts?${queryString.stringify(query)}&page=${page}&limit=${limit}`
      )
      const data = res?.data

      setCount(res?.count || 0)

      data.map((item, index) => {
        const data = { ...item }
        item.id = (page - 1) * limit + index + 1
        item.date = moment(item?.date).format("DD-MM-YYYY")
        item.time = moment(item?.time, "hh:mm a").format("hh:mm a")

        item.district_name = item?.district?.name
        item.campaign_name = item?.campaign?.name

        item.messages = (
          <>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  id="button-tooltip-2ss"
                  style={{ position: "absolute", top: "-30px" }}
                >
                  {item?.message}
                </Tooltip>
              }
            >
              <div>
                {item.message?.slice(0, 70)}
                {item?.message?.length > 70 ? "..." : ""}{" "}
              </div>
            </OverlayTrigger>
          </>
        )

        item.from = (
          <span
            className={`badge rounded-pill font-size-12 ${
              item.from === 1 ? "bg-soft-success" : "bg-soft-info"
            }`}
          >
            {item.from === 1 ? "Popup" : "Contact Us"}
          </span>
        )

        item.Description = " smot docs "
        item.options = (
          <div>
            <i
              className="uil-trash-alt me-2"
              style={{ fontSize: "1.2em", cursor: "pointer" }}
              onClick={() => deleteData(item._id)}
            ></i>

            <MToolTip title="Lead" placement="top" arrow>
              <i
                className="fas fa-share"
                onClick={() => {
                  navigate("/new-lead", {
                    state: { data: data },
                  })
                }}
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginRight: "0.5rem",
                }}
              ></i>
            </MToolTip>
          </div>
        )

        delete item.message
        return item
      })

      setTableData(res?.data)
    } catch (error) {}
  }

  const handleSelect = (e, name) => {
    const value = e ? (isArray(e) ? e.map(item => item.value) : e.value) : null
    setSelected(pre => ({ ...pre, [name]: e }))
    setSearch(pre => ({ ...pre, [name]: value }))
    setPage(1)
  }

  function deleteData(id) {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`/contacts?id=${id}`)
          .then(res => {
            toastr.success(res.message)
            setState(!state)
          })
          .catch(err => {
            toastr.success(err.response.data.message)
          })
      }
    })
  }

  const handleSearchChange = e => {
    let { name, value } = e.target
    setSearch(pre => ({ ...pre, [name]: value }))
    setPage(1)
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        width: 150,
      },
      {
        label: "Mobile No.",
        field: "phone",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        width: 150,
      },
      {
        label: "District",
        field: "district_name",
        width: 150,
      },
      {
        label: "CSP",
        field: "branch",
        width: 150,
      },
      {
        label: "Campaign",
        field: "campaign_name",
        width: 150,
      },
      {
        label: "Message",
        field: "messages",
        width: 150,
      },
      {
        label: "From",
        field: "from",
        width: 150,
      },
      {
        label: "Actions",
        field: "options",
        sort: "desc",
        width: 400,
      },
    ],
    rows: tableData,
  }

  const handleExport = async () => {
    const res = await get(
      `/contacts?${queryString.stringify(search)}&page=1&limit=${count}`
    )
    const data = res?.data
    const exportData = []
    data.map((item, index) => {
      item.date = moment(item?.date).format("DD-MM-YYYY")
      item.time = moment(item?.time, "hh:mm a").format("hh:mm a")
      const dataExport = {}
      dataExport["#"] = index + 1
      dataExport.Date = item.date
      dataExport.Time = item.time
      dataExport.Name = item.name
      dataExport.Mobile = item.phone
      dataExport.Email = item.email
      dataExport.District = item?.district?.name
      dataExport.CSP = item?.branch
      dataExport.Campaign = item?.campaign?.name
      dataExport.Message = item?.message
      dataExport.From = item.from === 1 ? "Popup" : "Contact Us"
      dataExport.Message = item?.message
      exportData.push(dataExport)
      delete item.message
      return item
    })
    if (exportData.length > 0) {
      jsonToExcel(exportData, `contacts-${moment().format("DD-MM-YY")}`)
    } else {
      toastr.info(`There are no contacts to export`)
    }
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Contact Form" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>From</Label>
                          <AvField
                            name="from_date"
                            value={search.from_date || ""}
                            onChange={handleSearchChange}
                            max={moment().format("YYYY-MM-DD")}
                            className="form-control"
                            type="date"
                            placeholder="From"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>To</Label>
                          <AvField
                            name="to_date"
                            value={search.to_date || ""}
                            max={moment().format("YYYY-MM-DD")}
                            min={search.from_date}
                            onChange={handleSearchChange}
                            className="form-control"
                            type="date"
                            placeholder="To"
                          />
                        </div>
                      </Col>
                      <Admin>
                        <Col md="2">
                          <div className="mb-3">
                            <Label>CSP</Label>
                            <Select
                              isClearable
                              value={selected.csp || null}
                              onChange={e => {
                                handleSelect(e, "csp")
                              }}
                              options={allotted.branches}
                            />
                          </div>
                        </Col>
                      </Admin>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>District</Label>
                          <Select
                            isClearable
                            value={selected.district || null}
                            onChange={e => {
                              handleSelect(e, "district")
                            }}
                            options={OPTIONS.distrcts}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Campaign</Label>
                          <Select
                            options={OPTIONS.Campaign}
                            isClearable
                            menuPosition="fixed"
                            onChange={e => {
                              handleSelect(e, "campaign")
                            }}
                            value={selected.campaign || null}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>From</Label>
                          <Select
                            options={FROM_OPTIONS}
                            isClearable
                            menuPosition="fixed"
                            onChange={e => {
                              handleSelect(e, "from")
                            }}
                            value={selected.from || null}
                          />
                        </div>
                      </Col>
                      <Col className="mt-1 pt-4">
                        <div className="mb-3">
                          <Button
                            color="danger"
                            type="button"
                            onClick={() => {
                              setSearch({})
                              setSelected({})
                            }}
                          >
                            Reset
                          </Button>
                          <Button
                            style={{ marginLeft: "14px" }}
                            color="warning"
                            type="button"
                            onClick={handleExport}
                          >
                            Export
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <DataTable
                    data={data}
                    count={count}
                    page={page}
                    limit={limit}
                    onPageChange={e => setPage(e)}
                    onLimitChange={e => setLimit(e)}
                    id="contacttableid1sss"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default Contacts
