import React, { useState, useEffect } from "react"
import { Modal, Row, Col, Label, Button, Input } from "reactstrap"
import Select from "react-select"
import { get, put } from "../../../../helpers/api_helper"
import moment from "moment"
import { minutesToHour } from "../../../../helpers/functions"
import toastr from "toastr"
const ReportDetails = ({ id, isOpen, onClick, reset, details = true }) => {
  const [selectedStatus, setSelectedStatus] = useState({})
  const [comments, setComments] = useState("")
  const [requestData, setRequestData] = useState({
    name: "",
    mobile: "0000000000",
    email: "",
    username: "",
    salary: 0,
    salaryType: "",
    attendance: [],
    status: "",
  })

  const [approveStatusOptions, setApproveStatusOptions] = useState([
    { label: "Approve", value: 0 },
    { label: "Reject", value: 3 },
  ])
  useEffect(() => {
    if (id && details) handleData()
    if (id && !details) handleAttendance()
    // eslint-disable-next-line
  }, [id])
  const handleData = () => {
    get(`attendance/report-details?id=${id}`).then(res => {
      const data = res.data

      const name =
        (data.firstName ? data.firstName : "") +
        " " +
        (data.lastName ? data.lastName : "")
      const staff =
        (data.userFirstName ? data.userFirstName : "") +
        " " +
        (data.userLastName ? data.userLastName : "")

      const date = moment(data.date).format("DD-MM-YYYY")
      const time = moment(data.time, "HH:mm:ss").format("hh:mm a")

      const salaryType = data.salaryType === 1 ? "Hourly" : "Monthly"
      const salary = data.salary ? data.salary : 0
      const status =
        data.status === 0
          ? "Approved"
          : data.status === 3
          ? "Rejected"
          : "Pending"
      const newOptions = [
        { label: "Approve", value: 0 },
        { label: "Reject", value: 3 },
      ]

      setSelectedStatus({})
      setApproveStatusOptions(
        newOptions.filter(option => option.value !== data.status)
      )
      // data.status = status

      setRequestData({
        name: name,
        salary: salary,
        salaryType: salaryType,
        mobile: data.mobile,
        email: data.email,
        branch: data.branch,
        batch: data.batch,
        course: data.course,
        username: data.username,
        attendance: data.attendance,
        status: data.status,
        statusText: status,
        comments: data.comments,
        staff,
        date,
        time,
      })
    })
  }

  const handleAttendance = () => {
    get(`attendance/report-details?id=${id}`).then(res => {
      setRequestData({
        attendance: res.data.attendance,
      })
    })
  }

  const statusChange = () => {
    const status = selectedStatus.value
    put("attendance/report-status", { status, id, comments })
      .then(res => {
        setSelectedStatus({})
        reset()
        setApproveStatusOptions([
          { label: "Approve", value: 0 },
          { label: "Reject", value: 3 },
        ])
        toastr.success(res.message)

        setComments("")
        handleAttendance()

        if (id && details) handleData()
        if (id && !details) handleAttendance()
      })
      .catch(err => {
        toastr.error(err.response.data.message)
      })
  }
  return (
    <React.Fragment>
      <Modal
        size="xl"
        centered
        isOpen={isOpen}
        toggle={() => {
          onClick()
          setSelectedStatus({})
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Report details</h5>
          <button
            type="button"
            onClick={() => onClick()}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <Row>
            <Col md={12} hidden={!details}>
              <ul className="lead-details">
                <li>
                  Name : <Label>{requestData?.name}</Label>
                </li>

                {requestData?.mobile ? (
                  <li>
                    Mobile : <Label>{requestData?.mobile}</Label>
                  </li>
                ) : (
                  ""
                )}
                {requestData?.email ? (
                  <li>
                    Email : <Label>{requestData?.email}</Label>
                  </li>
                ) : (
                  ""
                )}
                <li>
                  Username : <Label>{requestData?.username}</Label>
                </li>
                {requestData?.branch ? (
                  <li>
                    Branch : <Label>{requestData?.branch}</Label>
                  </li>
                ) : (
                  ""
                )}
                {requestData?.batch ? (
                  <li>
                    Batch : <Label>{requestData?.batch}</Label>
                  </li>
                ) : (
                  ""
                )}
                {requestData?.course ? (
                  <li>
                    Course : <Label>{requestData?.course}</Label>
                  </li>
                ) : (
                  ""
                )}

                <li>
                  Salary :{" "}
                  <Label>
                    {requestData?.salary} {requestData?.salaryType}
                  </Label>
                </li>
                <li>
                  Status : <Label>{requestData?.statusText}</Label>
                </li>
                {requestData.status !== 2 ? (
                  <>
                    <li>
                      {requestData?.statusText} Date :{" "}
                      <Label>{requestData?.date}</Label>
                    </li>
                    <li>
                      {requestData?.statusText} Time :{" "}
                      <Label>{requestData?.time}</Label>
                    </li>
                    {requestData?.comments ? (
                      <li>
                        Comments : <Label>{requestData?.comments}</Label>
                      </li>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </ul>
            </Col>
            <Col md={12}>
              {/* <table
                className="table table-bordered mb-0 table-hover table"
                id="reportDetailsTable"
              >
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Batch</th>
                    <th>Session</th>
                    <th>Course</th>
                    <th>Time</th>
                    <th>Staff</th>
                  </tr>
                </thead>
                <tbody>
                  {requestData.attendance.map(item => {
                    const date = moment(item.date).format("DD-MM-YYYY")
                    const staff =
                      (item.addedFirstName ? item.addedFirstName : "") +
                      " " +
                      (item.addedLastName ? item.addedLastName : "")
                    const time = minutesToHour(item.time)
                    return (
                      <tr>
                        <td>{date}</td>
                        <td>{item.batch}</td>
                        <td>{item.session}</td>
                        <td>{item.course}</td>
                        <td>{time}</td>
                        <td>{staff}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table> */}
              <table
                className="table table-bordered mb-0 table-hover table"
                id="reportDetailsTable"
              >
                <thead>
                  <tr>
                    <th>Session</th>
                    <th>Date</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Hours</th>
                    <th>Status</th>
                    <th>Staff</th>
                  </tr>
                </thead>
                <tbody>
                  {requestData?.attendance?.map(item => {
                    const date = moment(item.date).format("DD-MM-YYYY")
                    const from = moment(item.fromTime, "HH:mm:ss").format(
                      "hh:mm a"
                    )
                    const to = moment(item.toTime, "HH:mm:ss").format("hh:mm a")
                    const time = minutesToHour(item.time)
                    let status
                    if (item.status === 0)
                      status = (
                        <Button size="sm" color="success">
                          Active
                        </Button>
                      )
                    else if (item.status === 1)
                      status = (
                        <Button size="sm" color="danger">
                          Removed
                        </Button>
                      )
                    const staff =
                      (item.addedByFirstName ? item.addedByFirstName : "") +
                      " " +
                      (item.addedByLastName ? item.addedByLastName : "")
                    return (
                      <tr>
                        <td>{item.session}</td>
                        <td>{date}</td>
                        <td>{from}</td>
                        <td>{to}</td>
                        <td>{time}</td>
                        <td>{status}</td>
                        <td>{staff}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </Col>

            <Col
              md={5}
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                marginTop: "0.5rem",
              }}
            >
              {" "}
              <b>Status</b>
            </Col>
            <Col
              md={3}
              style={{
                marginTop: "1rem",
              }}
            >
              <Select
                name="to"
                placeholder="Select..."
                options={approveStatusOptions}
                value={selectedStatus}
                onChange={selected => setSelectedStatus(selected)}
              />
            </Col>
            <Col
              md={3}
              style={{
                marginTop: "1rem",
              }}
            >
              <Input
                name="comments"
                placeholder="Comments"
                type="text"
                value={comments}
                onChange={e => setComments(e.target.value)}
              />
            </Col>

            <Col md={1}>
              <Button
                color="primary"
                style={{ float: "right", marginTop: "1rem" }}
                onClick={statusChange}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default ReportDetails
