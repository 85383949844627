import React, { useEffect, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import "./Lead.scss"
import { useSelector } from "react-redux"
import {
  getCampaign,
  getCourses,
  getEvents,
  getUsersOptions,
} from "../../../../store/options/actions"
import useBranch from "../../../../hooks/useBranch"
import { useDispatch } from "react-redux"
import { PRIVILEGES } from "../../../../config"
import { isArray } from "lodash"
import moment from "moment"
import { getLeadFor, getLeadStatus } from "../Add-Lead/backendHelper"
import queryString from "query-string"
import { get, del } from "../../../../helpers/api_helper"

import Tooltip from "@mui/material/Tooltip"

import Swal from "sweetalert2"

import toastr from "toastr"
import SkillLoanProcessing from "../../../Finance/SkillLoan/Processing"
import ScholarshipProcessing from "../../../Finance/Scholarship/Processing"
import Followup from "../Add-Lead/Followup"
import { jsonToExcel } from "../../../../helpers/functions"
import MuiCustomTablePagination from "../../../../components/Common/TablePagination"

import CircularProgress from "@mui/material/CircularProgress"
import useSearchValue from "../../../../hooks/useSearchValue"
import { useLocation } from "react-router-dom"

import AsyncSelect from "react-select/async"
import Admin from "../../../../components/Common/Admin"

function Leads() {
  const [count, setCount] = useState(0)
  const [page, setPage] = React.useState(0)

  const [studentOptions, setStudentOptions] = useState([])

  const location = useLocation()

  const [rowsPerPage, setRowsPerPage] = React.useState(20)
  const [isLoading, setIsLoading] = useState(false)

  const { allotted } = useSelector(state => state.Profile)

  const [keyWord, setKeyWord] = useState(null)

  const deferredKeyword = useSearchValue(keyWord, 500)

  const searchInitialState = {
    course: location.state?.course?._id ?? null,
    event: location.state?.event?._id ?? null,
    from: location.state?.type || "",
    from_date: location?.state?.from_date
      ? location.state.from_date
      : moment().startOf("month").format("YYYY-MM-DD"),
    to_date: location?.state?.to_date
      ? location?.state?.to_date
      : moment().format("YYYY-MM-DD"),
  }

  const searchInitialSelect = {
    course: location?.state?.course ?? null,
    event: location?.state?.event ?? null,
  }

  const [search, setSearch] = useState(searchInitialState)

  const [searchSelect, setSearchSelect] = useState(searchInitialSelect)

  const [exports, setExports] = useState([])

  const [rows, setRows] = useState([])

  const [scholarship, setScholarship] = useState({ show: false })

  const [skilloan, setSkilloan] = useState({ show: false })

  const [followup, setFollowup] = useState({ modal: false, studentId: null })

  const OPTIONS = useSelector(state => state.Options)

  const [branch] = useBranch()
  const dispatch = useDispatch()

  const [leadStatus, setLeadStatus] = useState([])

  // const navigate = useNavigate()

  const [leadFor, setLeadFor] = useState([])

  useEffect(() => {
    getLeadFor().then(res => {
      setLeadFor(res.data)
    })

    getLeadStatus("").then(res => {
      setLeadStatus(res.data)
    })
  }, [])

  useEffect(() => {
    get(`leads/options/students`).then(res => {
      setStudentOptions(res.data)
    })
  }, [])

  useEffect(() => {
    dispatch(getCourses())
    dispatch(getEvents())
    dispatch(getCampaign())
  }, [dispatch])

  useEffect(() => {
    dispatch(
      getUsersOptions({
        branch: branch?.value || "",
        privilege: PRIVILEGES.INTERN,
      })
    )
  }, [dispatch, branch])

  useEffect(() => {
    fetchLeads(search, page, rowsPerPage)
    // eslint-disable-next-line
  }, [search, page, rowsPerPage])

  useEffect(() => {
    if (deferredKeyword !== null) {
      setSearch(pre => ({ ...pre, keyWord: deferredKeyword }))
    }
  }, [deferredKeyword])

  const fetchLeads = (query = {}, page, limit) => {
    setIsLoading(true)
    get(`leads?${queryString.stringify(query)}&page=${page}&limit=${limit}`)
      .then(res => {
        const data = res.data

        const exportData = []

        data.map((item, idx) => {
          item.id = page * limit + idx + 1

          item.name = `${item?.reg_id?.firstname || ""} ${
            item?.reg_id?.lastname || ""
          }`
          item.name1 = `${item?.reg_id?.firstname || ""} ${
            item?.reg_id?.lastname || ""
          }`

          // item.name1 = (
          //   <span
          //     className="text-primary cursor-pointer"
          //     onClick={() =>
          //       navigate(`/student-details/${item.reg_id.unique_id}`, {
          //         state: { id: item.reg_id._id },
          //       })
          //     }
          //   >
          //     {`${item?.reg_id?.firstname || ""} ${
          //       item?.reg_id?.lastname || ""
          //     }`}
          //   </span>
          // )

          item.date = item.date && moment(item.date).format("DD-MM-YYYY")

          item.uniqueId = item?.reg_id?.unique_id

          item.assign = `${item?.assigned?.firstName || ""} ${
            item?.assigned?.lastName || ""
          }`

          item.mobile = item?.reg_id?.mobile
          item.email = item?.reg_id?.email
          item.course = item?.course?.name || item?.event?.name
          item.source = item?.source?.name
          item.remarks = item?.message

          item.paid =
            item?.courseFee?.paidAmount ||
            item?.eventRegistrationId?.paidAmount ||
            0

          item.due =
            item?.courseFee?.payableAmount - item?.courseFee?.paidAmount ||
            item?.eventRegistrationId?.due ||
            0

          item.temp_batch = (
            <span>
              {item?.tmpBatch?.branch?.name}
              <br />
              {item?.tmpBatch?.expStartingDate &&
                moment(item?.tmpBatch?.expStartingDate).format("MMMM YYYY")}
            </span>
          )

          delete item.message

          item.followupStatus = (
            <div className="text-center">
              <span
                style={{
                  background: item?.latest_followup_status?.color,
                }}
                className="badge font-size-12 ms-2"
              >
                {item?.latest_followup_status?.name}
              </span>
            </div>
          )

          item.action = (
            <div>
              <Tooltip title="Followup" arrow placement="top">
                <i
                  className="fas fa-eye me-2"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    // getLeadSingle(item._id)
                    setFollowup({
                      modal: true,
                      studentId: item?.reg_id?._id,
                      leadId: item._id,
                    })
                  }}
                ></i>
              </Tooltip>

              {item.skillLoan.requested && item.skillLoan?.requestedId && (
                <Tooltip title="Skill loan" arrow placement="top">
                  <i
                    onClick={() =>
                      setSkilloan({
                        show: true,
                        id: item?.skillLoan?.requestedId,
                      })
                    }
                    className="fa fa-university me-2"
                    style={{
                      fontSize: "1em",
                      cursor: "pointer",
                    }}
                  ></i>
                </Tooltip>
              )}

              {item.scholarship.requested && item.scholarship?.requestedId && (
                <Tooltip title="Scholarship" arrow placement="top">
                  <i
                    onClick={() =>
                      setScholarship({
                        show: true,
                        id: item?.scholarship?.requestedId,
                      })
                    }
                    className="fa fa-graduation-cap me-2"
                    style={{
                      fontSize: "1em",
                      cursor: "pointer",
                    }}
                  ></i>
                </Tooltip>
              )}

              <i
                onClick={() => handleDelete(item._id)}
                className="far fa-trash-alt"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                }}
              ></i>
            </div>
          )

          // TODO: Export details
          const dataExport = {}
          dataExport["#"] = idx + 1
          dataExport.Date = item.date
          dataExport.Type = item.type
          dataExport["Student Id"] = item.uniqueId
          dataExport.Name = item.name
          dataExport.Mobile = item.mobile
          dataExport["Course/Event"] = item.course
          dataExport.Source = item.source
          dataExport.Assigned = item.assign
          dataExport.Paid = item.paid
          dataExport.Due = item.due
          dataExport.Status = item?.latest_followup_status?.name

          exportData.push(dataExport)

          // item.checkbox = (
          //   <div className="text-center">
          //     <input type="checkbox" name="" id="" />
          //   </div>
          // )

          return item
        })

        setCount(res.totalCount)
        setRows(data)
        setExports(exportData)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const fetchLeadsExport = (query = {}, page, limit = count) => {
    get(
      `leads?${queryString.stringify(query)}&page=${page}&limit=${limit}`
    ).then(res => {
      const data = res.data

      const exportData = []

      data.map((item, idx) => {
        item.id = idx + 1

        item.name = `${item?.reg_id?.firstname || ""} ${
          item?.reg_id?.lastname || ""
        }`

        item.date = item.date && moment(item.date).format("DD-MM-YYYY")

        item.uniqueId = item?.reg_id?.unique_id

        item.assign = `${item?.assigned?.firstName || ""} ${
          item?.assigned?.lastName || ""
        }`

        item.mobile = item?.reg_id?.mobile
        item.course = item?.course?.name || item?.event?.name
        item.source = item?.source?.name
        item.remarks = item?.message

        item.paid =
          item?.courseFee?.paidAmount ||
          item?.eventRegistrationId?.paidAmount ||
          0

        item.due =
          item?.courseFee?.payableAmount - item?.courseFee?.paidAmount ||
          item?.eventRegistrationId?.due ||
          0

        item.temp_batch = (
          <span>
            {item?.tmpBatch?.branch?.name}
            <br />
            {item?.tmpBatch?.expStartingDate &&
              moment(item?.tmpBatch?.expStartingDate).format("MMMM YYYY")}
          </span>
        )

        delete item.message
        const dataExport = {}
        dataExport["#"] = idx + 1
        dataExport.Date = item.date
        dataExport.Type = item.type
        dataExport["Student Id"] = item.uniqueId
        dataExport.Name = item.name
        dataExport.Mobile = item.mobile
        dataExport.CSP = item?.branch?.name
        dataExport["Course/Event"] = item.course
        dataExport.Source = item.source
        dataExport.Assigned = item.assign
        dataExport.Paid = item.paid
        dataExport.Due = item.due
        dataExport.Status = item?.latest_followup_status?.name

        exportData.push(dataExport)
        return item
      })
      if (exportData.length > 0) {
        jsonToExcel(exportData, `leads-${moment().format("DD-MM-YY")}`)
      } else {
        toastr.info(`There are leads to export`)
      }
      // setExports(exportData)
    })
  }

  const handleSearchChange = e => {
    let { name, value } = e.target
    setSearch(pre => ({ ...pre, [name]: value }))
    setPage(0)
  }

  const handleSearchSelect = (e, name) => {
    const value = e ? (isArray(e) ? e.map(item => item.value) : e.value) : null
    setSearchSelect(pre => ({ ...pre, [name]: e }))
    setSearch(pre => ({ ...pre, [name]: value }))
    setPage(0)
  }

  const handleDelete = id => {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`/leads/${id}`)
          .then(res => {
            refresh()
            toastr.success(res.message)
          })
          .catch(err => {
            toastr.success(err.response.data.message)
          })
      }
    })
  }

  const handleExport = () => {
    if (exports.length > 0) {
      // jsonToExcel(exports, `leads-${moment().format("DD-MM-YY")}`)
      fetchLeadsExport(search, page)
    } else {
      toastr.info(`There are no leads to export`)
    }
  }

  const refresh = () => {
    fetchLeads(search, page, rowsPerPage)
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      // {
      //   label: "",
      //   field: "checkbox",
      //   width: 150,
      // },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Type",
        field: "type",
        width: 150,
      },
      {
        label: "ID",
        field: "uniqueId",
        width: 150,
      },
      {
        label: "Name",
        field: "name1",
        width: 150,
      },
      {
        label: "Mobile",
        field: "mobile",
        width: 150,
      },
      {
        label: "Course/Event",
        field: "course",
        width: 150,
      },
      {
        label: "Source",
        field: "source",
        width: 150,
      },
      {
        label: "Assigned",
        field: "assign",
        width: 150,
      },
      {
        label: "Tmp Batch",
        field: "temp_batch",
        width: 150,
      },
      {
        label: "Paid",
        field: "paid",
        width: 150,
      },
      {
        label: "Due",
        field: "due",
        width: 150,
      },
      {
        label: "Status",
        field: "followupStatus",
        width: 150,
      },
      {
        label: "Actions",
        field: "action",
        sort: "desc",
        width: 400,
      },
    ],
    rows,
  }

  const handleChangePage = newPage => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const loadOptions = (inputValue, callback) => {
    if (inputValue.length > 2) {
      get(`leads/options/students?keyword=${inputValue}`).then(res => {
        callback(res.data)
      })
    }
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Leads" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {isLoading && (
                    <div className="d-flex justify-content-end me-4">
                      <CircularProgress size={30} />
                    </div>
                  )}
                  <AvForm>
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label> From</Label>
                          <AvField
                            name="from_date"
                            value={search.from_date || ""}
                            onChange={handleSearchChange}
                            max={moment().format("YYYY-MM-DD")}
                            className="form-control"
                            type="date"
                            placeholder="From"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>To</Label>
                          <AvField
                            name="to_date"
                            value={search.to_date || ""}
                            max={moment().format("YYYY-MM-DD")}
                            min={search.from_date}
                            onChange={handleSearchChange}
                            className="form-control"
                            type="date"
                            placeholder="To"
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Course</Label>
                          <Select
                            onChange={e => {
                              handleSearchSelect(e, "course")
                            }}
                            isClearable
                            value={searchSelect.course || null}
                            options={OPTIONS.courses}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Event</Label>
                          <Select
                            onChange={e => {
                              handleSearchSelect(e, "event")
                            }}
                            isClearable
                            value={searchSelect.event || null}
                            options={OPTIONS.events}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Source</Label>
                          <Select
                            onChange={e => {
                              handleSearchSelect(e, "source")
                            }}
                            isClearable
                            value={searchSelect.source || null}
                            options={leadFor}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Staff</Label>
                          <Select
                            options={OPTIONS.users}
                            isClearable
                            onChange={e => {
                              handleSearchSelect(e, "user")
                            }}
                            value={searchSelect.user || null}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Status</Label>
                          <Select
                            options={leadStatus}
                            isClearable
                            menuPosition="fixed"
                            onChange={e => {
                              handleSearchSelect(e, "status")
                            }}
                            value={searchSelect.status || null}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Campaign</Label>
                          <Select
                            options={OPTIONS.Campaign}
                            isClearable
                            menuPosition="fixed"
                            onChange={e => {
                              handleSearchSelect(e, "campaign")
                            }}
                            value={searchSelect.campaign || null}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Student</Label>
                          <AsyncSelect
                            isMulti
                            isClearable
                            cacheOptions
                            loadOptions={loadOptions}
                            defaultOptions={studentOptions}
                            onChange={e => {
                              handleSearchSelect(e, "reg_id")
                            }}
                            value={searchSelect.reg_id || null}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Type</Label>
                          <Select
                            options={[
                              {
                                value: "1",
                                label: "Course",
                              },
                              {
                                value: "2",
                                label: "Event",
                              },
                            ]}
                            isClearable
                            menuPosition="fixed"
                            onChange={e => {
                              handleSearchSelect(e, "type")
                            }}
                            value={searchSelect.type || null}
                          />
                        </div>
                      </Col>

                      <Admin>
                        <Col md="2">
                          <div className="mb-3">
                            <Label>CSP</Label>
                            <Select
                              isClearable
                              name="csp"
                              options={allotted.branches}
                              type="text"
                              onChange={e => {
                                handleSearchSelect(e, "branch")
                              }}
                              value={searchSelect.branch || null}
                            />
                          </div>
                        </Col>
                      </Admin>

                      <Col className="mt-4 pt-1">
                        <div className="mb-3">
                          <Button
                            color="danger"
                            type="button"
                            onClick={() => {
                              setSearch(searchInitialState)
                              setSearchSelect({})
                            }}
                          >
                            Reset
                          </Button>
                          <Button
                            style={{ marginLeft: "14px" }}
                            color="warning"
                            type="button"
                            onClick={handleExport}
                          >
                            Export
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                  <MDBDataTable
                    id="leadTable"
                    bordered
                    responsive
                    displayEntries={false}
                    paging={false}
                    searching={false}
                    // onSearch={value => {
                    // setKeyWord(value)
                    // setSearch(pre => ({ ...pre, keyWord: value }))
                    // }}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>

                  <MuiCustomTablePagination
                    rowsPerPageOptions={[10, 20, 50, 100, 200]}
                    count={count}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      {followup.modal && (
        <Modal
          show={followup.modal}
          isOpen={followup.modal}
          toggle={() => {
            refresh()
            setFollowup({ modal: false })
          }}
          size="xl"
          centered={true}
          id="leadModal"
          className="lead-modal"
        >
          <div className="modal-header">
            <div className="modal-title">
              <h6 style={{ marginBottom: "0px" }} className="modal-title mt-0">
                {/* #{leadData.unique_id} */}
              </h6>
            </div>
            <button
              style={{ marginBottom: "2px" }}
              type="button"
              onClick={() => {
                refresh()
                setFollowup({ modal: false })
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>

            <Followup followup={followup} />
          </div>
        </Modal>
      )}

      {scholarship.show && (
        <Modal
          show={scholarship.show}
          isOpen={scholarship.show}
          size="xl"
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Scholarship Details</h5>
            <button
              type="button"
              onClick={() => {
                setScholarship({ show: false })
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <ScholarshipProcessing id={scholarship?.id} />
          </div>
        </Modal>
      )}

      {skilloan.show && (
        <Modal show={skilloan.show} isOpen={skilloan.show} size="xl" centered>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Skill Loan Details</h5>
            <button
              type="button"
              onClick={() => {
                setSkilloan({ show: false })
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <SkillLoanProcessing id={skilloan?.id} />
          </div>
        </Modal>
      )}
    </>
  )
}

export default Leads
