/* eslint-disable */
import React, { useState, useEffect } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { AvField, AvForm } from "availity-reactstrap-validation"

import {
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  Nav,
  Modal,
  Label,
  Button,
} from "reactstrap"
import { MDBDataTable } from "mdbreact"
import "./style.scss"
import { useNavigate } from "react-router-dom"
import YouTubeIcon from "@mui/icons-material/YouTube"
import classnames from "classnames"
import Basic from "./Tabs/Basic"
import FeeDetails from "./Tabs/FeeDetails"
import CourseDetails from "./Tabs/CourseDetails"
import SEODetails from "./Tabs/SEODetails"
import { get, del, put } from "../../../helpers/api_helper"
import Swal from "sweetalert2"
import toastr from "toastr"
import { createContext } from "react"
import Tooltip from "@mui/material/Tooltip"
import Select from "react-select"
import { useSelector, useDispatch } from "react-redux"
import { CSVLink } from "react-csv"
import MuiCustomTablePagination from "../../../components/Common/TablePagination"

import { getBranches } from "../../../store/options/actions"
import { jsonToExcel } from "../../../helpers/functions"
import moment from "moment"
const CourseContext = createContext()

function ManageCourse() {
  const { branches } = useSelector(state => state.Options)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getBranches())
  }, [])

  const [filter, setfilter] = useState([])
  const [courseID, setCourseID] = useState({})
  const [count, setCount] = useState(0)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(20)
  const [bascicDetails, setbascicDetails] = useState({})
  const [courseDetails, setcourseDetails] = useState({})
  const [seoDetails, setseoDetails] = useState({})
  const [feeDetails, setfeeDetails] = useState({})
  const [selectfiltercsp, setselectfiltercsp] = useState(null)
  const [dataToBeExported, setDataToBeExported] = useState([])
  const [search, setSearch] = useState('')
  const navigate = useNavigate()
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }
  const [activeTabJustify, setactiveTabJustify] = useState("1")
  const [tableDetails, settableDetails] = useState([])
  const [modal, setModal] = useState(false)
  const [url, seturl] = useState()

  useEffect(() => {
    toTop()
  }, [activeTabJustify])



  useEffect(() => {
    if (courseID?.objID) {
      if (activeTabJustify === "1") {
        get(`/course?_id=${courseID?.objID}`).then(res => {
          let result = res.data[0]
          setbascicDetails(result)
          setCourseID({ objID: result?._id })
        })
      } else if (activeTabJustify === "2") {
        get(`/course/fee_details?_id=${courseID?.objID}`).then(res => {
          let result = res.data
          setfeeDetails(result)
          setCourseID({ objID: result?._id })
        })
      } else if (activeTabJustify === "3") {
        get(`/course/details?_id=${courseID?.objID}`).then(res => {
          let result = res.data
          if (result.details.length === 0) {
            setcourseDetails({ details: [{}] })
          } else {
            setcourseDetails(result)
          }
          setCourseID({ objID: result?._id })
        })
      } else if (activeTabJustify === "4") {
        get(`/course/seo?_id=${courseID?.objID}`).then(res => {
          let result = res.data
          setseoDetails(result)
          setCourseID({ objID: result?._id })
        })
      }
    }
  }, [activeTabJustify])


  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Course",
        field: "name",
        width: 150,
      },
      {
        label: "Sector",
        field: "sector1",
        width: 150,
      },
      {
        label: "Duration",
        field: "duration1",
        width: 150,
      },
      {
        label: "Mode",
        field: "mode1",
        width: 150,
      },
      {
        label: "TP",
        field: "tranining_partner",
        width: 150,
      },
      {
        label: "Verify",
        field: "verify",
        width: 150
      },
      {
        label: "Action",
        field: "options",
        sort: "desc",
        width: 400,
      },
    ],
    rows: tableDetails,
  }

  function toggleCustomJustified(tab) {
    if (activeTabJustify !== tab) {
      setactiveTabJustify(tab)
    }
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function togModal(urL) {
    seturl(urL)
    setModal(!modal)
    removeBodyCss()
  }

  useEffect(() => {
    fetchallCourseList()
    // eslint-disable-next-line
  }, [activeTabJustify, courseID, filter, page, rowsPerPage, search])

  function fetchallCourseList() {
    get(`/course?branch=${filter?.csp}&page=${page}&limit=${rowsPerPage}&search=${search}`).then(
      res => {
        let result = res.data
        setCount(res.count)
        let dataExport = []
        result.map((item, index) => {
          let exportitem = {}
          item.id = (page * rowsPerPage) +  index + 1
          exportitem.ID = index + 1
          exportitem.Course = item.name
          item.sector1 = item?.sector?.name
          exportitem.Sector = item.sector1
          if (item.mode === "0") {
            item.mode1 = "Online"
            exportitem.Mode = "Online"
          }
          if (item.mode === "1") {
            item.mode1 = "Offline"
            exportitem.Mode = "Offline"
          }
          item.sector1 = item.sector.label
          item.staff = item?.addedBy?.firstName + item?.addedBy?.lastName

          item.tranining_partner = item.trainingPartner?.map((item, index, array) => (
            <>
              {item.label}
              {index < array.length - 1 && <>, <br /></>}
            </>
          ));
          if (item.unit === "1") { 
            item.duration1 = item.duration + " " + "Hours"
            exportitem.Duration = item.duration + " " + "Hours"
          } else if (item.unit === "2") {
            item.duration1 = item.duration + " " + "Days"
            exportitem.Duration = item.duration + " " + "Days"
           } else if (item.unit === "3") {
            item.duration1 = item.duration + " " + "Months"
            exportitem.Duration = item.duration + " " + "Months"
          }
          if (item?.assets?.video) {
            item.video = (
              <Tooltip title="View video">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <YouTubeIcon
                    onClick={() => {
                      togModal(item?.assets?.video)
                    }}
                    style={{ cursor: "pointer", color: "#00afc3" }}
                  />
                </div>
              </Tooltip>
            )
          }
          item.options = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  navigate(`/manage-course/${item._id}`, {
                    state: { id: item._id },
                  })
                }}
              ></i>
              <i
                className="fas fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop()
                  handleUpdate(item)
                  // courseIdtoBeUpdated(item?._id)
                }}
              ></i>
              <i
                onClick={() => {
                  handleDelete(item._id)
                }}
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
              ></i>
            </div>
          )

          if (item.webShow == 0) {
            item.verify = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <input
                  type="checkbox"
                  checked={item.webShow == 0}
                  onClick={() => { handleVerify(item._id, 0) }}
                />
              </div>
            );
          }

          if (item.webShow == 1) {
            item.verify = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <input
                  type="checkbox"
                  checked={item.webShow == 0}
                  onClick={() => { handleVerify(item._id, 1) }}
                />
              </div>
            );
          }
          exportitem.Sector = item.sector1
          dataExport.push(exportitem)
          return item
        })
        settableDetails(result)
        // setDataToBeExported(dataExport)
      }
    )
  }

  function fetchallCourseListexport() {
    get(`/course?branch=${filter?.csp}`).then(res => {
      let result = res.data
      setCount(res.count)
      let dataExport = []
      result.map((item, index) => {
        let exportitem = {}
        item.id = index + 1
        exportitem["Serial No"] = index + 1
        exportitem.Course = item.name
        item.sector1 = item?.sector?.name
        exportitem.Sector = item.sector1
        if (item.mode === "0") {
          item.mode1 = "Online"
          exportitem.Mode = "Online"
        }
        if (item.mode === "1") {
          item.mode1 = "Offline"
          exportitem.Mode = "Offline"
        }
        item.sector1 = item.sector.label
        item.staff = item?.addedBy?.firstName + item?.addedBy?.lastName

        if (item.unit === "1") {
          item.duration1 = item.duration + " " + "Hours"
          exportitem.Duration = item.duration + " " + "Hours"
        } else if (item.unit === "2") {
          item.duration1 = item.duration + " " + "Days"
          exportitem.Duration = item.duration + " " + "Days"
        } else if (item.unit === "3") {
          item.duration1 = item.duration + " " + "Months"
          exportitem.Duration = item.duration + " " + "Months"
        }
        exportitem.Sector = item.sector1
        dataExport.push(exportitem)
        return item
      })
      // settableDetails(result)
      // setDataToBeExported(dataExport)
      if(dataExport.length > 0){
        jsonToExcel(dataExport,`all-course-list-${moment().format("DD-MM-YY")}`)
      }else{
        toastr.warning("There are no data to export")
      }
    })
  }

  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`/course/${id}`)
          .then(res => {
            toastr.success(res.message)
            fetchallCourseList()
          })
          .catch(err => {
            toastr.success(err?.response?.data?.message)
            fetchallCourseList()
          })
      }
    })
  }

  function handleUpdate(item) {
    setCourseID({ ...courseID, objID: item._id })
    if (activeTabJustify === "1" && item._id) {
      get(`/course?_id=${item._id}`).then(res => {
        let result = res.data[0]
        setbascicDetails(result)
        setCourseID({ objID: result?._id })
      })
    }
    if (activeTabJustify === "3" && item._id) {
      get(`/course/details?_id=${item._id}`).then(res => {
        let result = res.data
        if (result.details.length === 0) {
          setcourseDetails({ details: [{}] })
        } else {
          setcourseDetails(result)
        }
        setCourseID({ objID: result?._id })
      })
    }

    if (activeTabJustify === "4" && item._id) {
      get(`/course/seo?_id=${item._id}`).then(res => {
        let result = res.data
        setseoDetails(result)
        setCourseID({ objID: result?._id })
      })
    }

    if (activeTabJustify === "2" && item._id) {
      get(`/course/fee_details?_id=${item._id}`).then(res => {
        let result = res.data
        setfeeDetails(result)
        setCourseID({ objID: result?._id })
      })
    }
  }

  function handleSelect(selected, name) {
    switch (name) {
      case "csp":
        setselectfiltercsp(selected)
        setfilter({ ...filter, csp: selected.value })
        break

      default:
        break
    }
  }

  function resetFilter() {
    setfilter({})
    setselectfiltercsp(null)
    fetchallCourseList()
    setSearch('')
  }

  const handleChangePage = newPage => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
  }


  function handleVerify(id, show) {

    put(`/course/webShow`, { _id: id, webShow: show == 0 ? 1 : 0 }).then((res) => {
      toastr.success(res?.message)
      fetchallCourseList()
    }).catch((err) => {
      console.log("err", err);
    })


  }

  return (
    <>
      <Modal size="lg" isOpen={modal} centered={true}>
        <div className="modal-header">
          <button
            onClick={() => {
              setModal(false)
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9">
            <iframe
              title="infoVideo"
              allowFullScreen
              className="embed-responsive-item"
              src={url}
            />
          </div>
        </div>
      </Modal>

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Course" />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4">Custom Tabs</CardTitle> */}
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTabJustify === "1",
                        })}
                        onClick={() => {
                          toggleCustomJustified("1")
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">Basic</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTabJustify === "2",
                        })}
                        onClick={() => {
                          toggleCustomJustified("2")
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-rupee-sign"></i>
                        </span>
                        <span className="d-none d-sm-block">Fee Details</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTabJustify === "3",
                        })}
                        onClick={() => {
                          toggleCustomJustified("3")
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-chalkboard-teacher"></i>
                        </span>
                        <span className="d-none d-sm-block">
                          Course Details
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTabJustify === "4",
                        })}
                        onClick={() => {
                          toggleCustomJustified("4")
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-code"></i>
                        </span>
                        <span className="d-none d-sm-block">SEO</span>
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <CourseContext.Provider value={{ courseID, setCourseID, activeTabJustify, setactiveTabJustify }}>
                    <TabContent activeTab={activeTabJustify}>
                      <TabPane tabId="1" className="p-3">
                        <Basic data={bascicDetails} />
                      </TabPane>
                      <TabPane tabId="2" className="p-3">
                        {activeTabJustify === "2" && (
                          <FeeDetails data={feeDetails} />
                        )}
                      </TabPane>
                      <TabPane tabId="3" className="p-3">
                        {activeTabJustify === "3" && (
                          <CourseDetails data={courseDetails} />
                        )}
                      </TabPane>
                      <TabPane tabId="4" className="p-3">
                        {activeTabJustify === "4" && (
                          <SEODetails data={seoDetails} />
                        )}
                      </TabPane>
                    </TabContent>
                  </CourseContext.Provider>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>


                    <Col md="3">
                      <div className="mb-3">
                        <Label>Csp</Label>
                        <Select
                          options={branches}
                          onChange={selected => {
                            handleSelect(selected, "csp")
                          }}
                          value={selectfiltercsp}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3" style={{ marginTop: "1.7rem" }}>
                        <AvForm>
                          <AvField
                            name="search"
                            placeholder="Search course "
                            type="text"
                            id="validationCustom01"
                            className="form-control"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}

                          />
                        </AvForm>

                      </div>
                    </Col>
                    <Col md="2">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "",
                        }}
                        className="mb-3"
                      >
                        <Button
                          onClick={resetFilter}
                          style={{ marginTop: "26px" }}
                          color="danger"
                          type="button"
                        >
                          Reset
                        </Button>
                        <Button
                          onClick={fetchallCourseListexport}
                          color="success"
                          className="mx-2"
                          style={{ marginTop: "26px" }}
                        >
                          {/* <CSVLink
                            data={dataToBeExported}
                            filename={
                              "Courselist_" +
                              `${selectfiltercsp?.label || "All"}` +
                              ".xls"
                            }
                            style={{ color: "white" }}
                          >
                            Export
                          </CSVLink> */}
                          Export
                        </Button>
                      </div>
                    </Col>




                  </Row>
                  <MDBDataTable
                    id="courseTableId"
                    bordered
                    responsive
                    searching={false}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                    displayEntries={false}
                    paging={false}
                  />
                  <MuiCustomTablePagination
                    count={count}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}
export { CourseContext }
export default ManageCourse
