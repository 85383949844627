import React, { useEffect, useRef } from 'react'
import { AvField, AvForm } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { MDBDataTable } from 'mdbreact';
import { del, get, post, put } from '../../../helpers/api_helper';
import { useState } from 'react';
import toastr from "toastr";
import Swal from "sweetalert2"
import "./zone.scss"
import moment from 'moment';
function Zone() {
    useEffect(() => {
        handleTableData()
        // eslint-disable-next-line
    }, [])

    const formRef = useRef();
    const [zoneDetailList, setZoneDetailList] = useState([])
    const [masterObject, setMasterObject] = useState({})
    const [nameIdToBeUpdated, setnameIdToBeUpdated] = useState(null)

    const toTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }


    function handleValidSubmit() {

        if (nameIdToBeUpdated) {
            put(`zone`, masterObject)
                .then((res) => {

                    toastr.success("Updated Successfully")
                    handleTableData()
                    setnameIdToBeUpdated(null)
                    formRef.current.reset()
                    setMasterObject({})
                })
                .catch((err) => {
                    toastr.error(err.response.data.message)

                })
        }
        else {

            post(`zone`, masterObject)
                .then((res) => {

                    toastr.success(res.message)
                    handleTableData()
                    formRef.current.reset()
                    setMasterObject({})
                }).catch((err) => {
                    toastr.error(err.response.data.message)

                })
        }
    }

    function handleTableData() {
        get(`zone`)
            .then((res) => {
                let Data = []
                res.data.map((item, index) => {

                    item.id = index + 1
                    item.date = moment(item.date).format("DD-MM-YYYY");
                    item.addedby = item?.addedBy?.firstName;

                    item.action = (
                        <div >

                            <i className="far fa-edit"
                                style={{
                                    cursor: "pointer",
                                    fontSize: "1em",
                                    marginRight: "0.5rem",
                                    marginLeft: "0.5rem"
                                }}
                                onClick={() => {
                                    toTop()
                                    preUpdatename(item)
                                }}
                            ></i>
                            <i className="far fa-trash-alt"
                                style={{ cursor: "pointer", fontSize: "1em" }}
                                onClick={() => {
                                    handleDelete(item._id)
                                }}
                            ></i>
                        </div>
                    )
                    Data.push(item)
                    return item
                })
                setZoneDetailList(Data)

            })
    }

    function handleChangeInput(e) {

        let name = e.target.name;
        let value = e.target.value;
        // console.log(name,"name");
        setMasterObject({ ...masterObject, [name]: value, });
    }

    let preUpdatename = (item) => {
        setnameIdToBeUpdated(item?._id)
        setMasterObject(
            {
                id: item._id,
                name: item.name,
                shortCode: item.shortCode
            }
        )
    }
    function handleDelete(id) {
        return Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(result => {
            if (result.isConfirmed) {
                del(`zone/${id}`)
                    .then(res => {
                        toastr.success(res.message)
                        handleTableData()
                    })
                    .catch(err => {
                        toastr.success(err.response.data.message)
                    })
            }
        })
    }
    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                width: 150,
            },
            {
                label: "Date",
                field: "date",
                width: 150,
            },
            {
                label: "Zone",
                field: "name",
                width: 150,
            },
            {
                label: "Code",
                field: "shortCode",
                width: 150,
            },
            {
                label: "User",
                field: "addedby",
                width: 150,
            },
            {
                label: "Actions",
                field: "action",
                sort: "desc",
                width: 400,
            },

        ],
        rows: zoneDetailList
    };
    return (
        <>
            <div className='page-content'>
                <div className='container-fluid'>
                    <Breadcrumbs title="Home" breadcrumbItem="Manage Zone" />
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <AvForm
                                        ref={formRef}
                                        onValidSubmit={(e, v) => {
                                            handleValidSubmit()
                                        }}>
                                        <Row>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Zone</Label>
                                                    <AvField
                                                        name="name"
                                                        className="form-control"
                                                        type="text"
                                                        value={masterObject.name||""}

                                                        onChange={handleChangeInput}

                                                        placeholder="Zone"
                                                        errorMessage="Enter Category"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Zone Code</Label>
                                                    <AvField
                                                        name="shortCode"
                                                        className="form-control"
                                                        type="text"
                                                        value={masterObject.shortCode||""}

                                                        onChange={handleChangeInput}

                                                        placeholder="Zone Code"

                                                    />
                                                </div>
                                            </Col>

                                            <Col>
                                                <div className="mb-3" style={{ paddingTop: "25px" }}>
                                                    {nameIdToBeUpdated ?
                                                        (
                                                            <div className="mb-3" style={{ paddingTop: "" }}>

                                                                <Button className='mx-2 butn1'
                                                                    color='warning'
                                                                    type='submit'
                                                                // disabled={countryObject ? true : false}

                                                                >
                                                                    {/* {countryObject ? "Updating" : "Update"} */}
                                                                    {"Update"}
                                                                </Button>
                                                            </div>
                                                        )
                                                        :
                                                        <div className="mb-3" style={{ paddingTop: "" }}>

                                                            <Button className='mx-2'
                                                                color='primary'
                                                                type='submit'
                                                            // disabled={addingcountry ? true : false}

                                                            >
                                                                {"Submit"}


                                                            </Button>
                                                        </div>}

                                                </div>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>


                    <Row>
                        <Col className='col-12'>
                            <Card>
                                <CardBody>


                                    <MDBDataTable
                                        id="ZoneId"
                                        bordered
                                        responsive
                                        searching={true}
                                        entries={20}
                                        disableRetreatAfterSorting={true}
                                        data={data}

                                    >

                                    </MDBDataTable>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>



                </div>
            </div>
        </>
    )
}

export default Zone