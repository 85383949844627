import React, { useState, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import Select from "react-select"

import useBranch from "../../../hooks/useBranch"
import { getBranches } from "../../../store/options/actions"
import {
  getDate,
  getOneMonthAgo,
  numberToString,
  setTitle,
} from "../../../helpers/functions"
import { get } from "../../../helpers/api_helper"
import toastr from "toastr"

import "./style.scss"
import queryString from "query-string"

const ProfitLoss = () => {
  setTitle("Profit And Loss")
  const dispatch = useDispatch()
  const ref = useRef()
  const { branches } = useSelector(state => state.Options)
  const todayDate = getDate()
  const emptyState = {
    amount: 0,
    total: 0,
    data: [],
  }
  const [typeOptions, setTypeOptions] = useState([])
  const [courseOptions, setCourseOptions] = useState([])
  const [batchOptions, setBatchOptions] = useState([])

  const [branch, setBranch] = useState(...useBranch())
  const [alreadyBranch] = useBranch()
  const [tableData, setTableData] = useState({
    income: emptyState,
    expense: emptyState,
  })
  const [masterObject, setMasterObject] = useState({
    branch: "",
    from: getOneMonthAgo(),
    to: getDate(),
    subType: [],
  })

  const [selectedFields, setSelectedFields] = useState({
    subType: "",
  })

  useEffect(() => {
    if (!branch) {
      dispatch(getBranches())
      handleData()
      handleTypeOptions()
    } else {
      const obj = { ...masterObject }
      obj.branch = branch.value
      setMasterObject(obj)
      handleData(obj)
      handleTypeOptions(obj)
      handleCourseOptions(obj)
      handleBatchOptions(obj)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleTypeOptions()
    handleCourseOptions()
    handleBatchOptions()
  }, [masterObject]) // eslint-disable-line

  // Fetch
  const handleData = async (obj = masterObject) => {
    const query = queryString.stringify(obj)

    await get(`income-expense/profit-loss?${query}`)
      .then(res => {
        if (!res.data || res.data?.length < 1) {
          toastr.error("No data found")
          setTableData({
            income: emptyState,
            expense: emptyState,
          })
          return
        }
        if (res.data[0] && res.data[0]?.type === 1) {
          setTableData({
            income: res.data[0] || emptyState,
            expense: res.data[1] || emptyState,
          })
        } else {
          setTableData({
            income: emptyState,
            expense: res.data[0] || emptyState,
          })
        }
      })
      .catch(err => {
        toastr.error(err.response.data.message)
        setTableData({
          income: emptyState,
          expense: emptyState,
        })
      })
  }

  const handleTypeOptions = async (obj = masterObject) => {
    try {
      const query = queryString.stringify(obj)
      const response = await get(
        `income-expense/profit-loss/options/type?${query}`
      )
      setTypeOptions(response.data)
    } catch (error) {}
  }

  const handleCourseOptions = async (obj = masterObject) => {
    try {
      const query = queryString.stringify(obj)
      const response = await get(
        `income-expense/profit-loss/options/course?${query}`
      )
      setCourseOptions(response.data)
    } catch (error) {}
  }

  const handleBatchOptions = async (obj = masterObject) => {
    try {
      const query = queryString.stringify(obj)
      const response = await get(
        `income-expense/profit-loss/options/batch?${query}`
      )
      setBatchOptions(response.data)
    } catch (error) {}
  }

  const handleSelectValueChange = (selected, name) => {
    const obj = { ...selectedFields }
    obj[name] = selected

    if (Array.isArray(selected)) {
      const values = selected.map(item => item.value)
      handleValueChange(values, name)
    }
    setSelectedFields(obj)
  }

  const handleValueChange = (value, name) => {
    const obj = { ...masterObject }
    obj[name] = value
    console.log(name, value, obj)

    if (name === "course") handleBatchOptions(obj)

    setMasterObject(obj)
  }

  const reset = () => {
    setMasterObject({ branch: branch?.value || "" })
    setSelectedFields({ subType: "" })
    ref.current.reset()

    if (!alreadyBranch) setBranch(null)
  }
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Profit And Loss" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm onValidSubmit={() => handleData()} ref={ref}>
                    <Row>
                      {alreadyBranch ? (
                        ""
                      ) : (
                        <Col md={2}>
                          <Label>CSP</Label>
                          <Select
                            value={branch || ""}
                            placeholder="Select ..."
                            options={branches}
                            onChange={selected => {
                              setBranch(selected)
                              handleValueChange(selected.value, "branch")
                            }}
                          ></Select>
                        </Col>
                      )}
                      <Col md="2">
                        <div className="mb-3">
                          <Label>From</Label>
                          <AvField
                            name="from"
                            className="form-control"
                            type="date"
                            value={masterObject.from || ""}
                            onChange={e =>
                              handleValueChange(e.target.value, e.target.name)
                            }
                            max={todayDate}
                            validate={{ required: { value: true } }}
                            errorMessage="From time required"
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>To</Label>
                          <AvField
                            name="to"
                            className="form-control"
                            type="date"
                            value={masterObject.to || ""}
                            onChange={e =>
                              handleValueChange(e.target.value, e.target.name)
                            }
                            min={masterObject.from}
                            max={todayDate}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <Label>Sub Head</Label>
                        <Select
                          name="subType"
                          placeholder="Select..."
                          options={typeOptions}
                          value={selectedFields.subType || ""}
                          isMulti
                          onChange={selected =>
                            handleSelectValueChange(selected, "subType")
                          }
                        ></Select>
                      </Col>
                      <Col md={2}>
                        <Label>Course</Label>
                        <Select
                          name="course"
                          placeholder="Select..."
                          options={courseOptions}
                          value={selectedFields.course || ""}
                          isMulti
                          onChange={selected =>
                            handleSelectValueChange(selected, "course")
                          }
                        ></Select>
                      </Col>
                      <Col md={2}>
                        <Label>Batch</Label>
                        <Select
                          name="batch"
                          placeholder="Select..."
                          options={batchOptions}
                          value={selectedFields.batch || ""}
                          isMulti
                          onChange={selected =>
                            handleSelectValueChange(selected, "batch")
                          }
                        ></Select>
                      </Col>
                      <Col>
                        <div
                          className="mb-3 d-flex gap-2"
                          style={{ paddingTop: alreadyBranch ? "26px" : "0" }}
                        >
                          <Button color="primary" type="submit">
                            Search
                          </Button>
                          <Button color="danger" type="button" onClick={reset}>
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Row>
                <Col md="6">
                  <Card>
                    <CardBody>
                      <table
                        className="table table-bordered"
                        id="profitAndLossTable"
                      >
                        <thead>
                          <th style={{ textAlign: "center" }} colspan="4">
                            Income
                          </th>
                        </thead>
                        <tbody key={JSON.stringify(tableData)}>
                          {tableData.income.data.map(item => {
                            const rowspan =
                              item.data?.length >= 1 ? item.data.length : 0
                            return item.data?.map((sub, index) => {
                              return (
                                <tr key={JSON.stringify(item)}>
                                  {index === 0 ? (
                                    <td rowspan={rowspan}>{item.name}</td>
                                  ) : (
                                    ""
                                  )}
                                  <td colspan="1">
                                    <span style={{ float: "left" }}>
                                      {sub.name}
                                    </span>
                                    <span style={{ float: "right" }}>
                                      {numberToString(sub.amount)}
                                    </span>
                                  </td>
                                  {index === 0 ? (
                                    <th colspan="2" rowspan={rowspan}>
                                      <span style={{ float: "right" }}>
                                        {numberToString(item.amount)}
                                      </span>
                                    </th>
                                  ) : (
                                    ""
                                  )}
                                </tr>
                              )
                            })
                          })}
                          <tr>
                            <th colspan="3">
                              <span style={{ float: "left" }}>TOTAL</span>
                              <span style={{ float: "right" }}>
                                {numberToString(tableData.income.amount)}
                              </span>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6">
                  <Card>
                    <CardBody>
                      <table className="table table-bordered">
                        <thead>
                          <th style={{ textAlign: "center" }} colspan="4">
                            Expense
                          </th>
                        </thead>
                        <tbody key={JSON.stringify(tableData)}>
                          {tableData.expense.data.map(item => {
                            const rowspan =
                              item.data?.length >= 1 ? item.data.length : 0

                            return item.data?.map((sub, index) => {
                              return (
                                <tr key={JSON.stringify(item)}>
                                  {index === 0 ? (
                                    <td rowspan={rowspan}>{item.name}</td>
                                  ) : (
                                    ""
                                  )}
                                  <td colspan="1">
                                    <span style={{ float: "left" }}>
                                      {sub.name}
                                    </span>
                                    <span style={{ float: "right" }}>
                                      {numberToString(sub.amount)}
                                    </span>
                                  </td>
                                  {index === 0 ? (
                                    <th colspan="2" rowspan={rowspan}>
                                      <span style={{ float: "right" }}>
                                        {numberToString(item.amount)}
                                      </span>
                                    </th>
                                  ) : (
                                    ""
                                  )}
                                </tr>
                              )
                            })
                          })}
                          <tr>
                            <th colspan="3">
                              <span style={{ float: "left" }}>TOTAL</span>
                              <span style={{ float: "right" }}>
                                {numberToString(tableData.expense.amount)}
                              </span>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <Card>
                    <CardBody>
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <td style={{ textAlign: "center" }}>
                              <span style={{ fontWeight: "bold" }}>Income</span>
                              : {numberToString(tableData.income.amount)}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span style={{ fontWeight: "bold" }}>
                                Expense
                              </span>
                              : {numberToString(tableData.expense.amount)}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span style={{ fontWeight: "bold" }}>
                                Balance
                              </span>
                              :{" "}
                              {numberToString(
                                (tableData.income.amount || 0) -
                                  (tableData.expense.amount || 0)
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default ProfitLoss
