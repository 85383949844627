import React, { useEffect, useState } from "react"
import "./addLead.scss"
import { MDBDataTable } from "mdbreact"
import { Button, Row } from "reactstrap"
import { get } from "../../../../helpers/api_helper"
import moment from "moment"
import { API_URL } from "../../../../config"
import { dateConverter, timeConverter } from "../../../../helpers/functions"

const Payments = ({ leadId }) => {
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [leadId])

  const handleTableData = async () => {
    try {
      const response = await get(`leads/followup/fee/${leadId}`)
      const data = response.data
      data?.map((item, index) => {
        item.id = index + 1
        item.date = dateConverter(item.date)
        item.time = timeConverter(item.time)
        item.status = (
          <Button
            style={{ width: "100%" }}
            color={
              item.status === 0
                ? "success"
                : item.status === 1
                ? "danger"
                : "warning"
            }
            disabled
            size="sm"
          >
            {item.status === 0
              ? "Success"
              : item.status === 1
              ? "Failed"
              : "Pending"}
          </Button>
        )
        item.type =
          item.type === 1
            ? "Cash"
            : item.type === 2
            ? "Skill loan"
            : item.type === 3
            ? "Scholarship"
            : item.type === 4
            ? "Online"
            : ""

        item.receipt = item.receiptPath ? (
          <a
            href={API_URL + item.receiptPath}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <i
              className="uil-file-download"
              style={{ fontSize: "1.5em", cursor: "pointer" }}
            ></i>
          </a>
        ) : (
          ""
        )
        return item
      })

      setTableData(data)
    } catch (err) {
      console.log(err.response?.data?.message)
    }
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        width: 150,
      },
      {
        label: "Receipt No",
        field: "receiptNo",
        width: 150,
      },
      {
        label: "Type",
        field: "type",
        width: 150,
      },
      {
        label: "Amount",
        field: "amount",
        width: 150,
      },
      {
        label: "Payment Id",
        field: "paymentId",
        width: 150,
      },
      {
        label: "Order Id",
        field: "orderId",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        width: 150,
      },
      {
        label: "Receipt",
        field: "receipt",
        sort: "desc",
        width: 400,
      },
    ],
    rows: tableData,
  }

  return (
    <>
      <h1 className="head-700-18-23">Payment History</h1>

      <Row className="mt-4">
        <MDBDataTable
          id="leadPaymentDetails"
          bordered
          responsive
          searching={false}
          displayEntries={false}
          paging={false}
          disableRetreatAfterSorting={true}
          data={data}
          sortable={false}
        ></MDBDataTable>
      </Row>
    </>
  )
}
export default Payments
