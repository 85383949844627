import React, { useEffect, useRef, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap"
import Tooltip from "@mui/material/Tooltip"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import "./addLead.scss"
import { useDispatch } from "react-redux"
import {
  getCourses,
  getDistrictOptions,
  getEvents,
  getTempBatch,
  getUsersOptions,
} from "../../../../store/options/actions"
import { useSelector } from "react-redux"
import { isArray } from "lodash"
import { getLeadFor } from "./backendHelper"
import { del, get, post } from "../../../../helpers/api_helper"
import moment from "moment"

import toastr from "toastr"
import Swal from "sweetalert2"
import SkillLoanProcessing from "../../../Finance/SkillLoan/Processing"
import ScholarshipProcessing from "../../../Finance/Scholarship/Processing"
import Followup from "./Followup"
import { PRIVILEGES } from "../../../../config"
import useBranch from "../../../../hooks/useBranch"
import queryString from "query-string"
import { jsonToExcel } from "../../../../helpers/functions"
import MuiCustomTablePagination from "../../../../components/Common/TablePagination"
import { useLocation } from "react-router-dom"

function AddLead() {
  const [count, setCount] = useState(0)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)

  const [master, setMaster] = useState({ state: 19 })
  const [rows, setRows] = useState([])

  const [branch] = useBranch()

  const location = useLocation()

  const [search, setSearch] = useState({})

  const [followup, setFollowup] = useState({ modal: false, studentId: null })

  const OPTIONS = useSelector(state => state.Options)

  const [exports, setExports] = useState([])

  const [selected, setSelected] = useState({
    state: {
      _id: 19,
      name: "Kerala",
      label: "Kerala",
      value: 19,
    },
  })

  const [searchSelect, setSearchSelect] = useState({})

  const [scholarship, setScholarship] = useState({ show: false })

  const [skilloan, setSkilloan] = useState({ show: false })

  const [leadFor, setLeadFor] = useState([])

  const dispatch = useDispatch()

  const formRef = useRef()

  useEffect(() => {
    dispatch(getCourses())
    dispatch(getEvents())
  }, [dispatch])

  useEffect(() => {
    const data = location?.state?.data || {}
    data.mobile = data?.phone || ""
    delete data._id
    delete data.date
    delete data.time
    delete data.branch
    delete data.from

    const district = data?.district?.name
      ? {
          label: data?.district?.name,
          value: data?.district?._id,
        }
      : null

    data.district = data?.district?._id
    data.campaign = data?.campaign?._id ?? null

    setSelected(pre => ({ ...pre, district }))
    setMaster(pre => ({ ...pre, ...data }))
  }, [location.state])

  console.log(master)

  useEffect(() => {
    dispatch(getDistrictOptions({ id: master.state }))
  }, [master.state, dispatch])

  useEffect(() => {
    dispatch(
      getUsersOptions({
        branch: branch?.value || "",
        privilege: PRIVILEGES.INTERN,
      })
    )
  }, [dispatch, branch])

  useEffect(() => {
    getLeadFor().then(res => {
      setLeadFor(res.data)
    })
  }, [])

  useEffect(() => {
    fetchLeads(search, page, rowsPerPage)
    // eslint-disable-next-line
  }, [search, page, rowsPerPage])

  const fetchLeads = (query = {}, page, limit) => {
    get(
      `leads?${queryString.stringify(query)}&page=${page}&limit=${limit}`
    ).then(res => {
      const data = res.data

      const exportData = []

      data.map((item, idx) => {
        item.id = idx + 1

        item.name = `${item?.reg_id?.firstname || ""} ${
          item?.reg_id?.lastname || ""
        }`

        item.date = item.date && moment(item.date).format("DD-MM-YYYY")

        item.uniqueId = item?.reg_id?.unique_id

        item.assign = `${item?.assigned?.firstName || ""} ${
          item?.assigned?.lastName || ""
        }`

        item.mobile = item?.reg_id?.mobile
        item.course = item?.course?.name || item?.event?.name
        item.source = item?.source?.name
        item.remarks = item?.message

        item.paid =
          item?.courseFee?.paidAmount ||
          item?.eventRegistrationId?.paidAmount ||
          0

        item.due =
          item?.courseFee?.payableAmount - item?.courseFee?.paidAmount ||
          item?.eventRegistrationId?.due ||
          0

        item.temp_batch = (
          <span>
            {item?.tmpBatch?.branch?.name}
            <br />
            {item?.tmpBatch?.expStartingDate &&
              moment(item?.tmpBatch?.expStartingDate).format("MMMM YYYY")}
          </span>
        )

        delete item.message

        item.followupStatus = (
          <div className="text-center">
            <span
              style={{
                background: item?.latest_followup_status?.color,
              }}
              className="badge font-size-12 ms-2"
            >
              {item?.latest_followup_status?.name}
            </span>
          </div>
        )

        item.action = (
          <div>
            <Tooltip title="Followup" arrow placement="top">
              <i
                className="fas fa-eye me-2"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                }}
                onClick={() => {
                  // getLeadSingle(item._id)
                  setFollowup({
                    modal: true,
                    studentId: item?.reg_id?._id,
                    leadId: item._id,
                  })
                }}
              ></i>
            </Tooltip>

            {item.skillLoan.requested && item.skillLoan?.requestedId && (
              <Tooltip title="Skill loan" arrow placement="top">
                <i
                  onClick={() =>
                    setSkilloan({
                      show: true,
                      id: item?.skillLoan?.requestedId,
                    })
                  }
                  className="fa fa-university me-2"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                  }}
                ></i>
              </Tooltip>
            )}

            {item.scholarship.requested && item.scholarship?.requestedId && (
              <Tooltip title="Scholarship" arrow placement="top">
                <i
                  onClick={() =>
                    setScholarship({
                      show: true,
                      id: item?.scholarship?.requestedId,
                    })
                  }
                  className="fa fa-graduation-cap me-2"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                  }}
                ></i>
              </Tooltip>
            )}

            <i
              onClick={() => handleDelete(item._id)}
              className="far fa-trash-alt"
              style={{
                fontSize: "1em",
                cursor: "pointer",
              }}
            ></i>
          </div>
        )

        // TODO: Export details
        const dataExport = {}

        dataExport.Date = item.date
        dataExport.Type = item.type
        dataExport["Student Id"] = item.uniqueId
        dataExport.Name = item.name
        dataExport.Mobile = item.mobile
        dataExport["Course/Event"] = item.course
        dataExport.Source = item.source
        dataExport.Assigned = item.assign
        dataExport.Paid = item.paid
        dataExport.Due = item.due
        dataExport.Status = item?.latest_followup_status?.name

        exportData.push(dataExport)

        // item.checkbox = (
        //   <div className="text-center">
        //     <input type="checkbox" name="" id="" />
        //   </div>
        // )

        return item
      })

      setCount(res.totalCount)
      setRows(data)
      setExports(exportData)
    })
  }

  const handleSelect = (e, name) => {
    const value = e ? (isArray(e) ? e.map(item => item.value) : e.value) : null
    setSelected(pre => ({ ...pre, [name]: e }))
    setMaster(pre => ({ ...pre, [name]: value }))
  }

  const handleChange = e => {
    let { name, value } = e.target
    setMaster(pre => ({ ...pre, [name]: value }))
  }

  const handleSearchChange = e => {
    let { name, value } = e.target
    setSearch(pre => ({ ...pre, [name]: value }))
  }

  const handleSearchSelect = (e, name) => {
    const value = e ? (isArray(e) ? e.map(item => item.value) : e.value) : null
    setSearchSelect(pre => ({ ...pre, [name]: e }))
    setSearch(pre => ({ ...pre, [name]: value }))
  }

  const refresh = () => {
    fetchLeads(search, page, rowsPerPage)
  }

  const reset = () => {
    formRef.current.reset()

    setSelected({
      state: { _id: 19, name: "Kerala", label: "Kerala", value: 19 },
    })

    setMaster({ state: 19 })
  }

  const handleSubmit = () => {
    post(`leads`, master)
      .then(res => {
        fetchLeads(search, 0, rowsPerPage)
        reset()
        toastr.success(res.message)
      })
      .catch(err => {
        toastr.error(err?.response?.data?.message)
        console.log(err)
      })
  }

  const handleDelete = id => {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`/leads/${id}`)
          .then(res => {
            toastr.success(res.message)
            fetchLeads(search, page, rowsPerPage)
            reset()
          })
          .catch(err => {
            toastr.success(err.response.data.message)
          })
      }
    })
  }

  const handleExport = () => {
    if (exports.length > 0) {
      jsonToExcel(exports, `leads-${moment().format("DD-MM-YY")}`)
    } else {
      toastr.info(`There are no leads to export`)
    }
  }

  const handleChangePage = newPage => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      // {
      //   label: "",
      //   field: "checkbox",
      //   width: 150,
      // },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Type",
        field: "type",
        width: 150,
      },
      {
        label: "ID",
        field: "uniqueId",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        width: 150,
      },
      {
        label: "Mobile",
        field: "mobile",
        width: 150,
      },
      {
        label: "Course/Event",
        field: "course",
        width: 150,
      },
      {
        label: "Source",
        field: "source",
        width: 150,
      },
      {
        label: "Assigned",
        field: "assign",
        width: 150,
      },
      {
        label: "Tmp Batch",
        field: "temp_batch",
        width: 150,
      },
      {
        label: "Paid",
        field: "paid",
        width: 150,
      },
      {
        label: "Due",
        field: "due",
        width: 150,
      },
      {
        label: "Status",
        field: "followupStatus",
        width: 150,
      },
      {
        label: "Actions",
        field: "action",
        sort: "desc",
        width: 400,
      },
    ],
    rows,
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Leads" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm ref={formRef} onValidSubmit={handleSubmit}>
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>
                            Mobile No.<span style={{ color: "red" }}>*</span>
                          </Label>
                          <AvField
                            name="mobile"
                            type="text"
                            onChange={handleChange}
                            value={master.mobile || ""}
                            errorMessage="Enter Mobile Number"
                            className="form-control"
                            placeholder="Enter Mobile No."
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: "^(0|91)?[6-9][0-9]{9}$",
                              },
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Name</Label>
                          <AvField
                            name="name"
                            type="text"
                            value={master.name || ""}
                            onChange={handleChange}
                            placeholder="Enter Name"
                            errorMessage="Enter valid Name"
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: "^[a-zA-Z ]+$",
                              },
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Email</Label>
                          <AvField
                            name="email"
                            onChange={handleChange}
                            value={master.email || ""}
                            className="form-control"
                            type="email"
                            placeholder="Enter Email ID"
                            errorMessage="Enter valid Email"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Qualification</Label>
                          <Select
                            options={OPTIONS.qualification}
                            value={selected.qualification || null}
                            onChange={e => {
                              handleSelect(e, "qualification")
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>State</Label>
                          <Select
                            options={OPTIONS.states}
                            defaultValue={selected.state}
                            value={selected.state || null}
                            onChange={e => {
                              handleSelect(e, "state")
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>District</Label>
                          <Select
                            value={selected.district || null}
                            onChange={e => {
                              handleSelect(e, "district")
                            }}
                            options={OPTIONS.distrcts}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>City</Label>
                          <AvField
                            name="city"
                            value={master.city || ""}
                            handleChange={handleChange}
                            className="form-control"
                            type="text"
                            placeholder="City Name"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Address</Label>
                          <AvField
                            name="address"
                            value={master.address || ""}
                            onChange={handleChange}
                            className="form-control"
                            rows={1}
                            type="textarea"
                            placeholder="Address"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Message</Label>
                          <AvField
                            className="form-control"
                            rows={1}
                            type="textarea"
                            placeholder="Message"
                            name="message"
                            value={master.message || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>For</Label>
                          <Select
                            options={[
                              {
                                label: "Course",
                                value: "Course",
                              },
                              {
                                label: "Event",
                                value: "Event",
                              },
                            ]}
                            value={selected.lead_type || null}
                            onChange={e => {
                              handleSelect(e, "lead_type")
                            }}
                          />
                        </div>
                      </Col>

                      {selected?.lead_type?.value === "Course" ? (
                        <>
                          <Col md="3">
                            <div className="mb-3">
                              <Label>Interested Course</Label>
                              <Select
                                options={OPTIONS.courses}
                                value={selected.course || null}
                                onChange={e => {
                                  handleSelect(e, "course")
                                  dispatch(getTempBatch({ course: e.value }))
                                }}
                              />
                            </div>
                          </Col>

                          <Col md="3">
                            <div className="">
                              <Label>Batch</Label>
                              <Select
                                name="batch"
                                onChange={selected => {
                                  handleSelect(selected, "batch")
                                }}
                                value={selected.batch || null}
                                options={OPTIONS.tempBatch}
                              />
                            </div>
                          </Col>
                        </>
                      ) : (
                        selected?.lead_type?.value === "Event" && (
                          <Col md="3">
                            <div className="mb-3">
                              <Label>Event</Label>
                              <Select
                                options={OPTIONS.events}
                                value={selected.event || null}
                                onChange={e => {
                                  handleSelect(e, "event")
                                }}
                              />
                            </div>
                          </Col>
                        )
                      )}

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Lead Source</Label>
                          <Select
                            type="text"
                            options={leadFor}
                            value={selected.source || null}
                            onChange={e => {
                              handleSelect(e, "source")
                            }}
                          />
                        </div>
                      </Col>

                      <Col>
                        <div className="mb-3 mt-4 pt-1">
                          <Button
                            className="me-3"
                            color="primary"
                            type="submit"
                          >
                            Submit
                          </Button>
                          <Button color="danger" type="button" onClick={reset}>
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label> From</Label>
                          <AvField
                            name="from_date"
                            value={search.from_date || ""}
                            onChange={handleSearchChange}
                            max={moment().format("YYYY-MM-DD")}
                            className="form-control"
                            type="date"
                            placeholder="From"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>To</Label>
                          <AvField
                            name="to_date"
                            value={search.to_date || ""}
                            max={moment().format("YYYY-MM-DD")}
                            min={search.from_date}
                            onChange={handleSearchChange}
                            className="form-control"
                            type="date"
                            placeholder="To"
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Course</Label>
                          <Select
                            onChange={e => {
                              handleSearchSelect(e, "course")
                            }}
                            value={searchSelect.course || null}
                            options={OPTIONS.courses}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Source</Label>
                          <Select
                            onChange={e => {
                              handleSearchSelect(e, "source")
                            }}
                            value={searchSelect.source || null}
                            options={leadFor}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Staff</Label>
                          <Select
                            options={OPTIONS.users}
                            onChange={e => {
                              handleSearchSelect(e, "user")
                            }}
                            value={searchSelect.user || null}
                          />
                        </div>
                      </Col>

                      <Col className="mt-4 pt-1">
                        <div className="mb-3">
                          <Button
                            color="danger"
                            type="button"
                            onClick={() => {
                              setSearch({})
                              setSearchSelect({})
                            }}
                          >
                            Reset
                          </Button>
                          {/* <Button
                            style={{ marginLeft: "14px" }}
                            color="warning"
                            type="button"
                            onClick={handleExport}
                          >
                            Export
                          </Button> */}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>

                  <MDBDataTable
                    id="Managetableid1"
                    bordered
                    responsive
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                    paging={false}
                  ></MDBDataTable>
                  <MuiCustomTablePagination
                    count={count}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      {followup.modal && (
        <Modal
          show={followup.modal}
          isOpen={followup.modal}
          toggle={() => {
            refresh()
            setFollowup({ modal: false })
          }}
          size="xl"
          centered={true}
          id="leadModal"
          className="lead-modal"
        >
          <div className="modal-header">
            <div className="modal-title">
              <h6 style={{ marginBottom: "0px" }} className="modal-title mt-0">
                {/* #{leadData.unique_id} */}
              </h6>
            </div>
            <button
              style={{ marginBottom: "2px" }}
              type="button"
              onClick={() => {
                refresh()
                setFollowup({ modal: false })
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>

            <Followup followup={followup} />
          </div>
        </Modal>
      )}

      {scholarship.show && (
        <Modal
          show={scholarship.show}
          isOpen={scholarship.show}
          size="xl"
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Scholarship Details</h5>
            <button
              type="button"
              onClick={() => {
                setScholarship({ show: false })
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <ScholarshipProcessing id={scholarship?.id} />
          </div>
        </Modal>
      )}

      {skilloan.show && (
        <Modal show={skilloan.show} isOpen={skilloan.show} size="xl" centered>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Skill Loan Details</h5>
            <button
              type="button"
              onClick={() => {
                setSkilloan({ show: false })
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <SkillLoanProcessing id={skilloan?.id} />
          </div>
        </Modal>
      )}
    </>
  )
}

export default AddLead
