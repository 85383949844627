import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { Modal } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import { get } from "../../../helpers/api_helper"
import moment from "moment"
import { getDate, getFirstday } from "../../../helpers/functions"
import { APP_NAME } from "../../../config"
import "./style.scss"
import DataTable from "../../../components/Common/DataTable"

import qs from "query-string"
import { Tooltip } from "@mui/material"

const SMSLogs = () => {
  document.title = `SMS Log | ${APP_NAME}`

  const [body, setBody] = useState(null)

  const [master, setMaster] = useState({
    from_date: getFirstday(),
    to_date: getDate(),
  })
  const [activity, setActivity] = useState([])
  const [type, setType] = useState({ value: 0, label: "All" })

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const [count, setCount] = useState(0)

  const typeOptions = [
    { value: 0, label: "All" },
    { value: 1, label: "Email" },
    { value: 2, label: "Whatsapp" },
    { value: 3, label: "Firebase" },
    { value: 4, label: "SMS" },
  ]

  const typeDeatils = {
    1: { name: "Email", color: "#ffa22e2e" },
    2: { name: "Whatsapp", color: "#10ff002e" },
    3: { name: "Firebase", color: "#ff31002e" },
    4: { name: "SMS", color: "#3d1bfb2e" },
  }

  // useEffect(() => {
  //   dispatch(getUsersOptions({}))
  // }, [dispatch])

  // const userOptions = useSelector(state => state.Options.users)

  function handleChange(e) {
    const { name, value } = e.target
    setMaster(pre => ({ ...pre, [name]: value }))
  }

  useEffect(() => {
    const filters = {
      page,
      limit,
      type: type?.value,
      from_date: master?.from_date,
      to_date: master?.to_date,
    }
    get(`message/logs?${qs.stringify(filters)}`)
      .then(res => {
        const data = res.data
        setCount(res?.count || 0)
        data.map((item, index) => {
          item.id = (page - 1) * limit + index + 1

          item.to = item?.to || item?.mobile || "----"

          if (Object.keys(item?.user).length > 0) {
            item.name = `${item?.user?.firstName || ""} ${
              item?.user?.firstName || ""
            }`
            item.regId = "----"
          } else if (Object.keys(item?.student).length > 0) {
            item.name = `${item?.student?.firstname || ""} ${
              item?.student?.lastname || ""
            }`

            item.regId = item?.student?.uniqueId || "----"
          } else {
            item.name = "----"
          }

          item.mobile = item?.user?.mobile || item?.student?.mobile || "----"

          item.email = item?.user?.email || item?.student?.email || "----"

          item.head = item?.head || "----"

          // item.type = typeDeatils[item.type]?.name
          item.type = (
            <span
              className={`badge rounded-pill  font-size-12`}
              style={{
                backgroundColor: typeDeatils[item.type]?.color,
                color: "gray",
              }}
            >
              {typeDeatils[item.type]?.name}
            </span>
          )
          item.time = moment(item.time, "HHmmss").format("hh:mm a")

          item.content =
            item?.content === null ? (
              <div
                className="p-1 cursor-pointer"
                style={{ color: "#5b73e8", textAlign: "center" }}
                onClick={() => handleView(item)}
              >
                view
              </div>
            ) : (
              <Tooltip placement="top" arrow title={item?.content}>
                {item.content?.slice(0, 50)} {item.content.length > 50 && "..."}
              </Tooltip>
            )
          return item
        })
        setActivity(data)
      })
      .catch(err => {
        console.log(err.response.data.message)
      })
  }, [master, type, page, limit])

  const handleView = item => {
    const id = item._id

    get(`message/log/${id}`).then(res => {
      setBody(res?.data?.content || null)
    })
  }

  function reset() {
    setMaster({
      from_date: getFirstday(),
      to_date: getDate(),
    })
    setType({ value: 0, label: "All" })
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Time", field: "time" },
      { label: "Type", field: "type" },
      { label: "Send To", field: "to" },
      { label: "Name", field: "name" },
      { label: "Reg Id", field: "regId" },
      { label: "Mobile", field: "mobile" },
      { label: "Email", field: "email" },
      { label: "Subject", field: "head" },
      { label: "Content", field: "content" },
    ],
    rows: activity,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Home" breadcrumbItem="Message Log" />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">From</Label>
                          <input
                            className="form-control"
                            type="date"
                            id="from_date"
                            name="from_date"
                            value={master.from_date}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">To</Label>

                          <input
                            className="form-control"
                            type="date"
                            id="to_date"
                            name="to_date"
                            value={master.to_date}
                            min={master.from_date}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Type</Label>
                          <Select
                            value={type}
                            onChange={selected => {
                              setType(selected)
                            }}
                            options={typeOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="1" style={{ paddingTop: "4px" }}>
                        <div className="mt-4 mb-3">
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <DataTable
                    data={data}
                    count={count}
                    page={page}
                    limit={limit}
                    onPageChange={e => setPage(e)}
                    onLimitChange={e => setLimit(e)}
                    id="messageLogId"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {body !== null && (
        <Modal size="lg" isOpen={body !== null} toggle={() => setBody(null)}>
          <div className="modal-header">
            <button
              onClick={() => {
                setBody(null)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div
              className="img-fluid"
              dangerouslySetInnerHTML={{ __html: body }}
            ></div>
            {/* <CardImg className="img-fluid" src={`${API_URL}${preview}`} alt="" /> */}
          </div>
        </Modal>
      )}
    </React.Fragment>
  )
}

export default SMSLogs
