import React, { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import axios from "axios"
import toastr from "toastr"
import Swal from "sweetalert2"
import { Row, Col, Card, CardBody, Label, Button } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { MDBDataTable } from "mdbreact"

import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { API_URL } from "../../../../config"
import { del, get, post, put } from "../../../../helpers/api_helper"

import "./style.scss"
import { handleImageValidation, setTitle } from "../../../../helpers/functions"

const SkillLoans = ({ options }) => {
  setTitle("Create Skill Loan")
  const navigate = useNavigate()
  const formRef = useRef()

  const initialState = {
    name: "",
    file: "",
    logo: "",
  }

  const [tableData, setTableData] = useState([])
  const [masterObject, setMasterObject] = useState(initialState)

  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [])

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Provider Name",
        field: "name",
        width: 150,
      },
      {
        label: "Logo ",
        field: "logoImage",
        width: 150,
      },
      {
        label: "File ",
        field: "pdf",
        width: 150,
      },
      {
        label: "Status",
        field: "statusList",
        width: 150,
      },
      {
        label: "Actions",
        field: "options",
        sort: "desc",
        width: 400,
      },
    ],
    rows: tableData,
  }
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  const handleTableData = async () => {
    get("/skill-loan/list").then(res => {
      const result = res.data
      result.map((item, index) => {
        item.id = index + 1

        if (item.upDate) item.date = moment(item.upDate).format("DD-MM-YYYY")
        else item.date = moment(item.date).format("DD-MM-YYYY")

        if (item.logo) {
          item.logoImage = (
            <img
              src={`${API_URL}${item.logo}`}
              style={{ width: "80px", height: "50px" }}
              alt="logo"
            ></img>
          )
        }
        if (item.document) {
          item.pdf = (
            <a
              href={`${API_URL}${item.document}`}
              target="_blank"
              rel="noreferrer"
            >
              <i
                className="fas fa-file-pdf"
                style={{
                  cursor: "pointer",
                  fontSize: "1.5em",
                  color: "red",
                  marginRight: "0.5rem",
                  marginLeft: "0.5rem",
                }}
              ></i>
            </a>
          )
        }
        item.options = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              onClick={() => {
                navigate(`/skills-loans/${item.uniqueId}`)
              }}
              style={{ cursor: "pointer" }}
              className="fas fa-eye"
            ></i>{" "}
            {options.edit ? (
              <>
                <i
                  className="far fa-edit"
                  onClick={() => handleEdit(item)}
                  style={{
                    cursor: "pointer",
                    fontSize: "1em",
                    marginRight: "0.5rem",
                    marginLeft: "0.5rem",
                  }}
                ></i>
                <i
                  className="far fa-trash-alt"
                  onClick={() => handleDelete(item._id)}
                  style={{ cursor: "pointer", fontSize: "1em" }}
                ></i>
              </>
            ) : (
              ""
            )}
          </div>
        )
        if (item.status === 0) {
          item.statusList = (
            <Button
              className="btn btn-sm"
              color="success"
              onClick={() => handleStatusChange(item._id, item.status)}
            >
              Active
            </Button>
          )
        } else {
          item.statusList = (
            <Button
              className="btn btn-sm"
              color="warning"
              onClick={() => handleStatusChange(item._id, item.status)}
            >
              Inactive
            </Button>
          )
        }
        return item
      })

      setTableData(result)
    })
  }

  const handleValueChange = async (value, name) => {
    const obj = { ...masterObject }
    obj[name] = value
    setMasterObject(obj)
  }

  const handleFileUpload = e => {
    const selectedFile = e.target.files[0]

    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "application/pdf",
      "text/plain",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ]

    if (!allowedTypes.includes(selectedFile.type)) {
      toastr.error(
        "Please select a valid file type (JPEG, PNG, PDF, TXT, DOC, DOCX, XLS, XLSX)"
      )
      return
    }
    const fd = new FormData()
    fd.append("file", e.target.files[0])
    axios.post(`${API_URL}skill-loan/image/file`, fd).then(response => {
      if (response.data.status === 200) {
        handleValueChange(response.data.data.new_filename, "file")
      } else {
        toastr.error(response.data.message)
      }
    })
  }

  const handleLogoUpload = async e => {
    const selectedFile = e.target.files[0]
    if (selectedFile) {
      try {
        await handleImageValidation(selectedFile, 300, 150)

        const fd = new FormData()
        fd.append("logo", e.target.files[0])
        axios.post(`${API_URL}skill-loan/image/logo`, fd).then(response => {
          if (response.data.status === 200) {
            handleValueChange(response.data.data.new_filename, "logo")
          } else {
            toastr.error(response.data.message)
          }
        })
      } catch (error) {
        toastr.error(error)
        e.target.value = ""
      }
    }
  }

  const handleSubmit = () => {
    if (masterObject.id) {
      put("/skill-loan/update", masterObject)
        .then(res => {
          reset()
          toastr.success(res.message)
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    } else {
      post("/skill-loan/add", masterObject)
        .then(res => {
          reset()

          toastr.success(res.message)
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    }
  }
  const handleEdit = item => {
    setMasterObject({
      id: item._id,
      name: item.name,
      logo: item.logo,
      file: item.document,
    })
  }

  const handleDelete = id => {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`/skill-loan/delete?id=${id}`)
          .then(res => {
            toastr.success(res.message)
            handleTableData()
          })
          .catch(err => {
            toastr.success(err.response.data.message)
          })
      }
    })
  }

  const handleStatusChange = async (id, status) => {
    await put("/skill-loan/status", { id, status }).then(res => {
      toastr.success(res.message)
      reset()
    })
  }

  const reset = () => {
    formRef.current.reset()
    handleTableData()
    setMasterObject(initialState)
    toTop()
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Skill Loan" />
          {options.edit ? (
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm onValidSubmit={handleSubmit} ref={formRef}>
                      <Row>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>SKill Loan Provider</Label>
                            <AvField
                              name="name"
                              className="form-control"
                              type="text"
                              value={masterObject.name}
                              onChange={e => {
                                handleValueChange(e.target.value, "name")
                              }}
                              validate={{ required: { value: true } }}
                              placeholder="SKill Loan Provider"
                              errorMessage="Enter Name"
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label>
                              Logo{" "}
                              <span style={{ color: "#0d0d0d94" }}>
                                (300x150)
                              </span>
                            </Label>
                            {masterObject?.logo ? (
                              <div div className="img-wraps d-flex flex-column">
                                <img
                                  alt=""
                                  width="150"
                                  height="75"
                                  src={`${API_URL}${masterObject.logo}`}
                                />
                                <button
                                  className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                  onClick={() => {
                                    setMasterObject(pre => ({
                                      ...pre,
                                      logo: null,
                                    }))
                                  }}
                                  style={{ width: "150px" }}
                                  type="button"
                                >
                                  Delete
                                </button>
                              </div>
                            ) : (
                              <AvField
                                name="logo"
                                className="form-control"
                                type="file"
                                onChange={handleLogoUpload}
                              />
                            )}
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>File</Label>
                            {masterObject?.file ? (
                              <div div className="img-wraps d-flex flex-column">
                                <img
                                  alt=""
                                  width="150"
                                  height="150"
                                  src={`${API_URL}${masterObject.file}`}
                                />
                                <button
                                  className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                  onClick={() => {
                                    setMasterObject(pre => ({
                                      ...pre,
                                      file: null,
                                    }))
                                  }}
                                  style={{ width: "150px" }}
                                  type="button"
                                >
                                  Delete
                                </button>
                              </div>
                            ) : (
                              <AvField
                                name="file"
                                className="form-control"
                                type="file"
                                onChange={handleFileUpload}
                              />
                            )}
                          </div>
                        </Col>
                        <Col>
                          <div className="mb-3" style={{ paddingTop: "25px" }}>
                            <Button
                              color={`${
                                masterObject.id ? "warning" : "primary"
                              }`}
                              type="submit"
                            >
                              {masterObject.id ? "Update" : "Submit"}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            ""
          )}

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="skillLoanTable"
                    bordered
                    responsive
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default SkillLoans
