import { MDBDataTable } from "mdbreact";
import moment from "moment"
import React, { useEffect, useState } from "react"
import { Row, Col } from "reactstrap";
import { get } from "../../../../helpers/api_helper";
import { API_URL } from "../../../../config";
import { Tooltip } from "@mui/material";


function Certification(props) {

  useEffect(() => {
    JobApplied()
  }, [props?.id])

  const [selectedenrolled, setselectedEnrolled] = useState([])

  function JobApplied() {
    get(`/student/student-certificate?studentId=${props?.id}`).then(res => {
        const result = res?.data
        const newResult = result.map((item, index) => ({
          ...item,
          id: index + 1,
          type: item.type === 1 ? 'Course Completion' : item.type === 2 ? 'NCVET Short-term' : item.type === 3 ? 'NCVET Long-term' : item.type === 4 ? 'TP Assessment' : '', 
          // 1 - Course Completion, 2 - NCVET Short-term, 3 - NCVET Long-term, 4 - TP Assessment
          issueDate: moment(item?.issueDate).format("DD-MM-YYYY"),
          certificate: <Tooltip arrow title = 'View'><i onClick={()=>viewCertificate(item.certificate)} style={{fontSize:'1.3rem',color:'red', cursor:'pointer'}} className="fas fa-file-pdf"></i></Tooltip>
        }))
        console.log(result, "ress")
        setselectedEnrolled(newResult)
      }).catch(error => {
        console.error("Error :", error)
      })
  }

  const viewCertificate = (path)=> {
    const openPath = `${API_URL}${path}`
    window.open(openPath,'_blank')
  }

  const certificates = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Course",
        field: "course", 
        width: 150, 
      }, 
      {
        label: "Issue Date",
        field: "issueDate",
        width: 50,
      },
      { 
        label: "Type", 
        field: "type",
        width: 50,
      },
      { 
        label: "Published By", 
        field: "addedBy",
        width: 50,
      },
      {
        label: "Certificate",
        field: "certificate",
        width: 50,
      },
    ],
    rows: selectedenrolled
  }
  return (
    <> 
      <Row>
        <Col xl="12">
          <MDBDataTable
            id="certificationTableId"
            className="certification-api"
            responsive
            bordered
            data={certificates}
            info={true}
            searching={true}
            disableRetreatAfterSorting={true}
            entries={20}
          />
        </Col>
      </Row>
    </>
  )
}

export default Certification