import PropTypes from "prop-types"
import React from "react"
import { Col, Card, CardBody } from "reactstrap"
import CountUp from "react-countup"
import { useNavigate } from "react-router-dom"

const MiniWidget = props => {
  const navigate = useNavigate()
  const handleNavigate = report => {
    if (report.for) {
      let state
      if (report.for === "follow-up") {
        state = { type: report.type, filterData: { ...report?.filterData } }
      } else {
        state = { type: report.type, ...report?.filterData }
      }

      navigate(`/${report.for}`, {
        state,
      })

      // if (report.for === "follow-up") {
      //   navigate(`/${report.for}`, {
      //     state: { type: report.type, filterData: { ...report?.filterData } },
      //   })
      // } else {
      //   let state
      //   if (report.type) {
      //     state = {
      //       type: report.type,
      //       from_date: report?.from
      //         ? report.from
      //         : moment().format("YYYY-MM-DD"),
      //       to_date: report?.to ? report.to : moment().format("YYYY-MM-DD"),
      //     }
      //   } else {
      //     state = {
      //       from_date: moment().format("YYYY-MM-DD"),
      //       to_date: moment().format("YYYY-MM-DD"),
      //     }
      //   }

      //   navigate(`/${report.for}`, {
      //     state,
      //   })
      // }
    }
  }

  return (
    <React.Fragment>
      {props.reports.map((report, key) => (
        <Col md={4} key={key}>
          <Card>
            <CardBody
              onClick={() => handleNavigate(report)}
              className="cursor-pointer"
            >
              <div className="float-end mt-2">
                {/* <ReactApexChart
                  options={report.options}
                  series={report.series}
                  type={report.charttype}
                  height={report.chartheight}
                  width={report.chartwidth}
                /> */}
                {/* <i
                  style={{
                    marginTop: "2px",
                    fontSize: "30px",
                    marginRight: "3rem",
                  }}
                > */}
                {report?.icon}
                {/* </i> */}
              </div>
              <div>
                <h4 className="mb-1 mt-1">
                  <span>
                    <CountUp
                      end={report.value}
                      separator=","
                      prefix={report.prefix}
                      suffix={report.suffix}
                      decimals={report.decimal}
                    />
                  </span>
                </h4>
                <p className="text-muted mb-0">{report.title}</p>
              </div>
              <p
                onClick={e => {
                  if (report?.badgeNavigate) {
                    e.stopPropagation()
                    navigate(`/${report.for}`, {
                      state: {
                        from_date: report?.from,
                        to_date: report?.to,
                      },
                    })
                  }
                }}
                className="text-muted mt-3 mb-0"
              >
                <span className={"text-" + report.color + " me-1"}>
                  {/* <i className={report.icon + " me-1"}></i> */}
                  {report.badgeValue}
                </span>{" "}
                {report.desc}
              </p>
            </CardBody>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  )
}

export default MiniWidget

MiniWidget.propTypes = {
  reports: PropTypes.array,
}
