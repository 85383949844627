import React, { useEffect, useRef, useState } from 'react'
import { AvField, AvForm } from "availity-reactstrap-validation";
import Breadcrumbs from '../../../../components/Common/Breadcrumb'
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { MDBDataTable } from 'mdbreact';
import "./style.scss"
import { get, post, del, put } from '../../../../helpers/api_helper';
import moment from 'moment';
import toastr from 'toastr';
import Swal from 'sweetalert2';


function EventCategory() {

    const [masterObj, setmasterObj] = useState({})
    const formRef = useRef()
    const [tableData, setTableData] = useState([])
    const [categoryIdToBeUpdated, setCategoryIdToBeUpdated] = useState(null)


    useEffect(() => {
        handleTableData()

        // eslint-disable-next-line
    }, []);


    function handleTableData() {
        get(`/events-category/list`)
            .then(res => {
                let result = res.data
                let eventData = []
                result?.map((item, indx) => {
                    item.id = indx + 1
                    item.date = moment(item?.date).format("DD-MM-YYYY")
                    item.time = moment(item?.time, "hh:mm a").format("hh:mm a");

                    item.options = (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <i
                                className="far fa-edit"
                                style={{
                                    fontSize: "1em",
                                    cursor: "pointer",
                                    marginLeft: "0.5rem",
                                    marginRight: "0.5rem",
                                }}
                                onClick={() => {
                                    toTop()
                                    handleUpdate(item)
                                    setCategoryIdToBeUpdated(item?._id)
                                }}
                            ></i>
                            <i
                                className="far fa-trash-alt"
                                style={{ fontSize: "1em", cursor: "pointer" }}
                                onClick={() => {
                                    handleDelete(item?._id)
                                }}
                            ></i>
                        </div>
                    )

                    eventData.push(item)
                    return item
                })
                setTableData(eventData)
            })
    }

    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                width: 150,
            },
            {
                label: "Date",
                field: "date",
                width: 150,
            },
           
            {
                label: "Category",
                field: "name",
                width: 150,
            },
            {
                label: "Staff",
                field: "staff",
                width: 150,
            },
            {
                label: "Action",
                field: "options",
                sort: "desc",
                width: 400,
            },
        ],
        rows: tableData,
    };

    function handleSubmit() {
        if (categoryIdToBeUpdated) {
            put(`/events-category/edit/`, masterObj)
                .then(res => {
                    toastr.success(res.message)
                    reset()
                    setCategoryIdToBeUpdated(null)
                })
                .catch(err => {
                    toastr.error(err.response.data.message)
                    return
                })
        } else {
            post(`events-category/add`, masterObj)
                .then(res => {
                    toastr.success(res.message)
                    reset()
                })
                .catch(err => {
                    toastr.error(err.response.data.message)
                    return
                })
        }
    }

    function handleChange(e) {
        let { name, value } = e.target
        setmasterObj({ ...masterObj, [name]: value })
    }

    const toTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        })
    }

    function handleUpdate(item) {
        setmasterObj({ _id: item._id, name: item?.name })
    }

    function handleDelete(id) {
        return Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(result => {
            if (result.isConfirmed) {
                del(`/events-category/${id}`)
                    .then(res => {
                        toastr.success(res.message)
                        handleTableData()
                        setmasterObj({
                            ...masterObj, name: ""
                        })
                        setCategoryIdToBeUpdated(null)
                    })
                    .catch(err => {
                        toastr.success(err.response.data.message)
                    })
            }
        })
    }

    function reset() {
        setmasterObj({
            ...masterObj,
            name: ""
        })
        formRef.current.reset()
        handleTableData()
    }


    return (
        <>
            <div className='page-content'>
                <div className='container-fluid'>
                    <Breadcrumbs title="Home" breadcrumbItem="Event Category" />

                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <AvForm className="needs-validation" ref={formRef}
                                        onValidSubmit={(e, v) => {
                                            handleSubmit(e, v)
                                        }}
                                    >
                                        <Row>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Category</Label>
                                                    <AvField
                                                        name="name"
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="category"
                                                        errorMessage="Enter Category"
                                                        validate={{ required: { value: true } }}
                                                        value={masterObj?.name}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </Col>

                                            <Col>
                                                <div className="mb-3" style={{ paddingTop: "25px" }}>
                                                    <Button
                                                        color={categoryIdToBeUpdated ? "warning" : "primary"}
                                                        className="me-2"
                                                        type="submit"
                                                    >
                                                        {categoryIdToBeUpdated ? "Update" : "Submit"}
                                                    </Button>
                                                </div>
                                            </Col>

                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>


                    <Row>
                        <Col className='col-12'>
                            <Card>
                                <CardBody>
                                    <MDBDataTable
                                        id="eventCategodyTableId"
                                        bordered
                                        responsive
                                        searching={true}
                                        entries={20}
                                        disableRetreatAfterSorting={true}
                                        data={data}
                                    >
                                    </MDBDataTable>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </div>
        </>
    )
}

export default EventCategory;