import React from "react"
import { Pie } from "react-chartjs-2"

const PieChart = ({ completed, pending }) => {
  const data = {
    labels: ["Completed", "Pending"],
    datasets: [
      {
        data: [pending, completed - pending],
        backgroundColor: ["#5b73e8", "#ebeff2"],
        hoverBackgroundColor: ["#5b73e8", "#ebeff2"],
        hoverBorderColor: "#fff",
      },
    ],
  }

  const options = {
    legend: {
      display: false,
    },
  }

  return <Pie width={1200} height={900} options={options} data={data} />
}

export default PieChart
