import React, { useEffect, useRef } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { useState } from "react"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { get } from "../../../../helpers/api_helper"
import toastr from "toastr"
import {
  dateConverter,
  numberToCurrency,
  setTitle,
} from "../../../../helpers/functions"
import { useLocation } from "react-router-dom"
import TablePagination from "../../../../components/Common/Pagination"

import "./styles.scss"

const ExpenseReport = () => {
  setTitle("Expense Report")
  const { state } = useLocation()

  const formRef = useRef()
  const [tableData, setTableData] = useState([])
  const [masterObject, setMasterObject] = useState({
    branch: state?.branch || "",
  })
  const [selectedFields, setSelectedFields] = useState({
    branch: state?.branch
      ? { label: state.branchName, value: state.branch }
      : "",
  })

  const [branchOptions, setBranchOptions] = useState([])
  const [typeOptions, setTypeOptions] = useState([])
  const [accountOptions, setAccountOptions] = useState([])
  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 20

  useEffect(() => {
    handleBranchOptions()
    handleTypeOptions()
    handleAccountOptions()
    // eslint-disable-next-line
  }, [masterObject])

  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [masterObject])

  const handleTableData = async (perPage = page) => {
    try {
      const query = `income-expense/expense/list?page=${perPage}&limit=${limit}&branch=${masterObject.branch}&subType=${masterObject.subType}&account=${masterObject.account}&from=${masterObject.from}&to=${masterObject.to}`
      const response = await get(query)

      response.data.map((item, index) => {
        item.id = index + 1 + (perPage - 1) * limit

        item.date = dateConverter(item.date)
        item.addedDate = dateConverter(item.addedDate)

        item.amount = numberToCurrency(item.amount)

        item.staff = `${item.staffFirstName || ""} ${item.staffLastName || ""}`

        return item
      })
      const count = response.count
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(response.data)
    } catch (error) {}
  }

  const handleBranchOptions = async () => {
    try {
      const query = `income-expense/options/expense/branch?subType=${masterObject.subType}&account=${masterObject.account}&from=${masterObject.from}&to=${masterObject.to}`
      const response = await get(query)
      setBranchOptions(response.data)
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }
  const handleTypeOptions = async () => {
    try {
      const query = `income-expense/options/expense/type?branch=${masterObject.branch}&account=${masterObject.account}&from=${masterObject.from}&to=${masterObject.to}`
      const response = await get(query)
      setTypeOptions(response.data)
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }
  const handleAccountOptions = async () => {
    try {
      const query = `income-expense/options/expense/account?branch=${masterObject.branch}&subType=${masterObject.subType}&from=${masterObject.from}&to=${masterObject.to}`
      const response = await get(query)
      setAccountOptions(response.data)
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }

  const handleSelectValueChange = (selected, name) => {
    const obj = { ...selectedFields }
    obj[name] = selected
    setSelectedFields(obj)

    handleValueChange(selected.value, name)
  }
  const handleValueChange = (value, name) => {
    const obj = { ...masterObject }
    obj[name] = value
    setMasterObject(obj)
    setPage(1)
  }
  const handleChange = (event, value) => {
    setPage(value)
    handleTableData(value)
  }
  const reset = () => {
    setMasterObject({})
    setSelectedFields({})
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Added Date",
        field: "addedDate",
        width: 150,
      },
      {
        label: "Branch",
        field: "branch",
        width: 150,
      },
      {
        label: "Sub Head",
        field: "subType",
        width: 150,
      },
      {
        label: "Chart of Account",
        field: "chartOfAccount",
        width: 150,
      },
      {
        label: "Fund Source",
        field: "fundSource",
        width: 150,
      },
      {
        label: "Amount",
        field: "amount",
        width: 150,
      },
      {
        label: "Comments",
        field: "comment",
        width: 150,
      },
      {
        label: "Added by",
        field: "staff",
        width: 150,
      },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Home" breadcrumbItem="Expense Report" />

        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <AvForm ref={formRef}>
                  <Row>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>From</Label>
                        <AvField
                          name="from"
                          className="form-control"
                          type="date"
                          value={masterObject.from || ""}
                          onChange={e =>
                            handleValueChange(e.target.value, e.target.name)
                          }
                        />
                      </div>
                    </Col>

                    <Col md="2">
                      <div className="mb-3">
                        <Label>To</Label>
                        <AvField
                          name="to"
                          className="form-control"
                          type="date"
                          value={masterObject.to || ""}
                          onChange={e =>
                            handleValueChange(e.target.value, e.target.name)
                          }
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Branch</Label>
                        <Select
                          name="Branch"
                          type="text"
                          placeholder="Select..."
                          errorMessage="Enter Category"
                          options={branchOptions}
                          value={selectedFields.branch || ""}
                          onChange={selected => {
                            handleSelectValueChange(selected, "branch")
                          }}
                        />
                      </div>
                    </Col>

                    <Col md="2">
                      <div className="mb-3">
                        <Label>Account Sub Head</Label>
                        <Select
                          name="type"
                          type="text"
                          placeholder="Select..."
                          options={typeOptions}
                          value={selectedFields.subType || ""}
                          onChange={selected => {
                            handleSelectValueChange(selected, "subType")
                          }}
                        />
                      </div>
                    </Col>

                    <Col md="2">
                      <div className="mb-3">
                        <Label>Charts of Account</Label>
                        <Select
                          name="account"
                          type="text"
                          placeholder="Select..."
                          errorMessage="Enter Category"
                          options={accountOptions}
                          value={selectedFields.account || ""}
                          onChange={selected => {
                            handleSelectValueChange(selected, "account")
                          }}
                        />
                      </div>
                    </Col>

                    <Col>
                      <div
                        className="mb-3 d-flex gap-2"
                        style={{ paddingTop: "25px" }}
                      >
                        <Button color="danger" type="button" onClick={reset}>
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <MDBDataTable
                  id="expenseReport"
                  bordered
                  responsive
                  data={data}
                  disableRetreatAfterSorting={true}
                  info={false}
                  sortable={false}
                  searching={false}
                  paging={false}
                ></MDBDataTable>
                <TablePagination
                  page={page}
                  onChange={handleChange}
                  count={totalPage}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ExpenseReport
