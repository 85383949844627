import React, { useEffect, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
// import "./Lead.scss"
import { useSelector } from "react-redux"
import {
  getCourses,
  getEvents,
  getUsersOptions,
} from "../../../../store/options/actions"
import useBranch from "../../../../hooks/useBranch"
import { useDispatch } from "react-redux"
import { PRIVILEGES } from "../../../../config"
import { isArray } from "lodash"
import moment from "moment"
import { getLeadFor } from "../Add-Lead/backendHelper"
import queryString from "query-string"
import { get } from "../../../../helpers/api_helper"

import toastr from "toastr"
import { jsonToExcel } from "../../../../helpers/functions"
import MuiCustomTablePagination from "../../../../components/Common/TablePagination"

const TrashedLead = () => {
  const [count, setCount] = useState(0)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(20)

  const [search, setSearch] = useState({})

  const [searchSelect, setSearchSelect] = useState({})

  const [exports, setExports] = useState([])

  const [rows, setRows] = useState([])

  const OPTIONS = useSelector(state => state.Options)

  const [branch] = useBranch()
  const dispatch = useDispatch()

  const [leadFor, setLeadFor] = useState([])

  useEffect(() => {
    getLeadFor().then(res => {
      setLeadFor(res.data)
    })
  }, [])

  useEffect(() => {
    dispatch(getCourses())
    dispatch(getEvents())
  }, [dispatch])

  useEffect(() => {
    dispatch(
      getUsersOptions({
        branch: branch?.value || "",
        privilege: PRIVILEGES.INTERN,
      })
    )
  }, [dispatch, branch])

  useEffect(() => {
    fetchLeads(search, page, rowsPerPage)
    // eslint-disable-next-line
  }, [search, page, rowsPerPage])

  const fetchLeads = (query = {}, page, limit) => {
    get(
      `leads/trashed?${queryString.stringify(
        query
      )}&page=${page}&limit=${limit}`
    ).then(res => {
      const data = res.data

      const exportData = []

      data.map((item, idx) => {
        item.id = idx + 1

        item.name = `${item?.reg_id?.firstname || ""} ${
          item?.reg_id?.lastname || ""
        }`

        item.date = item.date && moment(item.date).format("DD-MM-YYYY")

        item.assign = `${item?.assigned?.firstName || ""} ${
          item?.assigned?.lastName || ""
        }`

        item.mobile = item?.reg_id?.mobile
        item.course = item?.course?.name || item?.event?.name
        item.source = item?.source?.name
        item.remarks = item?.message

        delete item.message

        const dataExport = {}

        dataExport.Date = item.date
        dataExport.Name = item.name
        dataExport.Mobile = item.mobile
        dataExport.Course = item.course
        dataExport.Source = item.source
        dataExport.Assigned = item.assign

        exportData.push(dataExport)

        return item
      })

      setCount(res.totalCount)
      setRows(data)
      setExports(exportData)
    })
  }

  const handleSearchChange = e => {
    let { name, value } = e.target
    setSearch(pre => ({ ...pre, [name]: value }))
  }

  const handleSearchSelect = (e, name) => {
    const value = e ? (isArray(e) ? e.map(item => item.value) : e.value) : null
    setSearchSelect(pre => ({ ...pre, [name]: e }))
    setSearch(pre => ({ ...pre, [name]: value }))
  }

  const handleExport = () => {
    if (exports.length > 0) {
      jsonToExcel(exports, `leads-${moment().format("DD-MM-YY")}`)
    } else {
      toastr.info(`There are no leads to export`)
    }
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Type",
        field: "type",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        width: 150,
      },
      {
        label: "Mobile",
        field: "mobile",
        width: 150,
      },
      {
        label: "Course/Event",
        field: "course",
        width: 150,
      },
      {
        label: "Source",
        field: "source",
        width: 150,
      },
      {
        label: "Assigned",
        field: "assign",
        width: 150,
      },
    ],
    rows,
  }

  const handleChangePage = newPage => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
  }
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Trashed Leads" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label> From</Label>
                          <AvField
                            name="from"
                            value={search.from || ""}
                            onChange={handleSearchChange}
                            max={moment().format("YYYY-MM-DD")}
                            className="form-control"
                            type="date"
                            placeholder="From"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>To</Label>
                          <AvField
                            name="to"
                            value={search.to || ""}
                            max={moment().format("YYYY-MM-DD")}
                            min={search.from_date}
                            onChange={handleSearchChange}
                            className="form-control"
                            type="date"
                            placeholder="To"
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Course</Label>
                          <Select
                            onChange={e => {
                              handleSearchSelect(e, "course")
                            }}
                            value={searchSelect.course || null}
                            options={OPTIONS.courses}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Source</Label>
                          <Select
                            onChange={e => {
                              handleSearchSelect(e, "source")
                            }}
                            value={searchSelect.source || null}
                            options={leadFor}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Staff</Label>
                          <Select
                            options={OPTIONS.users}
                            onChange={e => {
                              handleSearchSelect(e, "user")
                            }}
                            value={searchSelect.user || null}
                          />
                        </div>
                      </Col>

                      <Col className="mt-4 pt-1">
                        <div className="mb-3">
                          <Button
                            color="danger"
                            type="button"
                            onClick={() => {
                              setSearch({})
                              setSearchSelect({})
                            }}
                          >
                            Reset
                          </Button>
                          <Button
                            style={{ marginLeft: "14px" }}
                            color="warning"
                            type="button"
                            onClick={handleExport}
                          >
                            Export
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                  <MDBDataTable
                    id="Managetableid1"
                    bordered
                    responsive
                    searching={false}
                    displayEntries={false}
                    paging={false}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>
                  <MuiCustomTablePagination
                    count={count}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default TrashedLead
