import React, { useEffect, useRef, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap"
import Select from "react-select"
import { MDBDataTable } from "mdbreact"
import { get } from "../../../../helpers/api_helper.js"
import "./style.scss"
import moment from "moment"
import {
  calculateGST,
  jsonToExcel,
  setTitle,
} from "../../../../helpers/functions"
import toastr from "toastr"

// import { Modal } from "react-bootstrap"

import TablePagination from "../../../../components/Common/Pagination"
import Tooltip from "@mui/material/Tooltip"
import { API_URL } from "../../../../config.js"
import PaymentDetails from "../PaymentDetails/index.jsx"
import SearchStudent from "../../../../components/Common/SearchStudents.jsx"

import queryString from "query-string"

const FeesPayments = ({ options }) => {
  setTitle("Fee Payments")

  const formRef = useRef()
  const initialState = {
    from: "",
    to: "",
    student: "",
    course: "",
    batch: "",
    type: "",
  }

  const toggle = (start = true) => {
    var body = document.body

    if (start) {
      body.classList.remove("vertical-collpsed")
      // body.classList.remove("sidebar-enable")
      body.setAttribute("data-sidebar-size", "sm")
    } else {
      body.classList.add("vertical-collpsed")
      body.classList.add("sidebar-enable")
      body.setAttribute("data-sidebar-size", "lg")
    }
  }

  useEffect(() => {
    toggle()

    return () => {
      toggle(false)
    }
    // eslint-disable-next-line
  }, [])

  const [modal, setModal] = useState(false)

  const [selectedId, setSelectedId] = useState({})

  const [tableData, setTableData] = useState([])
  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const limit = 20

  const [filterObject, setFilterObject] = useState(initialState)
  const [selectedFields, setSelectedFields] = useState({})

  const [courseOrEvent, setCourseOrEvent] = useState([])
  const [batches, setBatches] = useState([])
  const [branches, setBranches] = useState([])
  const forOptions = [
    { label: "Course", value: 0 },
    { label: "Event", value: 2 },
  ]
  const typeOptions = [
    { label: "Cash", value: 1 },
    { label: "Skill loan", value: 2 },
    { label: "Scholarship", value: 3 },
    { label: "Online", value: 4 },
  ]

  useEffect(() => {
    handleBatchOptions()
    handleBranchOptions()
    setPage(1)

    // eslint-disable-next-line
  }, [filterObject])

  const handleCourseOptions = async (obj = filterObject) => {
    const query = `?${queryString.stringify(obj)}`

    get(`/fee/options/course${query}`).then(res => {
      setCourseOrEvent(res.data)
    })
  }
  const handleEventOptions = async (obj = filterObject) => {
    const query = `?${queryString.stringify(obj)}`

    get(`/fee/options/event${query}`).then(res => {
      setCourseOrEvent(res.data)
    })
  }

  const handleBatchOptions = async (obj = filterObject) => {
    const query = `?${queryString.stringify(obj)}`

    get(`/fee/options/batch${query}`).then(res => {
      setBatches(res.data)
    })
  }
  const handleBranchOptions = async (obj = filterObject) => {
    const query = `?${queryString.stringify(obj)}`

    get(`/fee/options/branch${query}`).then(res => {
      setBranches(res.data)
    })
  }

  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [filterObject])

  const handleTableData = (perPage = page) => {
    const query = `page=${perPage}&limit=${limit}&${queryString.stringify(
      filterObject
    )}`

    get(`/fee/list?${query}`).then(res => {
      const result = res.data
      const count = res.count
      result?.map((item, index) => {
        item.id = index + 1 + (perPage - 1) * limit
        item.time = moment(item.time, "HH:mm:ss").format("hh:mm a")
        item.date = moment(item.date).format("DD-MM-YYYY")
        item.student =
          (item.studentFirstName ? item.studentFirstName : "") +
          " " +
          (item?.studentLastName ? item.studentLastName : "")
        const paymentType = item.paymentFor === 0 ? "Course" : "Event"
        item.for = paymentType
        item.fee = item.fee?.toLocaleString("en-IN") || 0
        item.paid = item.paid?.toLocaleString("en-IN") || 0
        item.due = item.due?.toLocaleString("en-IN") || 0
        item.status1 = item.status
        item.status = (
          <Button
            style={{ width: "100%" }}
            color={
              item.status === 0
                ? "success"
                : item.status === 1
                ? "danger"
                : "warning"
            }
            disabled
          >
            {item.status === 0
              ? "Success"
              : item.status === 1
              ? "Failed"
              : "Pending"}
          </Button>
        )

        item.options = (
          <div>
            {/* {options.edit ? ( */}
            <i
              className="fas fa-eye"
              onClick={() => {
                handlePopUp(item._id)
              }}
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
            ></i>
            {/* ) : (
              ""
            )} */}
            {item.receiptPath ? (
              <Tooltip title="Receipt" arrow placement="top">
                <a
                  href={API_URL + item.receiptPath}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <i
                    className="uil-file-download"
                    style={{ fontSize: "1.5em", cursor: "pointer" }}
                  ></i>
                </a>
              </Tooltip>
            ) : (
              ""
            )}
          </div>
        )
        return item
      })

      const totalPage = Math.ceil(Number(count) / limit)
      setCount(Number(count))
      setTotalPage(totalPage)
      setTableData(result)
    })
  }

  const handleExport = async limit => {
    const query = `limit=${limit}&${queryString.stringify(filterObject)}`

    await get(`/fee/list?${query}`).then(res => {
      const result = res.data
      const exportData = []

      if (result.length > 0) {
        result?.map((item, index) => {
          item.id = index + 1 + (page - 1) * limit
          item.time = moment(item.time, "HH:mm:ss").format("hh:mm a")
          item.date = moment(item.date).format("DD-MM-YYYY")
          item.student =
            (item.studentFirstName ? item.studentFirstName : "") +
            " " +
            (item?.studentLastName ? item.studentLastName : "")

          item.type =
            item.type === 1
              ? "Cash"
              : item.type === 2
              ? "Skill loan"
              : item.type === 3
              ? "Scholarship"
              : item.type === 4
              ? "Online"
              : ""

          const paymentType = item.paymentFor === 0 ? "Course" : "Event"
          item.paymentType = paymentType
          const { taxableValue, taxAmount } = calculateGST(
            item.paid || 0,
            item.gst || 0
          )
          const dataExport = {}
          dataExport.id = item.id
          dataExport.Time = item.time
          dataExport.Date = item.date
          dataExport.StudentID = item.studentId
          dataExport.Student = item.student
          dataExport.Center = item.csp
          dataExport.For = paymentType
          dataExport["Course/Event"] = item.name
          dataExport.Fee = item.fee
          dataExport["Tax Amount"] = taxAmount.toFixed(2)
          dataExport["Taxable Value"] = taxableValue.toFixed(2)
          dataExport.Paid = item.paid
          dataExport.Due = item.due
          dataExport.Type = item.type
          dataExport.Status =
            item.status === 0
              ? "Success"
              : item.status === 1
              ? "Failed"
              : "Pending"

          dataExport["Payment Id"] = item.paymentId
          dataExport["Order Id"] = item.orderId
          exportData.push(dataExport)

          return item
        })
        jsonToExcel(exportData, `Fees-${moment().format("DD-MM-YY")}`)
      } else {
        toastr.info(`There are no fees to export`)
      }
    })
  }

  const handleSelectChange = (selected, name) => {
    const obj = { ...selectedFields }
    obj[name] = selected
    setSelectedFields(obj)

    const value = selected
      ? Array.isArray(selected)
        ? selected.map(item => item.value)
        : selected.value
      : null
    handleValueChange(value, name)
  }

  const handleValueChange = async (value, name) => {
    const obj = { ...filterObject }
    obj[name] = value
    if (name === "for") {
      if (value === 0) {
        handleCourseOptions(obj)
      } else {
        handleEventOptions(obj)
      }
    }
    setFilterObject(obj)
  }

  const handleChange = (event, value) => {
    setPage(value)
    handleTableData(value)
  }

  const handlePopUp = id => {
    setModal(true)
    setSelectedId(id)
  }

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }
  const reset = () => {
    formRef.current.reset()
    setFilterObject(initialState)
    toTop()
    setCourseOrEvent([])
    setSelectedFields({})

    // handleBatchOptions()
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        width: 150,
      },
      {
        label: "Receipt No",
        field: "uniqueId",
        width: 150,
      },
      {
        label: "Center",
        field: "csp",
        width: 150,
      },
      {
        label: "Student",
        field: "student",
        width: 150,
      },
      {
        label: "Register No",
        field: "studentId",
        width: 150,
      },

      {
        label: "For",
        field: "for",
        width: 150,
      },

      {
        label: "Course/Event",
        field: "name",
        width: 150,
      },
      {
        label: "Fee",
        field: "fee",
        width: 150,
      },
      {
        label: "Paid",
        field: "paid",
        width: 150,
      },
      {
        label: "Due",
        field: "due",
        width: 150,
      },

      {
        label: "Status",
        field: "status",
        width: 150,
      },
      {
        label: "Actions",
        field: "options",
        sort: "desc",
        width: 400,
      },
    ],
    rows: tableData,
  }

  return (
    <>
      <Modal
        show={modal}
        isOpen={modal}
        toggle={() => setModal(!modal)}
        size="lg"
        centered={true}
        className="lead-modal"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Payment Details</h5>
          <button
            type="button"
            onClick={() => {
              setModal(false)
              // handleTableData()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <PaymentDetails id={selectedId} />
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Fee payments" />
          <Card>
            <CardBody>
              <AvForm ref={formRef}>
                <Row>
                  <Col md={2}>
                    <div className="mb-3">
                      <Label>From </Label>
                      <AvField
                        name="from"
                        className="form-control"
                        type="date"
                        value={filterObject.from}
                        onChange={e => {
                          handleValueChange(e.target.value, "from")
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="mb-3">
                      <Label>To </Label>
                      <AvField
                        name="to"
                        className="form-control"
                        type="date"
                        value={filterObject.to}
                        onChange={e => {
                          handleValueChange(e.target.value, "to")
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <Label>Student</Label>
                    <SearchStudent
                      api={`/fee/options/student`}
                      onChange={selected => {
                        handleSelectChange(selected, "student")
                      }}
                      value={selectedFields.student}
                      master={filterObject}
                    />
                  </Col>
                  <Col md={2}>
                    <div className="mb-3">
                      <Label>For</Label>
                      <Select
                        name="type"
                        classNamePrefix="select2-selection"
                        options={typeOptions}
                        value={selectedFields.type || ""}
                        menuPosition="fixed"
                        onChange={selected => {
                          handleSelectChange(selected, "type")
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="mb-3">
                      <Label>Course/Event</Label>
                      <Select
                        name="course"
                        classNamePrefix="select2-selection"
                        options={courseOrEvent}
                        value={selectedFields.course || ""}
                        menuPosition="fixed"
                        onChange={selected => {
                          handleSelectChange(selected, "course")
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={2}>
                    <div className="mb-3">
                      <Label>Batch</Label>
                      <Select
                        name="course"
                        classNamePrefix="select2-selection"
                        options={batches}
                        menuPosition="fixed"
                        value={selectedFields.batch || ""}
                        onChange={selected => {
                          handleSelectChange(selected, "batch")
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="mb-3">
                      <Label>Branch</Label>
                      <Select
                        name="course"
                        classNamePrefix="select2-selection"
                        options={branches}
                        menuPosition="fixed"
                        value={selectedFields.branch || ""}
                        onChange={selected => {
                          handleSelectChange(selected, "branch")
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="mb-3">
                      <Label>Type</Label>
                      <Select
                        name="students"
                        classNamePrefix="select2-selection"
                        options={typeOptions}
                        value={selectedFields.type || ""}
                        onChange={selected => {
                          handleSelectChange(selected, "type")
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="mb-3" style={{ marginTop: "26px" }}>
                      <Button color="danger" onClick={reset}>
                        Reset
                      </Button>
                      {options.export ? (
                        <Button
                          style={{ marginLeft: "14px" }}
                          color="warning"
                          type="button"
                          onClick={() => handleExport(count)}
                        >
                          Export
                        </Button>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                </Row>
              </AvForm>

              <MDBDataTable
                id="feePaymentsTable"
                className="mt-3"
                bordered
                responsive
                searching={false}
                entries={20}
                paging={false}
                info={false}
                sortable={false}
                disableRetreatAfterSorting={true}
                data={data}
              ></MDBDataTable>
              <TablePagination
                page={page}
                onChange={handleChange}
                count={totalPage}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  )
}

export default FeesPayments
