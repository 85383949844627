import React, { useEffect, useState } from "react"
import { Row, Col, Table } from "reactstrap"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import { Box, Divider } from "@mui/material"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import StoreIcon from "@mui/icons-material/Store"
import Event from "@mui/icons-material/Event"
import Person from "@mui/icons-material/Person"
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap"
import { MDBDataTable } from "mdbreact"
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import Members from "./Tabs/Members"
import Summary from "./Tabs/Summary"
import Events from "./Tabs/Event"
import { useLocation } from "react-router-dom"
import { get } from "../../../helpers/api_helper"
import moment from "moment"
import Rejected from "./Tabs/Rejected"

const CommunityDetails = () => {
  const [tab3, setTab3] = useState("2")
  const { state } = useLocation()

  const handleChangeTab = (event, newValue) => {
    setTab3(newValue)
  }
  const [popupView, setPopupView] = useState(false)
  const [communitylist, setCommunitylist] = useState(null)
  useEffect(() => {
    handleListData()
  }, [])

  function handleListData() {
    get(`/community/list/${state?.id}`).then(res => {

      let result = res?.data
      setCommunitylist(result)
    })
  }

  const Eventdata = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        width: 150,
      },
      {
        label: "Category",
        field: "category",
        width: 150,
      },
      {
        label: "Event Name",
        field: "event_name",
        width: 150,
      },
      {
        label: "Venue",
        field: "venue",
        sort: "desc",
        width: 400,
      },
      {
        label: "Status",
        field: "status",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        width: 150,
      },
    ],
    rows: [
      {
        id: "1",
        date: "14-10-2023",
        time: "9:30 am",
        category: "inauguration",
        event_name: (
          <>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  id="button-tooltip-2"
                  style={{ position: "absolute", top: "-30px" }}
                >
                  Datsi School for Storytellers’ Open House
                </Tooltip>
              }
            >
              <div>Datsi School for Storyteller...</div>
            </OverlayTrigger>
          </>
        ),
        venue: (
          <>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  id="button-tooltip-2"
                  style={{ position: "absolute", top: "-30px" }}
                >
                  Kinfra Film & Video Park, Sainik school PO Chanthavila,
                  Kazhakootam, Thiruvananthapuram, Kerala – 695585{" "}
                </Tooltip>
              }
            >
              <div>Kinfra Film & Video Park...</div>
            </OverlayTrigger>
          </>
        ),
        status: <h6 style={{ color: "red" }}>{"Completed"}</h6>,
        staff: "Admin",
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-qrcode"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
            ></i>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                setPopupView(true)
              }}
            ></i>
            <i
              className="fas fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ fontSize: "1em", cursor: "pointer" }}
            ></i>
          </div>
        ),
      },
      {
        id: "2",
        date: "2-12-2021",
        time: "9:00 am",
        category: "Hackathon",
        event_name: (
          <>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  id="button-tooltip-2"
                  style={{ position: "absolute", top: "-30px" }}
                >
                  The Reflections Automotive Challenge powered by Bloombloom
                </Tooltip>
              }
            >
              <div>The Reflections Automotive...</div>
            </OverlayTrigger>
          </>
        ),
        venue: "Online",
        status: <h6 style={{ color: "red" }}>{"Completed"}</h6>,
        staff: "Admin",
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-qrcode"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
            ></i>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                setPopupView(true)
              }}
            ></i>
            <i
              className="fas fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ fontSize: "1em", cursor: "pointer" }}
            ></i>
          </div>
        ),
      },
      {
        id: "3",
        date: "21-04-2022",
        time: "10:00 am",
        category: "MoU",
        event_name: (
          <>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  id="button-tooltip-2"
                  style={{ position: "absolute", top: "-30px" }}
                >
                  ASAP Kerala and AkzoNobel execute MoU
                </Tooltip>
              }
            >
              <div>ASAP Kerala and AkzoNob...</div>
            </OverlayTrigger>
          </>
        ),
        venue: "CSP THAVANUR",
        status: <h6 style={{ color: "red" }}>{"Completed"}</h6>,
        staff: "Admin",
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-qrcode"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
            ></i>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                setPopupView(true)
              }}
            ></i>
            <i
              className="fas fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ fontSize: "1em", cursor: "pointer" }}
            ></i>
          </div>
        ),
      },
      {
        id: "4",
        date: "07-11-2021",
        time: "05:00 PM",
        category: "Webinar",
        event_name: (
          <>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  id="button-tooltip-2"
                  style={{ position: "absolute", top: "-30px" }}
                >
                  Grab Your Dream Job in Software Testing – A Webinar on
                  Software Testing Field
                </Tooltip>
              }
            >
              <div>Grab Your Dream Job in...</div>
            </OverlayTrigger>
          </>
        ),
        venue: "Online",
        status: <h6 style={{ color: "red" }}>{"Completed"}</h6>,
        staff: "Admin",
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-qrcode"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
            ></i>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                setPopupView(true)
              }}
            ></i>
            <i
              className="fas fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ fontSize: "1em", cursor: "pointer" }}
            ></i>
          </div>
        ),
      },
      {
        id: "5",
        date: "01-12-2021",
        time: "12:00 PM",
        category: "Workshop",
        event_name: (
          <>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  id="button-tooltip-2"
                  style={{ position: "absolute", top: "-30px" }}
                >
                  ASAP Sponsored Online Internship Program on “Modelling Real
                  Time Water Distribution Systems using EPANET Software”
                </Tooltip>
              }
            >
              <div>ASAP Sponsored Online Interns...</div>
            </OverlayTrigger>
          </>
        ),
        venue: "Online",
        status: <h6 style={{ color: "red" }}>{"Completed"}</h6>,
        staff: "Admin",
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-qrcode"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
            ></i>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                setPopupView(true)
              }}
            ></i>
            <i
              className="fas fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ fontSize: "1em", cursor: "pointer" }}
            ></i>
          </div>
        ),
      },
    ],
  }

console.log("test",communitylist);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Home"
            breadcrumbItem={"Community Details"}
          />

          <Row>
            <Col xl="12">
              {/* First Dialogue */}

              <div>
                <div className="row">
                  <div className="col-xl-3">
                    <div
                      className="card"
                      style={{
                        // width: "fit-content",
                        // width: "351px",
                        height: "max-content",
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                      }}
                    >
                      <div className="card-body">
                        <div className="float-end dropdown"></div>
                        <h5
                          className="mt-2 mb-2"
                          style={{
                            textAlign: "center",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}
                        >
                          {communitylist?.community}
                        </h5>
                        <div
                          className="text-muted"
                          style={{
                            textAlign: "center",
                            color: "green !important",
                            fontFamily: "IBM Plex Sans,sans-serif",
                            fontSize: "14px",
                            marginBottom: "3px",
                          }}
                        >
                          <div
                            class="btn-group btn-group-sm"
                            role="group"
                            aria-label="Basic example"
                          >
                            {/* <button
                                                    class="btn btn-success"
                                                    style={{
                                                        borderRadius: "40px",
                                                        marginRight: "10px",
                                                        padding: "0",
                                                        width: "28px",
                                                        cursor: "default",
                                                    }}
                                                >
                                                    <i
                                                        className="uil-phone-alt"
                                                        style={{ fontSize: "15px" }}
                                                    />
                                                </button> */}
                          </div>
                        </div>
                        <p
                          style={{
                            textAlign: "center",
                            marginBottom: "3px",
                          }}
                        ></p>

                        <Divider />

                        <div className="mt-2">
                          <p className="mb-1">Created Date:</p>
                          <h5 className="font-size-14">
                            {moment(communitylist?.date).format("DD-MM-YYYY")}
                          </h5>
                        </div>
                        {/* <div className="mt-2">
                                            <p className="mb-1">Course Code:</p>
                                            <h5 className="font-size-14">MSFF008</h5>
                                        </div> */}
                        <div className="mt-2">
                          <p className="mb-1">Cluster:</p>
                          <h5 className="font-size-14">
                            {communitylist?.cluster?.name}
                          </h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">Sector:</p>

                          <h5 className="font-size-14">
                            {communitylist?.sector?.name}
                          </h5>
                        </div>

                        <div className="mt-2">
                          <p className="mb-1">Total Members:</p>
                          <h5 className="font-size-14">{communitylist?.totalMembers}</h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">Events:</p>
                          <h5 className="font-size-14">{communitylist?.eventsCount}</h5>
                        </div>
                        {/* <div className="mt-2">
                                            <p className="mb-1">Course Category:</p>
                                            <h5 className="font-size-14">Non Technical</h5>
                                        </div>
                                        <div className="mt-2">
                                            <p className="mb-1">Request:</p>
                                            <h5 className="font-size-14">50</h5>
                                        </div> */}
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-9" style={{ marginBottom: "30px" }}>
                    <div
                      className="mb-0 card"
                      style={{
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={tab3}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              indicatorColor="primary"
                              textColor="primary"
                              variant="fullWidth"
                              onChange={handleChangeTab}
                              aria-label="lab API tabs example"
                            >
                              <Tab
                                icon={
                                  <StoreIcon style={{ fontSize: "20px" }} />
                                }
                                label="Requested Members"
                                value="1"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={<Person style={{ fontSize: "20px" }} />}
                                label="Members"
                                value="2"
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                 icon={<NoAccountsIcon style={{ fontSize: "20px" }} />}
                                label="Rejected"
                                value="3"
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={<Event style={{ fontSize: "20px" }} />}
                                label="Event"
                                value="4"
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                            </TabList>
                          </Box>

                          <TabPanel
                            value="1"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Summary />
                          </TabPanel>
                          <TabPanel
                            value="2"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans, sans-serif",
                            }}
                          >
                            <Members />
                          </TabPanel>
                          <TabPanel
                            value="3"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Rejected/>
                            </TabPanel>
                          <TabPanel
                            value="4"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Events />

                            {/* <div className="table-responsive">
                            <Table bordered>
                              <thead>
                                <tr>
                                  {Eventdata.columns.map(
                                    (column, columnIndex) => (
                                      <th
                                        key={columnIndex}
                                        className="p-3"
                                        style={{
                                          fontWeight: "500",
                                          textAlign: "justify",
                                        }}
                                      >
                                        {column.label}
                                      </th>
                                    )
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {Eventdata.rows.map((row, rowIndex) => (
                                  <tr key={rowIndex}>
                                    {Object.values(row).map(
                                      (cell, cellIndex) => (
                                        <td
                                          key={cellIndex}
                                          className="p-3"
                                          style={{ textAlign: "left" }}
                                        >
                                          {cell}
                                        </td>
                                      )
                                    )}
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                            </div> */}
                          </TabPanel>
                        </TabContext>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h5>Event Details</h5>
          </div>

          <button
            type="button"
            onClick={() => setPopupView(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body" style={{ paddingTop: "0px" }}>
          <Row>
            <Col md="12">
              <table
                id="product_available_day"
                className="table table-bordered dataTable"
              >
                <tr>
                  <th
                    class="mdl-td-c"
                    style={{ textAlign: "left", padding: "10px" }}
                  >
                    Category
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>inauguration</td>
                  <th class="mdl-td-c" style={{ textAlign: "left" }}>
                    CSP
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    CSP Kazhakkottam
                  </td>
                </tr>
                <tr>
                  <th
                    class="mdl-td-c"
                    style={{ textAlign: "left", padding: "10px" }}
                  >
                    Event
                  </th>
                  <th> : </th>
                  <td
                    style={{ paddingLeft: "20px", textAlign: "justify" }}
                    colSpan={5}
                  >
                    Datsi School for Storytellers’ Open House
                  </td>
                </tr>
                <tr>
                  <th
                    class="mdl-td-c"
                    style={{ textAlign: "left", padding: "10px" }}
                  >
                    Venue
                  </th>
                  <th> : </th>
                  <td
                    style={{ paddingLeft: "20px", textAlign: "justify" }}
                    colSpan={5}
                  >
                    Kinfra Film & Video Park, Sainik school PO Chanthavila,
                    Kazhakootam, Thiruvananthapuram, Kerala – 695585
                  </td>
                </tr>
                <tr>
                  <th
                    class="mdl-td-c"
                    style={{ textAlign: "left", padding: "10px" }}
                  >
                    Type
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>Offline</td>
                  <th class="mdl-td-c" style={{ textAlign: "left" }}>
                    Resource Person
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Mr. IP Laiju
                  </td>
                </tr>
                <tr>
                  <th
                    class="mdl-td-c"
                    style={{ padding: "10px 11px", textAlign: "left" }}
                  >
                    Start Date
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>14-10-2023</td>
                  <th class="mdl-td-c" style={{ textAlign: "left" }}>
                    Start Time
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    9:30 am
                  </td>
                </tr>
                <tr>
                  <th
                    class="mdl-td-c"
                    style={{ textAlign: "left", padding: "10px" }}
                  >
                    End Date
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>14-10-2023</td>
                  <th class="mdl-td-c" style={{ textAlign: "left" }}>
                    End Time
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    4:30 pm
                  </td>
                </tr>
                <tr>
                  <th
                    class="mdl-td-c"
                    style={{ textAlign: "left", padding: "10px" }}
                  >
                    Added By
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Admin
                  </td>
                </tr>
              </table>
            </Col>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default CommunityDetails
