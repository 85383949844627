import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavLink,
  NavItem,
  Row,
  Progress,
  Modal,
  Input,
  Button,
  Spinner,
} from "reactstrap"
import classnames from "classnames"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { MDBDataTable } from "mdbreact"
import "./style.scss"
import { Tooltip } from "@mui/material"
import { useNavigate } from "react-router-dom"
import Followup from "./FollowUp"
import { get, put } from "../../../helpers/api_helper"
import MuiCustomTablePagination from "../../../components/Common/TablePagination"
import moment from "moment"
import toastr from "toastr"
import { jsonToExcel } from "../../../helpers/functions"

const BatchWiseStatus = () => {
  const navigate = useNavigate()
  const [activeTabJustify, setActiveTabJustify] = useState(2)
  const [tableData, setTableData] = useState([])
  const [modal, setModal] = useState(false)
  const [batchId, setBatchId] = useState(null)
  const [assessStatus, setAssessStatus] = useState(null)
  const [refresh, setRefresh] = useState(1)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(20)
  const [count, setCount] = useState(0)
  const [search, setSearch] = useState("")
  const [loading, setLoading] = useState(false)
  const [assessmentType, setAssessmentType] = useState(null)
  const [lastUpdate, setLastUpdate] = useState(null)
  const [exportData, setExportData] = useState({})


  const handlePageChange = newPage => {
    setPage(newPage)
  }

  const handleView = (url, batchId, batch, cspId, csp) => {
    navigate(`/${url}`, {
      state: {
        batchId: batchId,
        batch: batch,
        cspId: cspId,
        csp: csp,
      },
    })
  }

  const handleChangeRowsPerPage = event => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(0)
  }

  const fetchData = () => {
    get(
      `assessment/batch-wise-status?status=${activeTabJustify}&page=${
        page + 1
      }&limit=${pageSize}&keyword=${search}`
    ).then(res => {
      if (res.success) {
        setCount(res.totalCount)
        setLastUpdate(res.lastUpdate[0])
        const exportData = []

        const data = res.data.map((item, index) => {
          
          item.id = index + 1 + page * pageSize
          item.batch_id = item._id
          item.csp = item.branch.name
          item.batchId = item.batchId
          item.type =item.type
          item.name = item.name
          item.course = item.course.name
          item.nos = item.students
          item.assessment =
            item.assessmentType == 1
              ? "ASAP"
              : item.assessmentType == 2
              ? "TP"
              : ""
          item.date = item.assessmentDate
            ? moment(item.assessmentDate).format("DD-MM-YYYY")
            : ""
          item.status = (
            <div style={{ whiteSpace: "nowrap" }}>
              {item.assessmentStatus === 0 ? (
                <span
                  style={{
                    width: "115px",
                    padding: "0.3rem",
                  }}
                  className="badge bg-dark font-size-12 ms-2"
                >
                  Cancelled
                </span>
              ) : item.assessmentStatus === 1 ? (
                <Tooltip arrow title="Assessment Requested">
                  <span
                    style={{
                      width: "115px",
                      padding: "0.3rem",
                      backgroundColor: "#a075bf",
                    }}
                    className="badge font-size-12 ms-2 "
                  >
                    Assessment Re...
                  </span>
                </Tooltip>
              ) : item.assessmentStatus === 2 ? (
                <Tooltip arrow title="Assessment Scheduled">
                  <span
                    style={{
                      width: "115px",
                      padding: "0.3rem",
                    }}
                    className="badge font-size-12 ms-2 bg-warning"
                  >
                    Assessment Sche...
                  </span>
                </Tooltip>
              ) : item.assessmentStatus === 3 ? (
                <Tooltip arrow title="Requested Date Change">
                  <span
                    style={{
                      width: "115px",
                      padding: "0.3rem",
                    }}
                    className="badge font-size-12 ms-2 bg-danger"
                  >
                    Date Change Re...
                  </span>
                </Tooltip>
              ) : item.assessmentStatus === 4 ? (
                <Tooltip arrow title="Assessment Confirmed">
                  <span
                    style={{
                      width: "115px",
                      padding: "0.3rem",
                    }}
                    className="badge font-size-12 ms-2 bg-info"
                  >
                    Assessment Con...
                  </span>
                </Tooltip>
              ) : item.assessmentStatus === 5 ? (
                <span
                  style={{
                    width: "115px",
                    padding: "0.3rem",
                  }}
                  className="badge font-size-12 ms-2 bg-success"
                >
                  Assessment Completed
                </span>
              ) : item.assessmentStatus === 6 ? (
                "Attendance Marked"
              ) : item.assessmentStatus === 7 ? (
                "Grades Marked"
              ) : item.assessmentStatus === 8 ? (
                "Certificate Published"
              ) : (
                <span
                  style={{
                    width: "115px",
                    padding: "0.3rem",
                  }}
                  className=" badge font-size-12 ms-2 bg-secondary"
                >
                  Pending
                </span>
              )}
            </div>
          )
          item.progress = (
            <Progress
              label={item.progressionPercentage.toFixed(2)}
              value={item.progressionPercentage.toFixed(2)}
              barAriaLabelledBy={item.progressionPercentage.toFixed(2)}
              style={{ height: "20px", width: "85px", fontSize: ".8rem" }}
            >
              {item.progressionPercentage.toFixed(0)}%
            </Progress>
          )

          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Tooltip arrow title="Follow Up">
                <i
                  className="fas fa-eye"
                  onClick={() => {
                    setBatchId(item._id)
                    setModal(true)
                    setAssessStatus(item.assessmentStatus)
                    setAssessmentType(item.assessmentType)
                  }}
                  style={{
                    cursor: "pointer",
                    fontSize: "1.2em",
                    marginRight: "0.5rem",
                  }}
                ></i>
              </Tooltip>

              <span
                style={{
                  position: "relative",
                  display: "inline-block",
                  marginRight: "0.5rem",
                }}
              >
                <Tooltip arrow title="Internal Marks">
                  <i
                    className="fas fa-book"
                    style={{
                      cursor: "pointer",
                      fontSize: "1.2em",
                      marginRight: ".2rem",
                    }}
                    onClick={() =>
                      handleView(
                        "internal-marks",
                        item._id,
                        item.name,
                        item.branch._id,
                        item.branch.name
                      )
                    }
                  ></i>
                  {item.internalsMarked && (
                    <i
                      className="fas fa-check"
                      style={{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        fontSize: "0.6em",
                        color: "green",
                        backgroundColor: "white",
                      }}
                    ></i>
                  )}
                </Tooltip>
              </span>
              <span
                style={{
                  position: "relative",
                  display: "inline-block",
                  marginRight: "0.5rem",
                }}
              >
                <Tooltip
                  disableHoverListener={item.assessmentStatus < 5}
                  arrow
                  title="Assessment Attendance"
                >
                  <i
                    className="fas fa-calendar-alt"
                    style={{
                      cursor: `${item.assessmentStatus >= 5 ? "pointer" : ""}`,
                      fontSize: "1.2em",
                      marginRight: ".2rem",
                      color: `${item.assessmentStatus >= 5 ? "black" : "gray"}`,
                    }}
                    onClick={() => {
                      if (item.assessmentStatus >= 5) {
                        handleView(
                          "assessment-attendance",
                          item._id,
                          item.name,
                          item.branch._id,
                          item.branch.name,
                          item.attendanceMarked
                        )
                      }
                    }}
                  ></i>
                  {item.attendanceMarked && (
                    <i
                      className="fas fa-check"
                      style={{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        fontSize: "0.6em",
                        color: "green",
                        backgroundColor: "white",
                      }}
                    ></i>
                  )}
                </Tooltip>
              </span>
              <span
                style={{
                  position: "relative",
                  display: "inline-block",
                  marginRight: "0.5rem",
                }}
              >
                <Tooltip
                  disableHoverListener={!item.attendanceMarked}
                  arrow
                  title="Assessment Grade"
                >
                  <i
                    className="fas fa-graduation-cap"
                    style={{
                      cursor: `${item.attendanceMarked ? "pointer" : ""}`,
                      fontSize: "1.2em",
                      marginRight: ".1rem",
                      color: `${item.attendanceMarked ? "black" : "gray"}`,
                    }}
                    onClick={() => {
                      if (item.attendanceMarked) {
                        handleView(
                          "assessment-grade",
                          item._id,
                          item.name,
                          item.branch._id,
                          item.branch.name
                        )
                      }
                    }}
                  ></i>
                  {item.gradeMarked && (
                    <i
                      className="fas fa-check"
                      style={{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        fontSize: "0.6em",
                        color: "green",
                        backgroundColor: "white",
                      }}
                    ></i>
                  )}
                </Tooltip>
              </span>
              <span
                style={{
                  position: "relative",
                  display: "inline-block",
                  marginRight: "0.5rem",
                }}
              >
                <Tooltip
                  disableHoverListener={!item.gradeMarked}
                  arrow
                  title="Certification"
                >
                  {item.assessmentType == "1" ? (
                    <>
                      <i
                        className="far fa-file-alt"
                        style={{
                          cursor: `${item.gradeMarked ? "pointer" : ""}`,
                          fontSize: "1.2em",
                          marginRight: "0.2rem",
                          color: `${item.gradeMarked ? "black" : "gray"}`,
                        }}
                        onClick={() => {
                          if (item.gradeMarked) {
                            handleView(
                              "certification-generation",
                              item._id,
                              item.name,
                              item.branch._id,
                              item.branch.name
                            )
                          }
                        }}
                      ></i>
                    </>
                  ) : (
                    <i
                      className="far fa-file-alt"
                      style={{
                        cursor: `${item.gradeMarked ? "pointer" : ""}`,
                        fontSize: "1.2em",
                        marginRight: "0.2rem",
                        color: `${item.gradeMarked ? "black" : "gray"}`,
                      }}
                      onClick={() => {
                        if (item.gradeMarked) {
                          handleView(
                            "tp-assessment-certificate",
                            item._id,
                            item.name,
                            item.branch._id,
                            item.branch.name
                          )
                        }
                      }}
                    ></i>
                  )}
                  {item.certificatePublished && (
                    <i
                      className="fas fa-check"
                      style={{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        fontSize: "0.6em",
                        color: "green",
                        backgroundColor: "white",
                      }}
                    ></i>
                  )}
                </Tooltip>
              </span>
              <span
                style={{
                  position: "relative",
                  display: "inline-block",
                  marginRight: "0.5rem",
                }}
              >
                <Tooltip arrow title="Progress Report">
                  <i
                    className="far fa-list-alt"
                    style={{
                      cursor: "pointer",
                      fontSize: "1.2em",
                      marginRight: "0.2rem",
                    }}
                    onClick={() =>
                      handleView(
                        "progress-report",
                        item._id,
                        item.name,
                        item.branch._id,
                        item.branch.name
                      )
                    }
                  ></i>
                  {item.certificatePublished &&
                    item.gradeMarked &&
                    item.attendanceMarked && (
                      <i
                        className="fas fa-check"
                        style={{
                          position: "absolute",
                          bottom: 0,
                          right: 0,
                          fontSize: "0.6em",
                          color: "green",
                          backgroundColor: "white",
                        }}
                      ></i>
                    )}
                </Tooltip>
              </span>
            </div>
          )
          const dataExport = {}
          dataExport.id = item.id
          dataExport.batch_id = item.batchId
          dataExport.csp = item.branch?.name
          dataExport.batchId = item.batchId
          dataExport.name = item.name
          dataExport.course = item.course
          dataExport.Students = item.students
          dataExport.Type	 = item.assessment	
          if(activeTabJustify !== 1){
            dataExport['Assessment Date'] =  item.assessmentDate
          }
          // dataExport["Progress(%)"] = item?.progress ? item.progressionPercentage.toFixed(2) : null
          if(item?.progress)  dataExport["Progress(%)"] = item.progressionPercentage.toFixed(2)
            if(activeTabJustify === 3){
              dataExport["Status"] = item?.status ? item.assessmentStatus === 0 ? (
                  "Cancelled"
              ) : item.assessmentStatus === 1 ? (
               "Assessment Requested"
              ) : item.assessmentStatus === 2 ? (
                "Assessment Scheduled"
              ) : item.assessmentStatus === 3 ? (
                "Requested Date Change"
              ) : item.assessmentStatus === 4 ? (
               "Assessment Confirmed"
              ) : item.assessmentStatus === 5 ? (
               "Assessment Completed"
              ) : item.assessmentStatus === 6 ? (
                "Attendance Marked"
              ) : item.assessmentStatus === 7 ? (
                "Grades Marked"
              ) : item.assessmentStatus === 8 ? (
                "Certificate Published"
              ) : (
                "Pending"
              ) : null
            }


          exportData.push(dataExport)
          return item
        })
        setExportData(exportData)
        setTableData(data)
      }
    })
  }
  const handleExportData = () => {
    if (exportData.length > 0) {
      jsonToExcel(
        exportData,
        `${
          activeTabJustify === 1
            ? "Ongoing Batches"
            : activeTabJustify === 2
            ? "Completed Batches"
            : activeTabJustify === 3
            ? "Confirmed Assessment"
            : activeTabJustify === 4
            ? "Ongoing Assessment"
            : activeTabJustify === 5
            ? "Completed Assessment"
            : ""
        }-${moment().format("DD-MM-YY")}`
      )
    } else {
      toastr.info(`There are No Data to export`)
    }
  }

  const toggle = (start = true) => {
    var body = document.body

    if (start) {
      body.classList.remove("vertical-collpsed")
      body.classList.remove("sidebar-enable")
      body.setAttribute("data-sidebar-size", "sm")
    } else {
      body.classList.add("vertical-collpsed")
      body.classList.add("sidebar-enable")
      body.setAttribute("data-sidebar-size", "lg")
    }
  }

  useEffect(() => {
    toggle()

    return () => {
      toggle(false)
    }
    // eslint-disable-next-line
  }, [])

  const handleRefresh = () => {
    setRefresh(prev => prev + 1)
  }

  const handleReload = () => {
    setLoading(true)
    put(`assessment/checkCompleted`)
      .then(res => {
        if (res.success) {
          setTimeout(() => setLoading(false), 2000)
          toastr.success(res.message)
          handleRefresh()
        }
      })
      .catch(err => toastr.error(err.response?.data?.message))
  }

  useEffect(() => {
    handleReload()
  }, [])

  const handleModalClose = () => {
    setModal(false)
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [activeTabJustify, page, pageSize, search, refresh])

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Batch ID",
        field: "batchId",
        width: 150,
      },
      {
        label: "Batch",
        field: "name",
        width: 150,
      },
      {
        label: "Course",
        field: "course",
        width: 150,
      },
      {
        label: "CSP",
        field: "csp",
        width: 150,
      },
      {
        label: "Students",
        field: "nos",
        width: 150,
      },
      ...(activeTabJustify !== 1
        ? [
            {
              label: "Assessment Date",
              field: "date",
              width: 50,
            },
          ]
        : []),
      {
        label: "Type",
        field: "assessment",
        width: 150,
      },
      ...(activeTabJustify === 1 || activeTabJustify === 2
        ? [
            {
              label: "Progress",
              field: "progress",
              width: 150,
            },
          ]
        : []),
      ...(activeTabJustify === 2
        ? [
            {
              label: "Status",
              field: "status",
              width: 150,
            },
          ]
        : []),
      {
        label: "Action",
        field: "action",
        width: 150,
      },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumb
          title="Home"
          breadcrumbItem="Batch Wise Assessment Status"
        />
        <Card>
          <CardBody>
            <Row className="nav-row">
              <Col md="12">
                <Col xl={12}>
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTabJustify === 1,
                        })}
                        onClick={() => {
                          setActiveTabJustify(1)
                          setPage(0)
                        }}
                      >
                        <Tooltip arrow title="Ongoing Batches">
                          <div
                            style={{ width: "100%" }}
                            className="d-block d-md-none"
                          >
                            <i className="fas fa-book-open"></i>
                          </div>
                        </Tooltip>
                        <span className="d-none d-md-block">
                          Ongoing Batches
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTabJustify === 2,
                        })}
                        onClick={() => {
                          setActiveTabJustify(2)
                          setPage(0)
                        }}
                      >
                        <Tooltip arrow title="Completed Batches">
                          <div
                            style={{ width: "100%" }}
                            className="d-block d-md-none"
                          >
                            <i className="fas fa-chalkboard-teacher"></i>
                          </div>
                        </Tooltip>
                        <span className="d-none d-md-block">
                          Completed Batches
                        </span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTabJustify === 3,
                        })}
                        onClick={() => {
                          setActiveTabJustify(3)
                          setPage(0)
                        }}
                      >
                        <Tooltip arrow title=" Scheduled Assessment">
                          <div
                            style={{ width: "100%" }}
                            className="d-block d-md-none"
                          >
                            <i className="fas fas fa-calendar-day"></i>
                          </div>
                        </Tooltip>
                        <span className="d-none d-md-block">
                          Confirmed Assessment
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTabJustify === 4,
                        })}
                        onClick={() => {
                          setActiveTabJustify(4)
                          setPage(0)
                        }}
                      >
                        <Tooltip arrow title="Ongoing Assessment">
                          <div
                            style={{ width: "100%" }}
                            className="d-block d-md-none"
                          >
                            <i className="fas fa-hourglass-half"></i>
                          </div>
                        </Tooltip>
                        <span className="d-none d-md-block">
                          Ongoing Assessment
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTabJustify === 5,
                        })}
                        onClick={() => {
                          setActiveTabJustify(5)
                          setPage(0)
                        }}
                      >
                        <Tooltip arrow title="Completed Assessment">
                          <div
                            style={{ width: "100%" }}
                            className="d-block d-md-none"
                          >
                            <i className="fas fa-check-circle"></i>
                          </div>
                        </Tooltip>
                        <span className="d-none d-md-block">
                          Completed Assessment
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
              </Col>
            </Row>
            <Row style={{ marginBottom: "10px" }}>
              <Col md="5"></Col>
              <Col md="7">
                <div
                  style={{
                    display: "flex",
                    direction: "row",
                    justifyContent: "flex-end",
                    alignItems: "baseline",
                  }}
                >
                  <p>
                    Last Updated :{" "}
                    {moment(lastUpdate?.lastUpdatedDate).format("DD/MM")}{" "}
                    {moment(lastUpdate?.lastUpdatedTime, "HH:mm").format(
                      "hh:mm A"
                    )}
                  </p>
                  <Tooltip title="Refresh" arrow>
                    <>
                      <Button
                        color="success"
                        onClick={handleReload}
                        style={{
                          height: "100%",
                          width: "60px",
                          marginLeft: "5px",
                        }}
                        disabled={loading}
                        outline
                      >
                        {loading ? (
                          <Spinner style={{ height: "15px", width: "15px" }} />
                        ) : (
                          <i class="fas fa-redo"></i>
                        )}
                      </Button>
                    </>
                  </Tooltip>
                  <Button
                    onClick={handleExportData}
                    style={{
                      height: "100%",
                      marginLeft: "5px",
                    }}
                    color="primary"
                  >
                    Export
                  </Button>
                  <Input
                    placeholder="Search..."
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    style={{
                      marginRight: "5px",
                      marginLeft: "5px",
                      width: "200px",
                    }}
                  ></Input>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl="12">
                <MDBDataTable
                  id="batchWiseTableId"
                  responsive
                  bordered
                  info={false}
                  searching={false}
                  paging={false}
                  entries={20}
                  disableRetreatAfterSorting={false}
                  data={data}
                  sortable={false}
                />
              </Col>
              {count > 20 && (
                <MuiCustomTablePagination
                  count={count}
                  page={page}
                  rowsPerPage={pageSize}
                  handleChangePage={handlePageChange}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
              )}
            </Row>
            <Modal
              show={modal}
              isOpen={modal}
              toggle={() => {
                setBatchId(null)
                setModal(false)
                setAssessStatus(null)
              }}
              size="xl"
              centered={true}
              id="assessmentModal"
              className="assessment-modal"
            >
              <div className="modal-header">
                <h5
                  className="modal-title"
                  style={{ marginTop: "15px", marginLeft: "15px" }}
                >
                  Assessment Follow Up
                </h5>
                <button
                  type="button"
                  onClick={() => {
                    handleModalClose()
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              {batchId && (
                <Followup
                  batchId={batchId}
                  handleRefresh={handleRefresh}
                  assessStatus={assessStatus}
                  assessmentType={assessmentType}
                />
              )}
            </Modal>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default BatchWiseStatus
