import React from 'react'
import { Row, Col, Card, CardBody } from "reactstrap";
import { MDBDataTable } from 'mdbreact';
import Breadcrumb from '../../../../components/Common/Breadcrumb';


function NotEnrolledReport() {

    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                width: 150,
            },
            {
                label: "Date",
                field: "date",
                width: 150,
            },
            {
                label: "Reg No",
                field: "reg_no",
                width: 150,
            },
            {
                label: "Student",
                field: "student",
                width: 150,
            },
            {
                label: "Email",
                field: "email",
                width: 150,
            },
            {
                label: "Number",
                field: "number",
                width: 150,
            },
            {
                label: "Address",
                field: "address",
                width: 150,
            },
            {
                label: "City",
                field: "city",
                sort: "desc",
                width: 400,
            },

        ],
        rows: [
            {
                id: "1",
                date: "2023-12-14",
                reg_no: "AS0029",
                student: "Sreedarsh",
                email: "sreedarsh@gamil.com",
                number: "8714061742",
                address: "Bhatt Road",
                city: "Calicut"
            },
            {
                id: "2",
                date: "2023-12-11",
                reg_no: "AS0026",
                student: "Shammas",
                email: "shammas@gamil.com",
                number: "9847975633",
                address: "Kolathara p.o",
                city: "kozhikode"
            },
            {
                id: "3",
                date: "2023-12-08",
                reg_no: "AS0020",
                student: "Anuranj",
                email: "anuranj@gamil.com",
                number: "9605378474",
                address: "Narmadha",
                city: "Palakkad"
            },
            {
                id: "4",
                date: "2023-07-08",
                reg_no: "AS0019",
                student: "Jamsheer",
                email: "jamsheer@gamil.com",
                number: "79024802997",
                address: "Theertham",
                city: "Kannur"
            },
        ],
    };

    return (
        <>
            <div className='page-content'>
                <div className='container-fluid'>
                    <Breadcrumb title="Home" breadcrumbItem="Not Enrolled Students" />

                    <Row>
                        <Col className='col-12'>
                            <Card>
                                <CardBody>

                                    <MDBDataTable
                                        id="Managetableid1"
                                        bordered
                                        responsive
                                        searching={true}
                                        entries={20}
                                        disableRetreatAfterSorting={true}
                                        data={data}
                                    >

                                    </MDBDataTable>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </div>
        </>
    )
}

export default NotEnrolledReport;