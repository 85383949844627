import moment from "moment"
import React, { useEffect, useState } from "react"
import Select from "react-select"
import PropTypes from "prop-types"

function FinancialQuarter(props) {
  const [options, setOptions] = useState([])

  useEffect(() => {
    const currentYear = moment().year()
    const quarters = [
      {
        label: `Q1 (Apr 1 - Jun 30)`,
        from: moment(`${currentYear}-04-01`).format("YYYY-MM-DD"),
        to: moment(`${currentYear}-06-30`).format("YYYY-MM-DD"),
        value: `${moment(`${currentYear}-04-01`).format("YYYY-MM-DD")},${moment(
          `${currentYear}-06-30`
        ).format("YYYY-MM-DD")}`,
      },
      {
        label: `Q2 (Jul 1 - Sep 30)`,
        from: moment(`${currentYear}-07-01`).format("YYYY-MM-DD"),
        to: moment(`${currentYear}-09-30`).format("YYYY-MM-DD"),
        value: `${moment(`${currentYear}-07-01`).format("YYYY-MM-DD")},${moment(
          `${currentYear}-09-30`
        ).format("YYYY-MM-DD")}`,
      },
      {
        label: `Q3 (Oct 1 - Dec 31)`,
        from: moment(`${currentYear}-10-01`).format("YYYY-MM-DD"),
        to: moment(`${currentYear}-12-31`).format("YYYY-MM-DD"),
        value: `${moment(`${currentYear}-10-01`).format("YYYY-MM-DD")},${moment(
          `${currentYear}-12-31`
        ).format("YYYY-MM-DD")}`,
      },
      {
        label: `Q4 (Jan 1 - Mar 31)`,
        from: moment(`${currentYear + 1}-01-01`).format("YYYY-MM-DD"),
        to: moment(`${currentYear + 1}-03-31`).format("YYYY-MM-DD"),
        value: `${moment(`${currentYear + 1}-01-01`).format(
          "YYYY-MM-DD"
        )},${moment(`${currentYear + 1}-03-31`).format("YYYY-MM-DD")}`,
      },
    ]

    setOptions(quarters)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {/* <Label>Financial Quarter</Label> */}
      <Select
        value={props.value}
        onChange={props.onChange}
        options={options}
        menuPosition="fixed"
        classNamePrefix="select2-selection"
        {...props}
      />
    </>
  )
}

FinancialQuarter.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default FinancialQuarter
