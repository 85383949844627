import React, { useState, useRef, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import moment from "moment"
import Select from "react-select"
import { MDBDataTable } from "mdbreact"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Row, Col, Button, Label } from "reactstrap"
import { Modal } from "react-bootstrap"

import TablePagination from "../../../../components/Common/Pagination"

import { get } from "../../../../helpers/api_helper"
import { getScholarshipStatus } from "../../../../store/options/actions"

// import "./style.scss"
import ScholarshipProcessing from "../Processing"
import SearchStudent from "../../../../components/Common/SearchStudents"

import queryString from "query-string"

const Request = ({ id, options }) => {
  const initialState = {
    from: "",
    to: "",
    scholarship: id,
    student: "",
    status: "",
  }

  const [filterObject, setFilterObject] = useState(initialState)
  const [selectedFields, setSelectedFields] = useState({})

  const [tableData, setTableData] = useState([])
  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 20

  const [selectedRequest, setSelectedRequest] = useState(null)
  const [modal, setModal] = useState(false)

  const formRef = useRef()
  const dispatch = useDispatch()

  const { scholarshipStatus } = useSelector(state => state.Options)

  useEffect(() => {
    dispatch(getScholarshipStatus())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleTableData()
    setPage(1)
    // eslint-disable-next-line
  }, [filterObject])

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  const handleTableData = async (perPage = page) => {
    const query = `page=${perPage}&limit=${limit}&${queryString.stringify(
      filterObject
    )}`
    get(`/scholarship/request/list?${query}`).then(res => {
      const result = res.data
      const count = res.count
      result.map((item, index) => {
        item.id = index + 1 + (perPage - 1) * limit
        // item.name = item.fundSource

        const statusData = item.status
        item.status = (
          <div>
            <span
              className="badge font-size-12 ms-2"
              style={{ backgroundColor: statusData.color }}
            >
              {statusData.name}
            </span>
          </div>
        )

        item.date = moment(item.date).format("DD-MM-YYYY")

        if (options.edit)
          item.options = (
            <div>
              <i
                className="fas fa-eye"
                onClick={() => {
                  setModal(true)
                  setSelectedRequest(item._id)
                }}
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
              ></i>
            </div>
          )

        return item
      })

      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(result)
    })
  }

  const handleSelectChange = (selected, name) => {
    const obj = { ...selectedFields }
    obj[name] = selected
    setSelectedFields(obj)

    const value = selected
      ? Array.isArray(selected)
        ? selected.map(item => item.value)
        : selected.value
      : null
    handleValueChange(value, name)
  }

  const handleValueChange = async (value, name) => {
    const obj = { ...filterObject }
    obj[name] = value
    setFilterObject(obj)
  }

  const handleChange = (event, value) => {
    setPage(value)
    handleTableData(value)
  }
  const reset = () => {
    formRef.current.reset()
    handleTableData()
    setSelectedFields({})
    setFilterObject(initialState)
    setPage(1)
    toTop()
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Req. ID",
        field: "uniqueId",
        width: 150,
      },
      {
        label: "Reg. ID",
        field: "studentUniqueId",
        width: 150,
      },
      {
        label: "Student",
        field: "student",
        width: 150,
      },
      {
        label: "Course",
        field: "course",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        width: 150,
      },
      {
        label: "Action",
        field: "options",
        width: 150,
      },
    ],
    rows: tableData,
  }
  return (
    <>
      <AvForm ref={formRef}>
        <Row>
          <Col md={3}>
            <div className="mb-3">
              <Label>From </Label>
              <AvField
                name="from"
                className="form-control"
                type="date"
                value={filterObject.from || ""}
                onChange={e => {
                  handleValueChange(e.target.value, "from")
                }}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-3">
              <Label>To </Label>
              <AvField
                name="to"
                className="form-control"
                type="date"
                value={filterObject.to || ""}
                onChange={e => {
                  handleValueChange(e.target.value, "to")
                }}
              />
            </div>
          </Col>
          <Col md={3}>
            <Label>Student</Label>
            <SearchStudent
              api={`/scholarship/options/students`}
              onChange={selected => {
                handleSelectChange(selected, "student")
              }}
              value={selectedFields.student}
              master={filterObject}
            />
          </Col>
          <Col md={3}>
            <Label>Status</Label>
            <Select
              name="status"
              placeholder="Select..."
              options={scholarshipStatus}
              value={selectedFields.status || ""}
              onChange={selected => {
                handleSelectChange(selected, "status")
              }}
            />
          </Col>

          <Col md={2}>
            <Button color="danger" onClick={reset}>
              Reset
            </Button>
          </Col>
        </Row>
      </AvForm>
      <MDBDataTable
        id="scholarshipsRequest"
        className="mt-3"
        bordered
        responsive
        searching={false}
        entries={20}
        paging={false}
        info={false}
        disableRetreatAfterSorting={true}
        data={data}
      ></MDBDataTable>
      <TablePagination page={page} onChange={handleChange} count={totalPage} />
      <Modal show={modal} size="xl" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Scholarship Details</h5>
          <button
            type="button"
            onClick={() => {
              setModal(false)
              handleTableData()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <ScholarshipProcessing id={selectedRequest} />
        </div>
      </Modal>
    </>
  )
}

export default Request
