import React, { useEffect, useRef, useState } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"

import { Modal } from "react-bootstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { AvField, AvForm } from "availity-reactstrap-validation"

import Select from "react-select"
import { MDBDataTable } from "mdbreact"
import { useDispatch, useSelector } from "react-redux"
import { getDistrictOptions } from "../../../store/options/actions"
import axios from "axios"
import { API_URL, APP_NAME } from "../../../config"
import toastr from "toastr"
import { del, get, post, put } from "../../../helpers/api_helper"
import "./styles.scss"
import Swal from "sweetalert2"
import { handleImageValidation, jsonToExcel } from "../../../helpers/functions"
import moment from "moment"
import { Tooltip } from "@mui/material"

const Company = props => {
  document.title = `Company | ${APP_NAME}`

  const options = props.options

  const dispatch = useDispatch()

  const [master, setMaster] = useState({ state: 19, topCompany: false })
  const [details, setDetails] = useState([])
  const [selectedState, setSelectedState] = useState({
    _id: 19,
    name: "Kerala",
    label: "Kerala",
    value: 19,
  })
  const [selectedDistrict, setSelectedDistrict] = useState(null)
  const [companyDetails, setCompanyDetails] = useState({})
  const [companyType, setCompanyType] = useState({
    options: [],
    selected: null,
  })
  const [modal, setModal] = useState(false)
  const [reload, setReload] = useState(false)

  const formRef = useRef()

  const [exports, setExports] = useState([])

  const { states, distrcts } = useSelector(state => state.Options)

  useEffect(() => {
    dispatch(getDistrictOptions({ id: 19 }))
  }, [dispatch])

  useEffect(() => {
    fetchCompanyTypes()
  }, [])

  useEffect(() => {
    get("company").then(res => {
      const data = res.companies

      const exportData = []

      data.map((item, idx) => {
        item.id = idx + 1

        item.district_name = item?.district?.district_name || ""

        item.staff_name = `${item.added_by?.firstName || ""} ${
          item.added_by?.lastName || ""
        }`

        item.action = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {/* {options.view && ( */}
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2em",
                marginRight: "0.5em",
              }}
              onClick={() => {
                handleModal(item._id)
              }}
            ></i>
            {/* )} */}
            {/* {options.view && ( */}
            <i
              className="far fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.1em",
                marginRight: "0.5em",
              }}
              onClick={() => {
                preUpdateCompany(item._id)
                toTop()
              }}
            ></i>
            {/* )} */}
            <i
              className="far fa-trash-alt"
              style={{ fontSize: "1em", cursor: "pointer" }}
              onClick={() => {
                handleDelete(item._id)
              }}
            ></i>
          </div>
        )

        const exportDetails = {
          "#": idx + 1,
          Id: item?.unqid,
          Name: item?.name,
          "Short Code": item?.shortcode,
          "Contact Person": item?.person,
          Designation: item?.design,
          Mobile: item?.mob,
          Email: item?.email,
          Website: item?.web,
          State: item?.state?.name,
          District: item?.district_name,
          Address: item?.address,
          PIN: item?.pin,
          "Added By": item?.staff_name,
        }
        exportData.push(exportDetails)

        return item
      })
      setDetails(data)
      setExports(exportData)
    })
    // eslint-disable-next-line
  }, [reload, options])

  function handleChange(e) {
    let { name, value, type, checked } = e.target

    if (type === "checkbox") value = !checked

    setMaster(pre => ({ ...pre, [name]: value }))
  }

  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`company/${id}`)
          .then(res => {
            toastr.success(res.message)
            setReload(pre => !pre)
          })
          .catch(err => {
            toastr.success(err.response.data.message)
          })
      }
    })
  }

  function preUpdateCompany(id) {
    get(`company/single/${id}`)
      .then(res => {
        const data = res.company
        const companyType = res.company.type
        data.id = id
        setSelectedState({ label: data?.state?.name, value: data?.state?._id })
        setSelectedDistrict({
          label: data?.district?.district_name,
          value: data?.district?._id,
        })

        setCompanyType(pre => ({
          ...pre,
          selected: companyType
            ? companyType.map(item => ({
                label: item.name,
                value: item._id,
                ...item,
              }))
            : [],
        }))

        data.type = data.type ? data.type.map(item => item._id) : []
        data.district = data?.district?._id
        dispatch(getDistrictOptions({ id: data?.state?._id }))
        data.state = data?.state?._id
        setMaster(data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  function handleModal(id) {
    get(`company/single/${id}`)
      .then(res => {
        const data = res.company
        data.district_name = data?.district?.district_name
        data.state_name = data?.state?.name
        setCompanyDetails(data)
        setModal(true)
      })
      .catch(err => {
        console.log(err.response.data)
      })
  }

  function handleSelectChange(e, name) {
    const data = { ...master }
    switch (name) {
      case "state":
        dispatch(getDistrictOptions({ id: e.value }))
        setSelectedState(e)
        data[name] = e.value
        data.district = null
        setSelectedDistrict(null)
        break
      case "district":
        setSelectedDistrict(e)
        data[name] = e.value
        break
      case "type":
        setCompanyType(pre => ({ ...pre, selected: e }))
        data[name] = e ? e.map(item => item.value) : []
        break

      default:
        break
    }
    setMaster(data)
  }

  function handleSubmit() {
    if (master.id) {
      put("company", master)
        .then(res => {
          setReload(pre => !pre)
          toastr.success(res.message)
          handleReset()
        })
        .catch(err => {
          console.log(err)
          toastr.error(err.response.data.message)
        })
    } else {
      post("company", master)
        .then(res => {
          setReload(pre => !pre)
          toastr.success(res.message)
          handleReset()
        })
        .catch(err => {
          console.log(err.response.data)
          toastr.error(err.response.data.message)
        })
    }
  }

  const uploadImage = async e => {
    const selectedFile = e.target.files[0]

    if (selectedFile) {
      try {
        await handleImageValidation(selectedFile)

        const fd = new FormData()

        fd.append("user_image", selectedFile)

        //TODO : change user api to company api
        const response = await axios.post(`${API_URL}user/image`, fd)

        if (response.data.status === 200) {
          setMaster({
            ...master,
            logo: response.data.data.new_filename,
          })
        } else {
          toastr.error(response.data.message)
        }
      } catch (error) {
        toastr.error(error)
        e.target.value = ""
      }
    }
  }

  const fetchCompanyTypes = () => {
    get("options/companies/type").then(res => {
      setCompanyType({ options: res.data, selected: null })
    })
  }

  function handleReset() {
    formRef.current.reset()
    setCompanyType(pre => ({ ...pre, selected: null }))
    setMaster({})
    setSelectedDistrict(null)
    setSelectedState(null)
  }

  const handleExport = () => {
    if (exports.length > 0) {
      jsonToExcel(exports, `company-${moment().format("DD-MM-YY")}`)
    } else {
      toastr.info(`There are no company to export`)
    }
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Company ID",
        field: "unqid",
        sort: "asc",
        width: 150,
      },
      {
        label: "Company Name",
        field: "name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Code",
        field: "shortcode",
        sort: "asc",
        width: 200,
      },
      {
        label: "Contact Person",
        field: "person",
        sort: "asc",
        width: 100,
      },
      {
        label: "Mobile",
        field: "mob",
        sort: "asc",
        width: 10,
      },
      {
        label: "District",
        field: "district_name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Added By",
        field: "staff_name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: details,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Home" breadcrumbItem="Company" />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={() => {
                      handleSubmit()
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Company Name
                          </Label>
                          <AvField
                            name="name"
                            placeholder="Company Name"
                            type="text"
                            errorMessage="Enter Company Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            onChange={handleChange}
                            value={master.name || ""}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Short Code</Label>
                          <AvField
                            name="shortcode"
                            placeholder="Short Code"
                            type="text"
                            errorMessage="Enter Short code"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            onChange={handleChange}
                            value={master.shortcode || ""}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Company Type
                          </Label>
                          <Select
                            name="state_id"
                            value={companyType.selected}
                            isMulti
                            onChange={selected => {
                              handleSelectChange(selected, "type")
                            }}
                            options={companyType.options}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Contact Person
                          </Label>
                          <AvField
                            name="person"
                            placeholder="Contact Person"
                            type="text"
                            errorMessage="Enter Contact Person"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: "^[a-zA-Z ]+$",
                                errorMessage: "Enter valid name",
                              },
                            }}
                            id="validationCustom01"
                            onChange={handleChange}
                            value={master.person || ""}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Designation
                          </Label>
                          <AvField
                            name="design"
                            placeholder="Designation"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={handleChange}
                            value={master.design || ""}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Mobile No.</Label>
                          <AvField
                            name="mob"
                            placeholder="Mobile No."
                            type="text"
                            errorMessage="Enter valid mobile no"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: "^(0|91)?[6-9][0-9]{9}$",
                                errorMessage: "Enter valid mobile no",
                              },
                            }}
                            id="validationCustom03"
                            onChange={handleChange}
                            value={master.mob || ""}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom04">
                            Landline No.
                          </Label>
                          <AvField
                            name="land"
                            placeholder="Landline No."
                            type="text"
                            errorMessage="Enter valid 11 digit number"
                            className="form-control"
                            validate={{
                              pattern: {
                                value: "^[0-9]{11}$",
                                errorMessage: "Enter valid landline no",
                              },
                            }}
                            id="validationCustom04"
                            onChange={handleChange}
                            value={master.land || ""}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Email ID</Label>
                          <AvField
                            name="email"
                            placeholder="Email ID"
                            type="email"
                            errorMessage="Enter valid Email"
                            className="form-control"
                            validate={{ email: true }}
                            id="validationCustom05"
                            onChange={handleChange}
                            value={master?.email || ""}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Website</Label>
                          <AvField
                            name="web"
                            placeholder="Website"
                            type="text"
                            className="form-control"
                            id="validationCustom05"
                            onChange={handleChange}
                            value={master?.web || ""}
                            validate={{
                              pattern: {
                                // eslint-disable-next-line no-useless-escape
                                value: `^((http|https)://)?(www\.)?([a-zA-Z0-9\-]{2,}\.)+[a-zA-Z]{2,}(/[a-zA-Z0-9/\-\?\+\=\&\%\:\.#]*)?$`,
                                errorMessage: "Enter valid website url",
                              },
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Address</Label>
                          <AvField
                            name="address"
                            placeholder="Address"
                            type="text"
                            className="form-control"
                            id="validationCustom03"
                            onChange={handleChange}
                            value={master?.address || ""}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>State</Label>
                          <Select
                            name="state_id"
                            value={selectedState}
                            onChange={selected => {
                              handleSelectChange(selected, "state")
                            }}
                            options={states}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>District</Label>
                          <Select
                            name="district_id"
                            value={selectedDistrict}
                            onChange={selected => {
                              handleSelectChange(selected, "district")
                            }}
                            options={distrcts}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">PIN</Label>
                          <AvField
                            name="pin"
                            placeholder="PIN"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={handleChange}
                            validate={{
                              pattern: {
                                value: "^[1-9][0-9]{5}$",
                                errorMessage:
                                  "PIN must be only with numbers 6 length",
                              },
                            }}
                            value={master?.pin || ""}
                            min={0}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">GSTIN</Label>
                          <AvField
                            name="gstin"
                            placeholder="GSTIN"
                            type="text"
                            className="form-control"
                            id="validationCustom03"
                            onChange={handleChange}
                            value={master?.gstin || ""}
                            min={0}
                            validate={{
                              pattern: {
                                value:
                                  "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$",
                                errorMessage: "Enter valid GST no",
                              },
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom04">PAN No.</Label>
                          <AvField
                            name="pan"
                            placeholder="PAN No"
                            type="text"
                            className="form-control"
                            id="validationCustom04"
                            onChange={handleChange}
                            value={master?.pan || ""}
                            validate={{
                              pattern: {
                                value: "^[A-Z]{5}[0-9]{4}[A-Z]{1}$",
                                errorMessage: "Enter valid pan no",
                              },
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">CIN No.</Label>
                          <AvField
                            name="cin"
                            placeholder="CIN No."
                            type="text"
                            className="form-control"
                            id="validationCustom05"
                            onChange={handleChange}
                            value={master?.cin || ""}
                            validate={{
                              pattern: {
                                value:
                                  "^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$",
                                errorMessage: "Enter valid CIN no",
                              },
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">TDS No.</Label>
                          <AvField
                            name="tds"
                            placeholder="TDS No"
                            type="text"
                            className="form-control"
                            id="validationCustom05"
                            onChange={handleChange}
                            value={master?.tds || ""}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Latitude</Label>
                          <AvField
                            name="latitude"
                            placeholder="Latitude"
                            type="text"
                            className="form-control"
                            id="validationCustom03"
                            onChange={handleChange}
                            value={master?.latitude || ""}
                            validate={{
                              pattern: {
                                // value: `^-?([0-8]?[0-9]|90)(\.[0-9]{1,10})$`, // Validates -90 to 90 with decimals
                                value: "^[0-9.]+$",
                                // errorMessage:"Enter a valid latitude between -90 and 90",
                              },
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom04">Longitude</Label>
                          <AvField
                            name="longitude"
                            placeholder="Longitude"
                            type="text"
                            className="form-control"
                            id="validationCustom04"
                            onChange={handleChange}
                            value={master?.longitude || ""}
                            validate={{
                              pattern: {
                                // value: `^-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,10})$`, // Validates -180 to 180 with decimals
                                value: "^[0-9.]+$",
                                errorMessage:
                                  "Enter a valid longitude between -180 and 180",
                              },
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Company Logo{" "}
                            <span className="label-image-size">(200x100)</span>
                          </Label>
                          {master?.logo ? (
                            <div div className="img-wraps d-flex flex-column">
                              <img
                                alt=""
                                width="150"
                                height="150"
                                src={`${API_URL}${master?.logo}`}
                              />
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={() =>
                                  setMaster(pre => ({ ...pre, logo: null }))
                                }
                                style={{ width: "150px" }}
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <input
                              name="logo"
                              type="file"
                              className="form-control"
                              id="validationCustom03"
                              onChange={uploadImage}
                            />
                          )}
                        </div>
                      </Col>

                      <Col md={2} className="mt-4 pt-2">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="topCompany"
                            id="defaultCheck2"
                            checked={master.topCompany}
                            onClick={handleChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="defaultCheck2"
                          >
                            Top Company
                          </label>
                        </div>
                      </Col>

                      <Col
                        md={2}
                        className="d-flex align-items-center mt-2 pt-2"
                      >
                        <Button
                          color={master?.id ? "warning" : "primary"}
                          className="me-2"
                          type="submit"
                        >
                          {master?.id ? "Update" : "Submit"}
                        </Button>
                        <Button
                          color="danger"
                          onClick={handleReset}
                          type="button"
                        >
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="p-2 pb-3 d-flex  justify-content-end">
                    <Tooltip
                      title="Export user details"
                      placement="left-start"
                      arrow
                    >
                      <i
                        onClick={handleExport}
                        className="fa fa-solid fa-file-export fs-4 cursor-pointer"
                      ></i>
                    </Tooltip>
                  </div>
                  <MDBDataTable
                    id="companyTableId"
                    disableRetreatAfterSorting={true}
                    responsive
                    bordered
                    entries={20}
                    data={data}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal show={modal} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Company Details</h5>
          <button
            type="button"
            onClick={() => {
              setModal(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <table
            id="companyDetailsTable"
            className="table table-bordered dataTable"
          >
            <tr>
              <td style={{ width: "160px", padding: "10px" }}>
                Company Name :
              </td>
              <td>{companyDetails?.name}</td>
              <td>Company ID :</td>
              <td style={{ textAlign: "left" }}>{companyDetails?.unqid}</td>
            </tr>
            <tr>
              <td style={{ padding: "10px" }}>Company Code :</td>
              <td>{companyDetails?.shortcode}</td>
              <td style={{ width: "110px" }}>Company Type :</td>
              <td style={{ textAlign: "left" }}>
                {companyDetails?.type?.map(item => (
                  <Badge
                    style={{
                      color: "white",
                      fontWeight: 400,
                      padding: ".3rem",
                      fontSize: "11px",
                      backgroundColor: "rgb(230, 230, 230)",
                      margin: "2px",
                    }}
                  >
                    {item.name}
                  </Badge>
                ))}
              </td>
            </tr>
            <tr>
              <td style={{ padding: "10px" }}>Contact Person :</td>
              <td>{companyDetails?.person}</td>
              <td>Designation :</td>
              <td style={{ textAlign: "left" }}>{companyDetails?.design}</td>
            </tr>
            <tr>
              <td style={{ padding: "10px" }}>Mobile :</td>
              <td>{companyDetails?.mob}</td>
              <td>Email :</td>
              <td style={{ textAlign: "left" }}>{companyDetails?.email}</td>
            </tr>
            <tr>
              <td style={{ padding: "10px" }}>Landline :</td>
              <td>{companyDetails?.land}</td>
              <td>WEB :</td>
              <td style={{ textAlign: "left" }}>{companyDetails?.web}</td>
            </tr>
            <tr>
              <td style={{ padding: "10px" }}>Address :</td>
              <td style={{ maxWidth: "180px" }}>{companyDetails?.address}</td>
              <td>PIN :</td>
              <td style={{ textAlign: "left" }}>{companyDetails?.pin}</td>
            </tr>
            <tr>
              <td style={{ padding: "10px" }}>District :</td>
              <td>{companyDetails?.district_name}</td>
              <td>State :</td>
              <td style={{ textAlign: "left" }}>
                {companyDetails?.state_name}
              </td>
            </tr>

            <tr>
              <td style={{ padding: "10px" }}>CIN NO :</td>
              <td>{companyDetails?.cin}</td>
              <td>GSTIN :</td>
              <td style={{ textAlign: "left" }}>{companyDetails?.gstin}</td>
            </tr>

            <tr>
              <td style={{ padding: "10px" }}>PAN No :</td>
              <td>{companyDetails?.pan}</td>
              <td>TDS No :</td>
              <td style={{ textAlign: "left" }}>{companyDetails?.tds}</td>
            </tr>
            <tr>
              <td style={{ padding: "10px" }}>Latitude :</td>
              <td>{companyDetails?.latitude}</td>
              <td>Longitude :</td>
              <td style={{ textAlign: "left" }}>{companyDetails?.longitude}</td>
            </tr>
            <tr>
              <td style={{ padding: "10px" }}>Date :</td>
              <td>
                {companyDetails?.date &&
                  moment(companyDetails?.date).format("DD-MM-YYYY")}
              </td>
              <td>Time :</td>
              <td style={{ textAlign: "left" }}>
                {companyDetails?.time &&
                  moment(companyDetails?.time, "HH:mm:ss").format("hh:mm a")}
              </td>
            </tr>
            <tr>
              <td style={{ padding: "10px" }}>Added by :</td>
              <td>
                {companyDetails?.added_by?.firstName || ""}{" "}
                {companyDetails?.added_by?.lastName}
              </td>
              <td>Updated by :</td>
              <td style={{ textAlign: "left" }}>
                {companyDetails?.updated_by?.firstName || ""}{" "}
                {companyDetails?.updated_by?.lastName}
              </td>
            </tr>
            <tr hidden={!companyDetails?.logo}>
              <td style={{ padding: "10px" }}>Logo :</td>
              <td style={{ textAlign: "left" }}>
                <img
                  style={{
                    width: "100px",
                    height: "100px",
                    paddingLeft: "0px",
                  }}
                  alt=""
                  src={`${API_URL}${companyDetails?.logo}`}
                ></img>
              </td>
            </tr>
          </table>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default Company
