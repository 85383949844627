import React, { useState, useEffect, useRef } from "react"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import Select from "react-select"

import { AvForm, AvField } from "availity-reactstrap-validation"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "./style.scss"
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle"

const ManageTeam = () => {
  const [tableData, setTableData] = useState([])

  const formRef = useRef()

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  const users = [
    { label: "Ashok", value: 1 },
    { label: "Latheef", value: 4 },
    { label: "Rahul", value: 3 },
    { label: "Riyas", value: 2 },
    { label: "Rizin", value: 5 },
  ]

  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [])

  function handleTableData() {
    let result = [
      {
        _id: "63846133990e76625ab43fdb",
        name: "Accountant",
        manager: {
          firstName: "Ashok",
        },
        leader: {
          firstName: "Rahul",
        },
        coordinator: {
          firstName: "Riyas",
        },
        members: [
          {
            firstName: "Riyas",
          },
          {
            firstName: "Latheef",
          },
        ],
      },
    ]
    result.map((item, index) => {
      item.action = (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            className="far fa-edit"
            style={{
              fontSize: "1em",
              cursor: "pointer",
              marginLeft: "0.1rem",
              marginRight: "0.5rem",
            }}
            onClick={() => {
              toTop()
            }}
          ></i>
          <i
            className="far fa-trash-alt"
            style={{ fontSize: "1em", cursor: "pointer" }}
          ></i>
        </div>
      )
      item.id = index + 1
      if (item?.manager) {
        item.team_manager =
          (item.manager.firstName || "") + (item.manager.lastName || "")
      }
      if (item?.coordinator) {
        item.team_coordinator =
          (item.coordinator.firstName || "") + (item.coordinator.lastName || "")
      }
      if (item?.leader) {
        item.team_leader =
          (item.leader.firstName || "") + (item.leader.lastName || "")
      }
      item.team_member = (
        <div>
          <ul style={{ listStyleType: "none", paddingInlineStart: "0px" }}>
            {item.members &&
              item.members.map(el => {
                let member = (el.firstName || "") + (el.lastName || "")
                return (
                  <li className="mb-1">
                    <span>
                      <RemoveCircleIcon
                        style={{
                          color: "#f46a6a",
                          cursor: "pointer",
                          height: "16px",
                        }}
                      />
                      {member}
                    </span>
                  </li>
                )
              })}
          </ul>
        </div>
      )
      return item
    })
    setTableData(result)
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Team Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Team Manager",
        field: "team_manager",
        sort: "asc",
        width: 150,
      },
      {
        label: "Team Coordinator",
        field: "team_coordinator",
        sort: "asc",
        width: 150,
      },
      {
        label: "Team Leader",
        field: "team_leader",
        sort: "asc",
        width: 150,
      },
      {
        label: "Members",
        field: "team_member",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: tableData,
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Team" />
          <Row>
            <Col className="col-12">
              <Card style={{ padding: "0px 0px 2px 0px" }}>
                <CardBody>
                  <AvForm ref={formRef} className="needs-validation">
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Team Name</Label>
                          <AvField
                            name="name"
                            placeholder="Team Name "
                            type="text"
                            errorMessage="Enter Team Name "
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Team Manager</Label>
                          <Select
                            name="manager_team"
                            options={users}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          ></p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Team Coordinator</Label>
                          <Select
                            name="cordinator_team"
                            options={users}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          ></p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Team Leader</Label>
                          <Select
                            name="leader_team"
                            options={users}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          ></p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Team Members</Label>
                          <Select
                            isMulti
                            name="member_team"
                            classNamePrefix="select2-selection"
                            options={users}
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          ></p>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3" style={{ paddingTop: "25px" }}>
                          <Button color="primary" type="submit">
                            Submit
                          </Button>
                          <Button
                            color="danger"
                            type="reset"
                            style={{ marginLeft: "1%" }}
                          >
                            {"  "}
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="manageStaffTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ManageTeam
