import React, { useEffect, useState } from "react"
import { Row, Col } from "reactstrap"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

import { MDBDataTable } from "mdbreact"

import moment from "moment"

import { get } from "../../../../helpers/api_helper"

function Registrations(props) {
  const jobpost = props.data
  const [registration, setRegistration] = useState([])

  useEffect(() => {
    handleTableData()
  }, [])

  function handleTableData() {
    get(`/job/saved?job=${jobpost}`).then(res => {
      console.log()
      let result = res.data
     let data= result.map((item, index) => {
        item.id = index + 1
        item.date = moment(item.date).format("DD-MM-YYYY")

        item.registration = item?.applicant?.uniqueId
        item.name = item?.applicant?.name
        item.mobile = item?.applicant?.mobile
        item.options = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
              // onClick={() => {
              //   setPopupView2(true)
              // }}
            ></i>
          </div>
        )
        return item
      })
      setRegistration(data)

      // setRegistration(result)
    })
  }
  const saved = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 200,
      },
      {
        label: "Reg No",
        field: "registration",
        sort: "asc",
        width: 200,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Mobile",
        field: "mobile",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Status",
      //   field: "status",
      //   sort: "asc",
      //   width: 200,
      // },

      // {
      //   label: "Action",
      //   field: "options",
      //   sort: "asc",
      //   width: 200,
      // },
    ],
    rows: registration,
  }
  return (
    <div>
      <Row>
        <Col className="col-12">
          <MDBDataTable
            id="registeredpeopleid"
            responsive
            bordered
            data={saved}
            searching={true}
            info={false}
            disableRetreatAfterSorting={true}
          />
        </Col>
      </Row>
    </div>
  )
}

export default Registrations
