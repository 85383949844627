import React, { useState, useRef, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"

import toastr from "toastr"
import moment from "moment"

import "./style.scss"

import { get, post, put } from "../../../helpers/api_helper"
import { getChartAccount } from "../../../store/options/actions"
import { getDate, setTitle } from "../../../helpers/functions"
const FundTransfer = ({ options }) => {
  setTitle("Fund Transfer")

  const date = getDate()
  const time = moment().format("HH:mm")

  const [tableData, setTableData] = useState([])
  const formRef = useRef()

  const { chartOfAccount } = useSelector(state => state.Options)
  const dispatch = useDispatch()

  const [balance, setBalance] = useState(0)
  const [masterObject, setMasterObject] = useState({
    fromFundSource: "",
    toFundSource: "",
    amount: "",
    comment: "",
    date,
    time,
  })

  const statusOptions = [
    { label: "Pending", value: 0 },
    { label: "Approve", value: 1 },
    { label: "Rejected", value: 2 },
  ]

  useEffect(() => {
    handleTableData()
    dispatch(getChartAccount())
    // eslint-disable-next-line
  }, [])

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }
  const handleTableData = async () => {
    get("/accounts/fund-transfer").then(res => {
      const result = res.data
      result.map((item, index) => {
        item.id = index + 1

        item.date = moment(item.date).format("DD-MM-YYYY")
        item.time = moment(item.time, "HH:mm:ss").format("HH:mm a")

        if (item.approvedStatus === 1) {
          item.statusOption = (
            <Button size="sm" color="success" disabled>
              Approved
            </Button>
          )
        } else if (item.approvedStatus === 2) {
          item.statusOption = (
            <Button size="sm" color="danger" disabled>
              Rejected
            </Button>
          )
        } else if (options.edit) {
          item.statusOption = (
            <Select
              options={statusOptions}
              value={statusOptions.filter(
                status => status.value === item.approvedStatus
              )}
              onChange={selected =>
                handleStatusChange(item._id, selected.value)
              }
              menuPosition="fixed"
            ></Select>
          )
        } else {
          item.statusOption = (
            <Button size="sm" color="warning" disabled>
              Pending
            </Button>
          )
        }

        return item
      })

      setTableData(result)
    })
  }

  const handleStatusChange = async (id, type) => {
    if (type !== 0) {
      put("/accounts/approve", { id, type })
        .then(res => {
          reset()
          toastr.success(res.message)
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    }
  }

  const handleValueChange = async (value, name, balance = 0) => {
    const obj = { ...masterObject }
    if (
      (name === "fromFundSource" && value === obj.toFundSource) ||
      (name === "toFundSource" && value === obj.fromFundSource)
    )
      return
    obj[name] = value

    if (name === "fromFundSource") setBalance(balance)

    setMasterObject(obj)
  }

  const handleSubmit = () => {
    post("/accounts/fund-transfer", masterObject)
      .then(res => {
        reset()
        toastr.success(res.message)
      })
      .catch(err => {
        toastr.error(err.response.data.message)
      })
  }
  const reset = () => {
    formRef.current.reset()
    dispatch(getChartAccount())
    handleTableData()
    setMasterObject({ name: "", date, time })
    toTop()
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        width: 150,
      },
      {
        label: "ID",
        field: "uniqueId",
        width: 150,
      },
      {
        label: "From ",
        field: "from",
        width: 150,
      },
      {
        label: "To",
        field: "to",
        width: 150,
      },
      {
        label: "Amount",
        field: "amount",
        width: 150,
      },
      {
        label: "Comment",
        field: "comment",
        width: 150,
      },
      {
        label: "User",
        field: "staff",
        width: 150,
      },

      {
        label: "Status",
        field: "statusOption",
        sort: "desc",
        width: 400,
      },
    ],
    rows: tableData,
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Fund Transfer" />
          {options.edit ? (
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm onValidSubmit={handleSubmit} ref={formRef}>
                      <Row>
                        <Col md={2}>
                          <div className="mb-3">
                            <AvField
                              name="date"
                              label="Date"
                              type="date"
                              value={masterObject.date}
                              defaultValue={date}
                              max={date}
                              onChange={e =>
                                handleValueChange(e.target.value, e.target.name)
                              }
                            />
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="mb-3">
                            <AvField
                              name="time"
                              label="Time"
                              type="time"
                              defaultValue={time}
                              value={masterObject.time}
                              onChange={e =>
                                handleValueChange(e.target.value, e.target.name)
                              }
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>From Fund Source</Label>
                            <Select
                              name="from"
                              placeholder="Select..."
                              options={chartOfAccount.filter(
                                item => item.value !== masterObject.toFundSource
                              )}
                              value={chartOfAccount.filter(
                                item =>
                                  item.value === masterObject.fromFundSource
                              )}
                              onChange={selected => {
                                handleValueChange(
                                  selected.value,
                                  "fromFundSource",
                                  selected.balance
                                )
                              }}
                            />
                            {masterObject.fromFundSource ? (
                              <span className="mt-2">
                                <b>Balance :</b> {balance}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>To Fund Source</Label>
                            <Select
                              name="to"
                              placeholder="Select..."
                              options={chartOfAccount.filter(
                                item =>
                                  item.value !== masterObject.fromFundSource
                              )}
                              value={chartOfAccount.filter(
                                item => item.value === masterObject.toFundSource
                              )}
                              onChange={selected => {
                                handleValueChange(
                                  selected.value,
                                  "toFundSource"
                                )
                              }}
                            />
                          </div>
                        </Col>

                        <Col md="2">
                          <div className="mb-3">
                            <Label>Amount</Label>
                            <AvField
                              name="amount"
                              className="form-control"
                              type="text"
                              value={masterObject.amount || ""}
                              onChange={e => {
                                handleValueChange(e.target.value, "amount")
                              }}
                              validate={{ required: { value: true } }}
                              placeholder="Amount"
                              errorMessage="Enter Amount"
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Comments</Label>
                            <AvField
                              name="Comments"
                              className="form-control"
                              type="text"
                              value={masterObject.comment || ""}
                              onChange={e => {
                                handleValueChange(e.target.value, "comment")
                              }}
                              validate={{ required: { value: true } }}
                              placeholder="Comments"
                              errorMessage="Enter Comments"
                            />
                          </div>
                        </Col>

                        <Col>
                          <div
                            className="mb-3 d-flex gap-2"
                            style={{ paddingTop: "26px" }}
                          >
                            <Button
                              color={`${
                                masterObject.id ? "warning" : "primary"
                              }`}
                              type="submit"
                            >
                              {masterObject.id ? "Update" : "Submit"}
                            </Button>
                            <Button color="danger" onClick={reset}>
                              Reset
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            ""
          )}

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="FundTransferId"
                    bordered
                    responsive
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default FundTransfer
