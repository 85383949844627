import React from "react"
import useUser from "../../hooks/useUser"
import PropTypes from "prop-types"

const Allowed = ({ children, privileges }) => {
  const data = useUser()
  return privileges.includes(data.privilage) && <>{children}</>
}

Allowed.propTypes = {
  privilege: PropTypes.array,
}


export const isAllowed = (privileges) => {
  const user = JSON.parse(localStorage.getItem("authUser") || "{}")
  return privileges.includes(user.privilage)
}

export default Allowed
