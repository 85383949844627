import React, { useState, useRef, useEffect } from 'react'
import { AvField, AvForm } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Row, Col, Card, CardBody, Button, Label, Modal, Spinner } from "reactstrap";
import { MDBDataTable } from 'mdbreact';
import { get, post, put } from "../../../helpers/api_helper"
import "./CampaignDashboard.scss"
import toastr from 'toastr';
import Swal from "sweetalert2"
import TablePagination from '../../../components/Common/Pagination';
import { Tooltip as TooltipMui } from '@mui/material';
import moment from 'moment';
import { jsonToExcel } from '../../../helpers/functions';

function Campaign() {
    const initialState = {
        name: "",
        shortCode: "",
        budget: "",
        track_variable:"",
    };

    const [tableData, setTableData] = useState([])
    const [listData, setListData] = useState(initialState)
    const [state, setState] = useState(false)
    const [result,setResult]=useState([])
    const [selctedItem,setSelectedItem] = useState(null)
    const handleCloseModal = () => setState(false);
    const formRef = useRef()
    const [totalPage, setTotalPage] = useState(0)
    const [page, setPage] = useState(1)
    const limit = 10
    const [trackClickObj,setTrackClickObj] = useState([])
    const [isLoading,setIsLoading] = useState(false)
    const [count, setCount] = useState(0)

    const handleValueChange = (e) => {
        let { name, value } = e.target;
        setListData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
    
    const reset = () => {
        setListData(initialState)   
        formRef.current.reset()
        handleTableData()
    }
    const toTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behaviour: "smooth"

        })
    }

    const handleEdit = (item) => {
        toTop();
        setListData({
            _id: item._id,
            name: item.campaign,
            shortCode: item.shortCode,
            budget: item.Budget
        });
    };
    

    const handleOpenModal = (selectedId) => {
        
        get(`/campaign/list/${selectedId}`)
            .then(res => {
                const result = res.data;
                setResult(result)
            })
    }

    function handleDelete(_id) {
        return Swal.fire({
            title: "Are you Sure?",
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(result => {
            if (result.isConfirmed) {
                put(`/campaign/delete/${_id}`)
                    .then(res => {
                        toastr.success(res.message)
                        reset()
                    })
                    .catch(err => {
                        toastr.success(err.response.data.message)
                    })
            }
        })
    }

    const handleSubmit = () => {

        if (listData._id) {
            put("/campaign/edit", listData)
                .then(res => {
                    toastr.success("updated Successfully")
                    reset()
                })
                .catch(err => {
                    toastr.error(err.response?.data?.message)
                })
        } else {
            post('/campaign/add', listData)
                .then(res => {
                    toastr.success("Submitted Successfully")
                    reset()
                })
                .catch(err => {
                    toastr.error(err.response?.data?.message)
                })
        }

    }

    const handleTableData = () => {
        const filterQuery = `page=${page}&limit=${limit}`

        get(`/campaign/list?${filterQuery}`)
            .then(res => {
             
                let result = res?.data
                const count = res?.count
                const totalPage = Math.ceil(Number(count) / limit)
                setTotalPage(totalPage)
                setCount(count)
                result.map((item, index) => {
                    item.id = index + 1 + (page - 1) * limit
                    item.date = item?.campaignDetail?.date
                    item.campaign = item?.campaignDetail?.name
                    item.shortCode = item?.campaignDetail?.shortCode
                    item.budget =  `₹${item?.campaignDetail?.budget }`
                    item.Budget = item?.campaignDetail?.budget
                    item.Leads = item?.lead 
                    item.student = item?.registeredStudent
                    item.addedBy = item?.addedBy?.name
                    item.clicks = item?.totalClicks
                    item.options = (
                        <div>
                            <i
                                className="fas fa-eye"
                                style={{
                                    fontSize: "1em",
                                    cursor: "pointer",
                                    marginRight: "0.5rem",
                                }}
                                onClick={(event) => {
                                    event.preventDefault();
                                    setState(true);
                                    setSelectedItem(item)
                                    handleOpenModal(item._id)
                                    setTrackClickObj([
                                        {
                                        campaign:item?.campaignDetail?.name,  
                                        leads:item.Leads,
                                        clicks:item.clicks,
                                        students:item.student,
                                        perLead : item.Leads == 0 ? 0 : (item?.Budget / item.Leads).toFixed(2),
                                        perClick : item.clicks == 0 ? 0 : (item?.Budget / item.clicks).toFixed(2),
                                        perStudent:item.student == 0 ? 0 : (item?.Budget / item.student).toFixed(2),
                                    }])

                                }}>

                            </i>
                            <i
                                className="far fa-edit"
                                style={{
                                    fontSize: "1em",
                                    cursor: "pointer",
                                    marginRight: "0.5rem",
                                }}
                                onClick={() => handleEdit(item)}

                            ></i>
                            <i
                                className="far fa-trash-alt"
                                style={{ fontSize: "1em", cursor: "pointer" }}
                                onClick={() => {
                                    handleDelete(item?._id)
                                }}

                            ></i>
                        </div>
                    )
                })
                setTableData(result)
              
            })
    }


    const handleExportData = async () => {
        try {
          setIsLoading(true)
          let res = await get(
            `/campaign/list?limit=${count}`
        )
          const data = res?.data
          const exportData = []
          if (data && Array.isArray(data) && data.length > 0) {
            data.map((item, index) => {
              const dataExport = {}
              dataExport["SI NO"] = index + 1
              dataExport.Date = item?.campaignDetail?.date
              dataExport.Campaign = item?.campaignDetail?.name
              dataExport['Short Code'] = item?.campaignDetail?.shortCode
              dataExport.Clicks = item?.totalClicks
              dataExport.Leads = item?.lead 
              dataExport.Budget = item?.campaignDetail?.budget
              dataExport['Added By'] = item?.addedBy?.name
             exportData.push(dataExport)
            })
            jsonToExcel(exportData, `Campaign-list-${moment().format("DD-MM-YY")}`)
            setIsLoading(false)
          } else {
            setIsLoading(false)
            toastr.info(`There are No data to export`)
          }
        } catch (error) {
          setIsLoading(false)
          toastr.info('Failed to export data. Please try again.');
        }
      }

    useEffect(() => {
        handleTableData()
    }, [page])

    
const handleChange = (event, value) => {
    setPage(value)
    
  }

  const trackClick_data = {
    columns: [
        {
            label: "#",
            field: "campaign",
            sort: "asc",    
            width: 150,
        },
        {
            label: "Clicks",
            field: "clicks",
            sort: "asc",
            width: 150,
        },
        {
            label: "Lead",
            field: "leads",
            sort: "asc",
            width: 150,
        },
        {
            label: "Student",
            field: "students",
            sort: "asc",
            width: 150,
        },
        {
            label: "Cost Per Clicks",
            field: "perClick",
            sort: "asc",
            width: 150,
        },
        {
            label: "Cost Per Lead",
            field: "perLead",
            sort: "asc",
            width: 150,
        },
        {
            label: "Cost Per Student",
            field: "perStudent",
            sort: "asc",
            width: 150,
        },

    ],
    rows:trackClickObj

}

    const followup_data = {
        columns: [
            {
                label: "Course/Event",
                field: "Name",
                sort: "asc",
                width: 150,
            },
            {
                label: "URL",
                field: "url",
                sort: "asc",
                width: 150,
            },
            {
                label: "Click",
                field: "clicks",
                sort: "asc",
                width: 150,
            },
            {
                label: "Lead",
                field: "lead",
                sort: "asc",
                width: 150,
            },
            {
                label: "Convension",
                field: "convensions",
                sort: "asc",
                width: 150,
            },

        ],
        rows:result.map((item,index)=>{
             
            return {
                clicks: item?.click_details?.clicks,
                Name: item?.courseORevent?.name,
                lead:item?.click_details?.leads,
                convensions: item?.click_details?.convension,
                url: <>
                    <a href="/">{ item?.click_details?.url}</a></>
            }
        }) 
 
    }
    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                width: 150,
            },
            {
                label: "Date",
                field: "date",
                width: 150,
            },
            {
                label: "Campaign ",
                field: "campaign",
                width: 150,
            },
            {
                label: "Short Code",
                field: "shortCode",
                width: 150,
            },

            {
                label: "Clicks ",
                field: "clicks",
                width: 150,
            },
            {
                label: "Leads",
                field: "Leads",
                width: 150,
            },
            {
                label: "Student",
                field: "student",
                width: 150,
            },
            {
                label: "Budget",
                field: "budget",
                width: 150,
            },

            {
                label: "Added By",
                field: "addedBy",
                width: 150,
            },

            {
                label: "Actions",
                field: "options",
                sort: "desc",
                width: 400,
            },

        ],
        // rows: dataforTable 
        rows: tableData
    };
    return (
        <>
            <div className='page-content'>
                <div className='container-fluid'>
                    <Breadcrumbs title="Home" breadcrumbItem="Manage Campaign" />
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <AvForm onValidSubmit={handleSubmit} ref={formRef}
                                    >
                                        <Row>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Campaign Name</Label>
                                                    <AvField
                                                        name="name"
                                                        value={listData.name || ""}
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Campaign Name"
                                                        errorMessage="Enter Campaign "
                                                        onChange={handleValueChange}
                                                        validate={{ 
                                                                        required: { value: true, errorMessage: "Campaign Name is required" },
                                                            pattern: { 
                                                                value: /^[A-Za-z\s]+$/, 
                                                                errorMessage: "Campaign Name must contain only alphabetic characters" 
                                                            } 
                                                        }}                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Short Code</Label>
                                                    <AvField
                                                        name="shortCode"
                                                        value={listData.shortCode || ""}
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Short Code"
                                                        onChange={handleValueChange}
                                                        errorMessage='Short Code is required'
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Budget</Label>
                                                    <AvField
                                                        name="budget"
                                                        value={listData.budget || ""}
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Budget"
                                                        errorMessage="Budget is required"
                                                        onChange={handleValueChange}
                                                        validate={{ 
                                                            required: { value: true, errorMessage: "Budget is required" },
                                                            pattern: { 
                                                                value: /^\d+$/, 
                                                                errorMessage: "Budget must be a numeric value" 
                                                            } 
                                                        }}                                                    />
                                                </div>
                                            </Col>
                                            <Col>

                                                <div className="mb-3" style={{ paddingTop: "25px" }}>

                                                    <Button className='mx-2'
                                                        color={`${listData._id ? "warning" : "primary"}`}
                                                        type='submit'
                                                    // disabled={addingcountry ? true : false}

                                                    >
                                                        {listData._id ? "Update" : "Submit"}

                                                    </Button>
                                                    <Button color="danger" type="reset" onClick={() => reset()}  >
                                                        Reset
                                                    </Button>
                                                </div>

                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>


                    <Row>
                        <Col className='col-12'>
                            <Card>
                                <CardBody>
                      <div className="p-2 pt-0 d-flex justify-content-end me-1 absolute top-10 ">
                       {isLoading ? 
                        <Spinner
                        color="dark"
                        size="sm"
                        style={{
                            position: "absolute",
                            top: "36px",
                            right: "174px",
                            zIndex: "3",
                          }}                      >
                        Loading...
                      </Spinner> :
                        <TooltipMui
                          title={"Export"}
                          arrow
                          placement="top"
                          id="button-tooltip-2"
                        >
                          <i
                            onClick={handleExportData}
                            className="cursor-pointer fs-4 fas fa-file-export"
                            style={{
                              position: "absolute",
                              top: "36px",
                              right: "172px",
                              zIndex: "3",
                            }}
                          ></i>
                        </TooltipMui>
                           }
                      </div>

                                    <MDBDataTable
                                        id="CampaignId"
                                        responsive
                                        bordered
                                        info={true}
                                        searching={true}
                                        entries={10}
                                        disableRetreatAfterSorting={true}
                                        data={data}
                                        paginationLabel={false}
                                        paging={false}
                                       
                                      />

                                    

                                    <TablePagination
                                    page={page}
                                    onChange={handleChange}
                                    count={totalPage}
                                    />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>



                </div>
            </div>


            <Modal
                show={state}
                isOpen={state}
                toggle={() => setState(!state)}
                size="xl"
                centered={true}
            >
                <div className="modal-header">
                    <div className="modal-title" >
                        <h5 style={{ marginBottom: "0px" }}>
                          {result.name} Campaign Details
                        </h5>

                    </div>
                    <button
                        type="button"
                        onClick={handleCloseModal}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>

              
                <div className="modal-body">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <table className='table tbl-u-boarderd' id="followupmodalId">
                                     

                                        <tbody>
                                            <tr>
                                                <th>Added Date</th>
                                                <th>:</th>
                                                <td>{selctedItem?.date}</td>

                                                <th>Campaign</th>
                                                <th>:</th>
                                                <td>{selctedItem?.campaignDetail?.name}</td>

                                                <th>Short Code</th>
                                                <th>:</th>
                                                <td style={{ textAlign: "left" }}>{selctedItem?.shortCode}</td>
                                            </tr>

                                            <tr>
                                                <th>Budget</th>
                                                <th>:</th>
                                                <td>{selctedItem?.budget}</td>

                                                <th>Added By</th>
                                                <th>:</th>
                                                <td style={{ textAlign: "left" }}>{selctedItem?.addedBy}</td>

                                                <td style={{ textAlign: "right" }}>{}</td>


                                            </tr>
                                        </tbody>


                                    </table>
                                </CardBody>
                            </Card>
                        </Col>

                        <h5 style={{ marginBottom: "5px", fontSize: "14px"}}>
                        Track Click Details
                        </h5>

                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <MDBDataTable
                                        id="campaigntrackClick_data"
                                        responsive
                                        bordered
                                        data={trackClick_data}
                                        searching={true}
                                        info={true}
                                        disableRetreatAfterSorting={true}
                                        entries={20}
                                    />
                                </CardBody>
                            </Card>
                        </Col> <Col md="12" lg="12">

                        </Col>

                        <h5 style={{ marginBottom: "5px", fontSize: "14px"}}>
                        Campaign URLs                       
                         </h5>

                        {result.length > 0 &&
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <MDBDataTable
                                        id="campaignpopupid"
                                        responsive
                                        bordered
                                        data={followup_data}
                                        searching={true}
                                        info={true}
                                        disableRetreatAfterSorting={true}
                                        entries={20}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        }
                    </Row>
                </div>

                {/* )} */}
            </Modal>
        </>
    )
}

export default Campaign