import React, { useState, useEffect } from "react"
import { Modal, Row, Col, Label, Button } from "reactstrap"
import { get, post, put } from "../../../../helpers/api_helper"
import { minutesToHour } from "../../../../helpers/functions"
import moment from "moment"
import Select from "react-select"
import toastr from "toastr"

import { AvField, AvForm } from "availity-reactstrap-validation"
const SessionHistory = ({ report, month, isOpen, onClick }) => {
  const [requestData, setReportData] = useState([])

  const [isNewSession, setIsNewSession] = useState(false)
  const [sessionOptions, setSessionOptions] = useState([])
  const [masterObject, setMasterObject] = useState({
    session: "",
    remarks: "",
  })

  useEffect(() => {
    if (isOpen) handleAttendance()
    // eslint-disable-next-line
  }, [isOpen])

  const handleAttendance = async () => {
    try {
      const response = await get(
        `attendance/work-report/history?trainer=${report.trainer}&month=${month}&batch=${report.batch}`
      )
      const data = response.data
      setReportData(data)
    } catch (err) {}
  }

  const handleStatusChange = async (session, time, status) => {
    try {
      const response = await put(`attendance/work-report/status/session`, {
        trainer: report.trainer,
        batch: report.batch,
        month,
        session,
        status,
        time,
      })
      toastr.success(response.message)
      handleAttendance()
    } catch (error) {
      console.log(error)
      toastr.error(error.response?.data?.message)
    }
  }

  const handleSessionOptions = async () => {
    try {
      const response = await get(
        `attendance/work-report/options/session?trainer=${report.trainer}&month=${month}&id=${report.batch}`
      )
      const data = response.data
      setSessionOptions(data)
    } catch (error) {}
  }
  const handleAddSession = async () => {
    try {
      const id = requestData._id
      const batch = report.batch
      const trainer = report.trainer
      const data = masterObject.session
      const remarks = masterObject.remarks

      const response = await post(`attendance/work-report/session/add`, {
        id,
        batch,
        trainer,
        data,
        remarks,
      })
      toastr.success(response.message)
      reset()
    } catch (error) {
      console.error(error)
      toastr.error(error.response?.data?.message)
    }
  }

  const addSession = async () => {
    setIsNewSession(true)
    handleSessionOptions()
    // const data = { ...requestData }
    // data?.data?.push({})
    // setReportData(data)
  }
  const handleValueChange = async (value, name) => {
    const obj = { ...masterObject }
    obj[name] = value
    setMasterObject(obj)
  }
  const reset = () => {
    handleAttendance()
    setMasterObject({ session: "", remarks: "" })
    setIsNewSession(false)
    setSessionOptions([])
  }

  return (
    <React.Fragment>
      <Modal
        size="xl"
        centered
        isOpen={isOpen}
        toggle={() => {
          onClick()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Session history</h5>
          <button
            type="button"
            onClick={() => onClick()}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <Row>
            <Col md={12}>
              <ul className="lead-details">
                <li>
                  Trainer :{" "}
                  <Label>{`${requestData.trainerFirstName || ""} ${
                    requestData.trainerLastName || ""
                  }`}</Label>
                </li>
                <li>
                  Username : <Label>{requestData.trainerUsername || ""}</Label>
                </li>
                <li>
                  Branch : <Label>{requestData.branch}</Label>
                </li>
                <li>
                  Batch : <Label>{requestData.batch}</Label>
                </li>
                <li>
                  Course : <Label>{requestData.course}</Label>
                </li>
                <li>
                  Total Days : <Label>{requestData.totalDays}</Label>
                </li>
                <li>
                  Total Sessions : <Label>{requestData.totalSessions}</Label>
                </li>
                <li>
                  Total Hours :{" "}
                  <Label>{minutesToHour(requestData.minutes)}</Label>
                </li>
              </ul>
            </Col>
            <Col md={12}>
              <table
                className="table table-bordered mb-0 table-hover table"
                id="sessionHistoryTable"
              >
                <thead>
                  <tr>
                    <th>Session</th>
                    <th>Date</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Hours</th>
                    <th>Staff</th>
                    <th>Remove</th>
                  </tr>
                </thead>
                <tbody>
                  {requestData?.data?.map(item => {
                    const date = moment(item.date).format("DD-MM-YYYY")
                    const from = moment(item.fromTime, "HH:mm:ss").format(
                      "hh:mm a"
                    )
                    const to = moment(item.toTime, "HH:mm:ss").format("hh:mm a")
                    const time = minutesToHour(item.time)
                    const staff =
                      (item.addedByFirstName ? item.addedByFirstName : "") +
                      " " +
                      (item.addedByLastName ? item.addedByLastName : "")
                    return (
                      <tr key={item.session}>
                        {/* {item.session ? ( 
                          <> */}
                        <td>{item.session}</td>
                        <td>{date}</td>
                        <td>{from}</td>
                        <td>{to}</td>
                        <td>{time}</td>
                        <td>{staff}</td>
                        <td>
                          {item.status === 1 ? (
                            <i
                              className={"bx bx-reset"}
                              onClick={() => {
                                handleStatusChange(item.sessionId, item.time, 0)
                              }}
                              style={{
                                fontSize: "1.5rem",
                                cursor: "pointer",
                                marginLeft: "0.2em",
                                marginRight: "0.5em",
                              }}
                            ></i>
                          ) : (
                            <i
                              className={"fas fa-trash"}
                              onClick={() =>
                                handleStatusChange(item.sessionId, item.time, 1)
                              }
                              style={{
                                fontSize: "1em",
                                cursor: "pointer",
                                marginLeft: "0.2em",
                                marginRight: "0.5em",
                              }}
                            ></i>
                          )}
                        </td>
                        {/* </> 
                          ) : (
                           <>
                             <td>
                               <Select options={[]} size="sm" />
                             </td>

                             <td></td>
                             <td></td>
                             <td></td>
                             <td></td>

                             {item.sessionId ? (
                               <td>
                                 <Input
                                   name="remarks"
                                   placeholder="Remarks"
                                 ></Input>
                               </td>
                             ) : (
                               <td></td>
                             )}
                             <td>
                               <Button color="primary" size="sm">
                                 Add
                               </Button>
                             </td>
                           </>
                         )} */}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              {!isNewSession && (
                <div className="d-flex justify-content-end mt-3 mb-3">
                  <button
                    type="button"
                    onClick={() => addSession()}
                    className="waves-effect btn btn-outline-light d-flex"
                    style={{ gap: "5px" }}
                  >
                    Add more <i className="mdi mdi-plus-circle-outline"></i>
                  </button>
                </div>
              )}
            </Col>
            {isNewSession && (
              <AvForm onValidSubmit={handleAddSession}>
                <Row>
                  <Col md={3} className="mt-3">
                    <Label>Session</Label>
                    <Select
                      options={sessionOptions}
                      value={masterObject.session || ""}
                      onChange={selected =>
                        handleValueChange(selected, "session")
                      }
                    />
                  </Col>
                  <Col md={3} className="mt-3">
                    <Label>Remarks</Label>
                    <AvField
                      name="remarks"
                      placeholder="Remarks"
                      value={masterObject.remarks || ""}
                      onChange={e =>
                        handleValueChange(e.target.value, "remarks")
                      }
                    ></AvField>
                  </Col>
                  <Col md={2} className="mt-3">
                    <Button
                      color="primary"
                      style={{ marginTop: "26px" }}
                      disabled={!masterObject.session?.value}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </AvForm>
            )}
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default SessionHistory
