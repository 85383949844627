import PropTypes from "prop-types"
import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import CountUp from "react-countup"
import { useNavigate } from "react-router-dom"

const MiniWidget = props => {
  const navigate = useNavigate()
  const handleNavigate = url => {
    if (!url) return
    navigate(url)
  }
  return (
    <Row>
      {props.reports.map((report, key) => (
        <Col md={6} xl={key < 3 ? 4 : 3} key={key}>
          <div className="card-section">
            <Card>
              <CardBody onClick={() => handleNavigate(report.url)} className="mini-body">
                <div className="float-end mb-2">
                  <i
                    style={{ marginTop: "2px", fontSize: "30px" }}
                    className={report.icon2}
                  ></i>
                </div>
                <div>
                  <h4 className="mb-1 mt-1">
                    <span>
                      {report.icon ? (
                        <i
                          style={{ fontSize: "18px" }}
                          className={report.icon + " me-1"}
                        ></i>
                      ) : null}
                      <CountUp
                        end={report.value}
                        separator=","
                        prefix={report.prefix}
                        suffix={report.suffix}
                        decimals={report.decimal}
                      />
                    </span>
                  </h4>
                  <p className="text-muted mb-0">{report.title}</p>
                </div>
                {/* {key >= 3 ? (
                  <p className="text-muted mt-3 mb-0">
                    <span className={"text-" + report.color + " me-1"}>
                      {report.icon ? (
                        <i className={report.icon + " me-1"}></i>
                      ) : null}
                      {report.badgeValue}
                    </span>{" "}
                    {report.desc}
                  </p>
                ) : (
                  ""
                )} */}
              </CardBody>
            </Card>
          </div>
        </Col>
      ))}
    </Row>
  )
}

export default MiniWidget

MiniWidget.propTypes = {
  reports: PropTypes.array,
}
