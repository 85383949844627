import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Modal,
  Row,
  Spinner,
} from "reactstrap"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import "./style.scss"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { useLocation } from "react-router-dom"
import { get, post } from "../../../helpers/api_helper"
import MuiCustomTablePagination from "../../../components/Common/TablePagination"
import toastr from "toastr"
import { API_URL, PRIVILEGES } from "../../../config"
import { isAllowed } from "../../../components/Common/Allowed"
import moment from "moment"
import { OverlayTrigger, Tooltip } from "react-bootstrap"


const TpCertification = () => {
  const { state } = useLocation()

  const [csp, setCsp] = useState({ label: "Select", value: "" })
  const [batch, setBatch] = useState({ label: "Select", value: "" })
  const [cspList, setCspList] = useState([])
  const [batchList, setBatchList] = useState([])
  const [toPublish, setToPublish] = useState([])
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(20)
  const [search, setSearch] = useState("")
  const [tableData, setTableData] = useState([])
  const [details, setDetails] = useState({})
  const [count, setCount] = useState(0)
  const [refresh, setRefresh] = useState(1)
  const [selectAll, setSelectAll] = useState(false)
  const [loading, setLoading] = useState(false)
  const [updatedTime,setUpdatedTime] = useState('')

  const getDateAndTime = () => {
    const now = moment();
    setUpdatedTime(now.format('DD/MM hh:mm A'))
  }

  const allowed = isAllowed([
    PRIVILEGES.ADMIN,
    PRIVILEGES.CSP_PROGRAM_MANAGER,
    PRIVILEGES.CERTIFICATE_PM,
  ])

  const fetchCspList = () => {
    get(`certification/options/tp-assessment-branch`).then(res => {
      if (res.success) {
        setCspList(res.data)
      }
    })
  }

  const fetchBatchList = () => {
    get(`certification/options/tp-assessment-batch?branch=${csp.value}`).then(
      res => {
        if (res.success) {
          if (batchList.length !== 0) {
            setBatch({ label: "Select...", value: "" })
          }
          setBatchList(res.data)
        }
      }
    )
  }

  const fetchTableData = () => {
    setLoading(true)
    get(
      `certification/students-tp?batch=${batch.value}&page=${
        page + 1
      }&limit=${pageSize}&keyword=${search}`
    )
      .then(res => {
        if (res.success) {
          getDateAndTime()
          const data = res.data[0]
          setCount(res.count)
          setDetails({
            id: data._id,
            name: data.name,
            batchId: data.batchId,
            branch: data.branch.name,
            course: data.course.name,
          })
          setTableData(
            data?.courseAssigned.map((item, index) => ({
              ...item,
              id: index + 1 + page * pageSize,
              student: `${item.firstName || ""} ${item.lastName || ""}`,
              regNo: item.unique_id || "",
            }))
          )
        }
      })
      .catch(err => toastr.error(err.response.data.message))
    setTimeout(() => setLoading(false), 1000)
  }

  const uploadImage = async e => {
    const selectedFile = e.target.files[0]
    setLoading(true)
    if (selectedFile) {
      try {
        const fd = new FormData()
        fd.append("certificate", selectedFile)
        fd.append("batch", batch.value)
        const response = await post(`certification/tp-bulkupload`, fd)
        if (response.code === 200) {
          toastr.success(response.message)
          setRefresh(prev => prev + 1)
        }
      } catch (error) {
        toastr.error(error.response.data.message)
      }
    }
    setLoading(false)
  }

  const handleCheckBox = id => {
    const index = toPublish.indexOf(id)
    if (index === -1) {
      setToPublish([...toPublish, id])
    } else {
      setToPublish(toPublish.filter(item => item !== id))
    }
  }

  const handleSelectAll = () => {
    if (selectAll) {
      setToPublish([])
    } else {
      setToPublish(
        tableData
          .filter(item => item?.tpCertificate && !item?.tpCertificatePublish)
          .map(item => item._id)
      )
    }
    if (
      tableData
        .filter(item => item?.tpCertificate && !item?.tpCertificatePublish)
        .map(item => item._id).length !== 0
    ) {
      setSelectAll(prev => !prev)
    }
  }

  const handlePublish = () => {
    let postBody
    if (selectAll) {
      postBody = {
        selectAll: true,
        batch: details.id,
      }
    } else {
      postBody = {
        _id: toPublish,
      }
    }
    post(`certification/tp-publish`, postBody)
      .then(res => {
        if (res.success) {
          toastr.success(res.message)
          setRefresh(prev => prev + 1)
          setSelectAll(false)
          setToPublish([])
        }
      })
      .catch(err => toastr.error(err.message))
  }

  useEffect(() => {
    if (batch.value) {
      fetchTableData()
    }
    //eslint-disable-next-line
  }, [batch, page, pageSize, search, refresh])

  useEffect(() => {
    if (state && state?.batchId) {
      setBatch({ label: state.batch, value: state.batchId })
      setCsp({ label: state.csp, value: state.cspId })
    }
    fetchCspList()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (csp.value) {
      fetchBatchList()
    }
    // eslint-disable-next-line
  }, [csp])

  const handleReset = () => {
    setTableData([])
    setDetails({})
    setCsp({ label: "Select", value: "" })
    setBatch({ label: "Select", value: "" })
    setToPublish([])
    setSelectAll(false)
    setPage(0)
    setBatchList([])
    setUpdatedTime('')
  }

  const rows = tableData.map((item, index) => ({
    ...item,
    certificate: (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {item?.tpCertificate === 1 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "baseline",
            }}
          >
            <a
              style={{ cursor: "pointer" }}
              href="#"
              arrow
              onClick={() => handleView(item?.tpCertificatePath)}
            >
              <i
                style={{
                  cursor: "pointer",
                  fontSize: "1.5em",
                  marginRight: "0.5rem",
                }}
                className="far fa-file-pdf"
              ></i>
              {item.tpCertificateId || "Certificate ID"}
            </a>
            {item.tpCertificatePublish && (
              <Tooltip title="Published" arrow>
                <i
                  style={{ color: "#009100", marginLeft: "10px" }}
                  className="fas fa-check"
                ></i>
              </Tooltip>
            )}
            {!item?.tpCertificatePublish && (
              <Tooltip title="Select to publish" arrow placement="bottom">
                <div style={{ marginLeft: "10px" }}>
                  <Input
                    checked={toPublish.includes(item._id)}
                    onClick={() => handleCheckBox(item._id)}
                    type="checkbox"
                  />
                </div>
              </Tooltip>
            )}
          </div>
        ) : item?.tpCertificate === 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            No Certificate Submitted
          </div>
        ) : (
          ""
        )}
      </div>
    ),
  }))

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Student",
        field: "student",
        width: 150,
      },
      {
        label: "Register No.",
        field: "regNo",
        width: 150,
      },
      {
        label: `Certificate `,
        field: "certificate",
        width: 50,
      },
    ],
    rows,
  }

  const handleChangeRowsPerPage = event => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handlePageChange = newPage => {
    setPage(newPage)
  }

  const handleView = path => {
    const openPath = `${API_URL}${path}`
    window.open(openPath, "_blank")
  }
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumb title="Home" breadcrumbItem="TP Assessment Certificate" />

        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <Row>
                  <Col md="12">
                    <Col xl={12}>
                      <Row className="nav-row">
                        <Col md="12">
                          <Col xl={12}>
                            <Row>
                              <Col md={3}>
                                <div>
                                  <Label>CSP Name</Label>
                                  <Select
                                    name="student"
                                    classNamePrefix="select2-selection"
                                    options={cspList}
                                    onChange={item => setCsp(item)}
                                    value={csp}
                                  />
                                </div>
                              </Col>
                              <Col md="3">
                                <div>
                                  <Label>Batch</Label>
                                  <Select
                                    name="batch"
                                    classNamePrefix="select2-selection"
                                    options={batchList}
                                    onChange={item => setBatch(item)}
                                    value={batch}
                                    width={50}
                                    isDisabled={csp.value === ""}
                                  />
                                </div>
                              </Col>

                              <Col>
                                <Button
                                  type="button"
                                  style={{
                                    marginTop: "1.7rem",
                                    marginLeft: "1rem",
                                  }}
                                  color="danger"
                                  onClick={handleReset}
                                >
                                  Reset
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Col>
                      </Row>
                    </Col>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <table className="table" id="batchtableId">
                      <tbody>
                        <tr>
                          <th>CSP</th>
                          <th>:</th>
                          <td>{details?.branch}</td>
                          <th>Batch</th>
                          <th>:</th>
                          <td>{details?.name}</td>
                          <th>Course</th>
                          <th>:</th>
                          <td>{details?.course}</td>
                          <td style={{ width: "300px" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                              }}
                            >
                              {allowed && (
                                <div>
                                  <Label marginLeft={5}>
                                    <Input
                                      style={{ whiteSpace: "nowrap" }}
                                      onClick={handleSelectAll}
                                      checked={selectAll}
                                      disabled={tableData.length === 0}
                                      type="checkbox"
                                    />{" "}
                                    Select All
                                  </Label>
                                </div>
                              )}
                              <>
                                {allowed && (
                                  <Button
                                    color="primary"
                                    style={{marginLeft:'15px'}}
                                    onClick={handlePublish}
                                    disabled={
                                      tableData.length === 0 ||
                                      toPublish.length === 0
                                    }
                                  >
                                    Publish
                                  </Button>
                                )}
                                <OverlayTrigger
                                  placement="top"
                                  disabled={batch.value === ""}
                                  overlay={
                                    <Tooltip
                                      id="button-tooltip-2"
                                      style={{ position: "absolute" }}
                                    >
                                      {loading ? "Refreshing..." : "Refresh"}
                                    </Tooltip>
                                  }
                                >
                                  <div
                                    style={{
                                      height: "37px",
                                      marginLeft:'10px'
                                    }}
                                  >
                                    <Button
                                      color="success"
                                      onClick={() =>
                                        setRefresh(prev => prev + 1)
                                      }
                                      style={{ height: "100%", width: "60px" }}
                                      disabled={loading || batch.value === ""}
                                      outline
                                    >
                                      {loading ? (
                                        <Spinner
                                          style={{
                                            height: "20px",
                                            width: "20px",
                                          }}
                                        />
                                      ) : (
                                        <i class="fas fa-redo"></i>
                                      )}
                                    </Button>
                                  </div>
                                </OverlayTrigger>
                              </>
                            </div>
                            
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {updatedTime && <div style={{display:"flex",justifyContent:'flex-end'}}><p>Last Updated : {updatedTime}</p></div>}
                  </Col>
                </Row>
                <Row style={{ marginBottom: "10px" }}>
                  <Col md="6"></Col>
                  <Col md="6">
                    <AvForm
                      style={{
                        height: "35px",
                        marginBottom: "0px",
                        display: "flex",
                        displayDirection: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                       {allowed &&<div >
                        <div className="d-flex  flex-row justify-content-between align-items-baseline">
                          {/* <p style={{ color: "red" }}>
                            * Select a zip file to upload
                          </p> */}
                          <AvField
                            disabled={batch.value ? false : true}
                            style={{ width: "300px" }}
                            name="image"
                            type="file"
                            className="form-control"
                            id="validationCustom03"
                            rows="1"
                            message='Select a zip file to upload'
                            title="Select a zip file to upload"
                            accept=".zip"
                            onChange={e => uploadImage(e)}
                          />
                        </div>
                      </div>}
                      <Input
                      style={{width: "200px", marginLeft: "10px" }}
                      placeholder="Search..."
                      value={search}
                      onChange={e => setSearch(e.target.value)}
                    ></Input>
                    </AvForm>
                    
                  </Col>
                </Row>
                <MDBDataTable
                  id="tpcertificateTableId"
                  responsive
                  bordered
                  info={true}
                  searching={false}
                  entries={20}
                  paging={false}
                  disableRetreatAfterSorting={true}
                  data={data}
                  totalPage={20}
                />
                {tableData?.length > 0 && count > 10 && (
                  <MuiCustomTablePagination
                    count={count}
                    page={page}
                    rowsPerPage={pageSize}
                    handleChangePage={handlePageChange}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default TpCertification
