import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  BreadcrumbItem,
  CardHeader,
} from "reactstrap"
import Stack from "@mui/material/Stack"
import { API_URL } from "../../../config"
import { MDBDataTable } from "mdbreact"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import FactCheckIcon from "@mui/icons-material/FactCheck"
import BallotIcon from "@mui/icons-material/Ballot"
import AssignmentIcon from "@mui/icons-material/Assignment"
import BookmarkIcon from "@mui/icons-material/Bookmark"
import SummarizeIcon from "@mui/icons-material/Summarize"
import { Box, Divider } from "@mui/material"
import { AvForm } from "availity-reactstrap-validation"
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap"
import Select from "react-select"
import { Link, useLocation, useParams } from "react-router-dom"
import MiniWidget from "./mini-widget"
import "./style.scss"
import { get } from "../../../helpers/api_helper"
import Summary from "./Tabs/Summary"
import Applied from "./Tabs/Applied"
import Saved from "./Tabs/Saved"
import Placed from "./Tabs/Placed"
import Shortlisted from "./Tabs/Shoetlisted"
import moment from "moment"
const ViewJob = props => {
  // const { jobId } = props.match.params;
  const { state } = useLocation()

  const { jobId } = useParams()

  const [popupView, setPopupView] = useState(false)
  const [popupView2, setPopupView2] = useState(false)
  const [popupView3, setPopupView3] = useState(false)
  const [popupView4, setPopupView4] = useState(false)

  const [tab3, setTab3] = React.useState("5")
  const [popupSavedView, setPopupSavedView] = useState(false)
  const [popupAppliedView, setPopupAppliedView] = useState(false)
  const [popupShortlistedView, setPopupShortlistedView] = useState(false)
  const [popupPlacedView, setPopupPlacedView] = useState(false)
  const [showDeclineAppliedForm, setShowDeclineAppliedForm] = useState(false)
  const [showDeclinePlacedForm, setShowDeclinePlacedForm] = useState(false)
  const [showDeclineShortlistedForm, setShowDeclineShortlistedForm] =
    useState(false)

  const handleChangeTab = (event, newValue) => {
    setTab3(newValue)
    // resetPaginateJob();
    // setSearchValueJob("");

    // resetPaginateApp();
    // setSearchValueApp("");

    // resetPaginateShort();
    // setSearchValueShort("");

    // resetPaginatePlaced();
    // setSearchValuePlaced("");

    // resetPaginateRemarks();
    // setSearchValueActivity("");
  }
  const [jobList, setJobList] = useState({})
  useEffect(() => {
    fetchdatalist()
  }, [])

  function fetchdatalist() {
    get(`/job?id=${state.id}`).then(res => {
      setJobList(res?.data)
    })
  }


  return (
    <React.Fragment>

      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">Job Post - {jobList?.name}</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      <Link to="#">Home</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="/create-job-post">Job Post</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="#"> {jobList?.name}</Link>
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              {/* First Dialogue */}

              <div>
                <div className="row">
                  <div className="col-xl-3">
                    <div
                      className="card"
                      style={{
                        // width: "fit-content",
                        // width: "351px",
                        height: "max-content",
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                      }}
                    >
                      <div className="card-body">
                        <div className="float-end dropdown"></div>

                        <h5
                          className="mt-2 mb-2"
                          style={{
                            textAlign: "center",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}
                        >
                          {jobList?.name}
                        </h5>
                        <Stack
                          direction="row"
                          style={{ justifyContent: "center" }}
                        >
                          <img
                            alt="Image"
                            src={`${API_URL}${jobList?.image}`}
                            style={{
                              width: "5rem",
                              height: "4rem",
                              //marginTop: "20%",
                              // width: "100%",
                              borderRadius: "5px",
                            }}
                          />
                        </Stack>
                        <div
                          className="text-muted"
                          style={{
                            textAlign: "center",
                            color: "green !important",
                            fontFamily: "IBM Plex Sans,sans-serif",
                            fontSize: "14px",
                            marginBottom: "3px",
                          }}
                        ></div>
                        {jobList.status !== undefined ? (
                          <p
                            style={{
                              textAlign: "center",
                              marginBottom: "3px",
                              color:
                                jobList.status === 0
                                  ? "green"
                                  : jobList.status === 2
                                  ? "orange"
                                  : jobList.status === 3
                                  ? "red"
                                  : jobList.status === 4
                                  ? "blue"
                                  : "",
                            }}
                          >
                            {jobList.status === 0
                              ? "Ongoing"
                              : jobList.status === 2
                              ? "Inactive"
                              : jobList.status === 3
                              ? "Expired"
                              : jobList.status === 4
                              ? "Filled"
                              : ""}
                          </p>
                        ) : null}
                        <Divider />
                        <div
                          className="mt-2"
                          //   style={{
                          //     display: "flex",
                          //     alignItems: "center",
                          //     justifyContent: "space-between",
                          //   }}
                        >
                          <p className="mb-1">Date</p>
                          <h5 className="font-size-14">
                            {moment(jobList.date).format("DD-MM-YYYY")}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div
                          className="mt-2"
                          //   style={{
                          //     display: "flex",
                          //     alignItems: "center",
                          //     justifyContent: "space-between",
                          //   }}
                        >
                          <p className="mb-1">Recruiter</p>
                          <h5 className="font-size-14">
                            {jobList?.recruiter?.name}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2">
                          <p className="mb-1"> Sector</p>
                          <h5 className="font-size-14">
                            {jobList?.sector?.name}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2">
                          <p className="mb-1">Qualification</p>
                          <h5 className="font-size-14">
                            {" "}
                            {jobList?.qualification
                              ? jobList.qualification
                                  ?.map(el => el?.name)
                                  .join(", ")
                              : ""}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2">
                          <p className="mb-1">Experience</p>
                          <h5 className="font-size-14">
                            {" "}
                            {jobList?.experience === 0
                              ? "0-1 Years"
                              : jobList?.experience === 1
                              ? "1-3 Years"
                              : jobList?.experience === 2
                              ? "3-5 Years"
                              : jobList?.experience === 3
                              ? "5-7 Years"
                              : jobList?.experience === 4
                              ? "7-9 Years"
                              : jobList?.experience === 5
                              ? "9+ Years"
                              : ""}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2">
                          <p className="mb-1">Gender</p>
                          <h5 className="font-size-14">
                            {" "}
                            {jobList?.gender === 0
                              ? "Male"
                              : jobList?.gender === 1
                              ? "Female"
                              : jobList?.gender === 2
                              ? "Transgender"
                              : jobList?.gender === 3
                              ? "No preference"
                              : ""}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2">
                          <p className="mb-1">No. of Openings</p>
                          <h5 className="font-size-14"> {jobList?.openings}</h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2">
                          <p className="mb-1">Expiry Date</p>
                          <h5 className="font-size-14">
                            {" "}
                            {moment(jobList?.expiryDate).format("DD-MM-YYYY")}{" "}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2">
                          <p className="mb-1">State</p>
                          <h5 className="font-size-14">
                            {" "}
                            {jobList?.state
                              ? jobList.state?.map(el => el?.name).join(", ")
                              : ""}{" "}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2">
                          <p className="mb-1">District</p>
                          <h5 className="font-size-14">
                            {" "}
                            {jobList?.location
                              ? jobList.location?.map(el => el?.name).join(", ")
                              : ""}{" "}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div
                          className="mt-2"
                          //   style={{
                          //     display: "flex",
                          //     alignItems: "center",
                          //     justifyContent: "space-between",
                          //   }}
                        >
                          <p className="mb-1">Salary</p>
                          <h5 className="font-size-14">
                          &#x20B9; {jobList?.salaryFrom}-{jobList?.salaryTo} LPA
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2">
                          <p className="mb-1">Eligibility</p>
                          <h5 className="font-size-14">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: jobList?.eligibility?.contents,
                              }}
                            ></div>
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />

                        <div className="mt-2">
                          <p className="mb-1">Overview</p>
                          <h5 className="font-size-14">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: jobList?.overview?.contents,
                              }}
                            ></div>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-9" style={{ marginBottom: "30px" }}>
                    <div
                      className="mb-0 card"
                      style={{
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={tab3}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              indicatorColor="primary"
                              textColor="primary"
                              variant="fullWidth"
                              onChange={handleChangeTab}
                              aria-label="lab API tabs example"
                            >
                              <Tab
                                icon={
                                  <SummarizeIcon style={{ fontSize: "20px" }} />
                                }
                                label="Summary"
                                value="5"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <AssignmentIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Applied"
                                value="1"
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              {/* <Tab
                                icon={
                                  <BookmarkIcon style={{ fontSize: "20px" }} />
                                }
                                label="Saved"
                                value="2"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              /> */}
                              <Tab
                                icon={
                                  <FactCheckIcon style={{ fontSize: "20px" }} />
                                }
                                label="Shortlisted"
                                value="3"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <BallotIcon style={{ fontSize: "20px" }} />
                                }
                                label="Placed"
                                value="4"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                            </TabList>
                          </Box>
                          <TabPanel
                            value="1"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Applied data={jobId} />
                          </TabPanel>
                          <TabPanel
                            value="2"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Saved data={jobId} />
                          </TabPanel>
                          <TabPanel
                            value="3"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Shortlisted data={jobId} />
                          </TabPanel>
                          <TabPanel
                            value="4"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Placed data={jobId} />
                          </TabPanel>
                          <TabPanel
                            value="5"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            {/* <Row>
                              <MiniWidget reports={reports} />
                            </Row> */}
                            <Summary data={jobId} />
                          </TabPanel>
                        </TabContext>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ViewJob
