import React, { useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label, Tooltip } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import { post } from "../../../helpers/api_helper"
import axios from "axios"
import { API_URL } from "../../../config"
import toastr from "toastr"
import "./Certificates.scss"
function Certificates() {
  const [tbottom, settbottom] = useState(false)
  const initialState = {
    name: "",
    for: "",
    uploadedFile: null,
    fields: [{}],
  }
  const [masterObject, setMasterObject] = useState(initialState)
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Title",
        field: "title",
        width: 150,
      },
      {
        label: "Issue Date",
        field: "date",
        width: 150,
      },
      {
        label: "Certificate Type",
        field: "certificate",
        width: 150,
      },
      {
        label: "Student",
        field: "Student",
        width: 150,
      },

      {
        label: "Course",
        field: "Course",
        width: 150,
      },

      {
        label: "Actions",
        field: "options",
        sort: "desc",
        width: 400,
      },
    ],
    rows: [
      {
        id: "1",
        title: "Completion Certificate",
        date: "24-01-2024",
        certificate: "Completion ",
        Student: "Alan Job",
        Course: "General Duty Assistant",

        options: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="far fa-edit"
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
            ></i>
            <i
              className="fas fa-lock"
              id="TooltipBottom"
              style={{
                fontSize: "1em",
                cursor: "pointer",

                marginRight: "0.5rem",
              }}
            ></i>
            <Tooltip
              placement="top"
              isOpen={tbottom}
              target="TooltipBottom"
              toggle={() => {
                settbottom(!tbottom)
              }}
            >
              DigiLocker
            </Tooltip>
            <i
              className="far fa-trash-alt"
              style={{ cursor: "pointer", fontSize: "1em" }}
            ></i>
          </div>
        ),
      },
      {
        id: "2",
        title: "Participation Award ",
        date: "24-01-2024",
        certificate: "Participation ",
        Student: "Alan Job",
        Course: "General Duty Assistant",

        options: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="far fa-edit"
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
            ></i>
            <i
              className="fas fa-lock"
              id="TooltipBottom"
              style={{
                fontSize: "1em",
                cursor: "pointer",

                marginRight: "0.5rem",
              }}
            ></i>
            <Tooltip
              placement="top"
              isOpen={tbottom}
              target="TooltipBottom"
              toggle={() => {
                settbottom(!tbottom)
              }}
            >
              DigiLocker
            </Tooltip>
            <i
              className="far fa-trash-alt"
              style={{ cursor: "pointer", fontSize: "1em" }}
            ></i>
          </div>
        ),
      },
      {
        id: "3",
        title: "Completion Certificate",
        date: "24-01-2024",
        certificate: "Completion ",
        Student: "Alan Job",
        Course: "General Duty Assistant",

        options: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="far fa-edit"
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
            ></i>
            <i
              className="fas fa-lock"
              id="TooltipBottom"
              style={{
                fontSize: "1em",
                cursor: "pointer",

                marginRight: "0.5rem",
              }}
            ></i>
            <Tooltip
              placement="top"
              isOpen={tbottom}
              target="TooltipBottom"
              toggle={() => {
                settbottom(!tbottom)
              }}
            >
              DigiLocker
            </Tooltip>
            <i
              className="far fa-trash-alt"
              style={{ cursor: "pointer", fontSize: "1em" }}
            ></i>
          </div>
        ),
      },
    ],
  }
  const forOptions = [
    { label: "Student", value: 0 },
    { label: "Batch", value: 1 },
  ]
  const handleValueChange = (value, name) => {
    const obj = { ...masterObject }
    obj[name] = value
    setMasterObject(obj)
  }
  
 
  const handleChange = (value, name, index) => {
    const data = { ...masterObject }
    const pre = data.fields
    pre[index][name] = value

    data.fields = pre
    setMasterObject(data)
  }
  const handleFileChange = event => {
    const file = event.target.files[0]
    const name = event.target.name
    const fd = new FormData()
    fd.append(name, file)
    axios

      .post(`${API_URL}certification/image/certificate`, fd)
      .then(response => {
        if (response.data.status === 200) {
          setMasterObject({
            ...masterObject,
            [name]: response?.data?.data?.filename,
          })
        } else {
          toastr.error(response.data.message)
        }
      })
      .catch(error => {
        console.error("Error uploading image:", error)
      })
    // Update the master object with the file name
    // setMasterObject({
    //   ...masterObject,
    //   uploadfile: fileName,
    // })

    // Do any other processing with the file if needed
  }
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Certificate-Template" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Certificate Type</Label>
                          <Select
                            name="Domain Code"
                            options={[
                              { label: "Participation", value: "0" },
                              { label: "Achivement", value: "1" },
                              { label: "Completion", value: "2" },
                            ]}
                            errorMessage="Enter Category"
                          />
                        </div>
                      </Col>
                    <Col md="2">
                        <div className="mb-3">
                          <Label>Certificate For</Label>
                          <Select
                            // onChange={selected => {
                            //   handleSelectChange(selected, "Offer")
                            // }}

                            onChange={selected => {
                              // setSelectedFor(selected)
                              handleValueChange(selected.value, "for")
                            }}
                            // value={selectedFor}
                            name="for"
                            options={forOptions}
                            type="text"
                            errorMessage="Enter Category"
                          />
                        </div>
                      </Col> 
                      {masterObject?.for === 0 && (
                        <>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label>Student</Label>
                              <Select
                                name="students"
                                isMulti
                                // options={students}
                                // value={selectedStudents}
                                // onChange={selected => {
                                //   handleSelect(selected, "students")
                                // }}
                              />
                            </div>
                          </Col>
                        </>
                      )}
                      {masterObject?.for === 1 ? (
                        <>
                          <Col md="4">
                            <div className="mb-3">
                              <Label>Batch</Label>
                              <Select
                                isMulti
                                name="batches"
                                classNamePrefix="select2-selection"
                                // value={selectedBatch}
                                // onChange={selected => {
                                //   handleSelect(selected, "batches")
                                // }}
                                // options={batches}
                              />
                            </div>
                          </Col>
                        </>
                      ) : null}
                    
                    
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Certificate Name</Label>
                        
                          <AvField
                            name="Domain Code"
                            placeholder="Certificate Name"
                            // options={[
                            //   { label: "Participation", value: "0" },
                            //   { label: "Achivement", value: "0" },
                            //   { label: "Completion", value: "1" }
                            // ]}
                            errorMessage="Enter Category"
                          />
                        </div>
                      </Col>

                      {masterObject.fields && (
                        <Col xl={12} className="mt-4 mb-2">
                          <Col className="col-12">
                            <div className="table-responsive">
                              <table className="table table-bordered mb-0">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Field Name</th>
                                    <th>X-Axis</th>
                                    <th>Y-Axis</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {masterObject?.fields?.map((item, idx) => (
                                    <tr key={idx}>
                                      <td
                                        style={{
                                          width: "120px",
                                          textAlign: "center",
                                        }}
                                      >
                                        {idx + 1}
                                      </td>
                                      <td>
                                        <AvField
                                          name="name"
                                          // onChange={handleChangeInput}
                                          onChange={e =>
                                            handleChange(
                                              e.target.value,
                                              "name",
                                              idx
                                            )
                                          }
                                          type="text"
                                        />
                                      </td> 
                                      <td className="qualityframeworktd">
                                        <input
                                          style={{ textAlign: "end" }}
                                          name="minutes"
                                          type="number"
                                          onChange={e =>
                                            handleChange(
                                              e.target.value,
                                              "xaxis",
                                              idx
                                            )
                                          }
                                          className="form-control numberTime"
                                        />
                                      </td>
                                      <td className="qualityframeworktd">
                                        <input
                                          style={{ textAlign: "end" }}
                                          name="minutes"
                                          type="number"
                                          onChange={e =>
                                            handleChange(
                                              e.target.value,
                                              "yaxis",
                                              idx
                                            )
                                          }
                                          className="form-control numberTime"
                                        />
                                      </td>

                                      {masterObject.fields.length > 1 && (
                                        <td style={{ width: "30px" }}>
                                          <span
                                            className="mr-1 p-2"
                                            style={{
                                              fontSize: "1.125rem",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              const data = { ...masterObject }
                                              data?.fields?.splice(idx, 1)
                                              setMasterObject(data)
                                            }}
                                          >
                                            <i className="trash-btn mdi mdi-delete"></i>
                                          </span>
                                        </td>
                                      )}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              <button
                                type="button"
                                onClick={() => {
                                  const data = { ...masterObject }
                                  data.fields.push({})
                                  setMasterObject(data)
                                }}
                                className="waves-effect btn btn-outline-light d-flex"
                                style={{ gap: "5px" }}
                              >
                                Add more{" "}
                                <i className="mdi mdi-plus-circle-outline"></i>
                              </button>
                            </div>
                          </Col>
                        </Col>
                      )}
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Upload Photo
                          </Label> 
                          {masterObject?.uploadedFile ? (
                            <div className="img-wraps d-flex flex-column">
                              {masterObject?.uploadedFile &&
                              masterObject?.uploadedFile.startsWith("http") ? (
                                <img
                                  alt=""
                                  width="250"
                                  height="150"
                                  src={`${masterObject?.uploadedFile}`}
                                />
                              ) : (
                                <>
                                  <div
                                    key={masterObject?.uploadedFile}
                                    style={{
                                      position: "relative",
                                      minWidth: "21cm",
                                      minHeight: "29.7cm",
                                    }}
                                  >
                                    <img
                                      style={{ width: "100%", height: "100%" }}
                                      // width="500"
                                      // height="500"
                                      src={`${API_URL}uploads/eventsPdf/QrCode/${masterObject?.uploadedFile}`}
                                      alt=""
                                      srcset=""
                                    />
                                    <div className="divabsolute">
                                      {masterObject.fields.map((item, idx) => (
                                        <h3
                                          style={{
                                            top: `${item.xaxis}px`,
                                            right: `${item.yaxis}px`,
                                          }}
                                        >
                                          {item.name}
                                        </h3>
                                      ))}
                                    </div>
                                  </div>
                                  {/* <object
                                type="application/pdf"
                                  alt=""
                                  width="250"
                                  height="150"
                                  data={`${API_URL}uploads/eventsPdf/QrCode/${masterObject?.uploadedFile}`}
                                /> */}
                                </>
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={() => {
                                  setMasterObject(pre => ({
                                    ...pre,
                                    uploadedFile: null,
                                  }))
                                }}
                                style={{ width: "250px", marginTop: "4px" }}
                                type="button"
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <input
                              name="uploadedFile"
                              type="file"
                              className="form-control"
                              id="validationCustom03"
                              onChange={handleFileChange}
                              rows="1"
                            />
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3" style={{ paddingTop: "25px" }}>
                          <Button color="primary" type="submit">
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="Managetableid1"
                    bordered
                    responsive
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default Certificates

