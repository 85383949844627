import React, { useState, useEffect, useContext, useRef } from "react"
import { CourseContext } from ".."

import { AvField, AvForm } from "availity-reactstrap-validation"
import toastr from "toastr"
import { Row, Col, Button, Label, Table, Card, CardBody } from "reactstrap"

import Select from "react-select"
import { getOrdinalNumber } from "../../../../helpers/functions"
import { getSkillLoan, getScholarship } from "../../../../store/options/actions"
import { useSelector, useDispatch } from "react-redux"
import { put } from "../../../../helpers/api_helper"


const FeeDetails = props => {
  const gstOption = [
    { label: "0%", value: 0 },
    { label: "5%", value: 1.05 },
    { label: "12%", value: 1.12 },
    { label: "18%", value: 1.18 },
    { label: "28%", value: 1.28 },
  ]
  const [master, setMaster] = useState({
    fee: {
      currency: "INR",
      skillLoan: false,
      scholarship: false,
      phaseOne: { installment: false, default: true, installments: [] },
      phaseTwo: { installment: false, default: false, installments: [] },
    },
  })
  const main = useContext(CourseContext)
  const formRef = useRef()
  const [isDisabled, setisDisabled] = useState(false)
  const [selectdGstPh1, setselectdGstPh1] = useState(null)
  const [selectedGstPh2, setselectedGstPh2] = useState(null)
  const [selectedSkillLoan, setselectedSkillLoan] = useState(null)
  const [selectedScholrships, setselectedScholrships] = useState(null)
  const [selectedassesment, setselectedassesment] = useState(null)
  const { scholarships, skillLoans } = useSelector(state => state.Options)
  const [selectcurrency, setselectcurrency] = useState({ label: "INR", value: "INR" })
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getSkillLoan())
    dispatch(getScholarship())
  }, [dispatch])

  useEffect(() => {
    if (Object.keys(props.data).length && main?.courseID?.objID) {
      setisDisabled(false)
      let prev = { ...master }
      props.data.fee.skillLoan = props.data.fee.skillLoan ? props.data.fee.skillLoan : false
      props.data.fee.scholarship = props.data.fee.scholarship ? props.data.fee.scholarship : false
      setselectcurrency({ label: props.data.fee.currency, value: props.data.fee.currency })
      if (props.data.fee.skillLoans.length > 0) {
        setselectedSkillLoan(props.data.fee.skillLoans)
      } else {
        setselectedSkillLoan(null)
      }
      if (props.data.fee.scholarships.length > 0) {
        setselectedScholrships(props.data.fee.scholarships)
      } else {
        setselectedScholrships(null)
      }

      if (props?.data?.fee?.phaseOne?.gst) {
        const gst = props?.data?.fee?.phaseOne?.gst
        let label
        let value
        if (gst === "0") {
          label = "0%"
          value = 0
        } else {
          const parts = gst.split(".")
          const percentageString = parseInt(parts[1])
          label = percentageString + "%"
          value = props?.data?.fee?.phaseOne?.gst
        }
        setselectdGstPh1({ label, value })
      }else setselectdGstPh1(null)
      

      if (props?.data?.fee?.phaseTwo?.gst) {
        const gst2 = props?.data?.fee?.phaseTwo?.gst
        let label2
        let value2
        if (gst2 === "0") {
          label2 = "0%"
          value2 = 0
        } else {
          const parts2 = gst2.split(".")
          const percentageString2 = parseInt(parts2[1])
          label2 = percentageString2 + "%"
          value2 = props?.data?.fee?.phaseTwo?.gst
        }
        setselectedGstPh2({ label: label2, value: value2 })
      }else setselectedGstPh2(null)
      

      const newData = {
        fee: {
          // ...master.fee, // Preserve existing fee properties
          ...props.data.fee, // Merge new fee properties from props.data
          phaseOne: {
            // ...master.fee.phaseOne, // Preserve existing phaseOne properties
            ...props.data.fee.phaseOne // Merge new phaseOne properties from props.data
          },
          phaseTwo: {
            // ...master.fee.phaseTwo, // Preserve existing phaseTwo properties
            ...props.data.fee.phaseTwo // Merge new phaseTwo properties from props.data
          },
        },
        _id: props.data._id // Preserve _id property
      };
      setMaster(prevMaster => ({ ...prevMaster, ...newData }));
    }
  }, [props.data])

  const handleChange = e => {
    let { name, value, type, checked } = e.target

    if (type === "radio") {
      value = value === "1" ? true : false
    } else if (type === "checkbox") value = !checked

    const data = { [name]: value }

    if (name === "courseFee") {


      data.installments = []
      let totalAmount = 0
      totalAmount += Number(value)

      data.netAmount = totalAmount

      data.taxableValue = 0
      data.taxAmount = 0

      data.taxableValue = (
        totalAmount -
        (totalAmount - totalAmount / Number(master.fee.phaseOne.gst) || 0)
      ).toFixed(0)

      data.taxAmount = (totalAmount - data.taxableValue).toFixed(0)

      if (
        master.fee.phaseOne.noOfInstallments &&
        master.fee.phaseOne.installment
      ) {
        for (let i = 0; i < master.fee.phaseOne.noOfInstallments; i++) {
          data.installments.push(
            (value / master.fee.phaseOne.noOfInstallments).toFixed(0)
          )
        }
      }
    }
    // if (name === "noOfInstallments" && master.fee.phaseOne.netAmount) {
    //   data.installments = []
    //   for (let i = 0; i < value; i++) {
    //     data.installments.push(
    //       (master?.fee?.phaseOne?.netAmount / value).toFixed(0)
    //     )
    //   }
    // }

    if (name === "noOfInstallments" && master.fee.phaseOne.netAmount) {
      data.installments = [];
      const netAmount = master.fee.phaseOne.netAmount;
      const numberOfInstallments = value;
      const installmentAmount = Math.floor(netAmount / numberOfInstallments);
      const remainingAmount = netAmount % numberOfInstallments;

      // Push equal installments for the first n-1 installments
      for (let i = 0; i < numberOfInstallments - 1; i++) {
        data.installments.push(installmentAmount);
      }

      // Last installment covers remaining amount if any
      data.installments.push(installmentAmount + remainingAmount);

    }

    setMaster(prevState => ({
      ...prevState,
      fee: {
        ...prevState.fee,
        phaseOne: {
          ...prevState.fee.phaseOne,
          ...data,
        },
      },
    }))
  }

  const handleSelectGST = e => {
    setselectdGstPh1(e)
    const amount = Number(master?.fee?.phaseOne?.courseFee || 0) || null
    let taxableValue = 0
    let taxAmount = 0
    if (e?.value !== 0 && e !== null) {
      taxableValue = (amount - (amount - amount / Number(e?.value || 0 ))).toFixed(0)
      taxAmount = (amount - taxableValue).toFixed(0)
    }
    setMaster(prevState => ({
      ...prevState,
      fee: {
        ...prevState.fee,
        phaseOne: {
          ...prevState.fee.phaseOne,
          taxableValue,
          taxAmount,
          gst: e?.value || 0,
        },
      },
    }))
  }

  const handleChangephase2 = e => {
    let { name, value, type, checked } = e.target

    if (type === "radio") {
      value = value === "1" ? true : false
    } else if (type === "checkbox") value = !checked

    const data = { [name]: value }

    if (name === "courseFee") {
      data.installments = []
      let totalAmount = 0
      totalAmount += Number(value)

      data.netAmount = totalAmount

      data.taxableValue = 0
      data.taxAmount = 0

      data.taxableValue = (
        totalAmount -
        (totalAmount - totalAmount / Number(master.fee.phaseTwo.gst) || 0)
      ).toFixed(0)

      data.taxAmount = (totalAmount - data.taxableValue).toFixed(0)

      if (
        master.fee.phaseTwo.noOfInstallments &&
        master.fee.phaseTwo.installment
      ) {
        for (let i = 0; i < master.fee.phaseTwo.noOfInstallments; i++) {
          data.installments.push(
            (value / master.fee.phaseTwo.noOfInstallments).toFixed(0)
          )
        }
      }
    }

    // if (name === "noOfInstallments" && master.fee.phaseTwo.netAmount) {
    //   data.installments = []
    //   for (let i = 0; i < value; i++) {
    //     data.installments.push(
    //       (master.fee.phaseTwo.netAmount / value).toFixed(0)
    //     )
    //   }
    // }

    if (name === "noOfInstallments" && master.fee.phaseTwo.netAmount) {
      data.installments = [];
      const netAmount = master.fee.phaseTwo.netAmount;
      const numberOfInstallments = value;
      const installmentAmount = Math.floor(netAmount / numberOfInstallments);
      const remainingAmount = netAmount % numberOfInstallments;

      // Push equal installments for the first n-1 installments
      for (let i = 0; i < numberOfInstallments - 1; i++) {
        data.installments.push(Number(installmentAmount));
      }

      // Last installment covers remaining amount if any
      data.installments.push(Number(installmentAmount + remainingAmount));

    }

    setMaster(prevState => ({
      ...prevState,
      fee: {
        ...prevState.fee,
        phaseTwo: {
          ...prevState.fee.phaseTwo,
          ...data,
        },
      },
    }))
  }

  const handleSelectGSTphase2 = e => {
    setselectedGstPh2(e)
    const amount = Number(master?.fee?.phaseTwo?.courseFee || 0) || null
    let taxableValue = 0
    let taxAmount = 0
    if (e?.value !== 0 && e !== null) {
      taxableValue = (amount - (amount - amount / Number(e?.value || 0))).toFixed(0)
      taxAmount = (amount - taxableValue).toFixed(0)
    }
    setMaster(prevState => ({
      ...prevState,
      fee: {
        ...prevState.fee,
        phaseTwo: {
          ...prevState.fee.phaseTwo,
          taxableValue,
          taxAmount,
          gst: e?.value || 0,
        },
      },
    }))
  }

  function handleChange1(e) {
    const { name, value, type } = e.target
    let data = {}
    if (type === "radio") {
      data = { [name]: value === "1" ? true : false }
    } else {
      data = { [name]: value }
    }
    setMaster(prevState => ({
      ...prevState,
      fee: {
        ...prevState.fee,
        ...data,
      },
    }))
  }

  function handleselect(selected, name) {
    switch (name) {
      case "skillLoans":
        setselectedSkillLoan(selected)
        const skillLoanIds = selected.map(item => item._id)
        setMaster(prevState => ({
          ...prevState,
          fee: {
            ...prevState.fee,
            skillLoans: skillLoanIds,
          },
        }))
        break
      case "scholarships":
        setselectedScholrships(selected)
        const scholorship = selected.map(item => item._id)
        setMaster(prevState => ({
          ...prevState,
          fee: {
            ...prevState.fee,
            scholarships: scholorship,
          },
        }))
        break
      case "assesmentType":
        setselectedassesment(selected)
        setMaster(prevState => ({
          ...prevState,
          fee: {
            ...prevState.fee,
            assesmentType: selected?.value,
          },
        }))
        break
      case "currencyType":
        setselectcurrency(selected)
        setMaster(prevState => ({
          ...prevState,
          fee: {
            ...prevState.fee,
            currency: selected?.value,
          },
        }))

      default:
        break
    }
  }


  function handleSubmit(e) {
    e.preventDefault()
    let data = { ...master }
    data._id = main?.courseID?.objID
    setisDisabled(true)
    put(`/course/fee_details`, data)
      .then(res => {
        toastr.success(res.message)
        // formRef.current.reset()
        main.setCourseID(pre => ({ objID: res.data._id }))
        main.setactiveTabJustify("3")
        // setMaster({
        //   fee: {
        //     skillLoan: false,
        //     scholarship: false,
        //     phaseOne: { installment: false, default: true, installments: [] },
        //     phaseTwo: { installment: false, installments: [] },
        //   },
        // })
        // setselectdGstPh1(null)
        // setselectedGstPh2(null)
        // setselectedScholrships(null)
        // setselectedSkillLoan(null)
        // setselectedassesment(null)
        // setselectcurrency(null)
        setisDisabled(false)
      })
      .catch(err => {
        toastr.error(err.response.data.message)
        setisDisabled(false)
      })
  }


  const handleCheckboxChange = (phase) => {
    setMaster((prevMaster) => {
      const updatedMaster = { ...prevMaster };

      // Uncheck the other phase if the current one is being checked
      if (phase === 'phaseOne') {
        updatedMaster.fee.phaseTwo.default = false;
      } else if (phase === 'phaseTwo') {
        updatedMaster.fee.phaseOne.default = false;
      }

      // Toggle the default value of the current phase
      updatedMaster.fee[phase].default = !prevMaster.fee[phase].default;

      return updatedMaster;
    });
  };



  const handleInstallmentChange = (idx, value) => {
    // Make a copy of the master object
    const updatedMaster = { ...master };
    // Update the installments array of phaseOne at the specified index
    updatedMaster.fee.phaseOne.installments[idx] = Number(value)
    // Update the state with the new master object
    setMaster(updatedMaster);
  };

  const handleInstallmentChange2 = (idx, value) => {
    // Make a copy of the master object
    const updatedMaster = { ...master };
    // Update the installments array of phaseOne at the specified index
    updatedMaster.fee.phaseTwo.installments[idx] = Number(value)
    // Update the state with the new master object
    setMaster(updatedMaster);
  };

  function resetAll() {
    setMaster({
      fee: {
        currency: "INR",
        skillLoan: false,
        scholarship: false,
        phaseOne: { installment: false, default: true, installments: [] },
        phaseTwo: { installment: false, default: false, installments: [] },
      },
    })
    setselectcurrency({ label: "INR", value: "INR" })
    setselectdGstPh1(null)
    setselectedGstPh2(null)
    setselectedScholrships(null)
    setselectedScholrships(null)
    setselectedSkillLoan(null)
    setselectedassesment(null)
    main.setCourseID({})


  }


  return (
    <AvForm ref={formRef} onValidSubmit={handleSubmit}>
      <Row>
        {/* phse1 */}
        <Row>
          <Card>
            <CardBody>
              <Row>
                <Col md={2}>
                  <div className="mb-3">
                    <Label>Markup Price</Label>
                    <AvField
                      name="markupPrice"
                      onChange={handleChange}
                      className="form-control"
                      type="number"
                      placeholder="Course Fee"
                      // errorMessage="Enter Course"
                      value={master?.fee?.phaseOne?.markupPrice || ""}
                      onWheel={(e) => e.target.blur()}
                    />
                  </div>
                </Col>
                <Col md={2}>
                  <div className="mb-3">
                    <Label>Course Fee</Label>
                    <AvField
                      name="courseFee"
                      onChange={handleChange}
                      className="form-control"
                      type="number"
                      placeholder="Selling Price"
                      // errorMessage="Enter Selling Price"
                      value={master?.fee?.phaseOne?.courseFee || ""}
                      onWheel={(e) => e.target.blur()}
                    />
                  </div>
                </Col>

                <Col md="2">
                  <div className="mb-3">
                    <Label>GST slabs</Label>
                    <Select
                      isClearable
                      name="gender"
                      classNamePrefix="select2-selection"
                      onChange={handleSelectGST}
                      options={gstOption}
                      value={selectdGstPh1}
                    />
                  </div>
                </Col>

                <Col
                  md="1"
                  xxl="1"
                  className="d-flex align-items-start mb-3 col-md-1 col-xxl-1"
                >
                  <div className="form-check form-check-right mt-4 pt-2">
                    <input
                      name="dafault"
                      checked={master?.fee?.phaseOne?.default}
                      type="checkbox"
                      className="form-check-input"
                      id="CustomCheck1"
                      onClick={() => handleCheckboxChange('phaseOne')}
                    />
                    <label htmlFor="CustomCheck1" className="form-check-label">
                      Default
                    </label>
                  </div>
                </Col>

                {master?.fee.phaseOne?.default === true ? (
                  <Col md={2}>
                    <div className="mb-3">
                      <Label>Expiry Date</Label>
                      <AvField
                        name="expireDate"
                        onChange={handleChange}
                        className="form-control"
                        type="date"
                        placeholder="Selling Price"
                        // errorMessage="Enter Selling Price"
                        value={master?.fee?.phaseOne?.expireDate || ""}
                      />
                    </div>
                  </Col>
                ) : ("")}

              </Row>

              <Row>
                <Row className="mb-3">
                  <div className="d-flex align-items-center">
                    {!!master?.fee?.phaseOne?.taxableValue > 0 &&
                      master?.fee?.phaseOne?.gst ? (
                      <div className="mt-3 me-3">
                        <Label>
                          Taxable value :{" "}
                          {master?.fee?.phaseOne?.taxableValue || ""}
                        </Label>
                      </div>
                    ) : (
                      ""
                    )}

                    {master?.fee?.phaseOne?.taxAmount >= 0 &&
                      master?.fee?.phaseOne?.gst ? (
                      <div className="mt-3 me-3">
                        <Label>
                          Tax Amount : {master?.fee?.phaseOne?.taxAmount}
                        </Label>
                      </div>
                    ) : (
                      ""
                    )}

                    {!!master?.fee?.phaseOne?.netAmount ? (
                      <div className="mt-3 me-3">
                        <Label>
                          {" "}
                          Course Fee: {master?.fee?.phaseOne?.netAmount || ""}
                        </Label>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Row>

                <Row className="mt-1 mb-4">
                  <Col
                    md="2"
                    xxl="1"
                    className="d-flex align-items-start mb-3 col-md-2 col-xxl-1"
                  >
                    <div className="form-check form-check-right mt-4 pt-2">
                      <input
                        name="installment"
                        checked={master?.fee?.phaseOne?.installment}
                        onClick={handleChange}
                        type="checkbox"
                        className="form-check-input"
                        id="CustomCheck1"
                      />
                      <label
                        htmlFor="CustomCheck1"
                        className="form-check-label"
                      >
                        Installment
                      </label>
                    </div>
                  </Col>

                  {master?.fee?.phaseOne?.installment && (
                    <Col md={2}>
                      <div className="mb-3">
                        <Label>No of installment</Label>
                        <AvField
                          name="noOfInstallments"
                          onChange={handleChange}
                          className="form-control"
                          type="number"
                          placeholder="Installment"
                          // errorMessage="Enter Selling Price"
                          value={master?.fee?.phaseOne?.noOfInstallments || ""}
                          onWheel={(e) => e.target.blur()}
                        />
                      </div>
                    </Col>
                  )}
                  {master?.fee?.phaseOne?.installment &&
                    master?.fee?.phaseOne?.noOfInstallments && (
                      <Col md={4}>
                        <Table className="table mb-0">
                          <thead>
                            <tr>
                              <th>Installments</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {master?.fee?.phaseOne?.installments?.map(
                              (row, idx) => (
                                <tr key={idx}>
                                  <td className="p-0">
                                    {getOrdinalNumber(idx + 1)} installment
                                  </td>
                                  <td className="p-0">
                                    <AvField
                                      name="markupPrice"
                                      value={row}
                                      className="form-control"
                                      type="number"
                                      placeholder={`installment amount`}
                                      // errorMessage="Enter Course"
                                      onChange={(e) => handleInstallmentChange(idx, e.target.value)}
                                      onWheel={(e) => e.target.blur()}
                                    />
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      </Col>
                    )}
                </Row>
              </Row>
            </CardBody>
          </Card>
        </Row>

        {/* phase2 */}

        <Row>
          <Card>
            <CardBody>
              <Row>
                <Col md={2}>
                  <div className="mb-3">
                    <Label>Markup Price</Label>
                    <AvField
                      name="markupPrice"
                      onChange={handleChangephase2}
                      className="form-control"
                      type="number"
                      placeholder="Course Fee"
                      // errorMessage="Enter Course"
                      value={master?.fee?.phaseTwo?.markupPrice || ""}
                      onWheel={(e) => e.target.blur()}
                    />
                  </div>
                </Col>
                <Col md={2}>
                  <div className="mb-3">
                    <Label>Course Fee</Label>
                    <AvField
                      name="courseFee"
                      onChange={handleChangephase2}
                      className="form-control"
                      type="number"
                      placeholder="Selling Price"
                      // errorMessage="Enter Selling Price"
                      value={master?.fee.phaseTwo?.courseFee || ""}
                      onWheel={(e) => e.target.blur()}
                    />
                  </div>
                </Col>

                <Col md="2">
                  <div className="mb-3">
                    <Label>GST slabs</Label>
                    <Select
                      isClearable
                      name="gst"
                      classNamePrefix="select2-selection"
                      onChange={handleSelectGSTphase2}
                      value={selectedGstPh2}
                      options={[
                        { label: "0%", value: 0 },
                        { label: "5%", value: 1.05 },
                        { label: "12%", value: 1.12 },
                        { label: "18%", value: 1.18 },
                        { label: "28%", value: 1.28 },
                      ]}
                    />
                  </div>
                </Col>


                <Col
                  md="1"
                  xxl="1"
                  className="d-flex align-items-start mb-3 col-md-1 col-xxl-1"
                >
                  <div className="form-check form-check-right mt-4 pt-2">
                    <input
                      name="dafault"
                      checked={master?.fee?.phaseTwo?.default}
                      type="checkbox"
                      className="form-check-input"
                      id="CustomCheck1"
                      onClick={() => handleCheckboxChange('phaseTwo')}
                    />
                    <label htmlFor="CustomCheck1" className="form-check-label">
                      Default
                    </label>
                  </div>
                </Col>

                {master?.fee.phaseTwo?.default === true ? (
                  <Col md={2}>
                    <div className="mb-3">
                      <Label>Expiry Date</Label>
                      <AvField
                        name="expireDate"
                        onChange={handleChangephase2}
                        className="form-control"
                        type="date"
                        placeholder="Selling Price"
                        // errorMessage="Enter Selling Price"
                        value={master?.fee?.phaseTwo?.expireDate || ""}
                      />
                    </div>
                  </Col>
                ) : ("")}
              </Row>

              <Row>
                <Row className="mb-3">
                  <div className="d-flex align-items-center">
                    {!!master?.fee?.phaseTwo?.taxableValue > 0 &&
                      master?.fee?.phaseTwo?.gst ? (
                      <div className="mt-3 me-3">
                        <Label>
                          Taxable value :{" "}
                          {master?.fee?.phaseTwo?.taxableValue || "0"}
                        </Label>
                      </div>
                    ) : ("")}

                    {master?.fee?.phaseTwo?.taxAmount >= 0 &&
                      master?.fee?.phaseTwo?.gst ? (
                      <div className="mt-3 me-3">
                        <Label>
                          Tax Amount : {master?.fee?.phaseTwo?.taxAmount}
                        </Label>
                      </div>
                    ) : ("")}

                    {!!master?.fee?.phaseTwo?.netAmount ? (
                      <div className="mt-3 me-3">
                        <Label>
                          {" "}
                          Course Fee: {master?.fee?.phaseTwo?.netAmount || ""}
                        </Label>
                      </div>
                    ) : ("")}
                  </div>
                </Row>

                <Row className="mt-1 mb-4">
                  <Col
                    md="2"
                    xxl="1"
                    className="d-flex align-items-start mb-3 col-md-2 col-xxl-1"
                  >
                    <div className="form-check form-check-right mt-4 pt-2">
                      <input
                        name="installment"
                        checked={master?.fee?.phaseTwo?.installment}
                        onClick={handleChangephase2}
                        type="checkbox"
                        className="form-check-input"
                        id="CustomCheck1"
                      />
                      <label
                        htmlFor="CustomCheck1"
                        className="form-check-label"
                      >
                        Installment
                      </label>
                    </div>
                  </Col>

                  {master?.fee?.phaseTwo?.installment && (
                    <Col md={2}>
                      <div className="mb-3">
                        <Label>No of installment</Label>
                        <AvField
                          name="noOfInstallments"
                          onChange={handleChangephase2}
                          className="form-control"
                          type="number"
                          placeholder="Installment"
                          // errorMessage="Enter Selling Price"
                          value={master?.fee?.phaseTwo?.noOfInstallments || ""}
                          onWheel={(e) => e.target.blur()}
                        />
                      </div>
                    </Col>
                  )}
                  {master?.fee?.phaseTwo?.installment &&
                    master?.fee?.phaseTwo?.noOfInstallments && (
                      <Col md={4}>
                        <Table className="table mb-0">
                          <thead>
                            <tr>
                              <th>Installments</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {master?.fee?.phaseTwo?.installments?.map(
                              (row, idx) => (
                                <tr key={idx}>
                                  <td className="p-0">
                                    {getOrdinalNumber(idx + 1)} installment
                                  </td>
                                  <td className="p-0">
                                    <AvField
                                      name="markupPrice"
                                      value={row}
                                      className="form-control"
                                      type="number"
                                      placeholder={`installment amount`}
                                      // errorMessage="Enter Course"
                                      onChange={(e) => handleInstallmentChange2(idx, e.target.value)}
                                      onWheel={(e) => e.target.blur()} />
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      </Col>
                    )}
                </Row>
              </Row>
            </CardBody>
          </Card>
        </Row>

        <Row>
          <Col md="2">
            <div className="mb-3">
              <Label style={{ paddingRight: "15px", display: "block" }}>
                Skill Loan Applicable?
              </Label>

              <div
                className="btn-group btn-group-sm"
                role="group"
                aria-label="Basic example"
              >
                <input
                  type="radio"
                  className="btn-check"
                  name="skillLoan"
                  value={1}
                  id="vbtn-radio3"
                  onClick={handleChange1}
                  checked={master?.fee?.skillLoan === true}
                />
                <label
                  style={{
                    padding: "7px 17.6px",
                    zIndex: "0",
                    fontSize: "11px",
                  }}
                  className="btn btn-outline-primary"
                  htmlFor="vbtn-radio3"
                >
                  Yes
                </label>
                <input
                  type="radio"
                  name="skillLoan"
                  className="btn-check"
                  value={0}
                  id="vbtn-radio4"
                  onClick={handleChange1}
                  checked={master?.fee?.skillLoan === false}
                />

                <label
                  style={{
                    padding: "7px 17.6px",
                    zIndex: "0",
                    fontSize: "11px",
                  }}
                  className="btn btn-outline-primary"
                  htmlFor="vbtn-radio4"
                >
                  No
                </label>
              </div>
            </div>
          </Col>

          {master?.fee?.skillLoan && (
            <Col md="4">
              <div className="mb-3">
                <Label>Skill Loan</Label>
                <Select
                  name="skillLoan"
                  isMulti
                  classNamePrefix="select2-selection"
                  value={selectedSkillLoan}
                  options={skillLoans}
                  onChange={selected => {
                    handleselect(selected, "skillLoans")
                  }}
                />
              </div>
            </Col>
          )}
        </Row>

        <Row>
          <Col md="2">
            <div className="mb-3">
              <Label style={{ paddingRight: "15px", display: "block" }}>
                Scholarship Available?
              </Label>

              <div
                className="btn-group btn-group-sm"
                role="group"
                aria-label="Basic example"
              >
                <input
                  type="radio"
                  className="btn-check"
                  name="scholarship"
                  value={1}
                  id="vbtn-radio5"
                  onClick={handleChange1}
                  checked={master?.fee?.scholarship === true}
                />
                <label
                  style={{
                    padding: "7px 17.6px",
                    zIndex: "0",
                    fontSize: "11px",
                  }}
                  className="btn btn-outline-primary"
                  htmlFor="vbtn-radio5"
                >
                  Yes
                </label>
                <input
                  type="radio"
                  name="scholarship"
                  className="btn-check"
                  value={0}
                  id="vbtn-radio6"
                  onClick={handleChange1}
                  checked={master.fee.scholarship === false}
                />

                <label
                  style={{
                    padding: "7px 17.6px",
                    zIndex: "0",
                    fontSize: "11px",
                  }}
                  className="btn btn-outline-primary"
                  htmlFor="vbtn-radio6"
                >
                  No
                </label>
              </div>
            </div>
          </Col>
          {master.fee?.scholarship && (
            <Col md="4">
              <div className="mb-3">
                <Label>Scholarship</Label>
                <Select
                  name="scholarships"
                  isMulti
                  classNamePrefix="select2-selection"
                  options={scholarships}
                  onChange={selected => {
                    handleselect(selected, "scholarships")
                  }}
                  value={selectedScholrships}
                />
              </div>
            </Col>
          )}
        </Row>

        <Row>
          <Col md="2">
            <div className="mb-3">
              <Label>Assessment Type</Label>
              <Select
                isClearable
                name="assesmentType"
                classNamePrefix="select2-selection"
                options={[
                  { value: "0", label: "Internal Assesment" },
                  { value: "1", label: "External Assesment" },
                ]}
                onChange={selected => {
                  handleselect(selected, "assesmentType")
                }}
                value={
                  master?.fee?.assesmentType === "1"
                    ? { value: "1", label: "External Assessment" }
                    : master.fee.assesmentType === "0"
                      ? { value: "0", label: "Internal Assessment" }
                      : null
                }
              />
            </div>
          </Col>

          <Col md={2}>
            <div className="mb-3">
              <Label>Assessment Fee</Label>
              <AvField
                name="assesmentFee"
                onChange={handleChange1}
                className="form-control"
                type="number"
                placeholder="Assessment Fee"
                // errorMessage="Enter Selling Price"
                value={master?.fee?.assesmentFee || ""}
                onWheel={(e) => e.target.blur()}
              />
            </div>
          </Col>

          <Col md="2">
            <Label>Currency</Label>
            <Select options={[
              { label: "INR", value: "INR" },
              { label: "USD", value: "USD" },
              { label: "EUR", value: "EUR" }
            ]}
              onChange={selected => {
                handleselect(selected, "currencyType")
              }}
              value={selectcurrency}
            />
          </Col>
        </Row>
        <Col>
          <div className="mb-3 mr-3 mt-4 pt-1 d-flex me-3 justify-content-end">
            <Button disabled={isDisabled ? true : false} color="primary" type="submit">
              Save & Next
            </Button>
            <Button onClick={resetAll}  className="mx-2" color="danger" type="reset">
                Reset
              </Button>
          </div>
        </Col>
      </Row>
    </AvForm>
  )
}

export default FeeDetails
