import React, { useEffect, useRef, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { Row, Col, Card, CardBody, Label, Button } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import toastr from "toastr"
import Select from "react-select"

import {
  dateConverter,
  numberToCurrency,
  setTitle,
} from "../../../helpers/functions"
import TablePagination from "../../../components/Common/Pagination"
import { get } from "../../../helpers/api_helper"

import "./style.scss"
import { AvField, AvForm } from "availity-reactstrap-validation"

const IncomeAndExpense = ({ options }) => {
  setTitle("Income/Expense")
  const formRef = useRef()
  const typeOptions = [
    { label: "Income", value: 1 },
    { label: "Expense", value: 2 },
  ]

  const [tableData, setTableData] = useState([])

  const [masterObject, setMasterObject] = useState({})
  const [selectedFields, setSelectedFields] = useState({})

  const [branchOptions, setBranchOptions] = useState([])
  const [subHeadOptions, setSubHeadOptions] = useState([])
  const [accountOptions, setAccountOptions] = useState([])

  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 20

  useEffect(() => {
    handleTableData(1)
    setPage(1)
    // eslint-disable-next-line
  }, [masterObject])

  useEffect(() => {
    handleBranchOptions()
    handleSubHeadOptions()
    handleAccountOptions()
    // eslint-disable-next-line
  }, [masterObject])

  const handleTableData = async (currentPage = page) => {
    get(
      `income-expense/list?page=${currentPage}&limit=${limit}&type=${masterObject.type}&subType=${masterObject.subType}&account=${masterObject.account}&from=${masterObject.from}&to=${masterObject.to}&branch=${masterObject.branch}`
    ).then(res => {
      const data = res.data
      data.map((item, index) => {
        item.id = index + 1 + (currentPage - 1) * limit

        item.date = dateConverter(item.date)
        item.addedDate = dateConverter(item.addedDate)

        item.amount = numberToCurrency(item.amount)

        item.staff = `${item.staffFirstName || ""} ${item.staffLastName || ""}`
        return item
      })
      const count = res.count
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(data)
    })
  }
  const handleBranchOptions = async () => {
    try {
      const query = `income-expense/options/branch?type=${masterObject.type}&subType=${masterObject.subType}&account=${masterObject.account}&from=${masterObject.from}&to=${masterObject.to}`
      const response = await get(query)
      setBranchOptions(response.data)
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }
  const handleSubHeadOptions = async () => {
    try {
      const query = `income-expense/options/subhead?type=${masterObject.type}&branch=${masterObject.branch}&account=${masterObject.account}&from=${masterObject.from}&to=${masterObject.to}`
      const response = await get(query)
      setSubHeadOptions(response.data)
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }
  const handleAccountOptions = async () => {
    try {
      const query = `income-expense/options/account?type=${masterObject.type}&branch=${masterObject.branch}&subType=${masterObject.subType}&from=${masterObject.from}&to=${masterObject.to}`
      const response = await get(query)
      setAccountOptions(response.data)
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }

  const handleSelectValueChange = (selected, name) => {
    const obj = { ...selectedFields }
    obj[name] = selected
    setSelectedFields(obj)

    handleValueChange(selected.value, name)
  }
  const handleValueChange = (value, name) => {
    const obj = { ...masterObject }
    obj[name] = value
    setMasterObject(obj)
    setPage(1)
  }

  const reset = () => {
    formRef.current?.reset()
    setMasterObject({})
    setSelectedFields({})
  }
  const handleChange = (event, value) => {
    setPage(value)
    handleTableData(value)
  }
  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Added Date", field: "addedDate" },
      { label: "Head", field: "type" },
      { label: "Sub Head", field: "subType" },
      { label: "Chart of Account", field: "chartOfAccount" },
      { label: "Fund Source", field: "fundSource" },
      { label: "Amount", field: "amount" },
      { label: "Comment", field: "comment" },
      { label: "Added By", field: "staff" },
    ],
    rows: tableData,
  }
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Income/Expense" />
          <Card>
            <CardBody>
              <AvForm ref={formRef}>
                <Row>
                  <Col md="2">
                    <div className="mb-3">
                      <Label>From</Label>
                      <AvField
                        name="from"
                        className="form-control"
                        type="date"
                        value={masterObject.from || ""}
                        onChange={e =>
                          handleValueChange(e.target.value, e.target.name)
                        }
                      />
                    </div>
                  </Col>

                  <Col md="2">
                    <div className="mb-3">
                      <Label>To</Label>
                      <AvField
                        name="to"
                        className="form-control"
                        type="date"
                        value={masterObject.to || ""}
                        onChange={e =>
                          handleValueChange(e.target.value, e.target.name)
                        }
                      />
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label>Branch</Label>
                      <Select
                        name="Branch"
                        type="text"
                        placeholder="Select..."
                        errorMessage="Enter Category"
                        options={branchOptions}
                        value={selectedFields.branch || ""}
                        onChange={selected => {
                          handleSelectValueChange(selected, "branch")
                        }}
                      />
                    </div>
                  </Col>

                  <Col md="2">
                    <div className="mb-3">
                      <Label>Head</Label>
                      <Select
                        name="type"
                        type="text"
                        placeholder="Select..."
                        options={typeOptions}
                        value={selectedFields.type || ""}
                        onChange={selected => {
                          handleSelectValueChange(selected, "type")
                        }}
                      />
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label>Sub Head</Label>
                      <Select
                        name="type"
                        type="text"
                        placeholder="Select..."
                        options={subHeadOptions}
                        value={selectedFields.subType || ""}
                        onChange={selected => {
                          handleSelectValueChange(selected, "subType")
                        }}
                      />
                    </div>
                  </Col>

                  <Col md="2">
                    <div className="mb-3">
                      <Label>Charts of Account</Label>
                      <Select
                        name="account"
                        type="text"
                        placeholder="Select..."
                        errorMessage="Enter Category"
                        options={accountOptions}
                        value={selectedFields.account || ""}
                        onChange={selected => {
                          handleSelectValueChange(selected, "account")
                        }}
                      />
                    </div>
                  </Col>

                  <Col>
                    <div className="mb-3 d-flex gap-2">
                      <Button color="danger" type="button" onClick={reset}>
                        Reset
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <MDBDataTable
                id="incomeExpenseTable"
                bordered
                responsive
                data={data}
                disableRetreatAfterSorting={true}
                info={false}
                sortable={false}
                searching={false}
                paging={false}
              ></MDBDataTable>
              <TablePagination
                page={page}
                onChange={handleChange}
                count={totalPage}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  )
}

export default IncomeAndExpense
