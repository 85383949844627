import moment from "moment"
import { APP_NAME, CRYPTO_HASH, PRIVILEGES } from "../config"

import CryptoJS from "crypto-js"

import * as XLSX from "xlsx"

import _ from "lodash"

export const setBrowserToken = token => {
  localStorage.setItem("browserToken", token)
}

export const getFirstday = date =>
  moment().startOf("month").format("YYYY-MM-DD")

export const getOneMonthAgo = date =>
  moment(date).subtract(1, "months").format("YYYY-MM-DD")

export const getDate = date => moment(date).format("YYYY-MM-DD")
export const getTime = time => moment(time).format("HH:mm:ss")

export const setTitle = name => {
  document.title = name + ` | ${APP_NAME} `
}
export const isAdmin = () => {
  const user = JSON.parse(localStorage.getItem("authUser"))

  return (
    user.privilage === PRIVILEGES.ADMIN &&
    (sessionStorage.getItem("branch") === "null" ||
      sessionStorage.getItem("branch") === null)
  )
  // return (
  //   ((user.privilage === PRIVILEGES.ADMIN ||
  //     user.privilage === PRIVILEGES.PROGRAM_MANAGER) &&
  //     sessionStorage.getItem("branch") === "null") ||
  //   sessionStorage.getItem("branch") === null
  // )
}
export const isRecruiter = () => {
  const user = JSON.parse(localStorage.getItem("authUser"))

  return {
    status: user.privilage === PRIVILEGES.RECRUITER,
    id: user.company[0],
  }
  // return (
  //   ((user.privilage === PRIVILEGES.ADMIN ||
  //     user.privilage === PRIVILEGES.PROGRAM_MANAGER) &&
  //     sessionStorage.getItem("branch") === "null") ||
  //   sessionStorage.getItem("branch") === null
  // )
}
export const isFinancePm = () => {
  const user = JSON.parse(localStorage.getItem("authUser"))

  console.log(user.privilage, PRIVILEGES.FINANCE_PROGRAMME_MANAGER)
  return user.privilage === PRIVILEGES.FINANCE_PROGRAMME_MANAGER
}

export const getOrdinalNumber = number => {
  if (typeof number !== "number" || isNaN(number)) {
    return "Invalid input"
  }

  const lastDigit = number % 10
  const secondToLastDigit = Math.floor((number % 100) / 10)

  let suffix = "th"

  if (secondToLastDigit !== 1) {
    switch (lastDigit) {
      case 1:
        suffix = "st"
        break
      case 2:
        suffix = "nd"
        break
      case 3:
        suffix = "rd"
        break
      default:
        break
    }
  }

  return (
    <span>
      {number}
      <sup>{suffix}</sup>
    </span>
  )
}

export const timeSince = (date, now_date) => {
  var seconds = Math.floor((now_date - date) / 1000)

  var interval = seconds / 31536000

  if (interval > 1) {
    return Math.floor(interval) + " yrs"
  }
  interval = seconds / 2592000
  if (interval > 1) {
    return Math.floor(interval) + " months"
  }
  interval = seconds / 86400
  if (interval > 1) {
    return Math.floor(interval) + " days"
  }
  interval = seconds / 3600
  if (interval > 1) {
    return Math.floor(interval) + " hrs"
  }
  interval = seconds / 60
  if (interval > 1) {
    return Math.floor(interval) + " min"
  }
  return Math.floor(seconds) + " sec"
}

export const generateShortCode = value => {
  const words = value.split(" ")
  let shortCode = ""

  const excludeWords = new Set(["of", "and"])

  for (const word of words) {
    if (!excludeWords.has(word.toLowerCase())) {
      const cleanWord = word.replace(/[^a-zA-Z0-9]/g, "")
      shortCode += cleanWord.charAt(0).toUpperCase()
    }
  }

  if (value.length > 5 && words.length === 1) {
    shortCode += value.toUpperCase().charAt(value.length - 1)
  }

  return shortCode
}

export const getBranch = () => {
  let branch = {}

  if (sessionStorage.getItem("branch") !== "undefined") {
    branch = JSON.parse(sessionStorage.getItem("branch"))
  }

  return branch
}

export const customContentStateConverter = contentState => {
  // changes block type of images to 'atomic'
  const newBlockMap = contentState.getBlockMap().map(block => {
    const entityKey = block.getEntityAt(0)
    if (entityKey !== null) {
      const entityBlock = contentState.getEntity(entityKey)
      const entityType = entityBlock.getType()
      switch (entityType) {
        case "IMAGE": {
          const newBlock = block.merge({
            type: "atomic",
            text: "img",
          })
          return newBlock
        }
        default:
          return block
      }
    }
    return block
  })
  const newContentState = contentState.set("blockMap", newBlockMap)
  return newContentState
}

export const jsonToExcel = (
  jsonArray,
  sheetName = moment().format("DDMMYYhhmmss")
) => {
  // Create a worksheet
  const ws = XLSX.utils.json_to_sheet(jsonArray)

  // Create a workbook
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, sheetName)

  // Save to a file
  XLSX.writeFile(wb, `${sheetName}.xlsx`)
}

export const handleImageValidation = async (
  file,
  width = 200,
  height = 100
) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = e => {
      const img = new Image()
      img.src = e.target.result

      img.onload = () => {
        if (img.width === width && img.height === height) {
          resolve(true)
        } else {
          reject(
            `Please upload an image with dimensions ${width}x${height} pixels.`
          )
        }
      }
    }

    reader.readAsDataURL(file)
  })
}

export const objectToString = obj => {
  return Object.entries(obj)
    .map(([key, value]) => `${key}=${value}`)
    .join("&")
}

export const convertMinutesToHours = minutes => {
  let hours = Math.floor(minutes / 60)
  let remainingMinutes = minutes % 60
  hours = Math.floor(hours).toString().padStart(2, "0")
  remainingMinutes = Math.floor(remainingMinutes).toString().padStart(2, "0")
  return hours + ":" + remainingMinutes
}

export const dateConverter = date => moment(date).format("DD-MM-YYYY")

export const timeConverter = time => moment(time, "HH:mm:ss").format("hh:mm a")

export const generatePermalink = str => {
  var code = str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "")
  return code
}

export const minutesToHour = minutes => {
  const totalHours = Math.floor(minutes / 60)

  const remainingMinutes = minutes % 60

  const formattedHours = totalHours.toString().padStart(2, "0")
  const formattedMinutes = remainingMinutes.toString().padStart(2, "0")

  return `${formattedHours}:${formattedMinutes}`
}

export function encrypt(data) {
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    CRYPTO_HASH
  ).toString()
  return encrypted
}

export const decrypt = data => {
  const decrypted = CryptoJS.AES.decrypt(data, CRYPTO_HASH).toString(
    CryptoJS.enc.Utf8
  )
  return decrypted
}

export const genderValue = value => {
  return value === 0
    ? "Female"
    : value === 1
    ? "Male"
    : value === 2
    ? "Other"
    : null
}

export const checkingPrivilege = value => {
  const user = JSON.parse(localStorage.getItem("authUser"))
  return user?.privilage === value
}

export const numberToString = (number = 0) => {
  return number.toLocaleString("en-IN") || 0
}
export const numberToCurrency = (number = 0) => {
  return (
    number?.toLocaleString("en-IN", {
      maximumFractionDigits: 0,
      style: "currency",
      currency: "INR",
    }) || "₹0"
  )
}

export const getCurrentFinancialYearSelect = (year = 2024) => {
  const nextYear = Number(year) + 1
  return {
    label: `${year}-${nextYear.toString().slice(-2)}`,
    from: moment(`${year}-04-01`).format("YYYY-MM-DD"),
    to: moment(`${nextYear}-03-31`).format("YYYY-MM-DD"),
    value: `${moment(`${year}-04-01`).format("YYYY-MM-DD")},${moment(
      `${nextYear}-03-31`
    ).format("YYYY-MM-DD")}`,
  }
}

export const getCurrentFinancialQuarterSelect = () => {
  const now = moment()
  const year = now.year()
  const month = now.month() + 1
  let quarter

  if (month >= 4 && month <= 6) {
    quarter = "Q1"
  } else if (month >= 7 && month <= 9) {
    quarter = "Q2"
  } else if (month >= 10 && month <= 12) {
    quarter = "Q3"
  } else {
    quarter = "Q4"
  }

  const quarters = {
    Q1: {
      label: `Q1 (Apr 1 - Jun 30)`,
      from: `${year}-04-01`,
      to: `${year}-06-30`,
      value: `${moment(`${year}-04-01`).format("YYYY-MM-DD")},${moment(
        `${year}-06-30`
      ).format("YYYY-MM-DD")}`,
    },
    Q2: {
      label: `Q2 (Jul 1 - Sep 30)`,
      from: `${year}-07-01`,
      to: `${year}-09-30`,
      value: `${moment(`${year}-07-01`).format("YYYY-MM-DD")},${moment(
        `${year}-09-30`
      ).format("YYYY-MM-DD")}`,
    },
    Q3: {
      label: `Q3 (Oct 1 - Dec 31)`,
      from: `${year}-10-01`,
      to: `${year}-12-31`,
      value: `${moment(`${year}-10-01`).format("YYYY-MM-DD")},${moment(
        `${year}-12-31`
      ).format("YYYY-MM-DD")}`,
    },
    Q4: {
      label: `Q4 (Jan 1 - Mar 31)`,
      from: `${year}-01-01`,
      to: `${year}-03-31`,
      value: `${moment(`${year + 1}-01-01`).format("YYYY-MM-DD")},${moment(
        `${year + 1}-03-31`
      ).format("YYYY-MM-DD")}`,
    },
  }

  return quarters[quarter]
}

export const getCurrentFinancialMonthSelect = () => {
  const now = moment()
  const year = now.year()
  const month = now.month() + 1
  let startYear = year

  if (month >= 1 && month <= 3) {
    startYear = year - 1
  }

  const monthLabels = [
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
    "Jan",
    "Feb",
    "Mar",
  ]

  const currentMonthIndex = (month - 4 + 12) % 12
  const label = `${monthLabels[currentMonthIndex]} ${startYear}`

  const start = moment(
    `${startYear}-${(currentMonthIndex + 4).toString().padStart(2, "0")}-01`
  ).format("YYYY-MM-DD")
  const end = moment(start).endOf("month").format("YYYY-MM-DD")

  return {
    label,
    from: start,
    to: end,
    value: `${start},${end}`,
  }
}

export const sideBarToggle = (start = true) => {
  var body = document.body

  if (start) {
    body.classList.remove("vertical-collpsed")
    body.classList.remove("sidebar-enable")
    body.setAttribute("data-sidebar-size", "sm")
  } else {
    body.classList.add("vertical-collpsed")
    body.classList.add("sidebar-enable")
    body.setAttribute("data-sidebar-size", "lg")
  }
}
export const isNull = field => {
  return (
    field === undefined ||
    field === "undefined" ||
    field === "" ||
    field === null ||
    field === "null"
  ) // || !!!field;
}

export const calculateGST = (amount = 5000, gst = 0) => {
  let taxableValue = amount
  let taxAmount = 0
  if (gst > 0) {
    taxableValue = parseFloat(amount - (amount - amount / Number(gst)))
    taxAmount = parseFloat(amount - taxableValue)
  }

  return { taxableValue, taxAmount }
}

// export const menuAllowed = (privileges = []) => {
//   if(!Array.isArray(privileges)) return
//   const userData = JSON.parse(localStorage.getItem("authUser"))
//   const privilege = userData?.privilage
//   // check user privilege is available in privileges
//   // if available then allow
//   // else return to default module
// }

export function convertMinutesToHoursFormat(minutes) {
  // Use lodash to ensure the input is a number
  const mins = _.toNumber(minutes)

  // Check if the conversion was successful
  if (_.isNaN(mins)) {
    throw new Error("Input must be a number or a string representing a number")
  }

  // Calculate hours and remaining minutes
  const hours = Math.floor(mins / 60)
  const remainingMinutes = mins % 60

  // Format the hours and minutes with leading zeros if necessary
  const formattedHours = _.padStart(hours.toString(), 2, "0")
  const formattedMinutes = _.padStart(remainingMinutes.toString(), 2, "0")

  return `${formattedHours}:${formattedMinutes}`
}
export const toTop = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  })
}
