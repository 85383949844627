import React, { useState, useRef, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"

import toastr from "toastr"
import Swal from "sweetalert2"
import moment from "moment"

import "./style.scss"

import { del, get, post, put } from "../../../helpers/api_helper"
import {
  getAccountSubType,
  getAccountType,
  getBranches,
} from "../../../store/options/actions"
import useBranch from "../../../hooks/useBranch"
import { useNavigate } from "react-router-dom"
import { setTitle } from "../../../helpers/functions"
import TablePagination from "../../../components/Common/Pagination"

const ChartOfAccount = ({ options }) => {
  setTitle("Chart Of Account")
  const [tableData, setTableData] = useState([])
  const navigate = useNavigate()
  const formRef = useRef()

  const { accountType, accountSubType, branches } = useSelector(
    state => state.Options
  )
  const dispatch = useDispatch()

  const [masterObject, setMasterObject] = useState({
    name: "",
    type: ""
  })
  const [filterObject, setFilterObject] = useState({ subType: "", type: "" })

  const [branch] = useState(...useBranch())
  const [alreadyBranch] = useBranch()

  const [filterAccountSubType, setAccountType] = useState([])

  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 20

  const handleAccountSubTypeOptions = type => {
    get(`/options/account-sub-type?type=${type}`).then(res =>
      setAccountType(res.data)
    )
  }

  useEffect(() => {
    if (!branch) dispatch(getBranches())
    else setMasterObject(prev => ({ ...prev, branch: branch?.value }))
    dispatch(getAccountType())
    dispatch(getAccountSubType())
    handleAccountSubTypeOptions()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [filterObject, page])

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  const handleView = id => {
    navigate("/transaction-log", {
      state: { account: id },
    })
  }

  const handleTableData = async () => {
    get(
      `/accounts/chart-account/list?type=${filterObject.type}&subType=${filterObject.subType}&page=${page}&limit=${limit}`
    ).then(res => {
      const result = res.data
      result.map((item, index) => {
        // item.id = index + 1
        item.id = index + 1 + (page - 1) * limit

        if (item.updated) item.staff = item.updated
        else item.staff = item.added

        if (item.upDate) item.date = moment(item.upDate).format("DD-MM-YYYY")
        else item.date = moment(item.date).format("DD-MM-YYYY")
        if (item.upTime)
          item.time = moment(item.upTime, "HH:mm:ss").format("hh:mm a")
        else item.time = moment(item.time, "HH:mm:ss").format("hh:mm a")
        item.options = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              onClick={() => handleView(item._id)}
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
            ></i>
            {options.edit ? (
              <>
                <i
                  className="far fa-edit"
                  onClick={() => handleEdit(item)}
                  style={{
                    cursor: "pointer",
                    fontSize: "1em",
                    marginRight: "0.5rem",
                    marginLeft: "0.5rem",
                  }}
                ></i>
                <i
                  className="far fa-trash-alt"
                  onClick={() => handleDelete(item._id)}
                  style={{ cursor: "pointer", fontSize: "1em" }}
                ></i>
              </>
            ) : (
              ""
            )}
          </div>
        )

        return item
      })

      const count = res.count
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(result)
    })
  }

  const handleValueChange = async (value, name) => {
    const obj = { ...masterObject }
    obj[name] = value
    setMasterObject(obj)
  }

  const handleSubmit = () => {
    if (masterObject.id) {
      put("/accounts/chart-account/update", masterObject)
        .then(res => {
          reset()
          toastr.success(res.message)
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    } else {
      post("/accounts/chart-account/add", masterObject)
        .then(res => {
          reset()

          toastr.success(res.message)
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    }
  }

  const handleEdit = item => {
    toTop()
    setMasterObject({
      id: item._id,
      name: item.name,
      type: item.subTypeId,
      branch: item.branchId,
    })
  }

  const handleDelete = id => {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`/accounts/chart-account/delete?id=${id}`)
          .then(res => {
            toastr.success(res.message)
            handleTableData()
          })
          .catch(err => {
            toastr.success(err.response.data.message)
          })
      }
    })
  }

  const handleChange = (event, value) => {
    setPage(value)
  }

  const reset = () => {
    formRef.current.reset()
    handleTableData()
    setMasterObject({ name: "" })
    toTop()
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        width: 150,
      },
      {
        label: "Account Head",
        field: "type",
        width: 150,
      },
      {
        label: "Account Sub Head",
        field: "subType",
        width: 150,
      },
      {
        label: "Chart Name",
        field: "name",
        width: 150,
      },
      {
        label: "Balance",
        field: "balance",
        width: 150,
      },
      {
        label: "Added By",
        field: "staff",
        width: 150,
      },

      {
        label: "Actions",
        field: "options",
        sort: "desc",
        width: 400,
      },
    ],
    rows: tableData,
  }
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Chart of Account" />
          {options.edit ? (
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm onValidSubmit={handleSubmit} ref={formRef}>
                      <Row>
                        {!alreadyBranch ? (
                          <Col md={3}>
                            <div className="mb-3">
                              <Label>Branch</Label>
                              <Select
                                name="branch"
                                placeholder="Select..."
                                options={branches}
                                value={branches.filter(
                                  item => item.value === masterObject.branch
                                )}
                                onChange={selected => {
                                  handleValueChange(selected.value, "branch")
                                  handleAccountSubTypeOptions(selected?.value)
                                }}
                              />
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                        <Col md={3}>
                          <div className="mb-3">
                            <Label>Account Sub Head</Label>
                            <Select
                              name="type"
                              placeholder="Select..."
                              options={accountSubType}
                              value={accountSubType.filter(
                                item => item.value === masterObject.type
                              )}
                              onChange={selected => {
                                handleValueChange(selected.value, "type")
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <Label>Chart Name</Label>
                            <AvField
                              name="name"
                              className="form-control"
                              type="text"
                              value={masterObject.name}
                              onChange={e => {
                                handleValueChange(e.target.value, "name")
                              }}
                              validate={{ required: { value: true } }}
                              placeholder="Chart Name"
                              errorMessage="Enter Name"
                            />
                          </div>
                        </Col>
                        {!masterObject.id && (
                          <Col md={3}>
                            <div className="mb-3">
                              <Label>Balance</Label>
                              <AvField
                                name="Balance"
                                className="form-control"
                                type="number"
                                value={masterObject.balance || ""}
                                onChange={e => {
                                  handleValueChange(e.target.value, "balance")
                                }}
                                // validate={{ required: { min: 0 } }}
                                placeholder="Chart balance"
                                errorMessage="Enter balance"
                              />
                            </div>
                          </Col>
                        )}
                        <Col>
                          <div
                            className="mb-3 d-flex gap-2"
                            style={{
                              paddingTop:
                                alreadyBranch || masterObject.id ? "26px" : "0",
                            }}
                          >
                            <Button
                              color={`${
                                masterObject.id ? "warning" : "primary"
                              }`}
                              type="submit"
                            >
                              {masterObject.id ? "Update" : "Submit"}
                            </Button>
                            <Button color="danger" onClick={reset}>
                              Reset
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            ""
          )}

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label>Account Head </Label>
                          <Select
                            name="type"
                            placeholder="Select..."
                            options={accountType}
                            value={accountType.filter(
                              item => item.value === filterObject.type
                            )}
                            onChange={selected => {
                              setFilterObject(prev => ({
                                ...prev,
                                type: selected.value,
                              }))
                              handleAccountSubTypeOptions(selected.value)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label>Account Sub Head</Label>
                          <Select
                            name="subType"
                            placeholder="Select..."
                            // options={accountSubType}
                            // value={accountSubType.filter(
                            //   item => item.value === filterObject.subType
                            // )}
                            options={filterAccountSubType}
                            value={filterAccountSubType.filter(
                              item => item.value === filterObject.subType
                            )}
                            onChange={selected => {
                              setFilterObject(prev => ({
                                ...prev,
                                subType: selected.value,
                              }))
                            }}
                          />
                        </div>
                      </Col>
                      <Col>
                        <div
                          className="mb-3 d-flex gap-2"
                          style={{ paddingTop: "28px" }}
                        >
                          <Button
                            color="danger"
                            type="submit"
                            onClick={() => setFilterObject({})}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>

                  <MDBDataTable
                    id="chartAccountID"
                    bordered
                    responsive
                    data={data}
                    disableRetreatAfterSorting={true}
                    info={false}
                    sortable={false}
                    searching={false}
                    paging={false}
                  ></MDBDataTable>
                  <TablePagination
                    page={page}
                    onChange={handleChange}
                    count={totalPage}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default ChartOfAccount
