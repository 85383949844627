import PropTypes from "prop-types"
import React from "react"
import { Col, Card, CardBody } from "reactstrap"
import CountUp from "react-countup"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

const MiniWidget = props => {
  const navigate = useNavigate();
  const getDateFilter = useSelector(state => state.Dashboard.data)


  return (
    <React.Fragment>
      {props.reports.map((report, key) => (
        <Col md={6} xl={4} lg={4} key={key}> 
          <Card>
            <CardBody  style={{cursor:"pointer"}}  onClick={()=>navigate('/events', { state: { eventStatus: report.id,startDate:getDateFilter?.startDate,endDate:getDateFilter?.endDate } })}>
              <div className="float-end mt-2">
           
                <i style={{marginTop:"2px" ,fontSize:"30px"}} className={report.icon2}></i>

              </div>
              <div>
                <h4 className="mb-1 mt-1">
                  <span>
                    <CountUp
                      end={report.value || 0}
                      separator=","
                      prefix={report.prefix}
                      suffix={report.suffix}
                      decimals={report.decimal}
                    />
                  </span>
                </h4>
                <p className="text-muted mb-0">{report.title}</p>
              </div>
              <p className="text-muted mt-3 mb-0">
                <span className={"text-" + report.color + " me-1"}>
                  {/* <i className={report.icon + " me-1"}></i> */}
                  {report.badgeValue || 0}
                </span>{" "}
                {report.desc}
              </p>
            </CardBody>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  )
}

export default MiniWidget

MiniWidget.propTypes = {
  reports: PropTypes.array,
}
