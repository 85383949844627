import React, { useEffect, useRef, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap"
import Select from "react-select"
import { MDBDataTable } from "mdbreact"
import "./style.scss"
import {
  getDate,
  dateConverter,
  setTitle,
  numberToCurrency,
} from "../../../../helpers/functions"
import { get, post, put } from "../../../../helpers/api_helper"
import useBranch from "../../../../hooks/useBranch"
import { useDispatch, useSelector } from "react-redux"
import { getBranches } from "../../../../store/options/actions"
import moment from "moment"
import toastr from "toastr"
import Details from "./details"
import TablePagination from "../../../../components/Common/Pagination"

const CreditNotes = ({ options }) => {
  setTitle("Credit Note")

  const date = getDate()
  const time = moment().format("HH:mm")

  const dispatch = useDispatch()
  const formRef = useRef()
  const initialState = {
    branch: "",
    paymentFor: "",
    student: "",
    course: "",
    event: "",
    amount: "",
    date: date,
    time: time,
  }
  const selectInitialState = {
    branch: "",
    paymentFor: "",
    student: "",
    course: "",
    event: "",
  }
  const paymentDetailsInitialState = {
    fee: 0,
    paid: 0,
    due: 0,
    history: [],
  }

  const toggle = (start = true) => {
    var body = document.body

    if (start) {
      body.classList.remove("vertical-collpsed")
      body.classList.remove("sidebar-enable")
      body.setAttribute("data-sidebar-size", "sm")
    } else {
      body.classList.add("vertical-collpsed")
      body.classList.add("sidebar-enable")
      body.setAttribute("data-sidebar-size", "lg")
    }
  }

  useEffect(() => {
    toggle()

    return () => {
      toggle(false)
    }
    // eslint-disable-next-line
  }, [])
  const [selectedFields, setSelectedFields] = useState(selectInitialState)
  const [modal, setModal] = useState(false)
  const [selectedId, setSelectedId] = useState(null)
  const [masterObject, setMasterObject] = useState(initialState)
  const [tableData, setTableData] = useState([])
  const [fundSourceOptions, setFundSourceOptions] = useState([])

  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 20

  const forOptions = [
    { label: "Course", value: 0 },
    { label: "Event", value: 1 },
  ]
  const [courseOptions, setCourseOptions] = useState([])
  const [eventOptions, setEventOptions] = useState([])
  const [studentOptions, setStudentOptions] = useState([])

  const [paymentDetails, setPaymentDetails] = useState(
    paymentDetailsInitialState
  )
  const [branch, setBranch] = useState(...useBranch())
  const [alreadyBranch] = useBranch()
  const { branches } = useSelector(state => state.Options)

  const statusOptions = [
    { label: "Approve", value: 0 },
    { label: "Cancel", value: 1 },
  ]

  useEffect(() => {
    if (!branch) {
      dispatch(getBranches())
    } else {
      setSelectedFields(prev => ({ ...prev, branch: branch }))
      setMasterObject(prev => ({ ...prev, branch: branch.value }))
      handleFundSourceOptions(branch.value)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [page])

  const handleSubmit = async () => {
    try {
      const response = await post(`/credit-note/create`, masterObject)
      reset()
      toastr.success(response.message)
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }
  const handleStudentOptions = async (fields = masterObject) => {
    if (!fields.branch) return
    try {
      const query = `credit-note/options/student?branch=${fields.branch}&paymentFor=${fields.paymentFor}`
      const response = await get(query)

      setStudentOptions(response.data)
    } catch {}
  }

  const handleCourseOptions = async (fields = masterObject) => {
    if (!fields.branch || !fields.student) return
    try {
      const query = `credit-note/options/courses?branch=${fields.branch}&paymentFor=${fields.paymentFor}&student=${fields.student}`
      const response = await get(query)

      setCourseOptions(response.data)
    } catch {}
  }

  const handleEventOptions = async (fields = masterObject) => {
    if (!fields.branch || !fields.student) return
    try {
      const query = `credit-note/options/events?branch=${fields.branch}&paymentFor=${fields.paymentFor}&student=${fields.student}`
      const response = await get(query)

      setEventOptions(response.data)
    } catch {}
  }

  const handlePaymentDetails = async (fields = masterObject) => {
    try {
      const query = `credit-note/history?student=${fields.student}&paymentFor=${fields.paymentFor}&course=${fields.course}&event=${fields.event}`
      const response = await get(query)

      handlePaymentDetailsHistory(response.data)
    } catch (error) {}
  }

  const handlePaymentDetailsHistory = data => {
    if (data === null) return
    data.due = (data.fee || 0) - (data.paid || 0)
    data.fee = numberToCurrency(data.fee)
    data.paid = numberToCurrency(data.paid)

    data.history =
      data.history &&
      data.history.map((item, index) => {
        item.id = index + 1
        item.date = dateConverter(item.date)
        item.type =
          item.type === 1
            ? "Cash"
            : item.type === 2
            ? "Skill loan"
            : item.type === 3
            ? "Scholarship"
            : "Online"

        // item.amount = item.amount?.toLocaleString("en-IN") || 0
        item.amount = numberToCurrency(item.amount)

        item.status = (
          <Button color="success" disabled size="sm">
            success
          </Button>
        )
        return item
      })

    setPaymentDetails(data)
  }
  const handleTableData = async () => {
    try {
      const response = await get(`credit-note/list?page=${page}&limit=${limit}`)
      const data = response.data
      data.map((item, index) => {
        // item.id = index + 1

        item.id = index + 1 + (page - 1) * limit
        item.date = dateConverter(item.date)
        item.time = moment(item.time, "HH:mm:ss").format("hh:mm a")
        item.student = `${item.studentFirstName || ""} ${
          item.studentLastName || ""
        } `
        item.addedBy = `${item.addedByFirstName || ""} ${
          item.addedByLastName || ""
        } `

        item.fee = numberToCurrency(item.fee)
        item.amount = numberToCurrency(item.amount)
        item.due = numberToCurrency(item.due)

        if (item.status === 2 && options.edit)
          item.status = (
            <Select
              menuPosition="fixed"
              onChange={selected =>
                handleStatusChange(item._id, selected.value)
              }
              options={statusOptions}
            ></Select>
          )
        else
          item.status = (
            <Button
              color={
                item.status === 0
                  ? "success"
                  : item.status === 2
                  ? "warning"
                  : "danger"
              }
              size="sm"
              disabled
            >
              {item.status === 0
                ? "Approved"
                : item.status === 2
                ? "Pending"
                : "Cancelled"}
            </Button>
          )
        item.options = (
          <div>
            <i
              className="fas fa-eye"
              onClick={() => {
                handlePopUp(item._id)
              }}
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
            ></i>
          </div>
        )
        return item
      })
      const count = response.count
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(data)
    } catch (error) {}
  }

  const handleFundSourceOptions = async branch => {
    get(`accounts/chart-account/options?$branch=${branch}`).then(res =>
      setFundSourceOptions(res.data)
    )
  }
  const handleStatusChange = async (id, status) => {
    try {
      const response = await put(`credit-note/status`, { id, status })
      handleTableData()
      toastr.success(response.message)
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }
  const handleSelectValueChange = async (name, selected) => {
    let fields = { ...selectedFields }
    fields[name] = selected
    if (name === "paymentFor" || name === "branch") {
      fields = {
        branch: fields.branch,
        paymentFor: fields.paymentFor,
      }
    }

    if (name === "student") {
      fields = {
        branch: fields.branch,
        paymentFor: fields.paymentFor,
        student: fields.student,
      }
    }
    setSelectedFields(fields)
    await handleValueChange(name, selected.value)
  }

  const handleValueChange = async (name, value) => {
    let fields = { ...masterObject }
    fields[name] = value

    if (name === "paymentFor" || name === "branch") {
      fields = {
        branch: fields.branch,
        paymentFor: fields.paymentFor,
        amount: fields.amount,
        date: fields.date,
        time: fields.time,
      }
      handleStudentOptions(fields)
      setCourseOptions([])
      setEventOptions([])
    }

    if (name === "branch") handleFundSourceOptions(value)

    if (name === "student") {
      if (fields.paymentFor === 0) handleCourseOptions(fields)
      if (fields.paymentFor === 1) handleEventOptions(fields)

      fields = {
        branch: fields.branch,
        paymentFor: fields.paymentFor,
        student: fields.student,
        amount: fields.amount,
        date: fields.date,
        time: fields.time,
      }
    }

    if (name === "course" || name === "event") handlePaymentDetails(fields)
    setMasterObject(fields)
  }

  const handlePopUp = id => {
    setModal(true)
    setSelectedId(id)
  }

  const handleChange = (event, value) => {
    setPage(value)
  }

  const reset = () => {
    setPage(1)
    setMasterObject({ ...initialState, branch: alreadyBranch?.value })
    setSelectedFields(selectInitialState)
    setBranch(alreadyBranch)
    handleTableData()
    formRef.current.reset()
    setPaymentDetails(paymentDetailsInitialState)
    if (!alreadyBranch) setFundSourceOptions([])
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        width: 150,
      },
      {
        label: "Credit Id",
        field: "uniqueId",
        width: 150,
      },
      {
        label: "Student",
        field: "student",
        width: 150,
      },
      {
        label: "Register No",
        field: "studentId",
        width: 150,
      },
      {
        label: "Course/Event",
        field: "name",
        width: 150,
      },
      {
        label: "Course Fee",
        field: "fee",
        width: 150,
      },
      {
        label: "Amount",
        field: "amount",
        width: 150,
      },
      {
        label: "Due",
        field: "due",
        width: 150,
      },

      {
        label: "Status",
        field: "status",
        width: 150,
      },
      {
        label: "Action",
        field: "options",
        width: 150,
      },
    ],
    rows: tableData,
  }

  const paymentHistory = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
        textCenter: true,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
        textCenter: true,
      },
      {
        label: "Type",
        field: "type",
        width: 150,
        textCenter: true,
      },

      {
        label: "Receipt No",
        field: "uniqueId",
        width: 150,
        textCenter: true,
      },
      {
        label: "Paid Amount",
        field: "amount",
        width: 150,
        textCenter: true,
      },
      {
        label: "Status",
        field: "status",
        width: 150,
        textCenter: true,
      },
    ],
    rows: paymentDetails.history,
  }
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Credit Notes" />
          {options.edit ? (
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm
                      id="manualPaymentsForm"
                      ref={formRef}
                      onValidSubmit={handleSubmit}
                    >
                      <Row>
                        {alreadyBranch ? (
                          ""
                        ) : (
                          <Col md={2}>
                            <Label>CSP</Label>
                            <Select
                              value={branch}
                              options={branches}
                              onChange={selected => {
                                setBranch(selected)
                                handleSelectValueChange("branch", selected)
                              }}
                            ></Select>
                          </Col>
                        )}
                        <Col md={2}>
                          <div className="mb-3">
                            <Label>Payment For</Label>
                            <Select
                              value={selectedFields.paymentFor}
                              onChange={selected =>
                                handleSelectValueChange("paymentFor", selected)
                              }
                              name="for"
                              options={forOptions}
                            />
                          </div>
                        </Col>

                        <Col md={3}>
                          <div className="mb-3">
                            <Label>Student</Label>
                            <Select
                              name="student"
                              isDisabled={
                                !masterObject.paymentFor && !masterObject.branch
                              }
                              options={studentOptions}
                              value={selectedFields.student || ""}
                              onChange={selected =>
                                handleSelectValueChange("student", selected)
                              }
                            />
                          </div>
                        </Col>

                        {masterObject.paymentFor === 0 ? (
                          <Col md={3}>
                            <div className="mb-3">
                              <Label>Course</Label>
                              <Select
                                name="course"
                                options={courseOptions}
                                value={selectedFields.course || ""}
                                onChange={selected =>
                                  handleSelectValueChange("course", selected)
                                }
                              />
                            </div>
                          </Col>
                        ) : masterObject.paymentFor === 1 ? (
                          <Col md={3}>
                            <div className="mb-3">
                              <Label>Event</Label>
                              <Select
                                name="Event"
                                options={eventOptions}
                                value={selectedFields.event || ""}
                                onChange={selected =>
                                  handleSelectValueChange("event", selected)
                                }
                              />
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        <Col md={2}>
                          <div className="mb-3">
                            <AvField
                              name="amount"
                              label="Amount"
                              placeholder="Amount"
                              type="number"
                              validate={{
                                required: { value: true },
                                min: { value: 0 },
                              }}
                              errorMessage="Enter Valid Amount"
                              value={masterObject.amount}
                              onChange={e =>
                                handleValueChange(e.target.name, e.target.value)
                              }
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Fund Source</Label>
                            <Select
                              name="fundSource"
                              value={selectedFields.fundSource || ""}
                              onChange={selected => {
                                handleSelectValueChange("fundSource", selected)
                              }}
                              options={fundSourceOptions}
                              errorMessage="Enter Fund Source"
                            />
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="mb-3">
                            <AvField
                              name="date"
                              label="Date"
                              type="date"
                              value={masterObject.date}
                              defaultValue={date}
                              max={date}
                              onChange={e =>
                                handleValueChange(e.target.name, e.target.value)
                              }
                            />
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="mb-3">
                            <AvField
                              name="time"
                              label="Time"
                              type="time"
                              defaultValue={time}
                              value={masterObject.time}
                              onChange={e =>
                                handleValueChange(e.target.name, e.target.value)
                              }
                            />
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <AvField
                              name="remarks"
                              label="Remarks"
                              type="textarea"
                              rows="1"
                              placeholder="Remarks"
                              value={masterObject.remarks || ""}
                              onChange={e =>
                                handleValueChange(e.target.name, e.target.value)
                              }
                            />
                          </div>
                        </Col>
                        <Col md={2}>
                          <div
                            className={
                              paymentDetails.history?.length
                                ? "mb-2 pt-4"
                                : masterObject.paymentFor === 0 ||
                                  masterObject.paymentFor === 1 ||
                                  !alreadyBranch
                                ? "pt-4"
                                : ""
                            }
                          >
                            <Button color="primary" className="me-2">
                              Submit
                            </Button>
                            <Button
                              color="danger"
                              type="button"
                              onClick={reset}
                            >
                              Reset
                            </Button>
                          </div>
                        </Col>
                        {paymentDetails.history?.length ? (
                          <>
                            <Col md={12}>
                              <Row>
                                <Col
                                  md={3}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Label>Fee</Label>
                                  <Label>{paymentDetails.fee}</Label>
                                </Col>
                                <Col
                                  md={3}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Label>Paid Amount</Label>
                                  <Label>{paymentDetails.paid}</Label>
                                </Col>
                                <Col
                                  md={3}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Label>Due Amount</Label>
                                  <Label>₹{paymentDetails.due}</Label>
                                </Col>
                                <Col
                                  md={3}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                ></Col>
                              </Row>
                            </Col>
                            <Col md={12}>
                              <MDBDataTable
                                id="creditNoteFirstTable"
                                bordered
                                responsive
                                searching={false}
                                entries={20}
                                displayEntries={false}
                                paging={false}
                                disableRetreatAfterSorting={true}
                                data={paymentHistory}
                                sortable={false}
                              ></MDBDataTable>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            ""
          )}

          <Card>
            <CardBody>
              <MDBDataTable
                id="creditNoteTable"
                className="mt-3"
                bordered
                responsive
                searching={false}
                entries={20}
                paging={false}
                info={false}
                sortable={false}
                disableRetreatAfterSorting={true}
                data={data}
              ></MDBDataTable>
              <TablePagination
                page={page}
                onChange={handleChange}
                count={totalPage}
              />
            </CardBody>
          </Card>
        </div>
      </div>
      <Modal
        show={modal}
        isOpen={modal}
        toggle={() => setModal(!modal)}
        size="lg"
        centered={true}
        className="lead-modal"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Details</h5>
          <button
            type="button"
            onClick={() => {
              setModal(false)
              // handleTableData()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <Details id={selectedId} />
      </Modal>
    </>
  )
}

export default CreditNotes
