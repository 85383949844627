import { GET_DATE } from "./actionsType"


const initialState = {
  message: null,
  loading: false,
  data: null,
}

const Dashboard = (state = initialState, action) => {
  switch (action.type) {
    case GET_DATE:
      state = {
        ...state,
        loading: true,
        data:action.payload
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Dashboard
