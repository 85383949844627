import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Card, Col, Row, CardBody } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { get } from "../../../helpers/api_helper"

const CommunityList = () => {
  // Define static data
  useEffect(() => {
    handleCardData()
  }, [])

  const [staticData, setstaticData] = useState(null)
  // const staticData = [
  //   {
  //     branchId: 1,
  //     name: "Node Community",
  //     member: 36,
  //     event: 22,
  //     sector: "IT",
  //     Totalrequest: "89",
  //     Totalaccept: "50",
  //   },
  //   {
  //     branchId: 2,
  //     name: "Python Community ",
  //     member: 42,
  //     sector: "IT",
  //     event: 33,
  //     Totalrequest: "79",
  //     Totalaccept: "53",
  //   },
  //   {
  //     branchId: 4,
  //     name: "React Community ",
  //     member: 65,
  //     event: 40,
  //     sector: "IT",
  //     Totalrequest: "59",
  //     Totalaccept: "20",
  //   },
  //   {
  //     branchId: 5,
  //     name: "Java Community ",
  //     member: 30,
  //     event: 15,
  //     sector: "IT",
  //     Totalrequest: "30",
  //     Totalaccept: "25",
  //   },
  //   {
  //     branchId: 6,
  //     name: "MERN Community",
  //     member: 44,
  //     event: 24,
  //     sector: "IT",
  //     Totalrequest: "89",
  //     Totalaccept: "50",
  //   },
  //   {
  //     branchId: 7,
  //     name: "MEAN Community ",
  //     member: 50,
  //     event: 35,
  //     sector: "IT",
  //     Totalrequest: "89",
  //     Totalaccept: "50",
  //   },
  //   {
  //     branchId: 8,
  //     name: "Flutter Community ",
  //     member: 58,
  //     event: 36,
  //     sector: "IT",
  //     Totalrequest: "40",
  //     Totalaccept: "55",
  //   },
  //   {
  //     branchId: 9,
  //     name: "Next Community ",
  //     member: 200,
  //     event: 300,
  //     sector: "IT",
  //     Totalrequest: "89",
  //     Totalaccept: "50",
  //   },
  //   // Add more static data objects as needed
  // ]
  function handleCardData(searchQuery = "") {
    get(`/community/list?keyword=${searchQuery}`).then(res => {
      let result = res?.data
      setstaticData(result)
    })
  }
  function handleSearch(e) {
    const searchQuery = e.target.value
    handleCardData(searchQuery)
  }
  // console.log(staticData,"staticdata");
  const navigate = useNavigate()

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem=" Community List" />

          <Row>
            <Col md={6}></Col>
            <Col md={6}>
              <div className="form-inline float-md-end mb-3">
                <div className="search-box ml-2">
                  <div className="position-relative">
                    <input
                      type="text"
                      onChange={handleSearch}
                      className="form-control rounded border-0"
                      placeholder="Search Community..."
                    />
                    <i className="mdi mdi-magnify search-icon"></i>
                  </div>
                </div>
              </div>
            </Col>
            {staticData?.map((item, index) => (
              <Col md="4" xl="4">
                <Card>
                  <CardBody
                    onClick={() => {
                      navigate(`/community_details/${item?.community}`, {
                        state: { id: item._id },
                      })
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="">
                      <div>
                        <h5 class="font-size-16 mb-1">
                          <a class="text-dark" href="/">
                            {item?.community}
                          </a>
                        </h5>
                        <div
                          style={
                            {
                              // display: "flex",
                              // justifyContent: "space-between",
                            }
                          }
                          className=""
                        >
                          <div style={{ paddingTop: "13px" }}>
                            <p style={{ fontSize: "14px" }} className="mb-0">
                              Total Members:{" "}
                              <span style={{ fontWeight: "bold" }}>
                                {item?.totalMembersCount}
                              </span>
                            </p>
                            <p style={{ fontSize: "14px" }} className="mb-0">
                              New Request:{" "}
                              <span style={{ fontWeight: "bold" }}>
                                {item?.totalRequestCount}
                              </span>
                            </p>
                            <p style={{ fontSize: "14px" }} className="mb-0">
                              Events:{" "}
                              <span style={{ fontWeight: "bold" }}>
                                {item?.eventCount}
                              </span>
                            </p>
                            <p style={{ fontSize: "14px" }} className="mb-0">
                              {" "}
                              Sector:{" "}
                              <span style={{ fontWeight: "bold" }}>
                                {item?.sector?.label}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CommunityList
