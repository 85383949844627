import React, { useEffect, useRef, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Modal,
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  Nav,
} from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import "./Lead.scss"
import classnames from "classnames"
import { get, put } from "../../../helpers/api_helper"
import moment from "moment"
import toastr from "toastr"
import { jsonToExcel } from "../../../helpers/functions"
import { useNavigate } from "react-router-dom"

function CommunityRequest() {
  const [popupView, setPopupView] = useState(false)
  const handleCloseModal = () => setPopupView(false)
  const [setSelectedStatus] = useState(null)
const navigate = useNavigate()
  // eslint-disable-next-line
  const [master, setmaster] = useState({
    name: " Muhammad Thaha Kp",
    mobile: "9876543467",
    email: "Thahakp@gmail.com",
    adhar: "8574648748474949",
    city: "Thalassery",
  })
  const [activeTabJustify, setactiveTabJustify] = useState("1")
  const [communityRequest, setCommunityRequest] = useState([])
  const [communityOptions, setCommunityOptions] = useState([])
  const [selectedcommunity, setselectedCommunity] = useState(null)
  const [selectedStatus, setselectedStatus] = useState(null)
  const [eventOptions, setEventOptions] = useState([])
  const [exports, setExports] = useState([])
  const initialState = {
    to: "",
    from: "",
    community: "",
    event: "",
    status: "",
  }
  const status = [
    { label: "Pending", value: 0 },
    { label: "Approved", value: 1 },
    { label: "Rejected", value: 2 },
  ]
  const formRef = useRef()
  const reset = () => {
    formRef.current.reset()
    setselectedCommunity(null)
    setselectedStatus(null)
    setMasterObject(initialState)
  }

  const [masterObject, setMasterObject] = useState(initialState)
  function toggleCustomJustified(tab) {
    if (activeTabJustify !== tab) {
      setactiveTabJustify(tab)
    }
  }
  useEffect(() => {
    handleTableData()
  }, [masterObject])

  useEffect(() => {
    fetch_student()
    fetch_Events()
  }, [])

  function handleTableData() {
    const SearchQuery = `from=${masterObject.from}&to=${masterObject.to}&event=${masterObject.event}&community=${masterObject.community}&status=${masterObject.status}`
    get(`/request-community/list?${SearchQuery}`).then(res => {
      let result = res?.data
      let data = []
      const exportData = []
      result.map((item, index) => {
        item.id = index + 1
        console.log(item,"items");
        item.date = moment(item.date).format("DD-MM-YYYY")
        item.user = item.addedBy.name
        item.uniqueid = item?.requestedStudent?.unique_id
        item.event = item?.events

        item.community = item?.community?.name

        item.action =(
          <>
          <i
              onClick={() => {
              navigate(`/student-details/${item?.requestedStudent?.unique_id}`,{
                state:{id:item?.requestedStudent?._id},
              })
              }}
              style={{
                cursor: "pointer",
              }}
              className="fas fa-eye"
            ></i></>
        )
        item.member =
          item?.requestedStudent?.firstName +
          " " +
          item?.requestedStudent?.firstName
        if (item.approvedStatus === 1) {
          item.statusOption = (
            <Button size="sm" color="success" disabled>
              Approved
            </Button>
          )
        } else if (item.approvedStatus === 2) {
          item.statusOption = (
            <Button size="sm" color="danger" disabled>
              Rejected
            </Button>
          )
        } else {
          item.statusOption = (
            <Select
              options={statusOptions}
              value={statusOptions.filter(
                status => status.value === item.approvedStatus
              )}
              onChange={selected =>
                handleStatusChange(item._id, selected.value)
              }
              menuPosition="fixed"
            ></Select>
          )
        }
        const dataExport = {}

        dataExport.id = item.id
        dataExport.Date = item.date
        dataExport.Name = item.uniqueid
        dataExport.Mobile = item.member
        dataExport.Community = item.community
        dataExport.User = item.user
        dataExport.Status =
          item?.approvedStatus === 0
            ? "Pending"
            : item?.approvedStatus === 1
            ? "Approved"
            : "Rejected"
        exportData.push(dataExport)
        data.push(item)
      })
      setExports(exportData)
      setCommunityRequest(data)
    })
  }
  const handleExport = () => {
    if (exports.length > 0) {
      jsonToExcel(exports, `Community-${moment().format("DD-MM-YY")}`)
    } else {
      toastr.info(`There are no Requests to export`)
    }
  }
  function fetch_student() {
    get("/community/option").then(res => {
      var event_data = res.data.map(item => {
        // console.log(item, "itemsss")
        return {
          label: item?.community,
          value: item?._id,
        }
      })
      setCommunityOptions([{ options: event_data }])
    })
  }
  function fetch_Events() {
    get("/events/listOptions").then(res => {
      var event_data = res.data.map(item => {
        return {
          label: item?.name,
          value: item?._id,
        }
      })

      setEventOptions([{ options: event_data }])
    })
  }

  const handleStatusChange = async (requestId, approvedStatus) => {
    if (approvedStatus !== 0) {
      put("/request-community/approve", { requestId, approvedStatus })
        .then(res => {
          handleTableData()
          toastr.success(res.message)
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    }
  }

  function handleSelectChange(selected, name) {
    switch (name) {
      case "status":
        setSelectedStatus(selected)
        break

      default:
        break
    }
  }

  const District = [
    { label: "Kozhikkode", value: 1 },
    { label: "Kannur", value: 2 },
    { label: "Malappuram", value: 3 },
  ]
  const statusOptions = [
    { label: "Pending", value: 0 },
    { label: "Approve", value: 1 },
    { label: "Rejected", value: 2 },
  ]
  // const staff = [
  //   { label: "admin", value: 1 },
  //   { label: "Ajay ", value: 2 },
  //   { label: "Varun", value: 3 },
  // ]
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "ID",
        field: "uniqueid",
        width: 150,
      },
      {
        label: "Members",
        field: "member",
        width: 150,
      },
      {
        label: "Community",
        field: "community",
        width: 150,
      },

      // {
      //   label: "Request date",
      //   field: "date",
      //   width: 150,
      // },
      // {
      //   label: "Event",
      //   field: "event",
      //   width: 150,
      // },

      // {
      //   label: "District",
      //   field: "disdtrict",
      //   width: 150,
      // },
      // {
      //   label: "Status",
      //   field: "statdus",
      //   width: 150,
      // },
      {
        label: "User",
        field: "user",
        width: 150,
      },
      {
        label: "Status",
        field: "statusOption",
        sort: "desc",
        width: 400,
      },
      {
          label: "Action",
          field: "action",
          width: 150,
        },
    ],
    rows: communityRequest,
  }
  const viewdetail_data = {
    // eslint-disable-next-line
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      // {
      //   label: "Ref No.",
      //   field: "ref",
      //   width: 150,
      // },

      {
        label: "Next Followup",
        field: "next",
        width: 150,
      },

      {
        label: "Comments",
        field: "Comments",
        width: 150,
      },

      {
        label: "Status",
        field: "Status",
        sort: "desc",
        width: 400,
      },
      //   {
      //     label: "Actions",
      //     field: "options",
      //     sort: "desc",
      //     width: 400,
      //   },
    ],
    rows: [
      {
        id: "1",
        date: "13-11-2023 12:11 pm",
        ref: "ASP112TRGF",
        Comments: "Status Changed to Prospective",
        next: "25-02-2024",
        Status: (
          <>
            <span className="badge bg-success font-size-12 ms-2">
              Prospective
            </span>
          </>
        ),
        Staff: "",
        options: (
          <div className="d-flex gap-2">
            <i
              className="uil-edit-alt"
              style={{ fontSize: "1.2em", cursor: "pointer" }}
            ></i>

            <i
              className="uil-trash-alt"
              style={{ fontSize: "1.2em", cursor: "pointer" }}
            ></i>
          </div>
        ),
      },
      {
        id: "2",
        date: "10-11-2023 12:11 pm",
        ref: "ASP112TRGF",
        Comments: "Contacted ",
        next: "18-02-2024",
        Status: (
          <>
            <span className="badge bg-warning font-size-12 ms-2">New</span>
          </>
        ),
        Staff: "",

        options: (
          <div className="d-flex gap-2">
            <i
              className="uil-edit-alt"
              style={{ fontSize: "1.2em", cursor: "pointer" }}
            ></i>

            <i
              className="uil-trash-alt"
              style={{ fontSize: "1.2em", cursor: "pointer" }}
            ></i>
          </div>
        ),
      },
    ],
  }
  const handleValueChange = (value, name) => {
    const obj = { ...masterObject }
    obj[name] = value
    setMasterObject(obj)
  }
  console.log(masterObject, "massteosjdfbb")

  return (
    <>
      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="xl"
        centered={true}
        className="lead-modal"
      >
        <div className="modal-header">
          <div className="modal-title">
            <h6 style={{ marginBottom: "0px" }} className="modal-title mt-0">
              #LD231001
            </h6>
          </div>
          <button
            style={{ marginBottom: "2px" }}
            type="button"
            onClick={handleCloseModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body">
          <Nav
            style={{ marginTop: "-16px" }}
            tabs
            className="nav-tabs-custom nav-justified"
          >
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTabJustify === "1",
                })}
                onClick={() => {
                  toggleCustomJustified("1")
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="fas fa-home"></i>
                </span>
                <span className="d-none d-sm-block">Lead Info</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTabJustify === "2",
                })}
                onClick={() => {
                  toggleCustomJustified("2")
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="fas fa-rupee-sign"></i>
                </span>
                <span className="d-none d-sm-block">Update Details</span>
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTabJustify}>
            <TabPane tabId="1" className="p-3">
              <Row>
                <Col md="12">
                  <table className="table table-borderd lead-info table-striped">
                    <tbody>
                      <tr>
                        <td>Name</td>
                        <th>: Muhammad Thaha Kp</th>
                        <td>Mobile</td>
                        <th>: 9876543467</th>
                        <td>Email</td>
                        <th>: Thahakp@gmail.com</th>
                        <td>District</td>
                        <th>: Kannur</th>
                      </tr>
                      <tr>
                        <td>Lead Type</td>
                        <th>: Course</th>
                        <td>Source</td>
                        <th>: Website</th>
                        <td>Campaign</td>
                        <th>: Partner Campaign</th>
                        <td>Date&Time</td>
                        <th>: 12-02-2024 , 5.30PM</th>
                      </tr>
                      <tr>
                        <td>Course</td>
                        <th>: Full Stack .NET with MEAN</th>
                        <td>CSP</td>
                        <th>: CSP KANNUR</th>
                        <td>Expected Start Date</td>
                        <th>: 02-03-2024</th>
                        <td></td>
                        <th></th>
                      </tr>
                      <tr>
                        <td>Payment </td>
                        <th>: Scholorship Requested </th>
                        <td>Amount</td>
                        <th>: 50% Scholarship </th>
                        <td></td>
                        <th></th>
                        <td></td>
                        <th></th>
                      </tr>
                    </tbody>
                  </table>
                </Col>
                <Col md="12" lg="12">
                  <AvForm>
                    <Row>
                      <Col md="3">
                        <div className="">
                          <Label>Status</Label>
                          <Select
                            name="description"
                            type="text"
                            onChange={selected => {
                              handleSelectChange(selected, "status")
                            }}
                            options={[
                              {
                                label: "Prospective",
                                value: 2,
                              },

                              {
                                label: "Cold Lead",
                                value: 1,
                              },
                              {
                                label: "Reassign",
                                value: 6,
                              },

                              {
                                label: "Not Intreseted",
                                value: 3,
                              },
                              {
                                label: "Not Reachable",
                                value: 4,
                              },
                              {
                                label: "Register",
                                value: 5,
                              },
                            ]}
                            errorMessage="Enter service"
                          />
                        </div>
                      </Col>
                      <>
                        <Col md="3">
                          <div className="">
                            <Label>Course</Label>
                            <Select
                              name="description"
                              type="text"
                              options={[
                                {
                                  label: "Diploma in Professional Accounting",
                                  value: 0,
                                },
                                {
                                  label:
                                    "Certified Program in EV Design, Simulation and Component Selection",
                                  value: 1,
                                },
                                {
                                  label: "General Duty Assistant",
                                  value: 2,
                                },
                                {
                                  label: "Texturing and LookDev",
                                  value: 3,
                                },
                                {
                                  label: "Automobile Technician",
                                  value: 4,
                                },
                              ]}
                              errorMessage="Enter service"
                            />
                          </div>
                        </Col>
                      </>

                      <Col md="3">
                        <div className="">
                          <Label>Comments</Label>
                          <AvField
                            name="Comments"
                            rows={1}
                            type="textarea"
                            placeholder="Comments"
                            errorMessage="Enter Description"
                          />
                        </div>
                      </Col>

                      <Col>
                        <div className="mb-3" style={{ paddingTop: "25px" }}>
                          <Button
                            style={{ marginRight: "1%" }}
                            color="primary"
                            type="submit"
                            className="mx-2"
                          >
                            Submit
                          </Button>
                          <Button
                            style={{ marginRight: "3%" }}
                            color="danger"
                            type="submit"
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </Col>
                <Col md="12">
                  <MDBDataTable
                    id=""
                    responsive
                    bordered
                    data={viewdetail_data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2" className="p-3">
              <AvForm>
                <Col md="12">
                  <table className="table table-borderd lead-info table-striped">
                    <tbody>
                      <tr>
                        <td style={{ width: "100px" }}>Name</td>
                        <td>
                          <AvField
                            name="name"
                            placeholder="Name"
                            value={master?.name}
                          />
                        </td>
                        <td style={{ width: "100px" }}>D.O.B</td>
                        <td>
                          <AvField name="name" placeholder="D.OB" type="date" />
                        </td>
                        <td style={{ width: "100px" }}>Mobile</td>
                        <td>
                          <AvField
                            name="name"
                            placeholder="Mobile"
                            value={master?.mobile}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "100px" }}>Email</td>
                        <td>
                          <AvField
                            name="name"
                            placeholder="Email"
                            value={master?.email}
                          />
                        </td>
                        <td style={{ width: "100px" }}>Gender</td>
                        <td>
                          <Select
                            name="name"
                            defaultValue={{ label: "Male", value: "1" }}
                            options={[
                              { label: "Male", value: "1" },
                              { label: "Fale", value: "2" },
                            ]}
                          />
                        </td>
                        <td style={{ width: "100px" }}>Category</td>
                        <td>
                          <Select
                            name="name"
                            options={[
                              { label: "GENERAL", value: "1" },
                              { label: "OBC/EZ", value: "2" },
                              { label: "SC/ST", value: "3" },
                            ]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "100px" }}>State</td>
                        <td>
                          <Select
                            name="name"
                            defaultValue={{ label: "Kerala", value: "1" }}
                            options={[{ label: "Kerala", value: "1" }]}
                          />
                        </td>
                        <td style={{ width: "100px" }}>District</td>
                        <td>
                          <Select
                            name="name"
                            defaultValue={{ label: "Kannur", value: "1" }}
                            options={[
                              { label: "Kannur", value: "1" },
                              { label: "Kozhikod", value: "2" },
                              { label: "Malapuram", value: "3" },
                              { label: "Thrissur", value: "4" },
                            ]}
                          />
                        </td>
                        <td style={{ width: "100px" }}>City</td>
                        <td>
                          <AvField
                            name="name"
                            placeholder="City"
                            value={master?.city}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td style={{ width: "100px" }}>Address</td>
                        <td>
                          <AvField
                            name="name"
                            type="textarea"
                            rows={1}
                            placeholder="address"
                          />
                        </td>
                        <td style={{ width: "100px" }}>Adhar No.</td>
                        <td>
                          <AvField
                            name="adharnumber"
                            placeholder="Adhar Number"
                          />
                        </td>

                        <td style={{ width: "100px" }}>Qualification</td>
                        <td>
                          <AvField
                            name="qualification"
                            placeholder="Qualification"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Row>
                    <Col md="12">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button type="submit" color="info">
                          Save & Update
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <hr />
                <Col md="12" lg="12">
                  <AvForm>
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Status</Label>
                          <Select
                            name="description"
                            type="text"
                            onChange={selected => {
                              handleSelectChange(selected, "status")
                            }}
                            options={[
                              {
                                label: "New",
                                value: 0,
                              },
                              {
                                label: "Cold Lead",
                                value: 1,
                              },
                              {
                                label: "Prospective",
                                value: 2,
                              },
                              {
                                label: "Not Intreseted",
                                value: 3,
                              },
                              {
                                label: "Not Reachable",
                                value: 4,
                              },
                              {
                                label: "Register",
                                value: 5,
                              },
                            ]}
                            errorMessage="Enter service"
                          />
                        </div>
                      </Col>

                      <>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Next Followup </Label>
                            <AvField
                              name="description"
                              type="date"
                              placeholder=""
                              errorMessage="Enter Service Code"
                            />
                          </div>
                        </Col>
                      </>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Comments</Label>
                          <AvField
                            name="Comments"
                            rows={1}
                            type="textarea"
                            placeholder="Comments"
                            errorMessage="Enter Description"
                          />
                        </div>
                      </Col>

                      <Col>
                        <div className="mb-3" style={{ paddingTop: "25px" }}>
                          <Button
                            style={{ marginRight: "1%" }}
                            color="primary"
                            type="submit"
                            className="mx-2"
                          >
                            Submit
                          </Button>
                          <Button
                            style={{ marginRight: "3%" }}
                            color="danger"
                            type="submit"
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </Col>
                <Col md="12">
                  <MDBDataTable
                    id="requestestcommunistID"
                    responsive
                    bordered
                    data={viewdetail_data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </Col>
              </AvForm>
            </TabPane>
          </TabContent>
        </div>
      </Modal>

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Join Request" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <AvForm ref={formRef}>
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label> From</Label>
                          <AvField
                            name="from"
                            className="form-control"
                            type="date"
                            value={masterObject.from}
                            onChange={e =>
                              handleValueChange(e.target.value, "from")
                            }
                            placeholder="From"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>To</Label>
                          <AvField
                            name="to"
                            className="form-control"
                            type="date"
                            value={masterObject.to}
                            onChange={e =>
                              handleValueChange(e.target.value, "to")
                            }
                            placeholder="To"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Community</Label>
                          <Select
                            value={selectedcommunity}
                            onChange={selected => {
                              handleValueChange(selected.value, "community")
                              setselectedCommunity(selected)
                            }}
                            name="community"
                            options={communityOptions}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Status</Label>
                          <Select
                            value={selectedStatus}
                            onChange={selected => {
                              handleValueChange(selected.value, "status")
                              setselectedStatus(selected)
                            }}
                            name="status"
                            options={status}
                          />
                        </div>
                      </Col>
                      {/* <Col md="2">
                        <div className="mb-3">
                          <Label> Event</Label>
                          <Select
                            //   value={eventOptions.filter((item)=>(
                            //     item.value === masterObject.event
                            // ))}
                            value={masterObject.event}
                            onChange={selected =>
                              handleValueChange(selected, "event")
                            }
                            name="event"
                            options={eventOptions}
                          />
                        </div>
                      </Col> */}
                      {/* <Col md="2">
                        <div className="mb-3">
                          <Label>District</Label>
                          <Select name="Search" options={District} />
                        </div>
                      </Col> */}

                      <Col>
                        <div
                          className="mb-3 mt-3"
                          style={{ paddingTop: "15px" }}
                        >
                          <Button onClick={reset} color="danger" type="reset">
                            Reset
                          </Button>
                          <Button
                            style={{ marginLeft: "14px" }}
                            color="warning"
                            type="submit"
                            onClick={handleExport}
                          >
                            Export
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                  <MDBDataTable
                    id="requestestcommunistID"
                    bordered
                    responsive
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default CommunityRequest
