import React, { useState, useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"

import toastr from "toastr"
import moment from "moment"
import Select from "react-select"
import { MDBDataTable } from "mdbreact"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"

import { get, put } from "../../../../helpers/api_helper"
import { getSkillLoanStatus } from "../../../../store/options/actions"
import { SKILL_LOAN_STATUS } from "../../../../config"
const SkillLoanProcessing = ({ id }) => {
  const [requestData, setRequestData] = useState({})
  const [tableData, setTableData] = useState([])
  const [masterObject, setMasterObject] = useState({ id: id })
  const [fundSourceOptions, setFundSourceOptions] = useState([])
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        width: 150,
      },
      {
        label: "Remarks",
        field: "remarks",
        width: 150,
      },
      {
        label: "Added By",
        field: "added",
        width: 150,
      },

      {
        label: "Status",
        field: "status",
        width: 150,
      },
    ],
    rows: tableData,
  }

  const ref = useRef()
  const dispatch = useDispatch()
  const { skillLoanStatus } = useSelector(state => state.Options)

  useEffect(() => {
    handleRequestData()
    if (!skillLoanStatus.length) dispatch(getSkillLoanStatus())
    // eslint-disable-next-line
  }, [])

  const handleRequestData = () => {
    get(`skill-loan/request/details?id=${id}`).then(res => {
      const result = res.data
      const followup = result?.followup || []
      delete result?.followup

      result.studentName =
        (result.studentFirstName ?? "") + " " + (result.studentLastName ?? "")

      followup.map((item, index) => {
        item.id = index + 1
        item.date = moment(item.date).format("DD-MM-YYYY")
        item.time = moment(item.time, "HH:mm:ss").format("hh:mm a")
        item.remarks = item.comments
        item.added = item.addedBy
        const statusData = item.status
        item.status = (
          <div>
            <span
              className="badge font-size-12 ms-2"
              style={{ backgroundColor: statusData.color }}
            >
              {statusData.name}
            </span>
          </div>
        )
        return item
      })

      setTableData(followup)
      setRequestData(result)
    })
  }

  const handleFundSourceOptions = async branch => {
    get(`accounts/chart-account/options?$branch=${branch}`).then(res =>
      setFundSourceOptions(res.data)
    )
  }
  const handleSubmit = () => {
    put("skill-loan/request/status", masterObject)
      .then(res => {
        reset()
        toastr.success(res.message)
      })
      .catch(err => {
        toastr.error(err.response.data.message)
      })
  }

  const handleValueChange = (value, name) => {
    const obj = { ...masterObject }
    obj[name] = value
    setMasterObject(obj)

    if (name === "status" && value === SKILL_LOAN_STATUS.APPROVED)
      handleFundSourceOptions(requestData.branch)
  }

  const reset = () => {
    ref.current?.reset()
    handleRequestData()
    setMasterObject({ id: id })
  }
  return (
    <>
      <Row>
        <Col md="12">
          <ul className="lead-details">
            <li>
              Name : <Label>{requestData?.studentName}</Label>
            </li>

            <li>
              Mobile : <Label>{requestData?.studentMobile}</Label>
            </li>
            <li>
              Email : <Label>{requestData?.studentEmail}</Label>
            </li>
            <li>
              District : <Label>{requestData?.district}</Label>
            </li>
            <li>
              CSP : <Label>{requestData?.csp}</Label>
            </li>
            <li>
              Course : <Label>{requestData?.course}</Label>
            </li>
            <li>
              Request Id: <Label>{requestData?.uniqueId}</Label>
            </li>
            <li>
              Course Fee: <Label>{requestData?.courseAmount}</Label>
            </li>

            <li>
              Requested Amount: <Label>{requestData?.requestAmount}</Label>
            </li>
            <li>
              Provider: <Label>{requestData?.provider}</Label>
            </li>
          </ul>
          {requestData.status === 2 ? (
            <ul className="lead-details">
              <li>
                Approved Date :{" "}
                <Label>
                  {moment(requestData?.approvedDate).format("DD-MM-YYYY")}
                </Label>
              </li>
              <li>
                Approved Time :{" "}
                <Label>
                  {moment(requestData?.approvedTime, "HH:mm:ss").format(
                    "hh:mm a"
                  )}
                </Label>
              </li>
              <li>
                Approved By : <Label>{requestData?.approvedBy}</Label>
              </li>
              <li>
                DD Number : <Label>{requestData?.ddNumber}</Label>
              </li>
              <li>
                DD Amount : <Label>{requestData?.ddAmount}</Label>
              </li>
              <li>
                Issued Date :{" "}
                <Label>
                  {moment(requestData?.issuedDate).format("DD-MM-YYYY")}
                </Label>
              </li>
              <li>
                Branch : <Label>{requestData?.ddBranch}</Label>
              </li>

              <li>
                Comments : <Label>{requestData?.approvedComments}</Label>
              </li>
            </ul>
          ) : (
            ""
          )}
          {requestData.status === 3 ? (
            <ul className="lead-details">
              <li>
                Rejected Date :{" "}
                <Label>
                  {moment(requestData?.approvedDate).format("DD-MM-YYYY")}
                </Label>
              </li>
              <li>
                Rejected Time :{" "}
                <Label>
                  {moment(requestData?.approvedTime, "HH:mm:ss").format(
                    "hh:mm a"
                  )}
                </Label>
              </li>
              <li>
                Rejected By : <Label>{requestData?.approvedBy}</Label>
              </li>
              <li>
                Comments : <Label>{requestData?.approvedComments}</Label>
              </li>
            </ul>
          ) : (
            ""
          )}
          {/* <table className="table lead-info table-striped">
            <tbody>
              <tr>
                <td>Name</td>
                <th>: {requestData?.studentName}</th>
                <td>Mobile</td>
                <th>: {requestData?.studentMobile}</th>
                <td>Email</td>
                <th>: {requestData?.studentEmail}</th>
                <td>District</td>
                <th>: {requestData?.district}</th>
                <td></td>
                <th></th>
              </tr>

              <tr>
                <td>CSP</td>
                <th>: {requestData?.csp}</th>
                <td>Course</td>
                <th>: {requestData?.course}</th>

                <td>Course Amount</td>
                <th>:</th>
                <td>Request Amount</td>
                <th>:</th>
              </tr>
              <tr>
                <td>CSP</td>
                <th>: {requestData?.csp}</th>
                <td>Course</td>
                <th>: {requestData?.course}</th>

                <td>Provider</td>
                <th>:</th>
                <td>Request Amount</td>
                <th>:</th>
              </tr>
            </tbody>
          </table> */}
        </Col>
        {requestData.status === 0 ? (
          <Col md="12" lg="12">
            <Card>
              <CardBody>
                <AvForm ref={ref} onValidSubmit={() => handleSubmit()}>
                  <Row>
                    <Col md="3">
                      <div className="">
                        <Label>Status</Label>
                        <Select
                          name="description"
                          type="text"
                          value={skillLoanStatus.filter(
                            item => item.value === masterObject.status
                          )}
                          onChange={selected => {
                            handleValueChange(selected.value, "status")
                          }}
                          options={skillLoanStatus.filter(
                            item => item.value !== requestData.currentStatus
                          )}
                          errorMessage="Enter Status"
                        />
                      </div>
                    </Col>
                    {masterObject.status === SKILL_LOAN_STATUS.APPROVED ? (
                      <>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>DD Number</Label>
                            <AvField
                              name="dd-number"
                              type="number"
                              placeholder="DD Number"
                              errorMessage="Enter DD Number"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Please enter DD Number",
                                },
                                minLength: {
                                  value: 6,
                                  errorMessage:
                                    "Minimum length is 6 characters",
                                },
                                maxLength: {
                                  value: 6,
                                  errorMessage:
                                    "Maximum length is 6 characters",
                                },
                              }}
                              value={masterObject.number}
                              onChange={e => {
                                handleValueChange(e.target.value, "number")
                              }}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>DD Amount</Label>
                            <AvField
                              name="dd-Amount"
                              type="number"
                              placeholder="DD Amount"
                              errorMessage="Enter DD Amount"
                              validate={{ required: { value: true } }}
                              value={masterObject.amount}
                              onChange={e => {
                                handleValueChange(e.target.value, "amount")
                              }}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Issued Date</Label>
                            <AvField
                              name="Date"
                              rows={1}
                              type="date"
                              placeholder="date"
                              errorMessage="Enter Date"
                              validate={{ required: { value: true } }}
                              value={masterObject.date}
                              onChange={e => {
                                handleValueChange(e.target.value, "date")
                              }}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Branch</Label>
                            <AvField
                              name="branch"
                              rows={1}
                              type="text"
                              placeholder="Branch"
                              errorMessage="Enter Branch"
                              value={masterObject.branch}
                              validate={{ required: { value: true } }}
                              onChange={e => {
                                handleValueChange(e.target.value, "branch")
                              }}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Fund Source</Label>
                            <Select
                              name="fundSource"
                              value={fundSourceOptions.filter(
                                item => item.value === masterObject.fundSource
                              )}
                              onChange={selected => {
                                handleValueChange(selected.value, "fundSource")
                              }}
                              options={fundSourceOptions}
                              errorMessage="Enter Fund Source"
                            />
                          </div>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                    {masterObject.status && (
                      <>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Comments</Label>
                            <AvField
                              name="Comments"
                              rows={1}
                              type="textarea"
                              placeholder="Comments"
                              errorMessage="Enter comments"
                              validate={{ required: { value: true } }}
                              value={masterObject.comments}
                              onChange={e => {
                                handleValueChange(e.target.value, "comments")
                              }}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3" style={{ paddingTop: "25px" }}>
                            <Button
                              style={{ marginRight: "1%" }}
                              color="primary"
                              type="submit"
                              className="mx-2"
                            >
                              Submit
                            </Button>
                            <Button
                              style={{ marginRight: "3%" }}
                              color="danger"
                              onClick={() => reset()}
                            >
                              Reset
                            </Button>
                          </div>
                        </Col>
                      </>
                    )}
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        ) : (
          ""
        )}
        <Col md={12} className="mt-3">
          <Card>
            <CardBody>
              <MDBDataTable
                id="viewSkillLoanHistory"
                responsive
                bordered
                data={data}
                searching={true}
                info={true}
                disableRetreatAfterSorting={true}
                entries={20}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default SkillLoanProcessing
