/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from "react"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Modal,
  CardTitle,
  Input,
} from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import toastr from "toastr"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { del, get, post, put } from "../../../helpers/api_helper"
import { getStudent, getCourses } from "../../../store/options/actions"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import axios from "axios"
import { API_URL } from "../../../config"
import "./style.scss"
import moment from "moment"
import { timeSince } from "../../../helpers/functions"
import Swal from "sweetalert2"
import SelectErrorMessage from "../../../components/Common/SelectErrorMessage"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const CreateTicket = () => {
  const formRef = useRef()
  const formRef1 = useRef()

  const dispatch = useDispatch()
  // const { students } = useSelector(student => student.Options)
  const { courses } = useSelector(courses => courses.Options)
  const [students,setStudents] = useState([])
  const ref = useRef()
  const [popupView, setPopupView] = useState(false)
  const handleCloseModal = () => setPopupView(false)
  const [selectedStatus, setSelectedStatus] = useState(null)
  const [selectedComment,setSelectedComment] = useState('')
  const [selectedModalId,setSelectedModalId] = useState("")
  const [popupData, setPopupDta] = useState(null)
  const [tableData, setTableData] = useState([])
  const [resModal, setResModal] = useState()
  const [selectedStudent, setselectedStudent] = useState(null)
  const [selectedCategory, setselectedCategory] = useState(null)
  const [selectedpriority, setselectedpriority] = useState(null)
  const [error, setError] = useState({})
  const [Modalerror, setModalError] = useState({})

  const [users,setUsers] = useState([])
  const [selectedUser,setSelectedUser] = useState(null)
  const [selectedRemarks, setSelectedRemarks] = useState(null)
  const [assignedErr,setAssignedErr] = useState(false)

  const initialState = {
    student: "",
    category: "",
    priority: "",
    remarks: "",
    attachment: "",
    source: 1
  }

  const [masterObject, setMasterObject] = useState(initialState)
  const [ticketCategoryOptions, setTicketCategoryOptions] = useState([])
  const [popupDataTable, setpopupDataTable] = useState([])

  const filterState = {
    from:"",
    to:"",
    priority:"",
    student:"",
    category:"",
    status:""
  }
  const [filterData, setfilterData] = useState(filterState)
  const [studentFilter, setstudentFilter] = useState(null)
  const [cateogryFilter, setcateogryFilter] = useState(null)
  const [priorityFilter, setpriorityFilter] = useState(null)
  const [statusFilter, setStatusFilter] = useState(null)

  const[ remarkErr,setRemarkErr] = useState(false)
 
  const initialModalState = {
    status: "",
    remarks: "",
    comment:""
  }
  const [master, setMaster] = useState(initialModalState)

  useEffect(()=>{
     const fetchStudents = async()=>{
      try {
        const res = await get("/options/student")
        setStudents(res?.data)
      } catch (error) {
        
      }
     }
     fetchStudents()
  },[])

  useEffect(()=>{
    const fetchUsers = async()=>{
      try {
        const res = await get("/options/users")
        setUsers(res?.user)
        return res?.user
      } catch (error) {
        
      }
    }
    fetchUsers()
  },[])



  function handleSelectChange(selected, name) {
    switch (name) {
      case "status":
        setSelectedStatus(selected)
        break

      default:
        break
    }
  }
  const handleModaleValueChange = (name, selected) => {
    switch (name) {
      case "status":
        setSelectedStatus(selected)
        setMaster({ ...master, status: selected.value })
        break;
      case "remarks":
        setMaster({ ...master, remarks: selected })
      default:
        break;
    }
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
        sort: "asc",
      },
      {
        label: "ID",
        field: "uniqueId",
        width: 50,
        sort: "asc",
      },
      {
        label: "Student",
        field: "studentName",
        width: 150,
        sort: "asc",
      },
      {
        label: "Student ID",
        field: "studentId",
        width: 150,
        sort: "asc",
      },
      {
        label: "Category",
        field: "categoryname",
        width: 200,
        sort: "asc",
      },
      {
        label: "Priority",
        field: "priorityName",
        width: 150,
        sort: "asc",
      },
      {
        label: "Remarks",
        field: "remark",
        width: 200,
        sort: "asc",
      },
      {
        label: "Assigned",
        field: "assignedName",
        width: 400,
        sort: "asc",
      },
      {
        label: "Status",
        field: "status1",
        width: 150,
        sort: "asc",
      },
      {
        label: "Actions",
        field: "options",
        sort: "asc",
        width: 400,
      },
    ],

    rows: tableData,
  }




  const datas = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
        sort: "asc",
      },
      {
        label: "Date",
        field: "updatedDate",
        width: 100,
        sort: "asc",
      },
      {
        label: "Time",
        field: "updateTime",
        width: 100,
        sort: "asc",
      },
      {
        label: "Remarks",
        field: "remarks",
        width: 150,
        sort: "asc",
      },
      {
        label: "User",
        field: "upstaff",
        width: 150,
        sort: "asc",
      },
      {
        label: "Status",
        field: "status1",
        width: 150,
        sort: "asc",
      },
    ],
    rows: popupDataTable,
  }

  useEffect(() => {
    dispatch(getStudent({}))
    dispatch(getCourses({}))

    ftechAllTicketCategory()
    handleTicketData()
  }, [filterData])

  const isNull = (field) => {
    return field === undefined || field === "undefined" || field === "" || field === null || field === "null"; 
  };

  const assignedTcket = async()=>{

    if(isNull(selectedUser) || isNull(selectedRemarks)){
    return toastr.error('Please provide Assigned and Remarks')
    }
  
    try {
      const response = await post(`/ticket/assignedTcket`,{
        ticketId:popupData?._id,
        assigned:selectedUser?.value,
        remark:selectedRemarks
      })
      handleTicketData()
      toastr.success(response?.message)
    } catch (error) {
      
    }
  }


  const handleTicketData = async () => {
    get(`/ticket?from=${filterData?.from}&to=${filterData?.to}&student=${filterData?.student}&priority=${filterData?.priority}&category=${filterData?.category}&status=${filterData?.status}`).then((res) => {
      let result = res.data
      result && result.map((item, index) => {
        item.id = index + 1;
        item.studentName = item.student.label
        item.studentId = item.student.uniqueId
        item.categoryname = item.category.label
        if (item.priority == 1) {
          item.priorityName = "High"
        } else if (item.priority == 2) {
          item.priorityName = "Medium"
        } else if (item.priority == 3) {
          item.priorityName = "Low"
        }

        item.remark = (
          <>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  id="button-tooltip-2"
                  style={{ position: "absolute", top: "-30px" }}
                >
                  {item.remarks}
                </Tooltip>
              }
            >
              <div>{item.remarks?.slice(0, 50)} {item.remarks.length > 50 ?"...":""} </div>
            </OverlayTrigger>
          </>
        )

       
        let statusButtons

        switch (item.status) {
          case 0:
            statusButtons = (
              <div>
                <Button className="btn btn-sm" color="info" style={{ width: '70px' }}>
                  New
                </Button>
              </div>
            )
            break
          case 3:
            statusButtons = (
              <div>
                <Button className="btn btn-sm" color="success" style={{ width: '70px' }}>
                  In Progress
                </Button>
              </div>
            )
            break
          case 4:
            statusButtons = (
              <div>
                <Button className="btn btn-sm" color="warning" style={{ width: '70px' }}>
                  On Hold
                </Button>
              </div>
            )
            break
          case 2:
            statusButtons = (
              <div>
                <Button className="btn btn-sm" color="danger" style={{ width: '70px' }}>
                  Closed
                </Button>
              </div>
            )
            break
          default:
            statusButtons = null
        }
        item.status1 = statusButtons

        
           
        item.assignedName = item.assignedData?.label
        item.options = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              onClick={() => {
                handlePopupdate(item._id)
                setSelectedModalId(item._id)
                setSelectedUser(item?.assignedData)
                setSelectedRemarks(item?.assignedRemark)
              }}
              style={{
                fontSize: "1em",
                cursor: "pointer",
              }}
            ></i>
            <i
              className="far fa-edit"
              onClick={() => { handleUpdate(item) }}
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              onClick={() => handleDelete(item._id)}
              style={{ cursor: "pointer", fontSize: "1em" }}
            ></i>

           {item?.attachment?

              <>
               <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  id="button-tooltip-2"
                  style={{ position: "absolute", top: "-30px" }}
                >
                  {'View Attachment'}
                </Tooltip>
              }
            >
           
               <a
                href={API_URL + item?.attachment}
                target="_blank"
                rel="noreferrer"
              >
                <i
                  className="fa fa-file"
                  style={{
                    cursor: "pointer",
                    fontSize: "1.5em",
                    color: "gray",
                    marginRight: "0.5rem",
                    
                    marginLeft: "0.5rem",
                  }}
                ></i>
              </a>
              </OverlayTrigger>
              </>
      :''}
          </div>
        )
        return item
      })
      setTableData(result)
    })

  }

  const handleFileUpload = e => {
    const file = e.target.files[0];
    if (
      !(
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'application/pdf' 
        // (file.type.startsWith('video/') && file.size <= 15 * 1024 * 1024) 
      )
    ) {
      toastr.error('Invalid file type or size. Only images (JPEG, PNG), PDFs');
      return;
    }
    const fd = new FormData()
    fd.append("file", file)
    post(`ticket/image`, fd).then((res) => {
    
      setMasterObject({ ...masterObject, attachment: res.data.new_filename })
    })

  }


  const handleSubmit = () => {

    let flag = false;
    if (selectedCategory === null) {
      flag = true
      setError(pre => ({ ...pre, category: true }))
    }
    if (selectedStudent === null) {
      flag = true
      setError(pre => ({ ...pre, student: true }))
    }

    if (selectedpriority === null) {
      flag = true
      setError(pre => ({ ...pre, priority: true }))
    }
     if(masterObject.remarks === ''){
      return setRemarkErr(true)
     }

    if (flag) {
      return
    }

    if (masterObject._id) {
      put("/ticket", masterObject)
        .then(res => {
          resetAll()
          toastr.success(res.message)
          setselectedCategory(null)
          setselectedStudent(null)
          setselectedpriority(null)
          handleTicketData()
        })
        .catch(err => {
          toastr.error('error oocured when updating')
        })
    } else {
      post("/ticket", masterObject)
        .then(res => {
          reset();
          setselectedCategory(null);
          setselectedStudent(null);
          setselectedpriority(null);
          toastr.success(res.message);
          setError({})
          
          handleTicketData();
        })
        .catch(err => {
          toastr.error(err.message)
        })
    }
  }


  // const handleModalSubmit = () => {
  //   put(`/ticket/followup`, master).then((res) => {
  //     formRef.current.reset()
  //     handlePopupdate(res.data._id)
  //     resetModal()
    
  //     handleTicketData();
  //     toastr.success(res.message);
  //     setMaster({})
  //     console.log("mster===============", master);

  //   })

  // }


  const handleModalSubmit = async()=>{
    let flag = 0
    if (selectedStatus === null) {
      setModalError(pre => ({ ...pre, status: true }))
      flag = 1
    }
    if (selectedComment === '') {
       setModalError(pre => ({ ...pre, comment: true }))
       flag = 1
     }
     if(flag != 0){
      return
     }
    try {
      const obj = {_id:selectedModalId,remarks:selectedComment,status:selectedStatus?.value}
      const res =await put(`/ticket/followup`, obj)
      formRef.current?.reset()
      handlePopupdate(res.data._id)
      resetModal()
    
      handleTicketData();
      toastr.success(res.message);
      setMaster({})
      
    } catch (error) {
      
    }
  }


  const handleFollowUpId = async item => {
    fetchFollowupData(item._id)
  }

  useEffect(() => { }, [resModal])

  const calculateTAT = (createdAt, updatedAt) => {
    // Parse date-time strings into Date objects
    const startDate = new Date(createdAt)
    const endDate = new Date(updatedAt)

    // Calculate time difference in milliseconds
    const timeDifference = endDate - startDate

    // Convert time difference to seconds
    const timeDifferenceInSeconds = Math.abs(timeDifference) / 1000

    // Handle cases where startDate is after endDate
    if (timeDifference < 0) {
      return "0"
    }

    // Handle cases where time difference is less than 24 hours
    if (timeDifferenceInSeconds < 24 * 3600) {
      const hours = Math.floor(timeDifferenceInSeconds / 3600)
      const minutes = Math.floor((timeDifferenceInSeconds % 3600) / 60)
      const seconds = Math.floor(timeDifferenceInSeconds % 60)

      if (hours === 0 && minutes === 0 && seconds === 0) {
        return "0"
      } else if (hours === 0) {
        return `${minutes} minutes ${seconds} seconds`
      } else if (minutes === 0 && seconds === 0) {
        return `${hours} hours`
      } else {
        return `${hours} hours ${minutes} minutes`
      }
    } else {
      // Calculate days
      const days = Math.ceil(timeDifferenceInSeconds / (24 * 3600))
      return `${days} day${days > 1 ? "s" : ""}`
    }
  }

  const fetchFollowupData = id => {
    get(`/ticket/getticketmodal/${id}`).then(res => {
      let Result = res.data

      Result.forEach(item => {
        item.id = Result.indexOf(item) + 1
        item.User = `${item.firstName ? item.firstName + " " : ""}${item.lastName ? item.lastName + " " : ""
          }`
        item.Remarks = item.remarks
        item.Lastupdate = moment(item.update).format("DD-MM-YYYY")
        item.CreatedAt = moment(item.createdAt).format("DD-MM-YYYY")
        item.Status = item.followupStatus
        item.create = item.createdAt
        item.UpDate = item.update
        item.UpTime = item.upTime
        const dateTimeString = `${item.UpDate}T${item.UpTime}`
        const dateTime = new Date(dateTimeString)
        const isoString = dateTime.toISOString()
        item.update = isoString
        let TAT = timeSince(item.create, new Date())
        item.tat = TAT

        let statusButtons

        switch (item.Status) {
          case "New":
            statusButtons = (
              <div>
                <Button className="btn btn-sm" color="info">
                  New
                </Button>
              </div>
            )
            break
          case "On progress":
            statusButtons = (
              <div>
                <Button className="btn btn-sm" color="warning">
                  On progress
                </Button>
              </div>
            )
            break
          case "On Hold":
            statusButtons = (
              <div>
                <Button className="btn btn-sm" color="danger">
                  On Hold
                </Button>
              </div>
            )
            break
          case "Closed":
            statusButtons = (
              <div>
                <Button className="btn btn-sm" color="success">
                  Closed
                </Button>
              </div>
            )
            break
          default:
            statusButtons = null
        }

        item.options = statusButtons
      })

      setResModal(Result)
    })
  }

  function ftechAllTicketCategory() {
    get(`/options/ticket-category`).then(res => {
      let result = res?.data?.map(item => {
        return {
          label: item?.categoryName,
          value: item?._id,
        }
      })
      setTicketCategoryOptions(result)
    })
  }

  //onchangeG

  const handleValueChange = async (name, selected) => {

    if (name == "student") {
      setselectedStudent(selected)
    } else if (name == "category") {
      setselectedCategory(selected)
    } else if (name == "priority") {
      setselectedpriority(selected)
    }
    const obj = { ...masterObject }
    obj[name] = selected.value
    setMasterObject(obj)
  }


  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  const reset = () => {
    formRef.current?.reset()
    setMasterObject(initialState)
    toTop()
    setSelectedStatus(null)
    setMaster(initialModalState)
    handleTicketData()
  }

  function resetAll() {
    setError({})
    setselectedCategory(null);
    setselectedStudent(null)
    setselectedpriority(null)
    setMasterObject(initialState)
    setRemarkErr(false)
  }


  
  function restAllFilter(){
    ref.current?.reset()

    setcateogryFilter(null)
    setstudentFilter(null)
    setpriorityFilter(null)
    setStatusFilter(null)
    setfilterData(filterState)
  }

  const resetModal = ()=>{
    formRef1.current?.reset()
    setMaster(initialModalState)
    setSelectedStatus(null)
    setSelectedComment("")
    handleTicketData()
    setModalError({})
  }


  function handleUpdate(item) {
    toTop()
    setselectedCategory(item?.category)
    setselectedStudent(item?.student)
    if (item?.priority == 1) {
      setselectedpriority({ label: "High", value: 1 })
    } else if (item?.priority == 2) {
      setselectedpriority({ label: "Medium", value: 2 })
    } else if (item?.priority == 3) {
      setselectedpriority({ label: "Low", value: 3 })
    }
    setMasterObject(item)

  }

  const handleDelete = item => {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(
          `/ticket?id=${item}`
        )
          .then(res => {
            toastr.success(res.message)
            handleTicketData()
          })
          .catch(err => {
            toastr.success(err?.response?.data?.message)
            console.log("err", err.response);
          })
      }
    })
  }

  function handlePopupdate(id) {
    setPopupView(true)
    setMaster({ ...master, _id: id })
    get(`/ticket/followup?id=${id}`).then((res) => {
      setPopupDta(res?.data)
      // setSelectedUser(res?.data?.assigned)
      // setSelectedRemarks(res?.data?.assignedRemark)
      let followupDetails = res?.data?.followup
      followupDetails && followupDetails.map((item, index) => {
        item.id = index + 1;
        item.updatedDate = moment(item.upDate).format("DD-MM-YYYY")
        item.updateTime = moment(item.upTime, "HH:mm:ss").format("hh:mm A")
        item.upstaff = item.addedBy.firstName + " " + item.addedBy.lastName
        if (item.status == 0) {
          item.status1 = "New"
        } else if (item.status == 2) {
          item.status1 = "Closed"
        } else if (item.status == 3) {
          item.status1 = "In Progress"
        } else if (item.status == 4) {
          item.status1 = "On Hold"
        }
      })
      setpopupDataTable(followupDetails)
    }).catch((err) => {
      console.log("err", err);
    })

  }

  function handleChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    setfilterData({ ...filterData, [name]: value })
  }


  let initial ={
    student:"",
    category:"",
    priority:"",
    from:"",
    to:"",
  }


  function handleFilterChnage(name, selected) {
    switch (name) {
      case "student":
        setfilterData({ ...filterData, [name]: selected.value })
        setstudentFilter(selected)
        break;
      case "category":
        setfilterData({ ...filterData, [name]: selected.value })
        setcateogryFilter(selected)
        break;
      case "priority":
        setfilterData({ ...filterData, [name]: selected.value })
        setpriorityFilter(selected)
        break;
        case "status":
          setfilterData({ ...filterData, [name]: selected.value })
          setStatusFilter(selected)
          break;
      default:
        break;
    }
  }
 


  return (
    <>
      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() =>{ setPopupView(!popupView)}}
        size="xl"
        centered={true}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h6 style={{ marginBottom: "0px" }} className="modal-title mt-0">
              {popupData?.uniqueId} / {moment(popupData?.date).format("DD-MM-YYYY")}
            </h6>
          </div>
          <button
            style={{ marginBottom: "2px" }}
            type="button"
            onClick={handleCloseModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body">
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <CardTitle>Ticket Info</CardTitle>
                  {popupData && (
                    <Row>
                      <Col md="3">
                        <p>
                          Name:{" "}
                          <b>
                            {popupData.student.firstname ? popupData.student.firstname : ""}{" "}
                            {popupData.student.lastname ? popupData.student.lastname : ""}
                          </b>
                        </p>
                      </Col>

                      <Col md="3">
                        <p>
                          Mobile :{" "}
                          <b>{popupData.student.mobile ? popupData.student.mobile : ""}</b>
                        </p>
                      </Col>

                      <Col md="3">
                        <p>
                          Email ID :{" "}
                          <b>{popupData.student.email ? popupData.student.email : ""}</b>
                        </p>
                      </Col>

                      <Col md="3">
                        <p>
                          District :{" "}
                          <b>{popupData.student.district ? popupData.student.district : ""}</b>
                        </p>
                      </Col>

                      <Col md="3">
                        <p>
                          State :{" "}
                          <b>{popupData.student.state ? popupData.student.state : ""}</b>
                        </p>
                      </Col>
                      <Col md="3">
                        <p>
                          Source : <b>{popupData.source == 1 ? "CRM" : popupData.source == 2 ? "APP" : popupData.source == 3 ? "Web" : ""}</b>
                        </p>
                      </Col>
                      {popupData?.addedBy?.firstName ? (
                      <Col md="3">
                        <p>
                          Staff : <b>{`${popupData?.addedBy?.firstName || ""} ${popupData?.addedBy?.lastName || ""}`}</b>
                        </p>
                      </Col>

                      ): ("")}
                    </Row>

                    
                  )}
                </CardBody>
              </Card>
            </Col>





            <Col md="12" lg="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef1}
                    onValidSubmit={() => assignedTcket()}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3" style={{ height: "100%" }}>
                          <Label>Assigned</Label>
{/* 
                          <Select
              name="status"
              className="fixed-menu" 
              type="text"
              onChange={selected => {
                assignedTcket(item._id, selected); // Pass selected value to the assignedTicket function
              }}
              value={users.find(option => option?.value === item?.assigned)} // Use find instead of filter
              options={users && users}
            /> */}
                          
                          <Select
                          
                            name="status"
                            type="text"
                            onChange={selected => {
                             setSelectedUser(selected)
                            }}
                            value={selectedUser}
                            options={users}
                          />
                           <SelectErrorMessage
                        show={selectedUser === null && assignedErr === true}
                        customStyle={{
                          width: "100%",
                          fontSize: "87.5%",
                          color: "var(--bs-form-invalid-color)",
                        }}
                        message="Please select a Category"
                      />
                        </div>
                       
                      </Col>
                      <Col md="3">
                        <div className="" style={{ height: "100%" }}>
                          <Label>Remarks</Label>

                          <AvField
                            name="Remarks"
                            type="textarea"
                            placeholder="Comments"
                            id="validationCustom01"
                            className="form-control"
                          
                            style={{ height: "40%" }}
                            value={selectedRemarks}
                            onChange={(e)=> setSelectedRemarks(e.target.value)}
                          />
                          <SelectErrorMessage
                        show={selectedRemarks === '' && assignedErr === true}
                        customStyle={{
                          width: "100%",
                          fontSize: "87.5%",
                          color: "var(--bs-form-invalid-color)",
                        }}
                        message="Enter Comment"
                      />
                        </div>
                       
                      </Col>


                      <Col>
                        <div className="mb-3" style={{ paddingTop: "25px" }}>
                          <Button
                            style={{ marginRight: "1%" }}
                            color="primary"
                            type="submit"
                          >
                            Submit
                          </Button>
                          
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>

            <Col md="12" lg="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef1}
                    onValidSubmit={() => handleModalSubmit()}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3" style={{ height: "100%" }}>
                          <Label>Status</Label>
                          <Select
                          
                            name="status"
                            type="text"
                            onChange={selected => {
                              handleModaleValueChange("status", selected)
                            }}
                            value={selectedStatus}
                            options={[
                              { label: "New", value: 0 },
                              { label: "Closed", value: 2 },
                              { label: "In Progress", value: 3 },
                              { label: "On Hold", value: 4 },
                            ]}
                          />
                           <SelectErrorMessage
                        show={selectedStatus === null && Modalerror?.status}
                        customStyle={{
                          width: "100%",
                          fontSize: "87.5%",
                          color: "var(--bs-form-invalid-color)",
                        }}
                        message="Please select a Status"
                      />
                        </div>
                       
                      </Col>
                      <Col md="3">
                        <div className="" style={{ height: "100%" }}>
                          <Label>Comments</Label>

                          <AvField
                            name="comment"
                            type="textarea"
                            placeholder="Comments"
                            id="validationCustom01"
                            className="form-control"
                          
                            style={{ height: "40%" }}
                            value={selectedComment}
                            onChange={(e)=> setSelectedComment(e.target.value)}
                          />
                          <SelectErrorMessage
                        show={selectedComment === '' && Modalerror?.comment}
                        customStyle={{
                          width: "100%",
                          fontSize: "87.5%",
                          color: "var(--bs-form-invalid-color)",
                        }}
                        message="Enter Comment"
                      />
                        </div>
                       
                      </Col>


                      <Col>
                        <div className="mb-3" style={{ paddingTop: "25px" }}>
                          <Button
                            style={{ marginRight: "1%" }}
                            color="primary"
                            type="submit"
                          >
                            Submit
                          </Button>
                          <Button
                            style={{ marginRight: "3%" }}
                            color="danger"
                            type="reset"
                            onClick={() => {
                              resetModal()
                            }}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
            <Col md="12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id=""
                    responsive
                    bordered
                    data={datas}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Ticket" breadcrumbItem="Create Ticket" />
          <div>
            <Card>
              <CardBody>
                <AvForm
                  className="needs-validation"
                  ref={formRef}
                  onValidSubmit={() => handleSubmit()}
                >
                  <Row>
                    <Col md="2">
                      <Label>Student</Label>

                      <Select
                        name="student"
                        classNamePrefix="select2-selection"
                        options={students}
                        onChange={selected => {
                          handleValueChange("student", selected)
                        }}
                        value={selectedStudent}
                      />
                      <SelectErrorMessage
                        show={selectedStudent === null && error.student}
                        customStyle={{
                          width: "100%",
                          fontSize: "87.5%",
                          color: "var(--bs-form-invalid-color)",
                        }}
                        message="Please select a Student"
                      />
                    </Col>
                    <Col md="2">
                      <Label>Category</Label>

                      <Select
                        name="category"
                        classNamePrefix="select2-selection"
                        options={ticketCategoryOptions}
                        onChange={selected => {
                          handleValueChange("category", selected)
                        }}
                        value={selectedCategory}
                      />
                      <SelectErrorMessage
                        show={selectedCategory === null && error.category}
                        customStyle={{
                          width: "100%",
                          fontSize: "87.5%",
                          color: "var(--bs-form-invalid-color)",
                        }}
                        message="Please select a Category"
                      />
                    </Col>
                    <Col md="2">
                      <Label>Priority</Label>

                      <Select
                        name="priority"
                        classNamePrefix="select2-selection"
                        options={[
                          { label: "High", value: 1 },
                          { label: "Medium", value: 2 },
                          { label: "Low", value: 3 },
                        ]}
                        onChange={selected => {
                          handleValueChange("priority", selected)
                        }}
                        value={selectedpriority}
                      />
                      <SelectErrorMessage
                        show={selectedpriority === null && error.priority}
                        customStyle={{
                          width: "100%",
                          fontSize: "87.5%",
                          color: "var(--bs-form-invalid-color)",
                        }}
                        message="Please select a priority"
                      />
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom01">Remarks</Label>

                        <AvField
                          name="remarks"
                          placeholder="Remarks "
                          type="text"
                          id="validationCustom01"
                          className="form-control"
                          errorMessage="Enter remarks "
                          validate={{ required: { value: remarkErr } }}
                          onChange={e => {
                            handleValueChange("remarks", e.target);
                          }}
                          value={masterObject?.remarks}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label for="fileUpload">Attachment</Label>
                        {
                          masterObject?.attachment ? (
                            <div className="img-wraps d-flex flex-column">
                              <object width={150} height={150} type="application/pdf" data={`${API_URL}${masterObject?.attachment}`} />
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={() => {
                                  setMasterObject({ ...masterObject, attachment: null })
                                }}
                                style={{ width: "150px" }}
                                type="button"
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <>
                              <Input
                                type="file"
                                name="attachment"
                                id="fileUpload"
                                onChange={handleFileUpload}
                              />

                            </>
                          )
                        }

                      </div>
                    </Col>

                    <Col md="2" style={{ paddingTop: "4px" }}>
                      <div className="mt-2">
                        <Button
                          color={masterObject?._id ? "warning" : "primary"}
                          className="me-2"
                          type="submit"
                        >
                          {masterObject?._id ? "Update" : "Submit"}
                        </Button>
                        <Button
                          style={{ marginRight: "3%" }}
                          color="danger"
                          type="reset"
                          onClick={resetAll}
                        >
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </div>
          <Card>
            <CardBody>
              <AvForm
                className="needs-validation justifyContent-between"
                ref={ref}
              >
                <Row>
                  <Col md="2">
                    <div className="mb-3">
                      <Label> From</Label>
                      <AvField
                        name="from"
                        className="form-control"
                        type="date"
                        placeholder="From"
                        onChange={handleChange}
                        value={filterData.from}
                      />
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label>To</Label>
                      <AvField
                        name="to"
                        className="form-control"
                        type="date"
                        placeholder="To"
                        onChange={handleChange}
                        value={filterData.to}
                      />
                    </div>
                  </Col>

                  <Col md="2">
                    <Label>Student</Label>

                    <Select
                      name="student"
                      classNamePrefix="select2-selection"
                      options={students}
                      onChange={selected => {
                        handleFilterChnage("student", selected)
                      }}
                      value={studentFilter}
                    />
                  </Col>
                  <Col md="2">
                    <Label>Category</Label>

                    <Select
                      name="category"
                      classNamePrefix="select2-selection"
                      options={ticketCategoryOptions}
                      onChange={selected => {
                        handleFilterChnage("category", selected)
                      }}
                      value={cateogryFilter}
                    />
                  </Col>

                  <Col md="2">
                    <Label>Priority</Label>

                    <Select
                      name="priority"
                      classNamePrefix="select2-selection"
                      options={[
                        { label: "High", value: 1 },
                        { label: "Medium", value: 2 },
                        { label: "Low", value: 3 },
                      ]}
                      onChange={selected => {
                        handleFilterChnage("priority", selected)
                      }}
                      value={priorityFilter}
                    />
                  </Col>
                  <Col md="2">
                    <Label>Status</Label>
                    <Select
                      name="priority"
                      classNamePrefix="select2-selection"
                      options={[
                        { label: "New", value: 0 },
                        { label: "Closed", value: 2 },
                        { label: "In Progress", value: 3 },
                        { label: "On Hold", value: 4 },
                      ]}
                      onChange={selected => {
                        handleFilterChnage("status", selected)
                      }}
                      value={statusFilter}
                    />
                  </Col>
                  <Col md="2" style={{ paddingTop: "4px" }}>
                    <div className="mt-2 mb-2">
                      <Button
                        style={{ marginRight: "3%" }}
                        color="danger"
                        type="submit"
                        onClick={restAllFilter}
                      >
                        Reset
                      </Button>

                      {/* <Button color="danger" className="me-2" type="reset">
                            Reset
                          </Button> */}
                    </div>
                  </Col>
                </Row>
              </AvForm>
              <Row>
                <Col md={12}>
                  <MDBDataTable
                    id="createTicket"
                    responsive
                    bordered
                    info={true}
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  )
}

export default CreateTicket