import React, { useEffect, useRef, useState } from "react"

//Import Breadcrumb
import { APP_NAME } from "../../../../config"
import { Container, Modal } from "reactstrap"

import { AvForm, AvField } from "availity-reactstrap-validation"
import { get, post, put } from "../../../../helpers/api_helper"
import toastr from "toastr"

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import moment from "moment"
import Followup from "../Add-Lead/Followup"
import "./style.scss"
const LeadsBoard = () => {
  document.title = `Leads board | ${APP_NAME}`

  const [checkBoxName, setCheckBoxName] = useState()
  const [master, setMaster] = useState({
    from_date: moment().startOf("month").format("YYYY-MM-DD"),
    to_date: moment().format("YYYY-MM-DD"),
  })
  const [columns, setColumns] = useState([])

  const [followup, setFollowup] = useState({
    modal: false,
    studentId: null,
    leadId: null,
  })

  const [checkBox, setCheckBox] = useState([
    "62c7d7427333e58969b3de0d", // Not interested
    "62c7d7287333e58969b3de0c", // Cold Lead
    "62c7d6597333e58969b3de08", // New
    "6392cbe02d917dfcd065310a", // Course confirm
    "6392cbcc2d917dfcd0653109", // Not reachable
  ])

  const getLeadStatus = () => get("/leads/options/status")

  useEffect(() => {
    getLeadStatus().then(res => {
      setCheckBoxName(res.data)
    })
  }, [])

  useEffect(() => {
    const abortController = new AbortController()

    const signal = abortController.signal

    fetchLead(checkBox, master, signal)

    return () => {
      abortController.abort()
    }
  }, [checkBox, master])

  function checkboxClick(e) {
    let { checked, value } = e.target
    checked = !checked
    if (checked) {
      setCheckBox(prevCheckBox => {
        if (!prevCheckBox.includes(value)) {
          return [...prevCheckBox, value]
        }
        return prevCheckBox
      })
    } else {
      setCheckBox(prevCheckBox => prevCheckBox.filter(id => id !== value))
    }
  }

  const handleChange = e => {
    const { name, value } = e.target
    setMaster(pre => ({ ...pre, [name]: value }))
  }

  function fetchLead(checkBox, { from_date = "", to_date = "" }, signal = "") {
    const checkBoxQueryString = checkBox.join(",")
    get(
      `leads/kanban?from_date=${from_date || ""}&to_date=${
        to_date || ""
      }&followup_status=${checkBoxQueryString}`,
      { signal }
    ).then(res => {
      const newData = res?.data?.reduce((el, item) => {
        const itemId = item._id
        el[itemId] = {
          name: item.name,
          items: item.items,
        }
        return el
      }, {})

      setColumns(newData)
    })
  }

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return
    const { source, destination } = result

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId]
      const destColumn = columns[destination.droppableId]
      const sourceItems = [...sourceColumn.items]
      const destItems = [...destColumn.items]
      const [removed] = sourceItems.splice(source.index, 1)
      destItems.splice(destination.index, 0, removed)

      const cardId = removed._id
      const destinationBoxId = destination.droppableId

      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      })

      put("leads/kanban", { leadId: cardId, status: destinationBoxId })
        .then(res => {
          fetchLead(checkBox, master)
        })
        .catch(err => {
          console.log(err.response?.data)
        })
    } else {
      const column = columns[source.droppableId]
      const copiedItems = [...column.items]
      const [removed] = copiedItems.splice(source.index, 1)
      copiedItems.splice(destination.index, 0, removed)
      const cardId = removed._id
      const destinationBoxId = destination.droppableId
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      })

      put("leads/kanban", { lead_id: cardId, status: destinationBoxId })
        .then(res => {
          fetchLead(checkBox, master)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  function handleOpen(item) {
    setFollowup({
      modal: true,
      leadId: item._id,
      studentId: item?.lead_reg_id?._id,
    })
  }

  return (
    <React.Fragment>
      <div className="page-content" style={{ background: "white" }}>
        <Container fluid>
          {/* //? Filter */}
          <div
            className="mt-2"
            style={{ display: "flex", alignItems: "center" }}
          >
            {checkBoxName?.map((item, index) => {
              const isChecked = checkBox.includes(item?._id)

              return (
                <div
                  key={index + "lead_status"}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    name={item?.name}
                    onClick={checkboxClick}
                    key={index}
                    type="checkbox"
                    checked={isChecked === true ? true : false}
                    value={item?._id}
                  />
                  <p className="mx-3 mb-0" style={{ textWrap: "nowrap" }}>
                    {item?.name}
                  </p>
                </div>
              )
            })}

            <AvForm className="d-flex align-items-center">
              <div className="d-flex mx-2 align-items-center">
                <label style={{ marginTop: ".5rem" }}>From</label>
                <AvField
                  style={{ width: "120px", marginLeft: "10px" }}
                  name="from_date"
                  className="mt-3"
                  type="date"
                  value={master.from_date || ""}
                  onChange={handleChange}
                />
              </div>

              <div className="d-flex mx-2 align-items-center">
                <label style={{ marginTop: ".5rem" }}>To</label>
                <AvField
                  style={{ width: "120px", marginLeft: "10px" }}
                  name="to_date"
                  className="mt-3"
                  type="date"
                  value={master.to_date || ""}
                  onChange={handleChange}
                />
              </div>
            </AvForm>
          </div>

          {/* //? Kanban */}
          <div className="kanboard mt-3">
            <DragDropContext
              onDragEnd={result => onDragEnd(result, columns, setColumns)}
            >
              {Object.entries(columns)?.map(([columnId, column], index) => {
                return (
                  <div key={columnId} className={`kanboard_child `}>
                    <div className="kanboard_header">
                      <div className="board_header_left">{column?.name}</div>
                      <div className="board_header_right">
                        <h6 className="card_icon kanboard_close">
                          {column?.items?.length}
                        </h6>
                        <h6 className="card_icon kanboard_view">
                          <i class="fa fa-angle-right"></i>
                        </h6>
                      </div>
                    </div>
                    <div className="kanboard_body">
                      <h6 className="board_name">{column?.name}</h6>

                      <Droppable droppableId={columnId} key={columnId}>
                        {(provided, snapshot) => {
                          return (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                // background: snapshot.isDraggingOver
                                //   ? "lightblue"
                                //   : "lightgrey",
                                //   padding: 4,
                                width: 215,
                                minHeight: 200,
                                display: "flex",
                                flexDirection: "column",
                                gap: 10,
                              }}
                            >
                              {column?.items?.map((item, index) => {
                                return (
                                  <Draggable
                                    key={item._id}
                                    draggableId={item._id}
                                    index={index}
                                  >
                                    {(provided, snapshot) => {
                                      return (
                                        <div
                                          onClick={() => {
                                            handleOpen(item)
                                          }}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            //   userSelect: "none",
                                            //   padding: 16,
                                            //   margin: "0 0 8px 15px",
                                            //   minHeight: "50px",
                                            //   backgroundColor: snapshot.isDragging
                                            //     ? "#263B4A"
                                            //     : "#456C86",
                                            //   color: "white",
                                            borderColor: "#99bf34",
                                            ...provided.draggableProps.style,
                                          }}
                                          class="kanboard_card"
                                        >
                                          <h5 className="mb-0">
                                            {`${
                                              item?.lead_reg_id?.firstname || ""
                                            } ${
                                              item?.lead_reg_id?.lastname || ""
                                            }`}
                                          </h5>
                                          <p className="mb-0">{item?.course}</p>

                                          <div className="flex_align">
                                            <p className="mb-0">
                                              {moment(
                                                item?.followup?.date ||
                                                  item?.date
                                              ).format("DD-MM-YYYY")}
                                              {/* Date  */}
                                            </p>
                                            <p className="mb-0">
                                              {item?.followup?.addedBy
                                                ? `${
                                                    item?.followup?.addedBy
                                                      ?.firstName || ""
                                                  } ${
                                                    item?.followup?.addedBy
                                                      ?.lastName || ""
                                                  }`
                                                : `${
                                                    item?.addedBy?.firstName ||
                                                    ""
                                                  } ${
                                                    item?.addedBy?.lastName ||
                                                    ""
                                                  }`}
                                            </p>
                                            <i
                                              style={{ cursor: "pointer" }}
                                              className="fa fa-eye icon_eye"
                                              onClick={() => handleOpen(item)}
                                            ></i>
                                          </div>
                                        </div>
                                      )
                                    }}
                                  </Draggable>
                                )
                              })}
                              {provided.placeholder}
                            </div>
                          )
                        }}
                      </Droppable>
                    </div>
                  </div>
                )
              })}
            </DragDropContext>
          </div>
        </Container>
        {followup.modal && (
          <Modal
            show={followup.modal}
            isOpen={followup.modal}
            toggle={() => {
              fetchLead(checkBox, master)
              setFollowup({ modal: false })
            }}
            size="xl"
            centered={true}
            id="leadModal"
            className="lead-modal"
          >
            <div className="modal-header">
              <div className="modal-title">
                <h6
                  style={{ marginBottom: "0px" }}
                  className="modal-title mt-0"
                >
                  {/* #{leadData.unique_id} */}
                </h6>
              </div>
              <button
                style={{ marginBottom: "2px" }}
                type="button"
                onClick={() => {
                  fetchLead(checkBox, master)
                  setFollowup({ modal: false })
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>

              <Followup followup={followup} />
            </div>
          </Modal>
        )}
      </div>
    </React.Fragment>
  )
}

export default LeadsBoard
