import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Button,
  Label, BreadcrumbItem
} from "reactstrap";
import { MDBDataTable } from "mdbreact";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import BallotIcon from "@mui/icons-material/Ballot";
import AssignmentIcon from "@mui/icons-material/Assignment";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { Box, Divider } from "@mui/material";
import { AvForm } from "availity-reactstrap-validation"
import { Modal } from "react-bootstrap"
import Select from "react-select"
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import MiniWidget from './mini-widget'
import { useParams } from 'react-router-dom';
import { get, put } from "../../../helpers/api_helper";
import { API_URL } from "../../../config";
import moment from "moment";
import TablePagination from "../../../components/Common/Pagination";
import toastr from "toastr"



const ViewJob = (props) => {

  // const { jobId } = props.match.params;
  const navigate = useNavigate()
  const {recruiterId} = useParams()
// const { state } = useLocation()

  const [recruiterDetails,setRecruiterDetails] = useState(null)
  const [summary,setSummary] = useState(null)
  const [jobPost,setJobPost] = useState([])
  const [internships,setInternships] = useState([])
  const [modalData,setModalData] = useState(null)

  const [popupView4, setPopupView4] = useState(false)

  const [tab3, setTab3] = React.useState("5");

  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 20


  const [totalPage2, setTotalPage2] = useState(0)
  const [page2, setPage2] = useState(1)

  const [state,setState] = useState(false)

  const status = [
    { label: "Ongoing", value: 0 },
    { label: "Inactive", value: 2 },
    { label: "Expired", value: 3 },
    { label: "Filled", value: 4 },
  ]


useEffect(() => {
  fetchRecruiterDetails()
}, [])

useEffect(()=>{
  fetchSummary()
},[])

useEffect(()=>{
  fetchjobPost()
},[page,state])

useEffect(()=>{
  let status  = -1
  if(tab3 == 1){
    status = 0
  }else if(tab3 == 3){
    status = 2
  }else if(tab3 == 4){
    status = 3
  }
  if(status >= 0){
    fetchInternship(status)
  }
},[tab3,page2])

const handleChange = (event, value) => {
  setPage(value)
}

const handleChange2 = (event, value) => {
  setPage2(value)
}


   const fetchRecruiterDetails = async()=>{
      try {
        const response = await get(`/recruiter/single-recruiter?recruiterId=${recruiterId}`)
        setRecruiterDetails(response?.data)
      } catch (error) {
        
      }
    }

    const fetchSummary = async()=>{
      try {
        const response = await get(`/recruiter/summary?recruiterId=${recruiterId}`)
        setSummary(response?.data)
      } catch (error) {
        
      }
    }

    const fetchjobPost = async()=>{
      try {
         const filterQuery = `recruiterId=${recruiterId}&page=${page}&limit=${limit}`
        const response = await get(`/recruiter/job-posts?${filterQuery}`)
        setJobPost(response?.data)
        const count = response?.count
        const totalPage = Math.ceil(Number(count) / limit)
        setTotalPage(totalPage)
      } catch (error) {
        
      }
    }

    const fetchInternship = async(status)=>{
      try {
        const filterQuery = `recruiterId=${recruiterId}&page=${page}&limit=${limit}`
        const response = await get(`/recruiter/Internship?${filterQuery}`)
        setInternships(response?.data)
        const count = response?.count
        const totalPage = Math.ceil(Number(count) / limit)
        setTotalPage2(totalPage)
      } catch (error) {
        
      }
    }
  const handleChangeTab = (event, newValue) => {
    setTab3(newValue);

  };

  const handleStatusChange = async (requestId, approvedStatus) => {
    put("/job/status", { _id: requestId, status: approvedStatus })
      .then(res => {
        setState(!state)
        toastr.success(res.message)
      })
      .catch(err => {
        toastr.error(err.response.data.message)
      })
  }

  const handleStatusChange2 = async (requestId, approvedStatus) => {
    put("/internship/status", { _id: requestId, status: approvedStatus })
      .then(res => {
       fetchInternship()
        toastr.success(res.message)
      })
      .catch(err => {
        toastr.error(err.response.data.message)
      })
  }



  const jobPosts = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "ID",
        field: "shortlisted_reg_no",
        sort: "asc",
        width: 400,
      },
      {
        label: "Job Name",
        field: "job",
        sort: "asc",
        width: 400,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 200,
      },
     
      {
        label: "Staff",
        field: "Staff",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: 
    jobPost.map((item,index)=>{
      return {
        id: index + 1 + (page - 1) * limit,
        date: moment(item.date).format("DD-MM-YYYY"),
        shortlisted_reg_no: item?.uniqueId,
        shortlisted_cand_name: item?.qualification,
        job: item?.name,
        Staff: `${item?.firstname || ""} ${item?.lastname || ""}`,
        shortlisted_cand_mob: "9365524183",
        status:  (
          <div className="mb-3">
            <Select
              menuPosition="fixed"
              name="job_status"
              placeholder="status"
              onChange={selected => {
                handleStatusChange(item?._id, selected.value)
              }}
              value={status?.filter(status => status.value === item?.status)}
              options={status}
              classNamePrefix="select2-selection"
            />
          </div>
        ),
        
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                navigate(`/create-job-post/${item?.uniqueId}`, {
                  state: { id: item._id },
                })
              }}
              ></i>
            </div>
          )
        };
      })
  };


 

  const internship = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "ID",
        field: "uniqueId",
        sort: "asc",
        width: 400,
      },
      {
        label: "Internship Name",
        field: "name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 200,
      },
     
      {
        label: "Staff",
        field: "Staff",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: internships.map((item,index)=>{
      return   {
        id: index + 1 + (page2 - 1) * limit,
        date: moment(item.date).format("DD-MM-YYYY"),
        uniqueId: item?.uniqueId,
        name: item?.name,
        status:  (
          <div className="mb-3">
            <Select
              menuPosition="fixed"
              name="job_status"
              placeholder="status"
              onChange={selected => {
                handleStatusChange2(item?._id, selected.value)
              }}
              value={status?.filter(status => status.value === item?.status)}
              options={status}
              classNamePrefix="select2-selection"
            />
          </div>
        ),  
        Staff:`${item?.firstname || ""} ${item?.lastname || ""}` ,    
         action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                navigate(`/create-internship-post/${item?.uniqueId}`, {
                  state: { id: item._id },
                })
              }}
              // onClick={() => {
              //   setPopupView4(true)
              //   setModalData(item)
              // }}
            ></i>
           

          </div>
         )
        };
      })
  
   
  };


  const series1 = [50]

  const options1 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series2 = [70]

  const options2 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series3 = [55]

  const options3 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series4 = [33]

  const options4 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const reports = [

    {
      id: 1,
      title: "Job Post",
      value: summary?.jopPost,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      series: series1,
      options: options1,
    },
    {
      id: 2,
      title: "Internship",
      value:  summary?.internship,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      series: series2,
      options: options2,
    },
   


  ]


  return (
    <React.Fragment>

      <Modal
        show={popupView4}
        isOpen={popupView4}
        toggle={() => setPopupView4(!popupView4)}
        size="lg"
        centered={true}>
        <div className="modal-header">
          <div className="modal-title">
            <h5>Applied Details</h5>
          </div>

          <button
            type="button"
            onClick={() => setPopupView4(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body" style={{ paddingTop: '0px' }}>
          <Row>
            <Col md="12">
              <table
                id="product_available_day"
                className="table table-bordered dataTable"
              >
                <tr>
                  <th style={{ padding: "10px 11px", textAlign: 'left' }}>Job</th>
                  <th> : </th>
                  <td style={{ paddingLeft: '20px' }}>{modalData?.jobDetails?.name}</td>
                  <th style={{ textAlign: "left" }}>Recruiters</th>
                  <th> : </th>
                  <td style={{ paddingLeft: '20px', textAlign: "justify" }}>{recruiterDetails?.name}</td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: '10px' }}>Application ID</th>
                  <th> : </th>
                  <td style={{ paddingLeft: '20px' }}>{modalData?.uniqueId}</td>
                  <th style={{ textAlign: "left" }}>Candidate</th>
                  <th> : </th>
                  <td style={{ paddingLeft: '20px', textAlign: "justify" }}>{modalData?.applicantDetails?.name}</td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: '10px' }}>Mobile</th>
                  <th> : </th>
                  <td style={{ paddingLeft: '20px', textAlign: "justify" }}>{modalData?.applicantDetails?.mobile}</td>
                  <th style={{ textAlign: "left" }}>Email</th>
                  <th> : </th>
                  <td style={{ paddingLeft: '20px', textAlign: "justify" }}>{modalData?.applicantDetails?.email}</td>
                </tr>
                {tab3 == 4 &&
                <tr>
                  <th style={{ textAlign: "left", padding: '10px' }}>Placed Date</th>
                  <th> : </th>
                  <td style={{ paddingLeft: '20px' }}>{moment(modalData?.placedDate).format("DD-MM-YYYY")}</td>
                  <th style={{ textAlign: "left" }}>Placed Time</th>
                  <th> : </th>
                  <td style={{ paddingLeft: '20px', textAlign: "justify" }}>{moment(modalData?.placedTime, "HH:mm:ss").format("hh:mm A")} </td>
                </tr>
                }

                {tab3 == 4 &&
                <tr>
                  <th style={{ textAlign: "left", padding: '10px' }}>Shortlisted Date</th>
                  <th> : </th>
                  <td style={{ paddingLeft: '20px' }}>{moment(modalData?.shortlistDate).format("DD-MM-YYYY")}</td>
                  <th style={{ textAlign: "left" }}>Shortlisted Time</th>
                  <th> : </th>
                  <td style={{ paddingLeft: '20px', textAlign: "justify" }}>{moment(modalData?.shortlistTime, "HH:mm:ss").format("hh:mm A")}</td>
                </tr>
                }


                <tr>
                  <th style={{ textAlign: "left", padding: '10px' }}>Experiance</th>
                  <th> : </th>
                  <td style={{ paddingLeft: '20px', textAlign: "justify" }}>Fresher</td>
                  <th style={{ textAlign: "left" }}>Qualificaion</th>
                  <th> : </th>
                  <td style={{ paddingLeft: '20px', textAlign: "justify" }}>{modalData?.qualificationDetails?.name}</td>
                </tr>
              </table>
            </Col>
          </Row>
        </div>
      </Modal>


      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">Recruiter - {recruiterDetails?.companyName}</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      <Link to="#">Home</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="/create-recruiter">Recruiters</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="#">{recruiterDetails?.companyName}</Link>
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              {/* First Dialogue */}

              <div>
                <div className="row">
                <div className="col-xl-3">
                    <div
                      className="card"
                      style={{
                        // width: "fit-content",
                        // width: "351px",
                        height: "max-content",
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                      }}
                    >
                      <div className="card-body">
                        <div className="float-end dropdown">
                       
                        </div>

                        <h5
                          className="mt-2 mb-2"
                          style={{
                            textAlign: "center",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}
                        >
                       {recruiterDetails?.companyName}
                        </h5>
                        <Stack
                          direction="row"
                          style={{ justifyContent: "center" }}
                        >
                          <img
                            alt="Image"
                            src={`${API_URL}/${recruiterDetails?.logo}`}
                            style={{
                              width: "5rem",
                              height: "4rem",
                              //marginTop: "20%",
                              // width: "100%",
                              borderRadius: "5px",
                            }}
                          />
                        </Stack>
                        <div
                          className="text-muted"
                          style={{
                            textAlign: "center",
                            color: "green !important",
                            fontFamily: "IBM Plex Sans,sans-serif",
                            fontSize: "14px",
                            marginBottom: "3px",
                          }}
                        >
                          
                        </div>
                      
                        <Divider />
                        <div
                        
                          className="mt-2"
                       
                        >
                          <p className="mb-1">Company ID:</p>
                          <h5 className="font-size-14">{recruiterDetails?.companyId}</h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div
                          className="mt-2"
                        
                        >
                          <p className="mb-1">Company Code:</p>
                          <h5 className="font-size-14">
                          {recruiterDetails?.shortcode}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2">
                          <p className="mb-1"> Contact Person:</p>
                          <h5 className="font-size-14">
                           {recruiterDetails?.person}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2">
                          <p className="mb-1">Mobile:</p>
                          <h5 className="font-size-14">
                            {" "}
                            {recruiterDetails?.mob}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2">
                          <p className="mb-1">Email:</p>
                          <h5 className="font-size-14">
                            {" "}
                            {recruiterDetails?.email}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2">
                          <p className="mb-1">Address:</p>
                          <h5 className="font-size-14">
                            {" "}
                            {recruiterDetails?.address}
                          </h5>
                        </div>
                        
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2"> 
                          <p className="mb-1">District:</p>
                          <h5 className="font-size-14">{recruiterDetails?.district}</h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2">
                          <p className="mb-1">State:</p>
                          <h5 className="font-size-14">{recruiterDetails?.state}</h5>
                        </div>
                       
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-9" style={{ marginBottom: "30px" }}>
                    <div
                      className="mb-0 card"
                      style={{
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={tab3}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              indicatorColor="primary"
                              textColor="primary"
                              variant="fullWidth"
                              onChange={handleChangeTab}
                              aria-label="lab API tabs example"
                            >
                              <Tab
                                icon={
                                  <SummarizeIcon style={{ fontSize: "20px" }} />
                                }
                                label="Summary"
                                value="5"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <LocationCityIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Job Posts"
                                value="6"
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <AssignmentIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Internship"
                                value="1"
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              
                            </TabList>
                          </Box>
                          
                       
                    
                          <TabPanel value="5" style={{
                            fontSize: "13px",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}>
                          
                            <Row>
                              <MiniWidget reports={reports} />
                            </Row>
                          </TabPanel>
                          <TabPanel value="6" style={{
                            fontSize: "13px",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}>
                            <Row>
                              <Col className="col-12">
                              <MDBDataTable
                                  id="dashboardTableId"
                                  responsive
                                  bordered
                                  searching={true}
                                  entries={20}
                                  disableRetreatAfterSorting={true}
                                  data={jobPosts}
                                  paginationLabel={false}
                                  paging={false}
                                />
                                  <TablePagination
                                  page={page}
                                  onChange={handleChange}
                                  count={totalPage}
                                /> 
                              </Col>
                            </Row>
                          </TabPanel>
                          <TabPanel value="1" style={{
                            fontSize: "13px",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}>
                            <Row>
                              <Col className="col-12">
                                
                              <MDBDataTable
                                  id="movementTable"
                                  responsive
                                  bordered
                                  data={internship}
                                  searching={true}
                                  info={true}
                                  disableRetreatAfterSorting={true}
                                  paginationLabel={false}
                                  paging={false}
                                />

                              <TablePagination
                                  page={page2}
                                  onChange={handleChange2}
                                  count={totalPage2}
                                /> 
                              </Col>
                            </Row>
                          </TabPanel>
                        </TabContext>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>

            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );


}

export default ViewJob