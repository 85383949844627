import React, { useEffect, useRef, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Label, Button } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import { MDBDataTable } from "mdbreact"



// import SearchnameMobile from "../../Students/SearchnameMobile"
import "./OfferCode.scss"
import { del, get, post, put } from "../../../helpers/api_helper"
// import AsyncSelect from "react-select/async"
import moment from "moment"
import toastr from "toastr"
import Swal from "sweetalert2"
import { useNavigate } from "react-router-dom"
import { setTitle } from "../../../helpers/functions"

function OfferCode() {
  setTitle("Offer Code")
  const [selectedOffer, setSelectedOffer] = useState(null)
  const [students, setStudents] = useState([])
  const [selectedStudents, setselectedStudents] = useState(null)
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const limit = 50;
  const [search, setSearch] = useState("");
  const debounceTimeout = useRef(null);

  const [selectedBatch, setselectedBatch] = useState(null)
  const [selectedCourse, setselectedCourses] = useState(null)
  const [selectedEvents, setselectedEvents] = useState(null)
  const [selectedFor, setSelectedFor] = useState(null)
  const [batches, setBatches] = useState([])
  const [courses, setCourses] = useState([])
  const [Events, setEvents] = useState([])
  const formRef = useRef()
  const navigate = useNavigate()

  const filterRef = useRef()
  const [selectedForFilter, setSelectedForFilter] = useState(null)
  const [selectedAmountOrPercentage, setSelectedAmountOrPercentage] =
    useState(null)
  const [selectedValidFrom, setSelectedValidFrom] = useState("")
  const [selectedValidTo, setSelectedValidTo] = useState("")
  const [selectedStatus, setselectedstatus] = useState(null)

  // eslint-disable-next-line
  const [showAdditionalFields, setShowAdditionalFields] = useState({
    show: false,
  })

useEffect(() => {
    const fetchStudentOptions = async () => {
      setIsLoading(true);
      try {
        const res = await get(`/offercode/options/student?page=${page}&limit=${limit}&search=${search}`);
        setStudents(prevStudents => [...prevStudents, ...res.data]);
        setHasMore(res.data.students.length === limit);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    fetchStudentOptions();
  }, [page,search]);


  useEffect(() => {
    handleBatchOptions()
    handleCourseOptions()
    handleEventOptions() 
  }, [])


  useEffect(() => {
    handleTableData()
  }, [
    selectedForFilter,
    selectedValidFrom,
    selectedValidTo,
    selectedStatus,
    selectedAmountOrPercentage,
  ])

  const forOptions = [
    { label: "Course", value: 0 },
    { label: "Student", value: 1 },
    { label: "Batch", value: 2 },
    { label: "Event", value: 3 },
  ]

  // const handleDataSelected = selectedData => {
  //   setShowAdditionalFields({ ...selectedData })
  // }
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }
  const handleBatchOptions = async () => {
    get("/options/batches").then(res => {
      var event_data = res.data.map(item => {
        // console.log(item, "itemsss")
        return {
          label: item?.label + " " + item?.uniqueId,
          value: item?._id,
        }
      })
      setBatches(event_data)
    })
  }
  const handleCourseOptions = async () => {
    get("/options/courses").then(res => {
      var event_data = res.data.map(item => {
        // console.log(item, "itemsss")
        return {
          label: item?.label + " " + item?.uniqueId,
          value: item?._id,
        }
      })
      setCourses(event_data)
    })
  }
  const handleEventOptions = async () => {
    get("/options/events").then(res => {
      var event_data = res.data.map(item => {
        // console.log(item, "itemsss")
        return {
          label: item?.label + " " + item?.uniqueId,
          value: item?._id,
        }
      })
      setEvents(event_data)
    })
  }



  // function fetchStudentOptions() {
  //   get("/options/students").then(res => {
  //     console.log("student", res)

  //   })
  // }

  const reset = () => {
    formRef.current?.reset()
    handleTableData()
    setMasterObject(initialState)
    setselectedCourses(null)
    setselectedEvents(null)
    setSelectedFor(null)
    setselectedBatch(null)
    setselectedStudents(null)

    toTop()
  }

  const resetFilter = () => {
    filterRef.current?.reset()
    setSelectedForFilter(null)
    setSelectedValidFrom("")
    setSelectedValidTo("")
    setselectedstatus(null)
    setSelectedAmountOrPercentage(null)
  }

  function handleSelect(selected, name) {
    switch (name) {
      case "students":
        setselectedStudents(selected)
        let student = selected.map(item => item.value)
        setMasterObject({
          ...masterObject,
          students: student,
        })
        break
      case "batches":
        setselectedBatch(selected)
        let batch = selected.map(item => item.value)
        setMasterObject({
          ...masterObject,
          batches: batch,
        })
        break
      case "courses":
        setselectedCourses(selected)
        let course = selected.map(item => item.value)
        setMasterObject({
          ...masterObject,
          courses: course,
        })
        break
      case "event":
        setselectedEvents(selected)
        let event = selected.map(item => item.value)
        setMasterObject({
          ...masterObject,
          event: event,
        })
        break
      default:
        break
    }
  }

  // const [studentOptions, setStudentOptions] = useState([])
  const [offerCode, setOfferCode] = useState([])
  const initialState = {
    name: "",
    for: "",
    code: "",
    maxLimit: "",
    validTo: "",
    validFrom: "",
    To: "",
    From: "",
    amountOrPercentage: "",
    type: 0,
  }
  const [masterObject, setMasterObject] = useState(initialState)


  // console.log(masterObject, "masterobject")

  const handleStatusChange = async (_id, status) => {
    console.log(status, "status")
    if (status === 0) {
      await put("/offerCode/status", { _id: _id, status: 2 }).then(res => {
        // console.log(res, "status")
        handleTableData()
        toastr.success(res.message)
      })
    } else if (status === 2) {
      await put("/offerCode/status", { _id: _id, status: 0 }).then(res => {
        // console.log(res, "status")
        handleTableData()
        toastr.success(res.message)
      })
    }
  }

  function handleTableData() {
    let obj = `offerCodeFor=${selectedForFilter?.value}&type=${selectedAmountOrPercentage?.value}&validFrom=${selectedValidFrom}&validTo=${selectedValidTo}&status=${selectedStatus?.value}`
    get(`/offerCode?${obj}`).then(res => {
      let result = res.data

      result.map((item, index) => {
        item.id = index + 1
        item.Type =
          item.type === 0 ? "Percentage" : item.type === 1 ? "Amount" : ""

        item.allotedAmount = item.allotedAmount?.toLocaleString("en-IN") || 0
        item.totalAvailedCount =
          item.totalAvailedCount?.toLocaleString("en-IN") || 0
        item.totalAvailedAmount =
          item.totalAvailedAmount?.toLocaleString("en-IN") || 0
        item.For =
          item.for === 0
            ? "Course"
            : item.for === 1
            ? "Student"
            : item.for === 2
            ? "Batch"
            : item.for === 3
            ? "Event"
            : ""
        item.status = (
          <Button
            className="btn btn-sm"
            color={item.status === 0 ? "success" : "warning"}
            onClick={() => handleStatusChange(item?._id, item?.status)}
          >
            {item.status === 0 ? "Active" : "Inactive"}
          </Button>
        )
        // if (item.status === 0) {
        //   item.statusList = (
        //     <Button
        //       className="btn btn-sm"
        //       color="success"
        //       onClick={() => handleStatusChange(item?._id, item?.status)}
        //     >
        //       Active
        //     </Button>
        //   )
        // } else if (item.status === 2) {
        //   item.statusList = (
        //     <Button
        //       className="btn btn-sm"
        //       color="warning"
        //       onClick={() => handleStatusChange(item?._id, item?.status)}
        //     >
        //       Inactive
        //     </Button>
        //   )
        // }
        item.options = (
          <div>
            <i
              className="fas fa-eye"
              onClick={() => {
                navigate(`/offer-codes/${item.name}`, {
                  state: { id: item._id },
                })
              }}
              style={{
                color: "black",
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
            ></i>
            <i
              className="far fa-edit"
              onClick={() => handleEdit(item._id)}
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              onClick={() => {
                handleDelete(item?._id)
              }}
              style={{ cursor: "pointer", fontSize: "1em" }}
            ></i>
          </div>
        )
        return item
      })
      setOfferCode(result)
    })
  }
  const handleValueChange = (value, name) => {
    const obj = { ...masterObject }
    obj[name] = value
    setMasterObject(obj)
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "For",
        field: "For",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        width: 150,
      },
      {
        label: "Code",
        field: "code",
        width: 150,
      },
      {
        label: "Amount/Percentage",
        field: "Type",
        width: 150,
      },
      {
        label: "Value",
        field: "amountOrPercentage",
        width: 150,
      },
      {
        label: "Alloted Amount",
        field: "allotedAmount",
        width: 150,
      },
      {
        label: "Availed Count",
        field: "totalAvailedCount",
        width: 150,
      },
      {
        label: "Availed Amount",
        field: "totalAvailedAmount",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        width: 150,
      },
      {
        label: "Actions",
        field: "options",
        sort: "desc",
        width: 400,
      },
    ],
    rows: offerCode,
  }


  const handleEdit = id => {
    toTop()
    get(`/offerCode/single/${id}`).then((res)=>{
      console.log("ressss edit",res);
      let item=res.data

      const selectedFor = forOptions.filter(option => option.value == item.for)
      setSelectedFor(selectedFor)
      setselectedStudents(item?.students)
      setselectedBatch(item?.batches)
      setselectedEvents(item?.event)
      setselectedCourses(item?.courses)

      let forCES =  ""
      if(item.for ==0){
        forCES = "courses"
      }else if(item.for==1){
        forCES="students"
      }else if(item.for==2){
        forCES="batches"
      }else if(item.for==3){
        forCES="event"
      }

      setMasterObject({
        _id: item._id,
        name: item.name,
        amountOrPercentage: item.amountOrPercentage,
        for: item?.for,
        type: item?.type,
        maxLimit: item?.maxLimit,
        allotedAmount: item?.allotedAmount,
        validTo: item?.validTo,
        validFrom: item?.validFrom, 
        code: item?.code,
        status: item?.status,
        [forCES]:item[forCES].map((el)=>el.value)
      })

    })
  }

  function handleSubmit() {
    if (masterObject._id) {
      put("/offerCode", masterObject)
        .then(res => {
          console.log(res, "eventsedit")
          toastr.success(res.message)
          handleTableData()
          formRef.current.reset()
          reset()
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    } else {
      post("/offerCode", masterObject)
        .then(res => {
          console.log(res, "events")
          toastr.success(res.message)
          handleTableData()
          formRef.current.reset()

          reset()
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    }
  }
  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`/offerCode?id=${id}`)
          .then(res => {
            toastr.success(res.message)
            handleTableData()
            formRef.current.reset()
          })
          .catch(err => {
            toastr.success(err.response.data.message)
          })
      }
    })
  }


  const loadMoreOptions = () => {
    if (hasMore && !isLoading) {
      setPage(prevPage => prevPage + 1);
    }
  };
  

  const customStyles = {
    menuList: (provided) => ({
      ...provided,
      maxHeight: '300px',
      overflowY: 'auto',
      position: 'relative'
    })
  };


  const handleSearchChange = (value) => {
    setSearch(value);
    setPage(1);
  };

  const debouncedSearchChange = (value) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      handleSearchChange(value);
    }, 
    300); // Adjust the debounce delay as needed
  };



  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Offer Code" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm onValidSubmit={handleSubmit} ref={formRef}>
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Offer Code For</Label>
                          <Select
                            onChange={selected => {
                              setSelectedFor(selected)
                              handleValueChange(selected.value, "for")
                            }}
                            value={selectedFor}
                            name="for"
                            options={forOptions}
                            type="text"
                            errorMessage="Enter Category"
                          />
                        </div>
                      </Col>

                      {masterObject?.for === 0 && (
                        <>
                          <Col md="4">
                            <div className="mb-3">
                              <Label>Course</Label>
                              <Select
                                isMulti
                                name="courses"
                                classNamePrefix="select2-selection"
                                value={selectedCourse}
                                options={courses}
                                onChange={selected => {
                                  handleSelect(selected, "courses")
                                }}
                              />
                            </div>
                          </Col>
                        </>
                      )}
                      {masterObject?.for === 1 && (
                        <>
                          <Col md={4}>
                            <div className="mb-3">
                            <label>Student</label>
                            <Select
                              name="students"
                              isMulti
                              options={students}
                              value={selectedStudents}
                              onChange={selected => {
                                handleSelect(selected, "students")
                              }}
                              styles={customStyles}
                              onMenuScrollToBottom={loadMoreOptions}
                              isLoading={isLoading}
                              loadingMessage={() => <p>Loading...</p>}
                              onInputChange={value => {
                                debouncedSearchChange(value);
                              }}

                            />
                          </div>
                          </Col>
                        </>
                      )}
                      {masterObject?.for === 2 ? (
                        <>
                          <Col md="4">
                            <div className="mb-3">
                              <Label>Batch</Label>
                              <Select
                                isMulti
                                name="batches"
                                value={selectedBatch}
                                classNamePrefix="select2-selection"
                                onChange={selected => {
                                  handleSelect(selected, "batches")
                                }}
                                options={batches}
                              />
                            </div>
                          </Col>
                        </>
                      ) : null}
                      {masterObject?.for === 3 ? (
                        <>
                          <Col md="4">
                            <div className="mb-3">
                              <Label>Event</Label>
                              <Select
                                name="event"
                                isMulti
                                value={selectedEvents}
                                onChange={selected =>
                                  handleSelect(selected, "event")
                                }
                                classNamePrefix="select2-selection"
                                options={Events}
                              />
                            </div>
                          </Col>
                        </>
                      ) : null}

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Offer Name</Label>
                          <AvField
                            name="name"
                            className="form-control"
                            value={masterObject?.name}
                            onChange={e =>
                              handleValueChange(e.target.value, "name")
                            }
                            type="text"
                            placeholder="Offer Name"
                            validate={{ required: { value: true } }}
                            errorMessage="Enter Offer Name"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Offer Code</Label>
                          <AvField
                            name="code"
                            className="form-control"
                            value={masterObject?.code}
                            onChange={e =>
                              handleValueChange(e.target.value, "code")
                            }
                            type="text"
                            placeholder="Offer Code"
                            validate={{ required: { value: true } }}
                            errorMessage="Enter Offer Code"
                          />
                        </div>
                      </Col>

                      <Row>
                        <Col md="3" className="d-flex">
                          <div className=" d-flex align-items-center gap-5">
                            <div>
                              <input
                                class="form-check-input"
                                type="radio"
                                name="type"
                                id="flexRadioDefault1"
                                checked={!masterObject.type}
                                onClick={e => {
                                  masterObject.type
                                    ? handleValueChange(0, "type")
                                    : handleValueChange(1, "type")
                                }}
                                style={{
                                  marginRight: "10px",
                                  borderColor: "gray",
                                }}
                              />
                              <label
                                class="form-check-label"
                                for="flexRadioDefault1"
                              >
                                Percentage
                              </label>
                            </div>
                            <div>
                              <input
                                class="form-check-input"
                                type="radio"
                                name="type"
                                id="flexRadioDefault2"
                                checked={masterObject.type}
                                onClick={e => {
                                  masterObject.type
                                    ? handleValueChange(0, "type")
                                    : handleValueChange(1, "type")
                                }}
                                style={{
                                  marginRight: "10px",
                                  borderColor: "gray",
                                }}
                              />
                              <label
                                class="form-check-label"
                                for="flexRadioDefault2"
                              >
                                Amount
                              </label>
                            </div>
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>
                              {masterObject.type === 0
                                ? "Percentage"
                                : "Amount"}
                            </Label>
                            <AvField
                              name="amountOrPercentage"
                              className="form-control"
                              value={masterObject?.amountOrPercentage}
                              onChange={e =>
                                handleValueChange(
                                  e.target.value,
                                  "amountOrPercentage"
                                )
                              }
                              type="text"
                              placeholder={
                                masterObject.type === 0
                                  ? "Percentage"
                                  : "Amount"
                              }
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Enter Value",
                                },
                                isPercentage: value => {
                                  if (masterObject.type === 0) {
                                    // Check if it's a percentage field
                                    const percentage = parseFloat(value)
                                    return (
                                      !isNaN(percentage) &&
                                      percentage >= 0 &&
                                      percentage <= 100
                                    )
                                  }
                                  return true // If it's not a percentage field, no validation needed
                                },
                              }}
                              errorMessage="Enter a valid value"
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <Label>Amount Limit</Label>
                            <AvField
                              name="maxLimit"
                              className="form-control"
                              type="text"
                              value={masterObject?.maxLimit}
                              onChange={e =>
                                handleValueChange(e.target.value, "maxLimit")
                              }
                              placeholder="Amount Limit"
                              validate={{ required: { value: true } }}
                              errorMessage="Enter Amount Limit"
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <Label>Total Alloted Amount</Label>
                            <AvField
                              name="allotedAmount"
                              className="form-control"
                              type="text"
                              value={masterObject?.allotedAmount}
                              onChange={e =>
                                handleValueChange(
                                  e.target.value,
                                  "allotedAmount"
                                )
                              }
                              placeholder="Alloted Amount Limit"
                              validate={{
                                required: { value: true },
                                min: { value: masterObject?.maxLimit },
                              }}
                              errorMessage="Enter Alloted Amount"
                            />
                          </div>
                        </Col>

                        <Col md="2">
                          <div className="mb-3">
                            <Label>Valid From</Label>
                            <AvField
                              name="validFrom"
                              className="form-control"
                              value={masterObject?.validFrom}
                              onChange={e =>
                                handleValueChange(e.target.value, "validFrom")
                              }
                              type="date"
                              validate={{ required: { value: true } }}
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <Label>Valid To</Label>
                            <AvField
                              name="validTo"
                              value={masterObject?.validTo || ""}
                              onChange={e =>
                                handleValueChange(e.target.value, "validTo")
                              }
                              min={masterObject?.validFrom}
                              className="form-control"
                              type="date"
                              validate={{ required: { value: true } }}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Col>
                        <div className="mb-3" style={{ paddingTop: "25px" }}>
                          <Button
                            color={masterObject._id ? "warning" : "primary"}
                            type="submit"
                          >
                            {masterObject._id ? "Update" : "Submit"}
                          </Button>
                          <Button
                            style={{ marginLeft: "10px" }}
                            color="danger"
                            type="reset"
                            onClick={reset}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <AvForm ref={filterRef}>
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Offer Code For</Label>
                          <Select
                            onChange={selected => {
                              setSelectedForFilter(selected)
                            }}
                            value={selectedForFilter}
                            name="for"
                            options={forOptions}
                            type="text"
                            errorMessage="Enter Category"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Amount/Percentage</Label>
                          <Select
                            onChange={selected => {
                              setSelectedAmountOrPercentage(selected)
                            }}
                            value={selectedAmountOrPercentage}
                            name="for"
                            options={[
                              { label: "Percentage", value: 0 },
                              { label: "Amount", value: 1 },
                            ]}
                            type="text"
                            errorMessage="Enter Category"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Valid From</Label>
                          <AvField
                            name="validFrom"
                            className="form-control"
                            value={selectedValidFrom}
                            onChange={e => setSelectedValidFrom(e.target.value)}
                            type="date"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Valid To</Label>
                          <AvField
                            name="validTo"
                            value={selectedValidTo}
                            onChange={e => setSelectedValidTo(e.target.value)}
                            min={selectedValidFrom}
                            className="form-control"
                            type="date"
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Status</Label>
                          <Select
                            onChange={selected => {
                              setselectedstatus(selected)
                            }}
                            value={selectedStatus}
                            name="status"
                            options={[
                              { label: "Active", value: 0 },
                              { label: "Inactive", value: 2 },
                            ]}
                            type="text"
                            errorMessage="Enter Category"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <Button
                          style={{ marginTop: "27px" }} // Add marginTop style here
                          color="danger"
                          type="reset"
                          onClick={resetFilter}
                        >
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                  <MDBDataTable
                    id="OfferCodeID"
                    bordered
                    responsive
                    pagination={false}
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                    sortable={false}
                  ></MDBDataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default OfferCode
