import React, { useEffect, useState } from 'react'
import { Row, Col} from "reactstrap"
import FinancialYear from './FinancialYear'
import Select from "react-select"
import { getCurrentFinancialMonthSelect, getCurrentFinancialQuarterSelect, getCurrentFinancialYearSelect } from '../../helpers/functions'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import FinancialQuarter from './FinancialQuarter'
import FinancialMonth from './FinancialMonth'
import { getDate } from '../../store/dashboard/actions'



const AdvancedDateFilter = () => {
  const getCurrentFinancialYear = getCurrentFinancialYearSelect()
  const getCurrentFinancialQuarter = getCurrentFinancialQuarterSelect()
  const getCurrentFinancialMonth = getCurrentFinancialMonthSelect()

  const [masterObject, setMasterObject] = useState({
    type: 1,
    year: getCurrentFinancialYear.value,
    quarter: getCurrentFinancialQuarter.value,
    month: getCurrentFinancialMonth.value,
    startDate: getCurrentFinancialYear.from,
    endDate: getCurrentFinancialYear.to,
  })

  const dispatch = useDispatch()

  useEffect(()=>{
    // const getCurrent = getCurrentFinancialYearSelect()
   if(getCurrentFinancialYear) dispatch(getDate({startDate:getCurrentFinancialYear.from,endDate:getCurrentFinancialYear.to}))
},[])


  const [selectedFields, setSelectedFields] = useState({
    type: { label: "Year", value: 1 },
    year: getCurrentFinancialYear,
    quarter: getCurrentFinancialQuarter,
    month: getCurrentFinancialMonth,
  })

  const typeOptions = [
    { label: "Year", value: 1 },
    { label: "Quarterly", value: 2 },
    { label: "Monthly", value: 3 },
    { label: "Custom", value: 4 },
  ]

  const handleSelectValueChange = async (selected, name) => {
    const obj = { ...selectedFields }
    obj[name] = selected
    setSelectedFields(obj)
    handleValueChange(selected, name)
  }

  const handleValueChange = async (selected, name) => {

    let obj = { ...masterObject }
    const value = selected.value
    obj[name] = value

    const isTypeChange = name === "type"
    const isYearChange = name === "year"
    const isQuarterChange = name === "quarter"
    const isMonthChange = name === "month"

    const isSelectChange = isYearChange || isQuarterChange || isMonthChange
    if (isSelectChange) obj = handleDateChange(obj, selected)

    if (isTypeChange && value === 1) handleDateChange(obj, selectedFields.year)
    if (isTypeChange && value === 3) handleDateChange(obj, selectedFields.month)
    if (isTypeChange && value === 2)
      handleDateChange(obj, selectedFields.quarter)

    // if(value === 4 && name !== 'startDate' && name !== 'endDate') dispatch(getDate({startDate:obj.startDate,endDate:obj.endDate}))
    if(name === 'startDate') dispatch(getDate({startDate:selected?.value,endDate:obj.endDate}))
    if(name === 'endDate') dispatch(getDate({endDate:selected?.value,startDate:obj.startDate}))

    setMasterObject(obj)
  }

  const handleDateChange = (obj, selected) => {
    obj.startDate = selected.from
    obj.endDate = selected.to
    dispatch(getDate({startDate:selected.from,endDate:selected.to}))

    return obj
  }


  return (
    <Row id="finance-dashboard-year">
    <Col md={masterObject.type === 2 ? 5 : 6}>
      {/* <h4 className="mb-0">Finance-Dashboard</h4> */}
    </Col>
    <Col md={masterObject.type === 2 ? 4 : 3}>
      <Row className="finance-dashboard-filter">
        <Col md={masterObject.type === 2 ? 5 : 6}>
          <Select
            options={typeOptions}
            value={selectedFields.type}
            onChange={selected =>
              handleSelectValueChange(selected, "type")
            }
          ></Select>
        </Col>
        {masterObject.type === 1 ? (
          <Col md={masterObject.type === 2 ? 7 : 6}>
            <FinancialYear
              onChange={selected =>
                handleSelectValueChange(selected, "year")
              }
              value={selectedFields.year}
            />
          </Col>
        ) : masterObject.type === 2 ? (
          <Col md={6}>
            <FinancialQuarter
              onChange={selected =>
                handleSelectValueChange(selected, "quarter")
              }
              value={selectedFields.quarter}
            />
          </Col>
        ) : masterObject.type === 3 ? (
          <Col md={6}>
            <FinancialMonth
              onChange={selected =>
                handleSelectValueChange(selected, "month")
              }
              value={selectedFields.month}
            />
          </Col>
        ) : (
          ""
        )}
      </Row>
    </Col>
    <Col md={3}>
      <AvForm>
        <Row>
          <Col md={6}>
            <AvField
              name="startDate"
              type="date"
              value={masterObject.startDate || ""}
              onChange={(e) =>{
                handleValueChange(e.target, e.target.name)
                }
              }
              disabled={masterObject.type !== 4}
            >
              {" "}
            </AvField>
          </Col>
          <Col md={6}>
            <AvField
              name="endDate"
              type="date"
              value={masterObject.endDate || ""}
              onChange={(e) =>{
                handleValueChange(e.target, e.target.name)
              }
              }
              disabled={masterObject.type !== 4}
              min={masterObject.startDate}
            ></AvField>
          </Col>
        </Row>
      </AvForm>
    </Col>
  </Row>
  )
}

export default AdvancedDateFilter
