import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import React, { useState, useRef, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import moment from "moment"
import queryString from "query-string"

import Select from "react-select"
import { MDBDataTable } from "mdbreact"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { Modal } from "react-bootstrap"

import { get } from "../../../../helpers/api_helper"
import { setTitle } from "../../../../helpers/functions"
import { getScholarshipStatus } from "../../../../store/options/actions"

import ScholarshipProcessing from "../Processing"
import TablePagination from "../../../../components/Common/Pagination"
import SearchStudent from "../../../../components/Common/SearchStudents"

const ScholarshipRequests = ({ options }) => {
  setTitle("Scholarship Requests")

  const initialState = {
    from: "",
    to: "",
    scholarship: "",
    student: "",
    status: "",
  }

  const toggle = (start = true) => {
    var body = document.body

    if (start) {
      body.classList.remove("vertical-collpsed")
      body.classList.remove("sidebar-enable")
      body.setAttribute("data-sidebar-size", "sm")
    } else {
      body.classList.add("vertical-collpsed")
      body.classList.add("sidebar-enable")
      body.setAttribute("data-sidebar-size", "lg")
    }
  }

  useEffect(() => {
    toggle()

    return () => {
      toggle(false)
    }
    // eslint-disable-next-line
  }, [])

  const [filterObject, setFilterObject] = useState(initialState)
  const [selectedFields, setSelectedFields] = useState({})

  const [tableData, setTableData] = useState([])
  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 20

  const [selectedRequest, setSelectedRequest] = useState(null)
  const [modal, setModal] = useState(false)

  const formRef = useRef()
  const dispatch = useDispatch()

  // Providers, Student, Course, Status
  const { scholarshipStatus } = useSelector(state => state.Options)

  useEffect(() => {
    dispatch(getScholarshipStatus())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleScholarshipOptions()
    handleTableData()
    setPage(1)
    // eslint-disable-next-line
  }, [filterObject])

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }
  const [scholarships, setScholarships] = useState([])

  const handleScholarshipOptions = async () => {
    try {
      const query = queryString.stringify(filterObject)
      const response = await get(`scholarship/options/scholarships?${query}`)
      setScholarships(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleTableData = async (perPage = page) => {
    try {
      const query = `page=${perPage}&limit=${limit}&${queryString.stringify(
        filterObject
      )}`
      const response = await get(`scholarship/request/list?${query}`)

      const result = response.data
      const count = response.count
      result.map((item, index) => {
        item.id = index + 1 + (perPage - 1) * limit
        // item.name = item.fundSource

        const statusData = item.status
        item.status = (
          <div>
            <span
              className="badge font-size-12 ms-2"
              style={{ backgroundColor: statusData.color }}
            >
              {statusData.name}
            </span>
          </div>
        )

        item.date = moment(item.date).format("DD-MM-YYYY")
        item.courseAmount = item.courseAmount?.toLocaleString("en-IN") || 0
        item.approvedAmount = item.approvedAmount?.toLocaleString("en-IN")
        if (options.edit) {
          item.options = (
            <div>
              <i
                className="fas fa-eye"
                onClick={() => {
                  setModal(true)
                  setSelectedRequest(item._id)
                }}
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
              ></i>
            </div>
          )
        }

        return item
      })

      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(result)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSelectChange = (selected, name) => {
    const obj = { ...selectedFields }
    obj[name] = selected
    setSelectedFields(obj)

    const value = selected
      ? Array.isArray(selected)
        ? selected.map(item => item.value)
        : selected.value
      : null
    handleValueChange(value, name)
  }

  const handleValueChange = async (value, name) => {
    const obj = { ...filterObject }
    obj[name] = value
    setFilterObject(obj)
  }

  const handleChange = (event, value) => {
    setPage(value)
    handleTableData(value)
  }
  const reset = () => {
    formRef.current.reset()
    setFilterObject(initialState)
    setPage(1)
    setSelectedFields({})
    toTop()
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Req. ID", field: "uniqueId" },
      { label: "Scholarship ", field: "name" },
      { label: "Student", field: "student" },
      { label: "Register No", field: "studentUniqueId" },
      { label: "Course", field: "course" },
      { label: "Course Fee", field: "courseAmount" },
      { label: "Approved Amount", field: "approvedAmount" },
      { label: "Status", field: "status" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Scholarship Requests" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <AvForm ref={formRef}>
                    <Row>
                      <Col md={2}>
                        <div className="mb-3">
                          <Label>From </Label>
                          <AvField
                            name="from"
                            className="form-control"
                            type="date"
                            value={filterObject.from || ""}
                            onChange={e => {
                              handleValueChange(e.target.value, "from")
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="mb-3">
                          <Label>To </Label>
                          <AvField
                            name="to"
                            className="form-control"
                            type="date"
                            value={filterObject.to || ""}
                            onChange={e => {
                              handleValueChange(e.target.value, "to")
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <Label>Scholarship</Label>
                        <Select
                          name="scholarship"
                          placeholder="Select..."
                          options={scholarships}
                          value={selectedFields.scholarship || ""}
                          onChange={selected => {
                            handleSelectChange(selected, "scholarship")
                          }}
                        />
                      </Col>
                      <Col md={3}>
                        <Label>Student</Label>
                        <SearchStudent
                          api={`/scholarship/options/students`}
                          onChange={selected => {
                            handleSelectChange(selected, "student")
                          }}
                          value={selectedFields.student}
                          master={filterObject}
                        />
                      </Col>
                      <Col md={2}>
                        <Label>Status</Label>
                        <Select
                          name="status"
                          placeholder="Select..."
                          options={scholarshipStatus}
                          value={selectedFields.status || ""}
                          onChange={selected => {
                            handleSelectChange(selected, "status")
                          }}
                        />
                      </Col>

                      <Col>
                        <Button
                          style={{ marginTop: "26px" }}
                          color="danger"
                          onClick={reset}
                        >
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                  <MDBDataTable
                    id="scholarshipRequest"
                    className="mt-3"
                    bordered
                    responsive
                    searching={false}
                    entries={20}
                    paging={false}
                    info={false}
                    disableRetreatAfterSorting={true}
                    data={data}
                    sortable={false}
                  ></MDBDataTable>
                  <TablePagination
                    page={page}
                    onChange={handleChange}
                    count={totalPage}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Modal show={modal} size="xl" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Scholarship Details</h5>
          <button
            type="button"
            onClick={() => {
              setModal(false)
              handleTableData()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <ScholarshipProcessing id={selectedRequest} />
        </div>
      </Modal>
    </>
  )
}

export default ScholarshipRequests
