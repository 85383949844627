import React, { useEffect, useRef, useState } from "react"
import {  AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import { get } from "../../../helpers/api_helper"
// import toastr from "toastr"
import moment from "moment"
import {  jsonToExcel } from "../../../helpers/functions"
import TablePagination from "../../../components/Common/Pagination"
import { useTransition } from "react"
import toastr from "toastr"
import './style.css'
import useBranch from "../../../hooks/useBranch"

const ReportStudent = () => {
  const myCsp = useBranch()
  const [myCspId, setMyCspId] = useState("")
  const [tableDatas,setTableDatas] = useState([])
  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 20
  const filterRef = useRef()
  const [courses,setCourses] = useState([])
  const [batches,setBatches] = useState([])
  const [branches,setBranches] = useState([])
  const [selectedCourse,setSelectedCourse] = useState(null)
  const [selectedBatch,setSelectedBatch] = useState(null)
  const [selectedBranch,setSelectedBranch] = useState(null)
  const [exports, setExports] = useState([])
  
  useEffect(() => {
    let cspId = myCsp ? myCsp?.[0]?.value : ""
    if(cspId != ''){
       setMyCspId(cspId)
       setSelectedBranch( myCsp?.[0])
      }
  },[])

  useEffect(()=>{
    const fetch = async()=>{
      try {
        const res = await get(`report/options/student/course?branch=${selectedBranch?.value}`)
        setCourses(res.data)
      } catch (error) {
        
      }
    }
    if(selectedBranch?.value){
      fetch()
    }
  },[selectedBranch])

  useEffect(()=>{
    const fetch = async()=>{
      try {
        const res = await get(`report/options/student/batch?branch=${selectedBranch?.value}&course=${selectedCourse?.value}`)
        setBatches(res.data)
      } catch (error) {
        
      }
    }
    if(selectedBranch?.value && selectedCourse?.value) fetch()
  },[selectedCourse])

  useEffect(()=>{
    const fetch = async()=>{
      try {
        const res = await get('/report/options/student/branch')
        setBranches(res?.data)
      } catch (error) {
        
      }
    }
    fetch()
  },[])

  useEffect(()=>{
    fetchTableData()
  },[page,selectedBatch,selectedBranch,selectedCourse])

  const fetchTableData = async()=>{
    try {
      const res = await get(`/report/student?page=${page}&limit=${limit}&batch=${selectedBatch?.value}&branch=${selectedBranch?.value}&course=${selectedCourse?.value}`)
      let count = res.count
      let data = res.data;
      data = data.map((item, index) => {
        item.id = index + 1 + (page - 1) * limit
        return item; 
      });
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableDatas(data)

      const exportsWithoutId = data.map(({ _id,id, ...rest }) => rest);
      setExports(exportsWithoutId);      
    } catch (error) {
      
    }
  }

  const datas = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Registration No.",
        field: "regId",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        width: 150,
      },
      {
        label: "CSP",
        field: "branch",
        width: 150,
      },
      {
        label: "Course",
        field: "course",
        width: 150,
      },
      {
        label: "Batch",
        field: "batch",
        width: 150,
      },
      {
        label: "District",
        field: "district",
        width: 150,
      },
      
     
      
     
    
    ],
    rows: tableDatas,
  }


  const [isExportPending, startExport] = useTransition()


  const handleExport = () => {
    if (exports.length > 0) {
      jsonToExcel(exports, `fee-reports-${moment().format("DD-MM-YY")}`)
    } else {
      toastr.info(`There are fee reports to export`)
    }
  }



  const handleChange = (event, value) => {
    setPage(value)
  }


  const reset = () => {
    filterRef.current.reset()
    setSelectedBatch(null)
    setSelectedBranch(null)
    setSelectedCourse(null)
    setCourses([])
    setBatches([])
  }



  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Student Report" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <AvForm ref={filterRef}>
                    <Row>
                    {!myCspId && 
                      <Col md="2">
                        <div className="mb-3">
                          <Label>CSP</Label>
                          <Select
                            onChange={selected => {
                              setSelectedBranch(selected)
                            }}
                            value={selectedBranch}
                            isClearable={true}
                            options={branches}
                          />
                        </div>
                      </Col>
                     }
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Course</Label>
                          <Select
                            onChange={selected => {
                             setSelectedCourse(selected)
                            }}
                            value={selectedCourse}
                            isClearable={true}
                            options={courses}
                          />
                        </div>
                      </Col>
                    <Col md="2">
                        <div className="mb-3">
                          <Label>Batch</Label>
                          <Select
                            onChange={selected => {
                              setSelectedBatch(selected)
                            }}
                            value={selectedBatch}
                            isClearable={true}
                            options={batches}
                          />
                        </div>
                      </Col>

                      <Col className="mt-4 pt-1">
                        <div className="mb-3">
                          <Button color="danger" type="button" onClick={reset}>
                            Reset
                          </Button>

                          <Button
                            style={{ marginLeft: "14px" }}
                            color="warning"
                            type="button"
                            disabled={isExportPending}
                            onClick={handleExport}
                          >
                            Export{" "}
                            {isExportPending && (
                              <i className="mdi mdi-spin mdi-loading"></i>
                            )}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                  <MDBDataTable
                    id="ReportStudentTable"
                    bordered
                    responsive
                    searching={false}
                    displayEntries={false}
                    paging={false}
                    disableRetreatAfterSorting={true}
                    data={datas}
                    sortable={false}
                  ></MDBDataTable>
                  <TablePagination
                    page={page}
                    onChange={handleChange}
                    count={totalPage}
                  />
                  {/* <MuiCustomTablePagination
                    count={count}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  /> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default ReportStudent
