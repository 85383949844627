import * as url from "./actionTypes"

const initialState = {
  message: null,
  loading: false,
  privileges: [],
  modules: [],
  users: [],
  states: [],
  countries: [],
  distrcts: [],
  districts: [], // Duplicate of distrcts
  companies: [],
  branches: [],
  categories: [],
  accountType: [],
  accountSubType: [],
  chartOfAccount: [],
  operatingPartners: [],
  trainingPartners: [],
  certificationPartners: [],
  sectors: [],
  qualification: [],
  degree: [],
  stream: [],
  courses: [],
  students: [],
  skillLoans: [],
  skillLoanStatus: [],
  leadSource: [],
  Campaign: [],
  scholarships: [],
  scholarshipStatus: [],
  minorityStatus: [],
  studentCategory: [],
  tempBatch: [],
  cluster: [],
  expenseCategory: [],
  classRoomsLabs: [],
  events: [],
}

const Options = (state = initialState, action) => {
  switch (action.type) {
    case url.GET_PRIVILEGES:
      state = {
        ...state,
        loading: true,
      }
      break
    case url.GET_PRIVILEGES_SUCCESS:
      state = {
        ...state,
        loading: false,
        privileges: action.payload,
      }
      break
    case url.GET_MODULES:
      state = {
        ...state,
        loading: true,
      }
      break
    case url.GET_MODULES_SUCCESS:
      state = {
        ...state,
        loading: false,
        modules: action.payload,
      }
      break
    case url.GET_USERS:
      state = {
        ...state,
        loading: true,
      }
      break
    case url.GET_USERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        users: action.payload,
      }
      break
    case url.GET_STATES:
      state = {
        ...state,
        loading: true,
      }
      break
    case url.GET_STATES_SUCCESS:
      state = {
        ...state,
        loading: false,
        states: action.payload,
      }
      break
    case url.GET_COUNTRIES:
      state = {
        ...state,
        loading: true,
      }
      break
    case url.GET_COUNTRIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        countries: action.payload,
      }
      break
    case url.GET_DISTRICTS:
      state = {
        ...state,
        loading: true,
      }
      break
    case url.GET_DISTRICTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        distrcts: action.payload,
        districts: action.payload,
      }
      break
    case url.GET_COMPANIES:
      state = {
        ...state,
        loading: true,
      }
      break
    case url.GET_COMPANIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        companies: action.payload,
      }
      break
    case url.GET_BRANCHES:
      state = {
        ...state,
        loading: true,
      }
      break
    case url.GET_BRANCHES_SUCCESS:
      state = {
        ...state,
        loading: false,
        branches: action.payload,
      }
      break
    case url.GET_CATEGORY:
      state = {
        ...state,
        loading: true,
      }
      break
    case url.GET_CATEGORY_SUCCESS:
      state = {
        ...state,
        loading: false,
        categories: action.payload,
      }
      break
    case url.GET_STUDENT_CATEGORY:
      state = {
        ...state,
        loading: true,
      }
      break
    case url.GET_STUDENT_CATEGORY_SUCCESS:
      state = {
        ...state,
        loading: false,
        studentCategory: action.payload,
      }
      break
    case url.GET_ACCOUNT_TYPE:
      state = {
        ...state,
        loading: true,
      }
      break
    case url.GET_ACCOUNT_TYPE_SUCCESS:
      state = {
        ...state,
        loading: false,
        accountType: action.payload,
      }
      break
    case url.GET_ACCOUNT_SUB_TYPE:
      state = {
        ...state,
        loading: true,
      }
      break
    case url.GET_ACCOUNT_SUB_TYPE_SUCCESS:
      state = {
        ...state,
        loading: false,
        accountSubType: action.payload,
      }
      break
    case url.GET_CHART_ACCOUNT:
      state = {
        ...state,
        loading: true,
      }
      break
    case url.GET_CHART_ACCOUNT_SUCCESS:
      state = {
        ...state,
        loading: false,
        chartOfAccount: action.payload,
      }
      break
    case url.GET_OPERATING_PARTNER:
      state = {
        ...state,
        loading: true,
      }
      break
    case url.GET_OPERATING_PARTNER_SUCCESS:
      state = {
        ...state,
        loading: false,
        operatingPartners: action.payload,
      }
      break
    case url.GET_TRAINING_PARKING:
      state = {
        ...state,
        loading: true,
      }
      break
    case url.GET_TRAINING_PARKING_SUCCESS:
      state = {
        ...state,
        loading: false,
        trainingPartners: action.payload,
      }
      break
    case url.GET_CERTIFICATION_PARTNER:
      state = {
        ...state,
        loading: true,
      }
      break
    case url.GET_CERTIFICATION_PARTNER_SUCCESS:
      state = {
        ...state,
        loading: false,
        certificationPartners: action.payload,
      }
      break
    case url.GET_SECTOR:
      state = {
        ...state,
        loading: false,
      }
      break
    case url.GET_SECTOR_SUCCESS:
      state = {
        ...state,
        loading: false,
        sectors: action.payload,
      }
      break
    case url.GET_QUALIFICATION:
      state = {
        ...state,
        loading: false,
      }
      break
    case url.GET_QUALIFICATION_SUCCESS:
      state = {
        ...state,
        loading: false,
        qualification: action.payload,
      }
      break
    case url.GET_STREAM:
      state = {
        ...state,
        loading: false,
      }
      break
    case url.GET_STREAM_SUCCESS:
      state = {
        ...state,
        loading: false,
        stream: action.payload,
      }
      break
    case url.GET_DEGREE:
      state = {
        ...state,
        loading: false,
      }
      break
    case url.GET_DEGREE_SUCCESS:
      state = {
        ...state,
        loading: false,
        degree: action.payload,
      }
      break
    case url.GET_MINORITY_STATUS:
      state = {
        ...state,
        loading: false,
      }
      break
    case url.GET_MINORITY_STATUS_SUCCESS:
      state = {
        ...state,
        loading: false,
        minorityStatus: action.payload,
      }
      break
    case url.GET_COURSES:
      state = {
        ...state,
        loading: false,
      }
      break
    case url.GET_COURSES_SUCCESS:
      state = {
        ...state,
        loading: false,
        courses: action.payload,
      }
      break
    case url.GET_STUDENT:
      state = {
        ...state,
        loading: false,
      }
      break
    case url.GET_STUDENT_SUCCESS:
      state = {
        ...state,
        loading: false,
        students: action.payload,
      }
      break
    case url.GET_SKILL_LOANS:
      state = {
        ...state,
        loading: false,
      }
      break
    case url.GET_SKILL_LOANS_SUCCESS:
      state = {
        ...state,
        loading: false,
        skillLoans: action.payload,
      }
      break
    case url.GET_SKILL_LOAN_STATUS:
      state = {
        ...state,
        loading: false,
      }
      break
    case url.GET_SKILL_LOAN_STATUS_SUCCESS:
      state = {
        ...state,
        loading: false,
        skillLoanStatus: action.payload,
      }
      break
    case url.GET_SCHOLARSHIPS:
      state = {
        ...state,
        loading: false,
      }
      break
    case url.GET_SCHOLARSHIPS_SUCCESS:
      state = {
        ...state,
        loading: false,
        scholarships: action.payload,
      }
      break
    case url.GET_SCHOLARSHIP_STATUS:
      state = {
        ...state,
        loading: false,
      }
      break
    case url.GET_SCHOLARSHIP_STATUS_SUCCESS:
      state = {
        ...state,
        loading: false,
        scholarshipStatus: action.payload,
      }
      break
    case url.GET_LEAD_SOURCE:
      state = {
        ...state,
        loading: false,
      }
      break
    case url.GET_LEAD_SOURCE_SUCCESS:
      state = {
        ...state,
        loading: false,
        leadSource: action.payload,
      }
      break
    case url.GET_CAMPAIGN:
      state = {
        ...state,
        loading: false,
      }
      break
    case url.GET_CAMPAIGN_SUCCESS:
      state = {
        ...state,
        loading: false,
        Campaign: action.payload,
      }
      break

    case url.GET_TEMP_BATCH:
      state = {
        ...state,
        loading: false,
      }
      break
    case url.GET_TEMP_BATCH_SUCCESS:
      state = {
        ...state,
        loading: false,
        tempBatch: action.payload,
      }
      break
    case url.GET_CLUSTER:
      state = {
        ...state,
        loading: false,
      }
      break
    case url.GET_CLUSTER_SUCCESS:
      state = {
        ...state,
        loading: false,
        cluster: action.payload,
      }
      break
    case url.GET_EXPENSE_CATEGORY:
      state = {
        ...state,
        loading: false,
      }
      break
    case url.GET_EXPENSE_CATEGORY_SUCCESS:
      state = {
        ...state,
        loading: false,
        expenseCategory: action.payload,
      }
      break
    case url.GET_CLASSROOMS_LABS:
      state = {
        ...state,
        loading: false,
      }
      break
    case url.GET_CLASSROOMS_LABS_SUCCESS:
      state = {
        ...state,
        loading: false,
        classRoomsLabs: action.payload,
      }
      break
    case url.GET_EVENTS:
      state = {
        ...state,
        loading: false,
      }
      break
    case url.GET_EVENTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        events: action.payload,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default Options
