import React, { useEffect, useState } from "react"
import MiniWidget from "../../pages/Dashboard/Marketing/mini-widget"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Col, Container, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import "./LeadsDashboard.scss"
import Select from "react-select"
import { isArray } from "lodash"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { getCourses, getEvents } from "../../store/options/actions"
import { get } from "../../helpers/api_helper"
import FinancialYear from "../../components/Common/FinancialYear.jsx"
import {
  getCurrentFinancialMonthSelect,
  getCurrentFinancialQuarterSelect,
  getCurrentFinancialYearSelect,
} from "../../helpers/functions.js"
import FinancialQuarter from "../../components/Common/FinancialQuarter.jsx"
import FinancialMonth from "../../components/Common/FinancialMonth.jsx"
function LeadsDashboard() {
  const getCurrentFinancialYear = getCurrentFinancialYearSelect()
  const getCurrentFinancialQuarter = getCurrentFinancialQuarterSelect()
  const getCurrentFinancialMonth = getCurrentFinancialMonthSelect()
  const series1 = [50]

  const dispatch = useDispatch()

  const [counts, setCounts] = useState({
    leadToday: 0,
    leadTotal: 0,
    todayFollowup: 0,
    totalFollowup: 0,
    pendingFollowup: 0,
    upcomingFollowup: 0,
    unassignedLead: 0,
    leadCoversion: 0,
  })

  const [master, setMaster] = useState({})

  const [masterObject, setMasterObject] = useState({
    type: 1,
    year: getCurrentFinancialYear.value,
    quarter: getCurrentFinancialQuarter.value,
    month: getCurrentFinancialMonth.value,
    startDate: getCurrentFinancialYear.from,
    endDate: getCurrentFinancialYear.to,
  })

  const [selectedFields, setSelectedFields] = useState({
    type: { label: "Year", value: 1 },
    year: getCurrentFinancialYear,
    quarter: getCurrentFinancialQuarter,
    month: getCurrentFinancialMonth,
  })

  const typeOptions = [
    { label: "Year", value: 1 },
    { label: "Quarterly", value: 2 },
    { label: "Monthly", value: 3 },
    { label: "Custom", value: 4 },
  ]

  const [selected, setSelected] = useState({})

  const handleSelectValueChange = async (selected, name) => {
    const obj = { ...selectedFields }
    obj[name] = selected
    setSelectedFields(obj)
    handleValueChange(selected, name)
  }

  const handleValueChange = async (selected, name) => {
    let obj = { ...masterObject }
    const value = selected.value
    obj[name] = value

    const isTypeChange = name === "type"
    const isYearChange = name === "year"
    const isQuarterChange = name === "quarter"
    const isMonthChange = name === "month"

    const isSelectChange = isYearChange || isQuarterChange || isMonthChange
    if (isSelectChange) obj = handleDateChange(obj, selected)

    if (isTypeChange && value === 1) handleDateChange(obj, selectedFields.year)
    if (isTypeChange && value === 3) handleDateChange(obj, selectedFields.month)
    if (isTypeChange && value === 2)
      handleDateChange(obj, selectedFields.quarter)

    setMasterObject(obj)
  }
  const handleDateChange = (obj, selected) => {
    obj.startDate = selected.from
    obj.endDate = selected.to
    return obj
  }
  const options1 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series2 = [70]

  const options2 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series3 = [55]

  const options3 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series4 = [33]

  const options4 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const reports = [
    {
      id: 6,
      // icon: "mdi mdi-arrow-up-bold",
      title: "Unique Users",
      value: counts?.uniqueStudents,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      // suffix: "%",
      // badgeValue: "0",
      // color: "success",
      // desc: "Total",
      series: series4,
      options: options4,
      for: "student-details",
      from: masterObject?.startDate,
      to: masterObject?.endDate,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          style={{ width: "2.5rem", height: "2.5rem" }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
          />
        </svg>
      ),
      filterData: {
        from_date: masterObject?.startDate,
        to_date: masterObject?.endDate,
        ...selected,
      },
    },
    {
      id: 1,
      // icon: "mdi mdi-arrow-up-bold",
      title: "Total Leads",
      value: counts?.leadTotal,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      // badgeValue: counts?.leadTotal,
      // color: "success",
      // desc: "Total Leads",
      series: series1,
      options: options1,
      for: "leads",
      from: masterObject?.startDate,
      to: masterObject?.endDate,
      type: "full-leads",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          style={{ width: "2.5rem", height: "2.5rem" }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
          />
        </svg>
      ),
      filterData: {
        from_date: masterObject?.startDate,
        to_date: masterObject?.endDate,
        ...selected,
      },
    },
    {
      id: 6,
      // icon: "mdi mdi-arrow-up-bold",
      title: "Conversion Rate",
      value: counts?.leadCoversion,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "%",
      // badgeValue: "0",
      // color: "success",
      // desc: "Total",
      series: series4,
      options: options4,
      for: "leads",
      type: "conversion",
      from: masterObject?.startDate,
      to: masterObject?.endDate,
      badgeValue: counts?.leadCoversionCount,
      color: "success",
      desc: "Leads",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          style={{ width: "2.5rem", height: "2.5rem" }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
          />
        </svg>
      ),
      filterData: {
        from_date: masterObject?.startDate,
        to_date: masterObject?.endDate,
        ...selected,
      },
    },
    {
      id: 1,
      // icon: "mdi mdi-arrow-up-bold",
      title: "New Leads",
      value: counts?.leadToday,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      // badgeValue: counts?.leadTotal,
      // color: "success",
      // desc: "Total Leads",
      series: series1,
      options: options1,
      for: "leads",
      // badgeNavigate: true,
      from: masterObject?.startDate,
      to: masterObject?.endDate,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          style={{ width: "2.5rem", height: "2.5rem" }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
          />
        </svg>
      ),
      filterData: {
        from_date: masterObject?.startDate,
        to_date: masterObject?.endDate,
        ...selected,
      },
    },
    {
      id: 2,
      title: "Today's Follow-ups",
      value: counts?.todayFollowup,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue: counts?.totalFollowup,
      color: "success",
      desc: "Total Follow-ups",
      series: series2,
      options: options2,
      for: "follow-up",
      type: "today",
      from: masterObject?.startDate,
      to: masterObject?.endDate,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          style={{ width: "2.5rem", height: "2.5rem" }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 0 1-.923 1.785A5.969 5.969 0 0 0 6 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337Z"
          />
        </svg>
      ),
      filterData: {
        from_date: masterObject?.startDate,
        to_date: masterObject?.endDate,
        ...selected,
      },
    },
    {
      id: 3,
      // icon: "mdi mdi-arrow-down-bold",
      title: "Pending Follow-ups",
      value: counts?.pendingFollowup,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      // badgeValue: "2.9%",
      // color: "danger",
      // desc: "Total",
      series: series3,
      options: options3,
      for: "follow-up",
      type: "pending",
      filterData: {
        from_date: masterObject?.startDate,
        to_date: masterObject?.endDate,
        ...selected,
      },
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          id="pending"
          strokeWidth={0}
          stroke="currentColor"
          style={{ width: "2.5rem", height: "2.5rem" }}
        >
          <g>
            <path d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"></path>
            <path d="M12,6a1,1,0,0,0-1,1v4.59L8.29,14.29a1,1,0,1,0,1.41,1.41l3-3A1,1,0,0,0,13,12V7A1,1,0,0,0,12,6Z"></path>
          </g>
        </svg>
      ),
    },
    {
      id: 4,
      // icon: "mdi mdi-arrow-up-bold",
      title: "Upcoming Follow-ups",
      value: counts?.upcomingFollowup,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      // badgeValue: "0",
      // color: "success",
      // desc: "Total",
      series: series4,
      options: options4,
      for: "follow-up",
      type: "upcoming",
      from: masterObject?.startDate,
      to: masterObject?.endDate,
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          strokeWidth={0.1}
          stroke="currentColor"
          style={{ width: "2.5rem", height: "2.5rem" }}
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <path
              d="M11.7249 16.5461L16.2249 13.9481L11.7249 11.35V12.9481H7.7749V14.9481H11.7249V16.5461Z"
              fill="#000000"
            ></path>
            <path
              d="M8 7C7.44772 7 7 7.44772 7 8C7 8.55228 7.44772 9 8 9H16C16.5523 9 17 8.55228 17 8C17 7.44772 16.5523 7 16 7H8Z"
              fill="#000000"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6 3C4.34315 3 3 4.34315 3 6V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V6C21 4.34315 19.6569 3 18 3H6ZM18 5H6C5.44772 5 5 5.44772 5 6V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V6C19 5.44772 18.5523 5 18 5Z"
              fill="#000000"
            ></path>
          </g>
        </svg>
      ),
      filterData: {
        from_date: masterObject?.startDate,
        to_date: masterObject?.endDate,
        ...selected,
      },
    },
    {
      id: 5,
      // icon: "mdi mdi-arrow-up-bold",
      title: "Unattended Leads",
      value: counts?.unassignedLead,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      // badgeValue: "0",
      // color: "success",
      // desc: "Total",
      series: series4,
      options: options4,
      for: "leads",
      type: "unassigned",
      from: masterObject?.startDate,
      to: masterObject?.endDate,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          style={{ width: "2.5rem", height: "2.5rem" }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z"
          />
        </svg>
      ),
      filterData: {
        from_date: masterObject?.startDate,
        to_date: masterObject?.endDate,
        ...selected,
      },
    },
    {
      id: 6,
      // icon: "mdi mdi-arrow-up-bold",
      title: "Fully Paid",
      value: counts?.paidDetails?.fullyPaid,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      // suffix: "%",
      // badgeValue: "0",
      // color: "success",
      // desc: "Total",
      series: series4,
      options: options4,
      for: "leads",
      type: "full-paid",
      from: masterObject?.startDate,
      to: masterObject?.endDate,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          style={{ width: "2.5rem", height: "2.5rem" }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 8.25H9m6 3H9m3 6-3-3h1.5a3 3 0 1 0 0-6M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
        </svg>
      ),
      filterData: {
        from_date: masterObject?.startDate,
        to_date: masterObject?.endDate,
        ...selected,
      },
    },
    {
      id: 6,
      // icon: "mdi mdi-arrow-up-bold",
      title: "Partially Paid",
      value: counts?.paidDetails?.partiallyPaid,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      // suffix: "%",
      // badgeValue: "0",
      // color: "success",
      // desc: "Total",
      series: series4,
      options: options4,
      for: "leads",
      type: "partially-paid",
      from: masterObject?.startDate,
      to: masterObject?.endDate,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          style={{ width: "2.5rem", height: "2.5rem" }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 8.25H9m6 3H9m3 6-3-3h1.5a3 3 0 1 0 0-6M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
        </svg>
      ),
      filterData: {
        from_date: masterObject?.startDate,
        to_date: masterObject?.endDate,
        ...selected,
      },
    },
    {
      id: 6,
      // icon: "mdi mdi-arrow-up-bold",
      title: "Payment Confirm",
      value: counts?.paymentConfirm,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      // suffix: "%",
      // badgeValue: "0",
      // color: "success",
      // desc: "Total",
      series: series4,
      options: options4,
      for: "leads",
      type: "payment-confirm",
      from: masterObject?.startDate,
      to: masterObject?.endDate,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          style={{ width: "2.5rem", height: "2.5rem" }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 8.25H9m6 3H9m3 6-3-3h1.5a3 3 0 1 0 0-6M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
        </svg>
      ),
      filterData: {
        from_date: masterObject?.startDate,
        to_date: masterObject?.endDate,
        ...selected,
      },
    },
  ]

  const handleSelect = (e, name) => {
    const value = e ? (isArray(e) ? e.map(item => item.value) : e.value) : null
    setSelected(pre => ({ ...pre, [name]: e }))
    setMasterObject(pre => ({ ...pre, [name]: value }))
  }
  const handleChange = e => {
    let { name, value } = e.target
    setMaster(pre => ({ ...pre, [name]: value }))
  }
  const OPTIONS = useSelector(state => state.Options)
  useEffect(() => {
    dispatch(getCourses())
    dispatch(getEvents())
  }, [])
  useEffect(() => {
    get(
      `leads/dashboard?from_date=${masterObject?.startDate}&to_date=${masterObject?.endDate}&event=${masterObject?.event}&course=${masterObject?.course}`
    ).then(res => {
      setCounts(res.data)
    })
  }, [masterObject, selected, selectedFields])

  function reset() {
    setMasterObject({
      type: 1,
      year: getCurrentFinancialYear.value,
      quarter: getCurrentFinancialQuarter.value,
      month: getCurrentFinancialMonth.value,
      startDate: getCurrentFinancialYear.from,
      endDate: getCurrentFinancialYear.to,
    })
    setSelectedFields({
      type: { label: "Year", value: 1 },
      year: getCurrentFinancialYear,
      quarter: getCurrentFinancialQuarter,
      month: getCurrentFinancialMonth,
    })
    setSelected({})
  }

  return (
    <div>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Dashboard" />
          <AvForm>
            <Row id="dashboard-head ">
              <Col md={masterObject.type == 2 ? 4 : 5}>
                <Row className="dashboard-filter">
                  <Col md="6">
                    <Select
                      name="batch"
                      options={OPTIONS.courses}
                      onChange={sl => handleSelect(sl, "course")}
                      value={selected.course || null}
                      width={50}
                      placeholder="Course"
                    />
                  </Col>

                  <Col md="6">
                    <Select
                      name="student"
                      options={OPTIONS.events}
                      onChange={sl => handleSelect(sl, "event")}
                      value={selected.event || null}
                      placeholder="Event"
                    />
                  </Col>
                </Row>
              </Col>

              <Col md={masterObject.type === 2 ? 4 : 3}>
                <Row className="dashboard-filter justify-content-end">
                  <Col md={masterObject.type === 2 ? 5 : 6}>
                    <Select
                      options={typeOptions}
                      value={selectedFields.type}
                      onChange={selected =>
                        handleSelectValueChange(selected, "type")
                      }
                    ></Select>
                  </Col>
                  {masterObject.type === 1 ? (
                    <Col md={masterObject.type === 2 ? 7 : 6}>
                      <FinancialYear
                        onChange={selected =>
                          handleSelectValueChange(selected, "year")
                        }
                        value={selectedFields.year}
                      />
                    </Col>
                  ) : masterObject.type === 2 ? (
                    <Col md={6}>
                      <FinancialQuarter
                        onChange={selected =>
                          handleSelectValueChange(selected, "quarter")
                        }
                        value={selectedFields.quarter}
                      />
                    </Col>
                  ) : masterObject.type === 3 ? (
                    <Col md={6}>
                      <FinancialMonth
                        onChange={selected =>
                          handleSelectValueChange(selected, "month")
                        }
                        value={selectedFields.month}
                      />
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
              </Col>
              <Col md={4}>
                <Row>
                  <Col md={5}>
                    <AvField
                      name="startDate"
                      type="date"
                      value={masterObject.startDate || ""}
                      onChange={e => handleValueChange(e.target, e.target.name)}
                      disabled={masterObject.type !== 4}
                    >
                      {" "}
                    </AvField>
                  </Col>
                  <Col md={5}>
                    <AvField
                      name="endDate"
                      type="date"
                      value={masterObject.endDate || ""}
                      onChange={e => handleValueChange(e.target, e.target.name)}
                      disabled={masterObject.type !== 4}
                      min={masterObject.startDate}
                    ></AvField>
                  </Col>
                  <Col md="2">
                    <div>
                      <button
                        onClick={reset}
                        title="reset"
                        style={{
                          border: "none",
                          color: "green",
                          fontSize: "20px",
                          background: "unset",
                        }}
                        className="fas fa-undo mt-1"
                      ></button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </AvForm>
          <Row className="mt-4">
            <MiniWidget reports={reports} />
          </Row>

          {/* <Row>
            <Col md="12" lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Lead @ Asap</CardTitle>

                  <Chartapex />
                </CardBody>
              </Card>
            </Col>
            <Col md="4" lg="4">
              <CardTitle className=""></CardTitle>
              <TopProduct />
            </Col>
          </Row> */}
        </Container>
      </div>
    </div>
  )
}

export default LeadsDashboard
