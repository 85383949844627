import React, { useEffect, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"

import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap"

import moment from "moment"

import toastr from "toastr"
import { get } from "../../helpers/api_helper"
import { API_URL } from "../../config"
const JobModal = ({ data }) => {

  useEffect(() => {
    if(data){

      handleTableData()
    }
  }, [data])
  const [modal, setModal] = useState({})
  function handleTableData() {
    get(`job/detail?id=${data}`).then(res => {

      let result = res?.data
     console.log(result,"results");
      setModal(result)
    })
  }
  return (
    <div>
      {" "}
      <div className="modal-body" style={{ paddingTop: "0px" }}>
        <Row>
          <Col md="12">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tbody>
                <tr>
                  <th style={{ textAlign: "start" }}>Applied Date</th>
                  <th> : </th>
                  <td style={{ textAlign: "start" }}>{moment(modal?.date).format("DD-MM-YYYY")} </td>
                  <th style={{ padding: "", textAlign: "start" }}>Job</th>
                  <th> : </th>
                  <td style={{ textAlign: "start" }}>{modal?.job?.name} </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "start" }}>Recruiter</th>
                  <th> : </th>
                  <td style={{ textAlign: "start" }}>
                    {modal?.recruiter?.name}
                  </td>
                  <th style={{ textAlign: "start" }}>Job ID</th>
                  <th> : </th>
                  <td style={{ textAlign: "start" }}> {modal?.job?.uniqueId}</td>
                </tr>
                <tr>
                  <th style={{ textAlign: "start" }}>Application ID</th>
                  <th> : </th>
                  <td style={{ textAlign: "start" }}>
                    {" "}
                    {modal?.uniqueId}
                  </td>
                  <th style={{ textAlign: "start" }}>Mobile</th>
                  <th> : </th>
                  <td style={{ textAlign: "start" }}>
                    {modal?.applicant?.mobile}
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "start" }}>Email</th>
                  <th> : </th>
                  <td style={{ textAlign: "start" }}>
                    {modal?.applicant?.email}
                  </td>
                  <th style={{ textAlign: "start" }}>Qualification</th>
                  <th> : </th>
                  <td style={{ textAlign: "start" }}>
                
                    {modal?.applicant?.qualification
                      ?.map(el => el.name)
                      .join(", ")}
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "start" }}>Uploaded Resume</th>
                  <th> : </th>
                  <td style={{ textAlign: "start" }}>
                    {" "}
                    {modal?.applicant?.uploadedResume && (
                      <a
                        href={API_URL + modal?.applicant?.uploadedResume}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i
                          className="fas fa-file-pdf"
                          style={{
                            fontSize: "1.2em",
                            cursor: "pointer",
                            color: "red",
                          }}
                        ></i>
                      </a>
                    )}
                  </td>
                </tr>
                {modal?.status==4||modal.status==5?(
                  <tr>
                  <th style={{ textAlign: "start" }}>
                   Remarks
                  </th>
                  <th> : </th>
                  <td style={{ textAlign: "start" }}>{modal?.reject?.remarks}</td>
                </tr>
                ):""}
                
              </tbody>
            </table>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default JobModal
