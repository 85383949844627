import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Badge,
  Label,
  Collapse,
} from "reactstrap"

import { AvForm } from "availity-reactstrap-validation"
import { useBatch } from "../BatchContext"
import { get, post } from "../../../../../helpers/api_helper"
import moment from "moment"
import AsyncSelect from "react-select/async"
import toastr from "toastr"

import "./Student.scss"
import Allowed from "../../../../../components/Common/Allowed"
import { PRIVILEGES } from "../../../../../config"
import { Link } from "react-router-dom"

const Students = props => {
  // eslint-disable-next-line

  const [student, setStudent] = useState(null)

  const [rows, setRows] = useState([])

  const [assignedRows, setAssignedRows] = useState([])

  const { next, back, details = false, ...context } = useBatch()

  const [unAssignedStudentsShow, setUnAssignedStudentsShow] = useState(true)

  const [assignedStudentsShow, setAssignedStudentsShow] = useState(true)

  useEffect(() => {
    if (context.master.id) {
      getUnAssignedStudents(context.master.id)
      getAssignedStudents(context.master.id)
    }
    // eslint-disable-next-line
  }, [context.master.id])

  const getUnAssignedStudents = id => {
    get(`batch/unassigned-students/${id}`)
      .then(res => {
        const data = res.data
        setRows(data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const getAssignedStudents = id => {
    get(`batch/assigned-students/${context.master.id}`)
      .then(res => {
        const data = res.students
        data.map((item, idx) => {
          item.id = idx + 1
          return item
        })

        setAssignedRows(data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleBatchAssign = (batchId, leadId, idx = false) =>
    post(`batch/student/assign`, { batchId, leadId })
      .then(res => {
        getAssignedStudents(context.master.id)
        if (idx !== false) {
          const data = [...rows]
          data.splice(idx, 1)
          setRows(data)
        }
        toastr.success(res?.message)
      })
      .catch(err => {
        console.log(err?.response?.data)
        toastr.error("Something went wrong")
      })

  const handleUnassignStudent = (batchId, leadId, idx) => {
    post(`batch/student/unassign`, { batchId, leadId })
      .then(res => {
        getUnAssignedStudents(context.master.id)
        const data = [...assignedRows]
        data.splice(idx, 1)
        setAssignedRows(data)
        toastr.success(res?.message)
      })
      .catch(err => {
        console.log(err?.response?.data)
        toastr.error("Something went wrong")
      })
  }

  // const [studentOptions, setStudentOptions] = useState([])

  const loadOptions = async (value, callback) => {
    if (value.length >= 2) {
      const response = await get(
        `batch/search/unassigned-students/${context?.master?.course}?search=${value}`
      ).then(res => res)
      const data = response.data
      callback(data)
    } else {
      callback([])
    }
  }

  const handleAssign = () => {
    handleBatchAssign(context.master.id, student.leadId).then(() => {
      setStudent(null)
    })
  }

  return (
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody>
            <Link
              to="#"
              onClick={() => setUnAssignedStudentsShow(pre => !pre)}
              className="text-dark"
            >
              <div>
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 overflow-hidden">
                    <h5 className="font-size-16 mb-1">Unassigned Students</h5>
                  </div>
                  <div className="flex-shrink-0">
                    <i className="mdi mdi-chevron-up accor-down-icon font-size-24"></i>
                  </div>
                </div>
              </div>
            </Link>

            <Collapse className="mb-3 mt-3" isOpen={unAssignedStudentsShow}>
              <AvForm onValidSubmit={handleAssign}>
                <Row>
                  <Col md="12">
                    <Row>
                      <Col md="6">
                        <Label>Search Student</Label>
                        <AsyncSelect
                          defaultOptions
                          id="manualPaymentsStudentSearch"
                          value={student}
                          loadOptions={loadOptions}
                          onChange={e => setStudent(e)}
                          noOptionsMessage={() => null}
                          placeholder="Search Student's name, Mobile, UniqueId"
                        />
                      </Col>
                      <Col md="3" style={{ paddingTop: "25px" }}>
                        <Button type="submit" color="primary">
                          Assign
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </AvForm>

              <div className="table-responsive">
                <table
                  id="unAssignedStudents"
                  className="table table-bordered mb-0"
                >
                  <thead>
                    <tr>
                      <Allowed
                        privileges={[
                          PRIVILEGES.ADMIN,
                          PRIVILEGES.BUSINESS_HEAD,
                          PRIVILEGES.CSP_PROGRAM_MANAGER,
                        ]}
                      >
                        <th></th>
                      </Allowed>
                      <th>#</th>
                      <th>Date</th>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Mobile</th>
                      <th>Scholarship</th>
                      <th>Skill Loan</th>
                      <th>Pre Requisites</th>
                      <th>Paid Amount</th>
                      <th>Due</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows?.map((row, idx) => (
                      <tr key={row.id}>
                        <Allowed
                          privileges={[
                            PRIVILEGES.ADMIN,
                            PRIVILEGES.BUSINESS_HEAD,
                            PRIVILEGES.CSP_PROGRAM_MANAGER,
                          ]}
                        >
                          <th className="text-center">
                            <input
                              type="checkbox"
                              name="headerCheckbox"
                              id="headerCheckbox"
                              checked={false}
                              onClick={() =>
                                handleBatchAssign(
                                  row?._id,
                                  row?.student?._id,
                                  idx
                                )
                              }
                              style={{ cursor: "pointer" }}
                            ></input>
                          </th>
                        </Allowed>

                        <td className="text-center">{idx + 1}</td>
                        <td>
                          {row.date && moment(row.date).format("DD-MM-YYYY")}
                        </td>
                        <td>{row?.student?.profile?.unique_id}</td>
                        <td>{`${row?.student?.profile?.firstname || ""} ${
                          row?.student?.profile?.lastname || ""
                        }`}</td>

                        <td>{row?.student?.profile?.mobile} </td>
                        <td>{row?.student?.scholarship?.scholarship}</td>
                        <td>{row?.student?.skillLoan?.skillLoan}</td>
                        <td>
                          <ul
                            style={{
                              listStyle: "none",
                              display: "flex",
                              padding: "0",
                              alignItems: "center",
                              justifyContent: "center",
                              // width: "160px",
                              flexWrap: "wrap",
                            }}
                          >
                            {Object.keys(row?.student?.preRequisites).map(
                              key => (
                                <li key={key} style={{ padding: "3px" }}>
                                  <div style={{ textTransform: "capitalize" }}>
                                    {row?.student?.preRequisites[key] ? (
                                      <Badge
                                        style={{
                                          padding: ".3rem",
                                          fontSize: "11px",
                                        }}
                                        className="bg-success"
                                      >
                                        {key}
                                      </Badge>
                                    ) : (
                                      <Badge
                                        style={{
                                          padding: ".3rem",
                                          fontSize: "11px",
                                        }}
                                        className="bg-danger"
                                      >
                                        {key}
                                      </Badge>
                                    )}
                                  </div>{" "}
                                  <span></span>
                                </li>
                              )
                            )}
                          </ul>
                        </td>
                        <td>
                          {row?.student?.courseFee?.paidAmount
                            ? row?.student?.courseFee?.paidAmount
                            : row?.student?.paylater
                            ? "Paylater"
                            : 0}
                        </td>
                        <td>
                          {row?.student?.courseFee?.payableAmount -
                            row?.student?.courseFee?.paidAmount || "0"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Collapse>
          </CardBody>
        </Card>
        {/* <div
      className="mb-3"
      style={{ textAlign: "end" }}
    >
      <Button color="primary" type="submit">
        Submit
      </Button>
    </div> */}

        <Card>
          <CardBody>
            <Link
              to="#"
              onClick={() => setAssignedStudentsShow(pre => !pre)}
              className="text-dark"
            >
              <div>
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 overflow-hidden">
                    <h5 className="font-size-16 mb-1">Assigned Students</h5>
                  </div>
                  <div className="flex-shrink-0">
                    <i className="mdi mdi-chevron-up accor-down-icon font-size-24"></i>
                  </div>
                </div>
              </div>
            </Link>

            <Collapse className="mb-3 mt-3" isOpen={assignedStudentsShow}>
              <div className="table-responsive">
                <h6>Assigned Students</h6>

                <table className="table table-bordered mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Date</th>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Mobile</th>
                      <th>Scholarship</th>
                      <th>Skill Loan</th>
                      <th>Paid Amount</th>
                    </tr>
                  </thead>
                  {assignedRows?.map((row, idx) => (
                    <tbody key={idx}>
                      <tr>
                        <td>{idx + 1}</td>
                        <td>
                          {row.date && moment(row.date).format("DD-MM-YYYY")}
                        </td>
                        <td>{row?.profile?.unique_id}</td>
                        <td>{`${row?.profile?.firstname || ""} ${
                          row?.profile?.lastname || ""
                        }`}</td>
                        <td>{row?.profile?.mobile}</td>
                        <td>{row?.leadId?.scholarshipId?.name}</td>
                        <td>{row?.leadId?.skillLoanId?.name}</td>
                        <td>
                          {row?.leadId?.courseFee?.paidAmount
                            ? row?.leadId?.courseFee?.paidAmount
                            : row?.leadId?.paylater && "Pay Later"}
                        </td>

                        <Allowed
                          privileges={[
                            PRIVILEGES.ADMIN,
                            PRIVILEGES.BUSINESS_HEAD,
                            PRIVILEGES.CSP_PROGRAM_MANAGER,
                          ]}
                        >
                          <td>
                            <i
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleUnassignStudent(
                                  row?.batch,
                                  row?.leadId?._id,
                                  idx
                                )
                              }
                              className="fas fa-window-close"
                            ></i>
                          </td>
                        </Allowed>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </Collapse>
          </CardBody>
        </Card>
      </Col>
      <Col md="" style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="ms-1">
          {!details && (
            <Button
              type="button"
              onClick={() => context.reset({})}
              className="mr-auto"
              color="danger"
            >
              Reset
            </Button>
          )}
        </div>

        <div>
          {/* <Button
            onClick={() => {
              back()
            }}
            type="button"
            className="me-2"
            color="primary"
          >
            Back
          </Button> */}
          <Button
            onClick={() => {
              next()
            }}
            type="button"
            className="me-2"
            color="primary"
          >
            Next
          </Button>
        </div>
      </Col>
    </Row>
  )
}

export default Students
