import React, { useEffect, useRef, useState } from "react"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Label, Button } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import "./style.scss"
import { del, get, post, put } from "../../../helpers/api_helper"
import toastr from "toastr"
import moment from "moment"
import SelectErrorMessage from "../../../components/Common/SelectErrorMessage"
import Swal from "sweetalert2"
import { getCourses, getStudent } from "../../../store/options/actions"
import { useSelector, useDispatch } from "react-redux"
import MuiCustomTablePagination from "../../../components/Common/TablePagination"
////////////////////////////
const ScreeningAssign = () => {

  const { courses } = useSelector(state => state.Options)
  const dispatch = useDispatch()

  const ref = useRef()

  const refFilter = useRef()
  const initialState = {
    assignFor: "",
    batch: [],
    students: [],
    course: [],
    branch:[],
    mainBatch:[],
    test: "",
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
  }
  const [masterObject, setMasterObject] = useState(initialState)

  const [mastercheckbox, setMasterCheckBox] = useState([])

  const [filterObject, setFilterObject] = useState({page:0,pageSize:20, fromDate:null, toDate:null, cluster:null,})

  const [tableData, setTableData] = useState([])
  const [students,setStudents] = useState([])

  const [reload, setReload] = useState(false)
  const [filterBatch, setFilterBatch] = useState([])
  const [filterStudent, setFilterStudent] = useState([])
  const [batches, setBatches] = useState([])
  const [batchesFilter, setBatchesFilter] = useState([])

  const [csps,setCsps] = useState([])
  const [selectedCsp,setSelectedCsp] = useState(null)
  const [courseOptions,setCourseOptions] = useState([])
  const [mainBatch,setMainBatch] = useState([])

  const [selectedBatches, setSelectedBatches] = useState(null)
  const [selectedStudents, setSelectedStudents] = useState(null)
  const [selectedCourses, setSelectedCourses] = useState(null)
  const [selectedMainBatch,setSelectedMainBatch] = useState(null)

  const [testOptions, setTestOptions] = useState([])
  const [filterCourse, setFilterCourse] = useState([])
  const [mainbatchesFilter,setMainbatchesFilter] = useState([])
  const [selectedMainBatchFilter,setSelectedMainBAtchFilter] = useState(null)
  const [selectedBatchesStudent, setSelectedBatchesStudent] = useState(null)
  const [selectedBatchesFilter, setSelectedBatchesFilter] = useState(null)
  const [selectedStudentsFilter, setSelectedStudentsFilter] = useState(null)
  const [selectedCoursesFilter, setSelectedCoursesFilter] = useState(null)
  const [studentSearch,setStudentSearch] = useState('')
  const [studentListLoading,setStudentListLoading] = useState(false)
  const [listpage,setListpage] = useState(0)
  const [count,setCount] = useState(0)

  const assignForOptions = [
    {label: "Temporary Batch",value: 0,},
    { label: "Student", value: 1 },
    { label: "Course", value: 2 },
  ]

  const [error, setError] = useState({
    assignFor: false,
    batch: false,
    students: false,
    test: false,
    course: false,
  })

  useEffect(() => {
    handleTestOptions()
    handleFilterBatchOptions()
    handleFilterStudentsOptions()
    dispatch(getCourses())
    dispatch(getStudent())
    handleMainBatchOptionsFilter()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [
    filterObject,
    selectedBatchesFilter,
    selectedCoursesFilter,
    selectedStudentsFilter,
    selectedMainBatchFilter
  ])

  useEffect(() => {
    if (masterObject?.branch?.length > 0) {
      handleBatchOptions();
    }
  }, [masterObject.branch]);

  useEffect(() => {
    if (masterObject.assignFor == 2) {
      handleCourseOptions();
    }
  }, [masterObject.assignFor]);

  useEffect(() => {
    if (masterObject?.course?.length > 0) {
      handleMainBatchOptions();
    }
  }, [masterObject.course]);

  const handleBatchOptions = async () => {
    try {
      const response = await get(`/assign-test/options/batch?branch=${masterObject?.branch?.join(',')}`);
      setBatches(response?.data);
    } catch (error) {
      console.error("Error fetching batch options:", error);
    }
  };

  const handleCourseOptions = async() =>{
    try {
      const response = await get(`/assign-test/options/course`);
      setCourseOptions(response?.data);
    } catch (error) {
      console.error("Error fetching batch options:", error);
    }
  }

  const handleMainBatchOptions = async() =>{
    try {
      const response = await get(`/assign-test/options/main-batch?course=${masterObject?.course?.join(',')}`);
      setMainBatch(response?.data);
    } catch (error) {
      console.error("Error fetching batch options:", error);
    }
  }

  const handleMainBatchOptionsFilter = async() =>{
    try {
      const response = await get(`/options/batches`);
      setMainbatchesFilter(response?.data);
    } catch (error) {
      console.error("Error fetching batch options:", error);
    }
  }


  // useEffect(() => {
  //   const handleStudentsOptions = async () => {
  //     let a = []
  //     let c=[]
  //     a = selectedBatches // Assuming selectedBatches is already defined
  //     if(Array.isArray(a)){
  //       let b = a.map((item) => {
  //         c.push(item.value )
  //       });
  //     }

  //     await get(`/assign-test/options/students?id=${c}`)
  //     // await get("/assign-test/options/filter/students")
  //       .then(res => {
  //         setStudents(res.data)
  //       })
  //       .catch(err => {
  //         console.log("err", err)
  //       })
  //   }
  //   // if(selectedBatches?.value != undefined && selectedBatches?.value != null){
  //   //   handleStudentsOptions()
  //   // }
  //   handleStudentsOptions()

  // }, [selectedBatches])

  useEffect(() => {
    const handleFilterCourseOptions = () => {
      get("assign-test/options/filter/course").then(res =>
        setFilterCourse(res?.data)
      )
    }
    handleFilterCourseOptions()
  }, [])

  useEffect(() => {
    const BatchOptionsFilter = async () => {
      await get("assign-test/options/filter/batch").then(res => {
        setBatchesFilter(res?.data)
      })
    }
    BatchOptionsFilter()
  }, [])

  const handleSingleCheckboxChange = (e, item) => {
    if (e.target.checked) {
      setMasterCheckBox(prevData => [...prevData, item._id])
    } else {
      setMasterCheckBox(prevData => prevData.filter(id => id !== item._id))
    }
  }
  useEffect(()=>{
     const fetchCsp = async() =>{
      try {
        const res = await get(`/options/branches`)
        setCsps(res?.branches)
      } catch (error) {
        
      }
     }
     if(masterObject.assignFor === 0)  fetchCsp()
  },[masterObject?.assignFor])



  // const handleMenuScroll = ({ scrollHeight, clientHeight, scrollTop }) => {
  //   const scrollPercentage = (scrollTop + clientHeight) / scrollHeight;
  //   console.log( scrollHeight, clientHeight, scrollTop )
  //   if (scrollPercentage > 0.9) {
  //     setPage(prev=>prev+1)
  //   }
  // };


  const fetchStudents = ()=> {
    if(masterObject.assignFor === 1){
      setStudentListLoading(true)
      get(`assign-test/options/all-students?page=${listpage}&limit=50&search=${studentSearch}`).then(res=>{
        if (res.success && res?.data?.length > 0) {
          setStudents(prev => [
            ...prev,
            ...res.data
              .filter(item => !prev.some(student => student.value === item.value))
              .map(item => ({
                label: item.label,
                value: item.value
              }))
          ]);
        }

      }).catch(err=>toastr.error(err?.response?.data.message))
      setStudentListLoading(false)
    }else{
      setStudents([])
    }
  }

  useEffect(()=>{
    fetchStudents()
  },[masterObject.assignFor,listpage,studentSearch])

  const generateRankList = () => {
    const dataToSubmit = {
      assignTest: mastercheckbox,
    }
    post(`/assign-test/generate-ranklist`, dataToSubmit)
      .then(res => {
        toastr.success(res.message)
        setMasterCheckBox([])
        setReload((pre)=>!pre)
        // Handle successful response
      })
      .catch(err => {
        toastr.error(err.response?.data.message)
        // Handle error
      })
  }

  const handleTableData = async () => {
    get(
      `assign-test/list?batch=${selectedBatchesFilter?.value}&mainBatch=${selectedMainBatchFilter?.value}&student=${selectedStudentsFilter?.value}&course=${selectedCoursesFilter?.value}&from=${filterObject?.from}&to=${filterObject?.to}&page=${filterObject.page}&limit=${filterObject.pageSize}`
    ).then(res => {
      const result = res?.data
      setCount(res?.count || 0)
      result.map((item, index) => {
        // item.id = mastercheckbox.includes(item._id) ? "one" : "other"
        item.id = index + 1 + (filterObject.page*filterObject.pageSize)
        item._id = item._id
        item.for = assignForOptions.find(
          option => option.value === item.assignFor
        )?.label

        item.date = moment(item.date).format("DD-MM-YYYY")
        item.startDate = moment(item.startDate).format("DD-MM-YYYY")
        item.endDate = moment(item.endDate).format("DD-MM-YYYY")
        item.startTime = moment(item.startTime, "HH:mm").format("hh:mm a")
        item.endTime = moment(item.endTime, "HH:mm").format("hh:mm a")

        if (item.updatedBy) {
          item.staff =
            (item.updatedBy.firstName || "") +
            " " +
            (item.updatedBy.lastName || "")
        } else {
          item.staff =
            (item.addedBy.firstName || "") + " " + (item.addedBy.lastName || "")
        }
        item.checkbox = (
          <>
            <input
              type="checkbox"
              id="tableCheckbox"
              className="form-check-input"
              style={{ cursor: "pointer" }}
              // value={mastercheckbox.includes(item._id) ? false : true}
              // checked={mastercheckbox.filter(id => id == item._id)}
              // checked={mastercheckbox.includes(item._id)}
              onClick={e => handleSingleCheckboxChange(e, item)}
            ></input>
          </>
        )
        item.options = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {/* <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/screen_assign/${item.test}`, {
                  state: { id: item._id },
                })
              }}
            ></i> */}

            <i
              className="far fa-edit"
              onClick={() => handleEdit(item._id)}
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
            ></i>
                      
            <i
              className="far fa-trash-alt"
              onClick={() => handleDelete(item._id)}
              style={{ cursor: "pointer", fontSize: "1em" }}
            ></i>
          </div>
        )

        return item
      })
      setTableData(result)
    })
  }

 

  const handleTestOptions = course => {
    get(`/options/screen-test?course=${course}`).then(res => {
      setTestOptions(res?.data)
    })
  }

  const handleFilterBatchOptions = () => {
    get("assign-test/options/filter/batch").then(res =>
      setFilterBatch(res?.data)
    )
  }

  const handleFilterStudentsOptions = () => {
    get("assign-test/options/filter/students").then(res =>
      setFilterStudent(res?.data)
    )
  }

  const handleValueChange = async (value, name) => {
    const obj = { ...masterObject }
    obj[name] = value
    setMasterObject(obj)
  }


  const handleFilterValueChange = (value, name) => {
    const obj = { ...filterObject }
    obj[name] = value
    obj.page = 0
    obj.pageSize = 20
    setFilterObject(obj)
  }

  const handleBatchSelection = selected => {
    const selectedValues = selected.map(option => option.value)
    setError(prev => ({ ...prev, batch: false }))

    setMasterObject(prev => ({
      ...prev,
      batch: selectedValues,
    }))
  }

  const handleSelection = (selected, name) => {
    const selectedValues = selected.map(option => option.value);
    setError(prev => ({ ...prev, [name]: false }));
    setMasterObject(prev => ({
      ...prev,
      [name]: selectedValues,
    }));
    setSelectedCsp(selected);
  };

  function validateTimeframe(startDate, startTime, endDate, endTime) {
    const startDateTime = moment(
      startDate + " " + startTime,
      "YYYY-MM-DD HH:mm"
    )
    const endDateTime = moment(endDate + " " + endTime, "YYYY-MM-DD HH:mm")

    if (endDateTime.isBefore(startDateTime, "day")) {
      return false
    }

    if (endDateTime.isBefore(startDateTime)) {
      return false
    }

    return true
  }

  const handleSubmit = () => {
    const errors = { ...error }
    let alreadyError = false
    

    if (!masterObject.assignFor && masterObject.assignFor !== 0) {
      errors.assignFor = true
      alreadyError = true
    }

    if (masterObject.assignFor === 0 && !masterObject?.branch?.length > 0) {
      errors.branch = true
      alreadyError = true
    } else {
      errors.branch = false
    }
    if (masterObject.assignFor === 0 && !masterObject?.batch?.length > 0) {
      errors.batch = true
      alreadyError = true
    } else {
      errors.batch = false
    }
    if (!masterObject.test) {
      errors.test = true
      alreadyError = true
    }
    if (masterObject.assignFor === 1 && !masterObject.students?.length > 0) {
      errors.students = true
      alreadyError = true
    } else {
      errors.students = false
    }
    if (masterObject.assignFor === 2 && !masterObject.course?.length > 0) {
      errors.course = true
      alreadyError = true
    }else{
      errors.course = false
    }
    if (masterObject.assignFor === 2 && !masterObject.mainBatch?.length > 0) {
      errors.mainBatch = true
      alreadyError = true
    }else{
      errors.mainBatch = false
    }

    if(!masterObject?.id && masterObject.startDate === masterObject.endDate &&  masterObject.startTime < moment().format('HH:mm')){
      return toastr.error("Start time must be greater than the current time.")
    }

    if(masterObject.startDate === masterObject.endDate && masterObject.endTime <= masterObject.startTime){
      return toastr.error("End time must be greater than the Start time.")
    }


    setError(errors)
    if (alreadyError) return
    if (masterObject.id) {
      put("assign-test/update", masterObject)
        .then(res => {  
          reset()
          toastr.success(res.message)
        })
        .catch(err => {
          toastr.error(err.response?.data.message)
        })
    } else {
      post("assign-test/add", masterObject)
        .then(res => {
          reset()
          toastr.success(res?.message)
        })
        .catch(err => {
          toastr.error(err.response?.data.message)
        })
    }
  }

  const handleEdit = async (id) => {
    get(`assign-test/list-single?id=${id}`).then(async res=>{
      const data = res?.data
      data.startDate = moment(data.startDate).format("YYYY-MM-DD")
      data.endDate = moment(data.endDate).format("YYYY-MM-DD")
      data.startTime = moment(data.startTime, "hh:mm a").format("HH:mm")
      data.endTime = moment(data.endTime, "hh:mm a").format("HH:mm")
      data.id = data._id
      data.test = data.testId
      setMasterObject(data)

      if(data.assignFor === 0){
        setSelectedCsp(data.branchData)
        const response = await get(`/assign-test/options/batch?branch=${data?.branch?.join(',')}`);
        let batchOptions = response.data
        const temp = batchOptions.filter(item => data.batch.includes(item.value))
          setSelectedBatches(temp)
      }


      // if (data.assignFor === 0 || data.assignFor === 1) {
      //   let batchOptions = [...batches]
      //   if(batchOptions.length < 1) batchOptions =  await handleBatchOptions()
      //   const temp = batchOptions.filter(item => data.batch.includes(item.value))
      
      //   setSelectedBatches(temp)
      // }

      if (data?.assignFor == 1) {
        const student = data?.students?.map(dataItem => {
          return {
            ...dataItem,
            label: (dataItem.firstName || "") + " " + (dataItem.lastName || ""),
            value: dataItem._id,
          }
        })
        setSelectedStudents(student)
      }

      if (data?.assignFor === 2) {
          const courses = data.courseData.map(dataItem => {
            return {
              ...dataItem,
              label: dataItem.name,
              value: dataItem._id,
            }
          })
          setSelectedCourses(courses)
          setSelectedMainBatch(data?.mainBatchData)
        }
    }).catch(err=>{
    if(err.response?.data?.message)  toastr.error(err.response?.data?.message)
    
    })
    toTop()



    // if (item?.assignFor === 1) {
    //   const student = item.students.map(dataItem => {
    //     return {
    //       ...dataItem,
    //       label: (dataItem.firstName || "") + " " + (dataItem.lastName || ""),
    //       value: dataItem._id,
    //     }
    //   })
    //   setSelectedStudents(student)

    //   let a = []
    //   // if (Array.isArray(item.students)) {
    //   //   let b = item.students.map(item => {
    //   //     a.push(item._id)
    //   //   })
    //   // }

    //   item.students = a
    // }
    // if (item?.assignFor === 2) {
    //   const courses = item.course.map(dataItem => {
    //     return {
    //       ...dataItem,
    //       label: dataItem.name,
    //       value: dataItem._id,
    //     }
    //   })
    //   setSelectedCourses(courses)
    // }

    // setMasterObject(item)
  }

  const handleDelete = async id => {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`assign-test/delete?id=${id}`)
          .then(res => {
            toastr.success(res.message)
            handleTableData()
          })
          .catch(err => {
            toastr.error(err?.response?.data?.message)
          })
      }
    })
  }

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }
  const reset = () => {
    ref.current?.reset()
    toTop()
    handleTableData()
    setError({})
    setMasterObject(initialState)
    setSelectedBatches(null)
    setSelectedCourses(null)
    setSelectedStudents(null)
    setSelectedBatchesStudent(null)
    setSelectedCsp(null)
    setSelectedMainBatch(null)
    setStudentSearch('')
    setStudents([])
  }

  const resetFiter = () => {
    refFilter.current?.reset()
    setFilterObject({page:0,pageSize:20, fromDate:null, toDate:null, cluster:null,})
    setSelectedBatchesFilter(null)
    setSelectedCoursesFilter(null)
    setSelectedStudentsFilter(null)
    setSelectedMainBAtchFilter(null)
  }

  const handleChangeRowsPerPage = event => {
    setFilterObject({
      ...filterObject,
      pageSize: parseInt(event.target.value, 10),
      page: 0,
    })
  }

  const handlePageChange = newPage => {
    setFilterObject({
      ...filterObject,
      page: newPage,
    })
  }

  const data = {
    columns: [
      {
        label: "",
        field: "checkbox",
        width: 50,
      },
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "For",
        field: "for",
        width: 150,
      },
      {
        label: "Test",
        field: "test",
        width: 150,
      },

      {
        label: "Start date",
        field: "startDate",
        width: 150,
      },
      {
        label: "Start time",
        field: "startTime",
        width: 150,
      },
      {
        label: "End Date",
        field: "endDate",
        width: 150,
      },
      {
        label: "End time",
        field: "endTime",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff",
        width: 150,
      },
      {
        label: "Action",
        field: "options",
        width: 150,
      },
    ],
    rows: tableData,
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem="Assign Test" />
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <AvForm
                    className="needs-validation"
                    ref={ref}
                    onValidSubmit={handleSubmit}
                  >
                    <Row>
                      <Col md={2}>
                        <div className="mb-3">
                          <Label>Assign For</Label>
                          <Select
                            name="for"
                            classNamePrefix="select2-selection"
                            options={assignForOptions}
                            value={assignForOptions.filter(
                              option => option.value === masterObject.assignFor
                            )}
                            onChange={selected => {
                              handleValueChange(selected.value, "assignFor")
                              setError(prev => ({ ...prev, assignFor: false }))
                            }}
                          />
                          <SelectErrorMessage
                            customStyle={{
                              width: "100%",
                              fontSize: "87.5%",
                              color: "var(--bs-form-invalid-color)",
                            }}
                            message="Select Assign for"
                            show={error.assignFor}
                          />
                        </div>
                      </Col>
                      {masterObject.assignFor === 0 && (
                        <>
                         <Col md={3}>
                          <div className="mb-3">
                            <Label>CSP</Label>
                            <Select
                              isMulti
                              name="branch"
                              classNamePrefix="select2-selection"
                              options={csps}
                              value={selectedCsp} 
                               onChange={selected => {
                                handleSelection(selected, "branch")
                                setSelectedCsp(selected)
                              }}
                            />
                            <SelectErrorMessage
                              customStyle={{
                                width: "100%",
                                fontSize: "87.5%",
                                color: "var(--bs-form-invalid-color)",
                              }}
                              message="Select CSP"
                              show={error.branch}
                            />
                          </div>
                        </Col>
                        
                        <Col md={3}>
                          <div className="mb-3">
                            <Label>Temporary Batch</Label>
                            <Select
                              isMulti
                              name="batch"
                              classNamePrefix="select2-selection"
                              options={batches}
                              value={selectedBatches}
                              onChange={selected => {
                                setSelectedBatches(selected)
                                handleBatchSelection(selected)
                              }}
                              formatOptionLabel={option => (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <strong >
                                    {option.label}
                                  </strong>
                                  <span>{option.course}</span>
                                </div>
                              )}
                            />
                            <SelectErrorMessage
                              customStyle={{
                                width: "100%",
                                fontSize: "87.5%",
                                color: "var(--bs-form-invalid-color)",
                              }}
                              message="Select Batch"
                              show={error.batch}
                            />
                          </div>
                        </Col>
                        
                        
                        </>
                        
                      )}

                      {/* {masterObject.assignFor === 1 && !masterObject.id&& 
                      
                      <Col md={3}>
                      <div className="mb-3">
                        <Label>Temparory Batch</Label>
                        <Select
                          name="assign"
                          classNamePrefix="select2-selection"
                          options={batches}
                          value={selectedBatchesStudent}
                          onChange={(selected)=>setSelectedBatchesStudent(selected)}
                          formatOptionLabel={option => (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                              }}
                            >
                         <strong class="gray-text">{option.label.courseName}</strong>
                              <span>
                                next batch start :
                                {option.label.expStartingDate}
                              </span>
                            </div>
                          )}
                        />
                      </div>
                    </Col>
                      } */}

                      {masterObject.assignFor === 1 ? (
                        <>
                          <Col md={3}>
                            <div className="mb-3">
                              <Label>Student</Label>
                              <Select
                                isMulti
                                name="students"
                                classNamePrefix="select2-selection"
                                // value={selected.students}
                                value={selectedStudents}
                                options={students}
                                onChange={selected => {
                                  const selectedValues = selected.map(
                                    option => option.value
                                  )
                                  handleValueChange(selectedValues, "students")
                                  setSelectedStudents(selected)
                                  setError(prev => ({
                                    ...prev,
                                    students: false,
                                  }))
                                }}
                                isLoading={studentListLoading}
                                loadingMessage={() => <p>Loading...</p>}
                                onMenuScrollToBottom={() =>
                                  setListpage(prev => prev + 1)
                                }
                                onInputChange={value => {
                                  setStudentSearch(value)
                                  setListpage(0)
                                }}
                              />
                              <SelectErrorMessage
                                customStyle={{
                                  width: "100%",
                                  fontSize: "87.5%",
                                  color: "var(--bs-form-invalid-color)",
                                }}
                                message="Select Students"
                                show={error.students}
                              />
                            </div>
                          </Col>
                        </>
                      ) : null}

                      {masterObject.assignFor === 2 ? (
                        <>
                        <Col md={3}>
                          <div className="mb-3">
                            <Label>Course</Label>
                            <Select
                              isMulti
                              name="course"
                              classNamePrefix="select2-selection"
                              // value={selected.students}
                              value={selectedCourses}
                              options={courseOptions}
                              onChange={selected => {
                                const selectedValues = selected.map(
                                  option => option.value
                                )
                                handleValueChange(selectedValues, "course")
                                setError(prev => ({
                                  ...prev,
                                  students: false,
                                }))
                                setSelectedCourses(selected)
                              }}
                            />
                            <SelectErrorMessage
                              customStyle={{
                                width: "100%",
                                fontSize: "87.5%",
                                color: "var(--bs-form-invalid-color)",
                              }}
                              message="Select Students"
                              show={error.course}
                            />
                          </div>
                        </Col>

                        <Col md={3}>
                          <div className="mb-3">
                            <Label>Batch</Label>
                            <Select
                              isMulti
                              name="course"
                              classNamePrefix="select2-selection"
                              // value={selected.students}
                              value={selectedMainBatch}
                              options={mainBatch}
                              onChange={selected => {
                                const selectedValues = selected.map(
                                  option => option.value
                                )
                                handleValueChange(selectedValues, "mainBatch")
                                setError(prev => ({
                                  ...prev,
                                  mainBatch: false,
                                }))
                                setSelectedMainBatch(selected)
                              }}
                            />
                            <SelectErrorMessage
                              customStyle={{
                                width: "100%",
                                fontSize: "87.5%",
                                color: "var(--bs-form-invalid-color)",
                              }}
                              message="Select Batch"
                              show={error.mainBatch}
                            />
                          </div>
                        </Col>
                        </>
                        
                      ) : null}

                      <Col md={3}>
                        <div className="mb-3">
                          <Label>Test Name</Label>
                          <Select
                            name="test"
                            classNamePrefix="select2-selection"
                            options={testOptions}
                            // value={selected.test}
                            value={testOptions.filter(
                              option => option.value === masterObject.test
                            )}
                            onChange={selected => {
                              handleValueChange(selected.value, "test")
                              setError(prev => ({ ...prev, test: false }))
                            }}
                          />
                          <SelectErrorMessage
                            customStyle={{
                              width: "100%",
                              fontSize: "87.5%",
                              color: "var(--bs-form-invalid-color)",
                            }}
                            message="Select Test"
                            show={error.test}
                          />
                        </div>
                      </Col>

                      <Col md={2}>
                        <div className="mb-3">
                          <Label>Start Date</Label>
                          <AvField
                            name="startDate"
                            type="date"
                            className="form-control"
                            value={masterObject.startDate || ""}
                            validate={{ required: { value: true } }}
                            errorMessage="Enter Start date"
                            min={
                              !masterObject.id && moment().format("YYYY-MM-DD")
                            }
                            onChange={e =>
                              handleValueChange(e.target.value, e.target.name)
                            }
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="mb-3">
                          <Label>Start Time</Label>
                          <AvField
                            name="startTime"
                            type="time"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            value={masterObject.startTime || ""}
                            errorMessage="Enter Start time"
                            onChange={e =>
                              handleValueChange(e.target.value, e.target.name)
                              
                            }
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="mb-3">
                          <Label>End Date</Label>
                          <AvField
                            name="endDate"
                            type="date"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            min={masterObject.startDate}
                            value={masterObject.endDate || ""}
                            errorMessage="Enter End date"
                            onChange={e =>
                              handleValueChange(e.target.value, e.target.name)
                            }
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="mb-3">
                          <Label>End Time</Label>
                          <AvField
                            name="endTime"
                            type="time"
                            className="form-control"
                            value={masterObject.endTime || ""}
                            validate={{ required: { value: true } }}
                            errorMessage="Enter End time"
                            onChange={e =>
                              handleValueChange(e.target.value, e.target.name)
                            }
                          />
                        </div>
                      </Col>
                      <Col md={3} style={{ paddingTop: "26px" }}>
                        <Button
                          type="submit"
                          color={masterObject.id ? "warning" : "primary"}
                          className="me-2"
                        >
                          {masterObject.id ? "Update" : "Submit"}
                        </Button>
                        <Button type="button" color="danger" onClick={reset}>
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <AvForm ref={refFilter}>
                    <Row>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label>Temporary Batch</Label>
                          <Select
                            name="assign"
                            classNamePrefix="select2-selection"
                            options={batchesFilter}
                            value={selectedBatchesFilter}
                            onChange={selected => {
                              setSelectedBatchesFilter(selected)
                              setFilterObject({...filterObject,page: 0, pageSize: 20})
                            }
                            }
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label> Batch</Label>
                          <Select
                            name="batch"
                            classNamePrefix="select2-selection"
                            options={mainbatchesFilter}
                            value={selectedMainBatchFilter}
                            onChange={selected => {
                              setSelectedMainBAtchFilter(selected)
                              setFilterObject({...filterObject,page: 0, pageSize: 20})
                            }
                            }
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="mb-3">
                          <Label>Student</Label>
                          <Select
                            name="assign"
                            classNamePrefix="select2-selection"
                            options={filterStudent}
                            value={selectedStudentsFilter}
                            onChange={selected =>{
                              setSelectedStudentsFilter(selected)
                              setFilterObject({...filterObject,page: 0, pageSize: 20})
                            }
                            }
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="mb-3">
                          <Label>Course</Label>
                          <Select
                            name="assign"
                            classNamePrefix="select2-selection"
                            options={filterCourse}
                            value={selectedCoursesFilter}
                            onChange={selected => {
                              setSelectedCoursesFilter(selected)
                              setFilterObject({...filterObject,page: 0, pageSize: 20})
                            }
                            }
                          />
                        </div>
                      </Col>
                      {/* <Col md={2}>
                        <div className="mb-3">
                          <Label>Test Name</Label>
                          <Select
                            name="assign"
                            classNamePrefix="select2-selection"
                            options={testOptions}
                            value={selectedTestFilter}
                            onChange={(selected)=>setSelectedTestFilter(selected)}
                          />
                        </div>
                      </Col> */}
                      <Col md="2">
                        <div className="mb-3">
                          <Label>From date</Label>
                          <AvField
                            name="from"
                            type="date"
                            value={filterObject.from || ""}
                            onChange={e =>
                              handleFilterValueChange(
                                e.target.value,
                                e.target.name
                              )
                            }
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>To date</Label>
                          <AvField
                            name="to"
                            type="date"
                            value={filterObject.to || ""}
                            onChange={e =>
                              handleFilterValueChange(
                                e.target.value,
                                e.target.name
                              )
                            }
                          />
                        </div>
                      </Col>
                      <Col>
                        <Button
                          type="button"
                          style={{ marginTop: "1.7rem" }}
                          color="danger"
                          onClick={resetFiter}
                        >
                          Reset
                        </Button>
                      </Col>
                      <Row>
                        <Col className="mb-3" style={{ textAlign: "end" }}>
                          <Button
                            style={{}}
                            color="primary"
                            onClick={generateRankList}
                          >
                            Generate Rank List
                          </Button>
                        </Col>
                      </Row>
                      <Col md={12}>
                        <MDBDataTable
                          id="screeningAssignTableid"
                          responsive
                          bordered
                          key={reload}
                          info={false}
                          paging={false}
                          searching={true}
                          disableRetreatAfterSorting={true}
                          data={data}
                        />
                      </Col>
                      {tableData?.length > 0 && (
                        <MuiCustomTablePagination
                          count={count}
                          page={filterObject?.page}
                          rowsPerPage={filterObject?.pageSize}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                      )}
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default ScreeningAssign
