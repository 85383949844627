import React, { useEffect, useRef, useState } from "react"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { MDBDataTable } from "mdbreact"
import { useNavigate } from "react-router-dom"
import toastr from "toastr"
import "./style.scss"
import { get, post, put, del } from "../../../helpers/api_helper"
import moment from "moment"

import { getCluster, getSectors } from "../../../store/options/actions"
import ReactSelect from "react-select"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import Swal from "sweetalert2"

const CommunityCreate = () => {
  const navigate = useNavigate()
  const { cluster, sectors } = useSelector(state => state.Options)
  const dispatch = useDispatch()
  const [selectedSector, setSelectedSector] = useState(null)
  const [selectCluster, setSelectedCluster] = useState(null)

  const formRef = useRef()

  useEffect(() => {
    dispatch(getCluster({}))
    // dispatch(getSectors({}))
    // eslint-disable-next-line
  }, [dispatch])
  useEffect(() => {
    handleTableData()
  }, [])
  const initialState = {
    community: "",
    cluster: "",
    sector: "",
  }

  const [TableData, setTableData] = useState([])
  const [masterObject, setMasterObject] = useState(initialState)
  
  function handleSelect(selected, name) {
    switch (name) {
      case "sector":
        setSelectedSector(selected)
        setMasterObject({
          ...masterObject,
          sector: selected.value,
        })
        break
      case "cluster":
        setSelectedCluster(selected)
        setSelectedSector(null)
        setMasterObject({
          ...masterObject,
          cluster: selected.value,
          sector: null,
        })
        dispatch(getSectors(selected.sector))
        break

      default:
        break
    }
  }
  function handleTableData() {
    get(`/community/list`).then(res => {
      let data = []
      let result = res?.data
      result?.map((item, index) => {
        item.date = moment(item.date).format("DD-MM-YYYY")
        item.id = index + 1
        item.options = (
          <div>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                navigate(`/community_details/${item?.community}`, {
                  state: { id: item._id },
                })
              }} 
            ></i>
            <i
              className="uil-edit-alt"
              onClick={() => {
                handleEdit(item)
                toTop()
              }}
              style={{
                fontSize: "1.2em",
                cursor: "pointer",
                marginRight: "0.5rem",
              }}
            ></i>
            <i
              className="uil-trash-alt"
              style={{ fontSize: "1.2em", cursor: "pointer" }}
              onClick={() => {
                handleDelete(item?._id)
              }}
            ></i>
          </div>
        )
        item.sectorname = item?.sector?.label
        item.clustername = item?.cluster?.label

        data.push(item)
      })
      setTableData(data)
    })
  }

  const dataTable = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Community Name",
        field: "community",
        sort: "asc",
        width: 150,
      },
      {
        label: "Cluster",
        field: "clustername",
        sort: "asc",
        width: 270,
      },
      {
        label: "Sector",
        field: "sectorname",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Events",
      //   field: "eventsCounts",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Total Members",
        field: "totalMembersCount",
        sort: "asc",
        width: 200,
      },
      {
        label: "Actions",
        field: "options",
        sort: "desc",
        width: 400,
      },
    ],
    rows: TableData,
  }
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }
  const reset = () => {
    formRef.current.reset()
    handleTableData()
    setSelectedSector(null)
    setSelectedCluster(null)
    setMasterObject(initialState)
    toTop()
  }
  const handleSubmit = () => {
    if (masterObject._id) {
      put(`/community/edit`, masterObject).then(res => {
        toastr.success(res.message) 
        handleTableData() 
        formRef.current.reset()

        reset()
      })
    } else {
      post(`/community/add`, masterObject)
        .then(res => {
          toastr.success(res.message)
          handleTableData()
          formRef.current.reset()
          reset()
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    }
  }

  const handleEdit = item => {
    // console.log(item, "itemssss")
    // item.cluster = item?.cluster?.value
    // item.sector = item?.sector?.value
 
    setSelectedSector({
      label: item?.sector.label,
      value: item?.sector?.value,
    })
    setSelectedCluster({
      label: item?.cluster.label,
      value: item?.cluster?.value,
    })
    setMasterObject({
      _id: item._id,
      community: item.community,
      cluster: item?.cluster?.value,
      sector: item?.sector?.value,
    })
  } 
  const handleValueChange = (selected, name) => {
    const obj = { ...masterObject }
    obj[name] = selected.value

    if (name === "cluster") {
      dispatch(getSectors(selected.sectors))
    }
    setMasterObject(obj)
  }
  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`/community/${id}`)
          .then(res => {
            toastr.success(res.message)
            handleTableData()
            formRef.current.reset()
          })
          .catch(err => {
            toastr.success(err.response.data.message)
          })
      }
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Home" breadcrumbItem=" Create Community" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    onValidSubmit={handleSubmit}
                    ref={formRef}
                    className="needs-validation "
                  >
                    <Row>
                      <Col md="3" className="mb-3">
                        <Label htmlFor="validationCustom01">
                          Community Name
                        </Label>
                        <AvField
                          name="community"
                          placeholder="Community Name"
                          type="text"
                          errorMessage="Enter Community Name"
                          value={masterObject?.community}
                          onChange={e =>
                            handleValueChange(e.target, e.target.name)
                          }
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="validationCustom01"
                        />
                      </Col>

                      <Col md="3" className="mb-3">
                        <Label htmlFor="validationCustom01">Cluster</Label>
                        <ReactSelect
                          name="cluster"
                          type="text"
                          options={cluster}
                          errorMessage="Enter Cluster"
                          // value={cluster?.filter(
                          //   item => item.value === masterObject?.cluster
                          // )}
                          // onChange={selected =>
                          //   handleValueChange(selected, "cluster")
                          // }
                          value={selectCluster}
                          onChange={selected => {
                            handleSelect(selected, "cluster")
                          }}
                          validate={{ required: { value: true } }}
                          id="validationCustom01"
                        />
                      </Col>
                      <Col md="3" className="mb-3">
                        <Label htmlFor="validationCustom01">Sector</Label>
                        <ReactSelect
                          name="sector"
                          errorMessage="Enter Sector"
                          options={sectors}
                          // onChange={selected =>
                          //   handleValueChange(selected, "sector")
                          // }
                          // value={sectors?.filter(
                          //   item => item.value === masterObject?.sector
                          // )}
                          isDisabled={!masterObject.cluster}
                          value={selectedSector}
                          onChange={selected => {
                            handleSelect(selected, "sector")
                          }}
                          validate={{ required: { value: true } }}
                          id="validationCustom01"
                        />
                      </Col>
                      <Col md="3" className="mb-3 mt-4">
                        <Button
                          color={masterObject._id ? "warning" : "primary"}
                          type="submit"
                        >
                          {masterObject._id ? "Update" : "Submit"}
                        </Button>
                        <Button
                          style={{ marginLeft: "10px" }}
                          color="danger"
                          type="reset"
                          onClick={reset}
                        >
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="companyTableId1"
                    responsive
                    bordered
                    data={dataTable}
                    searching={true}
                    // info={true}
                    // disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CommunityCreate
