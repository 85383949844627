import React, { useEffect, useState } from "react"
import "./addLead.scss"
import { MDBDataTable } from "mdbreact"
import { Row } from "reactstrap"
import { get } from "../../../../helpers/api_helper"
import Tooltip from "@mui/material/Tooltip"
import moment from "moment"
import { API_URL } from "../../../../config"

const Documents = ({ profileID }) => {
  useEffect(() => { }, [profileID])
  console.log("leadid", profileID);
  const [rows, setrows] = useState([])

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      // {
      //   label: "",
      //   field: "checkbox",
      //   width: 150,
      // }, 
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Document",
        field: "type1",
        width: 150,
      },
      {
        label: "Document . No",
        field: "number",
        width: 150,
      },
      {
        label: "",
        field: "action",
        sort: "desc",
        width: 400,
      },
    ],
    rows,
  }

  useEffect(() => {
    getallDocuments()

  }, [])


  function getallDocuments() {
    get(`profile/documents/${profileID}`).then((res) => {
      console.log("rehefheigegeigherigh", res);
      let result = res.data.documents;
      result && result.map((item, index) => {
        item.id = index + 1
        item.type1 = item.type.name
        item.date = moment(item.date).format("DD-MM-YYYY")

        item.action = (
          <Tooltip title="View Document" arrow placement="top">
            <i
              onClick={() => {
                window.open(
                  `${API_URL}${item?.path}`
                )
              }}
              style={{ cursor: "pointer" }}
              className="fas fa-eye">

            </i>
          </Tooltip>
        )

        return item
      })
      setrows(result)
    })
  }

  return (
    <>
      <h1 class="head-700-18-23">Uploaded Documents</h1>
     
      <Row className="mt-4">
        <MDBDataTable
          id="tableDocument"
          bordered
          responsive
          searching={false}
          displayEntries={false}
          paging={false}
          disableRetreatAfterSorting={true}
          data={data}
        ></MDBDataTable>
      </Row>
    </>
  )
}
export default Documents
