import React, { useEffect, useRef, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Row, Col } from "reactstrap"
import { get } from "../../helpers/api_helper"
import { useNavigate } from "react-router-dom"
import useSearchValue from "../../hooks/useSearchValue"

function SearchnameMobile({ onDataSelected }) {
  const [keyWord, setKeyWord] = useState()
  const selectedRef = useRef(null)

  const [selectedId, setSelectedId] = useState(-1)
  const [highlightedIndex, setHighlightedIndex] = useState(-1)

  const navigate = useNavigate()
  const [filteredData, setFilteredData] = useState([])
  const [optionsCust, setOptionsCust] = useState([])

  const [anchorElStudent, setAnchorElStudent] = useState(false);

  const studentRef = useRef();


  const searchValue = useSearchValue(keyWord, 500)


  useEffect(() => {
    if (searchValue) {
      handleSearchChange(searchValue)
    }
  }, [searchValue])

  const handleSearchChange = search => {
    get(`/student/search?search=${search}`).then(res => {
      const data = res.data

      data.map(item => {
        item.value = item._id
        item.label =
          (item.firstname ? `${item.firstname} ` : "") +
          (item.lastname ? `${item.lastname} ` : "") +
          (item.mobile ? `${item.mobile} ` : "")
        return item
      })
      setSelectedId(-1)
      setHighlightedIndex(-1)
      setOptionsCust(data)

      setFilteredData(data)
    })
  }
  const [showAdditionalFields, setShowAdditionalFields] = useState({
    show: false,
  })

  // const handleSearch = e => {
  //   const result = () =>
  //     filteredData.filter(
  //       word => e.length > 0 && new RegExp(e, "ig").test(word.label)
  //     )
  //   setHighlightedIndex(-1)
  //   setOptionsCust(result)
  // }

  const handleKeyDown = e => {
    if (e.key === "ArrowUp" && highlightedIndex > 0) {
      e.preventDefault()
      setHighlightedIndex(highlightedIndex - 1)
    } else if (
      e.key === "ArrowDown" &&
      highlightedIndex < optionsCust.length - 1
    ) {
      e.preventDefault()
      setHighlightedIndex(highlightedIndex + 1)
    } else if (e.key === "Enter" && highlightedIndex >= 0) {
      e.preventDefault()
      setKeyWord("")
      setOptionsCust([])
      const selectedOption = optionsCust[highlightedIndex] // Get the selected option
      if (selectedOption) {
        // Check if the selected option exists
        navigate("/student-details/" + selectedOption.unique_id, {
          state: { id: selectedOption._id },
        })
      }
    }
  }

  // const handleKeyDown = (e) => {
  //   console.log(e.key,e)
  //   // Validate inputs
  //   if (!filteredData || !optionsCust) {
  //     return; // Exit early if inputs are not defined
  //   }
  //   // Ensure highlightedIndex is within bounds
  //   if (e.key === "ArrowUp" && highlightedIndex > 0) {
  //     e.preventDefault();
  //     setHighlightedIndex((prevIndex) => prevIndex - 1);
  //   } else if (e.key === "ArrowDown" && highlightedIndex < filteredData.length - 1) {
  //     e.preventDefault();
  //     setHighlightedIndex((prevIndex) => prevIndex + 1);
  //   } else if (e.key === "Enter" && highlightedIndex >= 0) {
  //     e.preventDefault();
  //     const selectedOption = optionsCust[highlightedIndex];
  //     if (selectedOption) {
  //       handleSelectSearch(selectedOption);
  //     }
  //   }
  // }

  useEffect(() => {
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({
        block: "nearest",
        inline: "nearest",
      })
    }
  }, [selectedRef, highlightedIndex])
  const handleSelectSearch = e => {
    setOptionsCust([])
    setKeyWord("")
    // setFilteredData([]);

    navigate("/student-details/" + e.unique_id, {
      state: { id: e._id },
    })
  }
  const handleSearch1 = () => {
    const value = keyWord
    setOptionsCust([])

    setKeyWord("")
    navigate("/student-details", {
      state: { key: value },
    })
  }


  useEffect(() => {
    function handleCloseStudentSearch(e) {
      if (!studentRef.current.contains(e.target)){

        setKeyWord("")
        setOptionsCust([])
      } 
    }
    document.addEventListener("mousedown", handleCloseStudentSearch);
    return () => {
      document.removeEventListener("mousedown", handleCloseStudentSearch);
    };
  });


  // console.log(keyWord.length,"keyworddsdd");
  return (
    <div className="search-bar" ref={studentRef}>
      <AvForm onValidSubmit={handleSearch1}>
        <Row>
          <Col md="12">
            <div
              style={{ position: "relative", minWidth: "215px" }}
              className="mb-1 mt-3 ms-2 "
            >
              <AvField
                // ref={searchInputRef}
                menuPosition="fixed"
                placeholder="Search Student name/mobile/ID"
                value={keyWord}
                name="subcategory"
                onKeyDown={e => {
                  handleKeyDown(e)
                  let keyWord = e.target.value

                  if (
                    e.key === "Backspace" &&
                    (!keyWord || keyWord.key === 0)
                  ) {
                    // alert("Please")
                    setOptionsCust([])
                  }
                }}
                onChange={e => {
                  if (e.target.value.length === 0) {
                    setOptionsCust([])
                  }
                  // else {
                    setKeyWord(e.target.value)
                  // }
                }}
                // onChange={e => handleSearch(e.target.value)}
                classNamePrefix="select2-selection"
                onFocus={() => setShowAdditionalFields({ show: false })}
              />
              {optionsCust.length > 0 && !showAdditionalFields.show && (
                <div class="fetch-names-div">
                  {optionsCust.map((item, index) => (
                    <>
                      <a
                        // onClick={() => {
                        //   navigate(item.route, {
                        //     id: item.id,
                        //   })
                        //   setKeyWord("")
                        // }}
                        key={item.label}
                      >
                        <p
                          className="select-email-p"
                          //  className={`select-email-p ${
                          //   highlightedIndex === index ? "active-email" : ""
                          // }`}
                          ref={highlightedIndex === index ? selectedRef : null}
                          onClick={e => {
                            handleSelectSearch(item)
                          }}
                          style={{
                            backgroundColor:
                              index === highlightedIndex
                                ? "rgb(240, 249, 255)"
                                : "",
                          }}
                        >
                          {item.label}
                        </p>
                      </a>
                    </>
                  ))}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </AvForm>
    </div>
  )
}

export default SearchnameMobile
