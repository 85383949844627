import React, { useEffect, useState } from "react"
import { Row, Col, Button, Modal } from "reactstrap"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import { Box, Divider } from "@mui/material"
import Breadcrumb from "../../../../components/Common/Breadcrumb"
import GradingIcon from "@mui/icons-material/Grading"
import Tooltip from "@mui/material/Tooltip"

import SkillLoanProcessing from "../Processing"

import { get, put } from "../../../../helpers/api_helper"
import { useParams } from "react-router-dom"
import { API_URL } from "../../../../config"
import toastr from "toastr"

import Request from "./request.jsx"
import { setTitle } from "../../../../helpers/functions.js"

const SkillLoansDetails = ({ options }) => {
  setTitle("Skill Loan Details")
  const [tab3, setTab3] = React.useState("1")
  const [modal, setModal] = useState(false)
  const [masterObject, setMasterObject] = useState({
    name: "",
    logo: "",
    totalRequests: 0,
    totalSanctioned: 0,
    totalRejected: 0,
    totalRequestAmount: 0,
    totalSanctionedAmount: 0,
    totalRejectedAmount: 0,
    sanctionedPercentage: 0,
  })

  const { id } = useParams()
  const handleChangeTab = (event, newValue) => {
    setTab3(newValue)
  }

  useEffect(() => {
    handleData()
    // eslint-disable-next-line
  }, [])

  const handleData = async () => {
    get(`skill-loan/details?id=${id}`).then(res => {
      setMasterObject(res.data)
    })
  }

  const handleStatusChange = async () => {
    const id = masterObject._id
    const status = masterObject.status
    await put("/skill-loan/status", { id, status }).then(res => {
      toastr.success(res.message)
      handleData()
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem={masterObject.name} />
          <Row>
            <Col xl="12">
              <div>
                <div className="row">
                  <div className="col-xl-3">
                    <div
                      className="card"
                      style={{
                        // width: "fit-content",
                        // width: "351px",
                        height: "max-content",
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                      }}
                    >
                      <div className="card-body">
                        <div className="float-end dropdown"></div>
                        <h5
                          className="mt-2 mb-2"
                          style={{
                            textAlign: "center",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}
                        >
                          {masterObject.name}
                        </h5>
                        <div
                          className="text-muted"
                          style={{
                            textAlign: "center",
                            color: "green !important",
                            fontFamily: "IBM Plex Sans,sans-serif",
                            fontSize: "14px",
                            marginBottom: "3px",
                          }}
                        >
                          <div
                            class="btn-group btn-group-sm"
                            role="group"
                            aria-label="Basic example"
                          >
                            <img
                              style={{ width: "100px", height: "50px" }}
                              src={API_URL + masterObject.logo}
                              alt="logo"
                            />
                          </div>
                        </div>
                        <p
                          style={{
                            textAlign: "center",
                            marginBottom: "3px",
                          }}
                        ></p>

                        <Divider />

                        {/* <div className="mt-2">
                          <p className="mb-1">Provider Name:</p>
                          <h5 className="font-size-14">{masterObject.name}</h5>
                        </div> */}
                        {masterObject.document ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            className="mt-2"
                          >
                            <p className="mb-1">File:</p>
                            <Tooltip title="View File" arrow placement="top">
                              <a
                                href={API_URL + masterObject.document}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i
                                  className="fas fa-file-pdf"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "1.5em",
                                    color: "red",
                                  }}
                                ></i>
                              </a>
                            </Tooltip>
                          </div>
                        ) : (
                          ""
                        )}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                          className="mt-2"
                        >
                          <p className="mb-1">Status:</p>

                          {masterObject.status === 0 ? (
                            <Button
                              className="btn btn-sm mb-1"
                              color="success"
                              onClick={() => handleStatusChange()}
                            >
                              Active
                            </Button>
                          ) : (
                            <Button
                              className="btn btn-sm mb-1"
                              color="warning"
                              onClick={() => handleStatusChange()}
                            >
                              Inactive
                            </Button>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          className="mt-2"
                        >
                          <p className="mb-1">Total Requests:</p>
                          <h5
                            style={{ fontWeight: "600", color: "#1327b7" }}
                            className="font-size-14"
                          >
                            {masterObject.totalRequests}
                          </h5>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          className="mt-2"
                        >
                          <p className="mb-1">New Requests:</p>
                          <h5
                            style={{ fontWeight: "600", color: "#5b73e8" }}
                            className="font-size-14"
                          >
                            {masterObject.totalNewRequests}
                          </h5>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          className="mt-2"
                        >
                          <p className="mb-1">Approved:</p>
                          <h5
                            style={{ fontWeight: "600", color: "green" }}
                            className="font-size-14"
                          >
                            {masterObject.totalSanctioned}
                          </h5>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          className="mt-2"
                        >
                          <p className="mb-1">Processing:</p>
                          <h5
                            style={{ fontWeight: "600", color: "#f1b44c" }}
                            className="font-size-14"
                          >
                            {masterObject.totalProcessing}
                          </h5>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          className="mt-2"
                        >
                          <p className="mb-1">Rejected:</p>
                          <h5
                            style={{ fontWeight: "600", color: "red" }}
                            className="font-size-14"
                          >
                            {masterObject.totalRejected}
                          </h5>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          className="mt-2"
                        >
                          <p className="mb-1">Approved Percentage</p>
                          <h5
                            style={{ fontWeight: "600", color: "green" }}
                            className="font-size-14"
                          >
                            {masterObject.sanctionedPercentage
                              ? masterObject.sanctionedPercentage?.toFixed(0)
                              : 0}
                            %
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-9" style={{ marginBottom: "30px" }}>
                    <div
                      className="mb-0 card"
                      style={{
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={tab3}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              indicatorColor="primary"
                              textColor="primary"
                              variant="fullWidth"
                              onChange={handleChangeTab}
                              aria-label="lab API tabs example"
                            >
                              <Tab
                                icon={
                                  <GradingIcon style={{ fontSize: "20px" }} />
                                }
                                label="Requests"
                                value="1"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                            </TabList>
                          </Box>
                          <TabPanel
                            value="1"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Request
                              id={masterObject._id}
                              options={options}
                            ></Request>
                          </TabPanel>
                        </TabContext>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Modal isOpen={modal} size="xl" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Skill Loan Details</h5>
          <button
            type="button"
            onClick={() => {
              setModal(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <SkillLoanProcessing />
        </div>
      </Modal>
    </React.Fragment>
  )
}
export default SkillLoansDetails
