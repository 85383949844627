import React from "react"
import ReactApexChart from "react-apexcharts"

const PieChart = ({ answers, type }) => {
  // if (answers && type) {
  //   // console.log("piechart answers", answers, "type", type)
  // }
  // type 0 yes or no
  // type 1 star
  // type 2 emoji
  let requiredAnswers
   if(type===0){
    requiredAnswers = ['2', '1'];
   }else if(type ===1 || type === 2){
    requiredAnswers = ['5', '4', '3', '2', '1'];
   }

const answerMap = new Map();
answers.forEach(item => {
    answerMap.set(item.answer, item.count);
});

const finalArray = requiredAnswers.map(answer => {
    const count = answerMap.has(answer) ? answerMap.get(answer) : 0;
    return { answer, count };
});
  // const sortedAnswers = answers.sort((a, b) => parseInt(a.answer) - parseInt(b.answer));
  let series
  let labelArray
  if (finalArray && finalArray.length>0 && type !== undefined) {
    if (type === 0) {
      labelArray = finalArray.map(item =>item.answer === '2' ? "No" : item.answer === '1' ? "Yes" : '')
    } else if (type === 1 || type === 2) {
      labelArray = finalArray.map(item => {
        return item.answer === '5' ? (
          'Excellent'
        ) : item.answer === '4' ? (
          'Best'
        ) : item.answer === '3' ? (
          'Good'
        ) : item.answer === '2' ? (
          'Average'
        ) : item.answer === '1' ? (
          'Need Improvement'
        ) : ''
      })
    } else if (type === 2) {
      labelArray = finalArray.map(item => `${item.answer} Star`)
    }
    series = finalArray.map(item => item.count)
  }
  // labels: ["Excellent", "best", "Good", "Average", "Need Improvement"],
  // const series = [55, 55, 55, 17, 15]
  const options = {
    labels: labelArray,
    colors: ["#34c38f", "#5b73e8", "#f1b44c", "#50a5f1", "#f46a6a"],
    legend: {
      show: !0,
      position: "right",
      horizontalAlign: "right",
      verticalAlign: "middle",
      floating: !1,
      fontSize: "14px",
      offsetX: 0,
      itemMargin: {
        horizontal: 0, // Adjust the horizontal gap between labels
        vertical: 15, // Adjust the vertical gap between labels
      },
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: !1,
          },
        },
      },
    ],
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="pie"
      height="280"
      className="apex-charts"
    />
  )
}

export default PieChart
