import React, { useEffect, useState } from "react"
import { Row, Col, Button } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import { Box, Divider } from "@mui/material"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import GradingIcon from "@mui/icons-material/Grading"
// import sbibank from "../../../assets/images/sbibank.svg"
// import { AvField, AvForm } from "availity-reactstrap-validation"
// import Select from "react-select"
import image1 from "../../../assets/images/image5.png"
import { useLocation } from "react-router-dom";
import { get } from "../../../helpers/api_helper"
import { useParams } from "react-router-dom";
import { object } from "yup"
import moment from "moment"
import { API_URL } from "../../../config"

const Testdetails = props => {
  const [tab3, setTab3] = React.useState("1");
  const [testDetails, setTestDetails] = React.useState({});
  const [isFetching, setIsFetching] = React.useState(true);
  const [questionAnswerList, setQuestionAnswerList] = React.useState([])

  useEffect(() => {
    fetchTestDetails()
  }, [])

  const location = useLocation();
  const itemData = location.state?.profileId;
  const { Id: testId } = useParams();
  
  const handleChangeTab = (event, newValue) => {
    setTab3(newValue)
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Question",
        field: "question",
        width: 150,
      },
      // {
      //   label: "Option 1",
      //   field: "option1",
      //   width: 150,
      // },
      // {
      //   label: "Option 2",
      //   field: "option2",
      //   width: 150,
      // },
      // {
      //   label: "Option 3",
      //   field: "option3",
      //   width: 150,
      // },
      // {
      //   label: "Option 4",
      //   field: "option4",
      //   width: 150,
      // },
      {
        label: "Correct Answer",
        field: "answer",
        width: 150,
      },
      {
        label: "Student Answer",
        field: "selectedAnswer",
        width: 150,
      },
      {
        label: "Status",
        field: "action",
        width: 150,
      },
    ],
    rows: questionAnswerList,
  }


  async function fetchTestDetails() {
    try {
      get(`/screen-test/result-details?profile=${itemData}&_id=${testId}`).then((res) => {
        setTestDetails(res.data);
        // if (res.data.questions[0].answer != null) {
          let data = res.data.questions.map((item, index) => {
            let status;
            if (item.status === 0) {
              status = <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fa fa-check"
                  style={{
                    cursor: "pointer",
                    fontSize: "1em",
                    marginRight: "0.5rem",
                    marginLeft: "0.5rem",
                    color: "green"
                  }}
                ></i>
              </div>
            } else {
              status = <div style={{ display: "flex", justifyContent: "center", fontSize: "18px", fontWeight: "600", color: "red" }}>
                x
              </div>
            }

            return {
              id: index + 1,
              action: status,
              ...item
            };
          });

          setQuestionAnswerList(data)
        // }

      }).catch((error) => {
        console.log("Error in fetchTestDetails =", error)
      }).finally(
        setIsFetching(false)
      )
    } catch (error) {
      console.log("Error in Testdetails Page inside fethTestDetails =", error)
    }
  }


  // Formates the date to DD-MM-YYYY //
  function formatDate(inputDate) {
    const parts = inputDate.split('-');
    if (parts.length !== 3) {
      return "Invalid date format";
    }
    const [year, month, day] = parts;
    const date = new Date(`${year}-${month}-${day}`);
    const formattedDate = `${("0" + date.getDate()).slice(-2)}-${("0" + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;
    return formattedDate;
  }

  return (
    <React.Fragment>
      {!isFetching && Object.keys(testDetails).length !== 0 && <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb
            title="Home"
            breadcrumbItem={`Test Details - ${testDetails?.profile?.firstname} ${testDetails?.profile?.lastname}`}
          />
          <Row>
            <Col xl="12">
              {/* First Dialogue */}

              <div>
                <div className="row">
                  <div className="col-xl-3">
                    <div
                      className="card"
                      style={{
                        // width: "fit-content",
                        // width: "351px",
                        height: "max-content",
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                      }}
                    >
                      <div className="card-body">
                        <div className="float-end dropdown"></div>
                        <h5
                          className="mt-2 mb-2"
                          style={{
                            textAlign: "center",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}
                        >
                          {testDetails?.profile?.firstname} {testDetails?.profile?.lastname}
                        </h5>
                        <div
                          className="text-muted"
                          style={{
                            textAlign: "center",
                            color: "green !important",
                            fontFamily: "IBM Plex Sans,sans-serif",
                            fontSize: "14px",
                            marginBottom: "3px",
                          }}
                        >
                          <div
                            class="btn-group btn-group-sm"
                            role="group"
                            aria-label="Basic example"
                          >
                            <img
                              style={{ width: "60px" }}
                              src={testDetails.profile.profile ? `${API_URL}${testDetails.profile.profile}` : '/ProfileAvatar.webp'}
                              alt="Profile"
                              onError={(e) => {
                                e.target.src = '/ProfileAvatar.webp'
                              }}
                            />
                          </div>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />

                        <Divider />
                        <div className="mt-2" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <p className="mb-1">Student ID:</p>
                          <h5 className="font-size-14">
                            {testDetails.profile.uniqueId}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2">
                          <p className="mb-1">Test Name:</p>
                          <h5 className="font-size-14">
                            {testDetails.test.name}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                   
                        {/* <div className="mt-2">
                          <p className="mb-1">Batch:</p>
                          <h5 className="font-size-14">
                            Artificial Intelligence - B1???
                          </h5>
                        </div> */}
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <p className="mb-1">Test Date:</p>
                          <h5 className="font-size-14">
                            {formatDate(testDetails.startDate)}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <p className="mb-1">Test Status:</p>
                          <h5 className="font-size-14" style={{ fontWeight: "500",  }}>
                            {testDetails.timeTaken ? "Completed" : 'Not Attended'}
                          </h5>
                        </div>
            
                        <hr style={{ margin: "0",color: "#00afc3" }} />
                        <div className="mt-2" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <p className="mb-1">Start Date:</p>
                          <h5 className="font-size-14" style={{ fontWeight: "500",  }}>
                            {formatDate(testDetails?.startDate)}
                          </h5>
                        </div>
                        <hr style={{ margin: "0",color: "#00afc3" }} />
                        <div className="mt-2" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <p className="mb-1">Start Time:</p>
                          <h5 className="font-size-14" style={{ fontWeight: "500",  }}>
                            {moment(testDetails?.startTime,"HH:mm s").format("hh:mm a")}
                          </h5>
                        </div>
                        <hr style={{ margin: "0",color: "#00afc3" }} />
                        <div className="mt-2" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <p className="mb-1">End Date:</p>
                          <h5 className="font-size-14" style={{ fontWeight: "500",  }}>
                          {formatDate(testDetails?.endDate)}
                          </h5>
                        </div>
                        <hr style={{ margin: "0",color: "#00afc3" }} />
                        <div className="mt-2" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <p className="mb-1">End Time:</p>
                          <h5 className="font-size-14" style={{ fontWeight: "500",  }}>
                            {moment(testDetails?.endTime, "HH:mm s").format("hh:mm a")}
                          </h5>  
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <p className="mb-1">Time Taken:</p>
                          <h5 className="font-size-14" style={{ fontWeight: "500", }}>
                            {testDetails.timeTaken} Minutes
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />

<div className="mt-2" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
  <p className="mb-1">Total Questions:</p>
  <h5 className="font-size-14" style={{ fontWeight: "500" }}>
    {testDetails?.test?.totalQuestions}
  </h5>
</div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <p className="mb-1">Attended:</p>
                          <h5 className="font-size-14" style={{ fontWeight: "500", color: "green" }}>
                            {testDetails.result.attended}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <p className="mb-1">Correct Answer:</p>
                          <h5 className="font-size-14" style={{ fontWeight: "500", color: "#00afc3" }}>
                            {testDetails.result.correct}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <p className="mb-1">Wrong Answer:</p>
                          <h5 className="font-size-14" style={{ fontWeight: "500", color: "red" }}>
                            {testDetails.result.wrong}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <p className="mb-1">Total Score:</p>
                          <h5 className="font-size-14" style={{ fontWeight: "500", color: "#006aae" }}>
                            {testDetails.result.score}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <p className="mb-1">Result Status:</p>
                          <h5 className="font-size-14" style={{ fontWeight: "500", }}>
                            {testDetails.resultStatus == 0 ? "Pass":"Fail"}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />

                      </div>
                    </div>
                  </div>

                  <div className="col-xl-9" style={{ marginBottom: "30px" }}>
                    <div
                      className="mb-0 card"
                      style={{
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={tab3}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              indicatorColor="primary"
                              textColor="primary"
                              variant="fullWidth"
                              onChange={handleChangeTab}
                              aria-label="lab API tabs example"
                            >
                              <Tab
                                icon={
                                  <GradingIcon style={{ fontSize: "20px" }} />
                                }
                                label="Test Details"
                                value="1"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                            </TabList>
                          </Box>
                          <TabPanel
                            value="1"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >

                            <Row>
                              <Col className="col-12">
                                <MDBDataTable
                                  id="appliedDataId"
                                  responsive
                                  bordered
                                  data={data}
                                  searching={true}
                                  info={false}
                                  disableRetreatAfterSorting={true}
                                />
                              </Col>
                            </Row>
                          </TabPanel>
                        </TabContext>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>}
    </React.Fragment>
  )
}
export default Testdetails
