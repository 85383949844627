import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link, useLocation } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"
import Cookies from "js-cookie"

//Import images
import moment from "moment"

import { get, put } from "../../../helpers/api_helper"
import { timeSince } from "../../../helpers/functions"
//i18n
import { withTranslation } from "react-i18next"

const NotificationDropdown = props => {
  const [menu, setMenu] = useState(false)
  const [count, setCount] = useState(0)
  const [list, setList] = useState([])

  const location = useLocation()

  const getNotificationCount = () => {
    get("/notification/get_count").then(res => {
      setCount(res.count)
      setList(res.data)
    })
  }
  const handleClickReadAll = () => {
    put("/notification/read_all").then(res => {
      if (location.pathname === "/notifications")
        window.location.href = "/notifications"
      setMenu(false)
      getNotificationCount()
    })
  }
  const readNotification = id => {
    put("/notification/read", { id })
      .then(res => {})
      .catch(err => {})
  }

  useEffect(() => {
    if (Cookies.get("_token")) getNotificationCount()
  }, [])

  useEffect(() => {
    if (Cookies.get("_token")) {
      const notification = setInterval(function () {
        getNotificationCount()
      }, 60000)

      return () => {
        clearInterval(notification)
      }
    }
  }, [])
  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => {
          if (count === 0) setMenu(false)
          else setMenu(!menu)
        }}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="uil-bell"></i>
          <span className="badge bg-danger rounded-pill">{count}</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 font-size-16">
                  {" "}
                  {props.t("Notification")}{" "}
                </h6>
              </Col>
              <div className="col-auto">
                <Link to="#!" className="small" onClick={handleClickReadAll}>
                  {" "}
                  Mark all as read
                </Link>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {list &&
              list.map((item, index) => {
                return (
                  <Link
                    to={item.url}
                    onClick={() => {
                      setMenu(false)
                      readNotification(item._id)
                      getNotificationCount()
                    }}
                    className="text-reset notification-item"
                  >
                    <div className="d-flex align-items-start">
                      <div className="avatar-xs me-2">
                        <span
                          className="avatar-title rounded-circle font-size-16"
                          style={{ backgroundColor: "#1a71b5" }}
                        >
                          <i className="fas fa-bell" />
                        </span>
                      </div>
                      <div className="flex-1 w-100">
                        <h6 className="mt-0 mb-1">{item?.title}</h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1">{item?.body}</p>
                          <Row>
                            <Col md="4">
                              <p className="mb-0">
                                <i className="mdi mdi-clock-outline" />{" "}
                                {timeSince(
                                  new Date(
                                    moment(item?.date).format(
                                      "YYYY-MM-DD HH:mm:ss"
                                    )
                                  ),
                                  new Date(
                                    moment().format("YYYY-MM-DD HH:mm:ss")
                                  )
                                )}
                              </p>
                            </Col>
                            <Col md="8">
                              <p className="mb-0">
                                {moment(item?.date).format(
                                  "DD-MM-YYYY hh:mm a"
                                )}
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Link>
                )
              })}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 text-center"
              to="/notification"
            >
              <i className="uil-arrow-circle-right me-1"></i>{" "}
              {props.t("View all")}{" "}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}
