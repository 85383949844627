import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import withRouter from "../../components/Common/withRouter"

import { logoutUser } from "../../store/actions"
import { useLocation, useNavigate } from "react-router-dom"
import { APP_NAME } from "../../config"
import { getMenuSuccessful } from "../../store/menu/actions"

const Logout = () => {
  document.title = ` Logout | ${APP_NAME} `

  const history = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    dispatch(logoutUser(history, location?.state?.refreshToken || false))
    dispatch(getMenuSuccessful({data:null}))
    sessionStorage.removeItem ("module")
    sessionStorage.removeItem("module_code")
    sessionStorage.removeItem("module_id")
    sessionStorage.removeItem("module_url") 
    sessionStorage.removeItem("branch")
  }, [dispatch, history, location]) 

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
  logoutUser: PropTypes.func,
}

export default withRouter(connect(null, { logoutUser })(Logout))
