import React, { useEffect, useRef, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import { OverlayTrigger, Tooltip, Modal } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import $ from "jquery"
import { get, post, del, put } from "../../../../helpers/api_helper"
import { getBranches, getUsersOptions } from "../../../../store/options/actions"
import { useDispatch, useSelector } from "react-redux"
import { API_URL, PRIVILEGES } from "../../../../config"
import axios from "axios"
import toastr from "toastr"
import {
  EditorState,
  // ContentState,
  // convertFromHTML,
  // convertToRaw,
} from "draft-js"
// import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
// import draftToHtml from "draftjs-to-html"
import Swal from "sweetalert2"

import SunEditor from "suneditor-react"
import plugins from "suneditor/src/plugins"
import { en } from "suneditor/src/lang"
import { EditorView } from "codemirror"
import katex from "katex"
import "suneditor/dist/css/suneditor.min.css"
import "katex/dist/katex.min.css"
import useBranch from "../../../../hooks/useBranch"
import { generatePermalink } from "../../../../helpers/functions"
import SelectErrorMessage from "../../../../components/Common/SelectErrorMessage"
// import { AiOutlineEye } from "react-icons/ai"
import "./style.scss"
const Creation = props => {
  const optionssun = {
    plugins: plugins,
    height: 250,
    EditorView: {
      src: EditorView,
      options: {
        indentWithTabs: true,
        tabSize: 2,
      },
    },
    katex: katex,
    lang: en,
    buttonList: [
      [
        "font",
        "fontSize",
        "formatBlock",
        "bold",
        "underline",
        "italic",
        "paragraphStyle",
        "blockquote",
        "strike",
        "subscript",
        "superscript",
        "fontColor",
        "hiliteColor",
        "textStyle",
        "removeFormat",
        "undo",
        "redo",
        "outdent",
        "indent",
        "align",
        "horizontalRule",
        "list",
        "lineHeight",
        "table",
        "link",
        // 'image',
        // 'video',
        // 'audio',
        // You must add the 'katex' library at options to use the 'math' plugin.
        // 'math',
        // You must add the "imageGalleryUrl".
        // 'imageGallery',
        "fullScreen",
        "showBlocks",
        "codeView",
        "preview",
        // 'print'
        // 'save',
        // 'template'
      ],
    ],
  }

  // const [popupView, setPopupView] = useState(false)
  const navigate = useNavigate()
  const [master, setMaster] = useState({
    installment: false,
    installments: [],
    videoUrl: "",
    csp: [],
  })
  const formRef = useRef()
  const filterRef = useRef()
  const initialState = {
    from: "",
    to: "",
    category: "",
    status: "",
  }
  // const [filterObject, setFilterObject] = useState(initialState)
  const [eventCategory, setEventCategory] = useState([])
  const [eventStatus, setEventStatus] = useState([
    { label: "Upcoming", value: 0 },
    { label: "Completed", value: 1 },
    { label: "Ongoing", value: 2 },
  ])
  const [selectedFilterCategory, setSelectedFilterCategory] = useState(null)
  const [selectedFilterStatus, setSelectedFilterStatus] = useState(null)
  const [selectedFromFilter, setSelectedFromFilter] = useState("")
  const [selectedToFilter, setSelectedToFilter] = useState("")
  const [state, setState] = useState(false)

  const [isDisabled, setisDisabled] = useState(false)

  const [lastReservation, setLastReservation] = useState(null)

  const gstOption = [
    { label: "0%", value: "" },
    { label: "5%", value: 1.05 },
    { label: "12%", value: 1.12 },
    { label: "18%", value: 1.18 },
    { label: "28%", value: 1.28 },
  ]

  const [selectedGst, setSelectedGst] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [categoryOptions, setCategoryOptions] = useState([])
  const [communityOptions, setCommunityOptions] = useState([])
  const [selectedCSP, setSelectedCSP] = useState([])
  const [error, setError] = useState({})
  const [selectedType, setSelectedType] = useState(null)
  const [selectedCommunity, setSelectedCommunity] = useState(null)
  const [tableData, setTableData] = useState([])
  // const [slectperson, setslectperson] = useState(null)
  const [classRoom, setClassRoom] = useState([])
  const [selectedClassRoom, setSelectedClassRoom] = useState(null)
  // const [personlist, setpersonlist] = useState([])
  const [errors, setErrors] = useState({ categoryName: "", typeName: "" })
  const options = props.options
  const dispatch = useDispatch()

  const [eventPartners, setEventPartners] = useState([])
  const [selectedPartner, setSelectedPartner] = useState(null)

  const isBefore = false
  // const isBefore = moment(moment(master?.startDate).add(1, "day")).isBefore()

  const [branch] = useBranch()
  // console.log("selectedcsp", selectedCSP)

  useEffect(() => {
    dispatch(getBranches())
    // eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    dispatch(
      getUsersOptions({
        // branch: branch?.value || "",
        privilege: PRIVILEGES.INTERN,
      })
    )
  }, [dispatch, branch])

  useEffect(() => {
    return async () => {
      if (lastReservation) {
        await clearReservation(lastReservation)
      }
    }
  }, [lastReservation])

  useEffect(() => {
    if (master?.csp?.length > 0) fetchClass(master.csp.map(c => c.cspId))
    else setClassRoom([])
  }, [master.csp])

  const { branches, users } = useSelector(state => state.Options)

  useEffect(() => {
    fetch_category()
    fetch_community()
    // eslint-disable-next-line
  }, [options])

  useEffect(() => {
    fetchEventPartners()
  }, [])

  useEffect(() => {
    handleTableData()
  }, [
    state,
    options,
    selectedFilterCategory,
    selectedFilterStatus,
    selectedFromFilter,
    selectedToFilter,
  ])

  useEffect(() => {
    const fetchEventCategory = async () => {
      try {
        const res = await get("/options/event-category")
        setEventCategory(res.data)
      } catch (error) {}
    }

    fetchEventCategory()
  }, [])

  const clearReservation = async reservation => {
    await post(
      `classroomsorlabs/clear-booking?reservation=${reservation}`
    ).then(res => {
      console.log(res)
    })
  }

  function fetch_category() {
    get("/community/option").then(res => {
      var community_data =
        res.data &&
        res.data.map(el => {
          return {
            label: el?.community,
            value: el?._id,
          }
        })
      setCommunityOptions([{ options: community_data }])
    })
  }
  function fetch_community() {
    get("/events-category/list").then(res => {
      var category_data =
        res.data &&
        res.data.map(el => {
          return {
            label: el?.name,
            value: el?._id,
          }
        })
      setCategoryOptions([{ options: category_data }])
    })
  }

  // editior
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  )

  const fetchClass = async branch => {
    try {
      const response = await get(`/events/listClassrooms?branch=${branch}`)
      setClassRoom(response.data)
    } catch (error) {}
  }

  function resetAll() {
    setMaster({ installment: false, installments: [] })
    setEditorState(EditorState.createEmpty())
    setSelectedCSP([])
    setError({})
    // setMaster({})
    setSelectedCategory(null)
    setSelectedGst(null)
    setSelectedType(null)
    setSelectedCommunity(null)
    setSelectedClassRoom(null)
  }

  function handleTableData() {
    const filterQuery = `?category=${selectedFilterCategory?.value}&from=${selectedFromFilter}&to=${selectedToFilter}&eventStatus=${selectedFilterStatus?.value}`
    get(`events/list${filterQuery}`).then(res => {
      let result = res.data
      let eventData = []
      result?.map((item, indx) => {
        item.id = indx + 1
        item.date = moment(item?.date).format("DD-MM-YYYY")
        // item.time = moment(item?.startTime, "HH:mm:ss").format("hh:mm A");
        item.category = item?.category?.label
        item.staff = item?.addedBy?.label
        item.itemsName = generateTooltip(item?.eventname)
        item.action = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="copy-event-tooltip">Copy Event</Tooltip>}
            >
              <i
                className="fas fa-share"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginRight: "0.5rem",
                  marginTop: "2px",
                }}
                onClick={() => {
                  toTop()
                  handleCopyevent(item._id)
                }}
              ></i>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="copy-event-tooltip">Download QR CODE</Tooltip>
              }
            >
              <a
                href={`${API_URL}${item?.qrCodePdf}`}
                target="_blank"
                rel="noreferrer"
              >
                <i
                  className="fas fa-qrcode"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginRight: "0.5rem",
                  }}
                  // onClick={() => {
                  //   toTop()
                  //   handleCopyevent(item._id)
                  // }}
                ></i>
              </a>
            </OverlayTrigger>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
                marginTop: "2px",
              }}
              onClick={() => {
                navigate(`/creation/${item.uniqueId}`, {
                  state: { _id: item._id },
                })
              }}
            ></i>
            <i
              className="far fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
                marginTop: "2px",
              }}
              onClick={() => {
                toTop()
                handleUpdate(item._id)
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ fontSize: "1em", cursor: "pointer", marginTop: "2px" }}
              onClick={() => {
                handleDelete(item?._id)
              }}
            ></i>
          </div>
        )

        item.eventstatus1 =
          item.eventStatus === 0 ? (
            <h6 style={{ color: "#f1b44c" }}>Upcoming</h6>
          ) : item.eventStatus === 1 ? (
            <h6 style={{ color: "#f46a6a" }}>Completed</h6>
          ) : (
            <h6 style={{ color: "#34c38f" }}>Ongoing</h6>
          )

        // if (item.eventStatus === "0") {
        //   item.status = (
        //     <h6
        //       style={{
        //         color: "red",
        //       }}
        //     >
        //       {"Not Completed"}
        //     </h6>
        //   )
        // } else {
        //   item.status = (
        //     <h6
        //       style={{
        //         color: "green",
        //       }}
        //     >
        //       {"Completed"}
        //     </h6>
        //   )
        // }

        eventData.push(item)

        return item
      })
      setTableData(eventData)
    })
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      // {
      //   label: "Time",
      //   field: "time",
      //   width: 150,
      // },
      // {
      //   label: "Category",
      //   field: "category",
      //   width: 150,
      // },
      {
        label: "EventID",
        field: "uniqueId",
        width: 150,
      },
      {
        label: "Event Name",
        field: "name",
        width: 150,
      },
      {
        label: "Venue",
        field: "venue",
        sort: "desc",
        width: 400,
      },
      {
        label: "Status",
        field: "eventstatus1",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        width: 150,
      },
    ],
    rows: tableData,
  }

  // const validateEndTime = (value, ctx, input, cb) => {
  //   const { startDate, endDate, startTime } = master

  //   // Check if startDate and endDate are the same
  //   if (startDate === endDate) {
  //     const startDateTime = moment(
  //       `${startDate} ${startTime}`,
  //       "YYYY-MM-DD HH:mm"
  //     )
  //     const endDateTime = moment(`${endDate} ${value}`, "YYYY-MM-DD HH:mm")

  //     // Validate if end time is greater than start time
  //     if (endDateTime.isSameOrAfter(startDateTime)) {
  //       cb(true)
  //     } else {
  //       cb("End time should be greater than start time")
  //     }
  //   } else {
  //     // If startDate and endDate are different, no validation needed
  //     cb(true)
  //   }
  // }

  const handleChange = e => {
    let { name, value, type, checked } = e.target

    if (type === "radio") {
      value = value === "1" ? true : false
    } else if (type === "checkbox") value = !checked

    const data = { [name]: value }

    if (name === "fee") {
      data.installments = []
      let totalAmount = 0
      totalAmount += Number(value)
      data.netAmount = totalAmount
      data.taxableValue = 0
      data.taxAmount = 0
      data.taxableValue = parseInt(
        totalAmount - (totalAmount - totalAmount / Number(master.gstLabs) || 0)
      )
      data.taxAmount = parseInt(totalAmount - data.taxableValue)
      if (master?.installmentCount && master?.installment) {
        for (let i = 0; i < master?.installmentCount; i++) {
          data.installments.push(parseInt(value / master.installmentCount))
        }
      }
      if (master?.gstLabs) {
        const lab = gstOption.find(item => item.label === master.gstLabs).value
        const amount = Number(value) || null
        let taxableValue = 0
        let taxAmount = 0
        if (lab !== 0) {
          taxableValue = parseInt(amount - (amount - amount / Number(lab)))
          taxAmount = parseInt(amount - taxableValue)
        }

        data.taxableValue = taxableValue
        data.taxAmount = taxAmount
        // setMaster(pre => ({ ...pre, taxableValue, taxAmount, gstLabs: e.label }))
      }
    }
    if (name === "installmentCount" && master?.netAmount) {
      data.installments = []
      for (let i = 0; i < value; i++) {
        data.installments.push(parseInt(master.netAmount / value))
      }
    }

    setMaster(pre => ({ ...pre, ...data }))
  }

  const handleSelectGST = e => {
    setSelectedGst(e)
    const amount = Number(master?.fee) || null
    let taxableValue = 0
    let taxAmount = 0
    if (e.value !== 0) {
      taxableValue = parseInt(amount - (amount - amount / Number(e.value)))
      taxAmount = parseInt(amount - taxableValue)
    }

    setMaster(pre => ({ ...pre, taxableValue, taxAmount, gstLabs: e.label }))
  }

  // let handleDate = e => {
  //   var date1 = $("#start_date").val()
  //   var date2 = $("#end_date").val()

  //   let toDate = moment(date1)
  //   let fromDate = moment(date2)
  //   let result = fromDate.diff(toDate, "days")

  //   if (result + 1 <= 0) {
  //     setMaster({
  //       ...master,
  //       [e.target.name]: e.target.value,
  //       end_date: "",
  //     })
  //     date2 = ""
  //   } else {
  //     setMaster({
  //       ...master,
  //       [e.target.name]: e.target.value,
  //     })
  //   }
  // }

  const handleDate = e => {
    let { name, value } = e.target

    const data = { ...master, [name]: value }

    if (name === "startDate") data.endDate = null

    if (data.startDate && data.endDate) {
      const details = []
      for (
        let i = data.startDate;
        i <= data.endDate;
        i = moment(i).add(1, "days").format("YYYY-MM-DD")
      ) {
        let date = moment(i).format("YYYY-MM-DD")

        const dateExists = data?.booking?.find(item => item?.startDate === date)

        if (dateExists) details.push(dateExists)
        else details.push({ date, startDate: date, endDate: date })
      }
      data.booking = details
    }

    setMaster(data)
  }

  const handleSelectClass = async (e, qIndex) => {
    const details = { ...master }

    const data = details.booking[qIndex]

    let flag = false

    await post(`classroomsorlabs/reservation`, {
      classroom: e.value,
      date: data.startDate || data.date,
      startTime: data.startTime,
      endTime: data.endTime,
      oldReservationId: data?.reservationId || "",
    })
      .then(res => {
        data.reservationId = res.data._id
        data.selectedClassRoom = e
        data.classroom = e.value
        setLastReservation(data.reservationId)
      })
      .catch(err => {
        toastr.warning(err?.response?.data?.message || "Something went wrong")
        flag = true
        data.selectedClassRoom = data.selectedClassRoom
          ? data.selectedClassRoom
          : null
      })

    if (flag) {
      return
    }

    setMaster(details)
  }

  const handleTableChange = async (e, idx) => {
    const details = { ...master }

    let { name, value } = e.target

    name = name.split("-")[0]

    const data = details.booking[idx]

    data[name] = value

    if (data.reservationId) {
      clearReservation(data.reservationId)
    }
    data.reservationId = null
    data.selectedClassRoom = null
    data.classroom = null

    setMaster(details)
  }

  function handleTime(e) {
    let name = e.target.name
    let value = e.target.value
    setMaster({ ...master, [name]: value })

    if (name === "startTime" || name === "endTime") {
      const newStartTime = name === "startTime" ? value : master.startTime
      const newEndTime = name === "endTime" ? value : master.endTime

      timeCheck(master.startDate, newStartTime, master.endDate, newEndTime)
    }
  }

  const timeCheck = (start, startTime, end, endTime) => {
    if (start === end && startTime < endTime) console.log("Chose valid time")
  }

  function handleRegistration(e) {
    let name = e.target.name
    let value = e.target.value
    setMaster({ ...master, [name]: value })
  }

  function handleInputChange(e) {
    let { name, value } = e.target

    const data = { [name]: value }

    if (name === "name") {
      data.permalink = generatePermalink(value)
    }

    setMaster({ ...master, ...data })
  }

  const uploadImage = e => {
    const file = e.target.files[0]
    const name = e.target.name

    if (!file) {
      toastr.error("Please select an image file.")
      return
    }

    // Check if file is an image
    if (!file.type.startsWith("image")) {
      toastr.error("Please choose an image file.")
      e.target.value = ""
      return
    }

    // Create image object to get dimensions
    const img = new Image()
    img.src = URL.createObjectURL(file)

    img.onload = () => {
      // Check image dimensions
      if (!(img.width == 800 && img.height == 500)) {
        toastr.error("Image dimensions should be between 800x500 pixels")
        e.target.form.reset()
        return
      }

      // Check file size
      if (file.size > 200 * 1024) {
        toastr.error("Image size should be Lessthan 200KB")
        e.target.value = ""
        return
      }
      // Image is valid, proceed with upload
      const fd = new FormData()
      fd.append(name, file)
      axios
        .post(`${API_URL}Events/image/upload`, fd)
        .then(response => {
          if (response.data.status === 200) {
            setMaster({
              ...master,
              [name]: response.data.data.new_filename,
            })
          } else {
            toastr.error(response.data.message)
          }
        })
        .catch(error => {
          console.error("Error uploading image:", error)
        })
    }
  }

  const uploadBanner = e => {
    const file = e.target.files[0]
    const name = e.target.name
    if (!file) {
      toastr.error("Please select an image file.")
      return
    }
    // Check if file is an image
    if (!file.type.startsWith("image")) {
      toastr.error("Please choose an image file.")
      e.target.value = ""
      return
    }
    // Create image object to get dimensions
    const img = new Image()
    img.src = URL.createObjectURL(file)
    img.onload = () => {
      //   // Check image dimensions
      if (!(img.width >= 1600 && img.height >= 250)) {
        toastr.error("Image dimensions should be at least 1600x250 pixels")
        e.target.form.reset()
        return
      }
      // Check file size
      if (file.size > 300 * 1024 || file.size < 100 * 1024) {
        toastr.error("Image size should be between 100KB and 200KB")
        e.target.value = ""
        return
      }
      // Image is valid, proceed with upload
      const fd = new FormData()
      fd.append(name, file)
      axios
        .post(`${API_URL}events/image/banner`, fd)
        .then(response => {
          if (response.data.status === 200) {
            setMaster({
              ...master,
              [name]: response.data.data.new_filename,
            })
          } else {
            toastr.error(response.data.message)
          }
        })
        .catch(error => {
          console.error("Error uploading image:", error)
        })
    }
  }

  const uploadImage2 = e => {
    const file = e.target.files[0]
    const name = e.target.name

    if (!file) {
      toastr.error("Please select a file.")
      e.target.value = ""
      return
    }

    // Check if file is a PDF
    if (file.type !== "application/pdf") {
      toastr.error("Please choose a PDF file.")
      e.target.value = ""
      return
    }
    // Check file size
    if (file.size > 5 * 1024 * 1024) {
      toastr.error("PDF file size should be 5MB or less")
      e.target.value = ""
      return
    }
    // PDF is valid, proceed with upload
    const fd = new FormData()
    fd.append(name, file)
    axios
      .post(`${API_URL}Events/image/uploads`, fd)
      .then(response => {
        if (response.data.status === 200) {
          setMaster({
            ...master,
            [name]: response.data.data.new_filename,
          })
        } else {
          toastr.error(response.data.message)
        }
      })
      .catch(error => {
        console.error("Error uploading PDF:", error)
      })
  }

  const uploadThumbnail = e => {
    const file = e.target.files[0]
    const name = e.target.name

    if (!file) {
      toastr.error("Please select a file.")
      e.target.value = ""
      return
    }
    // Check if file is an image
    if (!file.type.startsWith("image")) {
      toastr.error("Please choose an image file.")
      e.target.value = ""
      return
    }
    // Check if file is a PDF
    // if (file.type !== "application/pdf") {
    //   toastr.error("Please choose a PDF file.")
    //   e.target.value = ""
    //   return
    // }
    const img = new Image()
    img.src = URL.createObjectURL(file)
    img.onload = () => {
      if (
        !(
          img.width >= 800 &&
          img.width <= 1000 &&
          img.height >= 700 &&
          img.height <= 900
        )
      ) {
        toastr.error("Image dimensions should be at least 800x700 pixels")
        e.target.form.reset()
        return
      }
      // Check file size
      // if (file.size > 5 * 1024 * 1024) {
      //   toastr.error("PDF file size should be 5MB or less")
      //   e.target.value = ""
      //   return
      // }
      // PDF is valid, proceed with upload
      const fd = new FormData()
      fd.append(name, file)
      axios
        .post(`${API_URL}Events/image/thumbnail`, fd)
        .then(response => {
          if (response.data.status === 200) {
            setMaster({
              ...master,
              [name]: response.data.data.new_filename,
            })
          } else {
            toastr.error(response.data.message)
          }
        })
        .catch(error => {
          console.error("Error uploading PDF:", error)
        })
    }
  }

  function handleSubmit(e) {
    e.preventDefault()

    if (
      selectedCSP.length === 0 ||
      selectedCommunity === null ||
      selectedCategory === null ||
      selectedClassRoom === null ||
      selectedType === null ||
      selectedPartner === null
    ) {
      setError(prevState => ({
        ...prevState,
        csp: selectedCSP.length === 0,
        community: selectedCommunity === null,
        category: selectedCategory === null,
        classroom: selectedClassRoom === null,
        type: selectedType === null,
        partner: selectedPartner === null,
      }))

      // return
    }

    if (!master.uploadedFile) {
      // Assuming 'master.image' represents the selected file
      setError(prevState => ({ ...prevState, uploadedFile: true }))
      return
    }

    if (
      master.videoUrl != "" &&
      master.videoUrl != undefined &&
      !master?.thumbnail
    ) {
      // Assuming 'master.image' represents the selected file
      setError(prevState => ({ ...prevState, Thumbnail: true }))
      return
    }

    if (selectedCategory === null) {
      let errorVal = errors
      if (selectedCategory === null) {
        errorVal.categoryName = "Please select category"
      }
      if (selectedType === null) {
        errorVal.typeName = "Please select type"
      }
      setErrors({ ...errorVal })
    } else {
      if (master._id) {
        setisDisabled(true)
        put(`events/edit/`, master)
          .then(res => {
            toastr.success(res.message)
            reset()
            formRef.current.reset()
            setisDisabled(false)
          })
          .catch(err => {
            toastr.error(err.response.data.message)
            setisDisabled(false)
          })
      } else {
        setisDisabled(true)
        post("events/add", master)
          .then(res => {
            toastr.success(res.message)
            reset()
            formRef.current.reset()
            setisDisabled(false)
          })
          .catch(err => {
            toastr.error(err.response.data.message)
            setisDisabled(false)
          })
      }
    }
    setEditorState(EditorState.createEmpty())
    handleTableData()
  }

  const resetFilter = () => {
    filterRef.current?.reset()
    setSelectedFromFilter("")
    setSelectedToFilter("")
    setSelectedFilterCategory(null)
    setSelectedFilterStatus(null)
    setState(!state)
  }

  function reset() {
    formRef.current.reset()
    setError({})
    setLastReservation(null)
    setSelectedCategory(null)
    setSelectedGst(null)
    setSelectedCommunity(null)
    setSelectedCSP([])
    setSelectedType(null)
    setMaster({ csp: [] })
    setErrors({ ...errors, typeName: "", categoryName: "" })
    handleTableData()
    setSelectedClassRoom(null)
    setSelectedPartner(null)
  }

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  function handleUpdate(id) {
    get(`events/list/${id}`)
      .then(res => {
        const result = res.data

        const selectedCsp = result?.csp.map(item => {
          item.label = item?.cspId?.name
          item.value = item?.cspId?._id

          item.selected = {
            label: `${item?.assign?.firstName || ""} ${
              item?.assign?.lastName || ""
            }`,
            value: item?.assign?._id,
          }
          return item
        })

        setSelectedCSP(selectedCsp)
        let selectedClass = classRoom.find(
          item => item.value === result.classes
        )
        setSelectedClassRoom(selectedClass)

        setSelectedCategory({
          label: result?.category?.name,
          value: result?.category?._id,
        })
        setSelectedCommunity({
          label: result?.community?.community,
          value: result?.community?._id,
        })

        setSelectedType({
          label: result.type === "0" ? "Online" : "Offline" || null,
          value: result.type || null,
        })

        setSelectedType({
          label: result.type === "0" ? "Online" : "Offline" || null,
          value: result.type || null,
        })

        setSelectedGst({
          label: result.gstLabs || null,
          value: result.gstLabs || null,
        })

        setSelectedPartner(result?.partner)

        const csps = result?.csp?.map(csp => ({
          ...csp,
          cspId: csp?.cspId?._id,
          assign: csp?.assign?._id,
        }))

        result.csp = csps
        result.category = result.category._id
        result.partner = result?.partner?.value

        if (result?.booking?.length > 0) {
          result.booking.map(book => {
            book.selectedClassRoom = {
              label: book?.classroom?.name,
              value: book?.classroom?._id,
            }
            book.classroom = book?.classroom?._id
          })
        }

        delete result.community

        setMaster(result)
      })
      .catch(err => {
        // Handle error here
      })
  }

  function handleCopyevent(id) {
    get(`events/list/${id}`)
      .then(res => {
        const result = res.data

        const selectedCsp = result?.csp.map(item => {
          item.label = item?.cspId?.name
          item.value = item?.cspId?._id

          item.selected = {
            label: `${item?.assign?.firstName || ""} ${
              item?.assign?.lastName || ""
            }`,
            value: item?.assign?._id,
          }
          return item
        })

        setSelectedCSP(selectedCsp)

        setSelectedCategory({
          label: result?.category?.name,
          value: result?.category?._id,
        })

        setSelectedType({
          label: result.type === "0" ? "Online" : "Offline" || null,
          value: result.type || null,
        })

        setSelectedGst({
          label: result.gstLabs || null,
          value: result.gstLabs || null,
        })
        const csps = result?.csp?.map(csp => ({
          ...csp,
          cspId: csp?.cspId?._id,
          assign: csp?.assign?._id,
        }))
        result.csp = csps
        result.category = result.category._id
        delete result._id
        delete result.startTime
        delete result.startDate
        delete result.registrationClosesBy
        delete result.endTime
        delete result.endDate
        delete result.community
        result.booking = []

        setMaster(result)
      })
      .catch(err => {
        console.error(err)
        // Handle error here
      })
  }

  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`Events/${id}`)
          .then(res => {
            toastr.success(res.message)
            handleTableData()
            formRef.current.reset()
          })
          .catch(err => {
            toastr.success(err.response.data.message)
          })
      }
    })
  }

  // Function to generate tooltip content
  const generateTooltip = name => {
    let displayText = name
    if (name && name.length > 20) {
      displayText = `${name.substring(0, 20)}...`
    }

    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip
            id="button-tooltip-2"
            style={{ position: "absolute", top: "-30px" }}
          >
            {name}
          </Tooltip>
        }
      >
        <span>{displayText}</span>
      </OverlayTrigger>
    )
  }

  const onChangeSuneditor = content => {
    setMaster(prevState => ({
      ...prevState, // Copy previous state
      eventDescriptionBefore: content, // Update editor content
    }))
  }

  const onChangeSuneditor2 = content => {
    setMaster(prevState => ({
      ...prevState, // Copy previous state
      eventDescriptionAfter: content, // Update editor content
    }))
  }

  const handleSelectAssign = (selectedValue, idx) => {
    const data = { ...master }
    data.csp[idx].assign = selectedValue.value
    setMaster(data)

    const selected = [...selectedCSP]
    selected[idx].selected = selectedValue
    setSelectedCSP(selected)
  }

  const handleClass = async item => {
    let flag = false

    const data = {
      classes: item.value,
      maxStrength: item.maxStrength,
    }

    // await post(`classroomsorlabs/reservation`, {
    //   classroom: item.value,
    //   startDate: master.startDate,
    //   startTime: master.startTime,
    //   endDate: master?.endDate,
    //   endTime: master.endTime,
    //   oldReservationId: master.reservationId,
    //   type: "event",
    // })
    //   .then(res => {
    //     console.log(res)
    //     const details = res.data
    //     data.reservationId = details._id
    //     setLastReservation(data.reservationId)
    //   })
    //   .catch(err => {
    //     toastr.warning(err?.response?.data?.message || "Something went wrong")
    //     flag = true
    //   })

    // if (flag) {
    //   return
    // }

    setMaster(prevState => ({ ...prevState, ...data }))
    setSelectedClassRoom(item)
  }

  const handleSelectBranch = async item => {
    setSelectedCSP(item)
    setSelectedClassRoom(null)

    if (lastReservation && !master.master?._id) {
      clearReservation(lastReservation)
    }

    setMaster(pre => ({
      ...pre,
      csp: item?.map(item => ({
        cspId: item?.value,
      })),
      classes: null,
    }))
  }

  // function fetchAllusercsp() {
  //   get(`/options/users?branch=${selectedCSP?.map(item => item._id)}`).then(
  //     res => {
  //       // console.log("resuser", res)
  //       let result = res.user
  //       setpersonlist(result)
  //     }
  //   )
  // }
  // useEffect(() => {
  //   fetchAllusercsp()
  // }, [selectedCSP])

  // console.log("permast", master)

  const fetchEventPartners = () => {
    get(`options/event-partner`).then(res => {
      setEventPartners(res.data)
    })
  }

  console.log(master, "Master")

  const validationDate = idx => {
    const date = master?.booking[idx - 1]?.startDate
    return moment(date).add(1, "day").format("YYYY-MM-DD")
  }

  return (
    <>
      {/* <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h5>Event Details</h5>
          </div>

          <button
            type="button"
            onClick={() => setPopupView(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body" style={{ paddingTop: "0px" }}>
          <Row>
            <Col md="12">
              <table
                id="product_available_day"
                className="table table-bordered dataTable"
              >
                <tr>
                  <th
                    class="mdl-td-c"
                    style={{ textAlign: "left", padding: "10px" }}
                  >
                    Category
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>inauguration</td>
                  <th class="mdl-td-c" style={{ textAlign: "left" }}>
                    CSP
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    CSP Kazhakkottam
                  </td>
                </tr>
                <tr>
                  <th
                    class="mdl-td-c"
                    style={{ textAlign: "left", padding: "10px" }}
                  >
                    Event
                  </th>
                  <th> : </th>
                  <td
                    style={{ paddingLeft: "20px", textAlign: "justify" }}
                    colSpan={5}
                  >
                    Datsi School for Storytellers’ Open House
                  </td>
                </tr>
                <tr>
                  <th
                    class="mdl-td-c"
                    style={{ textAlign: "left", padding: "10px" }}
                  >
                    Venue
                  </th>
                  <th> : </th>
                  <td
                    style={{ paddingLeft: "20px", textAlign: "justify" }}
                    colSpan={5}
                  >
                    Kinfra Film & Video Park, Sainik school PO Chanthavila,
                    Kazhakootam, Thiruvananthapuram, Kerala – 695585
                  </td>
                </tr>
                <tr>
                  <th
                    class="mdl-td-c"
                    style={{ textAlign: "left", padding: "10px" }}
                  >
                    Type
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>Offline</td>
                  <th class="mdl-td-c" style={{ textAlign: "left" }}>
                    Resource Person
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Mr. IP Laiju
                  </td>
                </tr>
                <tr>
                  <th
                    class="mdl-td-c"
                    style={{ padding: "10px 11px", textAlign: "left" }}
                  >
                    Start Date
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>14-10-2023</td>
                  <th class="mdl-td-c" style={{ textAlign: "left" }}>
                    Start Time
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    9:30 am
                  </td>
                </tr>
                <tr>
                  <th
                    class="mdl-td-c"
                    style={{ textAlign: "left", padding: "10px" }}
                  >
                    End Date
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>14-10-2023</td>
                  <th class="mdl-td-c" style={{ textAlign: "left" }}>
                    End Time
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    4:30 pm
                  </td>
                </tr>
                <tr>
                  <th
                    class="mdl-td-c"
                    style={{ textAlign: "left", padding: "10px" }}
                  >
                    Added By
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Admin
                  </td>
                </tr>
              </table>
            </Col>
          </Row>
        </div>
      </Modal> */}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Event Creation" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="needs-validation"
                    ref={formRef}
                    onValidSubmit={(e, v) => {
                      handleSubmit(e, v)
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Category</Label>
                          <Select
                            name="category"
                            classNamePrefix="select2-selection"
                            options={categoryOptions}
                            className="selectcategory"
                            value={selectedCategory}
                            validate={{ required: { value: true } }}
                            onChange={value => {
                              setSelectedCategory(value)
                              setMaster(pre => ({
                                ...pre,
                                category: value.value,
                              }))
                            }}
                          />
                          <SelectErrorMessage
                            show={selectedCategory === null && error.category}
                            customStyle={{
                              width: "100%",
                              fontSize: "87.5%",
                              color: "var(--bs-form-invalid-color)",
                            }}
                            message="Please select a category"
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>CSP</Label>
                          <Select
                            name="csp"
                            // isMulti
                            classNamePrefix="select2-selection"
                            options={branches}
                            value={selectedCSP}
                            className="selectcsp"
                            menuPosition="fixed"
                            onChange={selected =>
                              handleSelectBranch([
                                { ...selected, selected: null },
                              ])
                            }
                          />
                          <SelectErrorMessage
                            show={selectedCSP.length === 0 && error.csp}
                            customStyle={{
                              width: "100%",
                              fontSize: "87.5%",
                              color: "var(--bs-form-invalid-color)",
                            }}
                            message="Please select a CSP"
                          />
                        </div>
                      </Col>

                      {selectedCSP?.map((item, idx) => (
                        <>
                          <Col md="3">
                            <div className="mb-3">
                              <Label>{item?.label} Assign Staff</Label>
                              <Select
                                name="staff"
                                options={users.filter(user =>
                                  user?.branch?.includes(item?.value)
                                )}
                                value={item?.selected}
                                onChange={value => {
                                  handleSelectAssign(value, idx)
                                }}
                                classNamePrefix="select2-selection"
                              />
                            </div>
                          </Col>
                        </>
                      ))}

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Venue</Label>
                          <AvField
                            name="venue"
                            className="form-control"
                            type="text"
                            placeholder="Venue Name"
                            errorMessage="Enter Venue"
                            // validate={{ required: { value: true } }}
                            value={master?.venue || ""}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Event Name</Label>
                          <AvField
                            name="name"
                            className="form-control"
                            type="text"
                            placeholder="Event Name"
                            errorMessage="Enter Name"
                            validate={{ required: { value: true } }}
                            value={master?.name || ""}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Permalink </Label>
                          <AvField
                            name="permalink"
                            className="form-control"
                            type="text"
                            placeholder="Permalink"
                            errorMessage="Enter permalink"
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: /^[a-z0-9]+(-[a-z0-9]+)*$/,
                                errorMessage: "Invalid Permalink",
                              },
                            }}
                            value={master?.permalink || ""}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>

                      <Row className="mb-3">
                        <Col md={2}>
                          <div className="mb-3">
                            <Label> Fee</Label>
                            <AvField
                              name="fee"
                              onChange={handleChange}
                              className="form-control"
                              type="number"
                              min={0}
                              placeholder="Fee"
                              value={master?.fee || ""}
                              errorMessage="Enter Fee"
                              validate={{ required: { value: true } }}
                            />
                          </div>
                        </Col>

                        <Col md="2">
                          <div className="mb-3">
                            <Label>GST slabs</Label>
                            <Select
                              name="gstLabs"
                              classNamePrefix="select2-selection"
                              onChange={handleSelectGST}
                              options={gstOption}
                              className="selectgst"
                              value={selectedGst}
                            />
                          </div>
                        </Col>

                        <Col>
                          <div className=" mt-3 d-flex align-items-center">
                            {selectedGst && (
                              <>
                                {!!master?.taxableValue > 0 &&
                                  master?.gstLabs && (
                                    <div className="mt-3 me-3">
                                      <Label>
                                        Taxable value :{" "}
                                        {master?.taxableValue || ""}
                                      </Label>
                                    </div>
                                  )}

                                {master?.taxAmount >= 0 && master?.gstLabs && (
                                  <div className="mt-3 me-3">
                                    <Label>
                                      Tax Amount : {master?.taxAmount}
                                    </Label>
                                  </div>
                                )}
                              </>
                            )}

                            {master?.fee ? (
                              <>
                                {master?.netAmount === 0 ? (
                                  <div className="mt-3 me-3">
                                    <Label>Free</Label>
                                  </div>
                                ) : (
                                  <div className="mt-3 me-3">
                                    <Label>Fee: {master?.fee || ""}</Label>
                                  </div>
                                )}
                              </>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Event partner</Label>
                          <Select
                            name="registrationClosesBy"
                            options={eventPartners}
                            className="selectcommunity"
                            value={selectedPartner}
                            onChange={value => {
                              setSelectedPartner(value)
                              setMaster(pre => ({
                                ...pre,
                                partner: value.value,
                              }))
                            }}
                          />
                          <SelectErrorMessage
                            show={selectedPartner === null && error.partner}
                            customStyle={{
                              width: "100%",
                              fontSize: "87.5%",
                              color: "var(--bs-form-invalid-color)",
                            }}
                            message="Please select a partner"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Community</Label>
                          <Select
                            name="registrationClosesBy"
                            options={communityOptions}
                            className="selectcommunity"
                            value={selectedCommunity}
                            onChange={value => {
                              setSelectedCommunity(value)
                              setMaster(pre => ({
                                ...pre,
                                community: value.value,
                              }))
                            }}
                            // Add required attribute
                            required
                          />
                          <SelectErrorMessage
                            show={selectedCommunity === null && error.community}
                            customStyle={{
                              width: "100%",
                              fontSize: "87.5%",
                              color: "var(--bs-form-invalid-color)",
                            }}
                            message="Please select a Community"
                          />
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="mb-3">
                          <Label>Resource Person Name </Label>
                          <AvField
                            name="resourcePerson"
                            onChange={handleChange}
                            className="form-control"
                            type="text"
                            placeholder="Resource Person Name"
                            value={master?.resourcePerson || ""}
                            errorMessage="Enter Resource Person"
                            validate={{ required: { value: true } }}
                          />
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="mb-3">
                          <Label>Resource Person Mobile </Label>
                          <AvField
                            name="resourcePersonMobile"
                            onChange={handleChange}
                            className="form-control"
                            type="text"
                            placeholder="Resource Person Mobile"
                            value={master?.resourcePersonMobile || ""}
                            errorMessage="Enter Resource Person Mobile"
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: "^(0|91)?[6-9][0-9]{9}$",
                                errorMessage: "Enter valid mobile no",
                              },
                            }}
                          />
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="mb-3">
                          <Label>Resource Person Email </Label>
                          <AvField
                            name="resourcePersonEmail"
                            onChange={handleChange}
                            className="form-control"
                            type="email"
                            placeholder="Resource Person Email"
                            value={master?.resourcePersonEmail || ""}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Type</Label>
                          <Select
                            name="type"
                            value={selectedType}
                            classNamePrefix="select2-selection"
                            options={[
                              { label: "Online", value: 0 },
                              { label: "Offline", value: 1 },
                            ]}
                            onChange={value => {
                              setSelectedType(value)
                              setMaster(pre => ({
                                ...pre,
                                type: value.value,
                              }))
                            }}
                            required
                          />
                          <SelectErrorMessage
                            show={selectedType === null && error.type}
                            customStyle={{
                              width: "100%",
                              fontSize: "87.5%",
                              color: "var(--bs-form-invalid-color)",
                            }}
                            message="Please select a type"
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Registration Closes by </Label>
                          <AvField
                            name="registrationClosesBy"
                            className="form-control"
                            type="date"
                            value={master?.registrationClosesBy || ""}
                            onChange={e => handleRegistration(e)}
                            errorMessage="This field is required"
                            validate={{ required: { value: true } }}
                            max={master?.startDate}
                          />
                        </div>
                      </Col>

                      <Col md={2}>
                        <div className="mb-3">
                          <Label>Max Strength</Label>
                          <AvField
                            name="maxStrength"
                            onChange={handleChange}
                            className="form-control"
                            type="text"
                            placeholder="Max strength"
                            value={master?.maxStrength || ""}
                            errorMessage="Enter max strength"
                            min={1}
                            validate={{ required: { value: true } }}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Upload Image{" "}
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="copy-event-tooltip">
                                  {" "}
                                  Specifications: Image should be of type PNG or
                                  JPEG.Dimensions must be exactly 800x500 pixels
                                  Size Should be lessthan 200kb
                                </Tooltip>
                              }
                            >
                              <i
                                className="fas fa-info-circle"
                                style={{
                                  cursor: "pointer",
                                  fontSize: "1em",
                                  marginRight: "0.5rem",
                                }}
                              ></i>
                            </OverlayTrigger>
                          </Label>
                          {master?.uploadedFile ? (
                            <div div className="img-wraps d-flex flex-column">
                              {master?.uploadedFile &&
                              master?.uploadedFile.startsWith("http") ? (
                                <img
                                  alt=""
                                  width="150"
                                  height="150"
                                  src={`${master?.uploadedFile}`}
                                />
                              ) : (
                                <img
                                  alt=""
                                  width="150"
                                  height="150"
                                  src={`${API_URL}${master?.uploadedFile}`}
                                />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={() => {
                                  setMaster(pre => ({
                                    ...pre,
                                    uploadedFile: null,
                                  }))
                                }}
                                style={{ width: "150px", marginTop: "4px" }}
                                type="button"
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <input
                              name="uploadedFile"
                              type="file"
                              className="form-control"
                              id="validationCustom03"
                              onChange={uploadImage}
                              rows="1"
                            />
                          )}
                          <SelectErrorMessage
                            show={
                              master?.uploadedFile == null && error.uploadedFile
                            }
                            customStyle={{
                              width: "100%",
                              fontSize: "87.5%",
                              color: "var(--bs-form-invalid-color)",
                            }}
                            message="Please Upload Image"
                          />
                        </div>
                        {/* <p style={{ fontSize: ".7rem", marginTop: "-1rem" }}>
                          (800*600)-(1600*1200)/100-200kb
                        </p> */}
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Upload Banner{" "}
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="copy-event-tooltip">
                                  {" "}
                                  Specifications: Image should be PNG or JPEG,
                                  with dimensions 1600*300 pixels, and size
                                  100-200kb.
                                </Tooltip>
                              }
                            >
                              <i
                                className="fas fa-info-circle"
                                style={{
                                  cursor: "pointer",
                                  fontSize: "1em",
                                  marginRight: "0.5rem",
                                }}
                              ></i>
                            </OverlayTrigger>
                          </Label>
                          {master?.banner ? (
                            <div div className="img-wraps d-flex flex-column">
                              {master?.banner &&
                              master?.banner.startsWith("http") ? (
                                <img
                                  alt=""
                                  width="250"
                                  height="150"
                                  src={`${master?.banner}`}
                                />
                              ) : (
                                <img
                                  alt=""
                                  width="250"
                                  height="150"
                                  src={`${API_URL}${master?.banner}`}
                                />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={() => {
                                  setMaster(pre => ({
                                    ...pre,
                                    banner: null,
                                  }))
                                }}
                                style={{ width: "250px", marginTop: "4px" }}
                                type="button"
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <input
                              name="banner"
                              type="file"
                              className="form-control"
                              id="validationCustom03"
                              onChange={uploadBanner}
                              rows="1"
                            />
                          )}
                        </div>
                        {/* <p style={{ fontSize: ".7rem", marginTop: "-1rem" }}>
                          (1600*250)/100-300kb
                        </p> */}
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Upload File
                          </Label>
                          {master?.pdf ? (
                            <div div className="img-wraps d-flex flex-column">
                              {master?.pdf && master?.pdf.startsWith("http") ? (
                                <img
                                  alt=""
                                  width="150"
                                  height="200"
                                  src={`${master?.pdf}`}
                                />
                              ) : (
                                <object
                                  type="application/pdf"
                                  alt=""
                                  width="150"
                                  height="200"
                                  data={`${API_URL}${master?.pdf}`}
                                />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={() => {
                                  setMaster(pre => ({
                                    ...pre,
                                    pdf: null,
                                  }))
                                }}
                                style={{ width: "150px", marginTop: "4px" }}
                                type="button"
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <input
                              name="pdf"
                              type="file"
                              className="form-control"
                              id="validationCustom03"
                              onChange={uploadImage2}
                              rows="1"
                            />
                          )}
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Video URL</Label>
                          <AvField
                            type="url"
                            name="videoUrl"
                            placeholder="Video URL"
                            onChange={handleInputChange}
                            value={master?.videoUrl || ""}
                          />
                        </div>
                      </Col>
                      {master?.videoUrl && (
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">
                              Thumbnail{" "}
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="copy-event-tooltip">
                                    {" "}
                                    Specifications: Image should be PNG or JPEG,
                                    with dimensions 800x700, and size 100-200kb.
                                  </Tooltip>
                                }
                              >
                                <i
                                  className="fas fa-info-circle"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "1em",
                                    marginRight: "0.5rem",
                                  }}
                                ></i>
                              </OverlayTrigger>
                            </Label>
                            {master?.thumbnail ? (
                              <div div className="img-wraps d-flex flex-column">
                                {master?.thumbnail &&
                                master?.thumbnail.startsWith("http") ? (
                                  <img
                                    alt=""
                                    width="150"
                                    height="150"
                                    src={`${master?.thumbnail}`}
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    width="150"
                                    height="150"
                                    src={`${API_URL}${master?.thumbnail}`}
                                  />
                                )}
                                <button
                                  className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                  onClick={() => {
                                    setMaster(pre => ({
                                      ...pre,
                                      thumbnail: null,
                                    }))
                                  }}
                                  style={{ width: "150px", marginTop: "4px" }}
                                  type="button"
                                >
                                  Delete
                                </button>
                              </div>
                            ) : (
                              <>
                                <input
                                  name="thumbnail"
                                  type="file"
                                  className="form-control"
                                  id="validationCustom03"
                                  onChange={uploadThumbnail}
                                  validate={{
                                    required: {
                                      value: master?.videoUrl !== "",
                                      errorMessage:
                                        "Thumbnail is required when Video URL is provided",
                                    },
                                  }}
                                  rows="1"
                                />

                                <SelectErrorMessage
                                  show={master?.videoUrl && error.Thumbnail}
                                  customStyle={{
                                    width: "100%",
                                    fontSize: "87.5%",
                                    color: "var(--bs-form-invalid-color)",
                                  }}
                                  message="Please Upload Thumbnail"
                                />
                              </>
                            )}
                          </div>
                          {/* <p style={{ fontSize: ".7rem", marginTop: "-1rem" }}>
                          (800*600)-(1600*1200)/100-200kb
                        </p> */}
                        </Col>
                      )}
                      <Row md={12}>
                        <Col md="2">
                          <div className="mb-3">
                            <Label>Start Date</Label>
                            <input
                              name="startDate"
                              className="form-control"
                              disabled={master?.startDate && isBefore}
                              type="date"
                              onChange={handleDate}
                              value={master?.startDate || ""}
                              validate={{ required: { value: true } }}
                              errorMessage="This field is required"
                              min={moment().format("YYYY-MM-DD")} // Set min attribute to today's date
                            />
                          </div>
                        </Col>

                        <Col md="2">
                          <div className="mb-3">
                            <Label>End Date</Label>
                            <AvField
                              name="endDate"
                              className="form-control"
                              disabled={master?.endDate && isBefore}
                              type="date"
                              value={master?.endDate || ""}
                              onChange={handleDate}
                              min={master?.startDate}
                              errorMessage="This field is required"
                              validate={{ required: { value: true } }}
                            />
                          </div>
                        </Col>

                        {master?.booking?.length > 0 && (
                          <Col md={12}>
                            <div className="d-flex justify-content-end me-3">
                              <Button
                                type="button"
                                size="xs"
                                color="primary"
                                className="btn-soft-primary waves-effect waves-light p-1 px-2 m-2"
                                onClickCapture={() =>
                                  window.open("/class-availability", "_blank")
                                }
                              >
                                Check availability
                                <i className="uil uil-arrow-right ms-2"></i>
                              </Button>
                            </div>
                            <table className="table table-bordered mb-0">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Date</th>
                                  <th>From</th>
                                  <th>To</th>
                                  <th>Class</th>
                                </tr>
                              </thead>
                              <tbody>
                                {master?.booking.map((book, idx) => {
                                  return (
                                    <tr>
                                      <td style={{ width: "60px" }}>
                                        {idx + 1}
                                      </td>

                                      <td style={{ width: "180px" }}>
                                        <AvField
                                          style={{ textAlign: "end" }}
                                          name={`date-${idx}`}
                                          validate={{
                                            required: { value: true },
                                          }}
                                          onChange={e => {
                                            const data = { ...master }
                                            data.booking[idx].startDate =
                                              e.target.value
                                            setMaster(data)
                                          }}
                                          disabled
                                          value={book?.startDate}
                                          min={
                                            idx === 0
                                              ? null
                                              : validationDate(idx)
                                          }
                                          type="date"
                                          className="form-control numberTime"
                                        />
                                      </td>

                                      <td style={{ width: "180px" }}>
                                        <AvField
                                          style={{ textAlign: "end" }}
                                          name={`startTime-${idx}`}
                                          disabled={isBefore}
                                          onBlur={e => {
                                            handleTableChange(e, idx)
                                          }}
                                          validate={{
                                            required: {
                                              value: true,
                                            },
                                          }}
                                          value={book?.startTime}
                                          type="time"
                                          className="form-control numberTime"
                                        />
                                      </td>

                                      <td style={{ width: "180px" }}>
                                        <AvField
                                          style={{ textAlign: "end" }}
                                          name={`endTime-${idx}`}
                                          value={book?.endTime}
                                          disabled={isBefore}
                                          validate={{
                                            required: {
                                              value: true,
                                            },
                                          }}
                                          onBlur={e => {
                                            handleTableChange(e, idx)
                                          }}
                                          type="time"
                                          className="form-control numberTime"
                                        />
                                      </td>
                                      <td style={{ width: "280px" }}>
                                        <div className="mb-3">
                                          <Select
                                            name="class"
                                            classNamePrefix="select2-selection"
                                            menuPosition="fixed"
                                            options={classRoom}
                                            onChange={e =>
                                              handleSelectClass(e, idx)
                                            }
                                            value={book?.selectedClassRoom}
                                            isDisabled={
                                              !(
                                                book?.startTime &&
                                                book?.endTime &&
                                                !isBefore
                                              )
                                            }
                                          />
                                        </div>
                                      </td>

                                      <td
                                        style={{
                                          width: "30px",
                                          verticalAlign: "middle",
                                        }}
                                      >
                                        {!isBefore && (
                                          <span
                                            className="mr-1"
                                            style={{
                                              fontSize: "1.125rem",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              const data = { ...master }
                                              data.booking.splice(idx, 1)
                                              setMaster(data)
                                            }}
                                          >
                                            <i className="trash-btn mdi mdi-delete"></i>
                                          </span>
                                        )}
                                      </td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          </Col>
                        )}
                      </Row>

                      <hr style={{ margin: "0", color: "#00afc3" }} />

                      <Row className="mt-2">
                        <h5 className="mt-2">Upcoming</h5>
                        <Col md="12" className="events">
                          <SunEditor
                            setDefaultStyle="font-family: Arial; font-size: 14px; z-index:0"
                            style={{ zIndex: 0 }}
                            lang="en"
                            setOptions={optionssun}
                            onChange={content => onChangeSuneditor(content)}
                            setContents={master?.eventDescriptionBefore || ""}
                          />
                        </Col>
                      </Row>

                      <hr style={{ margin: "0", color: "#00afc3" }} />

                      <Row className="mt-2">
                        <h5 className="mt-2">Completed</h5>
                        <Col md="12">
                          <SunEditor
                            setDefaultStyle="font-family: Arial; font-size: 14px;"
                            lang="en"
                            setOptions={optionssun}
                            onChange={content => onChangeSuneditor2(content)}
                            setContents={master?.eventDescriptionAfter || ""}
                          />
                        </Col>
                      </Row>

                      <Col>
                        <div
                          className="mb-3 d-flex"
                          style={{ paddingTop: "25px" }}
                        >
                          <Button
                            color={`${master?._id ? "warning" : "primary"}`}
                            disabled={isDisabled}
                            type="submit"
                          >
                            {master?._id ? "Update" : "Submit"}
                          </Button>
                          <Button
                            className="mx-2"
                            color="danger"
                            type="button"
                            // onClick={resetAll}
                            onClick={reset}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm ref={filterRef}>
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>From</Label>
                          <AvField
                            name="from"
                            className="form-control"
                            value={selectedFromFilter}
                            max={selectedToFilter}
                            onChange={e => {
                              setSelectedFromFilter(e.target.value)
                            }}
                            type="date"
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>To</Label>
                          <AvField
                            name="to"
                            min={selectedFromFilter}
                            value={selectedToFilter}
                            onChange={e => {
                              setSelectedToFilter(e.target.value)
                            }}
                            className="form-control"
                            type="date"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Category</Label>
                          <Select
                            name="category"
                            classNamePrefix="select2-selection"
                            value={selectedFilterCategory}
                            onChange={selected => {
                              setSelectedFilterCategory(selected)
                            }}
                            options={eventCategory}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Status</Label>
                          <Select
                            name="status"
                            classNamePrefix="select2-selection"
                            value={selectedFilterStatus}
                            onChange={selected => {
                              setSelectedFilterStatus(selected)
                            }}
                            options={eventStatus}
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3" style={{ paddingTop: "25px" }}>
                          <Button
                            onClick={resetFilter}
                            color="danger"
                            type="submit"
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="eventcreateID"
                    bordered
                    responsive
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default Creation
