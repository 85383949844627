import React, { useState, useRef, useEffect } from "react"
import Breadcrumb from "../../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Label, Button, Modal } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import { MDBDataTable } from "mdbreact"
import toastr from "toastr"
import axios from "axios"
import { API_URL } from "../../../../config"
import { get, post, del, put } from "../../../../helpers/api_helper"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import image1 from "../../../../assets/images/PRP-190-2022-04-21-PRASANTH-1-scaled.jpg"
import moment from "moment"
import Swal from "sweetalert2"
import "./style.scss"
import { image } from "suneditor/src/plugins"

function Gallery() {
  const [master, setMaster] = useState({})
  const formRef = useRef()

  const [eventOptions, setEventOptions] = useState([])
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [errors, setErrors] = useState({ eventName: "", expenseName: "" })
  const [fileValue, setFileValue] = useState([
    {
      image: "",
      comments: "",
    },
  ])

  const [popupView, setPopupView] = useState(false)
  const [fieldCount, setFieldCount] = useState(1)
  const [tableData, setTableData] = useState([])
  const [popupData, setPopupData] = useState([])
  const [galleryIdToBeUpdated, setGalleryIdToBeUpdated] = useState(null)
  const [modal, setModal] = useState(false)
  const [url, seturl] = useState()

  useEffect(() => {
    fetch_events()
    handleTableData()
  }, [])

  function fetch_events() {
    get("events-gallery/event-complete").then(res => {
      var event_data = res.data.map(item => {
        return {
          label: item?.name + (item.uniqueId ? " - " + item.uniqueId : ""),
          value: item?._id,
        }
      })
      setEventOptions([{ options: event_data }])
    })
  }

  function togModal(urL) {
    seturl(urL)
    setModal(true)
    removeBodyCss()
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Event",
        field: "event_name",
        width: 150,
      },
      {
        label: "Image Count",
        field: "count",
        width: 150,
      },
      {
        label: "Video",
        field: "video",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        width: 150,
      },
    ],
    rows: tableData,
  }

  const handleRemoveField = index => {
    const list = [...fileValue]
    list.splice(index, 1)
    setFileValue(list)
  }

  const handleAddField = () => {
    setFileValue(prevFileValue => [
      ...prevFileValue,
      {
        image: "",
        comments: "",
      },
    ])
  }

  function handleCommentChange(e, index) {
    var list = [...fileValue]
    var comments = e.target.value
    list[index].comments = comments
    setFileValue(list)
  }

  const uploadImage = (e, index) => {
    const file = e.target.files[0]

    // Check file type
    if (
      !file.type.match("image/jpeg") &&
      !file.type.match("image/jpg") &&
      !file.type.match("image/png")
    ) {
      toastr.error("Please upload a JPEG, JPG, or PNG image.")
      e.target.value = ""

      return
    }

    // Check file size
    if (file.size > 200 * 1024 || file.size < 100 * 1024) {
      toastr.error("Please upload an image between 100KB and 200KB in size.")
      e.target.value = ""
      return
    }

    // Create a temporary image element to check dimensions
    const img = new Image()
    img.onload = function () {
      // Check image dimensions
      if (img.width !== 800 || img.height !== 600) {
        toastr.error(
          "Please upload an image with dimensions exactly 800x600 pixels."
        )
        e.target.value = ""
        return
      }

      // Create FormData object and append the image file
      const fd = new FormData()
      fd.append("images", file)

      axios
        .post(`${API_URL}events-gallery/image/upload`, fd)
        .then(res => {
          if (res.data.status === 200) {
            const newFileValue = [...fileValue]
            newFileValue[index] = {
              ...newFileValue[index],
              image: res.data.data.new_filename,
            }
            setFileValue(newFileValue)
          } else {
            toastr.error(res.data.message)
          }
        })
        .catch(error => {
          toastr.error("An error occurred while uploading the image.")
          console.error("Error uploading image:", error)
          e.target.form.reset()
        })
    }
    img.src = URL.createObjectURL(file)
  }

  function handleChange(e) {
    let { name, value } = e.target
    setMaster({ ...master, [name]: value })
  }

  function handleSubmit(e) {
    e.preventDefault()
    let data = {
      youtubeURL: master?.youtubeURL,
      event: master?.event,
      images: fileValue,
      _id: master?._id,
    }
    if (selectedEvent === null) {
      let errorVal = errors
      if (selectedEvent === null) {
        errorVal.eventName = "Please select event"
      }
      setErrors({ ...errorVal })
    } else {
      if (master._id) {
        put("/events-gallery/edit/", data)
          .then(res => {
            toastr.success(res.message)
            reset()
            setGalleryIdToBeUpdated(null)
            formRef.current.reset()
          })
          .catch(err => {
            toastr.error(err.response.data.message)
            console.log(err)
          })
      } else {
        post("/events-gallery/add", data)
          .then(res => {
            toastr.success(res.message)
            reset()
            formRef.current.reset()
            setMaster(null)
          })
          .catch(err => {
            toastr.error(err.response.data.message)
            console.log(err)
          })
      }
    }
  }

  function handleTableData() {
    get("/events-gallery/list").then(res => {
      let result = res.data
      let eventData = []
      result?.map((item, indx) => {
        item.id = indx + 1
        item.date = moment(item?.startDate).format("DD-MM-YYYY")
        item.time = moment(item?.startTime, "hh:mm a").format("hh:mm a")
        item.event_name = item?.event?.name
        item.staff = item?.addedBy?.name

        
        item.video = Array.isArray(item.youtubeURL) && item.youtubeURL.length > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "20px",
              cursor: "pointer",
            }}
          >
            {item.youtubeURL.map((url, index) => (
              <i
                key={index}
                className="fab fa-youtube"
                onClick={() => {
                  togModal(url)
                }}
              ></i>
            ))}
          </div>
        ) : null;
        // item.video = item.youtubeURL ? (
        //   <div
        //     style={{
        //       display: "flex",
        //       justifyContent: "center",
        //       fontSize: "20px",
        //       cursor: "pointer",
        //     }}
        //   >
        //     <i
        //       className="fab fa-youtube"
        //       onClick={() => {
        //         togModal(item?.youtubeURL)
        //       }}
        //     ></i>
        //   </div>
        // ) : null

        item.action = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                handleClickOpen(item?._id)
                setPopupView(true)
              }}
            ></i>
            <i
              className="far fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                toTop()
                handleUpdate(item)
                setGalleryIdToBeUpdated(item._id)
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ fontSize: "1em", cursor: "pointer" }}
              onClick={() => {
                handleDelete(item?._id)
              }}
            ></i>
          </div>
        )

        // item?.image?.map((el,idx)=>{
        //   console.log(el,"el");
        // })
        let foundNonNullImage = false
        item.images.forEach((el, indx) => {
          if (
            (el.image === "" || el.image === undefined) &&
            !foundNonNullImage
          ) {
            item.count = 0 
          } else {
            foundNonNullImage = true
            item.count = indx === 0 ? 1 : indx + 1
          }
        })

        eventData.push(item)

        return item
      })
      setTableData(eventData)
    })
  } 

  function handlePreUpdate(id) {
    get(`/events-gallery/list-add?eventId=${id}`)
      .then(res => {
        const result = res.data[0] 
        
         console.log(res.data[0],"adsahj");
        if (result) { 
        
          if (!result.images.length) {
            result.images = [{ image: "", comments: "" }]
            
            setMaster(result)
          } else {
            let updatedValue = result?.images.map((el, indx) => {
              return {
                image: el?.image,
                comments: el?.comments,
              }
            })
            setGalleryIdToBeUpdated(true)                 
            setFileValue(updatedValue)
            setMaster(result)
          }
        }else {
          setFileValue([{image: "", comments: ""}])
          // setMaster({images:[]});
          setGalleryIdToBeUpdated(false)                 

        }
      })
      .catch(err => {})
  }
  function handleClickOpen(id) {
    get(`/events-gallery/list/${id}`).then(res => {
      const result = res.data
      console.log(res, "resultt")
      result.date = moment(result.data).format("DD-MM-YYYY")

      setPopupData(result)
      console.log(result, "popupData")
    })
  }

  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`/events-gallery/${id}`)
          .then(res => {
            toastr.success(res.message)
            handleTableData()
            setGalleryIdToBeUpdated(null)
            setFileValue([
              {
                image: "",
                comments: "",
              },
            ])
            formRef.current.reset()
          })
          .catch(err => {
            toastr.success(err.response.data.message)
          })
      }
    })
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  function handleUpdate(item) {
    let updatedValue = item?.images.map((el, indx) => {
      return {
        image: el?.image,
        comments: el?.comments,
      }
    })

    setFileValue(updatedValue)
    setSelectedEvent({
      label: item?.event?.name,
      value: item?.event?._id,
    })
    setMaster(item)
    // get(`/events-gallery/list/${id}`)
    //   .then(res => {
    //     const result = res.data
    //     console.log("gallerysi", result)

    //     setSelectedEvent({
    //       label: result?.event?.name,
    //       value: result?.event?._id,
    //     })

    //     let updatedValue = result?.images.map((item, indx) => {
    //       return {
    //         image: item?.image,
    //         comments: item?.comments,
    //       }
    //     })

    //     setFileValue(updatedValue)

    //     setMaster(result)
    //   })
    //   .catch(err => {
    //     console.error(err)
    //     // Handle error here
    //   })
  }

  function reset() {
    setFileValue([
      {
        image: "",
        comments: "",
      },
    ])
    handleTableData()
    setSelectedEvent(null)
  }

  function handleSelect(selected, name) {
    switch (name) {
      case "event":
        // handleTableData(selected.value)

        setSelectedEvent(selected)
        setMaster({
          event: selected.value,
        })
        handlePreUpdate(selected.value)
        break

      default:
        break
    }
  }

  return (
    <>
      <Modal
        show={popupView}
        isOpen={popupView}
        size="lg"
        centered={true}
        toggle={() => setPopupView(!popupView)}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h5>
              {popupData?.event?.name} - {popupData?.event?.uniqueId}
            </h5>
          </div>

          <button
            type="button"
            onClick={() => setPopupView(false)}
            className="close"
            aria-label="Close"
            data-dismiss="false"
          ></button>
        </div>

        <div style={{ padding: "20px" }}>
          <div>
            Youtube URL : <h6 class="font-size-14"><a href={popupData?.youtubeURL} target="_blank">{popupData?.youtubeURL}</a></h6>
          </div>
          <p>Event Images:</p>
          <Row>
            {popupData?.images?.map(el => (
              <>
                <Col md="4">
                  <div>
                    <img src={`${API_URL}${el?.image}`} alt="" width="100%" />
                    <p style={{ textAlign: "center" }}>{el.comments}</p>
                  </div>
                </Col>
              </>
            ))}
          </Row>
        </div>
      </Modal>

      <Modal
        size="lg"
        isOpen={modal}
        centered={true}
        // toggle={() => {
        //   togModal()
        // }}
      >
        <div className="modal-header">
          <button
            onClick={() => {
              setModal(false)
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9">
            <iframe
              title="infoVideo"
              allowFullScreen
              className="embed-responsive-item"
              src={url}
            />
          </div>
        </div>
      </Modal>

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem="Event Gallery" />

          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="needs-validation"
                    ref={formRef}
                    onValidSubmit={(e, v) => {
                      handleSubmit(e, v)
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Event</Label>
                          <Select
                            name="event"
                            classNamePrefix="select2-selection"
                            options={eventOptions}
                            value={selectedEvent}
                            onChange={selected => {
                              handleSelect(selected, "event")
                            }}
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedEvent === null ? errors?.eventName : ""}
                          </p>
                        </div>
                      </Col>
                      {/* <Col md="2">
                        <div className="mb-3">
                          <Label>Category</Label>
                          <Select
                            name="category"
                            classNamePrefix="select2-selection"
                            options={[
                              { label: "Webinar", value: 0 },
                              { label: "Workshop", value: 1 },
                              { label: "Hackathon", value: 2 },
                              { label: "MoU", value: 2 },
                              { label: "inauguration", value: 2 },
                            ]}
                          />
                        </div>
                      </Col> */}
                    </Row>

                    <div>
                      {fileValue &&
                        Array.isArray(fileValue) &&
                        fileValue.map((el, index) => (
                          <div key={index} className="field-set">
                            <Row>
                              <Col md="3">
                                <div className="mb-1">
                                  <Label>{`Image ${index + 1}`}  <OverlayTrigger
                              placement="top"
                              overlay={ 
                                <Tooltip style={{width:"150px"}} id="copy-event-tooltip">
                                  {" "}
                                  Specifications: Image should be PNG,JPEG or JPG,
                                  with dimensions 800x600
                                  pixels, and size 100-200kb.
                                </Tooltip> 
                              }
                            > 
                              <i
                                className="fas fa-info-circle"
                                style={{
                                  cursor: "pointer",
                                  fontSize: "1em",
                                  marginRight: "0.5rem",
                                }}
                              ></i>
                            </OverlayTrigger>   </Label>
                                  {el?.image ? (
                                    <div
                                      div
                                      className="img-wraps d-flex flex-column"
                                    >
                                      {el?.image &&
                                      el?.image.startsWith("http") ? (
                                        <img
                                          alt=""
                                          width="150"
                                          height="150"
                                          src={`${el?.image}`}
                                        />
                                      ) : (
                                        <img
                                          alt=""
                                          width="150"
                                          height="150"
                                          src={`${API_URL}${el?.image}`}
                                        />
                                      )}
                                      <button
                                        className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                        onClick={() => {
                                          setFileValue(prevFileValue =>
                                            prevFileValue.filter(
                                              (_, i) => i !== index
                                            )
                                          )
                                        }}
                                        style={{
                                          width: "150px",
                                          marginTop: "4px",
                                        }}
                                        type="button"
                                      >
                                        Delete
                                      </button>
                                    </div>
                                  ) : (
                                    <input
                                      name="image"
                                      type="file"
                                      className="form-control"
                                      id="validationCustom03"
                                      onChange={e => uploadImage(e, index)}
                                      rows="1"
                                    />
                                  )}
                                </div>
                              </Col>

                              <Col md="4">
                                <div className="mb-1">
                                  <Label>Comments</Label>
                                  <AvField
                                    name="comments"
                                    value={el?.comments}
                                    onChange={e => {
                                      handleCommentChange(e, index)
                                    }}
                                    placeholder="Comments"
                                    type="textarea"
                                    rows={1}
                                  />
                                </div>
                              </Col>

                              <Col style={{ marginTop: "2.1rem" }} md="1">
                                {fileValue?.length !== 1 && (
                                  <div>
                                    <i
                                      style={{ cursor: "pointer" }}
                                      class="far fa-trash-alt"
                                      onClick={() => handleRemoveField(index)}
                                    ></i>
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </div>
                        ))}
                      <Col
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          marginLeft: "74px",
                        }}
                        md="6"
                      >
                        <span
                          style={{
                            marginBottom: ".5rem",
                            color: "blue",
                            cursor: "pointer",
                          }}
                          onClick={handleAddField}
                        >
                          Add More +
                        </span>
                      </Col>
                    </div>

                    <Row>
                      <Col md="3">
                        <Label>Youtube URL</Label>
                        <AvField
                          type="url"
                          name="youtubeURL"
                          placeholder="Youtube URL"
                          value={master?.youtubeURL || ""}
                          className="form-control"
                          onChange={e => handleChange(e)}
                          validate={{
                            pattern: {
                              value:
                                /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/,
                              errorMessage: "Please enter a valid Youtube URL",
                            },
                          }}
                        />
                      </Col>

                      <Col md="3" style={{ marginTop: "27px" }}>
                        {galleryIdToBeUpdated ? (
                          <Button type="update" color="warning">
                            Update
                          </Button>
                        ) : (
                          <Button type="submit" color="primary">
                            Submit
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="galleryTableId"
                    bordered
                    responsive
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default Gallery
