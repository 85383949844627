import React, { useState, useEffect, useRef } from "react"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import Select from "react-select"

import { AvForm, AvField } from "availity-reactstrap-validation"

import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { get, post, put } from "../../../helpers/api_helper.js"
import moment from "moment"
import toastr from "toastr"

import "./styles.scss"
import TablePagination from "../../../components/Common/Pagination.js"
import { dateConverter } from "../../../helpers/functions.js"
import queryString from "query-string"
const Attendance = props => {
  const formRef = useRef()
  const filterRef = useRef()

  const initialState = {
    batch: "",
    session: "",
    subSession: "",
    fromTime: "",
    toTime: "",
  }

  const [tableData, setTableData] = useState([])
  const [masterObject, setMasterObject] = useState(initialState)
  const [selected, setSelected] = useState({
    batch: null,
    session: null,
    subSession: null,
  })

  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 20

  const [filterObject, setFilterObject] = useState({})
  const [sessionDate, setSessionDate] = useState("")
  const [trainer, setTrainer] = useState("")
  const [batchOptions, setBatchOptions] = useState([])
  const [sessionOptions, setSessionOptions] = useState([])
  const [subSessionOptions, setSubSessionOptions] = useState([])

  const [assigned, setAssigned] = useState([])
  const [unAssigned, setUnAssignedDetails] = useState([])

  useEffect(() => {
    handleBatchOptions()
  }, [])

  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [filterObject, page])

  const handleTableData = () => {
    const params = `page=${page}&limit=${limit}&${queryString.stringify(
      filterObject
    )}`
    get(`/attendance/list?${params}`).then(res => {
      const result = res.data
      const count = res.count
      result.map((item, index) => {
        item.id = index + 1 + (page - 1) * limit
        item.date = moment(item.date).format("DD-MM-YYYY")
        item.trainer =
          (item.trainerFirstName ? item.trainerFirstName : "") +
          " " +
          (item.trainerLastName ? item.trainerLastName : "")

        if (item.updateFirstName) {
          item.staff =
            (item.updateFirstName ? item.updateFirstName : "") +
            " " +
            (item.updateLastName ? item.updateLastName : "")
        } else {
          item.staff =
            (item.addedFirstName ? item.addedFirstName : "") +
            " " +
            (item.addedLastName ? item.addedLastName : "")
        }
        return item
      })
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(result)
    })
  }

  const handleBatchOptions = () => {
    get("attendance/batch").then(res => {
      setBatchOptions(res.data)
    })
  }
  const handleSessionOptions = async batch => {
    try {
      const response = await get(`attendance/sessions?id=${batch}`)
      const data = response.data
      data.map(item => {
        item.label = (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <strong>{item.label}</strong>
            <span>{dateConverter(item.date)}</span>
          </div>
        )
        return item
      })
      setSessionOptions(data)
    } catch (err) {
      console.log(err)
    }
  }
  const handleSubSessionOptions = async (batch, session) => {
    try {
      const response = await get(
        `attendance/sub-sessions?id=${batch}&session=${session}`
      )
      const data = response.data
      if (data?.length === 1) handleSubSelection(data[0], session)
      else setMasterObject(prev => ({ ...prev, session: session }))
      data.map((item, index) => {
        item.label = (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <strong>{index + 1}</strong>
            <span>{dateConverter(item.date)}</span>
          </div>
        )
        return item
      })
      setSubSessionOptions(data)
    } catch (err) {}
  }

  const getBatchStudents = async (batch, session, subSession, from, to) => {
    await get(
      `attendance/students?id=${batch}&session=${session}&subSession=${subSession}`
    ).then(res => {
      const master = { ...masterObject }

      master.session = session
      master.subSession = subSession
      master.fromTime = from
      master.toTime = to
      if (res.update) {
        master.id = res.attendance?._id
        master.fromTime = res.attendance?.fromTime
        master.toTime = res.attendance?.toTime
        master.attendanceMarkedDate = moment(res.attendance?.date).format(
          "DD-MM-YYYY"
        )
      }

      setMasterObject(master)

      const result = res.data
      const assigned = []
      const unAssigned = []
      result.map((item, index) => {
        item.id = item._id
        item.name =
          (item.firstName ? item.firstName : "") +
          " " +
          (item.lastName ? item.lastName : "")

        item.username = item.username ? item.username : ""
        if (item.attended === "true" || item.attended === true) {
          assigned.push(item)
        } else {
          unAssigned.push(item)
        }
        return item
      })
      setAssigned(assigned)
      setUnAssignedDetails(unAssigned)
    })
  }

  const handleSubmit = () => {
    const obj = { ...masterObject }
    obj.students = [...assigned, ...unAssigned]
    obj.date = moment(sessionDate, "DD-MM-YYYY").format("YYYY-MM-DD")
    if (masterObject.id) {
      put("/attendance/update", obj)
        .then(res => {
          reset()
          toastr.success(res.message)
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    } else {
      post("/attendance/add", obj)
        .then(res => {
          reset()
          toastr.success(res.message)
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    }
  }

  const handleValueChange = async (name, value) => {
    const obj = { ...masterObject }
    obj[name] = value
    setMasterObject(obj)
  }
  const handleFilterValueChange = (name, value) => {
    const obj = { ...filterObject }
    obj[name] = value
    setFilterObject(obj)
  }

  const handleSubSelection = async (item, session = masterObject.session) => {
    setSelected(prev => ({
      ...prev,
      subSession: item,
    }))

    await getBatchStudents(
      masterObject.batch,
      session,
      item.value,
      item.from,
      item.to
    )

    if (!item.trainerFirstName)
      toastr.warning("Trainer Not Configured for this Session")
    const trainer =
      (item.trainerFirstName ? item.trainerFirstName : "") +
      " " +
      (item.trainerLastName ? item.trainerLastName : "")

    const date = moment(item.date).format("DD-MM-YYYY")
    setSessionDate(date)
    setTrainer(trainer)
  }

  const handleAssign = idx => {
    const data = [...assigned]
    const unAssignedData = [...unAssigned]
    let item = data.splice(idx, 1)[0]
    item.attended = false
    unAssignedData.push(item)
    setAssigned(data)
    setUnAssignedDetails(unAssignedData)
  }
  const handleUnAssign = idx => {
    const data = [...unAssigned]
    const assignedData = [...assigned]
    let item = data.splice(idx, 1)[0]
    item.attended = true
    assignedData.push(item)
    setAssigned(assignedData)
    setUnAssignedDetails(data)
  }
  const reset = () => {
    formRef.current.reset()
    setMasterObject({})
    handleBatchOptions()
    setSessionOptions([])
    setSubSessionOptions([])
    setSelected({})
    setFilterObject({})
    setPage(1)
  }

  const handleChange = (event, value) => {
    setPage(value)
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Branch", field: "branch" },
      { label: "Batch", field: "batch" },
      { label: "Course", field: "course" },
      { label: "Session", field: "session" },
      { label: "trainer", field: "trainer" },
      { label: "Staff", field: "staff" },
    ],
    rows: tableData,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Attendance" />
          <Card>
            <CardBody>
              <AvForm
                ref={formRef}
                className="needs-validation"
                onValidSubmit={handleSubmit}
              >
                <Row>
                  <Col md={3}>
                    <Label>Batch</Label>
                    <Select
                      options={batchOptions}
                      value={selected.batch || ""}
                      onChange={selected => {
                        setMasterObject({ batch: selected.value })
                        setSelected(prev => ({ batch: selected }))
                        handleSessionOptions(selected.value)
                      }}
                    ></Select>
                    {masterObject.subSession ? (
                      <p style={{ marginBottom: 0, marginTop: "0.5rem" }}>
                        <b>Trainer</b>: {trainer}
                      </p>
                    ) : (
                      ""
                    )}

                    {masterObject?.id && (
                      <>
                        <p className="text-success" style={{ marginBottom: 0, marginTop: "0.5rem" }}>
                          <b>Attendance Marked</b>
                        </p>
                        <p style={{ marginBottom: 0, marginTop: "0.5rem" }}>
                          <b>
                            Attendance Marked Date :{" "}
                            {masterObject.attendanceMarkedDate}
                          </b>
                        </p>
                      </>
                    )}
                  </Col>
                  <Col md={3}>
                    <Label>Session</Label>
                    <Select
                      options={sessionOptions}
                      value={selected.session || ""}
                      isDisabled={!masterObject.batch || masterObject.id}
                      onChange={async selected => {
                        // await handleValueChange("session", selected.value)
                        setSelected(prev => ({
                          ...prev,
                          session: selected,
                          subSession: null,
                        }))
                        handleSubSessionOptions(
                          masterObject.batch,
                          selected.value
                        )
                      }}
                    ></Select>
                  </Col>
                  <Col md={2}>
                    <Label>Sub Session</Label>
                    <Select
                      options={subSessionOptions}
                      isDisabled={!masterObject.session || masterObject.id}
                      value={selected.subSession || ""}
                      onChange={selected => {
                        handleSubSelection(selected)
                      }}
                    ></Select>
                    {masterObject.subSession ? (
                      <p style={{ marginBottom: 0, marginTop: "0.5rem" }}>
                        <b>Date</b>: {sessionDate}
                      </p>
                    ) : (
                      ""
                    )}
                  </Col>

                  <Col md={2}>
                    <Label>From</Label>
                    <AvField
                      name="fromTime"
                      type="time"
                      errorMessage="Select From"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="validationCustom01"
                      disabled={!masterObject.subSession}
                      value={masterObject.fromTime || ""}
                      onChange={e => {
                        handleValueChange("fromTime", e.target.value)
                      }}
                    ></AvField>
                  </Col>
                  <Col md={2}>
                    <Label>To</Label>
                    <AvField
                      name="toTime"
                      type="time"
                      errorMessage="Select From"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="validationCustom01"
                      disabled={!masterObject.subSession}
                      value={masterObject.toTime || ""}
                      onChange={e => {
                        handleValueChange("toTime", e.target.value)
                      }}
                    ></AvField>
                  </Col>

                  {!masterObject.subSession ? (
                    <Col md="2">
                      <Button
                        color="danger"
                        type="button"
                        onClick={() => reset()}
                      >
                        {"  "}
                        Reset
                      </Button>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
                {masterObject.subSession ? (
                  <>
                    <Row className="mt-5">
                      <Col md={6}>
                        <div className="table-responsive">
                          <h6>Attended</h6>
                          <table
                            id="unAssignedStudents"
                            className="table table-bordered mb-0"
                          >
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Student Id</th>
                                <th>Name</th>
                                <th>Mobile</th>
                              </tr>
                            </thead>
                            <tbody>
                              {assigned?.map((row, idx) => (
                                <tr>
                                  <td>{idx + 1}</td>
                                  <td>{`${row?.username} `}</td>
                                  <td>{`${row?.name}`}</td>
                                  <td>{row?.mobile}</td>

                                  <td>
                                    <i
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        handleAssign(idx)
                                      }}
                                      className="fas fa-window-close"
                                    ></i>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="table-responsive">
                          <h6>Not attended</h6>

                          <table className="table table-bordered mb-0">
                            <thead>
                              <tr>
                                <th></th>
                                <th>#</th>
                                <th>Student Id</th>
                                <th>Name</th>
                                <th>Mobile</th>
                              </tr>
                            </thead>
                            <tbody>
                              {unAssigned?.map((row, idx) => (
                                <tr key={row.id}>
                                  <th className="text-center">
                                    <input
                                      type="checkbox"
                                      name="headerCheckbox"
                                      id="headerCheckbox"
                                      checked={false}
                                      onClick={() => handleUnAssign(idx)}
                                      style={{ cursor: "pointer" }}
                                    ></input>
                                  </th>
                                  <td className="text-center">{idx + 1}</td>
                                  <td>{`${row?.username} `}</td>
                                  <td>{`${row?.name} `}</td>
                                  <td>{row?.mobile} </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                      <Col md="12">
                        <div
                          style={{
                            paddingTop: "25px",
                            display: "flex",
                            justifyContent: "end",
                          }}
                        >
                          <Button
                            color={masterObject.id ? "warning" : "primary"}
                            type="submit"
                            style={{ marginRight: "0.5rem" }}
                          >
                            {/* <Save></Save> */}
                            {"  "}
                            {masterObject.id ? "Update" : "Submit"}
                          </Button>
                          <Button
                            color="danger"
                            type="button"
                            onClick={() => reset()}
                          >
                            {"  "}
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}
              </AvForm>
            </CardBody>
          </Card>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <AvForm ref={filterRef}>
                    <Row>
                      <Col md={2}>
                        <Label>From</Label>
                        <AvField
                          name="fromTime"
                          type="date"
                          errorMessage="Select From"
                          className="form-control"
                          id="validationCustom01"
                          value={filterObject.from || ""}
                          onChange={e => {
                            handleFilterValueChange("from", e.target.value)
                          }}
                        ></AvField>
                      </Col>
                      <Col md={2}>
                        <Label>To</Label>
                        <AvField
                          name="to"
                          type="date"
                          errorMessage="Select From"
                          className="form-control"
                          id="validationCustom01"
                          value={filterObject.to || ""}
                          onChange={e => {
                            handleFilterValueChange("to", e.target.value)
                          }}
                          min={filterObject.from}
                        ></AvField>
                      </Col>
                      <Col md={2}>
                        <Label>Batch</Label>
                        <Select
                          options={batchOptions}
                          value={selected.filterBatch || ""}
                          onChange={selected => {
                            handleFilterValueChange("batch", selected.value)
                            setSelected(prev => ({ filterBatch: selected }))
                          }}
                        ></Select>
                      </Col>

                      <Col md={2}>
                        <Button
                          color="danger"
                          type="button"
                          style={{ marginTop: "26px" }}
                          onClick={() => reset()}
                        >
                          {"  "}
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                  <MDBDataTable
                    id="attendanceTable"
                    responsive
                    bordered
                    data={data}
                    searching={false}
                    sortable={false}
                    info={false}
                    paging={false}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                  <TablePagination
                    page={page}
                    onChange={handleChange}
                    count={totalPage}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Attendance
