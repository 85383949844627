import React, { useEffect, useRef, useState } from "react"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { MDBDataTable } from "mdbreact"
import Swal from "sweetalert2"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { del, get, post, put } from "../../../helpers/api_helper"
import toastr from "toastr"
// import "./style.scss"

const TicketCategory = () => {
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  const [master, setMaster] = useState({})
  const [tableData, setTableData] = useState([])
  const [ticketCategoryIdToBeUpdated, setticketCategoryIdToBeUpdated] =
    useState(null)

  const ref = useRef()

  const data = {
    columns: [
      {
        label: "Index",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Category Name",
        field: "categoryName",
        sort: "asc",
        width: 150,
        className: "text-center", // Add this class to center the text
      },

      {
        label: "Action",
        field: "options",
        sort: "asc",
        width: 200,
        className: "text-center", // Add this class to center the text
      },
    ],
    rows: tableData,
  }

  function handleValidSubmit(e, v) {
    if (ticketCategoryIdToBeUpdated) {
      put(`/ticket/category`, master)
        .then(res => {
          toastr.success(res.message)
          ref.current.reset()
          handleTableData()
          setMaster({})
          setticketCategoryIdToBeUpdated(null)
        })
        .catch(err => {
          toastr.error(err.response.data.message)

          ref.current.reset()
          handleTableData()
          setMaster({})
          setticketCategoryIdToBeUpdated(null)
        })
    } else {
      post(`/ticket/category`, master)
        .then(res => {
          if (res.success === true) {
            toastr.success(res.message)
            ref.current.reset()
            setMaster({})
            setticketCategoryIdToBeUpdated(null)
          } else {
            toastr.error("Faild to add ticket category")
            ref.current.reset()
            handleTableData()
            setMaster({})
            setticketCategoryIdToBeUpdated(null)
          }
          handleTableData()
        })
        .catch(err => {
          toastr.error(err.response.data.message)
          ref.current.reset()
          handleTableData()
          setMaster({})
          setticketCategoryIdToBeUpdated(null)
        })
    }
  }


  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [])



  const handleTableData = async () => {
    get("/ticket/category").then(res => {
      const result = res.data
      result.map((item, index) => {
        item.id = index + 1
        item.options = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="far fa-edit"
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
              onClick={() => {
                toTop()
                handleUpdate(item)
                setticketCategoryIdToBeUpdated(item?._id)
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ cursor: "pointer", fontSize: "1em" }}
              onClick={() => {
                handleDelete(item._id)
              }}
            ></i>
          </div>
        )
        return item
      })
      setTableData(result)
    })
    function handleUpdate(item) {
      setMaster({ id: item?._id, categoryName: item?.categoryName })
    }
  }


  //onchangeG
  function handleTicketCategory(e) {
    let { name, value } = e.target
    setMaster({ ...master, [name]: value })
  }

  //DELETE
  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`/ticket/category?id=${id}`)
          .then(res => {
            toastr.success(res.message)
            handleTableData()
          })
          .catch(err => {
            toastr.error(err.response.data.message)
          })
      }
    })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb title="Ticket" breadcrumbItem="Ticket Category" />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={ref}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v)
                    }}
                  >

                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Category Name
                          </Label>

                          <AvField
                            name="categoryName"
                            placeholder="Category Name"
                            type="text"
                            errorMessage="Enter Category Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            value={master?.categoryName || ""}
                            onChange={handleTicketCategory}
                            id="validationCustom01"
                          />
                        </div>
                      </Col>
                      <Col md="3" style={{ paddingTop: "4px" }}>
                        <div className="mt-4">
                          <Button
                            className="me-2"
                            color={
                              ticketCategoryIdToBeUpdated
                                ? "warning"
                                : "primary"
                            }
                            type="submit"
                          >
                            {ticketCategoryIdToBeUpdated ? "Update" : "Submit"}
                          </Button>

                          {/* <Button color="danger" className="me-2" type="reset">
                            Reset
                          </Button> */}
                        </div>
                      </Col>
                    </Row>
                    
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="ticketCategory"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default TicketCategory
