import React, { useEffect, useRef, useState } from "react"
import { Row, Col, Card, CardBody, Label, Button } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import { MDBDataTable } from "mdbreact"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"
import { del, get, post, put } from "../../../helpers/api_helper"
import toastr from "toastr"
import Swal from "sweetalert2"
import { getBranches } from "../../../store/options/actions"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import "./CreateClass.scss"
import Admin from "../../../components/Common/Admin"
import useBranch from "../../../hooks/useBranch"

function CreateClass() {
  const initialState = {
    csp: "",
    type: "",
    name: "",
    maxStrength: "",
    code: "",
    setSelectedBranches: "",
    setSelectedTypes: "",
  }
  const dispatch = useDispatch()

  const [branch] = useBranch()

  const [selectedBranches, setSelectedBranches] = useState(null)
  const [tableData, setTableData] = useState([])
  const formRef = useRef(null)
  const [selectedType, setSelectedType] = useState(null)
  const [masterobj, setmasterobj] = useState({csp:branch?.value})

  const navigate = useNavigate()
  const { branches } = useSelector(branch => branch.Options)

  useEffect(() => {
    dispatch(getBranches({}))
  }, [dispatch])

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "CSP",
        field: "csp1",
        width: 150,
      },
      {
        label: "Type",
        field: "type",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        width: 150,
      },
      {
        label: "Code",
        field: "code",
        width: 150,
      },
      {
        label: "Strength",
        field: "maxStrength",
        width: 150,
      },
      {
        label: "Status",
        field: "statusList",
        width: 150,
      },
      {
        label: "Action",
        field: "options",
        width: 150,
      },
    ],
    rows: tableData,
  }

  const handleStatusChange = async (id, status) => {
    await put("/ClassroomsOrLabs/status", { id, status }).then(res => {
      toastr.success(res.message)
      reset()
    })
  }

  const handleValueChange = (value, name) => {
    setmasterobj({ ...masterobj, [name]: value })
  }

  const handleSubmit = () => {
    if (masterobj.id) {
      put("/ClassroomsOrLabs/edit", masterobj)
        .then(res => {
          toastr.success("updated Successfully")
          reset()
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    } else {
      post("/ClassroomsOrLabs/add", masterobj)
        .then(res => {
          toastr.success("Submitted successfully")
          reset()
          formRef.current.reset()
          setmasterobj({csp:branch?.value})
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    }
  }

  const handleTableData = async () => {
    get("/ClassroomsOrLabs/list").then(res => {
      const result = res.data
      result.map((item, index) => {
        item.id = index + 1
        item.csp1 = item?.csp?.name
        // if (item.upDate) item.date = moment(item.upDate).format("DD-MM-YYYY")
        // else item.date = moment(item.date).format("DD-MM-YYYY")
        item.options = (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <i
              // onClick={() => handleView(row)}
              onClick={() => {
                navigate(`/class-creation/${item._id}`, {
                  state: { id: item._id },
                })
              }}
              className="fas fa-eye"
            ></i>{" "}
            <i
              className="far fa-edit"
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
              onClick={() => handleEdit(item)}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ cursor: "pointer", fontSize: "1em" }}
              onClick={() => handleDelete(item._id)}
            ></i>
          </div>
        )
        if (item.status === 0) {
          item.statusList = (
            <Button
              className="btn btn-sm"
              color="success"
              onClick={() => handleStatusChange(item._id, item.status)}
            >
              Active
            </Button>
          )
        } else {
          item.statusList = (
            <Button
              className="btn btn-sm"
              color="danger"
              onClick={() => handleStatusChange(item._id, item.status)}
            >
              Inactive
            </Button>
          )
        }
        return item
      })

      setTableData(result)
    })
  }
  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        post(`ClassroomsOrLabs/delete/${id}`)
          .then(res => {
            toastr.success(res.status)
            handleTableData()
          })
          .catch(err => {
            toastr.success(err.response.data.message)
          })
      }
    })
  }
  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [])
  function handleSelectChange(selected, name) {
    switch (name) {
      case "csp":
        setSelectedBranches(selected)
        setmasterobj({ ...masterobj, csp: selected.value })
        break
      case "type":
        setSelectedType(selected)
        setmasterobj({ ...masterobj, type: selected.value })
        break
      default:
        break
    }
  }
  const reset = () => {
    handleTableData()
    formRef.current?.reset()
    setSelectedBranches(null)
    setSelectedType(null)
    setmasterobj({csp:branch?.value})
  }
  const toTop = () => {
    window.scroll({
      top: "0",
      left: "0",
      behavior: "smooth",
    })
  }
  const handleEdit = item => {
    toTop()
    setSelectedBranches({ label: item?.csp?.name, value: item?.csp?._id })
    setSelectedType({ label: item.type, value: item?.type })
    setmasterobj({
      id: item._id,
      code: item.code,
      csp: item?.csp?._id,
      type: item?.type,
      name: item?.name,
      maxStrength: item?.maxStrength,
    })
  }
  const [master, setMaster] = useState({ label: "Class " })
  function handleVal(selecetd, name) {
    let label = "Class"
    if (name === "type") {
      switch (selecetd.value) {
        case "Class":
          label = "Class"
          break
        case "Lab":
          label = "Lab"
          break
        case "Hall":
          label = "Hall"
          break
        default:
          label = "Class"
          break
      }
    }
    setMaster({ ...master, label })
  }
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumb title="Home" breadcrumbItem="Create Class" />
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <AvForm
                  className="needs-validation"
                  onValidSubmit={handleSubmit}
                  ref={formRef}
                >
                  <Row>
                    <Admin>
                      <Col md="3">
                        <div className="">
                          <Label>CSP</Label>
                          <Select
                            name="csp"
                            value={selectedBranches}
                            classNamePrefix="select2-selection"
                            options={branches}
                            onChange={selected => {
                              handleSelectChange(selected, "csp")
                            }}
                          />
                        </div>
                      </Col>
                    </Admin>
                    <Col md="2">
                      <div className="">
                        <Label>Type</Label>
                        <Select
                          name="type"
                          value={selectedType}
                          classNamePrefix="select2-selection"
                          options={[
                            { label: "Class", value: "Class" },
                            { label: "Lab", value: "Lab" },
                            { label: "Hall", value: "Hall" },
                          ]}
                          onChange={selected => {
                            handleSelectChange(selected, "type")
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>
                          {(selectedType?.label || "") + " " + "Name"}
                        </Label>
                        <AvField
                          name="name"
                          placeholder={
                            (selectedType?.label || "") + " " + "Name"
                          }
                          validate={{ required: { value: true } }}
                          onChange={e => {
                            handleValueChange(e.target.value, "name")
                          }}
                          value={masterobj?.name || ""}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Max Strength</Label>
                        <AvField
                          name="maxStrength"
                          placeholder="Max Strength"
                          validate={{ required: { value: true } }}
                          onChange={e => {
                            handleValueChange(e.target.value, "maxStrength")
                          }}
                          value={masterobj?.maxStrength || ""}
                          type="number"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>
                          {(selectedType?.label || "") + " " + "Code"}
                        </Label>
                        <AvField
                          name="code"
                          validate={{ required: { value: true } }}
                          placeholder={
                            (selectedType?.label || "") + " " + "Code"
                          }
                          onChange={e => {
                            handleValueChange(e.target.value, "code")
                          }}
                          value={masterobj?.code || ""}
                        />
                      </div>
                    </Col>

                    <Col md="3" style={{ paddingTop: "25px" }}>
                      <Button
                        color={`${masterobj.id ? "warning" : "primary"}`}
                        type="submit"
                      >
                        {masterobj.id ? "Update" : "Submit"}
                      </Button>
                      <Button
                        color="danger"
                        type="reset"
                        style={{ marginLeft: "10px" }}
                        onClick={reset}
                      >
                        Reset
                      </Button>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <MDBDataTable
                  id="classtable"
                  responsive
                  bordered
                  info={true}
                  searching={true}
                  entries={20}
                  disableRetreatAfterSorting={true}
                  data={data}
                  sortable={false}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}
export default CreateClass
