import React, { useEffect, useRef } from "react"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import { useState } from "react"
import { MDBDataTable } from "mdbreact"
import { API_URL } from "../../../config"
import axios from "axios"
import toastr from "toastr"
import { get, post, put } from "../../../helpers/api_helper"
import moment from "moment"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import SelectErrorMessage from "../../../components/Common/SelectErrorMessage"
import Tooltip from "@mui/material/Tooltip"

import { useDispatch, useSelector } from "react-redux"
import { getBranches } from "../../../store/options/actions"
import queryString from "query-string"

import "./style.scss"
import useBranch from "../../../hooks/useBranch"
import {
  dateConverter,
  isAdmin,
  isFinancePm,
  numberToCurrency,
  setTitle,
} from "../../../helpers/functions"
import CreditFollowup from "./followup"

import TablePagination from "../../../components/Common/Pagination"

const CreditPayments = ({ options }) => {
  setTitle("Income/Expense")

  const types = { 1: "Income", 2: "Expense", Income: 1, Expense: 2 }
  const paymentTypes = { 1: "Credit", 2: "Payment", credit: 1, payment: 2 }
  const statusValues = {
    0: { color: "success", title: "Approved" },
    2: { color: "danger", title: "Cancelled" },
    3: { color: "warning", title: "Pending" },
    approved: 0,
    cancelled: 2,
    pending: 3,
  }

  const ref = useRef()
  const filterRef = useRef()
  const dispatch = useDispatch()

  const isOriginalAdmin = isAdmin()
  const isApproveUser = isFinancePm() || isOriginalAdmin

  const todayDate = moment().format("YYYY-MM-DD")
  const time = moment().format("HH:mm")

  const initialState = {
    date: todayDate,
    type: "",
    amount: "",
    remarks: "",
    attachment: "",
    paymentType: paymentTypes.payment,
  }

  const statusOptions = [
    { label: "Approve", value: statusValues.approved },
    { label: "Cancel", value: statusValues.cancelled },
  ]

  const typeOptions = [
    { label: "Income", value: types.Income },
    { label: "Expense", value: types.Expense },
  ]

  const paymentOptions = [
    { label: "Credit", value: paymentTypes.credit },
    { label: "Payment", value: paymentTypes.payment },
  ]

  const filterStatusOptions = [
    { label: "Approved", value: statusValues.approved },
    { label: "Cancelled", value: statusValues.cancelled },
    { label: "Pending", value: statusValues.pending },
  ]

  const [masterObject, setMasterObject] = useState(initialState)
  const [selectedFields, setSelectedFields] = useState(initialState)

  const [subHeadOptions, setSubHeadOptions] = useState([])
  const [fundSourceOptions, setFundSourceOptions] = useState([])
  const [chartAccountOptions, setChartAccountOptions] = useState([])

  const [courseOptions, setCourseOptions] = useState([])
  const [batchOptions, setBatchOptions] = useState([])

  const [filterBranchOptions, setFilterBranchOptions] = useState([])
  const [filterSubHeadOptions, setFilterSubHeadOptions] = useState([])

  const [tableData, setTableData] = useState([])

  const [filterObject, setFilterObject] = useState({})
  const [filterSelectObject, setFilterSelectObject] = useState({})

  const [branch, setBranch] = useState(...useBranch())
  const [alreadyBranch] = useBranch()

  const { branches } = useSelector(state => state.Options)

  const [selectedId, setSelectedId] = useState({ id: "", isCredit: true })
  const [isOpen, setIsOpen] = useState(false)

  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 20

  useEffect(() => {
    if (!branch) dispatch(getBranches())
    else {
      const obj = { ...masterObject }
      obj.branch = branch.value
      setMasterObject(obj)
      handleFundSourceOptions(obj)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [filterObject])

  useEffect(() => {
    handleFilterBranchOptions()
    handleFilterSubHeadOptions()
    // eslint-disable-next-line
  }, [filterObject])

  const handleTableData = async perPage => {
    try {
      const params = `page=${perPage}&limit=${limit}&${queryString.stringify(
        filterObject
      )}`
      const response = await get(`credit-income-expense/list?${params}`)
      const data = response.data
      const count = response.count

      data.map((item, index) => {
        item.id = index + 1 + (page - 1) * limit
        item.csp = item.branch
        item.staff = `${item.userFirstName || ""}  ${item.userLastName || " "}`
        item.date = dateConverter(item.date)
        item.actionDate = dateConverter(item.actionDate)
        item.amount = numberToCurrency(item.amount)

        const status = Number(item.status)
        item.type = types[item.type]
        item.paymentType = paymentTypes[item.paymentType]

        const isCreditPayment = item.isCreditPayment
        const isPending = status === 3
        const isStatusChange = isApproveUser && isPending && !isCreditPayment

        if (isPending) {
          if (isCreditPayment) {
            item.options = (
              <div className="d-flex justify-content-center align-items-center">
                {isOriginalAdmin && (
                  <i
                    className="far fa-edit"
                    onClick={() => handleEdit(item._id)}
                    style={{
                      cursor: "pointer",
                      fontSize: "1em",
                      marginRight: "0.5rem",
                    }}
                  ></i>
                )}
                <i
                  className="fas fa-money-check"
                  onClick={() => handleViewModal(item._id)}
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginRight: "0.5rem",
                  }}
                ></i>
              </div>
            )
          } else {
            // item.options = (
            //   <i
            //     className="far fa-edit"
            //     onClick={() => handleEdit(item._id)}
            //     style={{
            //       cursor: "pointer",
            //       fontSize: "1em",
            //       marginRight: "0.5rem",
            //     }}
            //   ></i>
            // )

            item.options = (
              <div className="d-flex justify-content-center align-items-center">
                {isOriginalAdmin && (
                  <i
                    className="far fa-edit"
                    onClick={() => handleEdit(item._id)}
                    style={{
                      cursor: "pointer",
                      fontSize: "1em",
                      marginRight: "0.5rem",
                    }}
                  ></i>
                )}
                <i
                  className="fas fa-eye"
                  onClick={() => handleViewModal(item._id, false)}
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginRight: "0.5rem",
                  }}
                ></i>
              </div>
            )
          }
        } else {
          if (isCreditPayment) {
            item.options = (
              <div className="d-flex justify-content-center align-items-center">
                <i
                  className="fas fa-money-check"
                  onClick={() => handleViewModal(item._id)}
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginRight: "0.5rem",
                  }}
                ></i>
              </div>
            )
          } else if (status === 0) {
            item.options = (
              <div className="d-flex justify-content-center align-items-center">
                {isOriginalAdmin && (
                  <i
                    className="far fa-edit"
                    onClick={() => handleEdit(item._id)}
                    style={{
                      cursor: "pointer",
                      fontSize: "1em",
                      marginRight: "0.5rem",
                    }}
                  ></i>
                )}
                <i
                  className="fas fa-eye"
                  onClick={() => handleViewModal(item._id, false)}
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginRight: "0.5rem",
                  }}
                ></i>
              </div>
            )
          } else {
            item.options = (
              <div className="d-flex justify-content-center align-items-center">
                <i
                  className="fas fa-eye"
                  onClick={() => handleViewModal(item._id, false)}
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginRight: "0.5rem",
                  }}
                ></i>
              </div>
            )
          }
        }

        if (isStatusChange) {
          item.status = (
            <Select
              name="status"
              // id="sm-select"
              options={statusOptions}
              menuPosition="fixed"
              onChange={selected => handleStatusChange(item._id, selected)}
            ></Select>
          )
        } else {
          item.status = (
            <div>
              <Button color={statusValues[status]?.color} size="sm" disabled>
                {statusValues[status]?.title}
              </Button>
            </div>
          )
        }

        if (item.attachment)
          item.attachment = (
            <a
              href={API_URL + item.attachment}
              target="_blank"
              rel="noreferrer"
            >
              <i
                className="far fa-file-image"
                style={{
                  cursor: "pointer",
                  fontSize: "1.5em",
                  color: "black",
                  marginRight: "0.5rem",
                  marginLeft: "0.5rem",
                }}
              ></i>
            </a>
          )

        return item
      })
      setTableData(data)

      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
    } catch (error) {}
  }

  const handleSubHeadOptions = async (obj = masterObject) => {
    if (!obj.type) return
    try {
      const response = await get(
        `credit-income-expense/options/sub-head?type=${obj.type}`
      )
      setSubHeadOptions(response.data)
    } catch (error) {}
  }

  const handleChartAccountOptions = async (obj = masterObject) => {
    if (!obj.subHead && !obj.branch) return
    try {
      const response = await get(
        `accounts/chart-account/options?branch=${obj.branch}&subType=${obj.subHead}`
      )
      setChartAccountOptions(response.data)
    } catch (err) {}
  }

  const handleFundSourceOptions = async (obj = masterObject) => {
    try {
      const response = await get(
        `credit-income-expense/options/fund-source?branch=${obj.branch}`
      )
      setFundSourceOptions(response.data)
    } catch (err) {}
  }

  const handleCourseOptions = async (obj = masterObject) => {
    try {
      const response = await get(
        `credit-income-expense/options/course?branch=${obj.branch}`
      )
      setCourseOptions(response.data)
    } catch (err) {}
  }

  const handleBatchOptions = async (obj = masterObject) => {
    try {
      const response = await get(
        `credit-income-expense/options/batch?branch=${obj.branch}&course=${obj.course}`
      )
      setBatchOptions(response.data)
    } catch (err) {}
  }

  const handleFilterBranchOptions = async (obj = filterObject) => {
    try {
      const params = `${queryString.stringify(obj)}`
      const response = await get(
        `credit-income-expense/filter/options/branches?${params}`
      )
      setFilterBranchOptions(response.data)
    } catch (error) {}
  }
  const handleFilterSubHeadOptions = async (obj = filterObject) => {
    try {
      const params = `${queryString.stringify(obj)}`
      const response = await get(
        `credit-income-expense/filter/options/sub-head?${params}`
      )
      setFilterSubHeadOptions(response.data)
    } catch (error) {}
  }

  const handleValidSubmit = async () => {
    if (!masterObject.type) return toastr.error("Type Required")
    if (!masterObject.chartOfAccount)
      return toastr.error("Chart Of Account Required")

    try {
      let response
      if (masterObject.id)
        response = await put("/credit-income-expense/edit", masterObject)
      else response = await post("/credit-income-expense/create", masterObject)

      toastr.success(response.message)
      reset()
    } catch (err) {
      console.log(err)
      toastr.error(err?.response?.data?.message)
    }
  }

  const handleStatusChange = async (id, selected) => {
    try {
      const response = await put(`/credit-income-expense/status`, {
        id,
        status: selected?.value,
      })
      toastr.success(response.message)
      handleTableData()
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data?.message)
    }
  }

  const handleEdit = async id => {
    if (!id) return
    try {
      const response = await get(`credit-income-expense/edit?id=${id}`)
      const data = response.data

      const obj = {}
      const selectObj = { ...data }

      obj.id = data._id
      obj.amount = data.amount
      obj.prevAmount = data.paidAmount

      obj.type = data.type
      obj.remarks = data.remarks
      obj.dueDate = data.dueDate
      obj.attachment = data.attachment
      obj.paymentType = data.paymentType

      obj.isDisabled = data.isDisabled
      obj.isPaymentApproved =
        data.status === 0 && data.paymentType === paymentTypes.payment

      obj.branch = data.branchId
      obj.subHead = data.subHeadId
      obj.chartOfAccount = data.chartOfAccountId
      obj.fundSource = data.fundSourceId

      obj.course = data.courseId
      obj.batch = data.batchId

      obj.date = data.date
      obj.time = data.time

      selectObj.type = typeOptions[data.type - 1]
      selectObj.paymentType = paymentOptions[data.paymentType - 1]

      setMasterObject(obj)
      setSelectedFields(selectObj)
      setBranch(data.branch)
      toTop()

      handleFundSourceOptions(obj)

      handleCourseOptions(obj)
      if (data.courseId) handleBatchOptions(obj)
    } catch (error) {
      console.log(error)
      toastr.error(error?.response?.data?.message)
    }
  }

  const handleSelectValueChange = (selected, name) => {
    const obj = { ...selectedFields }
    obj[name] = selected
    if (name === "branch" || name === "subHead") obj.chartOfAccount = null
    if (name === "branch") {
      obj.fundSource = null
      obj.course = null
      obj.batch = null
    }
    if (name === "type") {
      obj.subHead = null
      obj.chartOfAccount = null
    }
    if (name === "course") {
      obj.batch = null
    }
    setSelectedFields(obj)
    handleValueChange(selected.value, name)
  }

  const handleValueChange = (value, name) => {
    const obj = { ...masterObject }
    obj[name] = value
    if (name === "branch" || name === "subHead") {
      obj.chartOfAccount = null
      handleChartAccountOptions(obj)
    }

    if (name === "branch") {
      obj.fundSource = null
      obj.course = null
      obj.batch = null
      handleFundSourceOptions(obj)
      handleCourseOptions(obj)
    }
    if (name === "course") {
      obj.batch = null
      handleBatchOptions(obj)
    }
    if (name === "type") {
      handleSubHeadOptions(obj)
      obj.subHead = null
      obj.chartOfAccount = null
    }
    if (name === "paymentType" && value === paymentTypes.payment)
      handleFundSourceOptions(obj)
    setMasterObject(obj)
  }

  const handleFilterSelectValueChange = (selected, name) => {
    const obj = { ...filterSelectObject }
    obj[name] = selected
    setFilterSelectObject(obj)
    handleFilterValueChange({ value: selected.value, name })
  }
  const handleFilterValueChange = ({ value, name }) => {
    const obj = { ...filterObject }
    obj[name] = value
    setFilterObject(obj)
    setPage(1)
  }

  const handleFileUpload = async e => {
    const selectedFile = e.target.files[0]
    if (selectedFile) {
      try {
        const fd = new FormData()
        fd.append("image", e.target.files[0])
        axios.post(`${API_URL}expense/image`, fd).then(response => {
          if (response.data.status === 200) {
            handleValueChange(response.data.data.new_filename, "attachment")
          } else {
            toastr.error(response.data.message)
          }
        })
      } catch (error) {
        toastr.error(error)
        e.target.value = ""
      }
    }
  }

  const handleViewModal = async (id, isCredit = true) => {
    setIsOpen(true)
    setSelectedId({ id, isCredit })
  }

  const handleModalClose = () => {
    setIsOpen(false)
    setSelectedId({ id: null, isCredit: true })
    handleTableData()
  }

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  const reset = () => {
    toTop()

    setFilterObject({})
    setFilterSelectObject({})
    setSelectedFields({ ...initialState })

    ref.current?.reset()
    filterRef.current?.reset()

    setBranch(alreadyBranch)
    setSubHeadOptions([])
    setChartAccountOptions([])
    const obj = { ...initialState }

    if (alreadyBranch) {
      obj.branch = alreadyBranch.value || null
    } else {
      setFundSourceOptions([])
    }
    setMasterObject(obj)
  }

  const handleChange = (event, value) => {
    setPage(value)
    handleTableData(value)
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "CSP", field: "branch" },
      { label: "Head", field: "type" },
      { label: "Sub Head", field: "subHead" },
      { label: "Chart Of Account", field: "chartOfAccount" },
      { label: "Credit/Payment", field: "paymentType" },
      { label: "Amount", field: "amount" },
      { label: "Attachment", field: "attachment" },
      { label: "Status", field: "status" },
      { label: "Staff", field: "staff" },
      { label: "Actions", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumb title="Home" breadcrumbItem="Income/Expense" />
        <Card>
          <CardBody>
            <AvForm ref={ref} onValidSubmit={handleValidSubmit}>
              <Row>
                {alreadyBranch ? (
                  ""
                ) : (
                  <Col md={2}>
                    <Label>CSP</Label>
                    <Select
                      value={branch}
                      options={branches}
                      isDisabled={masterObject.isDisabled}
                      onChange={selected => {
                        setBranch(selected)
                        handleSelectValueChange(selected, "branch")
                      }}
                    ></Select>
                  </Col>
                )}
                <Col md={2}>
                  <Label>Head</Label>
                  <Select
                    name="type"
                    options={typeOptions}
                    value={selectedFields.type || ""}
                    isDisabled={!masterObject.branch || masterObject.isDisabled}
                    onChange={selected => {
                      handleSelectValueChange(selected, "type")
                    }}
                  ></Select>
                </Col>
                <Col md="2">
                  <div className="mb-3">
                    <Label>Sub Head</Label>
                    <Select
                      name="subHead"
                      value={selectedFields.subHead || ""}
                      options={subHeadOptions}
                      isDisabled={!masterObject.type || masterObject.isDisabled}
                      onChange={selected => {
                        handleSelectValueChange(selected, "subHead")
                        // setError(false)
                      }}
                    ></Select>
                    <SelectErrorMessage
                      customStyle={{
                        width: "100%",
                        fontSize: "87.5%",
                        color: "var(--bs-form-invalid-color)",
                      }}
                      message="Select Type"
                      // show={error}
                    />
                  </div>
                </Col>
                <Col md={3}>
                  <div className="mb-3">
                    <Label>Chart Of Account</Label>
                    <Select
                      name="chartOfAccount"
                      value={selectedFields.chartOfAccount || ""}
                      options={chartAccountOptions}
                      isDisabled={
                        !masterObject.subHead || masterObject.isDisabled
                      }
                      onChange={selected => {
                        handleSelectValueChange(selected, "chartOfAccount")
                        // setFundSourceError(false)
                      }}
                    ></Select>
                    <SelectErrorMessage
                      customStyle={{
                        width: "100%",
                        fontSize: "87.5%",
                        color: "var(--bs-form-invalid-color)",
                      }}
                      message="Select Chart Of Account"
                      // show={chartAccountError}
                    />
                  </div>
                </Col>
                <Col md={3}>
                  <div className="mb-3">
                    <Label>Course</Label>
                    <Select
                      name="course"
                      value={selectedFields.course || ""}
                      options={courseOptions}
                      isDisabled={
                        !masterObject.branch || masterObject.isDisabled
                      }
                      onChange={selected =>
                        handleSelectValueChange(selected, "course")
                      }
                    ></Select>
                  </div>
                </Col>
                {masterObject.course ? (
                  <Col md={3}>
                    <div className="mb-3">
                      <Label>Batch</Label>
                      <Select
                        name="batch"
                        value={selectedFields.batch || ""}
                        options={batchOptions}
                        isDisabled={
                          !masterObject.branch || masterObject.isDisabled
                        }
                        onChange={selected =>
                          handleSelectValueChange(selected, "batch")
                        }
                      ></Select>
                    </div>
                  </Col>
                ) : (
                  ""
                )}
                <Col md="2">
                  <div className="mb-1">
                    <Label>Amount (₹)</Label>
                    <AvField
                      name="amount"
                      className="form-control"
                      type="text"
                      placeholder="Amount"
                      value={masterObject.amount}
                      isDisabled={!masterObject.branch}
                      errorMessage="Enter valid Amount"
                      onChange={e =>
                        handleValueChange(e.target.value, e.target.name)
                      }
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Amount is required",
                        },
                        min: {
                          value: masterObject.isDisabled
                            ? masterObject.prevAmount
                            : 1,
                          errorMessage: `Amount must be at least ${
                            masterObject.isDisabled
                              ? masterObject.prevAmount || 0
                              : 1
                          }`,
                        },
                      }}
                    />
                  </div>
                </Col>
                <Col md={2}>
                  <Label>Credit/Payment</Label>
                  <Select
                    name="paymentType"
                    options={paymentOptions}
                    isDisabled={
                      !masterObject.branch ||
                      masterObject.isDisabled ||
                      masterObject.isPaymentApproved
                    }
                    onChange={selected => {
                      handleSelectValueChange(selected, "paymentType")
                    }}
                    value={selectedFields.paymentType || ""}
                  ></Select>
                </Col>
                {masterObject.paymentType === 2 ? (
                  <Col md={3}>
                    <div className="mb-3">
                      <Label>Bank / Fund Source</Label>
                      <Select
                        name="fundSource"
                        value={selectedFields.fundSource || ""}
                        options={fundSourceOptions}
                        isDisabled={masterObject.isDisabled}
                        onChange={selected => {
                          handleSelectValueChange(selected, "fundSource")
                          // setFundSourceError(false)
                        }}
                      ></Select>
                      <SelectErrorMessage
                        customStyle={{
                          width: "100%",
                          fontSize: "87.5%",
                          color: "var(--bs-form-invalid-color)",
                        }}
                        message="Select Fund Source"
                        // show={fundSourceError}
                      />
                    </div>
                  </Col>
                ) : (
                  <Col md="2">
                    <div className="mb-1">
                      <Label>Due Date</Label>
                      <AvField
                        name="dueDate"
                        className="form-control"
                        type="date"
                        value={masterObject.dueDate || ""}
                        validate={{ required: { value: true } }}
                        errorMessage="Enter Due Date"
                        onChange={e =>
                          handleValueChange(e.target.value, e.target.name)
                        }
                      />
                    </div>
                  </Col>
                )}

                <Col md={2}>
                  <Label>Date</Label>
                  <AvField
                    name={`date`}
                    className="form-control"
                    type="date"
                    placeholder="Date"
                    errorMessage="Enter valid Date"
                    defaultValue={todayDate}
                    value={masterObject.date || ""}
                    validate={{ required: { value: true } }}
                    max={todayDate}
                    onChange={e =>
                      handleValueChange(e.target.value, e.target.name)
                    }
                  />
                </Col>
                <Col md={2}>
                  <Label>Time</Label>
                  <AvField
                    name={`time`}
                    className="form-control"
                    type="time"
                    placeholder="Time"
                    errorMessage="Enter valid Time"
                    defaultValue={time}
                    value={masterObject.time || ""}
                    validate={{ required: { value: true } }}
                    onChange={e =>
                      handleValueChange(e.target.value, e.target.name)
                    }
                  />
                </Col>
                <Col md="3">
                  <div className="mb-3">
                    <Label htmlFor="validationCustom03">Attachments</Label>
                    {masterObject?.attachment ? (
                      <div div className="img-wraps d-flex flex-column">
                        <img
                          alt=""
                          width="150"
                          height="150"
                          src={`${API_URL}${masterObject.attachment}`}
                        />
                        <button
                          className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                          onClick={() => {
                            handleValueChange("", "attachment")
                          }}
                          style={{ width: "150px" }}
                          type="button"
                        >
                          Delete
                        </button>
                      </div>
                    ) : (
                      <Tooltip
                        title="(Invoice, Images, PDF)"
                        arrow
                        placement="bottom"
                      >
                        <div>
                          <AvField
                            name="file"
                            type="file"
                            className="form-control"
                            id="validationCustom03"
                            rows="1"
                            title=""
                            accept="image/*, .pdf, .xls, .xlsx"
                            onChange={handleFileUpload}
                          />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </Col>
                <Col md="3">
                  <Label>Remark</Label>
                  <AvField
                    name="remarks"
                    placeholder="Remarks"
                    errorMessage="Enter Remark"
                    type="text"
                    value={masterObject.remarks}
                    onChange={e =>
                      handleValueChange(e.target.value, e.target.name)
                    }
                  />
                </Col>

                <Col md={2}>
                  <div
                    className="d-flex"
                    style={{
                      marginTop: "26px",
                    }}
                  >
                    <Button
                      color={masterObject.id ? "warning" : "primary"}
                      type="submit"
                      className="me-2"
                    >
                      {masterObject.id ? "Update" : "Submit"}
                    </Button>
                    <Button color="danger" onClick={reset}>
                      Reset
                    </Button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <AvForm ref={filterRef}>
              <Row>
                <Col md={2}>
                  <div className="mb-3">
                    <Label>From</Label>
                    <AvField
                      name="from"
                      className="form-control"
                      type="date"
                      value={filterObject.form}
                      onChange={e => handleFilterValueChange(e.target)}
                    />
                  </div>
                </Col>
                <Col md={2}>
                  <div className="mb-3">
                    <Label>To</Label>
                    <AvField
                      name="to"
                      className="form-control"
                      type="date"
                      value={filterObject.to}
                      onChange={e => handleFilterValueChange(e.target)}
                    />
                  </div>
                </Col>
                {alreadyBranch ? (
                  ""
                ) : (
                  <Col md={2}>
                    <Label>CSP</Label>
                    <Select
                      name="branch"
                      options={filterBranchOptions}
                      value={filterSelectObject.branch || ""}
                      onChange={selected =>
                        handleFilterSelectValueChange(selected, "branch")
                      }
                    ></Select>
                  </Col>
                )}
                <Col md={2}>
                  <Label>Head</Label>
                  <Select
                    name="type"
                    options={typeOptions}
                    value={filterSelectObject.type || ""}
                    onChange={selected =>
                      handleFilterSelectValueChange(selected, "type")
                    }
                  ></Select>
                </Col>
                <Col md={2}>
                  <Label>Sub Head</Label>
                  <Select
                    name="subHead"
                    options={filterSubHeadOptions}
                    value={filterSelectObject.subHead || ""}
                    onChange={selected =>
                      handleFilterSelectValueChange(selected, "subHead")
                    }
                  ></Select>
                </Col>
                <Col md={2}>
                  <Label>Credit/Payment</Label>
                  <Select
                    name="paymentType"
                    options={paymentOptions}
                    value={filterSelectObject.paymentType || ""}
                    onChange={selected =>
                      handleFilterSelectValueChange(selected, "paymentType")
                    }
                  ></Select>
                </Col>
                <Col md={2}>
                  <Label>Status</Label>
                  <Select
                    name="status"
                    options={filterStatusOptions}
                    value={filterSelectObject.status || ""}
                    onChange={selected =>
                      handleFilterSelectValueChange(selected, "status")
                    }
                  ></Select>
                </Col>
                <Col md="1">
                  <Button
                    color="danger"
                    style={{ margin: "26px 0" }}
                    onClick={reset}
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
            </AvForm>

            <MDBDataTable
              id="creditPaymentTable"
              responsive
              bordered
              searching={false}
              entries={20}
              paging={false}
              info={false}
              sortable={false}
              disableRetreatAfterSorting={true}
              data={data}
            ></MDBDataTable>
            {totalPage > 1 && (
              <TablePagination
                page={page}
                onChange={handleChange}
                count={totalPage}
              />
            )}
          </CardBody>
        </Card>
      </div>
      <CreditFollowup
        id={selectedId.id}
        isCredit={selectedId.isCredit}
        handleModalClose={handleModalClose}
        isOpen={isOpen}
      />
    </div>
  )
}

export default CreditPayments
