import React, { useState, useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"

import toastr from "toastr"
import moment from "moment"
import Select from "react-select"
import { MDBDataTable } from "mdbreact"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"

import { get, put } from "../../../../helpers/api_helper"
import { getScholarshipStatus } from "../../../../store/options/actions"
import "./style.scss"
import { API_URL, SCHOLARSHIP_STATUS } from "../../../../config"

import axios from "axios"
const ScholarshipProcessing = ({ id }) => {
  const [requestData, setRequestData] = useState({})
  const [tableData, setTableData] = useState([])
  const [masterObject, setMasterObject] = useState({ id: id })
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        width: 150,
      },
      {
        label: "Remarks",
        field: "remarks",
        width: 150,
      },
      {
        label: "Added",
        field: "added",
        width: 150,
      },

      {
        label: "Status",
        field: "status",
        width: 150,
      },
    ],
    rows: tableData,
  }

  const ref = useRef()
  const dispatch = useDispatch()
  const { scholarshipStatus } = useSelector(state => state.Options)

  useEffect(() => {
    handleRequestData()
    if (!scholarshipStatus.length) dispatch(getScholarshipStatus())
    // eslint-disable-next-line
  }, [])

  const handleFileUpload = e => {
    const fd = new FormData()
    fd.append("document", e.target.files[0])
    axios.post(`${API_URL}scholarship/image/document`, fd).then(response => {
      if (response.data.status === 200) {
        handleValueChange(response.data.data.new_filename, "document")
      } else {
        toastr.error(response.data.message)
      }
    })
  }
  const handleRequestData = () => {
    get(`scholarship/request/details?id=${id}`).then(res => {
      const result = res.data
      const followup = result?.followup || []

      delete result?.followup

      followup.map((item, index) => {
        item.id = index + 1
        item.date = moment(item.date).format("DD-MM-YYYY")
        item.time = moment(item.time, "HH:mm:ss").format("hh:mm a")
        item.remarks = item.comments
        item.added = item.addedBy
        const statusData = item.status
        item.status = (
          <div>
            <span
              className="badge font-size-12 ms-2"
              style={{ backgroundColor: statusData.color }}
            >
              {statusData.name}
            </span>
          </div>
        )
        return item
      })
      // { 1: "Male", 2: "Female", 3: "Other" }
      //? 0 = female, 1 = male , 2 = other
      // const genderMap = { 1: "Male", 2: "Female", 3: "Other" }

      let genderOptions = { 0: 2, 1: 1, 2: 3 }
      const studentGender = genderOptions[result.studentGender]
      const studentCategory = result.studentCategory
      const studentDistrict = result.studentDistrict
      const studentMinorityStatus = result.studentMinorityStatus

      let eligible = {
        category: false,
        gender: false,
        district: false,
        minorityStatus: false,
      }
      if (result.scholarshipCustom) {
        if (
          result.scholarshipCustomConditions &&
          result.scholarshipCustomConditions.length
        ) {
          for (const data of result.scholarshipCustomConditions) {
            const {
              category,
              gender,
              district,
              percentage,
              maximum,
              minorityStatus,
            } = data
            const categoryMatch = !category || category === studentCategory
            const genderMatch = !gender || gender === studentGender
            const districtMatch = !district || district === studentDistrict
            const minorityMatch =
              !minorityStatus || minorityStatus === studentMinorityStatus

            if (
              categoryMatch &&
              genderMatch &&
              districtMatch &&
              minorityMatch
            ) {
              const percentageOfCourseFee =
                (Number(result.courseAmount) * Number(percentage)) / 100

              result.eligibleAmount = Math.min(
                percentageOfCourseFee,
                Number(maximum)
              )

              const eligible = {
                category: categoryMatch,
                gender: genderMatch,
                district: districtMatch,
                minorityStatus: minorityMatch,
              }
              result.eligible = eligible
              result.eligibleStatus = true
              break
            } else {
              result.eligibleAmount = 0
            }
          }
        } else {
          result.eligibleAmount = Math.min(
            (Number(result.courseAmount) * Number(result.percentage)) / 100,
            Number(result.maximum)
          )
        }
      } else {
        const categoryMatch =
          !result.scholarshipCategory?.length ||
          result.scholarshipCategory.includes(studentCategory)
        const genderMatch =
          !result.scholarshipGender?.length ||
          result.scholarshipGender.includes(studentGender)
        const districtMatch =
          !result.scholarshipDistrict?.length ||
          result.scholarshipDistrict.includes(studentDistrict)

        const minorityMatch =
          !result.scholarshipMinorityStatus?.length ||
          result.scholarshipMinorityStatus.includes(studentMinorityStatus)

        if (categoryMatch && genderMatch && districtMatch && minorityMatch) {
          result.eligibleAmount = Math.min(
            (Number(result.courseAmount) * Number(result.percentage)) / 100,
            Number(result.maximum)
          )
        } else {
          result.eligibleAmount = 0
        }
        eligible = {
          category: categoryMatch,
          gender: genderMatch,
          district: districtMatch,
          minorityStatus: minorityMatch,
        }
        result.eligible = eligible
        result.eligibleStatus =
          categoryMatch && genderMatch && districtMatch && minorityMatch
      }

      // const genderMap = { 1: "Male", 2: "Female", 3: "Other" }

      //? 0 = female, 1 = male , 2 = other
      const genderMap = { 0: "Female", 1: "Male", 2: "Other" }
      
      result.gender = genderMap[result.studentGender] || "Not provided"
      setTableData(followup)
      setRequestData(result)
    })
  }
  const handleSubmit = () => {
    put("scholarship/request/status", masterObject)
      .then(res => {
        reset()
        toastr.success(res.message)
      })
      .catch(err => {
        toastr.error(err.response.data.message)
      })
  }

  const handleValueChange = (value, name) => {
    const obj = { ...masterObject }
    obj[name] = value
    if (value === SCHOLARSHIP_STATUS.APPROVED) {
      console.log(requestData.eligibleAmount)
      obj.amount = requestData.eligibleAmount
    }
    console.log(obj)
    setMasterObject(obj)
  }

  const reset = () => {
    ref.current?.reset()
    handleRequestData()
    setMasterObject({ id: id })
  }
  return (
    <>
      <Row>
        <Col md="12">
          <ul className="lead-details">
            <li>
              Name : <Label>{requestData?.studentName}</Label>
            </li>

            <li>
              Mobile : <Label>{requestData?.studentMobile}</Label>
            </li>
            <li>
              Email : <Label>{requestData?.studentEmail}</Label>
            </li>
            <li>
              District : <Label>{requestData?.district}</Label>
            </li>
            <li>
              CSP : <Label>{requestData?.csp}</Label>
            </li>
            <li>
              Request Id: <Label>{requestData?.uniqueId}</Label>
            </li>
            <li>
              Course : <Label>{requestData?.course}</Label>
            </li>
            <li>
              Course Fee: <Label>{requestData?.courseAmount}</Label>
            </li>

            <li>
              Eligible Amount: <Label>{requestData?.eligibleAmount}</Label>
            </li>
            <li>
              Scholarship: <Label>{requestData?.scholarship}</Label>
            </li>
          </ul>
          {requestData.status === 2 ? (
            <ul className="lead-details">
              <li>
                Approved Date :{" "}
                <Label>
                  {moment(requestData?.approvedDate).format("DD-MM-YYYY")}
                </Label>
              </li>
              <li>
                Approved Time :{" "}
                <Label>
                  {moment(requestData?.approvedTime, "HH:mm:ss").format(
                    "hh:mm a"
                  )}
                </Label>
              </li>
              <li>
                Approved By : <Label>{requestData?.approvedBy}</Label>
              </li>
              <li>
                Approved Amount : <Label>{requestData?.approvedAmount}</Label>
              </li>
              <li>
                Comments : <Label>{requestData?.approvedComments}</Label>
              </li>
            </ul>
          ) : (
            ""
          )}
          {requestData.status === 3 ? (
            <ul className="lead-details">
              <li>
                Rejected Date :{" "}
                <Label>
                  {moment(requestData?.approvedDate).format("DD-MM-YYYY")}
                </Label>
              </li>
              <li>
                Rejected Time :{" "}
                <Label>
                  {moment(requestData?.approvedTime, "HH:mm:ss").format(
                    "hh:mm a"
                  )}
                </Label>
              </li>
              <li>
                Rejected By : <Label>{requestData?.approvedBy}</Label>
              </li>
              <li>
                Comments : <Label>{requestData?.approvedComments}</Label>
              </li>
            </ul>
          ) : (
            ""
          )}
          {/* <table className="table  lead-info table-striped">
            <tbody>
              <tr>
                <td>Name</td>
                <th>: {requestData?.studentName}</th>
                <td>Mobile</td>
                <th>: {requestData?.studentMobile}</th>
                <td>Email</td>
                <th>: {requestData?.studentEmail}</th>
                <td>District</td>
                <th>: {requestData?.district}</th>
                <td></td>
                <th></th>
              </tr>

              <tr>
                <td>Course</td>
                <th>: {requestData?.course}</th>
                <td>CSP</td>
                <th>: {requestData?.csp}</th>
                <td></td>
                <th></th>
                <td></td>
                <th></th>
              </tr>
              <tr>
                <td>Course Amount</td>
                <th>: {requestData?.courseAmount}</th>
                <td>Request Amount</td>
                <th>: {requestData?.requestAmount}</th>
                <td></td>
                <th></th>
                <td></td>
                <th></th>
                <td></td>
                <th></th>
              </tr>
            </tbody>
          </table> */}
        </Col>
        <Col md={6}>
          <table
            className="table table-bordered lead-info table-striped"
            id="scholarshipEligibleStatus"
          >
            <thead>
              <tr>
                <th>Eligible By Category</th>
                <td>{requestData.category ?? "Not provided"}</td>
                <td>
                  {requestData?.eligible?.category ? (
                    <p style={{ color: "green" }}>Eligible</p>
                  ) : (
                    <p style={{ color: "red" }}>Not Eligible</p>
                  )}
                </td>
              </tr>
              <tr>
                <th>Eligible By Gender</th>
                <td>{requestData.gender}</td>
                <td>
                  {requestData?.eligible?.gender ? (
                    <p style={{ color: "green" }}>Eligible</p>
                  ) : (
                    <p style={{ color: "red" }}>Not Eligible</p>
                  )}
                </td>
              </tr>
              <tr>
                <th>Eligible By District</th>
                <td>{requestData.district ?? "Not provided"}</td>
                <td>
                  {requestData?.eligible?.district ? (
                    <p style={{ color: "green" }}>Eligible</p>
                  ) : (
                    <p style={{ color: "red" }}>Not Eligible</p>
                  )}
                </td>
              </tr>
              <tr>
                <th>Eligible By Minority status</th>
                <td>{requestData.minorityStatus ?? "Not provided"}</td>
                <td>
                  {requestData?.eligible?.minorityStatus ? (
                    <p style={{ color: "green" }}>Eligible</p>
                  ) : (
                    <p style={{ color: "red" }}>Not Eligible</p>
                  )}
                </td>
              </tr>
              <tr>
                <td></td>
                <th>Eligible Status</th>
                <th>
                  {requestData.eligibleStatus ? (
                    <p style={{ color: "green" }}>Eligible</p>
                  ) : (
                    <p style={{ color: "red" }}>Not Eligible</p>
                  )}
                </th>
              </tr>
            </thead>
          </table>
        </Col>
        {requestData.status === 0 ? (
          <Col md="12" lg="12">
            <Card>
              <CardBody>
                <AvForm ref={ref} onValidSubmit={() => handleSubmit()}>
                  <Row>
                    <Col md="3">
                      <div className="">
                        <Label>Status</Label>
                        <Select
                          name="description"
                          type="text"
                          value={scholarshipStatus.filter(
                            item => item.value === masterObject.status
                          )}
                          onChange={selected => {
                            handleValueChange(selected.value, "status")
                          }}
                          options={scholarshipStatus.filter(
                            item => item.value !== requestData.currentStatus
                          )}
                          errorMessage="Enter Status"
                        />
                      </div>
                    </Col>
                    {masterObject.status === SCHOLARSHIP_STATUS.APPROVED ? (
                      <>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Document</Label>
                            {masterObject?.document ? (
                              <div div className="img-wraps d-flex flex-column">
                                <img
                                  alt=""
                                  width="150"
                                  height="150"
                                  src={`${API_URL}${masterObject.document}`}
                                />
                                <button
                                  className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                  onClick={() => {
                                    setMasterObject(pre => ({
                                      ...pre,
                                      document: null,
                                    }))
                                  }}
                                  style={{ width: "150px" }}
                                  type="button"
                                >
                                  Delete
                                </button>
                              </div>
                            ) : (
                              <AvField
                                name="file"
                                className="form-control"
                                type="file"
                                onChange={handleFileUpload}
                              />
                            )}
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Amount</Label>
                            <AvField
                              name="amount"
                              type="number"
                              placeholder="Approved amount"
                              errorMessage="Enter amount"
                              validate={{
                                required: { value: true },
                                // min: { value: 1 },
                                // For eligible amount validation
                                max: {
                                  value: requestData?.eligibleAmount || 0,
                                  errorMessage:
                                    "Amount is greater than Eligible amount",
                                },
                              }}
                              value={masterObject.amount}
                              onChange={e => {
                                handleValueChange(e.target.value, "amount")
                              }}
                            />
                          </div>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                    {masterObject.status && (
                      <>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Comments</Label>
                            <AvField
                              name="Comments"
                              rows={1}
                              type="textarea"
                              placeholder="Comments"
                              errorMessage="Enter comments"
                              validate={{ required: { value: true } }}
                              value={masterObject.comments}
                              onChange={e => {
                                handleValueChange(e.target.value, "comments")
                              }}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3" style={{ paddingTop: "25px" }}>
                            <Button
                              style={{ marginRight: "1%" }}
                              color="primary"
                              type="submit"
                              className="mx-2"
                            >
                              Submit
                            </Button>
                            <Button
                              style={{ marginRight: "3%" }}
                              color="danger"
                              onClick={() => reset()}
                            >
                              Reset
                            </Button>
                          </div>
                        </Col>
                      </>
                    )}
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        ) : (
          ""
        )}
        <Col md={12} className="mt-3">
          <Card>
            <CardBody>
              <MDBDataTable
                id="viewScholarshipHistory"
                responsive
                bordered
                data={data}
                searching={true}
                info={true}
                disableRetreatAfterSorting={true}
                entries={20}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ScholarshipProcessing
