import React, { useEffect, useState } from "react"
import { Row, Col, Label, Button, Collapse } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import { get } from "../../../../../helpers/api_helper"
import moment from "moment"
import "../style.scss"
import Select from "react-select"
import classnames from "classnames"
import { toggleLeftmenu } from "../../../../../store/actions"
import { Tooltip as TooltipMui } from "@mui/material"
import { jsonToExcel } from "../../../../../helpers/functions"
import toastr from "toastr"

function Registrations(props) {
  const EventId = props.data
  const [registration, setRegistration] = useState([])
  const [bookingDates, setbookingDates] = useState([])
  const [filter, setFilter] = useState({ date: null, attended: null })
  const [participationTable, setParticipationTable] = useState([])
  const [collapse, setCollapse] = useState(null)
  const [attendaceStatus, setAttendanceStatus] = useState(true)

  useEffect(() => {
    handleTableData()
    fetchAllDates()
    toggleLeftmenu()
    //eslint-disable-next-line
  }, [])

  function fetchAllDates() {
    get(`/event-reg/dates?event=${EventId}`).then(res => {
      let result = res?.Data[0]?.bookingDate
      const bookDates = result.map(date => ({ label: date, value: date }))
      setbookingDates(bookDates)
    })
  }

  function handleTableData() {
    get(`/event-reg/registeredList?event=${EventId}`).then(res => {
      let result = res?.events
      result.map((item, index) => {
        item.id = index + 1
        item.date = moment(item?.date).format("DD-MM-YYYY")
        item.time = moment(item?.time, "hh:mm a").format("hh:mm a")
        item.profile1 =
          (item?.profile?.firstname ?? "") +
          " " +
          (item?.profile?.lastname ?? "")
        item.mobile = item?.profile?.mobile 
        item.studentId = item?.profile?.unique_id
        item.eventname = item?.event?.name
        item.paidStatus = item?.payedStatus === 1 ? "Paid" : "Unpaid"
        item.eventid = item?.event?.uniqueId
        item.attdate = `${item?.attended} / ${item?.eventBooking}`
        return item
      })
      setRegistration(result)
    })
  }

  const handleExportData = async () =>{
    try {
      const res = await get(`/event-reg/registeredList?event=${EventId}`)
      let exportData = []
      if (res?.events && Array.isArray(res?.events) && res?.events.length > 0) {

      res?.events?.map((item,index)=>{
        const exportObj = {}
        exportObj['SI NO'] = index + 1
        exportObj['Registered Date'] = moment(item?.date).format("DD-MM-YYYY")
        exportObj['Ticket No.'] = item?.uniqueId
        exportObj['Student ID'] = item?.profile?.unique_id
        exportObj.Name =  `${item?.profile?.firstname || ""} ${item?.profile?.lastname || ""}`
        exportObj.Mobile = item?.profile?.mobile 
        exportObj['Paid Status'] = item?.payedStatus === 1 ? "Paid" : "Unpaid"
        exportObj.Attendance = `${item?.attended} / ${item?.eventBooking}`
        exportData.push(exportObj)
      })
      
        jsonToExcel(exportData, `Event-Registration-${moment().format("DD-MM-YY")}`)
      } else {
        toastr.info(`There are No Event Registration to export`)
      }
    } catch (error) {
      
    }
  }

  function handleParticipationTable() {
    get(
      `event-reg/events-attendance?eventId=${EventId}&date=${filter?.date?.value}&attended=${filter?.attended?.value}`
    ).then(res => {
      let result = res?.data
      if(res.data[0]?.attendance === undefined){
        setAttendanceStatus(false)
        return
      }else{
        setAttendanceStatus(true)
      }
      setParticipationTable(
        result.map((item, index) => ({
          id: index + 1,
          name: `${item?.profile?.firstname || ""} ${
            item?.profile?.lastname || ""
          }`,
          uniqueId: item?.uniqueId,
          mobile: item?.profile?.mobile,
          studentId: item?.profile?.unique_id,
          bookingDate: moment(item.bookingDate).format('DD-MM-YYYY'),
          attendance:
            item?.attendance === 1 ? (
              <span className="badge bg-success font-size-12 ms-2">
                Attended
              </span>
            ) : item?.attendance === 0 ? (
              <span className="badge bg-danger font-size-12 ms-2">
                Not Attended
              </span>
            ) : (
              ""
            ),
        }))
      )
    })
  }


  const handleExportParticipant = async () =>{
    try {
      const res = await get(`event-reg/events-attendance?eventId=${EventId}&date=${filter?.date?.value}&attended=${filter?.attended?.value}`)
      let exportData = []
      if (res?.data && Array.isArray(res?.data) && res?.data.length > 0) {
      res?.data?.map((item,index)=>{
        const exportObj = {}
        exportObj['SI NO'] = index + 1
        exportObj['Booking Date'] = moment(item?.bookingDate).format("DD-MM-YYYY")
        exportObj['Ticket No.'] = item?.uniqueId
        exportObj['Student ID'] = item?.profile?.unique_id
        exportObj.Name =  `${item?.profile?.firstname || ""} ${item?.profile?.lastname || ""}`
        exportObj.Mobile = item?.profile?.mobile 
        exportObj['Status'] = item?.attendance === 1 ? "Attended" : item?.attendance === 0 ? "Not Attended"  : ""
        exportData.push(exportObj)
      })
        jsonToExcel(exportData, `Event-Participation-${moment().format("DD-MM-YY")}`)
      } else {
        toastr.info(`There are No Event Participation to export`)
      }
    } catch (error) {
      
    }
  }

  useEffect(() => {
      handleParticipationTable()
    //eslint-disable-next-line
  }, [filter])

  const data1 = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Registered Date",
        field: "date",
        width: 150,
      },

      {
        label: "Ticket No.",
        field: "uniqueId",
        width: 150,
      },
      {
        label: "Student ID",
        field: "studentId",
        sort: "desc",
        width: 400,
      },
      {
        label: "Name",
        field: "profile1",
        sort: "desc",
        width: 400,
      },
      {
        label: "Mobile",
        field: "mobile",
        width: 150,
      },
      {
        label: "Paid Status",
        field: "paidStatus",
        width: 150,
      },
      {
        label: "Attendance",
        field: "attdate",
        width: 150,
      },
    ],
    rows: registration,
  }

  const data2 = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Booking Date",
        field: "bookingDate",
        width: 150,
      },
      {
        label: "Ticket No.",
        field: "uniqueId",
        width: 150,
      },
      {
        label: "Student ID",
        field: "studentId",
        sort: "desc",
        width: 400,
      },
      {
        label: "Name",
        field: "name",
        sort: "desc",
        width: 400,
      },
      {
        label: "Mobile",
        field: "mobile",
        width: 150,
      },

      {
        label: "Status",
        field: "attendance",
        width: 150,
      },
    ],
    rows: participationTable,
  }

  const handleCollapse = index => {
    if (collapse === index) {
      setCollapse(null)
    } else {
      setCollapse(index)
    }
  }

  return (
    <div>
      <div className="accordion mb-2" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className={classnames("accordion-button", "fw-medium", {
                collapsed: collapse !== 1,
              })}
              type="button"
              onClick={() => handleCollapse(1)}
              style={{ cursor: "pointer" }}
            >
              Event Registrations
            </button>
          </h2>
          <Collapse
            id="collapseOne"
            className="accordion-collapse"
            isOpen={collapse === 1}
          >
            <div className="accordion-body">
              <Row>
                <Col className="relative col-12 mt-2">
                      <div className="p-2 pt-0 d-flex justify-content-end me-1 absolute top-10 ">
                        <TooltipMui
                          title={"Export"}
                          arrow
                          placement="top"
                          id="button-tooltip-2"
                        >
                          <i
                            onClick={handleExportData}
                            className="cursor-pointer fs-4 fas fa-file-export"
                            style={{
                              position: "absolute",
                              top: "15px",
                              right: "170px",
                              zIndex: "3",
                            }}
                          ></i>
                        </TooltipMui>
                      </div>
                  <MDBDataTable
                    id="registeredpeopleid"
                    responsive
                    bordered
                    data={data1}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                  />
                </Col>
              </Row>
            </div>
          </Collapse>
        </div>
      </div>
      <div className="accordion mb-2" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className={classnames("accordion-button", "fw-medium", {
                collapsed: collapse !== 2,
              })}
              type="button"
              onClick={() => handleCollapse(2)}
              style={{ cursor: "pointer" }}
            >
              Event Participation
            </button>
          </h2>
          <Collapse
            id="collapseOne"
            className="accordion-collapse"
            isOpen={collapse === 2}
          >
            <div className="accordion-body">
              <Row>
                <Col md="3">
                  <Label>Booking Date</Label>
                  <Select
                    value={filter.date}
                    onChange={e => setFilter({ ...filter, date: e })}
                    options={bookingDates}
                  />
                </Col>
                <Col md="3">
                  <Label>Status</Label>
                  <Select
                    value={filter.attended}
                    onChange={e => setFilter({ ...filter, attended: e })}
                    options={[
                      { label: "Attended", value: 1 },
                      { label: "Not Attended", value: 0 },
                    ]}
                  />
                </Col>
                <Col md="5" style={{display:"flex"}}>
                  <Button
                    style={{ marginTop: "29px" }}
                    color="danger"
                    onClick={() => setFilter({ date: null, attended: null })}
                  >
                    Reset
                  </Button>
                  {!attendaceStatus && <p style={{marginTop:'33px', marginLeft:"5px ", color:'red'}}>* Attendance not marked.</p>}
                </Col>
                <Col className="relative col-12 mt-2">
                      <div className="p-2 pt-0 d-flex justify-content-end me-1 absolute top-10 ">
                        <TooltipMui
                          title={"Export"}
                          arrow
                          placement="top"
                          id="button-tooltip-2"
                        >
                          <i
                            onClick={handleExportParticipant}
                            className="cursor-pointer fs-4 fas fa-file-export"
                            style={{
                              position: "absolute",
                              top: "15px",
                              right: "170px",
                              zIndex: "3",
                            }}
                          ></i>
                        </TooltipMui>
                      </div>
                  <MDBDataTable
                    id="participationTableId"
                    responsive
                    bordered
                    data={data2}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                  />
                </Col>
              </Row>
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  )
}

export default Registrations
