import React, { useEffect, useState } from "react"
import { Card, CardBody, Table, CardTitle, Row, Col } from "reactstrap"
import { numberToCurrency } from "../../../../helpers/functions"
import { useNavigate } from "react-router-dom"
import { get } from "../../../../helpers/api_helper"

const ExpenseDetails = ({ masterObject }) => {
  const navigate = useNavigate()
  const [expenseDetails, setExpenseDetails] = useState([])
  const [expenseHeads, setExpenseHeads] = useState([])
  useEffect(() => {
    handleExpenseDetails()
    // eslint-disable-next-line
  }, [masterObject])

  const handleExpenseDetails = async () => {
    try {
      const response = await get(
        `/finance-dashboard/details/expense?startDate=${masterObject.startDate}&endDate=${masterObject.endDate}`
      )
      setExpenseDetails(response.data)
      setExpenseHeads(response.expenses)
    } catch (error) {}
  }

  const handleNavigate = item => {
    if (!item._id) return
    navigate("/expense-report", {
      state: { branch: item._id, branchName: item.name },
    })
  }
  
  return (
    <Row>
      {expenseDetails.length > 0 && (
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle className="h4 mb-4">Expense Details</CardTitle>
              <div className="table-responsive">
                <Table
                  className="table-centered table-nowrap mb-0"
                  id="expenseDetails"
                >
                  <thead className="table-light">
                    <tr>
                      <th>#</th>
                      <th>CSP</th>
                      {expenseHeads.map(item => (
                        <th>{item.name}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {expenseDetails?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td
                            onClick={() => handleNavigate(item)}
                            style={{ cursor: "pointer" }}
                          >
                            {item.name}
                          </td>
                          {expenseHeads.map(expense => {
                            const amount = numberToCurrency(
                              item?.data[expense._id]
                            )
                            return <td>{amount}</td>
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      )}
    </Row>
  )
}

export default ExpenseDetails
