const useGender = value => {
  return value === 0
    ? "Female"
    : value === 1
    ? "Male"
    : value === 2
    ? "Other"
    : null
}

export default useGender
