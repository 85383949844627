import React from "react"
import { Row, Col } from "reactstrap"

function index() {
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row className="mb-2">
            <Col md={6}></Col>
            <Col md={6}>
              <div className="form-inline float-md-end mb-3">
                <div className="search-box ml-2">
                  <div className="position-relative">
                    <input
                      type="text"
                      className="form-control rounded border-0"
                      placeholder="Search..."
                    />
                    <i className="mdi mdi-magnify search-icon"></i>
                  </div>
                </div>
              </div>
            </Col>
            <div class="col-12 col-md-6 col-xl-4">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex media">
                    <div class="avatar-sm me-4">
                      <img
                        alt=""
                        width="50"
                        height="50"
                        src="https://crmapi.bayspro.online///uploads/student/2024117103551IMG_5301.jpeg"
                      />
                    </div>
                    <div class="flex-1 align-self-center media-body">
                      <div>
                        <h5 class="text-truncate font-size-16 mb-1">
                          <a class="text-dark" href="/students">
                            Abhinand K
                          </a>
                        </h5>
                        <p class="text-muted">
                          <i class="fas fa-envelope"></i>{" "}
                          abhinandkunju123@gmail.com
                          <br />
                          <i class="fas fa-mobile-alt"></i> 9961436757
                        </p>
                      </div>
                      <button type="button" class="btn btn-sm btn btn-success">
                        Active
                      </button>
                    </div>
                  </div>
                  <div class=" d-flex justify-content-end">
                    <span
                      class="student-delete-btn"
                      style={{ fontSize: "1.4rem" }}
                    >
                      <i class="trash-btn mdi mdi-delete"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-xl-4">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex media">
                    <div class="avatar-sm me-4">
                      <img
                        alt=""
                        width="50"
                        height="50"
                        src="https://crmapi.bayspro.online///uploads/student/2024117101545eb22dd434735be32426170a62502490c.jpg"
                      />
                    </div>
                    <div class="flex-1 align-self-center media-body">
                      <div>
                        <h5 class="text-truncate font-size-16 mb-1">
                          <a class="text-dark" href="/students">
                            Afeeda Anwar
                          </a>
                        </h5>
                        <p class="text-muted">
                          <i class="fas fa-envelope"></i>{" "}
                          afeedha@gmail.com
                          <br />
                          <i class="fas fa-mobile-alt"></i> 9961436757
                        </p>
                      </div>
                      <button type="button" class="btn btn-sm btn btn-success">
                        Active
                      </button>
                    </div>
                  </div>
                  <div class=" d-flex justify-content-end">
                    <span
                      class="student-delete-btn"
                      style={{ fontSize: "1.4rem" }}
                    >
                      <i class="trash-btn mdi mdi-delete"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-xl-4">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex media">
                    <div class="avatar-sm me-4">
                      <img
                        alt=""
                        width="50"
                        height="50"
                        src="https://crmapi.bayspro.online///uploads/student/20231130164418IMG_20230801_161414_978.jpg"
                      />
                    </div>
                    <div class="flex-1 align-self-center media-body">
                      <div>
                        <h5 class="text-truncate font-size-16 mb-1">
                          <a class="text-dark" href="/students">
                            Akshay k k
                          </a>
                        </h5>
                        <p class="text-muted">
                          <i class="fas fa-envelope"></i>{" "}
                          alshayav@gmail.com
                          <br />
                          <i class="fas fa-mobile-alt"></i> 9961436757
                        </p>
                      </div>
                      <button type="button" class="btn btn-sm btn btn-success">
                        Active
                      </button>
                    </div>
                  </div>
                  <div class=" d-flex justify-content-end">
                    <span
                      class="student-delete-btn"
                      style={{ fontSize: "1.4rem" }}
                    >
                      <i class="trash-btn mdi mdi-delete"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-xl-4">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex media">
                    <div class="avatar-sm me-4">
                      <img
                        alt=""
                        width="50"
                        height="50"
                        src="https://crmapi.bayspro.online///uploads/student/20…-09-40-37-39_8b1cfbb769bd52fc36fa25a4fcc64305.jpg"
                      />
                    </div>
                    <div class="flex-1 align-self-center media-body">
                      <div>
                        <h5 class="text-truncate font-size-16 mb-1">
                          <a class="text-dark" href="/students">
                            Shammas
                          </a>
                        </h5>
                        <p class="text-muted">
                          <i class="fas fa-envelope"></i>{" "}
                          shammas@gmail.com
                          <br />
                          <i class="fas fa-mobile-alt"></i> 9961436757
                        </p>
                      </div>
                      <button type="button" class="btn btn-sm btn btn-success">
                        Active
                      </button>
                    </div>
                  </div>
                  <div class=" d-flex justify-content-end">
                    <span
                      class="student-delete-btn"
                      style={{ fontSize: "1.4rem" }}
                    >
                      <i class="trash-btn mdi mdi-delete"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-xl-4">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex media">
                    <div class="avatar-sm me-4">
                      <img
                        alt=""
                        width="50"
                        height="50"
                        src="https://crmapi.bayspro.online///uploads/student/2024117103551IMG_5301.jpeg"
                      />
                    </div>
                    <div class="flex-1 align-self-center media-body">
                      <div>
                        <h5 class="text-truncate font-size-16 mb-1">
                          <a class="text-dark" href="/students">
                            Anuranj
                          </a>
                        </h5>
                        <p class="text-muted">
                          <i class="fas fa-envelope"></i>{" "}
                          anuranj@gmail.com
                          <br />
                          <i class="fas fa-mobile-alt"></i> 9961436757
                        </p>
                      </div>
                      <button type="button" class="btn btn-sm btn btn-success">
                        Active
                      </button>
                    </div>
                  </div>
                  <div class=" d-flex justify-content-end">
                    <span
                      class="student-delete-btn"
                      style={{ fontSize: "1.4rem" }}
                    >
                      <i class="trash-btn mdi mdi-delete"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-xl-4">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex media">
                    <div class="avatar-sm me-4">
                      <img
                        alt=""
                        width="50"
                        height="50"
                        src="https://crmapi.bayspro.online///uploads/student/2024117103551IMG_5301.jpeg"
                      />
                    </div>
                    <div class="flex-1 align-self-center media-body">
                      <div>
                        <h5 class="text-truncate font-size-16 mb-1">
                          <a class="text-dark" href="/students">
                            Abhinand K
                          </a>
                        </h5>
                        <p class="text-muted">
                          <i class="fas fa-envelope"></i>{" "}
                          abhinandkunju123@gmail.com
                          <br />
                          <i class="fas fa-mobile-alt"></i> 9961436757
                        </p>
                      </div>
                      <button type="button" class="btn btn-sm btn btn-success">
                        Active
                      </button>
                    </div>
                  </div>
                  <div class=" d-flex justify-content-end">
                    <span
                      class="student-delete-btn"
                      style={{ fontSize: "1.4rem" }}
                    >
                      <i class="trash-btn mdi mdi-delete"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default index
