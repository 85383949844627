import React, { useEffect, useState } from "react"
import { Row, Col, Table, Card, Label, Button } from "reactstrap"
import { get } from "../../../helpers/api_helper"
import "./Footfall.scss"
import { CardContent, Divider } from "@mui/material"
import { AvField, AvForm } from "availity-reactstrap-validation"
import toastr from "toastr"
import moment from "moment"

const Footfall = () => {
  const [tableData, setTableData] = useState([])
  const [selectedRow, setSelectedRow] = useState({branchId:'',index:'',name:''})
  const [toggle, setToggle] = useState(true)
  const [tableLoading, setTableLoading] = useState(false)
  const [cardLoading, setCardLoading] = useState(false)
  const from = moment().subtract(30, 'days').format('YYYY-MM-DD')
  const to = moment().format('YYYY-MM-DD')
  const [filter, setFilter] = useState({ from: from, to: to })
  const [cardData, setCardData] = useState([])

  const fetchFootfallTable = () => {
    if (!filter.from || !filter.to) return
    if (filter.from > filter.to) {
      return toastr.error("Invalid Date Range")
    }
    if (filter.from && filter.to && filter.from < filter.to) {
      setTableLoading(true)
      get(`batch/csp-wise-footfall?from=${filter.from}&to=${filter.to}`)
        .then(res => {
          if (res.success) {
            setTableData(res.data)
          }
        })
        .catch(err => toastr.error(err.response.data.message))
      setTableLoading(false)
    }
  }

  useEffect(() => {
    fetchFootfallTable()
    //eslint-disable-next-line
  }, [filter.from, filter.to])

  const fetchFootfallTableCard = () => {  
    setCardLoading(true)
    get(`batch/csp-wise-batch?branch=${selectedRow.branchId}&from=${filter.from}&to=${filter.to}`)
      .then(res => {
        if (res.success) {
          setCardData(res.data)
          if(res.data.length === 0) {
            setSelectedRow({ branchId: '', index: '', name: '' })
            setToggle(true)
          }
        }
      })
      .catch(err => toastr.error(err.response.data.message))
    setCardLoading(false)
  }

  useEffect(() => {
    if (selectedRow.branchId) {
      fetchFootfallTableCard()
    }
    //eslint-disable-next-line
  },[selectedRow,filter.from, filter.to])

  const handleReset = () => {
    setFilter({ from: from, to: to })
  }

  const handleRowClick = ( index, branchId,name )=>{
    setSelectedRow({ branchId: branchId, index: index, name: name })
  }

  return (
    <div style={{ fontSize: "13px", fontFamily: "IBM Plex Sans, sans-serif" }}>
      <AvForm
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <span style={{ display: "flex", justifyContent: "space-evenly" }}>
          <span
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "baseline",
            }}
          >
            <Label style={{ marginRight: "10px" }}>From</Label>
            <AvField
              name="from"
              type="date"
              value={filter.from}
              onChange={e =>
                setFilter(prev => ({ ...prev, from: e.target.value }))
              }
              max={filter.to}
            />
          </span>
          <span
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "baseline",
              marginRight: "10px",
            }}
          >
            <Label style={{ marginLeft: "20px" }}>To</Label>
            <AvField
              style={{ marginLeft: "10px" }}
              name="to"
              type="date"
              value={filter.to}
              onChange={e =>
                setFilter(prev => ({ ...prev, to: e.target.value }))
              }
              max={to}
            />
          </span>
          <Button
            style={{ height: "36px", marginLeft: "10px" }}
            color="danger"
            onClick={handleReset}
          >
            Reset
          </Button>
        </span>
        <strong style={{ alignSelf: "baseline" }}>
          {selectedRow?.name}
        </strong>
        <Button
          style={{ height: "36px" }}
          color="primary"
          onClick={() => setToggle(prev => !prev)}
          disabled={cardData.length === 0}
        >
          Toggle Table
        </Button>
      </AvForm>
      <Row>
        {toggle && (
          <Col lg={6}>
            <Table id="footfallTableId" className="table-hover" bordered>
              <thead>
                <tr>
                  <th>#</th>
                  <th>CSP Center</th>
                  <th>Total Hours</th>
                  <th>Footfall</th>
                  <th># Events/Courses</th>
                </tr>
              </thead>
              <tbody>
                {tableData.length > 0 ? (
                  tableData.map((item, index) => (
                    <tr
                      key={index}
                      onClick={() => handleRowClick(index,item?.branchId,item.name)}
                    >
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>{item.time}</td>
                      <td>{item.footfall}</td>
                      <td>{item.eventOrCourse}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5}>No Rows</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        )}

        <Col lg={toggle ? 6 : 12}>
          <Row>
            {cardData.length > 0 && cardData.map ((item, index) => (
            <Col key={index} lg={toggle ? 6 : 3}>
              <Card>
                <CardContent>
                  <h6>{item.name}</h6>
                  <p>Enrolled : {item.totalStudents}</p>
                  <Divider />
                  <div className="d-flex justify-content-between">
                    <strong>Date</strong>
                    <strong>Hours</strong>
                    <strong>Attended</strong>
                  </div>
                  <div
                    className="custom-scrollbar"
                    style={{
                      overflowY: "auto",
                      height: "135px",
                      paddingRight: "10px",
                      marginTop: "10px",
                    }}
                  >{item.sessions.map((session, sessionIndex) => (
                    <div key={sessionIndex} className="d-flex justify-content-between">
                      <p>{moment(session?.date).format('DD/MM') || ""}</p>
                      <p>{Math.round(session?.minutes/60) || 0}</p>
                      <p>{session?.studentsAttended}</p>
                    </div>))}

                  </div>
                  <Divider />
                  <div
                    className="d-flex justify-content-between"
                    style={{ marginRight: "10px" }}
                  >
                    <strong>Footfall</strong>
                    <strong>{item.totalDuration}</strong>
                  </div>
                </CardContent>
              </Card>
            </Col>))}
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default Footfall
