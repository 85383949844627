import React, { useEffect, useRef, useState } from "react"
import { Row, Col, Card, CardBody, Label, Button } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import { MDBDataTable } from "mdbreact"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import moment from "moment"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

import "./style.scss"
import {
  getCompaniesOptions,
  getBranches,
} from "../../../store/options/actions"
import { API_URL } from "../../../config"
import axios from "axios"
import toastr from "toastr"
import Swal from "sweetalert2"
import Admin from "../../../components/Common/Admin"

// import
import { del, get, post, put } from "../../../helpers/api_helper"
import SelectErrorMessage from "../../../components/Common/SelectErrorMessage"
import { setTitle } from "../../../helpers/functions"

const CreateAgreement = () => {
  setTitle("Create Agreement")
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const formRef = useRef()

  const initialState = {
    companyName: "",
    type: "",
    partner: "",
    agreement: "",
    agreementDate: "",
    person: "",
    validity: "",
    documents: [{ name: "", file: "" }],
  }
  const [selected, setSelected] = useState({
    type: [],
    partner: [],
  })

  const { companies, branches } = useSelector(state => state.Options)

  useEffect(() => {
    dispatch(getBranches())
    // TODO: Agreement options based on company
    dispatch(getCompaniesOptions())
    // dispatch(getBranches())
    handleTableData()
    handleTypOptions()
    // eslint-disable-next-line
  }, [])

  const [masterObject, setMasterObject] = useState(initialState)
  const [tableData, setTableData] = useState([])
  const [typeOptions, setTypeOptions] = useState([])
  const [error, setError] = useState({
    type: false,
    partner: false,
  })

  const handleAddField = () => {
    const obj = { ...masterObject }
    obj.documents.push({ name: "", file: "" })
    setMasterObject(obj)
  }

  const handleRemoveField = index => {
    const obj = { ...masterObject }
    obj.documents.splice(index, 1)
    setMasterObject(obj)
  }

  const handleSubmit = () => {
    let alreadyError = false
    const selectError = { ...error }
    if (!masterObject.type) {
      alreadyError = true
      selectError.type = true
    }

    if (!masterObject.partner) {
      alreadyError = true
      selectError.partner = true
    }

    setError(selectError)

    if (alreadyError) return

    if (masterObject.id) {
      put("agreement", masterObject)
        .then(res => {
          toastr.success(res.message)
          reset()
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    } else {
      post("agreement", masterObject)
        .then(res => {
          toastr.success(res.message)
          reset()
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    }
  }

  const handleEdit = id => {
    toTop()
    get(`agreement/single?id=${id}`).then(res => {
      let result = res.data
      result.id = result._id
      // console.log(result)
      const selectedCsp = {
        label: result?.type?.name,
        value: result?.type?._id,
      }
      const selectedPartner = {
        label: result?.partner?.name,
        value: result?.partner?._id,
      }
      const selectedBranch = {
        label: result?.branch?.name,
        value: result?.branch?._id,
      }
      setSelected({
        type: selectedCsp,
        partner: selectedPartner,
        branch: selectedBranch,
      })
      setMasterObject(result)
    })
  }
  const handleDelete = async id => {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`agreement?id=${id}`)
          .then(res => {
            toastr.success(res.message)
            handleTableData()
          })
          .catch(err => {
            toastr.error(err.response.data.message)
          })
      }
    })
  }

  const handleNavigate = id => {
    navigate(`/agreement`, { state: { id, renew: true } })
  }

  const handleTableData = () => {
    get("agreement").then(res => {
      const result = res.data
      result.map((item, index) => {
        item.id = index + 1
        item.date = moment(item.date).format("DD-MM-YYYY")
        item.time = moment(item.time, "HH:mm:ss").format("hh:mm a")
        item.validUpto = moment(item.validity).format("DD-MM-YYYY")

        const object = moment()
        const date = moment(item.validity).endOf("day")

        if (date.isSame(object, "day"))
          item.remaining = `${date.diff(moment(), "hours")} Hours`
        else {
          const days = date.diff(moment(), "days")
          item.remaining = `${days <= 0 ? 0 : days} Days`
        }

        item.options = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              onClick={() => {
                handleNavigate(item._id)
              }}
              className="fas fa-eye"
              style={{
                cursor: "pointer",
                fontSize: "1em",
              }}
            ></i>
            <i
              onClick={() => handleEdit(item._id)}
              className="far fa-edit"
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              onClick={() => {
                handleDelete(item._id)
              }}
              style={{ cursor: "pointer", fontSize: "1em" }}
            ></i>
          </div>
        )

        return item
      })
      setTableData(result)
    })
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },

      {
        label: "CSP",
        field: "branch",
        width: 150,
      },
      {
        label: "Type",
        field: "type",
        width: 150,
      },
      {
        label: "Company",
        field: "partner",
        width: 150,
      },
      {
        label: "Agreement",
        field: "agreement",
        width: 150,
      },

      {
        label: "Valid up to",
        field: "validUpto",
        width: 150,
      },
      {
        label: "Remaining",
        field: "remaining",
        width: 150,
      },
      {
        label: "Action",
        field: "options",
        width: 150,
      },
    ],
    rows: tableData,
  }

  const handleFileUpload = (e, index) => {
    const fd = new FormData()
    fd.append("file", e.target.files[0])
    axios.post(`${API_URL}agreement/document`, fd).then(response => {
      if (response.data.status === 200) {
        handleDocumentValueChange(
          response.data.data.new_filename,
          index,
          "file"
        )
      } else {
        toastr.error(response.data.message)
      }
    })
  }

  const handleTypOptions = branch => {
    get(`agreement/category/options`).then(res => {
      setTypeOptions(res.data)
    })
  }
  const handleValueChange = (value, name) => {
    const obj = { ...masterObject }
    obj[name] = value
    setMasterObject(obj)
  }

  const handleDocumentValueChange = (value, index, name) => {
    const obj = { ...masterObject }
    obj.documents[index][name] = value
    setMasterObject(obj)
  }

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }
  const reset = () => {
    formRef.current.reset()
    handleTableData()
    setMasterObject(initialState)
    setSelected({})
    toTop()
  }
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumb title="Home" breadcrumbItem="Create Agreement" />
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <AvForm
                  className="needs-validation"
                  ref={formRef}
                  onValidSubmit={handleSubmit}
                >
                  <Row>
                    <Admin>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>CSP</Label>
                          <Select
                            name="branch"
                            classNamePrefix="select2-selection"
                            options={branches}
                            value={selected.branch || ""}
                            onChange={async selected => {
                              handleValueChange(selected.value, "branch")
                              setSelected(prev => ({
                                ...prev,
                                branch: selected,
                              }))
                              setError(prev => ({ ...prev, branch: false }))
                            }}
                          />
                          <SelectErrorMessage
                            customStyle={{
                              width: "100%",
                              fontSize: "87.5%",
                              color: "var(--bs-form-invalid-color)",
                            }}
                            message="Select CSP"
                            show={error.branch}
                          />
                        </div>
                      </Col>
                    </Admin>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Type of Document</Label>
                        <Select
                          name="type"
                          classNamePrefix="select2-selection"
                          options={typeOptions}
                          value={selected.type || ""}
                          onChange={async selected => {
                            handleValueChange(selected.value, "type")
                            setSelected(prev => ({ ...prev, type: selected }))
                            setError(prev => ({ ...prev, type: false }))
                          }}
                        />
                        <SelectErrorMessage
                          customStyle={{
                            width: "100%",
                            fontSize: "87.5%",
                            color: "var(--bs-form-invalid-color)",
                          }}
                          message="Select Type"
                          show={error.type}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="">
                        <Label>Company</Label>
                        <Select
                          name="partner"
                          classNamePrefix="select2-selection"
                          options={companies}
                          value={selected.partner || ""}
                          onChange={async selected => {
                            handleValueChange(selected.value, "partner")
                            setSelected(prev => ({
                              ...prev,
                              partner: selected,
                            }))
                            setError(prev => ({ ...prev, partner: false }))
                          }}
                        />
                        <SelectErrorMessage
                          customStyle={{
                            width: "100%",
                            fontSize: "87.5%",
                            color: "var(--bs-form-invalid-color)",
                          }}
                          message="Select Partner"
                          show={error.partner}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Agreement </Label>
                        <AvField
                          name="agreement"
                          placeholder="Agreement "
                          errorMessage="Enter agreement"
                          validate={{ required: { value: true } }}
                          value={masterObject.agreement || ""}
                          onChange={e =>
                            handleValueChange(e.target.value, e.target.name)
                          }
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Agreement Date</Label>
                        <AvField
                          name="agreementDate"
                          type="date"
                          placeholder="Agreement date"
                          validate={{ required: { value: true } }}
                          errorMessage="Enter agreement date"
                          value={masterObject.agreementDate || ""}
                          onChange={e =>
                            handleValueChange(e.target.value, e.target.name)
                          }
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Expiry date</Label>
                        <AvField
                          name="validity"
                          type="date"
                          placeholder="Valid up to"
                          errorMessage="Enter agreement validity"
                          validate={{
                            required: { value: true },
                            min: { value: masterObject.agreementDate },
                          }}
                          value={masterObject.validity || ""}
                          onChange={e =>
                            handleValueChange(e.target.value, e.target.name)
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                  <div>
                    {masterObject.documents?.map((document, index) => (
                      <div key={index} className="field-set">
                        <Row>
                          <Col md="3">
                            <div className="mb-1">
                              <Label>{`Document ${index + 1}`}</Label>
                              <AvField
                                name={`name${index}`}
                                placeholder="Document Name"
                                value={document.name}
                                onChange={e => {
                                  handleDocumentValueChange(
                                    e.target.value,
                                    index,
                                    "name"
                                  )
                                }}
                              />
                            </div>
                          </Col>
                          <Col md="3">
                            <div className="mb-1">
                              <Label>Upload Document</Label>

                              {document?.file ? (
                                <div
                                  div
                                  className="img-wraps d-flex flex-column"
                                >
                                  <img
                                    alt=""
                                    width="150"
                                    height="150"
                                    src={`${API_URL}${document.file}`}
                                  />
                                  <button
                                    className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                    onClick={() => {
                                      handleDocumentValueChange(
                                        "",
                                        index,
                                        "file"
                                      )
                                    }}
                                    style={{ width: "150px" }}
                                    type="button"
                                  >
                                    Delete
                                  </button>
                                </div>
                              ) : (
                                <AvField
                                  name={`upload${index}`}
                                  placeholder=""
                                  type="file"
                                  accept="image/*, .pdf, .xls, .xlsx"
                                  onChange={e => {
                                    handleFileUpload(e, index)
                                  }}
                                />
                              )}
                            </div>
                          </Col>
                          <Col style={{ marginTop: "2.1rem" }} md="1">
                            {masterObject.documents.length > 1 && (
                              <div>
                                <i
                                  style={{ cursor: "pointer" }}
                                  class="far fa-trash-alt"
                                  onClick={() => handleRemoveField(index)}
                                ></i>
                              </div>
                            )}
                          </Col>
                        </Row>
                      </div>
                    ))}
                    <Col
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                      md="6"
                    >
                      <span
                        style={{
                          marginBottom: ".5rem",
                          color: "blue",
                          cursor: "pointer",
                        }}
                        onClick={handleAddField}
                      >
                        Add More +
                      </span>
                    </Col>
                  </div>
                  <Row>
                    <Col className="" md="3">
                      <Button
                        color={masterObject.id ? "warning " : "primary"}
                        type="submit"
                        className="me-2"
                      >
                        {masterObject.id ? "Update" : "Submit"}
                      </Button>
                      <Button color={"danger"} type="button" onClick={reset}>
                        Reset
                      </Button>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <MDBDataTable
                  id="agreementTable"
                  responsive
                  bordered
                  info={true}
                  searching={true}
                  entries={20}
                  disableRetreatAfterSorting={true}
                  data={data}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default CreateAgreement
