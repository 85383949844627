import React, { useEffect, useState } from "react"
import { Row, Col,Button } from "reactstrap"
import { OverlayTrigger, Tooltip,Modal } from "react-bootstrap"
import { MDBDataTable } from "mdbreact"
import moment from "moment"
import { get, post, put } from "../../../../helpers/api_helper" 
import Select from "react-select"
import toastr from "toastr"
import { API_URL } from "../../../../config"


import { Link, useLocation } from "react-router-dom"
import InternshipModal from "../../InternshipModal"
function Registrations(props) {
  const [modal, setModal] = useState({})
  const [popupView, setPopupView] = useState(false)
  const [registration, setRegistration] = useState([])
  const { state } = useLocation()
  useEffect(() => {
    handleTableData()
  }, [])
  const generatResume = async requestId => {
    post("/job/generate-resume", { candidate: requestId })
      .then(res => {
        handleTableData()
       
        toastr.success(res.message)
        window.open(`${API_URL}${res?.data}`, "_blank");
      })
      .catch(err => {
        toastr.error(err.response.data.message)
      })
  }
  function handleTableData() {
    get(`/internship/application?internship=${state.id}&status=3`).then(res => {
      let result = res.data
     let data= result.map((item, index) => {


        item.id = index + 1
        item.applieddate = moment(item?.date).format("DD-MM-YYYY")
        item.date1 = moment(item?.shortlist?.date).format("DD-MM-YYYY")
        item.added = item.status === 2 ? item?.shortlist?.staff : item.status === 3 ? item?.placed?.staff : item.status === 4 || item.status === 5 ? item?.reject?.staff : ""

        item.registration = item?.applicant?.uniqueId
        item.name = item?.applicant?.name
        item.mobile = item?.applicant?.mobile
        item.options = (
          <div style={{ display: "flex", justifyContent: "center" ,alignItems:"center" }}>
          <i
            className="fas fa-eye"
            style={{
              fontSize: "1em",
              cursor: "pointer",
              
              marginRight: "0.5rem",
            }}
            onClick={() => {
              setPopupView(true)
              setModal(item)
            }}
          ></i>
                     
          {item?.applicant?.uploadedResume && (
                    <a
                      href={API_URL + item?.applicant?.uploadedResume}
                      target="_blank"
                      // rel="noreferrer"
                    >
                      <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="copy-event-tooltip3">Uploaded Resume</Tooltip>
            }
          >
                      <i
                        className="fas fa-file-pdf"
                        style={{
                          fontSize: "1.2em",
                          cursor: "pointer",
                          
                          marginRight: "0.5rem",
                          color: "red",
                        }}
                      ></i>
          </OverlayTrigger>

                    </a>
                  )} 

          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="copy-event-tooltip">Generated Resume</Tooltip>
            }
          >
            <i
              className="fas fa-file-pdf"
              onClick={() => generatResume(item?.applicant?._id)}
              style={{
                fontSize: "1.2em",
                cursor: "pointer",
                
                marginRight: "0.5rem",
                color: "#0066b2",
              }}
            ></i>
          </OverlayTrigger>
          {/* )} */}

        
        </div>
        )
        return item
      })
      setRegistration(data)

      // setRegistration(result)
    })
  }
  const placed = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "date1",
        sort: "asc",
        width: 200,
      },
      {
        label: "Reg No",
        field: "registration",
        sort: "asc",
        width: 200,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Mobile",
        field: "mobile",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Status",
      //   field: "status",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Staff",
        field: "added",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "options",
        sort: "asc",
        width: 200,
      },
    ],
    rows: registration,
  }
  return (
    <div>
          <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
      > 
        <div className="modal-header">
          <div className="modal-title">
            <h5>Applicant Details - {modal?.applicant?.name}</h5>
          </div>
          <button
            type="button"
            onClick={() => setPopupView(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <InternshipModal data={modal?._id} />
      </Modal>
      <Row>
        <Col className="col-12">
          <MDBDataTable
            id="registeredpeopleid"
            responsive
            bordered
            data={placed}
            searching={true}
            info={false}
            disableRetreatAfterSorting={true}
          />
        </Col>
      </Row>
    </div>
  )
}

export default Registrations
