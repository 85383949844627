import React, { useState, useRef, useEffect } from "react"
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
// import Modal from "react-bootstrap/Modal"
import Select from "react-select"

import $ from "jquery"
import toastr from "toastr"
import moment from "moment"

import { get, post } from "../../../helpers/api_helper"
import "./style.scss"
import {
  dateConverter,
  minutesToHour,
  setTitle,
  timeConverter,
} from "../../../helpers/functions"

import { jsonToExcel } from "../../../helpers/functions"
import WorkReport from "./WorkReport"
import { PRIVILEGES } from "../../../config"
import useMenuAllowed from "../../../hooks/useMenuAllowed"
import TablePagination from "../../../components/Common/Pagination"
const RemunerationCalculation = () => {
  setTitle("Remuneration")
  useMenuAllowed(PRIVILEGES.FINANCE_PROGRAMME_MANAGER)
  const formRef = useRef()

  const [month, setMonth] = useState(null)
  const [reportData, setReportData] = useState([])
  const [selectedReports, setSelectedReports] = useState([])
  const [tableData, setTableData] = useState([])

  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 20

  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [page])

  const handleMonthChange = value => {
    setMonth(value)
    setSelectedReports([])
    setReportData([])
  }
  const handleAllCheckboxChange = e => {
    for (let i = 0; i < reportData.length; i++) {
      handleCheck(e.target.checked, reportData[i])
    }
    if (e.target.checked === true) {
      $("input[type=checkbox]").prop("checked", true)
    } else {
      $("input[type=checkbox]").prop("checked", false)
    }
  }
  const handleCheck = (checked, item) => {
    if (checked) {
      setSelectedReports(prev =>
        prev.concat({
          report: item._id,
          trainer: item.trainer,
          branch: item.branchId,
          bank: item.bank,
          payableAmount: item.payableAmount,
          // chartOfAccount: item.chartOfAccount,
        })
      )
      if (!item.bank) {
        toastr.warning(`${item.name} Trainer don't have any bank account`)
      }
    } else
      setSelectedReports(prev => prev.filter(report => report.id !== item._id))
  }

  const handleCalculate = async () => {
    try {
      const response = await get(`remuneration/monthly-report?month=${month}`)
      const data = response.data
      if (data?.length < 1) {
        toastr.error("No report found")
        setReportData([])
        return
      }
      data.map((item, index) => {
        item.id = index + 1
        item.hours = minutesToHour(item.minutes || 0)
        item.name =
          (item?.trainerFirstName ? item.trainerFirstName : "") +
          " " +
          (item?.trainerLastName ? item.trainerLastName : "")

        const salary = item.salary || 0
        item.salaryText = salary + "/Monthly"

        item.payableAmount = salary
        item.checked = false
        item.checkbox = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <input
              type="checkbox"
              id={`tableCheckbox${index}`}
              name={`checkbox${index}`}
              style={{ cursor: "pointer" }}
              onChange={e => handleCheck(e.target.checked, item)}
            />
          </div>
        )

        item.options = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className={"fas fa-eye"}
              onClick={() => handleTableModalToggle(true, item._id)}
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2em",
                marginRight: "0.5em",
              }}
            ></i>
          </div>
        )
        return item
      })
      setReportData(data)
    } catch (err) {
      toastr.error(err.response.data.message)
      setReportData([])
    }
  }

  const generateSlip = async (id, uniqueId) => {
    try {
      const exportData = []
      const response = await get(`remuneration/ebt?id=${id}`)
      const data = response.data
      data.map(item => {
        const obj = {
          Name: `${item.firstName || ""} ${item.lastName}`,
          Mobile: `${item.mobile}`,
          "Credit To": `Bank Account`,
          "IFSC Code": `${item.ifsc || ""}`,
          "Account No": `${item.accountNo || ""}`,
          Amount: item.amount,
          Purpose: "Remuneration",
        }
        exportData.push(obj)
        return item
      })
      jsonToExcel(exportData, `EBT Slip-${uniqueId || ""}`)
    } catch (error) {
      toastr.error(error.response.data.message)
    }
    // if (report.length) {
    //   report.map(item => {
    //     const obj = {
    //       Name: `${item.firstName || ""} ${item.lastName}`,
    //       Mobile: `${item.mobile}`,
    //       "Credit To": `Bank Account`,
    //       "IFSC Code": `${item.ifsc || ""}`,
    //       "Account No": `${item.accountNo || ""}`,
    //       Amount: item.amount,
    //       Purpose: "Remuneration",
    //     }
    //     exportData.push(obj)
    //     return item
    //   })
    // jsonToExcel(exportData, `EBT Slip-${uniqueId || ""}`)
  }

  const handleTableData = async () => {
    try {
      const response = await get(
        `remuneration/list?page=${page}&limit=${limit}`
      )
      const data = response.data
      data.map((item, index) => {
        // item.id = index + 1
        item.id = index + 1 + (page - 1) * limit
        item.date = moment(item.date).format("DD-MM-YYYY")
        item.month = moment(item.month, "YYYY-MM").format("MMMM YYYY")

        item.name =
          (item?.firstName ? item.firstName : "") +
          " " +
          (item?.lastName ? item.lastName : "")

        if (item.status === 0) {
          item.status = (
            <Button
              color="success"
              style={{ width: "100%", padding: "0.2rem" }}
              disabled
            >
              Paid
            </Button>
          )
        } else if (item.status === 2) {
          item.status = (
            <Button
              color="danger"
              style={{ width: "100%", padding: "0.2rem" }}
              disabled
            >
              Unpaid
            </Button>
          )
        }
        item.options = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className={"fas fa-eye"}
              onClick={() => handleModalToggle(true, item._id)}
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2em",
                marginRight: "0.5em",
              }}
            ></i>

            <i
              className={"fa fa-file-excel"}
              onClick={() => generateSlip(item._id, item.uniqueId)}
              style={{
                fontSize: "1em",
                cursor: "pointer",
                color: "green",
                marginRight: "0.6em",
              }}
            ></i>
          </div>
        )
        return item
      })
      const count = response.count
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(data)
    } catch (error) {}
  }

  const handleSubmit = () => {
    const data = [...selectedReports]
    if (!data.length) toastr.error("Chose trainers")
    post("remuneration/create", { data, month })
      .then(res => {
        reset()
        toastr.success(res.message)
      })
      .catch(err => {
        toastr.error(err.response.data.message)
      })
  }

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }
  const reset = () => {
    formRef.current.reset()
    setMonth(null)
    setReportData([])
    setSelectedReports([])
    handleTableData()
    toTop()
    setSelectedId(null)
    setSelectedOption(null)
    if (selectedId) handleData()
  }

  const [openModal, setOpenModal] = useState(false)
  const [tableOpenModal, setTableOpenModal] = useState(false)

  const [selectedId, setSelectedId] = useState(null)
  const [selectedOption, setSelectedOption] = useState(null)
  const [options, setOptions] = useState([])
  const [reportDetails, setReportDetails] = useState({
    _id: "",
    date: "",
    time: "",
    month: "",
    addedByFirstName: "",
    addedByLastName: "",
    paidSource: null,
    paidDate: null,
    paidTime: null,
    paidByFirstName: null,
    paidByLastName: null,
    totalSalary: 0,
    data: [],
  })

  const handleModalToggle = (value = false, id = null) => {
    setOpenModal(value)
    setSelectedId(id)
    setSelectedOption(null)
    if (value === false) handleTableData()
  }

  useEffect(() => {
    if (selectedId && openModal) handleData()
    handleOptions()
    // eslint-disable-next-line
  }, [selectedId])

  const handleOptions = () => {
    get(`remuneration/source/options`).then(res => {
      setOptions(res.data)
    })
  }
  const handleData = async () => {
    try {
      const response = await get(`remuneration/report-details?id=${selectedId}`)
      const data = response.data
      console.log(data)
      data.date = dateConverter(data.date)
      data.time = timeConverter(data.time)
      data.name = `${data.addedByFirstName || ""} ${data.addedByLastName || ""}`
      data.paid = `${data.paidByFirstName || ""} ${data.paidByLastName || ""}`
      if (data.paidByFirstName) {
        data.paidDate = dateConverter(data.paidDate)
        data.paidTime = timeConverter(data.paidTime)
      }
      data.statusText = data.status === 0 ? "Paid" : "Unpaid"

      setReportDetails(data)
    } catch (error) {}
  }

  const handleTableModalToggle = (value = false, id = null) => {
    setTableOpenModal(value)
    setSelectedId(id)
  }

  const handlePay = () => {
    const account = selectedOption.value
    console.log(account, selectedId, reportDetails.totalSalary)
    post("remuneration/pay", {
      account,
      id: selectedId,
      amount: reportDetails.totalAmount,
    })
      .then(res => {
        setSelectedOption({})
        reset()
        toastr.success(res.message)
      })
      .catch(err => {
        toastr.error(err.response.data.message)
      })
  }
  const handleChange = (event, value) => {
    setPage(value)
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Id",
        field: "uniqueId",
        sort: "asc",
        width: 270,
      },
      {
        label: "Month",
        field: "month",
        sort: "asc",
        width: 150,
      },

      {
        label: "No. of Staff",
        field: "totalStaff",
        sort: "asc",
        width: 150,
      },
      {
        label: "No. of Reports",
        field: "totalReports",
        sort: "asc",
        width: 150,
      },
      {
        label: "Total Amount",
        field: "totalAmount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Added By",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "options",
        sort: "asc",
        width: 150,
      },
    ],
    rows: tableData,
  }

  const trainersReport = {
    columns: [
      {
        label: (
          <input
            type="checkbox"
            name="headerCheckbox"
            id="headerCheckbox"
            style={{ cursor: "pointer" }}
            onChange={handleAllCheckboxChange}
          ></input>
        ),
        field: "checkbox",
        sort: "asc",
        width: 150,
      },
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "ID",
        field: "uniqueId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Branch",
        field: "branch",
        sort: "asc",
        width: 150,
      },
      {
        label: "Trainer",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Batch",
        field: "batch",
        sort: "asc",
        width: 150,
      },

      // {
      //   label: "Days",
      //   field: "days",
      //   sort: "asc",
      //   width: 150,
      // },
      // {
      //   label: "Sessions",
      //   field: "sessions",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Hours",
        field: "hours",
        sort: "asc",
        width: 150,
      },
      {
        label: "Salary",
        field: "salaryText",
        sort: "asc",
        width: 150,
      },
      {
        label: "Payable",
        field: "payableAmount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Options",
        field: "options",
        sort: "asc",
        width: 150,
      },
    ],

    rows: reportData,
  }
  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Remuneration" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={handleCalculate}
                    >
                      <Col xl="12">
                        <Row>
                          <Col md="3">
                            <div className="mb-3">
                              <Label>Month</Label>
                              <AvField
                                name="month"
                                type="month"
                                errorMessage="Select Month"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                  // max: { value: moment().format("YYYY-MM"), errorMessage: 'Month cannot be in the future' }
                                }}
                                value={month || ""}
                                onChange={e => {
                                  console.log(e.target.value)
                                  handleMonthChange(e.target.value)
                                }}
                              />
                            </div>
                          </Col>

                          <Col md="3">
                            <div style={{ paddingTop: "25px" }}>
                              <Button
                                color="primary"
                                type="submit"
                                style={{ marginRight: "5%" }}
                              >
                                {"  "}
                                Calculate
                              </Button>
                              <Button
                                color="danger"
                                type="button"
                                onClick={() => reset()}
                              >
                                {"  "}
                                Reset
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {reportData.length ? (
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <MDBDataTable
                        id="trainerReportForRemuneration"
                        responsive
                        bordered
                        data={trainersReport}
                        searching={false}
                        sortable={false}
                        info={false}
                        disableRetreatAfterSorting={true}
                        paging={false}
                        key={JSON.stringify(reportData)}
                      />

                      <Row>
                        <Col
                          className="col-12"
                          style={{
                            paddingTop: "4px",
                            textAlign: "-webkit-right",
                          }}
                        >
                          <div className="mb-2">
                            <Button
                              color="primary"
                              type="submit"
                              onClick={() => {
                                handleSubmit()
                              }}
                            >
                              {"  "}
                              Submit
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : (
              ""
            )}

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <MDBDataTable
                      id="remunerationTable"
                      responsive
                      bordered
                      data={data}
                      disableRetreatAfterSorting={true}
                      info={false}
                      sortable={false}
                      searching={false}
                      paging={false}
                    />
                    <TablePagination
                      page={page}
                      onChange={handleChange}
                      count={totalPage}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
      <Modal
        size="xl"
        centered
        isOpen={openModal}
        toggle={() => handleModalToggle()}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Remuneration Details</h5>
          <button
            type="button"
            onClick={() => handleModalToggle()}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body">
          <ul className="lead-details" style={{ padding: 0 }}>
            <li>
              Date : <Label>{reportDetails?.date}</Label>
            </li>

            <li>
              Time : <Label>{reportDetails?.time}</Label>
            </li>

            <li>
              Id : <Label>{reportDetails?.uniqueId}</Label>
            </li>
            <li>
              Total Staff : <Label>{reportDetails?.totalStaff}</Label>
            </li>
            <li>
              Total Reports : <Label>{reportDetails?.totalReports}</Label>
            </li>
            <li>
              Total Amount : <Label>{reportDetails?.totalAmount}</Label>
            </li>
            <li>
              Added By : <Label>{reportDetails?.name}</Label>
            </li>
            <li>
              Status : <Label>{reportDetails?.statusText}</Label>
            </li>
            {reportDetails.status === 0 ? (
              <>
                <li>
                  Paid Date : <Label>{reportDetails?.paidDate}</Label>
                </li>
                <li>
                  Paid Time : <Label>{reportDetails?.paidTime}</Label>
                </li>
                <li>
                  Paid By : <Label>{reportDetails?.paid}</Label>
                </li>
              </>
            ) : (
              ""
            )}
          </ul>
          {reportDetails.status !== 0 ? (
            <Row className="mt-3">
              <Col md="3">
                <Label>Fund Source</Label>
                <Select
                  name="fundSource"
                  id="fundSource"
                  menuPosition="fixed"
                  options={options}
                  value={selectedOption}
                  onChange={selected => setSelectedOption(selected)}
                  classNamePrefix="select2-selection"
                />
              </Col>
              <Col md="3" style={{ marginTop: "26px" }}>
                <Button color="primary" type="button" onClick={handlePay}>
                  Pay Now
                </Button>
              </Col>
            </Row>
          ) : (
            ""
          )}
          {reportDetails.data.map(trainer => {
            return (
              <div className="table-container table-responsive">
                <h3 className="table-title">{`${trainer.firstName || ""} ${
                  trainer.lastName || ""
                }`}</h3>
                <table
                  className="table table-bordered"
                  id="remunerationDetailsTable"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Session</th>
                      <th>Date</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Hours</th>
                      <th>Staff</th>
                    </tr>
                  </thead>
                  <tbody>
                    {trainer?.data.map((item, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item.session}</td>
                          <td>{moment(item.date).format("DD-MM-YYYY")}</td>
                          <td>
                            {moment(item.fromTime, "HH:mm").format("hh:mm a")}
                          </td>
                          <td>
                            {moment(item.toTime, "HH:mm").format("hh:mm a")}
                          </td>
                          <td>{minutesToHour(item.time)}</td>
                          <td>
                            {`${item.firstName || ""} ${item.lastName || ""}`}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            )
          })}
        </div>

        {/* </div> */}
      </Modal>
      {tableOpenModal && (
        <WorkReport
          id={selectedId}
          onClick={handleTableModalToggle}
          isOpen={tableOpenModal}
        />
      )}
    </div>
  )
}

export default RemunerationCalculation
