import React, { useEffect, useState, useRef } from "react"

import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import Select from "react-select"
import { Editor } from "react-draft-wysiwyg"
import { MDBDataTable } from "mdbreact"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Row, Col, Card, CardBody, Label, Button } from "reactstrap"

import { API_URL } from "../../../../config"
import { PRIVILEGES } from "../../../../config"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { get, post, put, del } from "../../../../helpers/api_helper"
import {
  getCompaniesOptions,
  getCourses,
  getDistrictOptions,
  getMinorityStatus,
  getStudentCategory,
} from "../../../../store/options/actions"
import SelectErrorMessage from "../../../../components/Common/SelectErrorMessage"

import axios from "axios"
import toastr from "toastr"
import moment from "moment"
import Swal from "sweetalert2"

import "./style.scss"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import draftToHtml from "draftjs-to-html"
import {
  convertToRaw,
  EditorState,
  convertFromHTML,
  ContentState,
} from "draft-js"
import {
  customContentStateConverter,
  handleImageValidation,
  setTitle,
} from "../../../../helpers/functions"

const ScholarShips = ({ options }) => {
  setTitle("Create Scholarship")
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const formRef = useRef()

  const initialState = {
    course: [],
    condition: false,
    customConditions: [{}],
  }
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  )
  const [masterObject, setMasterObject] = useState(initialState)
  const [tableData, setTableData] = useState([])
  const [error, setError] = useState({
    fundingAgency: false,
    courses: false,
  })

  const { minorityStatus, districts, companies, courses, studentCategory } =
    useSelector(state => state.Options)

  useEffect(() => {
    dispatch(getDistrictOptions({ id: 19 }))
    dispatch(getCompaniesOptions({ type: PRIVILEGES.FUNDING_SOURCE }))
    dispatch(getCourses())
    dispatch(getStudentCategory())
    dispatch(getMinorityStatus())
    handleTableData()
    // eslint-disable-next-line
  }, [])

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Funding Agency",
        field: "fundingAgency",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        width: 150,
      },
      {
        label: "Max. Cap",
        field: "maximum",
        width: 150,
      },
      {
        label: "%",
        field: "percentage",
        width: 150,
      },
      {
        label: "File",
        field: "pdf",
        width: 150,
      },

      {
        label: "Status",
        field: "statusList",
        width: 150,
      },
      {
        label: "Actions",
        field: "options",
        sort: "desc",
        width: 400,
      },
    ],
    rows: tableData,
  }

  const genderOptions = [
    {
      label: "Male",
      value: 1,
    },
    {
      label: "Female",
      value: 2,
    },
    { label: "Other", value: 3 },
  ]

  const handleTableData = async () => {
    get("/scholarship/list").then(res => {
      const result = res.data
      result.map((item, index) => {
        item.id = index + 1

        let percentageString = ""
        if (item.condition) {
          for (const condition of item.customConditions) {
            percentageString += `${condition.percentage}% for `
            if (condition.category) {
              percentageString += `${condition.category} category, `
            }
            if (condition.gender) {
              condition.gender =
                condition.gender === 1
                  ? "Males"
                  : condition.gender === 2
                  ? "Females"
                  : "Others"
              percentageString += `${condition.gender}, `
            }
            if (condition.district) {
              percentageString += `${condition.district} district, `
            }
            if (condition.minorityStatus) {
              percentageString += `${condition.minorityStatus} minority, `
            }

            if (
              !condition.category &&
              !condition.gender &&
              !condition.minorityStatus &&
              !condition.district
            ) {
              percentageString += "All, "
            }
            percentageString = percentageString.slice(0, -2)
            percentageString += ". "
            percentageString += "<br />"
          }
        } else {
          percentageString = item.percentage + "%"
        }
        item.percentage = (
          <div dangerouslySetInnerHTML={{ __html: percentageString }}></div>
        )

        item.date = item.upDate
          ? moment(item.upDate).format("DD-MM-YYYY")
          : moment(item.date).format("DD-MM-YYYY")

        if (item.logo) {
          item.logoImage = (
            <img
              src={`${API_URL}${item.logo}`}
              style={{ width: "80px", height: "50px" }}
              alt="logo"
            ></img>
          )
        }
        if (item.document) {
          item.pdf = (
            <a
              href={`${API_URL}${item.document}`}
              target="_blank"
              rel="noreferrer"
            >
              <i
                className="fas fa-file-pdf"
                style={{
                  cursor: "pointer",
                  fontSize: "1.5em",
                  color: "red",
                  marginRight: "0.5rem",
                  marginLeft: "0.5rem",
                }}
              ></i>
            </a>
          )
        }
        item.options = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              onClick={() => {
                navigate(`/scholarships/${item.uniqueId}`)
              }}
              style={{ cursor: "pointer" }}
              className="fas fa-eye"
            ></i>{" "}
            {options.edit ? (
              <>
                <i
                  className="far fa-edit"
                  onClick={() => handleEdit(item._id)}
                  style={{
                    cursor: "pointer",
                    fontSize: "1em",
                    marginRight: "0.5rem",
                    marginLeft: "0.5rem",
                  }}
                ></i>
                <i
                  className="far fa-trash-alt"
                  onClick={() => handleDelete(item._id)}
                  style={{ cursor: "pointer", fontSize: "1em" }}
                ></i>
              </>
            ) : (
              ""
            )}
          </div>
        )
        if (item.status === 0) {
          item.statusList = (
            <Button
              className="btn btn-sm"
              color="success"
              onClick={() => handleStatusChange(item._id, item.status)}
            >
              Active
            </Button>
          )
        } else {
          item.statusList = (
            <Button
              className="btn btn-sm"
              color="warning"
              onClick={() => handleStatusChange(item._id, item.status)}
            >
              Inactive
            </Button>
          )
        }
        return item
      })

      setTableData(result)
    })
  }

  const handleSubmit = () => {
    let errorAvailable = false
    if (!masterObject.fundingAgency) {
      setError(prev => ({ ...prev, fundingAgency: true }))
      errorAvailable = true
    }

    if (errorAvailable) {
      toTop()
      return
    }

    if (masterObject.id) {
      put("/scholarship/update", masterObject)
        .then(res => {
          reset()
          toastr.success(res.message)
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    } else {
      post("/scholarship/add", masterObject)
        .then(res => {
          reset()

          toastr.success(res.message)
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    }
  }

  const handleEdit = id => {
    reset()
    get(`scholarship/edit?id=${id}`).then(res => {
      const result = res.data
      result.file = result.document
      result.id = id
      result.description = result.description ?? ""
      const blocksFromHTML = convertFromHTML(result.description)
      setEditorState(
        EditorState.createWithContent(
          customContentStateConverter(
            ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks,
              blocksFromHTML.entityMap
            )
          )
        )
      )
      if (!result.customConditions?.length) result.customConditions = [{}]
      setMasterObject(result)
    })
    toTop()
  }

  const handleDelete = async id => {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`/scholarship/delete?id=${id}`)
          .then(res => {
            toastr.success(res.message)
            handleTableData()
          })
          .catch(err => {
            toastr.success(err.response.data.message)
          })
      }
    })
  }

  const handleFileUpload = e => {
    const selectedFile = e.target.files[0]

    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "application/pdf",
      "text/plain",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ]

    if (!allowedTypes.includes(selectedFile.type)) {
      toastr.error(
        "Please select a valid file type (JPEG, PNG, PDF, TXT, DOC, DOCX, XLS, XLSX)"
      )
      return
    }
    const fd = new FormData()
    fd.append("file", e.target.files[0])
    axios.post(`${API_URL}scholarship/image/file`, fd).then(response => {
      if (response.data.status === 200) {
        handleValueChange(response.data.data.new_filename, "file")
      } else {
        toastr.error(response.data.message)
      }
    })
  }

  const handleLogoUpload = async e => {
    const selectedFile = e.target?.files[0]
    if (selectedFile) {
      try {
        // console.log("Selected")
        await handleImageValidation(selectedFile, 300, 150)

        const fd = new FormData()
        fd.append("logo", e.target?.files[0])
        axios.post(`${API_URL}scholarship/image/logo`, fd).then(response => {
          if (response.data.status === 200) {
            handleValueChange(response.data.data.new_filename, "logo")
          } else {
            toastr.error(response.data.message)
          }
        })
      } catch (error) {
        toastr.error(error)
        e.target.value = ""
      }
    }
  }

  const handleStatusChange = async (id, status) => {
    await put("/scholarship/status", { id, status }).then(res => {
      toastr.success(res.message)
      handleTableData()
    })
  }

  const handleValueChange = (value, name) => {
    const obj = { ...masterObject }
    obj[name] = value
    setMasterObject(obj)
  }

  const handleEditorStateChange = editorState => {
    setEditorState(editorState)

    const updateData = { ...masterObject }
    const htmlContent = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    )

    updateData.description = htmlContent
    setMasterObject(updateData)
  }

  const handleConditionChange = (value, name, index) => {
    const obj = { ...masterObject }
    obj.customConditions[index][name] = value
    setMasterObject(obj)
  }

  const addMore = () => {
    const obj = { ...masterObject }
    obj.customConditions.push({})
    setMasterObject(obj)
  }

  const remove = index => {
    const obj = { ...masterObject }
    obj.customConditions = obj.customConditions.filter((item, i) => i !== index)
    setMasterObject(obj)
  }

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }
  const reset = () => {
    formRef.current.reset()
    handleTableData()
    setMasterObject(initialState)
    toTop()
    setEditorState(() => EditorState.createEmpty())
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Scholarships" />
          {options.edit ? (
            <Card>
              <CardBody>
                <Row>
                  <Col xl="12">
                    <Card>
                      <CardBody>
                        <AvForm ref={formRef} onValidSubmit={handleSubmit}>
                          <Row>
                            <Col md="3">
                              <div className="mb-3">
                                <Label>Scholarship Name</Label>
                                <AvField
                                  name="name"
                                  type="text"
                                  placeholder="Scholarship Name"
                                  value={masterObject.name || ""}
                                  onChange={e =>
                                    handleValueChange(
                                      e.target.value,
                                      e.target.name
                                    )
                                  }
                                  validate={{ required: { value: true } }}
                                  errorMessage="Enter Name"
                                />
                              </div>
                            </Col>
                            <Col md="3">
                              <div className="mb-3">
                                <Label>Funding Agency</Label>
                                <Select
                                  name="fundingAgency"
                                  classNamePrefix="select2-selection"
                                  options={companies}
                                  value={companies.filter(
                                    item =>
                                      item.value === masterObject.fundingAgency
                                  )}
                                  onChange={selected => {
                                    handleValueChange(
                                      selected.value,
                                      "fundingAgency"
                                    )
                                    setError(prev => ({
                                      ...prev,
                                      fundingAgency: false,
                                    }))
                                  }}
                                />
                                <SelectErrorMessage
                                  customStyle={{
                                    width: "100%",
                                    fontSize: "87.5%",
                                    color: "var(--bs-form-invalid-color)",
                                  }}
                                  message="Select Funding Agency"
                                  show={error.fundingAgency ? true : false}
                                />
                              </div>
                            </Col>
                            <Col md="3">
                              <div className="mb-3">
                                <Label htmlFor="validationCustom03">
                                  Funding Scheme
                                </Label>
                                <AvField
                                  name="fundingScheme"
                                  type="text"
                                  className="form-control"
                                  placeholder="Funding Scheme"
                                  value={masterObject.fundingScheme || ""}
                                  onChange={e =>
                                    handleValueChange(
                                      e.target.value,
                                      e.target.name
                                    )
                                  }
                                  validate={{ required: { value: true } }}
                                  errorMessage="Enter Funding Scheme"
                                />
                              </div>
                            </Col>{" "}
                            <Col md="3">
                              <div className="mb-3">
                                <Label>Course</Label>
                                <Select
                                  isMulti
                                  name="course"
                                  classNamePrefix="select2-selection"
                                  options={courses}
                                  value={courses.filter(course =>
                                    masterObject.course?.includes(course.value)
                                  )}
                                  onChange={selected => {
                                    const selectedValues = selected.map(
                                      option => option.value
                                    )
                                    handleValueChange(selectedValues, "course")
                                  }}
                                />
                                <SelectErrorMessage
                                  customStyle={{
                                    width: "100%",
                                    fontSize: "87.5%",
                                    color: "var(--bs-form-invalid-color)",
                                  }}
                                  message="Select Course"
                                  show={error.courses ? true : false}
                                />
                              </div>
                            </Col>
                            <Col md="3">
                              <div className="mb-3">
                                <Label>Allocated Amount</Label>
                                <AvField
                                  name="amount"
                                  className="form-control"
                                  type="number"
                                  placeholder="Allocated Amount"
                                  value={masterObject.amount || ""}
                                  onChange={e =>
                                    handleValueChange(
                                      e.target.value,
                                      e.target.name
                                    )
                                  }
                                  validate={{
                                    min: { value: 1 },
                                    required: { value: true },
                                  }}
                                  errorMessage="Enter Amount"
                                />
                              </div>
                            </Col>
                            {/* <Col md="3">
                            <div className="mb-3">
                              <Label>Maximum Cap</Label>
                              <AvField
                                name="maximum"
                                className="form-control"
                                type="number"
                                placeholder="Maximum Cap"
                                value={masterObject.maximum || ""}
                                onChange={e =>
                                  handleValueChange(
                                    e.target.value,
                                    e.target.name
                                  )
                                }
                                validate={{
                                  min: { value: 1 },
                                  required: { value: true },
                                }}
                                errorMessage="Enter Max. Cap"
                              />
                            </div>
                          </Col> */}
                            <Col md="3">
                              <div className="mb-3">
                                <Label htmlFor="validationCustom03">
                                  Expiry Date
                                </Label>
                                <AvField
                                  name="expireDate"
                                  type="date"
                                  className="form-control"
                                  value={masterObject.expireDate || ""}
                                  onChange={e =>
                                    handleValueChange(
                                      e.target.value,
                                      e.target.name
                                    )
                                  }
                                  validate={{
                                    required: { value: true },
                                    min: {
                                      value: moment().format("YYYY-MM-DD"),
                                    },
                                  }}
                                  errorMessage="Enter Expire Date"
                                />
                              </div>
                            </Col>
                            <Col md="3">
                              <div className="mb-3">
                                <Label>
                                  Logo{" "}
                                  <span style={{ color: "#0d0d0d94" }}>
                                    (300x150)
                                  </span>
                                </Label>
                                {masterObject?.logo ? (
                                  <div
                                    div
                                    className="img-wraps d-flex flex-column"
                                  >
                                    <img
                                      alt=""
                                      width="150"
                                      height="75"
                                      src={`${API_URL}${masterObject?.logo}`}
                                    />
                                    <button
                                      className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                      onClick={() => {
                                        setMasterObject(pre => ({
                                          ...pre,
                                          logo: null,
                                        }))
                                      }}
                                      style={{ width: "150px" }}
                                      type="button"
                                    >
                                      Delete
                                    </button>
                                  </div>
                                ) : (
                                  <AvField
                                    name="logo"
                                    className="form-control"
                                    type="file"
                                    onChange={handleLogoUpload}
                                  />
                                )}
                              </div>
                            </Col>
                            <Col md="3">
                              <div className="mb-3">
                                <Label>File </Label>
                                {masterObject?.file ? (
                                  <div
                                    div
                                    className="img-wraps d-flex flex-column"
                                  >
                                    <img
                                      alt=""
                                      width="150"
                                      height="150"
                                      src={`${API_URL}${masterObject.file}`}
                                    />
                                    <button
                                      className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                      onClick={() => {
                                        setMasterObject(pre => ({
                                          ...pre,
                                          file: null,
                                        }))
                                      }}
                                      style={{ width: "150px" }}
                                      type="button"
                                    >
                                      Delete
                                    </button>
                                  </div>
                                ) : (
                                  <AvField
                                    name="file"
                                    className="form-control"
                                    type="file"
                                    onChange={handleFileUpload}
                                  />
                                )}
                              </div>
                            </Col>
                            <Col md="12">
                              <Label
                                style={{
                                  paddingRight: "15px",
                                  display: "block",
                                }}
                              >
                                Enter Custom conditions
                              </Label>
                              <div
                                className="btn-group btn-group-sm"
                                role="group"
                                aria-label="Basic example"
                              >
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="skillLoan"
                                  id="customConditions"
                                  checked={masterObject.condition}
                                  onClick={e => {
                                    masterObject.condition
                                      ? handleValueChange(false, "condition")
                                      : handleValueChange(true, "condition")
                                  }}
                                />
                                <label
                                  style={{
                                    padding: "7px 17.6px",
                                    zIndex: "0",
                                    fontSize: "11px",
                                  }}
                                  className="btn btn-outline-primary"
                                  htmlFor="customConditions"
                                >
                                  Yes
                                </label>
                                <input
                                  type="radio"
                                  name="skillLoan"
                                  className="btn-check"
                                  id="condition"
                                  checked={!masterObject.condition}
                                  onClick={e => {
                                    masterObject.condition
                                      ? handleValueChange(false, "condition")
                                      : handleValueChange(true, "condition")
                                  }}
                                />

                                <label
                                  style={{
                                    padding: "7px 17.6px",
                                    zIndex: "0",
                                    fontSize: "11px",
                                  }}
                                  className="btn btn-outline-primary"
                                  htmlFor="condition"
                                >
                                  No
                                </label>
                              </div>
                            </Col>
                            {masterObject.condition ? (
                              <Col md="12">
                                <div className="table-responsive">
                                  <table
                                    className="table table-bordered"
                                    id="createScholarshipTable"
                                  >
                                    <thead>
                                      <tr>
                                        <th>No.</th>
                                        <th>Category</th>
                                        <th>Gender</th>
                                        <th>Minority Status</th>
                                        <th>District</th>
                                        <th>Percentage</th>
                                        <th>Maximum Cap</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {masterObject.customConditions.map(
                                        (item, index) => {
                                          return (
                                            <tr>
                                              <td>{index + 1}</td>
                                              <td>
                                                <Select
                                                  name="category"
                                                  classNamePrefix="select2-selection"
                                                  options={studentCategory}
                                                  isClearable={true}
                                                  menuPosition="fixed"
                                                  value={
                                                    studentCategory.filter(
                                                      item =>
                                                        item?.value ===
                                                        masterObject
                                                          .customConditions[
                                                          index
                                                        ]?.category
                                                    ) || null
                                                  }
                                                  onChange={selected => {
                                                    handleConditionChange(
                                                      selected?.value,
                                                      "category",
                                                      index
                                                    )
                                                  }}
                                                />
                                                <SelectErrorMessage
                                                  message="Select Field"
                                                  show={
                                                    error[index] ? true : false
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <Select
                                                  name="gender"
                                                  classNamePrefix="select2-selection"
                                                  options={genderOptions}
                                                  isClearable={true}
                                                  menuPosition="fixed"
                                                  value={
                                                    genderOptions.filter(
                                                      item =>
                                                        item?.value ===
                                                        masterObject
                                                          .customConditions[
                                                          index
                                                        ]?.gender
                                                    ) || null
                                                  }
                                                  onChange={selected => {
                                                    handleConditionChange(
                                                      selected?.value,
                                                      "gender",
                                                      index
                                                    )
                                                  }}
                                                />
                                                <SelectErrorMessage
                                                  message="Select Field"
                                                  show={
                                                    error[index] ? true : false
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <Select
                                                  name="minorityStatus"
                                                  classNamePrefix="select2-selection"
                                                  options={minorityStatus}
                                                  isClearable={true}
                                                  menuPosition="fixed"
                                                  value={
                                                    minorityStatus.filter(
                                                      item =>
                                                        item?.value ===
                                                        masterObject
                                                          .customConditions[
                                                          index
                                                        ]?.minorityStatus
                                                    ) || null
                                                  }
                                                  onChange={selected => {
                                                    handleConditionChange(
                                                      selected?.value,
                                                      "minorityStatus",
                                                      index
                                                    )
                                                  }}
                                                />
                                                <SelectErrorMessage
                                                  message="Select Field"
                                                  show={
                                                    error[index] ? true : false
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <Select
                                                  name="district_id"
                                                  options={districts}
                                                  isClearable={true}
                                                  classNamePrefix="select2-selection"
                                                  menuPosition="fixed"
                                                  value={districts.filter(
                                                    item =>
                                                      item?.value ===
                                                      masterObject
                                                        .customConditions[index]
                                                        ?.district
                                                  )}
                                                  onChange={selected => {
                                                    handleConditionChange(
                                                      selected?.value,
                                                      "district",
                                                      index
                                                    )
                                                  }}
                                                />
                                                <SelectErrorMessage
                                                  message="Select Field"
                                                  show={
                                                    error[index] ? true : false
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <AvField
                                                  name={`percentage${index}`}
                                                  id={`validation${index}`}
                                                  type="number"
                                                  className="form-control"
                                                  placeholder="Percentage"
                                                  value={
                                                    masterObject
                                                      .customConditions[index]
                                                      .percentage || ""
                                                  }
                                                  onChange={e => {
                                                    handleConditionChange(
                                                      e.target.value,
                                                      "percentage",
                                                      index
                                                    )
                                                  }}
                                                  validate={{
                                                    min: { value: 1 },
                                                    max: { value: 99 },
                                                    required: { value: true },
                                                  }}
                                                  errorMessage="Enter Valid Percentage"
                                                />
                                              </td>

                                              <td>
                                                <AvField
                                                  // name="maximum"
                                                  name={`maximum${index}`}
                                                  id={`maximum${index}`}
                                                  className="form-control"
                                                  type="number"
                                                  placeholder="Max Cap"
                                                  // value={
                                                  //   masterObject.maximum || ""
                                                  // }
                                                  // onChange={e =>
                                                  //   handleValueChange(
                                                  //     e.target.value,
                                                  //     e.target.name
                                                  //   )
                                                  // }
                                                  value={
                                                    masterObject
                                                      .customConditions[index]
                                                      .maximum || ""
                                                  }
                                                  onChange={e => {
                                                    handleConditionChange(
                                                      e.target.value,
                                                      "maximum",
                                                      index
                                                    )
                                                  }}
                                                  validate={{
                                                    min: { value: 1 },
                                                    required: { value: true },
                                                  }}
                                                  errorMessage="Enter Max. Cap"
                                                />
                                              </td>
                                              <td>
                                                {masterObject.customConditions
                                                  .length > 1 ? (
                                                  <i
                                                    className="uil uil-times"
                                                    style={{
                                                      fontSize: "20px",
                                                      cursor: "pointer",
                                                      paddingLeft: "5px",
                                                      display: "flex",
                                                      justifyContent: "center",
                                                    }}
                                                    onClick={() =>
                                                      remove(index)
                                                    }
                                                  ></i>
                                                ) : (
                                                  ""
                                                )}
                                              </td>
                                            </tr>
                                          )
                                        }
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                                <Button
                                  color="primary"
                                  style={{
                                    padding: "0.2rem 0.5rem",
                                    float: "right",
                                  }}
                                  type="button"
                                  className="mb-3"
                                  onClick={() => {
                                    addMore()
                                  }}
                                >
                                  {/* <span
                                    
                                      style={{ cursor: "pointer" }}
                                    > */}
                                  Add more{" "}
                                  <i className="mdi mdi-plus-circle-outline"></i>
                                  {/* </span> */}
                                </Button>
                              </Col>
                            ) : (
                              <>
                                <Col md="2">
                                  <div className="mb-3">
                                    <Label>Student Category </Label>
                                    <Select
                                      isMulti
                                      name="category"
                                      classNamePrefix="select2-selection"
                                      options={studentCategory}
                                      // value={studentCategory.filter(
                                      //   item =>
                                      //     item.value === masterObject.category
                                      // )}
                                      // onChange={selected =>
                                      //   handleValueChange(
                                      //     selected.value,
                                      //     "category"
                                      //   )
                                      // }

                                      value={studentCategory.filter(course =>
                                        masterObject.category?.includes(
                                          course.value
                                        )
                                      )}
                                      onChange={selected => {
                                        const selectedValues = selected.map(
                                          option => option.value
                                        )
                                        handleValueChange(
                                          selectedValues,
                                          "category"
                                        )
                                        setError(prev => ({
                                          ...prev,
                                          condition: false,
                                        }))
                                      }}
                                    />
                                    <SelectErrorMessage
                                      customStyle={{
                                        width: "100%",
                                        fontSize: "87.5%",
                                        color: "var(--bs-form-invalid-color)",
                                      }}
                                      message="Select Field"
                                      show={error.condition}
                                    />
                                  </div>
                                </Col>
                                <Col md="2">
                                  <div className="mb-3">
                                    <Label>Gender</Label>
                                    <Select
                                      isMulti
                                      name="gender"
                                      classNamePrefix="select2-selection"
                                      options={genderOptions}
                                      value={genderOptions.filter(course =>
                                        masterObject.gender?.includes(
                                          course.value
                                        )
                                      )}
                                      onChange={selected => {
                                        const selectedValues = selected.map(
                                          option => option.value
                                        )
                                        handleValueChange(
                                          selectedValues,
                                          "gender"
                                        )
                                        setError(prev => ({
                                          ...prev,
                                          condition: false,
                                        }))
                                      }}
                                    />
                                    <SelectErrorMessage
                                      customStyle={{
                                        width: "100%",
                                        fontSize: "87.5%",
                                        color: "var(--bs-form-invalid-color)",
                                      }}
                                      message="Select Field"
                                      show={error.condition}
                                    />
                                  </div>
                                </Col>
                                <Col md="2">
                                  <div className="mb-3">
                                    <Label>Minority Status</Label>
                                    <Select
                                      isMulti
                                      name="minorityStatus"
                                      classNamePrefix="select2-selection"
                                      options={minorityStatus}
                                      value={minorityStatus.filter(course =>
                                        masterObject.minorityStatus?.includes(
                                          course.value
                                        )
                                      )}
                                      onChange={selected => {
                                        const selectedValues = selected.map(
                                          option => option.value
                                        )
                                        handleValueChange(
                                          selectedValues,
                                          "minorityStatus"
                                        )
                                        setError(prev => ({
                                          ...prev,
                                          condition: false,
                                        }))
                                      }}
                                    />
                                    <SelectErrorMessage
                                      customStyle={{
                                        width: "100%",
                                        fontSize: "87.5%",
                                        color: "var(--bs-form-invalid-color)",
                                      }}
                                      message="Select Field"
                                      show={error.condition}
                                    />
                                  </div>
                                </Col>
                                <Col md="2">
                                  <div className="mb-3">
                                    <Label>District</Label>
                                    <Select
                                      isMulti
                                      name="district"
                                      options={districts}
                                      classNamePrefix="select2-selection"
                                      value={districts.filter(course =>
                                        masterObject.district?.includes(
                                          course.value
                                        )
                                      )}
                                      onChange={selected => {
                                        const selectedValues = selected.map(
                                          option => option.value
                                        )
                                        handleValueChange(
                                          selectedValues,
                                          "district"
                                        )
                                        setError(prev => ({
                                          ...prev,
                                          condition: false,
                                        }))
                                      }}
                                    />
                                    <SelectErrorMessage
                                      customStyle={{
                                        width: "100%",
                                        fontSize: "87.5%",
                                        color: "var(--bs-form-invalid-color)",
                                      }}
                                      message="Select Field"
                                      show={error.condition}
                                    />
                                  </div>
                                </Col>
                                <Col md="2">
                                  <div className="mb-3">
                                    <Label htmlFor="validationCustom03">
                                      Percentage
                                    </Label>
                                    <AvField
                                      name="percentage"
                                      type="number"
                                      placeholder="Percentage"
                                      value={masterObject.percentage}
                                      onChange={e =>
                                        handleValueChange(
                                          e.target.value,
                                          e.target.name
                                        )
                                      }
                                      validate={{
                                        min: { value: 1 },
                                        max: { value: 99 },
                                        required: { value: true },
                                      }}
                                      errorMessage="Enter Valid Percentage"
                                    />
                                  </div>
                                </Col>
                                <Col md="2">
                                  <div className="mb-3">
                                    <Label>Maximum Cap</Label>
                                    <AvField
                                      name="maximum"
                                      className="form-control"
                                      type="number"
                                      placeholder="Maximum Cap"
                                      value={masterObject.maximum || ""}
                                      onChange={e =>
                                        handleValueChange(
                                          e.target.value,
                                          e.target.name
                                        )
                                      }
                                      validate={{
                                        min: { value: 1 },
                                        required: { value: true },
                                      }}
                                      errorMessage="Enter Max. Cap"
                                    />
                                  </div>
                                </Col>
                              </>
                            )}
                            <Col md="12">
                              <Editor
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="scholarshipDescription"
                                editorState={editorState}
                                onEditorStateChange={handleEditorStateChange}
                              />
                            </Col>
                            <Col>
                              <div
                                className="mb-3"
                                style={{ paddingTop: "25px" }}
                              >
                                <Button
                                  color={
                                    masterObject.id ? "warning" : "primary"
                                  }
                                  type="submit"
                                >
                                  {masterObject.id ? "Update" : "Submit"}
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </AvForm>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          ) : (
            ""
          )}

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="scholarshipTable"
                    bordered
                    responsive
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default ScholarShips
