import React from "react"
import { Row, Col, Card, CardBody, Label, Button } from "reactstrap"
import Version from "../../assets/fotos/external-drive.svg"
import Update from "../../assets/fotos/export-2.svg"
import Calender from "../../assets/fotos/calendar-1.svg"
import building from "../../assets/fotos/building.svg"
import Logo from "../../assets/images/asaplogo.svg"
const Aboutcompany = () => {
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col style={{ textAlign: "center" }}>
                      <div
                        style={{
                          textAlign: "center",
                          borderBottom: "1px solid #eee",
                          paddingBottom: "1.5rem",
                        }}
                      >
                        <img style={{ height: "100px" }} src={Logo} alt="" />
                        {/* <h4 style={{ fontSize: "1rem", paddingTop:"10px" }} className="mb-0">
                        ASAP Community Skill Park
                              </h4> */}
                      </div>
                    </Col>
                    <Col md="12">
                      <div style={{ paddingTop: "1rem" }}>
                        <Row>
                          <Col>
                            <div
                              className="d-flex gap-3 justify-content-center"
                              style={{
                                paddingTop: "1rem",
                              }}
                            >
                              <div>
                                <img src={Version} style={{ height: "20px" }} />
                              </div>
                              <div>
                                <h4
                                  style={{ fontSize: "1rem" }}
                                  className="mb-0"
                                >
                                  Version
                                </h4>
                                <p style={{ fontWeight: "400" }}>1.0.1</p>
                              </div>
                            </div>
                          </Col>
                          <Col>
                            <div
                              className="d-flex gap-3 justify-content-center"
                              style={{
                                paddingTop: "1rem",
                              }}
                            >
                              <div>
                                <img src={Update} style={{ height: "20px" }} />
                              </div>
                              <div>
                                <h4
                                  style={{ fontSize: "1rem" }}
                                  className="mb-0"
                                >
                                  Updated on
                                </h4>
                                <p style={{ fontWeight: "400" }}>
                                  Apr 11, 2024
                                </p>
                              </div> 
                            </div>
                          </Col>
                          <Col>
                            <div
                              className="d-flex gap-3 justify-content-center"
                              style={{
                                paddingTop: "1rem",
                              }}
                            >
                              <div>
                                <img
                                  src={Calender}
                                  style={{ height: "20px" }}
                                />
                              </div>
                              <div>
                                <h4
                                  style={{ fontSize: "1rem" }}
                                  className="mb-0"
                                >
                                  Released On
                                </h4>
                                <p style={{ fontWeight: "400" }}>
                                  Apr 11, 2024
                                </p>
                              </div>
                            </div>
                          </Col>
                          <Col>
                            <div
                              className="d-flex gap-3 justify-content-center"
                              style={{
                                paddingTop: "1rem",
                              }}
                            >
                              <div>
                                <img
                                  src={building}
                                  style={{ height: "20px" }}
                                />
                              </div>
                              <div>
                                <h4
                                  style={{ fontSize: "1rem" }}
                                  className="mb-0"
                                >
                                  Powered By
                                </h4>
                                <p style={{ fontWeight: "400" }}>
                                  <a
                                    className="text-dark"
                                    href="https://srvinfotech.com/"
                                  >
                                    SRV InfoTech
                                  </a>
                                </p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <Row></Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default Aboutcompany
