import React, { useEffect, useState } from 'react'
import { AvForm } from "availity-reactstrap-validation";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { MDBDataTable } from 'mdbreact';
import Select from "react-select";
import Breadcrumb from '../../../components/Common/Breadcrumb';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { get } from '../../../helpers/api_helper';
import moment from 'moment';
import toastr from "toastr"
import { jsonToExcel } from '../../../helpers/functions';
import useBranch from '../../../hooks/useBranch';
import './style.scss'


function CourseReport() {
    const myCsp = useBranch()
    const [myCspId, setMyCspId] = useState("")

    const [tableData,setTableData] = useState([])
    
    const [sectors,setSectors] = useState([])
    const [qualifications,setQualifications] = useState([])
    const [degrees,setDegrees] = useState([])
    const [streams,setSteams] = useState([])
    const [branches,setBranches] = useState([])
    const [skillLoans,setSkillLoans] = useState([])
    const [scholorShips,setScholorShips] = useState([])


    const intialState = {
        sector:"",
        mode:"",
        qualification:"",
        degree:"",
        stream:"",
        branch:"",
        skillLoan:"",
        scholorShip:""
    }
    const [filterObj,setFilterObj] = useState(intialState)

    useEffect(() => {
         const cspId = myCsp ? myCsp?.[0]?.value : ""
         setMyCspId(cspId)       
    }, [])

    useEffect(()=>{
      const fetchsector = async()=>{
        try {
            const res = await get("/options/sector")
            setSectors(res?.data)
        } catch (error) {
            
        }
      }
      fetchsector()
    },[])

    useEffect(()=>{
        const FetchData = async()=>{
          try {
              const res = await get("/options/qualifications")
              setQualifications(res?.data)
          } catch (error) {
              
          }
        }
        FetchData()
      },[])

      useEffect(()=>{
        const FetchData = async()=>{
          try {
              const res = await get("/options/degree")
              setDegrees(res?.data)
          } catch (error) {
              
          }
        }
        FetchData()
      },[])

      useEffect(()=>{
        const FetchData = async()=>{
          try {
              const res = await get("/options/stream")
              setSteams(res?.data)
          } catch (error) {
              
          }
        }
        FetchData()
      },[])

      useEffect(()=>{
        const FetchData = async()=>{
          try {
              const res = await get("/options/branches")
              setBranches(res?.branches)
          } catch (error) {
              
          }
        }
        FetchData()
      },[])

      useEffect(()=>{
        const FetchData = async()=>{
          try {
              const res = await get("/options/skill-loans")
              setSkillLoans(res?.data)
          } catch (error) {
              
          }
        }
        FetchData()
      },[])

      useEffect(()=>{
        const FetchData = async()=>{
          try {
              const res = await get("/options/scholarships")
              setScholorShips(res?.data)
          } catch (error) {
              
          }
        }
        FetchData()
      },[])

      const data = {
        columns: [
          { label: "#", field: "id", width: 150 },
          { label: "Course", field: "name", width: 150 },
          { label: "Course Code", field: "code", width: 150 },
          { label: "Sector", field: "sectorName", width: 150 },
          { label: "CSP", field: "csp", sort: "desc", width: 400 },
          { label: "Duration", field: "duration", width: 150 },
          { label: "Mode", field: "mode", sort: "desc", width: 400 },
          { label: "Program Mode", field: "ProgramMode", sort: "desc", width: 400 },
          { label: "QP Level", field: "QPLevel", sort: "desc", width: 400 },
          { label: "QP Code", field: "QPCode", sort: "desc", width: 400 },
          { label: "Age", field: "age", sort: "desc", width: 400 },
          { label: "Gender", field: "Gender", sort: "desc", width: 400 },
          { label: "Qualification", field: "Qualification", sort: "desc", width: 400 },
          { label: "Degree", field: "Degree", sort: "desc", width: 400 },
          { label: "Stream", field: "Stream", sort: "desc", width: 400 },
          { label: "Markup Price	", field: "MarkupPrice", sort: "desc", width: 400 },
          { label: "Course Fee	", field: "CourseFeess", sort: "desc", width: 400 },
          { label: "GST slabs	", field: "gstSlabs", sort: "desc", width: 400 },
          { label: "No. of Installments	", field: "NoOfInstallments", sort: "desc", width: 400 },
          { label: "Installments", field: "Installments", sort: "desc", width: 400 },
          { label: "Expiry Date", field: "ExpiryDate", sort: "desc", width: 400 },
          { label: "Skill Loan", field: "SkillLoan", sort: "desc", width: 400 },
          { label: "Scholarship", field: "Scholarship", sort: "desc", width: 400 },
          { label: "Assessment Type", field: "AssesmentType", sort: "desc", width: 400 },
          { label: "Assessment Fee", field: "AssesmentFee", sort: "desc", width: 400 },
          { label: "Added By", field: "addedBy", sort: "desc", width: 400 },
        ],
        rows: tableData
       
      };


    useEffect(()=>{
       const FetchTableData = async()=>{
        const cspId = myCsp ? myCsp?.[0]?.value : ""
      
        try {
              const res = await get(`/course/course-report?sector=${filterObj?.sector?.value}&mode=${filterObj?.mode?.value}&qualification=${filterObj?.qualification?.value}&degree=${filterObj?.degree?.value}&stream=${filterObj?.stream?.value}&skillLoan=${filterObj?.skillLoan?.value}&scholorShip=${filterObj?.scholorShip?.value}&branch=${cspId ? cspId :filterObj?.branch?.value}`) 
              const handleData =  res.data.map((item, index) => {
              const branch =  item?.branches ? item.branches.map(s => s.label).join(', ') : ""
              const Stream = item?.stream ? item?.stream.map(s => s.label).join(', ') : ""
              const Installments = item?.courseFee?.installments ? item?.courseFee?.installments.map(s => `₹${s}`).join(', ') : ""
              const SkillLoan= item?.skillLoans ? item?.skillLoans.map(s => s.label).join(', ') : ""
              const Scholarship = item?.scholarships ? item?.scholarships.map(s => s.label).join(', ') : ""
              const Qualification = item?.qualifications ? item?.qualifications.map(q => q.label).join(', ') : ""
              const Degree = item?.degrees ? item?.degrees.map(d => d.label).join(', ') : ""
           return {
                id: index + 1, 
                name: item?.name,
                code: item?.code,
                sectorName: item?.sector?.label,
                duration: `${item?.duration} ${unitMap[item?.unit]}`,
                mode: modeMap[item.mode],
                ProgramMode: ProgramModeMap[item?.programMode],
                QPLevel: item?.qpLevel,
                QPCode: item?.qpCode,
                age:item.ageFrom && item.ageTo ? `${item.ageFrom} to ${item.ageTo}` : "",
                Gender: item.gender ? item.gender.map(g => genderMap[g]).join(', ') : "", 
                Qualification:  (
                  <>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip
                          id="button-tooltip-2"
                          style={{ position: "absolute", top: "-30px" }}
                        >
                          {Qualification}
                        </Tooltip>
                      }
                    >                                    
                      <div style={{ cursor: "pointer" }}>
                        {Qualification?.slice(0, 50)}{Qualification.length > 50 ?' ...':""}
                      </div>
                    </OverlayTrigger>
                  </>
                ),
                Degree:  (
                  <>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip
                          id="button-tooltip-2"
                          style={{ position: "absolute", top: "-30px" }}
                        >
                          {Degree}
                        </Tooltip>
                      }
                    >                                    
                      <div style={{ cursor: "pointer" }}>
                        {Degree?.slice(0, 50)}{Degree.length > 50 ?' ...':""}
                      </div>
                    </OverlayTrigger>
                  </>
                ),
                Stream:  (
                  <>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip
                          id="button-tooltip-2"
                          style={{ position: "absolute", top: "-30px" }}
                        >
                          {Stream}
                        </Tooltip>
                      }
                    >                                    
                      <div style={{ cursor: "pointer" }}>
                        {Stream?.slice(0, 50)}{Stream.length > 50 ?' ...':""}
                      </div>
                    </OverlayTrigger>
                  </>
                ),
                csp: (
                  <>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip
                          id="button-tooltip-2"
                          style={{ position: "absolute", top: "-30px" }}
                        >
                          {branch}
                        </Tooltip>
                      }
                    >                                    
                      <div style={{ cursor: "pointer" }}>
                        {branch?.slice(0, 50)}{branch.length > 50 ?' ...':""}
                      </div>
                    </OverlayTrigger>
                  </>
                ),
                MarkupPrice:item?.courseFee?.markupPrice && `₹${item?.courseFee?.markupPrice}`,
                CourseFeess:item?.courseFee?.courseFee && `₹${item?.courseFee?.courseFee}`,
                gstSlabs:item?.courseFee?.gst && `${item?.courseFee?.gst}%`,
                NoOfInstallments:item?.courseFee?.installments?.length > 0 ? item?.courseFee?.installments.length : '' ,
                Installments: (
                  <>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip
                          id="button-tooltip-2"
                          style={{ position: "absolute", top: "-30px" }}
                        >
                          {Installments}
                        </Tooltip>
                      }
                    >                                    
                      <div style={{ cursor: "pointer" }}>
                        {Installments?.slice(0, 50)}{Installments.length > 50 ?' ...':""}
                      </div>
                    </OverlayTrigger>
                  </>
                ), 
                ExpiryDate:item?.courseFee?.expireDate && moment(item?.courseFee?.expireDate).format("DD-MM-YYYY"),
                SkillLoan:(
                  <>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip
                          id="button-tooltip-2"
                          style={{ position: "absolute", top: "-30px" }}
                        >
                          {SkillLoan}
                        </Tooltip>
                      }
                    >                                    
                      <div style={{ cursor: "pointer" }}>
                        {SkillLoan?.slice(0, 50)}{SkillLoan.length > 50 ?' ...':""}
                      </div>
                    </OverlayTrigger>
                  </>
                ), 
                Scholarship:(
                  <>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip
                          id="button-tooltip-2"
                          style={{ position: "absolute", top: "-30px" }}
                        >
                          {Scholarship}
                        </Tooltip>
                      }
                    >                                    
                      <div style={{ cursor: "pointer" }}>
                        {Scholarship?.slice(0, 50)}{Scholarship.length > 50 ?' ...':""}
                      </div>
                    </OverlayTrigger>
                  </>
                ), 
                AssesmentType:item?.assesmentType ? AssetMentMap[item?.assesmentType] :"",
                AssesmentFee:item?.assesmentFee ? `₹${item?.assesmentFee}` :"",
                addedBy: item?.added,
              }
            
        })
             setTableData(handleData)
        } catch (error) {
            
        }
       }
       FetchTableData()
    },[filterObj])




    const handleExport = async()=>{
      const cspId = myCsp ? myCsp?.[0]?.value : ""
    
      try {
           const res = await get(`/course/course-report?sector=${filterObj?.sector?.value}&mode=${filterObj?.mode?.value}&qualification=${filterObj?.qualification?.value}&degree=${filterObj?.degree?.value}&stream=${filterObj?.stream?.value}&skillLoan=${filterObj?.skillLoan?.value}&scholorShip=${filterObj?.scholorShip?.value}&branch=${cspId ? cspId :filterObj?.branch?.value}`) 
           const handleData =  res.data.map((item, index) => {
         return {
              ['Name'] : item?.name,
              ['Course Code']: item?.code,
              Sector: item?.sector?.label,
              CSP: item?.branches ? item.branches.map(s => s.label).join(', ') : "",
              Duration: `${item?.duration} ${unitMap[item?.unit]}`,
              Mode: modeMap[item.mode],
              ['Program Mode']: ProgramModeMap[item?.programMode],
              ['QP Level']: item?.qpLevel,
              ['QP Code']: item?.qpCode,
              Age:item.ageFrom && item.ageTo ? `${item.ageFrom} to ${item.ageTo}` : "",
              Gender: item.gender ? item.gender.map(g => genderMap[g]).join(', ') : "", 
              Qualification: item?.qualifications ? item?.qualifications.map(q => q.label).join(', ') : "", 
              Degree: item?.degrees ? item?.degrees.map(d => d.label).join(', ') : "", 
              Stream: item?.stream ? item?.stream.map(s => s.label).join(', ') : "", 
              ['Markup Price']:item?.courseFee?.markupPrice && `₹${item?.courseFee?.markupPrice}`,
              ['Course Fee']:item?.courseFee?.courseFee && `₹${item?.courseFee?.courseFee}`,
              ['Gst Slabs']:item?.courseFee?.gst && `${item?.courseFee?.gst}%`,
              ['No. Of Installments']:item?.courseFee?.installments?.length > 0 ? item?.courseFee?.installments.length : '' ,
              Installments:item?.courseFee?.installments ? item?.courseFee?.installments.map(s => `₹${s}`).join(', ') : "", 
              ['Expiry Date']:item?.courseFee?.expireDate && moment(item?.courseFee?.expireDate).format("DD-MM-YYYY"),
              SkillLoan:item?.skillLoans ? item?.skillLoans.map(s => s.label).join(', ') : "", 
              Scholarship:item?.scholarships ? item?.scholarships.map(s => s.label).join(', ') : "", 
              ['Assesment Type']:item?.assesmentType ? AssetMentMap[item?.assesmentType] :"",
              ['Assesment Fee']:item?.assesmentFee ? `₹${item?.assesmentFee}` :"",
              AddedBy: item?.added,
            }  
      })
           if (handleData.length > 0) {

          jsonToExcel(handleData, `Course-reports-${moment().format("DD-MM-YY")}`)
        } else {
          toastr.info(`There are No Course reports to export`)
        }
      } catch (error) {
          
      }
     }

    // const handleExport = () => {
    //     if (tableData.length > 0) {

    //         const rows = tableData.map(row => {
    //           const rowData = {};
    //           data.columns.forEach(col => {
    //             if(col.label!= '#') rowData[col.label] = row[col.field];
                
    //           });
    //           return rowData;
    //         });

    //       jsonToExcel(rows, `Course-reports-${moment().format("DD-MM-YY")}`)
    //     } else {
    //       toastr.info(`There are No Course reports to export`)
    //     }
    //   }

    const unitMap ={
        // 1 = hours, 2 = days , 3 = months
        1:"Hours",
        2:"Days",
        3:"Months"
    }

    const modeMap = {
         // 0 = Online , 1 = Offline, 2 = Blended
         0:"Online",
         1:"Offline",
         2:"Blended"
    }

    const ProgramModeMap = {
         // 1 = NSQF, 2 = Industry, 3 = ASAP NSQF, 4 = ASAP Course,
         // 5 = Accredited, 6 = Operating Partner Course
         1:"NSQF",
         2:"Industry",
         3:"ASAP NSQF",
         4:"ASAP Course",
         5:"Accredited",
         6:"Operating Partner Course"
        }

        const genderMap = {
          //? 0 = female, 1 = male, 2 = transgender, 3 = others

            0: "Female",
            1: "Male",
            2: "Transgender",
            3: "Others",
          };

        const AssetMentMap = {
            //1 = Internal assesment, 2 = External assesment
            1:"Internal",
            2:"External"
        }  



      const reset = () =>{
        setFilterObj(intialState)
      }

      
      

    return (
        <>
            <div className='page-content'>
                <div className='container-fluid'>
                    <Breadcrumb title="Home" breadcrumbItem="Course Report" />

                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <AvForm>
                                        <Row>
                                            {!myCspId &&
                                        <Col md="3">
                                                <div className="mb-3">
                                                    <Label >CSP</Label>
                                                    <Select
                                                        name="category"
                                                        classNamePrefix="select2-selection"
                                                        type="text"
                                                        onChange={(item)=>setFilterObj({...filterObj,branch:item})}
                                                        value={filterObj?.branch}
                                                        options={branches} />
                                                       
                                                </div>
                                            </Col>
                                               }
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Sector</Label>
                                                    <Select
                                                        name="category"
                                                        classNamePrefix="select2-selection"
                                                        type="text"
                                                        onChange={(item)=>setFilterObj({...filterObj,sector:item})}
                                                        value={filterObj?.sector}
                                                        options={sectors} />
                                                       
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Mode</Label>
                                                    <Select
                                                        name="category"
                                                        classNamePrefix="select2-selection"
                                                        type="text"
                                                        onChange={(item)=>setFilterObj({...filterObj,mode:item})}
                                                        value={filterObj?.mode}
                                                        options={[{label:"Online",value:0},{label:"Offline",value:1},{label:"Blended",value:2}]} />
                                                       
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Qualification</Label>
                                                    <Select
                                                        name="category"
                                                        classNamePrefix="select2-selection"
                                                        type="text"
                                                        onChange={(item)=>setFilterObj({...filterObj,qualification:item})}
                                                        value={filterObj?.qualification}
                                                        options={qualifications} />
                                                       
                                                </div>
                                            </Col>  
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Degree</Label>
                                                    <Select
                                                        name="category"
                                                        classNamePrefix="select2-selection"
                                                        type="text"
                                                        onChange={(item)=>setFilterObj({...filterObj,degree:item})}
                                                        value={filterObj?.degree}
                                                        options={degrees} />
                                                       
                                                </div>
                                            </Col>  
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Stream</Label>
                                                    <Select
                                                        name="category"
                                                        classNamePrefix="select2-selection"
                                                        type="text"
                                                        onChange={(item)=>setFilterObj({...filterObj,stream:item})}
                                                        value={filterObj?.stream}
                                                        options={streams} />
                                                       
                                                </div>
                                            </Col>

                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Skill Loan</Label>
                                                    <Select
                                                        name="category"
                                                        classNamePrefix="select2-selection"
                                                        type="text"
                                                        onChange={(item)=>setFilterObj({...filterObj,skillLoan:item})}
                                                        value={filterObj?.skillLoan}
                                                        options={skillLoans} />
                                                       
                                                </div>
                                            </Col>

                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Scholarship</Label>
                                                    <Select
                                                        name="category"
                                                        classNamePrefix="select2-selection"
                                                        type="text"
                                                        onChange={(item)=>setFilterObj({...filterObj,scholorShip:item})}
                                                        value={filterObj?.scholorShip}
                                                        options={scholorShips} />
                                                       
                                                </div>
                                            </Col>
                                            <Row>
                                            <Col>
                                                <div className="mb-3" style={{ paddingTop: "8px" }}>
                                                  
                                                    <Button type="button" onClick={reset} color="danger" style={{ marginLeft: "7px" }}>
                                                        Reset
                                                    </Button>
                                                    <Button type="button"  onClick={handleExport} color="warning"  style={{ marginLeft: "7px" }}>
                                                        Export
                                                    </Button>

                                                </div>
                                            </Col>
                                            </Row>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>


                    <Row>
                        <Col className='col-12'>
                            <Card>
                                <CardBody>


                                    <MDBDataTable
                                        id="Managetableid1course"
                                        bordered
                                        responsive
                                        searching={true}
                                        entries={20}
                                        disableRetreatAfterSorting={true}
                                        data={data}

                                    >

                                    </MDBDataTable>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>



                </div>
            </div>
        </>
    )
}

export default CourseReport;