import React, { useRef } from "react"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { MDBDataTable } from "mdbreact"

import { AvField, AvForm } from "availity-reactstrap-validation"

import "./style.scss"

const CommunityCategory = () => {
  const ref = useRef()
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Category Name",
        field: "categoryName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Code",
        field: "shortCode",
        sort: "asc",
        width: 270,
      },
      {
        label: "Icon",
        field: "image",
        sort: "asc",
        width: 270,
      },
      {
        label: "Max. Members",
        field: "max",
        sort: "asc",
        width: 270,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: [
      {
        id: 1,
        categoryName: "JavaScript",
        shortCode: "js",
        // image: "dashboard_icon.png",
        max: 5000,
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="far fa-edit"
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ cursor: "pointer", fontSize: "1em" }}
            ></i>
          </div>
        ),
      },
      {
        id: 2,
        categoryName: "Python",
        shortCode: "py",
        // image: "reports_icon.png",
        max: 1000,
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="far fa-edit"
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ cursor: "pointer", fontSize: "1em" }}
            ></i>
          </div>
        ),
      },
      {
        id: 2,
        categoryName: "React",
        shortCode: "react",
        // image: "reports_icon.png",
        max: 8000,
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="far fa-edit"
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ cursor: "pointer", fontSize: "1em" }}
            ></i>
          </div>
        ),
      },
    ],
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb title="Ticket" breadcrumbItem="Category" />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm ref={ref} className="needs-validation">
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Category Name
                          </Label>

                          <AvField
                            name="name"
                            placeholder="Category Name"
                            type="text"
                            errorMessage="Enter Category Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Shortcode</Label>

                          <AvField
                            name="shortCode"
                            placeholder="Shortcode"
                            type="text"
                            errorMessage="Enter Shortcode"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Icon</Label>
                          <AvField
                            name="icon"
                            type="file"
                            className="form-control"
                            id="validationCustom03"
                            rows="1"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Max. Members</Label>

                          <AvField
                            name="max_members"
                            placeholder="Max Members"
                            type="number"
                            errorMessage="Enter Maximum members"
                            className="form-control"
                            validate={{ required: { min: 1 } }}
                            id="validationCustom02"
                          />
                        </div>
                      </Col>
                      <Col md="3" style={{ paddingTop: "4px" }}>
                        <div className="mt-4">
                          <Button
                            color={"primary"}
                            className="me-2"
                            type="submit"
                          >
                            Submit
                          </Button>

                          <Button color="danger" className="me-2" type="reset">
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="communityCategory"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CommunityCategory
