import React, { useEffect, useState } from "react"
import { Row, Col, Button, Modal } from "reactstrap"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import { Box, Divider } from "@mui/material"
import Breadcrumb from "../../../../components/Common/Breadcrumb"
import GradingIcon from "@mui/icons-material/Grading"
import Tooltip from "@mui/material/Tooltip"

import { useParams } from "react-router-dom"

import ScholarshipProcessing from "../Processing"
import { get, put } from "../../../../helpers/api_helper"
import toastr from "toastr"
import { API_URL } from "../../../../config"

import Request from "./request.jsx"
import moment from "moment"

import "./style.scss"
import { setTitle } from "../../../../helpers/functions.js"

const ScholarshipDetails = ({ options }) => {
  setTitle("Scholarship Details")
  const [tab3, setTab3] = React.useState("1")
  const [modal, setModal] = useState(false)
  const [masterObject, setMasterObject] = useState({
    name: "",
    logo: "",
    totalRequests: 0,
    totalSanctioned: 0,
    totalRejected: 0,
    totalRequestAmount: 0,
    totalSanctionedAmount: 0,
    totalRejectedAmount: 0,
    sanctionedPercentage: 0,
  })

  const { id } = useParams()
  const handleChangeTab = (event, newValue) => {
    setTab3(newValue)
  }

  useEffect(() => {
    handleData()
    // eslint-disable-next-line
  }, [])

  const handleData = async () => {
    get(`scholarship/details?id=${id}`).then(res => {
      const result = res.data
      const course = result.course.length
        ? result.course?.join(", ")
        : "All courses"
      result.course = course

      let gender = result.gender?.map(item =>
        item === 1 ? "Male " : item === 2 ? "Female " : "Other "
      )

      result.gender = gender.length ? gender.join(",") : "All genders"

      const category = result.category?.length
        ? result.category?.join(",")
        : "All"
      result.category = category
      console.log(result)
      const minorityStatus = result.minorityStatus?.length
        ? result.minorityStatus?.join(",")
        : "All"
      console.log(minorityStatus)
      result.minorityStatus = minorityStatus

      const district = result.district?.length
        ? result.district?.join(",")
        : "All"
      result.district = district

      result.date = moment(result.date).format("DD-MM-YYYY")
      result.time = moment(result.time, "HH:mm:ss").format("hh:mm a")

      result.upDate = moment(result.upDate).format("DD-MM-YYYY")
      result.upTime = moment(result.upTime, "HH:mm:ss").format("hh:mm a")

      result.expireDate = moment(result.expireDate).format("DD-MM-YYYY")
      setMasterObject(result)
    })
  }

  const handleStatusChange = async () => {
    const id = masterObject._id
    const status = masterObject.status
    await put("/scholarship/status", { id, status }).then(res => {
      toastr.success(res.message)
      handleData()
    })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem={"Scholarships"} />
          <Row>
            <Col xl="12">
              <div>
                <div className="row">
                  <div className="col-xl-3">
                    <div
                      className="card"
                      style={{
                        // width: "fit-content",
                        // width: "351px",
                        height: "max-content",
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                      }}
                    >
                      <div className="card-body">
                        <div className="float-end dropdown"></div>
                        <h5
                          className="mt-2 mb-2"
                          style={{
                            textAlign: "center",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}
                        >
                          {masterObject.name}
                        </h5>
                        <div
                          className="text-muted"
                          style={{
                            textAlign: "center",
                            color: "green !important",
                            fontFamily: "IBM Plex Sans,sans-serif",
                            fontSize: "14px",
                            marginBottom: "3px",
                          }}
                        >
                          <div
                            class="btn-group btn-group-sm"
                            role="group"
                            aria-label="Basic example"
                          >
                            <img
                              style={{ width: "100px", height: "50px" }}
                              src={API_URL + masterObject.logo}
                              alt="logo"
                            />
                          </div>
                        </div>
                        <p
                          style={{
                            textAlign: "center",
                            marginBottom: "3px",
                          }}
                        ></p>

                        <Divider />

                        {/* <div className="mt-2">
                          <p className="mb-1">Provider Name:</p>
                          <h5 className="font-size-14">{masterObject.name}</h5>
                        </div> */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                          className="mt-2"
                        >
                          <p className="mb-1">Status:</p>

                          {masterObject.status === 0 ? (
                            <Button
                              className="btn btn-sm mb-1"
                              color="success"
                              onClick={() => handleStatusChange()}
                            >
                              Active
                            </Button>
                          ) : (
                            <Button
                              className="btn btn-sm mb-1"
                              color="warning"
                              onClick={() => handleStatusChange()}
                            >
                              Inactive
                            </Button>
                          )}
                        </div>
                        {masterObject.file ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            className="mt-2"
                          >
                            <p className="mb-1">File:</p>
                            <Tooltip title="View File" arrow placement="top">
                              <a
                                href={API_URL + masterObject.file}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i
                                  className="fas fa-file-pdf"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "1.5em",
                                    color: "red",
                                  }}
                                ></i>
                              </a>
                            </Tooltip>
                          </div>
                        ) : (
                          ""
                        )}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          className="mt-2"
                        >
                          <p className="mb-1">Total Request:</p>
                          <h5
                            style={{ fontWeight: "600", color: "#1327b7" }}
                            className="font-size-14"
                          >
                            {masterObject.totalRequests}
                          </h5>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          className="mt-2"
                        >
                          <p className="mb-1">Approved:</p>
                          <h5
                            style={{ fontWeight: "600", color: "green" }}
                            className="font-size-14"
                          >
                            {masterObject.totalSanctioned}
                          </h5>
                        </div>
                        {/* <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          className="mt-2"
                        >
                          <p className="mb-1">Processing:</p>
                          <h5
                            style={{ fontWeight: "600", color: "#f1b44c" }}
                            className="font-size-14"
                          >
                            {Math.abs(
                              masterObject.totalSanctioned -
                                masterObject.totalRejected
                            )}
                          </h5>
                        </div> */}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          className="mt-2"
                        >
                          <p className="mb-1">Rejected:</p>
                          <h5
                            style={{ fontWeight: "600", color: "red" }}
                            className="font-size-14"
                          >
                            {masterObject.totalRejected}
                          </h5>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          className="mt-2"
                        >
                          <p className="mb-1">Approved Percentage</p>
                          <h5
                            style={{ fontWeight: "600", color: "green" }}
                            className="font-size-14"
                          >
                            {masterObject.sanctionedPercentage
                              ? masterObject.sanctionedPercentage?.toFixed(0)
                              : 0}
                            %
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-9" style={{ marginBottom: "30px" }}>
                    <div
                      className="mb-0 card"
                      style={{
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={tab3}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              indicatorColor="primary"
                              textColor="primary"
                              variant="fullWidth"
                              onChange={handleChangeTab}
                              aria-label="lab API tabs example"
                            >
                              <Tab
                                icon={
                                  <GradingIcon style={{ fontSize: "20px" }} />
                                }
                                label="Summary"
                                value="1"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <GradingIcon style={{ fontSize: "20px" }} />
                                }
                                label="Requests"
                                value="2"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                            </TabList>
                          </Box>
                          <TabPanel
                            value="1"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col md="12">
                                <table
                                  id="product_available_day"
                                  width={"100%"}
                                  className="table table-bordered dataTable"
                                >
                                  <tr>
                                    <td
                                      style={{
                                        padding: "10px",

                                        textAlign: "justify",
                                      }}
                                    >
                                      Funding Agency
                                    </td>
                                    <th
                                      style={{ textAlign: "left" }}
                                      colSpan={4}
                                    >
                                      : {masterObject.fundingAgency}
                                    </th>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        padding: "10px",

                                        textAlign: "justify",
                                      }}
                                    >
                                      Funding Scheme
                                    </td>
                                    <th
                                      style={{ textAlign: "left" }}
                                      colSpan={4}
                                    >
                                      : {masterObject.fundingScheme}
                                    </th>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        padding: "10px",

                                        textAlign: "justify",
                                      }}
                                    >
                                      Allocated Amount
                                    </td>
                                    <th style={{ textAlign: "left" }}>
                                      : {masterObject.allocatedAmount}
                                    </th>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        padding: "10px",

                                        textAlign: "justify",
                                      }}
                                    >
                                      Sanctioned Amount
                                    </td>
                                    <th style={{ textAlign: "left" }}>
                                      : {masterObject.approvedAmount}
                                    </th>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        padding: "10px",

                                        textAlign: "justify",
                                      }}
                                    >
                                      Balance
                                    </td>
                                    <th style={{ textAlign: "left" }}>
                                      :{" "}
                                      {masterObject.allocatedAmount -
                                        masterObject.approvedAmount}
                                    </th>
                                  </tr>
                                  {/* <tr>
                                    <td
                                      style={{
                                        padding: "10px",

                                        textAlign: "justify",
                                      }}
                                    >
                                      Maximum Cap
                                    </td>
                                    <th style={{ textAlign: "left" }}>
                                      : {masterObject.maximumCap}/-
                                    </th>
                                  </tr> */}
                                  {/* <tr>
                                    {" "}
                                    <td
                                      style={{
                                        padding: "10px",
                                        textAlign: "justify",
                                      }}
                                    >
                                      % of Scholarship
                                    </td>
                                    <th
                                      style={{ textAlign: "left" }}
                                      colSpan={3}
                                    >
                                      : {masterObject.percentage}
                                    </th>
                                  </tr> */}
                                  <tr>
                                    <td
                                      style={{
                                        padding: "10px",

                                        textAlign: "justify",
                                      }}
                                    >
                                      Courses Applicable
                                    </td>
                                    <th
                                      colSpan={5}
                                      style={{ textAlign: "left" }}
                                    >
                                      : {masterObject.course}
                                    </th>
                                  </tr>

                                  <tr>
                                    <td
                                      style={{
                                        padding: "10px",

                                        textAlign: "justify",
                                      }}
                                    >
                                      Expiry Date
                                    </td>
                                    <th style={{ textAlign: "left" }}>
                                      : {masterObject.expireDate}
                                    </th>
                                  </tr>
                                  {/* <tr>
                                    <td
                                      style={{
                                        padding: "10px",

                                        textAlign: "justify",
                                      }}
                                    >
                                      Gender
                                    </td>
                                    <th style={{ textAlign: "left" }}>
                                      : {masterObject.gender}
                                    </th>
                                    <td
                                      style={{
                                        padding: "10px",
                                      }}
                                    >
                                      Category
                                    </td>
                                    <th style={{ textAlign: "left" }}>
                                      : {masterObject.category}
                                    </th>
                                  </tr> */}
                                  <tr>
                                    <td
                                      style={{
                                        padding: "10px",

                                        textAlign: "justify",
                                      }}
                                    >
                                      Added By
                                    </td>
                                    <th style={{ textAlign: "left" }}>
                                      : {masterObject.addedBy}
                                    </th>
                                    <td
                                      style={{
                                        padding: "10px",

                                        textAlign: "justify",
                                      }}
                                    >
                                      Date
                                    </td>
                                    <th style={{ textAlign: "left" }}>
                                      : {masterObject.date}
                                    </th>
                                    <td
                                      style={{
                                        padding: "10px",

                                        textAlign: "justify",
                                      }}
                                    >
                                      Time
                                    </td>
                                    <th style={{ textAlign: "left" }}>
                                      : {masterObject.time}
                                    </th>
                                  </tr>
                                  {masterObject.updatedBy ? (
                                    <tr>
                                      <td
                                        style={{
                                          padding: "10px",

                                          textAlign: "justify",
                                        }}
                                      >
                                        Updated By
                                      </td>
                                      <th style={{ textAlign: "left" }}>
                                        : {masterObject.updatedBy}
                                      </th>
                                      <td
                                        style={{
                                          padding: "10px",

                                          textAlign: "justify",
                                        }}
                                      >
                                        Date
                                      </td>
                                      <th style={{ textAlign: "left" }}>
                                        : {masterObject.upDate}
                                      </th>
                                      <td
                                        style={{
                                          padding: "10px",

                                          textAlign: "justify",
                                        }}
                                      >
                                        Time
                                      </td>
                                      <th style={{ textAlign: "left" }}>
                                        : {masterObject.upTime}
                                      </th>
                                    </tr>
                                  ) : (
                                    ""
                                  )}
                                </table>
                                {masterObject.condition ? (
                                  <>
                                    <table
                                      id="scholarshipConditionList"
                                      width={"100%"}
                                      className="table table-bordered dataTable"
                                    >
                                      <thead>
                                        <tr>
                                          <th>Category</th>
                                          <th>Gender</th>
                                          <th>Minority Status</th>
                                          <th>District</th>
                                          <th>Percentage</th>
                                          <th>Maximum</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {masterObject.customConditions?.map(
                                          item => {
                                            return (
                                              <tr>
                                                <td>{item?.category}</td>
                                                <td>
                                                  {item?.gender === 1
                                                    ? "Male"
                                                    : item.gender === 2
                                                    ? "Female"
                                                    : item.gender === 3
                                                    ? "Other"
                                                    : "All"}
                                                </td>
                                                <td>{item?.minorityStatus}</td>
                                                <td>{item?.district}</td>
                                                <td>{item?.percentage}%</td>
                                                <td>{item?.maximum}</td>
                                              </tr>
                                            )
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  </>
                                ) : (
                                  <table
                                    id="scholarshipConditionList"
                                    width={"100%"}
                                    className="table table-bordered dataTable"
                                  >
                                    <thead>
                                      <tr>
                                        <th>Category</th>
                                        <th>Gender</th>
                                        <th>Minority Status</th>
                                        <th>District</th>
                                        <th>Percentage</th>
                                        <th>Maximum</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>{masterObject?.category}</td>
                                        <td>{masterObject?.gender}</td>
                                        <td>{masterObject?.minorityStatus}</td>
                                        <td>{masterObject?.district}</td>
                                        <td>{masterObject?.percentage}%</td>
                                        <td>{masterObject?.maximumCap}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                )}
                                {masterObject.description ? (
                                  <>
                                    <p>Description :</p>
                                    <hr />
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: masterObject.description,
                                      }}
                                    ></div>
                                  </>
                                ) : (
                                  ""
                                )}
                                {/* <table className="table  table-striped">
                                  
                                  <tbody>
                                    <tr>
                                      <td>Total Amount </td>
                                      <th>: {masterObject.allocatedAmount}</th>

                                      <td>Sanctioned Amount</td>
                                      <th>
                                        : {masterObject.totalSanctionedAmount}
                                      </th>
                                    </tr>
                                    <tr>
                                      <td>Rejected Amount</td>
                                      <th>
                                        : {masterObject.totalRejectedAmount}
                                      </th>

                                      <td>Requested Amount</td>
                                      <th>
                                        : {masterObject.totalRequestAmount}
                                      </th>
                                    </tr>
                                    <tr>
                                      <td>Balance Amount</td>
                                      <th>
                                        :{" "}
                                        {masterObject.allocatedAmount -
                                          masterObject.totalSanctionedAmount}
                                      </th>
                                      <td>Sanctioned Percentage</td>
                                      <th>
                                        :{" "}
                                        {masterObject.sanctionedPercentage.toFixed(
                                          0
                                        )}
                                        %
                                      </th>
                                    </tr>
                                  </tbody>
                                </table> */}
                              </Col>
                            </Row>
                          </TabPanel>
                          <TabPanel
                            value="2"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Request
                              id={masterObject._id}
                              options={options}
                            ></Request>
                          </TabPanel>
                        </TabContext>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Modal isOpen={modal} size="xl" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Scholarship Details</h5>
          <button
            type="button"
            onClick={() => {
              setModal(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <ScholarshipProcessing />
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default ScholarshipDetails
