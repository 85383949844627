import React, { useEffect, useState } from "react"

import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"

import moment from "moment"

import { API_URL } from "../../../config"
const PlacementModal = ({data}) => {

  const [modal, setModal] = useState({})
 
  return (
    <div>
      
      {" "}
      <div className="modal-body" style={{ paddingTop: "0px" }}>
        <Row>
          <Col md="12">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tbody>
                <tr>
                  <th style={{ textAlign: "start" }}>Placement ID</th>
                  <th> : </th>
                  <td style={{ textAlign: "start" }}>{data?.uniqueId} </td>
                  <th style={{ padding: "", textAlign: "start" }}>Date</th>
                  <th> : </th>
                  <td style={{ textAlign: "start" }}>{data?.date}</td>
                </tr>
                <tr>
                  <th style={{ textAlign: "start" }}>Student Name</th>
                  <th> : </th>
                  <td style={{ textAlign: "start" }}>
                    {data?.student}
                  </td>
                  <th style={{ textAlign: "start" }}>Company</th>
                  <th> : </th>
                  <td style={{ textAlign: "start" }}> {data?.companyName}</td>
                </tr>
                <tr>
                  <th style={{ textAlign: "start" }}>Designation</th>
                  <th> : </th>
                  <td style={{ textAlign: "start" }}>
                    {" "}
                    {data?.designation}
                  </td>
                  {data?.imageData &&
                  <>
                  <th style={{ textAlign: "start" }}>Image</th>
                  <th> : </th>
                  <td style={{ textAlign: "start" }}>
                    {data?.imageData}
                  
                  </td>
                  </>
                 }
                </tr>
              
               
                  
              </tbody>
            </table>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default PlacementModal