import React, { useEffect, useState } from "react"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Badge, Spinner } from "reactstrap"
import "./StudentDetail.scss"
import { useNavigate } from "react-router-dom"
import { get, put } from "../../../helpers/api_helper"
import TablePagination from "../../../components/Common/Pagination"
import { Tooltip as TooltipMui} from "@mui/material"
import moment from "moment"
import toastr from "toastr"
import { jsonToExcel } from "../../../helpers/functions"

const Students = () => {
  const [students, setStudents] = useState([{}])
  const [search, setSearch] = useState("")
  const [page, setPage] = useState(1)
  const [pageSize] = useState(20)
  const [count, setCount] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [isLoading,setIsLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    handleDetailslist()
  }, [search, page, pageSize])


  const handleNavigate = e => {
    navigate(`/student-details/${e.mobile}`, {
      state: { id: e._id },
    })
  }

  function handleDetailslist() {
    get(
      `/student/details?keyword=${search}&page=${page - 1}&limit=${pageSize}`
    ).then(res => {
      setStudents(res)
      setCount(res.count)
      const totalPage = Math.ceil(Number(res.count) / pageSize)
      setPageCount(totalPage)
    })
  }

  const handleExportData = async () => {
    try {
      setIsLoading(true)
      let res = await get(
        `/student/details?keyword=${search}&limit=${count}`
      )
      const data = res?.data
      const exportData = []

      if (data && Array.isArray(data) && data.length > 0) {
        data.map((item, index) => {
          const dataExport = {}

          dataExport["SI NO"] = index + 1
          dataExport.ID = item?.unique_id
          dataExport.Name = `${item?.firstname || "" } ${item?.lastname || "" }`
          dataExport.Email = item?.email
          dataExport.Phone = item?.mobile
          dataExport.Status = item?.status == 0 ? "Active" : "Inactive" 
          exportData.push(dataExport)
        })

        jsonToExcel(exportData, `Students-list-${moment().format("DD-MM-YY")}`)
        setIsLoading(false)
      } else {
        setIsLoading(false)
        toastr.info(`There are No data to export`)
      }
    } catch (error) {
      setIsLoading(false)
      toastr.info('Failed to export data. Please try again.');
    }
  }


  function changeStatus(row) {
    const obj = {
      _id: row._id,
      status: row.status === 0 ? 2 : row.status === 2 ? 0 : null,
    }
    put(`student/status`, obj).then(res => {
      handleDetailslist()
    })
  }

  // const handleChangeRowsPerPage = event => {
  //   setPageSize(parseInt(event.target.value, 10))
  //   setPage(0)
  // }

  const handlePageChange = (event, value) => {
    setPage(value)
    window.scrollTo({top:0,behavior:'smooth'})
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid relative">
          <Breadcrumb title="Home" breadcrumbItem="Students" />
          <Col md='12'>
          <div className="p-2 pt-0 d-flex justify-content-end me-1 absolute top-10 ">
                     {isLoading ? 
                    <Spinner
                    color="dark"
                    size="sm"
                    style={{marginTop:"9px", marginRight:"9px"}}

                  >
                    Loading...
                  </Spinner> : 
                     <TooltipMui
                     title={"Export"}
                     arrow
                     placement="top"
                     id="button-tooltip-2"
                   >
                     <i
                       onClick={handleExportData}
                       className="cursor-pointer fs-4 fas fa-file-export"
                       style={{marginRight:"6px",marginTop:"6px"}}
                     ></i>
                   </TooltipMui>
                    }
                     
                      
              <div className="form-inline float-md-end mb-3">
                <div className="search-box ml-2">
                  <div className="position-relative">
                    <input
                      type="text"
                      onChange={e => {
                        setSearch(e.target.value)
                        setPage(1)
                      }}
                      className="form-control rounded border-0"
                      placeholder="Search..."
                    />
                    <i className="mdi mdi-magnify search-icon"></i>
                  </div>
                </div>
              </div>
         </div>
 
          </Col>
          <Row>
            {students?.data?.map((row, idx) => {
              return (
                <Col key={idx} xs={12} sm={12} md={6} xl={4} xxl={3}>
                  <Card className="student-card p-1">
                    <CardBody>
                      <div style={{ height: "160px" }}>
                        <div
                          onClick={e => {
                            changeStatus(row)
                            // const data = [...students.data]
                            // data[idx].status = row?.status === 1 ? 0 : 1
                            // // console.log(data,"data");
                            // setStudents(data)
                          }}
                          className="status"
                        >
                          {row.status === 0 ? (
                            <Badge className="bg-success">Active</Badge>
                          ) : row.status === 2 ? (
                            <Badge className="bg-warning">Inactive</Badge>
                          ) : null}
                        </div>

                        <div onClick={() => handleNavigate(row)}>
                          <p>
                            <i className="fas fa-id-card"></i>
                            ID: {row?.unique_id || ""}
                          </p>
                          <p>
                            <i className="fas fa-user"></i>
                            Name: {row.firstname || ""} {row.lastname || ""}
                          </p>
                          <p>
                            <i className="far fa-envelope"></i>
                            Email: {row.email || ""}
                          </p>
                          <p>
                            <i className="fas fa-phone-alt"></i>
                            Phone: {row.mobile || ""}
                          </p>
                          <p>
                            <i className="fas fa-school"></i>
                            CSP: {row.branch?.name || ""}
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )
            })}
            <Col md={12}>
              <Card>
                <CardBody>
                  <TablePagination
                    page={page}
                    onChange={handlePageChange}
                    count={pageCount}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default Students
