import React, { useEffect, useState } from "react"
import { Card, CardBody, Table, CardTitle, Row, Col, Modal } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { get } from "../../../../helpers/api_helper"
import moment from "moment"
import PaymentDetails from "../../Fees/PaymentDetails"
import { API_URL } from "../../../../config"

import Tooltip from "@mui/material/Tooltip"

const LatestTransaction = () => {
  const [latestTransaction, setLatestTransaction] = useState([])
  const [modal, setModal] = useState(false)
  const [selectedId, setSelectedId] = useState({})

  const navigate = useNavigate()

  useEffect(() => {
    LatestTransaction()
  }, [])

  const handlePopUp = id => {
    setModal(true)
    setSelectedId(id)
  }

  function LatestTransaction() {
    get(`/finance-dashboard/latest-payments`).then(res => {
      // let result = res?.counts
      setLatestTransaction(res)
    })
  }

  return (
    <>
      {latestTransaction.length > 0 && (
        <>
          <Modal
            show={modal}
            isOpen={modal}
            toggle={() => setModal(!modal)}
            size="lg"
            centered={true}
            className="lead-modal"
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">Payment Details</h5>
              <button
                type="button"
                onClick={() => {
                  setModal(false)
                  // handleTableData()
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <PaymentDetails id={selectedId} />
          </Modal>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4">Latest Payments</CardTitle>
                  <div className="table-responsive">
                    <Table
                      className="table-centered table-nowrap mb-0"
                      id="latestTransactions"
                    >
                      <thead className="table-light">
                        <tr>
                          <th>Receipt No</th>
                          <th>Date</th>
                          <th>Student Name</th>
                          <th>Course/event</th>
                          <th>Fee</th>
                          <th>Paid</th>
                          <th>Due</th>
                          <th>Payment Status</th>
                          {/* <th>Payment Method</th> */}
                          <th>View Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        {latestTransaction?.data?.map(res => {
                          return (
                            <tr>
                              <td>
                                <Link to="#" className="text-body fw-bold">
                                  {res?.uniqueId}
                                </Link>{" "}
                              </td>
                              <td>
                                {res?.date
                                  ? moment(res.date).format("DD-MM-YYYY")
                                  : ""}
                              </td>
                              <td
                                onClick={() => {
                                  navigate(
                                    `/student-details/${res?.student?.uniqueId}`,
                                    { state: { id: res?.student?._id } }
                                  )
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {/* <Link
                              to={`/student-details/${res?.student?.uniqueId}`}
                            > */}
                                {res?.student?.name}
                                {/* </Link> */}
                              </td>
                              <td>{res?.name}</td>

                              <td style={{ textAlign: "end" }}>₹{res?.fee}</td>
                              <td style={{ textAlign: "end" }}>
                                ₹{res?.amount}
                              </td>
                              <td style={{ textAlign: "end" }}>₹{res?.due}</td>
                              <td style={{ textAlign: "center" }}>
                                {res?.status === 0 ? (
                                  <span className="badge rounded-pill bg-soft-success font-size-12">
                                    Success
                                  </span>
                                ) : res?.status === 1 ? (
                                  <span className="badge rounded-pill bg-soft-danger font-size-12">
                                    Failed
                                  </span>
                                ) : (
                                  <span className="badge rounded-pill bg-soft-warning font-size-12">
                                    Pending
                                  </span>
                                )}
                              </td>
                              {/* <td>Cash</td> */}
                              <td>
                                <div>
                                  <i
                                    className="fas fa-eye"
                                    onClick={() => {
                                      handlePopUp(res._id)
                                    }}
                                    style={{
                                      fontSize: "1em",
                                      cursor: "pointer",
                                      marginLeft: "0.5rem",
                                      marginRight: "0.5rem",
                                    }}
                                  ></i>
                                  {res.receiptPath ? (
                                    <Tooltip
                                      title="Receipt"
                                      arrow
                                      placement="top"
                                    >
                                      <a
                                        href={API_URL + res.receiptPath}
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{
                                          textDecoration: "none",
                                          color: "inherit",
                                        }}
                                      >
                                        <i
                                          className="uil-file-download"
                                          style={{
                                            fontSize: "1.5em",
                                            cursor: "pointer",
                                          }}
                                        ></i>
                                      </a>
                                    </Tooltip>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default LatestTransaction
