import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  BreadcrumbItem,
  CardHeader,
} from "reactstrap"
import { API_URL } from "../../../config"
import Stack from "@mui/material/Stack"

import { MDBDataTable } from "mdbreact"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import FactCheckIcon from "@mui/icons-material/FactCheck"
import BallotIcon from "@mui/icons-material/Ballot"
import AssignmentIcon from "@mui/icons-material/Assignment"
import BookmarkIcon from "@mui/icons-material/Bookmark"
import SummarizeIcon from "@mui/icons-material/Summarize"
import { Box, Divider } from "@mui/material"
import { AvForm } from "availity-reactstrap-validation"
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap"
import Select from "react-select"
import { Link, useLocation } from "react-router-dom"
import MiniWidget from "./mini-widget"
// import "./style.scss"

import { get } from "../../../helpers/api_helper"
import Summary from "./Tabs/Summary"
import Applied from "./Tabs/Applied"
import Saved from "./Tabs/Saved"
import Placed from "./Tabs/Placed"
import Shortlisted from "./Tabs/Shoetlisted"
import moment from "moment"
const ViewJob = props => {
  // const { jobId } = props.match.params;
  const { state } = useLocation()

  const [popupView, setPopupView] = useState(false)
  const [popupView2, setPopupView2] = useState(false)
  const [popupView3, setPopupView3] = useState(false)
  const [popupView4, setPopupView4] = useState(false)

  const [tab3, setTab3] = React.useState("5")
  const [popupSavedView, setPopupSavedView] = useState(false)
  const [popupAppliedView, setPopupAppliedView] = useState(false)
  const [popupShortlistedView, setPopupShortlistedView] = useState(false)
  const [popupPlacedView, setPopupPlacedView] = useState(false)
  const [showDeclineAppliedForm, setShowDeclineAppliedForm] = useState(false)
  const [showDeclinePlacedForm, setShowDeclinePlacedForm] = useState(false)
  const [showDeclineShortlistedForm, setShowDeclineShortlistedForm] =
    useState(false)

  const handleChangeTab = (event, newValue) => {
    setTab3(newValue)
    // resetPaginateJob();
    // setSearchValueJob("");

    // resetPaginateApp();
    // setSearchValueApp("");

    // resetPaginateShort();
    // setSearchValueShort("");

    // resetPaginatePlaced();
    // setSearchValuePlaced("");

    // resetPaginateRemarks();
    // setSearchValueActivity("");
  }
  const [jobList, setJobList] = useState({})
  useEffect(() => {
    fetchdatalist()
  }, [])

  function fetchdatalist() {
    get(`/internship?id=${state.id}`).then(res => {
      setJobList(res?.data)
    })
  }
  

  return (
    <React.Fragment>
      <Modal
        show={popupView4}
        isOpen={popupView4}
        toggle={() => setPopupView4(!popupView4)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h5>Applied Details</h5>
          </div>

          <button
            type="button"
            onClick={() => setPopupView4(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body" style={{ paddingTop: "0px" }}>
          <Row>
            <Col md="12">
              <table
                id="product_available_day"
                className="table table-bordered dataTable"
              >
                <tr>
                  <th style={{ padding: "10px 11px", textAlign: "left" }}>
                    Job
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>Cyber Security</td>
                  <th style={{ textAlign: "left" }}>Recruiters</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    UST Global
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>
                    Application ID
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>ASPJP0001</td>
                  <th style={{ textAlign: "left" }}>Candidate</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    {" "}
                    AL Amin Ali
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>Mobile</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    9365524183
                  </td>
                  <th style={{ textAlign: "left" }}>Email</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    alamin@gmail.com
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>
                    Experiance
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Fresher
                  </td>
                  <th style={{ textAlign: "left" }}>Qualificaion</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Graduation
                  </td>
                </tr>
              </table>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h5>Placed Details</h5>
          </div>

          <button
            type="button"
            onClick={() => setPopupView(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body" style={{ paddingTop: "0px" }}>
          <Row>
            <Col md="12">
              <table
                id="product_available_day"
                className="table table-bordered dataTable"
              >
                <tr>
                  <th style={{ padding: "10px 11px", textAlign: "left" }}>
                    Job
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>Cyber Security</td>
                  <th style={{ textAlign: "left" }}>Recruiters</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    UST Global
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>
                    Application ID
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>ASPJP0001</td>
                  <th style={{ textAlign: "left" }}>Candidate</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    AL Amin Ali
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>
                    Placed Date
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>24-01-2024</td>
                  <th style={{ textAlign: "left" }}>Placed Time</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    02:30 pm
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>Mobile</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    9365524183
                  </td>
                  <th style={{ textAlign: "left" }}>Email</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    alamin@gmail.com
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>
                    Experiance
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Fresher
                  </td>
                  <th style={{ textAlign: "left" }}>Qualificaion</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Graduation
                  </td>
                </tr>
              </table>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal
        show={popupView2}
        isOpen={popupView2}
        toggle={() => setPopupView(!popupView2)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h5>Shortlisted Details</h5>
          </div>

          <button
            type="button"
            onClick={() => setPopupView2(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body" style={{ paddingTop: "0px" }}>
          <Row>
            <Col md="12">
              <table
                id="product_available_day"
                className="table table-bordered dataTable"
              >
                <tr>
                  <th style={{ padding: "10px 11px", textAlign: "left" }}>
                    Job
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>Cyber Security</td>
                  <th style={{ textAlign: "left" }}>Recruiters</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    UST Global
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>
                    Application ID
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>ASPJP0001</td>
                  <th style={{ textAlign: "left" }}>Candidate</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    AL Amin Ali
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>
                    Shortlisted Date
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>24-01-2024</td>
                  <th style={{ textAlign: "left" }}>Shortlisted Time</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    02:30 pm
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>Mobile</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    9365524183
                  </td>
                  <th style={{ textAlign: "left" }}>Email</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    alamin@gmail.com
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>
                    Experiance
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Fresher
                  </td>
                  <th style={{ textAlign: "left" }}>Qualificaion</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Graduation
                  </td>
                </tr>
              </table>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal
        show={popupView3}
        isOpen={popupView3}
        toggle={() => setPopupView3(!popupView3)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h5>Saved Details</h5>
          </div>

          <button
            type="button"
            onClick={() => setPopupView3(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body" style={{ paddingTop: "0px" }}>
          <Row>
            <Col md="12">
              <table
                id="product_available_day"
                className="table table-bordered dataTable"
              >
                <tr>
                  <th style={{ padding: "10px 11px", textAlign: "left" }}>
                    Job
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>Cyber Security</td>
                  <th style={{ textAlign: "left" }}>Recruiters</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    UST Global
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>
                    Application ID
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>ASPJP0001</td>
                  <th style={{ textAlign: "left" }}>Candidate</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    AL Amin Ali
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>Mobile</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    9365524183
                  </td>
                  <th style={{ textAlign: "left" }}>Email</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    alamin@gmail.com
                  </td>
                </tr>
                <tr>
                  <th style={{ textAlign: "left", padding: "10px" }}>
                    Experiance
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Fresher
                  </td>
                  <th style={{ textAlign: "left" }}>Qualificaion</th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    Graduation
                  </td>
                </tr>
              </table>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal show={showDeclineAppliedForm} size="md" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Decline Candidate</h5>
          <button
            type="button"
            onClick={() => {
              setShowDeclineAppliedForm(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation">
          <div className="modal-body">
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label>Reason</Label>
                  <Select
                    name="decline_remark"
                    classNamePrefix="select2-selection"
                  />
                </div>
              </Col>
            </Row>
            <Row className="row mb-0">
              <Col className="col-12 text-end">
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </AvForm>
      </Modal>

      <Modal show={showDeclineShortlistedForm} size="md" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Decline Candidate</h5>
          <button
            type="button"
            onClick={() => {
              setShowDeclineShortlistedForm(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation">
          <div className="modal-body">
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label>Reason</Label>
                  <Select
                    name="decline_remark"
                    classNamePrefix="select2-selection"
                  />
                </div>
              </Col>
            </Row>
            <Row className="row mb-0">
              <Col className="col-12 text-end">
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </AvForm>
      </Modal>

      <Modal show={showDeclinePlacedForm} size="md" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Decline Candidate</h5>
          <button
            type="button"
            onClick={() => {
              setShowDeclinePlacedForm(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation">
          <div className="modal-body">
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label>Reason</Label>
                  <Select
                    name="decline_remark"
                    classNamePrefix="select2-selection"
                  />
                </div>
              </Col>
            </Row>

            <Row className="row mb-0">
              <Col className="col-12 text-end">
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </AvForm>
      </Modal>

      <Modal show={popupShortlistedView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Shortlisted Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupShortlistedView(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation">
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ width: "184px", padding: "10px" }}>
                  Application Name :
                </td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>Application ID :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Shortlisted Date :</td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>Shortlisted Time :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>State :</td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>District :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Institute :</td>
                <td style={{ width: "200px", wordBreak: "break-all" }}></td>
                <td style={{ paddingLeft: "25px" }}>Candidate :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Experience :</td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>qualification :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                ></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Status :</td>
                <td>{"Declined"}</td>
                <td style={{ paddingLeft: "25px" }}>Remarks :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                ></td>
              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>

      <Modal show={popupPlacedView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Placed Application Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupPlacedView(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation">
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ width: "184px", padding: "10px" }}>
                  Application Name :
                </td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>Reg No :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Placed Date :</td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>Placed Time :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>State :</td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>District :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Institute :</td>
                <td style={{ width: "200px", wordBreak: "break-all" }}></td>
                <td style={{ paddingLeft: "25px" }}>Candidate :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Experience :</td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>qualification :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                ></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Status :</td>
                <td>{"Declined"}</td>
                <td style={{ paddingLeft: "25px" }}>Remarks :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                ></td>
              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>

      <Modal show={popupSavedView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Saved Application Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupSavedView(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation">
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ width: "184px", padding: "10px" }}>
                  Application Name :
                </td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>Reg No :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Saved Date :</td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>Saved Time :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>State :</td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>District :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Subject :</td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>Experience :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Applicant :</td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>No of openings :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Class :</td>
                <td style={{ width: "191.67px", wordBreak: "break-all" }}></td>
                <td style={{ paddingLeft: "25px" }}>qualification :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                ></td>
              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>

      <Modal show={popupAppliedView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Application Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupAppliedView(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation">
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ width: "184px", padding: "10px" }}>
                  Application Name :
                </td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>Application ID :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>State :</td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>District :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Subject :</td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>Experience :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Applicant :</td>
                <td></td>
                <td style={{ paddingLeft: "25px" }}>Mobile :</td>
                <td style={{ textAlign: "left" }}></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Class :</td>
                <td style={{ width: "191.67px", wordBreak: "break-all" }}></td>
                <td style={{ paddingLeft: "25px" }}>qualification :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                ></td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Status :</td>
                <td>{"Declined"}</td>
                <td style={{ paddingLeft: "25px" }}>Remarks :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                ></td>
              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>

      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">Internship - {jobList?.name}</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      <Link to="#">Home</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="/create-internship-post">Internship</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="#"> {jobList?.name}</Link>
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              {/* First Dialogue */}

              <div>
                <div className="row">
                  <div className="col-xl-3">
                    <div
                      className="card"
                      style={{
                        // width: "fit-content",
                        // width: "351px",
                        height: "max-content",
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                      }}
                    >
                      <div className="card-body">
                        <div className="float-end dropdown">
                          {/* <a
                                                        aria-haspopup="true"
                                                        className="text-body font-size-16 ddropdown-toggle"
                                                        aria-expanded="false"
                                                    ></a> */}
                          {/* <div
                                                        tabindex="-1"
                                                        role="menu"
                                                        aria-hidden="true"
                                                        className="dropdown-menu-end dropdown-menu"
                                                    >
                                                        <a
                                                            to="/"
                                                            tabindex="0"
                                                            role="menuitem"
                                                            className="dropdown-item"
                                                        >
                                                            Edit
                                                        </a>
                                                        <a
                                                            to="/"
                                                            tabindex="1"
                                                            role="menuitem"
                                                            className="dropdown-item"
                                                        >
                                                            Action
                                                        </a>
                                                        <a
                                                            to="/"
                                                            tabindex="2"
                                                            role="menuitem"
                                                            className="dropdown-item"
                                                        >
                                                            Remove
                                                        </a>
                                                    </div> */}
                        </div>
                        <h5
                          className="mt-2 mb-2"
                          style={{
                            textAlign: "center",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}
                        >
                          {jobList?.name}
                        </h5>
                        {jobList?.image && (
                          <Stack
                            direction="row"
                            style={{ justifyContent: "center" }}
                          >
                            <img
                              alt=" Image"
                              src={`${API_URL}${jobList.image}`}
                              style={{
                                width: "5rem",
                                height: "4rem",
                                borderRadius: "5px",
                              }}
                            />
                          </Stack>
                        )}

                        <div
                          className="text-muted"
                          style={{
                            textAlign: "center",
                            color: "green !important",
                            fontFamily: "IBM Plex Sans,sans-serif",
                            fontSize: "14px",
                            marginBottom: "3px",
                          }}
                        >
                          {/* <div
                            class="btn-group btn-group-sm"
                            role="group"
                            aria-label="Basic example"
                          >
                            <button
                              class="btn btn-success"
                              style={{
                                borderRadius: "40px",
                                marginRight: "10px",
                                padding: "0",
                                width: "28px",
                                cursor: "default",
                              }}
                            >
                              <i
                                className="uil-phone-alt"
                                style={{ fontSize: "15px" }}
                              />
                            </button>
                            {jobList?.whatsApp}
                          </div> */}
                        </div>
                        {jobList.status !== undefined ? (
                          <p
                            style={{
                              textAlign: "center",
                              marginBottom: "3px",
                              color:
                                jobList.status === 0
                                  ? "green"
                                  : jobList.status === 2
                                  ? "orange"
                                  : jobList.status === 3
                                  ? "red"
                                  : jobList.status === 4
                                  ? "blue"
                                  : "",
                            }}
                          >
                            {jobList.status === 0
                              ? "Ongoing"
                              : jobList.status === 2
                              ? "Inactive"
                              : jobList.status === 3
                              ? "Expired"
                              : jobList.status === 4
                              ? "Filled"
                              : ""}
                          </p>
                        ) : null}
                        <Divider />
                        <div
                          className="mt-2"
                          //   style={{
                          //     display: "flex",
                          //     alignItems: "center",
                          //     justifyContent: "space-between",
                          //   }}
                        >
                          <p className="mb-1">Date:</p>
                          <h5 className="font-size-14">
                            {moment(jobList.date).format("DD-MM-YYYY")}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div
                          className="mt-2"
                          //   style={{
                          //     display: "flex",
                          //     alignItems: "center",
                          //     justifyContent: "space-between",
                          //   }}
                        >
                          <p className="mb-1">Recruiter:</p>
                          <h5 className="font-size-14">
                            {jobList?.recruiter?.name}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2">
                          <p className="mb-1"> Sector:</p>
                          <h5 className="font-size-14">
                            {jobList?.sector?.name}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div
                          className="mt-2"
                          //   style={{
                          //     display: "flex",
                          //     alignItems: "center",
                          //     justifyContent: "space-between",
                          //   }}
                        >
                          <p className="mb-1">Qualification:</p>
                          <h5 className="font-size-14">
                            {" "}
                            {jobList?.qualification
                              ? jobList.qualification
                                  ?.map(el => el?.name)
                                  .join(", ")
                              : ""}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2">
                          <p className="mb-1">Type:</p>
                          <h5 className="font-size-14">
                            {jobList?.type === 0 ? "Paid" : "Unpaid"}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2">
                          <p className="mb-1">Expiry Date:</p>
                          <h5 className="font-size-14">
                            {" "}
                            {moment(jobList?.expiryDate).format("DD-MM-YYYY")}{" "}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2">
                          <p className="mb-1">State:</p>
                          <h5 className="font-size-14">
                            {" "}
                            {jobList?.state
                              ? jobList.state?.map(el => el?.name).join(", ")
                              : ""}{" "}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2">
                          <p className="mb-1">Districts:</p>
                          <h5 className="font-size-14">
                            {" "}
                            {jobList?.location
                              ? jobList.location?.map(el => el?.name).join(", ")
                              : ""}{" "}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        {/* <div className="mt-2">
                          <p className="mb-1">Fee:</p>
                          <h5 className="font-size-14">{jobList?.fee}</h5>
                        </div> */}
                        {jobList?.type === 0 ? (
<>
                      
                        <div className="mt-2">
                          <p className="mb-1">Stipend:</p>
                          <h5 className="font-size-14">{jobList?.stipend}</h5>
                        </div>
</>
                        ):null}
                        
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2">
                          <p className="mb-1">Duration:</p>
                          <h5 className="font-size-14">
                            {jobList?.duration +
                              " " +
                              (jobList?.unit === 1
                                ? "Days"
                                : jobList?.unit === 2
                                ? "Weeks"
                                : jobList?.unit === 3
                                ? "Months"
                                : "Years")}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2">
                          <p className="mb-1">Eligibility:</p>
                          <h5 className="font-size-14">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: jobList?.eligibility?.contents,
                              }}
                            ></div>
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div className="mt-2">
                          <p className="mb-1">Overview:</p>
                          <h5 className="font-size-14">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: jobList?.overview?.contents,
                              }}
                            ></div>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-9" style={{ marginBottom: "30px" }}>
                    <div
                      className="mb-0 card"
                      style={{
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={tab3}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              indicatorColor="primary"
                              textColor="primary"
                              variant="fullWidth"
                              onChange={handleChangeTab}
                              aria-label="lab API tabs example"
                            >
                              <Tab
                                icon={
                                  <SummarizeIcon style={{ fontSize: "20px" }} />
                                }
                                label="Summary"
                                value="5"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <AssignmentIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Applied"
                                value="1"
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              {/* <Tab
                                icon={
                                  <BookmarkIcon style={{ fontSize: "20px" }} />
                                }
                                label="Saved"
                                value="2"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              /> */}
                              <Tab
                                icon={
                                  <FactCheckIcon style={{ fontSize: "20px" }} />
                                }
                                label="Shortlisted"
                                value="3"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <BallotIcon style={{ fontSize: "20px" }} />
                                }
                                label="Placed"
                                value="4"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                            </TabList>
                          </Box>
                          <TabPanel
                            value="1"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Applied />
                          </TabPanel>
                          <TabPanel
                            value="2"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Saved />
                          </TabPanel>
                          <TabPanel
                            value="3"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Shortlisted />
                          </TabPanel>
                          <TabPanel
                            value="4"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Placed />
                          </TabPanel>
                          <TabPanel
                            value="5"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            {/* <Row>
                              <MiniWidget reports={reports} />
                            </Row> */}
                            <Summary />
                          </TabPanel>
                        </TabContext>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ViewJob
