/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useTransition } from "react"
import { AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Collapse,
  Modal,
} from "reactstrap"
import Select from "react-select"
import classnames from "classnames"
import { get } from "../../../../helpers/api_helper"
import moment from "moment"
import toastr from "toastr"
import "./followup.scss"
import { Tooltip } from "@mui/material"
import Followup from "../Add-Lead/Followup"
import ScholarshipProcessing from "../../../Finance/Scholarship/Processing"
import SkillLoanProcessing from "../../../Finance/SkillLoan/Processing"
import { isArray } from "lodash"
import { useDispatch } from "react-redux"
import { getCourses, getUsersOptions } from "../../../../store/options/actions"
import { PRIVILEGES } from "../../../../config"
import useBranch from "../../../../hooks/useBranch"
import { useSelector } from "react-redux"
import { getLeadFor } from "../Add-Lead/backendHelper"
import qs from "query-string"
import { useLocation } from "react-router-dom"
import DataTable from "../../../../components/Common/DataTable"
import { Tooltip as TooltipMui } from "@mui/material"
import { jsonToExcel } from "../../../../helpers/functions"
import Admin from "../../../../components/Common/Admin"

function FollowUp() {
  const [isUpcomingPending, startUpcoming] = useTransition()
  const [isPendingFollowup, startPendingFollowup] = useTransition()

  const { state } = useLocation()

  const initialSearch = {
    course: state?.filterData?.course?._id ?? null,
    event: state?.filterData?.event?._id ?? null,
    from_date: state?.filterData?.from_date ?? null,
    to_date: state?.filterData?.to_date ?? null,
  }

  const [todayFollowups, setTodayFollowups] = useState([])

  const [upcomingFollowups, setUpcomingFollowups] = useState([])

  const [pendingFollowups, setPendingFollowups] = useState([])

  const [followup, setFollowup] = useState({ modal: false, studentId: null })

  const [branch] = useBranch()

  const [scholarship, setScholarship] = useState({ show: false })

  const [leadFor, setLeadFor] = useState([])

  const [search, setSearch] = useState(initialSearch)

  const [searchSelect, setSearchSelect] = useState({
    course: state?.filterData?.course ?? null,
    event: state?.filterData?.event ?? null,
  })

  const [skilloan, setSkilloan] = useState({ show: false })

  const [col1, setcol1] = useState(
    state?.type === "today" || state?.type === undefined
  )
  const [todayPage, setTodayPage] = useState(1)
  const [todayCount, setTodayCount] = useState(0)
  const [todayLimit, setTodayLimit] = useState(20)
  const [totalEntries, setTotalEntries] = useState(0)

  const [col2, setcol2] = useState(state?.type === "pending")
  const [pendingPage, setPendingPage] = useState(1)
  const [pendingCount, setPendingCount] = useState(0)
  const [pendingLimit, setPendingLimit] = useState(20)

  const [col3, setcol3] = useState(state?.type === "upcoming")
  const [upcomingPage, setUpcomingPage] = useState(1)
  const [upcomingCount, setUpcomingCount] = useState(0)
  const [upcomingLimit, setUpcomingLimit] = useState(20)

  const { allotted } = useSelector(state => state.Profile)

  const [reload, setReload] = useState(false)

  const OPTIONS = useSelector(state => state.Options)

  const [eventOptions, setEventOptions] = useState([])

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCourses())
    get("/options/events").then(res => setEventOptions(res.data))
  }, [dispatch])

  useEffect(() => {
    dispatch(
      getUsersOptions({
        branch: branch?.value || "",
        privilege: PRIVILEGES.INTERN,
      })
    )
  }, [dispatch, branch])

  useEffect(() => {
    getFollowups("today", search, todayPage, todayLimit).then(
      ({ data, count }) => {
        setTodayCount(count)
        setTodayFollowups(data)
      }
    )
    // eslint-disable-next-line
  }, [reload, search, todayPage, todayLimit])

  useEffect(() => {
    startPendingFollowup(() => {
      getFollowups("pending", search, pendingPage, pendingLimit).then(
        ({ data, count }) => {
          setPendingCount(count)
          setPendingFollowups(data)
        }
      )
    })
    // eslint-disable-next-line
  }, [reload, search, pendingPage, pendingLimit])

  useEffect(() => {
    startUpcoming(() => {
      getFollowups("upcoming", search, upcomingPage, upcomingLimit).then(
        ({ data, count }) => {
          setUpcomingCount(count)
          setUpcomingFollowups(data)
        }
      )
    })
    // eslint-disable-next-line
  }, [reload, search, upcomingPage, upcomingLimit])

  // useEffect(() => {
  // }, [reload])

  // useEffect(() => {
  // }, [reload])

  const getFollowups = async (type = "today", query, page, limit) => {
    return await get(
      `leads/followup/${type}/list?${qs.stringify(
        query
      )}&page=${page}&limit=${limit}`
    ).then(res => {
      const data = res.data
      const count = res.count
      setTotalEntries(count)
      data.map((item, idx) => {
        // item.id = idx + 1
        setIndex(item, idx, type)

        item.date =
          item.next_followup && moment(item.next_followup).format("DD-MM-YYYY")

        const targetDate = moment(item.next_followup, "YYYY-MM-DD")

        const pendingDays = moment().diff(targetDate, "days")

        item.pending = `${pendingDays} Days`

        item.name = `${item?.profile?.firstname || ""} ${
          item?.profile?.lastname || ""
        }`

        item.type = item?.leads?.type

        item.course = item?.leads?.course?.name || item?.leads?.events?.name

        item.mobile = item?.profile?.mobile

        item.reg_no = item?.profile?.reg_id

        item.assignedUser = `${item?.leads?.assigned?.firstName || ""}`

        item.followupStatus = (
          <div className="text-center">
            <span
              style={{
                background: item?.followup_status?.color,
              }}
              className="badge font-size-12 ms-2"
            >
              {item?.followup_status?.name}
            </span>
          </div>
        )

        item.action = (
          <div>
            <Tooltip title="Followup" arrow placement="top">
              <i
                className="fas fa-eye me-2"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                }}
                onClick={() => {
                  // getLeadSingle(item._id)
                  setFollowup({
                    modal: true,
                    studentId: item?.profile?._id,
                    leadId: item.leadId,
                  })
                }}
              ></i>
            </Tooltip>

            {item?.leads?.skillLoan?.requested &&
              item?.leads?.skillLoan?.requestedId && (
                <Tooltip title="Skill loan" arrow placement="top">
                  <i
                    onClick={() =>
                      setSkilloan({
                        show: true,
                        id: item?.leads?.skillLoan?.requestedId,
                      })
                    }
                    className="fa fa-university me-2"
                    style={{
                      fontSize: "1em",
                      cursor: "pointer",
                    }}
                  ></i>
                </Tooltip>
              )}

            {item?.leads?.scholarship?.requested &&
              item?.leads?.scholarship?.requestedId && (
                <Tooltip title="Scholarship" arrow placement="top">
                  <i
                    onClick={() =>
                      setScholarship({
                        show: true,
                        id: item?.leads?.scholarship?.requestedId,
                      })
                    }
                    className="fa fa-graduation-cap me-2"
                    style={{
                      fontSize: "1em",
                      cursor: "pointer",
                    }}
                  ></i>
                </Tooltip>
              )}
          </div>
        )

        return item
      })

      return { data, count }
    })
  }

  //Handle Export Data

  const typeMap = {
    today: "Today Followups",
    pending: "Pending Followups",
    upcoming: "Upcoming Followups",
  }

  const handleExportData = async (type = "today", query) => {
    try {
      let res = await get(
        `leads/followup/${type}/list?${qs.stringify(
          query
        )}&limit=${totalEntries}`
      )
      const data = res?.data
      const exportData = []

      if (data && Array.isArray(data) && data.length > 0) {
        data.map((item, index) => {
          const dataExport = {}

          dataExport["SI NO"] = index + 1
          dataExport.Date =
            item.next_followup &&
            moment(item.next_followup).format("DD-MM-YYYY")
          if (type == "pending") {
            const targetDate = moment(item?.next_followup, "YYYY-MM-DD")
            const pendingDays = moment().diff(targetDate, "days")
            dataExport.Pending = `${pendingDays} Days`
          }
          dataExport.Type = item?.leads?.type
          dataExport["Reg No"] = item?.profile?.reg_id
          dataExport.Name = `${item?.profile?.firstname || ""} ${
            item?.profile?.lastname || ""
          }`
          dataExport.Mobile = item?.profile?.mobile
          dataExport["Course/Event"] =
            item?.leads?.course?.name || item?.leads?.events?.name
          dataExport.Source = item?.source
          dataExport.Status = item?.followup_status?.name
          dataExport.Comment = item?.comments
          dataExport.Assigned = `${item?.leads?.assigned?.firstName || ""}`
          exportData.push(dataExport)
        })

        jsonToExcel(
          exportData,
          `${typeMap[type]}-list-${moment().format("DD-MM-YY")}`
        )
      } else {
        toastr.info(`There are No ${typeMap[type]} to export`)
      }
    } catch (error) {}
  }

  function togglecol1() {
    setcol1(!col1)
  }

  function togglecol2() {
    setcol2(!col2)
  }

  function togglecol3() {
    setcol3(!col3)
  }

  // const handleSearchChange = e => {
  //   let { name, value } = e.target
  //   setSearch(pre => ({ ...pre, [name]: value }))
  // }

  const handleSearchSelect = (e, name) => {
    const value = e ? (isArray(e) ? e.map(item => item.value) : e.value) : null
    setSearchSelect(pre => ({ ...pre, [name]: e }))
    setSearch(pre => ({ ...pre, [name]: value }))
    setTodayPage(1)
    setPendingPage(1)
    setUpcomingPage(1)
  }

  useEffect(() => {
    getLeadFor().then(res => {
      setLeadFor(res.data)
    })
  }, [])

  const setIndex = (item, index, type) => {
    if (type === "today") item.id = (todayPage - 1) * todayLimit + index + 1
    else if (type === "pending")
      item.id = (pendingPage - 1) * pendingLimit + index + 1
    else if (type === "upcoming")
      item.id = (upcomingPage - 1) * upcomingLimit + index + 1
  }

  const columns = [
    { label: "#", field: "id" },
    { label: "Date", field: "date" },
    { label: "Type", field: "type" },
    { label: "Reg No", field: "reg_no" },
    { label: "Name", field: "name" },
    { label: "Mobile", field: "mobile" },
    { label: "Course/Event", field: "course" },
    { label: "Source", field: "source" },
    { label: "Status", field: "followupStatus" },
    { label: "Comment", field: "comments" },
    { label: "Assigned", field: "assignedUser" },
    { label: "Actions", field: "action" },
  ]

  const todayFollowup = { columns, rows: todayFollowups }

  const upcomingFollowup = { columns, rows: upcomingFollowups }

  const pendingFollowup = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Pending", field: "pending" },
      { label: "Type", field: "type" },
      { label: "Reg No", field: "reg_no" },
      { label: "Name", field: "name" },
      { label: "Mobile", field: "mobile" },
      { label: "Course/Event", field: "course" },
      { label: "Source", field: "source" },
      { label: "Status", field: "followupStatus" },
      { label: "Comment", field: "comments" },
      { label: "Assigned", field: "assignedUser" },
      { label: "Actions", field: "action" },
    ],
    rows: pendingFollowups,
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Follow Up" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Course</Label>
                          <Select
                            isClearable
                            onChange={e => {
                              handleSearchSelect(e, "course")
                            }}
                            value={searchSelect.course || null}
                            options={OPTIONS.courses}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Events</Label>
                          <Select
                            isClearable
                            onChange={e => {
                              handleSearchSelect(e, "event")
                            }}
                            value={searchSelect.event || null}
                            options={eventOptions}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Source</Label>
                          <Select
                            isClearable
                            onChange={e => {
                              handleSearchSelect(e, "source")
                            }}
                            value={searchSelect.source || null}
                            options={leadFor}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Staff</Label>
                          <Select
                            isClearable
                            options={OPTIONS.users}
                            onChange={e => {
                              handleSearchSelect(e, "user")
                            }}
                            value={searchSelect.user || null}
                          />
                        </div>
                      </Col>

                      <Admin>
                        <Col md="2">
                          <div className="mb-3">
                            <Label>CSP</Label>
                            <Select
                              isClearable
                              name="csp"
                              options={allotted.branches}
                              type="text"
                              onChange={e => {
                                handleSearchSelect(e, "branch")
                              }}
                              value={searchSelect.branch || null}
                            />
                          </div>
                        </Col>
                      </Admin>

                      <Col className="mt-4 pt-1">
                        <div className="mb-3">
                          <Button
                            color="danger"
                            type="button"
                            onClick={() => {
                              setSearch({})
                              setSearchSelect({})
                            }}
                          >
                            Reset
                          </Button>
                          {/* <Button
                            style={{ marginLeft: "14px" }}
                            color="warning"
                            type="button"
                            // onClick={handleExport}
                          >
                            Export
                          </Button> */}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>

                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className={classnames(
                            "accordion-button",
                            "fw-medium",
                            { collapsed: !col1 }
                          )}
                          type="button"
                          onClick={togglecol1}
                          style={{ cursor: "pointer" }}
                        >
                          Today Followups
                        </button>
                      </h2>
                      <Collapse
                        id="collapseOne"
                        className="accordion-collapse"
                        isOpen={col1}
                      >
                        <div className="accordion-body ">
                          <div className="p-2 pt-0 d-flex justify-content-end me-1 absolute top-10 ">
                            <TooltipMui
                              title={"Export"}
                              arrow
                              placement="top"
                              id="button-tooltip-2"
                            >
                              <i
                                onClick={() =>
                                  handleExportData("today", search)
                                }
                                className="cursor-pointer fs-4 fas fa-file-export"
                              ></i>
                            </TooltipMui>
                          </div>
                          <DataTable
                            data={todayFollowup}
                            count={todayCount}
                            page={todayPage}
                            limit={todayLimit}
                            onPageChange={e => setTodayPage(e)}
                            onLimitChange={e => setTodayLimit(e)}
                            id="followupTable"
                          />
                        </div>
                      </Collapse>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className={classnames(
                            "accordion-button",
                            "fw-medium",
                            { collapsed: !col2 }
                          )}
                          type="button"
                          onClick={togglecol2}
                          style={{ cursor: "pointer" }}
                        >
                          Pending Followups
                        </button>
                      </h2>
                      <Collapse
                        id="collapseTwo"
                        className="accordion-collapse"
                        isOpen={col2}
                      >
                        <div className="accordion-body">
                          <div className="p-2 pt-0 d-flex justify-content-end me-1 absolute top-10 ">
                            <TooltipMui
                              title={"Export"}
                              arrow
                              placement="top"
                              id="button-tooltip-2"
                            >
                              <i
                                onClick={() =>
                                  handleExportData("pending", search)
                                }
                                className="cursor-pointer fs-4 fas fa-file-export"
                              ></i>
                            </TooltipMui>
                          </div>
                          <DataTable
                            data={pendingFollowup}
                            count={pendingCount}
                            page={pendingPage}
                            limit={pendingLimit}
                            onPageChange={e => setPendingPage(e)}
                            onLimitChange={e => setPendingLimit(e)}
                            id="followupTable"
                          />
                        </div>
                      </Collapse>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className={classnames(
                            "accordion-button",
                            "fw-medium",
                            { collapsed: !col3 }
                          )}
                          type="button"
                          onClick={togglecol3}
                          style={{ cursor: "pointer" }}
                        >
                          Upcoming Followups
                        </button>
                      </h2>
                      <Collapse
                        id="collapseThree"
                        className="accordion-collapse"
                        isOpen={col3}
                      >
                        <div className="accordion-body">
                          <div className="p-2 pt-0 d-flex justify-content-end me-1 absolute top-10 ">
                            <TooltipMui
                              title={"Export"}
                              arrow
                              placement="top"
                              id="button-tooltip-2"
                            >
                              <i
                                onClick={() =>
                                  handleExportData("upcoming", search)
                                }
                                className="cursor-pointer fs-4 fas fa-file-export"
                              ></i>
                            </TooltipMui>
                          </div>
                          <DataTable
                            data={upcomingFollowup}
                            count={upcomingCount}
                            page={upcomingPage}
                            limit={upcomingLimit}
                            onPageChange={e => setUpcomingPage(e)}
                            onLimitChange={e => setUpcomingLimit(e)}
                            id="followupTable"
                          />
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      {followup.modal && (
        <Modal
          show={followup.modal}
          isOpen={followup.modal}
          toggle={() => {
            setReload(pre => !pre)
            setFollowup({ modal: false })
          }}
          size="xl"
          centered={true}
          id="leadModal"
          className="lead-modal"
        >
          <div className="modal-header">
            <div className="modal-title">
              <h6 style={{ marginBottom: "0px" }} className="modal-title mt-0">
                {/* #{leadData.unique_id} */}
              </h6>
            </div>
            <button
              style={{ marginBottom: "2px" }}
              type="button"
              onClick={() => {
                setReload(pre => !pre)
                setFollowup({ modal: false })
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>

            <Followup followup={followup} />
          </div>
        </Modal>
      )}

      {/* Scholarship  */}
      {scholarship.show && (
        <Modal
          show={scholarship.show}
          isOpen={scholarship.show}
          size="xl"
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Scholarship Details</h5>
            <button
              type="button"
              onClick={() => {
                setScholarship({ show: false })
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <ScholarshipProcessing id={scholarship?.id} />
          </div>
        </Modal>
      )}
      {/* Skill Loan  */}
      {skilloan.show && (
        <Modal show={skilloan.show} isOpen={skilloan.show} size="xl" centered>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Skill Loan Details</h5>
            <button
              type="button"
              onClick={() => {
                setSkilloan({ show: false })
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <SkillLoanProcessing id={skilloan?.id} />
          </div>
        </Modal>
      )}
    </>
  )
}

export default FollowUp
