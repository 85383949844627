import React, { useEffect, useRef, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label, Input } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap"



import _ from "lodash"

import { useDispatch } from "react-redux"
import { isAdmin } from "../../../helpers/functions"
import { useNavigate } from "react-router-dom"
import SunEditor from "suneditor-react"
import plugins from "suneditor/src/plugins"
import { en } from "suneditor/src/lang"
import { EditorView } from "codemirror"
import katex from "katex"
import "suneditor/dist/css/suneditor.min.css"
import "katex/dist/katex.min.css"
import "./style.scss"
import toastr from "toastr"
import { del, get, post, put } from "../../../helpers/api_helper"
import Swal from "sweetalert2"
import {
  getCluster,
  getSectors,
  getQualification,
} from "../../../store/options/actions"
import { useSelector } from "react-redux"
import { isArray } from "lodash"
import moment from "moment"
import { API_URL, PRIVILEGES } from "../../../config"
import axios from "axios"
import SelectErrorMessage from "../../../components/Common/SelectErrorMessage"
import { isRecruiter } from "../../../helpers/functions"
import MuiCustomTablePagination from "../../../components/Common/TablePagination"
import { isAllowed } from "../../../components/Common/Allowed"
const Create = props => {
  const recruiter = isRecruiter()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [selected, setSelected] = useState({
    state: [
      {
        _id: 19,
        name: "Kerala",
        label: "Kerala",
        value: 19,
      },
    ],
  })
  const [search, setSearch] = useState('')
  const [searchSelect, setSearchSelect] = useState({})
  const initialstate = {
    name: "",
    stipend: "",
    duration: "",
    eligibility: "",
    fee: "",
    description: "",
    details: [{}],
    recruiter: "",
    status: "",
    recruiter1: "",
    unit: 3,
    expiryDate:"",
    state:[19],
    recruiter: recruiter.status ? recruiter.id : "",
  }
  const fieldTypeOptions = [
    { label: "Intership Description", value: "1" },
    { label: "Eligibility", value: "2" },
  ]
  const [master, setmaster] = useState(initialstate)
  const [error, setError] = useState({})
  const [recruitersoptions, setRecruitersoptions] = useState([])
  const [internshipoptions, setInternshipoptions] = useState([])
  const [selectOptions, setSelectOptions] = useState([...fieldTypeOptions])
  const [selectedrecruiter, setSelectedrecruiter] = useState(null)
  const [jobDistricts, setjobDistricts] = useState([])
  const [tableData, setTableData] = useState([])
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(20)
  const [count, setCount] = useState(0)
  const formRef = useRef()
  const { sectors, qualification, districts ,states} = useSelector(
    state => state.Options
  )
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  const allowed = isAllowed([
    PRIVILEGES.ADMIN,
    PRIVILEGES.CSP_PROGRAM_MANAGER,
    PRIVILEGES.RECRUITER_PM
  ])

  useEffect(() => {
    dispatch(getSectors())
    dispatch(getCluster())
    dispatch(getQualification())
    // dispatch(getDistrictOptions())
    // eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    fetchAllRecruiters()
  }, [])
  
  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [searchSelect,page,pageSize,search])

  function fetchAllRecruiters() {
    get(`/options/recruiters`).then(res => {
      let result = res?.data
      setRecruitersoptions(result)
    })
  }

  function fetchDistricts(s) {
    if (s?.length === 0) {
      setjobDistricts([])
      return
    }
    const stateString = s.map(item=>item.value).join(',')
    get(`/job/options/district?state=${stateString}`).then(res => {
      let result = res.data
      setjobDistricts(result)
    })
  }

  useEffect(()=>{
      fetchDistricts(selected?.state)
  },[selected.state])

  const options = {
    plugins: plugins,
    height: 250,
    EditorView: {
      src: EditorView,
      options: {
        indentWithTabs: true,
        tabSize: 2,
      },
    },
    katex: katex,
    lang: en,
    buttonList: [
      [
        "font",
        "fontSize",
        "formatBlock",
        "bold",
        "underline",
        "italic",
        "paragraphStyle",
        "blockquote",
        "strike",
        "subscript",
        "superscript",
        "fontColor",
        "hiliteColor",
        "textStyle",
        "removeFormat",
        "undo",
        "redo",
        "outdent",
        "indent",
        "align",
        "horizontalRule",
        "list",
        "lineHeight",
        "table",
        "link",
        // 'image',
        // 'video',
        // 'audio',
        // You must add the 'katex' library at options to use the 'math' plugin.
        // 'math',
        // You must add the "imageGalleryUrl".
        // 'imageGallery',
        "fullScreen",
        "showBlocks",
        "codeView",
        "preview",
        // 'print'
        // 'save',
        // 'template'
      ],
    ],
  }
  const handleStatusChange = async (requestId, approvedStatus) => {
    put("/internship/status", { _id: requestId, status: approvedStatus })
      .then(res => {
        handleTableData()
        toastr.success(res.message)
      })
      .catch(err => {
        toastr.error(err.response.data.message)
      })
  }

  const status = [
    { label: "Ongoing", value: 0 },
    { label: "Inactive", value: 2 },
    { label: "Expired", value: 3 },
    { label: "Filled", value: 4 },
  ]
  function handleTableData() {
    const SearchQuery = `status=${searchSelect?.status?.value}&recruiter=${searchSelect?.recruiter1?.value}&search=${search}&page=${page}&limit=${pageSize}`
    get(`/internship/basic?${SearchQuery}`).then(res => {
      setCount(res.count)
      let result = res.data
      result?.map((item, idx) => {
        item.id = idx + 1 + (page * pageSize)
        item.date = moment(item?.date).format("DD-MM-YYYY")
        item.qualification = item?.qualification
          ? item.qualification?.map(el => el?.name).join(", ")
          : ""

          item.exdate = item?.expiryDate
          ? moment(item?.expiryDate).format("DD-MM-YYYY")
          : ""
        item.recruiter = item?.recruiter?.name
        item.added = item.addedBy?.name
        item.status = (
          <div className="mb-3">
            <Select
              menuPosition="fixed"
              name="job_status"
              placeholder="Ongoing"
              onChange={selected => {
                handleStatusChange(item?._id, selected.value)
                // setselectedstatus(selected.value)
              }}
              value={status?.filter(status => status.value === item.status)}
              options={status}
              classNamePrefix="select2-selection"
            />
          </div>
        )
        item.options = (
          <div style={{ display: "flex", justifyContent: "center",alignItems:'center' }}>
                        <span
              style={{ 
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                width: "100px",
              }}
            >
             {allowed && item.approve && (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="copy-event-tooltip">
                      {item.approve === 2 ? "Unapprove" : "Approve"}
                    </Tooltip>
                  }
                >
                  <Input
                    onClick={() => handleApprove(item._id, item.approve)}
                    checked={item.approve === 2}
                    type="checkbox"
                    style={{ marginRight: "0.5rem" }}
                  />
                </OverlayTrigger>
              )}
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                navigate(`/create-internship-post/${item.uniqueId}`, {
                  state: { id: item._id },
                })
              }}
            ></i>
            <i
              className="far fa-edit"
              onClick={() => {
                handleUpdate(item._id)
                toTop()
              }}
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2rem",
                marginRight: "0.5rem",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ fontSize: "1em", cursor: "pointer" }}
              onClick={() => {
                handleDelete(item?._id)
              }}
            ></i>
            </span>
          </div> 
        )
        item.type = item.type === 0 ? "Paid" : "Unpaid"
      })
      setTableData(result)
    })
  }

  const handleApprove = (id,approve)=> {
    let value
    if(approve===1){
      value = 2
    }else if(approve === 2){
      value = 1
    }
    post(`internship/approve`,{internship:id,approve:value}).then(res=>{
      if(res.success){
        toastr.success(res.message)
        handleTableData()
      }
    }).catch(err=>{
      toastr.error(err.response.data.message)
    })
  }

  const reset = () => {
    formRef.current.reset()

    setmaster({ recruiter1: "", status: "" })
    setSelected({ recruiter1: "", status: "" })
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "ID",
        field: "uniqueId",
        width: 150,
      },
      {
        label: "Recruiter",
        field: "recruiter",
        width: 150,
      },
      {
        label: "Internship Name",
        field: "name",
        width: 150,
      },
      // {
      //   label: "Recruiter",
      //   field: "recruiter",
      //   width: 150,
      // },
      // {
      //   label: "Qualificaion",
      //   field: "qualification",
      //   width: 150,
      // },
      {
        label: "Type",
        field: "type",
        width: 150,
      },
      {
        label: "Expiry",
        field: "exdate",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        width: 150,
      },
      {
        label: "Added By",
        field: "added",
        width: 150,
      },
      {
        label: "Action",
        field: "options",
        width: 150,
      },
    ],
    rows: tableData,
  }

  function handleSubmit() {
    let flag = false

    if (!master.image) {
      // Assuming 'master.image' represents the selected file
      flag = true;
      setError(prevState => ({ ...prevState, image: true }));

    }

    if (selectedrecruiter == null && !isRecruiter().status) {
      flag = true
      setError(pre => ({ ...pre, recruiter: true }))
    }

    // if(selected?.unit==null){
    //   flag=true
    //   setError(pre => ({ ...pre, unit: true }))
    // }

    if (selected?.type == null) {
      flag = true
      setError(pre => ({ ...pre, type: true }))
    }

    if (flag) {
      return
    }
    if (master._id) {
      put(`/internship/basic`, master)
        .then(res => {
          toastr.success(res.message)
          handleTableData()
          handleReset()
          formRef.current.reset()
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    } else {
      post(`/internship/basic`, master)
        .then(res => {
          toastr.success(res.message)
          handleTableData()
          handleReset()
          formRef.current.reset()
        })
        .catch(err => {
          // formRef.current.reset()
          toastr.error(err.response.data.message)
        })
    }
  }
  function handleUpdate(id) {
    get(`/internship?id=${id}`).then(res => {
      let data = res.data
      if (data?.details && data?.details?.length) {
        hanldeOptionsData(data)
      }
      setSelectedrecruiter({
        label: data?.recruiter?.name,
        value: data?.recruiter?._id,
      })
      setSelected({
        recruiter: { label: data?.recruiter?.name, value: data?.recruiter._id },
        sector: { label: data?.sector?.name, value: data?.sector?._id },
        // location: { label: data?.location?.name, value: data?.location?._id },
        location: data.location
          ? data?.location?.map(item => ({
            label: item.name,
            value: item._id,
            ...item,
          }))
          : [],
          state: data.state
          ? data?.state?.map(item => ({
              label: item.name,
              value: item._id,
              ...item,
            }))
          : [],
        qualification: data.qualification
          ? data?.qualification?.map(item => ({
            label: item.name,
            value: item._id,
            ...item,
          }))
          : [],
        type: {
          label: data.type === 0 ? "paid" : "Unpaid" || null,
          value: data.type || null,
        },
        unit: {
          label:
            data.unit === 0
              ? "days"
              : data.unit === 1
                ? "Weeks"
                : data.unit === 0
                  ? "Months"
                  : "Years" || null,
          value: data.unit || null,
        },
      })
      setmaster(data)
    })
  }
  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`/internship/basic?id=${id}`)
          .then(res => {
            toastr.success(res.message)
            handleTableData()
            formRef.current.reset()
          })
          .catch(err => {
            toastr.success(err.response.data.message)
          })
      }
    })
  }

  const handleSelect = (e, name) => {
    const obj = { ...selected }
    const masterObj = { ...master }

    const value = e ? (isArray(e) ? e.map(item => item.value) : e.value) : null
    
    if (name === "state" && (e?.length < obj.state?.length || e?.length < 1)) {
      const removedState = _.difference(obj[name], e)
      masterObj.location = []

      obj.location = obj.location?.filter(el => {
        if (el.stateId !== removedState[0].value) {
          masterObj.location.push(el.value)
        }

        return el.stateId !== removedState[0].value
      })

 
    }
    obj[name] = e
    masterObj[name] = value

    setSelected(obj)
    setmaster(masterObj)
  }
  function handlevaluechange(value, name) {
    const obj = { ...master }
    obj[name] = value
    setmaster(obj)
  }

  const onChangeSuneditor = content => {
    setmaster(prevState => ({
      ...prevState,
      description: content, // Update description property with the new content
    }))
  }

  function handleReset() {
    formRef.current.reset()
    toTop()
    setmaster(initialstate)
    // setmaster({description:""})
    setSelectOptions([...fieldTypeOptions])
    setSelectedrecruiter(null)
    setSelected({
      recruiter: null,
      sector: null,
      qualification: null,
      type: null,
      location: null,
      state: {
        _id: 19,
        name: "Kerala",
        label: "Kerala",
        value: 19,
      },
    })
    setError({})
  }

  const onChangeSuneditor1 = (content, idx) => {
    setmaster(prevState => {
      const updatedMasterobj = { ...prevState }
      const updatedDetails = [...updatedMasterobj.details]
      updatedDetails[idx] = { ...updatedDetails[idx], contents: content }
      updatedMasterobj.details = updatedDetails
      return updatedMasterobj
    })
  }
  const handlePreValueChange = (value, name, index) => {
    const data = { ...master }
    const pre = data?.details
    pre[index][name] = value
    data.details = pre
    setmaster(data)
    hanldeOptionsData(data)
  }
  const hanldeOptionsData = master => {
    let options = [...fieldTypeOptions]

    master.details?.map(item => {
      if (item.tab) {
        options = options.filter(option => {
          return option.value !== "" + item.tab
        })
      }
      return item
    })

    setSelectOptions(options)
  }

  const admin = isAdmin()
  useEffect(() => {
    if (!admin) {
      const user = JSON.parse(localStorage.getItem("authUser"))
      setmaster(prev => ({ ...prev, recruiter: user?.company[0] }))
    }
  }, [])
  const handleSearchSelect = (e, name) => {
    setSearchSelect(pre => ({ ...pre, [name]: e }))
    setPage(0)
  }
  const uploadBanner = e => {
    const file = e.target.files[0]
    const name = e.target.name

    if (!file) {
      toastr.error("Please select an image file.")
      return
    }

    // Check if file is an image
    if (!file.type.startsWith("image")) {
      toastr.error("Please choose an image file.")
      e.target.value = ""
      return
    }

    // Create image object to get dimensions
    const img = new Image()
    img.src = URL.createObjectURL(file)

    img.onload = () => {
      if (!(img.width >= 1100 && img.height >= 640)) {
        toastr.error("Image dimensions should be at least 1100x640 pixels")
        e.target.value = ""
        return
      }

      // Check file size
      if (file.size > 300 * 1024 || file.size < 100 * 1024) {
        toastr.error("Image size should be between 150KB and 200KB")
        e.target.value = ""
        return
      }

      // Image is valid, proceed with upload
      const fd = new FormData()
      fd.append(name, file)
      axios
        .post(`${API_URL}internship/image`, fd)
        .then(response => {
          if (response.data.status === 200) {
            setmaster({
              ...master,
              image: response.data.data.new_filename,
            })
          } else {
            toastr.error(response.data.message)
          }
        })
        .catch(error => {
          console.error("Error uploading image:", error)
        })
    }
  }
  function handleSelectChange(selected, name) {
    switch (name) {
      case "recruiter":
        setSelectedrecruiter(selected)
        setmaster({ ...master, recruiter: selected.value })
        setError({ ...error, recruiter: false })
        break

      default:
        break
    }
  }

  const handleChangeRowsPerPage = event => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handlePageChange = newPage => {
    setPage(newPage)
  }


  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Create Internship" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm onValidSubmit={handleSubmit} ref={formRef}>
                    <Row>
                      {!isRecruiter().status ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Recruiters</Label>
                            <Select
                              name="recruiter"
                              classNamePrefix="select2-selection"
                              options={recruitersoptions}
                              className="recruiterzindex"
                              value={selectedrecruiter}
                              onChange={e => {
                                // setSelectedrecruiter(e)
                                handleSelectChange(e, "recruiter")
                                // setSelectedAssign(e)
                              }}
                            />
                            <SelectErrorMessage
                              show={error?.recruiter}
                              customStyle={{
                                width: "100%",
                                fontSize: "87.5%",
                                color: "var(--bs-form-invalid-color)",
                              }}
                              message="Please select a Recruiter"
                            />
                          </div>
                        </Col>
                      ) : null}
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Internship Title</Label>
                          <AvField
                            name="name"
                            className="form-control"
                            type="text"
                            value={master.name}
                            onChange={e => {
                              handlevaluechange(e.target.value, "name")
                            }}
                            placeholder="Internship Title"
                            errorMessage="Enter Internship Title"
                            validate={{ required: { value: true } }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Sector</Label>
                          <Select
                            // isMulti
                            name="sector"
                            className="sectorrzindex"
                            classNamePrefix="select2-selection"
                            options={sectors}
                            value={selected.sector}
                            onChange={selected => {
                              handleSelect(selected, "sector")
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Qualification</Label>
                          <Select
                            name="qualification"
                            isMulti
                            className="qualificationzindex"
                            value={selected.qualification}
                            onChange={selected => {
                              handleSelect(selected, "qualification")
                            }}
                            classNamePrefix="select2-selection"
                            options={qualification}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Type</Label>
                          <Select
                            name="type"
                            className="typzindex"
                            value={selected.type}
                            onChange={selected => {
                              setmaster({ ...master, stipend: "" })
                              handleSelect(selected, "type")
                              setError(pre => ({ ...pre, type: false }))
                            }}
                            classNamePrefix="select2-selection"
                            options={[
                              { label: "Paid", value: 0 },
                              { label: "Unpaid", value: 1 },
                            ]}
                          />
                          <SelectErrorMessage
                            show={error?.type}
                            customStyle={{
                              width: "100%",
                              fontSize: "87.5%",
                              color: "var(--bs-form-invalid-color)",
                            }}
                            message="Please Select a Type"
                          />
                        </div>
                      </Col>
                      {/* <Col md="2">
                        <div className="mb-3">
                          <Label>Fee</Label>
                          <AvField
                            name="range_to"
                            className="form-control"
                            type="number"
                            value={master.fee}
                            validate={{
                              isPercentage: value => {
                                // Check if it's a percentage field
                                const percentage = parseFloat(value)
                                if (value !== "") {
                                  // If a value is entered, validate it as a percentage
                                  return !isNaN(percentage) && percentage >= 0
                                }
                                return true // If no value is entered, no validation needed
                              },
                            }}
                            errorMessage="Enter a valid value"
                            onChange={e => {
                              handlevaluechange(e.target.value, "fee")
                            }}
                            min={0}
                            placeholder="Fee"
                          />
                        </div>
                      </Col> */}
                      {(selected.type?.value === 0 || master?.type === 0) && (
                        <Col md="2">
                          <div className="mb-3">
                            <Label>Stipend</Label>
                            <AvField
                              name="stipend"
                              className="form-control"
                              type="text"
                              value={master.stipend}
                              required
                              validate={{
                                // isPercentage: value => {
                                //   // Check if it's a percentage field
                                //   const percentage = parseFloat(value)
                                //   if (value !== "") {
                                //     // If a value is entered, validate it as a percentage
                                //     return !isNaN(percentage) && percentage >= 0
                                //   }
                                //   return false // If no value is entered, no validation needed
                                // },
                              }}
                              errorMessage="Enter Stipend"
                              onChange={e => {
                                handlevaluechange(e.target.value, "stipend")
                              }}
                              // min={0}
                              placeholder="Stipend"
                            />
                          </div>
                        </Col>
                      )}

                      <Col md="3 i2">
                        <div className="mb-2 i1 ">
                          <Label>Duration</Label>
                          <AvField
                            value={master.duration}
                            onChange={e => {
                              handlevaluechange(e.target.value, "duration")
                            }}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Enter Duration",
                              },
                              isPercentage: value => {
                                // Check if it's a percentage field
                                const percentage = parseFloat(value)
                                return !isNaN(percentage) && percentage >= 0

                                return true // If it's not a percentage field, no validation needed
                              },
                            }}
                            errorMessage="Enter a valid value"
                            placeholder="duration"
                            name="duration"
                            type="Number"
                          />
                        </div>

                        <div className="mb-2 i1 i3">
                          <Select
                            name="unit"
                            className="durationindex"
                            value={selected.unit}
                            onChange={selected => {
                              handleSelect(selected, "unit")
                            }}
                            options={[
                              { label: "Days", value: 1 },
                              { label: "Weeks", value: 2 },
                              { label: "Months", value: 3 },
                              { label: "Years", value: 4 },
                            ]}
                            classNamePrefix="select2-selection"
                            defaultValue={{ label: "Months", value: 3 }}
                          />

                          <SelectErrorMessage
                            show={error.unit}
                            customStyle={{
                              width: "100%",
                              fontSize: "87.5%",
                              color: "var(--bs-form-invalid-color)",
                            }}
                            message="Please Select Duration"
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>State</Label>
                          <Select
                            name="state"
                            isMulti
                            options={states}
                            className="locationzindex"
                            value={selected.state}
                            onChange={selected => {
                              // dispatch(getDistrictOptions({id:selected.value}))
                              handleSelect(selected, "state")
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>District</Label>
                          <Select
                            name="location"
                            isMulti
                            options={jobDistricts}
                            className="locationzindex"
                            value={selected.location || ""}
                            onChange={selected => {
                              handleSelect(selected, "location")
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Expiry</Label>
                          <AvField
                            name="expiryDate"
                            value={master?.expiryDate}
                            type="date"
                            onChange={e => {
                              handlevaluechange(e.target.value, "expiryDate")
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Upload Image{" "}
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="copy-event-tooltip">
                                  {" "}
                                  Specifications: Image should be PNG or JPEG,
                                  with dimensions 1600x1200 pixels, and size
                                  150-200kb.
                                </Tooltip>
                              }
                            >
                              <i
                                className="fas fa-info-circle"
                                style={{
                                  cursor: "pointer",
                                  fontSize: "1em",
                                  marginRight: "0.5rem",
                                }}
                              ></i>
                            </OverlayTrigger>
                          </Label>
                          {master?.image ? (
                            <div div className="img-wraps d-flex flex-column">
                              {master?.image &&
                              master?.image.startsWith("http") ? (
                                <img
                                  alt=""
                                  width="250"
                                  height="150"
                                  src={`${master?.image}`}
                                />
                              ) : (
                                <img
                                  alt=""
                                  width="250"
                                  height="150"
                                  src={`${API_URL}${master?.image}`}
                                />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={() => {
                                  setmaster(pre => ({
                                    ...pre,
                                    image: null,
                                  }))
                                }}
                                style={{ width: "250px", marginTop: "4px" }}
                                type="button"
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <input
                              name="file"
                              type="file"
                              className="form-control"
                              id="validationCustom03"
                              onChange={uploadBanner}
                              errorMessage="Please upload an image"
                              validate={{ required: { value: true } }}
                              rows="1"
                            />
                          )}
                          <SelectErrorMessage
                            show={master?.image == null && error.image}
                            customStyle={{
                              width: "100%",
                              fontSize: "87.5%",
                              color: "var(--bs-form-invalid-color)",
                            }}
                            message="Please Upload Image"
                          />
                        </div>
                        {/* <p style={{ fontSize: ".7rem", marginTop: "-1rem" }}>
                          (1600*250)/100-300kb
                        </p> */}
                      </Col>
                      {master?.details?.map((item, idx) => (
                        <Card>
                          <CardBody>
                            <Row>
                              <Col md="3">
                                <div className="mb-3">
                                  <Label>Tab name</Label>
                                  <Select
                                    className="text-editor-select"
                                    name="tab"
                                    classNamePrefix="select2-selection "
                                    options={selectOptions}
                                    value={fieldTypeOptions?.filter(
                                      tab => tab.value === item.tab
                                    )}
                                    onChange={selected =>
                                      handlePreValueChange(
                                        selected.value,
                                        "tab",
                                        idx
                                      )
                                    }
                                  />
                                  <SelectErrorMessage
                                    show={error.tab}
                                    customStyle={{
                                      width: "100%",
                                      fontSize: "87.5%",
                                      color: "var(--bs-form-invalid-color)",
                                    }}
                                    message="Please Select Duration"
                                  />
                                </div>
                              </Col>
                              {/* <Col md="5">
                                  <div className="mb-3">
                                    <Label htmlFor="validationCustom03">
                                      Topic Description
                                    </Label>
                                    <AvField
                                      name="tabDescription"
                                      type="textarea"
                                      rows="1"
                                      className="form-control"
                                      value={item.tabDescription || ""}
                                      onChange={e =>
                                        handlePreValueChange(
                                          e.target.value,
                                          "tabDescription",
                                          idx
                                        )
                                      }
                                      placeholder="Topic Description"
                                    />
                                  </div>
                                </Col> */}

                              <Col md="12">
                                <SunEditor
                                  setDefaultStyle="font-family: Arial; font-size: 14px; height: 100px;"
                                  lang="en"
                                  setOptions={options}
                                  onChange={content =>
                                    onChangeSuneditor1(content, idx)
                                  }
                                  setContents={item.contents || null}
                                />
                              </Col>
                              {master.details.length > 1 && (
                                <div className="d-flex justify-content-end">
                                  <span
                                    className="mr-1 p-2"
                                    style={{
                                      fontSize: "1.125rem",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      const data = { ...master }
                                      data.details.splice(idx, 1)
                                      hanldeOptionsData(data)
                                      setmaster(data)
                                    }}
                                  >
                                    <i className="trash-btn mdi mdi-delete"></i>
                                  </span>
                                </div>
                              )}
                            </Row>
                          </CardBody>
                        </Card>
                      ))}

                      {selectOptions.length > 0 && (
                        <Col md={12}>
                          <div className="d-flex justify-content-between align-items-center">
                            <button
                              onClick={e => {
                                e.preventDefault()
                                const data = { ...master }
                                data.details.push({})
                                setmaster(data)
                              }}
                              className="waves-effect btn btn-outline-light d-flex"
                              style={{ gap: "5px" }}
                            >
                              Add more{" "}
                              <i className="mdi mdi-plus-circle-outline"></i>
                            </button>
                          </div>
                        </Col>
                      )}
                      {/* <Col md="12">
                        <Label>Internship Description</Label>

                        <SunEditor
                          name="description"
                          setContents={master.description}
                          onChange={content => onChangeSuneditor(content)}
                          setDefaultStyle="font-family: Arial; font-size: 14px;"
                          lang="en"
                          setOptions={options}
                          // onChange={content => onChangeSuneditor(content, idx)}
                        />
                      </Col> */}

                      <Col>
                        <div className="mb-3" style={{ paddingTop: "25px" }}>
                          <Button
                            className="me-2"
                            color={master._id ? "warning" : "primary"}
                            type="submit"
                          >
                            {master._id ? "Update" : "Submit"}
                          </Button>
                          <Button
                            onClick={handleReset}
                            color="danger"
                            type="button"
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row style={{display:'flex',justifyContent:'flex-start',alignItems:'end'}}>
                      {isAdmin() ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Recruiter</Label>
                            <Select
                              name="recruiter1"
                              classNamePrefix="select2-selection"
                              options={recruitersoptions}
                              className="recruiterzindex"
                              value={searchSelect?.recruiter1 || null}
                              onChange={e => {
                                handleSearchSelect(e, "recruiter1")
                                // setSelectedAssign(e)
                              }}
                            />
                          </div>
                        </Col>
                      ) : null}
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Status</Label>
                          <Select
                            onChange={e => {
                              handleSearchSelect(e, "status")
                              // setSelectedAssign(e)
                            }}
                            value={searchSelect?.status || null}
                            // name="community"
                            options={status}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Search</Label>
                          <Input
                          placeholder="Search..."
                          type="text"
                            onChange={e => {
                              setSearch(e.target.value)
                              setPage(0)
                            }}
                            value={search}
                          />
                        </div>
                      </Col>

                      <Col>

                          <Button
                           style={{marginBottom:'15px'}}
                            onClick={() => {
                              setSearch('')
                              setSearchSelect({})
                            }}
                            color="danger"
                            type="button"
                          >
                            Reset
                          </Button>
                      </Col>
                    </Row>
                  </AvForm>
                  <MDBDataTable
                    id="internshipID"
                    bordered
                    responsive
                    searching={false}
                    paging={false}
                    info={false}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>
                  {count > 20 && (
                    <MuiCustomTablePagination
                      count={count}
                      page={page}
                      rowsPerPage={pageSize}
                      handleChangePage={handlePageChange}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default Create
