import React from "react"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import Select from "react-select"
import { MDBDataTable } from "mdbreact"
import { useState } from "react"
import { useEffect } from "react"
import PurchaseForm from "./PurchaseForm"
import { AvField, AvForm } from "availity-reactstrap-validation"
import "./PurchaseForm"
import "./style.scss"
import { API_URL } from "../../config"
import axios from "axios"
import toastr from "toastr"
import { get, post, del, put } from "../../helpers/api_helper"
import { useDispatch, useSelector } from "react-redux"
import { isNull } from "lodash"
import useBranch from "../../hooks/useBranch"
import { getBranches } from "../../store/options/actions"
import moment from "moment"
import DeleteModal from "../../components/Common/DeleteModal"
function Purchase() {
  const [showForm, setShowForm] = useState(false)
  // Main Form //
  const [date, setDate] = useState("")
  const [csp, setcsp] = useState("")
  const [purchaseName, setPurchaseName] = useState("")
  const [amount, setAmount] = useState("")
  const [remarks, setRemarks] = useState("")
  const [imagePath, setImagePath] = useState("")
  const [type, setType] = useState("")
  const [paymentStatus, setPaymentStatus] = useState(null)
  // Sub Form for Paid case only //
  const [name, setName] = useState("")
  const [bankName, setBankName] = useState("")
  const [accountNumber, setAccountNumber] = useState("")
  const [accountType, setAccountType] = useState("")
  const [ifscCode, setIfscCode] = useState("")
  const [bankSelected, setBankSelected] = useState(false)
  const [accountTypeSelected, setAccountTypeSelected] = useState(false)

  const [typeOptions, setTypeOptions] = useState([])
  const [branch, setBranch] = useState(...useBranch())
  const [alreadyBranch] = useBranch()
  const [listPurchaseData, setListPurchaseData] = useState([])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [toDeleteId, setToDeleteId] = useState("")
  const [toUpdateId, settoUpdateId] = useState("")

  const dispatch = useDispatch()

  useEffect(() => {
    handleCategoryOptions()

    if (!branch) dispatch(getBranches())
    else setcsp(branch)
  }, [])

  useEffect(() => {
    fetchPurchaseData()
  }, [])

  let { branches } = useSelector(state => state.Options)
  const handlePaidRadioClick = value => {
    setPaymentStatus(value)
    setShowForm(true)
  }

  // Open an extra form in the case of paid //
  const handleCloseForm = value => {
    setPaymentStatus(value)
    setShowForm(false)
  }

  // Data to be passed to the table //
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        width: 150,
      },
      {
        label: "Purchase Date",
        field: "purchaseDate",
        width: 150,
      },
      {
        label: "Purchase Name",
        field: "purchaseName",
        width: 150,
      },
      {
        label: "Amount (₹)",
        field: "amount",
        width: 150,
      },
      {
        label: "Remarks",
        field: "remarks",
        width: 150,
      },

      {
        label: "Invoice",
        field: "imageTag",
        sort: "desc",
        width: 400,
      },
      {
        label: "Staff",
        field: "addedFirstName",
        sort: "desc",
        width: 400,
      },
      {
        label: "Actions",
        field: "options",
        sort: "desc",
        width: 400,
      },
    ],
    rows: listPurchaseData,
  }

  const handleValueChange = value => {
    setImagePath(value)
  }

  // function sends a post request to store the image and it returns the path to the image //
  const handleLogoUpload = async e => {
    const selectedFile = e.target.files[0]
    if (selectedFile) {
      try {
        const fd = new FormData()
        fd.append("image", e.target.files[0])
        axios.post(`${API_URL}purchase/image`, fd).then(response => {
          if (response.data.status === 200) {
            handleValueChange(response.data.data.new_filename)
          } else {
            toastr.error(response.data.message)
          }
        })
      } catch (error) {
        toastr.error(error)
        e.target.value = ""
      }
    }
  }

  // Add a new purchase record in DB //
  async function createNewPurchaseOrder(requestType) {
    try {
      if (!csp) toastr.error("Please Select CSP")
      if (isNull(paymentStatus)) {
        toastr.error("Please select paid or unpaid!")
        return
      }
      let body
      if (showForm) {
        if (
          !date ||
          !purchaseName ||
          !name ||
          !bankName ||
          !accountNumber ||
          !accountType ||
          !ifscCode
        ) {
          if (amount > 0 && type && csp) {
            toastr.error("All Fields are required!")
            return
          }
        }
        body = {
          date: date,
          purchaseName: purchaseName,
          amount: amount,
          remarks: remarks,
          image: imagePath,
          paymentStatus: paymentStatus,
          accountHolderName: name,
          bankName: bankName.label,
          accountNumber: accountNumber,
          accountType: accountType.label,
          ifscCode: ifscCode,
          branch: csp._id,
          type: type.value,
        }
      } else {
        if (!date || !purchaseName) {
          if (amount > 0 && type && csp) {
            toastr.error("All Fields are required!")
            return
          }
        }
        body = {
          date: date,
          purchaseName: purchaseName,
          amount: amount,
          remarks: remarks,
          image: imagePath,
          paymentStatus: showForm ? 1 : 0,
          branch: csp._id,
          type: type.value,
          paymentStatus: paymentStatus,
        }
      }
      if (requestType == "update") {
        body.type = type
        body.accountType = accountType.label
        body.bankName = bankName.label
        body.id = toUpdateId
        put("/purchase/edit", body)
          .then(res => {
            toastr.success("Purchase Updated!")
            const index = listPurchaseData.findIndex(
              item => item._id == res.updatedPurchase[0]._id
            )
            let temp = [...listPurchaseData]
            let modifiedUpdatedPurchase = {
              ...res.updatedPurchase[0],
              date: moment(res.updatedPurchase[0].date).format("DD-MM-YYYY"),
              time: moment(res.updatedPurchase[0].time, "HH:mm:ss").format(
                "hh:mm a"
              ),
              purchaseDate: moment(res.updatedPurchase[0].purchaseDate).format(
                "DD-MM-YYYY"
              ),
              id: index + 1,
              options: (
                <div>
                  <i
                    onClick={() =>
                      updatePurchaseData(res.updatedPurchase[0]._id)
                    }
                    className="uil-edit-alt"
                    style={{
                      fontSize: "1.2em",
                      cursor: "pointer",

                      marginRight: "0.5rem",
                    }}
                  ></i>

                  <i
                    onClick={() => {
                      confirmDelete(res.updatedPurchase[0]._id)
                    }}
                    className="uil-trash-alt"
                    style={{ fontSize: "1.2em", cursor: "pointer" }}
                  ></i>
                </div>
              ),
              imageTag: (
                <img src={res.updatedPurchase[0].image} alt="Uploded Image" />
              ),
            }
            temp[index] = modifiedUpdatedPurchase
            setListPurchaseData(temp)
            handleReset()
          })
          .catch(error => {
            console.log("Error Updating Purchase =", error)
          })
        return
      }
      post("/purchase/create", body)
        .then(res => {
          let rows = res.data.map((item, index) => {
            return {
              ...item,
              date: moment(item.date).format("DD-MM-YYYY"),
              time: moment(item.time, "HH:mm:ss").format("hh:mm a"),
              purchaseDate: moment(item.purchaseDate).format("DD-MM-YYYY"),
              id: index + 1,
              options: (
                <div>
                  <i
                    onClick={() => updatePurchaseData(item._id)}
                    className="uil-edit-alt"
                    style={{
                      fontSize: "1.2em",
                      cursor: "pointer",

                      marginRight: "0.5rem",
                    }}
                  ></i>

                  <i
                    onClick={() => {
                      confirmDelete(item._id)
                    }}
                    className="uil-trash-alt"
                    style={{ fontSize: "1.2em", cursor: "pointer" }}
                  ></i>
                </div>
              ),
              imageTag: <img src={item.image} alt="Uploded Image" />,
            }
          })
          setListPurchaseData(rows)
          handleReset()
          toastr.success(res.message)
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    } catch (error) {
      console.log("Error in createNewPurchase =", error)
    }
  }

  const handleCategoryOptions = async () => {
    get("/expense/options/category").then(res => {
      setTypeOptions(res.data)
    })
  }

  // Function retest values in all fields //
  function handleReset() {
    setDate("")
    setcsp("")
    setPurchaseName("")
    setAmount("")
    setRemarks("")
    setImagePath("")
    setType("")
    setPaymentStatus(null)
    setName("")
    setBankName("")
    setAccountNumber("")
    setAccountType("")
    setIfscCode("")
    setBankSelected(false)
    setAccountTypeSelected(false)
    setcsp(alreadyBranch)
  }

  // Function is used to fetch all purchase Data //
  async function fetchPurchaseData() {
    try {
      let response = await get("/purchase/list")
      let rows = response.data.map((item, index) => {
        return {
          ...item,
          date: moment(item.date).format("DD-MM-YYYY"),
          time: moment(item.time, "HH:mm:ss").format("hh:mm a"),
          purchaseDate: moment(item.purchaseDate).format("DD-MM-YYYY"),
          id: index + 1,
          options: (
            <div>
              <i
                onClick={() => updatePurchaseData(item._id)}
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",

                  marginRight: "0.5rem",
                }}
              ></i>

              <i
                onClick={() => {
                  confirmDelete(item._id)
                }}
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
              ></i>
            </div>
          ),
          imageTag: <img src={item.image} alt="Uploded Image" />,
        }
      })
      setListPurchaseData(rows)
    } catch (error) {
      console.log("Error in purchase inside fetchPurchaseData=", error)
    }
  }

  // Function is used to delete row from purchase //
  async function deletePurchaseRow() {
    try {
      let response = await del(
        `/purchase/delete/${toDeleteId}?purchaseId=${toDeleteId}`
      )
      if (response.code == 200) {
        let temp = listPurchaseData.filter(item => item._id !== toDeleteId)
        setListPurchaseData(temp)
        setToDeleteId("")
        toastr.success("Purchase Deleted!")
      }
    } catch (error) {
      toastr.error("Deleting purchase Failed!")
      console.log("Error in deletePurchaseRow =", error)
    } finally {
      setShowDeleteModal(false)
    }
  }

  // Function is used to open Confirm delete pop up //
  function confirmDelete(id) {
    setToDeleteId(id)
    setShowDeleteModal(true)
  }

  // Close the confirm delete pop up //
  function closeDeleteModal() {
    setShowDeleteModal(false)
  }

  // Used to load data of the form in th case of update //
  async function updatePurchaseData(id) {
    try {
      let response = await get(`/purchase/singlePurchase?id=${id}`)
      let preFillContent = response.data[0]
      let selectedCSP = branches.find(item => item._id == preFillContent.branch)
      setcsp(selectedCSP)
      // Convert Date to yyyy-mm-dd //
      const date = new Date(preFillContent.date)
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, "0")
      const day = String(date.getDate()).padStart(2, "0")
      const formattedDate = `${year}-${month}-${day}`
      setDate(formattedDate)
      const res = await get("/expense/options/category")
      let selectedType = res.data.find(
        item => item.label == preFillContent.type
      )
      setType(selectedType)
      setPurchaseName(preFillContent.purchaseName)
      setAmount(preFillContent.amount)
      setRemarks(preFillContent.remarks)
      setImagePath(preFillContent.image)
      setPaymentStatus(0)
      setShowForm(false)
      if (preFillContent.paymentStatus) {
        let bankNameSelected = [
          { label: "Central Bank of India", value: 0 },
          { label: "Bank of Baroda", value: 1 },
          { label: "Canara Bank", value: 2 },
          { label: "Federal Bank", value: 3 },
          { label: "State Bank of India", value: 4 },
        ].find(item => item.label.includes(preFillContent.bankName))
        let accountTypeSelected = [
          { label: "saving", value: 0 },
          { label: "Salary Account", value: 1 },
          { label: "Fixed Deposit Account", value: 2 },
          { label: "NRI Account", value: 3 },
        ].find(item => item.label.includes(preFillContent.accountType))
        setPaymentStatus(1)
        setAccountNumber(preFillContent.accountNumber)
        setName(preFillContent.accountHolderName)
        setBankName("some name")
        setAccountType(preFillContent.accountType)
        setIfscCode(preFillContent.ifscCode)
        setShowForm(true)
        setBankName(bankNameSelected)
        setAccountType(accountTypeSelected)
      }
      settoUpdateId(id)
      scrollToTop()
    } catch (error) {
      console.log("Error in updatePurchaseData =", error)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds smooth scrolling behavior
    })
  }

  return (
    <>
      <DeleteModal
        onCloseClick={closeDeleteModal}
        onDeleteClick={deletePurchaseRow}
        show={showDeleteModal}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xl="12">
              <div>
                <h6 class="fs-4">Purchase Order</h6>
              </div>

              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Date</Label>
                          <AvField
                            name="Domain Code"
                            className="form-control"
                            type="date"
                            value={date}
                            onChange={e => {
                              setDate(e.target.value)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-2">
                          <Label>CSP</Label>
                          <Select
                            options={branches}
                            value={csp}
                            onChange={selected => {
                              setcsp(selected)
                            }}
                            name="Domain Code"
                            type="text"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-2">
                          <Label>Type</Label>
                          <Select
                            options={typeOptions}
                            value={type}
                            onChange={selected => {
                              setType(selected)
                            }}
                            name="Domain Code"
                            type="text"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-2">
                          <Label>Purchase Name</Label>
                          <AvField
                            value={purchaseName}
                            onChange={e => {
                              setPurchaseName(e.target.value)
                            }}
                            name="Domain Code"
                            className="form-control"
                            type="text"
                            placeholder="Purchase name"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-1">
                          <Label>Amount(₹)</Label>
                          <AvField
                            value={amount}
                            onChange={e => {
                              setAmount(e.target.value)
                            }}
                            name="Domain Code"
                            className="form-control"
                            type="text"
                            placeholder="Amount"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-1">
                          <Label>Remarks</Label>
                          <AvField
                            value={remarks}
                            onChange={e => {
                              setRemarks(e.target.value)
                            }}
                            name="Domain Code"
                            className="form-control"
                            type="text"
                            placeholder="Remarks"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        {imagePath ? (
                          <div div className="img-wraps d-flex flex-column">
                            {imagePath && (
                              <img
                                alt=""
                                width="150"
                                height="150"
                                src={`${API_URL}${imagePath}`}
                              />
                            )}
                            <button
                              className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                              onClick={() => {
                                setImagePath("")
                              }}
                              style={{ width: "150px" }}
                              type="button"
                            >
                              Delete
                            </button>
                          </div>
                        ) : (
                          <div className="mb-1">
                            <Label>Upload Invoice</Label>
                            <AvField
                              name="image"
                              type="file"
                              className="form-control"
                              id="validationCustom03"
                              rows="1"
                              title=""
                              accept="image/*"
                              onChange={handleLogoUpload}
                            />
                          </div>
                        )}
                      </Col>

                      {/* <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Select Image</Label>
                          {userObject?.user_image?(
                           <div div className="img-wraps d-flex flex-column">
                             {userObject.user_image&&(
                              userObject.user_image.startWith("http")?(
                                <img
                                alt=""
                                width="150"
                                height="150"
                                src={`${userObject.user_image}`}
                              />
                             
                              ):(
                                <img
                             alt=""
                             width="150"
                             height="150"
                             src={`${userObject.user_image}`}
                           />
                              )
                             )}
                                <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={() => {
                                  setUserObject(pre => ({
                                    ...pre,
                                    user_image: null,
                                  }))
                                }}
                                style={{ width: "150px" }}
                                type="button"
                              >
                                Delete
                              </button>
                       </div>
                           ):(
                            <AvField
                              name="user_image"
                              type="file"
                              className="form-control"
                              id="validationCustom03"
                              
                              rows="1"
                            />
                          )}
                        </div>
                      </Col>  */}
                    </Row>
                  </AvForm>
                  <div className="form-check form-check-inline fs-5 mt-2">
                    <input
                      className="form-check-input"
                      type="radio"
                      style={{ borderColor: "#006aae" }}
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      // value="option1"
                      onClick={() => handlePaidRadioClick(1)}
                      checked={paymentStatus}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      Paid
                    </label>
                  </div>
                  <div className="form-check form-check-inline fs-5">
                    <input
                      className="form-check-input"
                      type="radio"
                      style={{ borderColor: "#006aae" }}
                      name="inlineRadioOptions"
                      id="inlineRadio3"
                      onClick={() => handleCloseForm(0)}
                      value="option2"
                      checked={paymentStatus === 0}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio3">
                      Unpaid
                    </label>
                  </div>
                  {showForm && (
                    <PurchaseForm
                      name={name}
                      setName={setName}
                      bankName={bankName}
                      setBankName={setBankName}
                      accountNumber={accountNumber}
                      setAccountNumber={setAccountNumber}
                      accountType={accountType}
                      setAccountType={setAccountType}
                      ifscCode={ifscCode}
                      setIfscCode={setIfscCode}
                      bankSelected={bankSelected}
                      setBankSelected={setBankSelected}
                      accountTypeSelected={accountTypeSelected}
                      setAccountTypeSelected={setAccountTypeSelected}
                      handleClose={handleCloseForm}
                    />
                  )}
                  <Col>
                    <div className="mb-3 d-flex" style={{ paddingTop: "25px" }}>
                      <Button
                        onClick={() =>
                          toUpdateId
                            ? createNewPurchaseOrder("update")
                            : createNewPurchaseOrder("create")
                        }
                        color="primary"
                        type="submit"
                      >
                        {toUpdateId ? "Update" : "Submit"}
                      </Button>
                      <Button
                        onClick={handleReset}
                        className="mx-2"
                        color="danger"
                        type="reset"
                      >
                        Reset
                      </Button>
                    </div>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="purcahseid"
                    bordered
                    responsive
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default Purchase
