import React, { useEffect, useState } from "react"
import { Row, Col } from "reactstrap"

import { MDBDataTable } from "mdbreact"
import { useLocation } from "react-router-dom"
// import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { get } from "../../../../helpers/api_helper"
import moment from "moment"
import { useNavigate } from "react-router-dom"
import "./style.scss"
function Members() {
  const { state } = useLocation()
  const [membersList, setMembersList] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    handleTableList()
  }, [])
  function handleTableList() {
    get(`/events/eventsCommunity/?communityId=${state?.id}`).then(res => {
      let data = []
      let result = res?.data
      result?.map((item, index) => {
        item.date = moment(item?.date).format("DD-MM-YYYY")
        item.time = moment(item?.time,"hh:mm s").format("hh:mm a")
  
        item.id = index + 1
        item.categorys = item?.category?.name
        item.added = item?.addedBy?.firstName
        item.action = (
          <>
            <div>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  navigate(`/creation/${item.uniqueId}`, {
                    state: { _id: item._id },
                  })
                }}
              ></i>
            </div>
          </>
        )
        if (item.eventStatus === "0") {
          item.status = (
            <h6
            style={{
              color: "#03c7f3",
            }}
          >
            {"Upcoming"}
            </h6>
          )
        } else {
          item.status = (
            <h6
              style={{
                color: "green",
              }}
            >
              {"Completed"}
            </h6>
          )
        }
        data.push(item)
      })
      setMembersList(data)
    })
  }

  const shortlisted = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        width: 150,
      },
      {
        label: "Category",
        field: "categorys",
        width: 150,
      },
      {
        label: "Event Name",
        field: "name",
        width: 150,
      },
      {
        label: "Venue",
        field: "venue",
        sort: "desc",
        width: 400,
      },
      {
        label: "Status",
        field: "status",
        width: 150,
      },
      {
        label: "Staff",
        field: "added",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        width: 150,
      },
    ],
    rows: membersList,
  }
  return (
    <div>
      {" "}
      <Row>
        <Col className="col-12">
          <MDBDataTable
            id="eventcommunitid"
            data={shortlisted}
            responsive
            bordered
            searching={true}
            info={false}
            disableRetreatAfterSorting={true}
          />
        </Col>
      </Row>
    </div>
  )
}

export default Members
