import React, { useEffect, useState } from "react"
import MiniWidget from "./Cards/miniWidget.jsx"
import { Col, Container, Row } from "reactstrap"
import LatestRequests from "./Cards/latestRequests.jsx"
import LatestTransaction from "./Cards/latestTransactions.jsx"
import BatchProgression from "./Cards/batchProgress.jsx"
import RevenueDetails from "./Cards/RevenueDetails.jsx"
import Select from "react-select"
import { AvField, AvForm } from "availity-reactstrap-validation"

import { get } from "../../../helpers/api_helper"
import "./style.scss"
import FinancialYear from "../../../components/Common/FinancialYear.jsx"
import {
  getCurrentFinancialMonthSelect,
  getCurrentFinancialQuarterSelect,
  getCurrentFinancialYearSelect,
  setTitle,
} from "../../../helpers/functions.js"
import FinancialQuarter from "../../../components/Common/FinancialQuarter.jsx"
import FinancialMonth from "../../../components/Common/FinancialMonth.jsx"
import ExpenseDetails from "./Cards/expenseDetails.jsx"
import useBranch from "../../../hooks/useBranch.js"
import { useSelector } from "react-redux"

function FinanceDashboard() {
  setTitle("Finance Dashboard")
  const { allotted } = useSelector(state => state.Profile)
  const getCurrentFinancialYear = getCurrentFinancialYearSelect()
  const getCurrentFinancialQuarter = getCurrentFinancialQuarterSelect()
  const getCurrentFinancialMonth = getCurrentFinancialMonthSelect()
  // const date = getDate()

  const [cardDetails, setCardDetails] = useState(null)
  const [scholarshipDetails, setScholarshipDetails] = useState([])
  const [skillLoan, setSKillLoan] = useState([])

  const [masterObject, setMasterObject] = useState({
    type: 1,
    year: getCurrentFinancialYear.value,
    quarter: getCurrentFinancialQuarter.value,
    month: getCurrentFinancialMonth.value,
    startDate: getCurrentFinancialYear.from,
    endDate: getCurrentFinancialYear.to,
  })

  const [selectedFields, setSelectedFields] = useState({
    type: { label: "Year", value: 1 },
    year: getCurrentFinancialYear,
    quarter: getCurrentFinancialQuarter,
    month: getCurrentFinancialMonth,
  })

  const typeOptions = [
    { label: "Year", value: 1 },
    { label: "Quarterly", value: 2 },
    { label: "Monthly", value: 3 },
    { label: "Custom", value: 4 },
  ]

  useEffect(() => {
    const abortController = new AbortController()
    const signal = abortController.signal

    handleFinanceCard(signal)
    handleScholarship(signal)
    handleSkillLoan(signal)

    console.log("branch", allotted.branches)

    return () => {
      abortController.abort()
    }
    // handleExpenseDetails()
    // handleRevenueDetails()
    // eslint-disable-next-line
  }, [masterObject, allotted.branches])

  const handleFinanceCard = async signal => {
    try {
      const response = await get(
        `/finance-dashboard/counts?startDate=${masterObject.startDate}&endDate=${masterObject.endDate}`,
        { signal }
      )
      setCardDetails(response.counts)
    } catch (error) {}
  }

  const handleScholarship = async signal => {
    try {
      const response = await get(
        `/finance-dashboard/latest-skillLoan?startDate=${masterObject.startDate}&endDate=${masterObject.endDate}`,
        { signal }
      )
      setSKillLoan(response.data)
    } catch (error) {}
  }

  const handleSkillLoan = async signal => {
    try {
      const response = await get(
        `/finance-dashboard/latest-scholarship?startDate=${masterObject.startDate}&endDate=${masterObject.endDate}`,
        { signal }
      )
      setScholarshipDetails(response.data)
    } catch (error) {}
  }

  const handleSelectValueChange = async (selected, name) => {
    const obj = { ...selectedFields }
    obj[name] = selected
    setSelectedFields(obj)
    handleValueChange(selected, name)
  }

  const handleValueChange = async (selected, name) => {
    let obj = { ...masterObject }
    const value = selected.value
    obj[name] = value

    const isTypeChange = name === "type"
    const isYearChange = name === "year"
    const isQuarterChange = name === "quarter"
    const isMonthChange = name === "month"

    const isSelectChange = isYearChange || isQuarterChange || isMonthChange
    if (isSelectChange) obj = handleDateChange(obj, selected)

    if (isTypeChange && value === 1) handleDateChange(obj, selectedFields.year)
    if (isTypeChange && value === 3) handleDateChange(obj, selectedFields.month)
    if (isTypeChange && value === 2)
      handleDateChange(obj, selectedFields.quarter)

    setMasterObject(obj)
  }

  const handleDateChange = (obj, selected) => {
    obj.startDate = selected.from
    obj.endDate = selected.to
    return obj
  }

  const reports = [
    {
      id: 1,
      icon: "fas fa-rupee-sign",
      title: "Total Revenue",
      icon2: "fas fa-money-check-alt",
      value: cardDetails?.revenue,
      decimal: 0,
      url: "/income-report",
    },
    {
      id: 2,
      icon: "fas fa-rupee-sign",
      title: "Total Expense",
      icon2: "fas fa-money-check-alt",
      value: cardDetails?.expense,
      decimal: 0,
      url: "/expense-report",
    },
    {
      id: 3,
      icon: "fas fa-rupee-sign",
      icon2: "fas fa-money-bill",
      title: "Total Margin",
      value: (cardDetails?.revenue || 0) - (cardDetails?.expense || 0),
      decimal: 0,
      url: "/income-expense",
    },
    {
      id: 4,
      icon: "fas fa-rupee-sign",
      title: "New Fees",
      icon2: "fas fa-money-check-alt",
      value: cardDetails?.fees,
      decimal: 0,
      url: "/fee_payments",
    },
    {
      id: 5,
      icon: "fas fa-rupee-sign",
      icon2: "fas fa-money-bill",
      title: "Offer Codes",
      value: cardDetails?.offerCode,
      decimal: 0,
      url: "/offer-code-log",
    },
    {
      id: 6,
      icon: "",
      icon2: "fa fa-graduation-cap",
      title: "Scholarship Requests",
      value: cardDetails?.scholarshipRequest,
      decimal: 0,
      url: "/Scholarships-requests",
    },
    {
      id: 7,
      icon: "",
      icon2: "fa fa-university",
      title: "Skill Loan Requests",
      value: cardDetails?.skillLoanRequest,
      decimal: 0,
      url: "/skills-loan-request",
    },
  ]


 

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div id="finance-dashboard">
          <Container fluid>
            <Row id="dashboard-head">
              <Col md={masterObject.type === 2 ? 5 : 6}>
                <h4 className="mb-0">Finance-Dashboard</h4>
              </Col>
              <Col md={masterObject.type === 2 ? 4 : 3}>
                <Row className="dashboard-filter">
                  <Col md={masterObject.type === 2 ? 5 : 6}>
                    <Select
                      options={typeOptions}
                      value={selectedFields.type}
                      onChange={selected =>
                        handleSelectValueChange(selected, "type")
                      }
                    ></Select>
                  </Col>
                  {masterObject.type === 1 ? (
                    <Col md={masterObject.type === 2 ? 7 : 6}>
                      <FinancialYear
                        onChange={selected =>
                          handleSelectValueChange(selected, "year")
                        }
                        value={selectedFields.year}
                      />
                    </Col>
                  ) : masterObject.type === 2 ? (
                    <Col md={6}>
                      <FinancialQuarter
                        onChange={selected =>
                          handleSelectValueChange(selected, "quarter")
                        }
                        value={selectedFields.quarter}
                      />
                    </Col>
                  ) : masterObject.type === 3 ? (
                    <Col md={6}>
                      <FinancialMonth
                        onChange={selected =>
                          handleSelectValueChange(selected, "month")
                        }
                        value={selectedFields.month}
                      />
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
              </Col>
              <Col md={3}>
                <AvForm>
                  <Row>
                    <Col md={6}>
                      <AvField
                        name="startDate"
                        type="date"
                        value={masterObject.startDate || ""}
                        onChange={e =>
                          handleValueChange(e.target, e.target.name)
                        }
                        disabled={masterObject.type !== 4}
                      >
                        {" "}
                      </AvField>
                    </Col>
                    <Col md={6}>
                      <AvField
                        name="endDate"
                        type="date"
                        value={masterObject.endDate || ""}
                        onChange={e =>
                          handleValueChange(e.target, e.target.name)
                        }
                        disabled={masterObject.type !== 4}
                        min={masterObject.startDate}
                      ></AvField>
                    </Col>
                  </Row>
                </AvForm>
              </Col>
            </Row>
            <MiniWidget reports={reports} />
            <RevenueDetails masterObject={masterObject} />
            <ExpenseDetails masterObject={masterObject} />
            <Row>
              {scholarshipDetails.length > 0 && (
                <Col md={4} lg={4}>
                  <LatestRequests
                    list={scholarshipDetails}
                    link={"/Scholarships-requests"}
                    title="Latest Scholarship Requests"
                  />
                </Col>
              )}
              {skillLoan.length > 0 && (
                <Col md={4} lg={4}>
                  <LatestRequests
                    list={skillLoan}
                    link={"/skills-loan-request"}
                    title="Latest Skill loan Requests"
                  />
                </Col>
              )}

              {/* <Col md={4} lg={4}>
                <BatchProgression />
              </Col> */}
            </Row>
            <LatestTransaction />
          </Container>
        </div>
      </div>
    </div>
  )
}

export default FinanceDashboard
