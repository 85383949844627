import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { MDBDataTable } from "mdbreact"
import { get, put } from "../../../helpers/api_helper"
import moment from "moment"
import toastr from "toastr"
import { APP_NAME } from "../../../config"

const BlockIP = () => {
  document.title = `Blocked-IP | ${APP_NAME}`

  const [master, setMaster] = useState({})
  const [details, setDetails] = useState([])

  function handleChange(e) {
    let { name, value } = e.target
    setMaster(pre => ({ ...pre, [name]: value }))
  }

  useEffect(() => {
    getBlockedUsers(master)
    // eslint-disable-next-line
  }, [master])

  function getBlockedUsers(master) {
    get(`security/block-list?ip=${master.ip}`).then(res => {
      const data = res.blocked_user

      data.map((item, index) => {
        item.id = index + 1
        item.time = moment(item.login_on_time, "HHmmss").format("hh:mm a")

        if (item.attempts >= 5) {
          item.action = (
            <i
              onClick={() => updateStatus(item._id)}
              style={{ fontSize: "20px", color: "red", cursor: "pointer" }}
              className="uil-lock-open-alt"
            ></i>
          )
        } else {
          item.action = (
            <i
              style={{
                fontSize: "20px",
                color: "green",
                cursor: "pointer",
              }}
              className="uil-lock-alt"
            ></i>
          )
        }
        return item
      })
      setDetails(data)
    })
  }

  const updateStatus = item => {
    put(`security/unblock/${item}`, {})
      .then(res => {
        toastr.success(res.message)
        getBlockedUsers({})
      })
      .catch(err => {})
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "login_on_date",
        sort: "asc",
        width: 270,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 270,
      },
      // {
      //   label: "Date & Time",
      //   field: "datetime",
      //   sort: "asc",
      //   width: 270,
      // },
      {
        label: "User",
        field: "username",
        sort: "asc",
        width: 270,
      },
      {
        label: "IP Address",
        field: "login_ip",
        sort: "asc",
        width: 200,
      },
      {
        label: "Attempts",
        field: "attempts",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: details,
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Home" breadcrumbItem="Blocked IP" />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md="3">
                        <label>IP Address</label>
                        <div className="mb-3">
                          <AvField
                            name="ip"
                            placeholder="Search here"
                            value={master.ip}
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="blockedIpTableId"
                    responsive
                    bordered
                    data={data}
                    disableRetreatAfterSorting={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BlockIP
