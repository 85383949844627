import React, { useState, useRef, useEffect } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import "./style.scss"
import Swal from "sweetalert2"
import { get, post, put, del } from "../../../helpers/api_helper"
import toastr from "toastr"
import moment from "moment"
// import axios from "axios"

function CourseCategory() {

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }
  
  const formRef = useRef()
  const [master, setMaster] = useState({})
  const [tableDetails, settableDetails] = useState([])
  const [sectorIdToBeUpdated, setsectorIdToBeUpdated] = useState(null)

  useEffect(() => {
    fetchAllSrctor()
    // eslint-disable-next-line
  }, [])

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
        sort:"asc"
      },
      {
        label: "Date",
        field: "date1",
        width: 150,
        sort:"asc"

      },
      // {
      //   label: "Time",
      //   field: "time1",
      //   width: 150,
      //   sort:"asc"

      // },
      {
        label: "ID",
        field: "uniqueId",
        width: 150,
        sort:"asc"

      },
      {
        label: "Sector",
        field: "name",
        width: 150,
        sort:"asc"

      },
      {
        label: "Staff",
        field: "staff",
        width: 150,
        sort:"asc"

      },
      {
        label: "Action",
        field: "options",
        sort: "desc",
        width: 400,
        sort:"asc"

      },
    ],
    rows: tableDetails,
  }

  // submit function
  function handleValidSubmit(e, v) {

    if (sectorIdToBeUpdated) {

      put(`/sector/`, master)
        .then(res => {
          toastr.success(res.message)
          formRef.current.reset()
          fetchAllSrctor()
          setMaster({})
          setsectorIdToBeUpdated(null)
        })
        .catch(err => {
          toastr(err.response.data.message)
          formRef.current.reset()
          fetchAllSrctor()
          setMaster({})
          setsectorIdToBeUpdated(null)
        })
    } else {
      
      post(`sector/`, master)
        .then(res => {
          if (res.success === true) {
            toastr.success(res.message)
            formRef.current.reset()
            fetchAllSrctor()
            setMaster({})
            setsectorIdToBeUpdated(null)
          } else {
            toastr.error("Faild to add sector")
            formRef.current.reset()
            fetchAllSrctor()
            setMaster({})
            setsectorIdToBeUpdated(null)
          }
        })
        .catch(err => {
          toastr.error(err.response.data.message)
          formRef.current.reset()
          fetchAllSrctor()
          setMaster({})
          setsectorIdToBeUpdated(null)
        })
    }
  }

  //onchange
  function handleSector(e) {
    let { name, value } = e.target
    setMaster({ ...master, [name]: value })
  }

  // list alll sector

  function fetchAllSrctor() {
    get(`sector/`).then(res => {
      let result = res.data
      result &&
        result.map((item, index) => {
          item.id = index + 1
          item.date1 = moment(item?.date).format("DD-MM-YYYY")
          item.time1 = moment(item.time, "HH:mm:ss").format("hh:mm:ss A")
          item.staff=item?.addedBy?.firstName + item?.addedBy?.lastName
          item.options = (
            <div>
              <i
                className="far fa-edit"
                style={{
                  cursor: "pointer",
                  fontSize: "1em",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop()
                  handleUpdate(item)
                  setsectorIdToBeUpdated(item?._id)
                }}
              ></i>

              <i
                className="far fa-trash-alt"
                style={{ cursor: "pointer", fontSize: "1em" }}
                onClick={() => {
                  handleDelete(item._id)
                }}
              ></i>
            </div>
          )

          return item
        })
      settableDetails(result)
    })
  }
 
  //DELETE
  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`/sector/${id}`)
          .then(res => {
            toastr.success(res.message)
            fetchAllSrctor()
          })
          .catch(err => {
            toastr.success(err.response.data.message)
          })
      }
    })
  }

  //update
  function handleUpdate(item) {
    setMaster({ _id: item?._id, name: item?.name })
  }


  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Sector" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v)
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Sector</Label>
                          <AvField
                            name="name"
                            className="form-control"
                            type="text"
                            placeholder="Sector"
                            errorMessage="Enter Sector"
                            validate={{ required: { value: true } }}
                            value={master?.name||""}
                            onChange={handleSector}
                          />
                        </div>
                      </Col>

                      <Col>
                        <div className="mb-3" style={{ paddingTop: "25px" }}>
                          <Button
                            color={sectorIdToBeUpdated ? "warning" : "primary"}
                            type="submit"
                          >
                            {sectorIdToBeUpdated ? "Update" : "Submit"}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="sectorTableId"
                    bordered
                    responsive
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                    sortable={false}
                  ></MDBDataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default CourseCategory
