import React, { useEffect, useState } from "react"
import Breadcrumb from "../../../../components/Common/Breadcrumb"

import {
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  Nav,
} from "reactstrap"
import { MDBDataTable } from "mdbreact"
import { useLocation, useNavigate } from "react-router-dom"
import classnames from "classnames"

import "./style.scss"
import Basic from "./Tabs/Basic"
import Students from "./Tabs/Students"
import QualityFrameWork from "./Tabs/QualityFrameWork"
import Assessment from "./Tabs/Assessment"
import { BatchProvider } from "./BatchContext"
import { del, get, put } from "../../../../helpers/api_helper"
import moment from "moment"
import toastr from "toastr"
import TrainerAllocation from "./Tabs/TrainerAllocation"
import Swal from "sweetalert2"

function BatchCreation() {
  const navigate = useNavigate()

  const [rows, setRows] = useState([])

  const location = useLocation()

  const [activeTabJustify, setactiveTabJustify] = useState("1")

  const [master, setMaster] = useState({})

  const [isTabDisabled, setIsTabDisabled] = useState(true)

  useEffect(() => {
    const data = location?.state?.data || {}

    data.tempId = data?._id
    data.tempUniqueId = data?.uniqueId

    delete data?._id
    delete data.id
    setMaster(pre => ({ ...pre, ...data }))
  }, [location.state])

  useEffect(() => {
    getBatchList()
    // eslint-disable-next-line
  }, [])

  function start() {
    setactiveTabJustify("1")
  }

  function next() {
    setactiveTabJustify(Number(activeTabJustify) + 1 + "")
    toTop()
  }

  function back() {
    setactiveTabJustify(Number(activeTabJustify) - 1 + "")
    toTop()
  }

  function reset() {
    setactiveTabJustify("1")
    setMaster({})
  }

  const navigateToDetails = item => {
    navigate(`/batch-creations/${item.batchId}`, { state: { id: item._id } })
  }

  const handleDelete = item => {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`batch/${item._id}`)
          .then(res => {
            toastr.success(res.message)
            getBatchList()
          })
          .catch(err => {
            toastr.error(err.response.data.message)
          })
      }
    })
  }

  const getBatchList = () => {
    get(`batch`)
      .then(res => {
        res.data.map((item, idx) => {
          item.id = idx + 1

          item.date = item.date && moment(item.date).format("DD-MM-YYYY")

          item.courseName = item?.course?.name

          item.staff = `${item?.addedBy?.firstName || ""} ${
            item?.addedBy?.lastName || ""
          }`

          item.status = (
            <div className="text-center">
              <button
                onClick={() => handleToggle(item._id)}
                className={`btn btn-sm ${
                  item.status === 0 ? "btn-success" : "btn-warning"
                }`}
              >
                {item.status === 0 ? "Active" : "Inactive"}
              </button>
            </div>
          )

          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",

                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  navigateToDetails(item)
                }}
              ></i>
              <i
                className="far fa-edit"
                onClick={() => {
                  // start()
                  toTop()
                  setMaster(pre => ({
                    ...pre,
                    id: item._id,
                    course: item?.course?._id,
                    startDate: item?.startDate,
                    endDate: item?.endDate,
                  }))
                }}
                style={{
                  cursor: "pointer",
                  fontSize: "1em",
                  marginRight: "0.5rem",
                }}
              ></i>
              <i
                onClick={() => handleDelete(item)}
                className="far fa-trash-alt"
                style={{ cursor: "pointer", fontSize: "1em" }}
              ></i>
            </div>
          )
          return item
        })

        setRows(res.data)
      })
      .catch(err => {
        console.log(err?.response?.data)
      })
  }

  const handleToggle = id => {
    put(`batch/status/${id}`)
      .then(res => {
        getBatchList()
        toastr.success(res.message)
      })
      .catch(err => {
        toastr.error(err?.response?.data?.message)
      })
  }

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Batch ID",
        field: "batchId",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        width: 150,
      },

      {
        label: "Course",
        field: "courseName",
        width: 150,
      },
      {
        label: "Max. Students",
        field: "maxStrength",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        width: 150,
      },
    ],
    rows,
  }

  function toggleCustomJustified(tab) {
    if (activeTabJustify !== tab) {
      setactiveTabJustify(tab)
    }
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem="Batch Creation" />

          <Row>
            <Col md="12">
              <Col xl={12}>
                <Card>
                  <CardBody>
                    <Nav tabs className="nav-tabs-custom nav-justified">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTabJustify === "1",
                          })}
                          onClick={() => {
                            if (!master?.tempId && !isTabDisabled) {
                              toggleCustomJustified("1")
                            }
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-home"></i>
                          </span>
                          <span className="d-none d-sm-block">Basic</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTabJustify === "2",
                          })}
                          onClick={() => {
                            if (!master?.tempId && !isTabDisabled) {
                              toggleCustomJustified("2")
                            }
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-rupee-sign"></i>
                          </span>
                          <span className="d-none d-sm-block">Students</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTabJustify === "3",
                          })}
                          onClick={() => {
                            if (!master?.tempId && !isTabDisabled) {
                              toggleCustomJustified("3")
                            }
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-chalkboard-teacher"></i>
                          </span>
                          <span className="d-none d-sm-block">
                            Quality FrameWork
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTabJustify === "4",
                          })}
                          onClick={() => {
                            if (!master?.tempId && !isTabDisabled) {
                              toggleCustomJustified("4")
                            }
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-code"></i>
                          </span>
                          <span className="d-none d-sm-block">
                            Trainer Assign
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTabJustify === "5",
                          })}
                          onClick={() => {
                            if (!master?.tempId && !isTabDisabled) {
                              toggleCustomJustified("5")
                            }
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-code"></i>
                          </span>
                          <span className="d-none d-sm-block">Assessment</span>
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <BatchProvider
                      value={{
                        master,
                        setMaster,
                        next,
                        back,
                        getBatchList,
                        start,
                        reset,
                        setIsTabDisabled,
                      }}
                    >
                      <TabContent activeTab={activeTabJustify}>
                        <TabPane tabId="1" className="p-3">
                          <Basic />
                        </TabPane>
                        <TabPane tabId="2" className="p-3">
                          {activeTabJustify === "2" && <Students />}
                        </TabPane>
                        <TabPane tabId="3" className="p-3">
                          {activeTabJustify === "3" && <QualityFrameWork />}
                        </TabPane>
                        <TabPane tabId="4" className="p-3">
                          {activeTabJustify === "4" && (
                            <TrainerAllocation allowed={true} />
                          )}
                        </TabPane>
                        <TabPane tabId="5" className="p-3">
                          {activeTabJustify === "5" && <Assessment />}
                        </TabPane>
                      </TabContent>
                    </BatchProvider>
                  </CardBody>
                </Card>
              </Col>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="batchTableIdd"
                    responsive
                    bordered
                    info={true}
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                    totalPage={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default BatchCreation
