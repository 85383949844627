import React, { useState, useRef, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import moment from "moment"
import Select from "react-select"
import { MDBDataTable } from "mdbreact"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import TablePagination from "../../../components/Common/Pagination"

import { get } from "../../../helpers/api_helper"
import {
  getChartAccount,
  getAccountType,
  getAccountSubType,
} from "../../../store/options/actions"

import "./style.scss"
import { useLocation } from "react-router-dom"
import { getDate, numberToCurrency, setTitle } from "../../../helpers/functions"
import Tooltip from "@mui/material/Tooltip"

const TransactionLog = () => {
  setTitle("Transaction Log")
  const formRef = useRef()
  const { state } = useLocation()

  const date = getDate()
  const { chartOfAccount, accountType, accountSubType } = useSelector(
    state => state.Options
  )
  const dispatch = useDispatch()

  const initialState = {
    from: "",
    to: "",
    type: "",
    account: "",
  }

  const [userOptions, setUserOptions] = useState([])
  const [tableData, setTableData] = useState([])
  const [masterObject, setMasterObject] = useState(initialState)
  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 20

  useEffect(() => {
    dispatch(getChartAccount())
    dispatch(getAccountType())
    dispatch(getAccountSubType())
    handleUserOptions()

    if (state && state?.account)
      setMasterObject(prev => ({ ...prev, account: state.account }))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [masterObject])

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  const handleUserOptions = async () => {
    get(
      `/accounts/transaction/options/user?from=${masterObject.from}&to=${masterObject.to}&type=${masterObject.type}&account=${masterObject.account}&subType=${masterObject.subType}`
    ).then(res => setUserOptions(res.data))
  }
  const handleTableData = async (perPage = page) => {
    get(
      `/accounts/transaction/list?page=${perPage}&limit=${limit}&from=${masterObject.from}&to=${masterObject.to}&type=${masterObject.type}&account=${masterObject.account}&subType=${masterObject.subType}`
    ).then(res => {
      const result = res.data
      const count = res.count
      result.map((item, index) => {
        item.id = index + 1 + (perPage - 1) * limit

        item.credit = "---"
        item.debit = "---"

        if (item.type === 0) {
          item.credit = numberToCurrency(item.amount)
          item.type = "Credit"
        } else {
          item.debit = numberToCurrency(item.amount)
          item.type = "Debit"
        }

        item.date = moment(item.date).format("DD-MM-YYYY")
        item.addedDate = moment(item.addedDate).format("DD-MM-YYYY")

        item.balance = numberToCurrency(item.balance)

        item.remarks = (
          <Tooltip title={item.remarks} arrow placement="top">
            {String(item.remarks || "").substring(0, 35)}
            {item.remarks?.length > 35 ? "..." : ""}
          </Tooltip>
        )
        return item
      })

      const totalPage = Math.ceil(Number(count) / limit)
      // console.log(count, limit, totalPage)
      setTotalPage(totalPage)
      setTableData(result)
    })
  }

  const handleValueChange = async (value, name) => {
    const obj = { ...masterObject }
    obj[name] = value
    setMasterObject(obj)
    setPage(1)
  }
  const handleChange = (event, value) => {
    setPage(value)
    handleTableData(value)
  }
  const reset = () => {
    formRef.current.reset()
    setMasterObject(initialState)
    setPage(1)
    toTop()
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Added Date",
        field: "addedDate",
        width: 150,
      },
      {
        label: "Head",
        field: "accountType",
        width: 150,
      },
      {
        label: "Sub Head",
        field: "accountSubType",
        width: 150,
      },
      {
        label: "Account",
        field: "account",
        width: 150,
      },
      {
        label: "Tra. Id",
        field: "uniqueId",
        width: 150,
      },

      {
        label: "Debit",
        field: "debit",
        width: 150,
      },

      {
        label: "Credit",
        field: "credit",
        width: 150,
      },

      {
        label: "Balance",
        field: "balance",
        sort: "desc",
        width: 400,
      },
      {
        label: "Remark",
        field: "remarks",
        width: 150,
      },
      {
        label: "Added By",
        field: "staff",
        width: 150,
      },
    ],
    rows: tableData,
  }
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Transaction Log" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <AvForm ref={formRef}>
                    <Row>
                      <Col md={2}>
                        <div className="mb-3">
                          <Label>From </Label>
                          <AvField
                            name="from"
                            className="form-control"
                            type="date"
                            value={masterObject.from}
                            max={date}
                            onChange={e => {
                              handleValueChange(e.target.value, "from")
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="mb-3">
                          <Label>To </Label>
                          <AvField
                            name="to"
                            className="form-control"
                            type="date"
                            value={masterObject.to}
                            min={masterObject.from}
                            max={date}
                            onChange={e => {
                              handleValueChange(e.target.value, "to")
                            }}
                          />
                        </div>
                      </Col>

                      <Col md={2}>
                        <div className="mb-3">
                          <Label>Head</Label>
                          <Select
                            name="type"
                            placeholder="Select..."
                            options={accountType}
                            value={accountType.filter(
                              item => item.value === masterObject.type
                            )}
                            onChange={selected => {
                              handleValueChange(selected.value, "type")
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="mb-3">
                          <Label>Sub Head</Label>
                          <Select
                            name="subType"
                            placeholder="Select..."
                            options={accountSubType}
                            value={accountSubType.filter(
                              item => item.value === masterObject.subType
                            )}
                            onChange={selected => {
                              handleValueChange(selected.value, "subType")
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="mb-3">
                          <Label>Account</Label>
                          <Select
                            name="account"
                            placeholder="Select..."
                            options={chartOfAccount}
                            value={chartOfAccount.filter(
                              item => item.value === masterObject.account
                            )}
                            onChange={selected => {
                              handleValueChange(selected.value, "account")
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="mb-3">
                          <Label>Staff</Label>
                          <Select
                            name="staff"
                            placeholder="Select..."
                            options={userOptions}
                            value={userOptions.filter(
                              item => item.value === masterObject.user
                            )}
                            onChange={selected => {
                              handleValueChange(selected.value, "user")
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="mb-3 d-flex gap-2">
                          <Button color="danger" onClick={reset}>
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>

                  <MDBDataTable
                    id="transactionLog"
                    className="mt-3"
                    bordered
                    responsive
                    searching={false}
                    sortable={false}
                    entries={20}
                    paging={false}
                    info={false}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>

                  <TablePagination
                    page={page}
                    onChange={handleChange}
                    count={totalPage}
                  />
                  {/* <Pagination
                    key={page}
                    id="transactionLogPagination"
                    page={page}
                    onChange={handleChange}
                    count={totalPage}
                  /> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default TransactionLog
