import React, { useEffect, useState } from "react"
import { Row, Col, BreadcrumbItem, Modal } from "reactstrap"

import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import SummarizeIcon from "@mui/icons-material/Summarize"
import { Avatar, Box, Divider, Stack } from "@mui/material"
import { get } from "../../helpers/api_helper"

import Tooltip from "@mui/material/Tooltip"

import { Link } from "react-router-dom"
import { useLocation, useParams } from "react-router-dom"
import moment from "moment"
import YouTubeIcon from "@mui/icons-material/YouTube"
import Course from "./Tabs/Course"
import Summary from "./Tabs/Summary"
import Overview from "./Tabs/Overview"

const AssetDashboard = () => {
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/"

  const { state } = useLocation()
  const [tab, setTab] = React.useState("1")
  const [fetchData, setFetchData] = useState({})
  // console.log("fetchData: ", fetchData)
  const [modal, setModal] = useState(false)
  const [reload, setReload] = useState(false)
  const { cspid } = useParams()
  

  useEffect(() => {
    handleTableData()

    // eslint-disable-next-line
  }, [reload])


  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function togModal() {
    setModal(!modal)
    removeBodyCss()
  }

 

  function handleTableData() {
    get(`branch/single/${state?.id || cspid}`)
      .then(res => {
        const result = res?.branch
        setFetchData(result)
      })
      .catch(error => {
        console.error("Error fetching data:", error)
      })
  }

  const handleChangeTab = (event, newValue) => {
    setTab(newValue)
  }

  const reloadToggle = () => {
    setReload(pre => !pre)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">{fetchData?.name}</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      <Link to="/">Home</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="/view-csp">CSP</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="#">{fetchData?.name}</Link>
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              {/* First Dialogue */}

              <div>
                <div className="row">
                  <div className="col-xl-3">
                    <div
                      className="card"
                      style={{
                        // width: "fit-content",
                        // width: "351px",
                        height: "max-content",
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                      }}
                    >
                      <div className="card-body">
                        {fetchData?.videoURL && (
                          <Tooltip title="View video">
                            <span
                              onClick={togModal}
                              style={{ position: "absolute", right: "1rem" }}
                            >
                              <YouTubeIcon
                                style={{
                                  cursor: "pointer",
                                  color: "#00afc3",
                                  fontSize: "1.6rem",
                                }}
                              />
                            </span>
                          </Tooltip>
                        )}

                        <Stack
                          direction="row"
                          spacing={2}
                          style={{ justifyContent: "center" }}
                        >
                          <Avatar
                            alt={fetchData?.name}
                            src={`${API_URL}${fetchData?.image}`}
                            sx={{ width: 56, height: 56 }}
                            style={{
                              width: "6rem",
                              height: "6rem",
                              marginBottom: "auto",
                            }}
                          />
                        </Stack>

                        <div className="float-end dropdown"></div>
                        {/* <p style={{ marginBottom: "0px", textAlign: "center" }}>
                          <img
                            style={{ width: "95px" }}
                            src={`${API_URL}${fetchData?.operatingPartner?.logo}`}
                            alt=""
                          />
                        </p> */}
                        <h5
                          className="mt-2 mb-1"
                          style={{
                            textAlign: "center",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}
                        >
                          {fetchData?.name}
                        </h5>

                        <Divider />
                        <div className="mt-2">
                          <p className="mb-1">Branch ID:</p>
                          <h5 className="font-size-14">
                            {fetchData?.branchId}
                          </h5>
                        </div>

                        <div className="mt-2">
                          <p className="mb-1">Operating Partner:</p>
                          <h5 className="font-size-14">
                            {fetchData?.operatingPartner?.name}
                          </h5>
                        </div>

                        <div className="mt-2">
                          <p className="mb-1">Code:</p>
                          <h5 className="font-size-14">
                            {fetchData?.shortCode}
                          </h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">Contact Person:</p>
                          <h5 className="font-size-14">
                            {fetchData?.contactPerson}
                          </h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">Designation:</p>
                          <h5 className="font-size-14">
                            {fetchData?.designation}
                          </h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">Mobile:</p>
                          <h5 className="font-size-14">{fetchData?.mobile}</h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">Email:</p>
                          <h5 className="font-size-14">{fetchData?.email}</h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">Landline:</p>
                          <h5 className="font-size-14">
                            {fetchData?.landLineNumber}
                          </h5>
                        </div>

                        <div className="mt-2">
                          <p className="mb-1">City:</p>
                          <h5 className="font-size-14">{fetchData?.city}</h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">Address:</p>
                          <h5 className="font-size-14">{fetchData?.address}</h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">Pincode:</p>
                          <h5 className="font-size-14">{fetchData?.pincode}</h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">Distrct:</p>
                          <h5 className="font-size-14">
                            {fetchData?.district?.district_name}
                          </h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">State:</p>
                          <h5 className="font-size-14">
                            {fetchData?.state?.name}
                          </h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">Zone:</p>
                          <h5 className="font-size-14">
                            {fetchData?.zone?.name}
                          </h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">Latitude:</p>
                          <h5 className="font-size-14">
                            {fetchData?.latitude}
                          </h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">Longitude:</p>
                          <h5 className="font-size-14">
                            {fetchData?.longitude}
                          </h5>
                        </div>

                        <div className="mt-2">
                          <p className="mb-1">Added by:</p>
                          <h5 className="font-size-14">
                            {fetchData?.added_by?.firstName}{" "}
                            {fetchData?.added_by?.lastName}
                          </h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">Date :</p>
                          <h5 className="font-size-14">
                            {fetchData?.date &&
                              moment(fetchData?.date, "YYYY-MM-DD").format(
                                "DD-MM-YYYY"
                              )}
                          </h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">Time :</p>
                          <h5 className="font-size-14">
                            {fetchData?.time &&
                              moment(fetchData?.time, "HH:mm:ss").format(
                                "hh:mm a"
                              )}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-9" style={{ marginBottom: "30px" }}>
                    <div
                      className="mb-0 card"
                      style={{
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={tab}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              indicatorColor="primary"
                              textColor="primary"
                              variant="fullWidth"
                              onChange={handleChangeTab}
                              aria-label="lab API tabs example"
                            >
                              <Tab
                                icon={
                                  <SummarizeIcon style={{ fontSize: "20px" }} />
                                }
                                label="Summary"
                                value="1"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <SummarizeIcon style={{ fontSize: "20px" }} />
                                }
                                label="Overview"
                                value="2"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              {/* <Tab
                                icon={
                                  <SummarizeIcon style={{ fontSize: "20px" }} />
                                }
                                label="Courses"
                                value="3"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              /> */}
                            </TabList>
                          </Box>

                          <TabPanel
                            value="1"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Summary id={state?.id || cspid} />
                          </TabPanel>

                          <TabPanel
                            value="2"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Overview
                              details={fetchData}
                              reloadToggle={reloadToggle}
                            />
                          </TabPanel>
                          <TabPanel
                            value="3"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Course />
                          </TabPanel>
                        </TabContext>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        size="lg"
        isOpen={modal}
        centered={true}
        // toggle={() => {
        //   togModal()
        // }}
      >
        <div className="modal-header">
          <button
            onClick={() => {
              setModal(false)
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9">
            <iframe
              title="infoVideo"
              allowFullScreen
              className="embed-responsive-item"
              src={fetchData?.videoURL}
            />
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default AssetDashboard
