import React, { useEffect, useRef, useState } from "react"
import { Card, CardBody, Col, Container, FormGroup, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useLocation, useParams } from "react-router-dom"

import toastr from "toastr"
import { Box, Divider } from "@mui/material"
import Avatar from "@mui/material/Avatar"
import Stack from "@mui/material/Stack"

import PersonIcon from "@mui/icons-material/Person"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import LocationCityIcon from "@mui/icons-material/LocationCity"
import AccountBalanceIcon from "@mui/icons-material/AccountBalance"

// import Select from "react-select"
import { API_URL, APP_NAME } from "../../../config"
import moment from "moment"
import { get, put } from "../../../helpers/api_helper"
import { MDBDataTable } from "mdbreact"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Modal } from "react-bootstrap"
import BankAccount from "./Tabs/BankAccount"
import TablePagination from "../../../components/Common/Pagination"

import "./style.scss"
import useUser from "../../../hooks/useUser"
import { encrypt } from "../../../helpers/functions"
import useGender from "../../hooks/useGender"
const UserDashboard = () => {
  const { state } = useLocation()
  const test = useParams()
  const [details, setDetails] = useState({})

  const user = useUser()
  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 20

  document.title = `${test._id || details?.userId} | ${APP_NAME}`

  const formRef = useRef()

  const [userLogData, setuserLogData] = useState([])
  const [salaryData, setSalaryData] = useState([])

  const [reload, setReload] = useState(false)

  const [modal, setModal] = useState(false)
  const [tab, setTab] = useState("1")
  const [master, setMaster] = useState({})

  const [passShow, setpassShow] = useState(false)

  useEffect(() => {
    get(`user/single?user_id=${state?.id || test.user_id}`)
      .then(res => {
        const data = res.user

        const transformedSalaryData = data?.salary
          .filter(item => item.course)
          .map((item, idx) => {
            item.courses = item.course.name
            item.id = idx + 1 + (page - 1) * limit
            return item
          })

        setSalaryData(transformedSalaryData)
        data.branch =
          data?.branch?.map((item, index) => item.name).join(", ") || ""
        data.company =
          data?.company?.map(item => (
            <span className="me-2">{item.name}</span>
          )) || ""
        data.privilage = data.privilage?.privilege_name || ""
        setDetails(data)
        get(
          `security/activity?user_id=${data._id}&page=${page}&limit=${limit}`
        ).then(res => {
          const data = res.user

          data.map((item, idx) => {
            item.id = idx + 1

            item.time =
              item.time && moment(item.time, "HH:mm:ss").format("hh:mm a")
            return item
          })
          const count = res.count
          const totalPage = Math.ceil(Number(count) / limit)
          setTotalPage(totalPage)
          setuserLogData(data)
        })
      })
      .catch(err => {
        console.log(err)
      })
    // eslint-disable-next-line
  }, [reload, page])
  const handlePageChange = (event, value) => {
    setPage(value)
  }
  const handleChangeTab = (event, newValue) => {
    setTab(newValue)
  }

  function handleChange(e) {
    let { name, value } = e.target
    setMaster(pre => ({ ...pre, [name]: value }))
  }

  function handleReset() {
    formRef.current.reset()
    setMaster({})
  }

  function handleSubmit() {
    put("user/password-reset", { data: encrypt(master) })
      .then(res => {
        handleReset()
        setModal(false)
        toastr.success(res.message)
      })
      .catch(err => {
        console.log(err.response.data)
      })
  }

  const handleReload = () => setReload(pre => !pre)

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 200,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 200,
      },
      {
        label: "Created IP",
        field: "activity_ip",
        sort: "asc",
        width: 200,
      },
      {
        label: "Activity",
        field: "activity_desc",
        sort: "asc",
        width: 200,
      },
    ],
    rows: userLogData,
  }
  const salary = {
    columns: [
      {
        label: "#",
        field: "id",

        width: 100,
      },
      {
        label: "Course",
        field: "courses",
        width: 200,
      },
      {
        label: "Salary(hourly)",
        field: "amount",

        width: 200,
      },
    ],
    rows: salaryData,
  }

  return (
    <React.Fragment>
      <Modal
        show={modal}
        toggle={() => {
          setModal(pre => !pre)
          handleReset()
        }}
        centered={true}
        size="md"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Confirmation</h5>
          <button
            type="button"
            onClick={() => {
              setModal(pre => !pre)
              handleReset()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <AvForm
          className="needs-validation"
          ref={formRef}
          onValidSubmit={(e, v) => {
            handleSubmit()
          }}
        >
          <div className="modal-body">
            <Row>
              <Col md="12">
                <FormGroup className="my-3 position-relative" id="confirm-pass">
                  <AvField
                    name="password"
                    placeholder="Password"
                    type={`${passShow ? "text" : "password"}`}
                    errorMessage=" Please provide a valid password"
                    validate={{ required: { value: true } }}
                    id="validationCustom05"
                    value={master.password || ""}
                    onChange={handleChange}
                  />
                  {passShow ? (
                    <i
                      className="fas fa-eye-slash position-absolute"
                      onClick={() => setpassShow(pre => !pre)}
                      style={{
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                    ></i>
                  ) : (
                    <i
                      className="fas fa-eye position-absolute"
                      onClick={() => setpassShow(pre => !pre)}
                      style={{
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                    ></i>
                  )}
                </FormGroup>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <AvField
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    type={`${passShow ? "text" : "password"}`}
                    errorMessage="Please confirm the password"
                    className="form-control"
                    validate={{
                      required: { value: true },
                      match: {
                        value: "password",
                        errorMessage: "Password not match",
                      },
                    }}
                    id="validationCustom05"
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-dark"
              type="button"
              style={{ marginRight: "1rem" }}
              onClick={() => {
                setModal(pre => !pre)
                handleReset()
              }}
            >
              Close
            </button>
            <button className="btn btn-primary" type="submit">
              Confirm
            </button>
          </div>
        </AvForm>
      </Modal>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Home" breadcrumbItem={details?.userId} />

          <Row>
            <div className="col-xl-3">
              <div
                className="card"
                style={{
                  height: "max-content",
                  boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                }}
              >
                <div className="card-body">
                  <Stack
                    direction="row"
                    spacing={2}
                    style={{ justifyContent: "center" }}
                  >
                    <Avatar
                      alt={details?.firstName}
                      src={`${API_URL}${details?.user_image}`}
                      sx={{ width: 56, height: 56 }}
                      style={{
                        width: "6rem",
                        height: "6rem",
                        marginBottom: "auto",
                      }}
                    />
                  </Stack>
                  {user._id === details._id && (
                    <i
                      className="fas fa-key"
                      style={{
                        fontSize: "1em",
                        position: "relative",
                        float: "right",
                        bottom: "83px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setMaster(pre => ({ ...pre, id: details._id }))
                        setModal(pre => !pre)
                      }}
                    ></i>
                  )}
                  <h5
                    className="mt-3 mb-1"
                    style={{
                      textAlign: "center",
                      fontFamily: "IBM Plex Sans,sans-serif",
                    }}
                  >
                    {details?.username}
                  </h5>
                  <div
                    className="text-muted"
                    style={{
                      textAlign: "center",
                      color: "green !important",
                      fontFamily: "IBM Plex Sans,sans-serif",
                      fontSize: "14px",
                      marginBottom: "3px",
                    }}
                  >
                    {details?.mobile}
                  </div>
                  <div
                    className="text-muted"
                    style={{
                      textAlign: "center",
                      color: "green !important",
                      fontFamily: "IBM Plex Sans,sans-serif",
                      fontSize: "14px",
                      marginBottom: "3px",
                    }}
                  >
                    {details?.designation}
                  </div>
                  <p
                    className="text-muted"
                    style={{
                      textAlign: "center",
                      color: "green !important",
                      fontFamily: "IBM Plex Sans,sans-serif",
                      fontSize: "15px",
                      marginBottom: "0",
                    }}
                  >
                    {/* {details?.domain_name} */}
                  </p>
                  <div
                    className="mt-1 mb-2"
                    style={{
                      textAlign: "center",
                      fontFamily: "IBM Plex Sans,sans-serif",
                    }}
                  ></div>
                  <Divider />
                  <div className="mt-2" hidden={!details?.userId}>
                    <p className="mb-1">Full Name:</p>
                    <h5 className="font-size-14">
                      {details?.firstName} {details?.lastName}
                    </h5>
                  </div>

                  <div className="mt-2" hidden={!details?.userId}>
                    <p className="mb-1">Staff ID:</p>
                    <h5 className="font-size-14">{details?.userId}</h5>
                  </div>

                  <div className="mt-2" hidden={!details?.email}>
                    <p className="mb-1">Personal Email:</p>
                    <h5 className="font-size-14">{details?.email}</h5>
                  </div>

                  <div className="mt-2" hidden={!details?.gender}>
                    <p className="mb-1">Gender:</p>
                    <h5 className="font-size-14">
                      {useGender(details?.gender)}
                    </h5>
                  </div>

                  <div className="mt-2" hidden={!details?.user_dob}>
                    <p className="mb-1">DOB:</p>
                    <h5 className="font-size-14">
                      {details?.user_dob
                        ? moment(details.user_dob).format("DD-MM-YYYY")
                        : ""}
                    </h5>
                  </div>

                  <div className="mt-2" hidden={!details?.address}>
                    <p className="mb-1">Address:</p>
                    <h5 className="font-size-14">{details?.address}</h5>
                  </div>

                  <div
                    className="mt-2"
                    hidden={!details?.district?.district_name}
                  >
                    <p className="mb-1">District:</p>
                    <h5 className="font-size-14">
                      {details?.district?.district_name}
                    </h5>
                  </div>

                  <div className="mt-2" hidden={!details?.state?.name}>
                    <p className="mb-1">State:</p>
                    <h5 className="font-size-14">{details?.state?.name}</h5>
                  </div>

                  <div className="mt-2" hidden={!details?.qualification?.name}>
                    <p className="mb-1">Qualification:</p>
                    <h5 className="font-size-14">
                      {details?.qualification?.name}
                    </h5>
                  </div>

                  <div className="mt-2" hidden={!details?.privilage}>
                    <p className="mb-1">Staff Type:</p>
                    <h5 className="font-size-14">{details?.privilage}</h5>
                  </div>

                  <div className="mt-2" hidden={!details?.salary}>
                    <p className="mb-1"> Salary Type:</p>
                    <h5 className="font-size-14">
                      {details?.salaryType === 1 ? "Hourly" : "Monthly"}
                    </h5>
                  </div>

                  <div className="mt-2" hidden={!details?.company}>
                    <p className="mb-1">Company:</p>
                    <h5 className="font-size-14">{details?.company}</h5>
                  </div>

                  <div className="mt-2" hidden={!details?.branch}>
                    <p className="mb-1">Branch:</p>
                    <h5 className="font-size-14">{details?.branch}</h5>
                  </div>

                  {/* <div className="mt-2" hidden={!details?.userId}>
                    <p className="mb-1">GENDER:</p>
                    <h5 className="font-size-14">{details?.sex}</h5>
                  </div> */}

                  <div className="mt-2" hidden={!details?.designation}>
                    <p className="mb-1">Designation:</p>
                    <h5 className="font-size-14">{details?.designation}</h5>
                  </div>
                  <div className="mt-2" hidden={!details?.user_joining_date}>
                    <p className="mb-1">Join Date:</p>
                    <h5 className="font-size-14">
                      {details?.user_joining_date
                        ? moment(details.user_joining_date).format("DD-MM-YYYY")
                        : ""}
                    </h5>
                  </div>
                  <div className="mt-2" hidden={!details?.user_resigning_date}>
                    <p className="mb-1">Resign Date:</p>
                    <h5 className="font-size-14">
                      {details?.user_resigning_date
                        ? moment(details.user_resigning_date).format(
                            "DD-MM-YYYY"
                          )
                        : ""}
                    </h5>
                  </div>

                  <div className="mt-2" hidden={!details?.working_status}>
                    <p className="mb-1">Staff Status:</p>
                    <h5 className="font-size-14">{details?.working_status}</h5>
                  </div>

                  <div className="mt-2">
                    <p className="mb-1">User Type:</p>
                    <h5 className="font-size-14">
                      {details?.user_type?.includes(0) && "CRM"}
                      {details?.user_type?.includes(1) && "App"}
                    </h5>
                  </div>

                  <div className="mt-2" hidden={details?.app_user !== 1}>
                    <p className="mb-1">Language:</p>
                    <h5 className="font-size-14">
                      {details?.app_language === 1 ? "English" : "Malayalam"}
                    </h5>
                  </div>

                  <div className="mt-2" hidden={!details?.app_version}>
                    <p className="mb-1">Version:</p>
                    <h5 className="font-size-14">{details?.app_version}</h5>
                  </div>

                  <div className="mt-2" hidden={!details?.addedBy?.firstName}>
                    <p className="mb-1">Added by:</p>
                    <h5 className="font-size-14">{`${
                      details?.addedBy?.firstName || ""
                    } ${details?.addedBy?.lastName || ""}`}</h5>
                  </div>

                  <div className="mt-2" hidden={!details?.updatedBy?.firstName}>
                    <p className="mb-1">Updated by:</p>
                    <h5 className="font-size-14">{`${
                      details?.updatedBy?.firstName || ""
                    } ${details?.updatedBy?.lastName || ""}`}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-9" style={{ marginBottom: "30px" }}>
              <div
                className="mb-0 card"
                style={{
                  boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                  width: "100%",
                }}
              >
                <TabContext value={tab}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      indicatorColor="primary"
                      textColor="primary"
                      variant="fullWidth"
                      onChange={handleChangeTab}
                      aria-label="lab API tabs example"
                    >
                      <Tab
                        icon={<LocationCityIcon style={{ fontSize: "20px" }} />}
                        label="Activity log"
                        value="1"
                        size="small"
                        style={{
                          textTransform: "capitalize",
                          fontFamily: "IBM Plex Sans,sans-serif",
                        }}
                      />
                      <Tab
                        icon={
                          <AccountBalanceIcon style={{ fontSize: "20px" }} />
                        }
                        label="Bank"
                        value="2"
                        size="small"
                        style={{
                          textTransform: "capitalize",
                          fontFamily: "IBM Plex Sans,sans-serif",
                        }}
                      />
                      <Tab
                        icon={<PersonIcon style={{ fontSize: "20px" }} />}
                        label="Hourly Salary"
                        value="3"
                        size="small"
                        style={{
                          textTransform: "capitalize",
                          fontFamily: "IBM Plex Sans,sans-serif",
                        }}
                      />
                      {/* <Tab
                        label=""
                        value=""
                        size="small"
                        style={{
                          textTransform: "capitalize",
                          fontFamily: "IBM Plex Sans,sans-serif",
                        }}
                        disabled={true}
                      />
                      <Tab
                        label=""
                        value=""
                        size="small"
                        style={{
                          textTransform: "capitalize",
                          fontFamily: "IBM Plex Sans,sans-serif",
                        }}
                        disabled={true}
                      /> */}
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <Row>
                      <Col className="col-12">
                        <Card>
                          <CardBody>
                            <MDBDataTable
                              id="userActivitylogDataId"
                              responsive
                              bordered
                              data={data}
                              disableRetreatAfterSorting={true}
                              searching={false}
                              paging={false}
                              info={false}
                              sortable={false}
                            ></MDBDataTable>
                            <TablePagination
                              page={page}
                              onChange={handlePageChange}
                              count={totalPage}
                            />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </TabPanel>
                  <TabPanel value="2">
                    <BankAccount user={details} handleReload={handleReload} />
                  </TabPanel>
                  <TabPanel value="3">
                    <Row>
                      <Col className="col-12">
                        <Card>
                          <CardBody>
                            <MDBDataTable
                              id="salaryDataTable"
                              responsive
                              bordered
                              data={salary}
                              // disableRetreatAfterSorting={true}
                              entries={20}
                            />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </TabPanel>
                </TabContext>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UserDashboard
