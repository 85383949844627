import React, { useEffect, useRef, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  FormGroup,
} from "reactstrap"
import { Modal } from "react-bootstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import {
  getBranches,
  getCompaniesOptions,
  getDistrictOptions,
  getModules,
  getPrivileges,
} from "../../store/options/actions"
import { del, get, post, put } from "../../helpers/api_helper"
import toastr from "toastr"
import axios from "axios"
import moment from "moment"
import Swal from "sweetalert2"
import { API_URL, APP_NAME, COMPANY_TYPE, PRIVILEGES } from "../../config"
import { useNavigate } from "react-router-dom"
import "./styles.scss"
import { encrypt, genderValue, jsonToExcel } from "../../helpers/functions"
import { Tooltip } from "@mui/material"
import SelectErrorMessage from "../../components/Common/SelectErrorMessage"

const User = props => {
  document.title = `Users | ${APP_NAME}`

  const options = props.options

  const user = JSON.parse(localStorage.getItem("authUser")) || null

  const [selectedCourses, setSelectedCourses] = useState([])

  const [selectedState, setSelectedState] = useState({
    _id: 19,
    name: "Kerala",
    label: "Kerala",
    value: 19,
  })
  const [selectedDistrict, setSelectedDistrict] = useState(null)

  const [selectedGender, setSelectedGender] = useState(null)

  const [selectedQualification, setSelectedQualification] = useState(null)

  const [privilege, setPrivilege] = useState(null)

  const [courses, setCourses] = useState([])

  const [error, setError] = useState({})

  const formRef = useRef()
  const navigate = useNavigate()

  const [exports, setExports] = useState([])

  const [master, setMaster] = useState({})
  const [userObject, setUserObject] = useState({ salary: [{}], state: 19 })
  const [selectedPrivilege, setSelectedPrivilege] = useState(null)
  const [selectedModule, setSelectedModule] = useState(null)
  const [tableData, setTableData] = useState([])
  const [selectedCompany, setSelectedCompany] = useState(null)

  const [modal, setModal] = useState(false)
  const [passShow, setpassShow] = useState(false)

  const [display, setDisplay] = useState({ csp: false })

  const [selectedBranch, setSelectedBranch] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPrivileges(user.privilage))
    dispatch(getModules())
    dispatch(getCompaniesOptions())
    dispatch(getDistrictOptions({ id: 19 }))
    // eslint-disable-next-line
  }, [dispatch])

  const {
    privileges,
    modules,
    branches,
    companies,
    states,
    distrcts,
    qualification,
  } = useSelector(state => state.Options)

  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [options])

  useEffect(() => {
    if (userObject.company) {
      get(
        `options/partners-courses?trainingPartner=${userObject.company}`
      ).then(res => {
        setCourses(res.data)
      })
    }
  }, [userObject.company])

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  const uploadImage = e => {
    const fd = new FormData()
    fd.append("user_image", e.target.files[0])
    axios
      .post(`${API_URL}user/image`, fd, {
        // onUploadProgress: data => {
        //   // setUploadProgress(Math.round((100 * data.loaded) / data.total));
        // },
      })
      .then(response => {
        if (response.data.status === 200) {
          setUserObject({
            ...userObject,
            user_image: response.data.data.new_filename,
          })
        } else {
          toastr.error(response.data.message)
        }
      })
  }

  function handleChange(e) {
    let { name, value } = e.target
    const values = { [name]: value }
    if (name === "firstName" || name === "username")
      values.username = value.toLowerCase().replace(/[^a-zA-Z0-9]/g, "")
    setUserObject(pre => ({ ...pre, ...values }))
  }

  function handleSubmit() {
    const details = { ...userObject }

    let flag = false

    if (selectedCompany === null) {
      flag = true
      setError(pre => ({ ...pre, company: true }))
    }

    if (selectedPrivilege === null) {
      flag = true
      setError(pre => ({ ...pre, privilege: true }))
    }

    if (selectedPrivilege?.value === PRIVILEGES.TRAINER) {
      details.salary.map(item => {
        if (item.course === null || item.course === undefined) {
          item.courseError = true
          flag = true
        }
        return item
      })
    }

    setUserObject(details)

    if (flag) {
      return
    }

    if (userObject.id) {
      put("user", userObject)
        .then(res => {
          toastr.success(res.message)
          reset()
          handleTableData()
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    } else {
      post("user", userObject)
        .then(res => {
          toastr.success(res.message)
          reset()
          handleTableData()
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    }
  }

  function handlePasswordReset() {
    put("user/password-reset", { data: encrypt(master) })
      .then(res => {
        if (master.resendNotification) {
          handleTableData()
        }
        handleReset()
        setModal(false)
        toastr.success(res.message)
      })
      .catch(err => {
        toastr.error(err?.response?.data?.message)
        console.log(err.response.data)
      })
  }

  function reset() {
    setUserObject({ state: 19, salary: [{}] })
    formRef.current.reset()
    setSelectedPrivilege(null)
    setSelectedCompany(null)
    setSelectedModule(null)
    setSelectedBranch(null)
    setPrivilege(null)
    setSelectedGender(null)
    setSelectedQualification(null)
    setSelectedDistrict(null)
    setSelectedState(null)
    setSelectedCourses([])
    setDisplay(pre => ({ ...pre, csp: false }))
  }

  function handleReset() {
    formRef.current.reset()
    setMaster({})
  }

  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`user/${id}`)
          .then(res => {
            toastr.success(res.message)
            handleTableData()
          })
          .catch(err => {
            toastr.error(err?.response?.data?.message)
          })
      }
    })
  }

  const handleTwoFactor = (e, user) => {
    const { name, checked } = e.target

    const data = encrypt({ value: checked, user })

    put(`user/two-factor`, { data })
      .then(res => {
        toastr.success(res.message)
        handleTableData()
      })
      .catch(err => {
        toastr.error(err?.response?.data?.message)
      })
  }

  function handleTableData() {
    get("user")
      .then(res => {
        const data = res.user

        const exportData = []
        data.map((item, index) => {
          item.id = index + 1

          item.name1 = `${item?.firstName || ""} ${item?.lastName || ""}`

          item.privilage1 = item.privilage && item.privilage.privilege_name
          item.date = moment(item.date).format("DD-MM-YYYY")
          if (item?.updatedBy) {
            let updatedBy = item?.updatedBy || {}
            if (updatedBy?.lastName)
              item.staff = updatedBy?.firstName + " " + updatedBy?.lastName
            else item.staff = updatedBy?.firstName
          } else {
            let addedBy = item?.addedBy || {}
            if (addedBy?.lastName)
              item.staff = addedBy?.firstName + " " + addedBy?.lastName
            else item.staff = addedBy?.firstName
          }

          item.twoFactor = (
            <div className="form-check form-switch d-flex justify-content-center">
              <input
                className="cursor-pointer form-check-input"
                type="checkbox"
                onChange={e => handleTwoFactor(e, item._id)}
                checked={item?.twoFactor?.enabled || false}
                name="twoFactor"
              />
            </div>
          )

          item.userType = (
            <div className="text-center">
              <>
                {item.user_type.includes(0) && (
                  <i
                    className="uil-globe"
                    style={{
                      fontSize: "1.5em",
                      cursor: "pointer",
                      marginLeft: "0.2em",
                      marginRight: "0.3em",
                      color: "green",
                    }}
                  ></i>
                )}
                {item.user_type.includes(1) && (
                  <Tooltip
                    title={`version ${item?.app_version || ""}`}
                    arrow
                    placement="top"
                  >
                    <i
                      className="uil-android"
                      style={{
                        fontSize: "1.5em",
                        cursor: "pointer",
                        marginLeft: "0.2em",
                        marginRight: "0.5em",
                        color: "green",
                      }}
                    ></i>
                  </Tooltip>
                )}
              </>
            </div>
          )

          if (item?.status === 0) {
            item.block_status = (
              <Tooltip title="Block" arrow placement="left">
                <i
                  onClick={() => handleStatus(item._id)}
                  style={{
                    fontSize: "20px",
                    color: "green",
                    cursor: "pointer",
                  }}
                  className="uil-lock-alt"
                ></i>
              </Tooltip>
            )
          } else if (item?.status === 2) {
            item.block_status = (
              <Tooltip title="Unblock" arrow placement="left">
                <i
                  onClick={() => handleStatus(item._id)}
                  style={{ fontSize: "20px", color: "red", cursor: "pointer" }}
                  className="uil-lock-open-alt"
                ></i>
              </Tooltip>
            )
          }

          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Tooltip placement="top" arrow title="Resend email">
                {item?.updated && (
                  <i
                    className="fa"
                    style={{
                      fontSize: "1em",
                      cursor: "pointer",
                      marginLeft: "0.2em",
                      marginRight: "0.5em",
                    }}
                    onClick={() => {
                      setMaster(pre => ({
                        ...pre,
                        id: item._id,
                        resendNotification: true,
                      }))
                      setModal(true)
                    }}
                  >
                    &#xf021;
                  </i>
                )}
              </Tooltip>
              {/* <i
                className="fas fa-key"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  // setShowModal(true);
                  // setUserIdToBeUpdated(item._id);
                }}
              ></i> */}
              {/* {options.view && ( */}
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  navigate(`/user/${item.userId}`, {
                    state: { id: item._id },
                  })
                }}
              ></i>
              {/* )} */}

              {/* {options.edit && ( */}
              <>
                <Tooltip arrow placement="top" title="Change password">
                  <i
                    className="fas fa-key"
                    style={{
                      fontSize: "1em",
                      cursor: "pointer",
                      marginLeft: "0.1em",
                      marginRight: "0.5em",
                    }}
                    onClick={() => {
                      setMaster(pre => ({ ...pre, id: item._id }))
                      setModal(true)
                    }}
                  ></i>
                </Tooltip>
                <i
                  className="far fa-edit"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    toTop()
                    handleUpdate(item._id)
                  }}
                ></i>
              </>
              {/* )} */}
              {/* {options.delete && ( */}
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  handleDelete(item._id)
                }}
              ></i>
              {/* )} */}
            </div>
          )

          // TODO: Export details
          const dataExport = {}

          dataExport["#"] = index + 1
          dataExport.Date = item.date
          dataExport["User Id"] = item.userId

          dataExport.Username = item.username
          dataExport.Name = item.name1
          dataExport.Mobile = item.mobile
          dataExport.Privilege = item.privilage1
          dataExport.Staff = item.staff
          exportData.push(dataExport)

          return item
        })
        setExports(exportData)
        setTableData(data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleExport = () => {
    if (exports.length > 0) {
      jsonToExcel(exports, `users-${moment().format("DD-MM-YY")}`)
    } else {
      toastr.info(`There are no users to export`)
    }
  }

  function handleUpdate(id) {
    get(`user/single?user_id=${id}`)
      .then(res => {
        const data = res.user

        const courses = []

        data?.salary?.map(item => {
          item.courseSelected = {
            label: item?.course?.name,
            value: item?.course?._id,
          }

          item.course = item?.course?._id

          courses.push(item.course)

          return item
        })

        setSelectedModule({
          value: data?.modules?._id || null,
          label: data?.modules?.moduleName || null,
        })

        setSelectedPrivilege({
          value: data?.privilage?._id,
          label: data?.privilage?.privilege_name,
        })

        setSelectedState({
          label: data?.state?.name,
          value: data?.state?._id,
        })

        if (data?.state?._id)
          dispatch(getDistrictOptions({ id: data?.state?._id }))

        setSelectedDistrict({
          label: data?.district?.district_name,
          value: data?.district?._id,
        })

        setSelectedGender({
          label: genderValue(data?.gender),
          value: data?.gender,
        })

        setSelectedQualification({
          label: data?.qualification?.name,
          value: data?.qualification?._id,
        })

        data.state = data?.state?._id
        data.district = data?.district?._id
        data.qualification = data?.qualification?._id

        if (data?.privilage?._id === PRIVILEGES.RECRUITER) {
          setPrivilege(data?.privilage?._id)
        }

        if (data.company) {
          setSelectedCompany({
            label: data?.company[0]?.name,
            value: data?.company[0]?._id,
          })

          if (data?.company[0]?.type.includes(COMPANY_TYPE.ASAP)) {
            dispatch(getBranches({ operatingPartner: "" }))
          } else {
            dispatch(getBranches({ operatingPartner: data?.company[0]?._id }))
          }

          if (
            data?.company[0]?.type?.includes(COMPANY_TYPE.RECRUITER) &&
            data?.company[0]?.type?.length === 1
          ) {
            setPrivilege(PRIVILEGES.RECRUITER)
          } else {
            setPrivilege(null)
          }
        }

        const branch = data?.branch?.map(item => ({
          label: item.name,
          value: item._id,
        }))

        if (
          data?.company[0] &&
          (data?.company[0]?.type.includes(PRIVILEGES.OPERATING_PARTNER) ||
            data?.company[0]?.type.includes(COMPANY_TYPE.ASAP))
        ) {
          setDisplay(pre => ({ ...pre, csp: true }))
        }

        setSelectedBranch(branch)

        data.id = data?._id
        data.privilage = data?.privilage?._id
        data.modules = data?.modules?._id
        data.branch = data?.branch?.map(item => item._id)
        data.company = data.company && data?.company[0]?._id
        delete data.createdAt
        delete data.date
        delete data.updatedAt
        delete data.__v
        setUserObject(data)
        setSelectedCourses(courses)
      })
      .catch(err => {
        console.log(err)
      })
  }

  function handleStatus(user_id) {
    put(`user/status?user_id=${user_id}`)
      .then(res => {
        handleTableData()
        toastr.success(res.message)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleSelect = (e, name) => {
    let value = e.value

    const data = { [name]: value }

    switch (name) {
      case "company":
        if (e.type.includes(PRIVILEGES.OPERATING_PARTNER))
          setDisplay(pre => ({ ...pre, csp: true }))
        else setDisplay(pre => ({ ...pre, csp: false }))

        if (e.type.includes(COMPANY_TYPE.ASAP)) {
          dispatch(getBranches({ operatingPartner: "" }))
        } else {
          dispatch(getBranches({ operatingPartner: e.value }))
        }
        if (e.type.includes(COMPANY_TYPE.RECRUITER) && e.type.length === 1) {
          setPrivilege(PRIVILEGES.RECRUITER)
        } else {
          setPrivilege(null)
        }
        setSelectedPrivilege(null)
        setSelectedBranch(null)
        data.branch = null
        data.privilage = null
        data.salary = [{}]
        setSelectedCompany(e)
        break
      case "state":
        dispatch(getDistrictOptions({ id: e.value }))
        setSelectedState(e)
        setSelectedDistrict(null)
        data.district = null
        break
      case "district":
        setSelectedDistrict(e)
        break
      case "gender":
        setSelectedGender(e)
        break
      case "qualification":
        setSelectedQualification(e)
        break
      default:
        break
    }
    setUserObject(pre => ({ ...pre, ...data }))
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "User ID",
        field: "userId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Username",
        field: "username",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name1",
        sort: "asc",
        width: 400,
      },

      {
        label: "Mobile",
        field: "mobile",
        sort: "asc",
        width: 100,
      },
      {
        label: "Privilege",
        field: "privilage1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 100,
      },
      {
        label: "2 FA",
        field: "twoFactor",
        sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "block_status",
        sort: "asc",
        width: 50,
      },
      {
        label: "Info",
        field: "userType",
        sort: "asc",
        width: 50,
      },

      {
        label: "Action",
        field: "action",
        width: 300,
      },
    ],
    rows: tableData,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Home" breadcrumbItem="Users" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleSubmit()
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">First Name</Label>
                          <AvField
                            name="firstName"
                            placeholder="First Name"
                            type="text"
                            errorMessage="Enter First Name"
                            className="form-control-firstname"
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: "^[a-zA-Z ]+$",
                                errorMessage: "First Name only allowed letter",
                              },
                            }}
                            id="validationCustom01"
                            value={userObject.firstName || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Last Name</Label>
                          <AvField
                            name="lastName"
                            placeholder="Last Name"
                            type="text"
                            className="form-control"
                            id="validationCustom02"
                            value={userObject.lastName || ""}
                            validate={{
                              pattern: {
                                value: "^[a-zA-Z ]+$",
                                errorMessage: "Last Name only allowed letter",
                              },
                            }}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom04">Mobile</Label>
                          <AvField
                            name="mobile"
                            placeholder="Mobile"
                            type="number"
                            errorMessage="Please provide a valid mobile."
                            className="form-control"
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: "^(0|91)?[6-9][0-9]{9}$",
                                errorMessage: "Enter valid mobile no",
                              },
                            }}
                            id="validationCustom04"
                            value={userObject.mobile || ""}
                            onChange={handleChange}
                            minLength={10}
                            maxLength={10}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Email</Label>
                          <AvField
                            name="email"
                            placeholder="Email"
                            type="email"
                            errorMessage="Enter valid Email"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom03"
                            value={userObject.email || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>

                      <Col md={3}>
                        <Label>Gender</Label>
                        <Select
                          onChange={selected => {
                            handleSelect(selected, "gender")
                          }}
                          value={selectedGender}
                          options={[
                            { label: "Female", value: "0" },
                            { label: "Male", value: "1" },
                            { label: "Other", value: "2" },
                          ]}
                          classNamePrefix="select2-selection"
                        />
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">DOB</Label>
                          <input
                            className="form-control"
                            type="date"
                            id="user_dob"
                            name="user_dob"
                            max={moment().format("YYYY-MM-DD")}
                            value={userObject?.user_dob || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>

                      <Col md={3}>
                        <Label>Qualification</Label>
                        <Select
                          value={selectedQualification}
                          onChange={selected => {
                            handleSelect(selected, "qualification")
                          }}
                          options={qualification}
                          classNamePrefix="select2-selection"
                        />
                      </Col>

                      <Col md={3}>
                        <Label>State</Label>
                        <Select
                          value={selectedState}
                          onChange={selected => {
                            handleSelect(selected, "state")
                          }}
                          options={states}
                          classNamePrefix="select2-selection"
                        />
                      </Col>

                      <Col md={3}>
                        <Label>District</Label>
                        <Select
                          value={selectedDistrict}
                          onChange={selected => {
                            handleSelect(selected, "district")
                          }}
                          options={distrcts}
                          classNamePrefix="select2-selection"
                        />
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Address</Label>
                          <AvField
                            name="address"
                            type="textarea"
                            className="form-control"
                            id="validationCustom03"
                            rows={1}
                            value={userObject.address || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Username</Label>
                          <AvField
                            name="username"
                            placeholder="Username"
                            type="text"
                            errorMessage="Enter valid Username"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: "^[a-z0-9]+$",
                                errorMessage: "Please enter a valid username",
                              },
                            }}
                            id="validationCustom03"
                            value={userObject.username || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>

                      {!userObject.id && (
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">Password</Label>
                            <AvField
                              name="password"
                              placeholder="Password"
                              type="password"
                              errorMessage=" Please provide a valid password"
                              className="form-control"
                              validate={{
                                required: { value: true },
                                pattern: {
                                  value:
                                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/,
                                  errorMessage:
                                    "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character.",
                                },
                              }}
                              id="validationCustom05"
                              value={userObject.password || ""}
                              onChange={handleChange}
                            />
                          </div>
                        </Col>
                      )}

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Image</Label>
                          {userObject?.user_image ? (
                            <div div className="img-wraps d-flex flex-column">
                              {userObject.user_image &&
                              userObject.user_image.startsWith("http") ? (
                                <img
                                  alt=""
                                  width="150"
                                  height="150"
                                  src={`${userObject.user_image}`}
                                />
                              ) : (
                                <img
                                  alt=""
                                  width="150"
                                  height="150"
                                  src={`${API_URL}${userObject.user_image}`}
                                />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={() => {
                                  setUserObject(pre => ({
                                    ...pre,
                                    user_image: null,
                                  }))
                                }}
                                style={{ width: "150px" }}
                                type="button"
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField
                              name="user_image"
                              type="file"
                              className="form-control"
                              id="validationCustom03"
                              onChange={uploadImage}
                              rows="1"
                            />
                          )}
                        </div>
                      </Col>
                      <Col md={3}>
                        <Label>Company</Label>
                        <Select
                          name="company"
                          errorMessage="Select Company"
                          value={selectedCompany}
                          onChange={selected => {
                            setSelectedCourses([])
                            handleSelect(selected, "company")
                          }}
                          options={companies}
                          classNamePrefix="select2-selection"
                        />
                        <SelectErrorMessage
                          show={selectedCompany === null && error.company}
                          customStyle={{
                            width: "100%",
                            fontSize: "87.5%",
                            color: "var(--bs-form-invalid-color)",
                          }}
                          message="Please select a company"
                        />
                      </Col>

                      {display.csp && (
                        <Col md={3}>
                          <div className="mb-3">
                            <Label>CSP</Label>
                            <Select
                              name="branch"
                              value={selectedBranch}
                              isMulti
                              onChange={selected => {
                                setSelectedBranch(selected)
                                setUserObject(pre => ({
                                  ...pre,
                                  branch: selected
                                    ? selected.map(item => item.value)
                                    : [],
                                }))
                              }}
                              options={branches}
                              classNamePrefix="select2-selection"
                            />
                            <p
                              className="text-danger"
                              style={{ fontSize: "11px" }}
                            ></p>
                          </div>
                        </Col>
                      )}

                      <Col md={3}>
                        <div className="mb-3">
                          <Label>Privilege</Label>
                          <Select
                            name="privilage"
                            value={selectedPrivilege}
                            onChange={value => {
                              setSelectedPrivilege(value)
                              setUserObject(pre => ({
                                ...pre,
                                privilage: value.value,
                              }))
                            }}
                            options={privileges.filter(
                              item =>
                                privilege === null || item.value === privilege
                            )}
                            classNamePrefix="select2-selection"
                          />
                          <SelectErrorMessage
                            show={selectedPrivilege === null && error.privilege}
                            customStyle={{
                              width: "100%",
                              fontSize: "87.5%",
                              color: "var(--bs-form-invalid-color)",
                            }}
                            message="Please select a privilege"
                          />
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="mb-3">
                          <Label>Default Module</Label>
                          <Select
                            value={selectedModule}
                            onChange={value => {
                              setSelectedModule(value)
                              setUserObject(pre => ({
                                ...pre,
                                modules: value.value,
                              }))
                            }}
                            options={modules}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          ></p>
                        </div>
                      </Col>
                    </Row>

                    {selectedPrivilege?.value === PRIVILEGES.TRAINER && (
                      <Row>
                        <Col md={6}>
                          {userObject?.salary?.map((item, idx) => (
                            <Row key={idx}>
                              <Col
                                sm={userObject?.salary?.length > 1 ? 11 : 12}
                              >
                                <Row>
                                  <Col md={6}>
                                    <div className="mb-3">
                                      <Label>Course</Label>
                                      <Select
                                        value={item.courseSelected}
                                        onChange={value => {
                                          const data = { ...userObject }

                                          userObject.salary[idx].course =
                                            value.value

                                          userObject.salary[
                                            idx
                                          ].courseError = false

                                          userObject.salary[
                                            idx
                                          ].courseSelected = value
                                          setUserObject(data)
                                          if (value) {
                                            setSelectedCourses(pre => [
                                              ...pre,
                                              value.value,
                                            ])
                                          }
                                        }}
                                        options={courses.filter(
                                          item =>
                                            !selectedCourses.includes(
                                              item.value
                                            )
                                        )}
                                        classNamePrefix="select2-selection"
                                      />
                                      <SelectErrorMessage
                                        show={item.courseError}
                                        customStyle={{
                                          width: "100%",
                                          fontSize: "87.5%",
                                          color: "var(--bs-form-invalid-color)",
                                        }}
                                        message="Please select a course"
                                      />
                                    </div>
                                  </Col>

                                  <Col md="6">
                                    <div className="mb-3">
                                      <Label>
                                        Salary
                                        <span className="label-image-size">
                                          (hourly)
                                        </span>
                                      </Label>
                                      <AvField
                                        name={`amount-${idx}`}
                                        type="number"
                                        className="form-control"
                                        placeholder="Amount"
                                        value={item.amount || ""}
                                        min={0}
                                        onChange={e => {
                                          const data = { ...userObject }
                                          userObject.salary[idx].amount =
                                            e.target.value
                                          setUserObject(data)
                                        }}
                                        validate={{
                                          required: {
                                            value:
                                              item.course !== undefined &&
                                              item.course !== null,
                                          },
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                              {userObject?.salary?.length > 1 && (
                                <Col
                                  width={"200px"}
                                  sm="1"
                                  className="d-flex justify-content-center align-items-center"
                                >
                                  <div className="d-flex justify-content-end">
                                    <span
                                      className="mr-1"
                                      style={{
                                        fontSize: "1.125rem",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        const data = { ...userObject }
                                        data.salary.splice(idx, 1)
                                        setUserObject(data)
                                      }}
                                    >
                                      <i className="trash-btn mdi mdi-delete"></i>
                                    </span>
                                  </div>
                                </Col>
                              )}
                            </Row>
                          ))}

                          <button
                            onClick={e => {
                              e.preventDefault()
                              const data = { ...userObject }
                              data?.salary?.push({})
                              setUserObject(data)
                            }}
                            className="waves-effect btn btn-outline-light d-flex"
                            style={{ gap: "5px" }}
                          >
                            Add more{" "}
                            <i className="mdi mdi-plus-circle-outline"></i>
                          </button>
                        </Col>
                      </Row>
                    )}

                    <Row>
                      <Col
                        md="1"
                        className="mt-4"
                        style={{ paddingTop: "5px" }}
                      >
                        <div className="d-flex ">
                          <Button
                            className="me-2"
                            color={`${userObject.id ? "warning" : "primary"}`}
                            type="submit"
                          >
                            {userObject.id ? "Update" : "Submit"}
                          </Button>

                          <Button
                            onClick={() => reset()}
                            type="button"
                            color="danger"
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="p-2 pb-3 d-flex  justify-content-end">
                    <Tooltip
                      title="Export user details"
                      placement="left-start"
                      arrow
                    >
                      <i
                        onClick={handleExport}
                        className="fa fa-solid fa-file-export fs-4 cursor-pointer"
                      ></i>
                    </Tooltip>
                  </div>
                  <MDBDataTable
                    id="user-table"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        show={modal}
        toggle={() => {
          setModal(pre => !pre)
          handleReset()
        }}
        centered={true}
        size="md"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Confirmation</h5>
          <button
            type="button"
            onClick={() => {
              setModal(pre => !pre)
              handleReset()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <AvForm
          className="needs-validation"
          ref={formRef}
          onValidSubmit={(e, v) => {
            handlePasswordReset()
          }}
        >
          <div className="modal-body">
            <Row>
              <Col md="12">
                <FormGroup className="my-3 position-relative" id="confirm-pass">
                  <AvField
                    name="password"
                    placeholder="Password"
                    type={`${passShow ? "text" : "password"}`}
                    errorMessage=" Please provide a valid password"
                    validate={{ required: { value: true } }}
                    id="validationCustom05"
                    value={master.password || ""}
                    onChange={e =>
                      setMaster(pre => ({ ...pre, password: e.target.value }))
                    }
                  />
                  {passShow ? (
                    <i
                      className="fas fa-eye-slash position-absolute"
                      onClick={() => setpassShow(pre => !pre)}
                      style={{
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                    ></i>
                  ) : (
                    <i
                      className="fas fa-eye position-absolute"
                      onClick={() => setpassShow(pre => !pre)}
                      style={{
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                    ></i>
                  )}
                </FormGroup>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <AvField
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    type={`${passShow ? "text" : "password"}`}
                    errorMessage="Please confirm the password"
                    className="form-control"
                    validate={{
                      required: { value: true },
                      match: {
                        value: "password",
                        errorMessage: "Password not match",
                      },
                    }}
                    id="validationCustom05"
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-dark"
              type="button"
              style={{ marginRight: "1rem" }}
              onClick={() => {
                setModal(pre => !pre)
                handleReset()
              }}
            >
              Close
            </button>
            <button className="btn btn-primary" type="submit">
              Confirm
            </button>
          </div>
        </AvForm>
      </Modal>
    </React.Fragment>
  )
}

export default User
