import React from "react"
import Skilling from "./BusinessHeadSkilling/Skilling"
const Index = () => {


  return (
    <div>
     
      <Skilling />
      {/* <NonSkilling /> */}
    </div>
  )
}

export default Index
