import React, { useEffect, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import "./Temporarybatch.scss"
import Admin from "../../../../components/Common/Admin"
import toastr from "toastr"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { getUsersOptions } from "../../../../store/options/actions"
import { isArray } from "lodash"
import { useRef } from "react"
import SelectErrorMessage from "../../../../components/Common/SelectErrorMessage"
import { del, get, post, put } from "../../../../helpers/api_helper"
import moment from "moment"
import Swal from "sweetalert2"
import { useNavigate } from "react-router-dom"
import useBranch from "../../../../hooks/useBranch"
import { PRIVILEGES } from "../../../../config"
import { jsonToExcel } from "../../../../helpers/functions"
import TablePagination from "../../../../components/Common/Pagination"
import qs from "query-string"

function TemporaryBatch(props) {
  const [totalCount, setTotalCount] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 20

  const [master, setMaster] = useState({ webShow: 1, feeEnabled: true })

  const [isExportPending, setIsExportPending] = useState(false)

  const [filterObject, setFilterObject] = useState({})

  const [courses, setCourses] = useState([])

  const [filterCourseOptions, setFilterCourseOptions] = useState([])

  const [branch] = useBranch()

  const navigate = useNavigate()

  const [selected, setSelected] = useState({
    course: null,
    branch,
  })

  const [error, setError] = useState({})

  const [rows, setRows] = useState([])

  const { allotted } = useSelector(state => state.Profile)

  const Options = useSelector(state => state.Options)

  const formRef = useRef()

  const dispatch = useDispatch()

  // useEffect(() => {
  //   dispatch(getCourses())
  // }, [dispatch])

  useEffect(() => {
    handleTableData(page, filterObject)
    // eslint-disable-next-line
  }, [page, filterObject])

  useEffect(() => {
    if (selected?.branch?.value) {
      getCourses(selected?.branch?.value)
    }
  }, [selected?.branch])

  useEffect(() => {
    if (selected.branch !== null) {
      dispatch(
        getUsersOptions({
          branch: selected.branch.value,
          privilege: PRIVILEGES.INTERN,
        })
      )
    }
  }, [selected.branch, dispatch])

  useEffect(() => {
    get("/options/courses").then(res => setFilterCourseOptions(res.data))
  }, [])

  const handleTableData = (page = 1, query) => {
    get(`temp-batch?page=${page}&limit=${limit}&${qs.stringify(query)}`).then(
      res => {
        setTotalCount(Number(res.count || 0))
        res.data.map((item, idx) => {
          const data = { ...item }

          item.id = (page - 1) * limit + idx + 1

          item.date1 =
            item.date && moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY")

          item.courseName = item.course && item.course.name

          item.branchName = item?.branch?.name

          item.expStartingDate1 =
            item.expStartingDate &&
            moment(item.expStartingDate, "YYYY-MM-DD").format("DD-MM-YYYY")

          item.assign = item?.assigned?.label

          item.action = (
            <div>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  navigate(`${item.uniqueId}`, {
                    state: { id: item._id },
                  })
                  // setPopupView(true)
                }}
              ></i>
              <i
                className="fas fa-edit"
                onClick={() => handlePreUpdate(item)}
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginRight: "0.5rem",
                }}
              ></i>

              {/* {!item.batchCreated && item.leadsCount >= item.minStrength && ( */}
              <i
                className="fas fa-share"
                onClick={() => {
                  navigate("/batch-creations", {
                    state: { data },
                  })
                }}
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginRight: "0.5rem",
                }}
              ></i>
              {/* )} */}

              <i
                onClick={() => handleDelete(item._id)}
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
              ></i>
            </div>
          )
          return item
        })
        setRows(res.data)
      }
    )
  }

  const handleSelect = (e, name) => {
    const value = e ? (isArray(e) ? e.map(item => item.value) : e.value) : null
    setSelected(pre => ({ ...pre, [name]: e }))
    setMaster(pre => ({ ...pre, [name]: value }))
  }

  const handleChange = e => {
    let { name, value, type, checked } = e.target

    if (type === "checkbox") value = !checked

    const data = { [name]: value }

    setMaster(pre => ({ ...pre, ...data }))
  }

  const handleSubmit = () => {
    let flag = false

    if (selected.course === null) {
      setError(pre => ({ ...pre, course: true }))
      flag = true
    }

    if (flag) return

    if (master.id) {
      put("temp-batch", master)
        .then(res => {
          toastr.success(res.message)
          handleReset()
        })
        .catch(err => {
          toastr.error(err.message)
        })
    } else {
      post("temp-batch", master)
        .then(res => {
          toastr.success(res.message)
          handleReset()
        })
        .catch(err => {
          toastr.error(err.message)
        })
    }
  }

  const handlePreUpdate = item => {
    setSelected({
      course: { label: item?.course?.name, value: item?.course._id },
      branch: { label: item?.branch?.name, value: item?.branch?._id },
      assigned: item?.assigned || null,
    })

    setMaster({
      id: item._id,
      course: item.course._id,
      expStartingDate: item.expStartingDate,
      minStrength: item.minStrength,
      maxStrength: item.maxStrength,
      webShow: item?.webShow,
      feeEnabled: item?.feeEnabled,
      registerClosingDate: item?.registerClosingDate,
      registerClosingTime: item?.registerClosingTime,
    })

    toTop()
  }

  const handleExport = query => {
    setIsExportPending(true)
    get(`temp-batch?page=${1}&limit=${totalCount}&${qs.stringify(query)}`).then(
      res => {
        const exportData = []
        setTotalCount(Number(res.count || 0))
        res.data.map((item, idx) => {
          const dataExport = {
            "#": idx + 1,
            Date:
              item.date && moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"),
            "Batch Id": item.uniqueId,
            CSP: item?.branch?.name,
            Course: item.course && item.course.name,
            "Exp. Starting Date":
              item.expStartingDate &&
              moment(item.expStartingDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
            Assigned: item?.assigned?.label,
            "Min Str": item.minStrength,
            "Max Str": item.maxStrength,
            Lead: item.leadsCount,
            Paid: item.paidCount,
          }
          exportData.push(dataExport)
          return item
        })
        if (exportData.length > 0) {
          jsonToExcel(
            exportData,
            `temporary-batch-${moment().format("DD-MM-YY")}`
          )
        } else {
          toastr.info(`There are no temporary batch to export`)
        }
        setIsExportPending(false)
      }
    )
  }

  const handleDelete = id => {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`temp-batch/${id}`)
          .then(res => {
            toastr.success(res.message)
            handleReset()
          })
          .catch(err => {
            toastr.reset(err.message)
          })
      }
    })
  }

  const handleReset = () => {
    setSelected(pre => ({ ...pre, course: null, assigned: null, branch: null }))
    formRef.current.reset()
    setMaster({ webShow: true, feeEnabled: true })
    // setSelectedAssign(null)
    handleTableData()
  }

  const getCourses = branch => {
    get(`temp-batch/course/${branch}`).then(res => {
      setCourses(res.data)
    })
  }

  const handleChangePage = (event, value) => {
    setPage(value)
    // toTop()
  }

  const handleValueChange = (value, name) => {
    const obj = { ...filterObject }
    obj[name] = value
    setFilterObject(obj)
    setPage(1)
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date1",
        width: 150,
      },
      {
        label: "Batch Id",
        field: "uniqueId",
        width: 150,
      },
      {
        label: "CSP",
        field: "branchName",
        width: 150,
      },
      {
        label: "Course",
        field: "courseName",
        width: 150,
      },
      {
        label: "Exp. Starting Date",
        field: "expStartingDate1",
        width: 150,
      },
      {
        label: "Assigned",
        field: "assign",
        width: 150,
      },
      {
        label: "Min Str.",
        field: "minStrength",
        width: 150,
      },
      {
        label: "Max Str.",
        field: "maxStrength",
        width: 150,
      },
      {
        label: "Lead",
        field: "leadsCount",
        width: 150,
      },
      {
        label: "Paid",
        field: "paidCount",
        width: 150,
      },
      // {
      //   label: "Status",
      //   field: "status",
      //   width: 150,
      // },
      {
        label: "Actions",
        field: "action",
        sort: "desc",
        width: 400,
      },
    ],
    rows,
  }

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }
  const maxStrengthGreaterThanMin = (value, ctx, input, cb) => {
    if (parseInt(value) < parseInt(master.minStrength)) {
      return cb("Maximum Strength must be greater than Minimum Strength")
    }
    return cb(true)
  }
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Temporary Batch" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm onValidSubmit={handleSubmit} ref={formRef}>
                    <Row>
                      <Admin>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>CSP</Label>
                            <Select
                              name="Domain"
                              options={allotted.branches}
                              value={selected.branch}
                              type="text"
                              errorMessage="Enter Category"
                              onChange={e => {
                                setSelected(pre => ({ ...pre, course: null }))
                                handleSelect(e, "branch")
                              }}
                            />
                          </div>
                        </Col>
                      </Admin>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Course</Label>
                          <Select
                            name="Course"
                            options={courses}
                            value={selected.course}
                            type="text"
                            onChange={e => {
                              handleSelect(e, "course")
                              setError(pre => ({ ...pre, course: false }))
                            }}
                          />
                        </div>
                        <SelectErrorMessage
                          message="Select course"
                          show={error.course}
                        />
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Expected Starting Date</Label>
                          <AvField
                            name="expStartingDate"
                            value={master.expStartingDate || ""}
                            onChange={handleChange}
                            className="form-control"
                            // min={moment().format("YYYY-MM-DD")}
                            type="date"
                            validate={{ required: { value: true } }}
                            placeholder="Mobile No."
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Minimum Strength</Label>
                          <AvField
                            name="minStrength"
                            value={master.minStrength || ""}
                            onChange={handleChange}
                            className="form-control"
                            type="number"
                            placeholder="Minimum Strength"
                            // validate={{ required: { value: true } }}
                            errorMessage="Enter Category"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Maximum Strength</Label>
                          <AvField
                            name="maxStrength"
                            value={master.maxStrength || ""}
                            onChange={handleChange}
                            className="form-control"
                            type="number"
                            validate={{
                              required: { value: true },
                              maxStrengthGreaterThanMin,
                            }}
                            // validate={{ required: { value: true } }}
                            placeholder="Maximum Strength"
                            errorMessage="Enter Category"
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Registration closing date</Label>
                          <AvField
                            name="registerClosingDate"
                            value={master.registerClosingDate || ""}
                            onChange={handleChange}
                            className="form-control"
                            type="date"
                            placeholder="Date"
                            errorMessage="Registration closing date"
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Registration closing time</Label>
                          <AvField
                            name="registerClosingTime"
                            value={master.registerClosingTime || ""}
                            onChange={handleChange}
                            className="form-control"
                            type="time"
                            placeholder="Time"
                            errorMessage="Registration closing time"
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Assign</Label>
                          <Select
                            name="user"
                            options={Options.users}
                            value={selected.assigned}
                            onChange={e => {
                              handleSelect(e, "assigned")
                              // setSelectedAssign(e)
                            }}
                          />
                        </div>
                      </Col>

                      <Col md={2} className="mt-4 pt-2">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="webShow"
                            id="defaultCheck2"
                            checked={master.webShow}
                            onClick={handleChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="defaultCheck2"
                          >
                            Web show
                          </label>
                        </div>
                      </Col>
                      <Col md={2} className="mt-4 pt-2">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="feeEnabled"
                            id="defaultCheck2"
                            checked={master.feeEnabled}
                            onClick={handleChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="defaultCheck2"
                          >
                            Enable Fee
                          </label>
                        </div>
                      </Col>

                      <Col md="3" className="mb-3 mt-4">
                        <Button
                          color={master.id ? "warning" : "primary"}
                          type="submit"
                        >
                          {master.id ? "Update" : "Submit"}
                        </Button>
                        <Button
                          style={{ marginLeft: "10px" }}
                          color="danger"
                          type="reset"
                          onClick={handleReset}
                        >
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {/* <div className="p-2 pb-3 d-flex  justify-content-end">
                    <Tooltip
                      title="Export temporary batch details"
                      placement="left-start"
                      arrow
                    >
                      <i
                        onClick={handleExport}
                        className="fa fa-solid fa-file-export fs-4 cursor-pointer"
                      ></i>
                    </Tooltip>
                  </div> */}
                  <AvForm>
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label> From</Label>
                          <AvField
                            name="from"
                            value={filterObject.from || ""}
                            onChange={e =>
                              handleValueChange(e.target.value, e.target.name)
                            }
                            max={moment().format("YYYY-MM-DD")}
                            className="form-control"
                            type="date"
                            placeholder="From"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>To</Label>
                          <AvField
                            name="to"
                            max={moment().format("YYYY-MM-DD")}
                            min={filterObject.from}
                            value={filterObject.to || ""}
                            onChange={e =>
                              handleValueChange(e.target.value, e.target.name)
                            }
                            className="form-control"
                            type="date"
                            placeholder="To"
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Course</Label>
                          <Select
                            onChange={selected => {
                              handleValueChange(selected?.value, "course")
                              setSelected(prev => ({
                                ...prev,
                                filterCourse: selected,
                              }))
                            }}
                            value={selected.filterCourse || ""}
                            isClearable={true}
                            options={filterCourseOptions}
                          />
                        </div>
                      </Col>

                      <Col className="mt-4 pt-1">
                        <div className="mb-3">
                          <Button
                            color="danger"
                            type="button"
                            onClick={() => {
                              setFilterObject({})
                              setSelected(pre => ({
                                ...pre,
                                filterCourse: null,
                              }))
                            }}
                          >
                            Reset
                          </Button>

                          <Button
                            style={{ marginLeft: "14px" }}
                            color="warning"
                            type="button"
                            disabled={isExportPending}
                            onClick={() => handleExport(filterObject)}
                          >
                            Export{" "}
                            {isExportPending && (
                              <i className="mdi mdi-spin mdi-loading"></i>
                            )}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                  <MDBDataTable
                    id="temporaryBatchID"
                    bordered
                    responsive
                    searching={false}
                    displayEntries={false}
                    paging={false}
                    disableRetreatAfterSorting={true}
                    data={data}
                    sortable={false}
                  ></MDBDataTable>
                  <div className="d-flex justify-content-center">
                    <TablePagination
                      page={page}
                      onChange={handleChangePage}
                      count={Math.ceil(totalCount / limit)}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default TemporaryBatch
