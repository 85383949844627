import React, { useRef, useState, useEffect } from "react"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Label, Button, Modal } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { MDBDataTable } from "mdbreact"
import "./style.scss"
import Select from "react-select"
import { getCourses } from "../../../store/options/actions"
import { useSelector, useDispatch } from "react-redux"
import SunEditor from "suneditor-react"
import plugins from "suneditor/src/plugins"
import { en } from "suneditor/src/lang"
import { EditorView } from "codemirror"
import katex from "katex"
import "suneditor/dist/css/suneditor.min.css"
import "katex/dist/katex.min.css"
import { put, get, post, del } from "../../../helpers/api_helper"
import toastr from "toastr"
import Swal from "sweetalert2"
import moment from "moment"
import SelectErrorMessage from "../../../components/Common/SelectErrorMessage"
import TablePagination from "../../../components/Common/Pagination"

const TestCreation = () => {
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }
  const formRef = useRef()
  const [filterData, setFilterData] = useState({
    status: null,
    course: null,
  })
  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 20

  const options = {
    plugins: plugins,
    height: 250,
    EditorView: {
      src: EditorView,
      options: {
        indentWithTabs: true,
        tabSize: 2,
      },
    },
    katex: katex,
    lang: en,
    buttonList: [
      [
        "font",
        "fontSize",
        "formatBlock",
        "bold",
        "underline",
        "italic",
        "paragraphStyle",
        "blockquote",
        "strike",
        "subscript",
        "superscript",
        "fontColor",
        "hiliteColor",
        "textStyle",
        "removeFormat",
        "undo",
        "redo",
        "outdent",
        "indent",
        "align",
        "horizontalRule",
        "list",
        "lineHeight",
        "table",
        "link",
        // 'image',
        // 'video',
        // 'audio',
        // You must add the 'katex' library at options to use the 'math' plugin.
        // 'math',
        // You must add the "imageGalleryUrl".
        // 'imageGallery',
        "fullScreen",
        "showBlocks",
        "codeView",
        "preview",
        // 'print'
        // 'save',
        // 'template'
      ],
    ],
  }
  const { courses } = useSelector(state => state.Options)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCourses())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getAllTest()
  }, [filterData, page])

  const [popupView, setPopupView] = useState(false)
  const handleCloseModal = () => setPopupView(false)

  const [selectedCourse, setselectedCourse] = useState(null)
  const [master, setmaster] = useState({
    markQuestionAllocation: [{ questions: 0, mark: 0 }],
  })
  const [tabledetails, settabledetails] = useState([])
  const [popupData, setpopupData] = useState([])
  const [toatlMark, settoatlMark] = useState(0)
  const [totalQuestion, settotalQuestion] = useState(0)
  const [courseErr, setCourseErr] = useState(false)

  function handleUpdate(item) {
    setselectedCourse(item.course)
    item.course = item.course.map(el => el._id)
    setmaster(item)
  }

  function reset() {
    formRef.current.reset()
    setmaster({ mark: "", markQuestionAllocation: [{ questions: 0, mark: 0 }] })
    setselectedCourse(null)
    setCourseErr(false)
  }

  const handleChange = (event, value) => {
    setPage(value)
  }

  const status = [
    { label: "Active", value: 0 },
    { label: "InActive", value: 2 },
  ]

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Course",
        field: "course1",
        width: 150,
      },
      {
        label: "Test",
        field: "name",
        width: 150,
      },
      {
        label: "Questions",
        field: "questions",
        width: 150,
      },

      {
        label: "Max. Mark",
        field: "mark",
        width: 150,
      },
      // {
      //   label: "Eligible Mark",
      //   field: "min_mark",
      //   width: 150,
      // },
      {
        label: "Duration",
        field: "duration",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff",
        width: 150,
      },
      {
        label: "Status",
        field: "status1",
        width: 150,
      },
      {
        label: "Action",
        field: "options",
        width: 150,
      },
    ],
    rows: tabledetails,
  }

  function handleSelectChange(selected, name) {
    switch (name) {
      case "course":
        setselectedCourse(selected)
        setmaster({ ...master, course: selected.map(item => item._id) })
        setCourseErr(false)
        break

      default:
        break
    }
  }

  const handleFilterData = (name, value) => {
    setFilterData({ ...filterData, [name]: value })
  }

  const resetFilter = () => {
    setFilterData({
      course: null,
      status: null,
    })
  }

  function handleStatus(id, status) {
    put(`/screen-test/status`, { _id: id, status: status }).then(res => {
      toastr.success(res.message)
      getAllTest()
    })
  }

  const setCourseErrrr = () => {
    if (!master?.course) {
      return setCourseErr(true)
    } else {
      setCourseErr(false)
    }
  }

  function handlechange(e) {
    let name = e.target.name
    let value = e.target.value
    setmaster({ ...master, [name]: value })
  }
  function onChangeSuneditor(content) {
    setmaster(prev => {
      return {
        ...prev,
        instruction: content,
      }
    })
  }

  function handleSubmit(e) {
    if (!master?.course || master?.course.length == 0) {
      return setCourseErr(true)
    } else {
      setCourseErr(false)
    }

    e.preventDefault()
    if (master._id) {
      put(`/screen-test`, master)
        .then(res => {
          toastr.success(res.message)
          formRef.current.reset()
          setmaster({
            markQuestionAllocation: [{ mark: "", questions: 0, mark: 0 }],
          })
          getAllTest()
          setselectedCourse(null)
          reset()
        })
        .catch(err => {
          toastr.error(err?.response?.data?.message)
        })
    } else {
      post(`/screen-test`, master)
        .then(res => {
          console.log(res)
          toastr.success(res.message)
          formRef.current.reset()
          setmaster({
            markQuestionAllocation: [{ mark: "", questions: 0, mark: 0 }],
          })
          setselectedCourse(null)
          getAllTest()
          setselectedCourse(null)
          reset()
        })
        .catch(err => {
          toastr.error(err?.response?.data?.message)
        })
    }
  }

  function getAllTest() {
    get(
      `/screen-test?status=${filterData?.status?.value}&course=${filterData?.course?.value}&page=${page}&limit=${limit}`
    ).then(res => {
      let result = res.data
      const count = res?.count
      result &&
        result.map((item, index) => {
          item.id = index + 1
          item.date1 = moment(item.date).format("DD-MM-YYYY")
          item.staff = item.addedBy?.firstName + " " + item.addedBy?.lastName
          item.course1 = item.course.map(el => el.label).join(" , ")
          item.options = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-eye"
                onClick={() => {
                  setPopupView(true)
                  setpopupData(item)
                }}
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                }}
              ></i>
              <i
                className="fas fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop()
                  handleUpdate(item)
                  // courseIdtoBeUpdated(item?._id)
                }}
              ></i>
              <i
                onClick={() => {
                  handleDelete(item._id)
                }}
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
              ></i>
            </div>
          )
          if (item.status === 0) {
            item.status1 = (
              <div>
                <Button
                  onClick={() => {
                    handleStatus(item._id, 2)
                  }}
                  className="btn btn-sm"
                  color="success"
                >
                  Active
                </Button>
              </div>
            )
          }
          if (item.status === 2) {
            item.status1 = (
              <div>
                <Button
                  onClick={() => {
                    handleStatus(item._id, 0)
                  }}
                  className="btn btn-sm"
                  color="warning"
                >
                  Inactive
                </Button>
              </div>
            )
          }
          return item
        })
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      settabledetails(result)
    })
  }

  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`/screen-test/${id}`)
          .then(res => {
            toastr.success(res.message)
            getAllTest()
          })
          .catch(err => {
            toastr.success(err?.response?.data?.message)
          })
      }
    })
  }

  const addMore = () => {
    const obj = { ...master }
    obj.markQuestionAllocation.push({
      questions: 0,
      mark: 0,
    })
    setmaster(obj)
  }

  const handleOptionValueChange = (value, index, name) => {
    const obj = { ...master }
    obj.markQuestionAllocation[index][name] = value
    setmaster(obj)
  }
  useEffect(() => {
    let totalQuestions = 0
    let totalMarks = 0
    master?.markQuestionAllocation?.forEach(item => {
      const questions = parseInt(item.questions || 0)
      const marks = parseInt(item.mark || 0)
      totalQuestions += questions
      totalMarks += questions * marks
    })
    settoatlMark(totalMarks)
    settotalQuestion(totalQuestions)
  }, [master])

  const handleRemove = indexToRemove => {
    const obj = { ...master }
    const newValues = [
      ...obj.markQuestionAllocation.slice(0, indexToRemove),
      ...obj.markQuestionAllocation.slice(indexToRemove + 1),
    ]
    obj.markQuestionAllocation = newValues
    setmaster(obj)
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem="Test creation" />
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <AvForm
                    className="needs-validation"
                    ref={formRef}
                    onValidSubmit={handleSubmit}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Course</Label>
                          <Select
                            isMulti
                            className="text-editor-select"
                            name="course"
                            options={courses}
                            onChange={selected => {
                              handleSelectChange(selected, "course")
                            }}
                            value={selectedCourse}
                          />
                          <SelectErrorMessage
                            show={courseErr}
                            customStyle={{
                              width: "100%",
                              fontSize: "87.5%",
                              color: "var(--bs-form-invalid-color)",
                            }}
                            message="Please select a Course"
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label>Test Name</Label>
                          <AvField
                            name="name"
                            placeholder="Test Name"
                            validate={{ required: { value: true } }}
                            errorMessage="Enter Test Name"
                            className="form-control"
                            onChange={handlechange}
                            value={master?.name || ""}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="mb-3">
                          <Label>Questions</Label>
                          <AvField
                            name="questions"
                            type="number"
                            className="form-control"
                            min={0}
                            placeholder="Max. questions"
                            errorMessage="Enter Max question"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={handlechange}
                            value={master?.questions || ""}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="mb-3">
                          <Label>Mark</Label>
                          <AvField
                            name="mark"
                            type="number"
                            className="form-control"
                            min={1} // Minimum value allowed is 1
                            placeholder="Max. mark"
                            onChange={handlechange}
                            value={master?.mark}
                            errorMessage="Enter Max mark"
                            validate={{
                              required: { value: true }, // Field is required
                              min: {
                                value: 1,
                                errorMessage: "Mark must be at least 1",
                              }, // Minimum value allowed is 1
                            }}
                          />
                        </div>
                      </Col>

                      <Col md={2}>
                        <div className="mb-3">
                          <Label>Duration (Min)</Label>
                          <AvField
                            name="duration"
                            type="number"
                            className="form-control"
                            min={0}
                            placeholder="Test Duration"
                            errorMessage="Enter duration"
                            validate={{
                              required: { value: true },
                              min: { value: 1 },
                            }}
                            onChange={handlechange}
                            value={master?.duration || ""}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="mb-3">
                          <Label>Pass Mark</Label>
                          <AvField
                            name="passMark"
                            type="number"
                            className="form-control"
                            min={1} // minimum value allowed
                            max={master.mark} // maximum value allowed
                            placeholder="Pass Mark"
                            errorMessage="This field is required"
                            validate={{
                              // required: { value: true },
                              min: { value: 1 },
                              max: {
                                value: parseInt(master.mark),
                                errorMessage:
                                  "Field must be less than or equal to Mark",
                              },
                            }}
                            onChange={handlechange}
                            value={master?.passMark || ""}
                          />
                        </div>
                      </Col>
                      <Row>
                        <Col md="6">
                          <span>
                            <b>Note</b>(Total Questions Should be equal to
                            Max.questions & Total Mark Should be equal to max
                            mark)
                          </span>
                        </Col>
                        <Col md="6">
                          <span>
                            <b>Total Question: {totalQuestion}</b>
                          </span>
                          <span className="mx-3">
                            <b>Total Marks: {toatlMark}</b>
                          </span>
                        </Col>
                      </Row>

                      {master.markQuestionAllocation &&
                        master?.markQuestionAllocation?.map((el, index) => (
                          <>
                            <Row className="mt-2">
                              <Col md="3">
                                <div className="mb-3">
                                  <Label>Questions</Label>
                                  <AvField
                                    name="questions"
                                    placeholder="Questions"
                                    value={el?.questions || ""}
                                    type="number"
                                    onChange={e => {
                                      handleOptionValueChange(
                                        e.target.value,
                                        index,
                                        "questions"
                                      )
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col md="3">
                                <div className="mb-3">
                                  <Label>Mark</Label>
                                  <AvField
                                    name="mark"
                                    placeholder="Mark"
                                    value={el?.mark || ""}
                                    type="number"
                                    onChange={e => {
                                      handleOptionValueChange(
                                        e.target.value,
                                        index,
                                        "mark"
                                      )
                                    }}
                                  />
                                </div>
                              </Col>
                              {master.markQuestionAllocation.length !== 1 && (
                                <Col col={2}>
                                  <Button
                                    color="danger"
                                    onClick={() => handleRemove(index)}
                                    style={{ marginTop: "1.5rem" }}
                                  >
                                    Remove
                                  </Button>
                                </Col>
                              )}
                            </Row>
                          </>
                        ))}
                      <span
                        style={{ cursor: "pointer", textAlign: "center" }}
                        onClick={() => addMore()}
                      >
                        + Add New
                      </span>

                      <Col md={3} style={{ paddingTop: "" }}>
                        <Button
                          type="submit"
                          color={master?._id ? "warning" : "primary"}
                          className="me-2"
                        >
                          {master?._id ? "Update" : "Submit"}
                        </Button>
                        <Button type="button" color="danger" onClick={reset}>
                          Reset
                        </Button>
                      </Col>
                      <Col className="mt-3" md="12">
                        <Label>Instructions</Label>
                        <SunEditor
                          setDefaultStyle="font-family: Arial; font-size: 14px;"
                          lang="en"
                          setOptions={options}
                          onChange={content => onChangeSuneditor(content)}
                          setContents={master?.instruction || ""}
                        />
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="12">
                      <Card>
                        <CardBody>
                          <AvForm>
                            <Row>
                              <Col md="3">
                                <div className="mb-3">
                                  <Label>Course</Label>
                                  <Select
                                    name="course"
                                    type="text"
                                    placeholder="Select..."
                                    errorMessage="Enter Category"
                                    value={filterData?.course}
                                    options={courses}
                                    onChange={selected =>
                                      handleFilterData("course", selected)
                                    }
                                  />
                                </div>
                              </Col>

                              <Col md="3">
                                <div className="mb-3">
                                  <Label>Status</Label>
                                  <Select
                                    name="status"
                                    type="text"
                                    placeholder="Select..."
                                    errorMessage="Enter Category"
                                    onChange={selected =>
                                      handleFilterData("status", selected)
                                    }
                                    value={filterData?.status}
                                    options={status}
                                  />
                                </div>
                              </Col>

                              <Col>
                                <div
                                  className="mb-3 d-flex gap-2"
                                  style={{ paddingTop: "1.8rem" }}
                                >
                                  {/* <Button color="primary" type="submit" onClick={handleSearch}>
                          Search
                        </Button> */}
                                  <Button
                                    color="danger"
                                    type="button"
                                    onClick={resetFilter}
                                  >
                                    Reset
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </AvForm>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <MDBDataTable
                    id="testCreationTable"
                    className="mt-3"
                    bordered
                    responsive
                    searching={false}
                    entries={20}
                    paging={false}
                    info={false}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>
                  <TablePagination
                    page={page}
                    onChange={handleChange}
                    count={totalPage}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <Modal
          show={popupView}
          isOpen={popupView}
          toggle={() => setPopupView(!popupView)}
          size="lg"
          centered={true}
          className="lead-modal"
        >
          <div className="modal-header">
            <div className="modal-title">
              <h6 style={{ marginBottom: "0px" }} className="modal-title ">
                <h6>Questions</h6>
              </h6>
            </div>
            <button
              style={{ marginBottom: "2px" }}
              type="button"
              onClick={handleCloseModal}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <Col md="12">
              <table>
                <tbody>
                  <tr>
                    <td>Course</td>
                    <th>: {popupData?.course1}</th>
                  </tr>
                </tbody>
              </table>
              <table className="table table-borderd lead-info ">
                <tbody>
                  <tr>
                    <td>Test Name</td>
                    <th>: {popupData?.name}</th>
                  </tr>
                  <tr>
                    <td>Questions</td>
                    <th>: {popupData?.questions}</th>
                    <td>Max.Mark</td>
                    <th>: {popupData?.mark}</th>
                    <td>Pass.Mark</td>
                    <th>: {popupData?.passMark}</th>
                    <td>Duration</td>
                    <th>: {popupData?.duration}</th>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col md="12">
              <p>Instructions:</p>
              <div
                className="contentHtml"
                dangerouslySetInnerHTML={{
                  __html: popupData?.instruction,
                }}
              ></div>
            </Col>

            <div></div>
          </div>
        </Modal>
      </div>
    </>
  )
}
export default TestCreation
