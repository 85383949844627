import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { get } from "../../../helpers/api_helper"

const Chartapex = () => {
  const [graphData, setGraphData] = useState([])
  const [maxOrderCount, setMaxOrderCount] = useState(0)

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await get("/campaign/graph-dash")
        setGraphData(res?.data)
        const maxOrderCount = res?.data?.reduce((max, campaign) => {
          return Math.max(max, campaign?.maxLeadCount || 0)
        }, 0)

        setMaxOrderCount(maxOrderCount)
      } catch (error) {}
    }
    fetch()
  }, [])

  // Generate last 12 months dynamically
  const getLast12Months = () => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ]
    let result = []
    let date = new Date()

    for (let i = 0; i < 12; i++) {
      result.unshift(months[date.getMonth()])
      date.setMonth(date.getMonth() - 1)
    }

    return result
  }


  const options = {
    chart: { zoom: { enabled: !1 }, toolbar: { show: !1 } },
    colors: [
      "#f46a6a",  // Red
      "#5b73e8",  // Blue
      "#f1b44c",  // Yellow
      "#34c38f",  // Green
      "#50a5f1",  // Light Blue
      "#74788d",  // Grey
      "#e83e8c",  // Pink
      "#2a2b2e",  // Dark Grey
      "#6f42c1",  // Purple
      "#28a745",  // Dark Green
      "#fd7e14",  // Dark Orange
      "#17a2b8",  // Teal
      "#6610f2",  // Indigo
      "#dc3545",  // Dark Red
    ], 
       dataLabels: {
      enabled: !1,
    },
    stroke: {
      width: [3, 3],
      curve: "straight",
    },
    title: {
      text: "Total GMB Data",
      align: "left",
    },
    grid: {
      row: {
        colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.2,
      },
      borderColor: "#f1f1f1",
    },
    markers: {
      style: "inverted",
      size: 6,
    },
    xaxis: {
      categories: getLast12Months(),
      title: {
        text: "Month",
      },
      labels: {
        show: true, // Force showing labels
        rotate: -45, // Rotate labels to prevent overlap if needed
      },
    },    
    yaxis: {
      title: {
        text: "Number",
      },
      min: 0,
      max: maxOrderCount > 40 ? maxOrderCount : 40,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          xaxis: {
            labels: {
              show: true, // Ensure labels are shown
            },
          },
          chart: {
            toolbar: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    
  }

  return (
    <ReactApexChart
      options={options}
      series={graphData}
      type="line"
      height="380"
      className="apex-charts"
    />
  )
}

export default Chartapex
