import React, { useEffect, useRef, useState } from "react"
import { Row, Col, Button, Label } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import {getCourses,getDistrictOptions,getUsersOptions,} from "../../../../../store/options/actions"
// import { get } from "../../../../../helpers/api_helper"
import { useBatch } from "../BatchContext"
import Admin from "../../../../../components/Common/Admin"
import { isArray } from "lodash"
import useBranch from "../../../../../hooks/useBranch"
import { PRIVILEGES } from "../../../../../config"
import SelectErrorMessage from "../../../../../components/Common/SelectErrorMessage"
import { get, post, put } from "../../../../../helpers/api_helper"

import toastr from "toastr"
import moment from "moment"
import _ from "lodash"

const Basic = props => {
  const formRef = useRef()
  const [branch] = useBranch()

  const { next, ...context } = useBatch()

  const [master, setMaster] = useState({})

  
  console.log(master)

  const [masterInitial, setMasterInitial] = useState({})

  const [courses, setCourses] = useState([])

  const [selected, setSelected] = useState({
    course: null,
    csp: branch,
  })

  const [error, setError] = useState({})

  const [certificationPartner, setCertificationPartner] = useState([])

  const [trainingPartner, setTrainingPartner] = useState([])

  const dispatch = useDispatch()

  const { allotted } = useSelector(state => state.Profile)
  const { distrcts } = useSelector(state => state.Options)

  const OPTIONS = useSelector(state => state.Options)

  useEffect(() => {
    dispatch(getCourses())
    dispatch(getDistrictOptions({ id: 19 }))
    // dispatch(getTrainingPartner())
    // dispatch(getCertificationPartner())
    dispatch(getUsersOptions())
  }, [dispatch])

  useEffect(() => {
    dispatch(
      getUsersOptions({
        privilege: PRIVILEGES.TRAINER,
      })
    )
  }, [dispatch, branch, master.csp])

  useEffect(() => {
    if (master.course) {
      get(`options/course/training/${master.course}`).then(res => {
        setTrainingPartner(res.data)
      })

      get(`options/course/certification/${master.course}`).then(res => {
        setCertificationPartner(res.data)
      })
    }
  }, [master.course])

  const getCoursesCsp = branch => {
    get(`temp-batch/course/${branch}`).then(res => {
      setCourses(res.data)
    })
  }

  useEffect(() => {
    if (selected?.csp?.value) {
      getCoursesCsp(selected?.csp?.value)
    }
  }, [selected?.csp])

  useEffect(() => {
    if (selected.trainer) {
      setTrainerContext(selected?.trainer)
    }
  }, [selected.trainer])

  useEffect(() => {
    const selectedData = {}
    const data = context.master

    const masterData = {}

    selectedData.csp = data?.branch?.name
      ? {
          label: data?.branch?.name,
          value: data?.branch?._id,
        }
      : null

    selectedData.course = data?.course?.name
      ? {
          label: data?.course?.name,
          value: data?.course?._id,
        }
      : null

    setSelected(selectedData)
    masterData.csp = data?.branch?._id || branch?.value
    masterData.tempUniqueId = data?.tempUniqueId
    masterData.course = data?.course?._id
    masterData.tempId = data?.tempId
    masterData.minStrength = data?.minStrength
    masterData.maxStrength = data?.maxStrength
    //TODO ; must remove after all
    masterData.id = data?.id
    setMaster(masterData)
    setMasterInitial(masterData)
    getBatch(masterData?.id)
    // eslint-disable-next-line
  }, [branch, context.master])

  useEffect(() => {
    context.setIsTabDisabled(!_.isEqual(master, masterInitial))
  }, [master, masterInitial])

  const handleSelect = (e, name, sub = false) => {
    const value = e ? (isArray(e) ? e.map(item => item.value) : e.value) : null
    if (sub) {
      setMaster(pre => ({ ...pre, [sub]: { ...pre[sub], [name]: value } }))
    } else {
      setMaster(pre => ({ ...pre, [name]: value }))
    }
    setSelected(pre => ({ ...pre, [name]: e }))
    setError(pre => ({ ...pre, [name]: false }))
  }

  const handleChange = e => {
    let { name, value } = e.target
    setMaster(pre => ({ ...pre, [name]: value }))
  }

  const handleSubmit = e => {
    e.preventDefault()

    const fieldsToCheck = [
      "course",
      "trainingPartner",
      "certificationPartner",
      "trainer",
    ]

    let flag = false

    fieldsToCheck.forEach(field => {
      if (!selected[field]) {
        flag = true
        setError(prev => ({ ...prev, [field]: true }))
      }
    })

    console.log(master)

    if (flag) return

    if (master.id) {
      put(`batch`, master)
        .then(res => {
          toastr.success(res.message)
          context.getBatchList()
          setMasterInitial(master)
        })
        .catch(err => {
          toastr.error(err?.response?.data?.message)
        })
    } else {
      post(`batch`, master)
        .then(res => {
          toastr.success(res.message)
          context.reset({})
          reset()
          context.getBatchList()
        })
        .catch(err => {
          if (err?.response?.status === 412) {
            toastr.warning(err?.response?.data?.message)
            return
          }

          toastr.error(err?.response?.data?.message)
        })
    }
  }

  const getBatch = batchId => {
    if (batchId) {
      get(`batch/basic/${batchId}`)
        .then(res => {
          const data = res.data
          const selectedData = {}

          selectedData.csp = {
            label: data?.branch?.name,
            value: data?.branch?._id,
          }

          selectedData.course = {
            label: data?.course?.name,
            value: data?.course?._id,
          }

          selectedData.certificationPartner = {
            label: data?.certificationPartner?.name,
            value: data?.certificationPartner?._id,
          }

          selectedData.trainingPartner = {
            label: data?.trainingPartner?.name,
            value: data?.trainingPartner?._id,
          }

          const trainerValues = []
          selectedData.trainer = data?.trainer.map(item => {
            trainerValues.push(item?._id)
            return {
              label: `${item?.firstName || ""} ${item?.lastName || ""}`,
              value: item?._id,
            }
          })

          selectedData.gender = data?.preRequisites?.gender?.map(value => {
            switch (value) {
              case 0:
                return { value: 0, label: "Female" }
              case 1:
                return { value: 1, label: "Male" }
              default:
                return { value: 2, label: "Other" }
            }
          })

          const studentCategory = multiSelectBoxValueGenerate(
            data?.preRequisites?.studentCategory
          )

          const districts = multiSelectBoxValueGenerate(
            data?.preRequisites?.districts,
            "district_name"
          )

          selectedData.studentCategory = studentCategory.options
          selectedData.districts = districts.options

          data.trainer = trainerValues
          data.certificationPartner = data?.certificationPartner?._id
          data.trainingPartner = data?.trainingPartner?._id
          data.preRequisites.districts = districts.values
          data.preRequisites.studentCategory = studentCategory.values
          data.csp = data?.branch?._id
          data.course = data?.course?._id

          data.id = data?._id
          delete data._id
          delete data.branch

          setSelected(selectedData)
          setMaster(data)
          setMasterInitial(data)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  const multiSelectBoxValueGenerate = (array, field = "name") => {
    if (!Array.isArray(array)) return

    const options = []
    const values = []

    for (const item of array) {
      options.push({ label: item[field], value: item._id })
      values.push(item._id)
    }

    return {
      options,
      values,
    }
  }

  const reset = () => {
    formRef.current.reset()
    context.setMaster({})
    setSelected({})
    setMaster({})
  }

  const setTrainerContext = trainers => {
    context.setContextState(pre => ({
      ...pre,
      trainers,
    }))
  }

  return (
    <Row>
      <AvForm
        ref={formRef}
        onValidSubmit={handleSubmit}
        className="needs-validation"
      >
        <Row>
          <Col md="12">
            <Row>
              <Admin>
                <Col md="3">
                  <div className="mb-3">
                    <Label>CSP</Label>
                    <Select
                      name="Branch"
                      options={allotted.branches}
                      value={selected.csp || null}
                      type="text"
                      isDisabled={master?.tempId}
                      onChange={e => {
                        setSelected(pre => ({
                          ...pre,
                          course: null,
                          trainer: null,
                        }))
                        handleSelect(e, "csp")
                      }}
                    />
                  </div>
                </Col>
              </Admin>
              <Col md="3">
                <div className="mb-3">
                  <Label>Batch Name</Label>
                  <AvField
                    name="name"
                    value={master.name || ""}
                    onChange={handleChange}
                    placeholder="Batch Name"
                    validate={{ required: { value: true } }}
                    errorMessage="Enter Batch Name"
                    className="form-control"
                  />
                </div>
              </Col>
              <Col md="3">
                <div className="mb-3">
                  <Label>Course</Label>
                  <Select
                    name="course"
                    classNamePrefix="select2-selection"
                    options={courses}
                    value={selected.course || null}
                    isDisabled={master?.tempId}
                    onChange={e => {
                      setSelected(pre => ({
                        ...pre,
                        trainer: null,
                        trainingPartner: null,
                        certificationPartner: null,
                      }))
                      handleSelect(e, "course")
                    }}
                  />
                </div>
                <SelectErrorMessage
                  message="Select course"
                  show={error.course}
                />
              </Col>

              {master?.tempId && (
                <Col md="2">
                  <div className="mb-3">
                    <Label>Temp Batch Id</Label>
                    <AvField
                      name="tempUniqueId"
                      value={master.tempUniqueId || ""}
                      onChange={handleChange}
                      disabled={master?.tempId}
                      type="text"
                      className="form-control"
                    />
                  </div>
                </Col>
              )}

              <Col md="2">
                <div className="mb-3">
                  <Label>Starting Date</Label>
                  <AvField
                    name="startDate"
                    value={master.startDate || ""}
                    onChange={handleChange}
                    type="date"
                    className="form-control"
                    validate={{ required: { value: true } }}
                  />
                </div>
              </Col>

              <Col md="2">
                <div className="mb-3">
                  <Label>End Date</Label>
                  <AvField
                    name="endDate"
                    value={master.endDate || ""}
                    min={moment(master.startDate)
                      .add(1, "day")
                      .format("YYYY-MM-DD")}
                    onChange={handleChange}
                    type="date"
                    className="form-control"
                    validate={{ required: { value: true } }}
                  />
                </div>
              </Col>

              <Col md="4">
                <div className="mb-3">
                  <Label>Training Partner</Label>
                  <Select
                    name="start_date"
                    type="date"
                    options={trainingPartner}
                    value={selected.trainingPartner || null}
                    onChange={e => {
                      setSelected(pre => ({
                        ...pre,
                        trainer: null,
                      }))
                      handleSelect(e, "trainingPartner")
                    }}
                  />
                </div>
                <SelectErrorMessage
                  message="Select Training Partner"
                  show={error.trainingPartner}
                />
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label>Certificate Partner</Label>
                  <Select
                    name="start_date"
                    type="date"
                    options={certificationPartner}
                    value={selected.certificationPartner || null}
                    onChange={e => {
                      handleSelect(e, "certificationPartner")
                    }}
                  />
                </div>
                <SelectErrorMessage
                  message="Select Certification Partner"
                  show={error.certificationPartner}
                />
              </Col>

              <Col md="4">
                <div className="mb-3">
                  <Label>Trainer</Label>
                  <Select
                    name="start_date"
                    type="date"
                    // options={OPTIONS.users.filter(user =>
                    //   user?.salary?.includes(selected?.course?.value)
                    // )}
                    options={OPTIONS.users.filter(
                      user =>
                        user?.salary?.includes(selected?.course?.value) &&
                        user?.company?.includes(
                          selected?.trainingPartner?.value
                        )
                    )}
                    value={selected.trainer || null}
                    isMulti
                    onChange={e => {
                      handleSelect(e, "trainer")
                    }}
                  />
                </div>
                <SelectErrorMessage
                  message="Select Trainer"
                  show={error.trainer}
                />
              </Col>

              <Col md="2">
                <div className="mb-3">
                  <Label>Minimum Strength</Label>
                  <AvField
                    name="minStrength"
                    value={master.minStrength || ""}
                    onChange={handleChange}
                    type="number"
                    className="form-control"
                    min={1}
                    placeholder="Min. students"
                    validate={{ required: { value: true } }}
                  />
                </div>
              </Col>

              <Col md="2">
                <div className="mb-3">
                  <Label>Maximum Strength</Label>
                  <AvField
                    name="maxStrength"
                    value={master.maxStrength || ""}
                    onChange={handleChange}
                    type="number"
                    className="form-control"
                    min={master.minStrength}
                    placeholder="Max. students"
                    validate={{ required: { value: true } }}
                  />
                </div>
              </Col>

              <Row>
                <div className="mb-1">
                  <Label style={{ fontSize: ".9rem" }}>Pre requisites</Label>
                </div>
                <Col md="3">
                  <div className="mb-3">
                    <Label>Gender</Label>
                    <Select
                      isMulti
                      name="gender"
                      classNamePrefix="select2-selection"
                      options={[
                        { label: "Female", value: 0 },
                        { label: "Male", value: 1 },
                        { label: "Other", value: 2 },
                      ]}
                      value={selected.gender || null}
                      onChange={e => {
                        handleSelect(e, "gender", "preRequisites")
                      }}
                    />
                  </div>
                </Col>
                <Col md="3">
                  <div className="mb-3">
                    <Label>Caste Category </Label>
                    <Select
                      name="gender"
                      isMulti
                      classNamePrefix="select2-selection"
                      options={OPTIONS.studentCategory}
                      value={selected.studentCategory || null}
                      onChange={e => {
                        handleSelect(e, "studentCategory", "preRequisites")
                      }}
                    />
                  </div>
                </Col>
                <Col md="3">
                  <div className="mb-3">
                    <Label>District</Label>
                    <Select
                      isMulti
                      name="district_id"
                      value={selected.districts || null}
                      onChange={e => {
                        handleSelect(e, "districts", "preRequisites")
                      }}
                      options={distrcts}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>

        <Col style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="ms-1">
            <Button
              type="button"
              onClick={() => context.reset({})}
              className="mr-auto"
              color="danger"
            >
              Reset
            </Button>
          </div>

          <div>
            <Button type="submit" className="me-2" color="primary">
              Save
            </Button>

            {!context?.master?.tempId && (
              <Button
                onClick={() => {
                  next()
                }}
                type="button"
                className="me-2"
                color="primary"
              >
                Next
              </Button>
            )}
          </div>
        </Col>
      </AvForm>
    </Row>
  )
}

export default Basic
