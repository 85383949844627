import React, { useEffect, useRef, useState } from "react"
import { Col, Row, Label, Button } from "reactstrap"

import Select from "react-select"

import { AvField, AvForm } from "availity-reactstrap-validation"

import { Modal } from "react-bootstrap"

import { MDBDataTable } from "mdbreact"
import { del, get, post, put } from "../../../../helpers/api_helper"

import moment from "moment"

import Swal from "sweetalert2"

import toastr from "toastr"
import "../style.scss"
const BankAccount = ({ user, handleReload }) => {
  const formRef = useRef()
  const [master, setMaster] = useState({})
  const [bankList, setBankList] = useState([])
  const [bankOptions, setBankOptions] = useState([])
  const [popupBankData, setPopupBankData] = useState({})
  const [popupBankView, setPopupBankView] = useState(false)

  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [])

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  const handleTableData = () => {
    get(`user/banks/${user._id}`).then(res => {
      const options = []
      res?.data.map((item, idx) => {
        item.id = idx + 1
        item.date = moment(item.date).format("DD-MM-YYYY")

        item.time = moment(item.time, "HH:mm:ss").format("hh:mm a")

        item.staff = `${item.addedBy.firstName || ""} ${
          item.addedBy.lastName || ""
        }`

        item.action = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2em",
                marginRight: "0.5em",
              }}
              onClick={() => {
                setPopupBankData({
                  date: item.date,
                  time: item.time,
                  name: item.name,
                  branch: item.branch,
                  ifsc: item.ifsc,
                  staff: item.staff,
                  accountNo: item.accountNo,
                })
                setPopupBankView(true)
              }}
            ></i>
            <i
              className="far fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.1em",
                marginRight: "0.5em",
              }}
              onClick={() => {
                toTop()
                handlePreUpdate(item)
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ fontSize: "1em", cursor: "pointer" }}
              onClick={() => {
                handleDelete(item._id)
              }}
            ></i>
          </div>
        )

        options.push({ label: `${item.name} - ${item.ifsc}`, value: item._id })
        return item
      })

      setBankList(res.data)
      setBankOptions(options)
    })
  }

  const handleChange = e => {
    let { name, value } = e.target
    setMaster(pre => ({ ...pre, [name]: value }))
  }

  const handleReset = (isButton = false) => {
    setMaster({})
    formRef.current.reset()
    if (!isButton) {
      console.log("Resetting")
      handleTableData()
    }
  }

  const handleValidSubmit = () => {
    if (master.id) {
      put("user/bank", master)
        .then(res => {
          console.log(res)
          toastr.success(res.message)
          handleReset()
        })
        .catch(err => {
          toastr.error(err?.response?.data?.message)
        })
    } else {
      post("user/bank", { userId: user._id, ...master })
        .then(res => {
          if (bankList.length === 0) {
            handleReload()
          }
          handleReset()
          toastr.success(res.message)
        })
        .catch(err => {
          toastr.error(err?.response?.data?.message)
        })
    }
  }

  const handlePreUpdate = item => {
    setMaster({
      id: item._id,
      name: item.name,
      branchName: item.branch,
      accountNo: item.accountNo,
      ifsc: item.ifsc,
    })
  }

  const handleSetBank = e => {
    put("user/set-bank", { userId: user._id, bankId: e.value })
      .then(res => {
        console.log(res)
      })
      .catch(err => {
        toastr.error(err?.response?.data?.message)
      })
  }

  const handleDelete = id => {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`user/bank/${id}`)
          .then(res => {
            toastr.success(res.message)
            handleReset()
          })
          .catch(err => {
            toastr.error(err?.response?.data?.message)
          })
      }
    })
  }

  const BankData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 200,
      },
      {
        label: "Bank",
        field: "name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Branch",
        field: "branch",
        sort: "asc",
        width: 200,
      },
      {
        label: "IFSC",
        field: "ifsc",
        sort: "asc",
        width: 200,
      },
      {
        label: "Account No",
        field: "accountNo",
        sort: "asc",
        width: 200,
      },
      {
        label: "Added By",
        field: "staff",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: bankList,
  }

  return (
    <>
      <Row>
        <Col className="col-12">
          <Row>
            <Col
              className="col-12"
              style={{ fontFamily: "IBM Plex Sans,sans-serif" }}
            >
              <Row
                style={{
                  borderBottom: "1px solid #e0e0e0",
                  paddingBottom: ".3rem",
                  marginBottom: ".8rem",
                }}
              >
                <Col md={3}>
                  <div className="mb-3">
                    <Label>Default Bank</Label>
                    <Select
                      value={
                        user.bank
                          ? {
                              label: user.bank.name || null,
                              value: user.bank._id || null,
                            }
                          : null
                      }
                      name="bank"
                      onChange={handleSetBank}
                      classNamePrefix="select2-selection"
                      options={bankOptions}
                    />
                  </div>
                </Col>
              </Row>
              {/* <CardBody> */}
              <Row>
                <Col xl="12">
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={handleValidSubmit}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Bank Name</Label>
                          <AvField
                            placeholder="Bank Name"
                            type="text"
                            errorMessage="Enter Bank Name"
                            className="form-control"
                            validate={{
                              required: {
                                value: true,
                              },
                            }}
                            id="validationCustom03"
                            name="name"
                            value={master?.name || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Branch Name
                          </Label>
                          <AvField
                            placeholder="Branch Name"
                            type="text"
                            errorMessage="Enter Branch Name"
                            className="form-control"
                            validate={{
                              required: {
                                value: true,
                              },
                            }}
                            id="validationCustom03"
                            name="branchName"
                            value={master?.branchName || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Account No.
                          </Label>
                          <AvField
                            name="accountNo"
                            value={master?.accountNo || ""}
                            onChange={handleChange}
                            placeholder="Account No."
                            type="number"
                            errorMessage="Enter Account No."
                            className="form-control"
                            validate={{
                              required: {
                                value: true,
                              },
                              pattern: {
                                value: "^[0-9]{9,18}$",
                                errorMessage: "Enter valid account number",
                              },
                            }}
                            id="validationCustom03"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Bank IFSC</Label>
                          <AvField
                            name="ifsc"
                            value={master?.ifsc || ""}
                            onChange={handleChange}
                            placeholder="Bank IFSC"
                            type="text"
                            errorMessage="Enter Bank IFSC"
                            className="form-control"
                            validate={{
                              required: {
                                value: true,
                              },
                              pattern: {
                                value: "^[A-Z]{4}0[A-Z0-9]{6}$",
                                errorMessage: "Enter valid IFSC code",
                              },
                            }}
                            id="validationCustom03"
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div>
                          <Button
                            color={master.id ? "warning" : "primary"}
                            type="submit"
                            style={{
                              marginRight: "5%",
                            }}
                          >
                            {master.id ? "Update" : "Submit"}
                          </Button>

                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => handleReset(true)}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                  <div className="mt-3">
                    <MDBDataTable
                      id="staffBankTableID"
                      responsive
                      bordered
                      data={BankData}
                      searching={true}
                      info={true}
                      disableRetreatAfterSorting={true}
                      entries={20}
                    />
                  </div>
                </Col>
              </Row>
              {/* </CardBody> */}
              {/* </CardBody> */}
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal show={popupBankView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Bank Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupBankView(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm
          ref={formRef}
          className="needs-validation"
          onValidSubmit={(e, v) => {}}
        >
          <div className="modal-body">
            <table
              id="userBankAccountTable"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ textAlign: "left", paddingLeft: "10px" }}>
                  Date :
                </td>
                <td>{popupBankData?.date}</td>
                <td style={{ width: "184px", padding: "10px" }}>Time :</td>
                <td style={{ textAlign: "left" }}>{popupBankData?.time}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Bank Name :</td>
                <td>{popupBankData?.name}</td>
                <td style={{ width: "184px", padding: "10px" }}>
                  Bank Account :
                </td>
                <td style={{ textAlign: "left" }}>
                  {popupBankData?.accountNo}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Bank IFSC :</td>
                <td>{popupBankData?.ifsc}</td>
                <td style={{ padding: "10px" }}>Bank Branch :</td>
                <td style={{ textAlign: "left" }}>{popupBankData?.branch}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Created By :</td>
                <td>{popupBankData?.staff}</td>
                <td style={{ padding: "10px" }} hidden={!popupBankData?.Update}>
                  Updated Date :
                </td>
                <td style={{ textAlign: "left" }}>{popupBankData?.Update}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }} hidden={!popupBankData?.UpTime}>
                  Updated Time :
                </td>
                <td>{popupBankData?.UpTime}</td>

                <td
                  style={{ width: "184px", padding: "10px" }}
                  hidden={!popupBankData?.staff2}
                >
                  Updated By :
                </td>
                <td style={{ textAlign: "left" }}>{popupBankData?.staff2}</td>
              </tr>

              {/* </div> */}
            </table>
          </div>
        </AvForm>
      </Modal>
    </>
  )
}

export default BankAccount
