import React from "react"
import { Row, Col, Card, CardBody, Label, Button } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import { MDBDataTable } from "mdbreact"
import Breadcrumb from "../../../components/Common/Breadcrumb"

function index() {
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "CSP",
        field: "frmcsp",
        width: 150,
      },
    //   {
    //     label: "To CSP",
    //     field: "tocsp",
    //     width: 150,
    //   },
      {
        label: "Class",
        field: "class",
        width: 150,
      },
      {
        label: "Assets",
        field: "assets",
        width: 150,
      },
      {
        label: "Remarks",
        field: "remark",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        width: 150,
      },
    ],
    rows: [
      {
        id: "1",
        assets: "Computer",
        class: "Automobile Class XI",
        tocsp: "KANNUR CSP",
        frmcsp: "KOZHIKOD CSP",
        date: "12-01-2024",
        remark: "Alloted of 10 computers",
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="far fa-edit"
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ cursor: "pointer", fontSize: "1em" }}
            ></i>
          </div>
        ),
      },
      {
        id: "2",
        assets: "Desktops",
        class: "Drone Making Class XI",
        tocsp: "KOCHI CSP",
        frmcsp: "KANNUR CSP",
        date: "15-01-2024",
        remark: "Alloted of 15 Desktops",
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="far fa-edit"
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ cursor: "pointer", fontSize: "1em" }}
            ></i>
          </div>
        ),
      },
      {
        id: "3",
        assets: "Printers",
        class: "3D Printing Class X",
        tocsp: "KOCHI CSP",
        frmcsp: "KAKKANAD CSP",
        date: "15-01-2024",
        remark: "Alloted of 2 Printers",
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="far fa-edit"
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ cursor: "pointer", fontSize: "1em" }}
            ></i>
          </div>
        ),
      },
    ],
  }
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumb title="Home" breadcrumbItem="Assets Allocations" />

        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <AvForm className="needs-validation">
                  <Row>
                    <Col md="3">
                      <div className="">
                        <Label>CSP</Label>
                        <Select
                          name="course"
                          classNamePrefix="select2-selection"
                          options={[
                            { label: "KANNUR CSP", value: 2 },
                            { label: "ERNAKULAM CSP", value: 3 },
                            { label: "KOZHIKOD CSP", value: 4 },
                            { label: "MALAPURAM CSP", value: 5 },
                          ]}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Assets</Label>
                        <Select
                          name="students"
                          classNamePrefix="select2-selection"
                          options={[
                            { label: "Computer", value: 0 },
                            { label: "Land phones", value: 1 },
                            { label: "UPS", value: 2 },
                            { label: "CPU", value: 3 },
                          ]}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Class</Label>
                        <Select
                          name="batch"
                          classNamePrefix="select2-selection"
                          options={[
                            { label: "New 3 Day Batch", value: 0 },
                            { label: "New Batch", value: 1 },
                            { label: "New automobile batch", value: 2 },
                          ]}
                        />
                      </div>
                    </Col>

                    <Col md="3">
                      <div className="mb-3">
                        <Label>Descriptions</Label>
                        <AvField
                          name="des"
                          placeholder="description"
                          type="textarea"
                        />
                      </div>
                    </Col>

                    <Col md="3" style={{ paddingTop: "" }}>
                      <Button color="primary" type="submit">
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <MDBDataTable
                  id="allocationTableIdd"
                  responsive
                  bordered
                  info={true}
                  searching={true}
                  entries={20}
                  disableRetreatAfterSorting={true}
                  data={data}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default index
