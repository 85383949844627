import React, { useEffect, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label, Badge } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import "./style.scss"
import moment from "moment"
import { get } from "../../../helpers/api_helper"
import TablePagination from "../../../components/Common/Pagination"
import { useTransition } from "react"
import { jsonToExcel } from "../../../helpers/functions"
import toastr from "toastr"
// import { TablePagination } from "@mui/material"

const LeadsReport = () => {
  const [tableData, setTableData] = useState([])
  const [filterObject, setFilterObject] = useState({
    from: "",
    to: "",
    type: "",
    option: "",
  })
  const [selected, setSelected] = useState({ type: "", option: "" })
  const [exports, setExports] = useState([])
  const [otherOptions, setOtherOptions] = useState([])
  const [isExportPending, startExport] = useTransition()

  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 20

  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [page, filterObject])

  const handleTableData = () => {
    get(
      `report/lead?page=${page}&limit=${limit}&from_date=${filterObject.from}&to_date=${filterObject.to}&type=${filterObject.type}&option=${filterObject.option}`
    ).then(res => {
      const data = res.data

      const exportData = []

      data.map((item, idx) => {
        item.id = idx + 1 + (page - 1) * limit

        item.name = `${item?.reg_id?.firstname || ""} ${
          item?.reg_id?.lastname || ""
        }`

        item.date = item.date && moment(item.date).format("DD-MM-YYYY")

        item.assign = `${item?.assigned?.firstName || ""} ${
          item?.assigned?.lastName || ""
        }`

        item.mobile = item?.reg_id?.mobile
        item.email = item?.reg_id?.email
        item.course = item?.course?.name || item?.event?.name
        item.source = item?.source?.name
        item.branch = item?.branch?.name
        item.sector = item?.sector?.name
        item.remarks = item?.message
        item.payStatus = (
          // <span
          //   style={{
          //     background: item?.purchased ? "success" : "danger",
          //   }}
          //   className="badge font-size-12 ms-2"
          // >
          //   {item?.purchased ? "Paid" : "Not paid"}
          // </span>
          <Badge
            color={item?.purchased ? "success" : "danger"}
            className="font-size-12 ms-2"
          >
            {" "}
            {item?.purchased ? "Paid" : "Not paid"}
          </Badge>
        )

        delete item.message

        item.followupStatus = (
          <span
            style={{
              background: item?.latest_followup_status?.color,
            }}
            className="badge font-size-12 ms-2"
          >
            {item?.latest_followup_status?.name}
          </span>
        )

        // TODO: Export details
        const dataExport = {}

        dataExport.Date = item.date
        dataExport.Name = item.name
        dataExport.Mobile = item.mobile
        dataExport.Course = item.course
        dataExport.Source = item.source
        dataExport.Assigned = item.assign
        dataExport.Status = item?.latest_followup_status?.name

        exportData.push(dataExport)

        return item
      })

      const count = res.count
      setTableData(data)
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)

      startExport(() => {
        handleExportData(count)
      })
      // setRows(data)
      // setExports(exportData)
    })
  }

  const handleExportData = async count => {
    get(
      `report/lead?page=0&limit=${count}&from_date=${filterObject.from}&to_date=${filterObject.to}&type=${filterObject.type}`
    ).then(res => {
      const data = res.data

      const exportData = []

      data.map((item, idx) => {
        item.id = idx + 1 + (page - 1) * limit

        item.name = `${item?.reg_id?.firstname || ""} ${
          item?.reg_id?.lastname || ""
        }`

        item.date = item.date && moment(item.date).format("DD-MM-YYYY")

        item.assign = `${item?.assigned?.firstName || ""} ${
          item?.assigned?.lastName || ""
        }`

        item.mobile = item?.reg_id?.mobile
        item.email = item?.reg_id?.email
        item.course = item?.course?.name || item?.event?.name
        item.source = item?.source?.name
        item.branch = item?.branch?.name
        item.sector = item?.sector?.name
        item.remarks = item?.message
        item.payStatus = (
          <Badge
            color={item?.purchased ? "success" : "danger"}
            className="font-size-12 ms-2"
          >
            {" "}
            {item?.purchased ? "Paid" : "Not paid"}
          </Badge>
        )

        delete item.message

        item.followupStatus = (
          <span
            style={{
              background: item?.latest_followup_status?.color,
            }}
            className="badge font-size-12 ms-2"
          >
            {item?.latest_followup_status?.name}
          </span>
        )

        // TODO: Export details
        const dataExport = {}

        dataExport["#"] = idx + 1
        dataExport.Date = item.date
        dataExport["Unique Id"] = item.uniqueId
        dataExport["Name"] = item.name
        dataExport.Mobile = item.mobile
        dataExport.Email = item.email
        dataExport.Type = item.type
        dataExport["Course/Event"] = item.course
        dataExport["Sector"] = item.sector
        dataExport.Branch = item.branch
        dataExport.Assigned = item.assign
        dataExport["Lead Type"] = item.source
        dataExport["Status"] = item?.latest_followup_status?.name
        dataExport["Payment Status"] = item?.purchased ? "Paid" : "Not paid"

        exportData.push(dataExport)

        return item
      })
      setExports(exportData)
    })
  }

  const handleChange = (event, value) => {
    setPage(value)
  }

  const handleValueChange = (value, name) => {
    const obj = { ...filterObject }
    obj[name] = value
    setFilterObject(obj)
  }
  const typeOptions = [
    { label: "Course", value: "Course" },
    { label: "Event", value: "Event" },
  ]
  const reset = () => {
    setFilterObject({})
    setPage(1)
    setTotalPage(0)
    setSelected({type:"",option:""})
    setOtherOptions([])
  }

  const handleExport = () => {
    if (exports.length > 0) {
      jsonToExcel(exports, `fee-reports-${moment().format("DD-MM-YY")}`)
    } else {
      toastr.info(`There are fee leads to export`)
    }
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Unique Id",
        field: "uniqueId",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        width: 150,
      },

      {
        label: "Mobile",
        field: "mobile",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        width: 150,
      },
      {
        label: "Type",
        field: "type",
        width: 150,
      },
      {
        label: "Course/Event",
        field: "course",
        width: 150,
      },
      {
        label: "Sector",
        field: "sector",
        width: 150,
      },
      {
        label: "Center",
        field: "branch",
        width: 150,
      },

      {
        label: "Assigned",
        field: "assign",
        width: 150,
      },
      {
        label: "Lead Type",
        field: "source",
        width: 150,
      },
      {
        label: "Status",
        field: "followupStatus",
        width: 150,
      },
      {
        label: "Payment Status",
        field: "payStatus",
        sort: "desc",
        width: 400,
      },
    ],
    rows: tableData,
  }
  const handleOptions = value => {
    if (value === "Course")
      get("/options/courses").then(res =>  setOtherOptions(res.data))
    else if (value === "Event")
      get("/options/events").then(res => setOtherOptions(res.data))
    else setOtherOptions([])
  }
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Leads" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label> From</Label>
                          <AvField
                            name="from"
                            value={filterObject.from || ""}
                            onChange={e =>
                              handleValueChange(e.target.value, e.target.name)
                            }
                            max={moment().format("YYYY-MM-DD")}
                            className="form-control"
                            type="date"
                            placeholder="From"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>To</Label>
                          <AvField
                            name="to"
                            max={moment().format("YYYY-MM-DD")}
                            min={filterObject.from}
                            value={filterObject.to || ""}
                            onChange={e =>
                              handleValueChange(e.target.value, e.target.name)
                            }
                            className="form-control"
                            type="date"
                            placeholder="To"
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Type</Label>
                          <Select
                            onChange={selected => {
                              handleOptions(selected?.value)
                              handleValueChange(selected?.value, "type")
                              setSelected({ type: selected })
                            }}
                            value={selected.type}
                            isClearable={true}
                            options={typeOptions}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Course/ Event</Label>
                          <Select
                            onChange={selected => {
                              handleValueChange(selected?.value, "option")
                              setSelected(prev => ({
                                ...prev,
                                option: selected,
                              }))
                            }}
                            value={selected.option || ""}
                            isClearable={true}
                            options={otherOptions}
                          />
                        </div>
                      </Col>
                      <Col className="mt-4 pt-1">
                        <div className="mb-3">
                          <Button color="danger" type="button" onClick={reset}>
                            Reset
                          </Button>

                          <Button
                            style={{ marginLeft: "14px" }}
                            color="warning"
                            type="button"
                            disabled={isExportPending}
                            onClick={handleExport}
                          >
                            Export{" "}
                            {isExportPending && (
                              <i className="mdi mdi-spin mdi-loading"></i>
                            )}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                  <MDBDataTable
                    id="leadsReportTable"
                    bordered
                    responsive
                    searching={false}
                    displayEntries={false}
                    paging={false}
                    disableRetreatAfterSorting={true}
                    data={data}
                    sortable={false}
                  ></MDBDataTable>
                  <TablePagination
                    page={page}
                    onChange={handleChange}
                    count={totalPage}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default LeadsReport
