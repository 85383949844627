import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Modal,
  Row,
  Table,
} from "reactstrap"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import Select from "react-select"

import { AvField, AvForm } from "availity-reactstrap-validation"
import { get, post } from "../../../helpers/api_helper"
import toastr from "toastr"
import moment from "moment"
import { API_URL } from "../../../config"

const CertificateTemplate = () => {
  const [tableData, setTableData] = useState([])
  const [refresh, setRefresh] = useState(1)
  const [image, setImage] = useState("")
  const [name,setName]= useState('')
  const [type,setType] = useState({label:'Select...',value:''})
  const [modal,setModal] = useState(false)
  const [index,setIndex] = useState(null)

  const fetchTemplate = () => {
    get(`certificate-template`).then(res => {
      if (res.success) {
        setTableData(res.data)
      } else {
        toastr.error("Failed to fetch templates")
      }
    })
  }

  useEffect(() => {
    fetchTemplate()
  }, [refresh])

  const uploadImage = async e => {
    const selectedFile = e.target.files[0]

    if (selectedFile) {
      try {
        const fd = new FormData()
        fd.append("template", selectedFile)

        const response = await post(`certificate-template/upload`, fd)
        if (response.status === 200) {
          setImage(response.data.new_filename)
        } else {
          toastr.error(response.data.message)
        }
      } catch (error) {
        toastr.error("An error occurred while uploading the file.")
        e.target.value = ""
      }
    }
  }

  const handleSubmit = ()=>{
    if(!name){
      toastr.error('Name is required')
      return
    }
    if(!type.value){
      toastr.error('Type is required')
      return
    }
    if(!image){
      toastr.error('Choose a template image')
      return
    }
    const postBody = {
      name: name,
      type: type.value,
      image: image
    }
    post(`certificate-template`,postBody).then(res=>{
      if(res.success){
        toastr.success('Template uploaded.')
        setRefresh(prev=>prev + 1)
      }
    })
  }

  const viewTemplate = (index)=>{
    if(tableData[index].image){
    setModal(true)
    setIndex(index)
    }else{
      toastr.error('No preview availiable')
    }
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumb title="Home" breadcrumbItem="Certification Template" />
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <Row>
                  <Col md={3}>
                    <div>
                      <Label> Name</Label>
                      <Input
                        name="student"
                        classNamePrefix="select2-selection"
                        placeholder="Name"
                        onChange={(e)=>setName(e.target.value)}
                        value={name}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div>
                      <Label>Type</Label>
                      <Select
                        name="batch"
                        classNamePrefix="select2-selection"
                        options={[
                          { label: "Course Completion", value: 1 },
                          { label: "NCVET Short-term", value: 2 },
                          { label: "NCVET Long-term", value: 3 },
                        ]}
                        onChange={(item)=>setType(item)}
                        value={type}
                        width={50}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <AvForm
                      style={{ width: "300px", marginTop: "28px" }}
                      //  ref={formRef} onValidSubmit={handleSubmit}
                    >
                      <div style={{ width: "300px" }}>
                        <div className="d-flex  flex-column">
                          <AvField
                            name="image"
                            type="file"
                            className="form-control"
                            id="validationCustom03"
                            rows="1"
                            title=""
                            accept="image/*"
                            onChange={uploadImage}
                          />
                        </div>
                      </div>
                    </AvForm>
                  </Col>
                  <Col>
                    <div style={{ marginTop: "28px" }}>
                      <Button style={{ marginRight: "12px" }} color="primary" onClick={handleSubmit}>
                        Upload
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Table bordered>
                  <thead>
                    <tr>
                      <th style={{ verticalAlign: "middle" }}>#</th>
                      <th style={{ verticalAlign: "middle" }}>Name</th>
                      <th style={{ verticalAlign: "middle" }}>Type</th>
                      <th style={{ verticalAlign: "middle" }}>Date</th>
                      <th style={{ verticalAlign: "middle" }}>Time</th>
                      <th style={{ verticalAlign: "middle" }}>Added By</th>
                      <th style={{ verticalAlign: "middle" }}>Status</th>
                      <th style={{ verticalAlign: "middle" }}>Template</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((item, index) => (
                      <tr style={{ textAlign: "center" }}>
                        <td>{index + 1}</td>
                        <td style={{ textAlign: "start" }}>{item.name}</td>
                        <td>
                          {item.type === 1
                            ? "Course Completion"
                            : item.type === 2
                            ? "NCVET Short-term"
                            : item.type === 3
                            ? "NCVET Long-term"
                            : "err"}
                        </td>
                        <td>{moment(item.date).format("DD-MM-YYYY")}</td>
                        <td>
                          {moment(item.time, "HH:mm:ss").format("hh:mm A")}
                        </td>
                        <td>{item.addedBy.firstName}{' '}{item.addedBy.lastName}</td>
                        <td>
                          {item.status === 0
                            ? "Active"
                            : item.status === 1
                            ? "Inactive"
                            : "err"}
                        </td>
                        <td>
                          <a onClick={()=>viewTemplate(index)} href="#">View</a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Modal
                    show={modal}
                    isOpen={modal}
                    toggle={() => {
                      setModal(false)
                      setIndex(null)
                    }}
                    size="xl"
                    style={{
                      maxWidth: '50vw',
                      
                    }}
                    centered={true}>
                      <div className="modal-header">
          <h6 className="modal-title" style={{margin:'15px'}}>{tableData[index]?.name}</h6>
            <button
              type="button"
              onClick={() => {
                setModal(false);
                setIndex(null)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
                  <img src={`${API_URL}${tableData[index]?.image}`} alt="template"/>
                </Modal>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default CertificateTemplate
