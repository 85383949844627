import React, { useState, useEffect, useRef } from "react"
import { Row, Col, Card, CardBody, Label, Button } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import { MDBDataTable } from "mdbreact"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import moment from "moment"
import { del, get, post, put } from "../../../helpers/api_helper"
import { useNavigate } from "react-router-dom"
import { API_URL } from "../../../config"

import axios from "axios"
import toastr from "toastr"
import Swal from "sweetalert2"
import { dateConverter, setTitle } from "../../../helpers/functions"
import SelectErrorMessage from "../../../components/Common/SelectErrorMessage"

const Renewals = () => {
  setTitle("Renewals")
  const navigate = useNavigate()
  const formRef = useRef()

  const [masterObject, setMasterObject] = useState({
    partner: "",
    agreement: "",
    renewDate: "",
    person: "",
    validity: "",
    documents: [{ name: "", file: "" }],
  })
  const [selected, setSelected] = useState({ partner: "", agreement: "" })

  const [agreementData, setAgreementData] = useState({
    validity: "",
    date: "",
  })

  const [tableData, setTableData] = useState([])
  const [error, setError] = useState({
    agreement: false,
    partner: false,
  })

  const [partnerOptions, setPartnerOptions] = useState([])
  const [agreementOptions, setAgreementOptions] = useState([])

  useEffect(() => {
    handlePartnerOptions()
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [])

  const handlePartnerOptions = () => {
    get("agreement/options/partners").then(res => {
      setPartnerOptions(res.data)
    })
  }
  const handleAgreementOptions = id => {
    get(`agreement/options/agreements/expired?id=${id}`).then(res => {
      setAgreementOptions(res.data)
    })
  }

  const handleNavigate = id => {
    navigate(`/renew`, { state: { id, renew: false } })
  }

  const handleTableData = () => {
    get("agreement/renew").then(res => {
      const result = res.data
      result.map((item, index) => {
        // console.log(item)
        item.id = index + 1
        item.partner = item.company
        item.date = dateConverter(item.date)
        item.validity = dateConverter(item.validity)
        item.renewDate = dateConverter(item.renewDate)

        item.options = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              onClick={() => {
                handleNavigate(item._id)
              }}
              style={{
                cursor: "pointer",
              }}
              className="fas fa-eye"
            ></i>
            <i
              className="far fa-edit"
              onClick={() => handleEdit(item._id)}
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              onClick={() => handleDelete(item._id)}
              style={{ cursor: "pointer", fontSize: "1em" }}
            ></i>
          </div>
        )
        return item
      })

      setTableData(result)
    })
  }
  const handleSubmit = () => {
    const errors = { ...error }
    let alreadyError = false
    if (!masterObject.partner) {
      errors.partner = true
      alreadyError = true
    }

    if (!masterObject.agreement) {
      errors.agreement = true
      alreadyError = true
    }
    if (alreadyError) return

    if (masterObject.id) {
      put("agreement/renew", masterObject)
        .then(res => {
          toastr.success(res.message)
          reset()
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    } else {
      post("agreement/renew", masterObject)
        .then(res => {
          toastr.success(res.message)
          reset()
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    }
  }
  const handleEdit = id => {
    toTop()
    get(`agreement/renew/single?id=${id}`).then(res => {
      const select = { ...selected }
      const data = res.data

      const agreement = data.agreement
      select.partner = data.partner
      select.agreement = agreement

      setAgreementData({
        validity: moment(agreement.validity).format("DD-MM-YYYY"),
        date: moment(agreement.agreementDate).format("DD-MM-YYYY"),
      })

      data.agreement = data.agreement?.value
      data.partner = data.partner?.value

      if (data.documents?.length < 1) data.documents = [{ name: "", file: "" }]

      setMasterObject(data)
      setSelected(select)
    })
  }
  const handleDelete = async id => {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`agreement/renew?id=${id}`)
          .then(res => {
            toastr.success(res.message)
            handleTableData()
          })
          .catch(err => {
            toastr.error(err.response.data.message)
          })
      }
    })
  }

  const handleFileUpload = (e, index) => {
    // const selectedFile = e.target.files[0]

    // const allowedTypes = [
    //   "image/jpeg",
    //   "image/png",
    //   "application/pdf",
    //   "text/plain",
    //   "application/msword",
    //   "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    //   "application/vnd.ms-excel",
    //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    // ]

    // if (!allowedTypes.includes(selectedFile?.type)) {
    //   toastr.error(
    //     "Please select a valid file type (JPEG, PNG, PDF, TXT, DOC, DOCX, XLS, XLSX)"
    //   )
    //   return
    // }
    const fd = new FormData()
    fd.append("file", e.target.files[0])
    axios.post(`${API_URL}agreement/document`, fd).then(response => {
      if (response.data.status === 200) {
        handleDocumentValueChange(
          response.data.data.new_filename,
          index,
          "file"
        )
      } else {
        toastr.error(response.data.message)
      }
    })
  }

  const handleValueChange = (value, name) => {
    const obj = { ...masterObject }
    if (name === "partner") {
      obj.agreement = ""
    }
    obj[name] = value
    setMasterObject(obj)
  }
  const handleSelectedValueChange = (value, name) => {
    const obj = { ...selected }
    if (name === "partner") {
      obj.agreement = ""
    }
    obj[name] = value
    setSelected(obj)
  }
  const handlePartnerChange = selected => {
    handleValueChange(selected.value, "partner")
    handleSelectedValueChange(selected, "partner")
    handleAgreementOptions(selected.value)
  }
  const handleAgreementChange = selected => {
    handleValueChange(selected.value, "agreement")
    handleSelectedValueChange(selected, "agreement")
    setAgreementData({
      expireDate: selected.validity,
      validity: moment(selected.validity).format("DD-MM-YYYY"),
      date: moment(selected.agreementDate).format("DD-MM-YYYY"),
    })
  }

  const handleDocumentValueChange = (value, index, name) => {
    const obj = { ...masterObject }
    obj.documents[index][name] = value
    setMasterObject(obj)
  }

  const handleAddField = () => {
    const obj = { ...masterObject }
    obj.documents.push({ name: "", file: "" })
    setMasterObject(obj)
  }

  const handleRemoveField = index => {
    const obj = { ...masterObject }
    obj.documents.splice(index, 1)
    setMasterObject(obj)
  }
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }
  const reset = () => {
    formRef.current.reset()
    handleTableData()
    setMasterObject({
      partner: "",
      agreement: "",
      renewDate: "",
      person: "",
      validity: "",
      documents: [{ name: "", file: "" }],
    })
    setSelected({})
    setError({})
    toTop()
    setAgreementOptions([])
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Training Partner",
        field: "partner",
        width: 150,
      },
      {
        label: "Agreement",
        field: "agreementName",
        width: 150,
      },
      {
        label: "Renewed Person",
        field: "person",
        width: 150,
      },
      {
        label: "Renew Date",
        field: "renewDate",
        width: 150,
      },
      {
        label: "Valid up to",
        field: "validity",
        width: 150,
      },
      {
        label: "Action",
        field: "options",
        width: 150,
      },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumb title="Home" breadcrumbItem="Renewals" />

        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <AvForm
                  className="needs-validation"
                  ref={formRef}
                  onValidSubmit={handleSubmit}
                >
                  <Row>
                    <Col md="3">
                      <div className="">
                        <Label>Company</Label>
                        <Select
                          name="partner"
                          classNamePrefix="select2-selection"
                          options={partnerOptions}
                          value={selected.partner || ""}
                          isDisabled={masterObject.id}
                          onChange={selected => {
                            handlePartnerChange(selected)
                            setError(prev => ({ ...prev, partner: false }))
                          }}
                        />
                        <SelectErrorMessage
                          customStyle={{
                            width: "100%",
                            fontSize: "87.5%",
                            color: "var(--bs-form-invalid-color)",
                          }}
                          message="Select Partner"
                          show={error.partner}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Agreement </Label>
                        <Select
                          name="agreement"
                          classNamePrefix="select2-selection"
                          options={agreementOptions}
                          value={selected.agreement || ""}
                          isDisabled={masterObject.id}
                          onChange={selected => {
                            handleAgreementChange(selected)
                            setError(prev => ({ ...prev, agreement: false }))
                          }}
                        />
                        <SelectErrorMessage
                          customStyle={{
                            width: "100%",
                            fontSize: "87.5%",
                            color: "var(--bs-form-invalid-color)",
                          }}
                          message="Select Agreement"
                          show={error.agreement}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Renewed Person</Label>
                        <AvField
                          name="person"
                          placeholder="Renewed Person"
                          value={masterObject?.person || ""}
                          validate={{
                            required: { value: true },
                            pattern: {
                              value: "^[a-zA-ZÀ-ÖØ-öø-ÿ ]+$",
                              errorMessage: "Enter valid Person",
                            },
                          }}
                          errorMessage="Enter valid Person"
                          onChange={e => {
                            handleValueChange(e.target.value, e.target.name)
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Renew Date</Label>
                        <AvField
                          name="renewDate"
                          type="date"
                          value={masterObject?.renewDate || ""}
                          min={agreementData.expireDate}
                          validate={{ required: { value: true } }}
                          errorMessage="Renew Date is Required"
                          onChange={e => {
                            handleValueChange(e.target.value, e.target.name)
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Valid Up To</Label>
                        <AvField
                          name="validity"
                          type="date"
                          value={masterObject?.validity || ""}
                          validate={{
                            required: { value: true },
                            min: { value: masterObject.renewDate },
                          }}
                          errorMessage="Valid Up To is Required"
                          onChange={e => {
                            handleValueChange(e.target.value, e.target.name)
                          }}
                        />
                      </div>
                    </Col>

                    {masterObject?.agreement ? (
                      <>
                        <Col md="12">
                          <table className="table-bordered">
                            <tbody>
                              <tr>
                                <td
                                  style={{ width: "150px", textAlign: "left" }}
                                >
                                  Signed Date:
                                </td>
                                <th
                                  style={{ width: "150px", textAlign: "left" }}
                                >
                                  {agreementData.date}
                                </th>
                                <td
                                  style={{ width: "150px", textAlign: "left" }}
                                >
                                  Expiry:
                                </td>
                                <th
                                  style={{ width: "150px", textAlign: "left" }}
                                >
                                  {agreementData.validity}
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>
                  <div className="mt-3">
                    {masterObject?.documents?.map((document, index) => (
                      <div key={index} className="field-set">
                        <Row>
                          <Col md="3">
                            <div className="mb-1">
                              <Label>{`Document ${index + 1}`}</Label>
                              <AvField
                                name={`name${index}`}
                                placeholder="Document Name"
                                value={document.name}
                                onChange={e => {
                                  handleDocumentValueChange(
                                    e.target.value,
                                    index,
                                    "name"
                                  )
                                }}
                              />
                            </div>
                          </Col>
                          <Col md="3">
                            <div className="mb-1">
                              <Label>Upload Document</Label>

                              {document?.file ? (
                                <div
                                  div
                                  className="img-wraps d-flex flex-column"
                                >
                                  <img
                                    alt=""
                                    width="150"
                                    height="150"
                                    src={`${API_URL}${document.file}`}
                                  />
                                  <button
                                    className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                    onClick={() => {
                                      handleDocumentValueChange(
                                        "",
                                        index,
                                        "file"
                                      )
                                    }}
                                    style={{ width: "150px" }}
                                    type="button"
                                  >
                                    Delete
                                  </button>
                                </div>
                              ) : (
                                <AvField
                                  name={`upload${index}`}
                                  placeholder=""
                                  type="file"
                                  accept="image/*, .pdf, .xls, .xlsx"
                                  onChange={e => {
                                    handleFileUpload(e, index)
                                  }}
                                />
                              )}
                            </div>
                          </Col>
                          <Col style={{ marginTop: "2.1rem" }} md="1">
                            {masterObject.documents.length > 1 && (
                              <div>
                                <i
                                  style={{ cursor: "pointer" }}
                                  class="far fa-trash-alt"
                                  onClick={() => handleRemoveField(index)}
                                ></i>
                              </div>
                            )}
                          </Col>
                        </Row>
                      </div>
                    ))}
                    <Col
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                      md="6"
                    >
                      <span
                        style={{
                          marginBottom: ".5rem",
                          color: "blue",
                          cursor: "pointer",
                        }}
                        onClick={handleAddField}
                      >
                        Add More +
                      </span>
                    </Col>
                  </div>
                  <Row>
                    <Col className="" md="3">
                      <Button
                        color={masterObject.id ? "warning" : "primary"}
                        type="submit"
                      >
                        {masterObject.id ? "Update" : "Submit"}
                      </Button>
                      <Button
                        color="danger"
                        type="button"
                        onClick={reset}
                        className="ms-2"
                      >
                        Reset
                      </Button>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <MDBDataTable
                  id="renewAgreementTable"
                  responsive
                  bordered
                  info={true}
                  searching={true}
                  entries={20}
                  disableRetreatAfterSorting={true}
                  data={data}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Renewals
