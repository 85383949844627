import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import "./styles.scss"
import { get, put } from "../../../helpers/api_helper"
import { useLocation } from "react-router-dom"
import toastr from "toastr"
import Swal from "sweetalert2"
import useBranch from "../../../hooks/useBranch"
import Admin from "../../../components/Common/Admin"

const InternalMarks = () => {
  const [rows1, setRows1] = useState([])
  const [rows2, setRows2] = useState([])

  const { state } = useLocation()

  const [branch] = useBranch()

  const [csp, setCsp] = useState(
    branch ? branch : { label: "Select...", value: "" }
  )
  const [batch, setBatch] = useState({ label: "Select...", value: "" })
  const [cspList, setCspList] = useState([])
  const [batchList, setBatchList] = useState([])
  const [disableButton, setDisableButton] = useState(false)
  const [attendanceMarked,setAttendanceMarked] = useState(true)

  const fetchCspList = () => {
    get(`options/branches`).then(res => {
      if (res.success) {
        setCspList(res.branches)
      }
    })
  }

  const fetchBatchList = () => {
    get(`assessment/cspBatch?branch=${csp.value}`).then(res => {
      if (res.success) {
        if (batchList.length !== 0) {
          setBatch({ label: "Select...", value: "" })
        }
        setBatchList(res.data)
      }
    })
  }

  const fetchStudents = () => {
    
    if (batch.value) {
      get(`assessment/BatchStudentsForAttendance?batch=${batch.value}`).then(
        res => {
          if (res.success) {
            const data = res.data[0].courseAssigned
            const presentRows = data.filter(
              item => item.assessmentAttendance === 0
            )
            const absentRows = data.filter(
              item => item.assessmentAttendance === 1
            )
            setRows1(presentRows)
            setRows2(absentRows)
            setAttendanceMarked(res.data[0].attendanceMarked)
            res.data[0].attendanceMarked && toastr.info('Attendance already marked')
          }
        }
      ).catch(err=>(toastr.error(err.response.data.message)))
    }
  
  }

  useEffect(() => {
    if (state && state?.batchId) {
      setBatch({ label: state.batch, value: state.batchId })
      setCsp({ label: state.csp, value: state.cspId })
    }
    fetchCspList()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (csp.value) {
      fetchBatchList()
    }
    // eslint-disable-next-line
  }, [csp])

  useEffect(() => {
    fetchStudents()
    // eslint-disable-next-line
  }, [batch])

  const handleSubmit = id => {
    return Swal.fire({
      title: "Confirm publishing attendance ?",
      text: "This will publish attendance. You won't be able to change this later.",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then(result => {
      if (result.isConfirmed) {
        let idArray = []
        rows2.length > 0 && rows2.map(item => idArray.push(item._id))
        const postBody = {
          ...(idArray.length > 0 && { id: idArray }),
          assessmentAttendance: 1,
          batch: batch.value,
        }
        put(`assessment/updateAttendance`, postBody)
          .then(res => {
            if (res.success) {
              toastr.success("Attendance updated.")
              setDisableButton(true)
              setAttendanceMarked(true)
            }
          })
          .catch((err) => toastr.error(err.response.data.message))
      }
    })
  }

  const handleReset = ()=> {
    setBatch({label:'Select...',value:''})
    setCsp({label:'Select...',value:''})
    setRows1([])
    setRows2([])
    setBatchList([])
  }

  const handleAssign = idx => {
    const data = [...rows1]
    const unAssignedData = [...rows2]
    let item = data.splice(idx, 1)[0]
    item.assessmentAttendance = 1
    unAssignedData.push(item)
    setRows1(data)
    setRows2(unAssignedData)
    setDisableButton(false)
  }
  const handleUnAssign = idx => {
    const data = [...rows2]
    const assignedData = [...rows1]
    let item = data.splice(idx, 1)[0]
    item.assessmentAttendance = 0
    assignedData.push(item)
    setRows1(assignedData)
    setRows2(data)
    setDisableButton(false)
  }

  const columns1 = [
    {
      label: "#",
      field: "id",
      width: 50,
    },
    {
      label: "Student",
      field: "student",
      width: 150,
    },
    {
      label: "Register No.",
      field: "regNo",
      width: 150,
    },
    {
      label: "Attendance",
      field: "attendance",
      width: 150,
    },
  ]

  const columns2 = [
    {
      label: "Attendance",
      field: "attendance",
      width: 150,
    },
    {
      label: "#",
      field: "id",
      width: 50,
    },

    {
      label: "Student",
      field: "student",
      width: 150,
    },
    {
      label: "Register No.",
      field: "regNo",
      width: 150,
    },
  ]

  let data1
    data1 = {
      columns: columns1,
      rows: rows1.map((item, index) => ({
        id: index + 1,
        student: `${item.firstName || ''} ${item.lastName || ''}`,
        regNo: item.unique_id,
        attendance: attendanceMarked ? (
          <i
            className="fas fa-window-close"
          ></i>
        ) : (
          <i
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleAssign(index)
            }}
            className="fas fa-window-close"
          ></i>
        ),
      })),
    }

  const data2 = {
    columns: columns2,
    rows: rows2.map((item, index) => ({
      id: index + 1,
      student: `${item.firstName || ''} ${item.lastName|| ''}`,
      regNo: item.unique_id,
      attendance: attendanceMarked ? (<input type="checkbox" checked={false}/>):(
        <input
          type="checkbox"
          name="headerCheckbox"
          id="headerCheckbox"
          checked={false}
          onClick={() => handleUnAssign(index)}
          style={{ cursor: "pointer" }}
        ></input>
      ),
    })),
  }

  return (
    <div className="page-content">
      <div className="container-fluid" style={{minHeight:'500px'}}>
        <Breadcrumb title="Home" breadcrumbItem="Assessment Attendance" />

        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <Row>
                  <Col md="12">
                    <Col xl={12}>
                      <Row className="nav-row">
                        <Col md="12">
                          <Col xl={12}>
                            <Row>
                            <Admin>
                                <Col md={3}>
                                  <div>
                                    <Label>CSP Name</Label>
                                    <Select
                                      name="student"
                                      placeholder="Select..."
                                      classNamePrefix="select2-selection"
                                      options={cspList}
                                      onChange={item => setCsp(item)}
                                      value={csp}
                                    />
                                  </div>
                                </Col>
                              </Admin>
                              <Col md="3">
                                <div>
                                  <Label>Batch</Label>
                                  <Select
                                    name="batch"
                                    classNamePrefix="select2-selection"
                                    options={batchList}
                                    onChange={item => setBatch(item)}
                                    value={batch}
                                    width={50}
                                    isDisabled={csp.value === ''}
                                  />
                                </div>
                              </Col>

                              <Col>
                                <Button
                                  type="button"
                                  style={{
                                    marginTop: "1.7rem",
                                    marginLeft: "1rem",
                                  }}
                                  color="danger"
                                  onClick={handleReset}
                                >
                                  Reset
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Col>
                      </Row>
                    </Col>
                  </Col>
                </Row>
                
                  <Row>
                    <Col xl="6">
                      <h6>Present</h6>
                      <MDBDataTable
                        id="assessmentAttendanceTableId1"
                        responsive
                        bordered
                        info={false}
                        searching={true}
                        data={data1}
                        paging={false}
                      />
                    </Col>

                    <Col xl="6">
                      <h6>Absent</h6>
                      <MDBDataTable
                        id="assessmentAttendanceTableId2"
                        responsive
                        bordered
                        info={false}
                        searching={true}
                        data={data2}
                        paging={false}
                      />
                    </Col>
                    {(rows1.length > 0 || rows2.length > 0) && !attendanceMarked && (
                    <Col md="12">
                      <div
                        style={{
                          paddingTop: "25px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          color={"warning"}
                          type="submit"
                          style={{ marginRight: "0.5rem" }}
                          onClick={handleSubmit}
                          disabled={disableButton}
                        >
                          Submit
                        </Button>
                      </div>
                    </Col>
                    )}
                  </Row>
                
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default InternalMarks
