import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
// import { AvForm } from "availity-reactstrap-validation"

// import Select from "react-select"
// import { useDispatch, useSelector } from "react-redux"
// import { getPrivileges } from "../../../../store/options/actions"

// eslint-disable-next-line
import toastr from "toastr"
// eslint-disable-next-line
import { get, post } from "../../../../helpers/api_helper"
import { APP_NAME } from "../../../../config"

const Rules = props => {
  document.title = `Rules | ${APP_NAME}`
  const [reload, setReload] = useState(false)
  // const [dataOptions, setDataOptions] = useState([])

  const [privilegeOptions, setPrivilegeOptions] = useState([])

  const [details, setDetails] = useState([])

  // const dispatch = useDispatch()

  // useEffect(() => {
  //   dispatch(getPrivileges())
  // }, [dispatch])

  useEffect(() => {
    getPrivileges()
    getOptions()
    // eslint-disable-next-line no-use-before-define
  }, [reload])

  const getPrivileges = () => {
    get("privilage/app-dashboard").then(res => {
      setPrivilegeOptions(res.data)
    })
  }

  const getOptions = () => {
    get("menu/app-dashboard").then(res => {
      setDetails(res.data)
    })
  }

  const handleClickAllPrivilege = (e, privilege) => {
    const status = e.target.checked ? 1 : 0

    post(`rules/all-privilege/${privilege}`, { status })
      .then(res => {
        setReload(!reload)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleClickAllMenu = (e, menu) => {
    const status = e.target.checked ? 1 : 0

    post(`rules/all-menu/${menu}`, { status })
      .then(res => {
        setReload(!reload)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleClickSingleMenu = (e, menu, privilege) => {
    const status = e.target.checked ? 1 : 0
    post(`rules/single-menu/${privilege}/${menu}`, { status })
      .then(res => {
        setReload(!reload)
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Rules" />

          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <table className="w-100 newappdashboard-table table table-bordered dataTable">
                      <thead>
                        <tr
                          style={{
                            textAlign: "center",
                            verticalAlign: "bottom",
                          }}
                        >
                          <th></th>
                          {privilegeOptions.map((privilege, index) => (
                            <th
                              key={"header" + index}
                              className="text-capitalize"
                            >
                              {privilege.privilege_name.toLowerCase()}
                              <div>
                                <input
                                  type="checkbox"
                                  checked={privilege?.exists}
                                  onClick={e => {
                                    handleClickAllPrivilege(e, privilege._id)
                                  }}
                                />
                              </div>
                            </th>
                          ))}{" "}
                        </tr>
                      </thead>

                      <tbody>
                        {details.map((item, index1) => {
                          return (
                            <tr key={index1}>
                              <td>
                                <div style={{ display: "flex" }}>
                                  <input
                                    type="checkbox"
                                    onClick={e => {
                                      handleClickAllMenu(e, item._id)
                                    }}
                                    checked={item?.exists}
                                    className="mx-1"
                                  />
                                  {item?.name}
                                </div>
                              </td>
                              {item.result.map((subItem, index2) => {
                                return (
                                  <td key={index2} className="text-center">
                                    <input
                                      type="checkbox"
                                      onClick={e => {
                                        handleClickSingleMenu(
                                          e,
                                          item._id,
                                          subItem._id
                                        )
                                      }}
                                      checked={subItem?.exists}
                                    />
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Rules
