import React, { useEffect, useRef, useState } from "react"
import { Row, Col, Card, CardBody, Label } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { get } from "../../../helpers/api_helper"
import toastr from "toastr"
import Swal from "sweetalert2"
import {
  getBranches,
  getClassRoomsOrLabs,
} from "../../../store/options/actions"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import useBranch from "../../../hooks/useBranch"
import { isArray } from "lodash"

import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import BootstrapTheme from "@fullcalendar/bootstrap"
import moment from "moment"
import "./Classroom.scss"

function ClassAvailability() {
  const [branch] = useBranch()

  const [events, setEvents] = useState([])

  const dispatch = useDispatch()

  const [master, setMaster] = useState({ month: moment().format("YYYY-MM") })

  const [selected, setSelected] = useState({})

  const calendarRef = useRef(null)

  const OPTIONS = useSelector(state => state.Options)

  useEffect(() => {
    dispatch(getClassRoomsOrLabs({ branch: branch?.value }))
  }, [dispatch, branch])

  useEffect(() => {
    dispatch(getBranches({}))
  }, [dispatch])

  useEffect(() => {
    if (master.month) {
      updateCalendarDate(master.month)
    }
  }, [master])

  useEffect(() => {
    if (master.month && master.classroom) {
      getBookedClasses(master)
    }
  }, [master])

  const getBookedClasses = data => {
    get(
      `ClassroomsOrLabs/availability?classroom=${data.classroom}&month=${data.month}`
    ).then(res => {
      const data = res.data || []

      data.map((item, idx) => {
        item.id = idx + 1

        item.title = `${moment(item.timeStart, "HH:mm").format(
          "hh:mma"
        )}-${moment(item.timeEnd, "HH:mm").format("hh:mma")}
         ${item.name}`

        item.className = "bg-success text-white"

        return item
      })

      setEvents(data)
    })
  }

  const updateCalendarDate = month => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi()
      calendarApi.gotoDate(`${month}-01`)
    }
  }

  const handleSelect = (e, name) => {
    const value = e ? (isArray(e) ? e.map(item => item.value) : e.value) : null
    setSelected(pre => ({ ...pre, [name]: e }))
    setMaster(pre => ({ ...pre, [name]: value }))
  }

  const handleChange = e => {
    let { name, value } = e.target
    setMaster(pre => ({ ...pre, [name]: value }))
  }

  const toTop = () => {
    window.scroll({
      top: "0",
      left: "0",
      behavior: "smooth",
    })
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumb title="Home" breadcrumbItem="Create Class" />
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <AvForm
                  className="needs-validation"
                  // onValidSubmit={handleSubmit}
                  // ref={formRef}
                >
                  <Row>
                    <Col md="3">
                      <div className="">
                        <Label>Class</Label>
                        <Select
                          name="csp"
                          value={selected.classroom}
                          classNamePrefix="select2-selection"
                          options={OPTIONS.classRoomsLabs}
                          menuPosition="absolute"
                          onChange={selected => {
                            handleSelect(selected, "classroom")
                          }}
                        />
                      </div>
                    </Col>

                    <Col md="3">
                      <div className="">
                        <Label>Month</Label>
                        <AvField
                          name={`month`}
                          onChange={handleChange}
                          value={master.month}
                          type="month"
                          className="form-control numberTime"
                        />
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Card>
            <CardBody className="classroom">
              <FullCalendar
                ref={calendarRef}
                plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
                slotDuration={"00:20:00"}
                handleWindowResize={true}
                themeSystem="bootstrap"
                displayEventTime={false}
                eventClick={(data)=>{
                  console.log(data.event)
                }}
                // eventDisplay={false}
                headerToolbar={{
                  left: "",
                  center: "title",
                  right: "",
                }}
                events={events}
              />
            </CardBody>
          </Card>
        </Row>
      </div>
    </div>
  )
}

export default ClassAvailability
