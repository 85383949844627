import React, { useState } from "react"
import { Container, Row, Col, Modal } from "reactstrap"
import { APP_NAME } from "../../config"
import { useNavigate } from "react-router-dom"
const Footer = () => {
  const [popupView, setPopupView] = useState(false)
  const navigate = useNavigate()
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col sm={6}>
              ©{new Date().getFullYear()} {APP_NAME}
              {/* India Private Limited.  */}. All Rights Reserved
            </Col>
            <Col sm={6}>
              <div className="text-sm-end d-none d-sm-block">
                <i
                  className="fas fa-info-circle"
                  onClick={() => {
                    navigate(`/about-company`)
                  }}
                  style={{
                    fontSize: "1.2em",
                    color: "gray",
                  }}
                ></i>
                {/* <a className="text-dark" href="https://srvinfotech.com/">
                  Powered by SRV InfoTech
                </a> */}
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="md"
        // centered={true}
        style={{ marginTop: "8rem" }}
      >
        <div className="modal-header">
          {/* <div className="modal-title">
            
          </div> */}

          <button
            type="button"
            onClick={() => setPopupView(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body" style={{ paddingTop: "0px" }}>
          <Row>
            <Col md="12">
              <Row>
                <Col md="6">
                  <div style={{ paddingLeft: "3.5rem" }}>

                    
                    <h4
                      style={{ fontSize: "1rem", paddingTop: "1rem" }}
                      className="mb-0"
                    >
                      Version
                    </h4>
                    <p style={{ fontWeight: "400" }}>21.21.0</p>
                  </div>
                </Col>
                <Col md="6">
                  <div style={{ paddingLeft: "3.5rem" }}>
                    <h4
                      style={{ fontSize: "1rem", paddingTop: "1rem" }}
                      className="mb-0"
                    >
                      Updated on
                    </h4>
                    <p>Apr 11, 2024</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <div style={{ paddingLeft: "3.5rem" }}>
                    <h4
                      style={{ fontSize: "1rem", paddingTop: "1rem" }}
                      className="mb-0"
                    >
                      Released on
                    </h4>
                    <p style={{ fontWeight: "400" }}>Mar 4, 2024</p>
                  </div>
                </Col>
                <Col md="6">
                  <div style={{ paddingLeft: "3.5rem" }}>
                    <h4
                      style={{ fontSize: "1rem", paddingTop: "1rem" }}
                      className="mb-0"
                    >
                      Offered by
                    </h4>
                    <p>SRV InfoTech</p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default Footer
