import React, { useEffect, useState } from "react"
import { Row, Col, Button, Label } from "reactstrap"
import Tab from "@mui/material/Tab"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select"

import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import { Box, Divider } from "@mui/material"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import StoreIcon from "@mui/icons-material/Store"
import PersonIcon from "@mui/icons-material/Person"

import "./style.scss"
import { Card, CardBody } from "reactstrap"

import { useLocation, useParams } from "react-router-dom"

import Attendance from "./Tabs/Attendance"
import { BatchProvider } from "./BatchContext"
import Students from "./Tabs/Students"
import { get } from "../../../../helpers/api_helper"
import QualityFrameWork from "./Tabs/QualityFrameWork"
import moment from "moment"
import { API_URL, PRIVILEGES } from "../../../../config"
import { minutesToHour, sideBarToggle } from "../../../../helpers/functions"
import Assessment from "./Tabs/Assessment"
import TrainerAllocation from "./Tabs/TrainerAllocation"
import Allowed, { isAllowed } from "../../../../components/Common/Allowed"

const BatchCreation = props => {
  const tabAllowed = isAllowed([
    PRIVILEGES.ADMIN,
    PRIVILEGES.BUSINESS_HEAD,
    PRIVILEGES.CSP_PROGRAM_MANAGER,
  ])

  const [master, setMaster] = useState({
    startDate: moment().format("YYYY-MM-DD"),
  })
  const [batchDetails, setBatchDetails] = useState({})
  const { batchid } = useParams()
  const location = useLocation()

  useEffect(() => {
    sideBarToggle()
    return () => sideBarToggle(false)
  }, [])

  const [topic, setTopic] = useState([{}])

  useEffect(() => {
    getBatchDetails(location?.state?.id || batchid)
  }, [batchid, location.state])

  const [tab3, setTab3] = React.useState(tabAllowed ? "1" : "2")

  const handleChangeTab = (event, newValue) => {
    setTab3(newValue)
  }

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  function next() {
    setTab3(Number(tab3) + 1 + "")
    toTop()
  }

  function back() {
    setTab3(Number(tab3) - 1 + "")
    toTop()
  }

  const getBatchDetails = id => {
    get(`batch/single/${id}`)
      .then(res => {
        setBatchDetails(res.data)

        setMaster({
          id: res?.data?._id,
          course: res?.data?.course?._id,
          startDate: res?.data?.startDate,
          endDate: res?.data?.endDate,
        })
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem={batchDetails?.batchId} />

          <Row>
            <Col xl="12">
              {/* First Dialogue */}

              <div>
                <div className="row">
                  <div className="col-lg-3">
                    <div
                      className="card"
                      style={{
                        // width: "fit-content",
                        // width: "351px",
                        height: "max-content",
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                      }}
                    >
                      <div className="card-body">
                        <div className="float-end dropdown"></div>
                        <h5
                          className="mt-2 mb-2"
                          style={{
                            textAlign: "center",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}
                        >
                          {batchDetails?.name}
                        </h5>
                        {/* <p style={{ marginBottom: "0px", textAlign: "center" }}>
                          <img
                            style={{ width: "95px" }}
                            src={asaplogo}
                            alt=""
                          />
                        </p> */}
                        <div
                          className="text-muted"
                          style={{
                            textAlign: "center",
                            color: "green !important",
                            fontFamily: "IBM Plex Sans,sans-serif",
                            fontSize: "14px",
                            marginBottom: "3px",
                          }}
                        >
                          <div
                            class="btn-group btn-group-sm"
                            role="group"
                            aria-label="Basic example"
                          ></div>
                        </div>
                        <p
                          style={{
                            textAlign: "center",
                            marginBottom: "3px",
                          }}
                        ></p>

                        <Divider />

                        <div className="mt-3 d-flex justify-content-between">
                          <h6>Course Id </h6>

                          <p className="font-size-14">
                            {batchDetails?.course?.code}
                          </p>
                        </div>
                        <Divider />
                        <div className="mt-3 d-flex justify-content-between">
                          <h6>Course</h6>

                          <p className="font-size-14">
                            {batchDetails?.course?.name}
                          </p>
                        </div>
                        <Divider />
                        <div className="mt-3 d-flex justify-content-between">
                          <h6>Start Date</h6>
                          <p className="font-size-13">
                            {batchDetails?.startDate &&
                              moment(batchDetails?.startDate).format(
                                "DD-MM-YYYY"
                              )}
                          </p>
                        </div>
                        <Divider />
                        <div className="mt-3 d-flex justify-content-between">
                          <h6>End Date</h6>
                          <p className="font-size-13">
                            {" "}
                            {batchDetails?.endDate &&
                              moment(batchDetails?.endDate).format(
                                "DD-MM-YYYY"
                              )}
                          </p>
                        </div>
                        <Divider />
                        <div className="mt-3 d-flex justify-content-between">
                          <h6>Capacity</h6>
                          <p className="font-size-13">
                            {batchDetails?.maxStrength}
                          </p>
                        </div>
                        <Divider />
                        <div className="mt-2">
                          <h6 className="mb-1">Training Partner</h6>
                          <p>
                            <img
                              style={{ width: "95px" }}
                              src={
                                API_URL + batchDetails?.trainingPartner?.logo
                              }
                              onError={e => {
                                e.target.parentNode.style.display = "none"
                              }}
                              alt=""
                            />
                          </p>
                          <p className="font-size-14">
                            {batchDetails?.trainingPartner?.name}
                          </p>
                        </div>
                        <Divider />
                        <div className="mt-2">
                          <h6 className="mb-1">Certificate Partner</h6>
                          <p>
                            <img
                              style={{ width: "95px" }}
                              src={
                                API_URL +
                                batchDetails?.certificationPartner?.logo
                              }
                              onError={e => {
                                e.target.parentNode.style.display = "none"
                              }}
                              alt=""
                            />
                          </p>
                          <p className="font-size-14">
                            {" "}
                            {batchDetails?.certificationPartner?.name}
                          </p>
                        </div>
                        <Divider />
                        <div className="mt-3 d-flex justify-content-between">
                          <h6>Duration</h6>
                          <p className="font-size-13">
                            {batchDetails?.totalSessionTime &&
                              minutesToHour(
                                batchDetails?.totalSessionTime
                              )}{" "}
                            hours
                          </p>
                        </div>
                        <Divider />
                        <div className="mt-3 d-flex justify-content-between">
                          <h6>Branch</h6>
                          <p className="font-size-13">{batchDetails?.branchName}</p>
                        </div>
                        <Divider />
                        <div className="mt-3 d-flex justify-content-between">
                          <h6>Added By</h6>
                          <p className="font-size-13">
                            {batchDetails?.addedBy}
                          </p>
                        </div>
                        <Divider />
                        <div className="mt-3 d-flex justify-content-between">
                          <h6>Created Date</h6>
                          <p className="font-size-13">
                            {batchDetails?.date &&
                              moment(batchDetails?.date).format("DD-MM-YYYY")}
                          </p>
                        </div>
                        <Divider />
                        <div className="mt-3 d-flex justify-content-between">
                          <h6>Created Time</h6>
                          <p className="font-size-13">
                            {batchDetails?.time &&
                              moment(batchDetails?.time, "HH:mm:ss").format(
                                "hh:mm A"
                              )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-9" style={{ marginBottom: "30px" }}>
                    <div
                      className="mb-0 card"
                      style={{
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                        width: "100%",
                      }}
                    >
                      <BatchProvider
                        value={{
                          master,
                          setMaster,
                          next,
                          back,
                          details: true,
                          branch: batchDetails?.branch,
                        }}
                      >
                        <Box sx={{ width: "100%", typography: "body1" }}>
                          <TabContext value={tab3}>
                            <Box
                              sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                              <TabList
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                onChange={handleChangeTab}
                                aria-label="lab API tabs example"
                              >
                                <Tab
                                  icon={<PersonIcon style={{ fontSize: "" }} />}
                                  label="students Allocation"
                                  value="1"
                                  style={{
                                    textTransform: "capitalize",
                                    fontFamily: "IBM Plex Sans,sans-serif",
                                  }}
                                  hidden={!tabAllowed}
                                />
                                <Tab
                                  icon={
                                    <StoreIcon style={{ fontSize: "20px" }} />
                                  }
                                  label="QF Assign"
                                  value="2"
                                  style={{
                                    textTransform: "capitalize",
                                    fontFamily: "IBM Plex Sans,sans-serif",
                                  }}
                                />
                                <Tab
                                  icon={
                                    <StoreIcon style={{ fontSize: "20px" }} />
                                  }
                                  label="Trainer Assign"
                                  value="5"
                                  style={{
                                    textTransform: "capitalize",
                                    fontFamily: "IBM Plex Sans,sans-serif",
                                  }}
                                />
                                <Tab
                                  icon={
                                    <StoreIcon style={{ fontSize: "20px" }} />
                                  }
                                  label="Assessments"
                                  value="3"
                                  style={{
                                    textTransform: "capitalize",
                                    fontFamily: "IBM Plex Sans,sans-serif",
                                  }}
                                  hidden={!tabAllowed}
                                />
                                <Tab
                                  icon={
                                    <PersonIcon style={{ fontSize: "20px" }} />
                                  }
                                  label="Attendance"
                                  value="4"
                                  style={{
                                    textTransform: "capitalize",
                                    fontFamily: "IBM Plex Sans,sans-serif",
                                  }}
                                />
                              </TabList>
                            </Box>

                            <TabPanel
                              value="1"
                              style={{
                                fontSize: "13px",
                                fontFamily: "IBM Plex Sans,sans-serif",
                              }}
                            >
                              <Students />
                            </TabPanel>
                            <TabPanel
                              value="2"
                              style={{
                                fontSize: "13px",
                                fontFamily: "IBM Plex Sans,sans-serif",
                              }}
                            >
                              <QualityFrameWork />
                            </TabPanel>
                            <TabPanel
                              value="3"
                              style={{
                                fontSize: "13px",
                                fontFamily: "IBM Plex Sans,sans-serif",
                              }}
                            >
                              <Assessment />
                            </TabPanel>
                            <TabPanel
                              value="5"
                              style={{
                                fontSize: "13px",
                                fontFamily: "IBM Plex Sans,sans-serif",
                              }}
                            >
                              <TrainerAllocation
                                trainers={batchDetails?.trainers}
                                allowed={tabAllowed}
                              />
                            </TabPanel>
                            <TabPanel
                              value="4"
                              style={{
                                fontSize: "13px",
                                fontFamily: "IBM Plex Sans,sans-serif",
                              }}
                            >
                              <Attendance id={batchid} />
                            </TabPanel>
                          </TabContext>
                        </Box>
                      </BatchProvider>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default BatchCreation
