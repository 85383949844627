import React, { useEffect, useRef, useState } from "react"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { MDBDataTable } from "mdbreact"
import Swal from "sweetalert2"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { del, get, post, put } from "../../../helpers/api_helper"
import toastr from "toastr"
import "./style.css"
import moment from "moment"

const AssetCategoty = () => {
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  const [master, setMaster] = useState({})
  const [tableData, setTableData] = useState([])
  const [AssetCategoryIdToBeUpdated, setAssetCategoryIdToBeUpdated] =useState(null)
  const [state,setState] = useState(false)

  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [state])

  const ref = useRef()

  const data = {
    columns: [
      {
        label: "Index",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
        className: "text-center", // Add this class to center the text
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 150,
        className: "text-center", // Add this class to center the text
      },
      {
        label: "Category Name",
        field: "categoryName",
        sort: "asc",
        width: 150,
        className: "text-center", // Add this class to center the text
      },
      {
        label: "Added By",
        field: "added",
        sort: "asc",
        width: 150,
        className: "text-center", // Add this class to center the text
      },

      {
        label: "Action",
        field: "options",
        sort: "asc",
        width: 200,
        className: "text-center", // Add this class to center the text
      },
    ],
    rows: tableData,
  }
  function handleValidSubmit(e, v) {
    if (AssetCategoryIdToBeUpdated) {
      put(`/asset/category`, master)
        .then(res => {
          toastr.success(res.message)
          ref.current.reset()
          handleTableData()
          setMaster({})
          setAssetCategoryIdToBeUpdated(null)
        })
        .catch(err => {
          if(toastr.error(err?.response?.data?.message)){
            toastr.error(err?.response?.data?.message)
          }

          ref.current.reset()
          handleTableData()
          setMaster({})
          setAssetCategoryIdToBeUpdated(null)
        })
    } else {
      post(`/asset/category`, master)
        .then(res => {
          if (res.success === true) {
            toastr.success(res.message)
            ref.current.reset()
            setMaster({})
            setAssetCategoryIdToBeUpdated(null)
          } else {
            toastr.error("Faild to add Asset category")
            ref.current.reset()
            handleTableData()
            setMaster({})
            setAssetCategoryIdToBeUpdated(null)
          }
          handleTableData()
        })
        .catch(err => {
          if(toastr.error(err?.response?.data?.message)){
            toastr.error(err?.response?.data?.message)
          }
          ref?.current?.reset()
          handleTableData()
          setMaster({})
          setAssetCategoryIdToBeUpdated(null)
        })
    }
  }

  const reset = ()=>{
    ref.current.reset()
    setState(!state)
    setMaster({})
    setAssetCategoryIdToBeUpdated(null)
  }

  const handleTableData = async () => {
    get("/asset/category").then(res => {
      const result = res.data
      result.map((item, index) => {
        item.id = index + 1
        item.added = item?.addedByDetails?.name
        item.date = moment(item.date).format('DD-MM-YYYY')
        item.time = moment(item.time,"HH:mm:ss").format("hh:mm A")
        item.options = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="far fa-edit"
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
              onClick={() => {
                toTop()
                handleUpdate(item)
                setAssetCategoryIdToBeUpdated(item?._id)
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ cursor: "pointer", fontSize: "1em" }}
              onClick={() => {
                handleDelete(item._id,item?.categoryName)
              }}
            ></i>
          </div>
        )
        return item
      })
      setTableData(result)
    })
    function handleUpdate(item) {
      setMaster({ id: item?._id, categoryName: item?.categoryName })
    }
  }


  //onchangeG
  function handleAssetCategory(e) {
    let { name, value } = e.target
    setMaster({ ...master, [name]: value })
  }

  //DELETE
  function handleDelete(id,categoryName) {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`/asset/category?id=${id}&categoryName=${categoryName}`)
          .then(res => {
            toastr.success(res.message)
            handleTableData()
          })
          .catch(err => {
            if(toastr.error(err?.response?.data?.message)){
              toastr.error(err?.response?.data?.message)
            }        
          })
      }
    })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb title="Asset" breadcrumbItem="Asset Category" />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={ref}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v)
                    }}
                  >

                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Category Name
                          </Label>

                          <AvField
                            name="categoryName"
                            placeholder="Category Name"
                            type="text"
                            errorMessage="Enter Category Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            value={master?.categoryName || ""}
                            onChange={handleAssetCategory}
                            id="validationCustom01"
                          />
                        </div>
                      </Col>
                      <Col md="3" style={{ paddingTop: "4px" }}>
                        <div className="mt-4">
                          <Button
                            className="me-2"
                            color={
                              AssetCategoryIdToBeUpdated
                                ? "warning"
                                : "primary"
                            }
                            type="submit"
                          >
                            {AssetCategoryIdToBeUpdated ? "Update" : "Submit"}
                          </Button>
                          <Button
                            color="danger"
                            type="reset"
                            onClick={reset}
                          >
                            Reset
                          </Button>

                          {/* <Button color="danger" className="me-2" type="reset">
                            Reset
                          </Button> */}
                        </div>
                      </Col>
                      <Col md="3">
                          
                        </Col>
                    </Row>
                    
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>


          
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="AssetCategotyid"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default AssetCategoty
