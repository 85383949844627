import React, { useEffect, useRef, useState } from "react"
import { Row, Col, BreadcrumbItem } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import SummarizeIcon from "@mui/icons-material/Summarize"
import { Box, Divider } from "@mui/material"
import { useLocation } from "react-router-dom"
import { get, post } from "../../../helpers/api_helper"
import moment from "moment"
import { Link } from "react-router-dom"
// import Select from "react-select"
import { useParams } from "react-router-dom";


const AsssignTestDashboard = () => {
  // const { jobId } = props.match.params;
  const { state } = useLocation()
  const { id } = useParams(); 
  const [tab3, setTab3] = React.useState("2")
  const [tab4, setTab4] = React.useState("2")

  const [details, setDetails] = useState({})
  const [tabledata, setTabledata] = useState([])
  const [offerCodeLogData,setOfferCodeLogData] = useState([])
  const formRef = useRef()
  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 20

  const handleChangeTab = (event, newValue) => {
    setTab3(newValue)
  }

  
  const handleChangeTab2 = (event, newValue) => {
    setTab4(newValue)
  }

  useEffect(()=>{
    getLog()
  },[page])

  const getLog = async()=>{
    try {
      const filterQuery = `page=${page}&limit=${limit}&offerCode=${state?.id}`
      const response = await get(`/offerCode/OfferCodeLogList?${filterQuery}`)
      setOfferCodeLogData(response.data)
     const count = response?.count
     const totalPage = Math.ceil(Number(count) / limit)
     setTotalPage(totalPage)
    } catch (error) {
    }
  }

  useEffect(()=>{
    handleDetails()
  },[])

  const handleDetails = async()=>{
    try {
      let  res = await get(`/assign-test/list/${state.id}`)
       setDetails(res?.data[0])
    } catch (error) {
      
    }
  }


  // useEffect(() => {
  //   get(`/offerCode/availed?id=${state?.id}`)
  //     .then(res => {
  //       let result = res?.data
  //       let data = []
  //       result.map(item => {
  //         item.forname =
  //           item?.for === 0
  //             ? item?.courses?.map(res1 => res1.name).join(", ")
  //             : item?.for === 1
  //             ? item?.students
  //                 ?.map(res2 => res2.firstname + res2.lastname)
  //                 .join(", ")
  //             : item?.for === 2
  //             ? item?.batches?.map(res3 => res3.name).join(", ")
  //             : item?.for === 3
  //             ? item?.event?.map(res4 => res4.name).join(", ")
  //             : ""
              
  //             item.status = (
  //               <h5
  //               className="font-size-14"
  //               style={{
  //                 fontWeight: "500",
  //                 color: item?.status === 0 ? "green" : "red",
  //               }}
  //             >
  //               {item?.status === 0 ? "Active" : "Inactive"}
  //             </h5>
  //             )
  //         item.forType =
  //           item.for === 0
  //             ? "Course"
  //             : item.for === 1
  //             ? "Students"
  //             : item.for === 2
  //             ? "Batch"
  //             : "Event"
  //         item.To = moment(item.validTo).format("DD-MM-YYYY")
  //         item.From = moment(item.validFrom).format("DD-MM-YYYY")
  //         item.percoramnt = item?.type === 0 ? "Percentage" : "Amount"
  //         item?.availedData?.map((el, index) => {
  //           item.id = index + 1
  //           // item.name = el.student.firstname + " " + el.student.lastname
  //           item.date = moment(el?.availedDate).format("DD-MM-YYYY")
  //           item.courses = el?.course?.name
            
            
  //           data.push(item)
  //         })
  //         setTabledata(data)
  //         setDetails(item)
  //       })
  //     })
  //     .catch(err => {
  //       console.log(err)
  //     })

  //   // eslint-disable-next-line
  // }, [])
 
  const logData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 400,
      },
      {
        label: "Student",
        field: "student",
        sort: "asc",
        width: 200,
      },
      {
        label: "Course/Event",
        field: "type",
        sort: "asc",
        width: 200,
      },
      {
        label: "Name",
        field: "course",
        sort: "asc",
        width: 200,
      },
      {
        label: "Fee",
        field: "fee",
        sort: "asc",
        width: 200,
      },
      {
        label: "Availed Amount",
        field: "availed",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 200,
      },
    ],
    rows:offerCodeLogData.map((item,index)=>{
      return  {
        id: index + 1 + (page - 1) * limit,
        date:  moment(item.date).format('DD-MM-YYYY'),
        time: moment(item.time,'HH:mm:ss').format('hh:mm A'),
        offercode: item?.offerCodeDetails?.name,
        fee: `₹${item?.fee}`,
        availed: `₹${item?.availedAmount}`,
        status: item.status === 0 ? "Applied" : "Removed",
        course : item?.courseDetails?.name,
        student:item?.student,
        type:item?.type
      }
    })
      
  }
  
  const detailsData = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      // {
      //     label: "ID",
      //     field: "studentid",
      //     width: 150,
      // },
      {
        label: "Student",
        field: "name",
        width: 150,
      },
      {
        label: "Course",
        field: "courses",
        width: 150,
      },
      {
        label: "Amnt/Perc",
        field: "percoramnt",
        width: 150,
      },
      {
        label: "Value",
        field: "amountOrPercentage",
        width: 150,
      },
      // {
      //   label: "Status",
      //   field: "status",
      //   width: 150,
      // },
    ],
    rows: tabledata,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">{details?.Offer_Code}</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      <Link to="/dashboad">Home</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="/offer-codes">Offer Code</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="#">{details?.name}</Link>
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              {/* First Dialogue */}
              <div>
                <div className="row">
                  <div className="col-xl-3">
                    <div
                      className="card"
                      style={{
                        // width: "fit-content",
                        // width: "351px",
                        height: "max-content",
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                      }}
                    >
                      <div className="card-body">
                        <div className="float-end dropdown"></div>
                        <h5
                          className="mt-2 mb-2"
                          style={{
                            textAlign: "center",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}
                        >
                        {details?.test}
                        </h5>

                        <Divider />
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div
                          className="mt-2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="mb-1">Assign For:</p>
                          {details?.assignFor == 0 &&
                           <h5 className="font-size-14">Temparory Batch</h5>
                          }
                          {details?.assignFor == 1 &&
                           <h5 className="font-size-14">Student</h5>
                          }
                          {details?.assignFor == 2 &&
                           <h5 className="font-size-14">Course </h5>
                          }
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div
                          className="mt-2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="mb-1">Test Name:</p>
                          <h5 className="font-size-14">{details?.test}</h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                      
                        {/* <hr style={{ margin: "0", color: "#00afc3" }} /> */}
                        <div
                          className="mt-2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="mb-1">Start date:</p>
                          <h5 className="font-size-14">
                          { moment(details?.startDate, "YYYY-MM-DD").format("DD-MM-YYYY")}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div
                          className="mt-2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="mb-1">Start time:</p>
                          <h5 className="font-size-14">
                          {moment(details?.startTime, "HH:mm").format("hh:mm a")}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div
                          className="mt-2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="mb-1">End Date:</p>
                          <h5
                            className="font-size-14"
                            style={{ fontWeight: "500" }}
                          >
                          { moment(details?.endDate, "YYYY-MM-DD").format("DD-MM-YYYY")}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        <div
                          className="mt-2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="mb-1">End time:</p>

                          <h5
                            className="font-size-14"
                            style={{ fontWeight: "500" }}
                          >
                          {moment(details?.endTime, "HH:mm").format("hh:mm a")}
                          </h5>                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />


                    
                         
                        <div
                          className="mt-2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="mb-1">Staff:</p>
                          {details?.updatedBy?
                           <h5
                           className="font-size-14"
                           style={{ fontWeight: "500" }}
                         >
                          {(details?.updatedBy?.firstName || "") + " " + (details?.updatedBy?.lastName || "")}
                         </h5>
                         :
                         <h5
                         className="font-size-14"
                         style={{ fontWeight: "500" }}
                       >
                        {(details?.addedBy?.firstName || "") + " " + (details?.addedBy?.lastName || "")}
                       </h5>
                        }
                         
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} />
                        {/* <div
                          className="mt-2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="mb-1">Total Availed:</p>
                          <h5
                            className="font-size-14"
                            style={{ fontWeight: "500" }}
                          >
                            {details?.totalAvailed}
                          </h5>
                        </div>
                        <hr style={{ margin: "0", color: "#00afc3" }} /> */}
                      
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-9" style={{ marginBottom: "30px" }}>
                    <div
                      className="mb-0 card"
                      style={{
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={tab3}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              indicatorColor="primary"
                              textColor="primary"
                              variant="fullWidth"
                              onChange={handleChangeTab}
                              aria-label="lab API tabs example"
                            >
                             
                               <Tab
                                icon={
                                  <SummarizeIcon style={{ fontSize: "20px" }} />
                                }
                                label="Test log"
                                value="2"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                            </TabList>
                          </Box>

                         

                          <TabPanel
                            value="2"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col className="col-12">
                                <MDBDataTable
                                  id="movementTable"
                                  responsive
                                  bordered
                                  data={logData}
                                  searching={true}
                                  info={true}
                                  disableRetreatAfterSorting={true}
                                />
                              </Col>
                            </Row>
                          </TabPanel>
                        </TabContext>

                       
                      </Box>


                      
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AsssignTestDashboard
