import React, { useEffect, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import { get } from "../../../helpers/api_helper"
// import toastr from "toastr"
import moment from "moment"
import "./style.scss"
import { dateConverter, jsonToExcel } from "../../../helpers/functions"
import TablePagination from "../../../components/Common/Pagination"
import { useTransition } from "react"
import toastr from "toastr"

const EventReport = () => {
  const [tableData, setTableData] = useState([])

  const [count, setCount] = useState(0)
  const [isExportPending, startExport] = useTransition()

  const [selected, setSelected] = useState({
    type: "",
    branch: "",
    category: "",
    status: "",
  })

  const [filterObject, setFilterObject] = useState({
    from: "",
    to: "",
    type: "",
    branch: "",
    category: "",
    status: "",
  })
  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 20

  const [categoryOptions, setCategoryOptions] = useState([])
  const [branchOptions, setBranchOptions] = useState([])
  const [communityOptions, setCommunityOptions] = useState([])

  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [page, filterObject])

  useEffect(() => {
    handleCategoryOptions()
    handleCommunityOptions()
    handleBranchOptions()
    // eslint-disable-next-line
  }, [filterObject])

  const handleTableData = async () => {
    try {
      const response = await get(
        `report/event?page=${page}&limit=${limit}&from=${filterObject.from}&to=${filterObject.to}&type=${filterObject.type}&status=${filterObject.status}&branch=${filterObject.branch}&category=${filterObject.category}`
      )
      const data = response.data
      const count = response.count
      if (data && Array.isArray(data)) {
        data.map((item, index) => {
          item.id = index + 1 + (page - 1)
          item.csp = item.csp?.join(", ")
          item.type = item.type === 1 ? "Online" : "Offline"
          item.registrationCloseDate = dateConverter(item.registrationCloseDate)
          item.startDate = dateConverter(item.startDate)
          item.endDate = dateConverter(item.endDate)
          return item
        })
        const totalPage = Math.ceil(Number(count) / limit)
        setTotalPage(totalPage)
        setCount(count)
        setTableData(data)
      }
    } catch (error) {}
  }

  const handleCategoryOptions = async () => {
    try {
      const response = await get(
        `report/options/event/category?from=${filterObject.from}&to=${filterObject.to}&type=${filterObject.type}&status=${filterObject.status}&branch=${filterObject.branch}&category=${filterObject.category}`
      )

      setCategoryOptions(response.data)
    } catch (error) {}
  }
  const handleCommunityOptions = async () => {
    try {
      const response = await get(
        `report/options/event/community?from=${filterObject.from}&to=${filterObject.to}&type=${filterObject.type}&status=${filterObject.status}&branch=${filterObject.branch}&category=${filterObject.category}`
      )
      setCommunityOptions(response.data)
    } catch (error) {}
  }
  const handleBranchOptions = async () => {
    try {
      const response = await get(
        `report/options/event/branch?from=${filterObject.from}&to=${filterObject.to}&type=${filterObject.type}&status=${filterObject.status}&branch=${filterObject.branch}&category=${filterObject.category}`
      )
      setBranchOptions(response.data)
    } catch (error) {}
  }

  const handleExportData = async count => {
    try {
      const response = await get(
        `report/event?page=${page}&limit=${count}&from=${filterObject.from}&to=${filterObject.to}&type=${filterObject.type}&status=${filterObject.status}&branch=${filterObject.branch}&category=${filterObject.category}`
      )
      const data = response.data

      if (data && Array.isArray(data) && data.length > 0) {
        const exportData = []
        let index = 0
        for (let item of data) {
          index++
          const exportObj = {}
          exportObj["SI NO"] = index
          exportObj["Event ID"] = item.uniqueId
          exportObj.Category = item.category
          exportObj.Csp = item.csp?.join(", ")
          exportObj.Venue = item.venue
          exportObj["Event Name"] = item.name
          exportObj.Fee = item.fee
          exportObj["GST slab"] = item.gstLabs
          exportObj.Community = item.community
          exportObj["Resource Person Name"] = item.resourcePerson
          exportObj["Resource Person Email"] = item.resourcePersonEmail
          exportObj["Resource Person Mobile"] = item.resourcePersonMobile
          exportObj.Type = item.type === 1 ? "Online" : "Offline"
          exportObj["Registration Close Date"] = dateConverter(
            item.registrationCloseDate
          )
          exportObj["Start Date"] = dateConverter(item.startDate)
          exportObj["End Date"] = dateConverter(item.endDate)
          exportObj["Class Room"] = item.classRoom

          exportData.push(exportObj)
        }

        jsonToExcel(exportData, `event-reports-${moment().format("DD-MM-YY")}`)
      } else toastr.info(`There are Event reports to export`)
    } catch (error) {}
  }

  const handleExport = () => {
    startExport(async () => {
      await handleExportData(count)
    })
  }

  const handleChange = (event, value) => {
    setPage(value)
  }

  const handleSelectValueChange = (select, name) => {
    const obj = { ...selected }
    obj[name] = select
    setSelected(obj)
    handleValueChange(select.value, name)
  }
  const handleValueChange = (value, name) => {
    const obj = { ...filterObject }
    obj[name] = value
    setFilterObject(obj)
  }

  const typeOptions = [
    { label: "Online", value: 0 },
    { label: "Offline", value: 1 },
  ]
  const reset = () => {
    setFilterObject({})
    setPage(1)
    setTotalPage(0)
    setCount(0)
    setSelected({})
    // setCommunityOptions([])
    // setCategoryOptions([])
    // setBranchOptions([])
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Event Id",
        field: "uniqueId",
        width: 150,
      },
      {
        label: "Category",
        field: "category",
        width: 150,
      },
      {
        label: "Csp",
        field: "csp",
        width: 150,
      },

      {
        label: "Venue",
        field: "venue",
        width: 150,
      },
      {
        label: "Event Name",
        field: "name",
        width: 150,
      },
      {
        label: "Fee",
        field: "fee",
        width: 150,
      },
      {
        label: "GST slab",
        field: "gstLabs",
        width: 150,
      },
      {
        label: "Community",
        field: "community",
        width: 150,
      },
      {
        label: "Resource Person",
        field: "resourcePerson",
        width: 150,
      },
      {
        label: "Resource Email",
        field: "resourcePersonEmail",
        width: 150,
      },
      {
        label: "Resource Mobile",
        field: "resourcePersonMobile",
        width: 150,
      },
      {
        label: "Type",
        field: "type",
        width: 150,
      },
      {
        label: "Registration Close Date",
        field: "registrationCloseDate",
        width: 150,
      },
      {
        label: "Start Date",
        field: "startDate",
        width: 150,
      },
      {
        label: "End Date",
        field: "endDate",
        width: 150,
      },
      {
        label: "Class Room",
        field: "classRoom",
        width: 150,
      },
    ],
    rows: tableData,
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Event report" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>From</Label>
                          <AvField
                            name="from"
                            value={filterObject.from || ""}
                            onChange={e =>
                              handleValueChange(e.target.value, e.target.name)
                            }
                            max={moment().format("YYYY-MM-DD")}
                            className="form-control"
                            type="date"
                            placeholder="From"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>To</Label>
                          <AvField
                            name="to"
                            max={moment().format("YYYY-MM-DD")}
                            min={filterObject.from}
                            value={filterObject.to || ""}
                            onChange={e =>
                              handleValueChange(e.target.value, e.target.name)
                            }
                            className="form-control"
                            type="date"
                            placeholder="To"
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Type</Label>
                          <Select
                            onChange={selected => {
                              handleSelectValueChange(selected, "type")
                            }}
                            value={selected.type || ""}
                            // isClearable={true}
                            options={typeOptions}
                          />
                        </div>
                      </Col>
                      {/* <Admin> */}
                      <Col md="2">
                        <div className="mb-3">
                          <Label>CSP</Label>
                          <Select
                            onChange={selected => {
                              handleSelectValueChange(selected, "branch")
                            }}
                            value={selected.branch || ""}
                            // isClearable={true}
                            options={branchOptions}
                          />
                        </div>
                      </Col>
                      {/* </Admin> */}
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Community</Label>
                          <Select
                            onChange={selected => {
                              handleSelectValueChange(selected, "community")
                            }}
                            value={selected.community || ""}
                            // isClearable={true}
                            options={communityOptions}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Category</Label>
                          <Select
                            onChange={selected => {
                              handleSelectValueChange(selected, "category")
                            }}
                            value={selected.category || ""}
                            // isClearable={true}
                            options={categoryOptions}
                          />
                        </div>
                      </Col>

                      <Col className="mt-4 pt-1">
                        <div className="mb-3">
                          <Button color="danger" type="button" onClick={reset}>
                            Reset
                          </Button>

                          <Button
                            style={{ marginLeft: "14px" }}
                            color="warning"
                            type="button"
                            disabled={isExportPending}
                            onClick={handleExport}
                          >
                            Export{" "}
                            {isExportPending && (
                              <i className="mdi mdi-spin mdi-loading"></i>
                            )}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                  <MDBDataTable
                    id="eventReportTable"
                    bordered
                    responsive
                    searching={false}
                    displayEntries={false}
                    paging={false}
                    disableRetreatAfterSorting={true}
                    data={data}
                    sortable={false}
                  ></MDBDataTable>
                  <TablePagination
                    page={page}
                    onChange={handleChange}
                    count={totalPage}
                  />
                  {/* <MuiCustomTablePagination
                    count={count}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  /> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default EventReport
