import React, { useEffect, useRef, useState } from "react"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { useNavigate } from "react-router-dom"

import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import {
  getCompaniesOptions,
  getDistrictOptions,
  getStatesOptions,
} from "../../../store/options/actions"
import { MDBDataTable } from "mdbreact"

import { del, get, post, put } from "../../../helpers/api_helper"
import toastr from "toastr"
import Swal from "sweetalert2"
import "./styles.scss"
import { Modal } from "react-bootstrap"
import { API_URL, APP_NAME, PRIVILEGES } from "../../../config"
import {
  generatePermalink,
  handleImageValidation,
} from "../../../helpers/functions"

const Branch = () => {
  document.title = `CSP | ${APP_NAME}`

  const navigate = useNavigate()

  const dispatch = useDispatch()
  const formRef = useRef()

  const { states, companies, distrcts } = useSelector(state => state.Options)

  const [selectedDistrict, setSelectedDistrict] = useState(null)

  const [master, setMaster] = useState({ state: 19 })
  const [selectedState, setSelectedState] = useState({
    _id: 19,
    name: "Kerala",
    label: "Kerala",
    value: 19,
  })
  const [selectedOperatingPartner, setSelectedOperatingPartner] = useState(null)
  const [details, setDetails] = useState([])
  // eslint-disable-next-line
  const [popupData, setPopupData] = useState({})
  const [popupView, setPopupView] = useState(false)
  const [reload, setReload] = useState(false)
  const [zones, setZones] = useState({ selected: null, options: [] })

  useEffect(() => {
    dispatch(getStatesOptions({}))
    dispatch(getDistrictOptions({ id: 19 }))
    dispatch(getCompaniesOptions({ type: PRIVILEGES.OPERATING_PARTNER }))
  }, [dispatch])

  useEffect(() => {
    get("branch")
      .then(res => {
        const data = res.branches

        data.map((item, index) => {
          item.id = index + 1
          item.addedby = `${item.added_by.firstName || ""} ${
            item.added_by.lastName || ""
          }`
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                // onClick={() => {
                //   hanldePopup(item._id)
                // }}

                onClick={() => {
                  navigate(`/view-csp/${item.branchId}`, {
                    state: { id: item._id },
                  })
                }}
              ></i>

              {item?.pdfQr && (
                <a
                  href={`${API_URL}${item?.pdfQr}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginTop: "-3px" }}
                >
                  <i
                    className="fas fa-qrcode"
                    style={{
                      fontSize: "1em",
                      cursor: "pointer",
                      marginLeft: "0.2em",
                      marginRight: "0.5em",
                    }}
                  ></i>
                </a>
              )}
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  preUpdateBranch(item._id)
                  toTop()
                }}
              ></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  handleDelete(item._id)
                }}
              ></i>
            </div>
          )
          return item
        })
        setDetails(data)
      })
      .catch(err => {
        console.log(err.response.data)
      })
    // eslint-disable-next-line
  }, [reload])

  useEffect(() => {
    get("options/zones").then(res => {
      setZones({ options: res.data, selected: null })
    })
  }, [])

  function handleChange(e) {
    let { name, value } = e.target

    const data = { [name]: value }

    if (name === "name") {
      data.permalink = generatePermalink(value)
    }

    setMaster({ ...master, ...data })
  }

  function handleSelect(e, name) {
    const data = { [name]: e.value }

    if (name === "state") data.district = null

    setMaster(pre => ({ ...pre, ...data }))
  }

  function handleSubmit() {
    if (master.id) {
      put("branch", master)
        .then(res => {
          setReload(pre => !pre)
          toastr.success(res.message)
          handleReset()
        })
        .catch(err => {
          toastr.error(err.response.data.message)
          console.log(err.response.data)
        })
    } else {
      post("branch", master)
        .then(res => {
          setReload(pre => !pre)
          toastr.success(res.message)
          handleReset()
        })
        .catch(err => {
          toastr.error(err.response.data.message)
          console.log(err.response.data)
        })
    }
  }

  function preUpdateBranch(id) {
    get(`branch/single/${id}`)
      .then(res => {
        const data = res.branch
        data.id = id
        setSelectedOperatingPartner({
          label: data?.operatingPartner?.name,
          value: data?.operatingPartner?._id,
        })
        setSelectedState({
          label: data?.state?.name,
          value: data?.state?._id,
        })

        if (data?.state?._id) {
          dispatch(getDistrictOptions({ id: data?.state?._id }))
        }

        setSelectedDistrict({
          label: data?.district?.district_name,
          value: data?.district?._id,
        })

        setZones(pre => ({
          ...pre,
          selected: {
            label: data?.zone?.name,
            value: data?.zone?._id,
          },
        }))

        data.company = data?.company?._id
        data.state = data?.state?._id
        data.district = data?.district?._id
        setMaster(data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`branch/${id}`)
          .then(res => {
            toastr.success(res.message)
            setReload(pre => !pre)
          })
          .catch(err => {
            toastr.success(err.response.data.message)
          })
      }
    })
  }

  // function hanldePopup(id) {
  //   get(`branch/single/${id}`)
  //     .then(res => {
  //       const data = res.branch
  //       data.state = data?.state?.name
  //       data.company = data?.company?.name
  //       data.addedBy = `${data.added_by?.firstName || ""} ${data.added_by.lastName
  //         }`
  //       setPopupData(data)
  //       setPopupView(true)
  //     })
  //     .catch(err => {
  //       console.log(err)
  //     })
  // }

  const uploadImage = async e => {
    const selectedFile = e.target.files[0]
    console.log("selectedFile: ", selectedFile)

    if (selectedFile) {
      try {
        await handleImageValidation(selectedFile, 1000, 565)

        const fd = new FormData()

        fd.append("image", selectedFile)

        const response = await post(`branch/image`, fd)

        if (response.status === 200) {
          setMaster({
            ...master,
            image: response.data.new_filename,
          })
        } else {
          toastr.error(response.data.message)
        }
      } catch (error) {
        toastr.error(error)
        e.target.value = ""
      }
    }
  }

  function handleReset() {
    setMaster({})
    setZones(pre => ({ ...pre, selected: null }))
    formRef.current.reset()
    setSelectedState(null)
    setSelectedOperatingPartner(null)
    setSelectedDistrict(null)
  }

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "CSP",
        field: "name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Code",
        field: "shortCode",
        sort: "asc",
        width: 200,
      },
      {
        label: "Contact Person",
        field: "contactPerson",
        sort: "asc",
        width: 100,
      },
      {
        label: "Designation",
        field: "designation",
        sort: "asc",
        width: 150,
      },
      {
        label: "Mobile",
        field: "mobile",
        sort: "asc",
        width: 10,
      },
      {
        label: "City",
        field: "city",
        sort: "asc",
        width: 100,
      },
      {
        label: "Staff",
        field: "addedby",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: details,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Home" breadcrumbItem="CSP" />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={() => {
                      handleSubmit()
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">CSP Name</Label>
                          <AvField
                            name="name"
                            placeholder="CSP Name"
                            type="text"
                            errorMessage="Enter CSP Name"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: `^[a-zA-Z ]+$`,
                                errorMessage: "Enter valid CSP name",
                              },
                            }}
                            id="validationCustom01"
                            value={master.name || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Permalink</Label>
                          <AvField
                            name="permalink"
                            className="form-control"
                            type="text"
                            placeholder="Permalink"
                            errorMessage="Enter permalink"
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: /^[a-z0-9]+(-[a-z0-9]+)*$/,
                                errorMessage: "Invalid Permalink",
                              },
                            }}
                            value={master?.permalink || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Short Code</Label>
                          <AvField
                            name="shortCode"
                            placeholder="Short Code"
                            type="text"
                            errorMessage="Enter Short code"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={master.shortCode || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            Operating Partner
                          </Label>
                          <Select
                            name="company"
                            errorMessage="Select Company"
                            value={selectedOperatingPartner}
                            onChange={selected => {
                              handleSelect(selected, "operatingPartner")
                              setSelectedOperatingPartner(selected)
                            }}
                            options={companies}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Zone</Label>
                          <Select
                            name="zone"
                            errorMessage="Select zone"
                            value={zones.selected}
                            onChange={selected => {
                              handleSelect(selected, "zone")
                              setZones(pre => ({ ...pre, selected }))
                            }}
                            options={zones.options}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Contact Person
                          </Label>
                          <AvField
                            name="contactPerson"
                            placeholder="Contact Person"
                            type="text"
                            errorMessage="Enter Contact Person"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: "^[a-zA-Z ]+$",
                                errorMessage: "Enter name",
                              },
                            }}
                            id="validationCustom01"
                            value={master.contactPerson || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Designation
                          </Label>
                          <AvField
                            name="designation"
                            placeholder="Designation"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            value={master.designation || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Mobile No.</Label>
                          <AvField
                            name="mobile"
                            placeholder="Mobile No."
                            type="text"
                            errorMessage="Enter valid 10 digit number"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: "^(0|91)?[6-9][0-9]{9}$",
                                errorMessage: "Enter valid mobile no",
                              },
                            }}
                            id="validationCustom03"
                            value={master.mobile || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom04">
                            Landline No.
                          </Label>
                          <AvField
                            name="landLineNumber"
                            placeholder="Landline No."
                            type="text"
                            errorMessage="Enter valid 11 digit number"
                            validate={{
                              pattern: {
                                value: "^[0-9]{11}$",
                                errorMessage: "Enter valid landline no",
                              },
                            }}
                            className="form-control"
                            value={master.landLineNumber || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Email ID</Label>
                          <AvField
                            name="email"
                            placeholder="Email ID"
                            type="email"
                            errorMessage=" Please provide a valid Email ID."
                            className="form-control"
                            value={master.email || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Address</Label>
                          <AvField
                            name="address"
                            placeholder="Address"
                            type="textarea"
                            errorMessage="Enter Your Address"
                            className="form-control"
                            value={master.address || ""}
                            rows={1}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>State</Label>

                          <Select
                            name="state"
                            value={selectedState}
                            onChange={selected => {
                              handleSelect(selected, "state")
                              setSelectedState(selected)
                              setSelectedDistrict(null)
                              dispatch(
                                getDistrictOptions({ id: selected.value })
                              )
                            }}
                            options={states}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>District</Label>
                          <Select
                            name="district_id"
                            value={selectedDistrict}
                            onChange={selected => {
                              handleSelect(selected, "district")
                              setSelectedDistrict(selected, "district")
                            }}
                            options={distrcts}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>City</Label>
                          <AvField
                            name="city"
                            placeholder="City"
                            type="text"
                            className="form-control"
                            id="validationCustom03"
                            value={master.city || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Pincode</Label>
                          <AvField
                            name="pincode"
                            placeholder="Pincode"
                            type="text"
                            className="form-control"
                            validate={{
                              pattern: {
                                value: "^[1-9][0-9]{5}$",
                                errorMessage:
                                  "PIN must be only with numbers 6 length",
                              },
                            }}
                            id="validationCustom05"
                            value={master.pincode || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Latitude</Label>
                          <AvField
                            name="latitude"
                            placeholder="Latitude"
                            type="number"
                            errorMessage="Enter valid Latitude"
                            className="form-control"
                            value={master.latitude || ""}
                            validate={{
                              pattern: {
                                // value: `^-?([0-8]?[0-9]|90)(\.[0-9]{1,10})$`, // Validates -90 to 90 with decimals
                                value: "^[0-9.]+$",
                                // errorMessage:"Enter a valid latitude between -90 and 90",
                              },
                            }}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom04">Longitude</Label>
                          <AvField
                            name="longitude"
                            placeholder="Longitude"
                            type="text"
                            errorMessage="Enter valid Longitude"
                            className="form-control"
                            value={master.longitude || ""}
                            onChange={handleChange}
                            validate={{
                              pattern: {
                                // value: `^-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,10})$`, // Validates -180 to 180 with decimals
                                value: "^[0-9.]+$",
                                // errorMessage:"Enter a valid longitude between -180 and 180",
                              },
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Image
                            <span className="label-image-size">(800x500)</span>
                          </Label>
                          {master?.image ? (
                            <div div className="img-wraps d-flex flex-column">
                              <img
                                alt=""
                                width="150"
                                height="150"
                                src={`${API_URL}${master.image}`}
                              />
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={() =>
                                  setMaster(pre => ({ ...pre, image: null }))
                                }
                                style={{ width: "150px" }}
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <input
                              name="image"
                              type="file"
                              className="form-control"
                              id="validationCustom03"
                              accept=""
                              onChange={uploadImage}
                            />
                          )}
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom04">Video</Label>
                          <AvField
                            name="videoURL"
                            placeholder="Video URL"
                            type="url"
                            errorMessage="Enter valid URL"
                            className="form-control"
                            value={master.videoURL || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                      <Col
                        md="3"
                        className="d-flex align-items-center mt-2 pt-2"
                      >
                        <Button
                          color={master.id ? "warning" : "primary"}
                          type="submit"
                        >
                          {master.id ? "Update" : "Submit"}
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="branchTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Branch Details</h5>
          <button
            type="button"
            onClick={() => {
              setPopupView(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation">
          <div className="modal-body branchtable">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>
                  Branch ID :
                </td>
                <td>{popupData?.branchId}</td>
                <td style={{ paddingLeft: "25px" }}>Branch :</td>
                <td style={{ textAlign: "left" }}>{popupData?.name}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>Code :</td>
                <td>{popupData?.shortCode}</td>
                <td style={{ paddingLeft: "25px" }}>Operating Partner :</td>
                <td style={{ textAlign: "left" }}>
                  {popupData?.operatingPartner?.name}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>
                  Designation :
                </td>
                <td>{popupData?.designation}</td>
                <td style={{ paddingLeft: "25px" }}>Zone :</td>
                <td style={{ textAlign: "left" }}>{popupData?.zone?.name}</td>
              </tr>
              <tr hidden={!popupData?.landLineNumber && !popupData?.email}>
                <td style={{ padding: "10px", textAlign: "left" }}>
                  Landline No :
                </td>
                <td>{popupData?.landLineNumber}</td>
                <td style={{ paddingLeft: "25px" }}>Mobile :</td>
                <td style={{ textAlign: "left" }}>{popupData?.mobile}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>
                  Address :
                </td>
                <td style={{ width: "191.67px", wordBreak: "break-all" }}>
                  {popupData?.address}
                </td>
                <td style={{ paddingLeft: "25px" }}>Email :</td>
                <td style={{ textAlign: "left" }}>{popupData?.email}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>City :</td>
                <td>{popupData?.city}</td>
                <td style={{ paddingLeft: "25px" }}>State :</td>
                <td style={{ textAlign: "left" }}>{popupData?.state}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>
                  Latitude :
                </td>
                <td>{popupData?.latitude}</td>
                <td style={{ paddingLeft: "25px" }}>Pincode :</td>
                <td style={{ textAlign: "left" }}>{popupData?.pincode}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>
                  Contact Person :
                </td>
                <td style={{ textAlign: "left" }}>
                  {popupData?.contactPerson}
                </td>
                <td style={{ paddingLeft: "25px" }}>Longitude :</td>
                <td style={{ textAlign: "left" }}>{popupData?.longitude}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>
                  Added By :
                </td>
                <td style={{ textAlign: "left" }}>{popupData?.addedBy}</td>
              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>
    </React.Fragment>
  )
}

export default Branch
