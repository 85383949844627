import { MDBDataTable } from "mdbreact";
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
} from "reactstrap";
import { get } from "../../../helpers/api_helper";
import './Occupancy.scss'

const Occupancy = () => {
  const [tableData,setTableData] = useState([])


  const fetchOccupancy = ()=> {
    get(`batch/csp-wise-occupancy`).then(res=>{
      if(res.success){
        setTableData(res.data.map((item,index)=>({
          ...item,
          index: index + 1,
          csp: item.csp?.name,
          occupancy: `${((item.occupiedClass + item.occupiedLab)*100/(item.total)).toFixed(0)} %`
        })))
      }
    })
  }

  useEffect(()=>{
    fetchOccupancy()
  },[])

  const columns = [
    {
      label:'#',
      field: 'index'
    },
    {
      label: 'CSP Center',
      field: 'name'
    },
    {
      label: 'Total Classrooms',
      field: 'totalClass',
    },
    {
      label: 'Occupied Classrooms',
      field: 'occupiedClass',
    },
    {
      label: 'Total Labs',
      field: 'totalLab',
    },
    {
      label: 'Occupied Labs',
      field: 'occupiedLab',
    },
    {
      label: 'Total Labs/Classrooms',
      field: 'total',
    },
    {
      label: 'Occupancy',
      field: 'occupancy',
    },

  ]

  const data = { columns, rows:tableData}
  
  return (
    <div style={{ fontSize: "13px", fontFamily: "IBM Plex Sans, sans-serif" }}>
      <Row>
        <Col lg={12}>
        <MDBDataTable
                id="occupancyTableId"
                responsive
                bordered
                info={true}
                searching={true}
                entries={20}
                disableRetreatAfterSorting={true}
                data={data}
                paginationLabel={false}
                paging={false}
              />
        </Col>
      </Row>
    </div>
  );
};

export default Occupancy;


