import React, { useEffect, useRef, useState } from "react"
import { Card, CardBody, Row, Col, Label } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import { MDBDataTable } from "mdbreact"
import toastr from "toastr"
import queryString from "query-string"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { get } from "../../../helpers/api_helper"
import {
  dateConverter,
  setTitle,
  timeConverter,
  jsonToExcel,
} from "../../../helpers/functions"
import { Button } from "reactstrap"

import "./style.scss"
import TablePagination from "../../../components/Common/Pagination"
import moment from "moment"
const OfferCodeLog = () => {
  setTitle("Offer Code Log")
  const ref = useRef()
  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const limit = 20

  const [tableData, setTableData] = useState([])
  const [offerCodeOptions, setOfferCodeOptions] = useState([])
  const [studentOptions, setStudentOptions] = useState([])

  const [selectedFields, setSelectedFields] = useState({
    offerCode: "",
    student: "",
  })

  const [filterObject, setFilterObject] = useState({
    offerCode: "",
    student: "",
    from: "",
    to: "",
  })

  useEffect(() => {
    handleTableData()
    handleOfferCodeOptions()
    handleStudentCodeOptions()
    // eslint-disable-next-line
  }, [filterObject])

  const handleTableData = async (obj = filterObject, perPage = page) => {
    try {
      const response = await get(
        `offerCode/log?offerCode=${obj.offerCode}&from=${obj.from}&to=${obj.to}&code=${obj.offerCode}&student=${obj.student}&page=${perPage}&limit=${limit}`
      )
      const data = response?.data
      const count = response.count
      if (data && Array.isArray(data)) {
        data.map((item, index) => {
          item.id = index + 1 + (page - 1) * limit
          item.date = dateConverter(item.date)
          item.time = timeConverter(item.time)
          item.student = `${item.studentFirstName || ""} ${
            item.studentLastName || ""
          }`
          item.status = (
            <Button
              color={item.status === 0 ? "success" : "danger"}
              size="sm"
              disabled
            >
              {item.status === 0 ? "Applied" : "Removed"}
            </Button>
          )

          item.fee = item.fee?.toLocaleString("en-IN") || 0
          item.amount = item.amount?.toLocaleString("en-IN") || 0
          return item
        })

        setCount(Number(count))
        setTableData(data)
        const totalPage = Math.ceil(Number(count) / limit)
        setTotalPage(totalPage)
      }
    } catch (err) {}
  }

  const handleExport = async limit => {
    try {
      const params = `limit=${limit}&${queryString.stringify(filterObject)}`
      const response = await get(`offerCode/log?${params}`)
      const data = response.data
      const exportData = []

      if (!data || !Array.isArray(data) || !data.length)
        toastr.info(`There are no offer code log to export`)

      data.map((item, index) => {
        const dataExport = {}
        dataExport.Id = index + 1
        dataExport.Date = dateConverter(item.date)
        dataExport.Time = timeConverter(item.time)
        dataExport["Offer Code"] = item.code[0]
        dataExport.student = `${item.studentFirstName || ""} ${
          item.studentLastName || ""
        }`
        dataExport["Register No"] = item.studentId[0]
        dataExport["Course/Event"] = item.type[0]
        dataExport["Name"] = item.name[0]
        dataExport["Fee"] = item.fee
        dataExport["Availed Amount"] = item.amount
        dataExport.Status = item.status === 0 ? "Applied" : "Removed"

        exportData.push(dataExport)
        return item
      })
      jsonToExcel(exportData, `Offer Code Log -${moment().format("DD-MM-YY")}`)
    } catch (error) {
      console.log(error.message)
      toastr.info(`There are no offer code log to export`)
    }
  }

  const handleOfferCodeOptions = async (obj = filterObject) => {
    try {
      const response = await get(
        `offerCode/log/options?from=${obj.from}&to=${obj.to}&code=${obj.offerCode}&student=${obj.student}`
      )
      setOfferCodeOptions(response.data)
    } catch (error) {}
  }
  const handleStudentCodeOptions = async (obj = filterObject) => {
    try {
      const response = await get(
        `offerCode/log/options/student?from=${obj.from}&to=${obj.to}&code=${obj.offerCode}&student=${obj.student}`
      )
      setStudentOptions(response.data)
    } catch (error) {}
  }

  const handleSelectValueChange = (selected, name) => {
    const obj = { ...selectedFields }
    obj[name] = selected
    setSelectedFields(obj)
    handleValueChange(selected.value, name)
  }
  const handleValueChange = (value, name) => {
    const obj = { ...filterObject }
    obj[name] = value
    setFilterObject(obj)
    setPage(1)
  }

  const handleChange = (event, value) => {
    setPage(value)
    handleTableData(filterObject, value)
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Time", field: "time" },
      { label: "Offer Code", field: "code" },
      { label: "Student", field: "student" },
      { label: "Register No", field: "studentId" },
      { label: "Course/Event", field: "type" },
      { label: "Name", field: "name" },
      { label: "Fee", field: "fee" },
      { label: "Availed Amount", field: "amount" },
      { label: "Status", field: "status" },
    ],
    rows: tableData,
  }

  const reset = () => {
    setFilterObject({})
    setSelectedFields({})
    ref.current?.reset()
    setPage(1)
    setTotalPage(0)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Offer Code Log" />
          <Card>
            <CardBody>
              <AvForm ref={ref}>
                <Row>
                  <Col md={2}>
                    <AvField
                      name="from"
                      label="From"
                      type="date"
                      value={filterObject.from}
                      onChange={e =>
                        handleValueChange(e.target.value, e.target.name)
                      }
                    ></AvField>
                  </Col>
                  <Col md={2}>
                    <AvField
                      name="to"
                      label="To"
                      type="date"
                      value={filterObject.to}
                      min={filterObject.from}
                      onChange={e =>
                        handleValueChange(e.target.value, e.target.name)
                      }
                    ></AvField>
                  </Col>
                  <Col md={2}>
                    <Label>Offer Code</Label>
                    <Select
                      name="offerCode"
                      menuPosition="fixed"
                      options={offerCodeOptions}
                      value={selectedFields.offerCode || ""}
                      onChange={selected =>
                        handleSelectValueChange(selected, "offerCode")
                      }
                    ></Select>
                  </Col>
                  <Col md={2}>
                    <Label>Student</Label>
                    <Select
                      name="student"
                      options={studentOptions}
                      value={selectedFields.student || ""}
                      menuPosition="fixed"
                      onChange={selected =>
                        handleSelectValueChange(selected, "student")
                      }
                    ></Select>
                  </Col>
                  <Col md={2} style={{ marginTop: "26px" }}>
                    <Button color="danger" onClick={reset}>
                      Reset
                    </Button>

                    <Button
                      style={{ marginLeft: "14px" }}
                      color="warning"
                      type="button"
                      onClick={() => handleExport(count)}
                    >
                      Export
                    </Button>
                  </Col>
                </Row>
              </AvForm>
              <MDBDataTable
                id="couponCodeLog"
                data={data}
                className="mt-3"
                bordered
                responsive
                searching={false}
                entries={20}
                paging={false}
                info={false}
                sortable={false}
                disableRetreatAfterSorting={true}
              />
              <TablePagination
                page={page}
                onChange={handleChange}
                count={totalPage}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default OfferCodeLog
