import React, { useEffect, useRef, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import { get, post, del, put } from "../../../../helpers/api_helper"
import { OverlayTrigger, Tooltip, Modal } from "react-bootstrap"
import toastr from "toastr"
import "./style.scss"
import moment from "moment"
import Swal from "sweetalert2"
import { uniqueId } from "lodash"
import axios from "axios"
import { API_URL } from "../../../../config"

function Costing() {
  const [master, setMaster] = useState({})
  const formRef = useRef()

  const [popupView, setPopupView] = useState(false)
  const [eventOptions, setEventOptions] = useState([])
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [filterEvent, setFilterEvent] = useState(null)
  const [filterEventcategory, setFilterEventcategory] = useState(null)
  const [expenseOptions, setExpenseOptions] = useState([])
  const [selectedExpense, setSelectedExpense] = useState(null)
  const [tableData, setTableData] = useState([])
  const [errors, setErrors] = useState({ eventName: "", expenseName: "" })
  const [costIdToBeUpdated, setCostIdToBeUpdated] = useState(null)
  const [popupData, setpopupData] = useState([])
  const initialstate = {
    fromDate: "",
    toDate: "",
    events: "",
    expenseCategorys: "",
  }
  const [filterObject, setFilterObject] = useState(initialstate)

  function resetall() {
    setMaster({})
    setSelectedEvent(null)
    setExpenseOptions(null)
    setSelectedExpense(null)
    setErrors({ eventName: "", expenseName: "" })
  }

  useEffect(() => {
    fetch_events()
    fetch_expense()
  }, [])
  useEffect(() => {
    handleTableData()
  }, [filterObject])

  function fetch_events() {
    get("/events/listOptions").then(res => {
      var event_data = res.data.map(item => {
        return {
          label: item?.name + " " + (item.uniqueId ? " - " + item.uniqueId : ""),
          value: item?._id,
        }
      })
      setEventOptions([{ options: event_data }])
    })
  }
  function fetch_expense() {
    get("expense-category/list").then(res => {
      var expns_data = res.Data.map(item => {
        return {
          label: item?.name,
          value: item?._id,
        }
      })
      setExpenseOptions([{ options: expns_data }])
    })
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      // {
      //   label: "Category",
      //   field: "category",
      //   width: 150,
      // },
      {
        label: "Event Name",
        field: "name",
        width: 150,
      },
      // {
      //   label: "UniqueId",
      //   field: "uniqueId",
      //   width: 150,
      // },
      {
        label: "Expense Category",
        field: "expense_category",
        sort: "desc",
        width: 400,
      },
      {
        label: "Narration",
        field: "narration",
        width: 150,
      },
      {
        label: "Amount",
        field: "amount",
        width: 150,
      },
      {
        label: "Bill",
        field: "bill",
        width: 150,
      },
      {
        label: "Staff",
        field: "addedby",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        width: 150,
      },
    ],
    rows: tableData,
  }

  function handleChange(e) {
    let { name, value } = e.target
    setMaster({ ...master, [name]: value })
  }

  function handleSubmit() {
    if (selectedEvent === null || selectedExpense === null) {
      let errorVal = errors
      if (selectedEvent === null) {
        errorVal.eventName = "Please select event"
      }
      if (selectedExpense === null) {
        errorVal.expenseName = "Please select category"
      }
      setErrors({ ...errorVal })
    } else {
      if (master._id) {
        put(`event-costing/edit/`, master)
          .then(res => {
            toastr.success(res.message)
            reset()
          })
          .catch(err => {
            toastr.error(err.response.data.message)
            return
          })
      } else {
        post(`event-costing/add`, master)
          .then(res => {
            toastr.success(res.message)
            reset()
          })
          .catch(err => {
            toastr.error(err.response.data.message)
            return
          })
      }
    }
  }

  function reset() {
    setMaster({
      narration: "",
      amount: "",
    })
    formRef.current.reset()
    handleTableData()
    setSelectedEvent(null)
    setSelectedExpense(null)
  }

  function filterreset() {
    setFilterObject(initialstate)
    setFilterEvent(null)
    setFilterEventcategory(null)
    formRef.current.reset()
  }

  function handleTableData() {
    const filterQuery = `fromDate=${filterObject.fromDate}&toDate=${filterObject.toDate}&event=${filterObject.events}&expenseCategory=${filterObject.expenseCategorys}`
    get(`event-costing/filter?${filterQuery}`).then(res => {
      let result = res.data
      let costData = []
      result?.map((item, indx) => {
        item.id = indx + 1
        item.date = moment(item?.date).format("DD-MM-YYYY")
        item.time = moment(item?.time, "hh:mm a").format("hh:mm a")
        item.expense_category = item?.expenseCategory?.name
        item.name = generateTooltip(item?.event?.name)
        item.uniqueId = item?.event?.uniqueId
        item.addedby = item?.staff?.name
        item.action = (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",

                marginRight: "0.5rem",
              }}
              onClick={() => {
                setPopupView(true)
                setpopupData(item)
              }}
            ></i>
            <i
              className="far fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",

                marginRight: "0.5rem",
              }}
              onClick={() => {
                toTop()
                handleUpdate(item)
                setCostIdToBeUpdated(item?._id)
              }}
            ></i>
            {/* <a
              href={`${API_URL}${item?.uploadedFile}`}
              target="_blank"
              rel="noreferrer"
            >
              <i
                className="far fa-file-pdf"
                style={{
                  cursor: "pointer",
                  fontSize: "1em",
                  color: "red",

                  marginRight: "0.5rem",
                }}
              ></i>
            </a> */}
            <i
              className="far fa-trash-alt"
              style={{ fontSize: "1em", cursor: "pointer" }}
              onClick={() => {
                handleDelete(item?._id)
              }}
            ></i>
          </div>
        )
        item.bill = (
          <>
            <a
              href={`${API_URL}${item?.uploadedFile}`}
              target="_blank"
              rel="noreferrer"
            >
              <i
                className="far fa-file-pdf"
                style={{
                  cursor: "pointer",
                  fontSize: "1.5em",
                  color: "red",

                  marginRight: "0.5rem",
                }}
              ></i>
            </a>
          </>
        )

        costData.push(item)
        return item
      })
      setTableData(costData)
    })
  }
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }
  function handleUpdate(item) {
    setSelectedEvent({ label: item?.event?.name, value: item.event?._id })
    setSelectedExpense({
      label: item?.expenseCategory?.name,
      value: item?.expenseCategory?._id,
    })
    setMaster({
      _id: item?._id,
      event: item.event.value,
      expenseCategory: item.expenseCategory?._id,
      narration: item?.narration,
      amount: item.amount,
      uploadedFile: item?.uploadedFile,
    })
  }

  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`event-costing/${id}`)
          .then(res => {
            toastr.success(res.message)
            handleTableData()
            setCostIdToBeUpdated(null)
          })
          .catch(err => {
            toastr.success(err.response.data.message)
          })
      }
    })
  }

  const generateTooltip = name => {
    let displayText = name
    if (name && name.length > 20) {
      displayText = `${name.substring(0, 20)}...`
    }

    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip
            id="button-tooltip-2"
            style={{ position: "absolute", top: "-30px" }}
          >
            {name}
          </Tooltip>
        }
      >
        <span>{displayText}</span>
      </OverlayTrigger>
    )
  }
  const uploadImage = e => {
    const file = e.target.files[0]
    const name = e.target.name

    if (!file) {
      toastr.error("Nofile selected")
      e.target.form.reset()
      return
    }

    // Check file type
    if (file.type !== "application/pdf") {
      toastr.error("File type must be PDF")
      e.target.form.reset()

      return
    }

    // Check file size
    if (file.size > 5 * 1024 * 1024) {
      toastr.error("File size must be less than 5MB")
      e.target.form.reset()
      return
    }

    const fd = new FormData()
    fd.append(name, file)
    axios
      .post(`${API_URL}event-costing/image/costing`, fd, {
        // onUploadProgress: data => {
        //   // setUploadProgress(Math.round((100 * data.loaded) / data.total));
        // },
      })
      .then(response => {
     
        if (response.data.status === 200) {
          setMaster({
            ...master,
            [name]: response.data.data.new_filename,
          })
        } else {
          toastr.error(response.data.message)
        }
      })
  }

  return (
    <>
      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h5>Details</h5>
          </div>

          <button
            type="button"
            onClick={() => setPopupView(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body" style={{ paddingTop: "0px" }}>
          <Row>
            <Col md="12">
              <table
                id="product_available_day"
                className="table table-bordered dataTable"
              >
                <tr>
                  <th
                    class="mdl-td-c"
                    style={{ textAlign: "left", padding: "10px" }}
                  >
                    CSP
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "", display: "flex" }}>
                    {popupData &&
                      popupData?.event?.csp.map(item => (
                        <span className="">{item.label}</span>
                      ))}
                  </td>
                  <th class="mdl-td-c" style={{ textAlign: "left" }}>
                    {/* Category */}
                  </th>
                  {/* <th> : </th> */}
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    {/* {popupData?.eventCategory?.name} */}
                  </td>
                </tr>
                <tr>
                  <th
                    class="mdl-td-c"
                    style={{ textAlign: "left", padding: "10px" }}
                  >
                    Event
                  </th>
                  <th> : </th>
                  <td
                    style={{ paddingLeft: "20px", textAlign: "justify" }}
                    colSpan={5}
                  >
                    {popupData?.event?.name} - {popupData?.uniqueId}
                  </td>
                </tr>
                <tr>
                  <th
                    class="mdl-td-c"
                    style={{ textAlign: "left", padding: "10px" }}
                  >
                    Expense Category
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>
                    {popupData?.expenseCategory?.name}
                  </td>
                  <th class="mdl-td-c" style={{ textAlign: "left" }}>
                    Amount
                  </th>
                  <th> : </th>
                  <td style={{ textAlign: "left", paddingLeft: "10px" }}>
                    <i className="fas fa-rupee-sign"></i>
                    {popupData?.amount}
                  </td>
                </tr>
                <tr>
                  <th
                    class="mdl-td-c"
                    style={{ textAlign: "left", padding: "10px" }}
                  >
                    Date
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px" }}>{popupData?.date}</td>
                  <th class="mdl-td-c" style={{ textAlign: "left" }}>
                    Time
                  </th>
                  <th> : </th>
                  <td style={{ paddingLeft: "20px", textAlign: "justify" }}>
                    {popupData?.time}
                  </td>
                </tr>
                <tr>
                  <th
                    class="mdl-td-c"
                    style={{ textAlign: "left", padding: "10px" }}
                  >
                    Added By
                  </th>
                  <th> : </th>
                  <td
                    style={{ paddingLeft: "20px", textAlign: "justify" }}
                    colSpan={5}
                  >
                    {popupData?.staff?.name}
                  </td>
                </tr>
                <tr>
                  <th
                    class="mdl-td-c"
                    style={{ textAlign: "left", padding: "10px" }}
                  >
                    Narration
                  </th>
                  <th> : </th>
                  <td
                    style={{ paddingLeft: "20px", textAlign: "justify" }}
                    colSpan={5}
                  >
                    {popupData?.narration}
                  </td>
                </tr>

                <tr>
                  <th
                    class="mdl-td-c"
                    style={{ textAlign: "left", padding: "10px" }}
                  >
                    <Button
                      onClick={() => {
                        window.open(`${API_URL}${popupData?.uploadedFile}`)
                      }}
                      className="view-syllabus broucher"
                      style={{ color: "whitesmoke" }}
                    >
                      Download Bill <i class="fa fa-download"></i>
                    </Button>
                  </th>
                </tr>
              </table>
            </Col>
          </Row>
        </div>
      </Modal>

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Event Costing" />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="needs-validation"
                    ref={formRef}
                    onValidSubmit={(e, v) => {
                      handleSubmit(e, v)
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Event</Label>
                          <Select
                            name="event"
                            classNamePrefix="select2-selection"
                            options={eventOptions}
                            value={selectedEvent}
                            onChange={value => {
                              setSelectedEvent(value)
                              setMaster(pre => ({
                                ...pre,
                                event: value.value,
                              }))
                            }}
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedEvent === null ? errors?.eventName : ""}
                          </p>
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Expense Category</Label>
                          <Select
                            name="expenseCategory"
                            classNamePrefix="select2-selection"
                            options={expenseOptions}
                            value={selectedExpense}
                            onChange={value => {
                              setSelectedExpense(value)
                              setMaster(pre => ({
                                ...pre,
                                expenseCategory: value.value,
                              }))
                            }}
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedExpense === null
                              ? errors?.expenseName
                              : ""}
                          </p>
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Narration</Label>
                          <AvField
                            name="narration"
                            className="form-control"
                            type="textarea"
                            rows={1}
                            placeholder="Narration"
                            errorMessage="Enter Narration"
                            value={master?.narration}
                            validate={{ required: { value: true } }}
                            onChange={e => handleChange(e)}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Amount</Label>
                          <AvField
                            name="amount"
                            className="form-control"
                            type="number"
                            min={0}
                            placeholder="Amount"
                            errorMessage="Enter Amount"
                            value={master?.amount || ""}
                            onChange={e => handleChange(e)}
                            validate={{ required: { value: true } }}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Upload Bill</Label>

                          {master?.uploadedFile ? (
                            <div div className="img-wraps d-flex flex-column">
                              <object
                                type="application/pdf"
                                width="150"
                                height="150"
                                data={`${API_URL}${master?.uploadedFile}`}
                              />
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={() => {
                                  setMaster(pre => ({
                                    ...pre,
                                    uploadedFile: null,
                                  }))
                                }}
                                style={{ width: "150px" }}
                                type="button"
                              >
                                Delete
                              </button>
                              <a
                                className="mt-2"
                                style={{ fontStyle: "italic" }}
                                href={`${API_URL}${master?.uploadedFile}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View
                              </a>
                            </div>
                          ) : (
                            <input
                              name="uploadedFile"
                              type="file"
                              className="form-control"
                              id="validationCustom03"
                              onChange={uploadImage}
                              rows="1"
                            />
                          )}
                        </div>
                        <p style={{ fontSize: ".7rem", marginTop: "-1rem" }}>
                          (pdf size should be less than 5mb)
                        </p>
                      </Col>
                      <Col>
                        <div className="mb-3" style={{ paddingTop: "25px" }}>
                          <Button
                            color={master?._id ? "warning" : "primary"}
                            type="submit"
                          >
                            {master?._id ? "Update" : "Submit"}
                          </Button>

                          <Button
                            color="danger"
                            type="reset"
                            onClick={reset}
                            className="mx-2"
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <AvForm
                    className="needs-validation"
                    ref={formRef}
                    // onValidSubmit={(e, v) => {
                    //   handleSubmit(e, v)
                    // }}
                  >
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>From </Label>
                          <AvField
                            name="fromDate"
                            type="date"
                            classNamePrefix="select2-selection"
                            value={filterObject.fromDate}
                            onChange={e => {
                              // setSelectedEvent(value)
                              setFilterObject(pre => ({
                                ...pre,
                                fromDate: e.target.value,
                              }))
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>To</Label>
                          <AvField
                            name="toDate"
                            classNamePrefix="select2-selection"
                            type="date"
                            value={filterObject.toDate}
                            onChange={e => {
                              // setSelectedEvent(value)
                              setFilterObject(pre => ({
                                ...pre,
                                toDate: e.target.value,
                              }))
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Event</Label>
                          <Select
                            name="event"
                            classNamePrefix="select2-selection"
                            options={eventOptions}
                            value={filterEvent}
                            onChange={value => {
                              setFilterEvent(value)
                              setFilterObject(pre => ({
                                ...pre,
                                events: value.value,
                              }))
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Expense Category</Label>
                          <Select
                            name="expenseCategory"
                            classNamePrefix="select2-selection"
                            options={expenseOptions}
                            value={filterEventcategory}
                            onChange={value => {
                              setFilterEventcategory(value)
                              setFilterObject(pre => ({
                                ...pre,
                                expenseCategorys: value.value,
                              }))
                            }}
                          />
                        
                        </div>
                      </Col>

                      <Col>
                        <div className="mb-3" style={{ paddingTop: "25px" }}>
                          <Button
                            color="danger"
                            type="reset"
                            onClick={filterreset}
                            className="mx-2"
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                  <MDBDataTable
                    id="costingTableId"
                    bordered
                    responsive
                    searching={false}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default Costing
