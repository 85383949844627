import { MDBDataTable } from "mdbreact"
import React, { useEffect, useState } from "react"
import { Button, Modal } from "reactstrap"
import Tooltip from "@mui/material/Tooltip"

import {
  dateConverter,
  numberToCurrency,
  timeConverter,
} from "../../../../helpers/functions"
import { get } from "../../../../helpers/api_helper"
import PaymentDetails from "../../../Finance/Fees/PaymentDetails"
import CreditNoteDetails from "../../../Finance/Fees/CreditNotes/details"
import { API_URL } from "../../../../config"

const Statement = ({ id }) => {
  const typeMap = {
    1: "Cash",
    2: "Skill loan",
    3: "Scholarship",
    4: "Online",
  }

  const statusMap = {
    0: { color: "success", text: "Success" },
    1: { color: "danger", text: "Failed" },
  }

  const [selected, setSelected] = useState({ show: false, id: null })
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    if (id) handleData()
  }, [id]) //eslint-disable-line

  const handleData = async () => {
    try {
      const response = await get(`/student/statement?student=${id}`)
      const data = response.data
      data?.map((item, index) => {
        const isCreditNote = item.isCreditNote

        item.id = index + 1

        item.date = dateConverter(item.date)
        item.time = timeConverter(item.time)

        item.for = item.for === 0 ? "Course" : "Event"
        item.type = typeMap[item.type] || ""

        item.amount = numberToCurrency(item.amount)
        if (isCreditNote) item.type = "Credit Note"

        const status = statusMap[item.status] || {}
        item.status = (
          <Button
            size="sm"
            style={{ width: "100%" }}
            color={status.color}
            disabled
          >
            {status.text}
          </Button>
        )

        item.options = (
          <div>
            <Tooltip title="View" arrow placement="top">
              <i
                className="fas fa-eye"
                onClick={() => handleToggle(true, item)}
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
              ></i>
            </Tooltip>

            {item.receiptPath && (
              <Tooltip title="Receipt" arrow placement="top">
                <a
                  href={API_URL + item.receiptPath}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <i
                    className="uil-file-download"
                    style={{ fontSize: "1.5em", cursor: "pointer" }}
                  ></i>
                </a>
              </Tooltip>
            )}
          </div>
        )

        return item
      })

      setTableData(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleToggle = (show = false, item = {}) => {
    if (show) {
      setSelected({
        id: item._id || item.id,
        show,
        isCreditNote: item.isCreditNote,
      })
    } else {
      setSelected({ id: null, show, isCreditNote: item.isCreditNote })
    }
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Time", field: "time" },
      { label: "No.", field: "uniqueId" },
      { label: "For", field: "for" },
      { label: "Course/Event", field: "name" },
      { label: "Type", field: "type" },
      { label: "Amount", field: "amount" },
      { label: "Status", field: "status" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }
  return (
    <React.Fragment>
      <MDBDataTable
        id="studentStatement"
        bordered
        responsive
        sortable={false}
        searching={false}
        entries={20}
        paging={false}
        info={false}
        disableRetreatAfterSorting={true}
        data={data}
      ></MDBDataTable>
      <Modal
        show={selected.show}
        isOpen={selected.show}
        toggle={() => handleToggle(!selected.show, selected)}
        size="lg"
        centered={true}
        className="lead-modal"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Details</h5>
          <button
            type="button"
            onClick={() => handleToggle(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        {selected.isCreditNote ? (
          <CreditNoteDetails id={selected.id} />
        ) : (
          <PaymentDetails id={selected.id} />
        )}
      </Modal>
    </React.Fragment>
  )
}

export default Statement
