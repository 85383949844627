import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Table,
  CardTitle,
  Label,
  Row,
  Col,
  Button,
  Progress,
} from "reactstrap";
import "./completedStyle.scss";
import { AvForm } from "availity-reactstrap-validation";
import Select from "react-select";
import { MDBDataTable } from "mdbreact";
import { get } from "../../../../helpers/api_helper";
import moment from "moment";
import useBranch from "../../../../hooks/useBranch";
import TablePagination from "../../../../components/Common/Pagination";
import { useSelector } from "react-redux";

const ProgrammesUnderMobilisation = () => {
  const myCsp = useBranch()
  const [myCspId, setMyCspId] = useState("")
  const [UpcomingEvents,setUpcomingEvents] = useState([])
  const [events,setEvents] = useState([])
  const [selecttedEvents,setSelectedEvents] = useState(null)
  const [branches,setBranches] = useState([])
  const [selectedBranches,setSelectedBranches] = useState(null)
  const [category,setCategory] = useState([])
  const [selectedCategory,setSelectedCategory] = useState(null)
  const [state,setState] = useState(false)
  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 20
  const getDate = useSelector(state => state.Dashboard.data)

  useEffect(() => {
    let cspId = myCsp ? myCsp?.[0]?.value : ""
     setMyCspId(cspId)
  }, [])

  useEffect(()=>{
    const fetch = async()=>{
      try {
        const res = await get(`/events/events/options?eventStatus=${0}&csp=${selectedBranches?.value}&category=${selectedCategory?.value}&startDate=${getDate?.startDate}&endDate=${getDate?.endDate}`)
        setEvents(res?.data)
       } catch (error) {
        
       }
    }
    fetch()
  },[selectedBranches,selectedCategory,getDate])

  useEffect(()=>{
    const fetch = async()=>{
      try {
        const res = await get(`/events/csp/options?eventStatus=${0}&startDate=${getDate?.startDate}&endDate=${getDate?.endDate}`)
        setBranches(res?.data)
       } catch (error) {
        
       }
    }
    fetch()
  },[getDate])

  useEffect(()=>{
    const fetch = async()=>{
      try {
        const res = await get(`/events/category/options?eventStatus=${0}&csp=${selectedBranches?.value}&startDate=${getDate?.startDate}&endDate=${getDate?.endDate}`)
        setCategory(res?.data)
       } catch (error) {
        
       }
    }
    fetch()
  },[selectedBranches,getDate])

  useEffect(()=>{
    const fetch = async() =>{
      try {
        const res = await get(`events/dashboard/upcoming?page=${page}&limit=${limit}&csp=${selectedBranches?.value}&event=${selecttedEvents?.value}&category=${selectedCategory?.value}&startDate=${getDate?.startDate}&endDate=${getDate?.endDate}`)
        const data = res.data.map((item, index) => {
          return {
            ...item,
            id: index+1,
            csp: item?.cspDetails.map(e => e.name).join(', '),
            startDate:moment(item.startDate).format("DD-MM-YYYY"),
            endDate:moment(item.endDate).format("DD-MM-YYYY")
          };
        });
        setUpcomingEvents(data)
        const count = res?.count
        const totalPage = Math.ceil(Number(count) / limit)
        setTotalPage(totalPage)
      } catch (error) {
        
      }
    }
    fetch()
  },[selectedBranches,selecttedEvents,selectedCategory,state,page,getDate])

  const handleChange = (event, value) => {
    setPage(value)
  }

  const data = {
    columns: [
      { label: "#", field: "id", width: 10 },
      { label: "Event", field: "name", width: 150 },
      { label: "Category", field: "category", width: 150 },
      { label: "CSP", field: "csp", width: 150 },
      { label: "Candidate Registered", field: "totalRegistration", width: 150 },
      { label: "Leads", field: "leads", width: 150 },
      { label: "Start Date", field: "startDate", width: 150 },
      { label: "End Date", field: "endDate", width: 150 },
    ],
    rows:UpcomingEvents
  }

  const reset = () =>{
    setSelectedEvents(null)
    setSelectedBranches(null)
    setSelectedCategory(null)
    setState(!state)
  }
  
  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle className="h4 mb-4">Programmes Under Mobilisation</CardTitle>
              <AvForm>
                <Row>
                  {!myCspId && 
                <Col md="2">
                    <div className="mb-3">
                      <Label>CSP</Label>
                      <Select isClearable={true} 
                      options={branches} 
                      value={selectedBranches}
                       onChange={(item)=>setSelectedBranches(item)} 
                      />
                    </div>
                  </Col>}
                <Col md="2">
                    <div className="mb-3">
                      <Label>Category</Label>
                      <Select isClearable={true} 
                      options={category} 
                      value={selectedCategory} 
                      onChange={(item)=>setSelectedCategory(item)}
                      />
                      
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label>Event</Label>
                      <Select isClearable={true} 
                      options={events} 
                      value={selecttedEvents} 
                      onChange={(item)=>setSelectedEvents(item)}
                      />
                      
                    </div>
                  </Col>
                 
                 
                  <Col className="mt-4 pt-1">
                    <div className="mb-3">
                      <Button color="danger" 
                       type="reset"
                       onClick={reset}
                       >
                        Reset
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
              <MDBDataTable
                    id="eventCompletedId"
                    responsive
                    bordered
                    info={true}
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                    paginationLabel={false}
                    paging={false}
                  />
                  <TablePagination
                    page={page}
                    onChange={handleChange}
                    count={totalPage}
                  />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ProgrammesUnderMobilisation;
