import React, { useEffect, useState } from "react"
import {  Button, Col, Input, Label, Progress, Row } from "reactstrap"
import Select from "react-select"
import { MDBDataTable } from "mdbreact"
import "./styles.scss"
import { get, post } from "../../../helpers/api_helper"
import toastr from "toastr"
import moment from "moment"
import { isAllowed } from "../../../components/Common/Allowed"
import { PRIVILEGES } from "../../../config"
import { AvField, AvForm } from "availity-reactstrap-validation"

const Followup = ({ batchId, handleRefresh, assessmentType }) => {
  const [status, setStatus] = useState({ label: "Select", value: "" })
  const [remarks, setRemarks] = useState("")
  const [mode, setMode] = useState("")
  const [contact, setContact] = useState("")
  const [assessor, setAssessor] = useState("")
  const [batch_Id] = useState(batchId)
  const [latestStatus, setLatestStatus] = useState(null)
  const [date, setDate] = useState(null)
  const [startTime, setStartTime] = useState('')
  const [endTime,setEndTime] = useState('')
  const [email, setEmail] = useState("")
  const [disableButton, setDisableButton] = useState(false)
  const [refresh, setRefresh] = useState(1)

  const [followUpData, setFollowUpData] = useState({})
  const [followupTable, setFollowUpTable] = useState([])

  const [statusList, setStatusList] = useState([])

  const isCspPm = isAllowed([
    PRIVILEGES.ADMIN,
    PRIVILEGES.CSP_PROGRAM_MANAGER,
  ])

  const isAssessmentPm = isAllowed([
    PRIVILEGES.ADMIN,
    PRIVILEGES.ASSESSMENT_PM,
  ])

  const fetchData = () => {
    get(`assessment/singleListBranch?id=${batch_Id}`).then(res => {
      if (res.success) {
        setFollowUpData({
          ...res.data[0],
          assessmentDate: res.data[0].assessmentDate
            ? moment(res.data[0].assessmentDate).format("DD-MM-YYYY")
            : "",
          progress: res.data[0].sessions
            ? (res.data[0].sessionsCompleted / res.data[0].sessions) * 100
            : "0%",
        })
      }
    })
  }

  const fetchFollowUp = () => {
    setDisableButton(true)
    get(`assessment/list-followup?id=${batch_Id}`).then(res => {
      
      if (res.success) {
        const data = res.assessments
        setLatestStatus(res.assessments[0]?.type || null)
        setFollowUpTable(
          data.map((item, index) => ({
            ...item,
            id: index + 1,
            date: item.date ? moment(item.date).format("DD-MM-YYYY") : "",
            time: moment(item.time, "HH:mm:ss").format("hh:mm A"),
            status:
            item.type === 0 ? (
              <span  style={{
                width:'150px',
                padding: "0.3rem",
              }} className="badge bg-dark font-size-12 ms-2">
                Assessment Cancelled
              </span>
            ) : item.type === 1 ? (
                <span  style={{
                  width:'150px',
                  padding: "0.3rem",
                  backgroundColor:'#a075bf'
                }} className="badge font-size-12 ms-2">
                  Assessment Requested
                </span>
              ) : item.type === 2 ? (
                <span
                  style={{
                    width: "150px",
                    padding: "0.3rem",
                  }}
                  className="badge bg-warning font-size-12 ms-2"
                >
                  Assessment Scheduled 
                </span>
              ) : item.type === 3 ? (
                <span
                  style={{
                    width: "150px",
                    padding: "0.3rem",
                  }}
                  className="badge font-size-12 ms-2 bg-danger"
                >
                  Date Change Requested
                </span>
              ) : item.type === 4 ? (
                <span
                  style={{
                    width: "150px",
                    padding: "0.3rem",
                  }}
                  className="badge bg-info font-size-12 ms-2"
                >
                  Assessment Confirmed
                </span>
              ) : item.type === 5 ? (

                  <span
                    style={{
                      width: "150px",
                      padding: "0.3rem",
                    }}
                    className="badge bg-success font-size-12 ms-2"
                  >
                    Assessment Completed
                  </span>
              ) : item.type === 6 ? (
                <span
                  style={{
                    width: "150px",
                    padding: "0.3rem",
                  }}
                  className=" font-size-12 ms-2"
                >
                  Attendance Marked
                </span>
              ) : item.type === 7 ? (
                <span
                  style={{
                    width: "150px",
                    padding: "0.3rem",
                  }}
                  className=" font-size-12 ms-2"
                >
                  Grades Marked
                </span>
              ) : item.type === 8 ? (
                <span
                  style={{
                    width: "150px",
                    padding: "0.3rem",
                  }}
                  className=" font-size-12 ms-2"
                >
                  Certificate Published
                </span>
              ): (
                <span
                  style={{
                    width: "115px",
                    padding: "0.3rem",
                  }}
                  className=" font-size-12 ms-2 bg-secondary"
                >
                  Pending
                </span>
              ),
          }))
        )
      }
    })
    setDisableButton(false)
  }

  const validateEmail = () => {
    const regex = /^[^\s@]+@[^\s@]+\.(com|in|org|net|edu|gov)$/i
    const test = regex.test(email)
    if (!test) {
      toastr.error("Enter a valid email.")
    } else {
      return true
    }
  }

  const validateMobile = () => {
    const cleanedMobileNumber = contact.replace(/\D/g, "")
    const isValidLength = cleanedMobileNumber.length === 10
    const hasOnlyDigits = /^\d+$/.test(cleanedMobileNumber)

    if (!isValidLength || !hasOnlyDigits) {
      toastr.error("Enter a valid mobile number.")
      return false
    } else {
      return true
    }
  }

  const handleSubmit = () => {
    setDisableButton(true)
    if (status.value === 2 || status.value===3) {
      if (
        mode === "" ||
        assessor === "" ||
        batch_Id === undefined ||
        contact === "" ||
        email === ""
      ) {
        toastr.error("Enter all fields")
        return
      }
      if (!validateEmail() && !validateMobile()) {
        return
      }
    }else if (status.value === 1 || status.value===3 || status.value === -4) {
      if (date === null || startTime === "" || endTime === "") {
        toastr.error("Enter date, start time and end time.")
        return
      } else if (endTime < startTime) {
        toastr.error("Invalid time.")
        return
      }
    }
    const postBody = {
      remarks: remarks,
      mode: mode.value,
      type: status.value,
      assessorNumber: contact,
      assessorName: assessor,
      batch: batch_Id,
      assessmentDate: date || null,
      assessorEmail: email,
      assessmentStartTime: startTime || "",
      assessmentEndTime: endTime || "",
    }
    post(`assessment/add-followup`, postBody).then(res => {
      if (res.success) {
        toastr.success("Follow Up Updated")
        setRefresh(prev => prev + 1)
        handleRefresh()
        handleDiscard()
      }
    }).catch(error => {toastr.error(error.response.data.message)})
    setDisableButton(false)
  }

  const handleDiscard = () => {
    setStatus({ label: "Select", value: "" })
    setContact("")
    setAssessor("")
    setDate(null)
    setRemarks("")
    setMode("")
    setEmail("")
    setStartTime('')
    setEndTime('')
  }

  const staticStatusList = [
    {label:'Request Assessment',value:1},
    {label:'Schedule Assessment',value:2},
    {label:'Request Date Change',value:3},
    {label:'Confirm Assessment',value:4},
    {label:'Cancel Assessment',value:0},
    {label:'Reschedule Assessment',value:-4},
  ]

  const handleStatusList = () => {
    if (assessmentType === 1) {
      if (latestStatus === null || latestStatus === undefined || latestStatus === 0) {
        setStatusList(staticStatusList.filter(item => item.value === 1))
      } else if (latestStatus === 1) {
        setStatusList(staticStatusList.filter(item => item.value === 3 || item.value === 2))
      } else if (latestStatus === 2) {
        setStatusList(staticStatusList.filter(item => item.value === 4))
      } else if (latestStatus === 3) {
        setStatusList(staticStatusList.filter(item => item.value === 1 || item.value === 4))
      } else if (latestStatus === 4) {
        setStatusList(staticStatusList.filter(item => item.value === 0 || item.value === -4))
      }
    } 
    if (assessmentType === 2) {
      if(latestStatus === null || latestStatus === undefined  || latestStatus === 0) {
      setStatusList(staticStatusList.filter(item => item.value === 4))
      } else if (latestStatus === 4) {
        setStatusList(staticStatusList.filter(item => item.value === 0 || item.value === -4))
      }
    } 
  }

  const checkPrivilege = () => {
    if ((latestStatus === null || latestStatus === undefined || latestStatus === 0) && isCspPm) {
      return true
    } else if (latestStatus === 1 && isAssessmentPm) {
      return true
    } else if ((latestStatus === 2 || latestStatus ===3 ) && isCspPm) {
      return true
    } else if (latestStatus === 4 && isCspPm) {
      return true
    }
  } 

  useEffect(() => {
    if (batch_Id) {
      fetchFollowUp()
      fetchData()
    }
    // eslint-disable-next-line
  }, [batch_Id, refresh])

  useEffect(()=>{
    handleStatusList()
  },[latestStatus])

  const columns = [
    {
      label: "#",
      field: "id",
      width: 50,
    },
    {
      label: "Date",
      field: "date",
      width: 50,
    },
    {
      label: "Time",
      field: "time",
      width: 50,
    },
    {
      label: "Status",
      field: "status",
      width: 50,
    },
    {
      label: "Comments",
      field: "remarks",
      width: 50,
    },
    {
      label: "Added By",
      field: "addedBy",
      width: 50,
    },
  ]
  
  const allowedStatus = (values) => {
    if(values.includes(status?.value)){
      return true
    } else {
      return false
    }
  }

  const data = { columns, rows: followupTable }
  return (
    <>
      <div style={{ margin: "0 18px 18px 18px" }}>
        <Row>
          <Col md="12">
            <div style={{ overflowX: "auto" }}>
              <table className="table tbl-u-boarderd" id="followupmodalId">
                <tbody>
                  <tr>
                    <th>Batch</th>
                    <th>:</th>
                    <td style={{ textAlign: "left" }}>
                      {followUpData.batchId}
                    </td>

                    <th>CSP</th>
                    <th>:</th>
                    <td style={{ textAlign: "left" }}>
                      {followUpData.branch?.name}
                    </td>

                    <th>#Students</th>
                    <th>:</th>
                    <td style={{ textAlign: "left" }}>
                      {followUpData.students}
                    </td>
                  </tr>

                  <tr>
                    <th>Course Progress</th>
                    <th>:</th>
                    <td>
                      <Progress
                        min={30}
                        value={followUpData.progress}
                        style={{ height: "20px", fontSize: ".8rem" }}
                      >
                        <span className="progress-label">
                          {followUpData.progress > 0
                            ? followUpData.progress.toFixed(0)
                            : 0}
                          %
                        </span>
                      </Progress>
                    </td>
                    {assessmentType && (
                      <>
                        <th>Assessment Type</th>
                        <th>:</th>
                        <td style={{ textAlign: "left" }}>
                          {assessmentType === 2 ? 'TP' : assessmentType === 1? 'ASAP' : ''}
                        </td>
                      </>
                    )}
                    {followUpData?.assessmentDate && (
                      <>
                        <th>Assessment Date</th>
                        <th>:</th>
                        <td style={{ textAlign: "left" }}>
                          {followUpData?.assessmentDate}
                        </td>
                      </>
                    )}   
                  </tr>
                  <tr>
                  {followUpData?.assessmentStartTime && (
                      <>
                        <th>Start Time</th>
                        <th>:</th>
                        <td style={{ textAlign: "left" }}>
                          {moment(followUpData?.assessmentStartTime, 'HH:mm').format('hh:mm A')}
                        </td>
                      </>
                    )}
                  {followUpData?.assessmentEndTime && (
                      <>
                        <th>End Time</th>
                        <th>:</th>
                        <td style={{ textAlign: "left" }}>
                          {moment(followUpData?.assessmentEndTime, 'HH:mm').format('hh:mm A')}
                        </td>
                      </>
                    )}
                  {followUpData.assessmentMode && (
                      <>
                        <th>Mode</th>
                        <th>:</th>
                        <td style={{ textAlign: "left" }}>
                          {followUpData.assessmentMode}
                        </td>
                      </>
                    )}
                    
                  </tr>
                  <tr>
                  {followUpData.assessorName && (
                      <>
                        <th>Assessor</th>
                        <th>:</th>
                        <td style={{ textAlign: "left" }}>
                          {followUpData.assessorName}
                        </td>
                      </>
                    )}
                  {followUpData?.assessorEmail && (
                      <>
                        <th>Assessor Email</th>
                        <th>:</th>
                        <td style={{ textAlign: "left" }}>
                          {followUpData?.assessorEmail}
                        </td>
                      </>
                    )}
                    {followUpData?.assessorNumber && (
                      <>
                        <th>Assessor Number</th>
                        <th>:</th>
                        <td style={{ textAlign: "left" }}>
                          {followUpData?.assessorNumber}
                        </td>
                      </>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
            {followUpData.sessions === followUpData.sessionsCompleted &&
              latestStatus < 5 &&
              checkPrivilege() &&
              followUpData.sessionsCompleted !== 0 && (
                
                  <AvForm >
                    <Row style={{ display: "flex", justifyContent: "space-evenly" }}>
                  <Col xl="2" md="4">
                    <Label>Status</Label>
                    <Select
                      name="status"
                      classNamePrefix="select2-selection"
                      options={statusList}
                      value={status}
                      onChange={e => {setStatus(e)}}
                    />
                  </Col>

                  {(allowedStatus([1,3,-4]) || (assessmentType === 2 && status.value === 4)) && (
                    <Col xl="2" md="4">
                      <Label>Assessment Date</Label>
                      <AvField
                        name='date'
                        type="date"
                        className="form-control"
                        // validate={{ required: { value: true } }}
                        // min={new Date()}
                        value={date}
                        onChange={e => setDate(e.target.value)}
                      />
                    </Col>
                  )}
                  {(allowedStatus([1,3,-4]) || (assessmentType === 2 && status.value === 4)) && (
                    <Col xl="2" md="4">
                      <Label>Start Time</Label>
                      <AvField
                        name='startTime'
                        type="time"
                        className="form-control"
                        // validate={{ required: { value: true } }}
                        // min={new Date()}
                        value={startTime}
                        onChange={e => setStartTime(e.target.value)}
                      />
                    </Col>
                  )}
                  {(allowedStatus([1,3,-4]) || (assessmentType === 2 && status.value === 4)) && (
                    <Col xl="2" md="4">
                      <Label>End Time</Label>
                      <AvField
                        name='endTime'
                        type="time"
                        className="form-control"
                        // validate={{ required: { value: true } }}
                        // min={new Date()}
                        value={endTime}
                        onChange={e => setEndTime(e.target.value)}
                      />
                    </Col>
                  )}
                  {(allowedStatus([2,3]) || (assessmentType === 2 && status.value === 4)) && (
                    <Col xl="2" md="4">
                      <Label>Mode</Label>
                      <Select
                        name="mode"
                        classNamePrefix="select2-selection"
                        options={[
                          { label: "Online", value: "Online" },
                          { label: "Offline", value: "Offline" },
                        ]}
                        onChange={item => setMode(item)}
                        value={mode}
                      />
                    </Col>
                  )}
                  {(allowedStatus([2,3]) || (assessmentType === 2 && status.value === 4)) && (
                    <Col xl="2" md="4">
                      <Label style={{ whiteSpace: "nowrap" }}>
                        Assessor Name
                      </Label>
                      <Input
                        name="assessor"
                        type="text"
                        style={{ height: "38px" }}
                        placeholder="Enter Name"
                        value={assessor}
                        onChange={e => setAssessor(e.target.value)}
                      />
                    </Col>
                  )}
                  {(allowedStatus([2,3]) || (assessmentType === 2 && status.value === 4)) && (
                    <Col xl="2" md="4">
                      <Label style={{ whiteSpace: "nowrap" }}>
                        Assessor Contact
                      </Label>
                      <Input
                        name="contact"
                        type="text"
                        style={{ height: "38px" }}
                        placeholder="Enter Contact No."
                        value={contact}
                        onChange={e => setContact(e.target.value)}
                      />
                    </Col>
                  )}
                  {(allowedStatus([2,3]) || (assessmentType === 2 && status.value === 4)) && (
                    <Col xl="2" md="4">
                      <Label style={{ whiteSpace: "nowrap" }}>
                        Assessor Email
                      </Label>
                      <Input
                        name="email"
                        type="text"
                        style={{ height: "38px" }}
                        placeholder="Enter Email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                      />
                    </Col>
                  )}
                  <Col xl="2" md="4">
                    <Label style={{ whiteSpace: "nowrap" }}>Comments</Label>
                    <Input
                      name="remarks"
                      type="text"
                      style={{ height: "38px" }}
                      placeholder="Comments..."
                      value={remarks}
                      onChange={e => setRemarks(e.target.value)}
                    />
                  </Col>

                  <Col
                    xl="2"
                    md="4"
                    style={{ display: "flex", marginTop: "28px" }}
                  >
                    <Button
                      type="submit"
                      color="primary"
                      style={{ marginRight: "10px",height:'40px' }}
                      disabled={
                        status.value === "" || disableButton
                      }
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                    <Button style={{height:'40px'}} color="danger" onClick={handleDiscard}>
                      Discard
                    </Button>
                  </Col>
                  </Row>
                  </AvForm>
                
              )}
            <Row style={{ marginTop: "28px" }}>
              <Col md="12">
                <MDBDataTable
                  id="followupTable"
                  responsive
                  bordered
                  data={data}
                  searching={false}
                  info={false}
                  sortable={false}
                  paging={false}
                  disableRetreatAfterSorting={true}
                  entries={20}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Followup
