import React, { useEffect, useState } from "react"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, Label, Button } from "reactstrap"
import Select from "react-select"
import { AvField, AvForm } from "availity-reactstrap-validation"
import moment from "moment"
import { isArray } from "lodash"
import { useNavigate } from "react-router-dom"
import { get } from "../../helpers/api_helper"
import {
  getStudent,
  getCourses,
  getSectors,
  getCluster,
} from "../../store/options/actions"
import { useSelector, useDispatch } from "react-redux"
const Students = () => {
  // eslint-disable-next-line
  const dispatch = useDispatch()

  const { cluster, students, sectors, courses } = useSelector(
    state => state.Options
  )

  useEffect(() => {
    // dispatch(getSectors())
    dispatch(getStudent())
    dispatch(getCourses())
    dispatch(getCluster())
    // eslint-disable-next-line
  }, [])
  const initialState = {
    fromDate: "",
    toDate: "",
    
    purpose:"",
  }
  const [master, setMaster] = useState({})
  const [selected, setSelected] = useState({ status: "" })
  const [tabledetails, setTableDetails] = useState([])
  const [batchesOptions, setBatchesOptions] = useState([])
  const [filterObject, setFilterObject] = useState(initialState)

  const navigate = useNavigate()
  useEffect(() => {
    handleTableData()
  }, [filterObject])

  useEffect(() => {
    handleBatchOptions()
  }, [])

  const handleBatchOptions = async () => {
    get("/options/batches").then(res => {
      var event_data = res.data.map(item => {
        return {
          label: item?.label,
          value: item?._id,
        }
      })
      setBatchesOptions(event_data)
    })
  }
  const handleValueChange = async (selected, name) => {
    const obj = { ...filterObject }
    obj[name] = selected.value
    if (name === "cluster") {
      dispatch(getSectors(selected.sector))
    }
    setFilterObject(obj)
  }
  function handleTableData() {
    const filterQuery = `startDate=${filterObject.fromDate}&endDate=${filterObject.toDate}&purpose=${filterObject.purpose}`
    get(`visit-log/list?${filterQuery}`,).then(res => {
      const result = res.data
  
      result.map((item, index) => {
        item.id = index + 1
        item.date = moment(item.date).format("DD-MM-YYYY")
        item.time = moment(item.time, "HH:mm:ss").format("hh:mm a")
        // item.addedby = item?.addedBy?.firstName
        // item.course = item?.course?.name
        // item.csp = item?.branch?.name
        // item.batch = item?.batch?.name
        
        // item.option = (
        //   <div>
        //     <i
        //       className="fas fa-eye"
        //       onClick={() => {
        //         navigate(`/student-details/${item.profile.unique_id}`, {
        //           state: { id: item.profile._id },
        //         })
        //       }}
        //       style={{
        //         fontSize: "1.1em",
        //         cursor: "pointer",
        //         marginLeft: "0.5rem",
        //         marginRight: "0.5rem",
        //       }}
        //     />
        //   </div>
        // )
        return item
      })
      setTableDetails(result)
    }) 
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 200,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 100,
      },
      // {
      //   label: "CSP",
      //   field: "csp",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 200,
      }, 
      { 
        label: "Mobile",
        field: "phone",
        sort: "asc",
        width: 200,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 100,
      },
      {
        label:"Purpose",
        field: "purpose",
        sort: "asc",
        width: 100,
      },
      {
        label:"Remarks",
        field: "remarks",
        sort: "asc",
        width: 100,
      },
    
      // {
      //   label: "Status",
      //   field: "status",
      //   sort: "disabled",
      //   width: 100,
      // },
    ],
    rows: tabledetails,
  }
  const handleSelect = (e, name) => {
    const value = isArray(e) ? e.map(el => el.value) : e.value

    setSelected(pre => ({ ...pre, [name]: e }))
    setFilterObject(pre => ({ ...pre, [name]: value }))
  }
  // console.log(filterObject,"filterobjrct");
  const reset = () => {
    setMaster({})
    setSelected({ status: null ,purpose: null})
    setFilterObject(initialState)
  }
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem="Visit-Log" />
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md="2">
                        <Label>From Date</Label>
                        <AvField
                          name="fromDate"
                          type="date"
                          className="form-control"
                          placeholder="Last Name"
                          // value={moment().format("YYYY-MM-DD")}
                          value={filterObject.fromDate}
                          onChange={e => {
                            handleValueChange(e.target, "fromDate")
                          }}
                          // validate={{
                          //   required: {
                          //     value: true,
                          //     errorMessage: "Enter From Date",
                          //   },
                          // }}
                        />
                      </Col>
                      <Col md="2">
                        <Label>To Date</Label>
                        <AvField
                          name="toDate"
                          type="date"
                          value={filterObject.toDate}
                          onChange={e => {
                            handleValueChange(e.target, "toDate")
                          }} 
                          className="form-control"
                        />
                      </Col>
                      <Col md="2">
                        <Label>Purpose</Label>
                        <Select
                          name="status"
                          onChange={e => handleSelect(e, "purpose")}
                          // placeholder='Select'
                          value={selected.purpose}
                          // placeholder='Select'
                          options={[
                            { label: "Enquiry", value: 0 },
                            { label: "Officials", value: 1 },
                            { label: "Maintenance", value: 2 },
                            { label: "Others", value: 3 },
                          ]}
                          classNamePrefix="select2-selection"
                          menuPosition="auto"
                        />
                      </Col>

                      <Col md="3">
                        <div className="mt-4 pt-1">
                          {/* <Button
                            className="mx-2"
                            color="primary"
                            type="submit"
                          >
                            Filter
                          </Button> */}

                          <Button
                            onClick={reset}
                            className="mx-2"
                            color="danger"
                            type="reset"
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="visitlogID"
                    paging={false}
                    responsive
                    bordered
                    disableRetreatAfterSorting={true}
                    data={data}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default Students
