import React from 'react'
import { AvForm } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { MDBDataTable } from 'mdbreact';
import Select from "react-select";


function StudentReport() {

    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                width: 150,
            },
            {
                label: "Student",
                field: "student",
                width: 150,
            },
            {
                label: "Email",
                field: "email",
                width: 150,
            },
            {
                label: "Number",
                field: "number",
                width: 150,
            },
            {
                label: "Course",
                field: "course",
                width: 150,
            },
            {
                label: "Year",
                field: "year",
                sort: "desc",
                width: 400,
            },

        ],
        rows: [
            {
                id: "1",
                student: "Sreedarsh",
                email: "sreedarsh@gamil.com",
                number: "8714061742",
                course: "Diploma in Professional Accounting",
                year: "2024"
            },
            {
                id: "2",
                student: "Shammas",
                email: "shammas@gamil.com",
                number: "9847975633",
                course: "General Duty Assistant",
                year: "2024"
            },
            {
                id: "3",
                student: "Anuranj",
                email: "anuranj@gamil.com",
                number: "9605378474",
                course: "Texturing and LookDev",
                year: "2024"
            },
            {
                id: "4",
                student: "Jamsheer",
                email: "jamsheer@gamil.com",
                number: "79024802997",
                course: "Automobile Technician",
                year: "2024"
            },
        ],
    };

    return (
        <>
            <div className='page-content'>
                <div className='container-fluid'>
                    <Breadcrumbs title="Home" breadcrumbItem="Student Report" />


                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <AvForm>
                                        <Row>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Course</Label>
                                                    <Select
                                                        name="category"
                                                        classNamePrefix="select2-selection"
                                                        type="text"
                                                        options={[
                                                            { label: "Diploma in Professional Accounting", value: "Banking And Finance" },
                                                            { label: "Certified Program in EV Design, Simulation and Component Selection", value: "Electric Vehicle" },
                                                            { label: "General Duty Assistant", value: "Healthcare" },
                                                            { label: "Texturing and LookDev", value: "Media And Entertainment" },
                                                            { label: "Automobile Technician" }
                                                        ]}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Student</Label>
                                                    <Select
                                                        name="category"
                                                        classNamePrefix="select2-selection"
                                                        type="text"
                                                        options={[
                                                            { label: "Sreedarsh", value: "Banking And Finance" },
                                                            { label: "Shammas", value: "Electric Vehicle" },
                                                            { label: "Jamsheer", value: "Healthcare" },
                                                            { label: "Anuranj", value: "Media And Entertainment" },
                                                            { label: "Zenhall" }
                                                        ]}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="2">
                                                <div className="mb-3">
                                                    <Label >From Date</Label>
                                                    <input
                                                        name="from_date"
                                                        className="form-control"
                                                        type="date"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="2">
                                                <div className="mb-3">
                                                    <Label >To Date</Label>
                                                    <input
                                                        name="to_date"
                                                        className="form-control"
                                                        type="date"
                                                    />
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="mb-3" style={{ paddingTop: "25px" }}>
                                                    <Button color="danger" type="submit">
                                                        Reset
                                                    </Button>

                                                    <Button color="success" type="submit" style={{ marginLeft: "7px"}}>
                                                        Export
                                                    </Button>

                                                </div>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>


                    <Row>
                        <Col className='col-12'>
                            <Card>
                                <CardBody>


                                    <MDBDataTable
                                        id="Managetableid1"
                                        bordered
                                        responsive
                                        searching={true}
                                        entries={20}
                                        disableRetreatAfterSorting={true}
                                        data={data}

                                    >

                                    </MDBDataTable>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>



                </div>
            </div>
        </>
    )
}

export default StudentReport;