import React, { useEffect, useRef, useState } from "react"
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import { get } from "../../../helpers/api_helper"
import Breadcrumb from "../../../components/Common/Breadcrumb"

import "./style.scss"
import {
  dateConverter,
  numberToCurrency,
  setTitle,
} from "../../../helpers/functions"
import CreditFollowup from "../CreditPayments/followup"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import queryString from "query-string"
import useBranch from "../../../hooks/useBranch"

import TablePagination from "../../../components/Common/Pagination"

const CreditPayments = ({ options }) => {
  setTitle("Credit Transactions")

  const filterRef = useRef()
  const [alreadyBranch] = useBranch()

  const statusValues = {
    0: { color: "success", title: "Approved" },
    2: { color: "danger", title: "Cancelled" },
    3: { color: "warning", title: "Pending" },
    approved: 0,
    cancelled: 2,
    pending: 3,
  }

  const types = { 1: "Income", 2: "Expense", Income: 1, Expense: 2 }
  const filterStatusOptions = [
    { label: "Approved", value: statusValues.approved },
    { label: "Cancelled", value: statusValues.cancelled },
    { label: "Pending", value: statusValues.pending },
  ]
  const typeOptions = [
    { label: "Income", value: types.Income },
    { label: "Expense", value: types.Expense },
  ]

  const [tableData, setTableData] = useState([])

  const [filterObject, setFilterObject] = useState({ paymentType: 1 })
  const [filterSelectObject, setFilterSelectObject] = useState({})

  const [filterBranchOptions, setFilterBranchOptions] = useState([])
  const [filterSubHeadOptions, setFilterSubHeadOptions] = useState([])

  const [selectedId, setSelectedId] = useState(null)
  const [isOpen, setIsOpen] = useState(false)

  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 20

  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [filterObject])

  useEffect(() => {
    handleFilterBranchOptions()
    handleFilterSubHeadOptions()
    // eslint-disable-next-line
  }, [filterObject])

  const handleTableData = async (perPage = page) => {
    try {
      const params = `page=${perPage}&limit=${limit}&${queryString.stringify(
        filterObject
      )}`
      const response = await get(`/credit-income-expense/credit/list?${params}`)
      const data = response.data
      const count = response.count

      data.map((item, index) => {
        item.id = index + 1 + (perPage - 1) * limit
        item.date = dateConverter(item.date)
        item.type = types[item.type]
        item.branch = item?.branch?.name
        item.subHead = item?.subHead?.name
        item.chartOfAccount = item?.chartOfAccount?.name
        item.pending = Number(item.amount || 0) - Number(item.received || 0)

        item.pendingText = numberToCurrency(item.pending)
        item.amountText = numberToCurrency(item.amount)
        item.receivedText = numberToCurrency(item.received)

        const status = Number(item.status)
        const isPending = status === 3

        if (isPending) {
          item.options = (
            <i
              className="fas fa-money-check"
              onClick={() => handleCreditEdit(item._id)}
              style={{
                cursor: "pointer",
                fontSize: "1em",
                marginRight: "0.5rem",
              }}
            ></i>
          )
        } else {
          item.options = (
            <Button color={statusValues[status]?.color} size="sm" disabled>
              {statusValues[status]?.title}
            </Button>
          )
        }
        return item
      })
      setTableData(data)
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
    } catch (error) {}
  }

  const handleFilterBranchOptions = async (obj = filterObject) => {
    try {
      const params = `${queryString.stringify(obj)}`
      const response = await get(
        `credit-income-expense/filter/options/branches?${params}`
      )
      setFilterBranchOptions(response.data)
    } catch (error) {}
  }

  const handleFilterSubHeadOptions = async (obj = filterObject) => {
    try {
      const params = `${queryString.stringify(obj)}`
      const response = await get(
        `credit-income-expense/filter/options/sub-head?${params}`
      )
      setFilterSubHeadOptions(response.data)
    } catch (error) {}
  }

  const handleFilterSelectValueChange = (selected, name) => {
    const obj = { ...filterSelectObject }
    obj[name] = selected
    setFilterSelectObject(obj)
    handleFilterValueChange({ value: selected.value, name })
  }

  const handleFilterValueChange = ({ value, name }) => {
    const obj = { ...filterObject }
    obj[name] = value
    setFilterObject(obj)
    setPage(1)
  }

  const handleCreditEdit = async id => {
    setIsOpen(true)
    setSelectedId(id)
  }

  const handleModalClose = () => {
    setIsOpen(false)
    setSelectedId(null)
    handleTableData()
  }
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }
  const reset = () => {
    filterRef.current?.reset()
    toTop()

    setFilterObject({ paymentType: 1 })
    setFilterSelectObject({})
  }
  const handleChange = (event, value) => {
    setPage(value)
    handleTableData(value)
  }
  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "CSP", field: "branch" },
      { label: "Head", field: "type" },
      { label: "Sub Head", field: "subHead" },
      { label: "Chart Of Account", field: "chartOfAccount" },
      { label: "Amount", field: "amountText" },
      { label: "Received", field: "receivedText" },
      { label: "Pending", field: "pendingText" },
      { label: "Action", field: "options" },
    ],
    rows: tableData,
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumb title="Home" breadcrumbItem="Credit Transactions" />
        <Card>
          <CardBody>
            <AvForm ref={filterRef}>
              <Row>
                <Col md={2}>
                  <div className="mb-3">
                    <Label>From</Label>
                    <AvField
                      name="from"
                      className="form-control"
                      type="date"
                      value={filterObject.form}
                      onChange={e => handleFilterValueChange(e.target)}
                    />
                  </div>
                </Col>
                <Col md={2}>
                  <div className="mb-3">
                    <Label>To</Label>
                    <AvField
                      name="to"
                      className="form-control"
                      type="date"
                      value={filterObject.to}
                      onChange={e => handleFilterValueChange(e.target)}
                    />
                  </div>
                </Col>
                {alreadyBranch ? (
                  ""
                ) : (
                  <Col md={2}>
                    <Label>CSP</Label>
                    <Select
                      name="branch"
                      options={filterBranchOptions}
                      value={filterSelectObject.branch || ""}
                      onChange={selected =>
                        handleFilterSelectValueChange(selected, "branch")
                      }
                    ></Select>
                  </Col>
                )}
                <Col md={2}>
                  <Label>Head</Label>
                  <Select
                    name="type"
                    options={typeOptions}
                    value={filterSelectObject.type || ""}
                    onChange={selected =>
                      handleFilterSelectValueChange(selected, "type")
                    }
                  ></Select>
                </Col>
                <Col md={2}>
                  <Label>Sub Head</Label>
                  <Select
                    name="subHead"
                    options={filterSubHeadOptions}
                    value={filterSelectObject.subHead || ""}
                    onChange={selected =>
                      handleFilterSelectValueChange(selected, "subHead")
                    }
                  ></Select>
                </Col>
                <Col md={2}>
                  <Label>Status</Label>
                  <Select
                    name="status"
                    options={filterStatusOptions}
                    value={filterSelectObject.status || ""}
                    onChange={selected =>
                      handleFilterSelectValueChange(selected, "status")
                    }
                  ></Select>
                </Col>
                <Col md="1">
                  <Button
                    color="danger"
                    style={{ margin: alreadyBranch ? "26px 0" : "0 0 26px 0" }}
                    onClick={reset}
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
            </AvForm>
            <MDBDataTable
              id="creditsTable"
              responsive
              bordered
              searching={false}
              entries={20}
              paging={false}
              info={false}
              sortable={false}
              disableRetreatAfterSorting={true}
              data={data}
            ></MDBDataTable>
            {totalPage > 1 && (
              <TablePagination
                page={page}
                onChange={handleChange}
                count={totalPage}
              />
            )}
          </CardBody>
        </Card>
      </div>
      <CreditFollowup
        id={selectedId}
        handleModalClose={handleModalClose}
        isOpen={isOpen}
      />
    </div>
  )
}

export default CreditPayments
