import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"

import { MDBDataTable } from "mdbreact"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"

import $ from "jquery"
import toastr from "toastr"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"

import { get, post } from "../../../../helpers/api_helper"

import "./style.scss"
import ReportDetails from "../reportDetails"
import useBranch from "../../../../hooks/useBranch"
import { getBranches } from "../../../../store/options/actions"
import SessionHistory from "../SessionHistory"
import { minutesToHour, setTitle } from "../../../../helpers/functions"
import { PRIVILEGES } from "../../../../config"
import useMenuAllowed from "../../../../hooks/useMenuAllowed"
import TablePagination from "../../../../components/Common/Pagination"

const TrainerWorkReport = props => {
  setTitle("Trainer Work Report")
  useMenuAllowed(PRIVILEGES.FINANCE_PROGRAMME_MANAGER)

  const formRef = useRef()
  const dispatch = useDispatch()

  const [month, setMonth] = useState(null)
  const [selectedReport, setSelectedReport] = useState({
    trainer: null,
    batch: null,
  })

  const [reportData, setReportData] = useState([])
  const [selectedReports, setSelectedReports] = useState([])
  const [tableData, setTableData] = useState([])
  const [branch, setBranch] = useState(...useBranch())
  const [alreadyBranch] = useBranch()
  const { branches } = useSelector(state => state.Options)

  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 20
  
  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [page])

  useEffect(() => {
    if (!branch) {
      dispatch(getBranches())
    }
    // eslint-disable-next-line
  }, [])
  const handleMonthChange = value => {
    setMonth(value)
    setReportData([])
    setSelectedReports([])
  }
  const handleAllCheckboxChange = e => {
    for (let i = 0; i < reportData.length; i++) {
      handleCheck(e.target.checked, reportData[i])
    }
    if (e.target.checked === true) {
      $("input[type=checkbox]").prop("checked", true)
    } else {
      $("input[type=checkbox]").prop("checked", false)
    }
  }

  const handleCheck = (checked, item) => {
    if (checked) {
      setSelectedReports(prev =>
        prev.concat({
          trainer: item.trainer,
          batch: item.batchId,
        })
      )
    } else
      setSelectedReports(prev =>
        prev.filter(report => report.trainer !== item._id)
      )
  }

  const handleCalculate = async () => {
    if (!branch || !branch?.value) return toastr.error("Branch required")

    try {
      const response = await get(
        `attendance/report-history?month=${month}&branch=${branch?.value}`
      )
      const data = response.data
      if (data && Array.isArray(data)) {
        data.map((item, index) => {
          item.trainerName =
            (item?.trainerFirstName ? item.trainerFirstName : "") +
            " " +
            (item?.trainerLastName ? item.trainerLastName : "")

          item.hoursInLetters = minutesToHour(item.minutes)
          item.checked = false
          item.checkbox = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <input
                type="checkbox"
                id={`tableCheckbox${index}`}
                name={`checkbox${index}`}
                style={{ cursor: "pointer" }}
                onChange={e => handleCheck(e.target.checked, item)}
              />
            </div>
          )
          item.options = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className={"fas fa-eye"}
                onClick={() => handleTableModalToggle(true, item)}
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
              ></i>
            </div>
          )
          return item
        })
        setReportData(data)
      }
    } catch (err) {
      toastr.error(err.response.data.message)
      setReportData([])
    }
  }

  const handleTableData = () => {
    get(`attendance/report-list?page=${page}&limit=${limit}`).then(res => {
      const data = res.data
      data.map((item, index) => {
        // item.id = index + 1
        item.id = index + 1 + (page - 1) * limit
        item.date = moment(item.date).format("DD-MM-YYYY")
        item.time = moment(item.time, "HH:mm:ss").format("hh:mm a")
        item.month = moment(item.month, "YYYY-MM").format("MMM YYYY")

        item.name =
          (item?.trainerFirstName ? item.trainerFirstName : "") +
          " " +
          (item?.trainerLastName ? item.trainerLastName : "")

        item.hoursInLetters = minutesToHour(item.minutes || 0)
        if (item.status === 0) {
          item.status = (
            // <span class="badge rounded-pill bg-success">Approved</span>
            <Button
              size="sm"
              disabled={true}
              color="success"
              style={{ width: "100%", padding: "0.2rem" }}
            >
              Approved
            </Button>
          )
        } else if (item.status === 2) {
          item.status = (
            // <span class="badge rounded-pill bg-warning">Pending</span>
            <Button
              size="sm"
              disabled={true}
              color="warning"
              style={{ width: "100%", padding: "0.2rem" }}
            >
              Pending
            </Button>
          )
        } else {
          item.status = (
            // <span class="badge rounded-pill bg-danger">Rejected</span>
            <Button
              size="sm"
              disabled={true}
              color="danger"
              style={{ width: "100%", padding: "0.2rem" }}
            >
              Rejected
            </Button>
          )
        }
        item.options = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className={"fas fa-eye"}
              onClick={() => handleModalToggle(true, item._id)}
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2em",
                marginRight: "0.5em",
              }}
            ></i>
          </div>
        )
        return item
      })

      const count = res.count
      const totalPage = Math.ceil(Number(count) / limit)
      setTotalPage(totalPage)
      setTableData(data)
    })
  }

  const handleSubmit = () => {
    const data = [...selectedReports]
    if (!data.length) {
      toastr.error("Chose trainers")
      return
    }
    post("attendance/add-report", { data, month, branch: branch.value })
      .then(res => {
        reset()
        toastr.success(res.message)
      })
      .catch(err => {
        toastr.error(err.response.data.message)
      })
  }

  // const onStatusChange = () => reset()
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }
  const reset = () => {
    formRef.current.reset()
    setMonth(null)
    setReportData([])
    setSelectedReports([])
    handleTableData()
    toTop()
    setBranch(alreadyBranch)
  }

  const [openModal, setOpenModal] = useState(false)
  const [tableOpenModal, setTableOpenModal] = useState(false)
  const [selectedId, setSelectedId] = useState(null)

  const handleModalToggle = (value = false, id = null) => {
    setOpenModal(value)
    setSelectedId(id)
    if (value === false) handleTableData()
  }
  const handleTableModalToggle = (value = false, report = {}) => {
    setTableOpenModal(value)
    setSelectedReport({ trainer: report.trainer, batch: report.batchId })
    // if (value === false) handleTableData()
  }

  const handleChange = (event, value) => {
    setPage(value)
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },

      {
        label: "Id",
        field: "uniqueId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Month",
        field: "month",
        sort: "asc",
        width: 150,
      },
      {
        label: "Branch",
        field: "branch",
        sort: "asc",
        width: 150,
      },
      {
        label: "Trainer",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Batch",
        field: "batch",
        sort: "asc",
        width: 150,
      },
      {
        label: "Course",
        field: "course",
        sort: "asc",
        width: 150,
      },
      {
        label: "Days",
        field: "days",
        sort: "asc",
        width: 150,
      },
      {
        label: "Sessions",
        field: "sessions",
        sort: "asc",
        width: 150,
      },
      {
        label: "Hours",
        field: "hoursInLetters",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "options",
        sort: "asc",
        width: 150,
      },
    ],
    // rows: salaryData,
    rows: tableData,
  }

  const trainersReport = {
    columns: [
      {
        label: (
          <input
            type="checkbox"
            name="headerCheckbox"
            id="headerCheckbox"
            style={{ cursor: "pointer" }}
            onClick={e => handleAllCheckboxChange(e)}
          ></input>
        ),
        field: "checkbox",
        sort: "asc",
        width: 150,
      },
      {
        label: "Trainer",
        field: "trainerName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Batch",
        field: "batch",
        sort: "asc",
        width: 150,
      },
      {
        label: "Course",
        field: "course",
        sort: "asc",
        width: 150,
      },
      {
        label: "Days",
        field: "totalDays",
        sort: "asc",
        width: 150,
      },
      {
        label: "Sessions",
        field: "totalSessions",
        sort: "asc",
        width: 150,
      },
      {
        label: "Hours",
        field: "hoursInLetters",
        sort: "asc",
        width: 150,
      },
      {
        label: "Options",
        field: "options",
        sort: "asc",
        width: 150,
      },
    ],
    rows: reportData,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Trainer Work Report" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={handleCalculate}
                  >
                    <Col xl="12">
                      <Row>
                        {alreadyBranch ? (
                          ""
                        ) : (
                          <Col md={3}>
                            <Label>CSP</Label>
                            <Select
                              value={branch}
                              options={branches}
                              onChange={selected => {
                                setBranch(selected)
                                setSelectedReports([])
                                setReportData([])
                              }}
                            ></Select>
                          </Col>
                        )}
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Month</Label>
                            <AvField
                              name="month"
                              type="month"
                              errorMessage="Select Month"
                              className="form-control"
                              validate={{
                                required: { value: true },
                                // max: { value: moment().format("YYYY-MM"), errorMessage: 'Month cannot be in the future' }
                              }}
                              value={month || ""}
                              onChange={e => {
                                handleMonthChange(e.target.value)
                              }}
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div style={{ paddingTop: "25px" }}>
                            <Button
                              color="primary"
                              type="submit"
                              style={{ marginRight: "5%" }}
                            >
                              {"  "}
                              Calculate
                            </Button>
                            <Button
                              color="danger"
                              type="button"
                              onClick={() => reset()}
                            >
                              {"  "}
                              Reset
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {reportData.length ? (
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <MDBDataTable
                      id="trainerReport"
                      responsive
                      bordered
                      data={trainersReport}
                      searching={false}
                      sortable={false}
                      info={false}
                      disableRetreatAfterSorting={true}
                      paging={false}
                      key={JSON.stringify(reportData)}
                    />

                    <Row>
                      <Col
                        className="col-12"
                        style={{
                          paddingTop: "4px",
                          textAlign: "-webkit-right",
                        }}
                      >
                        <div className="mb-2">
                          <Button
                            color="primary"
                            type="submit"
                            onClick={() => {
                              handleSubmit()
                            }}
                          >
                            {"  "}
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            ""
          )}
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="reportTable"
                    responsive
                    bordered
                    data={data}
                    disableRetreatAfterSorting={true}
                    info={false}
                    sortable={false}
                    searching={false}
                    paging={false}
                  />
                  <TablePagination
                    page={page}
                    onChange={handleChange}
                    count={totalPage}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      {openModal && (
        <ReportDetails
          id={selectedId}
          onClick={handleModalToggle}
          isOpen={openModal}
          reset={reset}
        />
      )}
      {tableOpenModal && (
        <SessionHistory
          month={month}
          branch={branch?.value}
          report={selectedReport}
          onClick={handleTableModalToggle}
          isOpen={tableOpenModal}
        />
      )}
    </React.Fragment>
  )
}

export default TrainerWorkReport

TrainerWorkReport.propTypes = {
  error: PropTypes.any,
  categories: PropTypes.array,
}
