import React from "react"
import { Navigate } from "react-router-dom"

// Dashboard
// import MarketingDashboard from "../pages/Dashboard/Marketing/index"

//Pages
import PagesStarter from "../pages/Utility/pages-starter"
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Register1 from "../pages/AuthenticationInner/Register"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"

// Users
import User from "../pages/User"
import UserDashboard from "../pages/User/UserDashboard"

// Master Settings
import Privilage from "../pages/MasterSettings/Privilage"
import Modules from "../pages/MasterSettings/Modules"
import Rules from "../pages/MasterSettings/Rules/RulesCrm"
import RulesApp from "../pages/MasterSettings/Rules/RulesApp"
import AppDashboard from "../pages/MasterSettings/Rules/AppDashboard"
import Company from "../pages/MasterSettings/Company"
import Branch from "../pages/MasterSettings/Branch"
import StudentCategory from "../pages/Manage/StudentCategory/StudentCategory.js"
import Zone from "../pages/MasterSettings/Zone/Index"

// Accounts
import IncomeExpense from "../pages/Accounts/IncomeAndExpense/index.jsx"
import CreditDebitFund from "../pages/Accounts/CreditDebit"
import FundTransfer from "../pages/Accounts/FundTransfer"
import TransactionLog from "../pages/Accounts/TransactionLog"
import ChartOfAccount from "../pages/Accounts/ChartOfAccount/index.jsx"
import ProfitLoss from "../pages/Accounts/ProfitAndLoss/index.jsx"
import AccountDetailType from "../pages/Accounts/AccountDetailsType"
import AccountType from "../pages/Accounts/AccountType"

// Security
import BlockedIp from "../pages/Security/BlockedIp"
import UserActivity from "../pages/Security/UserActivity"
import MessageLog from "../pages/Security/MessageLog/index.jsx"

// Bulk upload
import BulkUpload from "../pages/BulkUpload/Bulkupload"

// Campaign
import CampaignDashboard from "../pages/Leads/Campaign/CampaignDashboard"
import Campaign from "../pages/Leads/Campaign/Campaign"
import Trackclicks from "../pages/Leads/Campaign/TrackClicks"

// Courses
import Course from "../pages/Course/ManageCourse"
import ManageCluster from "../pages/Course/ManageCluster"
import Chapter from "../pages/Course/Chapter"
import CourseDashboard from "../pages/Course/CourseDashboard"
import CourseSector from "../pages/Course/CourseSector"
import CourseTag from "../pages/Manage/Coursetag/CourseTag.js"

// Reports
import StudentReport from "../pages/Student/Reports/StudentReport"
import NoEnrolled from "../pages/Student/Reports/NoEnrolled"

// Students
import Students from "../pages/Students"
import StudentDetail from "../pages/Students/StudentDetail"
import StudentDashboard from "../pages/Students/StudentDetail/StudentDashboard"
import Dashboardstudent from "../pages/Student-Dashboard/index.js"
// import StudentDashboard1 from "../pages/Student-Dashboard"

// Leads
import LeadsBoard from "../pages/Leads/Leads/Board-Design"
import AddLead from "../pages/Leads/Leads/Add-Lead"
import Leads from "../pages/Leads/Leads/Lead"
import TrashedLead from "../pages/Leads/Leads/TrashedLeads"
import FollowUp from "../pages/Leads/Leads/Followup"
import LeadsDashboard from "../pages/Leads-dashboard/LeadsDashboard"
import PendingLeads from "../pages/Leads/Leads/PendingLeads"

// Batch
import Batch from "../pages/Students/Batch/BatchCreation"
import StudentAllocation from "../pages/Students/Batch/StudentsAllocation"
import FacultyAllocation from "../pages/Students/Batch/FacultyAllocation"
import StudentTransfer from "../pages/Students/Batch/StudentTranfer"
import InternalExam from "../pages/Students/Batch/InternalExam"
import Payment from "../pages/Students/Payments"
import StudentProfile from "../pages/Students/Profile"
// import Attendance from "../pages/Students/Attendance"
import TemporaryBatch from "../pages/Students/Batch/Temporary-Batch/Index"
import BatchcrrationDetail from "../pages/Students/Batch/BatchCreation/BatchcreationDetail"
import Batchview from "../pages/Students/Batch/Batch-view/Batchview"
import Attendance from "../pages/Attendance/Attendance"
import TemporaryDetail from "../pages/Students/Batch/Temporary-Batch/Dashboard/index.jsx"

// Events
import EventCategory from "../pages/Events/newEvents/EventCategory"
import Creation from "../pages/Events/newEvents/Creation"
import EventDashboard from "../pages/Events/newEvents/Creation/eventDashboard"
import Registration from "../pages/Events/newEvents/Registration"
import Gallery from "../pages/Events/newEvents/Gallery"
import EventCosting from "../pages/Events/newEvents/Costing"
import ExpenseCategory from "../pages/Events/newEvents/ExpenseCategory"
// import EventsDashboard from "../pages/Event-Dashboard/index.js"
import Events from "../pages/Events/newEvents/Events/index.js"
// import EventDashboard1 from "../pages/Event-Dashboard/index.js"
import ManualPaymentsevent from "../pages/Events/newEvents/ManualPayment/ManualPayments.js"
import AttendaceEvent from "../pages/Events/ManualAttendance/Attendace.js"
// Skill Loans, Scholarships
import SkillLoans from "../pages/Finance/SkillLoan/AddSkillLoan"
import SkillLoansDetails from "../pages/Finance/SkillLoan/Details/index.jsx"
import SkillLoanRequest from "../pages/Finance/SkillLoan/Request/index.jsx"
import SkillLoanSummary from "../pages/Finance/SkillLoan/Summary/index.jsx"
import ScholarShips from "../pages/Finance/Scholarship/Create"
import ScholarshipDetails from "../pages/Finance/Scholarship/Details/index.jsx"
import ScholarshipRequests from "../pages/Finance/Scholarship/Requests/index.jsx"
import ScholarshipSummary from "../pages/Finance/Scholarship/Summary/index.jsx"

// Screening Test
import TestCreation from "../pages/ScreeningTest/TestCreation"
import QuestionAssign from "../pages/ScreeningTest/AssignQuestions"
import Testdetails from "../pages/ScreeningTest/TestDetails/Testdetails"
import ScreeningAssign from "../pages/ScreeningTest/ScreeningAssign"
import RankList from "../pages/ScreeningTest/RankList"
import AsssignTestDashboard from "../pages/ScreeningTest/AssignTestDetails.jsx/index.jsx"
import RankListDetail from "../pages/ScreeningTest/RankList/RankListDetail.jsx"
import TestResults from "../pages/ScreeningTest/TestResults/TestResults.js"

// Assets
import CreateAssets from "../pages/Assets/CreateAsests"
import AssetDashboard from "../pages/Assets/AssetDashboard"
import AssetsMovement from "../pages/Assets/AssetsTransfer"
import AssetsAllocation from "../pages/Assets/AssetsAllocation"
import AssetsIssues from "../pages/Assets/AssetIssue"
import ReturnAssets from "../pages/Assets/Return"
import Trackassets from "../pages/Assets/TrackAssets"
import AssetCategoty from "../pages/Assets/AssetCategory/index.jsx"
import AssetSubCategoty from "../pages/Assets/AssetSubCategory/index.jsx"

// Recruiter
import Recruiter from "../pages/Internship/Recruiter"
import RecruiterDashboard from "../pages/Internship/Recruiter/recruiterdashboard"

// Job
// import AddJob from "../pages/Jobs/AddJob"
import ViewJob from "../pages/Jobs/ViewJob"
import ViewApplications from "../pages/Jobs/ViewApplications"
import Shortlist from "../pages/Jobs/Shortlist"
import Placements from "../pages/Jobs/Placements"
import AddJobPost from "../pages/Jobs/AddJob/AddJobPost.jsx"

// Internship
import Internship from "../pages/Internship/Create"
import Dashboard from "../pages/Internship/InternshipDashboard"
import ViewInternship from "../pages/Internship/Applied"
import InternshipShortlist from "../pages/Internship/Shortlisted"
import InternshipPlacements from "../pages/Internship/Placed"
// import InternshipDashboard from "../pages/Internship-Dashboard"
import OfferLetter from "../pages/Internship/OfferLetter"

// Notification
import Notification from "../pages/Notification"

// Ticket
import CreateTicket from "../pages/Ticket/TicketCreation"
import TicketCategory from "../pages/Ticket/TicketCategory"

// Team
import ManageTeam from "../pages/Team/AddTeam"

// Agreement
import CreateAgreement from "../pages/Agreement/CreateAgreement/index.jsx"
import AgreementDetails from "../pages/Agreement/AgreementDetails"
import AgreementCategory from "../pages/Agreement/Category"
import Renewals from "../pages/Agreement/Renewals/index.jsx"
// import Document from "../pages/Agreement/Document"
import RenewDetails from "../pages/Agreement/renewDetails.jsx"
// import ExpiryAlert from "../pages/Agreement/ExpiryAlert/index.jsx"

// Classroom/Lab
import ClassCreate from "../pages/ClassManage/Createclass"
import Labcreate from "../pages/ClassManage/CreateLab"
import ViewClass from "../pages/ClassManage/ClassDetails/ViewClass"
import ClassAvailability from "../pages/ClassManage/ClassAvailability"

// Community
import CommunityCategory from "../pages/Community/CommunityCategory"
import CommunityRegisteredMembers from "../pages/Community/RegisteredMembers"
import CommunityList from "../pages/Community/CommunityList"
import CommunityDetails from "../pages/Community/CommunityDetails"
import CommunityCreate from "../pages/Community/CommunityCreate.jsx"
import CommunityRequest from "../pages/Community/CommunityRequest/index.jsx"

// Manage
import StreamManage from "../pages/Manage/Stream/Stream"
import Degree from "../pages/Manage/Degree/Degree"
import Qualifications from "../pages/Manage/Qualification/Qualifications"
import MinorityStatus from "../pages/Manage/MInorityStatus"
import DocumentUpload from "../pages/Manage/DocumentUpload"

// Quality Framework
import QualityFrameWork from "../pages/QualityFrameWork"

// Website Contents
import PlacementsWeb from "../pages/Webcontents/Placements"
import Testimonials from "../pages/Webcontents/Testimonials"
import Faq from "../pages/Webcontents/Faqs"
import SiteMap from "../pages/SiteMap/SiteMap.jsx"
import EmailSubscription from "../pages/Email-Subscription/index.jsx"

// Offer Code
import OfferCodeLog from "../pages/Student-Manage/OfferCodeLog/index.jsx"
import OfferCode from "../pages/Student-Manage/OfferCode"
import OfferCodeDashboard from "../pages/Student-Manage/OfferCode/OfferCodeDashboard"

// Finance
// import FinanceDashboard from "../pages/Finance-dashboard/index.jsx"
import FinanceDashboard from "../pages/Finance/Dashboard"
import RevenueDetailed from "../pages/Finance/Dashboard/detailedReport.jsx"
import CreditNotes from "../pages/Finance/Fees/CreditNotes/index.jsx"
import BulkFee from "../pages/Finance/BulkFee/index.jsx"
import CalculateSalary from "../pages/Finance/Remuneration/index.jsx"
import TrainerWorkReport from "../pages/Finance/WorkReport/TrainerWorkReport"

// Fees
import FeePayments from "../pages/Finance/Fees/FeePayments"
import ManualPayments from "../pages/Finance/Fees/ManualPayments"
import OnlinePayments from "../pages/Finance/Fees/OnlinePayments"
// import Receipts from "../pages/Finance/Fees/Receipts"

// Income Expense Report
import IncomeReport from "../pages/Finance/Reports/Income"
import ExpenseReport from "../pages/Finance/Reports/Expense"

// Feedback
import FeedBackQuestions from "../pages/FeedBack/FeedBackQuestions"
import Feedbacks from "../pages/FeedBack/feedbacks"
import Feedbackreports from "../pages/FeedBack/FeedBack-Reports"

// Purchase Entry
import Purchase from "../pages/Purchase/Purchase"
// import Invoice from "../pages/Purchase/Invoice_status.jsx"
import Invoicestatus from "../pages/Purchase/Invoice_status.jsx"

// Expense
import CreditPayments from "../pages/Finance/CreditPayments/index.jsx"
import Credits from "../pages/Finance/Credits/index.jsx"

// CSP DASHBOARD
import DashboardCSP from "../pages/Dashboard-CSP"
import BusinessHead from "../pages/BusinessHeadDashboard/index.jsx"
import CSPDashboard from "../pages/CSP-Dashboard"
import CspDetails from "../pages/CSP-Dashboard/CspDetails"
import CSPDashboard1 from "../pages/Dashboard-CSP"

// Contacts
import Contacts from "../pages/Contacts/index.jsx"

// Reports
import LeadsReport from "../pages/Reports/Lead"
import ScholarshipReport from "../pages/Reports/Scholarship/index.jsx"
import FeeReport from "../pages/Reports/Fee/index.jsx"
import EventReport from "../pages/Reports/Event/index.jsx"
import ReportStudent from "../pages/Reports/Student/index.jsx"
import CourseReport from "../pages/Reports/Course/index.js"

// Assessment
import ProgressReport from "../pages/Assessment/ProgressReport/index.js"
import AssessmentAttendance from "../pages/Assessment/AssessmentAttendance/index.js"
import AssessmentGrades from "../pages/Assessment/AssesmentGrades/index.js"
import BatchWiseStatus from "../pages/Assessment/BatchWiseStatus/index.js"
import InternalMarks from "../pages/Assessment/InternalMarks/index.js"

// Certification
import Certificates from "../pages/Student-Manage/Certificates"
import AddCertificate from "../pages/Student-Manage/Certificates/addCertificate.jsx"
import CertificationGeneration from "../pages/Certification/Asap-Certification/index.js"
import TpCertification from "../pages/Certification/Tp-Certification/index.js"
import CourseCompletion from "../pages/Certification/CourseCompletion/index.js"
import CertificateTemplate from "../pages/Certification/Certificate-Template/index.js"
import CertificateLog from "../pages/Certification/Certificate-Log/index.js"

// Visit Log
import VisitLog from "../pages/VisitLog/index.jsx"

// About company
import Aboutcompany from "../pages/Aboutcmpny/index.jsx"

// Updates
import Updates from "../pages/Updates/index.jsx"
import EventBoard from "../pages/Events-Dashboard/BusinessHeadDashboard/index.jsx"

import Planner from "../pages/Planner/index.jsx"
import PlannerCategory from "../pages/Manage/PlannerCategory/index.jsx"
import PlannerDetails from "../pages/Planner/Details.jsx"

//Own Pages
const userRoutes = [
  //dahshboard
  {
    path: "/dashboard",
    component: <DashboardCSP title="DashboardCSP" />,
  },
  // {
  //   path: "/student-dashboard",
  //   component: <DashboardCSP title="Student dashboard" />,
  // },
  { path: "/student-dashboard", component: <Dashboardstudent /> },

  // {
  //   path: "/events-dashboard",
  //   component: <EventDashboard1 title="Events Dashboard" />,
  // },
  {
    path: "/internship-dashboard",
    component: <DashboardCSP title="Internship Dashboard" />,
  },
  {
    path: "/finance-dashboard",
    component: <FinanceDashboard title="Finance Dashboard" />,
  },
  { path: "/finance-dashboard/details", component: <RevenueDetailed /> },
  { path: "/leads-dashboard", component: <LeadsDashboard /> },
  { path: "/campaign-dashboard", component: <CampaignDashboard /> },

  // Campaign
  { path: "/campaign", component: <Campaign /> },
  { path: "/track-clicks", component: <Trackclicks /> },

  // Leads
  { path: "/new-lead", component: <AddLead /> },
  { path: "/leads", component: <Leads /> },
  { path: "/trashed-leads", component: <TrashedLead /> },
  { path: "/follow-up", component: <FollowUp /> },
  { path: "/board-design", component: <LeadsBoard /> },
  { path: "/pending-leads", component: <PendingLeads /> },

  //Utility
  { path: "/pages", component: <PagesStarter /> },

  // Users
  { path: "/user", component: <User /> },
  { path: "/user/:user_id", component: <UserDashboard /> },

  // Master Settings
  { path: "/privilage", component: <Privilage /> },
  { path: "/rules", component: <Rules /> },
  { path: "/rules-app", component: <RulesApp /> },
  { path: "/app-dashboard", component: <AppDashboard /> },
  { path: "/modules", component: <Modules /> },
  { path: "/company", component: <Company /> },
  { path: "/csp", component: <Branch /> },
  { path: "/zone", component: <Zone /> },
  { path: "/view-csp", component: <CSPDashboard /> },
  { path: "/csp-dashboard", component: <CSPDashboard1 /> },
  { path: "/view-csp/:cspid", component: <CspDetails /> },

  //BUSINESS HEAD
  { path: "/business-head", component: <BusinessHead /> },

  // Events
  { path: "/events", component: <Events /> },
  { path: "/category-event", component: <EventCategory /> },
  { path: "/creation", component: <Creation /> },
  { path: "/creation/:Id", component: <EventDashboard /> },
  { path: "/registration", component: <Registration /> },
  { path: "/gallery", component: <Gallery /> },
  { path: "/event-costing", component: <EventCosting /> },
  { path: "/expense-category", component: <ExpenseCategory /> },
  { path: "/event/payment", component: <ManualPaymentsevent /> },
  { path: "/event/attendance", component: <AttendaceEvent /> },
  { path: "/events-dashboard", component: <EventBoard /> },

  // Accounts
  { path: "/income-expense", component: <IncomeExpense /> },
  { path: "/credit-debit-fund", component: <CreditDebitFund /> },
  { path: "/fund-transfer", component: <FundTransfer /> },
  { path: "/transaction-log", component: <TransactionLog /> },
  { path: "/chart-of-account", component: <ChartOfAccount /> },
  { path: "/profit-loss", component: <ProfitLoss /> },
  { path: "/account-detail-type", component: <AccountDetailType /> },
  { path: "/account-type", component: <AccountType /> },

  // Bulk Upload
  { path: "/bulk_upload", component: <BulkUpload /> },

  // Course
  { path: "/manage-sector", component: <CourseSector /> },
  { path: "/manage-course", component: <Course /> },
  { path: "/manage-cluster", component: <ManageCluster /> },
  { path: "/manage-chapter", component: <Chapter /> },
  { path: "/manage-course/:courseId", component: <CourseDashboard /> },

  // Reports
  { path: "/student-report", component: <StudentReport /> },
  { path: "/no-enrolled", component: <NoEnrolled /> },

  // Create Expense
  { path: "/credit-payment", component: <CreditPayments /> },
  { path: "/credits", component: <Credits /> },

  // Security
  { path: "/user-activity", component: <UserActivity /> },
  { path: "/blocked-ip", component: <BlockedIp /> },
  { path: "/message-log", component: <MessageLog /> },

  // Students
  { path: "/students", component: <Students /> },
  { path: "/student-details", component: <StudentDetail /> },
  { path: "/student-details/:id", component: <StudentDashboard /> },

  // Batch
  { path: "/batch-view", component: <Batchview /> },
  { path: "/temporary-batch", component: <TemporaryBatch /> },
  { path: "/temporary-batch/:tempBatchId", component: <TemporaryDetail /> },
  { path: "/batch-creations", component: <Batch /> },
  { path: "/batch-creations/:batchid", component: <BatchcrrationDetail /> },
  { path: "/students-allocation", component: <StudentAllocation /> },
  { path: "/allocation-faculty", component: <FacultyAllocation /> },
  { path: "/student-transfer", component: <StudentTransfer /> },
  { path: "/internalexam-creation", component: <InternalExam /> },
  { path: "/payment", component: <Payment /> },
  { path: "/student", component: <StudentProfile /> },
  { path: "/attendance", component: <Attendance /> },

  // Skill Loans, Scholarships
  { path: "/scholarships", component: <ScholarShips /> },
  { path: "/Scholarships-requests", component: <ScholarshipRequests /> },
  { path: "/Scholarships/:id", component: <ScholarshipDetails /> },
  { path: "/skills-loans", component: <SkillLoans /> },
  { path: "/skills-loan-request", component: <SkillLoanRequest /> },
  { path: "/skills-loans/:id", component: <SkillLoansDetails /> },
  { path: "/skill-loan-summary", component: <SkillLoanSummary /> },
  { path: "/scholarship-summary", component: <ScholarshipSummary /> },

  // Assessment
  { path: "/batch-wise-status", component: <BatchWiseStatus /> },
  { path: "/progress-report", component: <ProgressReport /> },
  { path: "/internal-marks", component: <InternalMarks /> },
  { path: "/assessment-attendance", component: <AssessmentAttendance /> },
  { path: "/assessment-grade", component: <AssessmentGrades /> },

  // Certification
  { path: "/certificate-template", component: <Certificates /> },
  { path: "/certificate", component: <AddCertificate /> },
  { path: "/certification-generation", component: <CertificationGeneration /> },
  { path: "/tp-assessment-certificate", component: <TpCertification /> },
  { path: "/course-completion", component: <CourseCompletion /> },
  { path: "/certification-template", component: <CertificateTemplate /> },
  { path: "/certification-log", component: <CertificateLog /> },

  // Offer Code
  { path: "/offer-codes", component: <OfferCode /> },
  { path: "/offer-codes/:Id", component: <OfferCodeDashboard /> },
  { path: "/offer-code-log", component: <OfferCodeLog /> },

  // Manage
  { path: "/stream", component: <StreamManage /> },
  { path: "/degree", component: <Degree /> },
  { path: "/qualification", component: <Qualifications /> },
  { path: "/student-category", component: <StudentCategory /> },
  { path: "/minority-status", component: <MinorityStatus /> },
  { path: "/document-upload", component: <DocumentUpload /> },
  { path: "/course-tag", component: <CourseTag /> },

  // Assets
  { path: "/asset-category", component: <AssetCategoty /> },
  { path: "/asset-sub-category", component: <AssetSubCategoty /> },
  { path: "/asset-creation", component: <CreateAssets /> },
  { path: "/asset-creation/:assetId", component: <AssetDashboard /> },
  { path: "/asset-movement", component: <AssetsMovement /> },
  { path: "/asset-allocations", component: <AssetsAllocation /> },
  { path: "/asset-issues", component: <AssetsIssues /> },
  { path: "/returns", component: <ReturnAssets /> },
  { path: "/asset-tracking", component: <Trackassets /> },

  // Remuneration
  { path: "/remuneration-calculation", component: <CalculateSalary /> },

  // Agreement
  { path: "/create-agreement", component: <CreateAgreement /> },
  { path: "/renewals", component: <Renewals /> },
  // { path: "/expiry-alerts", component: <ExpiryAlert /> },
  { path: "/agreement", component: <AgreementDetails /> },
  { path: "/agreement-category", component: <AgreementCategory /> },
  { path: "/renew", component: <RenewDetails /> },

  // Screening Test
  { path: "/create_test", component: <TestCreation /> },
  { path: "/test-results", component: <TestResults /> },
  { path: "/question_assign", component: <QuestionAssign /> },
  { path: "/screen_assign", component: <ScreeningAssign /> },
  { path: "/screen_assign/:id", component: <AsssignTestDashboard /> },
  { path: "/rank_list", component: <RankList /> },
  { path: "/rank_list/:id", component: <RankListDetail /> },
  { path: "/test_details/:Id", component: <Testdetails /> },

  // Ticket
  { path: "/create_ticket", component: <CreateTicket /> },
  { path: "/ticket_category", component: <TicketCategory /> },

  // Community
  { path: "/community_category", component: <CommunityCategory /> },
  { path: "/members_registration", component: <CommunityRegisteredMembers /> },
  { path: "/community_list", component: <CommunityList /> },
  { path: "/community_details/:id", component: <CommunityDetails /> },
  { path: "/community_create", component: <CommunityCreate /> },
  { path: "/community_request", component: <CommunityRequest /> },

  // Team
  { path: "/manage_team", component: <ManageTeam></ManageTeam> },

  // Recruiter
  { path: "/recruiter", component: <Recruiter /> },
  { path: "/recruiter/:recruiterId", component: <RecruiterDashboard /> },

  // Job
  { path: "/create-job-post", component: <AddJobPost /> },
  { path: "/create-job-post/:jobId", component: <ViewJob /> },
  { path: "/job-applications", component: <ViewApplications /> },
  { path: "/shortlist", component: <Shortlist /> },
  { path: "/job-placements", component: <Placements /> },
  { path: "/offer-letter", component: <OfferLetter /> },

  // Internship
  { path: "/create-internship-post", component: <Internship /> },
  { path: "/create-internship-post/:Id", component: <Dashboard /> },
  { path: "/internship-applications", component: <ViewInternship /> },
  { path: "/internship-shortlisted", component: <InternshipShortlist /> },
  { path: "/internship-placements", component: <InternshipPlacements /> },

  // Income Expense Report
  { path: "/income-report", component: <IncomeReport /> },
  { path: "/expense-report", component: <ExpenseReport /> },

  // Purchase Entry
  { path: "/purchase-entry", component: <Purchase /> },
  { path: "/income-status-update", component: <Invoicestatus /> },

  // Classrooms/Labs
  { path: "/class-creation", component: <ClassCreate /> },
  { path: "/class-availability", component: <ClassAvailability /> },
  { path: "/lab-creation", component: <Labcreate /> },
  { path: "/class-creation/:classId", component: <ViewClass /> },

  // Fees
  { path: "/fee_payments", component: <FeePayments /> },
  { path: "/manual_payments", component: <ManualPayments /> },
  { path: "/online-payments", component: <OnlinePayments /> },
  { path: "/credit-note", component: <CreditNotes /> },
  { path: "/bulk-fee", component: <BulkFee /> },
  // { path: "/receipts", component: <Receipts /> },
  { path: "/trainer-work-reports", component: <TrainerWorkReport /> },

  // Quality Framework
  { path: "/quality-framework", component: <QualityFrameWork /> },

  // Website Contents
  { path: "/placement", component: <PlacementsWeb /> },
  { path: "/testimonials", component: <Testimonials /> },
  { path: "/faq", component: <Faq /> },
  { path: "/site-map", component: <SiteMap /> },
  { path: "/email-subscription", component: <EmailSubscription /> },

  // Reports
  { path: "/reports-leads", component: <LeadsReport /> },
  { path: "/reports-scholarships", component: <ScholarshipReport /> },
  { path: "/reports-fees", component: <FeeReport /> },
  { path: "/reports-event", component: <EventReport /> },
  { path: "/reports-student", component: <ReportStudent /> },
  { path: "/reports-course", component: <CourseReport /> },

  // Notification
  { path: "/notification", component: <Notification /> },

  // Feedback
  { path: "/feedbacks", component: <Feedbacks /> },
  { path: "/feedbacks-questions", component: <FeedBackQuestions /> },
  { path: "/feedbacks-reports", component: <Feedbackreports /> },

  // About company
  { path: "/about-company", component: <Aboutcompany /> },

  // Visit Log
  { path: "/visit-log", component: <VisitLog /> },

  // Updates
  { path: "/updates", component: <Updates /> },

  // Contacts
  { path: "/contact", component: <Contacts /> },

  // Planner
  { path: "/planner", component: <Planner /> },
  { path: "/planner-category", component: <PlannerCategory /> },
  { path: "/planner-details", component: <PlannerDetails /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to={() => sessionStorage.getItem("module_url")} />,
  },
  {
    path: "*",
    component: <Navigate to={() => sessionStorage.getItem("module_url")} />,
  },
]

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },

  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },

  // Authentication Inner
  { path: "/pages-login", component: <Login1 /> },
  { path: "/pages-register", component: <Register1 /> },
  { path: "/page-recoverpw", component: <Recoverpw /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
]

export { userRoutes, authRoutes }
