import React, { useState, useEffect, useRef } from "react"
import Select from "react-select"
import { MDBDataTable } from "mdbreact"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Row, Col, Card, CardBody, Label, Button } from "reactstrap"
import { get, post } from "../../../helpers/api_helper"
import { Modal } from "react-bootstrap"
import { API_URL } from "../../../config"
import toastr from "toastr"

import Tooltip from "@mui/material/Tooltip"
import {
  dateConverter,
  getDate,
  numberToCurrency,
  timeConverter,
} from "../../../helpers/functions"
import axios from "axios"
import moment from "moment"

const CreditFollowup = ({ id, isOpen, handleModalClose, isCredit = true }) => {
  const ref = useRef()
  const todayDate = getDate()
  const time = moment().format("HH:mm")

  const types = { 1: "Income", 2: "Expense", Income: 1, Expense: 2 }

  const initialState = {
    id: "",
    date: todayDate,
    time: time,
    amount: "",
    fundSource: "",
    attachment: "",
    remarks: "",
    isDisabled: false,
  }

  const [masterObject, setMasterObject] = useState({ ...initialState, id: id })
  const [selectedFields, setSelectedFields] = useState({ ...initialState })

  const [selectedData, setSelectedData] = useState({})
  const [tableData, setTableData] = useState([])
  const [fundSourceOptions, setFundSourceOptions] = useState([])

  useEffect(() => {
    if (id) {
      handleData()
      setMasterObject({ ...initialState, id: id })
      setSelectedFields({ ...initialState })
    }
    // eslint-disable-next-line
  }, [id])

  const handleData = async () => {
    try {
      const response = await get(
        `credit-income-expense/credit/details?id=${id}`
      )
      const data = response.data
      if (!data) return

      data.date = dateConverter(data.date)
      data.time = timeConverter(data.time)

      data.addedDate = dateConverter(data.addedDate)
      data.addedTime = timeConverter(data.addedTime)

      data.type = types[data.type]

      data.approvedDate = data.approvedDate
        ? dateConverter(data.approvedDate)
        : ""
      data.approvedTime = data.approvedTime
        ? timeConverter(data.approvedTime)
        : ""
      const amount = data.amount || 0
      const paid = data.received || 0

      data.paid = numberToCurrency(paid)
      data.amount = numberToCurrency(amount)
      data.pending = numberToCurrency(amount - paid)

      data.staff = `${data.staffFirstName || ""} ${data.staffLastName || ""}`
      data.approvedBy = `${data.approvedFirstName || ""} ${
        data.approvedLastName || ""
      }`

      if (data.attachment)
        data.attachment = (
          <a href={API_URL + data.attachment} target="_blank" rel="noreferrer">
            <i
              // className="fas fa-file-pdf"
              className="far fa-file-image"
              style={{
                cursor: "pointer",
                fontSize: "1.5em",
                color: "black",
                // color: "red",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
            ></i>
          </a>
        )

      data.statusText =
        data.status === 2
          ? "Cancelled"
          : data.status === 0
          ? "Approved"
          : "Pending"

      setSelectedData(data)
      handleFollowupData(data.creditPayments)
      await handleFundSourceOptions(data.branchId)
    } catch (err) {}
  }

  const handleFollowupData = async data => {
    if (!Array.isArray(data) || data.length < 1) setTableData([])

    data.map((item, index) => {
      item.id = index + 1

      item.date = dateConverter(item.date)
      item.time = item.time ? timeConverter(item.time) : ""

      item.amount = numberToCurrency(item.amount)
      item.due = numberToCurrency(item.due)

      item.staff = `${item.staffFirstName || ""} ${item.staffLastName || ""}`

      if (item.attachment)
        item.attachment = (
          <a href={API_URL + item.attachment} target="_blank" rel="noreferrer">
            <i
              // className="fas fa-file-pdf"
              className="far fa-file-image"
              style={{
                cursor: "pointer",
                fontSize: "1.5em",
                color: "black",
                // color: "red",
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
              }}
            ></i>
          </a>
        )

      return item
    })
    setTableData(data)
  }
  const handleFundSourceOptions = async branch => {
    try {
      const response = await get(
        `credit-income-expense/options/fund-source?branch=${branch}`
      )
      setFundSourceOptions(response.data)
    } catch (err) {}
  }

  const handleSubmit = async () => {
    if (!masterObject.fundSource) {
      return toastr.error("Fund Source is required")
    }
    try {
      const response = await post(
        `credit-income-expense/credit/add`,
        masterObject
      )
      toastr.success(response.message)
      reset()
    } catch (error) {
      toastr.error(error.response.data.message)
    }
  }

  const handleSelectValueChange = (selected, name) => {
    const obj = { ...selectedFields }
    obj[name] = selected
    setSelectedFields(obj)
    handleValueChange(selected.value, name)
  }

  const handleValueChange = (value, name) => {
    const obj = { ...masterObject }
    obj[name] = value
    setMasterObject(obj)
  }

  const handleFileUpload = async e => {
    const selectedFile = e.target.files[0]
    if (selectedFile) {
      try {
        const fd = new FormData()
        fd.append("image", e.target.files[0])
        axios.post(`${API_URL}expense/image`, fd).then(response => {
          if (response.data.status === 200) {
            handleValueChange(response.data.data.new_filename, "attachment")
          } else {
            toastr.error(response.data.message)
          }
        })
      } catch (error) {
        toastr.error(error)
        e.target.value = ""
      }
    }
  }

  const reset = () => {
    ref.current?.reset()
    handleData()
    setMasterObject({ ...initialState, id: id })
    setSelectedFields({ ...initialState })
  }

  const data = {
    columns: [
      { label: "#", field: "id" },
      { label: "Date", field: "date" },
      { label: "Time", field: "time" },
      { label: "Amount", field: "amount" },
      { label: "Due", field: "due" },
      { label: "Fund Source", field: "fundSource" },
      { label: "Attachment", field: "attachment" },
      { label: "Remarks", field: "remarks" },
      { label: "Added By", field: "staff" },
    ],
    rows: tableData,
  }
  return (
    <>
      <Modal show={isOpen} size="xl" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            {isCredit ? "Credit" : ""} Payment Details
          </h5>
          <button
            type="button"
            onClick={handleModalClose}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <Row>
            <Col md="12">
              <ul className="lead-details">
                <li>
                  Date : <Label>{selectedData?.date}</Label>
                </li>
                <li>
                  Time : <Label>{selectedData?.time}</Label>
                </li>
                <li>
                  CSP : <Label>{selectedData?.branch}</Label>
                </li>
                <li>
                  Head : <Label>{selectedData?.type}</Label>
                </li>
                <li>
                  Sub Head : <Label>{selectedData?.subHead}</Label>
                </li>
                <li>
                  Chart Of Account :{" "}
                  <Label>{selectedData?.chartOfAccount}</Label>
                </li>
                <li>
                  Amount : <Label>{selectedData?.amount}</Label>
                </li>
                <li hidden={!selectedData.fundSource}>
                  Fund Source : <Label>{selectedData?.fundSource}</Label>
                </li>
                <li hidden={selectedData.paymentType === 2}>
                  Paid : <Label>{selectedData?.paid}</Label>
                </li>
                <li hidden={selectedData.paymentType === 2}>
                  Pending: <Label>{selectedData?.pending}</Label>
                </li>
                <li hidden={!selectedData.course}>
                  Course: <Label>{selectedData?.course}</Label>
                </li>
                <li hidden={!selectedData.batch}>
                  Batch: <Label>{selectedData?.batch}</Label>
                </li>
                <li hidden={!selectedData.attachment}>
                  Attachment: <Label>{selectedData?.attachment}</Label>
                </li>
                <li hidden={!selectedData.remarks}>
                  Remarks: <Label>{selectedData?.remarks}</Label>
                </li>
                <li>
                  Added By: <Label>{selectedData?.staff}</Label>
                </li>
                <li>
                  Added Date: <Label>{selectedData?.addedDate}</Label>
                </li>
                <li>
                  Added Time: <Label>{selectedData?.addedTime}</Label>
                </li>
              </ul>
              {selectedData.status === 2 || selectedData.status === 0 ? (
                <ul className="lead-details">
                  <li>
                    {selectedData?.statusText} Date:{" "}
                    <Label>{selectedData?.approvedDate}</Label>
                  </li>
                  <li>
                    {selectedData?.statusText} Time:{" "}
                    <Label>{selectedData?.approvedTime}</Label>
                  </li>
                  <li>
                    {selectedData?.statusText} By :{" "}
                    <Label>{selectedData?.approvedBy}</Label>
                  </li>
                </ul>
              ) : (
                ""
              )}
            </Col>
            {isCredit &&
              selectedData.status !== 2 &&
              selectedData.status !== 0 && (
                <Col md="12">
                  <Card>
                    <CardBody>
                      <AvForm ref={ref} onValidSubmit={() => handleSubmit()}>
                        <Row>
                          <Col md={2}>
                            <Label>Date</Label>
                            <AvField
                              name={`date`}
                              className="form-control"
                              type="date"
                              placeholder="Date"
                              errorMessage="Enter valid Date"
                              defaultValue={todayDate}
                              value={masterObject.date || ""}
                              validate={{ required: { value: true } }}
                              max={todayDate}
                              onChange={e =>
                                handleValueChange(e.target.value, e.target.name)
                              }
                            />
                          </Col>
                          <Col md={2}>
                            <Label>Time</Label>
                            <AvField
                              name={`time`}
                              className="form-control"
                              type="time"
                              placeholder="Time"
                              errorMessage="Enter valid Time"
                              defaultValue={time}
                              value={masterObject.time || ""}
                              validate={{ required: { value: true } }}
                              onChange={e =>
                                handleValueChange(e.target.value, e.target.name)
                              }
                            />
                          </Col>
                          <Col md={2}>
                            <Label>Amount (₹)</Label>
                            <AvField
                              name={`amount`}
                              className="form-control"
                              type="number"
                              placeholder="Amount"
                              errorMessage="Enter valid Amount"
                              validate={{ required: { value: true } }}
                              value={masterObject.amount}
                              min={1}
                              onChange={e =>
                                handleValueChange(e.target.value, e.target.name)
                              }
                            />
                          </Col>

                          <Col md={3}>
                            <div className="mb-3">
                              <Label>Fund Source</Label>
                              <Select
                                name="fundSource"
                                options={fundSourceOptions}
                                value={selectedFields.fundSource}
                                onChange={selected =>
                                  handleSelectValueChange(
                                    selected,
                                    "fundSource"
                                  )
                                }
                              ></Select>
                            </div>
                          </Col>
                          <Col md="3">
                            <div className="mb-3">
                              <Label htmlFor="validationCustom03">
                                Attachments
                              </Label>

                              {masterObject?.attachment ? (
                                <div
                                  div
                                  className="img-wraps d-flex flex-column"
                                >
                                  {masterObject.attachment && (
                                    <img
                                      alt=""
                                      width="150"
                                      height="150"
                                      src={`${API_URL}${masterObject.attachment}`}
                                    />
                                  )}
                                  <Button
                                    onClick={e =>
                                      handleValueChange("", "attachment")
                                    }
                                    type="button"
                                    color="danger"
                                    size="sm"
                                    style={{ width: "150px" }}
                                    className="btn-block waves-effect waves-light"
                                  >
                                    Delete
                                  </Button>
                                </div>
                              ) : (
                                <Tooltip
                                  title="(Invoice, Images, PDF)"
                                  arrow
                                  placement="bottom"
                                >
                                  <div>
                                    <AvField
                                      name="file"
                                      type="file"
                                      className="form-control"
                                      id="validationCustom03"
                                      rows="1"
                                      title=""
                                      accept="image/*, .pdf, .xls, .xlsx"
                                      onChange={e => handleFileUpload(e)}
                                    />
                                  </div>
                                </Tooltip>
                              )}
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="mb-3">
                              <Label>Remarks</Label>
                              <AvField
                                type="textarea"
                                rows={1}
                                name="remarks"
                                placeholder="Remarks"
                                value={masterObject.remarks}
                                onChange={e =>
                                  handleValueChange(
                                    e.target.value,
                                    e.target.name
                                  )
                                }
                              ></AvField>
                            </div>
                          </Col>
                          <Col md={2}>
                            <div className="d-flex mt-4">
                              <Button
                                color="primary"
                                type="submit"
                                className="me-2"
                              >
                                Submit
                              </Button>
                              <Button
                                color="danger"
                                type="button"
                                onClick={reset}
                              >
                                Reset
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              )}
            {isCredit && (
              <Col md={12} className="mt-3">
                <Card>
                  <CardBody>
                    <MDBDataTable
                      id="creditPaymentsFollowup"
                      responsive
                      bordered
                      data={data}
                      searching={false}
                      paging={false}
                      info={false}
                      sortable={false}
                      disableRetreatAfterSorting={true}
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </div>
      </Modal>
    </>
  )
}

export default CreditFollowup
