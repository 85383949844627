import React, { useEffect, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap"
import { get, put, post } from "../../../helpers/api_helper"
import moment from "moment"
import { API_URL } from "../../../config"
import { useSelector, useDispatch } from "react-redux"
import { getSectors, getCluster } from "../../../store/options/actions"
import toastr from "toastr"
import "./style.scss"
import InternshipModal from "../InternshipModal"
import Swal from "sweetalert2"
import MuiCustomTablePagination from "../../../components/Common/TablePagination"

function    Applied() {
  const [popupView, setPopupView] = useState(false)
  const [modal, setModal] = useState({})

  const [tableDatadetails, setTableDatadetails] = useState([])
  const [applicantOptions, setApplicantOptions] = useState(null)
  const [recruitersOptions, setRecruitersOptions] = useState(null)
  const [internshipoptions, setInternshipoptions] = useState(null)
  const [jobsOptions, setJobsOptions] = useState(null)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(20)
  const [count, setCount] = useState(0)
  const initialState = {
    fromDate: "",
    toDate: "",
    cluster: "",
    sector: "",
    applicant: "",
    recruiter: "",
    status: "",
    jobpost: "",
  }
  const statusoptionsfilter = [
    { label: "Applied", value: 0 },
    { label: "Shortlist", value: 2 },
    { label: "Placed", value: 3 },
    { label: "Rejected", value: 4 },
  ]
  const [filterObject, setFilterObject] = useState(initialState)
  const dispatch = useDispatch()
  const { cluster, sectors } = useSelector(state => state.Options)
  useEffect(() => {
    dispatch(getSectors())
    dispatch(getCluster())
    // eslint-disable-next-line
  }, [])
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "ID",
        field: "uniqueId",
        width: 150,
      },
      {
        label: "Internship",
        field: "jobname",
        width: 150,
      },
      {
        label: "Recruiters",
        field: "Recruitername",
        width: 150,
      },
      {
        label: "Applicant",
        field: "applicantname",
        width: 150,
      },
      {
        label: "Mobile",
        field: "applicantmobile",
        sort: "desc",
        width: 400,
      },
      {
        label: "Status",
        field: "status1",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        width: 150,
      },
    ],
    rows: tableDatadetails,
  }
  useEffect(() => {
    handleApplicantOptions()
    handleRecruiterOptions()
    handleJobsOptions()
    fetchInternship()
  }, [])
  useEffect(() => {
    handleTableData()
  }, [filterObject,page,pageSize])

  const reset = () => {
    setFilterObject(initialState)
  }
  function fetchInternship() {
    get(`/internship/internship-option`).then(res => {
      var event_data = res.data.map(item => {
        return {
          label: item?.name,
          value: item?._id,
        }
      })
      setInternshipoptions(event_data)
    })
  }
  const handleApplicantOptions = async () => {
    get("/options/batches").then(res => {
      var event_data = res.data.map(item => {
        return {
          label: item?.label,
          value: item?._id,
        }
      })
      setApplicantOptions(event_data)
    })
  }
  const handleJobsOptions = async () => {
    get("options/jobs").then(res => {
      var event_data = res.data.map(item => {
        return {
          label: item?.label,
          value: item?._id,
        }
      })
      setJobsOptions(event_data)
    })
  }
  const handleRecruiterOptions = async () => {
    get("/options/recruiters").then(res => {
      var event_data = res.data.map(item => {
        return {
          label: item?.label,
          value: item?._id,
        }
      })
      setRecruitersOptions(event_data)
    })
  }
  const statusOptions = [
    { label: "Shortlist", value: 2 },
    { label: "Reject", value: 4 },
  ]
  

  const handleStatusChange = async (requestId, approvedStatus) => {
    if (approvedStatus == 4) {
      Swal.fire({
        title: "Reason For Rejection",
        html:
          '<textarea id="remark-text" class="swal2-input" placeholder="Enter your remark "></textarea>',
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonText: 'Submit',
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#3e9bee",
        preConfirm: () => {
          const remark = Swal.getPopup().querySelector('#remark-text').value;
          return { remark };
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const { remark } = result.value
          put("/internship/application-status", { _id: requestId, status: approvedStatus, remarks: remark })
            .then(res => {
              handleTableData()
              toastr.success(res.message)
            })
            .catch(err => {
              toastr.error(err.response.data.message)
            })
        }
      })
    } else {
      put("/internship/application-status", { _id: requestId, status: approvedStatus })
        .then(res => {
          handleTableData()
          toastr.success(res.message)
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })

    }
  
  }


  const generatResume = async requestId => {
    post("/job/generate-resume", { candidate: requestId })
      .then(res => {
        handleTableData()
       
        toastr.success(res.message)
        window.open(`${API_URL}${res?.data}`, "_blank");
      })
      .catch(err => {
        toastr.error(err.response.data.message)
      })
  }
 
  function handleTableData() {
    const filterQuery = `from=${filterObject.fromDate}&to=${filterObject.toDate}&sector=${filterObject.sector}&recruiter=${filterObject.recruiter}&job=${filterObject.jobpost}&applicant=${filterObject.applicant}&status=${filterObject.status}&internship=${filterObject.internship}&page=${page + 1}&limit=${pageSize}`
    get(`/internship/applications?${filterQuery}`).then(res => {
      setCount(res.count)
      let result = res?.data
      let data = result.map((item, index) => {
        item.id = index + 1 + (page*pageSize)
        item.date = moment(item?.date).format("DD-MM-YYYY")
        item.jobname = item?.internship?.name
        item.Recruitername = item?.recruiter?.name
        item.applicantname = item?.applicant?.name
        item.applicantmobile = item?.applicant?.mobile
        if (item.status === 2) {
          item.status1 = (
            <Button
              style={{ width: "80px" }}
              size="sm"
              color="warning"
              disabled
            >
              Shortlisted
            </Button>
          )
        } else if (item.status === 4 || item.status === 5) {
          item.status1 = (
            <Button style={{ width: "80px" }} size="sm" color="danger" disabled>
              Rejected
            </Button>
          )
        } else if (item.status === 3) {
          item.status1 = (
            <Button
              style={{ width: "80px" }}
              size="sm"
              color="success"
              disabled
            >
              Placed
            </Button>
          )
        } else {
          item.status1 = (
            <Select
              options={statusOptions}
              value={statusOptions.filter(
                status => status.value === item.approvedStatus
              )}
              onChange={selected =>
                handleStatusChange(item._id, selected.value)
              }
              menuPosition="fixed"
            ></Select>
          )
        }
        item.action = (
          <div style={{ display: "flex", justifyContent: "center" ,alignItems:"center" }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                
                marginRight: "0.5rem",
              }}
              onClick={() => {
                setPopupView(true)
                setModal(item)
              }}
            ></i>
                       
            {item?.applicant?.uploadedResume && (
                      <a
                        href={API_URL + item?.applicant?.uploadedResume}
                        target="_blank"
                        // rel="noreferrer"
                      >
                        <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="copy-event-tooltip3">Uploaded Resume</Tooltip>
              }
            >
                        <i
                          className="fas fa-file-pdf"
                          style={{
                            fontSize: "1.2em",
                            cursor: "pointer",
                            
                            marginRight: "0.5rem",
                            color: "red",
                          }}
                        ></i>
            </OverlayTrigger>

                      </a>
                    )} 

            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="copy-event-tooltip">Generated Resume</Tooltip>
              }
            >
              <i
                className="fas fa-file-pdf"
                onClick={() => generatResume(item?.applicant?._id)}
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  
                  marginRight: "0.5rem",
                  color: "#0066b2",
                }}
              ></i>
            </OverlayTrigger>
            {/* )} */}

          
          </div>
        )

        return item
      })
      setTableDatadetails(data)
    })
  }

  const handleValueChange = async (selected, name) => {
    const obj = { ...filterObject }
    obj[name] = selected.value

    setFilterObject(obj)
    setPage(0)
  }

  const handleChangeRowsPerPage = event => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handlePageChange = newPage => {
    setPage(newPage)
  }

  return (
    <>
      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
      > 
        <div className="modal-header">
          <div className="modal-title">
            <h5>Applicant Details - {modal?.applicant?.name}
            {modal.status==2&&<Button
              style={{ width: "80px" }}
              size="sm"
              color="warning"
              disabled
              className="mx-2"
            >
              Shortlisted
            </Button>}
            {modal.status==4 &&
             <Button className="mx-2" style={{ width: "80px" }} size="sm" color="danger" disabled>
             Rejected
           </Button>
            }
             {modal.status==5 &&
             <Button className="mx-2" style={{ width: "80px" }} size="sm" color="danger" disabled>
             Rejected
           </Button>
            }
            {modal.status==3&&<Button
              style={{ width: "80px" }}
              size="sm"
              color="success"
              disabled
              className="mx-2"
            >
              Placed
            </Button>}
            
            </h5>
          </div>
          <button
            type="button"
            onClick={() => setPopupView(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <InternshipModal data={modal?._id} />
      </Modal>

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Home"
            breadcrumbItem="Internship - Applications"
          />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>From</Label>
                          <AvField
                            name="from_time"
                            className="form-control"
                            type="date"
                            value={filterObject.fromDate}
                            onChange={e => {
                              handleValueChange(e.target, "fromDate")
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>To</Label>
                          <AvField
                            name="to_time"
                            className="form-control"
                            type="date"
                            value={filterObject.toDate}
                            onChange={e => {
                              handleValueChange(e.target, "toDate")
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Sector</Label>
                          <Select
                            name="sector"
                            classNamePrefix="select2-selection"
                            options={sectors}
                            value={sectors?.filter(
                              item => item.value === filterObject?.sector
                            )}
                            onChange={selected => {
                              handleValueChange(selected, "sector")
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Recruiters</Label>
                          <Select
                            name="Recruiters"
                            classNamePrefix="select2-selection"
                            options={recruitersOptions}
                            value={recruitersOptions?.filter(
                              item => item.value === filterObject?.recruiter
                            )}
                            onChange={selected => {
                              handleValueChange(selected, "recruiter")
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Internship</Label>
                          <Select
                            name="internship"
                            classNamePrefix="select2-selection"
                            options={internshipoptions}
                            value={internshipoptions?.filter(
                              item => item.value === filterObject?.internship
                            )}
                            onChange={selected => {
                              handleValueChange(selected, "internship")
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Status</Label>
                          <Select
                            name="status"
                            classNamePrefix="select2-selection"
                            options={statusoptionsfilter}
                            value={statusoptionsfilter?.filter(
                              item => item.value === filterObject?.status
                            )}
                            onChange={selected => {
                              handleValueChange(selected, "status")
                            }}
                          />
                        </div>
                      </Col>
                      <Col> 
                        <div className="">
                          <Button onClick={reset} style={{marginBottom:'10px'}} color="danger" type="button">
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>


                  <MDBDataTable
                    id="appliedId"
                    bordered
                    responsive
                    searching={false}
                    paging={false}
                    info={false}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>
                       {count > 20 && (
                <MuiCustomTablePagination
                  count={count}
                  page={page}
                  rowsPerPage={pageSize}
                  handleChangePage={handlePageChange}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
              )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default Applied
