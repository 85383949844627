import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Input, Label, Row, Table } from "reactstrap"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import Select from "react-select"
import "./style.scss"
import { useLocation } from "react-router-dom"
import { get } from "../../../helpers/api_helper"
import MuiCustomTablePagination from "../../../components/Common/TablePagination"
import toastr from "toastr"
import { API_URL } from "../../../config"
import useBranch from "../../../hooks/useBranch"
import Admin from "../../../components/Common/Admin"
//import { Breadcrumbs } from '@mui/material'

const ProgressReport = () => {
  const { state } = useLocation()

  const [branch] = useBranch()

  const [csp, setCsp] = useState(
    branch ? branch : { label: "Select...", value: "" }
  )

  const [batch, setBatch] = useState({label:'Select...',value:''})
  const [cspList, setCspList] = useState([])
  const [batchList, setBatchList] = useState([])
  const [internalExam, setInternalExam] = useState([])
  const [marks,setMarks] = useState([])
  const [assessmentType,setAssessmentType] = useState(null)
  const [attendanceMarked,setAttendanceMarked] = useState(false)
  const [internalsMarked,setInternalsMarked] = useState(false)
  const [gradeMarked,setGradeMarked] = useState(false)
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [count, setCount] = useState(0);
  const [search,setSearch] = useState('')

  const fetchCspList = () => {
    get(`options/branches`).then(res => {
      if (res.success) {
        setCspList(res.branches)
      }
    })
  }

  const fetchBatchList = () => {

    get(`assessment/cspBatch?branch=${csp.value}`).then(res => {
      if (res.success) {    
        if (batchList.length !== 0) {
          setBatch({ label: "Select...", value: "" })
        }
        setBatchList(res.data)
      }
    })
  }

  const fetchStudents = () => {
    if (batch.value) {
      get(`assessment/cspBatchStudents?batch=${batch.value}&page=${page + 1}&limit=${pageSize}&keyword=${search}`).then(res => {
        if (res.success) {
          setInternalExam(res.data[0].internalExam || [])
          setAssessmentType(res.data[0].assessmentType || '')
          setMarks(res.data[0].courseAssigned || [])
          setCount(res.data[0].Count)
          setAttendanceMarked(res.data[0].attendanceMarked)
          setInternalsMarked(res.data[0].internalsMarked)
          setGradeMarked(res.data[0].gradeMarked)
          let toastrString = ''
          if(!res.data[0].internalsMarked){
            toastrString += 'Internal marks not added. '
          }
          if(!res.data[0].attendanceMarked){
            toastrString += 'Assessment attendance not marked. '
          }
          if(!res.data[0].gradeMarked){
            toastrString  += 'Assessment grades not marked.'
          }
          if(toastrString.length > 0){
            toastr.info(toastrString)
          }
        }
      })
    }
  }

  useEffect(()=>{
    fetchStudents()
     // eslint-disable-next-line
  },[batch,page,pageSize,search])

  useEffect(() => {
    if (state && state?.batchId) {
      setBatch({label: state.batch, value:state.batchId})
      setCsp({label: state.csp, value:state.cspId})
    }
    fetchCspList()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (csp.value) {
      fetchBatchList()
      
    }
    setSearch('')
    // eslint-disable-next-line
  }, [csp])

  const handleReset = () => {
    setBatch({label:'Select...',value:''})
    setCsp({label:'Select...',value:''})
    setInternalExam([])
    setMarks([])
    setBatchList([])
  }

  const handleChangeRowsPerPage = event => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handlePageChange = newPage => {
    setPage(newPage)
  }

  const viewCertificate = (path)=> {
    if(!path){
      return
    }
    const openPath = `${API_URL}${path}`
    window.open(openPath,'_blank')
  }

  return (
    <div className="page-content">
      <div className="container-fluid" style={{minHeight:'500px'}}>
        <Breadcrumb title="Home" breadcrumbItem="Progress Report" />
        <Card>
          <CardBody>
            <Row className="nav-row">
              <Col md="12">
                <Col xl={12}>
                  <Row>
                  <Admin>
                      <Col xs={4} md={3}>
                        <div>
                          <Label>CSP Name</Label>
                          <Select
                            name="student"
                            classNamePrefix="select2-selection"
                            options={cspList}
                            onChange={item => setCsp(item)}
                            value={csp}
                          />
                        </div>
                      </Col>
                    </Admin>
                    <Col xs={4} md="3">
                      <div>
                        <Label>Batch</Label>
                        <Select
                          name="batch"
                          classNamePrefix="select2-selection"
                          options={batchList}
                          onChange={item => setBatch(item)}
                          value={batch}
                          width={50}
                          isDisabled={csp.value === ''}
                        />
                      </div>
                    </Col>
                    <Col>
                      <Button
                        type="button"
                        style={{ marginTop: "1.7rem", marginLeft: "1rem" }}
                        color="danger"
                        onClick={handleReset}
                      >
                        Reset
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Col>
            </Row>
            <Row style={{marginBottom:'10px'}}><Col md='10'></Col><Col md='2'><Input placeholder="Search..." value={search} onChange={(e)=>setSearch(e.target.value)}></Input></Col></Row>
            <Row>
              <Col xl="12">
                <div style={{ overflowX: "auto", maxWidth: "100%" }}>
               

                  <Table id="progressReportTableId" style={{width:'100%'}} bordered>
                    <thead>
                      <tr>
                      <th style={{ verticalAlign: "middle" }} rowSpan={2}>
                          #
                        </th>
                        <th style={{ verticalAlign: "middle" }} rowSpan={2}>
                          Register No.
                        </th>
                        <th style={{ verticalAlign: "middle" }} rowSpan={2}>
                          Student
                        </th>
                        <th colSpan={internalExam && internalExam.length} rowSpan={internalExam && internalExam.length ? 1 : 2} style={{ verticalAlign: "middle" }}>Internal Marks</th>
                        <th colSpan={2}>Final Assessment</th>

                        <th style={{ verticalAlign: "middle" }} rowSpan={2}>
                          Certificate
                        </th>
                      </tr>
                      <tr>
                      {internalExam && internalExam.length >0 && internalExam.map((item, index) => (
                          <th key={index}>
                            Test {index+1}
                            {item.examType === "0" ? ` (${item.maxMark})` : ""}
                          </th>
                        ))}
                        <th style={{ verticalAlign: "middle" }}>Attendance</th>
                        <th style={{ verticalAlign: "middle" }}>Final Grade</th>
                      </tr>
                    </thead>
                    <tbody>
                    {marks && (marks.length > 0 ? marks.map((item, index) => (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{item.unique_id}</td>
                          <td>{item.firstName || ''}{' '}{item.lastName || ''}</td>
                          {item.internalMarks && item.internalMarks.length > 0 ? item.internalMarks.map((subItem,subIndex)=> (
                              <td key={subIndex}>
                                {internalsMarked ? subItem.mark : ''}
                              </td>
                            )
                          ) : <td></td>}
                          <td>{attendanceMarked ? (item.assessmentAttendance === 0 ? 'Present' : item.assessmentAttendance === 1 ? 'Absent' : '') : ''}</td>
                          <td>{(attendanceMarked && gradeMarked) ? (item.assessmentAttendance === 0 ? item.finalGrade : item.assessmentAttendance === 1 ? 'NA' : '') : ''}</td>
                          <td>{(item.asapCertificateUrl || item.tpCertificateUrl) &&<i
                  className="far fa-file-pdf"
                  onClick={()=>(assessmentType === 1 ? viewCertificate(item.asapCertificateUrl) : assessmentType === 2 ? viewCertificate(item.tpCertificateUrl) : '')}
                  style={{
                    cursor: "pointer",
                    fontSize: "1.2em",
                    marginRight: "0.5rem",
                  }}
                ></i>}</td>
                        </tr>
                      )): <tr><td colSpan={7}> No Rows </td></tr>)}
                      
                    </tbody>
                  </Table>
                  {marks.length > 0 && count > 20 && (
                   <MuiCustomTablePagination
                   count={count}
                   page={page}
                   rowsPerPage={pageSize}
                   handleChangePage={handlePageChange}
                   handleChangeRowsPerPage={handleChangeRowsPerPage}
                 />)}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default ProgressReport
