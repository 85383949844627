import React, { useEffect, useRef, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import Admin from "../../components/Common/Admin"
import { getBranches } from "../../store/options/actions"
import { useDispatch, useSelector } from "react-redux"
import { del, get, post, put } from "../../helpers/api_helper"
import moment from "moment"
import toastr from "toastr"
import "./style.scss"
import Swal from "sweetalert2"
import SelectErrorMessage from "../../components/Common/SelectErrorMessage"

const Updates = () => {
  const [error, setError] = useState({})
  const [masterObject, setMasterObject] = useState({})
  const [selectedCsp, setSelectedCsp] = useState(null)
  const [TableData, setTableData] = useState([])
  const formRef = useRef()
  const { branches } = useSelector(state => state.Options)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getBranches())
    handleTableData()
    // eslint-disable-next-line
  }, [])

  const reset = () => {
    formRef.current.reset()
    handleTableData()
    setError({})
    setSelectedCsp(null)
    setMasterObject({ content: "" })
  }
  function handleTableData() {
    get(`/updates/list`).then(res => {
      // let data = []
      let result = res?.data
      let data = result?.map((item, index) => {
        item.date = moment(item?.date).format("DD-MM-YYYY")
        item.id = index + 1
        item.csp = item?.csp?.name
        item.options = (
          <div>
            {/* <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                navigate(`/community_details/${item?.community}`, {
                  state: { id: item._id },
                })
              }} 
            ></i> */}
            <i
              className="uil-edit-alt"
              onClick={() => {
                handleEdit(item._id)
                // toTop()
              }}
              style={{
                fontSize: "1.2em",
                cursor: "pointer",
                marginRight: "0.5rem",
              }}
            ></i>
            <i
              className="uil-trash-alt"
              style={{ fontSize: "1.2em", cursor: "pointer" }}
              onClick={() => {
                handleDelete(item?._id)
              }}
            ></i>
          </div>
        )
        return item
        // data.push(item)
      })
      setTableData(data)
    })
  }

  const handleEdit = id => {
    // console.log(item, "itemssss")
    // item.cluster = item?.cluster?.value
    // item.sector = item?.sector?.value
    get(`/updates/list/${id}`).then(res => {
      const data = res?.data[0]

      setMasterObject(data)
      setSelectedCsp({
        label: data?.csp.name,
        value: data?.csp?._id,
      })
    })
    // setMasterObject({
    //   _id: item._id,
    //   content:item.content,
    //   csp: item.csp,

    // })
  }
  const handleSubmit = () => {
    let flag = false
    if (selectedCsp === null) {
      flag = true
      setError(pre => ({ ...pre, csp: true }))
    }
    if (flag) {
      return
    }
    if (masterObject._id) {
      put(`/updates/edit/${masterObject._id}`, masterObject).then(res => {
        toastr.success(res.message)
        handleTableData()
        formRef.current.reset()

        reset()
      })
    } else {
      post(`/updates/add`, masterObject)
        .then(res => {
          toastr.success(res.message)
          handleTableData()
          formRef.current.reset()
          reset()
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    }
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "CSP",
        field: "csp",
        width: 150,
      },
      {
        label: "Content",
        field: "content",
        width: 150,
      },

      {
        label: "Actions",
        field: "options",
        sort: "desc",
        width: 400,
      },
    ],
    rows: TableData,
  }
  function handleValueChange(value, name) {
    const obj = { ...masterObject }
    obj[name] = value
    setMasterObject(obj)
  }

  function handleSelectChange(selected, name) {
    switch (name) {
      case "csp":
        setSelectedCsp(selected)
        setMasterObject({ ...masterObject, csp: selected.value })
        break

      default:
        break
    }
  }
  const handleDelete = id => {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`/updates/delete/${id}`)
          .then(res => {
            toastr.success(res.message)
            reset()
          })
          .catch(err => {
            toastr.reset(err.message)
          })
      }
    })
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Updates" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm onValidSubmit={handleSubmit} ref={formRef}>
                    <Row>
                      <Admin>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>CSP</Label>
                            <Select
                              name="csp"
                              options={branches}
                              type="text"
                              onChange={selected => {
                                handleSelectChange(selected, "csp")
                              }}
                              value={selectedCsp}
                            />
                            <SelectErrorMessage
                              show={selectedCsp === null && error.csp}
                              customStyle={{
                                width: "100%",
                                fontSize: "87.5%",
                                color: "var(--bs-form-invalid-color)",
                              }}
                              message="Please select a CSP"
                            />
                          </div>
                        </Col>
                      </Admin>
                      <Col md="4">
                        <div className="mb-3">
                          <Label>Content</Label>
                          <AvField
                            name="content"
                            className="form-control"
                            type="textarea"
                            onChange={e => {
                              handleValueChange(e.target.value, "content")
                            }}
                            value={masterObject.content}
                            placeholder="Content"
                          />
                        </div>
                      </Col>

                      <Col>
                        <div className="mb-3" style={{ paddingTop: "25px" }}>
                          <Button
                            color={masterObject._id ? "warning" : "primary"}
                            type="submit"
                          >
                            {masterObject.id ? "Update" : "Submit"}
                          </Button>
                          <Button
                            style={{ marginLeft: "10px" }}
                            color="danger"
                            type="reset"
                            onClick={reset}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="UpdatesID"
                    bordered
                    responsive
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default Updates
